import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-helpers-liability',
  templateUrl: './helpers-liability.component.html',
  styleUrls: ['./helpers-liability.component.css']
})
export class HelpersLiabilityComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY]);
    }

  ngOnInit() {
  }

}
