<div>
  <div class="col-12">
    <h5>{{ 'motorClaim.confirmVehicle.heading' | translate }}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #MOTOR_HOLDER_NAME [disabled]="!transactionInfo.getIsTransactionOrigin()" [fieldId]="'MOTOR_HOLDER_NAME'" [formGroup]="confirmVehicleDetailFormGroup" name="policyHolderName"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #IS_POLICYHOLDER [fieldId]="'IS_POLICYHOLDER'" [formGroup]="confirmVehicleDetailFormGroup" name="isPolicyholder"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="claim.getIsPolicyholder() === false">
          <qnect-ui #POLICYHOLDER_EMAIL [fieldId]="'POLICYHOLDER_EMAIL'" [formGroup]="confirmVehicleDetailFormGroup" name="policyHolderEmail"></qnect-ui>
        </div>
        <p>{{ 'motorClaim.confirmVehicle.vehicleDetails' | translate }}</p> <BR>
        <div class="form-group">
            <qnect-ui #MOTOR_VEHICLE_REG_NUMBER [disabled]="!transactionInfo.getIsTransactionOrigin()" [fieldId]="'MOTOR_VEHICLE_REG_NUMBER'" [formGroup]="confirmVehicleDetailFormGroup" name="vehicleRegistrationNumber"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #MOTOR_MAKE_AND_MODEL [fieldId]="'MOTOR_MAKE_AND_MODEL'" [formGroup]="confirmVehicleDetailFormGroup" name="makeAndModel"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #MOTOR_YEAR_MNFCTURE [fieldId]="'MOTOR_YEAR_MNFCTURE'" [formGroup]="confirmVehicleDetailFormGroup" name="yearOfManufacture" [dynamicOptions]="yearOptions"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
</div>
