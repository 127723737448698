import {Component, EventEmitter, Injector, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { ClaimTypeItem } from 'src/app/model/claim-type-item.model';
import { UiOptionsModel } from 'src/app/ui/ui-options.model';

@Component({
  selector: 'ec-form-selection-modal',
  templateUrl: './ec-form-selection-modal.html',
  styleUrls: ['./ec-form-selection-modal.scss']
})
export class EcFormSelectionModalComponent extends ClaimsBaseComponent implements OnInit {

  @ViewChild('ec_form_selection_modal', {static: true}) 
  private modal: TemplateRef<any>;

  ecModalForm: FormGroup;
  occurredAtWorkOptions: UiOptionsModel[] = [];

  @Input() claimTypeData;
  @Output() onConfirmEmit = new EventEmitter<ClaimTypeItem>();

  constructor(private injector : Injector,
              private fb: FormBuilder,
              private modalService: NgbModal) {
      super(injector);
  }

  ngOnInit(): void {
    this.ecModalForm = this.fb.group({
      occurredAtWork: [],
      howManySickLeaveDays: [],
      employeeTaking: []
    });

    this.populateOccurredAtWorkOptions();
  }

  ngAfterViewInit() {
    let self = this;
        
    setTimeout(function () {self.hideShowField(self.ecModalForm.get('occurredAtWork').value)},10);
    this.ecModalForm.get('occurredAtWork').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.hideShowField(data);
    });

    setTimeout(function () {self.hideShowField2(self.ecModalForm.get('howManySickLeaveDays').value)},10);
    this.ecModalForm.get('howManySickLeaveDays').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.hideShowField2(data);
    });
  }

  hideShowField(data) {
    if (data === 'Uncertain' || data === 'No') {
      this.ecModalForm.get('howManySickLeaveDays').reset();
      this.ecModalForm.get('howManySickLeaveDays').disable({onlySelf: false, emitEvent: false});
      this.ecModalForm.get('employeeTaking').reset();
      this.ecModalForm.get('employeeTaking').disable({onlySelf: false, emitEvent: false});
    } else if (data === 'Yes') {
      this.ecModalForm.get('howManySickLeaveDays').enable({onlySelf: false, emitEvent: false});
    }
  }

  hideShowField2(data) {
    if (data === 'More than 3') {
      this.ecModalForm.get('employeeTaking').enable({onlySelf: false, emitEvent: false});
    } else {
      this.ecModalForm.get('employeeTaking').reset();
      this.ecModalForm.get('employeeTaking').disable({onlySelf: false, emitEvent: false});
    }
  }

  open() {
    return this.modalService.open(this.modal, {size: 'lg', backdrop: 'static', keyboard: false});
  }

  dismiss() {
    this.resetFields();
    this.modalService.dismissAll();
  }

  onConfirmFormSelection() {
    let claimTypeId = -1;
    let selectedClaimType;
    if (super.validateForm(this.ecModalForm)) {
      if (this.ecModalForm.get('occurredAtWork').value === 'Uncertain' || 
          (this.ecModalForm.get('occurredAtWork').value === 'Yes' 
          && this.ecModalForm.get('howManySickLeaveDays').value === 'More than 3')) {
        //Go to Form 2
        claimTypeId = 1;
      } else if (this.ecModalForm.get('occurredAtWork').value === 'No') {
        //Go to Form 2A
        claimTypeId = 2;
      } else if (this.ecModalForm.get('occurredAtWork').value === 'Yes' 
              && this.ecModalForm.get('howManySickLeaveDays').value === '3 or Less') {
        //Go to Form 2B
        claimTypeId = 0;
      }
      selectedClaimType = this.claimTypeData.find(claimTypeItem => claimTypeItem.claimTypeId === claimTypeId);

    }

    this.onConfirmEmit.emit(selectedClaimType);
    this.dismiss();
  }

  populateOccurredAtWorkOptions() {
    this.occurredAtWorkOptions.push(new UiOptionsModel("Yes", "claimSection.generalLabels.generalButtonLabels.yes"));
    this.occurredAtWorkOptions.push(new UiOptionsModel("Uncertain", "ecClaim.accidentDetails.uncertain"));
    this.occurredAtWorkOptions.push(new UiOptionsModel("No", "claimSection.generalLabels.generalButtonLabels.no"));
  }

  resetFields() {
    this.ecModalForm.get('occurredAtWork').reset();
    this.ecModalForm.get('howManySickLeaveDays').reset();
    this.ecModalForm.get('howManySickLeaveDays').disable({onlySelf: false, emitEvent: false});
    this.ecModalForm.get('employeeTaking').reset();
    this.ecModalForm.get('employeeTaking').disable({onlySelf: false, emitEvent: false});
  }
}
