export class DomesticEmployerLiability {

    private wasHelperInjured: boolean;
    private helperName: string;
    private helperHkidPassport: string;
    private helperDetails: string;

    getWasHelperInjured(): boolean {
        return this.wasHelperInjured;
    }

    setWasHelperInjured(wasHelperInjured: boolean): void {
        this.wasHelperInjured = wasHelperInjured;
    }

    getHelperName(): string {
        return this.helperName;
    }

    setHelperName(helperName: string): void {
        this.helperName = helperName;
    }

    getHelperHkidPassport(): string {
        return this.helperHkidPassport;
    }

    setHelperHkidPassport(helperHkidPassport: string): void {
        this.helperHkidPassport = helperHkidPassport;
    }

    getHelperDetails(): string {
        return this.helperDetails;
    }

    setHelperDetails(helperDetails: string): void {
        this.helperDetails = helperDetails;
    }
    static jsonConvert(domesticEmployerLiability: DomesticEmployerLiability): DomesticEmployerLiability {
        domesticEmployerLiability = Object.assign(new DomesticEmployerLiability(), domesticEmployerLiability);
        return domesticEmployerLiability;
    }
}
