import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable()
export class PageService {

    entryUrl: string = "";
    entryUrlSubject: BehaviorSubject<string> = new BehaviorSubject("");
    parameters = {};

    private tooltipTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    tooltipText$: Observable<string> = this.tooltipTextSubject.asObservable();

    getParameters() {
        return this.parameters;
    }

    getEntryUrl(): Observable<string>  {
        return this.entryUrlSubject;
    }

    getEntryUrlDirect(): string  {
        return this.entryUrl;
    }

    setEntryUrl(value: string) {
        this.entryUrl = value;
        this.entryUrlSubject.next(this.entryUrl);
    }

    showTooltip(txt: string){
        this.tooltipTextSubject.next(txt);
    }

    hideTooltip(){
        this.tooltipTextSubject.next('');
    }
}


