import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Claim} from "../../../../model/claim.model";
import {MissedEvent} from "../../../../model/travel/missed-event.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {takeUntil} from "rxjs";
import { TransactionInfoService } from "src/app/services/transaction-info.service";

@Component({
  selector: 'app-detail-of-miss-event',
  templateUrl: './detail-of-miss-event.component.html',
  styleUrls: ['./detail-of-miss-event.component.css']
})
export class DetailOfMissEventComponent extends ClaimsBaseComponent implements OnInit {
    @ViewChild('lossType', {static: true}) el: ElementRef;
    missedEventDetailsForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    missedEvent: MissedEvent;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.missedEvent = this.claim.getClaimTravel().getMissedEvent();
        if(this.missedEvent.getDateOfMissedEvent() == null){
            this.missedEvent.setDateOfMissedEvent(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {

        this.pushGAViewMissEventDetails();

        this.missedEventDetailsForm = this.fb.group({

         missedEventDate: [this.missedEvent.getDateOfMissedEvent()],
         countryEventHeld: [this.missedEvent.getCountryEventHeld()],
         reasonOfMissEvent: [this.missedEvent.getReasonOfMissedEvent()],
         otherReasonOfMissEvent: [this.missedEvent.getOtherReasonOfMissedEvent()]
        });

    }

    ngAfterViewInit() {

        this.missedEventDetailsForm.valueChanges.subscribe(data => {

            this.missedEvent.setDateOfMissedEvent(super.getValueInComponent('missedEventDate'));
            this.missedEvent.setCountryEventHeld(this.missedEventDetailsForm.get('countryEventHeld').value);
            this.missedEvent.setCountryEventHeldDesc(super.getTextInComponent('countryEventHeld'));
            this.missedEvent.setReasonOfMissedEvent(this.missedEventDetailsForm.get('reasonOfMissEvent').value);
            this.missedEvent.setReasonOfMissedEventDesc(super.getTextInComponent('reasonOfMissEvent'));
            this.missedEvent.setOtherReasonOfMissedEvent(this.missedEventDetailsForm.get('otherReasonOfMissEvent').value);

            this.validateClaimDetailsComplete();
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideOthersField(self.missedEvent.getReasonOfMissedEvent()),10});
        this.missedEventDetailsForm.get('reasonOfMissEvent').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOthersField(data);
        });
    }

    hideOthersField(reasonOfMissEvent) {

        if (reasonOfMissEvent && reasonOfMissEvent.toLowerCase() === 'others') {
            this.missedEventDetailsForm.get('otherReasonOfMissEvent').enable({onlySelf: false, emitEvent: false});
        } else {
            this.missedEventDetailsForm.get('otherReasonOfMissEvent').reset();
            this.missedEventDetailsForm.get('otherReasonOfMissEvent').disable({onlySelf: false, emitEvent: false});
        }
    }


    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 11, subMenuIndex: 0});

            this.router.navigate(["/claimform/missedEvent/lossOfMissEvent"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateClaimDetailsComplete() {
       let isFormValidOnChange: boolean = super.validateFormOnChange(this.missedEventDetailsForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 11, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 11, subMenuIndex: 0});
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.missedEventDetailsForm);
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewMissEventDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Missed Event – Details',
            'vPath': '/claim/missed-event/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
