<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.detailsOfIncident.detailsOfIncident' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                     {{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.detailsOfIncident.dateOfAdmission' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(hospitalizationHelper.getDateOfAdmission())}}
                </div>
            </div>
            <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                         {{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.detailsOfIncident.dateOfDischarge' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(hospitalizationHelper.getDateOfDischarge())}}
                    </div>
                </div>            
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.detailsOfIncident.incidentOccured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{hospitalizationHelper.getCountryIncidentOccurDesc()}}
                </div>
            </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.detailsOfIncident.descOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{hospitalizationHelper.getDescOfIncident()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.detailsOfIncident.helpersLiabilitAmountClaim' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{hospitalizationHelper.getAmountClaimCurrency()}} {{hospitalizationHelper.getAmountClaim() | number : '1.2-2'}}
                    </div>
                </div>      
                </div>               
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>