
export class OtherClaimDMH {

    private dateOfIncident: Date;
    private countryIncidentOccur: string;
    private countryIncidentOccurDesc: string;
    private descOfIncident: string;
    private amountClaim: string;
    private amountClaimCurrency: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getDateOfIncident(): Date {
        return this.dateOfIncident;
    }

    setDateOfIncident(value: Date) {
        this.dateOfIncident = value;
    }

    getCountryIncidentOccur(): string {
        return this.countryIncidentOccur;
    }

    setCountryIncidentOccur(value: string) {
        this.countryIncidentOccur = value;
    }

    getCountryIncidentOccurDesc(): string {
        return this.countryIncidentOccurDesc;
    }

    setCountryIncidentOccurDesc(value: string) {
        this.countryIncidentOccurDesc = value;
    }

    getDescOfIncident(): string {
        return this.descOfIncident;
    }

    setDescOfIncident(value: string) {
        this.descOfIncident = value;
    }

    getAmountClaim(): string {
        return this.amountClaim;
    }

    setAmountClaim(value: string) {
        this.amountClaim = value;
    }

    getAmountClaimCurrency(): string {
        return this.amountClaimCurrency;
    }

    setAmountClaimCurrency(value: string) {
        this.amountClaimCurrency = value;
    }


    
    static jsonConvert(otherClaimDMH: OtherClaimDMH): OtherClaimDMH {

        //todo: convert date fields object if any
        if (otherClaimDMH.getDateOfIncident() != null) {
            otherClaimDMH.setDateOfIncident(new Date(otherClaimDMH.getDateOfIncident()));
        }

        return otherClaimDMH;
    }
}