<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.reroute.title2' | translate}}</p>
  </div>
  <BR>

  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #TRAVEL_REROUTE_05_TAKEDIFFROUTE [fieldId]="'TRAVEL_REROUTE_05_TAKEDIFFROUTE'" [formGroup]="reroutingForm" name="reroutingTakeDiffRoute"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_REROUTE_06_TRANSPOCHANGE [fieldId]="'TRAVEL_REROUTE_06_TRANSPOCHANGE'" [formGroup]="reroutingForm" name="reroutingTranspoChange"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

