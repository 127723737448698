import {Component, OnInit} from "@angular/core";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimTypes } from "src/app/model/claim-type.model";

@Component({
  selector: 'app-home-lost-personal-item',
  templateUrl: './home-lost-personal-item.component.html',
  styleUrls: ['./home-lost-personal-item.component.css']
})
export class HomeLostPersonalItemComponent implements OnInit {
    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]);
    }

  ngOnInit() {
  }

}
