import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class HelpersLiabilityFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('PHOTOS', 'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.photos', SmartqDocuments.DMH_TPDAMAGEORINJURY, true));
        requiredDocuments.push(new DocumentField('THIRDPARTY', 'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.thirdParty', SmartqDocuments.DMH_TPCORRESPONDENCE, true));
        requiredDocuments.push(new DocumentField('WITNESSSTATEMENT', 'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.witnessStatement', SmartqDocuments.DMH_WITNESS, false));
        if(this.claim.getClaimDomesticHelper().getHelpersLiability().getIsIncidentReport()){
            requiredDocuments.push(new DocumentField('POLICEREPORT', 'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.policeReport', SmartqDocuments.DMH_POLICE, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}
