import { Component, OnInit } from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
  selector: 'app-accidental-damage',
  templateUrl: './accidental-damage.component.html',
  styleUrls: ['./accidental-damage.component.css']
})
export class AccidentalDamageComponent implements OnInit {

  constructor( private transactionInfoService: TransactionInfoService) {
      this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT]);
  }

  ngOnInit() {
  }

}


