<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && !formControl.valid && formControl.touched) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
  <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
  <ng-container *ngIf="!mapError">
    <div class="col-md-{{uiModel.size}} col-xs-12">
          <input formControlName="{{uiModel.name}}" type="text" id="{{fieldId}}"
          placeholder="{{uiModel.placeholder | translate}}"
          [ngClass]="{'ui-disabled' : uiModel.disabled}" class="form-control" *ngIf="!uiModel.textOnly" maxlength="{{uiModel.maxlength}}" [attr.list]="dataList" autocomplete="off"
          [readonly]="disabled"
          (change)="zoomOut()"
          autocorrect="off" autocapitalize="off" spellcheck="off" #mapLocation>
    </div>
    <div class="col-md-{{uiModel.size}} col-xs-12">
      <p style="font-size: 14px;" *ngIf="uiModel.isOtherTextField">{{charsLeft}} {{'claimSection.generalLabels.placeHolders.remainingChar' | translate}}  </p>

      <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | translate}}</p>
      <ui-error *ngIf="formControl.touched || uiModel.textOnly" [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
    </div>
    <div class="col-md-{{uiModel.size}} col-xs-12" *ngIf="locationPermissionGranted">
        <small id="map-btn" (click)="setCurrentUserLocation()">{{'motorClaim.maps.currentLocation' | translate}}</small>
    </div>
    <br/>
    <div class="col-md-{{uiModel.size}} col-xs-12" *ngIf="!mapError">
      <agm-map (mapClick)="placeMarker($event)" [latitude]="lat" [longitude]="lng" [maxZoom]="20" [minZoom]="1" [zoom]="zoom"> 
          <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true" (dragEnd)="placeMarker($event)">
          </agm-marker> 
        </agm-map>
    </div>
  </ng-container>
  <ng-container *ngIf="mapError">
    <div class="col-md-{{uiModel.size}} col-xs-12">
          <input formControlName="{{uiModel.name}}" type="text" id="{{fieldId}}"
          placeholder="{{uiModel.placeholder | translate}}"
          [ngClass]="{'ui-disabled' : uiModel.disabled}" class="form-control" *ngIf="!uiModel.textOnly" maxlength="{{uiModel.maxlength}}" [attr.list]="dataList" autocomplete="off"
          [readonly]="disabled"
          autocorrect="off" autocapitalize="off" spellcheck="off">
    </div>
    <div class="col-md-{{uiModel.size}} col-xs-12">
      <p style="font-size: 14px;" *ngIf="uiModel.isOtherTextField">{{charsLeft}} {{'claimSection.generalLabels.placeHolders.remainingChar' | translate}}  </p>

      <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | translate}}</p>
      <ui-error *ngIf="formControl.touched || uiModel.textOnly" [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
    </div>
  </ng-container>
  
</div>
