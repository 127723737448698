<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'homeProduct.authorityReport.title' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #HOME_AUTHORITY_INCIDENT_REPORT [fieldId]="'HOME_AUTHORITY_INCIDENT_REPORT'" [formGroup]="policeReportForm" name="hasReportedIncidentToPolice"></qnect-ui>
        </div>
        <ng-container  *ngIf="this.policeReport.getHasReportedIncidentToPolice()">
          <div class="form-group">
            <qnect-ui #HOME_AUTHORITY_REPORT_DATE [fieldId]="'HOME_AUTHORITY_REPORT_DATE'" [formGroup]="policeReportForm" [claimsDateValidator]="this.claim.getClaimPersonalAccident().getAccidentDetails().getAccidentDate()" name="dateOfReport"></qnect-ui>
          </div>
          <div class="form-group"> 
            <qnect-ui #HOME_AUTHORITY_REFERENCE_NO [fieldId]="'HOME_AUTHORITY_REFERENCE_NO'" [formGroup]="policeReportForm" name="referenceNumber"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
