import {BaggageDelayItem} from "../claim-travel-baggage-delay-item.model";
import {BaggageDelayItemDetail} from "./baggage-delay-item.model";

export class BaggageDelay {

    private dateOfDelBaggage: Date;
    private baggageDelayTime: Date;
    private collectBaggageTime: Date;
    private wasDelayOnReturnFlight: boolean;
    private emergencyPurchasedItemsArray: BaggageDelayItemDetail[];
    private countryOccurred: string;
    private countryOccurredDesc: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }
    
    getEmergencyPurchasedItemsArray(): BaggageDelayItemDetail[] {
        return this.emergencyPurchasedItemsArray;
    }

    setEmergencyPurchasedItemsArray(value: BaggageDelayItemDetail[]) {
        this.emergencyPurchasedItemsArray = value;
    }

    getCollectBaggageTime(): Date {
        return this.collectBaggageTime;
    }

    setCollectBaggageTime(value: Date) {
        this.collectBaggageTime = value;
    }

    getWasDelayOnReturnFlight(): boolean {
        return this.wasDelayOnReturnFlight;
    }

    setWasDelayOnReturnFlight(value: boolean) {
        this.wasDelayOnReturnFlight = value;
    }

    getDateOfDelBaggage(): Date {
        return this.dateOfDelBaggage;
    }

    setDateOfDelBaggage(value: Date) {
        this.dateOfDelBaggage = value;
    }

    getBaggageDelayTime(): Date {
        return this.baggageDelayTime;
    }

    setBaggageDelayTime(value: Date) {
        this.baggageDelayTime = value;
    }

    getCountryOccurred(): string {
        return this.countryOccurred;
    }

    setCountryOccurred(value: string) {
        this.countryOccurred = value;
    }

    getCountryOccurredDesc(): string {
        return this.countryOccurredDesc;
    }

    setCountryOccurredDesc(value: string) {
        this.countryOccurredDesc = value;
    }

    static jsonConvert(baggageDelay: BaggageDelay): BaggageDelay {

      // travel claim type BDEL - Baggage Delay
    if (baggageDelay.getDateOfDelBaggage() != null) {
        baggageDelay.setDateOfDelBaggage(new Date(baggageDelay.getDateOfDelBaggage()));
    }
    if (baggageDelay.getBaggageDelayTime() != null) {
        baggageDelay.setBaggageDelayTime(new Date(baggageDelay.getBaggageDelayTime()));
    }

    if(baggageDelay.getCollectBaggageTime() != null) {
        baggageDelay.setCollectBaggageTime(new Date(baggageDelay.getCollectBaggageTime()));
    }

    if(baggageDelay.getEmergencyPurchasedItemsArray() != null){
        let baggageDelayItemDetail: BaggageDelayItemDetail[] = [];

        for (const baggageItems of baggageDelay.getEmergencyPurchasedItemsArray()) {
                let item = Object.assign(new BaggageDelayItemDetail(),baggageItems);
                item = BaggageDelayItemDetail.jsonConvert(item);
                baggageDelayItemDetail.push(item); 
        }
        
        baggageDelay.setEmergencyPurchasedItemsArray(baggageDelayItemDetail);
        
    }
        return baggageDelay;
    }

}
