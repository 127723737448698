import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UiComponent} from '../../ui.component';

@Directive({selector: '[aboveMaxDateValidator]'})
export class AboveMaxDateValidatorDirective implements OnInit, AfterViewInit {

  @Input('aboveMaxDateValidator') dateRef: Date;
  @Input() operator: string;
  dateComponent: UiComponent;

  constructor(private host: UiComponent) {
    this.dateComponent = host;
  }

  ngOnInit() {
    this.initializeDateValidator();
  }

  ngAfterViewInit() {
    this.initializeDateValidator();
  }

  initializeDateValidator() {
    const dateComponentType = this.dateComponent.fieldConfig['type'];
    if (dateComponentType === 'datepicker' || dateComponentType === 'datetimepicker') {
      this.dateComponent.setupCustomValidatorList([this.validate()]);
      if (this.dateRef) {
        this.dateComponent.update();
      }
    } else {
      console.log('claimsDateValidator not in datepicker component. UI Component validator error: ' +
        this.dateComponent.fieldId + ' - ' + this.dateComponent.fieldConfig['name']);
    }
  }

  validate = () => {
    return (c: FormControl) => {
      let isError: boolean;
      if (this.dateRef && this.dateComponent.getDateValue()) {
        //console.log(this.dateRef);
        //console.log(new Date(this.dateRef));
        //console.log(this.dateComponent.getDateValue());
        // Just prepared operator string for flexible date validation in future :)
        switch (this.operator) {
          case 'from':
            isError = new Date(this.dateRef) < new Date(this.dateComponent.getDateValue());
            break;
          default: // from
            isError = new Date(this.dateRef) < new Date(this.dateComponent.getDateValue());
            break;
        }
      }

      if (isError) {
        return {'aboveMaxDateValidator': true};
      }
      return null;
    }
  }

}
