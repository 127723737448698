export class ThirdPartyLegalDetails {

    private hasAnyClaimMade: boolean;
    private tpClaimOtherInsurerName: string;
    private tpClaimContactNumber: string;

    getHasAnyClaimMade(): boolean {
        return this.hasAnyClaimMade;
    }

    setHasAnyClaimMade(hasAnyClaimMade: boolean): void {
        this.hasAnyClaimMade = hasAnyClaimMade;
    }

    getTpClaimOtherInsurerName(): string {
        return this.tpClaimOtherInsurerName;
    }

    setTpClaimOtherInsurerName(tpClaimOtherInsurerName: string): void {
        this.tpClaimOtherInsurerName = tpClaimOtherInsurerName;
    }

    getTpClaimContactNumber(): string {
        return this.tpClaimContactNumber;
    }

    setTpClaimContactNumber(tpClaimContactNumber: string): void {
        this.tpClaimContactNumber = tpClaimContactNumber;
    }
    static jsonConvert(thirdPartyLegalDetails: ThirdPartyLegalDetails): ThirdPartyLegalDetails {
        thirdPartyLegalDetails = Object.assign(new ThirdPartyLegalDetails(), thirdPartyLegalDetails);
        return thirdPartyLegalDetails;
    }
}
