<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : !formControl.valid && formControl.touched}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
    <div class="col-md-{{uiModel.size}}">
        <div class="flex-container">
            <div [ngClass]="{'radioCard' : option.value, 'selectedCard' : formControl.value == option.value}"
                 *ngFor="let option of uiModel.options" (click)="toggle(option)" >
                <input type="radio" formControlName="{{uiModel.name}}" id="{{fieldId}}_{{option.value}}{{frmArrayIndex}}"
                       value="{{option.value}}">
                <label class="ui-radio" for="{{fieldId}}_{{option.value}}{{frmArrayIndex}}">
                    <div class="ui-label">
                        <span>{{option.label | translate}}</span>
                    </div>
                </label>
            </div>
        </div>
        <ui-error *ngIf="formControl.touched" [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
    </div>
</div>
