<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.deathAndSeriousBodyInjury.detailsOfAccident.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #PAT1_DATE_THE_ACCIDENT_OCCURRED [fieldId]="'PAT1_DATE_THE_ACCIDENT_OCCURRED'" [formGroup]="deTailOfAccidentForm" name="dateTheAccidentOccurred"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="!claim.getClaimTravel().getGBAPlanType()">
          <qnect-ui #PAT16_COUNTRY_OF_LOSS [fieldId]="'PAT16_COUNTRY_OF_LOSS'" [formGroup]="deTailOfAccidentForm" name="countryOfLoss" ></qnect-ui>
        </div>
        <div class="form-group" *ngIf="claim.getClaimTravel().getGBAPlanType()">
          <qnect-ui #GBA_PLACE_OF_LOSS [fieldId]="'GBA_PLACE_OF_LOSS'" [formGroup]="deTailOfAccidentForm" name="placeOfLoss"></qnect-ui>
        </div>
        <div class="form-group click-more-content" id="q1">
          <qnect-ui #PAT2_NATURE_OF_ILLNESS [fieldId]="'PAT2_NATURE_OF_ILLNESS'" [formGroup]="deTailOfAccidentForm" name="natureOfIllness"></qnect-ui>
        </div>
        <div class="form-group click-more-content" *ngIf="this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness() =='Others'">
          <qnect-ui #PAT3_OTHER_TYPE_DESC [fieldId]="'PAT3_OTHER_TYPE_DESC'" [formGroup]="deTailOfAccidentForm" name="otherTypeDesc"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PAT4_WAS_MUGGING [fieldId]="'PAT4_WAS_MUGGING'" [formGroup]="deTailOfAccidentForm" name="wasMugging"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary new-btn float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #not_covered_modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{deathAndSeriousBodyInjuryLoss.getNatureOfIllnessDesc()}}</h4>
  </div>
  <div class="modal-body modal_contents">
    <p>{{'claimSection.deathAndSeriousBodyInjury.detailsOfAccident.majorBurnsNotCovered' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-modal" (click)="c('Close click')">
      {{'claimSection.generalLabels.generalButtonLabels.ok' | translate}}
    </button>
  </div>
</ng-template>