import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { ClaimTypes } from "../../model/claim-type.model";
import { DocumentField } from "../../model/document-field";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class MissedConnectionFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.copyTicket', SmartqDocuments.AIRTICKET, true));
        requiredDocuments.push(new DocumentField('RELDOC', 'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.relDocs', SmartqDocuments.CORRESPONDENCE, true));
        requiredDocuments.push(new DocumentField('RECEIPTS', 'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptDoc', SmartqDocuments.INVOICE, true));
        if(this.claim.getClaimTravel().getMissConnection().getHasExtTransCost()){
            requiredDocuments.push(new DocumentField('EXTRATRANSCOST', 'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptTrans', SmartqDocuments.INVOICE, true));
        }
        if(this.claim.getClaimTravel().getMissConnection().getHasExtAccomCost()){
            requiredDocuments.push(new DocumentField('EXTRAACCOMCOST', 'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptAccom', SmartqDocuments.CORRESPONDENCE, true));
        }
        if(UtilitiesService.checkForMinors(this.claim)){
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}