export class DynamicRouteModel {

    claimIndex: number;                
    routeIndex: number;
    routeUrl: string;

    constructor(claimIndex: number, routeIndex: number, routeUrl: string){
        this.claimIndex = claimIndex;
        this.routeIndex = routeIndex;
        this.routeUrl = routeUrl;
    } 

    getClaimIndex(): number {
        return this.claimIndex;
    }

    setClaimIndex(value: number) {
        this.claimIndex = value;
    }

    getRouteIndex(): number {
        return this.routeIndex;
    }

    setRouteIndex(value: number) {
        this.routeIndex = value;
    }

    getRouteUrl(): string {
        return this.routeUrl;
    }

    setRouteUrl(value: string) {
        this.routeUrl = value;
    }

    static jsonConvert(dynamiCRouteModel: DynamicRouteModel[]): DynamicRouteModel[] {
        dynamiCRouteModel = dynamiCRouteModel.map(x => Object.assign(new DynamicRouteModel(x.getClaimIndex(), x.getRouteIndex(), x.getRouteUrl()), x));
        return dynamiCRouteModel;
    }

}