import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-baggage-delay',
  templateUrl: './baggage-delay.component.html',
  styleUrls: ['./baggage-delay.component.css']
})
export class BaggageDelayComponent implements OnInit {

    constructor(private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]);
    }

  ngOnInit() {
  }

}
