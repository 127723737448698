import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visited-doctor-support-doc',
  templateUrl: './visited-doctor-support-doc.component.html',
  styleUrls: ['./visited-doctor-support-doc.component.css']
})
export class VisitedDoctorSupportDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
