import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-employee-compensation',
  templateUrl: './employee-compensation.component.html',
  styleUrls: ['./employee-compensation.component.css']
})
export class EmployeeCompensationComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]);
    }

  ngOnInit() {
  }

}
