<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{'ecClaim.employeeDetails.title' | translate}}</h5>
    <p>{{'ecClaim.employeeDetails.subHeading' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="injuredEmployeeDetailsForm">
        <ng-container *ngIf="transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_EC_FORM2A' ||
          transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_EC_FORM2'" [ngTemplateOutlet]="form2form2a"></ng-container>
        <ng-container *ngIf="transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_EC_FORM2B'" [ngTemplateOutlet]="form2b"></ng-container>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #form2b>
  <div class="form-group">
    <qnect-ui #EC_INJURED_NAME [fieldId]="'EC_INJURED_NAME'" [formGroup]="injuredEmployeeDetailsForm" name="name"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_ID [fieldId]="'EC_INJURED_ID'" [formGroup]="injuredEmployeeDetailsForm" name="idNumber"
              [hkidValidator]="this.employeeDetails.getIdType()?.toLowerCase() == 'hkid' ? 'hkid' : ''"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_TELEPHONE [fieldId]="'EC_INJURED_TELEPHONE'" [formGroup]="injuredEmployeeDetailsForm" name="telephoneNumber"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_EMAIL [fieldId]="'EC_INJURED_EMAIL'" [formGroup]="injuredEmployeeDetailsForm" name="emailAddress"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_ADDRESS [fieldId]="'EC_INJURED_ADDRESS'" [formGroup]="injuredEmployeeDetailsForm" name="address"></qnect-ui>
  </div>
</ng-template>

<ng-template #form2form2a>
  <div class="form-group">
    <qnect-ui #EC_INJURED_NAME [fieldId]="'EC_INJURED_NAME'" [formGroup]="injuredEmployeeDetailsForm" name="name"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_ID [fieldId]="'EC_INJURED_ID'" [formGroup]="injuredEmployeeDetailsForm" name="idNumber"
              [hkidValidator]="this.employeeDetails.getIdType()?.toLowerCase() == 'hkid' ? 'hkid' : ''"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_BIRTHDATE [fieldId]="'EC_INJURED_BIRTHDATE'" [formGroup]="injuredEmployeeDetailsForm" name="birthDate"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_GENDER [fieldId]="'EC_INJURED_GENDER'" [formGroup]="injuredEmployeeDetailsForm" name="gender"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_TELEPHONE [fieldId]="'EC_INJURED_TELEPHONE'" [formGroup]="injuredEmployeeDetailsForm" name="telephoneNumber"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_EMAIL [fieldId]="'EC_INJURED_EMAIL'" [formGroup]="injuredEmployeeDetailsForm" name="emailAddress"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_FAX [fieldId]="'EC_INJURED_FAX'" [formGroup]="injuredEmployeeDetailsForm" name="faxNumber"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_ADDRESS [fieldId]="'EC_INJURED_ADDRESS'" [formGroup]="injuredEmployeeDetailsForm" name="address" [maxLength]="isForm2A ? 62 : ''"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_OCCUPATION [fieldId]="'EC_INJURED_OCCUPATION'" [formGroup]="injuredEmployeeDetailsForm" name="occupation"></qnect-ui>
  </div>
  <div class="form-group">
    <qnect-ui #EC_INJURED_IS_APPRENTICE [fieldId]="'EC_INJURED_IS_APPRENTICE'" [formGroup]="injuredEmployeeDetailsForm" name="isApprentice"></qnect-ui>
  </div>
  <div class="form-group">
    <label>{{ 'ecClaim.employeeDetails.durationDate' | translate }}</label>
    <div class="row">
      <div class="col-6 col-md-4" style="padding-right: 0;">
        <div class="form-group">
          <label style="font-size: 15px;">{{ 'ecClaim.accidentDetails.from' | translate }}</label>
          <qnect-ui #EC_INJURED_DURATIONDATEFROM [fieldId]="'EC_INJURED_DURATIONDATEFROM'" [formGroup]="injuredEmployeeDetailsForm"
                    name="durationDateFrom"></qnect-ui>
        </div>
      </div>
      <div class="col-6 col-md-4" style="padding-left: 0;">
        <div class="form-group">
          <label class="date-label" style="font-size: 15px;">{{ 'ecClaim.accidentDetails.to' | translate }}</label>
          <qnect-ui #EC_INJURED_DURATIONDATETO [fieldId]="'EC_INJURED_DURATIONDATETO'" [formGroup]="injuredEmployeeDetailsForm"
                    name="durationDateTo" [claimsDateValidator]="employeeDetails.getDurationDateFrom()"></qnect-ui>
        </div>
      </div>
      <div class="col-md-4">
      </div>
    </div>
  </div>
</ng-template>