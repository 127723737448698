import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {Claim} from "../../../../model/claim.model";
import {MissedEvent} from "../../../../model/travel/missed-event.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
  selector: 'app-loss-of-miss-event',
  templateUrl: './loss-of-miss-event.component.html',
  styleUrls: ['./loss-of-miss-event.component.css']
})
export class LossOfMissEventComponent extends ClaimsBaseComponent implements OnInit {

    public hasRefund: boolean;
    lossOfMissEventForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    missedEvent: MissedEvent;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.missedEvent = this.claim.getClaimTravel().getMissedEvent();
    }

    ngOnInit() {

        this.pushGAViewMissEventLoss();
        if(this.missedEvent.getTicketCostCurrency() == null){
            this.missedEvent.setTicketCostCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()))
        }

        this.lossOfMissEventForm  = this.fb.group({

            amtPaid: [this.missedEvent.getTicketCost()],
            amtPaidCurrency: [this.missedEvent.getTicketCostCurrency()],
            isCreditCardPayment: [super.getBooleanString(this.missedEvent.getIsCreditCardPayment())],
            hasRefund: [super.getBooleanString(this.missedEvent.getHasRefund())],
            refundAmt: [this.missedEvent.getRefundAmount()],
            refundAmtCurrency: [this.missedEvent.getRefundAmountCurrency()]
        });
    }

    ngAfterViewInit() {

        this.lossOfMissEventForm.valueChanges.subscribe(data => {

            let missedEvent: MissedEvent = this.claim.getClaimTravel().getMissedEvent();

            missedEvent.setTicketCost(super.getValueInComponent('amtPaid'));
            missedEvent.setTicketCostCurrency(this.lossOfMissEventForm.get('amtPaidCurrency').value);
            if (this.lossOfMissEventForm.get('isCreditCardPayment').value != null) {
                missedEvent.setIsCreditCardPayment(this.lossOfMissEventForm.get('isCreditCardPayment').value == 'true');
            }
            if (this.lossOfMissEventForm.get('hasRefund').value != null) {
                missedEvent.setHasRefund(this.lossOfMissEventForm.get('hasRefund').value == 'true');
            }
            missedEvent.setRefundAmount(super.getValueInComponent('refundAmt'));
            missedEvent.setRefundAmountCurrency(this.lossOfMissEventForm.get('refundAmtCurrency').value);

            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideRefundField(self.getBooleanString(self.missedEvent.getHasRefund())),10});
        this.lossOfMissEventForm.get('hasRefund').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideRefundField(data);
        });

    }

    hideRefundField(hasRefund) {
        if (hasRefund == 'true') {
            this.lossOfMissEventForm.get('refundAmt').enable({onlySelf: false, emitEvent: false});
            this.lossOfMissEventForm.get('refundAmtCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.lossOfMissEventForm.get('refundAmt').reset();
            this.lossOfMissEventForm.get('refundAmtCurrency').setValue(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
            this.lossOfMissEventForm.get('refundAmt').disable({onlySelf: false, emitEvent: false});
            this.lossOfMissEventForm.get('refundAmtCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }


    goToNext() {

        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 11, subMenuIndex: 1});

            this.router.navigate(["/claimform/missedEvent/missEventSupportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.lossOfMissEventForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 11, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 11, subMenuIndex: 1});
        }
    }

    back() {
        this.router.navigate(["/claimform/missedEvent/detailOfMissEvent"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.lossOfMissEventForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewMissEventLoss() {
        (<any>window).dataLayer.push({
            'pageStep': 'Missed Event– Loss',
            'vPath': '/claim/missed-event/loss',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
