import { Component, OnInit } from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
  selector: 'app-fire',
  templateUrl: './fire.component.html',
  styleUrls: ['./fire.component.css']
})
export class FireComponent implements OnInit {

  constructor( private transactionInfoService: TransactionInfoService) {
      this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE]);
  }

  ngOnInit() {
  }

}


