<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
        <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{ 'claimSection.persLiab.detail' | translate}}</p>
    </div>
    <BR>

    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form>
                <div class="form-group">
                    <qnect-ui #PERS_LIAB_04_INCIDENT_DESCRIPTION [fieldId]="'PERS_LIAB_04_INCIDENT_DESCRIPTION'"  [formGroup]="liabilityForm" name="liabilityIncidentDescription"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #PERS_LIAB_05_AMOUNT [fieldId]="'PERS_LIAB_05_AMOUNT'" [formGroup]="liabilityForm" name="liabilityAmount"></qnect-ui>
                </div>
                <div class="form-group">

                </div>
            </form>
        </div>
    <div class="col-xl-3 col-lg-12">
    </div>
</div>
    
<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>

