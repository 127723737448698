import {Component, OnInit, Input} from '@angular/core';

import {NotificationMessage} from './notification-message.model';

@Component({
  selector: 'qnect-info-notification',
  templateUrl: './info-notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class InfoNotificationComponent implements OnInit {

  @Input()
  messages: NotificationMessage[] = [];
  @Input() showPreamble: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

}
