export let bodyParts = [
  {
    id: 'left-eye',
    label: 'ecClaim.bodyParts.leftEye',
    value: 'Left Eye',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
    formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'right-eye',
    label: 'ecClaim.bodyParts.rightEye',
    value: 'Right Eye',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'left-ear',
    label: 'ecClaim.bodyParts.leftEar',
    value: 'Left Ear',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Ear',
    injury: []
  },
  {
    id: 'right-ear',
    label: 'ecClaim.bodyParts.rightEar',
    value: 'Right Ear',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Ear',
    injury: []
  },
  {
    id: 'nose',
    label: 'ecClaim.bodyParts.nose',
    value: 'Nose',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Nose',
    injury: []
  },
  {
    id: 'forehead',
    label: 'ecClaim.bodyParts.forehead',
    value: 'Forehead',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Skull/scalp',
    injury: []
  },
  {
    id: 'chin',
    label: 'ecClaim.bodyParts.chin',
    value: 'Chin',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'top-of-the-head',
    label: 'ecClaim.bodyParts.topOfTheHead',
    value: 'Top of the Head',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Skull/scalp',
    injury: []
  },
  {
    id: 'left-temple',
    label: 'ecClaim.bodyParts.leftTemple',
    value: 'Left Temple',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Skull/scalp',
    injury: []
  },
  {
    id: 'right-temple',
    label: 'ecClaim.bodyParts.rightTemple',
    value: 'Right Temple',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Skull/scalp',
    injury: []
  },
  {
    id: 'left-of-the-head',
    label: 'ecClaim.bodyParts.leftOfTheHead',
    value: 'Left of the Head',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Skull/scalp',
    injury: []
  },
  {
    id: 'right-of-the-head',
    label: 'ecClaim.bodyParts.rightOfTheHead',
    value: 'Right of the Head',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Skull/scalp',
    injury: []
  },

  {
    id: 'left-cheek',
    label: 'ecClaim.bodyParts.leftCheek',
    value: 'Left Cheek',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'right-cheek',
    label: 'ecClaim.bodyParts.rightCheek',
    value: 'Right Cheek',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'mouth',
    label: 'ecClaim.bodyParts.mouth',
    value: 'Mouth',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Mouth/tooth',
    injury: []
  },
  {
    id: 'teeth',
    label: 'ecClaim.bodyParts.teeth',
    value: 'Teeth',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Mouth/tooth',
    injury: []
  },
  {
    id: 'left-eyelid',
    label: 'ecClaim.bodyParts.leftEyelid',
    value: 'Left Eyelid',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'right-eyelid',
    label: 'ecClaim.bodyParts.rightEyelid',
    value: 'Right Eyelid',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'left-eyebrow',
    label: 'ecClaim.bodyParts.leftEyebrow',
    value: 'Left Eyebrow',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'right-eyebrow',
    label: 'ecClaim.bodyParts.rightEyebrow',
    value: 'Right Eyebrow',
    area: 'head',
    areaLabel: 'ecClaim.injuryDetails.head',
    areaValue: 'Head',
      formBodyPart: 'Face',
    injury: []
  },
  {
    id: 'right-palm',
    label: 'ecClaim.bodyParts.rightPalm',
    value: 'Right Palm',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Hand/palm',
    injury: []
  },
  {
    id: 'right-distal-phalanx-of-the-little-finger',
    label: 'ecClaim.bodyParts.rightDistalPhalanxOfTheLittleFinger',
    value: 'Right Distal Phalanx of the Little Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-middle-phalanx-of-the-little-finger',
    label: 'ecClaim.bodyParts.rightMiddlePhalanxOfTheLittleFinger',
    value: 'Right Middle Phalanx of the Little Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-proximal-phalanx-of-the-little-finger',
    label: 'ecClaim.bodyParts.rightProximalPhalanxOfTheLittleFinger',
    value: 'Right Proximal Phalanx of the Little Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-distal-phalanx-of-the-ring-finger',
    label: 'ecClaim.bodyParts.rightDistalPhalanxOfTheRingFinger',
    value: 'Right Distal Phalanx of the Ring Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-middle-phalanx-of-the-ring-finger',
    label: 'ecClaim.bodyParts.rightMiddlePhalanxOfTheRingFinger',
    value: 'Right Middle Phalanx of the Ring Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-proximal-phalanx-of-the-ring-finger',
    label: 'ecClaim.bodyParts.rightProximalPhalanxOfTheRingFinger',
    value: 'Right Proximal Phalanx of the Ring Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-distal-phalanx-of-the-middle-finger',
    label: 'ecClaim.bodyParts.rightDistalPhalanxOfTheMiddleFinger',
    value: 'Right Distal Phalanx of the Middle Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-middle-phalanx-of-the-middle-finger',
    label: 'ecClaim.bodyParts.rightMiddlePhalanxOfTheMiddleFinger',
    value: 'Right Middle Phalanx of the Middle Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-proximal-phalanx-of-the-middle-finger',
    label: 'ecClaim.bodyParts.rightProximalPhalanxOfTheMiddleFinger',
    value: 'Right Proximal Phalanx of the Middle Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-distal-phalanx-of-the-index-finger',
    label: 'ecClaim.bodyParts.rightDistalPhalanxOfTheIndexFinger',
    value: 'Right Distal Phalanx of the Index Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-middle-phalanx-of-the-index-finger',
    label: 'ecClaim.bodyParts.rightMiddlePhalanxOfTheIndexFinger',
    value: 'Right Middle Phalanx of the Index Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-proximal-phalanx-of-the-index-finger',
    label: 'ecClaim.bodyParts.rightProximalPhalanxOfTheIndexFinger',
    value: 'Right Proximal Phalanx of the Index Finger',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-wrist',
    label: 'ecClaim.bodyParts.rightWrist',
    value: 'Right Wrist',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Hand/palm',
    injury: []
  },
  {
    id: 'right-proximal-phalanx-of-the-thumb',
    label: 'ecClaim.bodyParts.rightProximalPhalanxOfTheThumb',
    value: 'Right Proximal Phalanx of the Thumb',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-distal-phalanx-of-the-thumb',
    label: 'ecClaim.bodyParts.rightDistalPhalanxOfTheThumb',
    value: 'Right Distal Phalanx of the Thumb',
    area: 'right-hand',
    areaLabel: 'ecClaim.injuryDetails.rightHand',
    areaValue: 'Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-palm',
    label: 'ecClaim.bodyParts.leftPalm',
    value: 'Left Palm',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Hand/palm',
    injury: []
  },
  {
    id: 'left-distal-phalanx-of-the-little-finger',
    label: 'ecClaim.bodyParts.leftDistalPhalanxOfTheLittleFinger',
    value: 'Left Distal Phalanx of the Little Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-middle-phalanx-of-the-little-finger',
    label: 'ecClaim.bodyParts.leftMiddlePhalanxOfTheLittleFinger',
    value: 'Left Middle Phalanx of the Little Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-proximal-phalanx-of-the-little-finger',
    label: 'ecClaim.bodyParts.leftProximalPhalanxOfTheLittleFinger',
    value: 'Left Proximal Phalanx of the Little Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-distal-phalanx-of-the-ring-finger',
    label: 'ecClaim.bodyParts.leftDistalPhalanxOfTheRingFinger',
    value: 'Left Distal Phalanx of the Ring Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-middle-phalanx-of-the-ring-finger',
    label: 'ecClaim.bodyParts.leftMiddlePhalanxOfTheRingFinger',
    value: 'Left Middle Phalanx of the Ring Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-proximal-phalanx-of-the-ring-finger',
    label: 'ecClaim.bodyParts.leftProximalPhalanxOfTheRingFinger',
    value: 'Left Proximal Phalanx of the Ring Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-distal-phalanx-of-the-middle-finger',
    label: 'ecClaim.bodyParts.leftDistalPhalanxOfTheMiddleFinger',
    value: 'Left Distal Phalanx of the Middle Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-middle-phalanx-of-the-middle-finger',
    label: 'ecClaim.bodyParts.leftMiddlePhalanxOfTheMiddleFinger',
    value: 'Left Middle Phalanx of the Middle Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-proximal-phalanx-of-the-middle-finger',
    label: 'ecClaim.bodyParts.leftProximalPhalanxOfTheMiddleFinger',
    value: 'Left Proximal Phalanx of the Middle Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-distal-phalanx-of-the-index-finger',
    label: 'ecClaim.bodyParts.leftDistalPhalanxOfTheIndexFinger',
    value: 'Left Distal Phalanx of the Index Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-middle-phalanx-of-the-index-finger',
    label: 'ecClaim.bodyParts.leftMiddlePhalanxOfTheIndexFinger',
    value: 'Left Middle Phalanx of the Index Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-proximal-phalanx-of-the-index-finger',
    label: 'ecClaim.bodyParts.leftProximalPhalanxOfTheIndexFinger',
    value: 'Left Proximal Phalanx of the Index Finger',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-wrist',
    label: 'ecClaim.bodyParts.leftWrist',
    value: 'Left Wrist',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Hand/palm',
    injury: []
  },
  {
    id: 'left-proximal-phalanx-of-the-thumb',
    label: 'ecClaim.bodyParts.leftProximalPhalanxOfTheThumb',
    value: 'Left Proximal Phalanx of the Thumb',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-distal-phalanx-of-the-thumb',
    label: 'ecClaim.bodyParts.leftDistalPhalanxOfTheThumb',
    value: 'Left Distal Phalanx of the Thumb',
    area: 'left-hand',
    areaLabel: 'ecClaim.injuryDetails.leftHand',
    areaValue: 'Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-shin',
    label: 'ecClaim.bodyParts.rightShin',
    value: 'Right Shin',
    area: 'right-leg',
    areaLabel: 'ecClaim.injuryDetails.rightLeg',
    areaValue: 'Right Leg',
      formBodyPart: 'Leg',
    injury: []
  },
  {
    id: 'right-knee',
    label: 'ecClaim.bodyParts.rightKnee',
    value: 'Right Knee',
    area: 'right-leg',
    areaLabel: 'ecClaim.injuryDetails.rightLeg',
    areaValue: 'Right Leg',
      formBodyPart: 'Knee',
    injury: []
  },
  {
    id: 'back-of-the-right-knee',
    label: 'ecClaim.bodyParts.backOfTheRightKnee',
    value: 'Back of the Right Knee',
    area: 'right-leg',
    areaLabel: 'ecClaim.injuryDetails.rightLeg',
    areaValue: 'Right Leg',
      formBodyPart: 'Knee',
    injury: []
  },
  {
    id: 'right-thigh',
    label: 'ecClaim.bodyParts.rightThigh',
    value: 'Right Thigh',
    area: 'right-leg',
    areaLabel: 'ecClaim.injuryDetails.rightLeg',
    areaValue: 'Right Leg',
      formBodyPart: 'Thigh',
    injury: []
  },
  {
    id: 'left-shin',
    label: 'ecClaim.bodyParts.leftShin',
    value: 'Left Shin',
    area: 'left-leg',
    areaLabel: 'ecClaim.injuryDetails.leftLeg',
    areaValue: 'Left Leg',
      formBodyPart: 'Leg',
    injury: []
  },
  {
    id: 'left-knee',
    label: 'ecClaim.bodyParts.leftKnee',
    value: 'Left Knee',
    area: 'left-leg',
    areaLabel: 'ecClaim.injuryDetails.leftLeg',
    areaValue: 'Left Leg',
      formBodyPart: 'Knee',
    injury: []
  },
  {
    id: 'back-of-the-left-knee',
    label: 'ecClaim.bodyParts.backOfTheLeftKnee',
    value: 'Back of the Left Knee',
    area: 'left-leg',
    areaLabel: 'ecClaim.injuryDetails.leftLeg',
    areaValue: 'Left Leg',
      formBodyPart: 'Knee',
    injury: []
  },
  {
    id: 'left-thigh',
    label: 'ecClaim.bodyParts.leftThigh',
    value: 'Left Thigh',
    area: 'left-leg',
    areaLabel: 'ecClaim.injuryDetails.leftLeg',
    areaValue: 'Left Leg',
      formBodyPart: 'Thigh',
    injury: []
  },
  {
    id: 'neck',
    label: 'ecClaim.bodyParts.neck',
    value: 'Neck',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Neck',
    injury: []
  },
  {
    id: 'chest',
    label: 'ecClaim.bodyParts.chest',
    value: 'Chest',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Chest',
    injury: []
  },
  {
    id: 'abdomen',
    label: 'ecClaim.bodyParts.abdomen',
    value: 'Abdomen',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Abdomen',
    injury: []
  },
  {
    id: 'groin',
    label: 'ecClaim.bodyParts.groin',
    value: 'Groin',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Pelvis/groin',
    injury: []
  },
  {
    id: 'left-hip',
    label: 'ecClaim.bodyParts.leftHip',
    value: 'Left Hip',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Hip',
    injury: []
  },
  {
    id: 'right-hip',
    label: 'ecClaim.bodyParts.rightHip',
    value: 'Right Hip',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Hip',
    injury: []
  },
  {
    id: 'genital',
    label: 'ecClaim.bodyParts.genital',
    value: 'Genital',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Pelvis/groin',
    injury: []
  },  
  {
    id: 'left-breast',
    label: 'ecClaim.bodyParts.leftBreast',
    value: 'Left Breast',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Chest',
    injury: []
  },  
  {
    id: 'right-breast',
    label: 'ecClaim.bodyParts.rightBreast',
    value: 'Right Breast',
    area: 'neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.neckTrunk',
    areaValue: 'Neck & Trunk',
      formBodyPart: 'Chest',
    injury: []
  },
  {
    id: 'right-forearm',
    label: 'ecClaim.bodyParts.rightForearm',
    value: 'Right Forearm',
    area: 'right-arm',
    areaLabel: 'ecClaim.injuryDetails.rightArm',
    areaValue: 'Right Arm',
      formBodyPart: 'Forearm',
    injury: []
  },
  {
    id: 'right-elbow',
    label: 'ecClaim.bodyParts.rightElbow',
    value: 'Right Elbow',
    area: 'right-arm',
    areaLabel: 'ecClaim.injuryDetails.rightArm',
    areaValue: 'Right Arm',
      formBodyPart: 'Elbow',
    injury: []
  },
  {
    id: 'right-upper-arm',
    label: 'ecClaim.bodyParts.rightUpperArm',
    value: 'Right Upper Arm',
    area: 'right-arm',
    areaLabel: 'ecClaim.injuryDetails.rightArm',
    areaValue: 'Right Arm',
      formBodyPart: 'Upper arm',
    injury: []
  },
  {
    id: 'right-armpit',
    label: 'ecClaim.bodyParts.rightArmpit',
    value: 'Right Armpit',
    area: 'right-arm',
    areaLabel: 'ecClaim.injuryDetails.rightArm',
    areaValue: 'Right Arm',
      formBodyPart: 'Shoulder',
    injury: []
  },
  {
    id: 'right-shoulder',
    label: 'ecClaim.bodyParts.rightShoulder',
    value: 'Right Shoulder',
    area: 'right-arm',
    areaLabel: 'ecClaim.injuryDetails.rightArm',
    areaValue: 'Right Arm',
      formBodyPart: 'Shoulder',
    injury: []
  },
  {
    id: 'left-forearm',
    label: 'ecClaim.bodyParts.leftForearm',
    value: 'Left Forearm',
    area: 'left-arm',
    areaLabel: 'ecClaim.injuryDetails.leftArm',
    areaValue: 'Left Arm',
      formBodyPart: 'Forearm',
    injury: []
  },
  {
    id: 'left-elbow',
    label: 'ecClaim.bodyParts.leftElbow',
    value: 'Left Elbow',
    area: 'left-arm',
    areaLabel: 'ecClaim.injuryDetails.leftArm',
    areaValue: 'Left Arm',
      formBodyPart: 'Elbow',
    injury: []
  },
  {
    id: 'left-upper-arm',
    label: 'ecClaim.bodyParts.leftUpperArm',
    value: 'Left Upper Arm',
    area: 'left-arm',
    areaLabel: 'ecClaim.injuryDetails.leftArm',
    areaValue: 'Left Arm',
      formBodyPart: 'Upper arm',
    injury: []
  },
  {
    id: 'left-armpit',
    label: 'ecClaim.bodyParts.leftArmpit',
    value: 'Left Armpit',
    area: 'left-arm',
    areaLabel: 'ecClaim.injuryDetails.leftArm',
    areaValue: 'Left Arm',
      formBodyPart: 'Shoulder',
    injury: []
  },
  {
    id: 'left-shoulder',
    label: 'ecClaim.bodyParts.leftShoulder',
    value: 'Left Shoulder',
    area: 'left-arm',
    areaLabel: 'ecClaim.injuryDetails.leftArm',
    areaValue: 'Left Arm',
      formBodyPart: 'Shoulder',
    injury: []
  },
  {
    id: 'left-ankle',
    label: 'ecClaim.bodyParts.leftAnkle',
    value: 'Left Ankle',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Ankle',
    injury: []
  },
  {
    id: 'top-of-the-left-foot',
    label: 'ecClaim.bodyParts.topOfTheLeftFoot',
    value: 'Top of the Left Foot',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'left-heel',
    label: 'ecClaim.bodyParts.leftHeel',
    value: 'Left Heel',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'bottom-of-the-left-foot',
    label: 'ecClaim.bodyParts.bottomOfTheLeftFoot',
    value: 'Bottom of the Left Foot',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'left-5th-toe',
    label: 'ecClaim.bodyParts.left5thToe',
    value: 'Left 5th Toe',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'left-4th-toe',
    label: 'ecClaim.bodyParts.left4thToe',
    value: 'Left 4th Toe',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'left-3rd-toe',
    label: 'ecClaim.bodyParts.left3rdToe',
    value: 'Left 3rd Toe',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'left-2nd-toe',
    label: 'ecClaim.bodyParts.left2ndToe',
    value: 'Left 2nd Toe',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'left-big-toe',
    label: 'ecClaim.bodyParts.leftBigToe',
    value: 'Left Big Toe',
    area: 'left-foot',
    areaLabel: 'ecClaim.injuryDetails.leftFoot',
    areaValue: 'Left Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'right-ankle',
    label: 'ecClaim.bodyParts.rightAnkle',
    value: 'Right Ankle',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Ankle',
    injury: []
  },
  {
    id: 'top-of-the-right-foot',
    label: 'ecClaim.bodyParts.topOfTheRightFoot',
    value: 'Top of the Right Foot',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'right-heel',
    label: 'ecClaim.bodyParts.rightHeel',
    value: 'Right Heel',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'bottom-of-the-right-foot',
    label: 'ecClaim.bodyParts.bottomOfTheRightFoot',
    value: 'Bottom of the Right Foot',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'right-5th-toe',
    label: 'ecClaim.bodyParts.right5thToe',
    value: 'Right 5th Toe',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'right-4th-toe',
    label: 'ecClaim.bodyParts.right4thToe',
    value: 'Right 4th Toe',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'right-3rd-toe',
    label: 'ecClaim.bodyParts.right3rdToe',
    value: 'Right 3rd Toe',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'right-2nd-toe',
    label: 'ecClaim.bodyParts.right2ndToe',
    value: 'Right 2nd Toe',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'right-big-toe',
    label: 'ecClaim.bodyParts.rightBigToe',
    value: 'Right Big Toe',
    area: 'right-foot',
    areaLabel: 'ecClaim.injuryDetails.rightFoot',
    areaValue: 'Right Foot',
      formBodyPart: 'Foot',
    injury: []
  },
  {
    id: 'back-of-the-head',
    label: 'ecClaim.bodyParts.backOfTheHead',
    value: 'Back of the Head',
    area: 'back-head',
    areaLabel: 'ecClaim.injuryDetails.backHead',
    areaValue: 'Back Head',
      formBodyPart: 'Skull/scalp',
    injury: []
  },
  {
    id: 'left-calf',
    label: 'ecClaim.bodyParts.leftCalf',
    value: 'Left Calf',
    area: 'left-leg',
    areaLabel: 'ecClaim.injuryDetails.leftLeg',
    areaValue: 'Left Leg',
    formBodyPart: 'Leg',
    injury: []
  },
  {
    id: 'right-calf',
    label: 'ecClaim.bodyParts.rightCalf',
    value: 'Right Calf',
    area: 'right-leg',
    areaLabel: 'ecClaim.injuryDetails.rightLeg',
    areaValue: 'Right Leg',
      formBodyPart: 'Leg',
    injury: []
  },
  {
    id: 'nail-of-the-left-little-finger',
    label: 'ecClaim.bodyParts.nailOfTheLeftLittleFinger',
    value: 'Nail of the Left Little Finger',
    area: 'back-left-hand',
    areaLabel: 'ecClaim.injuryDetails.backLeftHand',
    areaValue: 'Back Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'nail-of-the-left-ring-finger',
    label: 'ecClaim.bodyParts.nailOfTheLeftRingFinger',
    value: 'Nail of the Left Ring Finger',
    area: 'back-left-hand',
    areaLabel: 'ecClaim.injuryDetails.backLeftHand',
    areaValue: 'Back Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'left-knuckles',
    label: 'ecClaim.bodyParts.leftKnuckles',
    value: 'Left Knuckles',
    area: 'back-left-hand',
    areaLabel: 'ecClaim.injuryDetails.backLeftHand',
    areaValue: 'Back Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'back-of-the-left-hand',
    label: 'ecClaim.bodyParts.backOfTheLeftHand',
    value: 'Back of the Left Hand',
    area: 'back-left-hand',
    areaLabel: 'ecClaim.injuryDetails.backLeftHand',
    areaValue: 'Back Left Hand',
      formBodyPart: 'Hand/palm',
    injury: []
  },
  {
    id: 'nail-of-the-left-middle-finger',
    label: 'ecClaim.bodyParts.nailOfTheLeftMiddleFinger',
    value: 'Nail of the Left Middle Finger',
    area: 'back-left-hand',
    areaLabel: 'ecClaim.injuryDetails.backLeftHand',
    areaValue: 'Back Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'nail-of-the-left-index-finger',
    label: 'ecClaim.bodyParts.nailOfTheLeftIndexFinger',
    value: 'Nail of the Left Index Finger',
    area: 'back-left-hand',
    areaLabel: 'ecClaim.injuryDetails.backLeftHand',
    areaValue: 'Back Left Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'nail-of-the-left-thumb',
    label: 'ecClaim.bodyParts.nailOfTheLeftThumb',
    value: 'Nail of the Left Thumb',
    area: 'back-left-hand',
    areaLabel: 'ecClaim.injuryDetails.backLeftHand',
    areaValue: 'Back Left Hand',
      formBodyPart: 'Finger',
    injury: []
  }, 
  {
    id: 'nail-of-the-right-little-finger',
    label: 'ecClaim.bodyParts.nailOfTheRightLittleFinger',
    value: 'Nail of the Right Little Finger',
    area: 'back-right-hand',
    areaLabel: 'ecClaim.injuryDetails.backRightHand',
    areaValue: 'Back Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'nail-of-the-right-ring-finger',
    label: 'ecClaim.bodyParts.nailOfTheRightRingFinger',
    value: 'Nail of the Right Ring Finger',
    area: 'back-right-hand',
    areaLabel: 'ecClaim.injuryDetails.backRightHand',
    areaValue: 'Back Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'right-knuckles',
    label: 'ecClaim.bodyParts.rightKnuckles',
    value: 'Right Knuckles',
    area: 'back-right-hand',
    areaLabel: 'ecClaim.injuryDetails.backRightHand',
    areaValue: 'Back Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'back-of-the-right-hand',
    label: 'ecClaim.bodyParts.backOfTheRightHand',
    value: 'Back of the Right Hand',
    area: 'back-right-hand',
    areaLabel: 'ecClaim.injuryDetails.backRightHand',
    areaValue: 'Back Right Hand',
      formBodyPart: 'Hand/palm',
    injury: []
  },
  {
    id: 'nail-of-the-right-middle-finger',
    label: 'ecClaim.bodyParts.nailOfTheRightMiddleFinger',
    value: 'Nail of the Right Middle Finger',
    area: 'back-right-hand',
    areaLabel: 'ecClaim.injuryDetails.backRightHand',
    areaValue: 'Back Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'nail-of-the-right-index-finger',
    label: 'ecClaim.bodyParts.nailOfTheRightIndexFinger',
    value: 'Nail of the Right Index Finger',
    area: 'back-right-hand',
    areaLabel: 'ecClaim.injuryDetails.backRightHand',
    areaValue: 'Back Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'nail-of-the-right-thumb',
    label: 'ecClaim.bodyParts.nailOfTheRightThumb',
    value: 'Nail of the Right Thumb',
    area: 'back-right-hand',
    areaLabel: 'ecClaim.injuryDetails.backRightHand',
    areaValue: 'Back Right Hand',
      formBodyPart: 'Finger',
    injury: []
  },
  {
    id: 'upper-back',
    label: 'ecClaim.bodyParts.upperBack',
    value: 'Upper Back',
    area: 'back-neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.backNeckTrunk',
    areaValue: 'Back Neck & Trunk',
      formBodyPart: 'Back',
    injury: []
  },
  {
    id: 'back-of-the-neck',
    label: 'ecClaim.bodyParts.backOfTheNeck',
    value: 'Back Of The Neck',
    area: 'back-neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.backNeckTrunk',
    areaValue: 'Back Neck & Trunk',
      formBodyPart: 'Back',
    injury: []
  },
  {
    id: 'lower-back',
    label: 'ecClaim.bodyParts.lowerBack',
    value: 'Lower Back',
    area: 'back-neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.backNeckTrunk',
    areaValue: 'Back Neck & Trunk',
      formBodyPart: 'Back',
    injury: []
  },
  {
    id: 'buttock',
    label: 'ecClaim.bodyParts.buttock',
    value: 'Buttock',
    area: 'back-neck-trunk',
    areaLabel: 'ecClaim.injuryDetails.backNeckTrunk',
    areaValue: 'Back Neck & Trunk',
      formBodyPart: 'Trunk',
    injury: []
  }
];