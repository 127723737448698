export class Repatriation {

    private dateOfRepatIncident: Date;
    private countryOfRepatIncident: string;
    private countryOfRepatIncidentDesc: string;
    private descriptionOfRepatIncident: string;
    private repatClaimedAmount: string;
    private repatClaimedCurrency: string;

    private hasAgentFeePaid : boolean = null;
    private agentFeePaidCurrency: string;
    private agentFeePaidAmount: string;

    private hasTempHelper : boolean = null;
    private tempHelperAmountCurrency: string;
    private tempHelperAmount: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

	getCountryOfRepatIncident(): string {
		return this.countryOfRepatIncident;
	}

	setCountryOfRepatIncident(value: string) {
		this.countryOfRepatIncident = value;
    }
    
    getCountryOfRepatIncidentDesc(): string {
		return this.countryOfRepatIncidentDesc;
	}

	setCountryOfRepatIncidentDesc(value: string) {
		this.countryOfRepatIncidentDesc = value;
	}

	getDescriptionOfRepatIncident(): string {
		return this.descriptionOfRepatIncident;
	}

	setDescriptionOfRepatIncident(value: string) {
		this.descriptionOfRepatIncident = value;
	}

	getRepatClaimedAmount(): string {
		return this.repatClaimedAmount;
	}

	setRepatClaimedAmount(value: string) {
		this.repatClaimedAmount = value;
	}

	getHasAgentFeePaid(): boolean {
		return this.hasAgentFeePaid;
	}

	setHasAgentFeePaid(value: boolean) {
		this.hasAgentFeePaid = value;
	}

	getAgentFeePaidCurrency(): string {
		return this.agentFeePaidCurrency;
    }
    
	setAgentFeePaidCurrency(value: string) {
		this.agentFeePaidCurrency = value;
	}

	getRepatClaimedCurrency(): string {
		return this.repatClaimedCurrency;
    }
    
	setRepatClaimedCurrency(value: string) {
		this.repatClaimedCurrency = value;
	}

    getDateOfRepatIncident(): Date {
        return this.dateOfRepatIncident;
    }
    setDateOfRepatIncident(value: Date) {
        this.dateOfRepatIncident = value;
    }

    getAgentFeePaidAmount(): string {
        return this.agentFeePaidAmount;
    }
    setAgentFeePaidAmount(value: string) {
        this.agentFeePaidAmount = value;
    }

    getHasTempHelper(): boolean {
        return this.hasTempHelper;
    }
    setHasTempHelper(value: boolean) {
        this.hasTempHelper = value;
    }

    getTempHelperAmountCurrency(): string {
        return this.tempHelperAmountCurrency;
    }
    setTempHelperAmountCurrency(value: string) {
        this.tempHelperAmountCurrency = value;
    }

    getTempHelperAmount(): string {
        return this.tempHelperAmount;
    }
    setTempHelperAmount(value: string) {
        this.tempHelperAmount = value;
    }

    static jsonConvert(repatriation: Repatriation): Repatriation {

        if (repatriation.getDateOfRepatIncident() != null) {
            repatriation.setDateOfRepatIncident(new Date(repatriation.getDateOfRepatIncident()));
        }

        return repatriation;
    }

}