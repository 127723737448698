import { ECAccidentDetails } from "./employee-compensation/ec-accident-details.model";
import { EmployerDetails } from "./employee-compensation/employer-details.model";
import {CompensationDetails} from './employee-compensation/compensation-details.model';
import {EmployeeDetails} from './employee-compensation/employee-details.model';
import {PeriodOfSickLeave} from './employee-compensation/period-of-sick-leave.model';
import {InsuranceDetails} from './employee-compensation/insurance-details.model';
import { OccupationalDisease } from "./employee-compensation/occupational-disease.model";
import { EmployeeEarnings } from "./employee-compensation/employee-earnings.model";
import {FatalCase} from './employee-compensation/fatal-case.model';
import { ECAccidentPlaceDetails } from "./employee-compensation/ec-accident-place-details.model";

export class ClaimEmployeeCompensation {

    private employeeDetails: EmployeeDetails = new EmployeeDetails();
    private accidentPlaceDetails: ECAccidentPlaceDetails = new ECAccidentPlaceDetails();
    private accidentDetails: ECAccidentDetails = new ECAccidentDetails();
    private compensationDetails: CompensationDetails = new CompensationDetails();
    private employerDetails: EmployerDetails = new EmployerDetails();
    private insuranceDetails: InsuranceDetails = new InsuranceDetails();
    private occupationalDisease: OccupationalDisease = new OccupationalDisease();
    private employeeEarnings: EmployeeEarnings = new EmployeeEarnings();
    private fatalCase: FatalCase = new FatalCase();
    private currency: string;

    private locationPermissionGranted: boolean = true;
    private mapReachable: boolean = true;
    private periodOfSickLeaves : PeriodOfSickLeave[] = [];
    private totalSickLeaveDays: number;
    private isSickLeaveContinuing: boolean;
    private isDutySuitableForEmployee: boolean;
    private contactEmployeeForValueAddedService: boolean;

    
    public getEmployeeDetails(): EmployeeDetails {
        return this.employeeDetails;
    }

    public setEmployeeDetails(employeeDetails: EmployeeDetails): void {
        this.employeeDetails = employeeDetails;
    }
    
    public setAccidentDetails(accidentDetails: ECAccidentDetails): void {
        this.accidentDetails = accidentDetails;
    }

    public getAccidentPlaceDetails(): ECAccidentPlaceDetails {
        return this.accidentPlaceDetails;
    }

    public getAccidentDetails(): ECAccidentDetails {
        return this.accidentDetails;
    }

    public setAccidentPlaceDetails(accidentPlaceDetails: ECAccidentPlaceDetails): void {
        this.accidentPlaceDetails = accidentPlaceDetails;
    }

    getCompensationDetails(): CompensationDetails {
        return this.compensationDetails;
    }

    setCompensationDetails(compensationDetails: CompensationDetails) {
        this.compensationDetails = compensationDetails;
    }

    public getEmployerDetails(): EmployerDetails{
        return this.employerDetails;
    }

    public setEmployerDetails(employerDetails: EmployerDetails): void {
        this.employerDetails = employerDetails;
    }


    public getInsuranceDetails(): InsuranceDetails{
        return this.insuranceDetails;
    }

    public setInsuranceDetails(insuranceDetails: InsuranceDetails): void {
        this.insuranceDetails = insuranceDetails;
    }
    
    public getOccupationalDisease(): OccupationalDisease{
        return this.occupationalDisease;
    }

    public setOccupationalDisease(occupationalDisease: OccupationalDisease): void {
        this.occupationalDisease = occupationalDisease;
    }

    public getEmployeeEarnings(): EmployeeEarnings {
        return this.employeeEarnings;
    }

    public setEmployeeEarnings(employeeEarnings: EmployeeEarnings): void {
        this.employeeEarnings = employeeEarnings;
    }

    public getFatalCase(): FatalCase {
        return this.fatalCase;
    }

    public setFatalCase(fatalCase: FatalCase): void {
        this.fatalCase = fatalCase;
    }

    getCurrency(): string {
        return this.currency;
    }

    setCurrency(value: string) {
        this.currency = value;
    }

    getLocationPermissionGranted(): boolean {
        return this.locationPermissionGranted;
    }

    setLocationPermissionGranted(value: boolean) {
        this.locationPermissionGranted = value;
    }

    getMapReachable(): boolean {
        return this.mapReachable;
    }
    
    setMapReachable(value: boolean) {
        this.mapReachable = value;
    }

    public getTotalSickLeaveDays(): number {
        return this.totalSickLeaveDays;
    }

    public setTotalSickLeaveDays(totalSickLeaveDays: number): void {
        this.totalSickLeaveDays = totalSickLeaveDays;
    }

    public getPeriodOfSickLeaves(): PeriodOfSickLeave[] {
        return this.periodOfSickLeaves;
    }

    public setPeriodOfSickLeaves(periodOfSickLeaves: PeriodOfSickLeave[]) {
        this.periodOfSickLeaves = periodOfSickLeaves;
    }

    public getIsSickLeaveContinuing(): boolean {
        return this.isSickLeaveContinuing;
    }

    public setIsSickLeaveContinuing(value: boolean) {
        this.isSickLeaveContinuing = value;
    }

    public getIsDutySuitableForEmployee(): boolean {
        return this.isDutySuitableForEmployee;
    }

    public setIsDutySuitableForEmployee(value: boolean) {
        this.isDutySuitableForEmployee = value;
    }

    public getContactEmployeeForValueAddedService(): boolean {
        return this.contactEmployeeForValueAddedService;
    }

    public setContactEmployeeForValueAddedService(value: boolean) {
        this.contactEmployeeForValueAddedService = value;
    }

    static jsonConvertClaimEmployeeCompensation(claimEC: ClaimEmployeeCompensation): ClaimEmployeeCompensation {

        claimEC = Object.assign(new ClaimEmployeeCompensation(), claimEC);

        if (claimEC !== null) {

            if (claimEC.getEmployeeDetails()) {
                claimEC.setEmployeeDetails(Object.assign(new EmployeeDetails(), claimEC.getEmployeeDetails()))
                EmployeeDetails.jsonConvert(claimEC.getEmployeeDetails());
            }

            if (claimEC.getAccidentPlaceDetails()) {
                claimEC.setAccidentPlaceDetails(Object.assign(new ECAccidentPlaceDetails(), claimEC.getAccidentPlaceDetails()))
                ECAccidentPlaceDetails.jsonConvert(claimEC.getAccidentPlaceDetails());
            }

            if (claimEC.getAccidentDetails()) {
                claimEC.setAccidentDetails(Object.assign(new ECAccidentDetails(), claimEC.getAccidentDetails()))
                ECAccidentDetails.jsonConvert(claimEC.getAccidentDetails());
            }

            if (claimEC.getCompensationDetails()) {
                claimEC.setCompensationDetails(Object.assign(new CompensationDetails(), claimEC.getCompensationDetails()))
                CompensationDetails.jsonConvert(claimEC.getCompensationDetails());
            }

            if (claimEC.getEmployerDetails()) {
                claimEC.setEmployerDetails(Object.assign(new EmployerDetails(), claimEC.getEmployerDetails()))
                EmployerDetails.jsonConvert(claimEC.getEmployerDetails());
            }

            if (claimEC.getInsuranceDetails()) {
                claimEC.setInsuranceDetails(Object.assign(new InsuranceDetails(), claimEC.getInsuranceDetails()))
                InsuranceDetails.jsonConvert(claimEC.getInsuranceDetails());
            }

            if (claimEC.getOccupationalDisease()) {
                claimEC.setOccupationalDisease(Object.assign(new OccupationalDisease(), claimEC.getOccupationalDisease()))
                OccupationalDisease.jsonConvert(claimEC.getOccupationalDisease());
            }

            if (claimEC.getEmployeeEarnings()) {
                claimEC.setEmployeeEarnings(Object.assign(new EmployeeEarnings(), claimEC.getEmployeeEarnings()))
                EmployeeEarnings.jsonConvert(claimEC.getEmployeeEarnings());
            }

            if (claimEC.getFatalCase()) {
                claimEC.setFatalCase(Object.assign(new FatalCase(), claimEC.getFatalCase()))
                FatalCase.jsonConvert(claimEC.getFatalCase());
            }

            if (claimEC.getPeriodOfSickLeaves() != null) {
                let periods: PeriodOfSickLeave[] = [];
                for (const period of claimEC.getPeriodOfSickLeaves()) {
          
                  let item = Object.assign(new PeriodOfSickLeave(), period);
                  item = PeriodOfSickLeave.jsonConvert(item);
                  periods.push(item);
          
                }
                claimEC.setPeriodOfSickLeaves(periods);
            }
        }
    
        return claimEC;
    }
}
