import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ClaimService} from "./claim.service";
import {TransactionInfo} from "../model/transaction-info.model";
import {SideMenuService} from "./side-menu.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationMenuService {

    constructor(public http: HttpClient,
                private claimService: ClaimService,
                private sideMenuService : SideMenuService) {

    }

    public setupClaimNavigationMenu(transactionInfo: TransactionInfo, country: string) {
        this.claimService.getClaimTypeSubMenu(transactionInfo).subscribe(data => {
            transactionInfo.getNavigationMenu().setClaimDetailMenu(data);
            // Tell the side menu component to reget the claims details menu
            this.sideMenuService.emitClaimMenuDone('init');
        });
    }

}
