<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ (isForm2b ? 'ecClaim.compensationDetails.title' : 'ecClaim.directSettlement.title') | translate }}</h5>
    <p *ngIf="!isForm2b">{{ 'ecClaim.directSettlement.subHeading' | translate }}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <app-period-of-sick-leave #periodofsickleave> </app-period-of-sick-leave>
        <div class="form-group"  *ngIf="isForm2b">
          <qnect-ui #EC_EMPCOMP_MONTHLYEARNINGS [fieldId]="'EC_EMPCOMP_MONTHLYEARNINGS'" [formGroup]="compensationDetailsForm"
                    [prefix]="currency" name="monthlyEarnings"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPCOMP_COMPENSATIONAMOUNT [fieldId]="'EC_EMPCOMP_COMPENSATIONAMOUNT'" [formGroup]="compensationDetailsForm"
                    [prefix]="currency" [regexPattern]="isForm2b ? '^(?:0|0\\.0{1,2}|[1-9]\\d{0,12}(?:\\.\\d{0,2})?)$' : ''" name="compensationAmount"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPCOMP_PAYMENTSTATUS [fieldId]="'EC_EMPCOMP_PAYMENTSTATUS'" [formGroup]="compensationDetailsForm"
                    name="paymentStatus"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="this.toBePaid(this.compensationDetails.getPaymentStatus())">
          <qnect-ui #EC_EMPCOMP_PAYMENTDATE [fieldId]="'EC_EMPCOMP_PAYMENTDATE'" [formGroup]="compensationDetailsForm"
                    name="paymentDate"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>