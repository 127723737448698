export class HomeItem {
    private lossType: string;
    private lossTypeDesc: string;
    private lossTypeDetail: string;
    private purchaseDate: Date;
    private purchasePriceType: string;
    private purchasePrice: number;
    private repairCostType: string;
    private repairCost: number;
    private replacementCostType: string;
    private replacementCost: number;
    private lostMoneyAmountType: string;
    private lostMoneyAmount: number;
    private otherTypeBriefDesc: string;
    private wasWatchBeingStored: boolean;
    private depreciationRate: string;
    private excessDepreciation: number;

    constructor() {
      this.lossType = 'default';
      this.lossTypeDesc = '';
      this.lossTypeDetail = '';
      this.purchaseDate = null;
      this.purchasePriceType = '';
      this.purchasePrice = null;
      this.repairCostType = '';
      this.repairCost = null;
      this.replacementCostType = '';
      this.replacementCost = null;
      this.lostMoneyAmountType = '';
      this.lostMoneyAmount = null;
      this.otherTypeBriefDesc = '';
      this.wasWatchBeingStored = null;
      this.depreciationRate = 'NA';
      this.excessDepreciation = 0;
  }

  getLossType(): string {
    return this.lossType;
  } 

  setLossType(value: string){
    this.lossType = value;
  }

  getLossTypeDesc(): string {
    return this.lossTypeDesc;
  } 

  setLossTypeDesc(value: string){
    this.lossTypeDesc = value;
  }

  getLossTypeDetail(): string {
    return this.lossTypeDetail;
  } 

  setLossTypeDetail(value: string){
    this.lossTypeDetail = value;
  }

  getPurchaseDate(): Date {
    return this.purchaseDate;
  } 

  setPurchaseDate(value: Date){
    this.purchaseDate = value;
  }

  getPurchasePriceType(): string {
    return this.purchasePriceType;
  } 

  setPurchasePriceType(value: string){
    this.purchasePriceType = value;
  }

  getPurchasePrice(): number {
    return this.purchasePrice;
  } 

  setPurchasePrice(value: number){
    this.purchasePrice = value;
  }

  getRepairCostType(): string {
    return this.repairCostType;
  } 

  setRepairCostType(value: string){
    this.repairCostType = value;
  }

  getRepairCost(): number {
    return this.repairCost;
  } 

  setRepairCost(value: number){
    this.repairCost = value;
  }

  getReplacementCostType(): string {
    return this.replacementCostType;
  } 

  setReplacementCostType(value: string){
    this.replacementCostType = value;
  }

  getReplacementCost(): number {
    return this.replacementCost;
  } 

  setReplacementCost(value: number){
    this.replacementCost = value;
  }

  getOtherTypeBriefDesc(): string {
    return this.otherTypeBriefDesc;
  } 

  setOtherTypeBriefDesc(value: string){
    this.otherTypeBriefDesc = value;
  }

  setWasWatchBeingStored(value: boolean) {
    this.wasWatchBeingStored = value;
  }

  getWasWatchBeingStored(): boolean {
    return this.wasWatchBeingStored;
  }

  getDepreciationRate(): string {
      return this.depreciationRate;
  }

  setDepreciationRate(value: string){
      this.depreciationRate = value;
  }

  getExcessDepreciation(): number {
        return this.excessDepreciation;
  }

  setExcessDepreciation(value: number){
        this.excessDepreciation = value;
  }

  getLostMoneyAmountType(): string {
      return this.lostMoneyAmountType;
    }

    setLostMoneyAmountType(value: string){
      this.lostMoneyAmountType = value;
    }

    getLostMoneyAmount(): number {
        return this.lostMoneyAmount;
    }

    setLostMoneyAmount(value: number){
        this.lostMoneyAmount = value;
    }

    static jsonConvert(homeItemDetail: HomeItem): HomeItem {

    homeItemDetail = Object.assign(new HomeItem(), homeItemDetail);

    if (homeItemDetail.getPurchaseDate() != null) {
      homeItemDetail.setPurchaseDate(new Date(homeItemDetail.getPurchaseDate()));
      }

      
    return homeItemDetail;
  }
}
