import {AfterViewInit, Component, ElementRef, Inject, OnInit} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {DateService} from "./date.service";
import {DatePipe, DOCUMENT} from "@angular/common";
import * as moment from 'moment';

declare var $: any;

@Component({
    selector: 'qnect-ui-datepicker',
    templateUrl: './ui-datepicker.component.html',
    styleUrls: ['../ui-base.component.scss', './ui-datepicker.component.scss']
})
export class UiDatepickerComponent extends UiBaseComponent implements OnInit, AfterViewInit {

    dt: Date;
    maxDate: Date;
    _minDate: Date;
    isDateValid: boolean = true;
    dateValidValue: Date;

    constructor(private dateService: DateService,
                private _elementRef: ElementRef,
                @Inject(DOCUMENT) private document: any) {

        super();
    }


    ngOnInit() {
        super.ngOnInit();
        if (!this.uiModel.dateFormat) {
            this.uiModel.dateFormat = DateService.getDefaultDateFormat();
        }

        if (this.formControl.value && this.formControl.value != '') {
            this.isDateValid = true;
            if (this.formControl.value instanceof Date) {
                this.setDateValue(this.formControl.value);
            } else {
                let date: Date = this.dateService.stringToDate(this.formControl.value, this.uiModel.dateFormat);
                this.setDateValue(date);
            }
        }

    }

    ngAfterViewInit(): void {

        let self = this;

        if (!this.frmArrayIndex) this.frmArrayIndex = '';
        let fieldId = this.uiModel.name + 'DateField' + this.frmArrayIndex;

        this.formGroup.valueChanges.subscribe(data => {
            this.isValidDate(this.formGroup.get(this.uiModel.name).value);
            this.dateValidValue = this.formGroup.get(this.uiModel.name).value;
        })

        $('#' + fieldId).daterangepicker({
            fontAwesome: true,
            singleDatePicker: true,
            showDropdowns: true,
            autoUpdateInput : false,
            locale: {
                format: this.uiModel.dateFormat.toUpperCase()
            }
        },function(start, end, label) {
            self.setDateValue(start.toDate());
        });

    }

    isValidDate(strDate: any) {
        if (strDate != null && strDate != "") {
            this.isDateValid = false;
            let dateMomentValidFormat1 = moment(strDate, 'DD/MM/YYYY');
            let dateMomentValidFormat2 = moment(strDate, 'DDMMYYYY');

            if (dateMomentValidFormat1.isValid() || dateMomentValidFormat2.isValid()) {
                this.isDateValid = true;
            }

        }
    }
    
    getDateValue() {
        return this.dt;

    }

    disabledPasteEvent(event: ClipboardEvent) {
        event.preventDefault();
    }

    setDateValue(date: Date) {
        if (date && date != null) {
            if (this.isDateValid) {
                this.dt = date;
                this.setDateAsNoonTime(this.dt);
                this.formControl.setValue(new DatePipe('en-US').transform(this.dt, this.uiModel.dateFormat), {onlySelf: false, emitEvent: true});
            } else {
                this.dt = this.dateValidValue;
                this.setDateAsNoonTime(this.dt);
            }
            this.isDateValid = true;
        }
    }

    setDateValueFromInput() {
        if (this.formControl.value && this.formControl.value.length > 0) {
            const date: any = this.dateService.stringToDate(this.formControl.value, this.uiModel.dateFormat);
            if (!isNaN(Date.parse(date))) {
                if (this._minDate !== null &&  this.dt < this._minDate) {
                    this.error('min');
                } else {
                    this.removeError('min');
                }

                if (this.maxDate !== null && this.dt > this.maxDate) {
                    this.error('max');
                } else {
                    this.removeError('max');
                }
            } else {
                this.error('pattern');
            }
        }

    }


    // In order to make sure timezone doesn't shift the date around we set the time to noon.
    setDateAsNoonTime(dateToSet: Date) {
        if (dateToSet && dateToSet instanceof Date) {
            dateToSet.setHours(12, 0, 0, 0);
        }
    }

    keydownEvent(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    }

}
