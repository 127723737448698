import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { DocumentField } from "../../model/document-field";
import { ClaimTypes } from "../../model/claim-type.model";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class OtherClaimsFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
    super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        if (this.claim.getClaimTravel().getGBAPlanType()) {
            requiredDocuments.push(new DocumentField('PROOFTRAVEL', 'claimSection.greaterBayArea.proofOfTravel', SmartqDocuments.PROOF_TRAVEL, true));
        } else {
            requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.airTicket', SmartqDocuments.AIRTICKET, true));
        }
        requiredDocuments.push(new DocumentField('OTHERDOC', 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others', SmartqDocuments.INVOICE, true));
        if (UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth())) {
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}
