
const PADDING = '00000000';

export class CurrencyUtil {

  static DECIMAL_SEPARATOR: string = '.';
  static THOUSANDS_SEPARATOR: string = ',';

  static transform(value: number | string, fractionSize: number = 2): string {

    let [integer, fraction = ''] = (value || '').toString()
      .split(this.DECIMAL_SEPARATOR);

    fraction = integer != '' && fractionSize > 0
      ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
      : '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

    return integer + fraction;
  }

  static parse(value: string, fractionSize: number = 2): string {

    //let [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);

    value = value.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');

    // fraction = fraction != ''
    //   ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
    //   : '';

    return value;
  }
}
