export class MissedEvent {

    private dateOfMissedEvent: Date;
    private countryEventHeld: string;
    private countryEventHeldDesc: string;
    private reasonOfMissedEvent: string;
    private reasonOfMissedEventDesc: string;
    private otherReasonOfMissedEvent: string;

    private ticketCost: string;
    private ticketCostCurrency: string;

    private isCreditCardPayment: boolean = null;

    private hasRefund: boolean = null;
    private refundAmount: string;
    private refundAmountCurrency: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getDateOfMissedEvent(): Date {
        return this.dateOfMissedEvent;
    }

    setDateOfMissedEvent(value: Date) {
        this.dateOfMissedEvent = value;
    }

    getCountryEventHeld(): string {
        return this.countryEventHeld;
    }

    setCountryEventHeld(value: string) {
        this.countryEventHeld = value;
    }

    getCountryEventHeldDesc(): string {
        return this.countryEventHeldDesc;
    }

    setCountryEventHeldDesc(value: string) {
        this.countryEventHeldDesc = value;
    }

    getReasonOfMissedEvent(): string {
        return this.reasonOfMissedEvent;
    }

    setReasonOfMissedEvent(value: string) {
        this.reasonOfMissedEvent = value;
    }

    getReasonOfMissedEventDesc(): string {
        return this.reasonOfMissedEventDesc;
    }

    setReasonOfMissedEventDesc(value: string) {
        this.reasonOfMissedEventDesc = value;
    }

    getOtherReasonOfMissedEvent(): string {
        return this.otherReasonOfMissedEvent;
    }

    setOtherReasonOfMissedEvent(value: string) {
        this.otherReasonOfMissedEvent = value;
    }

    getTicketCost(): string {
        return this.ticketCost;
    }

    setTicketCost(value: string) {
        this.ticketCost = value;
    }

    getTicketCostCurrency(): string {
        return this.ticketCostCurrency;
    }

    setTicketCostCurrency(value: string) {
        this.ticketCostCurrency = value;
    }

    getIsCreditCardPayment(): boolean {
        return this.isCreditCardPayment;
    }

    setIsCreditCardPayment(value: boolean) {
        this.isCreditCardPayment = value;
    }

    getHasRefund(): boolean {
        return this.hasRefund;
    }

    setHasRefund(value: boolean) {
        this.hasRefund = value;
    }

    getRefundAmount(): string {
        return this.refundAmount;
    }

    setRefundAmount(value: string) {
        this.refundAmount = value;
    }

    getRefundAmountCurrency(): string {
        return this.refundAmountCurrency;
    }

    setRefundAmountCurrency(value: string) {
        this.refundAmountCurrency = value;
    }

    static jsonConvert(missedEvent: MissedEvent): MissedEvent {

        if (missedEvent.getDateOfMissedEvent() != null) {
            missedEvent.setDateOfMissedEvent(new Date(missedEvent.getDateOfMissedEvent()));
        }

        return missedEvent;
    }
}