        <!--Travel Delay-->
        <app-summary-travel-delay *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_TDEL') !== -1" [claimItem]="claim"></app-summary-travel-delay>

        <!-- Visited a Doctor -->
        <app-summary-visited-a-doctor *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_MED_VISIT') !== -1"></app-summary-visited-a-doctor>

        <!-- stay In hospital -->
        <app-summary-stay-in-hospital *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_MED_STY') !== -1"></app-summary-stay-in-hospital>

        <!-- rental Vehicle Excess -->
        <app-summary-rental-vehicle *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_RVE') !== -1"></app-summary-rental-vehicle>

        <!-- other claim -->
        <app-summary-other-claim *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_OTH') !== -1"></app-summary-other-claim>

        <!-- trip Cancellation-->
        <app-summary-trip-cancellation *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_CAN') !== -1" [claimItem]="claim"></app-summary-trip-cancellation>

         <!-- Personal Accident -->
        <app-summary-personal-accident *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_MED_DTH') !== -1"></app-summary-personal-accident>

        <!-- personal liability-->
        <app-summary-personal-liability *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_LIAB') !== -1"></app-summary-personal-liability>

        <!-- Travel Re-route-->
        <app-summary-travel-reroute *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_CAN_RROUT') !== -1" [claimItem]="claim"></app-summary-travel-reroute>

        <!-- lost personal item -->
        <app-summary-lost-personal-item *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_BAG_LOST') !== -1"></app-summary-lost-personal-item>

        <!-- Damage personal item -->
        <app-summary-damage-personal-item *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_BAG_DMG') !== -1"></app-summary-damage-personal-item>

        <!-- Baggage Delay -->
        <app-summary-baggage-delay *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_BDEL') !== -1"></app-summary-baggage-delay>

        <!-- Miss Connection-->
        <app-summary-miss-connection *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_TDEL_MC') !== -1" [claimItem]="claim"></app-summary-miss-connection>

        <!-- Trip Curtailment-->
        <app-summary-trip-curtailment *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_CAN_CRTL') !== -1" [claimItem]="claim"></app-summary-trip-curtailment>

        <!-- Missed Event-->
        <app-summary-missed-event *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_MSEVNT') !== -1" [claimItem]="claim"></app-summary-missed-event>

