import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../../services/transaction-info.service";
import {ClaimTypes} from "../../../../model/claim-type.model";

@Component({
  selector: 'app-windscreen-accident',
  templateUrl: './windscreen.component.html',
  styleUrls: ['./windscreen.component.css']
})
export class WindscreenComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]);
    }

  ngOnInit() {
  }

}
