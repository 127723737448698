import {Claimant} from './claimant.model';

export class ClaimResponse {

    private claimNumber : string;
    private notificationNumber : string;
    private errorMessage : string;
    private claimIndex  : number;
    private claimant : Claimant;
    private riskType: string;
    private selectedClaimTypesList: string[] = [];

    getSelectedClaimTypesList(): string[] {
        return this.selectedClaimTypesList;
    }

    setSelectedClaimTypesList(value: string[]) {
        this.selectedClaimTypesList = value;
    }

    getClaimNumber(): string {
        return this.claimNumber;
    }

    setClaimNumber(value: string) {
        this.claimNumber = value;
    }

    getNotificationNumber(): string {
        return this.notificationNumber;
    }

    setNotificationNumber(value: string) {
        this.notificationNumber = value;
    }

    getErrorMessage(): string {
        return this.errorMessage;
    }

    setErrorMessage(value: string) {
        this.errorMessage = value;
    }

    getClaimIndex(): number {
        return this.claimIndex;
    }

    setClaimIndex(value: number) {
        this.claimIndex = value;
    }

    getClaimant(): Claimant {
        return this.claimant;
    }

    setClaimant(value: Claimant) {
        this.claimant = value;
    }

    getRiskType(): string {
        return this.riskType;
    }

    setRiskType(value: string) {
        this.riskType = value;
    }

    static jsonConvert(claimResponse: ClaimResponse): ClaimResponse {

        claimResponse = Object.assign(new ClaimResponse(), claimResponse);

        if (claimResponse != null) {

            if (claimResponse.getClaimant()) {
                claimResponse.setClaimant(Object.assign(new Claimant(), claimResponse.getClaimant()));
                Claimant.jsonConvert(claimResponse.getClaimant());
            }
        }

        return claimResponse;
    }



}

