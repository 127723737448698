<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && !formControl.valid && formControl.touched) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
  <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
  <div class="col-md-{{uiModel.size}} col-xs-12">
    <input formControlName="{{uiModel.name}}" type="text" class="form-control" id="{{fieldId}}"
           (keyup)="changed()" ng-blur="changed()"
           placeholder="{{uiModel.placeholder | translate}}"
           [ngClass]="{'ui-disabled' : uiModel.disabled}" *ngIf="!uiModel.textOnly" maxlength="{{uiModel.maxlength}}" [attr.list]="dataList" autocomplete="off"
           [readonly]="disabled"
           spellcheck="true">
    <p style="font-size: 14px;" *ngIf="uiModel.isOtherTextField">{{charsLeft}} {{'claimSection.generalLabels.placeHolders.remainingChar' | translate}}  </p>

    <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | translate}}</p>
    <ui-error *ngIf="formControl.touched || uiModel.textOnly" [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
  </div>
  <datalist id="{{dataList}}" *ngIf="uiModel.options" title="Choose a suggestion">
    <option *ngFor="let option of uiModel.options" value="{{option.label | translate}}"></option>
  </datalist>

</div>

