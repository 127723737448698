<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.lostPersonalItem.lossItem.description' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form [formGroup]="lossDetailForm">
        <div formArrayName="items" *ngFor="let item of lossDetailForm.get('items')['controls']; let i = index;">
          <div [formGroupName]="i">        
            <div class="form-group click-more-content">
              <label>{{ 'claimSection.lostPersonalItem.lossItem.item' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeItem(i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                <qnect-ui [fieldId]="'LOST_PERS_ITEM_11'"
                [frmArrayGroup]="lossDetailForm"
                [frmArrayName]="'items'"
                frmArrayIndex="{{i}}"
                name="lossType"></qnect-ui>
            </div>
            <div *ngIf="detailsOfLostItemsArray && detailsOfLostItemsArray != null">
              <div *ngIf="detailsOfLostItemsArray[i].getLossType() == 'Mobile Phone' 
              || detailsOfLostItemsArray[i].getLossType() == 'Tablet' 
              || detailsOfLostItemsArray[i].getLossType() == 'Laptop / Notebook' 
              || detailsOfLostItemsArray[i].getLossType() == 'Sports equipment'  
              || this.detailsOfLostItemsArray[i].getLossType() == 'Luggage / Trunk' 
              || this.detailsOfLostItemsArray[i].getLossType() == 'Suitcase / Case' 
              || this.detailsOfLostItemsArray[i].getLossType() == 'Backpack / Bag' 
              || this.detailsOfLostItemsArray[i].getLossType() == 'Handbag / Briefcase' 
              || this.detailsOfLostItemsArray[i].getLossType() == 'Camera' 
              || this.detailsOfLostItemsArray[i].getLossType() == 'Wallet'
              || this.detailsOfLostItemsArray[i].getLossType() == 'Earphones / Headphones'
              || this.detailsOfLostItemsArray[i].getLossType() == 'Chargers / Memory cards'">
                <div *ngIf="this.detailsOfLostItemsArray[i].getLossType() == 'Mobile Phone'" class="form-group">
                  <qnect-ui [fieldId]="'LOST_PERS_ITEM_24'"
                            [frmArrayGroup]="lossDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="lossTypeDetail"></qnect-ui>
                </div>
                <div class="form-group">

                  <qnect-ui [fieldId]="'LOST_PERS_ITEM_12'"
                            [frmArrayGroup]="lossDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            [aboveMaxDateValidator]="this.lostPersonalitem.getDateTheDamageOccurred()"
                            name="purchaseDate"></qnect-ui>

                </div>
                <div class="form-group click-more-content">
                    <qnect-ui [fieldId]="'LOST_PERS_ITEM_13'"
                              [frmArrayGroup]="lossDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="purchasePrice"></qnect-ui>
                </div>
              </div>
              <div *ngIf="this.detailsOfLostItemsArray[i].getLossType() == 'Watch'">
                <div class="form-group">

                  <qnect-ui [fieldId]="'LOST_PERS_ITEM_12'"
                            [frmArrayGroup]="lossDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            [aboveMaxDateValidator]="this.lostPersonalitem.getDateTheDamageOccurred()"
                            name="purchaseDate"></qnect-ui>

                </div>
                <div class="form-group click-more-content">
                    <qnect-ui [fieldId]="'LOST_PERS_ITEM_13'"
                              [frmArrayGroup]="lossDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="purchasePrice"></qnect-ui>
                </div>
              </div>
              <div *ngIf="detailsOfLostItemsArray[i].getLossType() == 'Passport / HKID / China Re-entry card' || 
                detailsOfLostItemsArray[i].getLossType() == 'Passport / NRIC / FIN'">
                <div class="form-group click-more-content">
                    <qnect-ui [fieldId]="'LOST_PERS_ITEM_14'"
                              [frmArrayGroup]="lossDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="costOfRepalceCost"></qnect-ui>
                </div>
              </div>

              <div *ngIf="detailsOfLostItemsArray[i].getLossType() == 'Personal Money'">
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'LOST_PERS_ITEM_17'"
                              [frmArrayGroup]="lossDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="lostMoneyAmount"></qnect-ui>
                </div>
              </div>
              <div *ngIf="detailsOfLostItemsArray[i].getLossType() == 'Credit Card'">
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'HOME_LOST_PERS_ITEM_18'"
                              [frmArrayGroup]="lossDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="unauthorisedMoneyLoss"></qnect-ui>
                </div>
              </div>
              <div *ngIf="detailsOfLostItemsArray[i].getLossType() == 'Others'">
                <div class="form-group">
                    <qnect-ui [fieldId]="'LOST_PERS_ITEM_19'"
                                [frmArrayGroup]="lossDetailForm"
                                [frmArrayName]="'items'"
                                frmArrayIndex="{{i}}"
                                name="lossTypeDetail"></qnect-ui>
                  </div>
                  <div class="form-group">
                  <qnect-ui [fieldId]="'LOST_PERS_ITEM_12'"
                            [frmArrayGroup]="lossDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            [aboveMaxDateValidator]="this.lostPersonalitem.getDateTheDamageOccurred()"
                            name="purchaseDate"></qnect-ui>  
                </div>
                <div class="form-group click-more-content">
                    <qnect-ui [fieldId]="'LOST_PERS_ITEM_13'"
                              [frmArrayGroup]="lossDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="purchasePrice"></qnect-ui>
                </div>                  
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'LOST_PERS_ITEM_20'"
                              [frmArrayGroup]="lossDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="otherTypeBriefDesc"></qnect-ui>
                </div>
              </div>
              <!-- bubble depreciation info -->
              <div *ngIf="detailsOfLostItemsArray[i].getLossType() != null && detailsOfLostItemsArray[i].getLossType() != ''">
                <div class="dept-del-info col-xl-8 col-xs-12 col-md-8">
                  <div class="row col-12 col-md-12">
                    <div class="col-3 col-md-2 percent-text"> % </div>
                    <div class=" col-9 col-md-10 dept-desc-text">{{'claimSection.damagePersonalItem.detailsOfDamageItems.depreciationInfo' | translate}}</div>
                  </div>
                </div>
              </div>
              <!-- bubble depreciation info -->
            </div>
            <div class="col-xl-2 col-lg-12">
                &nbsp;
            </div>
          </div>
        </div>
      </form>
    
      <div class="addFile" (click)="addItem()">
        <i class="fas fa-plus"></i>
        <span>{{ 'claimSection.generalLabels.generalButtonLabels.add' | translate}}</span>
      </div>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
