<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="new-btn btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.missedEvent.detailOfMissedEvent.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #MISS_EVENT_DATE [fieldId]="'MISS_EVENT_DATE'" name="missedEventDate" [formGroup]="missedEventDetailsForm"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #MISS_EVENT_COUNTRY_OCCURED [fieldId]="'MISS_EVENT_COUNTRY_OCCURED'" name="countryEventHeld" [formGroup]="missedEventDetailsForm"></qnect-ui>
        </div>
        <div class="form-group click-more-content" id="q1">
          <qnect-ui #MISS_EVENT_REASON [fieldId]="'MISS_EVENT_REASON'" name="reasonOfMissEvent" [formGroup]="missedEventDetailsForm"></qnect-ui>
        </div>
        <div>
            <div class="form-group click-more-content" id="q2" *ngIf="missedEvent.getReasonOfMissedEvent() == 'Others'">
              <qnect-ui #MISS_EVENT_REASON_OTHER [fieldId]="'MISS_EVENT_REASON_OTHER'" name="otherReasonOfMissEvent" [formGroup]="missedEventDetailsForm"></qnect-ui>
            </div>
        </div>

      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>
  <additional-claimant></additional-claimant>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
