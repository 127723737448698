

export class LostPersonalItemDetail {
  private lossType: string;
  private lossTypeDesc: string;
  private lossTypeDetail: string;
  private purchaseDate: Date;
  private purchasePriceType: string;
  private purchasePrice: number;
  private costOfRepalceCostType: string;
  private costOfRepalceCost: number;
  private costOfTransCostType: string;
  private costOfTransCost: number;
  private costOfAccomCostType: string;
  private costOfAccomCost: number;
  private hasExtraAccomCost: boolean;
  private hasExtraTransCost: boolean;
  private lostMoneyType: string;
  private lostMoneyAmount: number;
  private unauthorisedMoneyLossType: string;
  private unauthorisedMoneyLoss: number;
  private otherTypeBriefDesc: string;
  private wasWatchBeingStored: boolean;
  private depreciationRate: string;
  private excessDepreciation: number;
  private hasOriginalPurchasedReceipt: boolean;

  constructor() {
    this.lossType = 'default';
    this.lossTypeDesc = '';
    this.lossTypeDetail = '';
    this.purchaseDate =  null;
    this.purchasePriceType = '';
    this.costOfRepalceCostType = '';
    this.costOfRepalceCost = null;
    this.purchasePrice = null;
    this.costOfTransCostType = '';
    this.costOfTransCost = null;
    this.costOfAccomCostType = '';
    this.costOfAccomCost = null;
    this.hasExtraAccomCost = null;
    this.hasExtraTransCost = null;
    this.lostMoneyType = '';
    this.lostMoneyAmount = null;
    this.unauthorisedMoneyLossType = '';
    this.unauthorisedMoneyLoss = null;
    this.otherTypeBriefDesc = '';
    this.wasWatchBeingStored = null;
    this.depreciationRate = 'NA';
    this.excessDepreciation = 0;
    this.hasOriginalPurchasedReceipt = null;
  }

  getHasExtraTransCost(): boolean {
    return this.hasExtraTransCost;
  } 

  setHasExtraTransCost(value: boolean){
    this.hasExtraTransCost = value;
  }

  getHasExtraAccomCost(): boolean {
    return this.hasExtraAccomCost;
  } 

  setHasExtraAccomCost(value: boolean){
    this.hasExtraAccomCost = value;
  }

  getLossType(): string {
    return this.lossType;
  } 

  setLossType(value: string){
    this.lossType = value;
  }

  getLossTypeDesc(): string {
    return this.lossTypeDesc;
  } 

  setLossTypeDesc(value: string){
    this.lossTypeDesc = value;
  }

  getLossTypeDetail(): string {
    return this.lossTypeDetail;
  } 

  setLossTypeDetail(value: string){
    this.lossTypeDetail = value;
  }

  getPurchaseDate(): Date {
    return this.purchaseDate;
  } 

  setPurchaseDate(value: Date){
    this.purchaseDate = value;
  }

  getPurchasePriceType(): string {
    return this.purchasePriceType;
  } 

  setPurchasePriceType(value: string){
    this.purchasePriceType = value;
  }

  getPurchasePrice(): number {
    return this.purchasePrice;
  } 

  setPurchasePrice(value: number){
    this.purchasePrice = value;
  }

  getCostOfRepalceCostType(): string {
    return this.costOfRepalceCostType;
  } 

  setCostOfRepalceCostType(value: string){
    this.costOfRepalceCostType = value;
  }

  getCostOfRepalceCost(): number {
    return this.costOfRepalceCost;
  } 

  setCostOfRepalceCost(value: number){
    this.costOfRepalceCost = value;
  }

  getCostOfTransCostType(): string {
    return this.costOfTransCostType;
  } 

  setCostOfTransCostType(value: string){
    this.costOfTransCostType = value;
  }

  getCostOfTransCost(): number {
    return this.costOfTransCost;
  } 

  setCostOfTransCost(value: number){
    this.costOfTransCost = value;
  }
  
  getCostOfAccomCostType(): string {
    return this.costOfAccomCostType;
  } 

  setCostOfAccomCostType(value: string){
    this.costOfAccomCostType = value;
  }

  getCostOfAccomCost(): number {
    return this.costOfAccomCost;
  } 

  setCostOfAccomCost(value: number){
    this.costOfAccomCost = value;
  }

  getLostMoneyType(): string {
    return this.lostMoneyType;
  } 

  setLostMoneyType(value: string){
    this.lostMoneyType = value;
  }

  getLostMoneyAmount(): number {
    return this.lostMoneyAmount;
  } 

  setLostMoneyAmount(value: number){
    this.lostMoneyAmount = value;
  }

  getUnauthorisedMoneyLossType(): string {
    return this.unauthorisedMoneyLossType;
  } 

  setUnauthorisedMoneyLossType(value: string){
    this.unauthorisedMoneyLossType = value;
  }

  getUnauthorisedMoneyLoss(): number {
    return this.unauthorisedMoneyLoss;
  } 

  setUnauthorisedMoneyLoss(value: number){
    this.unauthorisedMoneyLoss = value;
  }

  getOtherTypeBriefDesc(): string {
    return this.otherTypeBriefDesc;
  } 

  setOtherTypeBriefDesc(value: string){
    this.otherTypeBriefDesc = value;
  }
  
  setWasWatchBeingStored(value: boolean) {
    this.wasWatchBeingStored = value;
  }

  getWasWatchBeingStored(): boolean {
    return this.wasWatchBeingStored;
  }

  getDepreciationRate(): string {
    return this.depreciationRate;
  }

  setDepreciationRate(value: string){
    this.depreciationRate = value;
  }

  getExcessDepreciation(): number {
    return this.excessDepreciation;
  }

  setExcessDepreciation(value: number){
    this.excessDepreciation = value;
  }

  getHasOriginalPurchasedReceipt(): boolean {
   return this.hasOriginalPurchasedReceipt;
  }

  setHasOriginalPurchasedReceipt(value: boolean) {
    this.hasOriginalPurchasedReceipt = value;
  }
  
  static jsonConvert(lostPersonalItemDetail: LostPersonalItemDetail): LostPersonalItemDetail {

    lostPersonalItemDetail = Object.assign(new LostPersonalItemDetail(), lostPersonalItemDetail);

    if (lostPersonalItemDetail.getPurchaseDate() != null) {
        lostPersonalItemDetail.setPurchaseDate(new Date(lostPersonalItemDetail.getPurchaseDate()));
      }

      
    return lostPersonalItemDetail;
  }

}
