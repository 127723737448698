import {Component, Injector, OnInit} from "@angular/core";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import {ClaimMotor} from '../../../model/claim-motor.model';

@Component({
    selector: 'motor-sections-summary',
    templateUrl: './motor-sections-summary.component.html'
})
export class MotorSectionsSummaryComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimMotor: ClaimMotor;

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimMotor = this.claim.getClaimMotor();
    }

    ngOnInit() {
    }

}
