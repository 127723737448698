export class StayInHospital {

    private wasStayHospitalOversea: boolean = null;
    private wasStayInIntensiveCare: boolean = null;
    private wardType: string;
    private wardTypeDesc: string;
    private periodType: string;
    private periodTypeDesc: string;
    private periodTime: string;
    private hasReceiveFollowUpTreament: boolean;
    private returnHkDate: Date;
    private lastTreamentDate: Date;
    private dateTheIllnessOccurred: Date;
    private natureOfIllness: string;
    private natureOfIllnessDesc: string;
    private diagnoseType: string;
    private diagnoseTypeDesc: string;
    private otherDiagnose: string;
    private wasMugging: boolean;
    private numberOfTreatmentBill: string;
    private otherNumOfTreatmentBill: string;
    private medicalExpenseCurrency: string;
    private medicalExpenseTypeAmount: string;
    private medicalExpenseType: string;
    private countryOfTheLossOccurred: string;
    private countryOfTheLossOccurredDesc: string;
    private estimatedAmount: number;
    private placeOfLoss: string;
    private placeOfLossDesc: string;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getCountryOfTheLossOccurredDesc(): string {
        return this.countryOfTheLossOccurredDesc;
    }

    setCountryOfTheLossOccurredDesc(value: string) {
        this.countryOfTheLossOccurredDesc = value;
    }

    getCountryOfTheLossOccurred(): string {
        return this.countryOfTheLossOccurred;
    }

    setCountryOfTheLossOccurred(value: string) {
        this.countryOfTheLossOccurred = value;
    }

    getReturnHkDate(): Date {
        return this.returnHkDate;
    }

    setReturnHkDate(value: Date) {
        this.returnHkDate = value;
    }

    getLastTreamentDate(): Date {
        return this.lastTreamentDate;
    }

    setLastTreamentDate(value: Date) {
        this.lastTreamentDate = value;
    }

    setMedicalExpenseType(value: string){
        this.medicalExpenseType = value;
    }

    getMedicalExpenseType(): string {
        return this.medicalExpenseType;
    }

    getNumberOfTreatmentBill(): string {
        return this.numberOfTreatmentBill;
    }

    setNumberOfTreatmentBill(value: string) {
        this.numberOfTreatmentBill = value;
    }

    getOtherNumOfTreatmentBill(): string {
        return this.otherNumOfTreatmentBill;
    }

    setOtherNumOfTreatmentBill(value: string) {
        this.otherNumOfTreatmentBill = value;
    }

    getMedicalExpenseCurrency(): string {
        return this.medicalExpenseCurrency;
    }

    setMedicalExpenseCurrency(value: string) {
        this.medicalExpenseCurrency = value;
    }

    getMedicalExpenseTypeAmount(): string {
        return this.medicalExpenseTypeAmount;
    }

    setMedicalExpenseTypeAmount(value: string) {
        this.medicalExpenseTypeAmount = value;
    }

    getDateTheIllnessOccurred(): Date {
        return this.dateTheIllnessOccurred;
    }

    setDateTheIllnessOccurred(value: Date) {
        this.dateTheIllnessOccurred = value;
    }

    getNatureOfIllness(): string {
        return this.natureOfIllness;
    }

    setNatureOfIllness(value: string) {
        this.natureOfIllness = value;
    }

    getNatureOfIllnessDesc(): string {
        return this.natureOfIllnessDesc;
    }

    setNatureOfIllnessDesc(value: string) {
        this.natureOfIllnessDesc = value;
    }

    getDiagnoseType(): string {
        return this.diagnoseType;
    }

    setDiagnoseType(value: string) {
        this.diagnoseType = value;
    }

    getDiagnoseTypeDesc(): string {
        return this.diagnoseTypeDesc;
    }

    setDiagnoseTypeDesc(value: string) {
        this.diagnoseTypeDesc = value;
    }

    getOtherDiagnose(): string {
        return this.otherDiagnose;
    }

    setOtherDiagnose(value: string) {
        this.otherDiagnose = value;
    }

    getWasMugging(): boolean {
        return this.wasMugging;
    }

    setWasMugging(value: boolean) {
        this.wasMugging = value;
    }

    getHasReceiveFollowUpTreament(): boolean {
        return this.hasReceiveFollowUpTreament;
    }

    setHasReceiveFollowUpTreament(value: boolean) {
        this.hasReceiveFollowUpTreament = value;
    }

    getWasStayHospitalOversea(): boolean {
        return this.wasStayHospitalOversea;
    }

    setWasStayHospitalOversea(value: boolean) {
        this.wasStayHospitalOversea = value;
    }

    getWasStayInIntensiveCare(): boolean {
        return this.wasStayInIntensiveCare;
    }

    setWasStayInIntensiveCare(value: boolean) {
        this.wasStayInIntensiveCare = value;
    }

    getWardType(): string {
        return this.wardType;
    }

    setWardType(value: string) {
        this.wardType = value;
    }

    getWardTypeDesc(): string {
        return this.wardTypeDesc;
    }

    setWardTypeDesc(value: string) {
        this.wardTypeDesc = value;
    }

    getPeriodType(): string {
        return this.periodType;
    }

    setPeriodType(value: string) {
        this.periodType = value;
    }

    getPeriodTypeDesc(): string {
        return this.periodTypeDesc;
    }

    setPeriodTypeDesc(value: string) {
        this.periodTypeDesc = value;
    }

    getPeriodTime(): string {
        return this.periodTime;
    }

    setPeriodTime(value: string) {
        this.periodTime = value;
    }

    getPlaceOfLoss(): string {
        return this.placeOfLoss;
    }

    setPlaceOfLoss(value: string){
        this.placeOfLoss = value;
    }

    getPlaceOfLossDesc(): string {
        return this.placeOfLossDesc;
    }

    setPlaceOfLossDesc(value: string){
        this.placeOfLossDesc = value;
    }

    static jsonConvert(stayInHospital: StayInHospital): StayInHospital {

        if (stayInHospital.getReturnHkDate() != null) {
            stayInHospital.setReturnHkDate(new Date(stayInHospital.getReturnHkDate()));
        }

        if (stayInHospital.getLastTreamentDate() != null) {
            stayInHospital.setLastTreamentDate(new Date(stayInHospital.getLastTreamentDate()));
        }

        if (stayInHospital.getDateTheIllnessOccurred() != null) {
            stayInHospital.setDateTheIllnessOccurred(new Date(stayInHospital.getDateTheIllnessOccurred()));
        }

        return stayInHospital;
    }


}