<div class="section form-group">
    <div class="sub-section">
        <label>{{'motorClaim.claimSection.windscreen.documents.description' | translate}}</label>
        <div class="row" *ngFor="let item of this.listOfDocuments;">
            <div class="col col-xs-12 col-lg-12">
                {{item.description | translate}}
            </div>
            <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                    {{ item.uploadedFileName }}
                </div>
            </ng-container>
        </div>
    </div>
    <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
        <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
        <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
            <div class="col col-xs-12 col-lg-12">
                {{item.description | translate}}
            </div>
            <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                    {{ item.uploadedFileName }}
                </div>
            </ng-container>
        </div>
    </div> 
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>