import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { Claim } from 'src/app/model/claim.model';
import { ClaimService } from 'src/app/services/claim.service';
import { Others } from 'src/app/model/common/others.model';
import {UtilitiesService} from '../../../../../utilities/utilities.service';

@Component({
    selector: 'app-pa-other-details',
    templateUrl: './pa-other-details.component.html'
})
export class PaOtherDetailsComponent extends ClaimsBaseComponent implements OnInit {

    othersForm: FormGroup;
    othersDetails: Others;
    claim: Claim;
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.othersDetails = this.claim.getClaimPersonalAccident().getOthers();
    }

    ngOnInit() {
        this.pushGAPageView();

        if (!this.isNotNullOrUndefinedStr(this.othersDetails.getEstAmountLossCurrency())){
            this.othersDetails.setEstAmountLossCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.othersForm = this.fb.group({
            descIncident: [this.othersDetails.getDescIncident()],
            estAmountLoss: [this.othersDetails.getEstAmountLoss()],
            estAmountLossCurrency: [this.othersDetails.getEstAmountLossCurrency()],
        });
    }

    ngAfterViewInit() {
         this.othersForm.valueChanges.subscribe(data => {
            this.othersDetails.setDescIncident(super.getValueInComponent('descIncident'));
            this.othersDetails.setEstAmountLoss(super.getValueInComponent('estAmountLoss'));
            this.othersDetails.setEstAmountLossCurrency(this.othersForm.get('estAmountLossCurrency').value);             
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 0});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/paOthers/authorityReport"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.othersForm);
    }

    back() {
        let url: string = '/claimform/claimDetail';
        if (!this.claim.getClaimPersonalAccident().getInjuryDetails().getHasBeenInjured() ||
            !this.claim.getClaimPersonalAccident().getHasEligibleClaimTypes()) {
            url = '/claimform/paInjuryDetails';
        }
        this.router.navigate([url], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' – Details of the incident';
        let vPath = this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details-of-the-incident';
        let stepNum = !this.claim.getClaimPersonalAccident().getHasEligibleClaimTypes() ?  5 : 6;
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': stepNum}
                }
            }
        });
    }
}
