import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {UtilitiesService} from '../../../utilities/utilities.service';
import {EmployeeDetails} from '../../../model/employee-compensation/employee-details.model';
import {TransactionInfo} from '../../../model/transaction-info.model';
import {ClaimTypes} from '../../../model/claim-type.model';

@Component({
    selector: 'app-employee-details',
    templateUrl: './employee-details.component.html'
})
export class EmployeeDetailsComponent extends ClaimsBaseComponent implements OnInit {

    injuredEmployeeDetailsForm: FormGroup;
    employeeDetails: EmployeeDetails;
    showTotalBox = false;
    transactionInfo: TransactionInfo;
    isForm2A = false;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {
        super(injector);
        this.employeeDetails = this.claim.getClaimEmployeeCompensation().getEmployeeDetails();
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.isForm2A = this.transactionInfo.getCurrentClaimType() === ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A];
    }

    ngOnInit() {
        if (!this.isNotNullOrUndefinedStr(this.employeeDetails.getIdType())){
            this.employeeDetails.setIdType('HKID');
        }

        if (!this.isNotNullOrUndefinedStr(this.employeeDetails.getTelephoneNumberCode())){
            this.employeeDetails.setTelephoneNumberCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }

        this.injuredEmployeeDetailsForm = this.fb.group({
            name: [this.employeeDetails.getName()],
            idNumberType: [this.employeeDetails.getIdType()],
            idNumber: [this.employeeDetails.getIdNumber()],
            telephoneNumberCode: [this.employeeDetails.getTelephoneNumberCode()],
            telephoneNumber: [this.employeeDetails.getTelephoneNumber()],
            emailAddress: [this.employeeDetails.getEmailAddress()],
            address: [this.employeeDetails.getAddress()],
            birthDate: [this.employeeDetails.getBirthDate()],
            gender: [this.employeeDetails.getGender()],
            genderDesc: [this.employeeDetails.getGenderDesc()],
            faxNumber: [this.employeeDetails.getFaxNumber()],
            occupation: [this.employeeDetails.getOccupation()],
            isApprentice: [super.getBooleanString(this.employeeDetails.getIsApprentice())],
            durationDateFrom: [this.employeeDetails.getDurationDateFrom()],
            durationDateTo: [this.employeeDetails.getDurationDateTo()]
        });
    }

    ngAfterViewInit() {
        this.injuredEmployeeDetailsForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.employeeDetails.setName(this.injuredEmployeeDetailsForm.get('name').value);
                this.employeeDetails.setIdType(this.injuredEmployeeDetailsForm.get('idNumberType').value);
                this.employeeDetails.setIdNumber(this.injuredEmployeeDetailsForm.get('idNumber').value);
                this.employeeDetails.setTelephoneNumber(this.injuredEmployeeDetailsForm.get('telephoneNumber').value);
                this.employeeDetails.setTelephoneNumberCode(this.injuredEmployeeDetailsForm.get('telephoneNumberCode').value);
                this.employeeDetails.setEmailAddress(this.injuredEmployeeDetailsForm.get('emailAddress').value);
                this.employeeDetails.setAddress(this.injuredEmployeeDetailsForm.get('address').value);
                this.employeeDetails.setBirthDate(super.getValueInComponent('birthDate'));
                this.employeeDetails.setGender(this.injuredEmployeeDetailsForm.get('gender').value);
                this.employeeDetails.setGenderDesc(super.getSelectedRadioText('gender'));
                this.employeeDetails.setFaxNumber(this.injuredEmployeeDetailsForm.get('faxNumber').value);
                this.employeeDetails.setOccupation(this.injuredEmployeeDetailsForm.get('occupation').value);
                this.employeeDetails.setDurationDateFrom(super.getValueInComponent('durationDateFrom'));
                this.employeeDetails.setDurationDateTo(super.getValueInComponent('durationDateTo'));
                if (this.injuredEmployeeDetailsForm.get('isApprentice').value != null) {
                    this.employeeDetails.setIsApprentice(this.injuredEmployeeDetailsForm.get('isApprentice').value == 'true');
                }
            });

            let self = this;
            setTimeout(function () {self.validateIdNumber(self.employeeDetails.getIdType())},10);
            this.injuredEmployeeDetailsForm.get('idNumberType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.validateIdNumber(data);
            });
    }

    validateForm(): boolean {
        return super.validateForm(this.injuredEmployeeDetailsForm);
    }

    validateIdNumber(idNumberType) {
        if ((this.employeeDetails.getIdType() !== null || this.employeeDetails.getIdType() !== undefined)
                && this.employeeDetails.getIdType() !== idNumberType) {
            this.injuredEmployeeDetailsForm.get('idNumber').reset();
        }
    }
    
    goToNext() {
       if (this.validateForm()) {
             let route = this.transactionInfo.getRouteByCurrentUrl('/detailsOfEmployee', true);
             if (route) {
                 this.sideMenuService.emitClaimComplete({claimTypeId: route.claimIndex, subMenuIndex: route.routeIndex});
                 this.sideMenuService.emitProcess(1, 0);
                 this.router.navigate([route.routeUrl], {
                     relativeTo: this.activatedRoute
                 })
             }
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }
}
