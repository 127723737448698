import {Component, OnInit} from "@angular/core";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import {UiBaseComponent} from "../ui-base.component";

@Component({
  selector: 'qnect-ui-timepicker',
  templateUrl: './ui-timepicker.component.html',
  styleUrls: ['../ui-base.component.scss', './ui-timepicker.component.scss']
})
export class UiTimepickerComponent extends UiBaseComponent implements OnInit {
  timeSelect: Date;
  minuteStep = 1;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const val = this.formControl.value;
    if (val instanceof Date || val == null) {
      this.setTimeValue(val);
    }
  }

  getTimeValue() {
    return this.timeSelect;
  }

  setTimeValue(date: Date) {

    if (UtilitiesService.isNullOrUndefined(date)) {
      // Default to 12 Hours 00 Minutes
      const newDate = new Date();
      newDate.setHours(12);
      newDate.setMinutes(0);
      this.timeSelect = newDate;
    } else {
      this.timeSelect = date;
    }
  }
}
