import {Injectable} from '@angular/core';
import {UiOptionsModel} from "../ui/ui-options.model";

@Injectable()
export class EcHelperService {
    
    getPlaceTypeOptions(accidentPlace): UiOptionsModel[] {
        let placeTypeOptions: UiOptionsModel[] = [];
        switch (accidentPlace) {
            case 'Construction site':
                placeTypeOptions = [
                    {"value":"01", "label":"ecClaim.accidentPlace.placeType.buildingWorksite" },
                    {"value":"02", "label":"ecClaim.accidentPlace.placeType.civilWorksite" },
                    {"value":"03", "label":"ecClaim.accidentPlace.placeType.renovation" }
                ]
                break;
            case 'Shipyard':
                placeTypeOptions = [
                    {"value":"04", "label":"ecClaim.accidentPlace.placeType.floatingVessel" },
                    {"value":"05", "label":"ecClaim.accidentPlace.placeType.nonFloatingVessel" },
                    {"value":"06", "label":"ecClaim.accidentPlace.placeType.maintenanceWorkshop" }
                ]
                break;
            case 'Manufactory':
                placeTypeOptions = [
                    {"value":"07", "label":"ecClaim.accidentPlace.placeType.productionArea" },
                    {"value":"08", "label":"ecClaim.accidentPlace.placeType.maintenanceWorkshop" },
                    {"value":"09", "label":"ecClaim.accidentPlace.placeType.loadingUnloading" },
                    {"value":"10", "label":"ecClaim.accidentPlace.placeType.storageArea" }
                ]
                break;
            case 'Others':
                placeTypeOptions = [
                    {"value":"11", "label":"ecClaim.accidentPlace.placeType.containeryard" },
                    {"value":"12", "label":"ecClaim.accidentPlace.placeType.cateringEstablishment" },
                    {"value":"15", "label":"ecClaim.accidentPlace.placeType.other" }
                ]
                break;
        }
        return placeTypeOptions;
    };
}