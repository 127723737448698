import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { CurrencyUtil } from '../utils/currency-util';
import {UiComponent} from "../../ui/ui.component";

@Directive({selector: '[qnectCurrencyInputFormatter]'})
export class CurrencyInputFormatterDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef, private uiComponent: UiComponent) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = CurrencyUtil.transform(this.el.value);
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.uiComponent.getValue();
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.uiComponent.isValid()) {
      this.el.value = CurrencyUtil.transform(value);
    }
  }

}
