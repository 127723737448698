export class EmployeeDetails {

    private name: string;
    private idType: string;
    private idNumber: string;
    private telephoneNumber: string;
    private telephoneNumberCode: string;
    private emailAddress: string;
    private address: string;
    private birthDate: Date;
    private gender: string;
    private genderDesc: string;
    private faxNumber: string;
    private occupation: string;
    private isApprentice: boolean;
    private durationDateFrom: Date;
    private durationDateTo: Date;


    public getName(): string{
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getIdType(): string {
        return this.idType;
    }

    public setIdType(idType: string): void {
        this.idType = idType;
    }

    public getIdNumber(): string {
        return this.idNumber;
    }

    public setIdNumber(idNumber: string): void {
        this.idNumber = idNumber;
    }

    public getTelephoneNumber(): string {
        return this.telephoneNumber;
    }

    public setTelephoneNumber(telephoneNumber: string): void {
        this.telephoneNumber = telephoneNumber;
    }

    public getTelephoneNumberCode(): string {
        return this.telephoneNumberCode;
    }

    public setTelephoneNumberCode(telephoneNumberCode: string): void {
        this.telephoneNumberCode = telephoneNumberCode;
    }

    public getEmailAddress(): string {
        return this.emailAddress;
    }

    public setEmailAddress(address: string): void {
        this.emailAddress = address;
    }

    public getAddress(): string {
        return this.address;
    }

    public setAddress(address: string): void {
        this.address = address;
    }

    public getBirthDate(): Date{
        return this.birthDate;
    }

    public setBirthDate(birthDate: Date): void {
        this.birthDate = birthDate;
    }

    public getGender(): string{
        return this.gender;
    }

    public setGender(gender: string): void {
        this.gender = gender;
    }

    public getGenderDesc(): string{
        return this.genderDesc;
    }

    public setGenderDesc(genderDesc: string): void {
        this.genderDesc = genderDesc;
    }

    public getFaxNumber(): string{
        return this.faxNumber;
    }

    public setFaxNumber(faxNumber: string): void {
        this.faxNumber = faxNumber;
    }

    public getOccupation(): string{
        return this.occupation;
    }

    public setOccupation(occupation: string): void {
        this.occupation = occupation;
    }
    
    public getIsApprentice(): boolean{
        return this.isApprentice;
    }

    public setIsApprentice(isApprentice: boolean): void {
        this.isApprentice = isApprentice;
    }

    public getDurationDateFrom(): Date{
        return this.durationDateFrom;
    }

    public setDurationDateFrom(durationDateFrom: Date): void {
        this.durationDateFrom = durationDateFrom;
    }

    public getDurationDateTo(): Date{
        return this.durationDateTo;
    }

    public setDurationDateTo(durationDateTo: Date): void {
        this.durationDateTo = durationDateTo;
    }

    static jsonConvert(employeeDetails: EmployeeDetails): EmployeeDetails {
        if (employeeDetails !== null) {
            if (employeeDetails.getBirthDate() !== null) {
                employeeDetails.setBirthDate(new Date(employeeDetails.getBirthDate()));
            }
    
            if (employeeDetails.getDurationDateFrom() !== null) {
                employeeDetails.setDurationDateFrom(new Date(employeeDetails.getDurationDateFrom()));
            }
    
            if (employeeDetails.getDurationDateTo() !== null) {
                employeeDetails.setDurationDateTo(new Date(employeeDetails.getDurationDateTo()));
            }
        }
        return employeeDetails;
    }

}