<div class="form-group section">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionTD.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.title' | translate}}</label>
            <!-- Display of ORIGINAL DELAYED DEPARTURE DETAILS -->
            <div *ngIf="this.travelDelay.getDelayedDepartureOrArrival() == 'delayedDeparture'">
                <span *ngIf="this.travelDelay.getIsOriginalFlightCancelled() == true">{{'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-9' | translate}}</span>
                <div class="row" *ngIf="!this.travelDelay.getManualInputFlag()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'claimSection.travelDelay.details.flightNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getFlightNumber()?.toUpperCase()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.depCountry' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getDelayedDepartureCountryDesc()}}
                    </div>
                </div>
                <div class="row" *ngIf="this.travelDelay.getOriginalFlightDate() != null && this.travelDelay.getOriginalFlightDate() != undefined">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.origDepSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getOriginalFlightDate())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.travelDelay.getDelayedFlightDate() != null && this.travelDelay.getDelayedFlightDate() != undefined">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.actDepSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getDelayedFlightDate())}}
                    </div>
                </div>
            </div>

            <!-- Display of ALTERNATIVE DELAYED DEPARTURE DETAILS -->
            <div *ngIf="this.travelDelay.getAltDelayedDepartureOrArrival() == 'delayedDeparture' && this.travelDelay.getIsOriginalFlightCancelled() == true">
                <br><span *ngIf="this.travelDelay.getIsOriginalFlightCancelled() == true">{{'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-10' | translate}}</span>
                <div class="row" *ngIf="!this.travelDelay.getIsAltManualInputFlag()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'claimSection.travelDelay.details.altFlightNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getAltFlightNumber()?.toUpperCase()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.depCountry' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getAltDelayedDepartureCountryDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.origDepSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getAltOriginalFlightDate())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.travelDelay.getAltDelayedFlightDate">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.actDepSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getAltDelayedFlightDate())}}
                    </div>
                </div>
            </div>

            <!-- Display of ORIGINAL DELAYED ARRIVAL DETAILS -->
            <div *ngIf="this.travelDelay.getDelayedDepartureOrArrival() == 'delayedArrival'">
                <span *ngIf="this.travelDelay.getIsOriginalFlightCancelled() == true">{{'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-9' | translate}}</span>
                <div class="row" *ngIf="!this.travelDelay.getManualInputFlag()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'claimSection.travelDelay.details.flightNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getFlightNumber()?.toUpperCase()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.arrCountry' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getDelayedArrivalCountryDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.origArrSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getDelayedArrivalOriginalFlightDate())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.travelDelay.getDelayedArrivalDelayedFlightDate">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.actArrSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getDelayedArrivalDelayedFlightDate())}}
                    </div>
                </div>
            </div>

            <!-- Display of ALTERNATIVE DELAYED ARRIVAL DETAILS -->
            <div *ngIf="this.travelDelay.getAltDelayedDepartureOrArrival() == 'delayedArrival' && this.travelDelay.getIsOriginalFlightCancelled() == true">
                <span *ngIf="this.travelDelay.getIsOriginalFlightCancelled() == true">{{'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-10' | translate}}</span>
                <div class="row" *ngIf="!this.travelDelay.getIsAltManualInputFlag()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'claimSection.travelDelay.details.altFlightNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getAltFlightNumber()?.toUpperCase()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.arrCountry' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.travelDelay.getAltDelayedArrivalCountryDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.origArrSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getAltDelayedArrivalOriginalFlightDate())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.travelDelay.getAltDelayedArrivalDelayedFlightDate">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionTD.actArrSchd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(this.travelDelay.getAltDelayedArrivalDelayedFlightDate())}}
                    </div>
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionRTD.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTD.subSectionRTD.causeDelay' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="this.claim.getClaimTravel().getTravelDelay().getDelayReason() != 'Other'">
                    {{this.claim.getClaimTravel().getTravelDelay().getDelayReasonDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="this.claim.getClaimTravel().getTravelDelay().getDelayReason() == 'Other'">
                    {{this.claim.getClaimTravel().getTravelDelay().getDelayReasonOthers()}}
                </div>
            </div>
        </div>
        <ng-container *ngIf="isNotNullOrUndefined(this.claim.getClaimTravel().getTravelDelay().getHasExtTransCost()) || isNotNullOrUndefined(this.claim.getClaimTravel().getTravelDelay().getHasExtAccomCost())">
            <div class="sub-section">
                <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionETC.title' | translate}}</label>
                <ng-container *ngIf="this.claim.getCountry() == 'SGP' || this.claim.getCountry() == 'MYS'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionTD.subSectionETC.extraTrans' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            <span *ngIf="this.claim.getClaimTravel().getTravelDelay().getHasExtTransCost()">{{this.claim.getClaimTravel().getTravelDelay().getExtraTransportationCurrency()}}&nbsp;{{this.claim.getClaimTravel().getTravelDelay().getExtraTransportationCost() | number : '1.2-2'}}</span>
                            <span *ngIf="!this.claim.getClaimTravel().getTravelDelay().getHasExtTransCost()">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionTD.subSectionETC.extraAccom' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            <span *ngIf="this.claim.getClaimTravel().getTravelDelay().getHasExtAccomCost()">{{this.claim.getClaimTravel().getTravelDelay().getExtraAccommodationCurrency()}}&nbsp;{{this.claim.getClaimTravel().getTravelDelay().getExtraAccommodationCost()  | number : '1.2-2'}}</span>
                            <span *ngIf="!this.claim.getClaimTravel().getTravelDelay().getHasExtAccomCost()">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="this.claim.getCountry() == 'HKG' || this.claim.getCountry() == 'HGI'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.travelDelay.extralCost.hasPrepaidExpenses' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(this.claim.getClaimTravel().getTravelDelay().getHasExtTransCost())}}
                        </div>
                    </div>
                    <div class="row" *ngIf="this.claim.getClaimTravel().getTravelDelay().getHasExtTransCost()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.travelDelay.extralCost.captionHowMuch' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{this.claim.getClaimTravel().getTravelDelay().getExtraTransportationCurrency()}}&nbsp;
                            {{this.claim.getClaimTravel().getTravelDelay().getExtraTransportationCost() | number : '1.2-2'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.travelDelay.extralCost.hasAdditionalExpenses' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(this.claim.getClaimTravel().getTravelDelay().getHasExtAccomCost())}}
                        </div>
                    </div>
                    <div class="row" *ngIf="this.claim.getClaimTravel().getTravelDelay().getHasExtAccomCost()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.travelDelay.extralCost.captionHowMuch' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{this.claim.getClaimTravel().getTravelDelay().getExtraAccommodationCurrency()}}&nbsp;
                            {{this.claim.getClaimTravel().getTravelDelay().getExtraAccommodationCost() | number : '1.2-2'}}
                        </div>
                    </div>

                </ng-container>

            </div>
        </ng-container>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>
