import {VehicleItemDetail} from './vehicle-item-detail.model';
import {InjuredItemDetail} from './injured-item-detail.model';
import {PropertyItemDetail} from './property-item-detail.model';

export class MotorAccidentCollission {
    
  private  accidentIncidentDate: Date;
  private  countryOccurence: string;
  private  countryOccurenceDesc: string;
  private  incidentAccidentOccur: string;
  private  wasVehicleParkWhenDamage: boolean;
  private  purposeOfUse: string;
  private  purposeOfUseDesc: string;
  private  descriptionOfAccident: string;
  private  driverType: string;
  private  driverTypeDesc: string;
  private  driverName: string;
  private  contactNumber: string;
  private  contactEmail: string;
  private  driverAge: string;
  private  wasDriverDrunk: boolean;
  private  whoCauseTheAccident: string;
  private  whoCauseTheAccidentDesc: string;
  private  otherDriverName: string;
  private  otherDriverContactNo: string;
  private  damageExtent: string;
  private  damageExtentDesc: string;
  private  isVehicleHasLeak: boolean;
  private  hasAirbagGoneOff: boolean;
  private  hasAnyWheelDamage: boolean;
  private  currentLocation: string;
  private  currentLocationDesc: string;
  private  otherLocation: string;
  private  willClaimRepairCost: boolean;
  private  repairerPanel: string;
  private vehicleDamageItemList: VehicleItemDetail[] = [];
  private injuredItemList: InjuredItemDetail[] = [];
  private propertyDamageItemList: PropertyItemDetail[] = [];
  private detailsOfDamagedItem: string[] = [];      // vehicle andor person andor property
    private detailsOfDamagedItemDesc: string[] = [];    // vehicle andor person andor property

  private hasAccidentInvolve: boolean;
  private didYouHit: string;
  private breathTestResult: string;
  private wasBreathTest: boolean;
  private licenseIssueDate: Date;
  private hasDriverHadValidLicense: boolean;
  private firstLicense: string;
  private firstLicenseDesc: string;
  private hasSelectedDamagePart: boolean;
  private repairerAddress: string;
  private repairerAddressDesc: string;
  private repairerName: string;
  private selectRepairer: string;
  private selectRepairerDesc: string;
  private repairerContact: string;
  private governmentExamLocation: string;
  private hasBeenDetained: boolean; 
  private detailsOfDamagedType: string[] = [];
  private detailsOfDamagedTypeDesc: string[] = [];
  private hasSelectedDamageType: boolean;
  private hasPaidOrReceived: boolean;
  private anyMechanicalPartsDamaged: boolean;

    getDetailsOfDamagedType(): string[] {
    return this.detailsOfDamagedType;
    }

    setDetailsOfDamagedType(value: string[]) {
        this.detailsOfDamagedType = value;
    }

    getDetailsOfDamagedTypeDesc(): string[] {
        return this.detailsOfDamagedTypeDesc;
    }

    setDetailsOfDamagedTypeDesc(value: string[]) {
        this.detailsOfDamagedTypeDesc = value;
    }
    getHasSelectedDamageType(): boolean {
        return this.hasSelectedDamageType;
    }
    
    setHasSelectedDamageType(value: boolean) {
        this.hasSelectedDamageType = value;
    }  

getRepairerAddress(): string {
    return this.repairerAddress;
}

setRepairerAddress(value: string) {
    this.repairerAddress = value;
}

getRepairerAddressDesc(): string {
    return this.repairerAddressDesc;
}

setRepairerAddressDesc(value: string) {
    this.repairerAddressDesc = value;
}

getRepairerName(): string {
    return this.repairerName;
}

setRepairerName(value: string) {
    this.repairerName = value;
}

getSelectRepairer(): string {
    return this.selectRepairer;
}

setSelectRepairer(value: string) {
    this.selectRepairer = value;
}

getSelectRepairerDesc(): string {
    return this.selectRepairerDesc;
}

setSelectRepairerDesc(value: string) {
    this.selectRepairerDesc = value;
}

getRepairerContact(): string {
        return this.repairerContact;
}

setRepairerContact(value: string) {
        this.repairerContact = value;
}

getGovernmentExamLocation(): string {
    return this.governmentExamLocation;
}

setGovernmentExamLocation(value: string) {
    this.governmentExamLocation = value;
}

getHasBeenDetained(): boolean {
    return this.hasBeenDetained;
}

setHasBeenDetained(value: boolean) {
    this.hasBeenDetained = value;
}

getHasSelectedDamagePart(): boolean {
    return this.hasSelectedDamagePart;
}

setHasSelectedDamagePart(value: boolean) {
    this.hasSelectedDamagePart = value;
}

getDetailsOfDamagedItem(): string[] {
return this.detailsOfDamagedItem;
}

setDetailsOfDamagedItem(value: string[]) {
    this.detailsOfDamagedItem = value;
}

getDetailsOfDamagedItemDesc(): string[] {
    return this.detailsOfDamagedItemDesc;
}

setDetailsOfDamagedItemDesc(value: string[]) {
    this.detailsOfDamagedItemDesc = value;
}

  getBreathTestResult(): string {
    return this.breathTestResult;
  }

  setBreathTestResult(breathTestResult: string): void {
    this.breathTestResult = breathTestResult;
  } 
  getWasBreathTest(): boolean {
    return this.wasBreathTest;
  }

  setWasBreathTest(wasBreathTest: boolean): void {
    this.wasBreathTest = wasBreathTest;
  } 
  getLicenseIssueDate(): Date {
    return this.licenseIssueDate;
  }

  setLicenseIssueDate(licenseIssueDate: Date): void {
    this.licenseIssueDate = licenseIssueDate;
  } 

  getHasDriverHadValidLicense(): boolean {
    return this.hasDriverHadValidLicense;
  }

  setHasDriverHadValidLicense(hasDriverHadValidLicense: boolean): void {
    this.hasDriverHadValidLicense = hasDriverHadValidLicense;
  } 

  getFirstLicense(): string {
      return this.firstLicense;
  }

  setFirstLicense(firstLicense: string){
      this.firstLicense = firstLicense;
  }

  getFirstLicenseDesc(): string {
    return this.firstLicenseDesc;
  }

  setFirstLicenseDesc(firstLicenseDesc: string){
    this.firstLicenseDesc = firstLicenseDesc;
  }

  getHasAccidentInvolve(): boolean {
      return this.hasAccidentInvolve;
  }
  
  setHasAccidentInvolve(hasAccidentInvolve: boolean): void {
      this.hasAccidentInvolve = hasAccidentInvolve;
  } 
  
  getDidYouHit(): string {
      return this.didYouHit;
  }
  
  setDidYouHit(didYouHit: string): void {
      this.didYouHit = didYouHit;
  } 
  

getRepairerPanel(): string {
    return this.repairerPanel;
}

setRepairerPanel(repairerPanel: string): void {
    this.repairerPanel = repairerPanel;
} 


getWillClaimRepairCost(): boolean {
    return this.willClaimRepairCost;
}

setWillClaimRepairCost(willClaimRepairCost: boolean): void {
    this.willClaimRepairCost = willClaimRepairCost;
} 

getOtherLocation(): string {
    return this.otherLocation;
}

setOtherLocation(otherLocation: string): void {
    this.otherLocation = otherLocation;
} 

getCurrentLocation(): string {
    return this.currentLocation;
}

setCurrentLocation(currentLocation: string): void {
    this.currentLocation = currentLocation;
}   

getCurrentLocationDesc(): string {
    return this.currentLocationDesc;
}

setCurrentLocationDesc(currentLocationDesc: string): void {
    this.currentLocationDesc = currentLocationDesc;
}   

getHasAnyWheelDamage(): boolean {
    return this.hasAnyWheelDamage;
}

setHasAnyWheelDamage(hasAnyWheelDamage: boolean): void {
    this.hasAnyWheelDamage = hasAnyWheelDamage;
} 

getHasAirbagGoneOff(): boolean {
    return this.hasAirbagGoneOff;
}

setHasAirbagGoneOff(hasAirbagGoneOff: boolean): void {
    this.hasAirbagGoneOff = hasAirbagGoneOff;
} 

getIsVehicleHasLeak(): boolean {
    return this.isVehicleHasLeak;
}

setIsVehicleHasLeak(isVehicleHasLeak: boolean): void {
    this.isVehicleHasLeak = isVehicleHasLeak;
}

getAnyMechanicalPartsDamaged(): boolean {
    return this.anyMechanicalPartsDamaged;
}

setAnyMechanicalPartsDamaged(anyMechanicalPartsDamaged: boolean): void {
    this.anyMechanicalPartsDamaged = anyMechanicalPartsDamaged;
}   

getDamageExtent(): string {
    return this.damageExtent;
}

setDamageExtent(damageExtent: string): void {
    this.damageExtent = damageExtent;
} 

getDamageExtentDesc(): string {
    return this.damageExtentDesc;
}

setDamageExtentDesc(damageExtentDesc: string): void {
    this.damageExtentDesc = damageExtentDesc;
} 

getDriverType(): string {
    return this.driverType;
}

setDriverType(driverType: string): void {
    this.driverType = driverType;
} 

getDriverName(): string {
    return this.driverName;
}

setDriverName(driverName: string): void {
    this.driverName = driverName;
} 

getContactNumber(): string {
    return this.contactNumber;
}

setContactNumber(contactNumber: string): void {
    this.contactNumber = contactNumber;
}

getContactEmail(): string {
  return this.contactEmail;
}

setContactEmail(contactEmail: string): void {
  this.contactEmail = contactEmail;
}

    getDriverAge(): string {
    return this.driverAge;
}

setDriverAge(driverAge: string): void {
    this.driverAge = driverAge;
} 

getWasDriverDrunk(): boolean {
    return this.wasDriverDrunk;
}

setWasDriverDrunk(wasDriverDrunk: boolean): void {
    this.wasDriverDrunk = wasDriverDrunk;
} 

getWhoCauseTheAccident(): string {
    return this.whoCauseTheAccident;
}

setWhoCauseTheAccident(whoCauseTheAccident: string): void {
    this.whoCauseTheAccident = whoCauseTheAccident;
} 

getWhoCauseTheAccidentDesc(): string {
    return this.whoCauseTheAccidentDesc;
}

setWhoCauseTheAccidentDesc(whoCauseTheAccidentDesc: string): void {
    this.whoCauseTheAccidentDesc = whoCauseTheAccidentDesc;
} 

getOtherDriverName(): string {
    return this.otherDriverName;
}

setOtherDriverName(otherDriverName: string): void {
    this.otherDriverName = otherDriverName;
} 

getOtherDriverContactNo(): string {
    return this.otherDriverContactNo;
}

setOtherDriverContactNo(otherDriverContactNo: string): void {
    this.otherDriverContactNo = otherDriverContactNo;
}  

getAccidentIncidentDate(): Date {
    return this.accidentIncidentDate;
}

setAccidentIncidentDate(accidentIncidentDate: Date): void {
    this.accidentIncidentDate = accidentIncidentDate;
}

getCountryOccurence(): string {
    return this.countryOccurence;
}

setCountryOccurence(countryOccurence: string): void {
    this.countryOccurence = countryOccurence;
}

getCountryOccurenceDesc(): string {
    return this.countryOccurenceDesc;
}

setCountryOccurenceDesc(countryOccurenceDesc: string): void {
    this.countryOccurenceDesc = countryOccurenceDesc;
}

getIncidentAccidentOccur(): string {
    return this.incidentAccidentOccur;
}

setIncidentAccidentOccur(incidentAccidentOccur: string): void {
    this.incidentAccidentOccur = incidentAccidentOccur;
}

getWasVehicleParkWhenDamage(): boolean {
    return this.wasVehicleParkWhenDamage;
}

setWasVehicleParkWhenDamage(wasVehicleParkWhenDamage: boolean): void {
    this.wasVehicleParkWhenDamage = wasVehicleParkWhenDamage;
}

getPurposeOfUse(): string {
    return this.purposeOfUse;
}

setPurposeOfUse(purposeOfUse: string): void {
    this.purposeOfUse = purposeOfUse;
}

getDescriptionOfAccident(): string {
    return this.descriptionOfAccident;
}

setDescriptionOfAccident(descriptionOfAccident: string): void {
    this.descriptionOfAccident = descriptionOfAccident;
}

    getInjuredItemList(): InjuredItemDetail[] {
        return this.injuredItemList;
    }

    setInjuredItemList(value: InjuredItemDetail[]) {
        this.injuredItemList = value;
    }

    getVehicleDamageItemList(): VehicleItemDetail[] {
        return this.vehicleDamageItemList;
    }

    setVehicleDamageItemList(value: VehicleItemDetail[]) {
        this.vehicleDamageItemList = value;
    }

    getPropertyDamageItemList(): PropertyItemDetail[] {
        return this.propertyDamageItemList;
    }

    setPropertyDamageItemList(value: PropertyItemDetail[]) {
        this.propertyDamageItemList = value;
    }

    getHasPaidOrReceived(): boolean {
        return this.hasPaidOrReceived;
    }

    setHasPaidOrReceived(value: boolean){
        this.hasPaidOrReceived = value;
    }

    getPurposeOfUseDesc(): string {
        return this.purposeOfUseDesc;
    }
    
    setPurposeOfUseDesc(purposeOfUseDesc: string): void {
        this.purposeOfUseDesc = purposeOfUseDesc;
    }

    getDriverTypeDesc(): string {
        return this.driverTypeDesc;
    }

    setDriverTypeDesc(value: string) {
        this.driverTypeDesc = value;
    }

    static jsonConvert(collision: MotorAccidentCollission): MotorAccidentCollission {

        if(collision.getAccidentIncidentDate() != null){
            collision.setAccidentIncidentDate(new Date(collision.getAccidentIncidentDate()));
        }

        if (collision.getLicenseIssueDate() != null) {
            collision.setLicenseIssueDate(new Date(collision.getLicenseIssueDate()));
        }

        
        if (collision.getVehicleDamageItemList() != null) {
            let vehicleItemDetail: VehicleItemDetail[] = [];

            for (const vehicleItems of collision.getVehicleDamageItemList()) {
                    let item = Object.assign(new VehicleItemDetail(),vehicleItems);
                    vehicleItemDetail.push(item);
            }
            collision.setVehicleDamageItemList(vehicleItemDetail);
        }

        if (collision.getPropertyDamageItemList() != null) {
            let propertyItemDetail: PropertyItemDetail[] = [];

            for (const propertyItems of collision.getPropertyDamageItemList()) {
                    let item = Object.assign(new PropertyItemDetail(),propertyItems);
                    propertyItemDetail.push(item);
            }
            collision.setPropertyDamageItemList(propertyItemDetail);
        }

        if (collision.getInjuredItemList() != null) {
            let injuredItemDetail: InjuredItemDetail[] = [];

            for (const injuredItems of collision.getInjuredItemList()) {
                    let item = Object.assign(new InjuredItemDetail(),injuredItems);
                    injuredItemDetail.push(item);
            }
            collision.setInjuredItemList(injuredItemDetail);
        }

        if (collision.getInjuredItemList() != null) {
            let injuredItemDetail: InjuredItemDetail[] = [];

            for (const injuredItems of collision.getInjuredItemList()) {
                    let item = Object.assign(new InjuredItemDetail(),injuredItems);
                    injuredItemDetail.push(item);
            }
            collision.setInjuredItemList(injuredItemDetail);
        }
       
        return collision;
    }

}
