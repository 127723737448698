export class RentalVehicle {
    private dateOfIncident: Date;
    private descOfIncident: string;
    private wasRentalLicensed: boolean;
    private hasInsurance: boolean;
    private wasDriverInList: boolean;
    private rentalExcessPaidCurrency: string;
    private rentalExcessPaidCost: number;
    private countryIncidentOccured : string;
    private countryIncidentOccuredDesc : string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    setRentalExcessPaidCost(value: number){
        this.rentalExcessPaidCost = value;
    }

    getRentalExcessPaidCost(): number {
        return this.rentalExcessPaidCost;
    }      

    setRentalExcessPaidCurrency(value: string){
        this.rentalExcessPaidCurrency = value;
    }

    getRentalExcessPaidCurrency(): string {
        return this.rentalExcessPaidCurrency;
    }    

    setWasDriverInList(value: boolean){
        this.wasDriverInList = value;
    }

    getWasDriverInList(): boolean {
        return this.wasDriverInList;
    }    

    setHasInsurance(value: boolean){
        this.hasInsurance = value;
    }

    getHasInsurance(): boolean {
        return this.hasInsurance;
    }

    setWasRentalLicensed(value: boolean){
        this.wasRentalLicensed = value;
    }

    getWasRentalLicensed(): boolean {
        return this.wasRentalLicensed;
    }

    setDescOfIncident(value: string){
        this.descOfIncident = value;
    }

    getDescOfIncident(): string {
        return this.descOfIncident;
    }

    setDateOfIncident(value: Date){
        this.dateOfIncident = value;
    }

    getDateOfIncident(): Date {
        return this.dateOfIncident;
    }

    getCountryIncidentOccured(): string {
        return this.countryIncidentOccured;
    }

    setCountryIncidentOccured(value: string) {
        this.countryIncidentOccured = value;
    }

    getCountryIncidentOccuredDesc(): string {
        return this.countryIncidentOccuredDesc;
    }

    setCountryIncidentOccuredDesc(value: string) {
        this.countryIncidentOccuredDesc = value;
    }

    static jsonConvert(rentalVehicle: RentalVehicle): RentalVehicle {

        if (rentalVehicle.getDateOfIncident() != null) {
            rentalVehicle.setDateOfIncident(new Date(rentalVehicle.getDateOfIncident()));
        }
        return rentalVehicle;
    }

}
