import {DynamicItem} from './dynamic-item.model';

export class DynamicItemAction {

  public static get DELETE(): string {
    return 'DELETE';
  }

  public static get ADD(): string {
    return 'ADD';
  }

  public static get EDIT(): string {
    return 'EDIT';
  }

  public static get RETRIEVE(): string {
    return 'RETRIEVE';
  }

  public static get DISPLAY(): string {
    return 'DISPLAY';
  }

  public static get CLEAR(): string {
    return 'CLEAR';
  }


  action: string;
  item: DynamicItem;
}
