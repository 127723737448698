import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../../../services/side-menu.service";
import { ClaimDocumentsComponent } from '../../../../claim-documents/claim-documents.component';
import { ClaimService } from '../../../../../services/claim.service';
import { DocumentHelperService } from '../../../../../services/document-helper.service';
import { TransactionInfoService } from '../../../../../services/transaction-info.service';
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from '../../../../../services/document-form-factory.service';
import { ClaimTypes } from '../../../../../model/claim-type.model';
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Claim } from 'src/app/model/claim.model';
import { LostPersonalItemDetail } from 'src/app/model/travel/lost-personal-detail.model';
import { DocumentField } from 'src/app/model/document-field';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UtilitiesService} from '../../../../../utilities/utilities.service';
declare var $: any;
@Component({
  selector: 'app-loss-upload-document',
  templateUrl: './loss-upload-document.component.html',
  styleUrls: ['./loss-upload-document.component.css']
})
export class LossUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    claim: Claim;
    detailsOfLostItemsArray: LostPersonalItemDetail[];
    passportAndHkidFlag: Boolean;
    requiredDocuments: DocumentField[];
    unuploadedDocs: string[][] = [];
    doc = "DOC";
    @ViewChild('alert_unuploaded_docs', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    listOfUnuploadedDocs: any;
    translateService: TranslateService;

    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService,
        private activatedRoute: ActivatedRoute,
        private router: Router, 
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        private translate: TranslateService,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]);

                this.claim = this.claimService.getClaim();
                this.detailsOfLostItemsArray = this.claim.getClaimTravel().getLostPersonalItem().getDetailsOfLostItemsArray();
                this.passportAndHkidFlag = false;
                this.translateService = this.translate;
    }

    ngOnInit() {
        this.pushGAViewLostItemDocs();
      this.upLoadFileModel = this.fb.group(
          {
              airTicket: [],
              originalReceiptOfLoseItem: [],
              loseReport: [],
              compensationBreakdown:[],
              originReceiptForPassport:[],
              extraTransCost: [],
              extraAccomCost: [],
              choosedPassport: [],
              otherItems: this.fb.array([this.createItem()])

          });
          super.ngOnInit();

          //Modify required documents upload
          this.requiredDocuments = this.formBuilder.getRequiredDocuments();
  }
 

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }
    
    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    goToNext() { 
        this.listOfUnuploadedDocs = "";
        let stpDocIdList = this.requiredDocuments
            .reduce((result, { id, stpRequired }) => stpRequired == true ? result.push(id) && result : result, []);
        let stpAlertMessage = this.buildSTPAlertMessage(this.claimService.getClaim(), this.translate, stpDocIdList);

        if(!UtilitiesService.isEmpty(stpAlertMessage)){
            this.showDocAlertModal();
        } else {
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 3});
            this.router.navigate(["/claimform/moreClaimType"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    showDocAlertModal() {
         this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
             backdrop: 'static',
             keyboard: false
         });
     }

     continueToNext(){
        this.sideMenuService.emitProcess(1, 0);
        this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 3});
        this.router.navigate(["/claimform/moreClaimType"], {
            relativeTo: this.activatedRoute
        });
        this.docAlertModalRef.close();
     }

    //Google Analytics
    pushGAViewLostItemDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Lost Item – Document Upload',
            'vPath': '/claim/lost-item/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }
}
