import {Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';

@Component({
  selector: 'sign-instruction-modal',
  templateUrl: './sign-instruction-modal.html',
  styleUrls: ['./sign-instruction-modal.scss']
})
export class SignInstructionModalComponent extends ClaimsBaseComponent implements OnInit {

  @ViewChild('sign_instruction_modal', {static: true}) 
  private instructionModal: TemplateRef<any>;

  constructor(private injector : Injector,
              private modalService: NgbModal) {
      super(injector);
  }

  ngOnInit(): void {
  }

  open() {
    this.modalService.open(this.instructionModal, {size: 'lg', backdrop: 'static', keyboard: false});
  }

  dismiss() {
    this.modalService.dismissAll();
  }

}
