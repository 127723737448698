import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {TravelDelay} from "../../../../model/travel/travel-delay.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-travel-extral-cost',
    templateUrl: './travel-extral-cost.component.html',
    styleUrls: ['./travel-extral-cost.component.css']
})
export class TravelExtralCostComponent extends ClaimsBaseComponent implements OnInit {

    travelExtralCostForm: FormGroup;
    showTotalBox = false;
    travelDelay : TravelDelay;
    currency: string;
    isGoldOrDiamondPlan: boolean = false;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.travelDelay = this.claim.getClaimTravel().getTravelDelay();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
        this.isGoldOrDiamondPlan = ['BE','BF','BK','BL','HE','HF','HK','HL','21','22','23','24','25','26','27','28','31','32','33','34'].indexOf(this.claim.getPlanCode()) !== -1 &&
            ['HKG', 'HGI'].indexOf(this.claim.getCountry()) !== -1;
    }

    ngOnInit() {

        this.pushGATravelCostView();

        if (!this.isNotNullOrUndefinedStr(this.travelDelay.getExtraTransportationCurrency())){
            this.travelDelay.setExtraTransportationCurrency(this.currency);
        }

        if (!this.isNotNullOrUndefinedStr(this.travelDelay.getExtraAccommodationCurrency())){
            this.travelDelay.setExtraAccommodationCurrency(this.currency);
        }

        this.travelExtralCostForm = this.fb.group({
            extraTransportationCost: [this.travelDelay.getExtraTransportationCost()],
            extraAccommodationCost: [this.travelDelay.getExtraAccommodationCost()],
            extraTransportationCurrency: [this.travelDelay.getExtraTransportationCurrency()],
            extraAccommodationCurrency: [this.travelDelay.getExtraAccommodationCurrency()],
            hasExtAccomCost: [super.getBooleanString(this.travelDelay.getHasExtAccomCost())],
            hasExtTransCost: [super.getBooleanString(this.travelDelay.getHasExtTransCost())]
        });
    }

    ngAfterViewInit() {

        this.travelExtralCostForm.valueChanges.subscribe(data => {
            this.travelDelay.setExtraTransportationCost(super.getValueInComponent('extraTransportationCost'));
            this.travelDelay.setExtraAccommodationCost(super.getValueInComponent('extraAccommodationCost'));
            this.travelDelay.setExtraTransportationCurrency(this.travelExtralCostForm.get('extraTransportationCurrency').value);
            this.travelDelay.setExtraAccommodationCurrency(this.travelExtralCostForm.get('extraAccommodationCurrency').value);
            if (this.travelExtralCostForm.get('hasExtAccomCost').value != null) {
                this.travelDelay.setHasExtAccomCost(this.travelExtralCostForm.get('hasExtAccomCost').value == 'true');
            }
            if (this.travelExtralCostForm.get('hasExtTransCost').value != null) {
                this.travelDelay.setHasExtTransCost(this.travelExtralCostForm.get('hasExtTransCost').value == 'true');
            }

            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideExtraTransCost(self.getBooleanString(self.travelDelay.getHasExtTransCost())),10});
        this.travelExtralCostForm.get('hasExtTransCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideExtraTransCost(data);
        });

        // Stop validation of fields when not required.
        setTimeout(function () {self.hideAccomExtraCost(self.getBooleanString(self.travelDelay.getHasExtAccomCost())),10});
        this.travelExtralCostForm.get('hasExtAccomCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideAccomExtraCost(data);
        });

    }

    hideExtraTransCost(hasTranExtraCost) {
        if (hasTranExtraCost == 'true') {
            this.travelExtralCostForm.get('extraTransportationCost').enable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraTransportationCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.travelExtralCostForm.get('extraTransportationCost').reset();
            this.travelExtralCostForm.get('extraTransportationCurrency').setValue(this.currency);
            this.travelExtralCostForm.get('extraTransportationCost').disable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraTransportationCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideAccomExtraCost(hasAccomExtraCost) {
        if (hasAccomExtraCost == 'true') {
            this.travelExtralCostForm.get('extraAccommodationCost').enable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraAccommodationCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.travelExtralCostForm.get('extraAccommodationCost').reset();
            this.travelExtralCostForm.get('extraAccommodationCurrency').setValue(this.currency);
            this.travelExtralCostForm.get('extraAccommodationCost').disable({onlySelf: false, emitEvent: false});
            this.travelExtralCostForm.get('extraAccommodationCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }
    back() {
        this.router.navigate(["/claimform/travelDelayed/reasonOfTravelDelay"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 2});

            this.router.navigate(["/claimform/travelDelayed/travelSupportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.travelExtralCostForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.travelExtralCostForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 0, subMenuIndex: 2});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //START - Google Analytics
    pushGATravelCostView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Travel Delay – Extra Cost',
            'vPath': '/claim/travel-delay/extracost',
            'event': 'vpageview',
        });
    }
    //END - Google Analytics

}
