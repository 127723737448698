/**
 * Created by 942281 on 4/08/2017.
 */
import {ClinicalExpenses} from "./domestic-helper/clinical-expenses.model";
import {DentalExpenses} from "./domestic-helper/dental-expenses.model";
import { HelpersLiability } from "./domestic-helper/helpers-liability.model";
import { DishonestyHelper } from "./domestic-helper/dishonesty-helper.model";
import { EmployeeCompensation } from "./domestic-helper/employee-compensation.model";
import { OtherClaimDMH } from "./domestic-helper/other-claim.model";
import { Repatriation } from "./domestic-helper/repatriation.model";
import { HospitalizationHelper } from "./domestic-helper/hospitalization-helper.model";
import { DeathAndPermanentDisability } from "src/app/model/domestic-helper/death-and-permanent-disability.model";

export class ClaimDomesticHelper {
  private patientName: string;
  private sufferedIllness: string;
  private conditionDetails: string;
  private claimedAmount: string;
  private domesticCurrency: string;
  

  // Hospital
  private hospitalAdmissionDate: Date;
  private hospitalDischargeDate: Date;
  private hospitalDiagnosis: String;
  // Out Patient
  private outPatientDate: Date;
  private outPatientDiagnosis: String;
  // Other
  private otherDomesticQuestion: String;
  private otherIncidentDate: Date;
  private otherDescription: String;

  //Clinical Expenses
  private clinicalExpenses: ClinicalExpenses = new  ClinicalExpenses();
  //Dental Expenses
  private dentalExpenses: DentalExpenses = new  DentalExpenses();  
  //Helpers Liability
  private helpersLiability : HelpersLiability = new  HelpersLiability();  
  //Dishonesty Helper
  private dishonestyHelper : DishonestyHelper = new  DishonestyHelper();
  //Employee Compensation
  private employeeCompensation : EmployeeCompensation = new EmployeeCompensation();
  //other Claim
  private otherClaimDMH : OtherClaimDMH = new OtherClaimDMH();
  //Repatriation
  private repatriation: Repatriation = new Repatriation();
  //Hospitalization Helper
  private hospitalizationHelper: HospitalizationHelper = new HospitalizationHelper();  
  //Death and permanent disability
  private deathAndPermanentDisability: DeathAndPermanentDisability = new DeathAndPermanentDisability(); 

  getClinicalExpenses(): ClinicalExpenses {
      return this.clinicalExpenses;
  }

  setClinicalExpenses(value: ClinicalExpenses) {
      this.clinicalExpenses = value;
  }

  getDeathAndPermanentDisability(): DeathAndPermanentDisability {
    return this.deathAndPermanentDisability;
  }

  setDeathAndPermanentDisability(value: DeathAndPermanentDisability) {
    this.deathAndPermanentDisability = value;
  }

  getEmployeeCompensation(): EmployeeCompensation {
    return this.employeeCompensation;
  }

  setEmployeeCompensation(value: EmployeeCompensation) {
    this.employeeCompensation = value;
  }

  getDentalExpenses(): DentalExpenses {
      return this.dentalExpenses;
  }

  setDentalExpenses(value: DentalExpenses) {
      this.dentalExpenses = value;
  }  

  getHelpersLiability(): HelpersLiability {
      return this.helpersLiability;
  }

  setHelpersLiability(value: HelpersLiability) {
      this.helpersLiability = value;
  } 

  getDishonestyHelper(): DishonestyHelper {
    return this.dishonestyHelper;
  }

  setDishonestyHelper(value: DishonestyHelper) {
    this.dishonestyHelper = value;
  }

  getOtherClaimDMH(): OtherClaimDMH {
    return this.otherClaimDMH;
  }

  setOtherClaimDMH(value: OtherClaimDMH) {
      this.otherClaimDMH = value;
  }

	getRepatriation(): Repatriation  {
		return this.repatriation;
	}

	setRepatriation(value: Repatriation ) {
		this.repatriation = value;
	}

  getHospitalizationHelper(): HospitalizationHelper {
    return this.hospitalizationHelper;
  }

  setHospitalizationHelper(value: HospitalizationHelper) {
      this.hospitalizationHelper = value;
  }
   
  getOtherDomesticQuestion(): String {
    return this.otherDomesticQuestion;
  }

  setOtherDomesticQuestion(value: String) {
    this.otherDomesticQuestion = value;
  }

  getOtherIncidentDate(): Date {
    return this.otherIncidentDate;
  }

  setOtherIncidentDate(value: Date) {
    this.otherIncidentDate = value;
  }

  getOtherDescription(): String {
    return this.otherDescription;
  }

  setOtherDescription(value: String) {
    this.otherDescription = value;
  }

  getOutPatientDate(): Date {
    return this.outPatientDate;
  }

  setOutPatientDate(value: Date) {
    this.outPatientDate = value;
  }

  getOutPatientDiagnosis(): String {
    return this.outPatientDiagnosis;
  }

  setOutPatientDiagnosis(value: String) {
    this.outPatientDiagnosis = value;
  }

  getHospitalDiagnosis(): String {
    return this.hospitalDiagnosis;
  }

  setHospitalDiagnosis(value: String) {
    this.hospitalDiagnosis = value;
  }

  getHospitalAdmissionDate(): Date {
    return this.hospitalAdmissionDate;
  }

  setHospitalAdmissionDate(value: Date) {
    this.hospitalAdmissionDate = value;
  }

  getHospitalDischargeDate(): Date {
    return this.hospitalDischargeDate;
  }

  setHospitalDischargeDate(value: Date) {
    this.hospitalDischargeDate = value;
  }

  getPatientName(): string {
    return this.patientName;
  }

  setPatientName(value: string) {
    this.patientName = value;
  }

  getSufferedIllness(): string {
    return this.sufferedIllness;
  }

  setSufferedIllness(value: string) {
    this.sufferedIllness = value;
  }

  getConditionDetails(): string {
    return this.conditionDetails;
  }

  setConditionDetails(value: string) {
    this.conditionDetails = value;
  }

  getClaimedAmount(): string {
    return this.claimedAmount;
  }

  setClaimedAmount(value: string) {
    this.claimedAmount = value;
  }

  getDomesticCurrency(): string {
    return this.domesticCurrency;
  }

  setDomesticCurrency(value: string) {
    this.domesticCurrency = value;
  }


  static jsonConvertClaimDomestic(claimDomesticHelper: ClaimDomesticHelper): ClaimDomesticHelper {

    claimDomesticHelper = Object.assign(new ClaimDomesticHelper(), claimDomesticHelper);

    if (claimDomesticHelper != null) {

        if (claimDomesticHelper.getClinicalExpenses()) {
            claimDomesticHelper.setClinicalExpenses(Object.assign(new ClinicalExpenses(), claimDomesticHelper.getClinicalExpenses()));
            ClinicalExpenses.jsonConvert(claimDomesticHelper.getClinicalExpenses());
        }

        if (claimDomesticHelper.getDentalExpenses()) {
          claimDomesticHelper.setDentalExpenses(Object.assign(new DentalExpenses(), claimDomesticHelper.getDentalExpenses()));
          DentalExpenses.jsonConvert(claimDomesticHelper.getDentalExpenses());
        }

        if (claimDomesticHelper.getHelpersLiability()) {
          claimDomesticHelper.setHelpersLiability(Object.assign(new HelpersLiability(), claimDomesticHelper.getHelpersLiability()));
          HelpersLiability.jsonConvert(claimDomesticHelper.getHelpersLiability());
        }

        if (claimDomesticHelper.getDishonestyHelper()) {
          claimDomesticHelper.setDishonestyHelper(Object.assign(new DishonestyHelper(), claimDomesticHelper.getDishonestyHelper()));
          DishonestyHelper.jsonConvert(claimDomesticHelper.getDishonestyHelper());
        }

        if (claimDomesticHelper.getEmployeeCompensation()) {
            claimDomesticHelper.setEmployeeCompensation(Object.assign(new EmployeeCompensation(), claimDomesticHelper.getEmployeeCompensation()));
            EmployeeCompensation.jsonConvert(claimDomesticHelper.getEmployeeCompensation());
        }

        if (claimDomesticHelper.getOtherClaimDMH()) {
          claimDomesticHelper.setOtherClaimDMH(Object.assign(new OtherClaimDMH(), claimDomesticHelper.getOtherClaimDMH()));
          OtherClaimDMH.jsonConvert(claimDomesticHelper.getOtherClaimDMH());
        }

        if (claimDomesticHelper.getRepatriation()) {
            claimDomesticHelper.setRepatriation(Object.assign(new Repatriation(), claimDomesticHelper.getRepatriation()));
            Repatriation.jsonConvert(claimDomesticHelper.getRepatriation());
        }

        if (claimDomesticHelper.getHospitalizationHelper()) {
          claimDomesticHelper.setHospitalizationHelper(Object.assign(new HospitalizationHelper(), claimDomesticHelper.getHospitalizationHelper()));
          HospitalizationHelper.jsonConvert(claimDomesticHelper.getHospitalizationHelper());
        }

        if (claimDomesticHelper.getDeathAndPermanentDisability()) {
          claimDomesticHelper.setDeathAndPermanentDisability(Object.assign(new DeathAndPermanentDisability(), claimDomesticHelper.getDeathAndPermanentDisability()));
          DeathAndPermanentDisability.jsonConvert(claimDomesticHelper.getDeathAndPermanentDisability());
        }

      if (claimDomesticHelper.getHospitalAdmissionDate() != null) {
        claimDomesticHelper.setHospitalAdmissionDate(new Date(claimDomesticHelper.getHospitalAdmissionDate()))
      }

      if (claimDomesticHelper.getHospitalDischargeDate() != null) {
        claimDomesticHelper.setHospitalDischargeDate(new Date(claimDomesticHelper.getHospitalDischargeDate()))
      }

      // Out Patient
      if (claimDomesticHelper.getOutPatientDate() != null) {
        claimDomesticHelper.setOutPatientDate(new Date(claimDomesticHelper.getOutPatientDate()))
      }

      // Other
      if (claimDomesticHelper.getOtherIncidentDate() != null) {
        claimDomesticHelper.setOtherIncidentDate(new Date(claimDomesticHelper.getOtherIncidentDate()))
      }
    }

    return claimDomesticHelper;
  }

}
