<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
      <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
      <p>{{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.description' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
                <qnect-ui #MV_COLLISSION_WILL_CLAIM_REPAIR_COST [fieldId]="'MV_COLLISSION_WILL_CLAIM_REPAIR_COST'" [formGroup]="vehicleDamageForm" name="willClaimRepairCost"></qnect-ui>
            </div>
            <div *ngIf="collission.getWillClaimRepairCost()">  
                        <div class="form-group">
                            <p class="extent-damage">{{ 'claimSection.generalLabels.areaOfVehicleDamaged' | translate}}</p>
                        </div>
                        
                        <div class="row reasonBox col-12">
                            <div class="damaged-part" (click)="selectDamageParts(damagePartList[0].damagePartValue)">
                                <img style="width:112px; height:183px;" src="{{damagePartList[0].selected ? damagePartList[0].iconSelected : damagePartList[0].icon}}" alt="">
                                <img class="img-top" style="width:112px; height:183px;" src="{{damagePartList[0].selected ? damagePartList[0].iconSelected : damagePartList[0].iconHover}}" alt="">
                            </div>
                            <div class="damaged-part" (click)="selectDamageParts(damagePartList[1].damagePartValue)" [class.active]="damagePartList[1].selected == true">
                                <img style="width:112px; height:183px;" src="{{damagePartList[1].selected ? damagePartList[1].iconSelected : damagePartList[1].icon}}" alt="">
                                <img class="img-top" style="width:112px; height:183px;" src="{{damagePartList[1].selected ? damagePartList[1].iconSelected : damagePartList[1].iconHover}}" alt="">
                            </div>
                        </div>
                        <div class="row reasonBox col-12">
                            <div class="damaged-part" (click)="selectDamageParts(damagePartList[2].damagePartValue)">
                                <img style="width:55px;height:213px;" src="{{damagePartList[2].selected ? damagePartList[2].iconSelected : damagePartList[2].icon}}" alt="">
                                <img class="img-top" style="width:55px;height:213px;" src="{{damagePartList[2].selected ? damagePartList[2].iconSelected : damagePartList[2].iconHover}}" alt="">
                            </div>
                            <div class="damaged-part" (click)="selectDamageParts(damagePartList[3].damagePartValue)">
                                <img style="width:112px;height:213px;" src="{{damagePartList[3].selected ? damagePartList[3].iconSelected : damagePartList[3].icon}}" alt="">
                                <img class="img-top" style="width:112px;height:213px;" src="{{damagePartList[3].selected ? damagePartList[3].iconSelected : damagePartList[3].iconHover}}" alt="">
                            </div>
                            <div class="damaged-part" (click)="selectDamageParts(damagePartList[4].damagePartValue)">
                                <img style="width:55px;height:213px;" src="{{damagePartList[4].selected ? damagePartList[4].iconSelected : damagePartList[4].icon}}" alt="">
                                <img class="img-top" style="width:55px;height:213px;" src="{{damagePartList[4].selected ? damagePartList[4].iconSelected : damagePartList[4].iconHover}}" alt="">
                            </div>
                        </div>
                        <div class="row reasonBox col-12">
                            <div class="damaged-part" (click)="selectDamageParts(damagePartList[5].damagePartValue)">
                                <img style="width:111px;height:70px;" src="{{damagePartList[5].selected ? damagePartList[5].iconSelected : damagePartList[5].icon}}" alt="">
                                <img class="img-top" style="width:111px;height:70px;" src="{{damagePartList[5].selected ? damagePartList[5].iconSelected : damagePartList[5].iconHover}}" alt="">
                            </div>
                            <div class="damaged-part" (click)="selectDamageParts(damagePartList[6].damagePartValue)">
                                <img style="width:111px;height:70px;" src="{{damagePartList[6].selected ? damagePartList[6].iconSelected : damagePartList[6].icon}}" alt="">
                                <img class="img-top" style="width:111px;height:70px;" src="{{damagePartList[6].selected ? damagePartList[6].iconSelected : damagePartList[6].iconHover}}" alt="">
                            </div>
                        </div>
                        
                        <div *ngIf="this.collission.getHasSelectedDamagePart() != null && !this.collission.getHasSelectedDamagePart()">
                                <small id="error-message">{{'error_messages.required.selectValue' | translate}}</small>
                        </div>
                        <BR>
                        <div class="form-group">
                            <qnect-ui #MV_ANY_MECHANICAL_PARTS_DAMAGED [fieldId]="'MV_ANY_MECHANICAL_PARTS_DAMAGED'" [formGroup]="vehicleDamageForm" name="anyMechanicalPartsDamaged"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MV_COLLISSION_EXTENT_OF_DAMAGE [fieldId]="'MV_COLLISSION_EXTENT_OF_DAMAGE'" [formGroup]="vehicleDamageForm" name="damageExtent"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MV_COLLISSION_IS_VEHICLE_HAS_LEAK [fieldId]="'MV_COLLISSION_IS_VEHICLE_HAS_LEAK'" [formGroup]="vehicleDamageForm" name="isVehicleHasLeak"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MV_COLLISSION_HAS_AIRBAG_GONE_OFF [fieldId]="'MV_COLLISSION_HAS_AIRBAG_GONE_OFF'" [formGroup]="vehicleDamageForm" name="hasAirbagGoneOff"></qnect-ui>
                        </div>
                        <div class="form-group">          
                            <qnect-ui #MV_COLLISSION_HAS_ANY_WHEEL_DAMAGE [fieldId]="'MV_COLLISSION_HAS_ANY_WHEEL_DAMAGE'" [formGroup]="vehicleDamageForm" name="hasAnyWheelDamage"></qnect-ui>
                        </div>
                        <div class="form-group">          
                            <qnect-ui #MV_COLLISSION_HAS_BEEN_DETAINED_BY_GOVERMENT [fieldId]="'MV_COLLISSION_HAS_BEEN_DETAINED_BY_GOVERMENT'" [formGroup]="vehicleDamageForm" name="hasBeenDetained"></qnect-ui>
                        </div>
                        <div class="form-group" *ngIf="collission.getHasBeenDetained()">          
                                <qnect-ui #MV_COLLISSION_GOVERNMENT_EXAM_LOCATION [fieldId]="'MV_COLLISSION_GOVERNMENT_EXAM_LOCATION'" [formGroup]="vehicleDamageForm" name="governmentExamLocation"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MV_COLLISSION_CURRENT_LOCATION [fieldId]="'MV_COLLISSION_CURRENT_LOCATION'" [formGroup]="vehicleDamageForm" name="currentLocation"></qnect-ui>
                        </div>
                        <div class="form-group" *ngIf="collission.getCurrentLocation() === 'Others'">
                            <qnect-ui #MVA_DAMAGE_OTHER_LOCATION [fieldId]="'MVA_DAMAGE_OTHER_LOCATION'" [formGroup]="vehicleDamageForm" name="otherLocation"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MV_COLLISSION_SELECT_REPAIRER [fieldId]="'MV_COLLISSION_SELECT_REPAIRER'" [formGroup]="vehicleDamageForm" name="selectRepairer"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <small id="repairer-btn" (click)="open(panelRepairerDetails,'panelRepairerDetails')">{{'motorClaim.panelRepairers.labels.clickHere1' | translate}}</small><small> {{'motorClaim.panelRepairers.labels.clickHere2' | translate}}</small>
                        </div>
                        <div *ngIf="collission.getSelectRepairer() === 'Others'">
                            <div class="form-group" class="row" id="repairer-warning">
                                <div class="col-sm-2 align-center" style="padding: 5px;">
                                    <img src="assets/images/exclamation-trvl-dly-final.png" id="ray-repairer">
                                </div>
                                <div class="col-sm-10 repairer-warning-msg">
                                    {{ 'motorClaim.panelRepairers.labels.ownRepairerWarning' | translate}}
                                </div>
                            </div>
                            <div class="form-group">
                                <qnect-ui #MV_COLLISSION_REPAIRER_NAME [fieldId]="'MV_COLLISSION_REPAIRER_NAME'" [formGroup]="vehicleDamageForm" name="repairerName"></qnect-ui>
                            </div>
                            <div class="form-group">
                                <qnect-ui #MV_COLLISSION_REPAIRER_ADDRESS [fieldId]="'MV_COLLISSION_REPAIRER_ADDRESS'" [formGroup]="vehicleDamageForm" name="repairerAddress"></qnect-ui>
                            </div>                
                        </div>
            </div>
        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #panelRepairerDetails let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title cursor-pointer">{{'motorClaim.panelRepairers.labels.panelRepairerDetails' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <qnect-ui #MV_COLLISSION_REPAIRER_DISTRICT [fieldId]="'MV_COLLISSION_REPAIRER_DISTRICT'" (change)="filterDistricts()" name="filterDistrict" [formGroup]="filterFormGroup"></qnect-ui>
      <BR>  
        <ng-container *ngFor="let pr of this.panelRepairerList">
            <div class="row panelRepairer">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 panelRepairerInfo">
                    <p class="repairerName">{{[pr.name | translate]}}</p>
                    <span>{{pr.address | translate}}</span><BR>
                    <ng-container *ngIf="isNotEmpty(pr.hotline | translate)"><span><strong>{{'motorClaim.panelRepairers.labels.hotline' | translate}} </strong><a href="tel://{{pr.hotline | translate}}">{{pr.hotline | translate}}</a></span><BR></ng-container>
                    <ng-container *ngIf="isNotEmpty(pr.fax | translate)"><span><strong>{{'motorClaim.panelRepairers.labels.fax' | translate}} </strong><a href="tel://{{pr.fax | translate}}">{{pr.fax | translate}}</a></span><BR></ng-container>
                    <ng-container *ngIf="isNotEmpty(pr.email | translate)"><span><strong>{{'motorClaim.panelRepairers.labels.email' | translate}} </strong><a href="mailto://{{pr.email | translate}}">{{pr.email | translate}}</a></span><BR></ng-container>
                    <span><a href="{{pr.website | translate }}" target="_blank" rel="noopener">{{pr.website | translate}}</a></span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 panelRepairerMap">
                    <img src="{{pr.mapImage}}" (click)="redirectToGoogleMaps(pr.mapUrl)" class="panelRepairerLocation" title="{{'motorClaim.panelRepairers.labels.mapTooltip' | translate}}">
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
  </ng-template>
