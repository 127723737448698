import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimHome } from 'src/app/model/claim-home.model';
import { takeUntil } from 'rxjs/operators';
import { HomeDetails } from 'src/app/model/home/home-details.model';
import {HomeItem} from '../../../../../model/home/home-item.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-water-details',
  templateUrl: './water-details.component.html',
  styleUrls: ['./water-details.component.css']
})
export class WaterDetailsComponent  extends ClaimsBaseComponent implements OnInit {

  waterForm: FormGroup;
  claimHome: ClaimHome;
  homeDetails: HomeDetails;
  showTotalBox = false;

  constructor(private fb: FormBuilder,
      private transactionInfoService: TransactionInfoService,
      private claimService: ClaimService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private injector: Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.claimHome = this.claim.getClaimHome();
      this.homeDetails = this.claim.getClaimHome().getHomeDetails();
  }

  ngOnInit() {
      this.pushGAWaterDetailsView();

      this.waterForm = this.fb.group({
          isTheWaterStillLeaking: [super.getBooleanString(this.homeDetails.getWater().getIsTheWaterStillLeaking())],
          isThereSeriousDamage: [super.getBooleanString(this.homeDetails.getIsThereSeriousDamage())],
          isThereInteriorDamage: [super.getBooleanString(this.homeDetails.getIsThereInteriorDamage())],
          isThereFixturesDamage: [super.getBooleanString(this.homeDetails.getIsThereFixturesDamage())],
          damageDescription: [this.homeDetails.getDamageDescription()],
          isAnyItemDamaged: [super.getBooleanString(this.homeDetails.getIsAnyItemDamaged())],
          isAppointBuilder: [super.getBooleanString(this.homeDetails.getIsAppointBuilder())],
          isAssistBuilder: [super.getBooleanString(this.homeDetails.getIsAssistBuilder())],
          isThereWindowDamage: [super.getBooleanString(this.homeDetails.getIsThereWindowDamage())]
      });
  }

  ngAfterViewInit() {

      this.waterForm.valueChanges.subscribe(data => {
          if (this.waterForm.get('isTheWaterStillLeaking').value != null) {
            this.homeDetails.getWater().setIsTheWaterStillLeaking(this.waterForm.get('isTheWaterStillLeaking').value == 'true');
          }
          if (this.waterForm.get('isThereSeriousDamage').value != null) {
            this.homeDetails.setIsThereSeriousDamage(this.waterForm.get('isThereSeriousDamage').value == 'true');
          }
          this.homeDetails.setDamageDescription(this.waterForm.get('damageDescription').value);
          if (this.waterForm.get('isAnyItemDamaged').value != null) {
            this.homeDetails.setIsAnyItemDamaged(this.waterForm.get('isAnyItemDamaged').value == 'true');
          }
          if (this.waterForm.get('isAppointBuilder').value != null) {
            this.homeDetails.setIsAppointBuilder(this.waterForm.get('isAppointBuilder').value == 'true');
          } 
          if (this.waterForm.get('isAssistBuilder').value != null) {
            this.homeDetails.setIsAssistBuilder(this.waterForm.get('isAssistBuilder').value == 'true');
          } 
          if (this.waterForm.get('isThereWindowDamage').value != null) {
            this.homeDetails.setIsThereWindowDamage(this.waterForm.get('isThereWindowDamage').value == 'true');
          } 
          if (this.waterForm.get('isThereInteriorDamage').value !== null) {
            this.homeDetails.setIsThereInteriorDamage(this.waterForm.get('isThereInteriorDamage').value == 'true');
          }
          if (this.waterForm.get('isThereFixturesDamage').value !== null) {
              this.homeDetails.setIsThereFixturesDamage(this.waterForm.get('isThereFixturesDamage').value == 'true');
          }
      });

      let self = this;

       // Stop validation of fields when not required.
       setTimeout(function () {self.hideAppointBuilder(self.getBooleanString(self.homeDetails.getWater().getIsTheWaterStillLeaking())),10});
       this.waterForm.get('isTheWaterStillLeaking').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideAppointBuilder(data);
       });
       setTimeout(function () {self.toggleAssistBuilder(),10});
       this.waterForm.get('isThereSeriousDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.toggleAssistBuilder();
       });
       this.waterForm.get('isThereInteriorDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.toggleAssistBuilder();
       });
       this.waterForm.get('isThereFixturesDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.toggleAssistBuilder();
       });
       this.waterForm.get('isAnyItemDamaged').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.clearHomeItemList(data);
       });
  }

  hideAppointBuilder(isAppointBuilder) {
      if (isAppointBuilder === "true") {
          this.waterForm.get('isAppointBuilder').enable({onlySelf: false, emitEvent: false});
      } else {
          this.waterForm.get('isAppointBuilder').reset();
          this.waterForm.get('isAppointBuilder').disable({onlySelf: false, emitEvent: false});
      }
  }

  toggleAssistBuilder() {
    let showAssistBuilder = (this.isNotNullOrUndefined(this.waterForm.get('isThereSeriousDamage').value) && this.waterForm.get('isThereSeriousDamage').value == "true") ||
      (this.isNotNullOrUndefined(this.waterForm.get('isThereInteriorDamage').value) && this.waterForm.get('isThereInteriorDamage').value == "true") ||
      (this.isNotNullOrUndefined(this.waterForm.get('isThereFixturesDamage').value) && this.waterForm.get('isThereFixturesDamage').value == "true") ? true : false;
    if (showAssistBuilder == true) {
      this.waterForm.get('isAssistBuilder').enable({ onlySelf: false, emitEvent: false });
      this.waterForm.get('damageDescription').enable({ onlySelf: false, emitEvent: false });
    } else {
      this.homeDetails.setIsAssistBuilder(false);
      this.waterForm.get('isAssistBuilder').reset();
      this.waterForm.get('isAssistBuilder').disable({ onlySelf: false, emitEvent: false });
      this.waterForm.get('damageDescription').reset();
      this.waterForm.get('damageDescription').disable({ onlySelf: false, emitEvent: false });
    }
  }

  clearHomeItemList(hasDamagedItems) {
      if (hasDamagedItems === "false" && !UtilitiesService.isNullOrUndefined(this.homeDetails.getHomeItems())) {
          this.homeDetails.setHomeItems([] as HomeItem[]);
      }
  }



  goToNext() {
      if (this.validateForm()) {
          let routes = this.transactionInfoService.getTransactionInfo().getDynamicRoutes();
        this.transactionInfoService.getTransactionInfo().setDynamicRoutes(this.transactionInfoService.getDynamicRoutingService().updateScreenVisibility(routes, 
            this.homeDetails.getIsAnyItemDamaged(), this.claim.getSelectedClaimTypesList()[0], "homeItems", this.router));
        this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
      }
  }

  validateForm(): boolean {
      return super.validateForm(this.waterForm);
  }


  showModal() {
      console.log('showModel');
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  back() {
      this.router.navigate(["/claimform/claimDetail/selectClaim"], {
          relativeTo: this.activatedRoute
      });
  }

  pushGAWaterDetailsView() {
    let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Details of the Incident';
    let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details';

    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({
      'pageStep': pageStep,
      'vPath': vPath,
      'event': 'vpageview',
      'ecommerce': {
        'checkout': {
            'actionField': {'step': 4}	 //Step4-Claim Details
          }
      }
    });
  }
}