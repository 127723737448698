import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './spinner/spinner.component';
import {SpinnerService} from './spinner/spinner.service';
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
  ],
  exports: [
    SpinnerComponent
  ],
  declarations: [
    SpinnerComponent
  ],
  providers: [
    SpinnerService
  ]
})
export class CoreModule { }

