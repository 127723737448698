<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'ecClaim.insuranceDetails.title' | translate }}</h5>
    <p>{{ 'ecClaim.insuranceDetails.subTitle' | translate }}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="insuranceForm">
        <div class="form-group">
          <qnect-ui #EC_INSURANCE_COMPANY_NAME [fieldId]="'EC_INSURANCE_COMPANY_NAME'" [formGroup]="insuranceForm"
                    [dynamicLabel] ="transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_EC_FORM2' ? 'ecClaim.insuranceDetails.nameForm2' : 'ecClaim.insuranceDetails.name'"
                    name="name"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_INSURANCE_COMPANY_ADDRESS [fieldId]="'EC_INSURANCE_COMPANY_ADDRESS'" [formGroup]="insuranceForm"
                    [dynamicLabel] ="transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_EC_FORM2' ? 'ecClaim.insuranceDetails.addressForm2' : 'ecClaim.insuranceDetails.address'"
                    name="address"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_INSURANCE_POLICY_NUMBER [fieldId]="'EC_INSURANCE_POLICY_NUMBER'" [formGroup]="insuranceForm" name="policyNumber"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
