<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{'claimSection.stayedInHospital.medicalBillsAndAmount.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div>
          <div>
            <div class="form-group click-more-content">

              <qnect-ui #HOS17 [fieldId]="'HOS17'" [formGroup]="medicalBillForm" name="numberOfTreatmentBill" (uiComponentCreated)="uiComponentCreated($event)"></qnect-ui>

              <div *ngIf="stayInHospital.getNumberOfTreatmentBill() == 'others'">
                  <qnect-ui #HOS18 [fieldId]="'HOS18'" [formGroup]="medicalBillForm" name="otherNumOfTreatmentBill"></qnect-ui>
              </div>

            </div>
            <div class="form-group click-more-content">
              <qnect-ui #HOS19 [fieldId]="'HOS19'" [formGroup]="medicalBillForm" name="medicalExpenseTypeAmount"></qnect-ui>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>