export class OccupationalDisease {

    private hospitalName: string;
    private commencementDate: Date;
    private suffering: string;
    private workType: string;
    private result: string;
    private resultDesc: string;
    private resultDate: Date;

    public getHospitalName(): string{
        return this.hospitalName;
    }

    public setHospitalName(hospitalName: string): void {
        this.hospitalName = hospitalName;
    }

    public getCommencementDate(): Date{
        return this.commencementDate;
    }

    public setCommencementDate(commencementDate: Date): void {
        this.commencementDate = commencementDate;
    }

    public getSuffering(): string{
        return this.suffering;
    }

    public setSuffering(suffering: string): void {
        this.suffering = suffering;
    }

    public getWorkType(): string{
        return this.workType;
    }

    public setWorkType(workType: string): void {
        this.workType = workType;
    }

    public getResult(): string{
        return this.result;
    }

    public setResult(result: string): void {
        this.result = result;
    }

    public getResultDesc(): string{
        return this.resultDesc;
    }

    public setResultDesc(resultDesc: string): void {
        this.resultDesc = resultDesc;
    }

    public getResultDate(): Date{
        return this.resultDate;
    }

    public setResultDate(resultDate: Date): void {
        this.resultDate = resultDate;
    }

    static jsonConvert(occupationalDisease: OccupationalDisease): OccupationalDisease {
        if (occupationalDisease !== null) {
            if (occupationalDisease.getCommencementDate() !== null) {
                occupationalDisease.setCommencementDate(new Date(occupationalDisease.getCommencementDate()));
            }
    
            if (occupationalDisease.getResultDate() !== null) {
                occupationalDisease.setResultDate(new Date(occupationalDisease.getResultDate()));
            }
        }
        
        return occupationalDisease;
    }
    
}