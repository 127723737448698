<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.detailsOfIncident' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                     {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.dateOfincident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(helpersLiability.getDateOfIncident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.incidentOccured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{helpersLiability.getCountryIncidentOccurDesc()}}
                </div>
            </div>
            <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                      {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.natureOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{helpersLiability.getNatureOfIncident()}}
                    </div>
            </div>
        </div>
        <div class="sub-section">
                <label>{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.descOfIncident' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.descOfIncidentTextArea' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{helpersLiability.getDescOfIncident()}}
                    </div>
                </div>                
                <div class="row" *ngIf="helpersLiability.getAmountClaim() != null && helpersLiability.getAmountClaim() != ''">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.helpersLiabilitAmountClaim' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{helpersLiability.getAmountClaimCurrency()}} {{helpersLiability.getAmountClaim() | number : '1.2-2'}}
                    </div>
                </div>
            </div>  
            <div class="sub-section">
                    <label>{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.reportingToPolice' | translate}} </label>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.isIncidentReportedToPolice' | translate}} 
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                                {{returnYesOrNoBoolean(helpersLiability.getIsIncidentReport())}}
                        </div>
                    </div>
                   <ng-container *ngIf="this.helpersLiability.getIsIncidentReport()">
                       <div class="row">
                           <div class="col col-xs-12 col-lg-7">
                           {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.policeStationNameAdd' | translate}}
                          </div>
                          <div class="col col-xs-12 col-lg-5-word-break content">
                           {{helpersLiability.getPoliceStationNameAndAddress()}}
                          </div>
                       </div>
                       <div class="row">
                          <div class="col col-xs-12 col-lg-7">
                           {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.reportRefNo' | translate}}
                         </div>
                         <div class="col col-xs-12 col-lg-5-word-break content">
                           {{helpersLiability.getReferenceNumber()}}
                         </div>
                       </div>
                       <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                          {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.summaryOfHelpersLiability.dateOfReport' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                          {{getDate(helpersLiability.getDateOfReport())}}
                        </div>
                       </div>
                  </ng-container>
                </div>               
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>