export class PeriodOfSickLeave {

    private startDate: Date;
    private endDate: Date;
    private quantity: string;
    private quantityDesc: string;
    private numberOfDays: number;

    constructor() {
        this.startDate = null;
        this.endDate = null;
        this.quantity = '';
        this.quantity = '';
    }

    public getStartDate(): Date {
        return this.startDate;
    }

    public setStartDate(startDate: Date): void {
        this.startDate = startDate;
    }

    public getEndDate(): Date {
        return this.endDate;
    }

    public setEndDate(endDate: Date): void {
        this.endDate = endDate;
    }

    getQuantity(): string {
        return this.quantity;
    }

    setQuantity(quantity: string) {
        this.quantity = quantity;
    }

    getQuantityDesc(): string {
        return this.quantityDesc;
    }

    setQuantityDesc(quantity: string) {
        this.quantityDesc = quantity;
    }

    getNumberOfDays(): number {
        return this.numberOfDays;
    }

    setNumberOfDays(numberOfDays: number) {
        this.numberOfDays = numberOfDays;
    }

    static jsonConvert(periodOfSickLeave: PeriodOfSickLeave): PeriodOfSickLeave {
        if (periodOfSickLeave !== null) {
            if (periodOfSickLeave.getStartDate() !== null) {
                periodOfSickLeave.setStartDate(new Date(periodOfSickLeave.getStartDate()));
            }

            if (periodOfSickLeave.getEndDate() !== null) {
                periodOfSickLeave.setEndDate(new Date(periodOfSickLeave.getEndDate()));
            }
        }
            
        return periodOfSickLeave;
    }
}