
<!-- payment & contact -->
<div class="form-group section">
    <label class="section-title">{{isNotNullOrUndefinedStr(claim.getPaymentRefundToBank()) ? ('pageSummaryClaim.paymentContact.title' | translate)  : ('pageSummaryClaim.paymentContact.contactInfo'| translate) }}</label>
    <div class="section-content">
        <div class="sub-section" *ngIf="claim.getServiceUnit() === 'GP' && claim.getPaymentRefundToBank() === 'Y'">
            <div class="row col-12">
                {{'pagePaymentAndContact.bankAcct.acctNoticeG400' | translate}}
            </div>
        </div>
        <div class="sub-section" *ngIf="claim.getServiceUnit() !== 'GP' && claim.getPaymentRefundToBank() === 'Y'">
            <label>{{ 'pageSummaryClaim.paymentContact.desc' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.paymentContact.acctName' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getAccountNameDirect()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.bankAcct.bankName' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getBankDirectDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getBranchCode())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.bankAcct.branchCode2' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getBranchCode()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getAccountNumberDirect())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.bankAcct.accountNumber2' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getAccountNumberDirect()}}
                </div>
            </div>
        </div>
        <div class="sub-section" *ngIf="claim.getPaymentRefundToBank() == 'O'">
            <label>{{ 'pagePaymentAndContact.payOverseasBank.overseasBankInfo' | translate }}</label>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getBeneficiaryName())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.payOverseasBank.beneficiaryName' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getOverseasBankPayment().getBeneficiaryName()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getBeneficiaryBankName())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.payOverseasBank.beneficiaryBankName' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getOverseasBankPayment().getBeneficiaryBankName()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getBeneficiaryBankAddress())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.payOverseasBank.beneficiaryBankAddress' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getOverseasBankPayment().getBeneficiaryBankAddress()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getSwiftCode())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.payOverseasBank.swiftCode' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getOverseasBankPayment().getSwiftCode()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getBeneficiaryAccountNumber())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.payOverseasBank.beneficiaryAccountNumber' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getOverseasBankPayment().getBeneficiaryAccountNumber()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getBeneficiaryBankCode())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.payOverseasBank.beneficiaryBankCode' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getOverseasBankPayment().getBeneficiaryBankCode()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getBeneficiaryBranchCode())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.payOverseasBank.beneficiaryBranchCode' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getOverseasBankPayment().getBeneficiaryBranchCode()}}
                </div>
            </div>
            <ng-container *ngIf="this.transactionInfo.getHasOverseasBankIntermediary()">
                <label style="padding-top: 15px;">{{ 'pagePaymentAndContact.payOverseasBank.intermediaryBankDetails' | translate }}</label>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getIntermediaryAccountholderName())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pagePaymentAndContact.payOverseasBank.intermediaryAccountholderName' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claim.getOverseasBankPayment().getIntermediaryAccountholderName()}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getIntermediaryBankName())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pagePaymentAndContact.payOverseasBank.intermediaryBankName' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claim.getOverseasBankPayment().getIntermediaryBankName()}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getIntermediaryBankAddress())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pagePaymentAndContact.payOverseasBank.intermediaryBankAddress' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claim.getOverseasBankPayment().getIntermediaryBankAddress()}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getIntermediarySwiftCode())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pagePaymentAndContact.payOverseasBank.intermediarySwiftCode' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claim.getOverseasBankPayment().getIntermediarySwiftCode()}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getOverseasBankPayment().getIntermediaryBankAccountNumber())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pagePaymentAndContact.payOverseasBank.intermediaryBankAccountNumber' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claim.getOverseasBankPayment().getIntermediaryBankAccountNumber()}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="sub-section" *ngIf="claim.getPaymentRefundToBank() == 'N'">
            <label>{{ 'pageSummaryClaim.paymentContact.chequeInfo' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.paymentContact.chequePayee' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getPaymentClaimantName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.paymentContact.mailingAddress' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getAddressLine1()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getAddressLine2())">
                <div class="col col-xs-12 col-lg-7">
                 
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getAddressLine2()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getAddressLine3())">
                <div class="col col-xs-12 col-lg-7">
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getAddressLine3()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getAddressLine4())">
                <div class="col col-xs-12 col-lg-7">
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getAddressLine4()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.paymentContact.country' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getAddressCountryText()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label *ngIf="isNotNullOrUndefinedStr(claim.getPaymentRefundToBank())">{{ 'pageSummaryClaim.paymentContact.contactInfo' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.paymentContact.name' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.claim.getContactName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.paymentContact.mobile' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.claim.getContactNumberCountryCode()}} {{this.claim.getContactNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.paymentContact.email' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.claim.getContactEmail()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefined(this.claim.getNotifyAgent())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.isAgentEmailNotification' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNoOther(this.claim.getNotifyAgent())}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(this.claim.getAgentEmail())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.agentEmail' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.claim.getAgentEmail()}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefined(this.claim.getDocumentShareToAgent())">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.docShareToAgent' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNoOther(this.claim.getDocumentShareToAgent())}}
                </div>
            </div>
        </div>
    </div>
</div>
