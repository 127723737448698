<div class="section form-group">
    <label class="section-title">{{'homeProduct.water.title' | translate}}</label>
    <div class="section-content">
        <!--Details-->
        <div class="sub-section">
            <label>{{'homeProduct.common.detailsOfTheIncident' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.water.isTheWaterStillLeaking' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(homeDetails.getWater().getIsTheWaterStillLeaking())}}
                </div>
            </div>
            <!-- <div class="row" *ngIf="homeDetails.getWater().getIsTheWaterStillLeaking()">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.common.appointBuilder' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(homeDetails.getIsAppointBuilder())}}
                </div>
            </div> -->
            <ng-container *ngIf="this.claim.getCountry().toLowerCase() == 'hgi'">
                <div class="row" *ngIf="this.claimHome.getIsContentPremClassExist()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.contentsBuilding.isThereInteriorDamage' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{returnYesOrNo(homeDetails.getIsThereInteriorDamage())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.claimHome.getHasFixFitFlag()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.contentsBuilding.isThereFixturesDamage' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{returnYesOrNo(homeDetails.getIsThereFixturesDamage())}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="this.claimHome.getIsBuildingPremClassExist()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.contentsBuilding.isThereWindowDamage' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{returnYesOrNo(homeDetails.getIsThereWindowDamage())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.contentsBuilding.isThereSeriousDamage' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{returnYesOrNo(homeDetails.getIsThereSeriousDamage())}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="homeDetails.getIsThereSeriousDamage() || homeDetails.getIsThereInteriorDamage() || homeDetails.getIsThereFixturesDamage()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.contentsBuilding.damageDescription' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{homeDetails.getDamageDescription()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.common.assistBuilder' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(homeDetails.getIsAssistBuilder())}}
                    </div>
                </div>
            </ng-container>
            <div class="row" *ngIf="this.claimHome.getIsContentPremClassExist()">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.contentsBuilding.isAnyItemDamaged' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(homeDetails.getIsAnyItemDamaged())}}
                </div>
            </div>
        </div>
        <div class="sub-section" *ngIf="homeDetails.getIsAnyItemDamaged()">
            <label>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subDmgItemDetail.title' | translate}}</label>
            <ng-container *ngFor="let item of this.claimHome.getHomeDetails().getHomeItems(); let i = index;">
                <ng-container >
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getLossType() != 'Others'">
                            {{item.getLossTypeDesc()}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="item.getLossType() == 'Others'">
                          {{item.getLossTypeDetail()}}
                       </div>
                    </div>
                    <div class="row" *ngIf="item.getLossType() == 'Others'">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.briefDescriptionOfItem' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getOtherTypeBriefDesc()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="item.getLossType() == 'Mobile Phone' ||  item.getLossType() == 'Laptop(s)'">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.descOfIncident' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getLossTypeDetail()}}
                        </div>
                    </div>

                    <div class="row" *ngIf="item.getRepairCost()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.repairCost' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getRepairCostType()}} {{item.getRepairCost() | number : '1.2-2'}}
                        </div>
                    </div>

                    <div class="row" *ngIf="item.getReplacementCost()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'homeProduct.homeItems.replacementCost' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getReplacementCostType()}} {{item.getReplacementCost() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>