import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/-/g, ' ').replace(/\b\w/g, (a) => a.toUpperCase());
  }

}
