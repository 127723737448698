
export class TravelReRoute {



    private reroutingCountryOccurred: string;
    private reroutingCountryOccurredDesc: string;
    private reroutingOriginalSchedule: Date;
    private reroutingTranspoCancelOrDelay: string;
    private reroutingActualDelaySchedule: Date;
    private reroutingTotalDelay: string;
    private reroutingTakeDiffRoute: string;
    private reroutingTranspoChange: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getReroutingCountryOccurred(): string {
        return this.reroutingCountryOccurred;
    }

    setReroutingCountryOccurred(value: string) {
        this.reroutingCountryOccurred = value;
    }

    getReroutingCountryOccurredDesc(): string {
        return this.reroutingCountryOccurredDesc;
    }

    setReroutingCountryOccurredDesc(value: string) {
        this.reroutingCountryOccurredDesc = value;
    }

    getReroutingOriginalSchedule(): Date {
        return this.reroutingOriginalSchedule;
    }

    setReroutingOriginalSchedule(value: Date) {
        this.reroutingOriginalSchedule = value;
    }

    getReroutingTranspoCancelOrDelay(): string {
        return this.reroutingTranspoCancelOrDelay;
    }

    setReroutingTranspoCancelOrDelay(value: string) {
        this.reroutingTranspoCancelOrDelay = value;
    }

    getReroutingActualDelaySchedule(): Date {
        return this.reroutingActualDelaySchedule;
    }

    setReroutingActualDelaySchedule(value: Date) {
        this.reroutingActualDelaySchedule = value;
    }

    getReroutingTotalDelay(): string {
        return this.reroutingTotalDelay;
    }

    setReroutingTotalDelay(value: string) {
        this.reroutingTotalDelay = value;
    }

    getReroutingTakeDiffRoute(): string {
        return this.reroutingTakeDiffRoute;
    }

    setReroutingTakeDiffRoute(value: string) {
        this.reroutingTakeDiffRoute = value;
    }

    getReroutingTranspoChange(): string {
        return this.reroutingTranspoChange;
    }

    setReroutingTranspoChange(value: string) {
        this.reroutingTranspoChange = value;
    }


    static jsonConvert(travelReRoute: TravelReRoute): TravelReRoute {
        if (travelReRoute.getReroutingActualDelaySchedule() != null) {
            travelReRoute.setReroutingActualDelaySchedule(new Date(travelReRoute.getReroutingActualDelaySchedule()))
        }

        if (travelReRoute.getReroutingOriginalSchedule() != null) {
            travelReRoute.setReroutingOriginalSchedule(new Date(travelReRoute.getReroutingOriginalSchedule()))
        }
    
        return travelReRoute;
    }

}