import {Component, Injector, OnInit} from "@angular/core";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import { ClaimHome } from "src/app/model/claim-home.model";

@Component({
    selector: 'home-sections-summary',
    templateUrl: './home-sections-summary.component.html'
})
export class HomeSectionsSummaryComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimHome: ClaimHome;

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimHome = this.claim.getClaimHome();
    }

    ngOnInit() {
    }

}
