import {Component, Injector, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {TravelDelay} from "../../../../model/travel/travel-delay.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import { Notifications } from "src/app/utilities/components/notification-messages/notifications.model";
import { FlightStatus } from "src/app/model/flight-status.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { FlightStatusService } from "src/app/services/flight-status.service";
import { takeUntil } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClaimTypeItemHelperService } from "src/app/services/claim-type-item-helper.service";
import { FlightStatusOperationalTimes } from "src/app/model/flight-status-operational-times.model";
import { PlanInfoService } from "src/app/services/plan-info.service";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import {DocumentHelperService} from "../../../../services/document-helper.service";
import {ClaimTypes} from '../../../../model/claim-type.model';

declare var $: any;
@Component({
    selector: 'app-detail-of-travel-delay',
    templateUrl: './detail-of-travel-delay.component.html',
    styleUrls: ['./detail-of-travel-delay.component.css']
})
export class DetailOfTravelDelayComponent extends ClaimsBaseComponent implements OnInit {

    detailOfTravelFormGroup: FormGroup;
    departureFormGroup: FormGroup;
    arrivalFormGroup: FormGroup;
    detailOfTravelFlightStatsFormGroup: FormGroup;
    detailOfAltTravelFlightStatsFormGroup: FormGroup;

    showTotalBox = false;
    claim:Claim;
    showClaimDetail = true;
    travelDelay : TravelDelay;
    notifications: Notifications = new Notifications();
    flightStatus: FlightStatus;
    flightStatusOperationalTimesList: FlightStatusOperationalTimes[] = [];
    allowedDelayHours = 0;
    hrsDelayed = 0;

    @ViewChild('TRAVELNOTDELAYEDSINGLE', {static: true}) travelNotDelayedSingleLegModal: TemplateRef<any>;
    travelNotDelayedSingleLegModalRef: any;
    @ViewChild('TRAVELNOTDELAYEDMULTI', {static: true}) travelNotDelayedMultiLegModal: TemplateRef<any>;
    travelNotDelayedMultiLegModalRef: any;
    @ViewChild('sorry_not_covered', {static: true}) notCoveredModal: TemplateRef<any>;
    notCoveredModalRef: any;
    @ViewChild('flight_cancelled', {static: true}) flightCancelledModal: TemplateRef<any>;
    flightCancelledModalRef: any;
    @ViewChild('confirm_delete_claim_type', {static: true}) deleteClaimTypeModal: TemplateRef<any>;
    deleteClaimTypeModalRef: any;

    constructor(private fb: FormBuilder,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private transactionService: TransactionInfoService,
                private flightStatusService: FlightStatusService,
                private modalService: NgbModal,
                private claimTypeItemHelperService: ClaimTypeItemHelperService,
                private planInfoService: PlanInfoService,
                private documentHelperService : DocumentHelperService,) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.travelDelay = this.claim.getClaimTravel().getTravelDelay();
        this.flightStatus = this.travelDelay.getFlightStatus();
        this.flightStatusOperationalTimesList = this.flightStatus.getFlightStatusOperationalTimesList();

        setTimeout(() => {
            this.flightStatusOperationalTimesList = this.flightStatus.getFlightStatusOperationalTimesList();
        }, 100);

        if(this.travelDelay.getFlightNumberDate() == null){
            this.travelDelay.setFlightNumberDate(this.claim.getDateOfLoss());
        }

        setTimeout(() => {
            var multilegRadioButton = "multi-leg-radio-"+this.travelDelay.getMultiLegFlightSelectedIndex();
            $("#"+multilegRadioButton).prop("checked", true);
        }, 200);

    }

    ngOnInit() {

        this.pushGATravelDetailsView();

        // delayed arrival is only available for HGI
        let country = this.claim.getCountry();
        /*if(this.travelDelay.getOriginalFlightDate() == undefined &&
            this.claim.getClaimTravel().getOriginalDepartureDate() != undefined){
            this.travelDelay.setOriginalFlightDate(this.claim.getClaimTravel().getOriginalDepartureDate());
        }*/
        if(UtilitiesService.isNullOrUndefined(this.travelDelay.getAltFlightNumberDate())){
            this.travelDelay.setAltFlightNumberDate(this.claim.getDateOfLoss());
        }

        if (country && country.toLowerCase() !== 'hgi'){
            this.travelDelay.setDelayedDepartureOrArrival("delayedDeparture");
        }/* else {
            if(this.travelDelay.getDelayedArrivalOriginalFlightDate() == undefined && this.claim.getClaimTravel().getOriginalReturnDate() != undefined){
                this.travelDelay.setDelayedArrivalOriginalFlightDate(this.claim.getClaimTravel().getOriginalReturnDate());
            }
        }*/

        this.detailOfTravelFlightStatsFormGroup = this.fb.group({
            flightNumber: [this.travelDelay.getFlightNumber()],
            flightNumberDate: [this.travelDelay.getFlightNumberDate()]
        });

        this.detailOfAltTravelFlightStatsFormGroup = this.fb.group({
            altFlightNumber: [this.travelDelay.getAltFlightNumber()],
            altFlightNumberDate: [this.travelDelay.getAltFlightNumberDate()]
        });

        this.detailOfTravelFormGroup = this.fb.group({
            delayedDepartureOrArrival: [this.travelDelay.getIsAltManualInputFlag() ? this.travelDelay.getAltDelayedDepartureOrArrival() : this.travelDelay.getDelayedDepartureOrArrival()]
        });

        this.departureFormGroup = this.fb.group({
            delayedDepartureCountry: [this.travelDelay.getIsAltManualInputFlag() ? this.travelDelay.getAltDelayedDepartureCountry() : this.travelDelay.getDelayedDepartureCountry()],
            delayedDepartureOriginDate: [this.travelDelay.getIsAltManualInputFlag() ? this.travelDelay.getAltOriginalFlightDate() : this.travelDelay.getOriginalFlightDate()],
            delayedDepartureActualDate: [this.travelDelay.getIsAltManualInputFlag()  ? this.travelDelay.getAltDelayedFlightDate() : this.travelDelay.getDelayedFlightDate()],
        });

        this.arrivalFormGroup = this.fb.group({
            delayedArrivalCountry: [this.travelDelay.getIsAltManualInputFlag() ? this.travelDelay.getAltDelayedArrivalCountry() : this.travelDelay.getDelayedArrivalCountry()],
            delayedArrivalOriginDate: [this.travelDelay.getIsAltManualInputFlag() ? this.travelDelay.getAltDelayedArrivalOriginalFlightDate() : this.travelDelay.getDelayedArrivalOriginalFlightDate()],
            delayedArrivalActualDate: [this.travelDelay.getIsAltManualInputFlag() ? this.travelDelay.getAltDelayedArrivalDelayedFlightDate() : this.travelDelay.getDelayedArrivalDelayedFlightDate() ]
        });

        this.flightStatus = this.travelDelay.getFlightStatus();
    }

    ngAfterViewInit() {

        this.detailOfTravelFlightStatsFormGroup.valueChanges.subscribe(data => {
            this.travelDelay.setFlightNumber(super.getValueInComponent('flightNumber'));
            this.travelDelay.setFlightNumberDate(super.getValueInComponent('flightNumberDate'));
            this.travelDelay.setFlightNumberFoundFlag(false);
            this.travelDelay.setFlightNumberSearchedFlag(null);
            this.travelDelay.setIsMultiLegFlight(null);
            this.travelDelay.setMultiLegFlightSelectedIndex(null);
            this.travelDelay.setHasSelectedLeg(null);
        });

        this.detailOfAltTravelFlightStatsFormGroup.valueChanges.subscribe(data => {
            this.travelDelay.setAltFlightNumber(super.getValueInComponent('altFlightNumber'));
            this.travelDelay.setAltFlightNumberDate(super.getValueInComponent('altFlightNumberDate'));
            this.travelDelay.setFlightNumberFoundFlag(false);
            this.travelDelay.setFlightNumberSearchedFlag(null);
            this.travelDelay.setIsMultiLegFlight(null);
            this.travelDelay.setMultiLegFlightSelectedIndex(null);
            this.travelDelay.setHasSelectedLeg(null);
        });

        this.detailOfTravelFormGroup.valueChanges.subscribe(data => {
            if(this.travelDelay.getIsAltManualInputFlag() && this.travelDelay.getIsOriginalFlightCancelled()) {
                this.travelDelay.setAltDelayedDepartureOrArrival(super.getValueInComponent('delayedDepartureOrArrival'));
            } else {
                this.travelDelay.setDelayedDepartureOrArrival(super.getValueInComponent('delayedDepartureOrArrival'));
            }

            this.arrivalFormGroup.reset();
            this.departureFormGroup.reset();
            this.resetAndEnableFormGroup();

            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);
        });

        this.departureFormGroup.valueChanges.subscribe(data => {
            if(this.travelDelay.getIsAltManualInputFlag() && this.travelDelay.getIsOriginalFlightCancelled()) {
                this.travelDelay.setAltDelayedDepartureCountry(this.departureFormGroup.get('delayedDepartureCountry').value);
                this.travelDelay.setAltDelayedDepartureCountryDesc(this.getTextInComponent('delayedDepartureCountry'));
                this.travelDelay.setAltOriginalFlightDate(super.getValueInComponent('delayedDepartureOriginDate'));
                this.travelDelay.setAltDelayedFlightDate(super.getValueInComponent('delayedDepartureActualDate'));
            } else if (!this.travelDelay.getIsAltManualInputFlag() && !this.travelDelay.getIsOriginalFlightCancelled()) {
                this.travelDelay.setDelayedDepartureCountry(this.departureFormGroup.get('delayedDepartureCountry').value);
                this.travelDelay.setDelayedDepartureCountryDesc(this.getTextInComponent('delayedDepartureCountry'));
                this.travelDelay.setOriginalFlightDate(super.getValueInComponent('delayedDepartureOriginDate'));
                this.travelDelay.setDelayedFlightDate(super.getValueInComponent('delayedDepartureActualDate'));
            }

            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);
        });



        this.arrivalFormGroup.valueChanges.subscribe(data => {
            if(this.travelDelay.getIsAltManualInputFlag() && this.travelDelay.getIsOriginalFlightCancelled()) {
                this.travelDelay.setAltDelayedArrivalCountry(this.arrivalFormGroup.get('delayedArrivalCountry').value);
                this.travelDelay.setAltDelayedArrivalCountryDesc(this.getTextInComponent('delayedArrivalCountry'));
                this.travelDelay.setAltDelayedArrivalOriginalFlightDate(super.getValueInComponent('delayedArrivalOriginDate'));
                this.travelDelay.setAltDelayedArrivalDelayedFlightDate(super.getValueInComponent('delayedArrivalActualDate'));
            } else if (!this.travelDelay.getIsAltManualInputFlag() && !this.travelDelay.getIsOriginalFlightCancelled()) {
                this.travelDelay.setDelayedArrivalCountry(this.arrivalFormGroup.get('delayedArrivalCountry').value);
                this.travelDelay.setDelayedArrivalCountryDesc(this.getTextInComponent('delayedArrivalCountry'));
                this.travelDelay.setDelayedArrivalOriginalFlightDate(super.getValueInComponent('delayedArrivalOriginDate'));
                this.travelDelay.setDelayedArrivalDelayedFlightDate(super.getValueInComponent('delayedArrivalActualDate'));
            }

            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);
        });

        this.validateClaimDetailsComplete();
    }

    resetAndEnableFormGroup() {
        if (!this.travelDelay.getManualInputFlag() && !this.travelDelay.getIsOriginalFlightCancelled()) {
            this.detailOfTravelFlightStatsFormGroup.enable();
            this.arrivalFormGroup.disable();
            this.departureFormGroup.disable();
            this.detailOfAltTravelFlightStatsFormGroup.disable();
        } else if (!this.travelDelay.getIsAltManualInputFlag() && this.travelDelay.getIsOriginalFlightCancelled()) {
            //this.detailOfTravelFlightStatsFormGroup.disable(); // can be enabled as we need the value for  orig flight number
            this.arrivalFormGroup.disable();
            this.departureFormGroup.disable();
            this.detailOfAltTravelFlightStatsFormGroup.enable();
        } else {
            if(this.claim.getCountry().toLowerCase() !== 'hgi'){
                this.departureFormGroup.enable();
                this.arrivalFormGroup.disable();
                this.resetEnableTravelFlightStatsFG();    //this.detailOfTravelFlightStatsFormGroup.disable();
                this.detailOfAltTravelFlightStatsFormGroup.disable();
            } else if(this.claim.getCountry().toLowerCase() == 'hgi'){
                if(this.travelDelay.getDelayedDepartureOrArrival() == 'delayedDeparture' || (this.travelDelay.getIsOriginalFlightCancelled() &&
                    this.travelDelay.getAltDelayedDepartureOrArrival() == 'delayedDeparture')) {
                    // console.log('hgi delayedDeparture');
                    this.departureFormGroup.enable();
                    this.arrivalFormGroup.disable();
                    this.resetEnableTravelFlightStatsFG();    //this.detailOfTravelFlightStatsFormGroup.disable();
                    this.detailOfAltTravelFlightStatsFormGroup.disable();
                } else if (this.travelDelay.getDelayedDepartureOrArrival() == 'delayedArrival' || (this.travelDelay.getIsOriginalFlightCancelled() &&
                    this.travelDelay.getAltDelayedDepartureOrArrival() == 'delayedArrival')) {
                    //console.log('hgi delayedArrival');
                    this.arrivalFormGroup.enable();
                    this.departureFormGroup.disable();
                    this.resetEnableTravelFlightStatsFG(); // this.detailOfTravelFlightStatsFormGroup.disable();
                    this.detailOfAltTravelFlightStatsFormGroup.disable();
                }
            }
        }
    }

    resetEnableTravelFlightStatsFG() {
        if (!this.travelDelay.getIsOriginalFlightCancelled()) {
            this.detailOfTravelFlightStatsFormGroup.disable();
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {

        if (this.validateForm()) {
            let showWarningModal = this.checkFlightDelayWarning();
            //show modal
            if(showWarningModal){
                this.showNotCoveredPopUp();
            }else{
                this.emitCompleteNavigateToNextPage()
            }
        }
    }

    search() {

        if (this.validateSearchedFlightStatsFields()) {
            let flightNumber = this.travelDelay.getIsAltFlightInputFlag() == true ? this.travelDelay.getAltFlightNumber() : this.travelDelay.getFlightNumber();
            let flightNumberDate = this.travelDelay.getIsAltFlightInputFlag() == true ? this.travelDelay.getAltFlightNumberDate() : this.travelDelay.getFlightNumberDate();

            let integrationToken : string = this.transactionService.getTransactionInfo().getIntegrationToken();
            this.travelDelay.setManualInputFlag(false);
            this.travelDelay.setIsAltManualInputFlag(false);
            this.travelDelay.setHasValidFlightDelay(false);

            if ((this.travelDelay.getFlightNumber() && this.travelDelay.getFlightNumber().toLowerCase() === 'test') || 
                (this.travelDelay.getAltFlightNumber() && this.travelDelay.getAltFlightNumber().toLowerCase() === 'test')) {
                    this.travelDelay.setFlightNumberFoundFlag(false);
                    this.travelDelay.setFlightNumberSearchedFlag(true);
                    console.log("skip flight api for input 'test'");
                    return;
            }

            console.log("calling getFlightStatusByFlightDepartedOnDate with "+ flightNumberDate + " and " + flightNumber);
            this.flightStatusService.getFlightStatusByFlightDepartedOnDate(flightNumber, flightNumberDate, integrationToken)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (res: any) => {
                        //Success
                        this.travelDelay.setFlightStatus(res);
                        this.flightStatus = this.travelDelay.getFlightStatus();
                        this.flightStatusOperationalTimesList = this.flightStatus.getFlightStatusOperationalTimesList();

                        if(this.flightStatus.getStatus() != null){

                            if(this.flightStatus.getStatus() == '200'){
                                console.log("SUCCESS");

                                if(this.flightStatusOperationalTimesList.length != 0){
                                    if(this.flightStatusOperationalTimesList.length == 1){
                                        this.travelDelay.setIsMultiLegFlight(false);
                                        this.setUpSingleLegFlightDetails(this.flightStatusOperationalTimesList[0]);
                                    } else if(this.flightStatusOperationalTimesList.length > 1){
                                        this.travelDelay.setIsMultiLegFlight(true);
                                        this.travelDelay.setFlightNumberFoundFlag(true);
                                        this.travelDelay.setFlightNumberSearchedFlag(true);
                                        this.setDepartureOrArrival();
                                    }
                                }
                            } else {
                                this.travelDelay.setFlightNumberFoundFlag(false);
                                this.travelDelay.setFlightNumberSearchedFlag(true);
                            }
                        } else {
                            this.travelDelay.setFlightNumberFoundFlag(false);
                            this.travelDelay.setFlightNumberSearchedFlag(true);
                        }
                    },
                    error: () => {
                        this.travelDelay.setFlightNumberFoundFlag(false);
                        this.travelDelay.setFlightNumberSearchedFlag(true);
                    }
                });
        }
    }


    setUpSingleLegFlightDetails(flightStatusOperationalTimesList: FlightStatusOperationalTimes) {
        let status = flightStatusOperationalTimesList.getStatus();
        if (!this.travelDelay.getIsOriginalFlightCancelled()) {
            this.buildTravelDelayValues(this.flightStatusOperationalTimesList[0], true);
            if (!UtilitiesService.isNullOrUndefined(status) && status === "C") {
                this.travelDelay.setFlightNumberFoundFlag(true);
                this.travelDelay.setFlightNumberSearchedFlag(true);
                this.travelDelay.setIsOriginalFlightCancelled(true);
                this.openFlightCancelledModal();
            } else {
                let country = this.claim.getCountry();
                if((country !== "HGI" && this.flightStatusOperationalTimesList[0].getIsDepartureDelayHoursNegative()) || (country === "HGI"
                    && this.flightStatusOperationalTimesList[0].getIsArrivalDelayHoursNegative() && this.flightStatusOperationalTimesList[0].getIsDepartureDelayHoursNegative())) {
                    this.travelDelay.setFlightNumberFoundFlag(true);
                    this.travelDelay.setFlightNumberSearchedFlag(true);
                    this.travelDelay.setIsOriginalFlightCancelled(false);
                    this.openFlightNumberNotDelayedSingleLegModal();
                } else {
                    this.travelDelay.setFlightNumberFoundFlag(true);
                    this.travelDelay.setFlightNumberSearchedFlag(true);
                    this.travelDelay.setHasValidFlightDelay(true);
                    this.travelDelay.setIsOriginalFlightCancelled(false);
                }
            }
        } else {
            // FOR ALTERNATIVE FLIGHT
            if (!UtilitiesService.isNullOrUndefined(status) && status === "C") {
                this.travelDelay.setFlightNumberFoundFlag(true);
                this.travelDelay.setFlightNumberSearchedFlag(true);
                this.openFlightCancelledModal();
            } else {
                this.travelDelay.setFlightNumberFoundFlag(true);
                this.travelDelay.setFlightNumberSearchedFlag(true);
                this.travelDelay.setHasValidFlightDelay(true);
                this.buildTravelDelayValues(this.flightStatusOperationalTimesList[0], false);
            }

        }
    }

    buildTravelDelayValues(flightStatusOperationalTimes: FlightStatusOperationalTimes, isOriginalFlight: boolean){

        if(this.claim.getCountry()?.toLowerCase() !== 'hgi'){
            if (isOriginalFlight) {
                this.travelDelay.setDelayedDepartureOrArrival('delayedDeparture');
                this.travelDelay.setDelayedDepartureCountry(flightStatusOperationalTimes.getDepartureCountryCode());
                this.travelDelay.setDelayedDepartureCountryDesc(flightStatusOperationalTimes.getDepartureCountryName());
                this.travelDelay.setOriginalFlightDate(flightStatusOperationalTimes.getPublishedDepartureDate());
                if (!UtilitiesService.isNullOrUndefined(flightStatusOperationalTimes.getActualDepartureDate())) {
                    this.travelDelay.setDelayedFlightDate(flightStatusOperationalTimes.getActualDepartureDate());
                }

            } else {
                this.travelDelay.setAltDelayedDepartureOrArrival('delayedDeparture');
                this.travelDelay.setAltDelayedDepartureCountry(flightStatusOperationalTimes.getDepartureCountryCode());
                this.travelDelay.setAltDelayedDepartureCountryDesc(flightStatusOperationalTimes.getDepartureCountryName());
                this.travelDelay.setAltOriginalFlightDate(flightStatusOperationalTimes.getPublishedDepartureDate());
                this.travelDelay.setAltDelayedFlightDate(flightStatusOperationalTimes.getActualDepartureDate());
            }

        } else {
            if(flightStatusOperationalTimes.getDepartureDelayHours() >= flightStatusOperationalTimes.getArrivalDelayHours()){
                //console.log('buildTravelDelayValues HGI -delayedDeparture');
                if (isOriginalFlight) {
                    this.travelDelay.setDelayedDepartureOrArrival('delayedDeparture');
                    this.travelDelay.setDelayedDepartureCountry(flightStatusOperationalTimes.getDepartureCountryCode());
                    this.travelDelay.setDelayedDepartureCountryDesc(flightStatusOperationalTimes.getDepartureCountryName());
                    this.travelDelay.setOriginalFlightDate(flightStatusOperationalTimes.getPublishedDepartureDate());
                    if (!UtilitiesService.isNullOrUndefined(flightStatusOperationalTimes.getActualDepartureDate())) {
                        this.travelDelay.setDelayedFlightDate(flightStatusOperationalTimes.getActualDepartureDate());
                    }
                } else {
                    this.travelDelay.setAltDelayedDepartureOrArrival('delayedDeparture');
                    this.travelDelay.setAltDelayedDepartureCountry(flightStatusOperationalTimes.getDepartureCountryCode());
                    this.travelDelay.setAltDelayedDepartureCountryDesc(flightStatusOperationalTimes.getDepartureCountryName());
                    this.travelDelay.setAltOriginalFlightDate(flightStatusOperationalTimes.getPublishedDepartureDate());
                    this.travelDelay.setAltDelayedFlightDate(flightStatusOperationalTimes.getActualDepartureDate());
                }
            } else if(flightStatusOperationalTimes.getDepartureDelayHours() < flightStatusOperationalTimes.getArrivalDelayHours()){
                //console.log('buildTravelDelayValues HGI - delayedArrival');
                if (isOriginalFlight) {
                    this.travelDelay.setDelayedDepartureOrArrival('delayedArrival');
                    this.travelDelay.setDelayedArrivalCountry(flightStatusOperationalTimes.getArrivalCountryCode());
                    this.travelDelay.setDelayedArrivalCountryDesc(flightStatusOperationalTimes.getArrivalCountryName());
                    this.travelDelay.setDelayedArrivalOriginalFlightDate(flightStatusOperationalTimes.getPublishedArrivalDate());
                    if (!UtilitiesService.isNullOrUndefined(flightStatusOperationalTimes.getActualArrivalDate())) {
                        this.travelDelay.setDelayedArrivalDelayedFlightDate(flightStatusOperationalTimes.getActualArrivalDate());
                    }
                } else {
                    this.travelDelay.setAltDelayedDepartureOrArrival('delayedArrival');
                    this.travelDelay.setAltDelayedArrivalCountry(flightStatusOperationalTimes.getArrivalCountryCode());
                    this.travelDelay.setAltDelayedArrivalCountryDesc(flightStatusOperationalTimes.getArrivalCountryName());
                    this.travelDelay.setAltDelayedArrivalOriginalFlightDate(flightStatusOperationalTimes.getPublishedArrivalDate());
                    this.travelDelay.setAltDelayedArrivalDelayedFlightDate(flightStatusOperationalTimes.getActualArrivalDate());
                }

            }
        }

    }

    //for multi leg flight - checking if to be displayed for arrival or departure
    setDepartureOrArrival(){
        for(let x = 0; x < this.flightStatusOperationalTimesList.length; x++){
            if(this.claim.getCountry()?.toLowerCase() !== 'hgi'){
                this.flightStatusOperationalTimesList[x].setDepartureOrArrival('delayedDeparture');
            }else {
                if(this.flightStatusOperationalTimesList[x].getDepartureDelayHours() >= this.flightStatusOperationalTimesList[x].getArrivalDelayHours()){
                    //console.log('setDepartureOrArrival : delayedDeparture');
                    this.flightStatusOperationalTimesList[x].setDepartureOrArrival('delayedDeparture');

                } else if(this.flightStatusOperationalTimesList[x].getDepartureDelayHours() < this.flightStatusOperationalTimesList[x].getArrivalDelayHours()){
                    //console.log('setDepartureOrArrival: delayedArrival');
                    this.flightStatusOperationalTimesList[x].setDepartureOrArrival('delayedArrival');
                }
            }
        }
    }

    buildMultilegTravelDelayValues(index: number, flightStatusOperationalTimes: FlightStatusOperationalTimes){
        this.travelDelay.setHasSelectedLeg(true);
        this.travelDelay.setMultiLegFlightSelectedIndex(index);
        var multilegRadioButton = "multi-leg-radio-"+index;
        $("#"+multilegRadioButton).prop("checked", true);
        let isOriginalFlight = this.travelDelay.getIsOriginalFlightCancelled() ? false : true;
        this.buildTravelDelayValues(flightStatusOperationalTimes, isOriginalFlight);
        this.travelDelay.setFlightNumberFoundFlag(true);
        this.travelDelay.setFlightNumberSearchedFlag(true);
    }

    showManualInputFields(){

        this.travelDelay.setManualInputFlag(true);
        // original flight is cancelled, then alternative flight not found in api
        if (this.travelDelay.getIsOriginalFlightCancelled() && this.travelDelay.getIsAltFlightInputFlag()) {
            this.travelDelay.setIsAltManualInputFlag(true);
            this.travelDelay.setIsAltFlightInputFlag(false);
            this.travelDelay.setAltDelayedDepartureOrArrival("delayedDeparture");
            this.travelDelay.setManualInputFlag(false);
        } else {
            this.travelDelay.setIsAltManualInputFlag(false);
            this.travelDelay.setIsAltFlightInputFlag(false);
            this.travelDelay.setDelayedDepartureOrArrival("delayedDeparture");
        }

        this.departureFormGroup.reset();
        this.arrivalFormGroup.reset();
        this.resetAndEnableFormGroup();
    }

    showFlighStatsInputFields(){
        this.travelDelay.setManualInputFlag(false);
        this.travelDelay.setFlightNumberFoundFlag(false);
        this.travelDelay.setFlightNumberSearchedFlag(null);
        this.travelDelay.setIsMultiLegFlight(null);
        this.travelDelay.setMultiLegFlightSelectedIndex(null);

        this.detailOfAltTravelFlightStatsFormGroup.reset();
        this.travelDelay.setHasValidFlightDelay(false);
        //$("#flt-stats-err-msg").removeClass("err-style");

        if (this.travelDelay.getIsOriginalFlightCancelled()) {
            this.travelDelay.setIsAltFlightInputFlag(true);
            this.travelDelay.setIsAltManualInputFlag(false);
            this.detailOfAltTravelFlightStatsFormGroup.reset();
        } else {
            this.detailOfTravelFlightStatsFormGroup.reset();

        }

        this.resetAndEnableFormGroup();
    }

    showManualInputFieldsSingleLegWithModalOpen(){

        this.travelDelay.setManualInputFlag(true);
        if (this.travelDelay.getIsOriginalFlightCancelled() && this.travelDelay.getIsAltFlightInputFlag()) {
            this.travelDelay.setIsAltManualInputFlag(true);
            this.travelDelay.setIsAltFlightInputFlag(false);
            this.travelDelay.setAltDelayedDepartureOrArrival("delayedDeparture");
            this.travelDelay.setManualInputFlag(false);
            this.detailOfAltTravelFlightStatsFormGroup.reset();
        } else {
            this.travelDelay.setIsAltManualInputFlag(false);
            this.travelDelay.setIsAltFlightInputFlag(false);
            this.detailOfTravelFlightStatsFormGroup.reset();

        }
        this.travelNotDelayedSingleLegModalRef.close();
        this.departureFormGroup.reset();
        this.arrivalFormGroup.reset();
        this.resetAndEnableFormGroup();
    }

    showFlighStatsInputFieldsSingleLegWithModalOpen(){

        this.travelDelay.setManualInputFlag(false);
        this.travelDelay.setFlightNumberFoundFlag(false);
        this.travelDelay.setFlightNumberSearchedFlag(null);
        this.travelDelay.setIsMultiLegFlight(null);
        this.travelDelay.setMultiLegFlightSelectedIndex(null);
        this.travelDelay.setHasValidFlightDelay(false);
        ////this.detailOfTravelFlightStatsFormGroup.reset();
        this.travelNotDelayedSingleLegModalRef.close();
        //$("#flt-stats-err-msg").removeClass("err-style");

        if (this.travelDelay.getIsOriginalFlightCancelled()) {
            this.detailOfAltTravelFlightStatsFormGroup.reset();
            //// this.travelDelay.setIsAltFlightInputFlag(false);
            this.travelDelay.setIsAltManualInputFlag(false);
        } else {
            this.detailOfTravelFlightStatsFormGroup.reset();
        }
        this.resetAndEnableFormGroup();
    }

    showManualInputFieldsMultiLegWithModalOpen(){

        this.travelDelay.setManualInputFlag(true);
        if (this.travelDelay.getIsOriginalFlightCancelled() && this.travelDelay.getIsAltFlightInputFlag()) {
            this.travelDelay.setIsAltManualInputFlag(true);
            this.travelDelay.setIsAltFlightInputFlag(false);
            this.travelDelay.setAltDelayedDepartureOrArrival("delayedDeparture");
            this.travelDelay.setManualInputFlag(false);
            this.detailOfAltTravelFlightStatsFormGroup.reset();
        } else {
            this.travelDelay.setIsAltManualInputFlag(false);
            this.travelDelay.setIsAltFlightInputFlag(false);
            this.detailOfTravelFlightStatsFormGroup.reset();
        }
        this.travelNotDelayedMultiLegModalRef.close();
        this.departureFormGroup.reset();
        this.arrivalFormGroup.reset();
        this.resetAndEnableFormGroup();


    }

    showFlighStatsInputFieldsMultiLegWithModalOpen(){

        this.travelDelay.setManualInputFlag(false);
        this.travelDelay.setFlightNumberFoundFlag(false);
        this.travelDelay.setFlightNumberSearchedFlag(null);
        this.travelDelay.setIsMultiLegFlight(null);
        this.travelDelay.setMultiLegFlightSelectedIndex(null);
        this.detailOfTravelFlightStatsFormGroup.reset();
        this.travelNotDelayedMultiLegModalRef.close();
        //$("#flt-stats-err-msg").removeClass("err-style");
        this.resetAndEnableFormGroup();
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd MMM yyyy");
        }
        else {
            return "";
        }
    }

    getTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "HH:mm");
        }
        else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd MMM yyyy HH:mm");
        }
        else {
            return "";
        }
    }

    validateForm(): boolean {

        let formToValidate : FormGroup;

        if(this.travelDelay.getManualInputFlag()){
            if (this.travelDelay.getDelayedDepartureOrArrival() === 'delayedArrival') {
                formToValidate = this.arrivalFormGroup;
            }
            else {
                formToValidate = this.departureFormGroup;
            }
        } else if (this.travelDelay.getIsAltManualInputFlag()) {
            if (this.travelDelay.getAltDelayedDepartureOrArrival() === 'delayedArrival') {
                formToValidate = this.arrivalFormGroup;
            }
            else {
                formToValidate = this.departureFormGroup;
            }
        }  else {

            formToValidate = this.detailOfTravelFlightStatsFormGroup;

            if (this.travelDelay.getIsAltFlightInputFlag() && this.travelDelay.getIsOriginalFlightCancelled()) {
                formToValidate = this.detailOfAltTravelFlightStatsFormGroup;
            }

            if(this.travelDelay.getFlightNumberSearchedFlag() == null){
                this.travelDelay.setFlightNumberSearchedFlag(false);
                return false;
            }else if(!this.travelDelay.getFlightNumberSearchedFlag()){
                this.travelDelay.setFlightNumberSearchedFlag(false);
                return false;
            }else if(!this.travelDelay.getFlightNumberFoundFlag()){
                this.travelDelay.setFlightNumberSearchedFlag(false);
                return false;
            }else if(this.travelDelay.getIsMultiLegFlight()){
                var selectedIndex = this.travelDelay.getMultiLegFlightSelectedIndex();
                if(this.travelDelay.getMultiLegFlightSelectedIndex() == null){
                    this.travelDelay.setHasSelectedLeg(false);
                    return false;
                } else if(this.flightStatusOperationalTimesList[selectedIndex].getDepartureOrArrival() == 'delayedDeparture' && this.flightStatusOperationalTimesList[selectedIndex].getIsDepartureDelayHoursNegative()) {
                    if (this.travelDelay.getIsOriginalFlightCancelled()) {
                        return true;
                    }
                    this.openFlightNumberNotDelayedMultiLegModal();
                    return false;
                } else if(this.flightStatusOperationalTimesList[selectedIndex].getDepartureOrArrival() == 'delayedArrival' && this.flightStatusOperationalTimesList[selectedIndex].getIsArrivalDelayHoursNegative()) {
                    if (this.travelDelay.getIsOriginalFlightCancelled()) {
                        return true;
                    }
                    this.openFlightNumberNotDelayedMultiLegModal();
                    return false;
                } else if (this.flightStatusOperationalTimesList[selectedIndex].getStatus() == 'C') {
                    // flight is cancelled
                    this.travelDelay.setIsOriginalFlightCancelled(true);
                    this.openFlightCancelledModal();
                    return false;
                }
            }
        }

        return super.validateForm(formToValidate);
    }

    validateSearchedFlightStatsFields(): boolean{
        if(!this.travelDelay.getFlightNumberSearchedFlag() || this.travelDelay.getFlightNumberSearchedFlag() == null){
            if(this.travelDelay.getIsAltFlightInputFlag() && this.travelDelay.getIsOriginalFlightCancelled()){
                return super.validateForm(this.detailOfAltTravelFlightStatsFormGroup);
            }
            return super.validateForm(this.detailOfTravelFlightStatsFormGroup);
        } else {
            return false;
        }
    }

    validateClaimDetailsComplete() {

        let formToValidate : FormGroup;
        if (this.travelDelay.getDelayedDepartureOrArrival() === 'delayedArrival') {
            formToValidate = this.arrivalFormGroup;
        }
        else {
            formToValidate = this.departureFormGroup;
        }

        let isFormValidOnChange: boolean = super.validateFormOnChange(formToValidate);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 0, subMenuIndex: 0});
        }
    }

    openFlightNumberNotDelayedSingleLegModal() {
        this.travelNotDelayedSingleLegModalRef = this.modalService.open(this.travelNotDelayedSingleLegModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    openFlightNumberNotDelayedMultiLegModal() {
        this.travelNotDelayedMultiLegModalRef = this.modalService.open(this.travelNotDelayedMultiLegModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    openFlightCancelledModal() {
        this.flightCancelledModalRef = this.modalService.open(this.flightCancelledModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });
    }

    deleteClaimType() {

        if(this.travelDelay.getIsMultiLegFlight()){
            this.travelNotDelayedMultiLegModalRef.close();
        } else{
            this.travelNotDelayedSingleLegModalRef.close();
        }

        //navigate to proper screen
        let selectedClaimTypeList: string[] = this.claim.getSelectedClaimTypesList();
        if(selectedClaimTypeList.length == 1){
            this.showClaimDetail = true;
            this.router.navigate(["/claimform/claimDetail"], {
                relativeTo: this.activatedRoute
            });
        } else if (selectedClaimTypeList.length > 1) {
            this.showClaimDetail = false;
            this.router.navigate(["/claimform/moreClaimType"], {
                relativeTo: this.activatedRoute
            });
        }

        this.removeTravelDelayClaimType(selectedClaimTypeList);

    }

    removeTravelDelayClaimType(selectedClaimTypeList: string[]) {
        if (selectedClaimTypeList.indexOf('CLAIM_TYPE_TDEL') !== -1) {
            this.claimTypeItemHelperService.deleteClaimType(0);
        }
    }

    goToClaimTripCancellation() {

        let selectedClaimTypeList = this.claim.getSelectedClaimTypesList();
        let transactionInfo = this.transactionService.getTransactionInfo();
        this.removeTravelDelayClaimType(selectedClaimTypeList);

        let choosedIndex = 0;
        if (selectedClaimTypeList.indexOf('CLAIM_TYPE_CAN') == -1) {
            selectedClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]);

            let claimTypeData = transactionInfo.getClaimTypeItemList();
            for (var i = 0; i < claimTypeData.length; i++) {
                if (claimTypeData[i]['claimTypeCode'] === ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]) {
                    claimTypeData[i]['selected'] = true;
                    choosedIndex = claimTypeData[i]['claimTypeId'];
                    break;
                }
            }
            transactionInfo.setClaimTypeItemList(claimTypeData);
            this.documentHelperService.addDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]);
        }

        this.sideMenuService.emitCliamType(choosedIndex);
        this.sideMenuService.emitComplete('finishDetail');
        this.sideMenuService.emitProcess(0, 4);

        //navigate to trip cancellation claim details
        let claimTypeDetailMenu = transactionInfo.getNavigationMenu().getClaimDetailMenu();
        let nextLinkUrl = claimTypeDetailMenu[choosedIndex]['subMenus'][0]['url'];
        console.log("goToClaimTripCancellation : nextLinkUrl" + nextLinkUrl);
        this.router.navigate([nextLinkUrl]);

        this.flightCancelledModalRef.close();

    }

    enterAltFlightDetails() {
        this.travelDelay.setIsAltFlightInputFlag(true);
        this.travelDelay.setIsAltManualInputFlag(false);
        this.travelDelay.setManualInputFlag(false);
        this.travelDelay.setFlightNumberSearchedFlag(null);
        this.travelDelay.setIsOriginalFlightCancelled(true);
        this.flightCancelledModalRef.close();
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //START - Google Analytics
    pushGATravelDetailsView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Travel Delay - Details',
            'vPath': '/claim/travel-delay/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
    //END - Google Analytics

    checkFlightDelayWarning(){
        let country = this.claim.getCountry();
        let isNotCoverModalShow : boolean = false;

        let isAnnual = (country === 'hgi' && (this.claim.getClaimTravel().getPeriodOfInsurance() === '0')) ||
            (country === 'hkg' &&  ['PTRMAT', 'PTRQAT'].indexOf(this.claim.getCatalogueCode()) !== -1);

       for(let travelPlanDelayedHrsList of this.planInfoService.getTravelPlanDelayedHrsList(country)){
            if(travelPlanDelayedHrsList.planCode.indexOf(this.claim.getPlanCode()) !== -1 &&
                travelPlanDelayedHrsList.isAnnual === isAnnual){
                this.allowedDelayHours = travelPlanDelayedHrsList.allowedDelayedHours;
                break;
            }
        }

        let originalFlightDate: Date;
        let delayedFlightDate: Date;

        if (this.travelDelay.getIsOriginalFlightCancelled()) {
           // console.log("checkFlightDelayWarning -OriginalFlightCancelled ");
            if (this.travelDelay.getAltDelayedDepartureOrArrival() === 'delayedArrival') {
                originalFlightDate = this.travelDelay.getDelayedArrivalOriginalFlightDate();
                delayedFlightDate =  this.travelDelay.getAltDelayedArrivalDelayedFlightDate();
              //  console.log("checkFlightDelayWarning -getAltDelayedDepartureOrArrival is delayedarrival ");
            } else  {
                originalFlightDate = this.travelDelay.getOriginalFlightDate();
                delayedFlightDate =  this.travelDelay.getAltDelayedFlightDate();
                //console.log("checkFlightDelayWarning -getAltDelayedDepartureOrArrival is delayeddeparture");
            }
        } else {
            if (this.travelDelay.getDelayedDepartureOrArrival() === 'delayedArrival') {
                originalFlightDate = this.travelDelay.getDelayedArrivalOriginalFlightDate();
                delayedFlightDate = this.travelDelay.getDelayedArrivalDelayedFlightDate();
                //console.log("checkFlightDelayWarning -DelayedDepartureOrArrival is delayedarrival");
            } else {
                originalFlightDate = this.travelDelay.getOriginalFlightDate();
                delayedFlightDate = this.travelDelay.getDelayedFlightDate();
                //console.log("checkFlightDelayWarning -DelayedDepartureOrArrival is delayeddeparture");
            }
        }

        originalFlightDate = new Date(originalFlightDate);
        delayedFlightDate = new Date (delayedFlightDate);

        this.hrsDelayed = UtilitiesService.getDateDiffInHours(originalFlightDate, delayedFlightDate);

        if(this.hrsDelayed < this.allowedDelayHours){
            isNotCoverModalShow = true;
        }

        return isNotCoverModalShow;

    }

    showNotCoveredPopUp() {
        this.notCoveredModalRef = this.modalService.open(this.notCoveredModal, {size: 'lg',
            backdrop: 'static',
            keyboard: false
        });

    }

    proceedAnywayBtn(){
        this.emitCompleteNavigateToNextPage();
        this.notCoveredModalRef.close();
    }

    cancelClaimBtn(modalReference : string){
        let transactionInfo = this.transactionService.getTransactionInfo();
        this.claimTypeItemHelperService.deleteClaimType(0);

        let navigationMenuModel = transactionInfo.getNavigationMenu();
        navigationMenuModel.setSelectClaimState("current");
        navigationMenuModel.setClaimDetailsState("true");

        this.router.navigate(['/claimform/claimDetail']);

        if (modalReference && modalReference == 'sorry_not_covered') {
            this.notCoveredModalRef.close();
        } else {
            this.deleteClaimTypeModalRef.close();
        }
    }

    cancelThisClaimBtn() {
        this.flightCancelledModalRef.close();

        this.deleteClaimTypeModalRef = this.modalService.open(this.deleteClaimTypeModal, {size: 'sm',
            backdrop: 'static',
            keyboard: false
        });
    }

    closeRemoveClaimBtn() {
        this.deleteClaimTypeModalRef.close();
        this.openFlightCancelledModal();
    }

    emitCompleteNavigateToNextPage(){
        this.sideMenuService.emitProcess(1, 0);
        this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 0});
        this.router.navigate(["/claimform/travelDelayed/reasonOfTravelDelay"], {
            relativeTo: this.activatedRoute
        });
    }
}