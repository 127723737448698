<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{'motorClaim.claimSection.motorAccidentCollission.accidentDetails.description' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
      <div class="col-xl-9 col-lg-12">
          <form>
              <div class="form-group">
                  <qnect-ui #MV_COLLISSION_INCIDENT_DATE [fieldId]="'MV_COLLISSION_INCIDENT_DATE'" [formGroup]="motorAccidentForm" name="accidentIncidentDate"></qnect-ui>
              </div>

                <div class="form-group">
                  <qnect-ui #MV_COLLISSION_COUNTRY_OCCURENCE [fieldId]="'MV_COLLISSION_COUNTRY_OCCURENCE'" [formGroup]="motorAccidentForm" name="countryOccurence"></qnect-ui>
                </div>
                <div class="form-group">
                  <qnect-ui #MV_COLLISSION_INCIDENT_OCCUR_MAP [fieldId]="'MV_COLLISSION_INCIDENT_OCCUR_MAP'" [formGroup]="motorAccidentForm" name="incidentAccidentOccur"></qnect-ui>
                </div>
                <div class="form-group">
                  <qnect-ui #MV_COLLISSION_WAS_VEHICLE_PARK_WHEN_DAMAGE [fieldId]="'MV_COLLISSION_WAS_VEHICLE_PARK_WHEN_DAMAGE'" [formGroup]="motorAccidentForm" name="wasVehicleParkWhenDamage"></qnect-ui>
                </div>
                <div class="form-group">
                  <qnect-ui #MV_COLLISSION_PURPOSE_OF_USE [fieldId]="'MV_COLLISSION_PURPOSE_OF_USE'" [formGroup]="motorAccidentForm" name="purposeOfUse"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #MV_COLLISSION_DESCRIPTION_OF_ACCIDENT [fieldId]="'MV_COLLISSION_DESCRIPTION_OF_ACCIDENT'" [formGroup]="motorAccidentForm" name="descriptionOfAccident"></qnect-ui>
                </div>
          </form>
      </div>
      <div class="col-xl-3 col-lg-12">
      </div>
    </div>
  
  <div class="submit-bar">
      <abandon-button></abandon-button>
      <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
      <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
  </div>