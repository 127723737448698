import {Component, Injector, OnInit} from '@angular/core';
import {ClaimTypes} from "../../../model/claim-type.model";
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {SideMenuService} from '../../../services/side-menu.service';
import {ClaimsBaseComponent} from '../../claim-base.component';

@Component({
  selector: 'app-travel-delay',
  templateUrl: './travel-delay.component.html',
  styleUrls: ['./travel-delay.component.css']
})
export class TravelDelayComponent extends ClaimsBaseComponent  implements OnInit {

  constructor(private injector : Injector) {
      super(injector);
      this.injector.get(TransactionInfoService).getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]);
  }

  ngOnInit() {
      // extra cost is not applicable to silver plan
      if (['BC','BD','BI','BJ','HC','HD','HI','HJ','11','12','13','14','15','16','17','18'].indexOf(this.claim.getPlanCode()) !== -1 &&
          ['HKG', 'HGI'].indexOf(this.claim.getCountry()) !== -1) {
          this.injector.get(SideMenuService).emitClaimTypeSubMenuHideShow({claimTypeId: 0, subMenuIndex: 2,
              value: ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL], show: false});
      }
  }

}
