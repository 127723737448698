export class TripCancellation {

    private originalBookDate: Date;
    private cancelDate: Date;
    private cancelTripReason: string;
    private cancelTripReasonDesc: string;
    private otherCancelTripReason: string;
    private hasRefund: string;
    private hasRefundDesc: string;
    private didUseMiles: boolean;
    private totalCostTypeOfTrip: string;
    private totalCost: string;
    private totalAmountRefundedType: string;
    private totalAmountRefunded: string;
    private totalAmountPaidCurrency: string;
    private totalAmountRefundedCurrency: string;
    private totalAmountPaidCost: string;
    private totalAmountRefundedCost: string;
    private countryOfLoss: string;
    private countryOfLossDesc: string;
    private estimatedAmount: number;
    private hasExtAccomCost: boolean;
    private extraAccommodationCost: string;
    private extraAccommodationCurrency: string;
    private hasExtTransCost: boolean;
    private extraTransportationCost: string;
    private extraTransportationCurrency: string;
    private countryOccurred: string[] = [];
    private countryOccurredDesc: string[] = [];


    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getCountryOfLossDesc(): string {
        return this.countryOfLossDesc;
    }

    setCountryOfLossDesc(value: string){
        this.countryOfLossDesc = value;
    }

    getCountryOfLoss(): string {
        return this.countryOfLoss;
    }

    setCountryOfLoss(value: string){
        this.countryOfLoss = value;
    }

    getTotalAmountRefundedCost(): string {
        return this.totalAmountRefundedCost;
    }

    setTotalAmountRefundedCost(value: string){
        this.totalAmountRefundedCost = value;
    }

    getTotalAmountPaidCost(): string {
        return this.totalAmountPaidCost;
    }

    setTotalAmountPaidCost(value: string){
        this.totalAmountPaidCost = value;
    }

    getTotalAmountRefundedCurrency(): string {
        return this.totalAmountRefundedCurrency;
    }

    setTotalAmountRefundedCurrency(value: string){
        this.totalAmountRefundedCurrency = value;
    }

    getTotalAmountPaidCurrency(): string {
        return this.totalAmountPaidCurrency;
    }

    setTotalAmountPaidCurrency(value: string){
        this.totalAmountPaidCurrency = value;
    }

    getTotalAmountRefunded(): string {
        return this.totalAmountRefunded;
    }

    setTotalAmountRefunded(value: string){
        this.totalAmountRefunded = value;
    }

    getTotalAmountRefundedType(): string {
        return this.totalAmountRefundedType;
    }

    setTotalAmountRefundedType(value: string){
        this.totalAmountRefundedType = value;
    }

    getTotalCost(): string {
        return this.totalCost;
    }

    setTotalCost(value: string){
        this.totalCost = value;
    }

    getTotalCostTypeOfTrip(): string {
        return this.totalCostTypeOfTrip;
    }

    setTotalCostTypeOfTrip(value: string){
        this.totalCostTypeOfTrip = value;
    }

    getDidUseMiles(): boolean {
        return this.didUseMiles;
    }

    setDidUseMiles(value: boolean){
        this.didUseMiles = value;
    }
    
    getHasRefund(): string{
        return this.hasRefund;
    }

    setHasRefund(value: string){
         this.hasRefund = value;
    }

    getHasRefundDesc(): string{
        return this.hasRefundDesc;
    }

    setHasRefundDesc(value: string){
         this.hasRefundDesc = value;
    }

    getOriginalBookDate(): Date {
        return this.originalBookDate;
    }

    setOriginalBookDate(value: Date){
        this.originalBookDate = value;
    }

    getCancelDate(): Date {
        return this.cancelDate;
    }

    setCancelDate(value: Date){
        this.cancelDate = value;
    }

    getCancelTripReason(): string {
        return this.cancelTripReason;
    }

    setCancelTripReason(value: string){
        this.cancelTripReason = value;
    }

    getCancelTripReasonDesc(): string {
        return this.cancelTripReasonDesc;
    }

    setCancelTripReasonDesc(value: string){
        this.cancelTripReasonDesc = value;
    }

    getOtherCancelTripReason(): string {
        return this.otherCancelTripReason;
    }

    setOtherCancelTripReason(value: string){
        this.otherCancelTripReason = value;
    }

    getHasExtTransCost(): boolean {
        return this.hasExtTransCost;
    }

    setHasExtTransCost(value: boolean) {
        this.hasExtTransCost = value;
    }

    getHasExtAccomCost(): boolean {
        return this.hasExtAccomCost;
    }

    setHasExtAccomCost(value: boolean) {
        this.hasExtAccomCost = value;
    }

    getExtraAccommodationCost(): string {
        return this.extraAccommodationCost;
    }

    setExtraAccommodationCost(value: string) {
        this.extraAccommodationCost = value;
    }

    getExtraTransportationCost(): string {
        return this.extraTransportationCost;
    }

    setExtraTransportationCost(value: string) {
        this.extraTransportationCost = value;
    }

    getExtraAccommodationCurrency(): string {
        return this.extraAccommodationCurrency;
    }

    setExtraAccommodationCurrency(value: string) {
        this.extraAccommodationCurrency = value;
    }

    getExtraTransportationCurrency(): string {
        return this.extraTransportationCurrency;
    }

    setExtraTransportationCurrency(value: string) {
        this.extraTransportationCurrency = value;
    }

    getCountryOccurred(): string[] {
        return this.countryOccurred;
    }

    setCountryOccurred(value: string[]) {
        this.countryOccurred = value;
    }

    getCountryOccurredDesc(): string[] {
        return this.countryOccurredDesc;
    }

    setCountryOccurredDesc(value: string[]) {
        this.countryOccurredDesc = value;
    }

    static jsonConvert(tripCancellation: TripCancellation): TripCancellation {

        if (tripCancellation.getOriginalBookDate() != null) {
            tripCancellation.setOriginalBookDate(new Date(tripCancellation.getOriginalBookDate()));
        }

        if (tripCancellation.getCancelDate() != null) {
            tripCancellation.setCancelDate(new Date(tripCancellation.getCancelDate()));
        }


        return tripCancellation;
    }


}
