import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DamagePersonalItem} from "../../../../model/travel/damage-personal-item.model";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-detail-of-damage',
  templateUrl: './detail-of-damage.component.html',
  styleUrls: ['./detail-of-damage.component.css']
})
export class DetailOfDamageComponent extends ClaimsBaseComponent implements OnInit {

    @ViewChild('lossType', {static: true}) el: ElementRef;

    detailOfDamageForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    damagePersonalItem: DamagePersonalItem;
    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.damagePersonalItem = this.claim.getClaimTravel().getDamagePersonalItem();
            if(this.damagePersonalItem.getDateTheDamageOccurred() == null){
                this.damagePersonalItem.setDateTheDamageOccurred(this.claim.getDateOfLoss());
            }
    }

    ngOnInit() {

        this.pushGAViewDamageItemDetails();

        this.detailOfDamageForm = this.fb.group(
            {
                dateTheDamageOccurred: [this.damagePersonalItem.getDateTheDamageOccurred()],
                countryTheDamageOccurred: [this.damagePersonalItem.getCountryTheDamageOccurred()],
                placeOfLoss: [this.damagePersonalItem.getPlaceOfLoss()],
                howDamageOccurred: [this.damagePersonalItem.getHowDamageOccurred()],
                otherDamageOccurred: [this.damagePersonalItem.getOtherDamageOccurred()]
            }
        );
    }

    ngAfterViewInit() {

        this.detailOfDamageForm.valueChanges.subscribe(data => {
            this.damagePersonalItem.setDateTheDamageOccurred(super.getValueInComponent('dateTheDamageOccurred'));
            this.damagePersonalItem.setCountryTheDamageOccurred(super.getValueInComponent('countryTheDamageOccurred'));
            this.damagePersonalItem.setCountryTheDamageOccurredDesc(super.getTextInComponent('countryTheDamageOccurred'));
            this.damagePersonalItem.setPlaceOfLoss(super.getValueInComponent('placeOfLoss'));
            this.damagePersonalItem.setPlaceOfLossDesc(super.getTextInComponent('placeOfLoss'));
            this.damagePersonalItem.setHowDamageOccurred(super.getValueInComponent('howDamageOccurred'));
            this.damagePersonalItem.setHowDamageOccurredDesc(super.getTextInComponent('howDamageOccurred'));
            this.damagePersonalItem.setOtherDamageOccurred(super.getValueInComponent('otherDamageOccurred'));

            this.validateClaimDetailsComplete();

        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideOthersField(self.damagePersonalItem.getHowDamageOccurred()),10});
        this.detailOfDamageForm.get('howDamageOccurred').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOthersField(data);
        });

        //console.log("asdsada"+this.damagePersonalItem.getDateTheDamageOccurred());
    }

    hideOthersField(howDamageOccured) {

        if (howDamageOccured && howDamageOccured.toLowerCase() == 'others') {
            this.detailOfDamageForm.get('otherDamageOccurred').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailOfDamageForm.get('otherDamageOccurred').reset();
            this.detailOfDamageForm.get('otherDamageOccurred').disable({onlySelf: false, emitEvent: false});
        }
    }

    save(): boolean {
        // if (!form.valid) {
        //   return false;
        // }
        this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 0});
        this.sideMenuService.emitProcess(1, 0);
        return true;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 0});

            this.router.navigate(["/claimform/damagePersonalItem/detailsOfDamageItems"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfDamageForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfDamageForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 2, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewDamageItemDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Damage Item – Damage Details',
            'vPath': '/claim/damage-item/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
