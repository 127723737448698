import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {RentalVehicle} from "../../../../model/travel/rental-vehicle.model";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-detail-of-incident',
    templateUrl: './detail-of-incident.component.html',
    styleUrls: ['./detail-of-incident.component.css']
})
export class DetailOfIncidentComponent extends ClaimsBaseComponent implements OnInit {

    deTailOfRentalForm: FormGroup;
    showTotalBox = false;
    rentalVehicle: RentalVehicle;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.rentalVehicle = this.claim.getClaimTravel().getRentalVehicle();
        if(this.rentalVehicle.getDateOfIncident() == null){
            this.rentalVehicle.setDateOfIncident(this.claim.getDateOfLoss());
        }
    }


    ngOnInit() {

        this.pushGAViewRentVehicleDetails();
        if(this.rentalVehicle.getRentalExcessPaidCurrency() == null){
            this.rentalVehicle.setRentalExcessPaidCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.deTailOfRentalForm = this.fb.group(
            {
                dateOfIncident: [this.rentalVehicle.getDateOfIncident()],
                countryIncidentOccured: [this.rentalVehicle.getCountryIncidentOccured()],
                descOfIncident: [this.rentalVehicle.getDescOfIncident()],
                rentalExcessPaidCost: [this.rentalVehicle.getRentalExcessPaidCost()],
                rentalExcessPaidCurrency: [this.rentalVehicle.getRentalExcessPaidCurrency()],
            }
        );
    }


    ngAfterViewInit() {
        this.deTailOfRentalForm.valueChanges.subscribe(data => {
            this.rentalVehicle.setDateOfIncident(super.getValueInComponent('dateOfIncident'));
            this.rentalVehicle.setCountryIncidentOccured(this.deTailOfRentalForm.get('countryIncidentOccured').value);
            this.rentalVehicle.setCountryIncidentOccuredDesc(this.getTextInComponent('countryIncidentOccured'));
            this.rentalVehicle.setDescOfIncident(super.getTextInComponent('descOfIncident'));
            this.rentalVehicle.setRentalExcessPaidCost(this.deTailOfRentalForm.get('rentalExcessPaidCost').value);
            this.rentalVehicle.setRentalExcessPaidCurrency(this.deTailOfRentalForm.get('rentalExcessPaidCurrency').value);

            this.validateClaimDetailsComplete();
        })
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 9, subMenuIndex: 0});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/rentalVehicleExcess/vehicleSupportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfRentalForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfRentalForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 9, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 9, subMenuIndex: 0});
        }
    }


    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewRentVehicleDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Rental Vehicle Excess – Incident Details',
            'vPath': '/claim/rental-vehicle-excess/incident-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
