import {Component, OnInit} from "@angular/core";


import {UiBaseComponent} from "../ui-base.component";

@Component({
  selector: 'qnect-ui-radio',
  templateUrl: './ui-radio.component.html',
  styleUrls: ['./ui-radio.component.scss', '../ui-base.component.scss']
})
export class UiRadioComponent extends UiBaseComponent implements OnInit {

  selectedText: string;
  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setSelectedText();
    this.formControl.valueChanges.subscribe(data => {
      this.setSelectedText();
    });
  }

  clicked() {
  }

  setSelectedText() {
    this.uiModel.options.forEach(each => {
    if (this.formControl.value === each.value) {
        this.selectedText = each.label;
        // break loop
        return false;
      }
    });
  }
  getSelectedRadioText() {
    return this.selectedText;
  }
}
