
export class CoverDetails {

    private coverCode: string;
    private coverDescription: string;
    private sumInsured: number;

    getCoverCode(): string {
        return this.coverCode;
    }

    setCoverCode(value: string) {
        this.coverCode = value;
    }

    getCoverDescription(): string {
        return this.coverDescription;
    }

    setCoverDescription(value: string) {
        this.coverCode = value;
    }

    getSumInsured(): number {
        return this.sumInsured;
    }

    setSumInsured(value: number) {
        this.sumInsured = value;
    }

    static jsonConvert(coverDetails: CoverDetails): CoverDetails {

        coverDetails = Object.assign(new CoverDetails(), coverDetails);
        return coverDetails;
    }

}