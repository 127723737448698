<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && ((!formControl.valid && formControl.touched) )) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>

    <div class="col-md-{{uiModel.size}}">
        <div class="row">

            <div *ngIf="uiModel.currencyFieldName" class="col-md-4">
                <input formControlName="{{uiModel.currencyFieldName}}" type="text" class="form-control" id="{{uiModel.currencyFieldId}}"
                       placeholder="{{uiModel.placeholder | translate}}"
                       value="{{uiModel.currencyFieldStaticValue}}"
                       [ngClass]="{'ui-disabled' : true}" *ngIf="!uiModel.textOnly" maxlength="{{uiModel.maxlength}}"  autocomplete="off"
                       [readonly]="true"
                       spellcheck="true">
            </div>


            <div class="col-md-{{amountFieldSize}} col-xs-{{amountFieldSize}}">
                <div class="input-group" *ngIf="!uiModel.textOnly">
                    <input id="{{fieldId}}"
                           formControlName="{{uiModel.name}}"
                           type="text"
                           autocomplete="off"
                           class="form-control"
                           aria-label="Amount (to the nearest dollar)"
                           (keypress)="onKeyPress($event)"
                           maxlength="{{uiModel.maxlength}}"
                           #inputField
                           qnectCurrencyInputFormatter>
                </div>
            </div>

            <div class="col-md-{{getDivRemainder()}}">
            </div>
        </div>
        <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | translate}}</p>
        <ui-error *ngIf="(formControl.touched || uiModel.textOnly) " [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
    </div>
    <span *ngIf="uiModel.popover" style="margin-top:10px" class="{{uiModel.popover.icon}} ui-icon" role="button"
          [popover]="uiModel.popover.content" placement="right" triggers="mouseenter:mouseleave"></span>
</div>
