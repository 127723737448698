import {Component, OnInit} from "@angular/core";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimTypes } from "src/app/model/claim-type.model";
@Component({
    selector: 'app-generic',
    templateUrl: './generic.component.html',
    styleUrls: ['./generic.component.css']
})
export class GenericComponent implements OnInit {

    constructor(private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]);
    }

    ngOnInit() {
    }

}
