export class Others {
    private descIncident: string;
    private estAmountLoss: string;
    private estAmountLossCurrency: string;
    
    getDescIncident(): string {
        return this.descIncident;
    }

    setDescIncident(descIncident: string): void {
        this.descIncident = descIncident;
    }

    getEstAmountLoss(): string {
        return this.estAmountLoss;
    }

    setEstAmountLoss(estAmountLoss: string): void {
        this.estAmountLoss = estAmountLoss;
    }

    getEstAmountLossCurrency(): string {
        return this.estAmountLossCurrency;
    }

    setEstAmountLossCurrency(estAmountLossCurrency: string): void {
        this.estAmountLossCurrency = estAmountLossCurrency;
    }
    static jsonConvert(others: Others): Others {
        others = Object.assign(new Others(), others);
        return others;
    }
}