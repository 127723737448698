export class DomesticInfoPlan {

    planCode = '';
    catalogCode = '';
    clinicalExpenses = 'No';
    dentalExpenses = 'No';
    hospitalizationOfHelper = 'No';
    lossOfCashServiceAllowance = 'No';
    deathOrPermanentDisability = 'No';
    repatriation = 'No';
    claimQuestionReplacement = 'No';
    claimQuestionTemporary = 'No';
    helpersLiability = 'No';
    dishonestyOfHelperLoss = 'No';
    employeeCompensation = 'Yes';
    otherClaims = 'Yes';
    cancerHeartDiseaseExtensionApplicability = 'No';
    generalPractitioner = 'No';
    accidentEmergencyService = 'No';
    registeredChineseMedicinePractitioner = 'No';
    physiotherapy = 'No';
    followUpConsultation =  'No';
    nonMemberOutpatientConsultation = 'No';
    labTestExtraMedicationCoPayment = 'No';
}
