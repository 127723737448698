<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.deathAndSeriousBodyInjury.detailOfOverseaHospitalStay.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #PAT7_WAS_STAY_HOSPITAL_OVERSEA [fieldId]="'PAT7_WAS_STAY_HOSPITAL_OVERSEA'" [formGroup]="deTailOfOverseaHospitalForm" name="wasStayHospitalOversea"></qnect-ui>
        </div>
        <ng-container *ngIf="deathAndSeriousBodyInjuryLoss.getWasStayHospitalOversea() == true">
          <div>
           <qnect-ui class="periodType-font-weight" #PAT5_PERIOD_TIME [fieldId]="'PAT5_PERIOD_TIME'" [formGroup]="deTailOfOverseaHospitalForm" name="periodTime"></qnect-ui>
          </div>
          <div class="form-group">
           <qnect-ui #PAT6_PERIOD_TYPE [fieldId]="'PAT6_PERIOD_TYPE'" [formGroup]="deTailOfOverseaHospitalForm" name="periodType"></qnect-ui>
         </div>
          <div class="form-group">
            <qnect-ui #PAT8_WAS_STAY_IN_INTENSIVE_CARE [fieldId]="'PAT8_WAS_STAY_IN_INTENSIVE_CARE'" [formGroup]="deTailOfOverseaHospitalForm" name="wasStayInIntensiveCare"></qnect-ui>
          </div>
          <div class="form-group" *ngIf="deathAndSeriousBodyInjuryLoss.getWasStayInIntensiveCare() == false">
            <qnect-ui #PAT9_WARD_TYPE [fieldId]="'PAT9_WARD_TYPE'" [formGroup]="deTailOfOverseaHospitalForm" name="wardType"></qnect-ui>
          </div>
        </ng-container>
       </form>
     </div>
     <div class="col-xl-3 col-lg-12">
       &nbsp;
     </div>
   </div>
   <div class="submit-bar">
     <abandon-button></abandon-button>
     <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
     <button (click)="goToNext()" class="btn btn-primary new-btn float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i
             class="fas fa-arrow-right" ></i></button>
   </div>
   <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
 </div>