export class AccidentDetails {
    
    private date: Date;
    private time: Date;
    private dateTime: Date;
    private location: string;
    private country: string;
    private countryDesc: string;
    private speedAtTimeOfAccident: string;
    private speedAtTimeOfAccidentDesc: string;
    private otherSpeedAtTimeOfAccident: string;
    private purposeAtTimeOfAccident: string;
    private purposeAtTimeOfAccidentDesc: string;
    private otherPurposeAtTimeOfAccident: string;
    private accidentDescription: string;

    getDate(): Date {
        return this.date;
    }

    setDate(date: Date): void {
        this.date = date;
    }

    getTime(): Date{
        return this.time;
    }

    setTime(time: Date): void {
        this.time = time;
    }


    getDateTime(): Date {
        return this.dateTime;
    }

    setDateTime(dateTime: Date): void {
        this.dateTime = dateTime;
    }

    getLocation(): string {
        return this.location;
    }

    setLocation(location: string): void {
        this.location = location;
    }

    getCountry(): string {
        return this.country;
    }

    setCountry(country: string): void {
        this.country = country;
    }

    getCountryDesc(): string {
        return this.countryDesc;
    }

    setCountryDesc(countryDesc: string): void {
        this.countryDesc = countryDesc;
    }

    getSpeedAtTimeOfAccident(): string {
        return this.speedAtTimeOfAccident;
    }

    setSpeedAtTimeOfAccident(speedAtTimeOfAccident: string): void {
        this.speedAtTimeOfAccident = speedAtTimeOfAccident;
    }

    
    getSpeedAtTimeOfAccidentDesc(): string {
        return this.speedAtTimeOfAccidentDesc;
    }

    setSpeedAtTimeOfAccidentDesc(speedAtTimeOfAccidentDesc: string): void {
        this.speedAtTimeOfAccidentDesc = speedAtTimeOfAccidentDesc;
    }

    getOtherSpeedAtTimeOfAccident(): string {
        return this.otherSpeedAtTimeOfAccident;
    }

    setOtherSpeedAtTimeOfAccident(otherSpeedAtTimeOfAccident: string): void {
        this.otherSpeedAtTimeOfAccident = otherSpeedAtTimeOfAccident;
    }


    getPurposeAtTimeOfAccident(): string {
        return this.purposeAtTimeOfAccident;
    }

    setPurposeAtTimeOfAccident(purposeAtTimeOfAccident: string): void {
        this.purposeAtTimeOfAccident = purposeAtTimeOfAccident;
    }

    getPurposeAtTimeOfAccidentDesc(): string {
        return this.purposeAtTimeOfAccidentDesc;
    }

    setPurposeAtTimeOfAccidentDesc(purposeAtTimeOfAccidentDesc: string): void {
        this.purposeAtTimeOfAccidentDesc = purposeAtTimeOfAccidentDesc;
    }

    getOtherPurposeAtTimeOfAccident(): string {
        return this.otherPurposeAtTimeOfAccident;
    }

    setOtherPurposeAtTimeOfAccident(otherPurposeAtTimeOfAccident: string): void {
        this.otherPurposeAtTimeOfAccident = otherPurposeAtTimeOfAccident;
    }

    getAccidentDescription(): string {
        return this.accidentDescription;
    }

    setAccidentDescription(accidentDescription: string): void {
        this.accidentDescription = accidentDescription;
    }

    
    static jsonConvert(accidentDetails: AccidentDetails): AccidentDetails {
        if (accidentDetails.getDateTime() != null) {
            accidentDetails.setDateTime(new Date(accidentDetails.getDateTime()));
        }
        return accidentDetails;
    }
    
}
