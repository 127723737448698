import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { DatePipe } from '@angular/common';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { DocumentField } from 'src/app/model/document-field';
import { PoliceReport } from 'src/app/model/motor/police-report.model';
import { Others } from 'src/app/model/common/others.model';

@Component({
    selector: 'app-summary-pa-other-details',
    templateUrl: './summary-pa-other-details.component.html',
    styleUrls: ['./summary-pa-other-details.component.css']
})
export class SummaryPaOtherDetailsComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimPA: ClaimPersonalAccident;
    otherDetails: Others;
    policeReport: PoliceReport;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];
    country: string;

    constructor(private claimService: ClaimService,
                private injector : Injector,
                private documentHelperService: DocumentHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.otherDetails = this.claimPA.getOthers();
        this.policeReport = this.claimPA.getPoliceReport();
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
        this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]).getRequiredDocuments(),
        ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]).getRequiredDocuments(),
          ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]); 
        this.country = this.claim.getCountry();
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.noSend");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }


}
