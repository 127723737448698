<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && !formControl.valid && formControl.touched) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
  <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
  <div class="col-md-{{uiModel.size}} col-xs-12 input-group">
    <input formControlName="{{uiModel.name}}" type="text" class="form-control" id="{{fieldId}}"
           placeholder="{{uiModel.placeholder | translate}}"
           [ngClass]="{'ui-disabled' : uiModel.disabled}" *ngIf="!uiModel.textOnly" maxlength="{{uiModel.maxlength}}" [attr.list]="dataList" autocomplete="off"
           [readonly]="disabled"
           spellcheck="true">
    <div class="input-group-append">
      <span class="input-group" style="margin: auto;padding: 10px;">{{uiModel.labelText2 | translate}}</span>
    </div>
    <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | translate}}</p>
  </div>
  <div class="col-md-{{uiModel.size}} col-xs-12 input-group">
    <ui-error *ngIf="formControl.touched || uiModel.textOnly" [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
  </div>
 <datalist id="{{dataList}}" *ngIf="uiModel.options" title="Choose a suggestion">
   <option *ngFor="let option of uiModel.options" value="{{option.label | translate}}"></option>
 </datalist>

</div>
