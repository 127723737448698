<div class="section form-group">
    <label class="section-title" *ngIf="!this.isBurglary">{{'homeProduct.' + this.causeTypeTitle + '.title' | translate}}</label>
    <div class="section-content">
        <!--Details-->
        <div class="sub-section">
            <label *ngIf="!this.isBurglary">{{'homeProduct.homeItems.detailsDamage' | translate}}</label>
            <label *ngIf="this.isBurglary">{{'homeProduct.homeItems.detailsBoth' | translate}}</label>
            <ng-container *ngIf="this.isBurglaryOrAccident">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.burglary.incidentDescription' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{homeDetails.getDamageDescription()}}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngFor="let item of this.claimHome.getHomeDetails().getHomeItems(); let i = index;">
                <ng-container>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getLossType() != 'Others'">
                            {{item.getLossTypeDesc()}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="item.getLossType() == 'Others'">
                            {{item.getLossTypeDetail()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="item.getLossType() == 'Others'">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.briefDescriptionOfItem' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getOtherTypeBriefDesc()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="item.getLossType() == 'Mobile Phone' ||  item.getLossType() == 'Laptop(s)'">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.descOfIncident' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getLossTypeDetail()}}
                        </div>
                    </div>

                    <div class="row" *ngIf="item.getRepairCost()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.repairCost' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getRepairCostType()}} {{item.getRepairCost() | number : '1.2-2'}}
                        </div>
                    </div>

                    <div class="row" *ngIf="item.getReplacementCost()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'homeProduct.homeItems.replacementCost' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getReplacementCostType()}} {{item.getReplacementCost() | number : '1.2-2'}}
                        </div>
                    </div>

                </ng-container>
            </ng-container>
        </div>
    </div>
</div>