<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.stayedInHospital.detailsOfOverseaHospitalStay.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #HOS04 [fieldId]="'HOS04'" [formGroup]="deTailOfOverseaHospitalForm" name="wasStayHospitalOversea" (uiComponentCreated)="uiComponentCreated($event)"></qnect-ui>
        </div>
        <ng-container *ngIf="stayInHospital.getWasStayHospitalOversea() == true">
          <div>
            <qnect-ui #HOS06 class="periodType-font-weight" [fieldId]="'HOS06'" [formGroup]="deTailOfOverseaHospitalForm" name="periodTime"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #HOS07 [fieldId]="'HOS07'" [formGroup]="deTailOfOverseaHospitalForm" name="periodType"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #HOS05 [fieldId]="'HOS05'" [formGroup]="deTailOfOverseaHospitalForm" name="wasStayInIntensiveCare" (uiComponentCreated)="uiComponentCreated($event)"></qnect-ui>
          </div>
          <div class="form-group" *ngIf="stayInHospital.getWasStayInIntensiveCare() == false">
            <qnect-ui #HOS08 [fieldId]="'HOS08'" [formGroup]="deTailOfOverseaHospitalForm" name="wardType"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>