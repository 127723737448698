import {Component, Input, OnInit} from "@angular/core";

import {NotificationMessage} from "./notification-message.model";

@Component({
  selector: 'qnect-error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class ErrorNotificationComponent implements OnInit {

  @Input()
  messages: NotificationMessage[] = [];
  @Input() showPreamble: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

}
