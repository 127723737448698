import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-death-and-permanent-disability',
  templateUrl: './death-and-permanent-disability.component.html',
  styleUrls: ['./death-and-permanent-disability.component.css']
})
export class DeathAndPermanentDisabilityComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH]);
    }

  ngOnInit() {
  }

}
