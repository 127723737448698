import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {StayInHospital} from "../../../../model/travel/stay-in-hospital.model";
import {takeUntil} from "rxjs";

@Component({
    selector: 'app-detail-of-hospital-illness',
    templateUrl: './detail-of-hospital-illness.component.html',
    styleUrls: ['./detail-of-hospital-illness.component.css']
})
export class DetailOfHospitalIllnessComponent extends ClaimsBaseComponent implements OnInit {

    deTailOfIllnessForm: FormGroup;
    showTotalBox = false;
    stayInHospital: StayInHospital;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.stayInHospital = this.claim.getClaimTravel().getStayInHospital();
    }

    ngOnInit() {

        this.pushGAViewStayHospitalDetails();

        this.deTailOfIllnessForm = this.fb.group({
            dateTheIllnessOccurred: [this.stayInHospital.getDateTheIllnessOccurred()],
            natureOfIllness: [this.stayInHospital.getNatureOfIllness()],
            diagnoseType: [this.stayInHospital.getDiagnoseType()],
            otherDiagnose: [this.stayInHospital.getOtherDiagnose()],
            wasMugging: [super.getBooleanString(this.stayInHospital.getWasMugging())],
            countryOfTheLossOccurred: [this.stayInHospital.getCountryOfTheLossOccurred()],
            placeOfLoss: [this.stayInHospital.getPlaceOfLoss()]
        })
    }

    ngAfterViewInit() {

        this.deTailOfIllnessForm.valueChanges.subscribe(data => {
            this.stayInHospital.setDateTheIllnessOccurred(super.getValueInComponent('dateTheIllnessOccurred'));
            this.stayInHospital.setNatureOfIllness(super.getValueInComponent('natureOfIllness'));
            this.stayInHospital.setNatureOfIllnessDesc(super.getTextInComponent('natureOfIllness'));
            this.stayInHospital.setDiagnoseType(super.getValueInComponent('diagnoseType'));
            this.stayInHospital.setDiagnoseTypeDesc(super.getTextInComponent('diagnoseType'));
            this.stayInHospital.setOtherDiagnose(super.getValueInComponent('otherDiagnose'));
            if (this.deTailOfIllnessForm.get('wasMugging').value != null) {
                this.stayInHospital.setWasMugging(this.deTailOfIllnessForm.get('wasMugging').value == 'true');
            }
            this.stayInHospital.setCountryOfTheLossOccurred(super.getValueInComponent('countryOfTheLossOccurred'));
            this.stayInHospital.setCountryOfTheLossOccurredDesc(this.getTextInComponent('countryOfTheLossOccurred'));
            this.stayInHospital.setPlaceOfLoss(super.getValueInComponent('placeOfLoss'));
            this.stayInHospital.setPlaceOfLossDesc(super.getTextInComponent('placeOfLoss'));

            this.validateClaimDetailsComplete();
        });
    }

    uiComponentCreated(fieldId: string) {

        //nature of illness
        if (fieldId === 'HOS13') {
            this.deTailOfIllnessForm.get('natureOfIllness').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                if (data.toLowerCase() === 'sickness') {
                    this.deTailOfIllnessForm.get('diagnoseType').enable({onlySelf: false, emitEvent: false});
                } else {
                    this.deTailOfIllnessForm.get('diagnoseType').reset();
                    this.deTailOfIllnessForm.get('diagnoseType').disable({onlySelf: false, emitEvent: false});
                    if (this.deTailOfIllnessForm.get('otherDiagnose').value != null && this.deTailOfIllnessForm.get('otherDiagnose').value != '') {
                        this.deTailOfIllnessForm.get('otherDiagnose').reset();
                        this.deTailOfIllnessForm.get('otherDiagnose').disable({onlySelf: false, emitEvent: false});
                    }
                }

                if (data.toLowerCase() === 'injury') {
                    this.deTailOfIllnessForm.get('wasMugging').enable({onlySelf: false, emitEvent: false});
                } else {
                    this.deTailOfIllnessForm.get('wasMugging').reset();
                    this.deTailOfIllnessForm.get('wasMugging').disable({onlySelf: false, emitEvent: false});

                }

            });
        }

        // nature of illness - diagnosis for sickness
        if (fieldId === 'HOS14') {
            this.deTailOfIllnessForm.get('diagnoseType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                if (data && data.toLowerCase() === 'other') {
                    this.deTailOfIllnessForm.get('otherDiagnose').enable({onlySelf: false, emitEvent: false});
                } else {
                    this.deTailOfIllnessForm.get('otherDiagnose').reset();
                    this.deTailOfIllnessForm.get('otherDiagnose').disable({onlySelf: false, emitEvent: false});
                }

            });
        }

    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 0});

            this.router.navigate(["/claimform/stayInHospital/detailsOfOverseaDoctorVisit"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfIllnessForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfIllnessForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 5, subMenuIndex: 0});
        }
    }

    //Google Analytics
    pushGAViewStayHospitalDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Stay Hospital – Illness Details',
            'vPath': '/claim/stay-hospital/illness-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
