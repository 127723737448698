      <!--Clinical Expenses-->
      <app-summary-clinical-expense *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_CLINICAL') !== -1"></app-summary-clinical-expense>
      <!--Dental Expenses-->
      <app-summary-dental-expense *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_DENTAL') !== -1"></app-summary-dental-expense>
      <!--Helpere Liability-->
      <app-summary-helpers-liability *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_HELP_LIABILITY') !== -1"></app-summary-helpers-liability>      
      <!--Repatriation-->
      <app-summary-repatriation *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_REPATRIATION') !== -1"></app-summary-repatriation>      
      <!--Dishonesty Helper-->
      <app-summary-dishonesty-helper *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_DISHONESTY') !== -1"></app-summary-dishonesty-helper>
      <!--Employee Compensation-->
      <app-summary-employee-compensation *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_EMP_LIABILITY') !== -1"></app-summary-employee-compensation>
      <!--Other Claim-->
      <app-summary-other-claim-dmh *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_OTHERS') !== -1"></app-summary-other-claim-dmh>  
      <!--Hospitalization Helper-->
      <app-summary-hospitalization-helper *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_HOSPITAL') !== -1"></app-summary-hospitalization-helper>      
      <!--Death And Permanent Disability-->
      <app-summary-death-and-permanent-disability *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_DMH_DEATH') !== -1"></app-summary-death-and-permanent-disability> 
      
