import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-travel-rerouting',
  templateUrl: './travel-rerouting.component.html',
  styleUrls: ['./travel-rerouting.component.css']
})
export class TravelReroutingComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT]);
    }

  ngOnInit() {
  }

}
