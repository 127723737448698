<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="new-btn btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.tripCurtailment.detailOfTripCurtailment.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #TRAVEL_CURTAILMENT1 [fieldId]="'TRAVEL_CURTAILMENT1'" [formGroup]="deTailOfCancelForm" name="dateOriginBook"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_CURTAILMENT13 [fieldId]="'TRAVEL_CURTAILMENT13'" [formGroup]="deTailOfCancelForm" name="countryCurtailmentOccurred"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_CURTAILMENT2 [fieldId]="'TRAVEL_CURTAILMENT2'" [formGroup]="deTailOfCancelForm" [claimsDateValidator]="claim.getClaimTravel().getOriginalDepartureDate()" name="dateActualDeparture"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_CURTAILMENT3 [fieldId]="'TRAVEL_CURTAILMENT3'" [formGroup]="deTailOfCancelForm" name="reasonForCancel"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_CURTAILMENT4 [fieldId]="'TRAVEL_CURTAILMENT4'" [formGroup]="deTailOfCancelForm" name="otherReason"  [hidden]="this.tripCurtailment.getReasonForCancel() !=='Others'"></qnect-ui>
        </div>
        <ng-container *ngIf="this.claim.getCountry().toLowerCase() == 'hgi' && this.tripCurtailment.getReasonForCancel() == 'Lost travel documents'">
          <div class="form-group">
            <qnect-ui #TRAVEL_CURTAILMENT14 [fieldId]="'TRAVEL_CURTAILMENT14'" [formGroup]="deTailOfCancelForm" name="wasLossDueToRobbery"></qnect-ui>
          </div>
          <div class="form-group" *ngIf="this.tripCurtailment.getWasLossDueToRobbery()">
            <qnect-ui #TRAVEL_CURTAILMENT15 [fieldId]="'TRAVEL_CURTAILMENT15'" [formGroup]="deTailOfCancelForm" name="didYouReport"></qnect-ui>
          </div>
          <ng-container *ngIf="this.tripCurtailment.getDidYouReport()">
            <div class="form-group">
              <qnect-ui #TRAVEL_CURTAILMENT16 [fieldId]="'TRAVEL_CURTAILMENT16'" [formGroup]="deTailOfCancelForm" name="authorityType"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="this.tripCurtailment.getAuthorityType() == 'Others'">
              <qnect-ui #TRAVEL_CURTAILMENT16_OTHERS [fieldId]="'TRAVEL_CURTAILMENT16_OTHERS'" [formGroup]="deTailOfCancelForm" name="otherAuthorityType"></qnect-ui>
            </div>
            <div class="form-group">
              <qnect-ui #TRAVEL_CURTAILMENT17 [fieldId]="'TRAVEL_CURTAILMENT17'" [formGroup]="deTailOfCancelForm" name="reportDate"></qnect-ui>
            </div>
          </ng-container>
        </ng-container>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>
  <additional-claimant></additional-claimant>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()"  class="btn new-btn
     btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i
            class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
