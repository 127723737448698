<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.detailsOfIncident.detailsOfIncident' | translate}}</p>
  </div>
  <BR>

  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
                <qnect-ui #DMH_DISHONESTY_HELPER_INCIDENT_DATE [fieldId]="'DMH_DISHONESTY_HELPER_INCIDENT_DATE'" [formGroup]="dishonestyHelperForm" name="dateOfIncident"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #DMH_DISHONESTY_HELPER_INCIDENT_OCCUR [fieldId]="'DMH_DISHONESTY_HELPER_INCIDENT_OCCUR'" [formGroup]="dishonestyHelperForm" name="countryIncidentOccur"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #DMH_DISHONESTY_HELPER_INCIDENT_DESCRIPTION [fieldId]="'DMH_DISHONESTY_HELPER_INCIDENT_DESCRIPTION'" [formGroup]="dishonestyHelperForm" name="descOfIncident"></qnect-ui>
            </div>
            <div class="form-group">          
                <qnect-ui #DMH_DISHONESTY_HELPER_TOTAL_EXPENSE [fieldId]="'DMH_DISHONESTY_HELPER_TOTAL_EXPENSE'" [formGroup]="dishonestyHelperForm" name="amountClaim"></qnect-ui>
            </div>
            <div class="form-group">

                <qnect-ui #DMH_DISHONESTY_HELPER_INCIDENT_REPORT [fieldId]="'DMH_DISHONESTY_HELPER_INCIDENT_REPORT'" [formGroup]="dishonestyHelperForm" name="isIncidentReport"></qnect-ui>

            </div>

            <div class="form-group" *ngIf="this.dishonestyHelper.getIsIncidentReport()">
                <qnect-ui #DMH_DISHONESTY_HELPER_NAME_ADDRESS [fieldId]="'DMH_DISHONESTY_HELPER_NAME_ADDRESS'" [formGroup]="dishonestyHelperForm" name="policeStationNameAndAddress"></qnect-ui>
                <qnect-ui #DMH_DISHONESTY_HELPER_REFERENCE_NO [fieldId]="'DMH_DISHONESTY_HELPER_REFERENCE_NO'" [formGroup]="dishonestyHelperForm" name="referenceNumber"></qnect-ui>
                <qnect-ui #DMH_DISHONESTY_HELPER_REPORT_DATE [fieldId]="'DMH_DISHONESTY_HELPER_REPORT_DATE'" [formGroup]="dishonestyHelperForm" [claimsDateValidator]="claim.getClaimDomesticHelper().getDishonestyHelper().getDateOfIncident()" name="dateOfReport"></qnect-ui>
            </div>

        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

