<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.summaryOfotherClaimDMH.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.summaryOfotherClaimDMH.detailsOfIncident' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                     {{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.summaryOfotherClaimDMH.dateOfincident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(otherClaimDMH.getDateOfIncident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.summaryOfotherClaimDMH.incidentOccured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{otherClaimDMH.getCountryIncidentOccurDesc()}}
                </div>
            </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.summaryOfotherClaimDMH.descOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{otherClaimDMH.getDescOfIncident()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.summaryOfotherClaimDMH.helpersLiabilitAmountClaim' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{otherClaimDMH.getAmountClaimCurrency()}} {{otherClaimDMH.getAmountClaim() | number : '1.2-2'}}
                    </div>
                </div>      
                </div>               
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>