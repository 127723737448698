export class DocumentTypes {
    
    documentTypes = [];
    
    constructor(){
        this.documentTypes["AIRTICKET"]="Boarding pass / Air ticket";
        this.documentTypes["CARRIERREPORT"]="Carrier's (airline, train, vessel) report stating duration and reason of delay";
        this.documentTypes["TRAVELITINERARY"]="Copy of travel itinerary";
        this.documentTypes["EXTRATRANSCOST"]="Receipt(s) for extra transport cost";
        this.documentTypes["EXTRAACCOMCOST"]="Receipt(s) for extra accommodation cost";
    }
    
    getDocumentDescription(docType): string {
        return this.documentTypes[docType];
    }
}