import { ValidatorFn } from '@angular/forms';

import { UiModel } from '../ui.model';
import { UiValidators } from './ui-validator';

export class UiCustomValidatorFactory {

	static getCustomValidators(_uiModel: UiModel): Map<string, ValidatorFn> {
		let customValidators: Map<string, ValidatorFn> = new Map();

		if (_uiModel.rangeValidator) {
		  if (_uiModel.rangeValidator.min) {
        customValidators.set('rangeMin', UiValidators.rangeMin(_uiModel.rangeValidator.min));
      }
		  if (_uiModel.rangeValidator.max) {
        customValidators.set('rangeMax', UiValidators.rangeMax(_uiModel.rangeValidator.max));
      }
		}
    if (_uiModel.atLeastOneRequired) {
      customValidators.set('atLeastOneRequired', UiValidators.atLeastOneRequired(_uiModel.atLeastOneRequired));
    }
    if (_uiModel.validationRegexList) {
      customValidators.set('validationRegexList', UiValidators.validationRegexList(_uiModel.validationRegexList));
    }

    if (_uiModel.type === 'timepicker') {
      customValidators.set('timePattern', UiValidators.timepickerPatternValidation());
    }

    if (_uiModel.type === 'datepicker') {
        customValidators.set('pattern', UiValidators.dateValidation(_uiModel.dateFormat, _uiModel.minDate,  _uiModel.maxDate));
    }

    if (_uiModel.type === 'datetimepicker') {
      customValidators.set('pattern', UiValidators.datetimeValidation(_uiModel.dateFormat, _uiModel.minDate,  _uiModel.maxDate));
    }

    if(_uiModel.p400moreThan1) {
      customValidators.set('p400moreThan1', UiValidators.p400moreThan1()
      );
    }

    if(_uiModel.p400noSpace) {
      customValidators.set('p400noSpace', UiValidators.p400noSpace());
    }

    if(_uiModel.p400Format) {
      customValidators.set('p400Format', UiValidators.p400Format());
    }

    if(_uiModel.p400noSpace) {
      customValidators.set('p400noSpace', UiValidators.p400noSpace());
    }

    if(_uiModel.p400consecutive) {
      customValidators.set('p400consecutive', UiValidators.p400consecutive());
    }

    if(_uiModel.atleastOneRegexValid) {
        customValidators.set('atleastOneRegexValid', UiValidators.atleastOneRegexValid(_uiModel.atleastOneRegexValid));
    }

    return customValidators;
}

}
