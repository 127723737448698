import {PersonalAccident} from "./personal-accident.model";
import {GenericResponsibility} from "./generic-responsibility.model";
import { Weather } from "./weather.model";
import { Water } from "./water.model";
import { HomeItem } from "./home-item.model";
import { DomesticEmployerLiability } from "./domestic-employer-liability.model";
import { ThirdPartyLossDetails } from "./third-party-loss-details.model";
import { ThirdPartyLegalDetails } from "./third-party-legal-details.model";
import { LostPersonalItem } from "../travel/lost-personal-item.model";
import { DamagePersonalItem } from "../travel/damage-personal-item.model";
import { PoliceReport } from "../motor/police-report.model";
import { Others } from "../common/others.model";

export class HomeDetails{

    private personalAccident: PersonalAccident = new PersonalAccident();
    private genericResponsibility: GenericResponsibility = new GenericResponsibility();
    private weather: Weather = new Weather();
    private water: Water = new Water();
    private others: Others = new Others();
    private isThereSeriousDamage: boolean;
    private damageDescription: String;
    private isAnyItemDamaged: boolean;
    private homeItems: HomeItem[];
    private lostPersonalItem: LostPersonalItem = new LostPersonalItem();
    private damagePersonalItem: DamagePersonalItem = new DamagePersonalItem();
    private thirdPartyLossDetails: ThirdPartyLossDetails = new ThirdPartyLossDetails();
    private thirdPartyLegalDetails: ThirdPartyLegalDetails = new ThirdPartyLegalDetails();
    private domesticEmployerLiability: DomesticEmployerLiability = new DomesticEmployerLiability();
    private isThePropertySafeToStayIn: boolean;
    private isAppointBuilder: boolean;
    private isAssistBuilder: boolean;
    private isThereWindowDamage: boolean;
    private isThereInteriorDamage: boolean;
    private isThereFixturesDamage: boolean;
    private policeReport: PoliceReport = new PoliceReport();

    getPersonalAccident(): PersonalAccident {
        return this.personalAccident;
    }

    setPersonalAccident(value: PersonalAccident) {
        this.personalAccident = value;
    }

    getGenericResponsibility(): GenericResponsibility {
        return this.genericResponsibility;
    }

    setGenericResponsibility(value: GenericResponsibility) {
        this.genericResponsibility = value;
    }

    getWeather(): Weather {
        return this.weather;
    }

    setWeather(weather: Weather): void {
        this.weather = weather;
    }

    getWater(): Water {
        return this.water;
    }

    setOthers(others: Others): void {
        this.others = others;
    }

    getOthers(): Others {
        return this.others;
    }

    setWater(water: Water): void {
        this.water = water;
    }

    getIsThereSeriousDamage(): boolean {
        return this.isThereSeriousDamage;
    }

    setIsThereSeriousDamage(isThereSeriousDamage: boolean): void {
        this.isThereSeriousDamage = isThereSeriousDamage;
    }

    getDamageDescription(): String{
        return this.damageDescription;
    }

    setDamageDescription(damageDescription: String) {
        this.damageDescription = damageDescription;
    }

    getIsThePropertySafeToStayIn(): boolean {
        return this.isThePropertySafeToStayIn;
    }

    setIsThePropertySafeToStayIn(isThePropertySafeToStayIn: boolean): void {
        this.isThePropertySafeToStayIn = isThePropertySafeToStayIn;
    }

    getIsAppointBuilder(): boolean {
        return this.isAppointBuilder;
    }

    setIsAppointBuilder(value: boolean): void {
        this.isAppointBuilder = value;
    }

    getIsAnyItemDamaged(): boolean {
        return this.isAnyItemDamaged;
    }

    setIsAnyItemDamaged(isAnyItemDamaged: boolean): void {
        this.isAnyItemDamaged = isAnyItemDamaged;
    }

    getHomeItems(): HomeItem[] {
        return this.homeItems;
    } 

    setHomeItems(homeItems: HomeItem[]){
        this.homeItems = homeItems;
    }

    getThirdPartyLossDetails(): ThirdPartyLossDetails {
        return this.thirdPartyLossDetails;
    }

    setThirdPartyLossDetails(thirdPartyLossDetails: ThirdPartyLossDetails): void {
        this.thirdPartyLossDetails = thirdPartyLossDetails;
    }

    getThirdPartyLegalDetails(): ThirdPartyLegalDetails {
        return this.thirdPartyLegalDetails;
    }

    setThirdPartyLegalDetails(thirdPartyLegalDetails: ThirdPartyLegalDetails): void {
        this.thirdPartyLegalDetails = thirdPartyLegalDetails;
    }

    getDomesticEmployerLiability(): DomesticEmployerLiability {
        return this.domesticEmployerLiability;
    }

    setDomesticEmployerLiability(domesticEmployerLiability: DomesticEmployerLiability): void {
        this.domesticEmployerLiability = domesticEmployerLiability;
    }

    getLostPersonalItem(): LostPersonalItem {
        return this.lostPersonalItem;
    }

    setLostPersonalItem(value: LostPersonalItem) {
        this.lostPersonalItem = value;
    }

    getDamagePersonalItem(): DamagePersonalItem {
        return this.damagePersonalItem;
    }

    setDamagePersonalItem(value: DamagePersonalItem) {
        this.damagePersonalItem = value;
    }

    getIsAssistBuilder(): boolean {
        return this.isAssistBuilder;
    }

    setIsAssistBuilder(value: boolean): void {
        this.isAssistBuilder = value;
    }

    getIsThereWindowDamage(): boolean {
        return this.isThereWindowDamage;
    }

    setIsThereWindowDamage(value: boolean): void {
        this.isThereWindowDamage = value;
    }

    getIsThereInteriorDamage(): boolean {
        return this.isThereInteriorDamage;
    }

    setIsThereInteriorDamage(value: boolean): void {
        this.isThereInteriorDamage = value;
    }

    getIsThereFixturesDamage(): boolean {
        return this.isThereFixturesDamage;
    }

    setIsThereFixturesDamage(value: boolean): void {
        this.isThereFixturesDamage = value;
    }

    getPoliceReport(): PoliceReport {
        return this.policeReport;
    } 

    setPoliceReport(value: PoliceReport){
        this.policeReport = value;
    }
    static jsonConvert(homeDetails: HomeDetails): HomeDetails {
       
        if (homeDetails != null) {
            if (homeDetails.getLostPersonalItem()) {
                homeDetails.setLostPersonalItem(Object.assign(new LostPersonalItem(), homeDetails.getLostPersonalItem()));
                LostPersonalItem.jsonConvert(homeDetails.getLostPersonalItem());
            }
            if (homeDetails.getDamagePersonalItem()) {
                homeDetails.setDamagePersonalItem(Object.assign(new DamagePersonalItem(), homeDetails.getDamagePersonalItem()));
                DamagePersonalItem.jsonConvert(homeDetails.getDamagePersonalItem());
            }
            if (homeDetails.getThirdPartyLossDetails()) {
                homeDetails.setThirdPartyLossDetails(Object.assign(new ThirdPartyLossDetails(), homeDetails.getThirdPartyLossDetails()));
                ThirdPartyLossDetails.jsonConvert(homeDetails.getThirdPartyLossDetails());
            }
            if (homeDetails.getThirdPartyLegalDetails()) {
                homeDetails.setThirdPartyLegalDetails(Object.assign(new ThirdPartyLegalDetails(), homeDetails.getThirdPartyLegalDetails()));
                ThirdPartyLegalDetails.jsonConvert(homeDetails.getThirdPartyLegalDetails());
            }
            if (homeDetails.getDomesticEmployerLiability()) {
                homeDetails.setDomesticEmployerLiability(Object.assign(new DomesticEmployerLiability(), homeDetails.getDomesticEmployerLiability()));
                DomesticEmployerLiability.jsonConvert(homeDetails.getDomesticEmployerLiability());
            }
            if (homeDetails.getGenericResponsibility()) {
                homeDetails.setGenericResponsibility(Object.assign(new GenericResponsibility(), homeDetails.getGenericResponsibility()));
                GenericResponsibility.jsonConvert(homeDetails.getGenericResponsibility());
            }
            if (homeDetails.getPersonalAccident()) {
                homeDetails.setPersonalAccident(Object.assign(new PersonalAccident(), homeDetails.getPersonalAccident()));
                PersonalAccident.jsonConvert(homeDetails.getPersonalAccident());
            }
            if (homeDetails.getWater()) {
                homeDetails.setWater(Object.assign(new Water(), homeDetails.getWater()));
                Water.jsonConvert(homeDetails.getWater());
            }
            if (homeDetails.getWeather()) {
                homeDetails.setWeather(Object.assign(new Weather(), homeDetails.getWeather()));
                Weather.jsonConvert(homeDetails.getWeather());
            }
            if (homeDetails.getOthers()) {
                homeDetails.setOthers(Object.assign(new Others(), homeDetails.getOthers()));
                Others.jsonConvert(homeDetails.getOthers());
            }
            if (homeDetails.getPoliceReport()) {
                homeDetails.setPoliceReport(Object.assign(new PoliceReport(), homeDetails.getPoliceReport()));
                PoliceReport.jsonConvert(homeDetails.getPoliceReport());
            }
            if (homeDetails.getHomeItems() != null) {
                let homeItemDetails: HomeItem[] = [];
                for (const homeItem of homeDetails.getHomeItems()) {
                        let item = Object.assign(new HomeItem(),homeItem);
                        item = HomeItem.jsonConvert(item);
                        homeItemDetails.push(item);
                    }
                homeDetails.setHomeItems(homeItemDetails);
            }
        }
        return homeDetails;
    }
}