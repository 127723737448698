<div class="section form-group">
    <label class="section-title">Theft or Attempted Theft</label>
    <div class="section-content">
        <!-- Incident & Accident Details-->
        <div class="sub-section">
            <label>{{'motorClaim.driverDetails.incidentAccidentDetails.heading' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.driverDetails.incidentAccidentDetails.dateTimeOfAccident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(theft.getAccidentDetails().getDateTime())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.claimSection.motorAccidentCollission.accidentDetails.countryOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{theft.getAccidentDetails().getCountryDesc()}}
                </div>
            </div>
                <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.driverDetails.incidentAccidentDetails.placeOfAccident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{theft.getAccidentDetails().getLocation()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.driverDetails.incidentAccidentDetails.descriptionOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{theft.getAccidentDetails().getAccidentDescription()}}
                </div>
            </div>
        </div>
        <!-- Police Report -->
        <div class="sub-section">
            <label>{{'motorClaim.driverDetails.policeReport.heading' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.driverDetails.policeReport.hasReportedIncidentToPolice' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(theft.getPoliceReport().getHasReportedIncidentToPolice())}}
                </div>
            </div>
            <div *ngIf="theft.getPoliceReport().getHasReportedIncidentToPolice()">
                <div class="row" *ngIf="isNotNullOrUndefined(theft.getPoliceReport().getDateOfReport())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'motorClaim.driverDetails.policeReport.dateOfReport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(theft.getPoliceReport().getDateOfReport())}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(theft.getPoliceReport().getPoliceReportNumber())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'motorClaim.driverDetails.policeReport.policeReportNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{theft.getPoliceReport().getPoliceReportNumber()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'motorClaim.driverDetails.policeReport.policeStation' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{theft.getPoliceReport().getPoliceStation()}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Supporting Documents -->
        <div class="sub-section">
            <label>{{'motorClaim.claimSection.windscreen.documents.description' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>