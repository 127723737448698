<div class="section form-group">
    <label *ngIf="!isDmhClaim" class="sub-section">{{'homeProduct.domesticEmployer.title' | translate}}</label>
    <label *ngIf="isDmhClaim" class="section-title">{{'homeProduct.domesticEmployer.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row" *ngIf="!isDmhClaim">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'homeProduct.domesticEmployer.wasHelperInjured' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.domesticEmployerLiability.getWasHelperInjured())}}
                </div>
            </div>

            <ng-container *ngIf="this.domesticEmployerLiability.getWasHelperInjured()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.domesticEmployer.helperName' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.domesticEmployerLiability.getHelperName()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.domesticEmployer.helperHkidPassport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.domesticEmployerLiability.getHelperHkidPassport()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.domesticEmployer.shareDetails' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.domesticEmployerLiability.getHelperDetails()}}
                    </div>
                </div>
            </ng-container>
    </div>
</div>