import {Component, Injector, Input, OnInit} from '@angular/core';
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'travel-sections-summary',
    templateUrl: './travel-sections-summary.component.html'
})
export class TravelSectionsSummaryComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    @Input() claimItem: Claim;

    constructor(private claimService: ClaimService,
                private injector : Injector) {
        super(injector);
    }

    ngOnInit() {

        if(!UtilitiesService.isNullOrUndefined(this.claimItem)) {
            this.claim = this.claimItem;
        } else {
            this.claim = this.claimService.getClaim();
        }
    }

}
