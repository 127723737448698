<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.detailsOfNatureIllness.detailsOfNatureIllness' | translate}}</p>
  </div>
  <BR>

  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
                <qnect-ui #DMH_DENTAL_COUNTRY_OCCUR [fieldId]="'DMH_DENTAL_COUNTRY_OCCUR'" [formGroup]="dentalForm" name="countryIncidentOccur"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #DMH_DENTAL_HELPERS_CONDITION [fieldId]="'DMH_DENTAL_HELPERS_CONDITION'" [formGroup]="dentalForm" name="isIncidentReport"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="this.dentalExpenses.getIsIncidentReport()">
                <qnect-ui #DMH_DENTAL_SPECIFY_CONDITION [fieldId]="'DMH_DENTAL_SPECIFY_CONDITION'" [formGroup]="dentalForm" name="specifyCondition"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #DMH_DENTAL_SELECT_TYPE_TREATMENT [fieldId]="'DMH_DENTAL_SELECT_TYPE_TREATMENT'" [formGroup]="dentalForm" name="selectTypeTreatment"></qnect-ui>
            </div>
                                                
        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

