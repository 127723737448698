import {Component, EventEmitter, Injector, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';

@Component({
  selector: 'forgot-password-modal',
  templateUrl: './forgot-password-modal.html',
  styleUrls: ['./forgot-password-modal.scss']
})
export class ForgotPasswordModalComponent extends ClaimsBaseComponent implements OnInit {

  @ViewChild('forgot_password_modal', {static: true}) 
  private modal: TemplateRef<any>;

  forgotPasswordForm: FormGroup;

  @Output() onSubmitEmit = new EventEmitter<string>();

  constructor(private injector : Injector,
              private fb: FormBuilder,
              private modalService: NgbModal) {
      super(injector);
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      repairerEmail: []
    });
  }

  handleSubmit() {
    if (super.validateForm(this.forgotPasswordForm)) {
      this.onSubmitEmit.emit(this.forgotPasswordForm.get('repairerEmail').value);
    }
  }

  open() {
    if (!this.modalService.hasOpenModals()) {
      this.forgotPasswordForm.get('repairerEmail').reset();
      this.modalService.open(this.modal, {size: 'md', backdrop: 'static', keyboard: false});
    }
  }
}
