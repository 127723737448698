import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { ClaimTypes } from "../../model/claim-type.model";
import { DocumentField } from "../../model/document-field";
import { Claim } from "../../model/claim.model";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class MissedEventFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.ticketDoc', SmartqDocuments.AIRTICKET, true));
        requiredDocuments.push(new DocumentField('ADMTICKET', 'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.receiptDoc', SmartqDocuments.INVOICE, true));
        requiredDocuments.push(new DocumentField('RELDOC', 'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.relDoc', SmartqDocuments.CORRESPONDENCE, true));
        requiredDocuments.push(new DocumentField('NREFTICKET', 'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.nonrefundDoc', SmartqDocuments.PAYMENTDOCUMENT, true));
        if(UtilitiesService.checkForMinors(this.claim)){
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}