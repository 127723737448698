import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {catchError, finalize, map} from "rxjs/operators";
import {DateService} from "../ui/ui-datepicker/date.service";
import {SpinnerService} from "../core/spinner/spinner.service";
import {ClaimAuthJson} from "../model/search-claim/claim-auth.model";
import {IntegrationClaimDetailsJson} from "../model/search-claim/integration-claim-details.model";
import {ClaimSearchResult} from "../model/search-claim/claim-search-result.model";
import { PolicyVerifyForm } from '../model/policy-verify-form.model';
import {ClaimDetails} from '../model/search-claim/claim-details.model';
import {StatusRemarks} from '../model/search-claim/status-remarks';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable()
export class ClaimSearchService {

    private claimSearchResult: ClaimSearchResult;
    statusRemarks: any;

    constructor(private http: HttpClient,
                private dateService: DateService,
                private spinnerService :  SpinnerService) {
       this.statusRemarks=  StatusRemarks;
    }

    public getClaimsStatus(policyVerifyForm: PolicyVerifyForm): Observable<ClaimAuthJson> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };

        const url: string = '/ms/claims-service/rest/check/claims/status';
        let formString : string = JSON.stringify(policyVerifyForm);

        return this.http.post<ClaimAuthJson>(url, formString, httpOptions)
            .pipe(map((res: ClaimAuthJson) => {

                let claimAuthJsonObj = new ClaimAuthJson();
                Object.assign(claimAuthJsonObj, res);

                let claimDetailsList: IntegrationClaimDetailsJson[] = [];
                for (const detail of claimAuthJsonObj.getClaimDetailsIntegrationInfolist()) {
                    let detailItem = Object.assign(new IntegrationClaimDetailsJson(), detail);
                    detailItem = IntegrationClaimDetailsJson.jsonConvert(detailItem);
                    claimDetailsList.push(detailItem);
                }

                claimAuthJsonObj.setClaimDetailsIntegrationInfolist(claimDetailsList);
                this.spinnerService.displayLoader(false);

                return claimAuthJsonObj;

            }))
            .pipe(catchError<any,Observable<ClaimAuthJson>>(this.handleError));
    }

    public sendOtp(token: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };

        const url: string = '/ms/claims-service/rest/sendOtp';

        return this.http.post<any>(url, {}, httpOptions)
            .pipe(map((res: any) => {
                return res;
            }))
            .pipe(catchError<any,Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    public verifyOtpAndGetClaimStatus(token: string, otp: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };

        let url: string = "/ms/claims-service/rest/verifyOtpAndGetClaimStatus";
        url += "?otp=" + otp;

        return this.http.get<any>(url, httpOptions)
            .pipe(map((res : any) => {
                return res;
            }))
            .pipe(catchError<any, Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        console.error('ClaimPublicService handleError');
        let errMsg: string;
        if (error instanceof HttpResponse) {
            console.error(error);
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            console.error('error.message ' + error.message);
            errMsg = error.message ? error.message : error.toString();
        }
        return throwError(() => new Error(errMsg));
    }

    public setUpClaimResult(claimAuthJson: ClaimAuthJson) {

        let claimSearchTransaction = new ClaimSearchResult();
        let claimSearchDetailList: ClaimDetails[] = [];

        let claimDetailsIntegrationList = claimAuthJson.getClaimDetailsIntegrationInfolist();

        for (let claimDetailsItem of claimDetailsIntegrationList) {
           let claimStatusDetailsObj = new ClaimDetails();
           let claimNotifNumber = claimDetailsItem.getClaimNotificationNumber();
           if (!UtilitiesService.isNullOrUndefined(claimNotifNumber) && claimNotifNumber != "") {
               claimStatusDetailsObj.setClaimNumber(claimNotifNumber);
           } else {
                claimStatusDetailsObj.setClaimNumber(UtilitiesService.isNullOrUndefined(claimAuthJson.getClaimNotifNumber()) ?
                    claimDetailsItem.getClaimNumber() :
                    claimDetailsItem.getClaimNumber() + ' [' + claimAuthJson.getClaimNotifNumber() + ']');
           }
            claimStatusDetailsObj.setDateOfLoss(claimDetailsItem.getDateOfLoss());
            claimStatusDetailsObj.setQnectStatus(claimDetailsItem.getQnectStatus());
            claimStatusDetailsObj.setProductType(claimDetailsItem.getProductType());
            claimStatusDetailsObj.setPolicyNumber(claimDetailsItem.getPolicyNumber());
            claimStatusDetailsObj.setClientName(claimDetailsItem.getClientName());
            claimStatusDetailsObj.setLastUpdate(claimDetailsItem.getLastUpdate());

            this.setStatus(claimStatusDetailsObj);

            claimSearchDetailList.push(claimStatusDetailsObj);
        }

       claimSearchTransaction.setProductType(claimAuthJson.getProductType());
       claimSearchTransaction.setCountry(claimAuthJson.getCountry());
       claimSearchTransaction.setPolicyType(claimAuthJson.getPolicyType());
       claimSearchTransaction.setPolicyNumber(claimAuthJson.getPolicyNumber());
       claimSearchTransaction.setIsManulife(claimAuthJson.getIsManulife());
       claimSearchTransaction.setCatalogueCode(claimAuthJson.getCatalogueCode());
       claimSearchTransaction.setClaimDetailsList(claimSearchDetailList);
       claimSearchTransaction.setClaimToken(claimAuthJson.getClaimToken());

       this.claimSearchResult = claimSearchTransaction;
    }

    private setStatus(claimDetails: ClaimDetails) {
        let remarks = this.statusRemarks.find(statusRemark => !UtilitiesService.isNullOrUndefined(claimDetails.getQnectStatus()) &&
            statusRemark.value.toLowerCase() === claimDetails.getQnectStatus().toLowerCase());
        if (remarks) {
            claimDetails.setRemarks(remarks.remarks);
            claimDetails.setImage(remarks.image);
            claimDetails.setStatusDesc(remarks.status)
        } else {
            // default to in assessment
            claimDetails.setRemarks("claimStatus.summary.status.assessmentDesc");
            claimDetails.setImage("assets/images/claim-search/processing.png");
            claimDetails.setStatusDesc("claimStatus.summary.status.assessment");
        }
    }

    public getClaimStatusResult(): ClaimSearchResult {
        return this.claimSearchResult
    }

}
