import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-modal-table',
  templateUrl: './modal-table.component.html'
})

@Injectable()
export class ModalTableComponent {

  selected = [];

  @Input() modalTableSelect: any;

  @Input() modalTableInjuryList: any;

  @Input() modalTableIsLoading: any;

  @Output() emitTableCustomSearchFn = new EventEmitter<any>();
  modalTableCustomSearchFn(term: any) {
    this.emitTableCustomSearchFn.emit(term);
  }

  @Output() emitTableAddTagFn = new EventEmitter<any>();
  modalTableAddTagFn(label: any) {
    this.emitTableAddTagFn.emit(label);
  }

  @Output() emitTableOnClickRemoveSpecificArea = new EventEmitter<any>();
  modalTableOnClickRemoveSpecificArea(id: any, area: any) {
    this.emitTableOnClickRemoveSpecificArea.emit({ id, area });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item['label'].toLocaleLowerCase().indexOf(term) > -1
  }

  addTagFn(term: string) {
    const formattedTerm = term.replace(/\s+/g, '-')
    const newCar = { id: formattedTerm, label: formattedTerm, value: term };
    return newCar;
  }
}
