<ng-template #modal_otp let-c="close" let-d="dismiss">
    <div *ngIf="!showCloseIcon"><br/></div>
    <div *ngIf="showCloseIcon" class="div-otp-close">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="otp-title">{{ 'otpBox.label' | translate }}</div>
        <h4>{{ 'otpBox.message' | translate }} {{maskedEmailAddress}}</h4><br/>
        <div>
            <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config" ></ng-otp-input>
        </div>
        <div *ngIf="showOtpError" >
            <small id="error-message">{{'otpBox.errorMessage' | translate}}</small>
        </div>
        <div class="mt-4">
            <div *ngIf="showResendOtpLink">
                <div class="content d-flex justify-content-center align-items-center"> 
                    <span>{{ 'otpBox.resendQuestion' | translate }}</span>
                </div>
                <div *ngIf="this.timeLeft > 0" disabled class="content d-flex justify-content-center align-items-center resend-disabled"> 
                    <a class="text-decoration-none ms-3">
                        {{ 'otpBox.resend' | translate }} ({{timeLeft}})</a>
                </div>
                <div *ngIf="this.timeLeft === 0" class="content d-flex justify-content-center align-items-center resend-enabled"> 
                    <a href="javascript:;" (click)="onSendOtp()"class="text-decoration-none ms-3">
                        {{ 'otpBox.resend' | translate }}</a>
                </div>
            </div>
            <br/><br/>
            <button class="new-btn btn-primary btn" [ngClass]="{'continue-disabled' : this.otp.length !== 6}"
                [disabled]="this.otp.length !== 6" 
                (click)="onVerifyOtp();" >
                    {{'claimSection.generalLabels.generalButtonLabels.continue' | translate}}
            </button>
        </div>
        <br/><br/>
    </div>
</ng-template>

<ng-template #modal_otp_locked let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="otp-title">{{ 'otpBox.locked' | translate }}</div><br/>
        <h4>{{ 'otpBox.lockedSublabel' | translate }}</h4>
    </div>
    <div class="mt-4">
        <button class="new-btn btn-primary btn" (click)="c('Close click')" >
                {{'claimSection.generalLabels.generalButtonLabels.continue' | translate}}
        </button>
        <br/><br/>
    </div>
</ng-template>