<div>
  <div class="prev-bar" *ngIf="isFromGeneric">
    <a [routerLink]="['/claimform/genericSelectClaim']" class="btn btn-secondary new-btn">
      <i class="fas fa-arrow-left"></i>&nbsp;{{'claimDetails.body.back' | translate}}&nbsp;
    </a>
  </div>
  <div class="col-12">
    <h5>{{ 'ecClaim.employerDetails.title' | translate}}</h5>
    <p>{{ 'ecClaim.employerDetails.subHeading' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="employerDetailsForm">
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_NAME [fieldId]="'EC_EMPLOYER_NAME'" [formGroup]="employerDetailsForm" name="employerName"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_BRN [fieldId]="'EC_EMPLOYER_BRN'" [formGroup]="employerDetailsForm" name="brn"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_TELEPHONE [fieldId]="'EC_EMPLOYER_TELEPHONE'" [formGroup]="employerDetailsForm" name="telephoneNumber"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_EMAIL [fieldId]="'EC_EMPLOYER_EMAIL'" [formGroup]="employerDetailsForm" name="emailAddress"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_FAX [fieldId]="'EC_EMPLOYER_FAX'" [formGroup]="employerDetailsForm" name="faxNumber"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_ADDRESS [fieldId]="'EC_EMPLOYER_ADDRESS'" [formGroup]="employerDetailsForm" name="address"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_TRADE [fieldId]="'EC_EMPLOYER_TRADE'" [formGroup]="employerDetailsForm" name="trade"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <!-- Particulars of the principal contractor/holding company -->
  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.employerDetails.particularsOfComp' | translate}}</h5>
    <p>{{ 'ecClaim.employerDetails.particularsOfCompSubHeading' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="contractorForm">
        <div class="form-group">
          <qnect-ui #EC_EMPLOYER_SUBCONTRACTOR [fieldId]="'EC_EMPLOYER_SUBCONTRACTOR'" [formGroup]="contractorForm" name="isSubContractor"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="employerDetails.getIsSubContractor() == false">
          <qnect-ui #EC_EMPLOYER_SUBSIDIARY [fieldId]="'EC_EMPLOYER_SUBSIDIARY'" [formGroup]="contractorForm" name="isSubsidiary"></qnect-ui>
        </div>

        <ng-container *ngIf="employerDetails.getIsSubContractor() || employerDetails.getIsSubsidiary()">
          <div class="form-group">
            <qnect-ui #EC_EMPLOYER_NAME [fieldId]="'EC_EMPLOYER_NAME'" [formGroup]="contractorForm" name="employerName"
                      [dynamicLabel]="employerDetails.getIsSubContractor() ? 'ecClaim.employerDetails.contractorName' :
                      'ecClaim.employerDetails.NameOfHoldingCompany'"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_EMPLOYER_BRN [fieldId]="'EC_EMPLOYER_BRN'" [formGroup]="contractorForm" name="brn"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_EMPLOYER_TELEPHONE [fieldId]="'EC_EMPLOYER_TELEPHONE'" [formGroup]="contractorForm" name="telephoneNumber"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_EMPLOYER_EMAIL [fieldId]="'EC_EMPLOYER_EMAIL'" [formGroup]="contractorForm" name="emailAddress"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_EMPLOYER_FAX [fieldId]="'EC_EMPLOYER_FAX'" [formGroup]="contractorForm" name="faxNumber"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_EMPLOYER_ADDRESS [fieldId]="'EC_EMPLOYER_ADDRESS'" [formGroup]="contractorForm" name="address"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_EMPLOYER_TRADE [fieldId]="'EC_EMPLOYER_TRADE'" [formGroup]="contractorForm" name="trade"></qnect-ui>
          </div>
        </ng-container>

      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
