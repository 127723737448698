<div class="section form-group">
  <label class="section-title"> {{'pageSummaryClaim.claimItem.sectionDoctorVisit.title' | translate}}</label>
  <div class="section-content">
      <div class="sub-section">
          <label>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.title' | translate}}</label>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                  {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.dateTheIllnessOccurred' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{getDate(visitedDoctor.getDateTheIllnessOccurred())}}
              </div>
          </div>
          <div class="row" *ngIf="visitedDoctor.getCountryOfTheLossOccurredDesc()">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.countryIllnessOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{visitedDoctor.getCountryOfTheLossOccurredDesc()}}
                </div>
          </div>
          <div class="row" *ngIf="visitedDoctor.getPlaceOfLossDesc()">
              <div class="col col-xs-12 col-lg-7">
                  {{'claimSection.visitedADoctor.detailOfIllness.whereIllnessInjuryOccurred' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{visitedDoctor.getPlaceOfLossDesc()}}
              </div>
          </div>
          <div class="row">
            <div class="col col-xs-12 col-lg-7">
              {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.isFirstVisitForThisCondition' | translate}}
            </div>
            <div class="col col-xs-12 col-lg-5 content">
                {{returnYesOrNoOtherContext(visitedDoctor.getIsFirstVisitForThisCondition())}}
            </div>
        </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.natureOfIllness' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                {{visitedDoctor.getNatureOfIllnessDesc()}}
              </div>
          </div>
          <ng-container *ngIf="visitedDoctor.getNatureOfIllness() === 'Sickness'">
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.diagnoseType' | translate}}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                    {{visitedDoctor.getDiagnoseTypeDesc()}}
                  </div>
              </div>
              <div class="row"
                  *ngIf="visitedDoctor.getDiagnoseType() === 'Others'">
                  <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.otherDiagnose' | translate}}
                  </div>
                  <div class="col col-xs-12 col-lg-5-word-break content">
                    {{visitedDoctor.getOtherDiagnose()}}
                  </div>
              </div>
          </ng-container>
          <ng-container *ngIf="visitedDoctor.getOtherDiagnose() === 'Injury'">
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.injuryResult' | translate}}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{returnYesOrNo(visitedDoctor.getWasMugging())}}
                  </div>
              </div>
          </ng-container>
          <div class="row">
                <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subOverseaDoc.typeMedical' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{visitedDoctor.getTypeOfMedicalPractitionerDesc()}}
                </div>
          </div>
          <div class="row"
                *ngIf="visitedDoctor.getTypeOfMedicalPractitioner() == 'Others'">
                <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subOverseaDoc.otherMedical' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{visitedDoctor.getOtherTypeOfMedicalPractitioner()}}
                </div>
           </div>
           <ng-container *ngIf="visitedDoctor.getNatureOfIllness() === 'Dental'">
                <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.wasCausedByAccidentalInjury' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(visitedDoctor.getWasCausedByAccidentalInjury())}}
                        </div>
                </div>
                <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subIllDetail.dentalTreatment' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{visitedDoctor.getDentalTreatmentDesc()}}
                        </div>
                </div>
            </ng-container>
      </div>
      <div class="sub-section">
          <label> {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subOverseaDoc.title' | translate}}</label>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subOverseaDoc.medOverseas' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{returnYesOrNo(visitedDoctor.getWasVisitedpractitioner())}}
              </div>
          </div>
      </div>
      <div class="sub-section">
          <label>{{'claimSection.visitedADoctor.followUpTreatment.title-2' | translate}}</label>
          <div class="row">
              <div class="col col-xs-12 col-lg-7" *ngIf="claim.getCountry()?.toLowerCase() === 'hkg' ||
                claim.getCountry()?.toLowerCase() === 'hgi'">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subFFTreatment.returnHkDate' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-7" *ngIf="claim.getCountry()?.toLowerCase() === 'sgp'">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subFFTreatment.returnSgpDate' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-7" *ngIf="claim.getCountry()?.toLowerCase() === 'mys'">
                  {{'claimSection.visitedADoctor.followUpTreatment.returnToMysDate' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{getDate(visitedDoctor.getReturnHkDate())}}
              </div>
          </div>
          <div class="row" *ngIf="visitedDoctor.getDateOfFirstVisit()">
              <div class="col col-xs-12 col-lg-7">
                  {{'claimSection.visitedADoctor.followUpTreatment.dateOfFirstVisit' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{getDate(visitedDoctor.getDateOfFirstVisit())}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subFFTreatment.receiveFFTreatment' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{returnYesOrNo(visitedDoctor.getHasReceiveFollowUpTreatment())}}
              </div>
          </div>
          <ng-container *ngIf="visitedDoctor.getHasReceiveFollowUpTreatment()">
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subFFTreatment.lastTreamentDate' | translate}}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{getDate(visitedDoctor.getLastTreatmentDate())}}
                  </div>
              </div>
          <div class="row">
                <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subFFTreatment.isTreatmentCompleted' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(visitedDoctor.getIsTreatmentCompleted())}}
                </div>
            </div>
         </ng-container>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subFFTreatment.hasSufferedIllness' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(visitedDoctor.getHasSufferedIllness())}}
                </div>
            </div>
      </div>
      <div class="sub-section">
          <label>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subBills.title' | translate}}</label>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subBills.numberOfTreatmentBill' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{visitedDoctor.getNumberOfTreatmentBill()}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7"
                   *ngIf="visitedDoctor.getNumberOfTreatmentBill() == 'Others'">
                   {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subBills.otherNumOfTreatmentBill' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{visitedDoctor.getOtherNumOfTreatmentBill()}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                {{'pageSummaryClaim.claimItem.sectionDoctorVisit.subBills.medicalExpenseTypeAmount' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{visitedDoctor.getMedicalExpenseType()}}
                  {{visitedDoctor.getMedicalExpenseTypeAmount() | number : '1.2-2'}}
              </div>
          </div>
      </div>
      <div class="sub-section">
        <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
        <div class="row" *ngFor="let item of this.listOfDocuments;">
            <div class="col col-xs-12 col-lg-12">
                {{item.description | translate}}
            </div>
            <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                    {{ item.uploadedFileName }}
                </div>
            </ng-container>
        </div>
      </div>
  </div>
  <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
    <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
    <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
      <div class="col col-xs-12 col-lg-12">
          {{item.description | translate}}
      </div>
      <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
          <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
              {{ item.uploadedFileName }}
          </div>
      </ng-container>
  </div>
</div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>