export class PaAccidentDetails {

    private accidentDate: Date;
    private accidentAddress: string;
    private accidentDescription: string;
    private occupation: string;
    private occupationDesc: string;
    private occupationOthers: string;
    private jobTitle: string;
    private countryIncidentOccur: string;
    private countryIncidentOccurDesc: string;

    public getAccidentDate(): Date {
        return this.accidentDate;
    }

    public setAccidentDate(accidentDate: Date): void {
        this.accidentDate = accidentDate;
    }

    public getAccidentAddress(): string {
        return this.accidentAddress;
    }

    public setAccidentAddress(accidentAddress: string): void {
        this.accidentAddress = accidentAddress;
    }

    public getAccidentDescription(): string {
        return this.accidentDescription;
    }

    public setAccidentDescription(accidentDescription: string): void {
        this.accidentDescription = accidentDescription;
    }

    public getOccupation(): string {
        return this.occupation;
    }

    public setOccupation(occupation: string): void {
        this.occupation = occupation;
    }

    public getOccupationDesc(): string {
        return this.occupationDesc;
    }

    public setOccupationDesc(occupationDesc: string): void {
        this.occupationDesc = occupationDesc;
    }

    public getOccupationOthers(): string {
        return this.occupationOthers;
    }

    public setOccupationOthers(occupationOthers: string): void {
        this.occupationOthers = occupationOthers;
    }

    public getJobTitle(): string {
        return this.jobTitle;
    }

    public setJobTitle(jobTitle: string): void {
        this.jobTitle = jobTitle;
    }

    public getCountryIncidentOccur(): string {
        return this.countryIncidentOccur;
    }

    public setCountryIncidentOccur(val: string): void {
        this.countryIncidentOccur = val;
    }

    public getCountryIncidentOccurDesc(): string {
        return this.countryIncidentOccurDesc;
    }

    public setCountryIncidentOccurDesc(val: string): void {
        this.countryIncidentOccurDesc = val;
    }

    static jsonConvert(accidentDetails: PaAccidentDetails): PaAccidentDetails {

    
        if (accidentDetails.getAccidentDate() != null) {
            accidentDetails.setAccidentDate(new Date(accidentDetails.getAccidentDate()));
        }
    
        return accidentDetails;
    }

}