import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {UiBaseComponent} from "../ui-base.component";
import {AbstractControl, Validators} from '@angular/forms';

import { UtilitiesService } from 'src/app/utilities/utilities.service';
declare var $: any;

@Component({
    selector: 'qnect-ui-phonenumber',
    templateUrl: './ui-phonenumber.component.html',
    styleUrls: ['../ui-base.component.scss', 'ui-phonenumber.component.css']
})
export class UiPhoneNumberComponent extends UiBaseComponent implements OnInit{

    @ViewChild('inputField', {static: true}) inputField: ElementRef;

    countryCodeFormControl: AbstractControl;
    countryCodeSelectedText: string = '';
    amountFieldSize: string = '12';

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.uiModel.countryCodeFieldName) {
            this.countryCodeFormControl = this.formGroup.get(this.uiModel.countryCodeFieldName);
      
            let validatorList = [];
            if (this.uiModel.required) validatorList.push(Validators.required);
            if (this.uiModel.maxlength) validatorList.push(Validators.maxLength(this.uiModel.maxlength));
            if (this.uiModel.validationRegex) validatorList.push(Validators.pattern(this.uiModel.validationRegex));
            this.formControl.setValidators(validatorList);
      
            this.setCountryCodeSelectedText();
            this.countryCodeFormControl.valueChanges.subscribe(data => {
              this.setCountryCodeSelectedText();
            });
          }
    }

    getDivRemainder() : number {
        return 12-parseInt (this.amountFieldSize)-4;
    }

    countryCodeFieldChange() {
        this.setCountryCodeSelectedText();
    }

    setCountryCodeSelectedText() {
        this.uiModel.countryCodeOptions.forEach(each => {
            if (this.countryCodeFormControl.value === each.value) {
              this.countryCodeSelectedText = each.value;
              // break loop
              return false;
            }
        });
      }

    countryCodeFieldChangeHandler(value: string) {
        if (!UtilitiesService.isNullOrUndefined(value)) {
            this.countryCodeSelectedText = value;
            this.countryCodeFormControl.setValue(value);
        }
    }
}