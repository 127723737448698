import {Component, Injector, OnInit, ViewChild, HostListener, TemplateRef} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ClaimService} from "../services/claim.service";
import {Claim} from "../model/claim.model";
import {IntegrationService} from "../services/integrationservice";
import {ActivatedRoute, Router} from "@angular/router";
import {ClaimsBaseComponent} from "../claim-pages/claim-base.component";
import {TransactionInfoService} from "../services/transaction-info.service";
import {takeUntil} from "rxjs";
import {Notifications} from "../utilities/components/notification-messages/notifications.model";
import { LangChangeEvent } from "@ngx-translate/core";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TransactionInfo} from "../model/transaction-info.model";
import {UiComponent} from "../ui/ui.component";
import {GoogleAnalyticsService} from '../services/google-analytics.service';
import * as moment from "moment";
import {ClaimMotor} from '../model/claim-motor.model';
import {environment} from "../../environments/environment";
import {UiOptionsModel} from "../ui/ui-options.model";
import { Product } from "../model/product.model";
import { PolicyVerifyForm } from "../model/policy-verify-form.model";
import { DateService } from "../ui/ui-datepicker/date.service";
import { UtilitiesService } from "../utilities/utilities.service";
import { PolicyAuthJson } from "../model/policy.auth-model";
import {OtpBoxComponent} from '../otp-box/otp-box.component';
import {UserService} from '../services/user.service';
import { ForgotPasswordModalComponent } from '../utilities/components/forgot-password-modal/forgot-password-modal';

declare var $: any;

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.css']
})
export class HomepageComponent extends ClaimsBaseComponent implements OnInit {
    public isOpenTerms = false;
    claim: Claim;
    homePageFormGroup: FormGroup;
    motorHomePageFormGroup: FormGroup;
    notifications: Notifications;
    videoEmbed: SafeResourceUrl;
    videoEmbedId: string;
    videoEmbedImage: string;
    videoShow: boolean = false;
    errorMessages: any[];
    autoPlay: number = 0;
    transactionInfo: TransactionInfo;
    isShowAdvisoryNotif: boolean = false;
    isPolicyHolder: boolean = true;
    claimStatusEntryUrl: string;
    policyWordingPDF : string;
    faqLabel : string;
    public entryUrl: string;
    uiOptions: UiOptionsModel[] = [];
    imageBannerClass: string;
    otpToken: string = "";
    showOtpError: boolean = false;
    otp: string = "";
    maskedEmailAddress: string = "";
    infoMessage: string = "";
    subInfoMessage: string = "";

    @ViewChild('POLICY_NUMBER_VALIDATOR', {static: false, read: UiComponent}) uipolicyNumberField: UiComponent;
    @ViewChild('MV_REPAIRER_PASSWORD', {static: false, read: UiComponent}) uiRepairerPasswordField: UiComponent;
    @ViewChild('MV_REPAIRER_EMAIL', {static: false, read: UiComponent}) uiRepairerEmail: UiComponent;
    @ViewChild('modal_otp', {static: true}) otpBox: OtpBoxComponent;
    @ViewChild('modal_info', {static: true}) private infoModal: TemplateRef<any>;
    @ViewChild('modal_user_locked', {static: true}) private lockedModal: TemplateRef<any>;
    @ViewChild('forgot_password_modal', {static: true}) forgotPasswordModal: ForgotPasswordModalComponent;

    private googleAnalyticsService: GoogleAnalyticsService;
    private transactionInfoService : TransactionInfoService;
    private claimService: ClaimService;
    private dateService: DateService;
    private userService: UserService;

    constructor(private fb: FormBuilder,
                public integrationService: IntegrationService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private modalService: NgbModal,
                private sanitizer: DomSanitizer,
                private injector : Injector) {

        super(injector);
        //Service
        this.googleAnalyticsService = this.injector.get(GoogleAnalyticsService);
        this.transactionInfoService = this.injector.get(TransactionInfoService);
        this.claimService = this.injector.get(ClaimService);
        this.dateService = this.injector.get(DateService);
        this.userService = this.injector.get(UserService);
        this.claim =  this.claimService.getClaim();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.isPolicyHolder = !this.transactionInfo.getIsRepairer();
        this.hideShowAdvisoryMessage();
    }

    ngOnInit() {

        if (this.transactionInfo.isMotorProduct()) {
            let claimMotor = this.claim.getClaimMotor();
            if (UtilitiesService.isNullOrUndefined(claimMotor)) {
                this.claim.setClaimMotor(new ClaimMotor());
            }
            this.motorHomePageFormGroup = this.fb.group(
                {
                    policyNumber: [this.claim.getPolicyNumber()],
                    coverNoteNumber: [this.claim.getCoverNoteNumber()],
                    dateOfLoss: [this.claim.getDateOfLoss()],
                    repairerEmail: [''],
                    repairerPassword: ['']
                }
            );
            this.resetCoverPolicyNumber("motorPolicyNumber");
        } else {
            this.homePageFormGroup = this.fb.group(
                {
                    policyNumber: [this.claim.getPolicyNumber()],
                    dateOfLoss: [this.claim.getDateOfLoss()]
                }
            );
        }

        this.videoEmbed = this.getVideoEmbed(this.translate.currentLang);
        this.videoEmbedId = this.getVideoEmbedId(this.translate.currentLang);
        this.videoEmbedImage = "https://img.youtube.com/vi/"+ this.videoEmbedId + "/hqdefault.jpg";

        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
        this.pushGAHomePageView();

        this.transactionInfoService.getDocumentTitle(this.transactionInfo.getPolicyType());
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();

        this.imageBannerClass = this.getImageBannerClass();

    }

    ngAfterViewInit() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.autoPlay = 0;
            this.videoEmbed = this.getVideoEmbed(this.translate.currentLang);
            this.videoEmbedId = this.getVideoEmbedId(this.translate.currentLang);
            this.videoEmbedImage = "https://img.youtube.com/vi/"+ this.videoEmbedId +"/hqdefault.jpg";
            this.transactionInfoService.getDocumentTitle(this.transactionInfo.getPolicyType());
            this.setFAQ();
            this.setClaimStatusUrl();
           
            if(this.translate.currentLang == 'en'){
                $('.claim-status-btn').css('text-align','left');
                $('.claim-FAQ-btn').css('text-align','left');
            }else{
                $('.claim-status-btn').css('text-align','center');
                $('.claim-FAQ-btn').css('text-align','center');
            } 
        });

        let self = this;
        setTimeout(() => {
            self.setFAQ();
            self.setClaimStatusUrl();
        }, 100);
    }

    showVideo() {
        this.videoShow = true;
        this.autoPlay = 1;
        $("#youtube-play-button").removeClass('play-button');
        this.videoEmbed = this.getVideoEmbed(this.translate.currentLang);
        this.videoEmbedId = this.getVideoEmbedId(this.translate.currentLang);
    }

    resetCoverPolicyNumber(fieldName: string) {
        if (fieldName  == 'coverNoteNumber') {
            this.claim.setIsCoverNotePreNumbered("CN");
            this.motorHomePageFormGroup.get('coverNoteNumber').enable({onlySelf: false, emitEvent: false});
            this.motorHomePageFormGroup.get('policyNumber').reset();
            this.motorHomePageFormGroup.get('policyNumber').disable({onlySelf: false, emitEvent: false});
            $('#motorPolicyNumberDiv').removeClass('active show');
            $('#coverNoteNumberDiv').addClass('active show');
        } else {
            this.claim.setIsCoverNotePreNumbered(null);
            this.motorHomePageFormGroup.get('policyNumber').enable({onlySelf: false, emitEvent: false});
            this.motorHomePageFormGroup.get('coverNoteNumber').reset();
            this.motorHomePageFormGroup.get('coverNoteNumber').disable({onlySelf: false, emitEvent: false});
            $('#motorPolicyNumberDiv').addClass('active show');
            $('#coverNoteNumberDiv').removeClass('active show');
        }
    }

    goToNext() {

        this.pushGAHomePageOnClick();
        if (this.validateForm()) {
            this.updateBasicClaimInfo();
            let policyNumber = this.claim.getPolicyNumber();

            if (policyNumber && policyNumber == 'tst1234') {
                let testProduct = new Product();
                testProduct.setProductType("PAM")
                this.transactionInfo.setProduct(testProduct);
                this.router.navigate(["/claimform/getStart"], {
                    relativeTo: this.activatedRoute
                });
            } else if (policyNumber) {
                this.notifications.clearMessages();
                let policyType = this.transactionInfo.getPolicyType();
                let loggingId = this.transactionInfo.getLoggingId();

                let policyVerifyForm: PolicyVerifyForm = new PolicyVerifyForm();
                policyVerifyForm.setCountry(this.claim.getCountry());
                policyVerifyForm.setPolicyNumber(policyNumber.toUpperCase());
                policyVerifyForm.setDateOfLoss(this.dateService.dateToString(this.claim.getDateOfLoss(), "dd/MM/yyyy"));
                policyVerifyForm.setPolicyType(policyType);
                policyVerifyForm.setLoggingId(loggingId);
                policyVerifyForm.setIsCoverNotePreNumbered(this.claim.getIsCoverNotePreNumbered());
                policyVerifyForm.setIsRepairer(!this.isPolicyHolder);

                if(policyType!="generic"){
                    this.integrationService.checkPolicyNumber(policyVerifyForm)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe({
                            next: (policyAuth: PolicyAuthJson) => {
                                if (policyAuth.getErrorStr() == null || policyAuth.getErrorStr() == '') {
                                    let claim: Claim = this.claimService.getClaim();
                                    claim.setProduct(policyAuth.getProductType());
                                    claim.setCountry(policyAuth.getCountry());
                                    this.transactionInfo.setServiceUnit(policyAuth.getServiceUnit());

                                    // Motor - windscreen repairer or pre-numbered / cover note
                                    if (policyType == "motor") {
                                        let isCoverNotePreNumbered: string = this.isNotNullOrUndefinedStr(policyAuth.getIsCoverNotePreNumbered()) ?
                                            policyAuth.getIsCoverNotePreNumbered() : "";
                                        claim.setIsCoverNotePreNumbered(isCoverNotePreNumbered);
                                        if (!this.isPolicyHolder && this.isNotNullOrUndefinedStr(this.motorHomePageFormGroup?.value?.repairerEmail) &&
                                            this.isNotNullOrUndefinedStr(this.motorHomePageFormGroup?.value?.repairerPassword)) {
                                            this.verifyUserCredentials();
                                        } else {
                                            this.router.navigate(["/claimform/getStart"], {
                                                relativeTo: this.activatedRoute
                                            });
                                        }
                                    } else {
                                        this.router.navigate(["/claimform/getStart"], {
                                            relativeTo: this.activatedRoute
                                        });
                                    }

                                } else {
                                    if(policyAuth.getErrorCode() == 'P03'){
                                        this.uipolicyNumberField.error("invalidProduct");
                                        throw new Error("PLCYERR - P03 - Error Looking up policy - checkPolicyNumber - invalid product");
                                    } else {
                                        this.uipolicyNumberField.error("notfound");
                                        throw new Error("PLCYERR - notFound " + policyAuth.getErrorCode() + " - Error Looking up policy - checkPolicyNumber");
                                    }
                                }
                            },
                            error: (err: any) => {
                                this.uipolicyNumberField.error("notfound");
                                throw new Error("PLCYERR - notfound all -  Error Looking up policy - checkPolicyNumber");
                            }
                        });
                } else {
                    this.integrationService.checkPolicyNumberGeneric(policyVerifyForm)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe({
                            next: (policyAuth: PolicyAuthJson) => {
                                if (policyAuth.getErrorStr() == null || policyAuth.getErrorStr() == '') {
                                    this.transactionInfo.setProductByString(policyAuth.getProductType());
                                    this.transactionInfo.setCountry(policyAuth.getCountry());
                                    this.transactionInfo.setServiceUnit(policyAuth.getServiceUnit());
                                    this.transactionInfo.setProductTypes(policyAuth.getProductTypes());
                                    this.transactionInfo.setCatalogueCode(policyAuth.getCatalogueCode());

                                    this.router.navigate(["/claimform/getStart"], {
                                        relativeTo: this.activatedRoute
                                    });
                                } else {
                                    if(policyAuth.getErrorCode() == 'P03'){
                                        this.uipolicyNumberField.error("invalidProduct");
                                        throw new Error("PLCYERR - P03 - Error Looking up policy - checkPolicyNumber - invalid product");
                                    } else {
                                        this.uipolicyNumberField.error("notfound");
                                        throw new Error("PLCYERR - notFound " + policyAuth.getErrorCode() + " - Error Looking up policy - checkPolicyNumber");
                                    }
                                }
                            },
                            error: (err: any) => {
                                this.uipolicyNumberField.error("notfound");
                                throw new Error("PLCYERR - notfound all -  Error Looking up policy - checkPolicyNumber");
                            }
                        });
                }
            }
        }
    }

    verifyUserCredentials() {
        this.userService.verifyUserCredentials(this.transactionInfo.getCountry(),
            this.motorHomePageFormGroup.value.repairerEmail, this.motorHomePageFormGroup.value.repairerPassword, 'CP')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response?.success == "true" && response.token) {
                        this.maskedEmailAddress = UtilitiesService.maskEmail(this.motorHomePageFormGroup.value.repairerEmail);
                        this.otpToken = response.token;
                        this.otpBox.open();
                    } else {
                        if (response.lockType) {
                            this.modalService.dismissAll();
                            this.openInfoModal(response.lockType);
                        } else if (response.errorcode)  {
                            let errorCode = response.errorcode?.toLowerCase();
                            if (errorCode == "err02" || errorCode == "err03") {
                                this.uiRepairerEmail.error(errorCode);
                            } else if (errorCode == "err04") {
                                this.uiRepairerPasswordField.error(errorCode);
                            } else {
                                this.uiRepairerEmail.error(errorCode);
                                this.uiRepairerPasswordField.error(errorCode);
                            }
                        }

                    }
                },
                error: () => {
                    this.uiRepairerEmail.error("err01");
                    this.uiRepairerPasswordField.error("err01");
                }
            });
    }

    // resend otp
    sendOtp() {
        this.showOtpError = false;
        this.userService.checkEmailIfRegistered(this.otpToken, this.motorHomePageFormGroup.value.repairerEmail, this.transactionInfo.getCountry())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.isExisting == "true" && response.token) {
                        this.otpToken = response.token;
                        this.otpBox.open();
                    } else {
                        if (response.locked) {
                            this.modalService.dismissAll();
                            this.openInfoModal("otp");
                        } else {
                            this.showOtpError = true;
                        }
                    }
                },
                error: (error: any) => {
                    this.openInfoModal(error);
                }
            });
    }

    verifyOtp(otp: string) {
        this.userService.verifyOtp(this.otpToken, otp, this.motorHomePageFormGroup.value.repairerEmail,
            this.transactionInfo.getCountry(), 'login')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (response: any) => {
                    if (response.success && response.userDetails?.efnolRepairerId) {
                        this.otpBox.dismiss();
                        this.claim.getClaimMotor().setRepairerId(response.userDetails.efnolRepairerId);
                        this.claim.getClaimMotor().setRepairerName(response.userDetails.provider);

                        this.router.navigate(["/claimform/getStart"], {
                            relativeTo: this.activatedRoute
                        });
                    } else {
                        if (response.locked) {
                            this.modalService.dismissAll();
                           this.openInfoModal("otp");
                        } else {
                            this.showOtpError = true;
                        }
                    }
                },
                error: (error: any) => {
                    this.openInfoModal(error);
                }
            });
    }

    openInfoModal(type: string) {
        if (type == "password") {
            this.infoMessage = "motorClaim.homePage.emailAddressLocked";
            this.subInfoMessage ="motorClaim.homePage.emailAddressLockedPassword";
        } else if (type == "otp") {
            this.infoMessage = "motorClaim.homePage.emailAddressLocked";
            this.subInfoMessage ="motorClaim.homePage.emailAddressLockedOtp";
        } else if (type == "forgotpassword-success") {
            this.infoMessage = "forgotPassword.submitSuccessMessage";
            this.subInfoMessage ="";
        } else if (type == "forgotpassword-error") {
            this.infoMessage = "forgotPassword.errorMessages.submitFailedMessage";
            this.subInfoMessage ="";
        } else {
            this.infoMessage = "registration.genericError";
            this.subInfoMessage  = "";
        }

        this.modalService.open(this.infoModal, {size: 'md', backdrop: 'static', keyboard: false});
    }

    validateForm(): boolean {
        if (this.transactionInfo.isMotorProduct()) {
            return super.validateForm(this.motorHomePageFormGroup);
        } else {
            return super.validateForm(this.homePageFormGroup);
        }
    }

    updateBasicClaimInfo() {
        if (this.transactionInfo.isMotorProduct()) {
            let claimMotor = this.claim.getClaimMotor();
            let isCoverNoteOrPreNumbered = this.claim.getIsCoverNotePreNumbered();
            let motorPolicyNumber = this.motorHomePageFormGroup.value.policyNumber ? this.motorHomePageFormGroup.value.policyNumber : "";
            if (!UtilitiesService.isNullOrUndefined(isCoverNoteOrPreNumbered) && isCoverNoteOrPreNumbered) {
                motorPolicyNumber = this.motorHomePageFormGroup.value.coverNoteNumber;
            }
            this.claim.setPolicyNumber(motorPolicyNumber);
            claimMotor.setIsLodgeByPolicyHolder(this.isPolicyHolder);
        } else {
            this.claim.setPolicyNumber(this.homePageFormGroup.value.policyNumber);
        }
        this.claim.setDateOfLoss(super.getValueInComponent('dateOfLoss'));
    }

    open(content, name) {
        this.modalService.open(content, {size: 'lg'});
    }

    hideShowAdvisoryMessage() {
        this.isShowAdvisoryNotif = false;
        let dateTodayMoment = moment(new Date(), 'DD/MM/YYYY');

        let minDateString = this.translate.instant("advisoryNotification.minDate");
        let minDateMoment = moment(new Date(minDateString), 'DD/MM/YYYY');

        let maxDateString = this.translate.instant("advisoryNotification.maxDate");
        let maxDateMoment = moment(new Date(maxDateString), 'DD/MM/YYYY');

        if (dateTodayMoment.isBetween(minDateMoment, maxDateMoment, 'days', '[]')) {
            this.isShowAdvisoryNotif = true;
        }

    }

    isHolderOrRepairer(value: string) {
        if (value == "policyholder") {
            this.isPolicyHolder = true;
            this.motorHomePageFormGroup.get('repairerEmail').reset();
            this.motorHomePageFormGroup.get('repairerEmail').disable({onlySelf: false, emitEvent: false});
            this.motorHomePageFormGroup.get('repairerPassword').reset();
            this.motorHomePageFormGroup.get('repairerPassword').disable({onlySelf: false, emitEvent: false});
            this.pushGACustomerClaim();
        } else {
            this.isPolicyHolder = false;
            this.motorHomePageFormGroup.get('repairerEmail').enable({onlySelf: false, emitEvent: false});
            this.motorHomePageFormGroup.get('repairerPassword').enable({onlySelf: false, emitEvent: false});
            this.pushGARepairerClaim();
        }
    }

    //START - Google Analytics
    pushGAHomePageView() {
        let utmSource = this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmSource()) ?
            this.googleAnalyticsService.getUtmSource() : "N/A" ;
        let utmMedium = this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmMedium()) ?
            this.googleAnalyticsService.getUtmMedium() : "N/A" ;
        let utmCampaign =  this.isNotNullOrUndefinedStr(this.googleAnalyticsService.getUtmCampaign()) ?
            this.googleAnalyticsService.getUtmCampaign() : "N/A" ;

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Home',
            'vPath': '/home',
            'utm_source': utmSource,
            'utm_medium': utmMedium,
            'utm_campaign': utmCampaign,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 1}	//Step1-Home
                }
            }
        });
    }

    pushGAHomePageOnClick() {
        this.pushGAOnClick('start');
    }

    pushGACustomerClaim() {
        this.pushGAOnClick('customerclaim');
    }

    pushGARepairerClaim() {
        this.pushGAOnClick('repairerclaim');
    }

    pushGAOnClick(eventInput: string) {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': eventInput});
    }
    //END - Google Analytics

    getVideoEmbed(currentLang: string): SafeResourceUrl {
        return  this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.getVideoEmbedId(currentLang) + '?rel=0&showinfo=0&autoplay=' + this.autoPlay);
    }

    getVideoEmbedId(currentLang: string): string {
        let policyType = this.transactionInfo.getPolicyType() === 'motor' ?
            this.transactionInfo.getPolicyType() : '';
        let videoId = this.transactionInfo.getCountry().toLowerCase() + "_" +
            currentLang + (policyType === 'motor' ? "_" + policyType : '');
        return environment.videoId[videoId];
    }

    setFAQ() {
        let policyType = this.transactionInfo.getPolicyType();
        if (this.isNotNullOrUndefinedStr(policyType)) {
            let faqPdfUrl = policyType.toLowerCase() + "_" + this.transactionInfo.getCountry()?.toLowerCase() + "_" +
                this.translate.currentLang;
            this.policyWordingPDF = environment.faq[faqPdfUrl];
        }
    }

    setClaimStatusUrl() {
        let baseUrl = document.location.origin + document.location.pathname;
        if (!UtilitiesService.isNullOrUndefined(baseUrl) && baseUrl != "") {
            baseUrl += "?";
            let countryParamValue = "country=" + this.transactionInfo.getCountry();
            let languageParam = this.getLanguageParamValue();
            let productParam = this.getProductParamValue();
            this.claimStatusEntryUrl = baseUrl + countryParamValue + productParam + languageParam + "&action=claimstatus";
        }
    }

    getLanguageParamValue() {
        if (this.transactionInfo.getCountry() === 'HKG' && this.translate.currentLang) {
            return "&language=" + this.translate.currentLang;
        }
        return '';
    }

    getProductParamValue() {
        let product = this.transactionInfo.getProduct();
        if (!UtilitiesService.isNullOrUndefined(product) && !UtilitiesService.isNullOrUndefined(product.getProductTypeDesc())) {
            let productDesc = product.getProductTypeDesc().toLowerCase();
            if (productDesc !== 'generic') {
                if (productDesc === 'domestic helper') {
                    return '&product=helper';
                } else if (productDesc === 'motors'){
                    return '&product=motor';
                } else {
                    return "&product=" + productDesc;
                }
            }

        }
        return '';
    }

    getImageBannerClass() {
        if (this.transactionInfo.isDomesticHelperProduct()) {
            return 'home-dmh';
        } else if (this.transactionInfo.isMotorProduct()){
            return 'home-motor';
        } else if (this.transactionInfo.isHomeProduct()) {
            return 'home-home'
        } else if (this.transactionInfo.isAccidentProduct()) {
            return 'home-pa'
        } else if (this.transactionInfo.isEmployeeCompensationProduct()) {
            return 'home-ec'
        } else {
            return 'home';
        }
    }

    mouseX = 0;
    mouseY = 0;
    tooltipText = ''

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e: MouseEvent) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY + window.scrollY ;
    }

    showTooltip(txt: string) {
        this.tooltipText = txt;
    }

    hideToolTip() {
        this.tooltipText = '';
    }

    onClickNotClose(e: any) {
        e.stopPropagation();
    }

    handleForgotPassword(email: string) {
        this.userService.sendResetPasswordLink(email)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (result: any) => {
                        if (this.modalService.hasOpenModals()) {
                            this.modalService.dismissAll();
                        }

                        if (result=== 'SUCCESS') {
                            this.openInfoModal("forgotpassword-success");
                        } else if (result === 'FAILED') {
                            this.openInfoModal("forgotpassword-error");
                        }
                    },
                    error: (err: any) => {
                        this.modalService.dismissAll();
                        this.openInfoModal(err);
                    }
            }
        );
    }

}