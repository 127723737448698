import {ClaimTypeSubMenu} from "./claim-type-submenu.model";

export class ClaimTypeMenu {

    claimTypeId: number = null ;
    claimTypeIdName = '';
    hidden: boolean = null;
    complete: boolean = null;
    subMenus: ClaimTypeSubMenu[] = [];

    static jsonConvert(claimTypeMenu: ClaimTypeMenu): ClaimTypeMenu {

        claimTypeMenu = Object.assign(new ClaimTypeMenu(), claimTypeMenu);

        if (claimTypeMenu.subMenus != null) {
            let claimTypeSubMenu: ClaimTypeSubMenu[] = [];

            for (const claimTypeSubMenuData of claimTypeMenu.subMenus) {
                let claimSubmenuItem= Object.assign(new ClaimTypeSubMenu(), claimTypeSubMenuData);
                claimSubmenuItem = ClaimTypeSubMenu.jsonConvert(claimSubmenuItem);
                claimTypeSubMenu.push(claimSubmenuItem);
            }
            claimTypeMenu.subMenus = claimTypeSubMenu;
        }

        return claimTypeMenu;
    }

}
