import {Component, Injector, OnInit, HostListener, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { ECAccidentDetails } from 'src/app/model/employee-compensation/ec-accident-details.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {takeUntil} from 'rxjs/operators';
import {TransactionInfo} from '../../../model/transaction-info.model';
import {SideMenuService} from '../../../services/side-menu.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BodyPartsComponent} from '../injured-body-parts/body-parts/body-parts.component';

@Component({
    selector: 'app-ec-injury-details',
    templateUrl: './ec-injury-details.component.html',
    styleUrls: ['./ec-injury-details.component.css']
})
export class ECInjuryDetailsComponent extends ClaimsBaseComponent implements OnInit {

    injuryDetailsForm: FormGroup;
    accidentDetails: ECAccidentDetails;
    showTotalBox = false;
    tooltipText = '';
    mouseX = 0;
    mouseY = 0;
    currentClaimType: string;
    transactionInfo: TransactionInfo;
    @ViewChild('bodyParts', {static: false}) bodyPartsComponent: BodyPartsComponent;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {
        super(injector);
        this.accidentDetails = this.claim.getClaimEmployeeCompensation().getAccidentDetails();
    }

    ngOnInit() {
        this.injuryDetailsForm = this.fb.group({
            accidentType: [this.accidentDetails.getAccidentType()],
            accidentTypeOthers: [this.accidentDetails.getAccidentTypeOthers()],
            fallFromHeightOf: [this.accidentDetails.getFallFromHeightOf()],
            diagnosis: [this.accidentDetails.getInjuryDiagnosis()],
            description: [this.accidentDetails.getInjuryDescription()],
            isAgentInvolved: [super.getBooleanString(this.accidentDetails.getIsAgentInvolved())],
            isTreatmentReceived: [super.getBooleanString(this.accidentDetails.getIsTreatmentReceived())],
            treatmentType: [this.accidentDetails.getTreatmentType()],
            agentsInvolved: [this.accidentDetails.getAgentsInvolved()],
            agentsInvolvedOthers: [this.accidentDetails.getAgentsInvolvedOthers()],
            otherMachineryType: [this.accidentDetails.getOtherMachineryType()],
            partCausingInjury: [this.accidentDetails.getPartCausingInjury()],
            agentsDescription: [this.accidentDetails.getAgentsDescription()],
        });
    }

    ngAfterViewInit() {
        this.injuryDetailsForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.accidentDetails.setAccidentType(this.injuryDetailsForm.get('accidentType').value);
                this.accidentDetails.setAccidentTypeDesc(super.getTextInComponent('accidentType'));
                this.accidentDetails.setAccidentTypeOthers(this.injuryDetailsForm.get('accidentTypeOthers').value);
                this.accidentDetails.setFallFromHeightOf(this.injuryDetailsForm.get('fallFromHeightOf').value);
                this.accidentDetails.setInjuryDiagnosis(this.injuryDetailsForm.get('diagnosis').value);
                this.accidentDetails.setInjuryDescription(this.injuryDetailsForm.get('description').value);
                this.accidentDetails.setIsAgentInvolved(this.injuryDetailsForm.get('isAgentInvolved').value == 'true');
                this.accidentDetails.setIsTreatmentReceived(this.injuryDetailsForm.get('isTreatmentReceived').value == 'true');
                this.accidentDetails.setTreatmentType(super.getMultiSelectDropdownValue('treatmentType'));
                this.accidentDetails.setAgentsInvolved(super.getMultiSelectDropdownValue('agentsInvolved'));
                this.accidentDetails.setAgentsInvolvedOthers(this.injuryDetailsForm.get('agentsInvolvedOthers').value);
                this.accidentDetails.setOtherMachineryType(this.injuryDetailsForm.get('otherMachineryType').value);
                this.accidentDetails.setPartCausingInjury(super.getMultiSelectDropdownValue('partCausingInjury'));
                this.accidentDetails.setAgentsDescription(this.injuryDetailsForm.get('agentsDescription').value);
        });

        this.injuryDetailsForm.get('accidentType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateAccidentTypeOthers(data);
        });

        this.injuryDetailsForm.get('isAgentInvolved').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateIsAccidentInvolveAgents(data);
        });

        this.injuryDetailsForm.get('agentsInvolved').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateAgentsInvolvedOthers(data);
            this.validateAgentsInvolvedOtherMachinery(data);
        });
    }

    validateAccidentTypeOthers(fieldValue) {
        this.injuryDetailsForm.get('accidentTypeOthers').reset();
        this.injuryDetailsForm.get('accidentTypeOthers').disable({onlySelf: false, emitEvent: false});
        this.injuryDetailsForm.get('fallFromHeightOf').reset();
        this.injuryDetailsForm.get('fallFromHeightOf').disable({onlySelf: false, emitEvent: false});
        if (fieldValue == '4') {
            this.injuryDetailsForm.get('fallFromHeightOf').enable({onlySelf: false, emitEvent: false});
        } else if (fieldValue == '17_15') {
            this.injuryDetailsForm.get('accidentTypeOthers').enable({onlySelf: false, emitEvent: false});
        }
    }

    validateIsAccidentInvolveAgents(fieldValue) {
       if (fieldValue && fieldValue == 'true') {
           this.injuryDetailsForm.get('agentsInvolved').enable({onlySelf: false, emitEvent: false});
           this.injuryDetailsForm.get('agentsDescription').enable({onlySelf: false, emitEvent: false});
       } else {
           this.injuryDetailsForm.get('agentsInvolved').reset();
           this.injuryDetailsForm.get('agentsInvolved').disable({onlySelf: false, emitEvent: false});
           this.injuryDetailsForm.get('agentsDescription').reset();
           this.injuryDetailsForm.get('agentsDescription').disable({onlySelf: false, emitEvent: false});
       }
    }

    validateAgentsInvolvedOthers(agentsInvolved) {
        if (agentsInvolved && this.isAgentsInvolvedContain(agentsInvolved, 'other')) {
            this.injuryDetailsForm.get('agentsInvolvedOthers').enable({onlySelf: false, emitEvent: false});
        } else {
            this.injuryDetailsForm.get('agentsInvolvedOthers').reset();
            this.injuryDetailsForm.get('agentsInvolvedOthers').disable({onlySelf: false, emitEvent: false});
        }
    }

    validateAgentsInvolvedOtherMachinery(agentsInvolved) {
        if (agentsInvolved && this.isAgentsInvolvedContain(agentsInvolved, 'other machinery')) {
            this.injuryDetailsForm.get('otherMachineryType').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('partCausingInjury').enable({onlySelf: false, emitEvent: false});
        } else {
            this.injuryDetailsForm.get('otherMachineryType').reset();
            this.injuryDetailsForm.get('otherMachineryType').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('partCausingInjury').reset();
            this.injuryDetailsForm.get('partCausingInjury').disable({onlySelf: false, emitEvent: false});
        }
    }

    isAgentsInvolvedContain(agentsInvolved, type) {
        return agentsInvolved && agentsInvolved.find(item => item.value?.toLowerCase() === type);
    }


    validateForm(): boolean {
        return super.validateForm(this.injuryDetailsForm);
    }
    
    goToNext() {
        let validateBodyPartsForm = this.bodyPartsComponent.validateBodyPartsForm();
        if (this.validateForm() && validateBodyPartsForm) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 4});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/form2/employeeEarnings"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/form2/detailsOfAccident"], {
            relativeTo: this.activatedRoute
        });
    }

    open(content) {
        this.injector.get(NgbModal).open(content, {size: 'lg', backdrop: 'static', keyboard: false});
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(e: MouseEvent) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY + window.scrollY ;
    }

    showTooltip(txt: string) {
        this.tooltipText = txt;
    }

    hideToolTip() {
        this.tooltipText = '';
    }

    onClickNotClose(e: any) {
        e.stopPropagation();
    }
}
