import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { DocumentField } from "../../model/document-field";
import { ClaimTypes } from "../../model/claim-type.model";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class TravelReroutingFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.copyTicket', SmartqDocuments.AIRTICKET, true));
        requiredDocuments.push(new DocumentField('CARRIERREPORT', 'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.carrierCert', SmartqDocuments.REPORT, true));
        requiredDocuments.push(new DocumentField('TRAVELITINERARY', 'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.docsProof', SmartqDocuments.CORRESPONDENCE, true));
        if(UtilitiesService.checkForMinors(this.claim)){
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}
