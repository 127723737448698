<div class="form-group row ui-error" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && !formControl.valid && formControl.touched) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
  <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
  <div class="col-md-{{uiModel.size}}">
    <timepicker id="{{fieldId}}" formControlName="{{uiModel.name}}" [formControl]="formControl" [(ngModel)]="timeSelect"
                [showMeridian]="uiModel.twelveHourClock" [minuteStep]="minuteStep" [ngClass]="{'ui-disabled' : uiModel.disabled}"
                [readonlyInput]="formControl.disabled || uiModel.disabled"></timepicker>
    <ui-error *ngIf="formControl.errors" [inputErrors]="formControl.errors"
              [dictionary]="uiModel.dictionary"></ui-error>
  </div>
  <span *ngIf="uiModel.popover" class="{{uiModel.popover.icon}} ui-icon" role="button"
        [popover]="uiModel.popover.content" placement="right" triggers="mouseenter:mouseleave"></span>

</div>
