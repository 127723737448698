<main>
  <div *ngIf="this.isShowAdvisoryNotif" class="container-fluid">
    <div class="alert alert-info alert-dismissible fade show" role="alert">
      <div class="home-wrap-1 container">
        <div class="row">
          <div class="col-1 col-lg-1 col-xs-6 notice-img">
            <img  src="assets/images/maintenance_icon.png" width="45" >
          </div>
          <div class="col-11 col-lg-11 col-xs-6 notice-desc">
            <div class="notice-title">{{'advisoryNotification.title' | translate}}</div>
            <div class="notice-content">{{'advisoryNotification.content' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section [ngClass]="this.imageBannerClass">
    <div *ngIf="!transactionInfo.isDomesticHelperProduct()" class="bg-overlay" id="bg-overlay-id"></div>
    <div class="home-wrap container">
      <h1 *ngIf="transactionInfo.isTravelProduct()">{{ 'home.heading.travelInsuranceHeading' | translate }}</h1>
      <h1 *ngIf="transactionInfo.isDomesticHelperProduct()">{{ 'home.heading.domesticHelperHeading' | translate }}</h1>
      <h1 *ngIf="transactionInfo.isMotorProduct()">{{ 'motorClaim.homePage.motorInsuranceHeading' | translate }}</h1>
      <h1 *ngIf="transactionInfo.isGenericClaim()">{{ 'home.heading.genericInsuranceHeading' | translate }}</h1>
      <h1 *ngIf="transactionInfo.isHomeProduct()">{{ 'home.heading.homeInsuranceHeading' | translate }}</h1>
      <h1 *ngIf="transactionInfo.isAccidentProduct()">{{ 'home.heading.accidentInsuranceHeading' | translate }}</h1>
      <h1 *ngIf="transactionInfo.isEmployeeCompensationProduct()">{{ 'home.heading.ecInsuranceHeading' | translate }}</h1>
      <h2>{{ 'home.heading.title2' | translate }}</h2>
    </div>
  </section>
  <section class="home-claim-simple bg-grey text-left">
    <div class="container">
      <div [ngClass]="{'home-form' : !transactionInfo.isMotorProduct(), 'home-form-motor' : transactionInfo.isMotorProduct()}">
        <form>
          <div class="row index-form col-xs-12" *ngIf="!transactionInfo.isMotorProduct()">
            <div class="row col-lg-8">
              <div class="col-12">
                <h5 style="padding-left:15px;">{{ 'home.body.title' | translate }}</h5>
              </div>
              <div class="row col-12">
                <div *ngIf="this.transactionInfo.isTravelProduct()" class="col-12 form-group" class="col-lg-4">
                  <qnect-ui #POLICY_NUMBER_VALIDATOR [fieldId]="'TRVL_POLICY_NUMBER'" [formGroup]="homePageFormGroup"
                            name="policyNumber"></qnect-ui>
                  <small id="how-policy-number"
                         (click)="open(how_to_find_policy_number,'how_to_find_policy_number')">{{'home.howToFindMyPolicyNumber.findMyPolicyNumber' | translate}}</small>
                </div>
                <div *ngIf="this.transactionInfo.isDomesticHelperProduct()" class="col-12 form-group" class="col-lg-4">
                  <qnect-ui #POLICY_NUMBER_VALIDATOR [fieldId]="'DMH_POLICY_NUMBER'" [formGroup]="homePageFormGroup"
                            name="policyNumber"></qnect-ui>
                  <small id="how-policy-number"
                         (click)="open(how_to_find_policy_number,'how_to_find_policy_number')">{{'home.howToFindMyPolicyNumber.findMyPolicyNumber' | translate}}</small>
                </div>
                <div *ngIf="this.transactionInfo.isGenericClaim() || this.transactionInfo.isHomeProduct() ||
                           this.transactionInfo.isAccidentProduct() || this.transactionInfo.isEmployeeCompensationProduct()" class="col-12 form-group" class="col-lg-4">
                  <qnect-ui #POLICY_NUMBER_VALIDATOR [fieldId]="'GENERIC_POLICY_NUMBER'" [formGroup]="homePageFormGroup"
                            name="policyNumber"></qnect-ui>
                  <small id="how-policy-number"
                         (click)="open(how_to_find_policy_number,'how_to_find_policy_number')">{{'home.howToFindMyPolicyNumber.findMyPolicyNumber' | translate}}</small>
                </div>
                <div class="col-12 col-lg-4 form-group">
                  <qnect-ui #DATE_OF_LOSS [fieldId]="'DATE_OF_LOSS'" [formGroup]="homePageFormGroup" name="dateOfLoss">
                  </qnect-ui>
                </div>
                <div class="col-12 col-lg-4 form-group desktop-ver">
                  <label class="col-12">&nbsp;</label>
                  <button (click)="goToNext()" class="new-btn btn-primary btn" type="submit"
                          name="submit">&nbsp;{{ 'home.body.start' | translate }}&nbsp;<em class="fa fa-angle-right"></em></button>
                </div>
              </div>
            </div>
            <ng-container [ngTemplateOutlet]="sideButtons"></ng-container>
          </div>
          <div class="row-motor index-form col-xs-12" *ngIf="this.transactionInfo.isMotorProduct()">
            <div class="row row-mobile col-lg-9">
              <div class="col-12 div-padding">
                <h5 class="title-padding">{{ 'home.body.title' | translate }}</h5>
              </div>
              <div class="row col-12 row-mobile" *ngIf="!isPolicyHolder">
                <div id="repairerNameDiv" class="col-12 col-lg-5 form-group div-padding">
                  <qnect-ui #MV_REPAIRER_EMAIL [fieldId]="'MV_REPAIRER_EMAIL'" [formGroup]="motorHomePageFormGroup" name="repairerEmail"
                            [dynamicLabel] ="'motorClaim.homePage.userId'"></qnect-ui>
                </div>
                <div class="col-12 col-lg-4 form-group div-padding">
                  <qnect-ui #MV_REPAIRER_PASSWORD [fieldId]="'MV_REPAIRER_PASSWORD'" [formGroup]="motorHomePageFormGroup" name="repairerPassCode"></qnect-ui>
                  <small id="forgot-password" (click)="forgotPasswordModal.open()">{{'forgotPassword.title' | translate}}?</small>
                </div>
                <div class="col-12 col-lg-3">
                </div>
              </div>
              <div class="row col-12 row-mobile">
                <div id="policyCoverNoteDiv" class="col-12 col-lg-5 form-group div-padding">
                  <ul class="nav auth-nav-tabs ff-stag auth-selector">
                    <li><a data-toggle="tab" href="#motorPolicyNumberDiv" (click)="resetCoverPolicyNumber('motorPolicyNumber')"  [ngClass]="{'active': this.claim.getIsCoverNotePreNumbered() == null || this.claim.getIsCoverNotePreNumbered() == ''}">{{ 'motorClaim.homePage.policyNumber' | translate }}</a></li>
                    <li class="sep-padding"> | </li>
                    <li><a data-toggle="tab" href="#coverNoteNumberDiv" (click)="resetCoverPolicyNumber('coverNoteNumber')" [ngClass]="{'active': this.claim.getIsCoverNotePreNumbered() != null && this.claim.getIsCoverNotePreNumbered() != ''}">{{ 'motorClaim.homePage.coverNote' | translate }}</a></li>
                  </ul>
                  <div class="tab-content">
                    <div id="motorPolicyNumberDiv" class="tab-pane fade in active show">
                      <div class="form-group">
                        <qnect-ui #POLICY_NUMBER_VALIDATOR [fieldId]="'MV_POLICY_NUMBER'" [formGroup]="motorHomePageFormGroup" name="policyNumber"></qnect-ui>
                        <small id="how-policy-number" (click)="open(how_to_find_policy_number,'how_to_find_policy_number')">{{'home.howToFindMyPolicyNumber.findMyPolicyNumber' | translate}}</small>
                      </div>
                    </div>
                    <div id="coverNoteNumberDiv" class="tab-pane fade">
                      <div class="form-group">
                        <qnect-ui #MV_COVERNOTE_NUMBER [fieldId]="'MV_COVERNOTE_NUMBER'" [formGroup]="motorHomePageFormGroup" name="coverNoteNumber"></qnect-ui>
                        <small id="how-covernote-number" (click)="open(how_to_find_covernote_number,'how_to_find_covernote_number')">{{'home.howToFindMyCoverNoteNumber.findMyCoverNoteNumber' | translate}}</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4 form-group div-padding">
                  <qnect-ui #MV_DATE_OF_DMG [fieldId]="'MV_DATE_OF_DMG'" [formGroup]="motorHomePageFormGroup" name="dateOfLoss"></qnect-ui>
                </div>
                <div class="col-12 col-lg-3 form-group desktop-ver div-padding">
                  <label class="col-12">&nbsp;</label>
                  <button (click)="goToNext()" class="new-btn-motor btn-primary btn btn-repair" type="submit" name="submit">&nbsp;{{ 'home.body.start' | translate }}&nbsp;<em class="fa fa-angle-right"></em></button>
                </div>
              </div>
              <div class="row col-12 div-helper row-mobile">
                <div class="col-12 form-group div-padding">
                  <label class="col-12">&nbsp;</label>
                  <button *ngIf="isPolicyHolder" class="new-btn btn-helper btn" (click)="isHolderOrRepairer('repairer')" >{{ 'motorClaim.homePage.isRepairer' | translate }}</button>
                  <button *ngIf="!isPolicyHolder" class="new-btn btn-helper btn" (click)="isHolderOrRepairer('policyholder')" >{{ 'motorClaim.homePage.isPolicyHolder' | translate }}</button>
                </div>
              </div>
            </div>
            <ng-container [ngTemplateOutlet]="sideButtons"></ng-container>
          </div>
        </form>
        <div class="row mobile-ver text-center">
          <div class="col-12 form-group">
            &nbsp;<BR>
            <button (click)="goToNext()" class="new-btn btn-primary btn" type="submit" name="submit">
              {{ 'home.body.start' | translate }}
              <em class="fa fa-angle-right"></em>
            </button>
          </div>
          <ng-container [ngTemplateOutlet]="sideButtonsMobile"></ng-container>
        </div>
      </div>
      <ng-container *ngIf="transactionInfo.getCountry() !== 'MYS'" [ngTemplateOutlet]="documentsAndVideo"></ng-container>
      <ng-container *ngIf="transactionInfo.getCountry() === 'MYS'" [ngTemplateOutlet]="documentsAndQRMys"></ng-container>
    </div>
  </section>
</main>
<ng-template #how_to_find_policy_number let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title cursor-pointer">
      {{'home.howToFindMyPolicyNumber.findYourPolicyNumber' | translate}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="policy-num-p" style="font-size: 17px; text-align: left">
      {{'home.howToFindMyPolicyNumber.subheader1' | translate}}
    </p>
    <p class="policy-num-p" style="font-size: 17px; text-align: left">
      {{'home.howToFindMyPolicyNumber.subheader2' | translate}}
    </p>
    <div class="row content">
      <div class="col-5">
        <h4 class="combination-desc"><strong>{{'home.howToFindMyPolicyNumber.combination1' | translate}}</strong></h4>
        <p class="combination"><span class="combination-secondary">XXXX-</span><span
                class="combination-main">A1234567</span><span class="combination-secondary">-YYY</span></p>
        <small class="policy-number">{{'home.howToFindMyPolicyNumber.policyNumber' | translate}}</small>
      </div>
      <div class="col-2">
        <h1 class="output-or">{{'home.howToFindMyPolicyNumber.or' | translate}}</h1>
      </div>
      <div class="col-5">
        <h4 class="combination-desc"><strong>{{'home.howToFindMyPolicyNumber.combination2' | translate}}</strong></h4>
        <p class="combination"><span class="combination-secondary">XXX-</span><span
                class="combination-main">AA123456</span><span class="combination-secondary">-YYY</span></p>
        <small class="policy-number">{{'home.howToFindMyPolicyNumber.policyNumber' | translate}}</small>
      </div>
    </div>
    <div *ngIf="transactionInfo.isGenericClaim() && transactionInfo.getCountry() !== 'MYS'" class="row col-12 content">
      <p style="font-size: 17px; text-align: left">{{'home.howToFindMyPolicyNumber.g400Note' | translate}}</p>
    </div>
    <div *ngIf="transactionInfo.getCountry() === 'MYS'" class="row col-12 content" style="font-size: 17px; text-align: left"
         [innerHTML]="'home.howToFindMyPolicyNumber.mysNote' | translate">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light"
            (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
  </div>
</ng-template>
<ng-template #how_to_find_covernote_number let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title cursor-pointer">
      {{'home.howToFindMyCoverNoteNumber.findYourCoverNoteNumber' | translate}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="policy-num-p" style="font-size: 17px; text-align: left">
      {{'home.howToFindMyCoverNoteNumber.subheader1' | translate}}
    </p>
    <p class="policy-num-p" style="font-size: 17px; text-align: left">
      {{'home.howToFindMyCoverNoteNumber.subheader2' | translate}}
    </p>
    <div class="row content">
      <div class="col-5">
        <h4 class="combination-desc"><strong>{{'home.howToFindMyCoverNoteNumber.combination1' | translate}}</strong></h4>
        <p class="combination"><span class="combination-secondary">XXXX-</span><span
                class="combination-main">V1234567</span><span class="combination-secondary">-YYY</span></p>
      </div>
      <div class="col-2">
        <h1 class="output-or">{{'home.howToFindMyCoverNoteNumber.or' | translate}}</h1>
      </div>
      <div class="col-5">
        <h4 class="combination-desc"><strong>{{'home.howToFindMyCoverNoteNumber.combination2' | translate}}</strong></h4>
        <p class="combination"><span class="combination-secondary">MVC No.</span><span
                class="combination-main">CN123456</span><span class="combination-secondary"></span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-5"></div>
      <div class="col-2"></div>
      <div class="col-5">
        <p class="combination"><span class="combination-secondary">MVC No.</span><span
                class="combination-main">1234567</span><span class="combination-secondary"></span></p>
      </div>
    </div>
    <div class="row">
      <div class="col-5"><small class="policy-number">{{'home.howToFindMyCoverNoteNumber.coverNoteNumber' | translate}}</small></div>
      <div class="col-2"></div>
      <div class="col-5"><small class="policy-number">{{'home.howToFindMyCoverNoteNumber.coverNoteNumber' | translate}}</small></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light"
            (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
  </div>
</ng-template>
<ng-template #sideButtons let-c="close" let-d="dismiss">
  <div [ngClass]="{'col-lg-3': transactionInfo.isMotorProduct(), 'col-lg-4': !transactionInfo.isMotorProduct(),
      'section-divider-motor': transactionInfo.isMotorProduct(), 'section-divider': !transactionInfo.isMotorProduct()}"
       class="row col-md-12 col-sm-12 desktop-ver">
    <div class="row col-12" style="padding:0px;">
      <h5>{{ 'home.body.title2' | translate }}</h5>
    </div>
    <div class="row col-12">
         <span>
         <a href="{{claimStatusEntryUrl}}" class="new-btn claim-status-btn btn" role="button"><img class="img-responsive" style="max-width:25px" src="assets/images/status.png" alt="">&nbsp; {{'claimStatus.title' | translate }}</a>
         </span>
    </div>
    <div class="row col-12">
      <span><a href="{{policyWordingPDF}}" class="new-btn claim-FAQ-btn btn" target="_blank" rel="noopener"><img class="img-responsive" style="max-width:25px" src="assets/images/faq.png" alt="">&nbsp; {{'home.heading.faqLabel' | translate}} &nbsp;</a></span>
    </div>
  </div>
</ng-template>
<ng-template #sideButtonsMobile let-c="close" let-d="dismiss">
  <div class="row col-lg-4 col-md-12 col-sm-12 mobile-ver mobile-side-buttons">
    <div class="row col-12">
      <p class="mobile-border"></p>
    </div>
    <BR>
    <div class="row col-12">
      <h5>{{ 'home.body.title2' | translate }}</h5>
    </div>
    <div class="row col-12">
         <span>
         <a href="{{claimStatusEntryUrl}}" class="new-btn claim-status-btn btn" role="button"><img class="img-responsive" style="max-width:25px" src="assets/images/status.png" alt="">&nbsp; {{'claimStatus.title' | translate }}</a>
         </span>
    </div>
    <div class="row col-12">
      <span><a href="{{policyWordingPDF}}" class="new-btn claim-FAQ-btn btn" target="_blank" rel="noopener"><img class="img-responsive" style="max-width:25px" src="assets/images/faq.png" alt="">&nbsp; {{'home.heading.faqLabel' | translate}} &nbsp;</a></span>
    </div>
    <BR>
    <BR>
  </div>
</ng-template>
<ng-template #documentsAndVideo>
  <div class="row col-12 row-mobile">
    <div class="resp-container col-lg-8 col-md-8">
      <div class="youtube">
        <div class="play-button" (click)="showVideo()" id="youtube-play-button"></div>
        <img class="img-responsive" *ngIf="!videoShow" src="{{videoEmbedImage}}" alt="">
        <div *ngIf="videoShow">
          <iframe class="resp-iframe" [src]="videoEmbed" title="" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4">
      <documents-to-prepare [transactionInfo]="transactionInfo" [isPolicyHolder]="isPolicyHolder"></documents-to-prepare>
    </div>
  </div>
</ng-template>
<ng-template #documentsAndQRMys>
  <div class="div-wrapper mb">
    <div class="row col-xs-12">
      <div class="row col-lg-8">
        <div class="dmg-personal-item">
          <div class="dmg-txt-wrap">
            <span><img src="./assets/images/motor-insurance.png" alt="motor-insurance"/></span>
            <h5>Motor insurance - roadside assistance</h5>
          </div>
          <p>Please scan the QR code for easy access to roadside assistance service or click <a href="https://qbe.carfix.my">qbe.carfix.my</a>. </p>
        </div>
      </div>
      <div class="row col-md-12 col-sm-12 col-lg-4">
        <div class="dmg-qr">
          <img src="./assets/images/CarFixQRCode.jpg" alt="CarFixQRCode"/>
        </div>
      </div>
    </div>
  </div>
  <documents-to-prepare [transactionInfo]="transactionInfo"></documents-to-prepare>
</ng-template>
<otp-box #modal_otp [otp]="otp" [maskedEmailAddress]="maskedEmailAddress" [showCloseIcon]="true" [showResendOtpLink]="true"
         [showOtpError]="showOtpError" (onVerifyOtpEmit)="verifyOtp($event)" (onSendOtpEmit)="sendOtp()" (onLockedEmit)="sendOtp()"></otp-box>
<ng-template #modal_info let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="modal-title">{{ infoMessage | translate }}</div>
    <span *ngIf="subInfoMessage">
         <br/>
         <h4>{{ subInfoMessage | translate }}</h4>
      </span>
  </div>
  <div class="mt-4">
    <button class="new-btn btn-primary btn" (click)="c('Close click')" >
      {{ 'claimSection.generalLabels.generalButtonLabels.ok' | translate}}
    </button>
    <br/><br/>
  </div>
</ng-template>
<forgot-password-modal #forgot_password_modal (onSubmitEmit)="handleForgotPassword($event)"></forgot-password-modal>