<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.title' | translate}}</h5>
    <p [innerHTML]="'ecClaim.accidentPlace.supplementaryInfo.subHeading' | translate"></p>
  </div>
  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.particularsWorksite.label' | translate}}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="supplementaryInfoForm">
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_COMMENCEMENT_DATE [fieldId]="'EC_SUPPLEMENTARY_INFO_COMMENCEMENT_DATE'" [formGroup]="supplementaryInfoForm" name="commencementDate"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_COMPLETION_DATE [fieldId]="'EC_SUPPLEMENTARY_INFO_COMPLETION_DATE'" [formGroup]="supplementaryInfoForm" name="expectedCompletionDate"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_CONTRACTOR_NAME [fieldId]="'EC_SUPPLEMENTARY_INFO_CONTRACTOR_NAME'" [formGroup]="supplementaryInfoForm" name="contractorName"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_SITE_ADDRESS [fieldId]="'EC_SUPPLEMENTARY_INFO_SITE_ADDRESS'" [formGroup]="supplementaryInfoForm" name="siteAddress"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_CONTRACT_NO [fieldId]="'EC_SUPPLEMENTARY_INFO_CONTRACT_NO'" [formGroup]="supplementaryInfoForm" name="contractNo"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_ACCIDENT_DATE [fieldId]="'EC_SUPPLEMENTARY_INFO_ACCIDENT_DATE'" [formGroup]="supplementaryInfoForm" name="accidentDate"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_CONTACT_NO [fieldId]="'EC_SUPPLEMENTARY_INFO_CONTACT_NO'" [formGroup]="supplementaryInfoForm" name="contactNo"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
        
  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.particularsProject.label' | translate}}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="supplementaryInfoForm">
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_PROJECT_NATURE [fieldId]="'EC_SUPPLEMENTARY_INFO_PROJECT_NATURE'" [formGroup]="supplementaryInfoForm" name="projectNature"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_IS_PRIVATE [fieldId]="'EC_SUPPLEMENTARY_INFO_IS_PRIVATE'" [formGroup]="supplementaryInfoForm" name="isPrivateProject"></qnect-ui>
        </div>
        <div *ngIf="accidentPlaceDetails.getIsPrivateProject()">
          <div class="form-group">
            <qnect-ui #EC_SUPPLEMENTARY_INFO_AUTHORIZED_PERSON_NAME [fieldId]="'EC_SUPPLEMENTARY_INFO_AUTHORIZED_PERSON_NAME'" [formGroup]="supplementaryInfoForm" name="authorizedPersonName"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_SUPPLEMENTARY_INFO_AUTHORIZED_PERSON_POSITION [fieldId]="'EC_SUPPLEMENTARY_INFO_AUTHORIZED_PERSON_POSITION'" [formGroup]="supplementaryInfoForm" name="authorizedPersonPosition"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_SUPPLEMENTARY_INFO_AUTHORIZED_PERSON_CONTACT_NO [fieldId]="'EC_SUPPLEMENTARY_INFO_AUTHORIZED_PERSON_CONTACT_NO'" [formGroup]="supplementaryInfoForm" name="autorizedPersonPhoneNo"></qnect-ui>
          </div>
        </div>
        <div *ngIf="accidentPlaceDetails.getIsPrivateProject() === false">
          <div class="form-group">
            <qnect-ui #EC_SUPPLEMENTARY_INFO_PUBLIC_WORKS [fieldId]="'EC_SUPPLEMENTARY_INFO_PUBLIC_WORKS'" [formGroup]="supplementaryInfoForm" name="publicWorks"></qnect-ui>
          </div>
          <div *ngIf="accidentPlaceDetails.getPublicWorks() === '99'">
            <div class="form-group">
              <qnect-ui #EC_SUPPLEMENTARY_INFO_PUBLIC_WORKS_OTHER [fieldId]="'EC_SUPPLEMENTARY_INFO_PUBLIC_WORKS_OTHER'" [formGroup]="supplementaryInfoForm" name="publicWorksOther"></qnect-ui>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>

  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.particularsPlace.title' | translate}}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="supplementaryInfoForm">
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_PLACE_OF_FALL [fieldId]="'EC_SUPPLEMENTARY_INFO_PLACE_OF_FALL'" [formGroup]="supplementaryInfoForm" name="placeOfFall"></qnect-ui>
        </div>
        <div *ngIf="accidentPlaceDetails.getPlaceOfFall() === '08'">
          <div class="form-group">
            <qnect-ui #EC_SUPPLEMENTARY_INFO_PLACE_OF_FALL_OTHER [fieldId]="'EC_SUPPLEMENTARY_INFO_PLACE_OF_FALL_OTHER'" [formGroup]="supplementaryInfoForm" name="placeOfFallOther"></qnect-ui>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>

  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.ethnicity.title' | translate}}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="supplementaryInfoForm">
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_ETHNICITY [fieldId]="'EC_SUPPLEMENTARY_INFO_ETHNICITY'" [formGroup]="supplementaryInfoForm" name="ethnicity"></qnect-ui>
        </div>
        <div *ngIf="accidentPlaceDetails.getEthnicity() === '11'">
          <div class="form-group">
            <qnect-ui #EC_SUPPLEMENTARY_INFO_ETHNICITY_OTHER [fieldId]="'EC_SUPPLEMENTARY_INFO_ETHNICITY_OTHER'" [formGroup]="supplementaryInfoForm" name="ethnicityOther"></qnect-ui>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>

  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.label' | translate}}</h5>
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.spoken' | translate}}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="supplementaryInfoForm">
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_CANTONESE [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_CANTONESE'" [formGroup]="supplementaryInfoForm" name="spokenCantonese"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_PUTONGHUA [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_PUTONGHUA'" [formGroup]="supplementaryInfoForm" name="spokenPutonghua"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_ENGLISH [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_ENGLISH'" [formGroup]="supplementaryInfoForm" name="spokenEnglish"></qnect-ui>
        </div>
        <ng-container *ngIf="isSpokenOtherLangEnabled">
          <div class="docRow">
            <div class="form-group" style="width:60%">
              <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_OTHER_TEXT [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_OTHER_TEXT'" [formGroup]="supplementaryInfoForm" name="spokenOtherText"></qnect-ui>
            </div>
            <div style="align-self: center; cursor: pointer;" (click)="addRemoveOtherLanguage('spoken', 'remove')">
              <i class="fas fa-times fa-2x"></i>
            </div>
          </div>
          <div class="docRow">
            <div class="form-group">
              <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_OTHER_OPTION [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_SPOKEN_OTHER_OPTION'" [formGroup]="supplementaryInfoForm" name="spokenOtherOpt"></qnect-ui>
            </div>
          </div>
        </ng-container>
        <div *ngIf="!isSpokenOtherLangEnabled" class="addFile" (click)="addRemoveOtherLanguage('spoken', 'add')">
          <i class="fas fa-plus"></i>
          <span>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.addOtherSpokenLanguage' | translate}}</span>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>

  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.reading' | translate}}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="supplementaryInfoForm">
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_CHINESE [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_CHINESE'" [formGroup]="supplementaryInfoForm" name="readingChinese"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_ENGLISH [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_ENGLISH'" [formGroup]="supplementaryInfoForm" name="readingEnglish"></qnect-ui>
        </div>
        <ng-container *ngIf="isReadingOtherLangEnabled">
          <div class="docRow">
            <div class="form-group" style="width:60%">
              <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_OTHER_TEXT [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_OTHER_TEXT'" [formGroup]="supplementaryInfoForm" name="readingOtherText"></qnect-ui>
            </div>
            <div style="align-self: center; cursor: pointer;" (click)="addRemoveOtherLanguage('reading', 'remove')">
              <i class="fas fa-times fa-2x"></i>
            </div>
          </div>
          <div class="docRow">
            <div class="form-group">
              <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_OTHER_OPTION [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_READING_OTHER_OPTION'" [formGroup]="supplementaryInfoForm" name="readingOtherOpt"></qnect-ui>
            </div>
          </div>
        </ng-container>
        <div *ngIf="!isReadingOtherLangEnabled" class="addFile" (click)="addRemoveOtherLanguage('reading', 'add')">
          <i class="fas fa-plus"></i>
          <span>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.addOtherReadingLanguage' | translate}}</span>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>

  <br/>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.written' | translate}}</h5>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="supplementaryInfoForm">
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_CHINESE [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_CHINESE'" [formGroup]="supplementaryInfoForm" name="writtenChinese"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_ENGLISH [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_ENGLISH'" [formGroup]="supplementaryInfoForm" name="writtenEnglish"></qnect-ui>
        </div>
        <ng-container *ngIf="isWrittenOtherLangEnabled">
          <div class="docRow">
            <div class="form-group" style="width:60%">
              <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_OTHER_TEXT [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_OTHER_TEXT'" [formGroup]="supplementaryInfoForm" name="writtenOtherText"></qnect-ui>
            </div>
            <div style="align-self: center; cursor: pointer;" (click)="addRemoveOtherLanguage('written', 'remove')">
              <i class="fas fa-times fa-2x"></i>
            </div>
          </div>
          <div class="docRow">
            <div class="form-group">
              <qnect-ui #EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_OTHER_OPTION [fieldId]="'EC_SUPPLEMENTARY_INFO_LANGUAGE_WRITTEN_OTHER_OPTION'" [formGroup]="supplementaryInfoForm" name="writtenOtherOpt"></qnect-ui>
            </div>
          </div>
        </ng-container>
        <div *ngIf="!isWrittenOtherLangEnabled" class="addFile" (click)="addRemoveOtherLanguage('written', 'add')">
          <i class="fas fa-plus"></i>
          <span>{{ 'ecClaim.accidentPlace.supplementaryInfo.languageAbility.addOtherWrittenLanguage' | translate}}</span>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>


  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

