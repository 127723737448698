export class OtherClaim {


    private otherDateOfIncident: Date;
    private otherIncidentDescription: string;
    private otherAmount: string;
    private otherCurrency: string;
    private otherCountryIncidentOccurred: string;
    private otherCountryIncidentOccurredDescription: string;
    private estimatedAmount: number;
    private placeOfLoss: string;
    private placeOfLossDesc: string;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getOtherDateOfIncident(): Date {
        return this.otherDateOfIncident;
    }

    setOtherDateOfIncident(value: Date) {
        this.otherDateOfIncident = value;
    }

    getOtherIncidentDescription(): string {
        return this.otherIncidentDescription;
    }

    setOtherIncidentDescription(value: string) {
        this.otherIncidentDescription = value;
    }

    getOtherAmount(): string {
        return this.otherAmount;
    }

    setOtherAmount(value: string) {
        this.otherAmount = value;
    }

    getOtherCurrency(): string {
        return this.otherCurrency;
    }

    setOtherCurrency(value: string) {
        this.otherCurrency = value;
    }

    getOtherCountryIncidentOccurred(): string {
        return this.otherCountryIncidentOccurred;
    }

    setOtherCountryIncidentOccurred(value: string) {
        this.otherCountryIncidentOccurred = value;
    }

    getOtherCountryIncidentOccurredDescription(): string {
        return this.otherCountryIncidentOccurredDescription;
    }

    setOtherCountryIncidentOccurredDescription(value: string) {
        this.otherCountryIncidentOccurredDescription = value;
    }

    getPlaceOfLoss(): string {
        return this.placeOfLoss;
    }

    setPlaceOfLoss(value: string){
        this.placeOfLoss = value;
    }

    getPlaceOfLossDesc(): string {
        return this.placeOfLossDesc;
    }

    setPlaceOfLossDesc(value: string){
        this.placeOfLossDesc = value;
    }

    static jsonConvert(otherClaim: OtherClaim): OtherClaim {

        if (otherClaim.getOtherDateOfIncident() != null) {
            otherClaim.setOtherDateOfIncident(new Date(otherClaim.getOtherDateOfIncident()));
        }

        return otherClaim;
    }
}