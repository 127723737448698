import {Component, OnInit} from "@angular/core";
import {UiBaseComponent} from "../ui-base.component";

@Component({
  selector: 'qnect-ui-textfield-label',
  templateUrl: './ui-textfield-label.component.html',
  styleUrls: ['../ui-base.component.scss']
})
export class UiTextfieldLabelComponent extends UiBaseComponent implements OnInit {

  private dataList: string;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.uiModel.options) {
      this.dataList = this.fieldId + 'DataList';
    }

  }

}
