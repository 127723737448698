import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { ClaimTypes } from "../../model/claim-type.model";
import { DocumentField } from "../../model/document-field";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";
import {DamagePersonalItemDetail} from "../../model/travel/damage-personal-detail.model";

export class DamagedPersonalItemFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        if (['SGP', 'MYS'].indexOf(this.claim.getCountry()) !== -1) {
            let isSGPStpRequired = this.claim.getCountry()?.toUpperCase() == "SGP";
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.copyTicket', SmartqDocuments.AIRTICKET, true), false));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('RECDAM', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.origReceiptSgp', SmartqDocuments.PURCHASERECEIPT, true), isSGPStpRequired));
        } else {
            // hkg, hgi
            let damageItems:  DamagePersonalItemDetail[] = this.claim.getClaimTravel().getDamagePersonalItem().getDetailsOfDamageItemsArray();
            let hasDocStpRequired =  true;

            if (this.claim.getClaimTravel().getGBAPlanType()) {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('PROOFTRAVEL', 'claimSection.greaterBayArea.proofOfTravel', SmartqDocuments.PROOF_TRAVEL, true), false));
                hasDocStpRequired = false;
            } else {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.copyTicket', SmartqDocuments.AIRTICKET, true), false));
            }

            if (damageItems && damageItems.length > 0) {
                // Original Purchase Receipt
                if (damageItems.find(item => item.getHasOriginalPurchasedReceipt() == true)) {
                    requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('PRCHRCPT', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.purchaseReceipt', SmartqDocuments.PURCHASERCPT, true), hasDocStpRequired));
                }
                // Reference of Original Purchase Price
                if (damageItems.find(item => item.getHasOriginalPurchasedReceipt() == false)) {
                    requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('REFPRCHRCPT', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.refPurchaseReceipt', SmartqDocuments.PURCHASERCPTREF, true), false));
                }
                //Repairment Receipt / Quotation for Repairment
                if (damageItems.find(item => (item.getLossType() !== 'Luggage / Trunk' && item.getLossType() !== 'Suitcase / Case') ||
                    (item.getHasRepairOrQuotationReceipt() && item.getHasRepairOrQuotationReceipt() == true))) {
                    requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('REPQTRPAIR', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.repairmentReceiptQuotation', SmartqDocuments.REPAIRRCPTQUOTATION, true), hasDocStpRequired));
                }
            }
        }

        requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('DAMREPORT', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.dmgRpt', SmartqDocuments.REPORT, true), false));
        requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('DAMPHOTO', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.photos', SmartqDocuments.PHOTOS, true), false));
        if(this.claim.getClaimTravel().getDamagePersonalItem().getHasCompensationReceived()){
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('COMPBREAK', 'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.compBreakdown', SmartqDocuments.PAYMENTDOCUMENT, true), false));
        }
        if(UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth())){
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true), false));
        }
        this.requiredDocuments = requiredDocuments;
    }
}