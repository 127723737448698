export class Weather {
    private typeOfWeather: String;
    private typeOfWeatherDesc: String;
    private typeOfWeatherOthers: String;

    getTypeOfWeather(): String {
        return this.typeOfWeather;
    }

    setTypeOfWeather(typeOfWeather: String): void {
        this.typeOfWeather = typeOfWeather;
    }
    
    getTypeOfWeatherDesc(): String {
        return this.typeOfWeatherDesc;
    }

    setTypeOfWeatherDesc(typeOfWeatherDesc: String): void {
        this.typeOfWeatherDesc = typeOfWeatherDesc;
    }


    getTypeOfWeatherOthers(): String {
        return this.typeOfWeatherOthers;
    }

    setTypeOfWeatherOthers(typeOfWeatherOthers: String): void {
        this.typeOfWeatherOthers = typeOfWeatherOthers;
    }
    static jsonConvert(weather: Weather): Weather {
        weather = Object.assign(new Weather(), weather);
        return weather;
    }
}