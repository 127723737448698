import { HospitalExpenseDetails } from "./hospital-expense-details.model";

export class HospitalCash {

    private hospitalExpenses: HospitalExpenseDetails[] = [];
    private requiredSurgery: string;
    private requiredSurgeryDesc: string;
    private totalHospitalExpenses: number;
    private sumInsured: number = null;

    public getHospitalExpenses(): HospitalExpenseDetails[] {
        return this.hospitalExpenses;
    }

    public setHospitalExpenses(hospitalExpenses: HospitalExpenseDetails[]) {
        this.hospitalExpenses = hospitalExpenses;
    }

    public getRequiredSurgery(): string {
        return this.requiredSurgery;
    }

    public setRequiredSurgery(requiredSurgery: string): void {
        this.requiredSurgery = requiredSurgery;
    }

    public getRequiredSurgeryDesc(): string {
        return this.requiredSurgeryDesc;
    }

    public setRequiredSurgeryDesc(requiredSurgeryDesc: string): void {
        this.requiredSurgeryDesc = requiredSurgeryDesc;
    }

    public getTotalHospitalExpenses(): number {
        return this.totalHospitalExpenses;
    }

    public setTotalHospitalExpenses(totalHospitalExpenses: number): void {
        this.totalHospitalExpenses = totalHospitalExpenses;
    }

    public getSumInsured(): number {
        return this.sumInsured;
    }

    public setSumInsured(sumInsured: number): void {
        this.sumInsured = sumInsured;
    }

    static jsonConvert(hospitalCash: HospitalCash): HospitalCash {

        if (hospitalCash != null) {

            if (hospitalCash.getHospitalExpenses() != null) {
                let expenses: HospitalExpenseDetails[] = [];
                for (const expense of hospitalCash.getHospitalExpenses()) {
                        let item = Object.assign(new HospitalExpenseDetails(), expense);
                        item = HospitalExpenseDetails.jsonConvert(item);
                        expenses.push(item);
                }
                hospitalCash.setHospitalExpenses(expenses);
            }

        }
        return hospitalCash;
        
    }

}