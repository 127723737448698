import {Component, OnInit} from "@angular/core";
@Component({
    selector: 'app-claim-detail',
    templateUrl: './claim-detail.component.html',
    styleUrls: ['./claim-detail.component.css']
})
export class ClaimDetailComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
