export class Option {

  label: string;
	value: string;
	disabled: boolean;

	constructor(label, value, disabled){
		this.label = label;
		this.value = value;
		this.disabled = disabled;
	}
      
}