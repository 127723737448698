<form [formGroup]="sickLeaveForm">
  <div formArrayName="items" *ngFor="let item of sickLeaveForm.get('items')['controls']; let i = index;">
    <div [formGroupName]="i">
      <span *ngIf="i==0"><label>{{ 'ecClaim.accidentDetails.periodOfSickLeave' | translate }}<span></span></label></span>
        <div class="row">
            <div class="col-md-8 col-xs-12">
                <div class="form-group">
                    <label class="date-label">{{ 'ecClaim.accidentDetails.fullHalfDay' | translate }}</label>
                    <span class="closeIcon" [hidden]="i==0" (click)="removeItem(i)"><i class="fa fa-times"></i></span>
                    <qnect-ui [fieldId]="'EC_ACCIDENT_FULL_HALF_DAY'"
                              [frmArrayGroup]="sickLeaveForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="fullHalfDay"></qnect-ui>
                </div>
            </div>
            <div class="col-md-4">

            </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-4 date-field1">
            <div class="form-group">
              <label class="date-label">{{ 'ecClaim.accidentDetails.from' | translate }}</label>
              <qnect-ui [fieldId]="'EC_ACCIDENT_SICK_LEAVE_FROM'"
                        [frmArrayGroup]="sickLeaveForm"
                        [frmArrayName]="'items'"
                        frmArrayIndex="{{i}}"
                        name="sickLeaveFromDate"
                        [dateRangeValidator]="getDatesForValidation(i, 'from')"></qnect-ui>
            </div>
          </div>
          <div class="col-6 col-md-4 date-field2">
            <div class="form-group">
                <label class="date-label">{{ 'ecClaim.accidentDetails.to' | translate }}</label>
                <qnect-ui [fieldId]="'EC_ACCIDENT_SICK_LEAVE_TO'"
                       [frmArrayGroup]="sickLeaveForm"
                       [frmArrayName]="'items'"
                       frmArrayIndex="{{i}}"
                       name="sickLeaveToDate"
                       [dateRangeValidator]="getDatesForValidation(i, 'to')"
                       [disabled]="this.periodOfSickLeaveArray && 
                       this.periodOfSickLeaveArray[i]?.getQuantity() == 'half day'"></qnect-ui>
           </div>
         </div>
         <div class="col-md-4">

         </div>
       </div>
   </div>
 </div>
 <div class="addFile">
     <a href="javascript:;" (click)="addItem()">
      <em class="fas fa-plus"></em> {{ 'ecClaim.accidentDetails.addAnotherPeriod' | translate}}
     </a>
 </div>
 <div class="form-group">
   <qnect-ui #EC_ACCIDENT_SICKLEAVEDAYS [fieldId]="'EC_ACCIDENT_SICKLEAVEDAYS'" [formGroup]="sickLeaveForm"
             name="sickLeaveDays" [maxValidator]="maxSickLeave" [disabled]="true"></qnect-ui>
 </div>
 <ng-container *ngIf="this.isForm2Or2a">
    <div class="form-group">
       <qnect-ui #EC_EMPCOMP_IS_SICK_LEAVE_CONTNUING [fieldId]="'EC_EMPCOMP_IS_SICK_LEAVE_CONTNUING'"
                      [formGroup]="sickLeaveForm" name="isSickLeaveContinuing"></qnect-ui>
    </div>
    <ng-container *ngIf="claimEmployeeCompensation.getIsSickLeaveContinuing()">
        <div class="qbeConnect-rectangle">
            <label class="qbeConnect-label">{{ 'ecClaim.compensationDetails.qbeConnect.label' | translate }}</label>
            <p [innerHTML]="'ecClaim.compensationDetails.qbeConnect.description1' | translate"></p>
            <div class="form-group">
                <qnect-ui #EC_EMPCOMP_IS_DUTY_SUITABLE_FOR_EMPLOYEE [fieldId]="'EC_EMPCOMP_IS_DUTY_SUITABLE_FOR_EMPLOYEE'"
                          [formGroup]="sickLeaveForm" name="isDutySuitableForEmployee"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #EC_EMPCOMP_CONTACT_EMPLOYEE_FOR_VALUE_ADDED_SERVICE [fieldId]="'EC_EMPCOMP_CONTACT_EMPLOYEE_FOR_VALUE_ADDED_SERVICE'"
                          [formGroup]="sickLeaveForm" name="contactEmployeeForValueAddedService"></qnect-ui>
            </div>
            <p [innerHTML]="'ecClaim.compensationDetails.qbeConnect.description2' | translate"></p>
        </div>
        <br/>
    </ng-container>
 </ng-container>
</form>