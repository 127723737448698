import {Component, Injector, OnInit} from "@angular/core";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import { DatePipe } from "@angular/common";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { DocumentField } from "src/app/model/document-field";
import { ClaimTypes } from "src/app/model/claim-type.model";

@Component({
    selector: 'generic-sections-summary',
    templateUrl: './generic-sections-summary.component.html',
    styleUrls: ['./generic-sections-summary.component.css']
})
export class GenericSectionsSummaryComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private injector : Injector,
                private documentHelperService: DocumentHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC])
    }

    ngOnInit() {
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }
}
