<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
        <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{ 'claimSection.travelDelay.details.description' | translate}}</p>
    </div>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12" *ngIf="(!this.travelDelay.getManualInputFlag() && !this.travelDelay.getIsOriginalFlightCancelled()) || (!this.travelDelay.getIsAltManualInputFlag() && this.travelDelay.getIsOriginalFlightCancelled())">
            <div *ngIf="!this.travelDelay.getManualInputFlag() && !this.travelDelay.getIsOriginalFlightCancelled()">
                <form>
                    <div class="form-group">
                        <qnect-ui #TRAVEL_DEL18 [fieldId]="'TRAVEL_DEL18'" [formGroup]="detailOfTravelFlightStatsFormGroup" name="flightNumber"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui #TRAVEL_DEL19 [fieldId]="'TRAVEL_DEL19'" [formGroup]="detailOfTravelFlightStatsFormGroup" name="flightNumberDate"></qnect-ui>
                    </div>
                </form>
            </div>
            <div *ngIf="!this.travelDelay.getIsAltManualInputFlag() && this.travelDelay.getIsOriginalFlightCancelled() && this.travelDelay.getIsAltFlightInputFlag()">
                <form>
                    <div class="form-group">
                        <qnect-ui #TRAVEL_DEL20 [fieldId]="'TRAVEL_DEL20'" [formGroup]="detailOfAltTravelFlightStatsFormGroup" name="altFlightNumber"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui #TRAVEL_DEL21 [fieldId]="'TRAVEL_DEL21'" [formGroup]="detailOfAltTravelFlightStatsFormGroup"  [claimsDateValidator]="this.travelDelay.getFlightNumberDate()" name="altFlightNumberDate"></qnect-ui>
                    </div>
                </form>
            </div>
            <br>
            <a (click)="search()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.search' | translate}}&nbsp;</a>
            <div *ngIf="this.travelDelay.getFlightNumberSearchedFlag() != null && !this.travelDelay.getFlightNumberSearchedFlag()">
                <small id="error-message">{{'error_messages.required.travelDelay.searchFlightDelayed' | translate}}</small>
            </div>
            <br><br>

            <div *ngIf="!this.travelDelay.getFlightNumberFoundFlag() && this.travelDelay.getFlightNumberSearchedFlag()" class="row" id="trvl-dly-warning">
                <div class="col-sm-2 align-center" style="padding: 5px;">
                    <img src="assets/images/exclamation-trvl-dly-final.png" id="ray-travel-delay">
                </div>
                <div class="col-sm-10 trvl-delay-warning-msg">
                    {{ 'claimSection.travelDelay.details.travelDelayErrorMessage.err-msg-1' | translate}} {{ 'claimSection.travelDelay.details.travelDelayErrorMessage.err-msg-2' | translate}} <a href="javascript:;" (click)="showManualInputFields()" class="Tertiary trvl-delay-warning-msg"> {{ 'claimSection.travelDelay.details.travelDelayErrorMessage.err-msg-4' | translate}}</a>
                    <!-- S<span *ngIf="!this.travelDelay.getIsAltManualInputFlag() && this.travelDelay.getIsOriginalFlightCancelled() && this.travelDelay.getIsAltFlightInputFlag()"><a href="javascript:;" (click)="showManualInputFields()" class="Tertiary trvl-delay-warning-msg"> {{ 'claimSection.travelDelay.details.travelDelayErrorMessage.err-msg-4' | translate}}</a></span>-->
                </div>
            </div>
            <!-- START OF SINGLE LEG FLIGHTS DISPLAY-->
            <div *ngIf="!this.travelDelay.getIsMultiLegFlight() && this.travelDelay.getHasValidFlightDelay()&& this.travelDelay.getFlightNumberFoundFlag() && this.travelDelay.getFlightNumberSearchedFlag()" class="row trvl-del-info">
                <div class="col-sm-4">
                    <h5 class="country-code-color">{{this.flightStatusOperationalTimesList[0].getDepartureCountryCode()}}</h5>
                    <p *ngIf="this.flightStatusOperationalTimesList[0].getStatus() === 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-8' | translate}}</b></span><br>
                        <span>{{getDateTime(this.flightStatusOperationalTimesList[0].getPublishedDepartureDate()) | translate }}</span>
                    </p>
                    <p *ngIf="this.flightStatusOperationalTimesList[0].getStatus() !== 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-10' | translate}}</b></span><br>
                        <span>{{getDateTime(this.flightStatusOperationalTimesList[0].getActualDepartureDate()) | translate }}</span>
                    </p>
               </div>

               <div class="col-sm-4"></div>

               <div class="col-sm-4 align-right">
                   <h5 class="country-code-color">{{this.flightStatusOperationalTimesList[0].getArrivalCountryCode()}}</h5>
                   <p *ngIf="this.flightStatusOperationalTimesList[0].getStatus() === 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-9' | translate}}</b></span><br>
                       <span>{{getDateTime(this.flightStatusOperationalTimesList[0].getPublishedArrivalDate()) | translate }}</span>
                   </p>
                   <p *ngIf="this.flightStatusOperationalTimesList[0].getStatus() !== 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-11' | translate}}</b></span><br>
                       <span>{{getDateTime(this.flightStatusOperationalTimesList[0].getActualArrivalDate()) | translate }}</span>
                   </p>
               </div>
               <br>
               <div class="col-sm-2"></div>
               <div class="col-sm-8 align-center">
                    <img src="assets/images/ray-trl-dly-final.png" id="ray-travel-delay">
                    <br>
                    <div *ngIf="this.claim.getCountry().toLowerCase() !== 'hgi' && this.travelDelay.getDelayedDepartureOrArrival() === 'delayedDeparture' && !this.flightStatusOperationalTimesList[0].getIsDepartureDelayHoursNegative()">
                        <h6 id="dep-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-1' | translate}} {{this.flightStatusOperationalTimesList[0].getDepartureDelayHours()}}
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                        </h6>
                    </div>
                    <div *ngIf="this.claim.getCountry().toLowerCase() === 'hgi' && this.travelDelay.getDelayedDepartureOrArrival() === 'delayedDeparture' && !this.flightStatusOperationalTimesList[0].getIsDepartureDelayHoursNegative()">
                        <h6 id="dep-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-1' | translate}} {{this.flightStatusOperationalTimesList[0].getDepartureDelayHours()}}
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                        </h6>
                        <h6 id="arr-delayed-hrs" class="trvl-info-hrs">
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-2' | translate}} {{this.flightStatusOperationalTimesList[0].getArrivalDelayHours()}}
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                        </h6>
                    </div>

                    <div *ngIf="this.claim.getCountry().toLowerCase() === 'hgi' && this.travelDelay.getDelayedDepartureOrArrival() === 'delayedArrival' && !this.flightStatusOperationalTimesList[0].getIsArrivalDelayHoursNegative()">
                        <h6 id="dep-delayed-hrs" class="trvl-info-hrs">
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-1' | translate}} {{this.flightStatusOperationalTimesList[0].getDepartureDelayHours()}}
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                        </h6>
                        <h6 id="arr-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-2' | translate}} {{this.flightStatusOperationalTimesList[0].getArrivalDelayHours()}}
                            {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}</h6>
                    </div>
                </div>
                <div class="col-sm-2"></div>
            </div>
            <div *ngIf="!this.travelDelay.getIsMultiLegFlight() && this.travelDelay.getFlightNumberFoundFlag() && this.travelDelay.getFlightNumberSearchedFlag() && !this.flightStatusOperationalTimesList[0].getIsDepartureDelayHoursNegative() && !this.flightStatusOperationalTimesList[0].getIsArrivalDelayHoursNegative() && claim.getCountry().toLowerCase() == 'hgi'" class="row">
                <div class="col-lg-12" id="remarks">
                    <p>{{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-4' | translate}}</p>
                </div>
            </div>
            <!--END OF SINGLE LEG FLIGHTS DISPLAY-->

            <!-- START OF MULTI-LEG FLIGHTS DISPLAY-->
            <div *ngIf="this.travelDelay.getFlightNumberSearchedFlag() != null && this.travelDelay.getFlightNumberSearchedFlag() && this.travelDelay.getHasSelectedLeg() != null && !this.travelDelay.getHasSelectedLeg()">
                <small id="error-message">{{'error_messages.required.travelDelay.searchFlightDelayedMultiLeg' | translate}}</small>
            </div>
            <br>
            <span *ngFor="let fsoperationalTime of this.flightStatusOperationalTimesList; let i = index" (click)="buildMultilegTravelDelayValues(i, fsoperationalTime)" class="row" id="multi-leg-option">
                    <div *ngIf="this.travelDelay.getIsMultiLegFlight() && this.travelDelay.getFlightNumberFoundFlag() && this.travelDelay.getFlightNumberSearchedFlag()" class="col-md-1 radio-button-class">
                        <div class="container">
                            <input type="radio" name="radio" id="multi-leg-radio-{{i}}" class="multi-leg-radio">
                            <span class="multi-leg-radio-checkmark"></span>
                        </div>
                    </div>
                    <div class="col-md-11">
                        <div *ngIf="this.travelDelay.getIsMultiLegFlight() && this.travelDelay.getFlightNumberFoundFlag() && this.travelDelay.getFlightNumberSearchedFlag()" class="row trvl-del-info">
                                <div class="col-sm-4">
                                    <h5 class="country-code-color">{{this.flightStatusOperationalTimesList[i].getDepartureCountryCode()}}</h5>
                                    <p *ngIf="this.flightStatusOperationalTimesList[i].getStatus() === 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-8' | translate}}</b></span><br>
                                     <span>{{getDateTime(this.flightStatusOperationalTimesList[i].getPublishedDepartureDate()) | translate }}</span>
                                    </p>

                                   <p *ngIf="this.flightStatusOperationalTimesList[i].getStatus() !== 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-10' | translate}}</b></span><br>
                                     <span>{{getDateTime(this.flightStatusOperationalTimesList[i].getActualDepartureDate()) | translate }}</span>
                                   </p>
                               </div>

                               <div class="col-sm-4"></div>

                               <div class="col-sm-4 align-right">
                                   <h5 class="country-code-color">{{this.flightStatusOperationalTimesList[i].getArrivalCountryCode()}}</h5>
                                    <p *ngIf="this.flightStatusOperationalTimesList[i].getStatus() === 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-9' | translate}}</b></span><br>
                                      <span>{{getDateTime(this.flightStatusOperationalTimesList[i].getPublishedArrivalDate()) | translate }}</span>
                                     </p>
                                    <p *ngIf="this.flightStatusOperationalTimesList[i].getStatus() !== 'C'"><span><b>{{'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-11' | translate}}</b></span><br>
                                      <span>{{getDateTime(this.flightStatusOperationalTimesList[i].getActualArrivalDate()) | translate }}</span>
                                    </p>
                               </div>
                               <br>
                               <div class="col-sm-2"></div>
                               <div class="col-sm-8 align-center">
                                    <img src="assets/images/ray-trl-dly-final.png" id="ray-travel-delay">
                                    <br>
                                    <div *ngIf="this.flightStatusOperationalTimesList[i].getStatus() != 'C'">
                                      <div *ngIf="this.claim.getCountry().toLowerCase() !== 'hgi' && this.travelDelay.getDelayedDepartureOrArrival() === 'delayedDeparture' && !this.flightStatusOperationalTimesList[i].getIsDepartureDelayHoursNegative()">
                                          <h6 id="dep-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">
                                              {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-1' | translate}} {{this.flightStatusOperationalTimesList[i].getDepartureDelayHours()}}
                                              {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                                          </h6>
                                       </div>
                                       <div *ngIf="this.claim.getCountry().toLowerCase() === 'hgi' && this.flightStatusOperationalTimesList[i].getDepartureOrArrival() === 'delayedDeparture' && !this.flightStatusOperationalTimesList[i].getIsDepartureDelayHoursNegative()">
                                          <h6 id="dep-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">
                                              {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-1' | translate}} {{this.flightStatusOperationalTimesList[i].getDepartureDelayHours()}}
                                              {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                                          </h6>
                                           <h6 id="arr-delayed-hrs" class="trvl-info-hrs">
                                               {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-2' | translate}} {{this.flightStatusOperationalTimesList[0].getArrivalDelayHours()}}
                                               {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                                           </h6>
                                        </div>
                                       <div *ngIf="this.claim.getCountry().toLowerCase() === 'hgi' && this.flightStatusOperationalTimesList[i].getDepartureOrArrival() === 'delayedArrival' && !this.flightStatusOperationalTimesList[i].getIsArrivalDelayHoursNegative()">
                                          <h6 id="dep-delayed-hrs" class="trvl-info-hrs">{{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-1' | translate}} {{this.flightStatusOperationalTimesList[i].getDepartureDelayHours()}}
                                             {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                                          </h6>
                                          <h6 id="arr-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">
                                              {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-2' | translate}} {{this.flightStatusOperationalTimesList[i].getArrivalDelayHours()}}
                                              {{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-3' | translate}}
                                          </h6>
                                       </div>

                                       <div *ngIf="(this.flightStatusOperationalTimesList[i].getDepartureOrArrival() == 'delayedDeparture' && this.flightStatusOperationalTimesList[i].getIsDepartureDelayHoursNegative()) ||
                                                (this.flightStatusOperationalTimesList[i].getDepartureOrArrival() == 'delayedArrival' && this.flightStatusOperationalTimesList[i].getIsArrivalDelayHoursNegative())">
                                        <h5 id="arr-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">{{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-5' | translate}}</h5>
                                       </div>
                                    </div>

                                    <div *ngIf="this.flightStatusOperationalTimesList[i].getStatus() === 'C'">
                                        <h5 id="arr-delayed-hrs" class="trvl-info-hrs highlight-trvl-info-hrs">{{ 'claimSection.travelDelay.details.travelDelayInfoFromAPI.info-api-msg-7' | translate}}</h5>
                                    </div>
                                </div>
                                <div class="col-sm-2"></div>
                        </div>
                    </div>
                </span>
            <!-- END OF MULTI-LEG FLIGHTS DISPLAY-->
        </div>

        <div class="col-xl-9 col-lg-12" *ngIf="this.travelDelay.getManualInputFlag() || this.travelDelay.getIsAltManualInputFlag()">
            <form>
                <div *ngIf="claim.getCountry().toLowerCase() == 'hgi'">
                    <qnect-ui #TRAVEL_DEL_12_DEP_OR_ARR_RADIO [fieldId]="'TRAVEL_DEL_12_DEP_OR_ARR_RADIO'" [formGroup]="detailOfTravelFormGroup" name="delayedDepartureOrArrival"></qnect-ui>
                </div>

                <div>
                    <div id="delayed-departure" *ngIf="travelDelay.getDelayedDepartureOrArrival().toLowerCase() == 'delayeddeparture' || (travelDelay.getIsOriginalFlightCancelled() && travelDelay.getIsAltManualInputFlag() && travelDelay.getAltDelayedDepartureOrArrival()?.toLowerCase() == 'delayeddeparture')">
                        <form>
                            <div class="form-group">
                                <qnect-ui #TRV_DEL_01_DEPART_COUNTRY [fieldId]="'TRV_DEL_01_DEPART_COUNTRY'" [formGroup]="departureFormGroup"
                                          name="delayedDepartureCountry"></qnect-ui>
                            </div>
                            <div class="form-group">
                                <qnect-ui #TRV_DEL_02_DEPART_ORIGIN_DATE [fieldId]="'TRV_DEL_02_DEPART_ORIGIN_DATE'" [formGroup]="departureFormGroup"
                                          name="delayedDepartureOriginDate"></qnect-ui>
                            </div>
                            <div class="form-group">
                                <qnect-ui #TRV_DEL_03_DEPART_ACTUAL_DATE [fieldId]="'TRV_DEL_03_DEPART_ACTUAL_DATE'"
                                          [claimsDateValidator]="this.travelDelay.getOriginalFlightDate()"
                                          [formGroup]="departureFormGroup"></qnect-ui>
                            </div>
                        </form>
                    </div>

                    <div id="delayed-arrival" *ngIf="claim.getCountry().toLowerCase() == 'hgi' && (travelDelay.getDelayedDepartureOrArrival().toLowerCase() == 'delayedarrival'  ||
                         (travelDelay.getIsOriginalFlightCancelled() && travelDelay.getIsAltManualInputFlag() && travelDelay.getAltDelayedDepartureOrArrival()?.toLowerCase() == 'delayedarrival'))">
                        <form>
                            <div class="form-group">
                                <qnect-ui #TRV_DEL_05_ARRIVAL_COUNTRY [fieldId]="'TRV_DEL_05_ARRIVAL_COUNTRY'" [formGroup]="arrivalFormGroup"
                                          name="delayedArrivalCountry"></qnect-ui>
                            </div>
                            <div class="form-group">
                                <qnect-ui #TRV_DEL_06_ARRIVAL_ORIGIN_DATE [fieldId]="'TRV_DEL_06_ARRIVAL_ORIGIN_DATE'" [formGroup]="arrivalFormGroup"
                                          name="delayedArrivalOriginDate"></qnect-ui>
                            </div>
                            <div class="form-group">
                                <qnect-ui #TRV_DEL_07_ARRIVAL_ACTUAL_DATE [fieldId]="'TRV_DEL_07_ARRIVAL_ACTUAL_DATE'" [formGroup]="arrivalFormGroup"
                                          [claimsDateValidator]="this.travelDelay.getDelayedArrivalOriginalFlightDate()" name="delayedArrivalActualDate"></qnect-ui>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <span> <a href="javascript:;" (click)="showFlighStatsInputFields()" class="Tertiary">{{ 'claimSection.travelDelay.details.travelDelayInfoManualInput.info-manual-msg-1' | translate}} </a>&nbsp;<p>{{ 'claimSection.travelDelay.details.travelDelayInfoManualInput.info-manual-msg-2' | translate}}</p></span>
                </div>
            </form>

        </div>
        <div class="col-xl-3 col-lg-12">
        </div>
    </div>
    <additional-claimant></additional-claimant>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #sorry_not_covered let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="cursor-pointer">{{ 'claimSection.travelDelay.details.travelDelayWarningMessage.title' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <div class="row content">
            <p class="upper-section">{{ 'claimSection.travelDelay.details.travelDelayWarningMessage.sorryToHear' | translate}} {{this.hrsDelayed}} {{ 'claimSection.travelDelay.details.travelDelayWarningMessage.hours' | translate}}</p>
            <BR>
            <p>{{ 'claimSection.travelDelay.details.travelDelayWarningMessage.travelDelayLessThan' | translate}} {{this.allowedDelayHours}} {{ 'claimSection.travelDelay.details.travelDelayWarningMessage.hoursAreNotCovered' | translate}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <div class="btn-center"><button type="button" class="btn btn-light cancel btnblock" (click)="cancelClaimBtn('sorry_not_covered')">{{ 'claimSection.travelDelay.details.travelDelayWarningMessage.cancelClaim' | translate}}</button></div>
        <div class="btn-center"><button type="button" class="btn btn-light btnblock" (click)="c('Close click')">{{ 'claimSection.travelDelay.details.travelDelayWarningMessage.updateDetails' | translate}}</button></div>
        <div class="btn-center"><button type="button" class="btn btn-light btnblock" (click)="proceedAnywayBtn()">{{ 'claimSection.travelDelay.details.travelDelayWarningMessage.proceedAnyway' | translate}}</button></div>
    </div>
</ng-template>
<ng-template #TRAVELNOTDELAYEDSINGLE let-c="close" let-d="dismiss" class="white-popup mfp-hide">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-1' | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body policy-content modal_content">
        {{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-2' | translate}}
        <b><span *ngIf="!this.travelDelay.getIsAltFlightInputFlag() && !this.travelDelay.getIsOriginalFlightCancelled()">{{this.travelDelay.getFlightNumber()?.toUpperCase()}}</span>
            <span *ngIf="this.travelDelay.getIsAltFlightInputFlag() && this.travelDelay.getIsOriginalFlightCancelled()">{{this.travelDelay.getAltFlightNumber()?.toUpperCase()}}</span></b>
        {{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-3' | translate}}
        <br><br>
        <div class="align-center">
            <a (click)="showFlighStatsInputFieldsSingleLegWithModalOpen()" style="color: #0064c1" class="btn new-btn btn-secondary">&nbsp;{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-10' | translate}}&nbsp;</a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a (click)="deleteClaimType()" style="color: #0064c1" class="btn new-btn btn-secondary">&nbsp;{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-5' | translate}}&nbsp;</a>
        </div>
        <br><br>
        {{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-6' | translate}} <a href="javascript:;" (click)="showManualInputFieldsSingleLegWithModalOpen()" class="Tertiary">{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-7' | translate}}</a>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="c('Close click')">{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-8' | translate}}</button>
    </div>
</ng-template>
<ng-template #TRAVELNOTDELAYEDMULTI let-c="close" let-d="dismiss" class="white-popup mfp-hide">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-1' | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body policy-content modal_content">
        {{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-9' | translate}}
        <br><br>
        <div class="align-center">
            <a (click)="c('Close click')" style="color: #0064c1" class="btn new-btn btn-secondary">&nbsp;{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-10' | translate}}&nbsp;</a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a (click)="deleteClaimType()" style="color: #0064c1" class="btn new-btn btn-secondary">&nbsp;{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-5' | translate}}&nbsp;</a>
        </div>
        <br><br>
        {{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-6' | translate}} <a href="javascript:;" (click)="showManualInputFieldsMultiLegWithModalOpen()" class="Tertiary">{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-7' | translate}}</a>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="c('Close click')">{{ 'claimSection.travelDelay.details.travelDelayNotDelayedMessage.not-del-msg-8' | translate}}</button>
    </div>
</ng-template>
<ng-template #flight_cancelled let-c="close" let-d="dismiss" class="white-popup mfp-hide">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-1' | translate}}</h5>
    </div>
    <div class="modal-body policy-content modal_content">
        <p>{{ 'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-2' | translate}}
            <span *ngIf="this.travelDelay.getIsAltFlightInputFlag()"><b>{{ this.travelDelay.getAltFlightNumber()?.toUpperCase()}}</b></span>
            <span *ngIf="!this.travelDelay.getIsAltFlightInputFlag()"><b>{{ this.travelDelay.getFlightNumber()?.toUpperCase()}}</b></span>
            {{ 'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-3' | translate}}</p>
        <p>{{ 'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-4' | translate}}</p>
        <br>
        <div class="align-center">
            <a (click)="enterAltFlightDetails()" style="color: #0064c1" class="btn new-btn btn-secondary">&nbsp;{{ 'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-5' | translate}}&nbsp;</a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a (click)="goToClaimTripCancellation()" style="color: #0064c1" class="btn new-btn btn-secondary">&nbsp;{{ 'claimSection.travelDelay.details.travelDelayCancelledMessage.cancelled-msg-6' | translate}}&nbsp;</a>
        </div>
        <br>
    </div>
    <div class="modal-footer">
        <div class="row col-12 col-xl-12 col-lg-12 cancel-claim">
            <button type="button" class="btn btn-light" (click)="cancelThisClaimBtn()">{{ 'claimSection.travelDelay.details.travelDelayWarningMessage.cancelClaim' | translate}}</button>
        </div>
    </div>
</ng-template>
<ng-template #confirm_delete_claim_type let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{'sideMenu.deleteClaim.header' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="closeRemoveClaimBtn()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="modal-body">
        <p>{{'sideMenu.deleteClaim.question1' | translate }}</p>
    </div>
    <div class="modal-footer">
        <div class="col-md-6 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="cancelClaimBtn('confirm_delete_claim_type')">{{'sideMenu.deleteClaim.yes' | translate }}</button>
        </div>
        <div class="col-md-6 text-left">
            <button type="button" class="btn btn-default btn-sm" (click)="closeRemoveClaimBtn()">{{'sideMenu.deleteClaim.no' | translate }}</button>
        </div>
    </div>
</ng-template>