import { FlightStatusOperationalTimes } from "./flight-status-operational-times.model";

export class FlightStatus {
  private status: string;
  private flightStatusOperationalTimesList: FlightStatusOperationalTimes[];

  getStatus(): string {
    return this.status;
  } 

  setStatus(value: string){
    this.status = value;
  }

  getFlightStatusOperationalTimesList(): FlightStatusOperationalTimes[] {
    return this.flightStatusOperationalTimesList;
  } 

  setFlightStatusOperationalTimesList(value: FlightStatusOperationalTimes[]){
    this.flightStatusOperationalTimesList = value;
  }

 static jsonConvert(flightStatus: FlightStatus): FlightStatus {
    flightStatus = Object.assign(new FlightStatus(), FlightStatus);

    if (flightStatus.getFlightStatusOperationalTimesList() != null) {
      flightStatus.setFlightStatusOperationalTimesList(new Array<FlightStatusOperationalTimes>());      
    }

    return flightStatus;
  } 


}
