<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.detailsOfIncident.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.detailsOfIncident.dateOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(deathAndPermanentDisability.getDateOfIncident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.detailsOfIncident.countryOfIncidentOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{deathAndPermanentDisability.getCountryOccurredDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.detailsOfIncident.descriptionOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{deathAndPermanentDisability.getDescriptionOfIncident()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.detailsOfIncident.amountClaimed' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{deathAndPermanentDisability.getTotalAmountCurrency()}} {{deathAndPermanentDisability.getTotalAmountValue() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>