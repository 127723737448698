import {Component, OnInit} from "@angular/core";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import {UiBaseComponent} from "../ui-base.component";

@Component({
  selector: 'qnect-ui-textfield',
  templateUrl: './ui-textfield.component.html',
  styleUrls: ['../ui-base.component.scss']
})
export class UiTextfieldComponent extends UiBaseComponent implements OnInit {

  private dataList: string;
  charsLeft = 200;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.uiModel.options) {
      this.dataList = this.fieldId + 'DataList';
    }

    this.countOtherTextFieldChar();
  }

  changed() {
      this.countOtherTextFieldChar();
  }

  countOtherTextFieldChar() {
      // for other text field to show how many chars remaining
      if(!UtilitiesService.isNullOrUndefined(this.uiModel.isOtherTextField) && this.uiModel.isOtherTextField) {
          let maxLength = UtilitiesService.isNullOrUndefined(this.uiModel.maxlength) ? 0 : this.uiModel.maxlength;
          let length = UtilitiesService.isNullOrUndefined(this.getValue()) ? 0 : this.getValue().length;
          this.charsLeft = maxLength - length;
      }
  }

}
