import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {DocumentField} from '../../../../model/document-field';
import {ClaimTypes} from '../../../../model/claim-type.model';
import {DocumentHelperService} from "../../../../services/document-helper.service";
import {EmployeeCompensation} from "../../../../model/domestic-helper/employee-compensation.model";

@Component({
    selector: 'app-summary-employee-compensation',
    templateUrl: './summary-employee-compensation.component.html',
    styleUrls: ['./summary-employee-compensation.component.css']
})

export class SummaryEmployeeCompensationComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    employeeCompensation: EmployeeCompensation;
    listOfDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.employeeCompensation = this.claim.getClaimDomesticHelper().getEmployeeCompensation();
        this.listOfDocuments = this.documentHelperService.getListOfDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]);
    }
    
    ngOnInit() { }

    returnYesOrNo(value: string) {
        if ('Yes' === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if ('No' === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}
