import {Injectable} from "@angular/core";
import {ClaimTypes} from '../model/claim-type.model';
import {PlanInfoService} from "./plan-info.service";
import { UtilitiesService } from "../utilities/utilities.service";

@Injectable()
export class DepreciationService {

    deptRateForDamagedItemList = [
        {
            "item": "mobilephone",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "tablet",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "laptopnotebook",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "sportsequipment",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "luggagetrunk",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "suitcasecase",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "backpackbag",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "handbagbriefcase",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "camera",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "wallet",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "watch",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "earphonesheadphones",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "chargersmemorycards",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "others",
            "category1": "NA",
            "category2": "NA",
            "category3": "NA",
            "category4": "NA",
            "category5": "NA"
        }
    ];

    deptRateForLostItemList = [
        {
            "item": "mobilephone",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "tablet",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "laptopnotebook",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "passporthkidchinareentrycard",
            "category1": "NA",
            "category2": "NA",
            "category3": "NA",
            "category4": "NA",
            "category5": "NA"
        },
        {
            "item": "personalmoney",
            "category1": "NA",
            "category2": "NA",
            "category3": "NA",
            "category4": "NA",
            "category5": "NA"
        },
        {
            "item": "creditcard",
            "category1": "NA",
            "category2": "NA",
            "category3": "NA",
            "category4": "NA",
            "category5": "NA"
        },
        {
            "item": "sportsequipment",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "luggagetrunk",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "suitcasecase",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "backpackbag",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "handbagbriefcase",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "camera",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "wallet",
            "category1": "10",
            "category2": "20",
            "category3": "30",
            "category4": "40",
            "category5": "50"
        },
        {
            "item": "watch",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "earphonesheadphones",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "chargersmemorycards",
            "category1": "10",
            "category2": "30",
            "category3": "40",
            "category4": "50",
            "category5": "60"
        },
        {
            "item": "others",
            "category1": "NA",
            "category2": "NA",
            "category3": "NA",
            "category4": "NA",
            "category5": "NA"
        }
    ];

    constructor(private planInfoService: PlanInfoService) {}

    getDepreciationRate(totalMonths: number, totalDays: number, claimType: string, itemType: string): string {
        let depreciationRate = "NA";
        let deptRateItemList = claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG] ? this.deptRateForDamagedItemList : this.deptRateForLostItemList;
        let itemTypeValue = itemType.replace(/[^a-zA-Z]/g, "").trim();
        let category = this.getDepreciationCategory(totalMonths, totalDays);

        if (!UtilitiesService.isNullOrUndefined(category) && category != "" && category != "NA") {
            depreciationRate = this.getDepreciationRateForItem(deptRateItemList, itemTypeValue, category);
        }

        return depreciationRate;
    }


    getDepreciationCategory(totalMonths: number, totalDays: number) {
        if (totalMonths < 12 && totalDays >= 0) {  // below 1 year
            return "category1";
        } else if ((totalMonths >= 12 && totalMonths <= 23 && totalDays >= 0) || (totalMonths == 24 && totalDays == 0)) {  // within 1 to 2 years
            return "category2";
        } else if ((totalMonths == 24 && totalDays > 0) || (totalMonths > 24 && totalMonths <= 35 && totalDays >= 0) || (totalMonths == 36 && totalDays == 0)) {   // greater than 2 years and = 3 years
            return "category3";
        } else if ((totalMonths == 36 && totalDays > 0) || (totalMonths > 36 && totalMonths <= 47 && totalDays >= 0) || (totalMonths == 48 && totalDays == 0)) {  // greater than 3 years and = 4 years
            return "category4";
        } else if ((totalMonths == 48 && totalDays > 0) || totalMonths > 48) {  //greater than 4 years
            return "category5";
        }
        return "";
    }

    getDepreciationRateForItem(deptRateForItemList, itemTypeValue, category): string {
        let deptRate = "NA";
        for (var i = 0; i < deptRateForItemList.length; i++) {
            let item = deptRateForItemList[i]['item'];
            if (item == itemTypeValue.toLowerCase()) {
                deptRate = deptRateForItemList[i][category];
                break;
            }
        }
        return deptRate;
    }


    isApplicableToQgiPlan(catalogCode: string, planCode: string, areaCode: string, poi: string): boolean {
        let qgiPlanList = this.planInfoService.getQgiPlanList();
        let periodOfInsurance = !UtilitiesService.isNullOrUndefined(poi) && poi != "" && poi != "0" ? "!0" : poi;
        return !!qgiPlanList.find(qgiPlan => qgiPlan.catalogCode == catalogCode && qgiPlan.planCode.indexOf(planCode) !== -1 &&
            qgiPlan.areaCode == parseInt(areaCode) && qgiPlan.periodOfInsurance == periodOfInsurance);
    }
}
