import {AbstractControl, FormGroup} from '@angular/forms';

export class UiFormService {

  static mapFormControlError(fc: AbstractControl, errorCodes: string[]) {
    if (fc && errorCodes) {
      errorCodes.forEach(code => {
        let error: any = {};
        error[code] = 'true';
        fc.setErrors(error);
      });
      if (errorCodes.length > 0) {
        fc.markAsTouched();
      }
    }
  }

  static mapFormErrors(formGroup: FormGroup, fieldErrors: any) {
    if (fieldErrors) {
      Object.keys(fieldErrors).forEach(key => {
        this.mapFormControlError(formGroup.get(key), fieldErrors[key]);
      });
    }
  }

  static removeError(fc: AbstractControl, error: string) {
    if (fc && fc.errors) {
      const errors: any = {};
      Object.keys(fc.errors).forEach(function (key) {
        if (error !== key) {
          errors[key] = true;
        }
      });
      fc.setErrors(Object.keys(errors).length > 0 ? errors : null);
    }
  }
}
