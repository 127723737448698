<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.reroute.title' | translate}}</p>
  </div>
  <BR>

  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
              <qnect-ui #TRAVEL_REROUTE_01_COUNTRYOCCURRED [fieldId]="'TRAVEL_REROUTE_01_COUNTRYOCCURRED'" [formGroup]="reroutingForm" name="reroutingCountryOccurred"></qnect-ui>
            </div>
            <div class="form-group">
              <qnect-ui #TRAVEL_REROUTE_02_ORIGINALSCHEDULE [fieldId]="'TRAVEL_REROUTE_02_ORIGINALSCHEDULE'" [formGroup]="reroutingForm" name="reroutingOriginalSchedule"></qnect-ui>
            </div>
            <div class="form-group">
              <qnect-ui #TRAVEL_REROUTE_03_TRANSPOCANCELORDELAY [fieldId]="'TRAVEL_REROUTE_03_TRANSPOCANCELORDELAY'" [formGroup]="reroutingForm" name="reroutingTranspoCancelOrDelay"></qnect-ui>
            </div>
            <div class="form-group"  *ngIf="this.travelReRoute.getReroutingTranspoCancelOrDelay()?.toLowerCase() === 'delayed'">
              <qnect-ui #TRAVEL_REROUTE_04_ACTUALDELAYSCHEDULE [fieldId]="'TRAVEL_REROUTE_04_ACTUALDELAYSCHEDULE'" [formGroup]="reroutingForm" [claimsDateValidator]="this.travelReRoute.getReroutingOriginalSchedule()" name="reroutingActualDelaySchedule"></qnect-ui>
            </div>

        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>
    <additional-claimant></additional-claimant>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

