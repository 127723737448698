<div>
  <div class="col-12">
    <h5> {{'claimDetails.body.title' | translate}}</h5>
    <p>{{'claimDetails.body.selectType' | translate}}</p>
    <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
  </div>
  <div class="row claim-box col-12">
    <div class = "col-lg-4 col-md-6 col-sm-6" *ngFor="let claimType of claimTypeData; let i = index"
         (click)="toggleActive(claimType)">
      <div class="cperson-wrapper text-center claim-wrapper" [class.active]="claimType.selected">

        <div class = "cpreson-outter claim-inner">
          <div>
            <img class="icon" style="width: 50px;height: 50px;" [src]="claimType['icon']" alt="">
            <h5 style="margin-top: 10px;">{{claimType.claimTypeName | translate}}</h5>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <button (click)="goToNext()"  class="new-btn btn btn-primary float-right">&nbsp;{{'claimDetails.body.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
</div>
