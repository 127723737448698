<nav>
    <div *ngIf="this.outageMessage" class="alert alert-danger error-notification">
         <div class="row error-content col-12">
             <i class="fa fa-exclamation-circle notification-icon" aria-hidden="true"></i>
             <div style="margin-left:15px;margin-top:8px;"><p>{{this.outageMessage | translate }}</p></div>
         </div>
    </div>
    <div class="container {{customClass}}">
        <div class="row col-14" *ngIf="this.transactionInfo.getAction() == null || this.transactionInfo.getAction() != 'claimstatus'">
            <!--<div style="display: flex; justify-content: space-around" >-->
            <div class="col-3 col-sm-4 col-md-6 col-lg-6 text-left" *ngIf="isHomePage">
                <span class="">
                    <a href="{{entryUrl}}"><img class="logo" src="assets/images/QBE_logo_black.png"></a>
                </span>
            </div>
            <div class="col-6 text-left" *ngIf="!isHomePage">
                <div *ngIf = "!isHomePage" class="menu-btn">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <span class="">
                    <a href="{{entryUrl}}"><img class="logo" src="assets/images/QBE_logo_black.png"></a>
                </span>
            </div>
            <div class="col-9 col-sm-8 col-md-6 col-lg-6 text-right search" *ngIf="isHomePage">
                <div class="lang-box" *ngIf="showLangSelector">
                        <span *ngIf="this.translate.currentLang == 'zh'"> <a class="" href="javascript:void(0);" (click)="changeLang('en')">En</a> </span>
                        <span *ngIf="this.translate.currentLang == 'en'"> <a class="" href="javascript:void(0);" (click)="changeLang('zh')">中文</a> </span>
                </div>
            </div>
            <div class="col-6 text-right search" *ngIf="!isHomePage">
            </div>
        </div>
        <!--claim search -->
        <div class="row col-12" *ngIf="this.transactionInfo.getAction() == 'claimstatus'">
            <div class="col-6 text-left">
                    <div *ngIf = "!isHomePage" class="menu-btn">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <span class="claim-search-logo">
                        <a href="{{entryUrl}}"><img class="logo" src="assets/images/QBE_logo_black.png"></a>
                    </span>
            </div>
            <div class="col-6 text-right search">
                <div class="lang-box" *ngIf="showLangSelector">
                    <div class="claim-search-translate">
                        <span *ngIf="this.translate.currentLang == 'zh'"> <a class="" href="javascript:void(0);" (click)="changeLang('en')">En</a> </span>
                        <span *ngIf="this.translate.currentLang == 'en'"> <a class="" href="javascript:void(0);" (click)="changeLang('zh')">中文</a> </span>
                    </div>   
                </div>
            </div>
        </div>
        <!--class="container"-->
        <!--</div>-->
    </div>
</nav>
