import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LostPersonalItem} from "../../../../model/travel/lost-personal-item.model";
import {takeUntil} from "rxjs";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-report-the-loss',
    templateUrl: './report-the-loss.component.html',
    styleUrls: ['./report-the-loss.component.css']
})
export class ReportTheLossComponent extends ClaimsBaseComponent implements OnInit {

    reportTheLossForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    lostPersonalItem: LostPersonalItem;
    currency: string;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
    }

    ngOnInit() {

        this.pushGAViewLostItemReport();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
        this.lostPersonalItem = this.claim.getClaimTravel().getLostPersonalItem();

        this.reportTheLossForm = this.fb.group({
            hasAnotherAuthority: [super.getBooleanString(this.lostPersonalItem.getHasAnotherAuthority())],
            authorityType: [this.lostPersonalItem.getAuthorityType()],
            otherAuthorityType: [this.lostPersonalItem.getOtherAuthorityType()],
            reportDate: [this.lostPersonalItem.getReportDate()],
            hasCompensationReceived: [super.getBooleanString(this.lostPersonalItem.getHasCompensationReceived())],
            compensationCurrency: [this.lostPersonalItem.getCompensationCurrency()],
            compensationValue: [this.lostPersonalItem.getCompensationValue()]
        });
    }

    ngAfterViewInit() {

        this.reportTheLossForm.valueChanges.subscribe(data => {
            if (this.reportTheLossForm.get('hasAnotherAuthority').value != null) {
                this.lostPersonalItem.setHasAnotherAuthority(this.reportTheLossForm.get('hasAnotherAuthority').value == 'true');
            }
            this.lostPersonalItem.setAuthorityType(super.getValueInComponent('authorityType'));
            this.lostPersonalItem.setAuthorityTypeDesc(super.getTextInComponent('authorityType'));
            this.lostPersonalItem.setOtherAuthorityType(super.getValueInComponent('otherAuthorityType'));
            this.lostPersonalItem.setReportDate(super.getValueInComponent('reportDate'));
            if (this.reportTheLossForm.get('hasCompensationReceived').value != null) {
                this.lostPersonalItem.setHasCompensationReceived(this.reportTheLossForm.get('hasCompensationReceived').value == 'true');
            }
            this.lostPersonalItem.setCompensationCurrency(this.reportTheLossForm.get('compensationCurrency').value);
            this.lostPersonalItem.setCompensationValue(super.getValueInComponent('compensationValue'));

            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideAnotherAuthority(self.getBooleanString(self.lostPersonalItem.getHasAnotherAuthority())),10});
        this.reportTheLossForm.get('hasAnotherAuthority').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideAnotherAuthority(data);
        });

        setTimeout(function () {self.hideCompensation(self.getBooleanString(self.lostPersonalItem.getHasCompensationReceived())),10});
        this.reportTheLossForm.get('hasCompensationReceived').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideCompensation(data);
        });
    }

    hideCompensation(hasCompensationReceived) {
        if (hasCompensationReceived == 'true') {
            this.reportTheLossForm.get('compensationValue').enable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('compensationCurrency').enable({onlySelf: false, emitEvent: false});
        } else {
            this.reportTheLossForm.get('compensationValue').reset();
            this.reportTheLossForm.get('compensationCurrency').setValue(this.currency);
            this.reportTheLossForm.get('compensationValue').disable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('compensationCurrency').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideAnotherAuthority(hasAnotherAuthority) {
        if (hasAnotherAuthority == 'true') {
            this.reportTheLossForm.get('authorityType').enable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('otherAuthorityType').enable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('reportDate').enable({onlySelf: false, emitEvent: false});
        } else {
            this.reportTheLossForm.get('authorityType').reset();
            this.reportTheLossForm.get('otherAuthorityType').reset();
            this.reportTheLossForm.get('reportDate').reset();
            this.reportTheLossForm.get('authorityType').disable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('otherAuthorityType').disable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('reportDate').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 2});

            this.router.navigate(["/claimform/lostPersonalItem/lossSupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/lostPersonalItem/detailsOfLossItems"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.reportTheLossForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.reportTheLossForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 1, subMenuIndex: 2});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewLostItemReport() {
        (<any>window).dataLayer.push({
            'pageStep': 'Lost Item - Reporting',
            'vPath': '/claim/lost-item/reporting',
            'event': 'vpageview',
        });
    }
}
