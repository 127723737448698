import {Component, OnInit} from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { ClaimService } from 'src/app/services/claim.service';

@Component({
    selector: 'app-home-others',
    templateUrl: './others.component.html',
    styleUrls: ['./others.component.css']
})
export class HomeOthersComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService,
                 private claimService: ClaimService) {
        if (!(this.claimService.getClaim().getClaimHome().getPlaceOfIncident() !== 'home'
                && this.claimService.getClaim().getClaimHome().getHasHSRRisk())) {
                this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OTHERS]);
        }
    }
  
    ngOnInit() {
    }
}
