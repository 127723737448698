import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ClaimMotor} from '../../../../model/claim-motor.model';

@Component({
    selector: 'app-summary-vehicle-details',
    templateUrl: './summary-vehicle-details.component.html',
    styleUrls: ['./summary-vehicle-details.component.css']
})

export class SummaryVehicleDetailsComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimMotor: ClaimMotor;

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimMotor = this.claim.getClaimMotor();

    }
    
    ngOnInit() {

    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
        }
    }

}
