import { AfterViewInit, Directive, Input } from '@angular/core';
import { UiComponent } from '../ui.component';

@Directive({selector: '[qnectChangeValueOnParentValueChange]'})
export class ChangeValueOnParentValueChangeDirective implements AfterViewInit {


  constructor(private uiComponent: UiComponent) {}

  @Input() qnectChangeValueOnParentValueChange: UiComponent;
  @Input() valueMap: string;

  ngAfterViewInit() {
    this.valueMap = JSON.parse(this.valueMap);
    this.changeValue();
    this.qnectChangeValueOnParentValueChange.valueChanges().subscribe(() => {
      this.changeValue();
    });
  }

  private changeValue() {
    const parentValue = this.qnectChangeValueOnParentValueChange.getValue();
    if (this.valueMap[parentValue]) {
      this.uiComponent.setValue(this.valueMap[parentValue]);
    }
  }
}

