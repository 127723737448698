export class BodyPart {

    private bodyPartId: string;
    private bodyPartValue: string;
    private bodyPartDesc: string;
    private formBodyPart: string;
    private natureOfInjury: string[] = [];
    private natureOfInjuryDesc: string[] = []; 

    getBodyPartId(): string {
        return this.bodyPartId;
    }

    setBodyPartId(bodyPartId: string) {
        this.bodyPartId = bodyPartId;
    }

    getBodyPartValue(): string {
        return this.bodyPartValue;
    }

    setBodyPartValue(bodyPartValue: string) {
        this.bodyPartValue = bodyPartValue;
    }

    getBodyPartDesc(): string {
        return this.bodyPartDesc;
    }

    setBodyPartDesc(bodyPartDesc: string) {
        this.bodyPartDesc = bodyPartDesc;
    }

    getNatureOfInjury(): string[] {
        return this.natureOfInjury;
    }

    setNatureOfInjury(natureOfInjury: string[]) {
        this.natureOfInjury = natureOfInjury;
    }

    getNatureOfInjuryDesc(): string[] {
        return this.natureOfInjuryDesc;
    }

    setNatureOfInjuryDesc(value: string[]) {
        this.natureOfInjuryDesc = value;
    }

    getFormBodyPart(): string {
        return this.formBodyPart;
    }

    setFormBodyPart(formBodyPart: string) {
        this.formBodyPart = formBodyPart;
    }

    static jsonConvert(bodyPart: BodyPart): BodyPart {
        bodyPart = Object.assign(new BodyPart(), bodyPart);
        return bodyPart;
    }

}