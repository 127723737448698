import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-left-view-tab',
  templateUrl: './left-view-tab.component.html'
})

@Injectable()
export class LeftViewTabComponent {

  @Output() emitLeftViewTabOnClickArea = new EventEmitter<any>();
  leftViewTabOnClickArea(e: any) {
    this.emitLeftViewTabOnClickArea.emit(e);
  }

  @Output() emitLeftViewTabShowTooltip = new EventEmitter<any>();
  leftViewTabShowTooltip(txt: any) {
    this.emitLeftViewTabShowTooltip.emit(txt);
  }

  @Output() emitLeftViewTabHideToolTip = new EventEmitter<any>();
  leftViewTabHideToolTip() {
    this.emitLeftViewTabHideToolTip.emit();
  }

  @Input() leftViewTabGender: any;

  @Input() leftViewTabTemp: any;

  isSelected(id: string) {
    return this.leftViewTabTemp.some((obj: any) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }
}
