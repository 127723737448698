import { Pipe, PipeTransform } from '@angular/core';
import {UiOptionsService} from './ui-options.service';
import {UiComponent} from './ui.component';

@Pipe({
  name: '_uiOptions'
})
export class UiOptionsPipe implements PipeTransform {

  constructor(private _uiOptions: UiOptionsService) { }

  transform(value: any, args?: any): any {
    if (!args || !value) { return; }
    if (args instanceof UiComponent) {
      return this._uiOptions.getUiOption(args, value);
    } else {
      return this._uiOptions.getConfigOption(args, value);
    }
  }

}
