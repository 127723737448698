import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../../services/transaction-info.service";
import {ClaimTypes} from "../../../../model/claim-type.model";

@Component({
  selector: 'app-motor-accident',
  templateUrl: './motor-accident.component.html',
  styleUrls: ['./motor-accident.component.css']
})
export class MotorAccidentComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]);
    }

  ngOnInit() {
  }

}
