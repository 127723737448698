import {Injectable} from '@angular/core';
import {UiOptionsModel} from "../ui/ui-options.model";

@Injectable()
export class MotorHelperService {

    damageTypeList = [
        {
            "damageTypeId": 1,
            "damageTypeValue": "Vehicle(s)",
            "damageTypeName": "motorClaim.claimSection.motorAccidentCollission.thirdParty.accidentInvolveItems.vehicle",
            "selected" : false
        },
        {
            "damageTypeId": 2,
            "damageTypeValue": "Person(s)",
            "damageTypeName": "motorClaim.claimSection.motorAccidentCollission.thirdParty.accidentInvolveItems.person",
            "selected" : false
        },
        {
            "damageTypeId": 3,
            "damageTypeValue": "Property",
            "damageTypeName": "motorClaim.claimSection.motorAccidentCollission.thirdParty.accidentInvolveItems.property",
            "selected" : false
        }
    ];

    motorPartsList = [
        {
            "motorPartId": 1,
            "motorPartValue": "Left rear window",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.leftRearWindow",
            "selected" : false
        },
        {
            "motorPartId": 2,
            "motorPartValue": "Left front window",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.leftFrontWindow",
            "selected" : false
        },
        {
            "motorPartId": 3,
            "motorPartValue": "Rear Windscreen",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.rearWindScreen",
            "selected" : false
        },
        {
            "motorPartId": 4,
            "motorPartValue": "Sunroof",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.sunRoof",
            "selected" : false
        },
        {
            "motorPartId": 5,
            "motorPartValue": "Front Windscreen",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.frontWindScreen",
            "selected" : false
        },
        {
            "motorPartId": 6,
            "motorPartValue": "Right rear window",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.rightRearWindow",
            "selected" : false
        },
        {
            "motorPartId": 7,
            "motorPartValue": "Right front window",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.rightFrontWindow",
            "selected" : false
        },
        {
            "motorPartId": 8,
            "motorPartValue": "Left mid window",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.leftMidWindow",
            "selected" : false
        
        },
        {
            "motorPartId": 9,
            "motorPartValue": "Right mid window",
            "motorPartName": "motorClaim.claimSection.windscreen.detailsOfRepair.rightMidWindow",
            "selected" : false
        }

    ];

    damagePartsList = [
        {
            "damagePartId": 0,
            "damagePartValue": "Left Front",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.leftFront",
            "icon": "assets/images/motor-icon/motor-damaged-parts/motor-white/left-front.png",
            "iconHover": "assets/images/motor-icon/motor-damaged-parts/motor-grey/left-front.png",
            "iconSelected": "assets/images/motor-icon/motor-damaged-parts/motor-blue/left-front.png",
            "selected" : false
        },
        {
            "damagePartId": 1,
            "damagePartValue": "Right Front",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.rightFront",
            "icon": "assets/images/motor-icon/motor-damaged-parts/motor-white/right-front.png",
            "iconHover": "assets/images/motor-icon/motor-damaged-parts/motor-grey/right-front.png",
            "iconSelected": "assets/images/motor-icon/motor-damaged-parts/motor-blue/right-front.png",
            "selected" : false
        },
        {
            "damagePartId": 2,
            "damagePartValue": "Left Side",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.leftSide",
            "icon": "assets/images/motor-icon/motor-damaged-parts/motor-white/left-side.png",
            "iconHover": "assets/images/motor-icon/motor-damaged-parts/motor-grey/left-side.png",
            "iconSelected": "assets/images/motor-icon/motor-damaged-parts/motor-blue/left-side.png",
            "selected" : false
        },        
        {
            "damagePartId": 3,
            "damagePartValue": "Top",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.top",
            "icon": "assets/images/motor-icon/motor-damaged-parts/motor-white/top.png",
            "iconHover": "assets/images/motor-icon/motor-damaged-parts/motor-grey/top.png",
            "iconSelected": "assets/images/motor-icon/motor-damaged-parts/motor-blue/top.png",
            "selected" : false
        },
        {
            "damagePartId": 4,
            "damagePartValue": "Right Side",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.rightSide",
            "icon": "assets/images/motor-icon/motor-damaged-parts/motor-white/right-side.png",
            "iconHover": "assets/images/motor-icon/motor-damaged-parts/motor-grey/right-side.png",
            "iconSelected": "assets/images/motor-icon/motor-damaged-parts/motor-blue/right-side.png",
            "selected" : false
        },
        {
            "damagePartId": 5,
            "damagePartValue": "Left Rear",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.leftRear",
            "icon": "assets/images/motor-icon/motor-damaged-parts/motor-white/left-rear.png",
            "iconHover": "assets/images/motor-icon/motor-damaged-parts/motor-grey/left-rear.png",
            "iconSelected": "assets/images/motor-icon/motor-damaged-parts/motor-blue/left-rear.png",
            "selected" : false
        },
        {
            "damagePartId": 6,
            "damagePartValue": "Right Rear",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.rightRear",
            "icon": "assets/images/motor-icon/motor-damaged-parts/motor-white/right-rear.png",
            "iconHover": "assets/images/motor-icon/motor-damaged-parts/motor-grey/right-rear.png",
            "iconSelected": "assets/images/motor-icon/motor-damaged-parts/motor-blue/right-rear.png",
            "selected" : false
        },
        {
            "damagePartId": 7,
            "damagePartValue": "Mechanical Parts",
            "damagePartName": "motorClaim.claimSection.repair.damageDetails.areasOfDamage.mechanicalParts",
            "icon": "",
            "selected" : false
        }
    ];

    wsRepairerList = [
        {
            "id": "WSREP01",
            "name": "motorClaim.repairer.gloriousMotors",
            "description": "Glorious Motors Services Ltd"
        },
        {
            "id": "WSREP02",
            "name": "motorClaim.repairer.hipOnGlassShop",
            "description": "Hip On Glass Shop"
        },
        {
            "id": "WSREP03",
            "name": "motorClaim.repairer.japanVehicle",
            "description": "Japan Vehicle Glass Co., Ltd"
        },
        {
            "id": "WSREP04",
            "name": "motorClaim.repairer.worldAutomobile",
            "description": "The World Automobile Glass"
        },
        {
            "id": "WSREP05",
            "name": "motorClaim.repairer.xinYi",
            "description": "Xinyi Automobile Glass Co. Ltd."
        },
        {
            "id": "WSREP06",
            "name": "motorClaim.repairer.yauBong",
            "description": "Yau Bong Car Glass Co., Ltd."
        }
    ];

    getMotorParts() {
        return this.motorPartsList;
    }

    getDamageParts(){
        return this.damagePartsList;
    }

    getDamageType(){
        return this.damageTypeList;
    }

    getWsRepairerOptions() {
        let uiOptions: UiOptionsModel[] = [];
        this.wsRepairerList.forEach(element => {
            const option = new UiOptionsModel(element.id, element.name);
            uiOptions.push(option);
        });
        return uiOptions;
    }

    getWsRepairer(repairerId: string) {
       return this.wsRepairerList.find(repairer => repairer.id === repairerId);
    }

}