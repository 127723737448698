import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClaimMotor } from 'src/app/model/claim-motor.model';
import { PoliceReport } from 'src/app/model/motor/police-report.model';
import { MotorAccidentCollission } from 'src/app/model/motor/motor-accident-collission.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import {ClaimTypes} from '../../../../../model/claim-type.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-motor-accident-detail',
  templateUrl: './motor-accident-detail.component.html',
  styleUrls: ['./motor-accident-detail.component.css']
})
export class MotorAccidentDetailComponent extends ClaimsBaseComponent  implements OnInit {

  policeReportForm: FormGroup;
  motorAccidentForm: FormGroup;
  claimMotor: ClaimMotor;
  policeReport: PoliceReport;
  collission : MotorAccidentCollission;
  showTotalBox = false;
  policyHolderName: string;
  driverName : string;
  constructor(private fb: FormBuilder,
              private transactionInfoService: TransactionInfoService,
              private claimService: ClaimService,
              private sideMenuService: SideMenuService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private injector : Injector) {
      super(injector);
      this.claim = this.claimService.getClaim();
      this.claimMotor = this.claim.getClaimMotor();
      this.policeReport = this.claimMotor.getPoliceReport();
      this.collission = this.claimMotor.getCollission();
  }

  ngOnInit() {

      this.pushGA();
    
    if (UtilitiesService.isNullOrUndefined(this.collission.getCountryOccurence()) || this.collission.getCountryOccurence() == "") {
      this.collission.setCountryOccurenceDesc("HONG KONG");
      this.collission.setCountryOccurence('HKG');
    }

     if (!this.collission.getAccidentIncidentDate()) {
     this.collission.setAccidentIncidentDate(this.claim.getDateOfLoss());
     }

      this.motorAccidentForm = this.fb.group({
        accidentIncidentDate: [this.collission.getAccidentIncidentDate()],
        countryOccurence: [this.collission.getCountryOccurence()],
        incidentAccidentOccur: [this.collission.getIncidentAccidentOccur()],
        wasVehicleParkWhenDamage: [super.getBooleanString(this.collission.getWasVehicleParkWhenDamage())],
        purposeOfUse: [this.collission.getPurposeOfUse()],
        descriptionOfAccident: [this.collission.getDescriptionOfAccident()]
      });
      this.policyHolderName = this.claim.getPolicyHolderName();
      this.driverName = this.claimMotor.getDriverGivenName() + +this.claimMotor.getDriverFamilyName();
  }
  
  ngAfterViewInit() {

      this.motorAccidentForm.valueChanges.subscribe(data => {
        if(this.motorAccidentForm.get('wasVehicleParkWhenDamage').value != null) {
          this.collission.setWasVehicleParkWhenDamage(this.motorAccidentForm.get('wasVehicleParkWhenDamage').value == 'true')
        }
        this.collission.setAccidentIncidentDate(super.getValueInComponent('accidentIncidentDate'));
        this.collission.setCountryOccurence(this.motorAccidentForm.get('countryOccurence').value);
        this.collission.setCountryOccurenceDesc(super.getTextInComponent('countryOccurence'));
        this.collission.setIncidentAccidentOccur(this.motorAccidentForm.get('incidentAccidentOccur').value);
        this.collission.setPurposeOfUse(super.getValueInComponent('purposeOfUse'));
        this.collission.setPurposeOfUseDesc(super.getTextInComponent('purposeOfUse'));
        this.collission.setDescriptionOfAccident(this.getTextInComponent('descriptionOfAccident'));

        this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 1, claimTypeIdName: ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION], subMenuIndex: 1, value: "Driver Details"});
       });
       this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 1, claimTypeIdName: ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION], subMenuIndex: 2, value: "Details of the Damage to Insured Vehicle"});
       
    }


  back() {
      this.router.navigate(["/claimform/claimDetail/selectClaim"], {
          relativeTo: this.activatedRoute
      });
  }

  goToNext() {
    if (this.validateForm()) {
      this.sideMenuService.emitProcess(1, 0);
      this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 0});
      let coverType = this.claimMotor.getCoverType();
      if(this.collission.getWasVehicleParkWhenDamage() === true){
          if (coverType === "CO" || coverType === "TF" || this.isNotNullOrUndefinedStr(this.claim.getIsCoverNotePreNumbered())) {
              this.router.navigate(["/claimform/collission/vehicleDamage"], {
                  relativeTo: this.activatedRoute
              });
          } else {
              this.router.navigate(["/claimform/collission/thirdPartyDetails"], {
                  relativeTo: this.activatedRoute
              });
          }
      }else{
        this.router.navigate(["/claimform/collission/driverDetails"], {
          relativeTo: this.activatedRoute
       });
      }
    }
  }

  validateForm(): boolean {
      return super.validateForm(this.motorAccidentForm);
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

    // Google Analytics
    pushGA() {
        (<any>window).dataLayer.push({
            'pageStep': 'Motor Accident/Damage –Damage-Accident Details',
            'vPath': '/claim/motor-accident-damage/damage-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},
                }
            }
        });
    }
}
