import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-miss-connect-support-doc',
  templateUrl: './miss-connect-support-doc.component.html',
  styleUrls: ['./miss-connect-support-doc.component.css']
})
export class MissConnectSupportDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
