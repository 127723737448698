 <div class="form-group section">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionTripCurt.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.dateOriginBook' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(this.tripCurtailment.getDateOriginBook())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.countryCurtailmentOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCurtailment.getCountryCurtailmentOccurredDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.dateCancelTrip' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(this.tripCurtailment.getDateActualDeparture())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.reasonForCancel' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content"  *ngIf="this.tripCurtailment.getReasonForCancel()?.toLowerCase() !== 'others'">
                    {{this.tripCurtailment.getReasonForCancelDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content"  *ngIf="this.tripCurtailment.getReasonForCancel()?.toLowerCase() === 'others'">
                    {{this.tripCurtailment.getOtherReason()}}
                </div>
            </div>
            <ng-container *ngIf="this.claim.getCountry().toLowerCase() == 'hgi' && this.tripCurtailment.getReasonForCancel()?.toLowerCase() == 'lost travel documents'">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.wasLossDueToRobbery' | translate}}
                        
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(this.tripCurtailment.getWasLossDueToRobbery())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.tripCurtailment.getWasLossDueToRobbery()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.didYouReport' | translate}}
                        
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(this.tripCurtailment.getDidYouReport())}}
                    </div>
                </div>
                <ng-container *ngIf="this.tripCurtailment.getDidYouReport()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.authority' | translate}}
                            
                        </div>
                        <div class="col col-xs-12 col-lg-5 content"  *ngIf="this.tripCurtailment.getAuthorityType()?.toLowerCase() !== 'others'">
                            {{this.tripCurtailment.getAuthorityTypeDesc()}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content"  *ngIf="this.tripCurtailment.getAuthorityType()?.toLowerCase() === 'others'">
                            {{this.tripCurtailment.getOtherAuthorityType()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionTripCurt.subTripCurt.reportDate' | translate}}
                            
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(this.tripCurtailment.getReportDate())}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTripCurt.subLossCurt.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subLossCurt.didUseMiles' | translate}}
                    
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.tripCurtailment.getDidUseMiles())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subLossCurt.totalAmount' | translate}}
                    
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCurtailment.getTotalCostTypeOfTrip()}}
                    {{this.tripCurtailment.getTotalCost() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subLossCurt.refund' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCurtailment.getHasRefundDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="this.tripCurtailment.getHasRefund()=='true'">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subLossCurt.totalRefund' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCurtailment.getTotalAmountRefundedType()}}
                    {{this.tripCurtailment.getTotalAmountRefunded() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTripCurt.subExtraCost.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subExtraCost.hasTransCost' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.tripCurtailment.getHasTransExtraCost())}}
                </div>
            </div>
            <div class="row" *ngIf="this.tripCurtailment.getHasTransExtraCost()">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subExtraCost.extraTransCost' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCurtailment.getExtraTransCostCurrency()}}
                    {{this.tripCurtailment.getExtraTransCostVal() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subExtraCost.hasAccomCost' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.tripCurtailment.getHasAccomExtraCost())}}
                </div>
            </div>
            <div class="row" *ngIf="this.tripCurtailment.getHasAccomExtraCost()">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCurt.subExtraCost.extraAccommCost' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCurtailment.getExtraAccomCostCurrency()}}
                    {{this.tripCurtailment.getExtraAccomCostVal() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>