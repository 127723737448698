export class HospitalExpenseDetails {

    private hospitalName: string;
    private dateAdmission: Date;
    private dateDischarge: Date;
    private hospitalExpense: number;
    private hospitalExpenseCurrency: string;

    constructor() {
        this.hospitalName = '';
        this.dateAdmission = null;
        this.dateDischarge = null;
        this.hospitalExpense = null;
        this.hospitalExpenseCurrency = '';
    }

    public getHospitalName(): string {
        return this.hospitalName;
    }

    public setHospitalName(hospitalName: string): void {
        this.hospitalName = hospitalName;
    }

    public getDateAdmission(): Date {
        return this.dateAdmission;
    }

    public setDateAdmission(dateAdmission: Date): void {
        this.dateAdmission = dateAdmission;
    }

    public getDateDischarge(): Date {
        return this.dateDischarge;
    }

    public setDateDischarge(dateDischarge: Date): void {
        this.dateDischarge = dateDischarge;
    }

    public getHospitalExpense(): number {
        return this.hospitalExpense;
    }

    public setHospitalExpense(hospitalExpense: number): void {
        this.hospitalExpense = hospitalExpense;
    }

    public getHospitalExpenseCurrency(): string {
        return this.hospitalExpenseCurrency;
    }

    public setHospitalExpenseCurrency(hospitalExpenseCurrency: string): void {
        this.hospitalExpenseCurrency = hospitalExpenseCurrency;
    }

    static jsonConvert(hospitalExpense: HospitalExpenseDetails): HospitalExpenseDetails {
    
        if (hospitalExpense.getDateAdmission() != null) {
            hospitalExpense.setDateAdmission(new Date(hospitalExpense.getDateAdmission()));
        }

        if (hospitalExpense.getDateDischarge() != null) {
            hospitalExpense.setDateDischarge(new Date(hospitalExpense.getDateDischarge()));
        }
    
        return hospitalExpense;
    }
}