<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'homeProduct.common.tellUsWhatHappened' | translate }}</h5>
    <p *ngIf="!this.isBurglary">{{ 'homeProduct.homeItems.detailsDamage' | translate}}</p>
    <p *ngIf="this.isBurglary">{{ 'homeProduct.homeItems.detailsBoth' | translate}}</p>
  </div> 
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form [formGroup]="homeItemsDetailForm">
        <div class="form-group" *ngIf="this.isBurglaryOrAccident">
          <qnect-ui #HOME_CONTENTSBUILDING_INCIDENTDESCRIPTION [fieldId]="'HOME_CONTENTSBUILDING_INCIDENTDESCRIPTION'" [formGroup]="homeItemsDetailForm" name="damageDescription"></qnect-ui>
        </div>   
        <div formArrayName="items" *ngFor="let item of homeItemsDetailForm.get('items')['controls']; let i = index;">
          <div [formGroupName]="i">
            <div class="form-group click-more-content">
              <label>{{'claimSection.damagePersonalItem.detailsOfDamageItems.item' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeItem(i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
              
              <qnect-ui *ngIf="!isBurglary" [fieldId]="'LOSS_ITEMS_HOME'"
                [frmArrayGroup]="homeItemsDetailForm"
                [frmArrayName]="'items'"
                frmArrayIndex="{{i}}"
                name="lossType"></qnect-ui>

                <qnect-ui *ngIf="isBurglary" [fieldId]="'LOSS_ITEMS_HOME_BURGLARY'"
                [frmArrayGroup]="homeItemsDetailForm"
                [frmArrayName]="'items'"
                frmArrayIndex="{{i}}"
                name="lossType"></qnect-ui>
             
            </div>
            <div *ngIf="this.detailsOfHomeItemsArray != null && this.detailsOfHomeItemsArray.length > 0">
              <div *ngIf="this.detailsOfHomeItemsArray[i].getLossType() == 'Others'">
                  <div class="form-group">
                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_14'"
                              [frmArrayGroup]="homeItemsDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="lossTypeDetail"></qnect-ui>
                  </div>
                  <div class="form-group">
                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_15'"
                              [frmArrayGroup]="homeItemsDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="otherTypeBriefDesc"></qnect-ui>
                  </div>
              </div>
              <div *ngIf="this.detailsOfHomeItemsArray[i].getLossType() == 'Laptop(s)' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Computer(s)' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Mobile Phone' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'TV/Television' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Watch' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Washing Machine' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Air Conditioner' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Induction Stove' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Exhaust/Ventilator' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Mattress' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Carpets' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Clothing/Personal Items' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Curtains/Blinds' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Furniture - Indoor' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Furniture - Outdoor' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Personal Documents' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Sporting Equipment' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Tools' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Appliances' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Bicycles' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Electronics' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Jewellery' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Tools' ||
              this.detailsOfHomeItemsArray[i].getLossType() == 'Others'">
              <div *ngIf="this.detailsOfHomeItemsArray[i].getLossType() == 'Mobile Phone' || this.detailsOfHomeItemsArray[i].getLossType() == 'Laptop(s)'" class="form-group">
                  <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_18'"
                            [frmArrayGroup]="homeItemsDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="descOfIncident"></qnect-ui>
                </div>   
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_16'"
                            [frmArrayGroup]="homeItemsDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="repairCost"></qnect-ui>
                </div>
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'LOSS_ITEMS_REPLACEMENT_COST'"
                            [frmArrayGroup]="homeItemsDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="replacementCost"></qnect-ui>
                </div>
              </div>
              <div *ngIf="detailsOfHomeItemsArray[i].getLossType() == 'Cash' && this.isBurglary">
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'LOSS_ITEMS_CASH_AMOUNT'"
                            [frmArrayGroup]="homeItemsDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="lostMoneyAmount"></qnect-ui>
                </div>
              </div>
            </div>
            <div class="col-xl-2 col-lg-12">
                &nbsp;
            </div>
          </div>
        </div>
      </form>
      <div class="addFile" (click)="addItem()">
        <i class="fas fa-plus"></i>
        <span>{{ 'claimSection.generalLabels.generalButtonLabels.add' | translate}}</span>
      </div>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
