import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { Claim } from "../../model/claim.model";
import { ClaimTypes } from "../../model/claim-type.model";
import { DocumentField } from "../../model/document-field";
import { OnInit } from "@angular/core";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class RentalVehicleFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.airTicket', SmartqDocuments.AIRTICKET, true));
        requiredDocuments.push(new DocumentField('RENTAGR', 'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.rentAgreement', SmartqDocuments.CARLEASEAGREEMENT, true));
        requiredDocuments.push(new DocumentField('RENTRECEIPT', 'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.rentalReceipt', SmartqDocuments.INVOICE, true));
        requiredDocuments.push(new DocumentField('REPAIRRECEIPT', 'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.repairReceipt', SmartqDocuments.INVOICE, true));
        requiredDocuments.push(new DocumentField('INCIDENTREPORT', 'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.damageReport', SmartqDocuments.REPORT, true));
        if(UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth())){
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}
