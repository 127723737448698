import { AfterViewInit, Directive, DoCheck, Input } from '@angular/core';
import { UiComponent } from '../ui.component';
import {Subject, takeUntil} from "rxjs";

@Directive({selector: '[qnectToggleDisableOnParentValueChange]'})
export class ToggleDisableOnParentValueChangeDirective implements AfterViewInit, DoCheck {


  constructor(private uiComponent: UiComponent) {}

  @Input() qnectToggleDisableOnParentValueChange: UiComponent;
  @Input() disableValues: string[] = [];

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.toggleDisable();

    this.qnectToggleDisableOnParentValueChange
        .valueChanges()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.toggleDisable();
        });
  }

  ngDoCheck() {
  }

  private toggleDisable() {
    let value = this.qnectToggleDisableOnParentValueChange.getValue();
    if (this.disableValues.indexOf(value) > -1) {
      this.uiComponent.disable();
    } else {
      this.uiComponent.enable();
    }
  }
}
