<qnect-modal-dialog>
  <div class="app-modal-header">
    Error
    <button type="button" class="close" aria-label="Close" (click)="modal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    An error has occurred: {{errorMsg}}
  </div>
  <div class="app-modal-footer">
    <div class="row btn-row" style="margin: 10px 0;">
      <div class="col-md-6 pull-left btn-left">
        <button type="button" class="btn btn-default" (click)="modal.hide()">
          OK
        </button>
      </div>
      <div class="col-md-6 pull-right btn-right">
        <!--<button type="button" class="btn btn-primary" (click)="abandon()">
          OK
        </button>-->
      </div>
    </div>
  </div>
</qnect-modal-dialog>
