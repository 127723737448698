import {Injectable} from "@angular/core";
import {DocumentFormBuilderComponent} from "../claim-pages/document-form-builder/document-form-builder.component";
import {TravelDelayFormBuilder} from "../claim-pages/document-form-builder/travel-delay-form-builder";
import {Claim} from "../model/claim.model";
import {VisitedDoctorFormBuilder} from "../claim-pages/document-form-builder/visited-doctor-form-builder";
import {ClaimService} from "./claim.service";
import {OtherClaimsFormBuilder} from "../claim-pages/document-form-builder/other-claims-form-builder";
import {ClaimTypes} from "../model/claim-type.model";
import {StayedInHospitalFormBuilder} from "../claim-pages/document-form-builder/stayed-in-hospital-form-builder";
import {DeathAndSeriousFormBuilder} from "../claim-pages/document-form-builder/death-and-serious-form-builder";
import {MissedConnectionFormBuilder} from "../claim-pages/document-form-builder/missed-connection-form-builder";
import {MissedEventFormBuilder} from "../claim-pages/document-form-builder/missed-event-form-builder";
import {RentalVehicleFormBuilder} from "../claim-pages/document-form-builder/rental-vehicle-form-builder";
import {BaggageDelayFormBuilder} from "../claim-pages/document-form-builder/baggage-delay-form-builder";
import {DamagedPersonalItemFormBuilder} from "../claim-pages/document-form-builder/damaged-personal-item-form-builder";
import {TripCancellationFormBuilder} from "../claim-pages/document-form-builder/trip-cancellation-form-builder";
import {LostPersonalItemFormBuilder} from "../claim-pages/document-form-builder/lost-personal-item-form-builder";
import {TripCurtailmentFormBuilder} from "../claim-pages/document-form-builder/trip-curtailment-form-builder";
import {TravelReroutingFormBuilder} from "../claim-pages/document-form-builder/travel-rerouting-form-builder";
import {PersonalLiabilityFormBuilder} from "../claim-pages/document-form-builder/personal-liability-form-builder";
import {ClinicalExpenseFormBuilder} from "../claim-pages/document-form-builder/domestic-helper-form-builder/clinical-expense-form-builder";
import { DentalExpenseFormBuilder } from "../claim-pages/document-form-builder/domestic-helper-form-builder/dental-expense-form-builder";
import { HelpersLiabilityFormBuilder } from "../claim-pages/document-form-builder/domestic-helper-form-builder/helpers-liability-form-builder";
import { DishonestyHelperFormBuilder } from "../claim-pages/document-form-builder/domestic-helper-form-builder/dishonesty-helper-form-builder";
import { EmployeeCompensationFormBuilder } from "src/app/claim-pages/document-form-builder/domestic-helper-form-builder/employee-compensation-form-builder";
import { OtherClaimFormBuilder } from "../claim-pages/document-form-builder/domestic-helper-form-builder/other-claim-form-builder";
import { HospitalizationHelperFormBuilder } from "../claim-pages/document-form-builder/domestic-helper-form-builder/hospitalization-helper-form-builder";
import { RepatriationFormBuilder } from "../claim-pages/document-form-builder/domestic-helper-form-builder/repatriation-form-builder";
import { DeathAndPermanentDisabilityFormBuilder } from "src/app/claim-pages/document-form-builder/domestic-helper-form-builder/death-and-permanent-disability-form-builder";
import { WindscreenFormBuilder } from "../claim-pages/document-form-builder/motor-form-builder/windscreen-form-builder";
import { RepairFormBuilder } from "../claim-pages/document-form-builder/motor-form-builder/repair-form-builder";
import { DamageFormBuilder } from "../claim-pages/document-form-builder/motor-form-builder/damage-form-builder";
import { TheftFormBuilder } from "../claim-pages/document-form-builder/motor-form-builder/theft-form-builder";
import { CollisionFormBuilder } from '../claim-pages/document-form-builder/motor-form-builder/collision-form-builder';
import { GenericFormBuilder } from "../claim-pages/document-form-builder/generic-form-builder";
import { TransactionInfoService } from "./transaction-info.service";
import { TransactionInfo } from "../model/transaction-info.model";
import { WaterFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/water-form-builder";
import { WeatherFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/weather-form-builder";
import { AccidentalDamageFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/accidental-damage-form-builder";
import { OthersHomeFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/others-home-form-builder";
import { FireFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/fire-form-builder";
import { BurglaryFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/burglary-form-builder";
import { VandalismFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/vandalism-form-builder";
import {OutsidehomeLostDamageFormBuilder} from '../claim-pages/document-form-builder/home-form-builder/outsidehome-lost-damage-form-builder';
import { DomesticEmployerClaimFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/domestic-employer-claim-form-builder";
import { PersonalAccidentClaimFormBuilder } from "../claim-pages/document-form-builder/home-form-builder/personal-accident-claim-form-builder";
import { AccidentalMedicalExpensesFormBuilder } from "../claim-pages/document-form-builder/pa-form-builder/accidental-medical-expenses-form-builder";
import { HospitalCashFormBuilder } from "../claim-pages/document-form-builder/pa-form-builder/hospital-cash-form-builder";
import { OthersPaFormBuilder } from "../claim-pages/document-form-builder/pa-form-builder/others-pa-form-builder";
import {ECFormBuilder} from '../claim-pages/document-form-builder/ec-form-builder';

@Injectable({
  providedIn: 'root'
})
export class DocumentFormFactoryService{
  private transInfo: TransactionInfo; 

  constructor(private claimService: ClaimService,
              private transInfoService: TransactionInfoService) {

  }

  getDocumentBuilder(claimType: string): DocumentFormBuilderComponent{
    let claim : Claim = this.claimService.getClaim();
    this.transInfo = this.transInfoService.getTransactionInfo();
    return this.getDocumentFormBuilder(claimType, claim);
  }

  getDocumentFormBuilder(claimType: string, claim: Claim): DocumentFormBuilderComponent {

    if(!this.transInfo.isHomeProduct()){
      if(claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]){
        return new TravelDelayFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT]){
        return new VisitedDoctorFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]){
        return new OtherClaimsFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]){
        return new MissedConnectionFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]){
        return new LostPersonalItemFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]){
        return new DamagedPersonalItemFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]){
        return new StayedInHospitalFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]){
        return new DeathAndSeriousFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]){
        return new BaggageDelayFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]){
        return new TripCancellationFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]){
        return new TripCurtailmentFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT]){
        return new TravelReroutingFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT]){
        return new MissedEventFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]){
        return new RentalVehicleFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]){
        return new PersonalLiabilityFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]){
        return new ClinicalExpenseFormBuilder(claim);
      } else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]){
        return new DentalExpenseFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY]){
        return new HelpersLiabilityFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY]){
        return new DishonestyHelperFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]){
        return new EmployeeCompensationFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS]){
        return new OtherClaimFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL]){
        return new HospitalizationHelperFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]){
        return new RepatriationFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH]){
        return new DeathAndPermanentDisabilityFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]){
        return new WindscreenFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_REPAIR]){
        return new RepairFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_DAMAGE]){
        return new DamageFormBuilder(claim);
      }else if (claimType==ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]){
        return new TheftFormBuilder(claim);
      }else if(claimType ==ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]){
        return new CollisionFormBuilder(claim);
      } else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]) {
        return new GenericFormBuilder(claim, this.transInfo);
      } else  if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]) {
        return new AccidentalMedicalExpensesFormBuilder(claim);
      } else if (claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]) {
        return new HospitalCashFormBuilder(claim);
      } else if (claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]) {
        return new OthersPaFormBuilder(claim);
      } else if([ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2],
          ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A],
          ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B]].indexOf(claimType) !== -1) {
          return new ECFormBuilder(claim, this.transInfo.getCurrentClaimType());
      }
    } else {
      if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WATER]) {
        return new WaterFormBuilder(claim);
      } else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER]) {
        return new WeatherFormBuilder(claim);
      } else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT]) {
        return new AccidentalDamageFormBuilder(claim);
      }  else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE]) {
        return new FireFormBuilder(claim);
      }  else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY]) {
        return new BurglaryFormBuilder(claim);
      }  else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_VANDALISM]) {
        return new VandalismFormBuilder(claim);
      }  else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]) {
        return new DomesticEmployerClaimFormBuilder(claim);
      }  else if(claimType == ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA]) {
        return new PersonalAccidentClaimFormBuilder(claim);
      } else if([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OTHERS],
        ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OUTSIDE_HSR], 
        ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OUTSIDE_NOT_HSR], 
        ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_NOT_ADDRESS]].indexOf(claimType) > -1){
        return new OthersHomeFormBuilder(claim, claimType, this.transInfo.isResidentialFire());
      } else {
        return new OutsidehomeLostDamageFormBuilder(claim);
      }
    }
    return null;
  }
}
