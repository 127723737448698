import {Component, Injector, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { ClaimsBaseComponent } from "src/app/claim-pages/claim-base.component";
import { Claim } from "src/app/model/claim.model";
import { DamagePersonalItemDetail } from "src/app/model/travel/damage-personal-detail.model";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { DamagePersonalItem } from "src/app/model/travel/damage-personal-item.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { UtilitiesService } from "src/app/utilities/utilities.service";
declare var $: any;

@Component({
  selector: 'app-damaged-items-details',
  templateUrl: './damaged-items-details.component.html',
  styleUrls: ['./damaged-items-details.component.css']
})
export class DamagedItemsDetailsComponent extends ClaimsBaseComponent implements OnInit {
    
    damageDetailForm: FormGroup;
    items: any[] = [];
    selectType: string;
    showTotalBox = false;
    claim: Claim;
    detailsOfDamageItemsArray: DamagePersonalItemDetail[];
    damagePersonalItem: DamagePersonalItem;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();

            this.damagePersonalItem = this.claim.getClaimHome().getHomeDetails().getDamagePersonalItem();
            
    }

    ngOnInit() {
        this.pushGADamagedDetailsView();

        this.detailsOfDamageItemsArray = this.damagePersonalItem.getDetailsOfDamageItemsArray();
        this.damageDetailForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.detailsOfDamageItemsArray))
        });
    }

    ngAfterViewInit() {

        this.damageDetailForm.valueChanges.subscribe(data => {

            this.detailsOfDamageItemsArray = [];

            for (let i = 0; i < data.items.length; i++) {

                let itemDetail : DamagePersonalItemDetail = new DamagePersonalItemDetail();

                itemDetail.setLossType(data.items[i].lossType);
                itemDetail.setLossTypeDesc(super.getIndexedTextInComponent('lossType', i));
                itemDetail.setLossTypeDetail(data.items[i].lossTypeDetail);
                itemDetail.setPurchaseDate(super.getIndexedValueInComponent("purchaseDate", i));
                itemDetail.setPurchasePriceType(data.items[i].purchasePriceType);
                itemDetail.setPurchasePrice(data.items[i].purchasePrice);
                itemDetail.setRepairCostType(data.items[i].repairCostType);
                itemDetail.setRepairCost(data.items[i].repairCost);
                itemDetail.setOtherTypeBriefDesc(data.items[i].otherTypeBriefDesc);
            
                this.detailsOfDamageItemsArray.push(itemDetail);
            }

            this.damagePersonalItem.setDetailsOfDamageItemsArray(this.detailsOfDamageItemsArray);
        });

        // Stop validation of fields when not required.
        let formArray : FormArray =  <FormArray> this.damageDetailForm.controls["items"];
        for (let i = 0; i < formArray.controls.length; i++) {
            formArray.controls[i].get('lossType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.damageTypeChanged(data, formArray.controls[i]);
            });
        }
    }

    damageTypeChanged(lossType, formGroup) {
        formGroup.get('lossTypeDetail').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchaseDate').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePriceType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePrice').disable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCostType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('otherTypeBriefDesc').disable({onlySelf: false, emitEvent: false});

        if (lossType == 'Others') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('otherTypeBriefDesc').enable({onlySelf: false, emitEvent: false});
        } else if (lossType == 'Mobile Phone') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
        }
        formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCostType').enable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCost').enable({onlySelf: false, emitEvent: false});
    }

    buildStoredItems(detailsOfDamageItemsArray: DamagePersonalItemDetail[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(detailsOfDamageItemsArray != null && detailsOfDamageItemsArray.length > 0 ) {

            for (let i = 0; i < detailsOfDamageItemsArray.length; i++) {

                let rowFormGroup = this.fb.group({
                    lossType: [detailsOfDamageItemsArray[i].getLossType()],
                    lossTypeDetail: [detailsOfDamageItemsArray[i].getLossTypeDetail()],
                    purchaseDate: [detailsOfDamageItemsArray[i].getPurchaseDate()],
                    purchasePriceType: [detailsOfDamageItemsArray[i].getPurchasePriceType()],
                    purchasePrice: [detailsOfDamageItemsArray[i].getPurchasePrice()],
                    repairCostType: [detailsOfDamageItemsArray[i].getRepairCostType()],
                    repairCost: [detailsOfDamageItemsArray[i].getRepairCost()],
                    otherTypeBriefDesc: [detailsOfDamageItemsArray[i].getOtherTypeBriefDesc()]
                });

                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        let currency = this.claim.getCountry() != 'SGP' ? 'HKD' : 'SGD';
        return this.fb.group({
            lossType: null,
            lossTypeDetail: '',
            purchaseDate: '',
            purchasePriceType: currency,
            purchasePrice: '',
            repairCostType: currency,
            repairCost: '',
            otherTypeBriefDesc: ''
        });

    }

    addItem(): void {
        let self = this;
        setTimeout(function () {

            let items = self.damageDetailForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);

            newItem.get('lossType').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.damageTypeChanged(data, newItem);
            });

        }, 10);
    }

    removeItem(i) {
        let items = this.damageDetailForm.get('items') as FormArray;
        items.removeAt(i);
        this.detailsOfDamageItemsArray.splice(i, 1);
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        this.validatePurchasePriceRepairCost();
        let result = super.validateForm(this.damageDetailForm);
        if (!result) {
            this.enablePurchasePriceRepairCost();
        }

        return result;
    }

    validatePurchasePriceRepairCost() {
        let data: DamagePersonalItemDetail[] = this.damagePersonalItem.getDetailsOfDamageItemsArray();
        if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (this.isNotNullOrUndefinedNum(data[i].getPurchasePrice()) || this.isNotNullOrUndefinedNum(data[i].getRepairCost())) {

                    let formArray: FormArray = <FormArray>this.damageDetailForm.controls["items"];
                    for (let j = 0; j < formArray.controls.length; j++) {
                        let isPurchasePriceEmpty: boolean = this.isNotNullOrUndefinedNum(data[j].getPurchasePrice()) ? false : true;
                        let isRepairCostEmpty: boolean = this.isNotNullOrUndefinedNum(data[j].getRepairCost()) ? false : true;
                        if (!isPurchasePriceEmpty && isRepairCostEmpty) {
                            formArray.controls[i].get('repairCost').disable({onlySelf: false, emitEvent: false});
                         } else if (!isRepairCostEmpty && isPurchasePriceEmpty) {
                            formArray.controls[i].get('purchasePrice').disable({onlySelf: false, emitEvent: false});
                        }
                    }
                }
            }
        }
    }

    enablePurchasePriceRepairCost() {
        let data: DamagePersonalItemDetail[] = this.damagePersonalItem.getDetailsOfDamageItemsArray();
        if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (this.isNotNullOrUndefinedNum(data[i].getPurchasePrice()) || this.isNotNullOrUndefinedNum(data[i].getRepairCost())) {
                    let formArray : FormArray =  <FormArray> this.damageDetailForm.controls["items"];
                    for (let i = 0; i < formArray.controls.length; i++) {
                        formArray.controls[i].get('repairCost').enable({onlySelf: false, emitEvent: false});
                        formArray.controls[i].get('purchasePrice').enable({onlySelf: false, emitEvent: false});
                    }
                }
            }
        }
    }

    validateMoneyFields(): boolean {
        let data: DamagePersonalItemDetail[] = this.damagePersonalItem.getDetailsOfDamageItemsArray();
        for (let i = 0; i < data.length; i++) {
                let formArray : FormArray =  <FormArray> this.damageDetailForm.controls["items"];
                for (let i = 0; i < formArray.controls.length; i++) {

                    if ((UtilitiesService.isNullOrUndefined(formArray.controls[i].get('repairCost').value) || formArray.controls[i].get('repairCost').value == "") &&
                        (UtilitiesService.isNullOrUndefined(formArray.controls[i].get('purchasePrice').value) || formArray.controls[i].get('purchasePrice').value == "")) {
                        return true;
                    }

                }

        }
        return false;
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.damageDetailForm);
        let isMoneyFieldsPopulated : boolean = this.validateMoneyFields();
        if(isFormValidOnChange && !isMoneyFieldsPopulated){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 2, subMenuIndex: 1});
        }
    }

    save(): boolean {
        this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});
        this.sideMenuService.emitProcess(1, 0);
        return true;
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    changeType(val) {
        var that = this;
        setTimeout(() => {
            $('.datetimepicker').daterangepicker(
                {
                    format: 'yyyy-mm-dd',
                    singleDatePicker: true,
                    minYear: 1900,
                    maxYear: 3000,
                    showDropdowns: true,
                    minDate: '01/01/1900',

                }).on('change', function (ev) {
                let tmpName = $(this).attr('data-name').split('-');
                let arrayItems = that.damageDetailForm.get('items') as FormArray;
                arrayItems.at(tmpName[1]).patchValue({
                    purchaseDate: ev.currentTarget.value
                })
            });
        });
    }

    pushGADamagedDetailsView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Details of Damaged Item';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details-of-damaged-item';
    
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
          'pageStep': pageStep,
          'vPath': vPath,
          'event': 'vpageview',
          'ecommerce': {
            'checkout': {
                'actionField': {'step': 4}	 //Step4-Claim Details
              }
          }
        });
    }

}
