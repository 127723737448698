import {Component, Injector, OnInit} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DeathAndSeriousBodyInjuryLoss} from "../../../../model/travel/death-and-serious-body-injury-loss";
import {takeUntil} from "rxjs";

@Component({
  selector: 'app-accident-follow-up-treament',
  templateUrl: './accident-follow-up-treament.component.html',
  styleUrls: ['./accident-follow-up-treament.component.css']
})
export class AccidentFollowUpTreamentComponent extends ClaimsBaseComponent implements OnInit {

    detailOfFollowUpTreamentForm: FormGroup;
    hasReceiveFollowUpTreament: boolean;
    showTotalBox = false;
    deathAndSeriousBodyInjuryLoss:DeathAndSeriousBodyInjuryLoss;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.deathAndSeriousBodyInjuryLoss = this.claim.getClaimTravel().getDeathAndSeriousBodyInjuryLoss();
    }
    ngOnInit() {

        this.pushGAViewAccidentFollowUp();

        this.detailOfFollowUpTreamentForm = this.fb.group(
            {
                hasReceiveFollowUpTreament: [super.getBooleanString(this.deathAndSeriousBodyInjuryLoss.getHasReceiveFollowUpTreament())],
                returnHkDate: [this.deathAndSeriousBodyInjuryLoss.getReturnHkDate()],
                lastTreamentDate: [this.deathAndSeriousBodyInjuryLoss.getLastTreamentDate()]
            }
        );

    }
    ngAfterViewInit() {
        this.detailOfFollowUpTreamentForm.valueChanges.subscribe(data =>{
            this.deathAndSeriousBodyInjuryLoss.setReturnHkDate(super.getValueInComponent('returnHkDate'));
            if (this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').value != null) {
                this.deathAndSeriousBodyInjuryLoss.setHasReceiveFollowUpTreament(this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').value == 'true');
            }
            this.deathAndSeriousBodyInjuryLoss.setLastTreamentDate(super.getValueInComponent('lastTreamentDate'));

            this.validateClaimDetailsComplete();

            this.hideRefundAmount(this.deathAndSeriousBodyInjuryLoss.getHasReceiveFollowUpTreament(), 
                this.deathAndSeriousBodyInjuryLoss.getReturnHkDate());
    
        }); 

        // Stop validation of fields when not required.
        let self = this;

        setTimeout(function () {
            self.hideRefundAmount(self.getBooleanString(self.deathAndSeriousBodyInjuryLoss.getHasReceiveFollowUpTreament()),
            self.deathAndSeriousBodyInjuryLoss.getReturnHkDate()), 10
        });

        this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideRefundAmount(data, this.deathAndSeriousBodyInjuryLoss.getReturnHkDate());
        });

    }

    hideRefundAmount(hasRefund, returnDate) {
        if (hasRefund && returnDate != undefined) {
            this.detailOfFollowUpTreamentForm.get('lastTreamentDate').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailOfFollowUpTreamentForm.get('lastTreamentDate').disable({onlySelf: false, emitEvent: false});
        }
    }
    


    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 2});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/personalAccident/medicalBills"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfFollowUpTreamentForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfFollowUpTreamentForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 13, subMenuIndex: 2});
        }
    }

    back() {
        this.router.navigate(["/claimform/personalAccident/detailOfOversea"], {
            relativeTo: this.activatedRoute
        });
    }

    //Google Analytics
    pushGAViewAccidentFollowUp() {
        (<any>window).dataLayer.push({
            'pageStep': 'Serious body injury & Death – Follow-up Treatment',
            'vPath': '/claim/serious-injury-death/followup',
            'event': 'vpageview',
        });
    }
}
