export class PolicyRisks {

    private riskNumber: number;
    private riskType: string;
    private premiumClasses : string[] = [];
    private clauses : string[] = [];

    getRiskNumber(): number {
        return this.riskNumber;
    }

    setRiskNumber(value: number) {
        this.riskNumber = value;
    }

    getRiskType(): string {
        return this.riskType;
    }

    setRiskType(value: string) {
        this.riskType = value;
    }

    getPremiumClasses(): string[] {
        return this.premiumClasses;
    }

    setPremiumClasses(value: string[]) {
        this.premiumClasses = value;
    }

    getClauses(): string[] {
        return this.clauses;
    }

    setClauses(value: string[]) {
        this.clauses = value;
    }

    static jsonConvert(policyRisks: PolicyRisks[]): PolicyRisks[] {
        policyRisks = policyRisks.map(x => Object.assign(new PolicyRisks(), x));
        return policyRisks;
    }

}