import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import { DeathAndPermanentDisability } from "src/app/model/domestic-helper/death-and-permanent-disability.model";


@Component({
  selector: 'app-death-and-permanent-disability-detail',
  templateUrl: './death-and-permanent-disability-detail.component.html',
  styleUrls: ['./death-and-permanent-disability-detail.component.css']
})

export class DeathAndPermanentDisabilityDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    deathAndPermanentDisabilityForm: FormGroup;
    claim: Claim;
    deathAndPermanentDisability: DeathAndPermanentDisability;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.deathAndPermanentDisability = this.claim.getClaimDomesticHelper().getDeathAndPermanentDisability();

        if (this.deathAndPermanentDisability.getCountryOccurred() == null) {
            this.deathAndPermanentDisability.setCountryOccurred('HKG');
        }

    }

    ngOnInit() {

        this.pushGAViewDeathAndPermanentDisabilityDeatil();

        if(this.deathAndPermanentDisability.getTotalAmountCurrency() == null || this.deathAndPermanentDisability.getTotalAmountCurrency() == ""){
            this.deathAndPermanentDisability.setTotalAmountCurrency("HKD");
        }

        if (!this.deathAndPermanentDisability.getDateOfIncident()) {
            this.deathAndPermanentDisability.setDateOfIncident(this.claim.getDateOfLoss());
        }

        this.deathAndPermanentDisabilityForm = this.fb.group({
            countryOccured: [this.deathAndPermanentDisability.getCountryOccurred()],
            dateOfIncident: [this.deathAndPermanentDisability.getDateOfIncident()],
            descriptionOfIncident: [this.deathAndPermanentDisability.getDescriptionOfIncident()],
            totalAmountCurrency: [this.deathAndPermanentDisability.getTotalAmountCurrency()],
            totalAmountValue: [this.deathAndPermanentDisability.getTotalAmountValue()]
        });
    }

    ngAfterViewInit() {
        this.deathAndPermanentDisabilityForm.valueChanges.subscribe(data => {
            this.deathAndPermanentDisability.setCountryOccurred(super.getValueInComponent('countryOccured'));
            this.deathAndPermanentDisability.setCountryOccurredDesc(this.getTextInComponent('countryOccured'));
            this.deathAndPermanentDisability.setDateOfIncident(super.getValueInComponent('dateOfIncident'));
            this.deathAndPermanentDisability.setDescriptionOfIncident(super.getValueInComponent('descriptionOfIncident'));
            this.deathAndPermanentDisability.setTotalAmountCurrency(this.deathAndPermanentDisabilityForm.get('totalAmountCurrency').value);
            this.deathAndPermanentDisability.setTotalAmountValue(super.getValueInComponent('totalAmountValue'));
        });

    }



    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 0});
            this.router.navigate(["/claimform/deathAndPermanentDisability/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.deathAndPermanentDisabilityForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewDeathAndPermanentDisabilityDeatil() {
        (<any>window).dataLayer.push({
            'pageStep': 'Permanent Disability or Death – Incident Details',
            'vPath': '/claim/permanent-disability-death/incident-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
              }
            }                                                              
        });
    }


}
