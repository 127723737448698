export class TripCurtailment {
    private dateOriginBook: Date;
    private countryCurtailmentOccurred: string;
    private countryCurtailmentOccurredDesc: string;
    private dateActualDeparture: Date;
    private reasonForCancel: string;
    private reasonForCancelDesc: string;
    private otherReason: string;
    private totalCostTypeOfTrip: string;
    private totalCost: string;
    private hasRefund: string;
    private hasRefundDesc: string;
    private totalAmountRefundedType: string;
    private totalAmountRefunded: string;
    private didUseMiles: boolean;
    private hasTransExtraCost: boolean;
    private extraTransCostVal: string;
    private extraTransCostCurrency: string;
    private hasAccomExtraCost: boolean;
    private extraAccomCostVal: string;
    private extraAccomCostCurrency: string;
    private estimatedAmount: number;
    private wasLossDueToRobbery: boolean;
    private didYouReport: boolean;
    private authorityType: string; 
    private authorityTypeDesc: string;
    private otherAuthorityType: string;
    private reportDate: Date;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getDateOriginBook(): Date{
        return this.dateOriginBook
    }

    setDateOriginBook(value: Date){
         this.dateOriginBook = value;
    }

    getDateActualDeparture(): Date{
        return this.dateActualDeparture
    }

    setDateActualDeparture(value: Date){
         this.dateActualDeparture = value;
    }

    getCountryCurtailmentOccurred(): string{
        return this.countryCurtailmentOccurred;
    }

    setCountryCurtailmentOccurred(value: string){
         this.countryCurtailmentOccurred = value;
    }

    getCountryCurtailmentOccurredDesc(): string{
        return this.countryCurtailmentOccurredDesc;
    }

    setCountryCurtailmentOccurredDesc(value: string){
        this.countryCurtailmentOccurredDesc = value;
    }

    getReasonForCancel(): string{
        return this.reasonForCancel;
    }

    setReasonForCancel(value: string){
         this.reasonForCancel = value;
    }

    getReasonForCancelDesc(): string{
        return this.reasonForCancelDesc;
    }

    setReasonForCancelDesc(value: string){
         this.reasonForCancelDesc = value;
    }

    getOtherReason(): string{
        return this.otherReason;
    }

    setOtherReason(value: string){
         this.otherReason = value;
    }

    getTotalCostTypeOfTrip(): string{
        return this.totalCostTypeOfTrip;
    }

    setTotalCostTypeOfTrip(value: string){
         this.totalCostTypeOfTrip = value;
    }

    getTotalCost(): string{
        return this.totalCost;
    }

    setTotalCost(value: string){
         this.totalCost = value;
    }

    getHasRefund(): string{
        return this.hasRefund;
    }

    setHasRefund(value: string){
         this.hasRefund = value;
    }

    getHasRefundDesc(): string{
        return this.hasRefundDesc;
    }

    setHasRefundDesc(value: string){
         this.hasRefundDesc = value;
    }

    getTotalAmountRefundedType(): string{
        return this.totalAmountRefundedType;
    }

    setTotalAmountRefundedType(value: string){
         this.totalAmountRefundedType = value;
    }

    getTotalAmountRefunded(): string{
        return this.totalAmountRefunded;
    }

    setTotalAmountRefunded(value: string){
         this.totalAmountRefunded = value;
    }

    getDidUseMiles(): boolean{
        return this.didUseMiles;
    }

    setDidUseMiles(value: boolean){
         this.didUseMiles = value;
    }

    getHasTransExtraCost(): boolean{
        return this.hasTransExtraCost;
    }

    setHasTransExtraCost(value: boolean){
         this.hasTransExtraCost = value;
    }

    getExtraTransCostVal(): string{
        return this.extraTransCostVal;
    }

    setExtraTransCostVal(value: string){
         this.extraTransCostVal = value;
    }

    getExtraTransCostCurrency(): string{
        return this.extraTransCostCurrency;
    }

    setExtraTransCostCurrency(value: string){
         this.extraTransCostCurrency = value;
    }

    getHasAccomExtraCost(): boolean{
        return this.hasAccomExtraCost;
    }

    setHasAccomExtraCost(value: boolean){
         this.hasAccomExtraCost = value;
    }

    getExtraAccomCostVal(): string{
        return this.extraAccomCostVal;
    }

    setExtraAccomCostVal(value: string){
         this.extraAccomCostVal = value;
    }

    getExtraAccomCostCurrency(): string{
        return this.extraAccomCostCurrency;
    }

    setExtraAccomCostCurrency(value: string){
         this.extraAccomCostCurrency = value;
    }

    getWasLossDueToRobbery(): boolean{
        return this.wasLossDueToRobbery;
    }

    setWasLossDueToRobbery(value: boolean){
         this.wasLossDueToRobbery = value;
    }

    getDidYouReport(): boolean{
        return this.didYouReport;
    }

    setDidYouReport(value: boolean){
         this.didYouReport = value;
    }

    getAuthorityType(): string {
        return this.authorityType;
    } 

    setAuthorityType(value: string){
        this.authorityType = value;
    }

    getAuthorityTypeDesc(): string {
        return this.authorityTypeDesc;
    } 

    setAuthorityTypeDesc(value: string){
        this.authorityTypeDesc = value;
    }

    getOtherAuthorityType(): string {
        return this.otherAuthorityType;
    } 

    setOtherAuthorityType(value: string){
        this.otherAuthorityType = value;
    }

    getReportDate(): Date{
        return this.reportDate;
    }

    setReportDate(value: Date){
         this.reportDate = value;
    }

    static jsonConvert(tripCurtailment: TripCurtailment): TripCurtailment {

        if (tripCurtailment.getDateActualDeparture() != null) {
            tripCurtailment.setDateActualDeparture(new Date(tripCurtailment.getDateActualDeparture()));
        }

        if (tripCurtailment.getDateOriginBook() != null) {
            tripCurtailment.setDateOriginBook(new Date(tripCurtailment.getDateOriginBook()));
        }

        if (tripCurtailment.getReportDate() != null) {
            tripCurtailment.setReportDate(new Date(tripCurtailment.getReportDate()));
        }

        return tripCurtailment;
    }


}
