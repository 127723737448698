export class InjuryDetails {

    private hasBeenInjured: boolean;
    private injuredPartsList: string[] = [];
    private injuredPartsDescList: string[] = [];
    private natureInjury: string;
    private natureInjuryDesc: string;
    private natureInjuryOthers: string;
    private isInternalInjury: boolean;
    private hasEncounteredSimilarIncident: boolean;
    private placeOfIncident: string;

    public getHasBeenInjured(): boolean {
        return this.hasBeenInjured;
    }

    public setHasBeenInjured(value: boolean){
        this.hasBeenInjured = value;
    }

    public getInjuredPartsList(): string[] {
        return this.injuredPartsList;
    }

    public setInjuredPartsList(value: string[]) {
        this.injuredPartsList = value;
    }

    public getInjuredPartsDescList(): string[] {
        return this.injuredPartsDescList;
    }

    public setInjuredPartsDescList(value: string[]) {
        this.injuredPartsDescList = value;
    }

    public getNatureInjury(): string {
        return this.natureInjury;
    }

    public setNatureInjury(natureInjury: string): void {
        this.natureInjury = natureInjury;
    }

    public getNatureInjuryDesc(): string {
        return this.natureInjuryDesc;
    }

    public setNatureInjuryDesc(natureInjuryDesc: string): void {
        this.natureInjuryDesc = natureInjuryDesc;
    }

    public getNatureInjuryOthers(): string {
        return this.natureInjuryOthers;
    }

    public setNatureInjuryOthers(natureInjuryOthers: string): void {
        this.natureInjuryOthers = natureInjuryOthers;
    }

    public getIsInternalInjury(): boolean {
        return this.isInternalInjury;
    }

    public setIsInternalInjury(value: boolean){
        this.isInternalInjury = value;
    }

    public getHasEncounteredSimilarIncident(): boolean {
        return this.hasEncounteredSimilarIncident;
    }

    public setHasEncounteredSimilarIncident(value: boolean){
        this.hasEncounteredSimilarIncident = value;
    }

    public getPlaceOfIncident(): string {
        return this.placeOfIncident;
    }

    public setPlaceOfIncident(placeOfIncident: string): void {
        this.placeOfIncident = placeOfIncident;
    }
    
    static jsonConvert(injuryDetails: InjuryDetails): InjuryDetails {

        injuryDetails = Object.assign(new InjuryDetails(), injuryDetails);
        
        return injuryDetails;
    }
}