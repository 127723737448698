<div>
    <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
      <h5>{{ 'homeProduct.common.tellUsWhatHappened' | translate }}</h5>
      <p>{{ 'homeProduct.common.detailsOfTheIncident' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
      <div class="col-lg-9 col-md-12">
  
        <form>
          <div>
            <div class="form-group" *ngIf="this.isBurglary">
              <qnect-ui #HOME_CONTENTSBUILDING_INCIDENTDESCRIPTION [fieldId]="'HOME_CONTENTSBUILDING_INCIDENTDESCRIPTION'" [formGroup]="contentsBuildingForm" name="damageDescription"></qnect-ui>
            </div>  
            <div class="form-group">
              <qnect-ui #HOME_CONTENTSBUILDING_ISTHEPROPERTYSAFETOSTAYIN [fieldId]="'HOME_CONTENTSBUILDING_ISTHEPROPERTYSAFETOSTAYIN'" [formGroup]="contentsBuildingForm" name="isThePropertySafeToStayIn"></qnect-ui>
            </div>
            <ng-container *ngIf="this.homeDetails.getIsThePropertySafeToStayIn() == false">
              <div class="form-group">
                <label [innerHTML]="'homeProduct.common.pleaseContact' | translate"></label>
              </div>
              <!-- <div class="form-group">
                <label [innerHTML]="'homeProduct.common.or' | translate"></label>
              </div>
              <div class="form-group">
                <qnect-ui #HOME_APPOINTBUILDER [fieldId]="'HOME_APPOINTBUILDER'" [formGroup]="contentsBuildingForm" name="isAppointBuilder"></qnect-ui>
              </div>   -->
              <div class="form-group">
                <label [innerHTML]="'homeProduct.common.or' | translate"></label>
              </div>
              <div class="form-group">
                <label [innerHTML]="'homeProduct.common.emergencyHotline' | translate"></label>
              </div>
            </ng-container>
            <ng-container *ngIf="this.claim.getCountry().toLowerCase() == 'hgi'">
              <div class="form-group" *ngIf="this.claimHome.getIsContentPremClassExist()">
                <qnect-ui #HOME_CONTENTSBUILDING_ISTHEREINTERIORDAMAGE [fieldId]="'HOME_CONTENTSBUILDING_ISTHEREINTERIORDAMAGE'" [formGroup]="contentsBuildingForm" name="isThereInteriorDamage"></qnect-ui>
              </div>
              <div class="form-group" *ngIf="this.claimHome.getHasFixFitFlag()">
                <qnect-ui #HOME_CONTENTSBUILDING_ISTHEREFIXTURESDAMAGE [fieldId]="'HOME_CONTENTSBUILDING_ISTHEREFIXTURESDAMAGE'" [formGroup]="contentsBuildingForm" name="isThereFixturesDamage"></qnect-ui>
              </div>
            </ng-container>
            <ng-container *ngIf="this.claimHome.getIsBuildingPremClassExist()">
              <div class="form-group">
                <qnect-ui #HOME_CONTENTSBUILDING_ISTHEREWINDOWDAMAGE [fieldId]="'HOME_CONTENTSBUILDING_ISTHEREWINDOWDAMAGE'" [formGroup]="contentsBuildingForm" name="isThereWindowDamage"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #HOME_CONTENTSBUILDING_ISTHERESERIOUSDAMAGE [fieldId]="'HOME_CONTENTSBUILDING_ISTHERESERIOUSDAMAGE'" [formGroup]="contentsBuildingForm" name="isThereSeriousDamage"></qnect-ui>
              </div>
            </ng-container>
            <ng-container *ngIf="claim.getClaimHome().getHomeDetails().getIsThereSeriousDamage() || claim.getClaimHome().getHomeDetails().getIsThereInteriorDamage() || claim.getClaimHome().getHomeDetails().getIsThereFixturesDamage()">
                <div class="form-group" *ngIf="!this.isBurglary">
                  <qnect-ui #HOME_CONTENTSBUILDING_DAMAGEDESCRIPTION [fieldId]="'HOME_CONTENTSBUILDING_DAMAGEDESCRIPTION'" [formGroup]="contentsBuildingForm" name="damageDescription"></qnect-ui>
                </div>
                <div class="form-group">
                  <qnect-ui #HOME_ASSISTBUILDER [fieldId]="'HOME_ASSISTBUILDER'" [formGroup]="contentsBuildingForm" name="isAssistBuilder"></qnect-ui>
                </div>
              <div  *ngIf="claim.getClaimHome().getHomeDetails().getIsAssistBuilder()" class="form-group">
                <div class="dept-del-info col-xl-8 col-xs-12 col-md-8">
                  <div class="row col-12 col-md-12">
                    <div class="col-3 col-md-2" style="padding: 5px;">
                      <img src="assets/images/builder.png" id="ray-travel-delay">
                  </div>
                    <div class=" col-9 col-md-10 dept-desc-text">{{ 'homeProduct.contentsBuilding.adjusterwillContact' | translate}}</div>
                  </div>
                </div>
              </div>
                <br/>
            </ng-container>
            <div class="form-group" *ngIf="this.claimHome.getIsContentPremClassExist()">
              <ng-container *ngIf="!isBurglary">
                <qnect-ui #HOME_CONTENTSBUILDING_ISANYITEMDAMAGED [fieldId]="'HOME_CONTENTSBUILDING_ISANYITEMDAMAGED'" [formGroup]="contentsBuildingForm" name="isAnyItemDamaged"></qnect-ui>
              </ng-container>
              <ng-container *ngIf="isBurglary">
                <qnect-ui #HOME_CONTENTSBUILDING_ISANYITEMDAMAGEDBURGLARY [fieldId]="'HOME_CONTENTSBUILDING_ISANYITEMDAMAGEDBURGLARY'" [formGroup]="contentsBuildingForm" name="isAnyItemDamaged"></qnect-ui>
              </ng-container>
            </div>
          </div>
        </form>
  
      </div>
      <div class="col-lg-3 col-xl-3 col-md-12">
        &nbsp;
      </div>
    </div>
    <div class="submit-bar">
      <abandon-button></abandon-button>
      <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
      <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
  </div>
  