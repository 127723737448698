import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {UiComponent} from '../../ui.component';
import {FormControl} from '@angular/forms';

@Directive({selector: '[maxValidator]'})
export class MaxValidatorDirective implements OnInit, AfterViewInit {

    @Input('maxValidator') textRef: string;
    textComponent: UiComponent;

    constructor(private host: UiComponent) {
        this.textComponent = host;
    }

    ngOnInit() {
        this.initializeEmailValidator();
    }

    ngAfterViewInit() {
        this.initializeEmailValidator();
    }

    initializeEmailValidator() {
        const textComponentType = this.textComponent.fieldConfig['type'];
        if (textComponentType === 'textfield') {
            this.textComponent.setupCustomValidatorList([this.validate()]);
            if (this.textRef) {
                this.textComponent.update();
            }
        }
    }

    validate = () => {
        return (c: FormControl) => {
            if (this.textRef && this.textComponent.getValue()) {
                let value = parseFloat(this.textComponent.getValue());
                let maxValue =  parseFloat(this.textRef);
                if (isNaN(value) || value < 0 || value > maxValue) {
                    let keyValues = 'maxValidator' + this.textRef;
                    const result = {};
                    result[keyValues] = true;
                    return result;
                }
            }
            return null;
        }
    }
}