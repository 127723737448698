import { FlightStatus } from "../flight-status.model";

export class TravelDelay{

    private originalFlightDate: Date;
    private originalFlightTime: Date;
    private delayedFlightDate: Date;
    private delayedFlightTime: Date;
    private delayedArrivalOriginalFlightDate: Date;
    private delayedArrivalDelayedFlightDate: Date;
    private delayedDepartureCountry: string;
    private delayedDepartureCountryDesc: string;
    private delayedArrivalCountry: string;
    private delayedArrivalCountryDesc: string;
    private flightDelayedQuestion: string;
    private delayReason: string;
    private delayReasonDesc: string;
    private delayReasonOthers: string;
    private isTravelDelayed: boolean;
    private hasExtAccomCost: boolean = null;
    private extraAccommodationCost: string;
    private extraAccommodationCurrency: string;
    private hasExtTransCost: boolean = null;
    private extraTransportationCost: string;
    private extraTransportationCurrency: string;
    private obtainReport: boolean;
    private delayedDepartureOrArrival: string = "delayedDeparture";
    private estimatedAmount: number;

    //FlightStats API
    private flightNumber: string;
    private flightNumberDate: Date;
    private flightNumberFoundFlag: boolean;
    private flightNumberSearchedFlag: boolean;
    private manualInputFlag: boolean;
    private flightStatus: FlightStatus = new FlightStatus();
    private isMultiLegFlight: boolean;
    private multiLegFlightSelectedIndex: number;
    private hasSelectedLeg: boolean;

    private isOriginalFlightCancelled: boolean;          // flag if original flight coming from api is cancelled
    private hasValidFlightDelay: boolean;                // flag if flight delay has valid delay hours for country

    private altFlightNumber: string;
    private altFlightNumberDate: Date;
    private isAltFlightInputFlag: boolean;               // if input will be from alternative flight
    private isAltManualInputFlag: boolean;               // if alternative flight is manually entered in
    private altOriginalFlightDate: Date;                 // alternative departure
    private altDelayedFlightDate: Date;                  // alternative delayed departure
    private altDelayedArrivalOriginalFlightDate: Date;   // alternative arrival
    private altDelayedArrivalDelayedFlightDate: Date;    //alternative delayed arrival

    private altDelayedDepartureCountry: string;
    private altDelayedDepartureCountryDesc: string;
    private altDelayedArrivalCountry: string;
    private altDelayedArrivalCountryDesc: string;
    private altDelayedDepartureOrArrival: string = "delayedDeparture";

    getAltDelayedArrivalDelayedFlightDate(): Date {
        return this.altDelayedArrivalDelayedFlightDate;
    }

    setAltDelayedArrivalDelayedFlightDate(value: Date) {
        this.altDelayedArrivalDelayedFlightDate = value;
    }

    getAltOriginalFlightDate(): Date {
        return this.altOriginalFlightDate;
    }

    setAltOriginalFlightDate(value: Date) {
        this.altOriginalFlightDate = value;
    }

    getAltDelayedFlightDate(): Date {
        return this.altDelayedFlightDate;
    }

    setAltDelayedFlightDate(value: Date) {
        this.altDelayedFlightDate = value;
    }

    getAltDelayedArrivalOriginalFlightDate(): Date {
        return this.altDelayedArrivalOriginalFlightDate;
    }

    setAltDelayedArrivalOriginalFlightDate(value: Date) {
        this.altDelayedArrivalOriginalFlightDate = value;
    }

    getAltDelayedDepartureCountry(): string {
        return this.altDelayedDepartureCountry;
    }

    setAltDelayedDepartureCountry(value: string) {
        this.altDelayedDepartureCountry = value;
    }

    getAltDelayedDepartureCountryDesc(): string {
        return this.altDelayedDepartureCountryDesc;
    }

    setAltDelayedDepartureCountryDesc(value: string) {
        this.altDelayedDepartureCountryDesc = value;
    }

    getAltDelayedArrivalCountry(): string {
        return this.altDelayedArrivalCountry;
    }

    setAltDelayedArrivalCountry(value: string) {
        this.altDelayedArrivalCountry = value;
    }

    getAltDelayedArrivalCountryDesc(): string {
        return this.altDelayedArrivalCountryDesc;
    }

    setAltDelayedArrivalCountryDesc(value: string) {
        this.altDelayedArrivalCountryDesc = value;
    }

    getAltDelayedDepartureOrArrival(): string {
        return this.altDelayedDepartureOrArrival;
    }

    setAltDelayedDepartureOrArrival(value: string) {
        this.altDelayedDepartureOrArrival = value;
    }

    setHasSelectedLeg(value: boolean) {
        this.hasSelectedLeg = value;
    }

    getHasSelectedLeg(): boolean {
        return this.hasSelectedLeg;
    }

    setMultiLegFlightSelectedIndex(value: number) {
        this.multiLegFlightSelectedIndex = value;
    }

    getMultiLegFlightSelectedIndex(): number {
        return this.multiLegFlightSelectedIndex;
    }

    setIsMultiLegFlight(value: boolean) {
        this.isMultiLegFlight = value;
    }

    getIsMultiLegFlight(): boolean {
        return this.isMultiLegFlight;
    }

    setFlightStatus(value: FlightStatus) {
        this.flightStatus = value;
    }

    getFlightStatus(): FlightStatus {
        return this.flightStatus;
    }

    setFlightNumberSearchedFlag(value: boolean) {
        this.flightNumberSearchedFlag = value;
    }

    getFlightNumberSearchedFlag(): boolean {
        return this.flightNumberSearchedFlag;
    }

    setFlightNumberFoundFlag(value: boolean) {
        this.flightNumberFoundFlag = value;
    }

    getFlightNumberFoundFlag(): boolean {
        return this.flightNumberFoundFlag;
    }

    setManualInputFlag(value: boolean) {
        this.manualInputFlag = value;
    }

    getManualInputFlag(): boolean {
        return this.manualInputFlag;
    }

    getFlightNumber(): string {
        return this.flightNumber;
    }

    setFlightNumber(value: string) {
        this.flightNumber = value;
    }

    getFlightNumberDate(): Date {
        return this.flightNumberDate;
    }

    setFlightNumberDate(value: Date) {
        this.flightNumberDate = value;
    }

    getDelayedDepartureOrArrival(): string {
        return this.delayedDepartureOrArrival;
    }

    setDelayedDepartureOrArrival(value: string) {
        this.delayedDepartureOrArrival = value;
    }

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getHasExtTransCost(): boolean {
        return this.hasExtTransCost;
    }

    setHasExtTransCost(value: boolean) {
        this.hasExtTransCost = value;
    }

    getHasExtAccomCost(): boolean {
        return this.hasExtAccomCost;
    }

    setHasExtAccomCost(value: boolean) {
        this.hasExtAccomCost = value;
    }

    setObtainReport(value: boolean){
        this.obtainReport = value;
    }

    getObtainReport(): boolean {
        return this.obtainReport;
    }

    getExtraAccommodationCost(): string {
        return this.extraAccommodationCost;
    }

    setExtraAccommodationCost(value: string) {
        this.extraAccommodationCost = value;
    }

    getExtraTransportationCost(): string {
        return this.extraTransportationCost;
    }

    setExtraTransportationCost(value: string) {
        this.extraTransportationCost = value;
    }

    getExtraAccommodationCurrency(): string {
        return this.extraAccommodationCurrency;
    }

    setExtraAccommodationCurrency(value: string) {
        this.extraAccommodationCurrency = value;
    }

    getExtraTransportationCurrency(): string {
        return this.extraTransportationCurrency;
    }

    setExtraTransportationCurrency(value: string) {
        this.extraTransportationCurrency = value;
    }

    getFlightDelayedQuestion(): string {
        return this.flightDelayedQuestion;
    }

    setFlightDelayedQuestion(value: string) {
        this.flightDelayedQuestion = value;
    }

    getOriginalFlightDate(): Date {
        return this.originalFlightDate;
    }

    setOriginalFlightDate(value: Date) {
        this.originalFlightDate = value;
    }

    getOriginalFlightTime(): Date {
        return this.originalFlightTime;
    }

    setOriginalFlightTime(value: Date) {
        this.originalFlightTime = value;
    }

    getDelayedFlightDate(): Date {
        return this.delayedFlightDate;
    }

    setDelayedFlightDate(value: Date) {
        this.delayedFlightDate = value;
    }

    getDelayedFlightTime(): Date {
        return this.delayedFlightTime;
    }

    setDelayedFlightTime(value: Date) {
        this.delayedFlightTime = value;
    }

    getDelayReason(): string {
        return this.delayReason;
    }

    setDelayReason(value: string) {
        this.delayReason = value;
    }

    getDelayReasonDesc(): string {
        return this.delayReasonDesc;
    }

    setDelayReasonDesc(value: string) {
        this.delayReasonDesc = value;
    }

    getDelayReasonOthers(): string {
        return this.delayReasonOthers;
    }

    setDelayReasonOthers(value: string) {
        this.delayReasonOthers = value;
    }

    getIsTravelDelayed(): boolean {
        return this.isTravelDelayed;
    }

    setIsTravelDelayed(value: boolean) {
        this.isTravelDelayed = value;
    }

    getDelayedDepartureCountry(): string {
        return this.delayedDepartureCountry;
    }

    setDelayedDepartureCountry(value: string) {
        this.delayedDepartureCountry = value;
    }

    getDelayedDepartureCountryDesc(): string {
        return this.delayedDepartureCountryDesc;
    }

    setDelayedDepartureCountryDesc(value: string) {
        this.delayedDepartureCountryDesc = value;
    }

    getDelayedArrivalCountry(): string {
        return this.delayedArrivalCountry;
    }

    setDelayedArrivalCountryDesc(value: string) {
        this.delayedArrivalCountryDesc = value;
    }

    getDelayedArrivalCountryDesc(): string {
        return this.delayedArrivalCountryDesc;
    }

    setDelayedArrivalCountry(value: string) {
        this.delayedArrivalCountry = value;
    }

    getDelayedArrivalDelayedFlightDate(): Date {
        return this.delayedArrivalDelayedFlightDate;
    }

    setDelayedArrivalDelayedFlightDate(value: Date) {
        this.delayedArrivalDelayedFlightDate = value;
    }


    getDelayedArrivalOriginalFlightDate(): Date {
        return this.delayedArrivalOriginalFlightDate;
    }

    setDelayedArrivalOriginalFlightDate(value: Date) {
        this.delayedArrivalOriginalFlightDate = value;
    }

    //additional
    setIsAltFlightInputFlag(value: boolean) {
        this.isAltFlightInputFlag = value;
    }

    getIsAltFlightInputFlag(): boolean {
        return this.isAltFlightInputFlag;
    }

    getAltFlightNumber(): string {
        return this.altFlightNumber;
    }

    setAltFlightNumber(value: string) {
        this.altFlightNumber = value;
    }

    getAltFlightNumberDate(): Date {
        return this.altFlightNumberDate;
    }

    setAltFlightNumberDate(value: Date) {
        this.altFlightNumberDate = value;
    }

    setIsOriginalFlightCancelled(value: boolean) {
        this.isOriginalFlightCancelled = value;
    }

    getIsOriginalFlightCancelled(): boolean {
        return this.isOriginalFlightCancelled;
    }

    setIsAltManualInputFlag(value: boolean) {
        this.isAltManualInputFlag = value;
    }

    getIsAltManualInputFlag(): boolean {
        return this.isAltManualInputFlag;
    }

    setHasValidFlightDelay(value: boolean) {
        this.hasValidFlightDelay = value;
    }

    getHasValidFlightDelay(): boolean {
        return this.hasValidFlightDelay;
    }

    static jsonConvert(travelDelay: TravelDelay): TravelDelay {

        // flight delay
        if (travelDelay.getOriginalFlightDate() != null) {
            travelDelay.setOriginalFlightDate(new Date(travelDelay.getOriginalFlightDate()));
        }
        if (travelDelay.getOriginalFlightTime() != null) {
            travelDelay.setOriginalFlightTime(new Date(travelDelay.getOriginalFlightTime()));
        }
        if (travelDelay.getDelayedFlightDate() != null) {
            travelDelay.setDelayedFlightDate(new Date(travelDelay.getDelayedFlightDate()));
        }
        if (travelDelay.getDelayedFlightTime() != null) {
            travelDelay.setDelayedFlightTime(new Date(travelDelay.getDelayedFlightTime()));
        }
        if (travelDelay.getDelayedArrivalOriginalFlightDate() != null) {
            travelDelay.setDelayedArrivalOriginalFlightDate(new Date(travelDelay.getDelayedArrivalOriginalFlightDate()));
        }
        if (travelDelay.getDelayedArrivalDelayedFlightDate() != null) {
            travelDelay.setDelayedArrivalDelayedFlightDate(new Date(travelDelay.getDelayedArrivalDelayedFlightDate()));
        }
        if (travelDelay.getFlightNumberDate() != null) {
            travelDelay.setFlightNumberDate(new Date(travelDelay.getFlightNumberDate()));
        }
        if (travelDelay.getFlightStatus() != null) {
            travelDelay.setFlightStatus(new FlightStatus());
        }

        if (travelDelay.getAltFlightNumberDate() != null) {
            travelDelay.setAltFlightNumberDate(new Date(travelDelay.getAltFlightNumberDate()));
        }

        if (travelDelay.getAltOriginalFlightDate() != null) {
            travelDelay.setAltOriginalFlightDate(new Date(travelDelay.getAltOriginalFlightDate()));
        }

        if (travelDelay.getAltDelayedFlightDate() != null) {
            travelDelay.setAltDelayedFlightDate(new Date(travelDelay.getAltDelayedFlightDate()));
        }

        if (travelDelay.getAltDelayedArrivalOriginalFlightDate() != null) {
            travelDelay.setAltDelayedArrivalOriginalFlightDate(new Date(travelDelay.getAltDelayedArrivalOriginalFlightDate()));
        }

        if (travelDelay.getAltDelayedArrivalDelayedFlightDate() != null) {
            travelDelay.setAltDelayedArrivalDelayedFlightDate(new Date(travelDelay.getAltDelayedArrivalDelayedFlightDate()));
        }

        return travelDelay;
    }
}