import {Claim} from '../model/claim.model';
import {JsonProperty} from "../utilities/utils/json-property.decorator";
import {TransactionInfo} from "./transaction-info.model";
export class AutoSaveForm {

  private saveId: number;
  private staffId: string;
  private identifier: string;
  private policyNo: string;
  private product: string;
  private transaction: string;
  private type: string;
  private entryUrl: string;

  @JsonProperty({name: 'claim', clazz: Claim})
  private claim: Claim = new Claim();

  @JsonProperty({name: 'transactionInfo', clazz: TransactionInfo})
  private transactionInfo: TransactionInfo = new TransactionInfo();


  getSaveId(): number {
    return this.saveId;
  }

  setSaveId(value: number) {
    this.saveId = value;
  }

  getStaffId(): string {
    return this.staffId;
  }

  setStaffId(value: string) {
    this.staffId = value;
  }

  getIdentifier(): string {
    return this.identifier;
  }

  setIdentifier(value: string) {
    this.identifier = value;
  }

  getPolicyNo(): string {
    return this.policyNo;
  }

  setPolicyNo(value: string) {
    this.policyNo = value;
  }

  getProduct(): string {
    return this.product;
  }

  setProduct(value: string) {
    this.product = value;
  }

  getTransaction(): string {
    return this.transaction;
  }

  setTransaction(value: string) {
    this.transaction = value;
  }

  getType(): string {
    return this.type;
  }

  setType(value: string) {
    this.type = value;
  }

  getClaim(): Claim {
    return this.claim;
  }

  setClaim(value: Claim) {
    this.claim = value;
  }

  getTransactionInfo(): TransactionInfo {
    return this.transactionInfo;
  }

  setTransactionInfo(value: TransactionInfo) {
    this.transactionInfo = value;
  }

  getEntryUrl(): string {
    return this.entryUrl;
  }

  setEntryUrl(value: string){
    this.entryUrl = value;
  }
}
