import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'qnect-ui-unknown-type',
  template: `
    <p style="color: red"><strong>Unknown UI Type for {{fieldId}}</strong></p>
  `,
  styles: []
})
export class UiUnknownTypeComponent implements OnInit {

  @Input() fieldId: string;

  constructor() { }

  ngOnInit() {
  }

}
