import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-personal-accident',
  templateUrl: './personal-accident.component.html',
  styleUrls: ['./personal-accident.component.css']
})
export class PersonalAccidentComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]);
    }

  ngOnInit() {
  }

}
