import {Injectable} from "@angular/core";
import {Observable, of, catchError, tap} from "rxjs";
import {Logger} from "../utilities/service/logger/logger";
import {UICountryService} from "./ui-country.service";
import {UICurrencyService} from "./ui-currency.service";
import {UIBankService} from "./ui-bank.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class UiConfigService {

  private config;
  private configUrl: string;

  constructor(private http: HttpClient, private _logger: Logger) {
  }

  getUiConfig(): any {
    return this.config;
  }

  setUiConfig(value : any): any {
    this.config = value;
  }

  loadUiConfig(): Observable<any> {
    if (this.config) {
      return of(this.config);
    } else {
      return this.getConfigJson(this.configUrl)
          .pipe(tap(response => {
        this.config = response;
        this.mapStaticListReference(this.config);
        return this.config;
      }));
    }
  }

  getConfigUrl(): string {
    return this.configUrl;
  }

  setConfigUrl(value: string) {
    this.configUrl = value;
  }

  getConfigJson(jsonFile: string): Observable<any> {
    return this.http.get<any>(jsonFile)
      .pipe(catchError((error: any, caught: Observable<any>) => {
        this._logger.error(error);
        throw("error in getConfigJson");
      }));
  }

  mapStaticListReference(configObject) {

    for (let entry in configObject) {

      if (configObject.hasOwnProperty(entry)) {

        if (configObject[entry].staticOptionName) {

          if ("COUNTRY_LIST" == configObject[entry].staticOptionName) {
            let countryService: UICountryService = new UICountryService();
            configObject[entry].options = countryService.getCountryOptions();
          }
          else if ("CURRENCY_LIST" == configObject[entry].staticOptionName) {
            let currencyService: UICurrencyService = new UICurrencyService();
            configObject[entry].options = currencyService.getCurrencyOptions();
          }
          else if ("CURRENCY_LIST_HKG" == configObject[entry].staticOptionName) {
            let currencyService: UICurrencyService = new UICurrencyService();
            configObject[entry].options = currencyService.getCurrencyOptionsHKG();
          }
          else if ("BANK_LIST" == configObject[entry].staticOptionName) {
            let bankService: UIBankService = new UIBankService();
            configObject[entry].options = bankService.getBankOptions();
          } 
          else if ("SGP_BANK_LIST" == configObject[entry].staticOptionName) {
            let bankService: UIBankService = new UIBankService();
            configObject[entry].options = bankService.getBankOptionsSgp();
          }
          else if ("MYS_BANK_LIST" == configObject[entry].staticOptionName) {
              let bankService: UIBankService = new UIBankService();
              configObject[entry].options = bankService.getBankOptionsMys();
          }
          else if ("COUNTRY_CODE_LIST" == configObject[entry].staticOptionName) {
              let countryService: UICountryService = new UICountryService();
              configObject[entry].options = countryService.getCountryCodeOptions();
          }
        }

        if (configObject[entry].currencyStaticOptionName) {
          if ("CURRENCY_LIST" == configObject[entry].currencyStaticOptionName) {
            let currencyService: UICurrencyService = new UICurrencyService();
            configObject[entry].currencyOptions = currencyService.getCurrencyOptions();
          }
          else if ("CURRENCY_LIST_HKG" == configObject[entry].currencyStaticOptionName) {
            let currencyService: UICurrencyService = new UICurrencyService();
            configObject[entry].currencyOptions = currencyService.getCurrencyOptionsHKG();
            //console.log("Setting in static list" + configObject[entry].staticOptionName + " field " + configObject[entry].name);
          }
        }

        if (configObject[entry].countryCodeStaticOptionName) {
          if ("COUNTRY_CODE_LIST" == configObject[entry].countryCodeStaticOptionName) {
            let countryService: UICountryService = new UICountryService();
            configObject[entry].countryCodeOptions = countryService.getCountryCodeOptions();
          }
        }
      }
    }


  }
}
