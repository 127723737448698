import {Component, Injector, Input, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import { DocumentField } from "../../../../model/document-field";
import { ClaimTypes } from "../../../../model/claim-type.model";
import { DocumentHelperService } from "../../../../services/document-helper.service";
import { FlightStatus } from "src/app/model/flight-status.model";
import {UtilitiesService} from '../../../../utilities/utilities.service';
import {TravelDelay} from '../../../../model/travel/travel-delay.model';

@Component({
    selector: 'app-summary-travel-delay',
    templateUrl: './summary-travel-delay.component.html',
    styleUrls: ['./summary-travel-delay.component.css']
})
export class SummaryTravelDelayComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimTravel: ClaimTravel;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];
    flightStatus: FlightStatus;
    travelDelay: TravelDelay;
    @Input() claimItem: Claim;

    constructor(private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private injector : Injector) {

        super(injector);
    }

    ngOnInit() {

        if (!UtilitiesService.isNullOrUndefined(this.claimItem)) {
            this.claim = this.claimItem;
        } else {
            this.claim = this.claimService.getClaim();
        }

        this.claimTravel = this.claim.getClaimTravel();
        this.flightStatus = this.claim.getClaimTravel().getTravelDelay().getFlightStatus();
        let isClaimantMinor = UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth());
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocumentsClaimant(this.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL], isClaimantMinor);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]);
        this.travelDelay = this.claimTravel.getTravelDelay();

    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }
}
