import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { ClaimTypes } from "src/app/model/claim-type.model";
import { Claim } from "src/app/model/claim.model";
import { PoliceReport } from "src/app/model/motor/police-report.model";
import { ClaimService } from "src/app/services/claim.service";
import { SideMenuService } from "src/app/services/side-menu.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimsBaseComponent } from "../../claim-base.component";

@Component({
    selector: 'app-authority-report',
    templateUrl: './authority-report.component.html',
    styleUrls: ['./authority-report.component.css']
})
export class AuthorityReportComponent extends ClaimsBaseComponent implements OnInit {

    policeReportForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    policeReport: PoliceReport;
    isFire: boolean;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.policeReport = this.claim.getClaimHome().getHomeDetails().getPoliceReport();
        let currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
        if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE]].indexOf(currentClaimType) > -1)){
            this.isFire = true;
        }
    }

    ngOnInit() {
        this.pushGAReportLossView();

        this.policeReportForm = this.fb.group({
            //HOME
            hasReportedIncidentToPolice: [super.getBooleanString(this.policeReport.getHasReportedIncidentToPolice())],
            policeStationNameAndAddress: [this.policeReport.getPoliceStation()],
            referenceNumber: [this.policeReport.getPoliceReportNumber()],
            dateOfReport: [this.policeReport.getDateOfReport()],
        });
    }

    ngAfterViewInit() {

        this.policeReportForm.valueChanges.subscribe(data => {
            //HOME
            if (this.policeReportForm.get('hasReportedIncidentToPolice').value != null) {
                this.policeReport.setHasReportedIncidentToPolice(this.policeReportForm.get('hasReportedIncidentToPolice').value == 'true');
            }
            this.policeReport.setPoliceStation(super.getValueInComponent('policeStationNameAndAddress'))
            this.policeReport.setPoliceReportNumber(super.getValueInComponent('referenceNumber'))
            this.policeReport.setDateOfReport(super.getValueInComponent('dateOfReport'));
        });

        // Stop validation of fields when not required.
        let self = this;
      
        setTimeout(function () {self.hidePoliceReport(self.getBooleanString(self.policeReport.getHasReportedIncidentToPolice())),10});
        this.policeReportForm.get('hasReportedIncidentToPolice').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hidePoliceReport(data);
        });

    }
    
    hidePoliceReport(hasReportedIncidentToPolice) {
        if (hasReportedIncidentToPolice == 'true') {
            this.policeReportForm.get('policeStationNameAndAddress').enable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('referenceNumber').enable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('dateOfReport').enable({onlySelf: false, emitEvent: false});
        } else {
            this.policeReportForm.get('policeStationNameAndAddress').reset();
            this.policeReportForm.get('referenceNumber').reset();
            this.policeReportForm.get('dateOfReport').reset();
            this.policeReportForm.get('policeStationNameAndAddress').disable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('referenceNumber').disable({onlySelf: false, emitEvent: false});
            this.policeReportForm.get('dateOfReport').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    validateForm(): boolean {
        return super.validateForm(this.policeReportForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAReportLossView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' – Report the loss';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/report-the-loss';

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,            
            'event': 'vpageview'
        });
    }
}
