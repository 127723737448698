
export class HelpersLiability {

    private dateOfIncident: Date;
    private countryIncidentOccur: string;
    private countryIncidentOccurDesc: string;
    private natureOfIncidentCode: string;
    private natureOfIncident: string;
    private descOfIncident: string;
    private amountClaim: string;
    private amountClaimCurrency: string;
    private isIncidentReport: boolean;
    private dateOfReport: Date;
    private policeStationNameAndAddress: string;
    private referenceNumber: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getDateOfIncident(): Date {
        return this.dateOfIncident;
    }

    setDateOfIncident(value: Date) {
        this.dateOfIncident = value;
    }

    getCountryIncidentOccur(): string {
        return this.countryIncidentOccur;
    }

    setCountryIncidentOccur(value: string) {
        this.countryIncidentOccur = value;
    }

    getCountryIncidentOccurDesc(): string {
        return this.countryIncidentOccurDesc;
    }

    setCountryIncidentOccurDesc(value: string) {
        this.countryIncidentOccurDesc = value;
    }


    getNatureOfIncidentCode(): string {
        return this.natureOfIncidentCode;
    }

    setNatureOfIncidentCode(value: string) {
        this.natureOfIncidentCode = value;
    }

    getNatureOfIncident(): string {
        return this.natureOfIncident;
    }

    setNatureOfIncident(value: string) {
        this.natureOfIncident = value;
    }

    getDescOfIncident(): string {
        return this.descOfIncident;
    }

    setDescOfIncident(value: string) {
        this.descOfIncident = value;
    }

    getAmountClaim(): string {
        return this.amountClaim;
    }

    setAmountClaim(value: string) {
        this.amountClaim = value;
    }

    getAmountClaimCurrency(): string {
        return this.amountClaimCurrency;
    }

    setAmountClaimCurrency(value: string) {
        this.amountClaimCurrency = value;
    }

    getIsIncidentReport(): boolean {
        return this.isIncidentReport;
    }

    setIsIncidentReport(value: boolean) {
        this.isIncidentReport = value;
    }
    
    getDateOfReport(): Date {
        return this.dateOfReport;
    }

    setDateOfReport(value: Date) {
        this.dateOfReport = value;
    }

    getPoliceStationNameAndAddress(): string {
        return this.policeStationNameAndAddress;
    }

    setPoliceStationNameAndAddress(value: string) {
        this.policeStationNameAndAddress = value;
    }

    getReferenceNumber(): string {
        return this.referenceNumber;
    }

    setReferenceNumber(value: string) {
        this.referenceNumber = value;
    }  
    
    static jsonConvert(helpersLiability: HelpersLiability): HelpersLiability {

        //todo: convert date fields object if any
        if (helpersLiability.getDateOfIncident() != null) {
            helpersLiability.setDateOfIncident(new Date(helpersLiability.getDateOfIncident()));
        }

        if (helpersLiability.getDateOfReport() != null) {
            helpersLiability.setDateOfReport(new Date(helpersLiability.getDateOfReport()));
        }

        return helpersLiability;
    }
}