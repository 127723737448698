<ng-template #ec_form_selection_modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{'ecClaim.selectClaim.formSelectionModal.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'ecClaim.selectClaim.formSelectionModal.guideYou' | translate }}</p><br/>
    <div class="form-group radio-center">
      <qnect-ui #EC_ACCIDENT_OCCURRED_AT_WORK [fieldId]="'EC_ACCIDENT_OCCURRED_AT_WORK'" [formGroup]="ecModalForm" 
        name="occurredAtWork" [dynamicLabel]="'ecClaim.selectClaim.formSelectionModal.accidentOccur'" 
        [dynamicOptions]="occurredAtWorkOptions"></qnect-ui>
    </div>
    <ng-container *ngIf="this.ecModalForm.get('occurredAtWork').value === 'Yes'">
      <br/>
      <div class="form-group radio-center">
        <qnect-ui #EC_MODAL_HOW_MANY_SICK_LEAVE_DAYS [fieldId]="'EC_MODAL_HOW_MANY_SICK_LEAVE_DAYS'" [formGroup]="ecModalForm" name="howManySickLeaveDays"></qnect-ui>
      </div>
      <ng-container *ngIf="this.ecModalForm.get('howManySickLeaveDays').value === 'More than 3'">
        <br/>
        <div class="form-group radio-center">
          <qnect-ui #EC_MODAL_IS_EMPLOYEE_TAKING [fieldId]="'EC_MODAL_IS_EMPLOYEE_TAKING'" [formGroup]="ecModalForm" name="employeeTaking"></qnect-ui>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary new-btn" (click)="dismiss()">{{'claimSection.generalLabels.generalButtonLabels.cancel' | translate}}</button>
    <button type="button" class="btn btn-primary new-btn" (click)="onConfirmFormSelection()">{{'claimSection.generalLabels.generalButtonLabels.confirm' | translate}}</button>
  </div>
  <br/><br/>
</ng-template>
