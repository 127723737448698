import {Component, Injector, OnInit} from '@angular/core';
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { ClaimHome } from 'src/app/model/claim-home.model';
import {PersonalAccident} from '../../../../model/home/personal-accident.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';

@Component({
    selector: 'app-summary-personal-accident-details',
    templateUrl: './summary-personal-accident-details.component.html',
    styleUrls: ['./summary-personal-accident-details.component.css']
})

export class SummaryPersonalAccidentDetailsComponent extends ClaimsBaseComponent implements OnInit {

    claimHome: ClaimHome;
    personalAccident: PersonalAccident;
    isPaClaim = false;

    constructor(private injector : Injector,
        private transactionInfoService: TransactionInfoService) {

        super(injector);
        this.claimHome = this.claim.getClaimHome();
        this.personalAccident = this.claimHome.getHomeDetails().getPersonalAccident();
        let currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
        if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA]].indexOf(currentClaimType) > -1)){
            this.isPaClaim = true;
        }
    }
    
    ngOnInit() {

    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
        return '';
    }
}
