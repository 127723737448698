import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-dental-expenses',
  templateUrl: './dental-expenses.component.html',
  styleUrls: ['./dental-expenses.component.css']
})
export class DentalExpensesComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]);
    }

  ngOnInit() {
  }

}
