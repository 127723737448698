import {Component, Injector, OnInit} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DeathAndSeriousBodyInjuryLoss} from "../../../../model/travel/death-and-serious-body-injury-loss";
import {takeUntil} from "rxjs";

@Component({
    selector: 'app-detail-of-oversea-hospital',
    templateUrl: './detail-of-oversea-hospital.component.html',
    styleUrls: ['./detail-of-oversea-hospital.component.css']
})
export class DetailOfOverseaHospitalComponent extends ClaimsBaseComponent implements OnInit {

    deTailOfOverseaHospitalForm: FormGroup;
    wasStayHospitalOversea: boolean;
    showTotalBox = false;
    wasStayInIntensiveCare: boolean;
    deathAndSeriousBodyInjuryLoss: DeathAndSeriousBodyInjuryLoss;

    constructor(private router: Router, private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.deathAndSeriousBodyInjuryLoss = this.claim.getClaimTravel().getDeathAndSeriousBodyInjuryLoss();
    }

    ngOnInit() {

        this.pushGAViewAccidentOversea();

        this.deTailOfOverseaHospitalForm = this.fb.group(
            {
                wasStayHospitalOversea: [super.getBooleanString(this.deathAndSeriousBodyInjuryLoss.getWasStayHospitalOversea())],
                wasStayInIntensiveCare: [super.getBooleanString(this.deathAndSeriousBodyInjuryLoss.getWasStayInIntensiveCare())],
                wardType: [this.deathAndSeriousBodyInjuryLoss.getWardType()],
                periodType: [this.deathAndSeriousBodyInjuryLoss.getPeriodType()],
                periodTime: [this.deathAndSeriousBodyInjuryLoss.getPeriodTime()]
            }
        );

    }

    ngAfterViewInit() {
        this.deTailOfOverseaHospitalForm.valueChanges.subscribe(data => {
            if (this.deTailOfOverseaHospitalForm.get('wasStayHospitalOversea').value != null) {
                this.deathAndSeriousBodyInjuryLoss.setWasStayHospitalOversea(this.deTailOfOverseaHospitalForm.get('wasStayHospitalOversea').value == 'true');
            }

            //check for other required fields based on hospital oversea value
            if (this.deTailOfOverseaHospitalForm.get('wasStayHospitalOversea').value == 'true') {
                if (this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value !== null && this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value !== undefined ) {
                    if (this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value != null) {
                        this.deathAndSeriousBodyInjuryLoss.setWasStayInIntensiveCare(this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value == 'true' ? true : false);
                    }
                }
            } else {
                if (this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value != null) {
                    this.deathAndSeriousBodyInjuryLoss.setWasStayInIntensiveCare(this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value);
                }
            }


            this.deathAndSeriousBodyInjuryLoss.setWardType(super.getValueInComponent('wardType'));
            this.deathAndSeriousBodyInjuryLoss.setWardTypeDesc(super.getTextInComponent('wardType'));
            this.deathAndSeriousBodyInjuryLoss.setPeriodType(super.getValueInComponent('periodType'));
            this.deathAndSeriousBodyInjuryLoss.setPeriodTypeDesc(super.getTextInComponent('periodType'));
            this.deathAndSeriousBodyInjuryLoss.setPeriodTime(super.getTextInComponent('periodTime'));

            this.validateClaimDetailsComplete();

        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideShowOverseaFields(self.deathAndSeriousBodyInjuryLoss.getWasStayHospitalOversea()),10});
        this.deTailOfOverseaHospitalForm.get('wasStayHospitalOversea').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowOverseaFields(data);
        });

        setTimeout(function () {self.hideShowWardTypeField(self.deathAndSeriousBodyInjuryLoss.getWasStayInIntensiveCare()),10});
        this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowWardTypeField(data);
        });

    }

    hideShowOverseaFields(wasHospitalOversea){

        if (wasHospitalOversea === true || wasHospitalOversea === 'true') {
            this.deTailOfOverseaHospitalForm.get('periodType').enable({onlySelf: false, emitEvent: false});
            this.deTailOfOverseaHospitalForm.get('periodTime').enable({onlySelf: false, emitEvent: false});
            this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').enable({onlySelf: false, emitEvent: false});
        } else {
            this.deTailOfOverseaHospitalForm.get('periodType').reset();
            this.deTailOfOverseaHospitalForm.get('periodTime').reset();
            this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').reset();
            this.deTailOfOverseaHospitalForm.get('periodType').disable({onlySelf: false, emitEvent: false});
            this.deTailOfOverseaHospitalForm.get('periodTime').disable({onlySelf: false, emitEvent: false});
            this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowWardTypeField(showWardType){
        if (showWardType === 'false' || showWardType === false) {
            this.deTailOfOverseaHospitalForm.get('wardType').enable({onlySelf: false, emitEvent: false});
        } else {
            this.deTailOfOverseaHospitalForm.get('wardType').reset();
            this.deTailOfOverseaHospitalForm.get('wardType').disable({onlySelf: false, emitEvent: false});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 1});
            this.sideMenuService.emitProcess(1, 0);

           /* let routerNavigate = this.deathAndSeriousBodyInjuryLoss.getNatureOfAccidentRouter();
            this.router.navigate([routerNavigate]); */

            if (this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness().toLowerCase() == 'death by accident'.toLowerCase() ||
                this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness().toLowerCase() == 'death due to sickness'.toLowerCase()) {
                this.router.navigate(['/claimform/personalAccident/medicalBills']);

            } else {
                this.router.navigate(['/claimform/personalAccident/followUpTreatment']);
            }
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfOverseaHospitalForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfOverseaHospitalForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 13, subMenuIndex: 1});
        }
    }

    back() {
        this.router.navigate(["/claimform/personalAccident/detailOfAccident"], {
            relativeTo: this.activatedRoute
        });
    }

    //Google Analytics
    pushGAViewAccidentOversea() {
        (<any>window).dataLayer.push({
            'pageStep': 'Serious body injury & Death – Oversea Hospital Stay',
            'vPath': '/claim/serious-injury-death/oversea-hospital-stay',
            'event': 'vpageview',
        });
    }

}
