import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {VisitedDoctor} from "../../../../model/travel/visted-a-doctor.model";
import {takeUntil} from "rxjs";

@Component({
    selector: 'app-detail-of-illness',
    templateUrl: './detail-of-illness.component.html',
    styleUrls: ['./detail-of-illness.component.css']
})
export class DetailOfIllnessComponent extends ClaimsBaseComponent implements OnInit {

    deTailOfIllnessForm: FormGroup;
    showTotalBox = false;
    visitedDoctor: VisitedDoctor;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.visitedDoctor = this.claim.getClaimTravel().getVisitedDoctor();
        if(this.visitedDoctor.getDateTheIllnessOccurred() == null){
            this.visitedDoctor.setDateTheIllnessOccurred(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {

        this.pushGAViewVisitDoctorDetails();

        this.deTailOfIllnessForm = this.fb.group(
            {
                illnessOccuredDate: [this.visitedDoctor.getDateTheIllnessOccurred()],
                natureOfIllness: [this.visitedDoctor.getNatureOfIllness()],
                diagnoseType: [this.visitedDoctor.getDiagnoseType()],
                otherDiagnose: [this.visitedDoctor.getOtherDiagnose()],
                countryOfTheLossOccurred: [this.visitedDoctor.getCountryOfTheLossOccurred()],
                placeOfLoss: [this.visitedDoctor.getPlaceOfLoss()],
                isFirstVisitForThisCondition: [super.getBooleanString(this.visitedDoctor.getIsFirstVisitForThisCondition())],
                typeOfMedicalPractitioner: [this.visitedDoctor.getTypeOfMedicalPractitioner()],
                otherTypeOfMedicalPractitioner: [this.visitedDoctor.getOtherTypeOfMedicalPractitioner()],
                dentalTreatment: [this.visitedDoctor.getDentalTreatment()],
                wasCausedByAccidentalInjury: [super.getBooleanString(this.visitedDoctor.getWasCausedByAccidentalInjury())],
            }
        );
    }

    ngAfterViewInit() {
        this.deTailOfIllnessForm.valueChanges.subscribe(data => {
            this.visitedDoctor.setDateTheIllnessOccurred(super.getValueInComponent('illnessOccuredDate'));
            this.visitedDoctor.setNatureOfIllness(this.deTailOfIllnessForm.get('natureOfIllness').value);
            this.visitedDoctor.setNatureOfIllnessDesc(super.getTextInComponent('natureOfIllness'));
            this.visitedDoctor.setDiagnoseType(this.deTailOfIllnessForm.get('diagnoseType').value);
            this.visitedDoctor.setDiagnoseTypeDesc(super.getTextInComponent('diagnoseType'));
            this.visitedDoctor.setOtherDiagnose(this.deTailOfIllnessForm.get('otherDiagnose').value);
            this.visitedDoctor.setCountryOfTheLossOccurred(this.deTailOfIllnessForm.get('countryOfTheLossOccurred').value);
            this.visitedDoctor.setCountryOfTheLossOccurredDesc(super.getTextInComponent('countryOfTheLossOccurred'));
            this.visitedDoctor.setPlaceOfLoss(super.getValueInComponent('placeOfLoss'));
            this.visitedDoctor.setPlaceOfLossDesc(super.getTextInComponent('placeOfLoss'));
            this.visitedDoctor.setTypeOfMedicalPractitioner(this.deTailOfIllnessForm.get('typeOfMedicalPractitioner').value);
            this.visitedDoctor.setTypeOfMedicalPractitionerDesc(super.getTextInComponent('typeOfMedicalPractitioner'));
            this.visitedDoctor.setOtherTypeOfMedicalPractitioner(this.deTailOfIllnessForm.get('otherTypeOfMedicalPractitioner').value);

            if (this.deTailOfIllnessForm.get('isFirstVisitForThisCondition').value != null) {
                this.visitedDoctor.setIsFirstVisitForThisCondition(this.deTailOfIllnessForm.get('isFirstVisitForThisCondition').value == 'true');
            }

            this.visitedDoctor.setDentalTreatment(this.deTailOfIllnessForm.get('dentalTreatment').value);
            this.visitedDoctor.setDentalTreatmentDesc(super.getTextInComponent('dentalTreatment'));

            if (this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').value != null) {
                this.visitedDoctor.setWasCausedByAccidentalInjury(this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').value == 'true');
            }

            this.validateClaimDetailsComplete();
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        self.deTailOfIllnessForm.get('natureOfIllness').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.natureOfIllnessFields(data);
        });

        self.deTailOfIllnessForm.get('diagnoseType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideOtherDiagnoseType(data);
        });

        setTimeout(function () {self.hideOthersField(self.visitedDoctor.getTypeOfMedicalPractitioner()),10});
        this.deTailOfIllnessForm.get('typeOfMedicalPractitioner').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOthersField(data);
        });

    }

    hideOthersField(data) {
        if (data && data.toLowerCase() === 'others') {
            this.deTailOfIllnessForm.get('otherTypeOfMedicalPractitioner').enable({onlySelf: false, emitEvent: false});
        } else {
            this.deTailOfIllnessForm.get('otherTypeOfMedicalPractitioner').reset();
            this.deTailOfIllnessForm.get('otherTypeOfMedicalPractitioner').disable({onlySelf: false, emitEvent: false});
        }
    }

    natureOfIllnessFields(natureOfIllness) {
        if (natureOfIllness && natureOfIllness.toLowerCase() === 'sickness') {
            this.deTailOfIllnessForm.get('diagnoseType').enable({onlySelf: false, emitEvent: false});
            this.deTailOfIllnessForm.get('dentalTreatment').reset();
            this.deTailOfIllnessForm.get('dentalTreatment').disable({onlySelf: false, emitEvent: false});
            this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').reset();
            this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').disable({onlySelf: false, emitEvent: false});

        } else if (natureOfIllness && natureOfIllness.toLowerCase() === 'dental') {
            this.deTailOfIllnessForm.get('dentalTreatment').reset();
            this.deTailOfIllnessForm.get('dentalTreatment').enable({onlySelf: false, emitEvent: false});
            this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').reset();
            this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').enable({onlySelf: false, emitEvent: false});
            this.deTailOfIllnessForm.get('diagnoseType').reset();
            this.deTailOfIllnessForm.get('diagnoseType').disable({onlySelf: false, emitEvent: false});
        }else {
            this.deTailOfIllnessForm.get('diagnoseType').reset();
            this.deTailOfIllnessForm.get('diagnoseType').disable({onlySelf: false, emitEvent: false});
            this.deTailOfIllnessForm.get('dentalTreatment').reset();
            this.deTailOfIllnessForm.get('dentalTreatment').disable({onlySelf: false, emitEvent: false});
            this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').reset();
            this.deTailOfIllnessForm.get('wasCausedByAccidentalInjury').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideOtherDiagnoseType(diagnoseType) {

        if (diagnoseType && diagnoseType.toLowerCase() === 'others') {
            this.deTailOfIllnessForm.get('otherDiagnose').enable({onlySelf: false, emitEvent: false});
        } else {
            this.deTailOfIllnessForm.get('otherDiagnose').reset();
            this.deTailOfIllnessForm.get('otherDiagnose').disable({onlySelf: false, emitEvent: false});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 0});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/visitedADoctor/detailsOfOverseaDoctorVisit"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfIllnessForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfIllnessForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 4, subMenuIndex: 0});
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    //Google Analytics
    pushGAViewVisitDoctorDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Visit Doctor – Illness Details',
            'vPath': '/claim/visit-doctor/illness-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}
