import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { Claim } from 'src/app/model/claim.model';
import { ClaimMotor } from 'src/app/model/claim-motor.model';
import { ClaimService } from 'src/app/services/claim.service';
import { DatePipe } from '@angular/common';
import { Theft } from 'src/app/model/motor/theft-model';
import { DocumentField } from 'src/app/model/document-field';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-summary-theft',
  templateUrl: './summary-theft.component.html',
  styleUrls: ['./summary-theft.component.css']
})
export class SummaryTheftComponent extends ClaimsBaseComponent implements OnInit {

  claim: Claim;
  claimMotor: ClaimMotor;
  theft: Theft;
  listOfDocuments: DocumentField[] = [];
  listOfOtherDocuments: DocumentField[] = [];

  constructor(private claimService: ClaimService,
      private injector: Injector,
      private documentHelperService: DocumentHelperService) {
       
        super(injector);
        this.claim = this.claimService.getClaim();
        this.theft = this.claim.getClaimMotor().getTheft();
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]).getRequiredDocuments(),
          ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]).getRequiredDocuments(),
          ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]);
  }

  ngOnInit() {

  }

  returnYesOrNo(value: boolean) {
      if (value) {
          return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
      } else {
          return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
      }
  }
  returnYesOrNoBoolean(value: boolean) {
      if (value) {
          return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
      } else {
          if (!value) {
              return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
          }
      }
      return '';
  }
  getDate(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
      }
      else {
          return "";
      }
  }
  getDateTime(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en\-US').transform(dt, "dd/MM/yyyy HH:mm");
      } else {
          return "";
      }
  }

    isNotNullOrUndefined(val: any){
        if(!UtilitiesService.isNullOrUndefined(val)){
            return true;
        }   
        return false;
    }

    isNotNullOrUndefinedStr(val: any){
        if(!UtilitiesService.isNullOrUndefined(val) && val != ''){
            return true;
        } 
        return false;
    }
}

