import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Version } from "../../version";
import { TransactionInfoService } from "./transaction-info.service";
import { DocumentField } from "../model/document-field";

@Injectable()
export class GenericDocumentService {


    constructor(private http: HttpClient) {
    }

    private getGenericDocumentCategoryList(): Observable<any> {
        return this.http.get('assets/GenericDocumentCategory.json' + Version.getUrlVersion());
    }

    getGenericUploadDocList(docCategory: string) {
        let genDocList: DocumentField[] = [];
        let genDocCategoryList = [];
        this.getGenericDocumentCategoryList().subscribe(data => {
            genDocCategoryList = data;

            genDocCategoryList.forEach(genDoc => {
                if (docCategory === genDoc.category) {
                    console.log('GENDOCLIST', genDoc.documents);
                    genDoc.documents.forEach(docu => {
                        let doc = new DocumentField(docu.id, docu.description, docu.smartqField, docu.isRequired);
                        genDocList.push(doc);
                    });
                }
            });
        });
        return genDocList;
    }

}


