export let injury = [
    {
        id: 'abrasion',
        label: 'ecClaim.accidentDetails.natureOfInjury.abrasion',
        value: '01',
    },
    {
        id: 'amputation',
        label: 'ecClaim.accidentDetails.natureOfInjury.amputation',
        value: '02',
    },
    {
        id: 'asphyxia',
        label: 'ecClaim.accidentDetails.natureOfInjury.asphyxia',
        value: '03',
    },
    {
        id: 'burn-heat',
        label: 'ecClaim.accidentDetails.natureOfInjury.burnHeat',
        value: '04',
    },
    {
        id: 'burn',
        label: 'ecClaim.accidentDetails.natureOfInjury.burn',
        value: 'Burn',
    },
    {
        id: 'contusion-bruise',
        label: 'ecClaim.accidentDetails.natureOfInjury.contusionBruise',
        value: '06',
    },
    {
        id: 'concussion',
        label: 'ecClaim.accidentDetails.natureOfInjury.concussion',
        value: '07',
    },
    {
        id: 'laceration-cut',
        label: 'ecClaim.accidentDetails.natureOfInjury.lacerationCut',
        value: '08',
    },
    {
        id: 'dislocation',
        label: 'ecClaim.accidentDetails.natureOfInjury.dislocation',
        value: '09',
    },
    {
        id: 'crushing',
        label: 'ecClaim.accidentDetails.natureOfInjury.crushing',
        value: '10',
    },
    {
        id: 'electric-shock',
        label: 'ecClaim.accidentDetails.natureOfInjury.electricShock',
        value: '11',
    },
    {
        id: 'fracture',
        label: 'ecClaim.accidentDetails.natureOfInjury.fracture',
        value: '12',
    },
    {
        id: 'puncture-wound',
        label: 'ecClaim.accidentDetails.natureOfInjury.punctureWound',
        value: '13',
    },
    {
        id: 'sprain-strain',
        label: 'ecClaim.accidentDetails.natureOfInjury.sprainStrain',
        value: '14',
    },
    {
        id: 'freezing',
        label: 'ecClaim.accidentDetails.natureOfInjury.freezing',
        value: '15',
    },
    {
        id: 'poisoning',
        label: 'ecClaim.accidentDetails.natureOfInjury.poisoning',
        value: '16',
    },
    {
        id: 'irritation',
        label: 'ecClaim.accidentDetails.natureOfInjury.irritation',
        value: '17',
    },
    {
        id: 'nausea',
        label: 'ecClaim.accidentDetails.natureOfInjury.nausea',
        value: '18',
    },
    {
        id: 'multiple-injuries',
        label: 'ecClaim.accidentDetails.natureOfInjury.multipleInjuries',
        value: '19',
    },
];