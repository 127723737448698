import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import {RouterService} from './router.service';
import {Injectable} from '@angular/core';
import {TransactionInfoService} from "../services/transaction-info.service";
import {UiConfigService} from "../ui/ui-config.service";
import {PageService} from "../services/page.service";
import { TransactionInfo } from '../model/transaction-info.model';

@Injectable()
export class PermissionGuard implements CanActivate {
    constructor(public router: Router,
                public routerService: RouterService,
                public transactionInfoService: TransactionInfoService,
                public pageService: PageService,
                private uiConfigService: UiConfigService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let path: string = route.routeConfig.path;
        let transactionInfo = this.transactionInfoService.getTransactionInfo();
        let isUserVerified = transactionInfo.getCustomerVerified();
        let uiConfig = this.uiConfigService.getUiConfig();
        let isRefresh = this.pageService.getParameters()['refresh'];
        let routeUrl: string = this.router.url;

        console.log("canActivate - path: " , path);

        if (['confirmTravelPeriod', 'confirmVehicleDetails', 'confirmIncidentDetails', 'employerDetails'].indexOf(path) !== -1) {
            if (this.routerService.finishGetStarted) {
                return true;
            } else {
               if(this.isSessionExpired(uiConfig, isUserVerified)) {
                   return false;
               }
            }
        }  else if (path === 'selectInsured') {
            let action = transactionInfo.getAction();
            if (this.routerService.finishConfirmTravelPeriod && transactionInfo.isTravelProduct()) {
                return true;
            } else if (action === TransactionInfo.AUTO_SAVE_RESUME && 
                        this.routerService.finishGetStarted && transactionInfo.isTravelProduct()) { //autosave
                return true;
            } else if (this.routerService.finishGetStarted && (transactionInfo.isDomesticHelperProduct() || 
                transactionInfo.isAccidentProduct())) {
                return true;
            } else {
                if (this.isSessionExpired(uiConfig, isUserVerified)) {
                    return false;
                }
            }
        } else if (path === 'selectClaim') {
            if (this.routerService.finishSelectInsured || this.routerService.finishConfirmVehicleDetails) {
                return true;
            } else {
                if (this.isSessionExpired(uiConfig, isUserVerified)) {
                    return false;
                }
            }
        } else if (path === 'paymentAndContact') {
            if (this.routerService.finishChooseClaim || this.routerService.finishGenericClaimDetail) {
                return true;
            } else {
                if (this.isSessionExpired(uiConfig, isUserVerified)) {
                    return false;
                }
            }
        } else if (path === 'summary') {
            if (this.routerService.finishPayment) {
                return true;
            } else {
                if (this.isSessionExpired(uiConfig, isUserVerified)) {
                    return true;
                }
            }
        } else if (path == 'genericClaimDetail') {
            if (this.routerService.finishGetStarted) {
                return true;
            } else {
                if (this.isSessionExpired(uiConfig, isUserVerified)) {
                    return true;
                }
            }
        } else if (path == 'genericUploadDocument') {
            if (this.routerService.finishGenericClaimDetail) {
                return true;
            } else {
                if (this.isSessionExpired(uiConfig, isUserVerified)) {
                    return true;
                }
            }
        } else {
            if (path == 'getStart') {
                if ((routeUrl === "/" && !isRefresh) || isRefresh) {
                    return this.isSessionExpired(uiConfig, true);
                }
                return true;
            } else {
                if ((routeUrl === "/" && !isRefresh) || isRefresh) {
                    return this.isSessionExpired(uiConfig, isUserVerified);
                }
                return true;
            }
        }
    }

    isSessionExpired(uiConfig , isCustomerVerified) {
        if (this.isConfigEmpty(uiConfig) || !isCustomerVerified) {
            this.router.navigate(['/error'], { queryParams: {errorType : 'SessionExpired'}});
            return false;
        }
        return true;
    }

    isConfigEmpty(obj): boolean {
        return Object.keys(obj).length === 0;
    }

}