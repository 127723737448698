import {Component, Input, OnInit} from "@angular/core";
import {NotificationMessage} from "./notification-message.model";

@Component({
  selector: 'qnect-file-error-notification',
  templateUrl: './file-error-notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class FileErrorNotificationComponent implements OnInit {

  @Input()
  messages: NotificationMessage[] = [];
  @Input() showPreamble: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

}
