<div class="form-group section">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionTripCancel.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTripCancel.subDetailCancel.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCancel.subDetailCancel.dateOriginBook' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(this.tripCancellation.getOriginalBookDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCancel.subDetailCancel.dateCancelTrip' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(this.tripCancellation.getCancelDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCancel.subDetailCancel.reasonForCancel' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="this.tripCancellation.getCancelTripReason()?.toLowerCase() !== 'others'">
                    {{this.tripCancellation.getCancelTripReasonDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="this.tripCancellation.getCancelTripReason()?.toLowerCase() === 'others'">
                    {{this.tripCancellation.getOtherCancelTripReason()}}
                </div>
            </div>
            <div class="row" *ngIf="this.tripCancellation.getCountryOccurredDesc().length > 0">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimSection.tripCancellation.detailOfCancelledTrip.countriesLeading' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getCountryValues()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTripCancel.subLossTripCancel.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCancel.subLossTripCancel.rewards' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.tripCancellation.getDidUseMiles())}}
                </div>
            </div>
            <div class="row">
                <div *ngIf="this.claim.isHKGorHGI()" class="col col-xs-12 col-lg-7">
                    {{'claimSection.tripCancellation.lossCausedByCancellation.amountPaidPerPerson' | translate}}
                </div>
                <div *ngIf="!this.claim.isHKGorHGI()" class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCancel.subLossTripCancel.totalAmount' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCancellation.getTotalAmountPaidCurrency()}}&nbsp;{{this.tripCancellation.getTotalAmountPaidCost() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCancel.subLossTripCancel.refund' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCancellation.getHasRefundDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="this.tripCancellation.getHasRefund()=='true'">
                <div *ngIf="this.claim.isHKGorHGI()" class="col col-xs-12 col-lg-7">
                    {{'claimSection.tripCancellation.lossCausedByCancellation.refundPerPerson' | translate}}
                </div>
                <div *ngIf="!this.claim.isHKGorHGI()" class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionTripCancel.subLossTripCancel.totalRefund' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.tripCancellation.getTotalAmountRefundedCurrency()}}&nbsp;{{this.tripCancellation.getTotalAmountRefundedCost() | number : '1.2-2'}}
                </div>
            </div>
            <ng-container *ngIf="this.claim.getCountry() != 'SGP'">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionETC.extraTrans' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        <span *ngIf="this.claim.getClaimTravel().getTripCancellation().getHasExtTransCost()">{{this.claim.getClaimTravel().getTripCancellation().getExtraTransportationCurrency()}}&nbsp;{{this.claim.getClaimTravel().getTripCancellation().getExtraTransportationCost() | number : '1.2-2'}}</span>
                        <span *ngIf="!this.claim.getClaimTravel().getTripCancellation().getHasExtTransCost()">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionTD.subSectionETC.extraAccom' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        <span *ngIf="this.claim.getClaimTravel().getTripCancellation().getHasExtAccomCost()">{{this.claim.getClaimTravel().getTripCancellation().getExtraAccommodationCurrency()}}&nbsp;{{this.claim.getClaimTravel().getTripCancellation().getExtraAccommodationCost()  | number : '1.2-2'}}</span>
                        <span *ngIf="!this.claim.getClaimTravel().getTripCancellation().getHasExtAccomCost()">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                    </div>
                </div>
            </ng-container>  
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>
