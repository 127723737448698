import { DamagePersonalItemDetail } from "./damage-personal-detail.model";

export class DamagePersonalItem {
    
    private dateTheDamageOccurred: Date;
    private countryTheDamageOccurred: string;
    private countryTheDamageOccurredDesc: string;
    private howDamageOccurred: string;
    private howDamageOccurredDesc: string;
    private otherDamageOccurred: string;
    private placeOfLoss: string;
    private placeOfLossDesc: string;

    private hasAnotherAuthority: boolean;
    private hasCompensationReceived: boolean;
    private authorityType: string;
    private authorityTypeDesc: string;
    private otherAuthorityType: string;
    
    private compensationCurrency: string;
    private compensationValue: string;
    private detailsOfDamageItemsArray: DamagePersonalItemDetail[];
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getDetailsOfDamageItemsArray(): DamagePersonalItemDetail[] {
        return this.detailsOfDamageItemsArray;
    } 

    setDetailsOfDamageItemsArray(value: DamagePersonalItemDetail[]){
        this.detailsOfDamageItemsArray = value;
    }

    getHasAnotherAuthority(): boolean {
        return this.hasAnotherAuthority;
    } 

    setHasAnotherAuthority(value: boolean){
        this.hasAnotherAuthority = value;
    }

    getAuthorityType(): string {
        return this.authorityType;
    } 

    setAuthorityType(value: string){
        this.authorityType = value;
    }

    getAuthorityTypeDesc(): string {
        return this.authorityTypeDesc;
    } 

    setAuthorityTypeDesc(value: string){
        this.authorityTypeDesc = value;
    }

    getOtherAuthorityType(): string {
        return this.otherAuthorityType;
    } 

    setOtherAuthorityType(value: string){
        this.otherAuthorityType = value;
    }

    getHasCompensationReceived(): boolean {
        return this.hasCompensationReceived;
    } 

    setHasCompensationReceived(value: boolean){
        this.hasCompensationReceived = value;
    }

    getCompensationCurrency(): string {
        return this.compensationCurrency;
    } 

    setCompensationCurrency(value: string){
        this.compensationCurrency = value;
    }

    getCompensationValue(): string {
        return this.compensationValue;
    } 

    setCompensationValue(value: string){
        this.compensationValue = value;
    }

    getDateTheDamageOccurred(): Date {
        return this.dateTheDamageOccurred;
    } 

    setDateTheDamageOccurred(value: Date){
        this.dateTheDamageOccurred = value;
    }
    
    getCountryTheDamageOccurred(): string {
        return this.countryTheDamageOccurred;
    } 

    setCountryTheDamageOccurred(value: string){
        this.countryTheDamageOccurred = value;
    }

    getCountryTheDamageOccurredDesc(): string {
        return this.countryTheDamageOccurredDesc;
    }

    setCountryTheDamageOccurredDesc(value: string){
        this.countryTheDamageOccurredDesc = value;
    }

    getHowDamageOccurred(): string {
        return this.howDamageOccurred;
    } 

    setHowDamageOccurred(value: string){
        this.howDamageOccurred = value;
    }

    getHowDamageOccurredDesc(): string {
        return this.howDamageOccurredDesc;
    } 

    setHowDamageOccurredDesc(value: string){
        this.howDamageOccurredDesc = value;
    }

    getOtherDamageOccurred(): string {
        return this.otherDamageOccurred;
    } 

    setOtherDamageOccurred(value: string){
        this.otherDamageOccurred = value;
    }

    getPlaceOfLoss(): string {
        return this.placeOfLoss;
    }

    setPlaceOfLoss(value: string){
        this.placeOfLoss = value;
    }

    getPlaceOfLossDesc(): string {
        return this.placeOfLossDesc;
    }

    setPlaceOfLossDesc(value: string){
        this.placeOfLossDesc = value;
    }

    static jsonConvert(damagePersonalItem: DamagePersonalItem): DamagePersonalItem {

        if (damagePersonalItem.getDateTheDamageOccurred() != null) {
            damagePersonalItem.setDateTheDamageOccurred(new Date(damagePersonalItem.getDateTheDamageOccurred()));
        }

        if (damagePersonalItem.getDetailsOfDamageItemsArray() != null) {
            let damagePersonalItemDetail: DamagePersonalItemDetail[] = [];
            for (const damagelostPersonalItems of damagePersonalItem.getDetailsOfDamageItemsArray()) {

                    let item = Object.assign(new DamagePersonalItemDetail(),damagelostPersonalItems);
                    item = DamagePersonalItemDetail.jsonConvert(item);
                    damagePersonalItemDetail.push(item); 
                
            }
    
            damagePersonalItem.setDetailsOfDamageItemsArray(damagePersonalItemDetail);
    
        }

        return damagePersonalItem;
    }

}
