<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
            <h5>{{ 'paClaim.icu.title' | translate }}</h5>
        <br>
    </div>
    <div class="row col-12">
        <div class="col-lg-9 col-md-12">
            <form>
                <div class="form-group">
                    <qnect-ui #PA_ICU_STAYEDIN [fieldId]="'PA_ICU_STAYEDIN'" [formGroup]="icuForm" name="stayedIn"></qnect-ui>
                </div>
                <ng-container *ngIf="this.claimPA.getAccidentalMedicalExpenses().getStayedIn()">
                    <div class="form-group">
                        <qnect-ui #PA_ICU_DATEFROM [fieldId]="'PA_ICU_DATEFROM'" [formGroup]="icuForm"
                                  [dateRangeValidator]="getDatesForValidation('a')" name="dateFrom"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui #PA_ICU_DATETO [fieldId]="'PA_ICU_DATETO'" [formGroup]="icuForm" name="dateTo"
                                  [dateRangeValidator]="getDatesForValidation('d')"></qnect-ui>
                    </div>
                </ng-container>
            </form>
        </div>
        <div class="col-lg-3 col-xl-3 col-md-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>