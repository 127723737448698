<div *ngIf="messages?.length > 0" class="col-md-12 alert alert-danger declineDivOuter">
  <h4>
    <div class="glyphicon glyphicon-warning-sign"></div>&nbsp;Decline Messages
  </h4>

  <div *ngIf="showPreamble">This risk is classified as a Decline under the Qnect platform due to the following reasons.
    Please email/contact your QBE representative<br/>
    to determine if they can assist you with this risk under a separate product and pricing option:
  </div>
  <ul class="baseline-left" *ngFor="let decline of messages">
    <li>{{decline.message | translate}}</li>
  </ul>
</div>
