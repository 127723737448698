<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'ecClaim.employeeEarnings.title'  | translate }}</h5>
    <p>{{ 'ecClaim.employeeEarnings.subHeading' | translate }}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_WORKING_DAYS [fieldId]="'EC_EARNINGS_WORKING_DAYS'" [formGroup]="employeeEarningsForm" name="workingDays"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="this.employeeEarnings.getWorkingDays()?.toLowerCase() == 'others'">
          <qnect-ui #EC_EARNINGS_SPECIFY [fieldId]="'EC_EARNINGS_SPECIFY'" [formGroup]="employeeEarningsForm" name="specify"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_PAIR_OR_UNPAID [fieldId]="'EC_EARNINGS_PAIR_OR_UNPAID'" [formGroup]="employeeEarningsForm" name="paidOrUnpaid"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_FIXED_OR_FLEXIBLE [fieldId]="'EC_EARNINGS_FIXED_OR_FLEXIBLE'" [formGroup]="employeeEarningsForm" name="fixedOrFlexible"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="this.employeeEarnings.getIsFixed()">
          <qnect-ui #EC_EARNINGS_DAY_OF_WEEK [fieldId]="'EC_EARNINGS_DAY_OF_WEEK'" [formGroup]="employeeEarningsForm" name="dayOfWeek"></qnect-ui>
        </div>
        <br/>

        <h5 style="font-size: 21px;">{{ 'ecClaim.employeeEarnings.monthlyEarnings' + getClaimTypeCaption() | translate }}
          <a href="javascript:;" (click)="open(monthlyEarningsInfo,'monthlyEarningsInfo')">
            <i class="hits"></i>
          </a>
        </h5>
        <p>{{ 'ecClaim.employeeEarnings.monthlyEarningsDesc' + getClaimTypeCaption() | translate }}</p>

        <div class="form-group">
          <qnect-ui #EC_EARNINGS_WAGES [fieldId]="'EC_EARNINGS_WAGES'" [formGroup]="employeeEarningsForm"
                    [prefix]="this.currency" name="wages"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_FOOD_ALLOWANCE [fieldId]="'EC_EARNINGS_FOOD_ALLOWANCE'" [formGroup]="employeeEarningsForm"
                    [prefix]="this.currency" name="foodAllowance"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_OTHER_ITEM [fieldId]="'EC_EARNINGS_OTHER_ITEM'" [formGroup]="employeeEarningsForm" name="otherItem"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_OTHER_AMOUNT [fieldId]="'EC_EARNINGS_OTHER_AMOUNT'" [formGroup]="employeeEarningsForm"
                    [prefix]="this.currency" name="otherAmount"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_TOTAL_AMOUNT [fieldId]="'EC_EARNINGS_TOTAL_AMOUNT'" [formGroup]="employeeEarningsForm"
                    [prefix]="this.currency" name="totalAmount" ></qnect-ui>
        </div>
        <br/>
        <h5 style="font-size: 21px;">{{ 'ecClaim.employeeEarnings.averageMonthlyEarnings'  | translate }}
          <a href="javascript:;" (click)="open(averageEarningsInfo,'averageEarningsInfo')">
            <i class="hits"></i>
          </a>
        </h5>
        <p>{{ 'ecClaim.employeeEarnings.averageMonthlyEarningsDesc' + getClaimTypeCaption() | translate }}</p>
        <div class="form-group">
          <qnect-ui #EC_EARNINGS_AVERAGE_EARNINGS [fieldId]="'EC_EARNINGS_AVERAGE_EARNINGS'" [formGroup]="employeeEarningsForm"
                    [prefix]="this.currency" name="averageEarnings"></qnect-ui>
          <p>
            <a href="javascript:;" (click)="open(calculate_monthly_earnings,'calculate_monthly_earnings')">
            {{ 'ecClaim.employeeEarnings.clickHere.link' | translate}}</a>  {{ 'ecClaim.employeeEarnings.clickHere.label' | translate}}
          </p>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #calculate_monthly_earnings let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">{{'ecClaim.employeeEarnings.headerLabel' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="modal-body-text">{{'ecClaim.employeeEarnings.bodyLabel' | translate}} <strong>{{this.currentMonth}}</strong>,
      {{'ecClaim.employeeEarnings.bodyLabel2' | translate}} <strong>{{this.datedFrom}}</strong>
      {{'ecClaim.employeeEarnings.to' | translate}} <strong>{{this.datedTo}}</strong>.
    </h4>
    <form [formGroup]="employeeEarningsAmountForm" >
      <div class="center-table">
        <table>
          <thead>
          <tr class ="row-table">
            <th><strong>{{'ecClaim.employeeEarnings.dateFormat' | translate}}</strong></th>
            <th><strong>{{'ecClaim.employeeEarnings.earnings' | translate}}</strong></th>
          </tr>
          </thead>
          <tbody>
          <ng-container formArrayName="items" *ngFor="let item of employeeEarningsAmountForm.get('items')['controls']; let i = index;">
              <tr class ="row-table">
                <td>
                  <span>{{item.value.month}}</span>
                </td>
                <td class ="earnings-padding">
                  <qnect-ui [fieldId]="'EC_EARNINGS_MONTHLY_EARNINGS'"
                            [frmArrayGroup]="employeeEarningsAmountForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            [prefix]="this.currency"
                            name="monthlyEarnings"></qnect-ui>
                </td>
              </tr>
          </ng-container>
          <tr>
            <td>
              <button type="button" class="btn btn-secondary new-btn calc-btn"
                      (click)="calculate()">{{'claimSection.generalLabels.generalButtonLabels.calculate' | translate}}</button>
            </td>
            <td class ="earnings-padding">
              <qnect-ui #EC_EARNINGS_TOTAL_MONTHLY_EARNINGS [fieldId]="'EC_EARNINGS_AVE_MONTHLY_EARNINGS'" [formGroup]="employeeEarningsAmountForm"
                        [prefix]="this.currency" name="aveMonthlyEarnings"></qnect-ui>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </form>
    <div class="buttons row">
      <div class="col-6" style="text-align: right;">
        <button type="button" class="btn btn-secondary new-btn"
                (click)="c('Close click')">{{'claimSection.generalLabels.generalButtonLabels.cancel' | translate}}</button>
      </div>
      <div class="col-6" style="text-align: left;">
        <button type="button" class="btn btn-primary new-btn"
                (click)="confirm()">{{'claimSection.generalLabels.generalButtonLabels.confirm' | translate}}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #monthlyEarningsInfo let-c="close" let-d="dismiss" class="white-popup mfp-hide">
  <div class="modal-header">
    <h5>{{'ecClaim.employeeEarnings.monthlyEarnings' + getClaimTypeCaption() | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal_content" [innerHTML]="'ecClaim.employeeEarnings.monthlyEarningsContent' | translate"></div>
</ng-template>

<ng-template #averageEarningsInfo let-c="close" let-d="dismiss" class="white-popup mfp-hide">
  <div class="modal-header">
    <h5 style="padding-left:55px;">{{'ecClaim.employeeEarnings.averageMonthlyEarningsPopUp.title' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal_content" [innerHTML]="'ecClaim.employeeEarnings.averageMonthlyEarningsPopUp.content1' | translate"></div>
  <div class="modal-body modal_content" [innerHTML]="'ecClaim.employeeEarnings.averageMonthlyEarningsPopUp.content2' | translate"></div>
  <div class="modal-body modal_content" [innerHTML]="'ecClaim.employeeEarnings.averageMonthlyEarningsPopUp.content3' | translate"></div>
  <div class="modal-body modal_content" [innerHTML]="'ecClaim.employeeEarnings.averageMonthlyEarningsPopUp.content4' | translate"></div>
  <div class="grid-container">
    <div class="item1">{{'ecClaim.employeeEarnings.averageMonthlyEarningsPopUp.content5' | translate}}</div>
    <div class="item2" style="align-self: center;">=</div>
    <div class="item3" style="border-bottom-style: ridge;">{{'ecClaim.employeeEarnings.averageMonthlyEarningsPopUp.content6' | translate}}</div>
    <div class="item4">4</div>
    </div>
</ng-template>