<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.tripCancellation.detailOfCancelledTrip.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #TRAVEL_CANCEL1 [fieldId]="'TRAVEL_CANCEL1'" [formGroup]="detailOfCancelForm" name="dateOriginBook"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_CANCEL3 [fieldId]="'TRAVEL_CANCEL3'" [formGroup]="detailOfCancelForm" name="dateCancelTrip"></qnect-ui>
          <qnect-ui #TRAVEL_CANCEL19 [fieldId]="'TRAVEL_CANCEL19'" [formGroup]="detailOfCancelForm" name="countryOfLoss" ></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_CANCEL11 [fieldId]="'TRAVEL_CANCEL11'" [formGroup]="detailOfCancelForm" name="reasonForCancel"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="this.tripCancellation.getCancelTripReason() == 'Others'">
          <qnect-ui #TRAVEL_CANCEL12 [fieldId]="'TRAVEL_CANCEL12'" [formGroup]="detailOfCancelForm" name="otherReason"></qnect-ui>
        </div>
        <!-- multi select component -->
        <div class="auth-selector">
          <ul class="nav auth-nav-tabs ff-stag">
              <li style="font-size:18px;">{{ 'claimSection.tripCancellation.detailOfCancelledTrip.countriesLeading' | translate }}*</li>
          </ul>
        </div>
        <div class="form-group row" [formGroup]="multiSelectForm">
          <div class="col-md-8 col-xs-12">
               <ng-multiselect-dropdown
                       formControlName="countries"
                       [placeholder]="pleaseSelect"
                       [settings]="dropdownSettings"
                       [data]="dropdownList"
                       [(ngModel)]="selectedItems"
                       (onSelect)="onItemSelect($event)"
                       (onDeSelect)="onDeSelect($event)"
                       [ngClass]="validateMultiSelect() ? setClass() : 'validField'"
               >
            </ng-multiselect-dropdown>
            <small *ngIf="validateMultiSelect()" class="errorMessageMultiSelect">
              {{'error_messages.required.tripCancel.countriesLeading' | translate}}</small>
          </div>
          <div class="col-md-4"></div>
      </div>
      <!-- multi select component -->
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>
  <additional-claimant></additional-claimant>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
