import {Component, Injector, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { takeUntil } from 'rxjs/operators';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { MedicalCertificate } from 'src/app/model/personal-accident/medical-certificate.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'app-medical-certificate',
    templateUrl: './medical-certificate.component.html',
    styleUrls: ['./medical-certificate.component.css']
})
export class MedicalCertificateComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    ameForm: FormGroup;
    medCertForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    medicalCertificates: MedicalCertificate[];
    currency: string;
    
    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
    }

    ngOnInit() {
        this.pushGAPageView();
        
        this.ameForm = this.fb.group({
            wasMedCertIssued: [super.getBooleanString(this.claimPA.getAccidentalMedicalExpenses().getWasMedCertIssued())]
        });

        this.medicalCertificates = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getMedicalCertificates();
        this.medCertForm = this.fb.group({
            items: this.fb.array(this.buildMedicalCertificateItems(this.medicalCertificates))
        });
    }

    ngAfterViewInit() {
        this.ameForm.valueChanges.subscribe(data => {
            if (this.ameForm.get('wasMedCertIssued').value !== null) {
                this.claimPA.getAccidentalMedicalExpenses().setWasMedCertIssued(this.ameForm.get('wasMedCertIssued').value == 'true');
            }
        });

        this.medCertForm.valueChanges.subscribe(data => {

            let medCerts: MedicalCertificate[] = [];

            for (let i = 0; i < data.items.length; i++) {

                let itemDetail : MedicalCertificate = new MedicalCertificate();
                itemDetail.setPeriodFrom(super.getIndexedValueInComponent("periodFrom", i));
                itemDetail.setPeriodTo(super.getIndexedValueInComponent("periodTo", i));
                itemDetail.setMedCertPurpose(data.items[i].medCertPurpose);
            
                medCerts.push(itemDetail);
            }

            this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setMedicalCertificates(medCerts);
        });

        let self = this;

        // Stop validation of fields when not required.
        setTimeout(() => {self.hideFields(self.getBooleanString(self.claimPA.getAccidentalMedicalExpenses().getWasMedCertIssued()))},10);
        this.ameForm.get('wasMedCertIssued').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideFields(data);
        });
    }

    buildMedicalCertificateItems(medicalCertificates: MedicalCertificate[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];

        if(medicalCertificates !== null && medicalCertificates.length > 0 ) {
            for(let medCert of medicalCertificates) {
                let rowFormGroup = this.fb.group({
                    periodFrom: [medCert.getPeriodFrom()],
                    periodTo: [medCert.getPeriodTo()],
                    medCertPurpose: [medCert.getMedCertPurpose()]
                });
                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultMedicalCertificateItems());
        }

        return formGroupArray;
    }

    createDefaultMedicalCertificateItems(): FormGroup {
        return this.fb.group({
            periodFrom: null,
            periodTo: null,
            medCertPurpose: ''
        });
    }

    addMedCertItem(): void {
        let self = this;
        setTimeout(function () {
            let items = self.medCertForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultMedicalCertificateItems();
            if (items.length < 5) {
                items.push(newItem);
            }
        }, 10);
    }
    
    removeMedCertItem(i) {
        let items = this.medCertForm.get('items') as FormArray;
        items.removeAt(i);
        this.medicalCertificates.splice(i, 1);
    }

    hideFields(wasMedCertIssued) {
        if (wasMedCertIssued === "true") {
            this.toggleForms(this.medCertForm, true);
        } else {
            this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().setMedicalCertificates(null);
            this.toggleForms(this.medCertForm, false);
        }
    }

    toggleForms(form: FormGroup, show: boolean) {
        if(show){
            form.enable({onlySelf: false, emitEvent: false});
        } else {
            form.disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 3});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/anotherInsurance"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        let wasMedCertIssued = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasMedCertIssued();
        if(super.validateForm(this.ameForm)){
            if(wasMedCertIssued){
                return super.validateForm(this.medCertForm);
            } else {
                return true;
            }
        }
    }

    back() {
        let wasAdmitted = this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted();
        let url = wasAdmitted ? "/claimform/accidentalMedicalExpenses/outpatient" : "/claimform/accidentalMedicalExpenses/hospital";
        this.router.navigate([url], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAPageView() {
        let pageStep = this.transactionInfo.getGaPageStep() + ' - Medical Certificate';
        let vPath = this.transactionInfo.getGaVPath() + '/medical-certificate';
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6}
                }
            }
        });
    }
}
