import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-visited-doctor',
  templateUrl: './visited-doctor.component.html',
  styleUrls: ['./visited-doctor.component.css']
})
export class VisitedDoctorComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT]);
    }

  ngOnInit() {
  }

}
