import { NavigationError, Router, Event } from "@angular/router";
import {Injectable, Injector} from "@angular/core";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpErrorResponse, HttpClient, HttpHeaders } from "@angular/common/http";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimService } from "src/app/services/claim.service";
import { DeviceDetectorService } from "ngx-device-detector";
import {PageService} from "../../services/page.service";

@Injectable()
export class ErrorsService{
    constructor(
        private injector: Injector, 
        public http: HttpClient,
        private claimService: ClaimService,
        public transactionService : TransactionInfoService,
        private deviceService: DeviceDetectorService,
        private pageService: PageService,
        private router: Router) {
          this.router.events.subscribe((event: Event) => { 
            if (event instanceof NavigationError) {
                if (!navigator.onLine) { return; }
                // Redirect to the ErrorComponent
                this.log(event.error).subscribe();
            }
          });
      }

    log(error) {

        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'}),
        };
      
        // Log the error to the console
        console.error(error);
        // Send error to server
        const errorToSend = this.addContextInfo(error);
        //return fakeHttpService.post(errorToSend);
        return this.http.post('/ms/claims-service/logClientError', errorToSend, httpOptions);
    }
    addContextInfo(error) {
        // All the context details that you want (usually coming from other services; Constants, UserService...)
        const name = error.name || null;
        const appId = 'efnol';
        const user = 'efnolUser';
        const time = new Date().toString();
        const id = `${appId}-${user}-${time}`;
        const location = this.injector.get(LocationStrategy); 
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const entryUrl = this.pageService.getEntryUrlDirect();
        const status = error.status || null;
        const message = error.message || error.toString();
        const stackTrace = error.stack || null;
        const claim = this.claimService.getClaim();
        const transactionInfo = this.transactionService.getTransactionInfo();
        const stack = error instanceof HttpErrorResponse ? null : error;
        const machineInfo = this.getDetails();
        const errorToSend = {name, appId, user, entryUrl, time, id, url, status, message, stackTrace, machineInfo, claim, transactionInfo};
        return errorToSend;
      }

      getDetails(){
        let deviceInfo = null;
        deviceInfo = this.deviceService.getDeviceInfo();
        return deviceInfo;
      }
}