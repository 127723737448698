import {Component, Injector, OnInit} from "@angular/core";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {DamagePersonalItem} from "src/app/model/travel/damage-personal-item.model";
import { ClaimTypes } from "../../../../model/claim-type.model";
import { DocumentField } from "../../../../model/document-field";
import { DocumentHelperService } from "../../../../services/document-helper.service";
import {DepreciationService} from '../../../../services/depreciation.service';
import * as moment from "moment";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import {SmartqDocuments} from "../../../../model/smartq-documents.model";
import {DamagePersonalItemDetail} from '../../../../model/travel/damage-personal-detail.model';
@Component({
  selector: 'app-summary-damage-personal-item',
  templateUrl: './summary-damage-personal-item.component.html',
  styleUrls: ['./summary-damage-personal-item.component.css']
})
export class SummaryDamagePersonalItemComponent extends ClaimsBaseComponent implements OnInit {
    
    claim: Claim;
    claimTravel: ClaimTravel;
    damagePersonalItem: DamagePersonalItem;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private depreciationService: DepreciationService,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.claimTravel = this.claim.getClaimTravel();
            this.damagePersonalItem = this.claim.getClaimTravel().getDamagePersonalItem();
            this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]);
            this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]);

    }

    ngOnInit() {
        if(this.claim.getCountry() === 'HKG' || this.claim.getCountry() === 'HGI'){
            this.getDepreciationRateForItems();
        }
    }

    getDepreciationRateForItems() {
        let damageItemsList = this.damagePersonalItem.getDetailsOfDamageItemsArray();
        let dateOfDamage = moment(this.damagePersonalItem.getDateTheDamageOccurred(), 'DD/MM/YYYY');
        let country = this.claim.getCountry();

        if (!UtilitiesService.isNullOrUndefined(dateOfDamage) && damageItemsList.length > 0) {
            for (var i = 0; i < damageItemsList.length; i++) {
                let purchasedDate = damageItemsList[i].getPurchaseDate();
                let item =  damageItemsList[i].getLossType();
                let depreciationRate = "NA";

                if (!UtilitiesService.isNullOrUndefined(purchasedDate) && !UtilitiesService.isNullOrUndefined(damageItemsList[i].getPurchasePrice()) &&
                    damageItemsList[i].getPurchasePrice() > 0 && this.isNotNullOrUndefinedStr(item)) {

                    let startDate = moment(purchasedDate, 'DD/MM/YYYY');
                    let diffInYears = dateOfDamage.diff(startDate, 'year');
                    startDate.add(diffInYears, 'years');
                    let diffInMonths = dateOfDamage.diff(startDate, 'months');
                    startDate.add(diffInMonths, 'months');
                    let diffInDays = dateOfDamage.diff(startDate, 'days');
                    let totalMonths = diffInMonths + (12 * diffInYears);
                    let totalDays = diffInDays;

                    // special case for item hgi and specific agent codes
                    if (country?.toLowerCase() == "hgi") {
                        let agentCode = this.claim.getAgentCode();
                        let isApplicableToPlan: boolean = this.depreciationService.isApplicableToQgiPlan(this.claim.getCatalogueCode(), this.claim.getPlanCode(),
                            this.claim.getClaimTravel().getAreaCode(), this.claim.getClaimTravel().getPeriodOfInsurance());
                        if (['011RP091','011RP092', '011RP093'].indexOf(agentCode) !== -1 && isApplicableToPlan) {
                            depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG], item);
                            if (item.toLowerCase() == "mobile phone") {
                                damageItemsList[i].setExcessDepreciation(20); // Apply 20% excess on mobile phones
                            }
                        } else {
                            // actual depreciation rule is applied only for qgi with more than 2 years of purchase
                            if (!(totalMonths <= 24)) {
                                depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG], item);
                            } else {
                                depreciationRate = "0";
                            }
                        }
                    } else {
                        depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG], item);
                        if (damageItemsList[i].getLossType()?.toLowerCase() == "others") {
                            depreciationRate = "0";
                        }
                    }

                    // if there is no purchase receipt attached, need to apply the additional 10% deduction to the actual depreciation rate
                    if (this.isNotNullOrUndefined(damageItemsList[i].getHasOriginalPurchasedReceipt())) {
                        if (((damageItemsList[i].getHasOriginalPurchasedReceipt() == true &&
                            !this.listOfDocuments.find(document => document.smartqField === SmartqDocuments.PURCHASERCPT &&
                                this.isNotNullOrUndefined(document.uploadedFileName))) ||
                            (damageItemsList[i].getHasOriginalPurchasedReceipt() == false)) && depreciationRate !== 'NA'){
                            depreciationRate = (parseFloat(depreciationRate) + 10).toString();
                        }
                    }

                    if (depreciationRate == "0") {
                        depreciationRate = "NA";
                    }
                }
                damageItemsList[i].setDepreciationRate(depreciationRate);
            }
        }
    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if(!value){
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDamagedParts(item: DamagePersonalItemDetail) {
        if (item.getHasNoDamagedParts()) {
            return item.getDamageDetail();
        } else {
            const damageConditions = [
                { condition: item.getHasBrokenHandle(), translationKey: 'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.brokenHandle' },
                { condition: item.getHasDamagedWheel(), translationKey: 'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.damagedWheel' },
                { condition: item.getHasDamagedZip(), translationKey: 'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.damagedZip' },
                { condition: item.getHasScratches(), translationKey: 'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.scratches' },
                { condition: item.getHasCrushedCrackedTorn(), translationKey: 'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.crushedCrackedTorn' }
            ];

            const temp = damageConditions
                .filter(damage => damage.condition)
                .map(damage => this.translate.instant(damage.translationKey));

            return temp.length > 0 ? temp.join(", ") : "";
        }
    }
}
