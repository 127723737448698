<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'homeProduct.common.tellUsWhatHappened' | translate }}</h5>
    <p>{{ 'homeProduct.personalAccident.title' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group" *ngIf="!isPaClaim">
          <qnect-ui #HOME_PA_HAS_INJURY [fieldId]="'HOME_PA_HAS_INJURY'" [formGroup]="personalAccidentForm" name="hasInjury"></qnect-ui>
        </div>
        <ng-container *ngIf="personalAccident.getHasInjury()">
          <div class="form-group">
            <qnect-ui #HOME_PA_INJURED_PERSON [fieldId]="'HOME_PA_INJURED_PERSON'" [formGroup]="personalAccidentForm" name="injuredPerson"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #HOME_PA_DETAILS [fieldId]="'HOME_PA_DETAILS'" [formGroup]="personalAccidentForm" name="descIncident"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #HOME_PA_DOCTOR_NAME [fieldId]="'HOME_PA_DOCTOR_NAME'" [formGroup]="personalAccidentForm" name="doctorName"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
