import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimDocumentsComponent} from "../../claim-documents/claim-documents.component";
import {DocumentHelperService} from "../../../services/document-helper.service";
import {ClaimService} from "../../../services/claim.service";
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from "../../../services/document-form-factory.service";
import { ClaimTypes } from "../../../model/claim-type.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
    selector: 'app-generic-support-document',
    templateUrl: './generic-support-document.component.html',
    styleUrls: ['./generic-support-document.component.css']
})
export class GenericSupportDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public hasRefunded: boolean = false;
    upLoadFileModel: FormGroup;
    showTotalBox = false;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private fb: FormBuilder,
                private sideMenuService: SideMenuService,
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                documentFormFactoryService: DocumentFormFactoryService, 
                spinnerService: SpinnerService, 
                modalService: NgbModal) {
            super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
            this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]);
    }

    ngOnInit() {
        super.ngOnInit();
        this.pushGAView();
        this.upLoadFileModel = this.fb.group(
            {
                otherDoc: [],
                otherItems: this.fb.array([this.createItem()])

            });
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: []
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        this.sideMenuService.emitProcess(1, 0);
        this.sideMenuService.emitComplete('finishGenericUploadDocument');
        this.router.navigate(["/claimform/anotherInsurance"], {
            relativeTo: this.activatedRoute
        });
    }

    back() {
        this.router.navigate(["/claimform/genericClaimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    pushGAView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Document Upload',
            'vPath': '/document-upload',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 4}
                }
            }
        });
    }
}
