import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-support-doc',
  templateUrl: './vehicle-support-doc.component.html',
  styleUrls: ['./vehicle-support-doc.component.css']
})
export class VehicleSupportDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
