export class MissConnection {

    private originalArrivalDate: Date;
    private actualArrivalDate: Date;
    private countryOccurred: string;
    private countryOccurredDesc: string;
    private reasonOfMissConnect: string;
    private reasonOfMissConnectDesc: string;
    private otherReasonOfMissConnect: string;

    private hasExtAccomCost: boolean = null;
    private extraAccommodationCost: string;
    private extraAccommodationCurrency: string;

    private hasExtTransCost: boolean = null;
    private extraTransportationCost: string;
    private extraTransportationCurrency: string;

    private obtainReport: boolean;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getHasExtTransCost(): boolean {
        return this.hasExtTransCost;
    }

    setHasExtTransCost(value: boolean) {
        this.hasExtTransCost = value;
    }

    getHasExtAccomCost(): boolean {
        return this.hasExtAccomCost;
    }

    setHasExtAccomCost(value: boolean) {
        this.hasExtAccomCost = value;
    }

    setObtainReport(value: boolean){
        this.obtainReport = value;
    }

    getObtainReport(): boolean {
        return this.obtainReport;
    }

    getExtraAccommodationCost(): string {
        return this.extraAccommodationCost;
    }

    setExtraAccommodationCost(value: string) {
        this.extraAccommodationCost = value;
    }

    getExtraTransportationCost(): string {
        return this.extraTransportationCost;
    }

    setExtraTransportationCost(value: string) {
        this.extraTransportationCost = value;
    }

    getExtraAccommodationCurrency(): string {
        return this.extraAccommodationCurrency;
    }

    setExtraAccommodationCurrency(value: string) {
        this.extraAccommodationCurrency = value;
    }

    getExtraTransportationCurrency(): string {
        return this.extraTransportationCurrency;
    }

    setExtraTransportationCurrency(value: string) {
        this.extraTransportationCurrency = value;
    }

    getOriginalArrivalDate(): Date {
        return this.originalArrivalDate;
    }

    setOriginalArrivalDate(value: Date) {
        this.originalArrivalDate = value;
    }

    getActualArrivalDate(): Date {
        return this.actualArrivalDate;
    }

    setActualArrivalDate(value: Date) {
        this.actualArrivalDate = value;
    }

    getCountryOccurred(): string {
        return this.countryOccurred;
    }

    setCountryOccurred(value: string) {
        this.countryOccurred = value;
    }

    getCountryOccurredDesc(): string {
        return this.countryOccurredDesc;
    }

    setCountryOccurredDesc(value: string) {
        this.countryOccurredDesc = value;
    }


    getReasonOfMissConnect(): string {
        return this.reasonOfMissConnect;
    }

    setReasonOfMissConnect(value: string) {
        this.reasonOfMissConnect = value;
    }

    getReasonOfMissConnectDesc(): string {
        return this.reasonOfMissConnectDesc;
    }

    setReasonOfMissConnectDesc(value: string) {
        this.reasonOfMissConnectDesc = value;
    }


    getOtherReasonOfMissConnect(): string {
        return this.otherReasonOfMissConnect;
    }

    setOtherReasonOfMissConnect(value: string) {
        this.otherReasonOfMissConnect = value;
    }

    static jsonConvert(missConnection: MissConnection): MissConnection {

        if (missConnection.getOriginalArrivalDate() != null) {
            missConnection.setOriginalArrivalDate(new Date(missConnection.getOriginalArrivalDate()));
        }
        if (missConnection.getActualArrivalDate() != null) {
            missConnection.setActualArrivalDate(new Date(missConnection.getActualArrivalDate()));
        }

        return missConnection;
    }
}