export class CompensationDetails {

    private monthlyEarnings: number;
    private compensationAmount: number;
    private paymentStatus: string;
    private paymentStatusDesc: string;
    private paymentDate: Date;

    public getMonthlyEarnings(): number {
        return this.monthlyEarnings;
    }

    public setMonthlyEarnings(monthlyEarnings: number): void {
        this.monthlyEarnings = monthlyEarnings;
    }

    public getCompensationAmount(): number {
        return this.compensationAmount;
    }

    public setCompensationAmount(compensationAmount: number): void {
        this.compensationAmount = compensationAmount;
    }

    public getPaymentStatus(): string {
        return this.paymentStatus;
    }

    public setPaymentStatus(paymentStatus: string): void {
        this.paymentStatus = paymentStatus;
    }

    public getPaymentStatusDesc(): string {
        return this.paymentStatusDesc;
    }

    public setPaymentStatusDesc(paymentStatusDesc: string): void {
        this.paymentStatusDesc = paymentStatusDesc;
    }

    public getPaymentDate(): Date {
        return this.paymentDate;
    }

    public setPaymentDate(paymentDate: Date): void {
        this.paymentDate = paymentDate;
    }

    static jsonConvert(compensationDetails: CompensationDetails): CompensationDetails {
        if (compensationDetails !== null) {
            if (compensationDetails.getPaymentDate() !== null) {
                compensationDetails.setPaymentDate(new Date(compensationDetails.getPaymentDate()));
            }
        }
        return compensationDetails;
    }
}