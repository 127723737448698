<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.baggageDelay.detailsOfEmergencyItemsPurchased.title' | translate}}</p>
  </div>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form [formGroup]="emergencyPurchaseForm">
        <div formArrayName="items" *ngFor="let item of emergencyPurchaseForm.get('items')['controls']; let i = index;">
          <div [formGroupName]="i">   
              <div class="form-group click-more-content" id="q1">
                <label>{{'claimSection.baggageDelay.detailsOfEmergencyItemsPurchased.item' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeItem(i)">{{'claimSection.baggageDelay.detailsOfEmergencyItemsPurchased.remove' | translate}}</button><BR>
                <qnect-ui [fieldId]="'TRAVEL_BAG_DEL10'"
                          [frmArrayGroup]="emergencyPurchaseForm"
                          [frmArrayName]="'items'"
                          frmArrayIndex="{{i}}"
                          name="emergencyPurchaseType"></qnect-ui>
              </div>
              <div *ngIf="this.emergencyPurchasedItemsArray != null && this.isNotNullOrUndefined(this.emergencyPurchasedItemsArray[i].getEmergencyPurchaseType())">
                  <div class="form-group" *ngIf="this.emergencyPurchasedItemsArray[i].getEmergencyPurchaseType() == 'Others'">
                      <qnect-ui [fieldId]="'TRAVEL_BAG_DEL11'"
                                [frmArrayGroup]="emergencyPurchaseForm"
                                [frmArrayName]="'items'"
                                frmArrayIndex="{{i}}"
                                name="emergencyPurchaseTypeDetail"></qnect-ui>
                  </div>
                  <div class="form-group">
                      <qnect-ui [fieldId]="'TRAVEL_BAG_DEL12'"
                                [frmArrayGroup]="emergencyPurchaseForm"
                                [frmArrayName]="'items'"
                                frmArrayIndex="{{i}}"
                                [aboveMaxDateValidator]="claim.getClaimTravel().getOriginalReturnDate()"
                                name="purchaseDate"></qnect-ui>
                  </div>
                  <div class="form-group">
                      <qnect-ui [fieldId]="'TRAVEL_BAG_DEL13'"
                                [frmArrayGroup]="emergencyPurchaseForm"
                                [frmArrayName]="'items'"
                                frmArrayIndex="{{i}}"
                                name="purchasePrice"></qnect-ui>
                  </div>
              </div>
              <div class="col-xl-2 col-lg-12">
                  &nbsp;
              </div>              
          </div>
        </div>
      </form>
      <div class="addFile" (click)="addItem()">
        <i class="fas fa-plus"></i>
        <span>{{'claimSection.baggageDelay.detailsOfEmergencyItemsPurchased.addItem' | translate}}</span>
      </div>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
