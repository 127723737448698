import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-back-view-modal',
  templateUrl: './back-view-modal.component.html'
})

@Injectable()
export class BackViewModalComponent {

  @Input() backViewModalGetArea: any;
  @Input() backViewModalIsOpen: any;
  @Input() backViewModalIsOpenF: any;

  @Input() backViewModalTable: any;
  @Input() backViewModalSelectHead: any;
  @Input() backViewModalSelectNeckTrunk: any;
  @Input() backViewModalSelectLeftArm: any;
  @Input() backViewModalSelectRightArm: any;
  @Input() backViewModalSelectLeftHand: any;
  @Input() backViewModalSelectRightHand: any;
  @Input() backViewModalSelectLeftLeg: any;
  @Input() backViewModalSelectRightLeg: any;
  @Input() backViewModalSelectLeftFoot: any;
  @Input() backViewModalSelectRightFoot: any;
  @Input() backViewModalTemp: any;

  @Input() backViewModalFetchedData: any;

  @Input() backViewModalInjuryList: any;

  @Input() backViewModalIsLoading: any;

  @Output() emitBackViewModalOnClickCloseSpecificArea = new EventEmitter<any>();
  backViewModalOnClickCloseSpecificArea() {
    this.emitBackViewModalOnClickCloseSpecificArea.emit();
  }

  @Output() emitBackViewModalOnClickSpecificArea = new EventEmitter<any>();
  backViewModalOnClickSpecificArea(id:any, area:any) {
    this.emitBackViewModalOnClickSpecificArea.emit({ id, area });
  }

  @Output() emitBackViewModalOnClickNotClose = new EventEmitter<any>();
  backViewModalOnClickNotClose(e: any) {
    this.emitBackViewModalOnClickNotClose.emit(e);
  }

  @Output() emitBackViewModalShowTooltip = new EventEmitter<any>();
  backViewModalShowTooltip(txt:any) {
    this.emitBackViewModalShowTooltip.emit(txt);
  }

  @Output() emitBackViewModalHideToolTip = new EventEmitter<any>();
  backViewModalHideToolTip() {
    this.emitBackViewModalHideToolTip.emit();
  }

  isSelected(id: any) {
    return this.backViewModalTemp.some((obj: any) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }

  @Output() emitBackViewModalOnSubmitSelected = new EventEmitter<any>();
  backViewModalOnSubmitSelected() {
    this.emitBackViewModalOnSubmitSelected.emit();
  }

  checkAreaExists(area: any) {
    return this.backViewModalTable.some((item: any) => item.area === area);
  }

  @Output() emitBackViewModalCustomSearchFn = new EventEmitter<any>();
  backViewModalCustomSearchFn(term: any) {
    this.emitBackViewModalCustomSearchFn.emit(term);
  }

  @Output() emitBackViewModalAddTagFn = new EventEmitter<any>();
  backViewModalAddTagFn(label: any) {
    this.emitBackViewModalAddTagFn.emit(label);
  }

  @Output() emitBackViewModalOnClickRemoveSpecificArea = new EventEmitter<any>();
  backViewModalOnClickRemoveSpecificArea(id: any, area: any) {
    this.emitBackViewModalOnClickRemoveSpecificArea.emit({ id, area });
  }
}
