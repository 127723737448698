import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UiComponent} from '../../ui.component';

@Directive({selector: '[claimsDateValidator]'})
export class DateValidatorDirective implements OnInit, AfterViewInit {

  @Input('claimsDateValidator') dateRef: Date;
  @Input() operator: string;
  dateComponent: UiComponent;

  constructor(private host: UiComponent) {
    this.dateComponent = host;
  }

  ngOnInit() {
    this.initializeDateValidator();
  }

  ngAfterViewInit() {
    this.initializeDateValidator();
  }

  initializeDateValidator() {
    const dateComponentType = this.dateComponent.fieldConfig['type'];
    if (dateComponentType === 'datepicker' || dateComponentType === 'datetimepicker') {
      if (dateComponentType === 'datepicker') {
          this.dateComponent.setupCustomValidatorList([this.validateDate()]);
      } else {
          this.dateComponent.setupCustomValidatorList([this.validate()]);
      }
      if (this.dateRef) {
        this.dateComponent.update();
      }
    } else {
        console.log('claimsDateValidator not in datepicker component. UI Component validator error: ' +
        this.dateComponent.fieldId + ' - ' + this.dateComponent.fieldConfig['name']);
    }
  }

  validate = () => {
    return (c: FormControl) => {
      let isError: boolean;
      if (this.dateRef && this.dateComponent.getDateValue()) {
        //console.log(this.dateRef);
        //console.log(new Date(this.dateRef));
        //console.log(this.dateComponent.getDateValue());
        // Just prepared operator string for flexible date validation in future :)
        switch (this.operator) {
          case 'from':
            isError = new Date(this.dateRef) > new Date(this.dateComponent.getDateValue());
            break;
          default: // from
            isError = new Date(this.dateRef) > new Date(this.dateComponent.getDateValue());
            break;
        }
      }

      if (isError) {
        return {'dateValidator': true};
      }
      return null;
    }
  };

    validateDate = () => {
        return (c: FormControl) => {
            let isError: boolean;
            if (this.dateRef && this.dateComponent.getDateValue()) {
                let dateReference = new Date(this.dateRef).setHours(0,0,0,0);
                let dateInput = new Date(this.dateComponent.getDateValue()).setHours(0,0,0,0);
                isError = dateReference > dateInput;
            }

            if (isError) {
                return {'dateValidator': true};
            }
            return null;
        }
    };

}
