import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { DocumentField } from "../../model/document-field";
import { ClaimTypes } from "../../model/claim-type.model";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class TripCurtailmentFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.copyTicket', SmartqDocuments.ASSESSMENT, true));
        requiredDocuments.push(new DocumentField('DOCCAN', 'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.suppCancel', SmartqDocuments.CORRESPONDENCE, true));
        requiredDocuments.push(new DocumentField('RECEIPT', 'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receipt', SmartqDocuments.INVOICE, true));
        if(this.claim.getClaimTravel().getTripCurtailment().getHasTransExtraCost()){
            requiredDocuments.push(new DocumentField('EXTRATRANSCOST', 'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receiptTrans', SmartqDocuments.PAYMENTDOCUMENT, true));
        }
        if(this.claim.getClaimTravel().getTripCurtailment().getHasAccomExtraCost()){
            requiredDocuments.push(new DocumentField('EXTRAACCOMCOST', 'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receiptAccom', SmartqDocuments.RECEIPTACCOMCOST, true));
        }
        requiredDocuments.push(new DocumentField('CERTIFICATE', 'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.certificate', SmartqDocuments.LEGALDOCUMENT, true));
        if(UtilitiesService.checkForMinors(this.claim)){
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}
