<ul *ngIf="errorMessages" [ngClass]="{'multiple' : errorMessages?.length > 1}">
	<li *ngFor="let error of errorMessages" class="text-danger">
		<div [ngSwitch]="isHtml(error.message)">
			<div *ngSwitchCase=true>
				<small id="error-message" [innerHTML]="error.message | translate"></small>
			</div>
			<div *ngSwitchCase=false>
				<small id="error-message">{{error.message | translate}}</small>
			</div>
		</div>
	</li>
</ul>
