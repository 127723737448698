<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{'claimSection.damagePersonalItem.detailsOfDamageItems.title' | translate}}</p>
  </div> 
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form [formGroup]="damageDetailForm">
        <div formArrayName="items" *ngFor="let item of damageDetailForm.get('items')['controls']; let i = index;">
          <div [formGroupName]="i">
            <div class="form-group click-more-content">
              <label>{{'claimSection.damagePersonalItem.detailsOfDamageItems.item' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeItem(i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
              
              <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_11'"
                [frmArrayGroup]="damageDetailForm"
                [frmArrayName]="'items'"
                frmArrayIndex="{{i}}"
                name="lossType"></qnect-ui>
             
            </div>
            <div *ngIf="this.detailsOfDamageItemsArray != null && this.detailsOfDamageItemsArray.length > 0">
              <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() == 'Others'">
                  <div class="form-group">
                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_14'"
                              [frmArrayGroup]="damageDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="lossTypeDetail"></qnect-ui>
                  </div>
                  <div class="form-group click-more-content" id="q1">
                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_15'"
                              [frmArrayGroup]="damageDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="otherTypeBriefDesc"></qnect-ui>
                  </div>
              </div>
              <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() == 'Mobile Phone' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Tablet' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Laptop / Notebook' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Sports equipment' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Luggage / Trunk' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Suitcase / Case' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Backpack / Bag' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Handbag / Briefcase' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Camera' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Wallet' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Earphones / Headphones' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Chargers / Memory cards' 
                  || this.detailsOfDamageItemsArray[i].getLossType() == 'Others'">
                <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() == 'Mobile Phone'" class="form-group">
                  <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_18'"
                            [frmArrayGroup]="damageDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="descOfIncident"></qnect-ui>
                </div>
                <div class="form-group">
                  <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_12'"
                            [frmArrayGroup]="damageDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            [aboveMaxDateValidator]="this.damagePersonalItem.getDateTheDamageOccurred()"
                            name="purchaseDate"></qnect-ui>
                </div>
                <div class="form-group click-more-content">
                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_13'"
                              [frmArrayGroup]="damageDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="purchasePrice"></qnect-ui>
                </div>
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_16'"
                            [frmArrayGroup]="damageDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="repairCost"></qnect-ui>
                </div>
              </div>
              <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() == 'Watch'">
                <div class="form-group">
                  <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_12'"
                            [frmArrayGroup]="damageDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            [aboveMaxDateValidator]="this.damagePersonalItem.getDateTheDamageOccurred()"
                            name="purchaseDate"></qnect-ui>
                </div>
                <div class="form-group click-more-content">
                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_13'"
                              [frmArrayGroup]="damageDetailForm"
                              [frmArrayName]="'items'"
                              frmArrayIndex="{{i}}"
                              name="purchasePrice"></qnect-ui>
                </div>
                <div class="form-group click-more-content">
                  <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_16'"
                            [frmArrayGroup]="damageDetailForm"
                            [frmArrayName]="'items'"
                            frmArrayIndex="{{i}}"
                            name="repairCost"></qnect-ui>
                </div>
              </div>
              <!-- bubble depreciation info -->
              <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() != null && this.detailsOfDamageItemsArray[i].getLossType() != ''">
                <div class="dept-del-info col-xl-8 col-xs-12 col-md-8">
                  <div class="row col-12 col-md-12">
                    <div class="col-3 col-md-2 percent-text"> % </div>
                    <div class=" col-9 col-md-10 dept-desc-text">{{'claimSection.damagePersonalItem.detailsOfDamageItems.depreciationInfo' | translate}}</div>
                  </div>
                </div>
              </div>
              <!-- bubble depreciation info -->
            </div>
            <div class="col-xl-2 col-lg-12">
                &nbsp;
            </div>
          </div>
        </div>
      </form>
      <div class="addFile" (click)="addItem()">
        <i class="fas fa-plus"></i>
        <span>{{ 'claimSection.generalLabels.generalButtonLabels.add' | translate}}</span>
      </div>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>

  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
