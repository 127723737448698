import { AfterViewInit, Directive, DoCheck } from '@angular/core';
import { UiComponent } from '../ui.component';

@Directive({selector: '[qnectMaxDefaultValueValidator]'})
export class MaxDefaultValueValidatorDirective implements AfterViewInit, DoCheck {


  constructor(private uiComponent: UiComponent) {}

  ngAfterViewInit() {
    this.addRangeMaxValidator();
  }

  ngDoCheck() {
    this.addRangeMaxValidator();
  }

  private addRangeMaxValidator() {
    // get default value
    const defaultValue = this.uiComponent.getDefaultValue();
    const value = this.uiComponent.getValue();
    // validate that the value is lower or equal to the default value
    if (value > defaultValue) {
      this.uiComponent.error('max-limit-exceeded');
    }
  }
}
