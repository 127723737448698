<ng-content select=".form"></ng-content>
<ng-content select=".table"></ng-content>

<qnect-modal-dialog>
  <div class="app-modal-header">
    <span>Confirmation</span>
  </div>
  <div class="app-modal-body">
    Are you sure you want to remove the said item ?
  </div>
  <div class="app-modal-footer">
    <div class="row">
      <div class="col-md-6 text-left">
        <button type="button" class="btn btn-primary btn-sm" (click)="modal.hide()">No</button>
      </div>
      <div class="col-md-6 text-right">
        <button type="button" class="btn btn-default btn-sm" (click)="removeItem()">Yes</button>
      </div>
    </div>
  </div>
</qnect-modal-dialog>
