<div class="section form-group">
    <label class="section-title" *ngIf="claimPA.getHasEligibleClaimTypes()">{{'paClaim.others.title' | translate}}</label>
    <label class="section-title" *ngIf="!claimPA.getHasEligibleClaimTypes()">{{'claimDetails.body.claimDetails' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'paClaim.others.detailsOfTheIncident' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.descOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{this.otherDetails.getDescIncident()}}
                </div>
            </div>    
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.estAmtLoss' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.otherDetails.getEstAmountLossCurrency()}}
                    {{this.otherDetails.getEstAmountLoss() | number : '1.2-2'}}
                </div>
            </div>
        </div>

        <div class="sub-section">
            <label>{{'paClaim.others.authorityReport' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.authorityReport.isIncidentReportedToPolice' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    <span>{{returnYesOrNo(this.policeReport.getHasReportedIncidentToPolice())}}</span>
                </div>
            </div>
            <ng-container *ngIf="this.policeReport.getHasReportedIncidentToPolice()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.authorityReport.dateOfReport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(this.policeReport.getDateOfReport())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.authorityReport.reportRefNo' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.policeReport.getPoliceReportNumber()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-container *ngIf="country === 'SGP'">
    <another-insurance-summary></another-insurance-summary>
</ng-container>

<div class="form-group section">
    <label class="section-title">{{'paClaim.others.supportDoc' | translate}}</label>
    <div class="row" *ngFor="let item of this.listOfDocuments;">
        <div class="col col-xs-12 col-lg-12">
            {{item.description | translate}}
        </div>
        <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
            <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                {{ item.uploadedFileName }}
            </div>
        </ng-container>
    </div>
    <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
        <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
        <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
            <div class="col col-xs-12 col-lg-12">
                {{item.description | translate}}
            </div>
            <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                    {{ item.uploadedFileName }}
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>