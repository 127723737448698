<div class="section form-group">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionReroute.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.title' | translate}}</label>            
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.reroutingCountryOccurredDesc' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{travelReRoute.getReroutingCountryOccurredDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.reroutingOriginalSchedule' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(travelReRoute.getReroutingOriginalSchedule())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.reroutingTranspoCancelOrDelay' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnConvertValue(travelReRoute.getReroutingTranspoCancelOrDelay())}}
                </div>
            </div>
            <div class="row" *ngIf="travelReRoute.getReroutingTranspoCancelOrDelay()?.toLowerCase() === 'delayed'">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.reroutingActualDelaySchedule' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(travelReRoute.getReroutingActualDelaySchedule())}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.title2' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.reroutingTakeDiffRoute' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(travelReRoute.getReroutingTakeDiffRoute())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionReroute.subSectionDetail.reroutingTranspoChange' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(travelReRoute.getReroutingTranspoChange())}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>