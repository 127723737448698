import {IntegrationClaimDetailsJson} from "./integration-claim-details.model";

export class ClaimAuthJson {

    private claimToken: string;
    private errorCode: string = "";
    private errorStr: string = "";
    private productType: string;
    private country: string;
    private policyType: string;
    private policyNumber: string;
    private isManulife: boolean;
    private claimNotifNumber: string;
    private isGroupMedical: boolean;
    private authName: string;
    private catalogueCode: string;
    private claimDetailsIntegrationInfolist: IntegrationClaimDetailsJson[] = [];
    private otpToken: string;
    private otpEmail: string;
    private otpStatus: string;

    getClaimToken(): string {
        return this.claimToken;
    }

    setClaimToken(value: string) {
        this.claimToken = value;
    }

    getErrorCode(): string {
        return this.errorCode;
    }

    setErrorCode(value: string) {
        this.errorCode = value;
    }

    getErrorStr(): string {
        return this.errorStr;
    }

    setErrorStr(value: string) {
        this.errorStr = value;
    }

    getProductType(): string {
        return this.productType;
    }

    setProductType(value: string) {
        this.productType = value;
    }

    getCountry(): string {
        return this.country;
    }

    setCountry(value: string) {
        this.country = value;
    }

    getPolicyType(): string {
        return this.policyType;
    }

    setPolicyType(value: string) {
        this.policyType = value;
    }

    getPolicyNumber(): string {
        return this.policyNumber;
    }

    setPolicyNumber(value: string) {
        this.policyNumber = value;
    }

    getIsManulife(): boolean {
        return this.isManulife;
    }

    setIsManulife(value: boolean) {
        this.isManulife = value;
    }

    getClaimDetailsIntegrationInfolist(): IntegrationClaimDetailsJson[] {
        return this.claimDetailsIntegrationInfolist;
    }

    setClaimDetailsIntegrationInfolist(value: IntegrationClaimDetailsJson[]) {
        this.claimDetailsIntegrationInfolist = value;
    }

    getClaimNotifNumber(): string {
		return this.claimNotifNumber;
	}

	setClaimNotifNumber(value: string) {
		this.claimNotifNumber = value;
	}

    getIsGroupMedical(): boolean {
        return this.isGroupMedical;
    }

    setIsGroupMedical(value: boolean) {
        this.isGroupMedical = value;
    }

    getAuthName(): string {
        return this.authName;
    }

    setAuthName(value: string) {
        this.authName = value;
    }

    getCatalogueCode(): string {
        return this.catalogueCode;
    }

    setCatalogueCode(value: string) {
        this.catalogueCode = value;
    }

    getOtpToken(): string {
        return this.otpToken;
    }

    setOtpToken(value: string) {
        this.otpToken = value;
    }

    getOtpEmail(): string {
        return this.otpEmail;
    }

    setOtpEmail(value: string) {
        this.otpEmail = value;
    }

    getOtpStatus(): string {
        return this.otpStatus;
    }

    setOtpStatus(value: string) {
        this.otpStatus = value;
    }

    static jsonConvert(claimAuthJson: ClaimAuthJson): ClaimAuthJson {

        claimAuthJson = Object.assign(new ClaimAuthJson(), claimAuthJson);

        if(claimAuthJson.getClaimDetailsIntegrationInfolist() != null){
            let claimDetailsList: IntegrationClaimDetailsJson[] = [];
            for (const detail of claimAuthJson.getClaimDetailsIntegrationInfolist()) {
                let detailItem = Object.assign(new IntegrationClaimDetailsJson(), detail);
                detailItem = IntegrationClaimDetailsJson.jsonConvert(detailItem);
                claimDetailsList.push(detailItem);
            }
            claimAuthJson.setClaimDetailsIntegrationInfolist(claimDetailsList);
        }
        return claimAuthJson;
    }
}