import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {OtherClaim} from "../../../../model/travel/other-claim.model";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-desc-of-incident',
    templateUrl: './desc-of-incident.component.html',
    styleUrls: ['./desc-of-incident.component.css']
})
export class DescOfIncidentComponent extends ClaimsBaseComponent implements OnInit {

    otherForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    otherClaim: OtherClaim;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private fb: FormBuilder,
                private claimService: ClaimService,
                private sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.otherClaim = this.claim.getClaimTravel().getOtherClaim();
    }

    ngOnInit() {

        this.pushGAViewOtherDescription();
        if(this.otherClaim.getOtherCurrency() == null){
            this.otherClaim.setOtherCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.otherForm = this.fb.group({

                otherIncidentDescription: [this.otherClaim.getOtherIncidentDescription()],
                otherAmount: [this.otherClaim.getOtherAmount()],
                otherCurrency: [this.otherClaim.getOtherCurrency()]
            }
        );
    }

    ngAfterViewInit() {

        this.otherForm.valueChanges.subscribe(data => {

            let claimTravel: ClaimTravel = this.claim.getClaimTravel();
            let otherClaims: OtherClaim = claimTravel.getOtherClaim();

            otherClaims.setOtherIncidentDescription(super.getValueInComponent('otherIncidentDescription'));
            otherClaims.setOtherAmount(super.getValueInComponent('otherAmount'));
            otherClaims.setOtherCurrency(this.otherForm.get('otherCurrency').value);

            this.validateClaimDetailsComplete();
            //console.log("this.otherForm.get('otherCurrency').value" + this.otherForm.get('otherCurrency').value);
        });

    }

    back() {
        this.router.navigate(["/claimform/otherClaim/detailsOfIncident"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 15, subMenuIndex: 1});

            this.router.navigate(["/claimform/otherClaim/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.otherForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 15, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 15, subMenuIndex: 1});
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.otherForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewOtherDescription() {
        (<any>window).dataLayer.push({
            'pageStep': 'Other Claims – Incident Description',
            'vPath': '/claim/other-claims/incident-description',
            'event': 'vpageview',
        });
    }
}
