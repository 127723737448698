<div>
  <div class="prev-bar">
    <a href="javascript:;" (click)="back()" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.deathAndSeriousBodyInjury.medicalBillsAndAmount.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form >
        <div >
          <div>
            <div class="form-group click-more-content" id="q1">
              <qnect-ui #PAT13_NUMBER_OF_TREATMENT_BILL [fieldId]="'PAT13_NUMBER_OF_TREATMENT_BILL'" [formGroup]="medicalBillForm" name="numberOfTreatmentBill"></qnect-ui>
            </div>
            <div class="form-group click-more-content" id="q1">
              <qnect-ui #PAT15_MEDICAL_EXPENSE_TYPE [fieldId]="'PAT15_MEDICAL_EXPENSE_TYPE'" [formGroup]="medicalBillForm" name="medicalExpenseType"></qnect-ui>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()"   class="btn btn-primary new-btn float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i
            class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>