<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="new-btn btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
        <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{'claimSection.rentalVehicle.detailOfIncident.title' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form>
                <div class="form-group">
                    <qnect-ui #RVE1_DATE_OF_INCIDENT [fieldId]="'RVE1_DATE_OF_INCIDENT'" [formGroup]="deTailOfRentalForm" name="dateOfIncident"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #RVE_1A_COUNTRY_INCIDENT_OCCURRED [fieldId]="'RVE_1A_COUNTRY_INCIDENT_OCCURRED'" [formGroup]="deTailOfRentalForm" name="countryIncidentOccured"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #RVE2_DESC_OF_INCIDENT [fieldId]="'RVE2_DESC_OF_INCIDENT'" [formGroup]="deTailOfRentalForm" name="descOfIncident"></qnect-ui>
                </div>
                <div class="form-group click-more-content">
            <qnect-ui #RVE5_RENTAL_EXCESS_PAID [fieldId]="'RVE5_RENTAL_EXCESS_PAID'" [formGroup]="deTailOfRentalForm" name="rentalExcessPaidCost"></qnect-ui>
        </div>
            </form>
        </div>
        <div class="col-xl-3 col-lg-12">
        </div>
    </div>

    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
