<div class="section form-group">
    <label class="section-title" *ngIf="isHomeNotAddress || isOutsideHome">{{'claimDetails.body.claimDetails' | translate}}</label>
    <label class="section-title" *ngIf="!isHomeNotAddress && !isOutsideHome">{{'homeProduct.others.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.descOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{this.otherDetails.getDescIncident()}}
                </div>
            </div>

            <div class="row" *ngIf="this.otherDetails.getEstAmountLoss()">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.estAmtLoss' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.otherDetails.getEstAmountLossCurrency()}}
                    {{this.otherDetails.getEstAmountLoss() | number : '1.2-2'}}
                </div>
            </div>
        </div>
    </div>
</div>