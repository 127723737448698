import {Component, OnInit} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";

@Component({
  selector: 'qnect-ui-textarea',
  templateUrl: './ui-textarea.component.html',
  styleUrls: ['../ui-base.component.scss']
})
export class UiTextareaComponent extends UiBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

}
