<div class="section form-group">
    <label *ngIf="!isPaClaim" class="sub-section">{{'homeProduct.personalAccident.title' | translate}}</label>
    <label *ngIf="isPaClaim" class="section-title">{{'homeProduct.personalAccident.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">

            <div class="row" *ngIf="!isPaClaim">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.personalAccident.anyInjury' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{returnYesOrNo(personalAccident.getHasInjury())}}
                </div>
            </div>
            <ng-container *ngIf="personalAccident.getHasInjury()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.personalAccident.injuredPerson' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{personalAccident.getInjuredPerson()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.personalAccident.descriptionOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{personalAccident.getDescOfIncident()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.personalAccident.nameOfDoctor' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{personalAccident.getDoctorName()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
