import {Injectable} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ClaimTypes } from "../model/claim-type.model";
import { DynamicRouteModel } from "../model/dynamic-route.model";
import { ClaimService } from "./claim.service";
import { SideMenuService } from "./side-menu.service";
import { ClaimHome } from "../model/claim-home.model";
import { UtilitiesService } from "../utilities/utilities.service";

@Injectable()
export class DynamicRoutingService {
    claimHome: ClaimHome;

    constructor(private sideMenuService: SideMenuService, private claimService: ClaimService) {}

    getDynamicHomeRoutes(causeType: string): DynamicRouteModel[] {
        this.claimHome =  this.claimService.getClaim().getClaimHome();

        let routes: DynamicRouteModel[] = [];

        if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WATER]) {
            routes.push(new DynamicRouteModel(0, 0, "/claimform/water/details"));
            routes.push(this.getItemRoute(causeType));
            if(this.claimHome.getHasHPLHHLRisk()){
                routes = this.pushAndEnable(routes, 0, 2, "/claimform/water/thirdPartyLossDetails", causeType);
            }
            routes = this.pushAndEnable(routes, 0, 3, "/claimform/water/responsibility", causeType);
            routes = this.pushAndEnable(routes, 0, 4, "/claimform/water/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER]) {
            routes.push(new DynamicRouteModel(1, 0, "/claimform/weather/details"));
            routes.push(this.getItemRoute(causeType));
            if(this.claimHome.getHasHPLHHLRisk()){
                routes = this.pushAndEnable(routes, 1, 2, "/claimform/weather/thirdPartyLossDetails", causeType);
            }
            routes = this.pushAndEnable(routes, 1, 3, "/claimform/weather/responsibility", causeType);
            routes = this.pushAndEnable(routes, 1, 4, "/claimform/weather/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT]) {
            routes.push(new DynamicRouteModel(2, 0, "/claimform/accidentalDamage/homeItems"));
            if(this.claimHome.getHasHPLHHLRisk()){
                routes = this.pushAndEnable(routes, 2, 1, "/claimform/accidentalDamage/thirdPartyLossDetails", causeType);
            }
            routes = this.pushAndEnable(routes, 2, 2, "/claimform/accidentalDamage/responsibility", causeType);
            routes = this.pushAndEnable(routes, 2, 3, "/claimform/accidentalDamage/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE]) {
            routes.push(new DynamicRouteModel(3, 0, "/claimform/fire/contentsBuilding"));
            routes.push(this.getItemRoute(causeType));
            routes = this.pushAndEnable(routes, 3, 2, "/claimform/fire/authorityReport", causeType);
            if(this.claimHome.getHasHPLHHLRisk()){
                routes = this.pushAndEnable(routes, 3, 3, "/claimform/fire/thirdPartyLossDetails", causeType);
            }
            if(this.claimHome.getHasHPPRisk()){
                routes = this.pushAndEnable(routes, 3, 4, "/claimform/fire/personalAccidentDetails", causeType);   
            }
            routes = this.pushAndEnable(routes, 3, 5, "/claimform/fire/responsibility", causeType);
            routes = this.pushAndEnable(routes, 3, 6, "/claimform/fire/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY]) {
            routes.push(new DynamicRouteModel(4, 0, "/claimform/burglary/contentsBuilding"));
            routes.push(this.getItemRoute(causeType));
            routes = this.pushAndEnable(routes, 4, 2, "/claimform/burglary/authorityReport", causeType);
            if(this.claimHome.getHasHPLHHLRisk()){
                routes = this.pushAndEnable(routes, 4, 3, "/claimform/burglary/thirdPartyLossDetails", causeType);   
            }
            if(this.claimHome.getHasHPPRisk()){
                routes = this.pushAndEnable(routes, 4, 4, "/claimform/burglary/personalAccidentDetails", causeType);   
            }
            routes = this.pushAndEnable(routes, 4, 5, "/claimform/burglary/responsibility", causeType);
            routes = this.pushAndEnable(routes, 4, 6, "/claimform/burglary/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        }  else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_VANDALISM]) {
            routes.push(new DynamicRouteModel(5, 0, "/claimform/vandalism/contentsBuilding"));
            routes.push(this.getItemRoute(causeType));
            routes = this.pushAndEnable(routes, 5, 2, "/claimform/vandalism/authorityReport", causeType);
            if(this.claimHome.getHasHPLHHLRisk()){
                routes = this.pushAndEnable(routes, 5, 3, "/claimform/vandalism/thirdPartyLossDetails", causeType);
            }
            routes = this.pushAndEnable(routes, 5, 4, "/claimform/vandalism/responsibility", causeType);
            routes = this.pushAndEnable(routes, 5, 5, "/claimform/vandalism/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if (causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]) {
            routes = this.pushAndEnable(routes, 8, 0, "/claimform/domesticEmployerClaim/domesticEmployer", causeType);
            
            routes = this.pushAndEnable(routes, 8, 1, "/claimform/domesticEmployerClaim/responsibility", causeType);
            routes = this.pushAndEnable(routes, 8, 2, "/claimform/domesticEmployerClaim/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        }  else if (causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA]) {
            routes = this.pushAndEnable(routes, 9, 0, "/claimform/personalAccidentClaim/personalAccidentDetails", causeType);
            routes = this.pushAndEnable(routes, 9, 1, "/claimform/personalAccidentClaim/responsibility", causeType);
            routes = this.pushAndEnable(routes, 9, 2, "/claimform/personalAccidentClaim/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if (causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_TP_LIABILITY]) {
            routes = this.pushAndEnable(routes, 10, 0, "/claimform/thirdPartyLiabilityClaim/lossDetails", causeType);
            routes = this.pushAndEnable(routes, 10, 1, "/claimform/thirdPartyLiabilityClaim/responsibility", causeType);
            routes = this.pushAndEnable(routes, 10, 2, "/claimform/thirdPartyLiabilityClaim/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if (causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OTHERS]) {
            routes.push(new DynamicRouteModel(11, 0, "/claimform/othersHome/details"));
            if(this.claimHome.getHasHPLHHLRisk()){
                routes = this.pushAndEnable(routes, 11, 1, "/claimform/othersHome/thirdPartyLossDetails", causeType);
            }
            routes = this.pushAndEnable(routes, 11, 2, "/claimform/othersHome/responsibility", causeType);
            routes = this.pushAndEnable(routes, 11, 3, "/claimform/othersHome/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if (causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]) {
            routes.push(new DynamicRouteModel(6, 0, "/claimform/homeLostPersonalItem/detailOfLoss"));
            routes.push(new DynamicRouteModel(6, 1, "/claimform/homeLostPersonalItem/detailsOfLossItems"));
            routes.push(new DynamicRouteModel(6, 2, "/claimform/homeLostPersonalItem/reportTheLoss"));
            routes = this.pushAndEnable(routes, 6, 3, "/claimform/homeLostPersonalItem/responsibility", causeType);
            routes = this.pushAndEnable(routes, 6, 4, "/claimform/homeLostPersonalItem/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        } else if (causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]) {
            routes.push(new DynamicRouteModel(7, 0, "/claimform/homeDamagedPersonalItem/detailsOfDamageItems"));
            routes = this.pushAndEnable(routes, 7, 1, "/claimform/homeDamagedPersonalItem/responsibility", causeType);
            routes = this.pushAndEnable(routes, 7, 2, "/claimform/homeDamagedPersonalItem/supportDoc", causeType);
            routes.push(new DynamicRouteModel(-1, -1, "/claimform/anotherInsurance"));
        }
        return routes;
    }

    getItemRoute(causeType: string): DynamicRouteModel {
        if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WATER]) {
            return new DynamicRouteModel(0, 1, "/claimform/water/homeItems");
        } else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER]) {
            return new DynamicRouteModel(1, 1, "/claimform/weather/homeItems");
        } else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE]) {
            return new DynamicRouteModel(3, 1, "/claimform/fire/homeItems");
        }  else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_VANDALISM]) {
            return new DynamicRouteModel(5, 1, "/claimform/vandalism/homeItems");
        }  else if(causeType==ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY]) {
            return new DynamicRouteModel(4, 1, "/claimform/burglary/homeItems");
        }
        return null;
    }

    updateScreenVisibility(routes: DynamicRouteModel[], show: boolean, causeType: string, routeName: string,  router: Router): DynamicRouteModel[]{
        let isRouteFound = false;
        let routeItem: DynamicRouteModel = this.getItemRoute(causeType);
        if(routes.findIndex(route => route.getRouteUrl().indexOf(routeName) > -1) > -1){
            isRouteFound = true;
        }
        if (show) {
          if(!isRouteFound){
            routes = this.addRouteAfter(routes, this.getItemRoute(causeType), router.url);
          }
        } else {
          if (isRouteFound){
            routes = this.removeRouteEnding(routes, routeName);
          }
        }
        this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: routeItem.getClaimIndex() , subMenuIndex: routeItem.getRouteIndex(), value: causeType});
        return routes;
    }

    pushAndEnable(routes: DynamicRouteModel[], claimIndex: number, routeIndex: number, routeUrl, causeType: string):  DynamicRouteModel[] {
        routes.push(new DynamicRouteModel(claimIndex, routeIndex, routeUrl));
        this.sideMenuService.emitClaimTypeSubMenuShowUsingUrl({claimTypeId: claimIndex, routeIndex: 1, claimType: causeType, routeUrl: routeUrl, show: true});
        return routes;
    }

    remmoveRoute(routes: DynamicRouteModel[], routeToBeRemoved: string): DynamicRouteModel[]{
        if(!UtilitiesService.isNullOrUndefined(routes)) {
            routes = routes.filter(route => route.getRouteUrl() !== routeToBeRemoved);
        }
        return routes;
    }

    removeRouteEnding(routes: DynamicRouteModel[], routeToBeRemoved: string): DynamicRouteModel[]{
        if(!UtilitiesService.isNullOrUndefined(routes)) {
            routes = routes.filter(route => route.getRouteUrl().indexOf(routeToBeRemoved) === -1);
        }
        return routes;
    }

    addRouteAfter(routes: DynamicRouteModel[], routeToBeAdded: DynamicRouteModel, currentRoute: string): DynamicRouteModel[]{
        
        if(!UtilitiesService.isNullOrUndefined(routes)) {
            const index : number = routes.findIndex(route => route.getRouteUrl() === currentRoute);
            routes.splice(index + 1, 0, routeToBeAdded);
        }
        return routes;
    } 
    
    getCurrentRoute(routes: DynamicRouteModel[], currentRoute: string): DynamicRouteModel{
        if(!UtilitiesService.isNullOrUndefined(routes)) {
            const index : number = routes.findIndex(route => route.getRouteUrl() === currentRoute);
            if(index > -1) {
                return routes[index];
            }
        }
        return null;
    }

    getNextRoute(routes: DynamicRouteModel[], currentRoute: string): DynamicRouteModel{
        if(!UtilitiesService.isNullOrUndefined(routes)) {
            const index : number = routes.findIndex(route => route.getRouteUrl() === currentRoute);
            if((index + 1) < routes.length){
                return routes[index+1];
            }
        }
        return null;
    }

    getPreviousRoute(routes: DynamicRouteModel[], currentRoute: string): DynamicRouteModel{
        if(!UtilitiesService.isNullOrUndefined(routes)) {
            const index : number = routes.findIndex(route => route.getRouteUrl() === currentRoute);
            if((index - 1) < 0){
                return new DynamicRouteModel(-1, -1, "/claimform/claimDetail/selectClaim");
            }
            return routes[index-1];
        }
        return null;
    }

    goToPreviousRoute(routes: DynamicRouteModel[], router: Router, activatedRoute: ActivatedRoute) {
        let previousRoute = this.getPreviousRoute(routes, router.url)
       router.navigate([previousRoute.getRouteUrl()], {
          relativeTo: activatedRoute
        });
    }

    goToNextRoute(routes: DynamicRouteModel[], router: Router, activatedRoute: ActivatedRoute) {
        let nextRoute = this.getNextRoute(routes, router.url);
        let currentRoute = this.getCurrentRoute(routes, router.url);
        if(currentRoute.getClaimIndex() > -1){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: currentRoute.getClaimIndex(), subMenuIndex:  currentRoute.getRouteIndex()});
        }
        if(currentRoute.getRouteUrl().indexOf("supportDoc") > -1){
            this.sideMenuService.emitComplete('finishChooseClaim');
        } 
        router.navigate([nextRoute.getRouteUrl()], {
            relativeTo: activatedRoute
        });
    }
    
}


