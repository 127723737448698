<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'homeProduct.liability.thirdPartyLoss.title' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group" *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_TP_LIABILITY') !== -1">
          <label>{{ 'homeProduct.liability.thirdPartyLoss.didIncidentInvolve' | translate}}</label>
        </div>
        <div class="form-group" *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_TP_LIABILITY') === -1">
          <qnect-ui #HOME_LIABILITY_TP_INCIDENT_INVOLVE [fieldId]="'HOME_LIABILITY_TP_INCIDENT_INVOLVE'" [formGroup]="tpLossDetailsForm" name="didIncidentInvolve"></qnect-ui>
        </div>

        <div class="damage-type-section" *ngIf="tpLossDetails.getIsIncidentInvolve() === 'true'">
          <div class="row reasonBox text-center">
              <div class="col-lg-3 col-md-3 col-3">
                  <div class="wspart-wrapper toggleActive " (click)="selectDamageType(damageTypeList[0].damageTypeValue)" [class.active]="damageTypeList[0].selected == true">
                      <span>{{damageTypeList[0].damageTypeName | translate}}</span>
                  </div>
              </div>
              <div class="col-lg-3 col-md-3 col-3">
                  <div class="wspart-wrapper toggleActive" (click)="selectDamageType(damageTypeList[1].damageTypeValue)" [class.active]="damageTypeList[1].selected == true">
                      <span>{{damageTypeList[1].damageTypeName | translate}}</span>
                  </div>
              </div>
              <div class="col-lg-6 col-md-6 col-6">
                &nbsp;
              </div>
          </div>
          <div *ngIf="showErrMsg">
              <small id="error-message">{{'error_messages.required.damagePersonalItem.selectItem' | translate}}</small>
          </div>
        </div>

        <div class="form-group" *ngIf="damageTypeList[0].selected">
          <form [formGroup]="tpLossDetailsForm">
            <div formArrayName="personItem" *ngFor="let item of tpLossDetailsForm.get('personItem')['controls']; let i = index;">
                <div [formGroupName]="i">
                  <div *ngIf="injuredItemsArray && injuredItemsArray != null">
                    <div>
                      <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.injury' | translate }} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeDamageItem('personItem', i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                      <div class="form-group">
                        <qnect-ui [fieldId]="'HOME_INJURED_PERSON_RELATIONSHIP'"
                                  [frmArrayGroup]="tpLossDetailsForm"
                                  [frmArrayName]="'personItem'"
                                  frmArrayIndex="{{i}}"
                                  name="relationship"></qnect-ui>
                      </div>
                      <div class="form-group">
                        <qnect-ui [fieldId]="'MVA_DAMAGE_INJURED_PERSON_NAME'"
                                  [frmArrayGroup]="tpLossDetailsForm"
                                  [frmArrayName]="'personItem'"
                                  frmArrayIndex="{{i}}"
                                  name="injuredPersonName"></qnect-ui>
                      </div>
                      <div class="form-group">
                        <qnect-ui [fieldId]="'MVA_DAMAGE_INJURED_PERSON_AGE'"
                                  [frmArrayGroup]="tpLossDetailsForm"
                                  [frmArrayName]="'personItem'"
                                  frmArrayIndex="{{i}}"
                                  name="ageGroup"></qnect-ui>
                      </div>
                      <div class="form-group">
                        <qnect-ui [fieldId]="'MVA_DAMAGE_INJURED_PERSON_NUMBER'"
                                  [frmArrayGroup]="tpLossDetailsForm"
                                  [frmArrayName]="'personItem'"
                                  frmArrayIndex="{{i}}"
                                  name="injuredPersonNumber"></qnect-ui>
                      </div>
                      <div class="form-group">
                        <qnect-ui [fieldId]="'MV_COLLISSION_EXTENT_OF_INJURY'"
                                  [frmArrayGroup]="tpLossDetailsForm"
                                  [frmArrayName]="'personItem'"
                                  frmArrayIndex="{{i}}"
                                  name="extentOfInjury"></qnect-ui>
                      </div>
                      <div class="form-group">
                        <label>{{ 'homeProduct.liability.pleaseDoNotMake' | translate}}</label>
                      </div>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-12">
                    &nbsp;
                </div>
              </div>
            </div>
          </form>
          <div class="addFile" (click)="addDamageItem('personItem')">
            <em class="fas fa-plus"></em>
            <span>{{'motorClaim.claimSection.motorAccidentCollission.thirdParty.addInjured' | translate}}</span>
        </div>
        </div>

        <div class="form-group" *ngIf="damageTypeList[1].selected">
          <form [formGroup]="tpLossDetailsForm" >
            <div formArrayName="propertyItem" *ngFor="let item of tpLossDetailsForm.get('propertyItem')['controls']; let i = index;">
              <div [formGroupName]="i">
                <div *ngIf="propertyDamageItemsArray && propertyDamageItemsArray != null">
                  <div>
                    <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.property' | translate }} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeDamageItem('propertyItem', i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                    <div class="form-group">
                      <qnect-ui [fieldId]="'MVA_DAMAGE_WHAT_DID_YOU_HIT'"
                                [frmArrayGroup]="tpLossDetailsForm"
                                [frmArrayName]="'propertyItem'"
                                frmArrayIndex="{{i}}"
                                name="whatDidYouHit"></qnect-ui>
                    </div>
                    <div class="form-group">
                      <qnect-ui [fieldId]="'HOME_DAMAGE_PROPERTY_OWNER_NAME'"
                                [frmArrayGroup]="tpLossDetailsForm"
                                [frmArrayName]="'propertyItem'"
                                frmArrayIndex="{{i}}"
                                name="propertyOwnerName"></qnect-ui>
                    </div>
                    <div class="form-group">
                      <qnect-ui [fieldId]="'MVA_DAMAGE_PROPERTY_OWNER_CONTACT_NO'"
                                [frmArrayGroup]="tpLossDetailsForm"
                                [frmArrayName]="'propertyItem'"
                                frmArrayIndex="{{i}}"
                              name="propertyOwnerNo"></qnect-ui>
                    </div>
                    <div class="form-group">
                      <label>{{ 'homeProduct.liability.pleaseDoNotMake' | translate}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-12">
                    &nbsp;
                </div>
              </div>
            </div>
          </form>
          <div class="addFile" (click)="addDamageItem('propertyItem')">
              <em class="fas fa-plus"></em>
              <span>{{'motorClaim.claimSection.motorAccidentCollission.thirdParty.addAnotherProperty' | translate}}</span>
          </div>
          <div class="col-lg-6 col-md-6 col-6">
            &nbsp;
          </div>
        </div>

        <div *ngIf="tpLossDetails.getIsIncidentInvolve() === 'Unsure'">
          <div class="form-group">
            <qnect-ui #HOME_LIABILITY_TP_INCIDENT_DESC [fieldId]="'HOME_LIABILITY_TP_INCIDENT_DESC'" [formGroup]="tpLossDetailsForm" name="incidentDescription"></qnect-ui>
          </div>
          <div class="form-group">
            <label>{{ 'homeProduct.liability.becomeAware' | translate}}</label>
          </div>
          <div class="form-group">
            <label>{{ 'homeProduct.liability.pleaseDoNotMake' | translate}}</label>
          </div>
        </div>

        <div class="form-group">
          <qnect-ui #HOME_LIABILITY_TP_CLAIM [fieldId]="'HOME_LIABILITY_TP_CLAIM'" [formGroup]="tpLossDetailsForm" name="hasMadeAnyClaim"></qnect-ui>
        </div>
        <div *ngIf="tpLegalDetails.getHasAnyClaimMade()">
          <div class="form-group">
            <qnect-ui #HOME_LIABILITY_TP_LEGAL_OTHER_INSURER_NAME [fieldId]="'HOME_LIABILITY_TP_LEGAL_OTHER_INSURER_NAME'" [formGroup]="tpLossDetailsForm" name="otherInsurerName"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #HOME_LIABILITY_TP_LEGAL_CONTACT_NUMBER [fieldId]="'HOME_LIABILITY_TP_LEGAL_CONTACT_NUMBER'" [formGroup]="tpLossDetailsForm" name="contactNumber"></qnect-ui>
          </div>
          <div class="form-group" *ngIf="tpLossDetails.getIsIncidentInvolve() !== 'Unsure'">
            <label>{{ 'homeProduct.liability.pleaseDoNotMake' | translate}}</label>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
