import {AfterViewInit, Directive, DoCheck, Input} from '@angular/core';
import {UiComponent} from '../ui.component';
import {Subject, takeUntil} from "rxjs";

@Directive({selector: '[qnectToggleEnableOnParentValueChange]'})
export class ToggleEnableOnParentValueChangeDirective implements AfterViewInit, DoCheck {


  constructor(private uiComponent: UiComponent) {
  }

  @Input() qnectToggleEnableOnParentValueChange: UiComponent;
  @Input() enableValues: string[] = [];
  @Input() skip: boolean;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.toggleEnable();

    this.qnectToggleEnableOnParentValueChange
        .valueChanges()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.toggleEnable();
        });

  }

  ngDoCheck() {
  }

  private toggleEnable() {
    if (!this.skip) {
      let value = this.qnectToggleEnableOnParentValueChange.getValue();

      if (!value || value == '') {
        value = ' ';
      }

      if (this.enableValues.indexOf(value) > -1) {
        this.uiComponent.enable();
      } else {
        this.uiComponent.disable();
      }
    }
  }
}
