<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
        <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{'motorClaim.claimSection.motorAccidentCollission.thirdParty.title' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <div class="form-group">
                <qnect-ui #MV_COLLISSION_ACCIDENT_INVOLVE [fieldId]="'MV_COLLISSION_ACCIDENT_INVOLVE'" [formGroup]="damageForm" name="hasAccidentInvolve"></qnect-ui>
            </div>
            <div *ngIf="collision.getHasAccidentInvolve()">
                <div class="damage-type-section">
                    <div class="row reasonBox text-center">
                        <div class="col-lg-4 col-md-4 col-4">
                            <div class="wspart-wrapper  toggleActive " (click)="selectDamageType(damageTypeList[0].damageTypeValue)" [class.active]="damageTypeList[0].selected == true">
                                <span>{{damageTypeList[0].damageTypeName | translate}}</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-4">
                            <div class="wspart-wrapper  toggleActive" (click)="selectDamageType(damageTypeList[1].damageTypeValue)" [class.active]="damageTypeList[1].selected == true">
                                <span>{{damageTypeList[1].damageTypeName | translate}}</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-4">
                            <div class="wspart-wrapper  toggleActive" (click)="selectDamageType(damageTypeList[2].damageTypeValue)" [class.active]="damageTypeList[2].selected == true">
                                <span>{{damageTypeList[2].damageTypeName | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="collision.getHasSelectedDamageType() != null && !collision.getHasSelectedDamageType()">
                        <small id="error-message">{{'error_messages.required.damagePersonalItem.selectItem' | translate}}</small>
                    </div>
                </div>
                <div class="form-group" *ngIf="damageTypeList[0].selected">
                    <form [formGroup]="damageForm">
                        <div formArrayName="vehicleItem" *ngFor="let item of damageForm.get('vehicleItem')['controls']; let i = index;">
                            <div [formGroupName]="i">
                                <div *ngIf="vehicleDamageItemsArray && vehicleDamageItemsArray != null">
                                    <div>
                                        <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.vehicle' | translate }} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeDamageItem('vehicleItem', i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_VEHICLE_REGISTRATION_NO'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'vehicleItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="vehicleRegNo"></qnect-ui>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_VEHICLE_OWNERS_NAME'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'vehicleItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="vehicleOwnerName"></qnect-ui>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_VEHICLE_OWNERS_CONTACT_NO'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'vehicleItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="vehicleOwnerNumber"></qnect-ui>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_OTHER_VEHICLE_INSURED'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'vehicleItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="otherVehicleInsured"></qnect-ui>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-xl-2 col-lg-12">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="addFile" (click)="addDamageItem('vehicleItem')">
                        <i class="fas fa-plus"></i>
                        <span>{{'motorClaim.claimSection.motorAccidentCollission.thirdParty.addAnotherVehicle' | translate}}</span>
                    </div>
                </div>
                <div class="form-group" *ngIf="damageTypeList[1].selected">
                    <form [formGroup]="damageForm">
                        <div formArrayName="personItem" *ngFor="let item of damageForm.get('personItem')['controls']; let i = index;">
                            <div [formGroupName]="i">
                                <div *ngIf="injuredItemsArray && injuredItemsArray != null">
                                    <div>
                                        <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.injury' | translate }} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeDamageItem('personItem', i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_INJURED_PERSON_RELATIONSHIP'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'personItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="relationship"></qnect-ui>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_INJURED_PERSON_NAME'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'personItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="injuredPersonName"></qnect-ui>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_INJURED_PERSON_AGE'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'personItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="ageGroup"></qnect-ui>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MVA_DAMAGE_INJURED_PERSON_NUMBER'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'personItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="injuredPersonNumber"></qnect-ui>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui [fieldId]="'MV_COLLISSION_EXTENT_OF_INJURY'"
                                                      [frmArrayGroup]="damageForm"
                                                      [frmArrayName]="'personItem'"
                                                      frmArrayIndex="{{i}}"
                                                      name="extentOfInjury"></qnect-ui>
                                        </div>
                                    </div>


                                </div>
                                <div class="col-xl-2 col-lg-12">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="addFile" (click)="addDamageItem('personItem')">
                        <i class="fas fa-plus"></i>
                        <span>{{'motorClaim.claimSection.motorAccidentCollission.thirdParty.addInjured' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="form-group"  *ngIf="damageTypeList[2].selected">
                <form [formGroup]="damageForm">
                    <div formArrayName="propertyItem" *ngFor="let item of damageForm.get('propertyItem')['controls']; let i = index;">
                        <div [formGroupName]="i">
                            <div *ngIf="propertyDamageItemsArray && propertyDamageItemsArray != null">
                                <div>
                                    <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.property' | translate }} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeDamageItem('propertyItem', i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                                    <div class="form-group">
                                        <qnect-ui [fieldId]="'MVA_DAMAGE_WHAT_DID_YOU_HIT'"
                                                  [frmArrayGroup]="damageForm"
                                                  [frmArrayName]="'propertyItem'"
                                                  frmArrayIndex="{{i}}"
                                                  name="whatDidYouHit"></qnect-ui>
                                    </div>
                                    <div class="form-group">
                                        <qnect-ui [fieldId]="'MVA_DAMAGE_PROPERTY_OWNER_NAME'"
                                                  [frmArrayGroup]="damageForm"
                                                  [frmArrayName]="'propertyItem'"
                                                  frmArrayIndex="{{i}}"
                                                  name="propertyOwnerName"></qnect-ui>
                                    </div>
                                    <div class="form-group">
                                        <qnect-ui [fieldId]="'MVA_DAMAGE_PROPERTY_OWNER_CONTACT_NO'"
                                                  [frmArrayGroup]="damageForm"
                                                  [frmArrayName]="'propertyItem'"
                                                  frmArrayIndex="{{i}}"
                                                  name="propertyOwnerNo"></qnect-ui>
                                    </div>
                                </div>


                            </div>
                            <div class="col-xl-2 col-lg-12">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </form>

                <div class="addFile" (click)="addDamageItem('propertyItem')">
                    <i class="fas fa-plus"></i>
                    <span>{{'motorClaim.claimSection.motorAccidentCollission.thirdParty.addAnotherProperty' | translate}}</span>
                </div>
            </div>
            <BR>
            <div *ngIf="collision.getHasAccidentInvolve() && collision.getDetailsOfDamagedType().length > 0" class="form-group">
                <qnect-ui #MV_COLLISSION_PAIDORRECEIVED [fieldId]="'MV_COLLISSION_PAIDORRECEIVED'" [formGroup]="damageForm" name="hasPaidOrReceived"></qnect-ui>
            </div>
        </div>
        <div class="col-xl-3 col-lg-12">
            &nbsp;
        </div>
    </div>

    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>