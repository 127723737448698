import { AfterViewInit, Directive, DoCheck, Input } from '@angular/core';
import { UiComponent } from '../ui.component';

@Directive({selector: '[qnectClearValueOnHide]'})
export class ClearValueOnHideDirective implements AfterViewInit, DoCheck {


  constructor(private uiComponent: UiComponent) {}

  ngAfterViewInit() {
    this.clearValue();
  }

  ngDoCheck() {
    this.clearValue();
  }

  private clearValue() {
    if (this.uiComponent.isHidden()) {
      this.uiComponent.setValue(null);
    }
  }
}
