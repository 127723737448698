<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfIllnessInjury.detailsOfTheInjury' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfIllnessInjury.countryWhereTheInjuryOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{clinicalExpenses.getCountryOccurredDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfIllnessInjury.natureOfTheInjury' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{clinicalExpenses.getNatureOfIllnessDesc()}}
                </div>
            </div>
            <div *ngIf="clinicalExpenses.getNatureOfIllness() == 'Injury'" class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfIllnessInjury.workInjury' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNoBoolean(clinicalExpenses.getDidHelperSustainInjury())}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.title' | translate}}</label>
            <div class="row">
                    <div class="col col-xs-12 col-lg-7" *ngIf="this.clinicalExpenses.getNatureOfIllness() == 'Sickness'">
                        <p>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.isConditionRelatedToTheFollowing' | translate}}</p>
                        <ul class="list-width">
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.pregnancy' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.nervousOrMentalDisease' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.restCureOrPhyCheckups' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.preventiveMedication' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.alcoholIntoxicationOrDrugs' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.aidsOrArc' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.anyPreexistingSickness' | translate}}</li>
                            <li *ngIf="this.domesticInfoPlan.cancerHeartDiseaseExtensionApplicability == 'No'">{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.cancerHeartDisease' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col col-xs-12 col-lg-5 content" *ngIf="this.clinicalExpenses.getNatureOfIllness() == 'Sickness'">
                        {{clinicalExpenses.getHasRelatedConditionsForSicknessDesc()}}
                    </div>
                    <div class="col col-xs-12 col-lg-7" *ngIf="this.clinicalExpenses.getNatureOfIllness() == 'Injury'">
                        <p>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.isConditionRelatedToTheFollowing' | translate}}</p>
                        <ul class="list-width">
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.injuryOutsideOfRestDays' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.selfInflicted' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.dentalTreatment' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.alcoholIntoxicationOrDrugs' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.illegalActivities' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.driving' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.underwaterActivities' | translate}}</li>
                            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.cosmetics' | translate}}</li>
                        </ul>
                    </div>
                    <div class="col col-xs-12 col-lg-5 content" *ngIf="this.clinicalExpenses.getNatureOfIllness() == 'Injury'">
                        {{returnYesOrNo(clinicalExpenses.getHasRelatedConditionsForInjury())}}
                    </div>
            </div>
            <div class="row" *ngIf="this.clinicalExpenses.getHasRelatedConditionsForSickness() == true">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessRelatedCondition' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{this.clinicalExpenses.getSicknessRelatedCondition()}}
                </div>
            </div>
            <div class="row" *ngIf="this.clinicalExpenses.getHasRelatedConditionsForInjury() == true">
                <div class="col col-xs-12 col-lg-7" *ngIf="this.clinicalExpenses.getHasRelatedConditionsForInjury() == true ">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryRelatedCondition' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{this.clinicalExpenses.getInjuryRelatedCondition()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.typeOfIllnessSuffered' | translate}}
                </div>
                <div *ngIf="clinicalExpenses.getTypeOfIllnessOrInjury() != 'Others'" class="col col-xs-12 col-lg-5 content">
                    {{clinicalExpenses.getTypeOfIllnessOrInjuryDesc()}}
                </div>
                <div *ngIf="clinicalExpenses.getTypeOfIllnessOrInjury() == 'Others'" class="col col-xs-12 col-lg-5-word-break content">
                    {{clinicalExpenses.getOtherTypeOfIllnessOrInjury()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.dateOfVisit' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(clinicalExpenses.getDateOfVisit())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.typeOfTreatment' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{clinicalExpenses.getTypeOfClinicalExpensesDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.totalClinicalExpense' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{clinicalExpenses.getTotalAmountOfExpensesCurrency()}}
                    {{clinicalExpenses.getTotalAmountOfExpensesValue() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>