import { AfterViewInit, Directive, Input } from '@angular/core';
import { UiComponent } from '../ui.component';

@Directive({selector: '[qnectParentPercentDefaulter]'})
export class ParentPercentDefaulterDirective implements AfterViewInit {


  constructor(private uiComponent: UiComponent) {}

  @Input() qnectParentPercentDefaulter: UiComponent[] = [];
  @Input() percentDefault: number = 0;

  ngAfterViewInit() {
    this.updateDefaultValue();
    for (const parentUi of this.qnectParentPercentDefaulter) {
      parentUi.valueChanges().subscribe(() => {
        this.updateDefaultValue();
      });
    }
  }

  private updateDefaultValue() {
    let totalValue = 0;
    for (const parentUi of this.qnectParentPercentDefaulter) {
      if (parentUi.getValue()) {
        totalValue += isNaN(Number(parentUi.getValue())) ? 0 : Number(parentUi.getValue());
      }
    }
    const defaultValue = (this.percentDefault / 100) * (totalValue);
    this.uiComponent.setDefaultValue(String(defaultValue));
    if (defaultValue > 0 || !this.uiComponent.getValue()) {
      this.uiComponent.setValue(String(Math.trunc(defaultValue)));
    }
  }
}

