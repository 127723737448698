import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bankFilter'
})
export class BankFilterPipe implements PipeTransform {

  transform(list: any[], field: string, keyword: string): any {

    if (!field || !keyword) {
      return list;
    }

    return list.filter(item => {
      // let itemFieldValue = item[field].toUpperCase();
        let itemFieldValue = item[field];
      return itemFieldValue.indexOf(keyword) >= 0;
    });

  }

}
