<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
      <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
      <p>{{ 'motorClaim.claimSection.windscreen.detailsOfDamage.description' | translate }}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
                <qnect-ui #MVA_WS_DATE_OF_DMG [fieldId]="'MVA_WS_DATE_OF_DMG'" [formGroup]="detailForm" name="dateOfAccident"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #MVA_WS_DMG_CAUSE [fieldId]="'MVA_WS_DMG_CAUSE'" [formGroup]="detailForm" name="causeOfDamage"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="windscreen.getCauseOfDamage()?.toLowerCase() == 'others'">
                <qnect-ui #MVA_WS_OTHER_DMG_CAUSE [fieldId]="'MVA_WS_OTHER_DMG_CAUSE'" [formGroup]="detailForm" name="otherCauseOfDamage"></qnect-ui>
            </div>

            <div class="form-group">
                <qnect-ui #MVA_WS_COUNTRY [fieldId]="'MVA_WS_COUNTRY'" [formGroup]="detailForm" name="countryOccured"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #MVA_WS_LOCATION_MAP [fieldId]="'MVA_WS_LOCATION_MAP'" [formGroup]="detailForm" name="placeOfIncident"></qnect-ui>
            </div>
        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

