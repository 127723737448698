export class PolicyVerifyForm {

  private country: string;
  private policyNumber: string;
  private name: string;
  private dateOfBirth: string;
  private idNumber: string;
  private dateOfLoss: string;
  private policyType: string;
  private loggingId: string;
  private isCoverNotePreNumbered: string;
  private claimNumber: string;
  private autoSaveId: string;
  private isRepairer: boolean;
  private origin: string;

  getCountry(): string {
    return this.country;
  }

  setCountry(value: string) {
    this.country = value;
  }

  getPolicyNumber(): string {
    return this.policyNumber;
  }

  setPolicyNumber(value: string) {
    this.policyNumber = value;
  }

  getName(): string {
    return this.name;
  }

  setName(value: string) {
    this.name = value;
  }

  getDateOfBirth(): string {
    return this.dateOfBirth;
  }

  setDateOfBirth(value: string) {
    this.dateOfBirth = value;
  }

  getIdNumber(): string {
    return this.idNumber;
  }

  setIdNumber(value: string) {
    this.idNumber = value;
  }

  getDateOfLoss(): string {
    return this.dateOfLoss;
  }

  setDateOfLoss(value: string) {
    this.dateOfLoss = value;
  }

  getPolicyType(): string {
    return this.policyType;
  }

  setPolicyType(value: string) {
    this.policyType = value;
  }

  getLoggingId(): string {
    return this.loggingId;
  }

  setLoggingId(value: string){
    this.loggingId = value;
  }

  getIsCoverNotePreNumbered(): string {
    return this.isCoverNotePreNumbered;
  }

  setIsCoverNotePreNumbered(value: string){
    this.isCoverNotePreNumbered = value;
  }

  getClaimNumber(): string {
    return this.claimNumber;
  }

  setClaimNumber(value: string){
    this.claimNumber = value;
  }

  getAutoSaveId(): string {
    return this.autoSaveId;
  }

  setAutoSaveId(value: string){
    this.autoSaveId = value;
  }

  getIsRepairer(): boolean {
    return this.isRepairer;
  }

  setIsRepairer(value: boolean){
    this.isRepairer = value;
  }

  getOrigin(): string {
    return this.origin;
  }

  setOrigin(value: string){
    this.origin = value;
  }
}
