import {Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DeathAndSeriousBodyInjuryLoss} from "../../../../model/travel/death-and-serious-body-injury-loss";
import {takeUntil} from "rxjs";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PLAN_TYPES} from '../../../../model/travel/plan-types.constants';

@Component({
    selector: 'app-detail-of-accident',
    templateUrl: './detail-of-accident.component.html',
    styleUrls: ['./detail-of-accident.component.css']
})
export class DetailOfAccidentComponent extends ClaimsBaseComponent implements OnInit {

    deTailOfAccidentForm: FormGroup;
    showTotalBox = false;
    deathAndSeriousBodyInjuryLoss: DeathAndSeriousBodyInjuryLoss;
    @ViewChild('not_covered_modal', {static: true}) notCoveredModal: TemplateRef<any>;
    notCoveredModalRef: any;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private injector : Injector) {

        super(injector);
        this.deathAndSeriousBodyInjuryLoss = this.claim.getClaimTravel().getDeathAndSeriousBodyInjuryLoss();
        if(this.deathAndSeriousBodyInjuryLoss.getDateTheAccidentOccurred() == null){
            this.deathAndSeriousBodyInjuryLoss.setDateTheAccidentOccurred(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {

        this.pushGAViewAccidentDetails();

        this.deTailOfAccidentForm = this.fb.group(
            {
                dateTheAccidentOccurred: [this.deathAndSeriousBodyInjuryLoss.getDateTheAccidentOccurred()],
                natureOfIllness: [this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness()],
                wasMugging: [super.getBooleanString(this.deathAndSeriousBodyInjuryLoss.getWasMugging())],
                otherTypeDesc: [this.deathAndSeriousBodyInjuryLoss.getOtherTypeDesc()],
                countryOfLoss: [this.deathAndSeriousBodyInjuryLoss.getCountryOfLoss()],
                placeOfLoss: [this.deathAndSeriousBodyInjuryLoss.getPlaceOfLoss()]
            }
        );

    }

    ngAfterViewInit() {
        this.deTailOfAccidentForm.valueChanges.subscribe(data => {
            this.deathAndSeriousBodyInjuryLoss.setDateTheAccidentOccurred(super.getValueInComponent('dateTheAccidentOccurred'));
            this.deathAndSeriousBodyInjuryLoss.setNatureOfIllness(super.getValueInComponent('natureOfIllness'));
            this.deathAndSeriousBodyInjuryLoss.setNatureOfIllnessDesc(super.getTextInComponent('natureOfIllness'));
            if (this.deTailOfAccidentForm.get('wasMugging').value != null)  {
                this.deathAndSeriousBodyInjuryLoss.setWasMugging(this.deTailOfAccidentForm.get('wasMugging').value == 'true');
            }
            this.deathAndSeriousBodyInjuryLoss.setOtherTypeDesc(super.getValueInComponent('otherTypeDesc'));
            this.deathAndSeriousBodyInjuryLoss.setCountryOfLoss(this.deTailOfAccidentForm.get('countryOfLoss').value);
            this.deathAndSeriousBodyInjuryLoss.setCountryOfLossDesc(this.getTextInComponent('countryOfLoss'));
            this.deathAndSeriousBodyInjuryLoss.setPlaceOfLoss(super.getValueInComponent('placeOfLoss'));
            this.deathAndSeriousBodyInjuryLoss.setPlaceOfLossDesc(super.getTextInComponent('placeOfLoss'));

            this.validateClaimDetailsComplete();
        });

        this.deTailOfAccidentForm.get('natureOfIllness').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowOthers(data);
            this.showNotCoveredPopUp(data);
            this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 13, subMenuIndex: 0 , value: data});
        });
    }

    hideShowOthers(natureOfIllness) {
        if (natureOfIllness && natureOfIllness.toLowerCase() === 'others') {
            this.deTailOfAccidentForm.get('otherTypeDesc').enable({onlySelf: false, emitEvent: false});
        } else {
            this.deTailOfAccidentForm.get('otherTypeDesc').reset();
            this.deTailOfAccidentForm.get('otherTypeDesc').disable({onlySelf: false, emitEvent: false});
        }
    }

    showNotCoveredPopUp(natureOfIllness) {
        if (this.claim.getClaimTravel().getGBAPlanType() === PLAN_TYPES.BASIC &&
            natureOfIllness?.toLowerCase() === 'major burns (second or third degree)') {
            this.notCoveredModalRef = this.injector.get(NgbModal).open(this.notCoveredModal, {size: 'md',
                backdrop: 'static',
                keyboard: false
            });
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 0});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/personalAccident/detailOfOversea"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfAccidentForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfAccidentForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 13, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 13, subMenuIndex: 0});
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail"], {
            relativeTo: this.activatedRoute
        });
    }

    //Google Analytics
    pushGAViewAccidentDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Serious body injury & Death – Accident Details',
            'vPath': '/claim/serious-injury-death/accident-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}
