import {Component, Injector, OnInit} from "@angular/core";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {DamagePersonalItem} from "src/app/model/travel/damage-personal-item.model";
import { ClaimTypes } from "../../../../model/claim-type.model";
import {DepreciationService} from '../../../../services/depreciation.service';
import * as moment from "moment";
import { UtilitiesService } from "src/app/utilities/utilities.service";
@Component({
  selector: 'app-summary-damaged-personal-items',
  templateUrl: './summary-damaged-personal-items.component.html',
  styleUrls: ['./summary-damaged-personal-items.component.css']
})
export class SummaryDamagedPersonalItemsComponent extends ClaimsBaseComponent implements OnInit {
    
    claim: Claim;
    damagePersonalItem: DamagePersonalItem;

    constructor(private claimService: ClaimService,
                private depreciationService: DepreciationService,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.damagePersonalItem = this.claim.getClaimHome().getHomeDetails().getDamagePersonalItem();
    }

    ngOnInit() {
        if(this.claim.getCountry() === 'HKG' || this.claim.getCountry() === 'HGI'){
            this.getDepreciationRateForItems();
        }
    }

    getDepreciationRateForItems() {
        let damageItemsList = this.damagePersonalItem.getDetailsOfDamageItemsArray();
        let dateOfDamage = moment(this.claim.getClaimHome().getDateOfIncident(), 'DD/MM/YYYY');
        let country = this.claim.getCountry();

        if (!UtilitiesService.isNullOrUndefined(dateOfDamage) && damageItemsList.length > 0) {
            for (var i = 0; i < damageItemsList.length; i++) {
                let purchasedDate = damageItemsList[i].getPurchaseDate();
                let item =  damageItemsList[i].getLossType();
                let depreciationRate = "NA";

                if (!UtilitiesService.isNullOrUndefined(purchasedDate) && !UtilitiesService.isNullOrUndefined(damageItemsList[i].getPurchasePrice()) &&
                    damageItemsList[i].getPurchasePrice() > 0 && this.isNotNullOrUndefinedStr(item)) {

                    let startDate = moment(purchasedDate, 'DD/MM/YYYY');
                    let diffInYears = dateOfDamage.diff(startDate, 'year');
                    startDate.add(diffInYears, 'years');
                    let diffInMonths = dateOfDamage.diff(startDate, 'months');
                    startDate.add(diffInMonths, 'months');
                    let diffInDays = dateOfDamage.diff(startDate, 'days');
                    let totalMonths = diffInMonths + (12 * diffInYears);
                    let totalDays = diffInDays;

                    // special case for item hgi and specific agent codes
                    if (this.isNotNullOrUndefinedStr(country) && country.toLowerCase() == "hgi") {
                        let agentCode = this.claim.getAgentCode();
                        let isApplicableToPlan: boolean = this.depreciationService.isApplicableToQgiPlan(this.claim.getCatalogueCode(), this.claim.getPlanCode(),
                            this.claim.getClaimTravel().getAreaCode(), this.claim.getClaimTravel().getPeriodOfInsurance());
                        if (['011RP091','011RP092', '011RP093'].indexOf(agentCode) !== -1 && isApplicableToPlan) {
                            depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG], item);
                            if (item.toLowerCase() == "mobile phone") {
                                damageItemsList[i].setExcessDepreciation(20); // Apply 20% excess on mobile phones
                            }
                        } else if (!(totalMonths <= 24)) {
                            depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG], item);
                        }
                    } else {
                        depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG], item);
                    }
                }
                damageItemsList[i].setDepreciationRate(depreciationRate);
            }
        }
    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if(!value){
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}
