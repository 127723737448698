<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.tripCurtailment.lossCausedByCurtailment.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">

      <form>
        <div class="form-group">
          <qnect-ui #TRAVEL_CURTAILMENT5 [fieldId]="'TRAVEL_CURTAILMENT5'" [formGroup]="lossCausedForm" name="didUseMiles"></qnect-ui>
        </div>
        <div >
          <div class="form-group click-more-content">
            <qnect-ui #TRAVEL_CURTAILMENT6 [fieldId]="'TRAVEL_CURTAILMENT6'" [formGroup]="lossCausedForm" name="totalCost"></qnect-ui>
          </div>
        </div>
        <div >
          <div class="form-group">
            <qnect-ui #TRAVEL_CURTAILMENT7 [fieldId]="'TRAVEL_CURTAILMENT7'" [formGroup]="lossCausedForm" name="hasRefund"></qnect-ui>
          </div>
          <div class="form-group click-more-content" id="q2" [hidden]="this.tripCurtailment.getHasRefund()!='true'">
            <qnect-ui #TRAVEL_CURTAILMENT8 [fieldId]="'TRAVEL_CURTAILMENT8'" [formGroup]="lossCausedForm" name="totalAmountRefunded"></qnect-ui>

          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <a  (click)="goToNext()"  href="javascript:;" class="new-btn btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></a>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
