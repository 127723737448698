import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Subject} from "rxjs";

@Component({
    selector: 'errorpage',
    templateUrl: './errorpage.component.html',
    styleUrls: ['./errorpage.component.css']
})
export class ErrorPageComponent implements OnInit  {
    
    routeParams;
    errorInfo;
    entryUrl: string = "https://claims.qbe.com/";
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.routeParams = this.activatedRoute.snapshot.queryParams;
        this.errorInfo = this.activatedRoute.snapshot.data;
    }

    geteClaimsUrl(country: string) {
        return this.entryUrl + country;
    }
}
