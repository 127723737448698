import {Component, Input, OnChanges, OnDestroy, OnInit} from "@angular/core";
import {UiValidators} from "../../validators/ui-validator";
import {Subject} from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
    selector: 'ui-error',
    templateUrl: './ui-error.component.html',
    styleUrls: ['./ui-error.component.scss']
})
export class UiErrorComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    inputErrors: any;
    @Input("dictionary")
    _dictionary = {}
    errorMessages: any[];

    protected ngUnsubscribe: Subject<void> = new Subject<void>();


    constructor(private translateService: TranslateService) {
    }

    ngOnInit() {
    }


    ngOnChanges(changes: any): void {
        var errors: any = changes.inputErrors.currentValue;
        this.errorMessages = UiValidators.getValidatorErrors(errors);
        this.getAllErrorCodes();
    }

    getAllErrorCodes() {

        if (this.errorMessages) {
            for (let error of this.errorMessages) {

                // For original text we need the english error.
                if (this._dictionary) {
                    let dictionaryEntry = this._dictionary[error.code];
                    if (dictionaryEntry) {
                        error.message = dictionaryEntry["en"];
                    }
                }
            }
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isHtml(message: string): boolean {
        if(!UtilitiesService.isNullOrUndefined(message)){
            var re = new RegExp("<([A-Za-z][A-Za-z0-9]*)\\b[^>]*>(.*?)<\\/\\1>");
            this.translateService.stream(message).subscribe((res:string) => {
            message = res;
        });
        if (re.test(message)) {
            return true;
            }
        }
        return false; 
    }

}
