import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {AutoComplete} from './components/auto-complete/auto-complete';
import {AutoCompleteComponent} from './components/auto-complete/auto-complete.component';
import {AutoCompleteDirective} from './components/auto-complete/auto-complete.directive';
import {DatePickerComponent} from './components/datepicker/datepicker.component';
import {DatepickerModule} from './components/datepicker/datepicker.module';
import {DynamicTableComponent} from './components/dynamic-table/dynamic-table.component';
import {TimepickerComponent} from './components/timepicker/timepicker.component';

import {ModalDialogComponent} from './components/modal-dialog/modal-dialog.component';
import {DeclineNotificationComponent} from './components/notification-messages/decline-notification.component';
import {ErrorNotificationComponent} from './components/notification-messages/error-notification.component';
import {FileErrorNotificationComponent} from './components/notification-messages/file-error-notification.component';
import {InfoNotificationComponent} from './components/notification-messages/info-notification.component';
import {NotificationMessagesComponent} from './components/notification-messages/notification-messages.component';
import {WarningNotificationComponent} from './components/notification-messages/warning-notification.component';
import {SelectableComponent} from './components/selectable/selectable.component';
import {UtilitiesTestComponent} from './components/utilities-test.component';
import {CurrencyInputFormatterDirective} from './directives/currency-input-formatter.directive';
import {UpperCaseTextDirective} from './directives/uppercase-text.directive';
import {DynamicFormButtonsComponent} from './components/dynamic-table/dynamic-form-buttons.component';
import {ViewportOffsetDirective} from './directives/viewport-offset.drective';
import {Logger} from "./service/logger/logger";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    ModalDialogComponent,
    UtilitiesTestComponent,
    AutoCompleteComponent,
    AutoCompleteDirective,
    SelectableComponent,
    NotificationMessagesComponent,
    ErrorNotificationComponent,
    FileErrorNotificationComponent,
    WarningNotificationComponent,
    InfoNotificationComponent,
    DeclineNotificationComponent,
    CurrencyInputFormatterDirective,
    UpperCaseTextDirective,
    ViewportOffsetDirective,
    DynamicTableComponent,
    TimepickerComponent,
    DynamicFormButtonsComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    DatepickerModule.forRoot(),
    TranslateModule
  ],
  exports: [
    CommonModule,
    AutoCompleteComponent,
    AutoCompleteDirective,
    ModalDialogComponent,
    UtilitiesTestComponent,
    SelectableComponent,
    NotificationMessagesComponent,
    ErrorNotificationComponent,
    FileErrorNotificationComponent,
    WarningNotificationComponent,
    InfoNotificationComponent,
    DeclineNotificationComponent,
    DatePickerComponent,
    TimepickerComponent,
    CurrencyInputFormatterDirective,
    UpperCaseTextDirective,
    ViewportOffsetDirective,
    DynamicTableComponent,
    DynamicFormButtonsComponent
  ],
  providers: [AutoComplete, Logger],
  entryComponents: [AutoCompleteComponent]
})
export class UtilitiesModule {
}
