<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.damagePersonalItem.reportTheDamage.title' | translate}}</p>
  </div>
  <div class="col-12">
    <div class="col-lg-9 col-xl-8 col-md-12" style="padding-left: 0" >
      <form>
        <div class="form-group">

          <qnect-ui #DAMAGE_PERS_ITEM_05 [fieldId]="'DAMAGE_PERS_ITEM_05'" [formGroup]="reportTheLossForm" name="hasAnotherAuthority"></qnect-ui>

        </div>
        <div class="click-more-content" id="q1" *ngIf="this.damagePersonalItem.getHasAnotherAuthority()">
          <div class="form-group click-more-content" id="q1">

            <qnect-ui #DAMAGE_PERS_ITEM_06 [fieldId]="'DAMAGE_PERS_ITEM_06'" [formGroup]="reportTheLossForm" name="authorityType"></qnect-ui>

            <div *ngIf="this.damagePersonalItem.getAuthorityType() == 'Others'">
              <qnect-ui #DAMAGE_PERS_ITEM_07 [fieldId]="'DAMAGE_PERS_ITEM_07'" [formGroup]="reportTheLossForm" name="otherAuthorityType"></qnect-ui>
            </div>

          </div>
        </div>

        <div class="form-group">
            <qnect-ui #DAMAGE_PERS_ITEM_08 [fieldId]="'DAMAGE_PERS_ITEM_08'" [formGroup]="reportTheLossForm" name="hasCompensationReceived"></qnect-ui>
        </div>

        <div class="form-group click-more-content" id="q1" *ngIf="this.damagePersonalItem.getHasCompensationReceived()">
            <qnect-ui #DAMAGE_PERS_ITEM_09 [fieldId]="'DAMAGE_PERS_ITEM_09'" [formGroup]="reportTheLossForm" name="compensationValue"></qnect-ui>
        </div>
      </form>

    </div>
    <div class="col-lg-4 col-md-12">
      &nbsp;
    </div>
  </div>

  <div class="submit-bar">
    <abandon-button></abandon-button>    
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}} </a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
