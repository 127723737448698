import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-miss-connect',
  templateUrl: './miss-connect.component.html',
  styleUrls: ['./miss-connect.component.css']
})
export class MissConnectComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]);
    }

  ngOnInit() {
  }

}
