export class EmployeeEarnings {

    private workingDays : string;
    private specify : string;
    private isPaid : boolean;
    private isFixed : boolean;
    private dayOfWeek : any[];
    private wages : number;
    private foodAllowance : number;
    private otherItem : string;
    private otherAmount : number;
    private totalAmount : number;
    private averageEarnings : number;
    private paidOrUnpaidDesc : string;
    private fixedOrFlexibleDesc : string;
    private workingDaysDesc : string;

    public getWorkingDays(): string {
        return this.workingDays;
    }

    public setWorkingDays(workingDays: string): void {
        this.workingDays = workingDays;
    }

    public getSpecify(): string {
        return this.specify;
    }

    public setSpecify(specify: string): void {
        this.specify = specify;
    }

    public getIsPaid(): boolean {
        return this.isPaid;
    }

    public setIsPaid(isPaid: boolean): void {
        this.isPaid = isPaid;
    }

    public getIsFixed(): boolean {
        return this.isFixed;
    }

    public setIsFixed(isFixed: boolean): void {
        this.isFixed = isFixed;
    }

    public getDayOfWeek(): any[] {
        return this.dayOfWeek;
    }

    public setDayOfWeek(dayOfWeek: any[]): void {
        this.dayOfWeek = dayOfWeek;
    }

    public getWages(): number {
        return this.wages;
    }

    public setWages(wages: number): void {
        this.wages = wages;
    }

    public getFoodAllowance(): number {
        return this.foodAllowance;
    }

    public setFoodAllowance(foodAllowance: number): void {
        this.foodAllowance = foodAllowance;
    }

    public getOtherItem(): string {
        return this.otherItem;
    }

    public setOtherItem(otherItem: string): void {
        this.otherItem = otherItem;
    }

    public getOtherAmount(): number {
        return this.otherAmount;
    }

    public setOtherAmount(otherAmount: number): void {
        this.otherAmount = otherAmount;
    }

    public getTotalAmount(): number {
        return this.totalAmount;
    }

    public setTotalAmount(totalAmount: number): void {
        this.totalAmount = totalAmount;
    }

    public getAverageEarnings(): number {
        return this.averageEarnings;
    }

    public setAverageEarnings(averageEarnings: number): void {
        this.averageEarnings = averageEarnings;
    }

    public getPaidOrUnpaidDesc(): string {
        return this.paidOrUnpaidDesc;
    }

    public setPaidOrUnpaidDesc(paidOrUnpaidDesc: string): void {
        this.paidOrUnpaidDesc = paidOrUnpaidDesc;
    }

    public getFixedOrFlexibleDesc(): string {
        return this.fixedOrFlexibleDesc;
    }

    public setFixedOrFlexibleDesc(fixedOrFlexibleDesc: string): void {
        this.fixedOrFlexibleDesc = fixedOrFlexibleDesc;
    }

    public getWorkingDaysDesc(): string {
        return this.workingDaysDesc;
    }

    public setWorkingDaysDesc(workingDaysDesc: string): void {
        this.workingDaysDesc = workingDaysDesc;
    }

    static jsonConvert(employeeEarnings: EmployeeEarnings): EmployeeEarnings {
        employeeEarnings = Object.assign(new EmployeeEarnings(), employeeEarnings);
        return employeeEarnings;
    }
}