import {Component, Injector, Input, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {TripCurtailment} from "../../../../model/travel/trip-curtailment.model";
import { ClaimTravel } from "src/app/model/claim-travel.model";
import { DocumentField } from "../../../../model/document-field";
import { ClaimTypes } from "../../../../model/claim-type.model";
import { DocumentHelperService } from "../../../../services/document-helper.service";
import {UtilitiesService} from '../../../../utilities/utilities.service';
@Component({
  selector: 'app-summary-trip-curtailment',
  templateUrl: './summary-trip-curtailment.component.html',
  styleUrls: ['./summary-trip-curtailment.component.css']
})
export class SummaryTripCurtailmentComponent extends ClaimsBaseComponent implements OnInit {

  claim: Claim;
  claimTravel: ClaimTravel;
  tripCurtailment: TripCurtailment;
  listOfDocuments: DocumentField[] = [];
  listOfOtherDocuments: DocumentField[] = [];
  @Input() claimItem: Claim;

  constructor(private claimService: ClaimService,
              private documentHelperService: DocumentHelperService,
              private injector : Injector) {

      super(injector);
  }

  ngOnInit() {

      if (!UtilitiesService.isNullOrUndefined(this.claimItem)) {
          this.claim = this.claimItem;
      } else {
          this.claim = this.claimService.getClaim();
      }

      this.claimTravel = this.claim.getClaimTravel();
      this.tripCurtailment = this.claim.getClaimTravel().getTripCurtailment();
      let isClaimantMinor = UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth());

      this.listOfDocuments = this.documentHelperService.getListOfUploadedDocumentsClaimant(this.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]).getRequiredDocuments(),
          ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL], isClaimantMinor);
      this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]).getRequiredDocuments(),
          ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]);

  }

  returnYesOrNo(value: boolean) {
    if (true === value) {
        return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
    } else {
        if (false === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
        }
    }

    return '';
    }

getDate(dt: Date) {
    if (dt && dt != null) {
        return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
    }
    else {
        return "";
    }
}

}
