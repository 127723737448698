import { Component, Injector, OnInit } from '@angular/core';
import { ClaimService } from "../../../../services/claim.service";
import { Claim } from "../../../../model/claim.model";
import { ClaimsBaseComponent } from "../../../claim-base.component";
import { DatePipe } from "@angular/common";
import { ClaimTypes } from '../../../../model/claim-type.model';
import { DocumentField } from '../../../../model/document-field';
import { DocumentHelperService } from '../../../../services/document-helper.service';
import { ClaimMotor } from 'src/app/model/claim-motor.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'app-summary-accident-collission',
    templateUrl: './summary-accident-collission.component.html',
    styleUrls: ['./summary-accident-collission.component.css']
})
export class SummaryAccidentCollissionComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimMotor: ClaimMotor;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private injector: Injector,
                private documentHelperService: DocumentHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimMotor = this.claim.getClaimMotor();
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]);
            
    }

    ngOnInit() {
   

    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
        }
    }
    returnYesOrNoBoolean(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            if (!value) {
                return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
            }
        }
        return '';
    }
    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }
    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }

    isNotNullOrUndefined(val: any){
        if(!UtilitiesService.isNullOrUndefined(val)){
            return true;
        }
        return false;
    }

    
    isNotNullOrUndefinedStr(val: any){
        if(!UtilitiesService.isNullOrUndefined(val) && val != ''){
            return true;
        } 
        return false;
    }
}
