import {Component, Injector, OnInit} from "@angular/core";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { Claim } from "src/app/model/claim.model";
import { ClaimService } from "src/app/services/claim.service";
import { DomesticEmployerLiability } from "src/app/model/home/domestic-employer-liability.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimTypes } from "src/app/model/claim-type.model";

@Component({
    selector: 'app-summary-domestic-employer',
    templateUrl: './summary-domestic-employer.component.html',
    styleUrls: ['./summary-domestic-employer.component.css']
})
export class SummaryDomesticEmployerComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    domesticEmployerLiability: DomesticEmployerLiability;
    isDmhClaim = false;

    constructor(private claimService: ClaimService,
                private injector : Injector,
                private transactionInfoService: TransactionInfoService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.domesticEmployerLiability = this.claim.getClaimHome().getHomeDetails().getDomesticEmployerLiability();
        let currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
        if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]].indexOf(currentClaimType) > -1)){
            this.isDmhClaim = true;
        }
    }

    ngOnInit() {
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
            }
        }
        return '';
    }

}
