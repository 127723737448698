import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { DatePipe } from '@angular/common';
import { ClaimPersonalAccident } from '../../../../model/claim-personal-accident.model';

@Component({
    selector: 'app-summary-personal-accident-claim-details',
    templateUrl: './summary-personal-accident-claim-details.component.html',
    styleUrls: ['./summary-personal-accident-claim-details.component.css']
})
export class SummaryPersonalAccidentClaimDetailsComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimPA: ClaimPersonalAccident;
    occupation: string = '';
    natureInjury: string = '';
    injuredParts: string = '';

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.setupOtherFields();
    }
    
    ngOnInit() {

    }

    setupOtherFields(){
        //set up Occupation
        if(this.claimPA.getAccidentDetails().getOccupation() != 'Others') {
            this.occupation = this.claimPA.getAccidentDetails().getOccupationDesc();
        } else {
            this.occupation = this.claimPA.getAccidentDetails().getOccupationOthers();
        }
        //set up Nature of Injury
        if (this.claimPA.getInjuryDetails().getHasBeenInjured()) {
            if(['Other', 'Multiple'].indexOf(this.claimPA.getInjuryDetails().getNatureInjury()) == -1) {
                this.natureInjury = this.claimPA.getInjuryDetails().getNatureInjuryDesc();
            } else {
                this.natureInjury = this.claimPA.getInjuryDetails().getNatureInjuryOthers();
            }
            if(this.claimPA.getInjuryDetails().getInjuredPartsList().length > 1) {
                this.injuredParts = this.claimPA.getInjuryDetails().getInjuredPartsList().join(", "); 
            } else {
                this.injuredParts = this.claimPA.getInjuryDetails().getInjuredPartsList()[0];
            }
        }
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.noSend");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }


}
