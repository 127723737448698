import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-front-view-modal',
  templateUrl: './front-view-modal.component.html'
})

@Injectable()
export class FrontViewModalComponent {

  @Input() frontViewModalGetArea: any;
  @Input() frontViewModalIsOpen: any;
  @Input() frontViewModalIsOpenF: any;

  @Input() frontViewModalTable: any;
  @Input() frontViewModalSelectHead: any;
  @Input() frontViewModalSelectNeckTrunk: any;
  @Input() frontViewModalSelectLeftArm: any;
  @Input() frontViewModalSelectRightArm: any;
  @Input() frontViewModalSelectLeftHand: any;
  @Input() frontViewModalSelectRightHand: any;
  @Input() frontViewModalSelectLeftLeg: any;
  @Input() frontViewModalSelectRightLeg: any;
  @Input() frontViewModalSelectLeftFoot: any;
  @Input() frontViewModalSelectRightFoot: any;
  @Input() frontViewModalTemp: any;
  
  @Input() frontViewModalFetchedData: any;

  @Input() frontViewModalInjuryList: any;

  @Input() frontViewModalIsLoading: any;

  @Input() frontViewModalIsSubmitted: any;

  @Output() emitFrontViewModalOnClickCloseSpecificArea = new EventEmitter<any>();
  frontViewModalOnClickCloseSpecificArea() {
    this.emitFrontViewModalOnClickCloseSpecificArea.emit();
  }

  @Output() emitFrontViewModalOnClickSpecificArea = new EventEmitter<any>();
  frontViewModalOnClickSpecificArea(id: any, area: any) {
    this.emitFrontViewModalOnClickSpecificArea.emit({ id, area });
  }

  @Output() emitFrontViewModalOnClickNotClose = new EventEmitter<any>();
  frontViewModalOnClickNotClose(e: any) {
    this.emitFrontViewModalOnClickNotClose.emit(e);
  }

  @Output() emitFrontViewModalShowTooltip = new EventEmitter<any>();
  frontViewModalShowTooltip(txt: any) {
    this.emitFrontViewModalShowTooltip.emit(txt);
  }

  @Output() emitFrontViewModalHideToolTip = new EventEmitter<any>();
  frontViewModalHideToolTip() {
    this.emitFrontViewModalHideToolTip.emit();
  }

  isSelected(id: any) {
    return this.frontViewModalTemp.some((obj: any) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }

  @Output() emitFrontViewModalOnSubmitSelected = new EventEmitter<any>();
  frontViewModalOnSubmitSelected() {
    this.emitFrontViewModalOnSubmitSelected.emit();
  }

  checkAreaExists(area: any) {
    return this.frontViewModalTable.some((item: any) => item.area === area);
  }

  @Output() emitFrontViewModalCustomSearchFn = new EventEmitter<any>();
  frontViewModalCustomSearchFn(term: any) {
    this.emitFrontViewModalCustomSearchFn.emit(term);
  }

  @Output() emitFrontViewModalAddTagFn = new EventEmitter<any>();
  frontViewModalAddTagFn(label: any) {
    this.emitFrontViewModalAddTagFn.emit(label);
  }

  @Output() emitFrontViewModalOnClickRemoveSpecificArea = new EventEmitter<any>();
  frontViewModalOnClickRemoveSpecificArea(id: any, area: any) {
    this.emitFrontViewModalOnClickRemoveSpecificArea.emit({ id, area });
  }
}
