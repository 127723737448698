import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DynamicSubject} from './service/dynamic-table.subject';
import {DynamicItem} from './model/dynamic-item.model';
import {DynamicItemAction} from './model/dynamic-item-action.model';
import {ModalDialogComponent} from '../modal-dialog/modal-dialog.component';

@Component({
  selector: 'qnect-dynamic-table',
  templateUrl: './dynamic-table.component.html'
})
export class DynamicTableComponent implements OnInit {
  items: DynamicItem[];

  @Input()
  subject: DynamicSubject;

  item: DynamicItem;

  @ViewChild(ModalDialogComponent, {static: true})
  modal: ModalDialogComponent;

  ngOnInit() {
    this.subject.actionItem.subscribe(actionItem => {
      const action = actionItem.action
      const item = actionItem.item;
      if (DynamicItemAction.ADD === action) {
        this.addItem(item);
      } else if (DynamicItemAction.EDIT === action) {
        this.editItem(item);
      } else if (DynamicItemAction.DELETE === action) {
        this.item = item;
        this.showRemoveModal();
      } else if (DynamicItemAction.RETRIEVE === action) {
        this.retrieveItem(item);
      }
    });

    this.subject.items.subscribe(items => {
      this.items = items;
    });
  }

  addItem(item: DynamicItem) {
    let id: number = 0;
    this.items.forEach(each => {
      id = each.id > id ? each.id : id;
    });
    item.id = ++id;
    this.items.push(item);
    this.subject.setItems(this.items);
  }

  showRemoveModal() {
    this.modal.show();
  }

  removeItem() {
    const index = this.items.findIndex(each => this.item.id === each.id);
    if (index > -1) {
      this.items.splice(index, 1);
      this.subject.setItems(this.items);
    }
    this.modal.hide();
  }

  editItem(item: DynamicItem) {
    if (item) {
      Object.assign(this.items.find(each => item.id === each.id), item);
      this.subject.setItems(this.items);
    }
  }

  retrieveItem(item: DynamicItem) {
    const actionItem: DynamicItemAction = new DynamicItemAction();
    actionItem.action = DynamicItemAction.DISPLAY;
    actionItem.item = this.items.find(each => item.id === each.id);
    this.subject.setActionItem(actionItem);
  }

}
