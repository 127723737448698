import {ClaimTypeMenu} from "./claim-type-menu.model";

export class NavigationMenu {

    private getStartedState: string = 'not started';                //not started or current or finish
    private confirmTravelPeriodState: string = 'not started';
    private selectInsuredState: string  = 'not started';
    private accidentDetailsState: string = 'not started';
    private injuryDetailsState: string = 'not started';
    private selectClaimState: string = 'not started';
    private claimDetailsState: string = 'true' ;
    private paymentAndContactState: string  = 'not started';
    private reviewSummaryState: string = 'not started';
    private claimDetailMenu: ClaimTypeMenu[] = [];
    private genericClaimDetailState: string = 'not started';
    private genericUploadDocumentState: string = 'not started';
    private confirmIncidentDetailsState: string = 'not started';


    getGetStartedState(): string {
        return this.getStartedState;
    }

    setGetStartedState(value: string) {
        this.getStartedState = value;
    }

    getConfirmTravelPeriodState(): string {
        return this.confirmTravelPeriodState;
    }

    setConfirmTravelPeriodState(value: string) {
        this.confirmTravelPeriodState = value;
    }

    getSelectInsuredState(): string {
        return this.selectInsuredState;
    }

    setSelectInsuredState(value: string) {
        this.selectInsuredState = value;
    }

    getSelectClaimState(): string {
        return this.selectClaimState;
    }

    setSelectClaimState(value: string) {
        this.selectClaimState = value;
    }

    getClaimDetailsState(): string {
        return this.claimDetailsState;
    }

    setClaimDetailsState(value: string) {
        this.claimDetailsState = value;
    }

    getClaimDetailMenu(): ClaimTypeMenu[] {
        return this.claimDetailMenu;
    }

    setClaimDetailMenu(value: ClaimTypeMenu[]) {
        this.claimDetailMenu = value;
    }

    getPaymentAndContactState(): string {
        return this.paymentAndContactState;
    }

    setPaymentAndContactState(value: string) {
        this.paymentAndContactState = value;
    }

    getReviewSummaryState(): string {
        return this.reviewSummaryState;
    }

    setReviewSummaryState(value: string) {
        this.reviewSummaryState = value;
    }

    getGenericClaimDetailState(): string {
        return this.genericClaimDetailState;
    }

    setGenericClaimDetailState(value: string) {
        this.genericClaimDetailState = value;
    }

    getGenericUploadDocumentState(): string {
        return this.genericUploadDocumentState;
    }

    setGenericUploadDocumentState(value: string) {
        this.genericUploadDocumentState = value;
    }

    getConfirmIncidentDetailsState(): string {
        return this.confirmIncidentDetailsState;
    }

    setConfirmIncidentDetailsState(value: string) {
        this.confirmIncidentDetailsState = value;
    }

    getAccidentDetailsState(): string {
        return this.accidentDetailsState;
    }

    setAccidentDetailsState(value: string) {
        this.accidentDetailsState = value;
    }

    getInjuryDetailsState(): string {
        return this.injuryDetailsState;
    }

    setInjuryDetailsState(value: string) {
        this.injuryDetailsState = value;
    }

    static jsonConvertNavigationMenu(navigationMenu: NavigationMenu): NavigationMenu {

        navigationMenu = Object.assign(new NavigationMenu(), navigationMenu);

       if(navigationMenu != null) {

           //convert claim detail menu object
           if (navigationMenu.getClaimDetailMenu() != null) {
               let claimDetailMenu: ClaimTypeMenu[] = [];

               for (const claimDetailMenuData of navigationMenu.getClaimDetailMenu() ) {
                   let claimDetailItem = Object.assign(new ClaimTypeMenu(), claimDetailMenuData);
                   claimDetailItem = ClaimTypeMenu.jsonConvert(claimDetailItem);
                   claimDetailMenu.push(claimDetailItem);
               }
               navigationMenu.setClaimDetailMenu(claimDetailMenu);
           }
       }

       return navigationMenu;

    }


}