<div class="section form-group ">
    <div class="section-content">
        <div class="sub-section">
            <label>{{'homeProduct.authorityReport.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7" *ngIf="!this.isFire">
                    {{'homeProduct.authorityReport.isIncidentReportedToPolice' | translate}}
                </div> 
                <div class="col col-xs-12 col-lg-7" *ngIf="this.isFire">
                    {{'homeProduct.authorityReport.isIncidentReportedToFire' | translate}}
                </div> 
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{returnYesOrNoBoolean(homeDetails.getPoliceReport().getHasReportedIncidentToPolice())}}
            </div>
            </div>
            <ng-container *ngIf="homeDetails.getPoliceReport().getHasReportedIncidentToPolice()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.authorityReport.dateOfReport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{getDate(homeDetails.getPoliceReport().getDateOfReport())}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(homeDetails.getPoliceReport().getPoliceStation())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.authorityReport.policeStationNameAdd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{homeDetails.getPoliceReport().getPoliceStation()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.authorityReport.reportRefNo' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{homeDetails.getPoliceReport().getPoliceReportNumber()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>