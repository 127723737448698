
export class Claimant {

    private claimantName : string;
    private claimantIdNumber : string;
    private claimantDateOfBirth : Date;
    private claimantIdentifier : string = '';
    private selectedClaimTypesList : string[] = [];

    getClaimantIdNumber(): string {
        return this.claimantIdNumber;
    }

    setClaimantIdNumber(value: string) {
        this.claimantIdNumber = value;
    }

    getClaimantDateOfBirth(): Date {
        return this.claimantDateOfBirth;
    }

    setClaimantDateOfBirth(value: Date) {
        this.claimantDateOfBirth = value;
    }

    getClaimantName(): string {
        return this.claimantName;
    }

    setClaimantName(value: string) {
        this.claimantName = value;
    }

    getClaimantIdentifier(): string {
        return this.claimantIdentifier;
    }

    setClaimantIdentifier(value: string) {
        this.claimantIdentifier = value;
    }

    getSelectedClaimTypesList(): string[] {
        return this.selectedClaimTypesList;
    }

    setSelectedClaimTypesList(value: string[]) {
        this.selectedClaimTypesList = value;
    }

    static jsonConvert(claimant: Claimant): Claimant {

        claimant = Object.assign(new Claimant(), claimant);

        if (claimant.getClaimantDateOfBirth() != null) {
            claimant.setClaimantDateOfBirth(new Date(claimant.getClaimantDateOfBirth()));
        }

        return claimant;
    }

}