import {Component, Injector, OnInit, ViewChild} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { Claim } from "src/app/model/claim.model";
import { ClaimsBaseComponent } from "../../claim-base.component";
import { HomeItem } from "src/app/model/home/home-item.model";
import { HomeDetails } from "src/app/model/home/home-details.model";
import { ClaimTypes } from "src/app/model/claim-type.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { UtilitiesService } from "src/app/utilities/utilities.service";
declare var $: any;

@Component({
  selector: 'app-home-items-details',
  templateUrl: './home-items-details.component.html',
  styleUrls: ['./home-items-details.component.css']
})
export class HomeItemsDetailsComponent extends ClaimsBaseComponent implements OnInit {
    
    homeItemsDetailForm: FormGroup;
    items: any[] = [];
    selectType: string;
    showTotalBox = false;
    claim: Claim;
    detailsOfHomeItemsArray: HomeItem[];
    isBurglaryOrAccident = false;
    isBurglary = false;
    currentClaimType: string; 

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private transactionInfoService: TransactionInfoService) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
            if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT]].indexOf(this.currentClaimType) > -1)){
                this.isBurglaryOrAccident = true;
            }
             if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY]].indexOf(this.currentClaimType) > -1)){
                    this.isBurglary = true;
            }
        
    }

    ngOnInit() {

        this.pushGAHomeItemDetailsView();

        this.detailsOfHomeItemsArray = this.claim.getClaimHome().getHomeDetails().getHomeItems();
        if(!this.isBurglaryOrAccident) {
            this.homeItemsDetailForm = this.fb.group({
                items: this.fb.array(this.buildStoredItems(this.detailsOfHomeItemsArray))
            });
        } else {
            this.homeItemsDetailForm = this.fb.group({
                damageDescription: [this.claim.getClaimHome().getHomeDetails().getDamageDescription()],
                items: this.fb.array(this.buildStoredItems(this.detailsOfHomeItemsArray))
            });
        }


    }

    ngAfterViewInit() {

        let homeDetails = this.claim.getClaimHome().getHomeDetails();

        this.homeItemsDetailForm.valueChanges.subscribe(data => {

            if(this.isBurglaryOrAccident){
                this.claim.getClaimHome().getHomeDetails().setDamageDescription(this.homeItemsDetailForm.get('damageDescription').value);
            }

            this.detailsOfHomeItemsArray = [];

            for (let i = 0; i < data.items.length; i++) {

                let itemDetail : HomeItem = new HomeItem();

                itemDetail.setLossType(data.items[i].lossType);
                itemDetail.setLossTypeDesc(super.getIndexedTextInComponent('lossType', i));
                itemDetail.setLossTypeDetail(data.items[i].lossTypeDetail);
                itemDetail.setReplacementCostType(data.items[i].replacementCostType);
                itemDetail.setReplacementCost(data.items[i].replacementCost);
                itemDetail.setRepairCostType(data.items[i].repairCostType);
                itemDetail.setRepairCost(data.items[i].repairCost);
                itemDetail.setOtherTypeBriefDesc(data.items[i].otherTypeBriefDesc);
                itemDetail.setLostMoneyAmount(data.items[i].lostMoneyAmount);
                itemDetail.setLostMoneyAmountType(data.items[i].lostMoneyAmountType);
            
                this.detailsOfHomeItemsArray.push(itemDetail);
            }

            homeDetails.setHomeItems(this.detailsOfHomeItemsArray);
            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);
        });

        // Stop validation of fields when not required.
        let formArray : FormArray =  <FormArray> this.homeItemsDetailForm.controls["items"];
        for (let i = 0; i < formArray.controls.length; i++) {
            formArray.controls[i].get('lossType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.homeItemTypeChanged(data, formArray.controls[i]);
            });
        }
    }

    homeItemTypeChanged(lossType, formGroup) {
        formGroup.get('lossTypeDetail').disable({onlySelf: false, emitEvent: false});
        formGroup.get('replacementCostType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('replacementCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCostType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('repairCost').disable({onlySelf: false, emitEvent: false});
        formGroup.get('otherTypeBriefDesc').disable({onlySelf: false, emitEvent: false});
        formGroup.get('lostMoneyAmount').disable({onlySelf: false, emitEvent: false});
        formGroup.get('lostMoneyAmountType').disable({onlySelf: false, emitEvent: false});

        if (lossType == 'Others') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('otherTypeBriefDesc').enable({onlySelf: false, emitEvent: false});
        } else if (lossType == 'Mobile Phone' || lossType == 'Laptop(s)') {
            formGroup.get('lossTypeDetail').enable({onlySelf: false, emitEvent: false});
        }

        if (lossType === 'Cash') {
            formGroup.get('lostMoneyAmount').enable({onlySelf: false, emitEvent: false});
            formGroup.get('lostMoneyAmountType').enable({onlySelf: false, emitEvent: false});
        } else {
            formGroup.get('replacementCostType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('replacementCost').enable({onlySelf: false, emitEvent: false});
            formGroup.get('repairCostType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('repairCost').enable({onlySelf: false, emitEvent: false});
        }

    }

    buildStoredItems(detailsOfHomeItemsArray: HomeItem[]): FormGroup[] {
        console.log("BUILD STORED ITEMS!!!");

        let formGroupArray : FormGroup[] = [];

        if(detailsOfHomeItemsArray != null && detailsOfHomeItemsArray.length > 0 ) {

            for (let i = 0; i < detailsOfHomeItemsArray.length; i++) {

                let rowFormGroup = this.fb.group({
                    lossType: [detailsOfHomeItemsArray[i].getLossType()],
                    lossTypeDetail: [detailsOfHomeItemsArray[i].getLossTypeDetail()],
                    replacementCostType: [detailsOfHomeItemsArray[i].getReplacementCostType()],
                    replacementCost: [detailsOfHomeItemsArray[i].getReplacementCost()],
                    repairCostType: [detailsOfHomeItemsArray[i].getRepairCostType()],
                    repairCost: [detailsOfHomeItemsArray[i].getRepairCost()],
                    otherTypeBriefDesc: [detailsOfHomeItemsArray[i].getOtherTypeBriefDesc()],
                    lostMoneyAmountType: [detailsOfHomeItemsArray[i].getLostMoneyAmountType()],
                    lostMoneyAmount: [detailsOfHomeItemsArray[i].getLostMoneyAmount()]
                });

                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }

    createDefaultItems(): FormGroup {
        let currency = this.claim.getCountry() != 'SGP' ? 'HKD' : 'SGD';
        return this.fb.group({
            lossType: null,
            lossTypeDetail: '',
            replacementCostType: currency,
            replacementCost: '',
            repairCostType: currency,
            repairCost: '',
            otherTypeBriefDesc: '',
            lostMoneyAmount: '',
            lostMoneyAmountType: currency
        });

    }

    addItem(): void {
        //let items = this.homeItemsDetailForm.get('items') as FormArray;
       // items.push(this.createDefaultItems());
        let self = this;
        setTimeout(function () {

            let items = self.homeItemsDetailForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems();
            items.push(newItem);

            newItem.get('lossType').valueChanges.pipe(takeUntil(self.ngUnsubscribe)).subscribe(data => {
                self.homeItemTypeChanged(data, newItem);
            });

        }, 10);
    }

    removeItem(i) {
        let items = this.homeItemsDetailForm.get('items') as FormArray;
        items.removeAt(i);
        this.detailsOfHomeItemsArray.splice(i, 1);
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    validateForm(): boolean {
        this.validateReplacementCostRepairCost();
        let result = super.validateForm(this.homeItemsDetailForm);
        if (!result) {
            this.enableReplacementCostRepairCost();
        }
        return result;
    }

    validateReplacementCostRepairCost() {
        let homeDetails: HomeDetails = this.claim.getClaimHome().getHomeDetails();
        let data: HomeItem[] = homeDetails.getHomeItems();
        if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (this.isNotNullOrUndefinedNum(data[i].getReplacementCost()) || this.isNotNullOrUndefinedNum(data[i].getRepairCost())) {

                    let formArray: FormArray = <FormArray>this.homeItemsDetailForm.controls["items"];
                    for (let j = 0; j < formArray.controls.length; j++) {
                        let isReplacementCostEmpty: boolean = this.isNotNullOrUndefinedNum(data[j].getReplacementCost()) ? false : true;
                        let isRepairCostEmpty: boolean = this.isNotNullOrUndefinedNum(data[j].getRepairCost()) ? false : true;
                        if (!isReplacementCostEmpty && isRepairCostEmpty) {
                            formArray.controls[i].get('repairCost').disable({onlySelf: false, emitEvent: false});
                         } else if (!isRepairCostEmpty && isReplacementCostEmpty) {
                            formArray.controls[i].get('replacementCost').disable({onlySelf: false, emitEvent: false});
                        }
                    }
                }
            }
        }
    }

    enableReplacementCostRepairCost() {
        let homeDetails: HomeDetails = this.claim.getClaimHome().getHomeDetails();
        let data: HomeItem[] = homeDetails.getHomeItems();
        if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (this.isNotNullOrUndefinedNum(data[i].getReplacementCost()) || this.isNotNullOrUndefinedNum(data[i].getRepairCost())) {
                    let formArray : FormArray =  <FormArray> this.homeItemsDetailForm.controls["items"];
                    for (let i = 0; i < formArray.controls.length; i++) {
                        formArray.controls[i].get('repairCost').enable({onlySelf: false, emitEvent: false});
                        formArray.controls[i].get('replacementCost').enable({onlySelf: false, emitEvent: false});
                    }
                }
            }
        }
    }

    validateMoneyFields(): boolean {
        let homeDetails: HomeDetails = this.claim.getClaimHome().getHomeDetails();
        let data: HomeItem[] = homeDetails.getHomeItems();
        for (let i = 0; i < data.length; i++) {
                let formArray : FormArray =  <FormArray> this.homeItemsDetailForm.controls["items"];
                for (let i = 0; i < formArray.controls.length; i++) {

                    if ((UtilitiesService.isNullOrUndefined(formArray.controls[i].get('repairCost').value) || formArray.controls[i].get('repairCost').value == "") &&
                        (UtilitiesService.isNullOrUndefined(formArray.controls[i].get('replacementCost').value) || formArray.controls[i].get('replacementCost').value == "")) {
                        return true;
                    }

                }

        }
        return false;
    }

    validateClaimDetailsComplete() {
     /*    let isFormValidOnChange: boolean = super.validateFormOnChange(this.homeItemsDetailForm);
        let isMoneyFieldsPopulated : boolean = this.validateMoneyFields();
        if(isFormValidOnChange && !isMoneyFieldsPopulated){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 2, subMenuIndex: 1});
        } */
    }

    save(): boolean {
        this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 1});
        this.sideMenuService.emitProcess(1, 0);
        return true;
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    changeType(val) {
        var that = this;
        setTimeout(() => {
            $('.datetimepicker').daterangepicker(
                {
                    format: 'yyyy-mm-dd',
                    singleDatePicker: true,
                    minYear: 1900,
                    maxYear: 3000,
                    showDropdowns: true,
                    minDate: '01/01/1900',

                }).on('change', function (ev) {
                let tmpName = $(this).attr('data-name').split('-');
                console.log('tmpName', tmpName);
                console.log('formArray', that.homeItemsDetailForm.get('items').value as FormArray);
                let arrayItems = that.homeItemsDetailForm.get('items') as FormArray;
                console.log('arrayItems', arrayItems);
                arrayItems.at(tmpName[1]).patchValue({
                    purchaseDate: ev.currentTarget.value
                })
            });

        });
    }

    pushGAHomeItemDetailsView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep();
        if (([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT], 
              ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_FIRE],
              ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY],
              ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_VANDALISM]].indexOf(this.currentClaimType) > -1)) {
            pageStep = pageStep + ' - Details of the damaged items'
        } else {
            pageStep = pageStep + ' - Item Details';
        }
        
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath();
        if (([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT]].indexOf(this.currentClaimType) > -1)) {
            vPath = vPath + '/details';
        } else {
            vPath = vPath + '/items';
        }
        
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        if (([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_ACCIDENT]].indexOf(this.currentClaimType) > -1)) {
            (<any>window).dataLayer.push({
                'pageStep': pageStep,
                'vPath': vPath,            
                'event': 'vpageview',
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': 4}	 //Step4-Claim Details }
                  }
                }
            });
        } else {
            (<any>window).dataLayer.push({
                'pageStep': pageStep,
                'vPath': vPath,            
                'event': 'vpageview'
            });
        }        
    }
}
