<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.missedEvent.lossOfMissedEvent.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">

      <form>
        <div >
          <div class="form-group click-more-content" id="q1">
            <!--<label for="original-time">Total amount  you paid for the event</label><BR>
           <select formControlName="totalAmountPaidCurrency" class="col-lg-3 col-3 form-control inline xs" id="arrival-country">
             <option value="HKD">HKD</option>
           </select>
           <input autocomplete="off" formControlName="totalAmountPaid" type="number" class="col-lg-6 col-8 form-control inline">
           -->
           <qnect-ui #MISS_EVENT_PAID_AMOUNT [fieldId]="'MISS_EVENT_PAID_AMOUNT'" [formGroup]="lossOfMissEventForm" name="amtPaid"></qnect-ui>
         </div>
       </div>
       <div >
         <div class="form-group">
           <qnect-ui #MISS_EVENT_CREDIT_CARD_PAYMENT [fieldId]="'MISS_EVENT_CREDIT_CARD_PAYMENT'" [formGroup]="lossOfMissEventForm" name="isCreditCardPayment"></qnect-ui>
         </div>
         <div class="form-group">
           <qnect-ui #MISS_EVENT_HAS_REFUND [fieldId]="'MISS_EVENT_HAS_REFUND'" [formGroup]="lossOfMissEventForm" name="hasRefund"></qnect-ui>
         </div>
         <div class="form-group click-more-content" id="q2" *ngIf="missedEvent.getHasRefund()">
           <!--<label for="refundCurrency">Total amount refunded</label><BR>
           <select formControlName="refundCurrency" class="col-lg-3 col-3 form-control inline xs" id="refundCurrency">
             <option value="HKD">HKD</option>
           </select>
           <input autocomplete="off" formControlName="refundCost" type="number" class="col-lg-6 col-8 form-control inline">
            -->
           <qnect-ui #MISS_EVENT_REFUND_AMOUNT [fieldId]="'MISS_EVENT_REFUND_AMOUNT'" [formGroup]="lossOfMissEventForm" name="refundAmt"></qnect-ui>
         </div>
       </div>

     </form>
   </div>
   <div class="col-lg-3 col-xl-3 col-md-12">
     &nbsp;
   </div>
 </div>
 <div class="submit-bar">
  <abandon-button></abandon-button>
   <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
   <a (click)="goToNext()"  href="javascript:;" class="new-btn btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></a>
 </div>
 <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
