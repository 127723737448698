import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[claimsUpperCase]'
})
export class UpperCaseTextDirective {
  private el: HTMLInputElement;

  constructor(private ref: ElementRef) {
    this.el = this.ref.nativeElement;
  }

  @HostListener('input', ['$event.target.value'])
  input(value) {
    this.el.value = value.toUpperCase();
  }
}
