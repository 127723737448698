import {Component, Injector} from '@angular/core';
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {EmployerDetails} from '../../../../model/employee-compensation/employer-details.model';

@Component({
    selector: 'app-summary-employer-details',
    templateUrl: './summary-employer-details.component.html',
    styleUrls: ['../employee-compensation-summary.component.css',]
})

export class SummaryEmployerDetailsComponent extends ClaimsBaseComponent {

    employerDetails: EmployerDetails;

    constructor(private injector : Injector) {
        super(injector);
        this.employerDetails = this.claim.getClaimEmployeeCompensation().getEmployerDetails();
    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        }
        return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
    }
}
