import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {ClaimDocumentsComponent} from "../../../claim-documents/claim-documents.component";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {DocumentHelperService} from "../../../../services/document-helper.service";
import {TransactionInfoService} from "../../../../services/transaction-info.service";
import {DocumentFormFactoryService} from "../../../../services/document-form-factory.service";
import {SpinnerService} from "../../../../core/spinner/spinner.service";
import {ClaimTypes} from "../../../../model/claim-type.model";
import {HttpClient} from "@angular/common/http";

declare var $: any;

@Component({
  selector: 'app-dental-expense-upload-document',
  templateUrl: './dental-expense-upload-document.component.html',
  styleUrls: ['./dental-expense-upload-document.component.css']
})

export class DentalExpenseUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    
    constructor(private fb: FormBuilder,
        public sideMenuService: SideMenuService, 
        claimService: ClaimService,
        documentHelperService: DocumentHelperService,
        transactionInfoService: TransactionInfoService,
        http: HttpClient,
        documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
                super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
                this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]);
    }

    ngOnInit() {

        this.pushGAViewDentalExpenseUpload();

        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 2});
        this.sideMenuService.emitProcess(1, 0);
    }

    //Google Analytics
    pushGAViewDentalExpenseUpload() {
        (<any>window).dataLayer.push({
            'pageStep': 'Dental Expenses – Document Upload',
            'vPath': '/claim/dental-expenses/documents',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 6},	//Step6-DocumentUpload
              }
            }                                          
        });
    }

}
