import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {Windscreen} from '../../../../model/motor/windscreen.model';
import {ClaimTypes} from '../../../../model/claim-type.model';
import {DocumentField} from '../../../../model/document-field';
import {DocumentHelperService} from '../../../../services/document-helper.service';

@Component({
    selector: 'app-summary-windscreen',
    templateUrl: './summary-windscreen.component.html',
    styleUrls: ['./summary-windscreen.component.css']
})

export class SummaryWindscreenComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    windscreen: Windscreen;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private injector : Injector,
                private documentHelperService: DocumentHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.windscreen = this.claim.getClaimMotor().getWindscreen();
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN])

    }
    
    ngOnInit() {

    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
        }
    }
    returnYesOrNoBoolean(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (!value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
        return '';
    }
    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}
