<div>
  <div class="col-12">
  <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
    <h5>{{ 'motorClaim.getStarted.heading' | translate }} </h5>
    <span *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isAccidentProduct()">
      <p>{{ 'pageGetStarted.heading.desc' | translate }}</p>
    </span>
    <span *ngIf="transactionInfo.isDomesticHelperProduct()">
      <p>{{ 'pageGetStarted.heading.dmhDesc' | translate }}</p>
    </span>
    <span  *ngIf="transactionInfo.isMotorProduct()">
      <p>{{ 'motorClaim.getStarted.description' | translate }}</p>
    </span>
    <span *ngIf="transactionInfo.isGenericClaim() || transactionInfo.isHomeProduct() || transactionInfo.isEmployeeCompensationProduct()">
      <p>{{ 'pageGetStarted.heading.genericInstruction' | translate }}</p>
    </span>
  </div>
  <div class="row col-12">
    <ng-container *ngIf="transactionInfo.isTravelProduct() && transactionInfo.getRealCountry() === 'HKG'">
        <div class="col-lg-9 col-md-12">
            <form>
                <ul class="nav nav-tabs ff-stag">
                    <li><a data-toggle="tab" href="#nameDiv" (click)="resetFields('fullname')"  [ngClass]="{'active': this.transactionInfo.getDateOfBirth() == null && this.transactionInfo.getDateOfBirth() == undefined}">{{ 'pageGetStarted.body.name' | translate }}</a></li>
                    <li><a data-toggle="tab" href="#birthDiv" (click)="resetFields('dateofbirth')" [ngClass]="{'active': this.transactionInfo.getDateOfBirth() != null && this.transactionInfo.getDateOfBirth() != undefined}">{{ 'pageGetStarted.body.dateOfBirth' | translate }}</a></li>
                </ul>
                <div class="tab-content">
                    <div id="nameDiv" class="tab-pane fade in active show">
                        <div class="form-group">
                            <qnect-ui #B2C02Name [formGroup]="policyAuthenticationModel" [fieldId]="'B2C02Name'" name="name"></qnect-ui>
                        </div>
                    </div>
                    <div id="birthDiv" class="tab-pane fade">
                        <div class="form-group">
                            <qnect-ui #B2C03DateOfBirth [formGroup]="policyAuthenticationModel" [fieldId]="'B2C03DateOfBirth'" name="dateOfBirth"></qnect-ui>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container *ngIf="transactionInfo.isDomesticHelperProduct()">
        <div class="col-lg-9 col-md-12">
            <form>
                <ul class="nav nav-tabs ff-stag">
                    <li><a data-toggle="tab" href="#helperNameDiv" (click)="resetFields('fullname')"  [ngClass]="{'active': this.transactionInfo.getAuthIdNumber() == '' || this.transactionInfo.getAuthIdNumber() == null}">{{ 'pageGetStarted.body.dmhName' | translate }}</a></li>
                    <li><a data-toggle="tab" href="#hkidDiv" (click)="resetFields('idNumber')" [ngClass]="{'active': this.transactionInfo.getAuthIdNumber() != null && this.transactionInfo.getAuthIdNumber() != ''}">{{ 'pageGetStarted.body.dmhHkidPassport' | translate }}</a></li>
                </ul>
                <div class="tab-content">
                    <div id="helperNameDiv" class="tab-pane fade in active show">
                        <div class="form-group">
                            <qnect-ui #DMH_AUTH_NAME [formGroup]="policyAuthenticationModel" [fieldId]="'DMH_AUTH_NAME'" name="name"></qnect-ui>
                        </div>
                    </div>
                    <div id="hkidDiv" class="tab-pane fade">
                        <div class="form-group">
                            <qnect-ui #DMH_AUTH_HELPER_ID [formGroup]="policyAuthenticationModel" [fieldId]="'DMH_AUTH_HELPER_ID'" name="idNumber"></qnect-ui>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container *ngIf="transactionInfo.isMotorProduct()">
        <div class="col-lg-9 col-md-12">
            <form>
                <div class="form-group" >
                    <qnect-ui #MOTOR_AUTH_NAME [formGroup]="policyAuthenticationModel" [fieldId]="'MOTOR_AUTH_NAME'" name="name"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #MOTOR_AUTH_REG_NUMBER [formGroup]="policyAuthenticationModel" [fieldId]="'MOTOR_AUTH_REG_NUMBER'" name="idNumber"></qnect-ui>
                </div>
            </form>
        </div>
    </ng-container>
    <!-- sgp, mys  -->
    <ng-container *ngIf="transactionInfo.getRealCountry() !== 'HKG'">
        <div class="col-lg-9 col-md-12">
            <form>
                <div class="form-group">
                    <qnect-ui #GENERIC_AUTH_NAME [formGroup]="policyAuthenticationModel" [fieldId]="'GENERIC_AUTH_NAME'" name="name"
                              [dynamicLabel] ="transactionInfo.isGenericClaim() ? 'pageGetStarted.body.genericHolderName' : ''"
                              [uiPopover]="uiPopover"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #GENERIC_ID_NUMBER [formGroup]="policyAuthenticationModel" [fieldId]="'GENERIC_ID_NUMBER'" name="idNumber"></qnect-ui>
                </div>
            </form>
        </div>
    </ng-container>
    <!-- hkg - generic, home and personal accident -->
    <ng-container *ngIf="transactionInfo.getRealCountry() === 'HKG' &&
        (transactionInfo.isGenericClaim() || transactionInfo.isHomeProduct() || transactionInfo.isAccidentProduct())">
        <div class="col-lg-9 col-md-12">
            <form>
                <div class="form-group" >
                    <qnect-ui #GENERIC_AUTH_NAME [formGroup]="policyAuthenticationModel" [fieldId]="'GENERIC_AUTH_NAME_BOTH'" name="name"
                              [dynamicLabel] ="transactionInfo.isGenericClaim() ? 'pageGetStarted.body.genericHolderName' : ''"
                              [uiPopover]="uiPopover"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #GENERIC_ID_NUMBER [formGroup]="policyAuthenticationModel" [fieldId]="'GENERIC_ID_NUMBER'" name="idNumber"></qnect-ui>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container *ngIf="transactionInfo.isEmployeeCompensationProduct()">
        <div class="col-lg-9 col-md-12">
            <form>
                <div class="form-group">
                    <qnect-ui #EC_AUTH_NAME [formGroup]="policyAuthenticationModel" [fieldId]="'EC_AUTH_NAME'" name="name"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #EC_AUTH_ID  [formGroup]="policyAuthenticationModel" [fieldId]="'EC_AUTH_ID'" name="idNumber"></qnect-ui>
                </div>
            </form>
        </div>
    </ng-container>

     <div class="col-lg-3 col-xl-3 col-md-12">
       &nbsp;
     </div>
   </div>
   <div class="submit-bar">
      <abandon-button></abandon-button>
     <button (click)="goToNext()" class="btn btn-primary float-right new-btn"> {{ 'pageGetStarted.button' | translate }} <i class="fas fa-arrow-right"></i></button>
   </div>

 </div>