import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {PageService} from "../services/page.service";
import {Subject, takeUntil, interval} from "rxjs";
import {TransactionInfoService} from "../services/transaction-info.service";
import {TransactionInfo} from "../model/transaction-info.model";
import {IntegrationService} from '../services/integrationservice';
declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

    public customClass: string;
    public entryUrl: string;
    translateService: TranslateService;
    isHomePage: boolean = true;
    transactionInfo: TransactionInfo;
    showLangSelector: boolean = true;
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    outageMessage: string;
    country: string;

    constructor(private router: Router,
                public translate: TranslateService,
                public transactionInfoService : TransactionInfoService,
                private pageService : PageService,
                private integrationService: IntegrationService) {

        this.translateService = this.translate;

        router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
            this.country = this.transactionInfoService.getTransactionInfo().getRealCountry();
            if (value instanceof NavigationEnd) {
                if (value.url.indexOf('/claimform') !== -1) {
                    this.customClass = 'customClass';
                    this.isHomePage = false;
                    this.showLangSelector = false;
                    this.getSystemStatus();
                } else if (value.url.indexOf('/complete') !== -1) {
                    this.customClass = 'completeClass';
                    this.isHomePage = true;
                    this.showLangSelector = false;
                    this.getSystemStatus();
                } else {
                    this.customClass = '';
                    this.isHomePage = true;
                    this.showLangSelector = this.country === 'HKG';
                    this.getSystemStatus();
                }
            }

            setTimeout(function () {
                $('.menu-btn').off('click').on('click', function() {
                    $(this).toggleClass('opened');
                    $('.container-left').toggleClass('opened');
                });

            }, 10);
        });
    }

    ngOnInit() {
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();

        this.pageService.getEntryUrl()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                entryUrl => {
                    this.entryUrl = entryUrl;
                }
         );

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    changeLang(lang) {
        console.log("change  Language " + lang);
        this.translate.use(lang);
    }

    getSystemStatus(){
        this.integrationService.getSystemStatus(this.transactionInfoService.getTransactionInfo().getRealCountry())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: status => {
                    if (status.toString() === "false") {
                        this.outageMessage  = "error_messages.notificationsErrMsg.systemUnavailable";
                        this.disabledButton(true);
                    } else {
                        this.outageMessage = "";
                        this.disabledButton(false);
                    }
                },
                error: (err: any) => {
                    this.outageMessage = "";
                    this.disabledButton(false);
                }
            });
    }

    disabledButton(disable: boolean) {
        // disable primary buttons (start, next, submit etc..)
        if (disable) {
            $('.btn-primary').prop('disabled', true);
        } else {
            $('.btn-primary').prop('disabled', false);
        }
    }

}
