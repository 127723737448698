<div class="section form-group">
    <label class="section-title">{{'claimDetails.body.claimDetails' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.dateOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(claim.getDateOfLoss())}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.countryOfOccurrence' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getClaimEventOccurredDesc()}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.descOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{claim.getIncidentDescription()}}
                </div>
            </div>

            <div *ngIf="claim.getAmountClaimed()" class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'claimDetails.generic.estAmtLoss' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getCurrencyOfAmount()}}
                    {{claim.getAmountClaimed() | number : '1.2-2'}}
                </div>
            </div>
        </div>

        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
             </div>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>