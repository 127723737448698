import { ClinicalExpenseDetails } from "./clinical-expense-details.model";
import { HospitalExpenseDetails } from "./hospital-expense-details.model";
import { MedicalCertificate } from "./medical-certificate.model";
import {OutpatientConsultDetails} from './outpatient-consult-details.model';

export class AccidentalMedicalExpenses {

    private wasAdmitted: boolean;
    //YES wasAdmitted
    private hospitalExpenses: HospitalExpenseDetails[] = [];

    //NO wasAdmitted
    private clinicalExpenses: ClinicalExpenseDetails[] = [];
    private fullyRecovered: boolean;
    private treatment: string;

    //Stay in ICQ
    private stayedIn: boolean;
    private dateFrom: Date;
    private dateTo: Date;

    //Outpatient consultation details
    private outpatientConsultation: OutpatientConsultDetails = new OutpatientConsultDetails();

    private totalHospitalExpenses: number;
    private totalClinicalExpenses: number;
    private sumInsured: number = null;

    //Medical Certificate
    private wasMedCertIssued: boolean;
    private medicalCertificates: MedicalCertificate[] = [];

    public getWasAdmitted(): boolean {
        return this.wasAdmitted;
    }

    public setWasAdmitted(value: boolean) {
        this.wasAdmitted = value;
    }

    public getHospitalExpenses(): HospitalExpenseDetails[] {
        return this.hospitalExpenses;
    }

    public setHospitalExpenses(hospitalExpenses: HospitalExpenseDetails[]) {
        this.hospitalExpenses = hospitalExpenses;
    }

    public getClinicalExpenses(): ClinicalExpenseDetails[] {
        return this.clinicalExpenses;
    }

    public setClinicalExpenses(clinicalExpenses: ClinicalExpenseDetails[]) {
        this.clinicalExpenses = clinicalExpenses;
    }

    public getFullyRecovered(): boolean {
        return this.fullyRecovered;
    }

    public setFullyRecovered(fullyRecovered: boolean): void {
        this.fullyRecovered = fullyRecovered;
    }

    public getTreatment(): string {
        return this.treatment;
    }

    public setTreatment(treatment: string): void {
        this.treatment = treatment;
    }

    public getDateFrom(): Date {
        return this.dateFrom;
    }
    
    public setDateFrom(dateFrom: Date): void {
        this.dateFrom = dateFrom;
    }

    public getDateTo(): Date {
        return this.dateTo;
    }

    public setDateTo(dateTo: Date): void {
        this.dateTo = dateTo;
    }

    public getStayedIn(): boolean {
        return this.stayedIn;
    }

    public setStayedIn(stayedIn: boolean): void {
        this.stayedIn = stayedIn;
    }

    public getTotalHospitalExpenses(): number {
        return this.totalHospitalExpenses;
    }

    public setTotalHospitalExpenses(totalHospitalExpenses: number): void {
        this.totalHospitalExpenses = totalHospitalExpenses;
    }
    
    public getTotalClinicalExpenses(): number {
        return this.totalClinicalExpenses;
    }

    public setTotalClinicalExpenses(totalClinicalExpenses: number): void {
        this.totalClinicalExpenses = totalClinicalExpenses;
    }

    public getSumInsured(): number {
        return this.sumInsured;
    }

    public setSumInsured(sumInsured: number): void {
        this.sumInsured = sumInsured;
    }

    public getOutpatientConsultation(): OutpatientConsultDetails {
        return this.outpatientConsultation;
    }

    public setOutpatientConsultation(value: OutpatientConsultDetails) {
        this.outpatientConsultation = value;
    }

    public getWasMedCertIssued(): boolean {
        return this.wasMedCertIssued;
    }

    public setWasMedCertIssued(value: boolean) {
        this.wasMedCertIssued = value;
    }

    public getMedicalCertificates(): MedicalCertificate[] {
        return this.medicalCertificates;
    }

    public setMedicalCertificates(medicalCertificates: MedicalCertificate[]) {
        this.medicalCertificates = medicalCertificates;
    }

    static jsonConvert(ame: AccidentalMedicalExpenses): AccidentalMedicalExpenses {
       
        if (ame != null) {

            if (ame.getHospitalExpenses() != null) {
                let expenses: HospitalExpenseDetails[] = [];
                for (const expense of ame.getHospitalExpenses()) {
                        let item = Object.assign(new HospitalExpenseDetails(), expense);
                        item = HospitalExpenseDetails.jsonConvert(item);
                        expenses.push(item);
                }
                ame.setHospitalExpenses(expenses);
            }
            if (ame.getClinicalExpenses() != null) {
                let expenses: ClinicalExpenseDetails[] = [];
                for (const expense of ame.getClinicalExpenses()) {
                    let item = Object.assign(new ClinicalExpenseDetails(), expense);
                    item = ClinicalExpenseDetails.jsonConvert(item);
                    expenses.push(item);
                }
                ame.setClinicalExpenses(expenses);
            }

            if (ame.getOutpatientConsultation() != null) {
                ame.setOutpatientConsultation(Object.assign(new OutpatientConsultDetails(), ame.getOutpatientConsultation()));
                OutpatientConsultDetails.jsonConvert(ame.getOutpatientConsultation());
            }

            if (ame.getMedicalCertificates() != null) {
                let certs: MedicalCertificate[] = [];
                for (const cert of ame.getMedicalCertificates()) {
                        let item = Object.assign(new MedicalCertificate(), cert);
                        item = MedicalCertificate.jsonConvert(item);
                        certs.push(item);
                }
                ame.setMedicalCertificates(certs);
            }

            if (ame.getDateFrom() != null) {
                ame.setDateFrom(new Date(ame.getDateFrom()));
            }
    
            if (ame.getDateTo() != null) {
                ame.setDateTo(new Date(ame.getDateTo()));
            }

        }
        return ame;
    }
}