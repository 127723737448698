<div class="form-group section">
        <label class="section-title">{{'pageSummaryClaim.claimItem.sectionBaggageDelay.title' | translate}}</label>
        <div class="section-content">
            <div class="sub-section">
                <label>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subBagDelay.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionBaggageDelay.subBagDelay.collectBaggageTime' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDatetime(this.baggageDelay.getCollectBaggageTime())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionBaggageDelay.subBagDelay.countryOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.baggageDelay.getCountryOccurredDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionBaggageDelay.subBagDelay.wasReturnFlight' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                       {{returnYesOrNo(this.baggageDelay.getWasDelayOnReturnFlight())}}
                    </div>
                </div>
            </div>
            <div class="sub-section">
                <label>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subEMPurchesItem.title' | translate}}</label>
                <ng-container *ngFor="let item of this.baggageDelay.getEmergencyPurchasedItemsArray(); let i = index;">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.baggageDelay.detailsOfEmergencyItemsPurchased.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content"
                             *ngIf="item.getEmergencyPurchaseType().toLowerCase() !== 'others'">
                            {{item.getEmergencyPurchaseTypeDesc()}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content"
                             *ngIf="item.getEmergencyPurchaseType().toLowerCase() === 'others'">
                            {{item.getEmergencyPurchaseTypeDetail()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionBaggageDelay.subEMPurchesItem.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionBaggageDelay.subEMPurchesItem.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="sub-section">
                <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
                <div class="row" *ngFor="let item of this.listOfDocuments;">
                  <div class="col col-xs-12 col-lg-12">
                      {{item.description | translate}}
                  </div>
                  <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                      <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                          {{ item.uploadedFileName }}
                      </div>
                  </ng-container>
              </div>
            </div>
            <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
                <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
                <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                  <div class="col col-xs-12 col-lg-12">
                      {{item.description | translate}}
                  </div>
                  <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                      <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                          {{ item.uploadedFileName }}
                      </div>
                  </ng-container>
              </div>
            </div>
    </div>
    
    <ng-template #documentNotUploaded>
        <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
            {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
        </div>
    </ng-template>
    