import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { EmployerDetails } from 'src/app/model/employee-compensation/employer-details.model';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {takeUntil} from 'rxjs/operators';
import {UtilitiesService} from '../../../utilities/utilities.service';

@Component({
    selector: 'app-employer-details',
    templateUrl: './employer-details.component.html'
})
export class EmployerDetailsComponent extends ClaimsBaseComponent implements OnInit {

    employerDetailsForm: FormGroup;
    contractorForm: FormGroup;
    employerDetails: EmployerDetails;
    showTotalBox = false;
    isFromGeneric = false;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService) {
        super(injector);
        this.employerDetails = this.claim.getClaimEmployeeCompensation().getEmployerDetails();
        this.isFromGeneric = this.injector.get(TransactionInfoService).getTransactionInfo().getIsFromGeneric();
    }

    ngOnInit() {
        if (!this.isNotNullOrUndefinedStr(this.employerDetails.getTelephoneNumberCode())){
            this.employerDetails.setTelephoneNumberCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }
        if (!this.isNotNullOrUndefinedStr(this.employerDetails.getContractorTelNumberCode())){
            this.employerDetails.setContractorTelNumberCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }

        this.employerDetailsForm = this.fb.group({
            employerName: [this.employerDetails.getEmployerName()],
            brn: [this.employerDetails.getBrn()],
            telephoneNumber: [this.employerDetails.getTelephoneNumber()],
            telephoneNumberCode: [this.employerDetails.getTelephoneNumberCode()],
            emailAddress: [this.employerDetails.getEmailAddress()],
            faxNumber: [this.employerDetails.getFaxNumber()],
            address: [this.employerDetails.getAddress()],
            trade: [this.employerDetails.getTrade()]
        });

        this.contractorForm = this.fb.group({
            isSubContractor: [super.getBooleanString(this.employerDetails.getIsSubContractor())],
            isSubsidiary: [super.getBooleanString(this.employerDetails.getIsSubsidiary())],
            employerName: [this.employerDetails.getContractorName()],
            brn: [this.employerDetails.getContractorBrn()],
            telephoneNumber: [this.employerDetails.getContractorTelNumber()],
            telephoneNumberCode: [this.employerDetails.getContractorTelNumberCode()],
            emailAddress: [this.employerDetails.getContractorEmail()],
            faxNumber: [this.employerDetails.getContractorFaxNumber()],
            address: [this.employerDetails.getContractorAddress()],
            trade: [this.employerDetails.getContractorTrade()],
        });

    }

    ngAfterViewInit() {
        this.employerDetailsForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.employerDetails.setEmployerName(this.employerDetailsForm.get('employerName').value);
                this.employerDetails.setBrn(this.employerDetailsForm.get('brn').value);
                this.employerDetails.setTelephoneNumber(this.employerDetailsForm.get('telephoneNumber').value);
                this.employerDetails.setTelephoneNumberCode(this.employerDetailsForm.get('telephoneNumberCode').value);
                this.employerDetails.setEmailAddress(this.employerDetailsForm.get('emailAddress').value);
                this.employerDetails.setFaxNumber(this.employerDetailsForm.get('faxNumber').value);
                this.employerDetails.setAddress(this.employerDetailsForm.get('address').value);
                this.employerDetails.setTrade(this.employerDetailsForm.get('trade').value);
            });

        this.contractorForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.employerDetails.setIsSubContractor(this.contractorForm.get('isSubContractor').value == 'true');
                this.employerDetails.setIsSubsidiary(this.contractorForm.get('isSubsidiary').value == 'true');
                this.employerDetails.setContractorName(this.contractorForm.get('employerName').value);
                this.employerDetails.setContractorBrn(this.contractorForm.get('brn').value);
                this.employerDetails.setContractorTelNumber(this.contractorForm.get('telephoneNumber').value);
                this.employerDetails.setContractorTelNumberCode(this.contractorForm.get('telephoneNumberCode').value);
                this.employerDetails.setContractorEmail(this.contractorForm.get('emailAddress').value);
                this.employerDetails.setContractorFaxNumber(this.contractorForm.get('faxNumber').value);
                this.employerDetails.setContractorAddress(this.contractorForm.get('address').value);
                this.employerDetails.setContractorTrade(this.contractorForm.get('trade').value);
            });

        this.contractorForm.get('isSubContractor').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateContractorFields(data);
            this.validateSubsidiaryField(data);
        });

        this.contractorForm.get('isSubsidiary').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateContractorFields(data);
        })

    }

    validateContractorFields(data) {
        if (data && data == 'true') {
            this.contractorForm.get('employerName').enable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('brn').enable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('telephoneNumber').enable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('emailAddress').enable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('faxNumber').enable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('address').enable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('trade').enable({onlySelf: false, emitEvent: false});
        } else if (data == 'false') {
            this.contractorForm.get('employerName').reset();
            this.contractorForm.get('employerName').disable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('brn').reset();
            this.contractorForm.get('brn').disable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('telephoneNumber').reset();
            this.contractorForm.get('telephoneNumber').disable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('emailAddress').reset();
            this.contractorForm.get('emailAddress').disable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('faxNumber').reset();
            this.contractorForm.get('faxNumber').disable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('address').reset();
            this.contractorForm.get('address').disable({onlySelf: false, emitEvent: false});
            this.contractorForm.get('trade').reset();
            this.contractorForm.get('trade').disable({onlySelf: false, emitEvent: false});
        }
    }

    validateSubsidiaryField(data) {
        if (data && data == "true") {
            this.contractorForm.get('isSubsidiary').reset();
            this.contractorForm.get('isSubsidiary').disable({onlySelf: false, emitEvent: false});
        } else {
            this.contractorForm.get('isSubsidiary').enable({onlySelf: false, emitEvent: false});
        }

    }


    validateForm(): boolean {
       let isEmployerFormValid = super.validateForm(this.employerDetailsForm);
       let isContractorFormValid = super.validateForm(this.contractorForm);
       return isEmployerFormValid && isContractorFormValid;
    }
    
    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitComplete('finishConfirmIncidentDetails');
            this.router.navigate(["/claimform/claimDetail"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }
}

