import { AccidentDetails } from "./accident-details.model";
import { PoliceReport } from "./police-report.model";

export class Theft {
    
    private policeReport: PoliceReport = new PoliceReport();
    private accidentDetails: AccidentDetails = new AccidentDetails();


    getPoliceReport(): PoliceReport {
        return this.policeReport;
    }

    setPoliceReport(policeReport: PoliceReport) {
        this.policeReport = policeReport;
    }

    getAccidentDetails(): AccidentDetails {
        return this.accidentDetails;
    }

    setAccidentDetails(accidentDetails: AccidentDetails) {
        this.accidentDetails = accidentDetails;
    }

    static jsonConvert(theft: Theft): Theft {

        if(theft.getAccidentDetails() != null){
            theft.setAccidentDetails(Object.assign(new AccidentDetails(), theft.getAccidentDetails()));
            AccidentDetails.jsonConvert(theft.getAccidentDetails());
        }

        if(theft.getPoliceReport() != null){
            theft.setPoliceReport(Object.assign(new PoliceReport(), theft.getPoliceReport()));
            PoliceReport.jsonConvert(theft.getPoliceReport());
        }

        return theft;
    }

}
