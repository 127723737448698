import { Component, OnInit } from '@angular/core';
import { ClaimDocumentsComponent } from 'src/app/claim-pages/claim-documents/claim-documents.component';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimService } from 'src/app/services/claim.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from 'src/app/services/document-form-factory.service';
import { SpinnerService } from 'src/app/core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Claim } from 'src/app/model/claim.model';
@Component({
  selector: 'app-repatriation-upload-document',
  templateUrl: './repatriation-upload-document.component.html',
  styleUrls: ['./repatriation-upload-document.component.css']
})
export class RepatriationUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

  public upLoadFileModel: FormGroup;
  showTotalBox = false;
  claim: Claim;
  constructor(private fb: FormBuilder,
      public sideMenuService: SideMenuService,
      private router: Router,
      private activatedRoute: ActivatedRoute, 
      claimService: ClaimService,
      documentHelperService: DocumentHelperService,
      transactionInfoService: TransactionInfoService,
      http: HttpClient,
      documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
              super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
              this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]);
              this.claim = this.claimService.getClaim();
  }
  

  ngOnInit() {

    this.pushGAViewRepatriationUpload();

      this.upLoadFileModel = this.fb.group(
          {
              airTicket: [],
              carrierCertificate: [],
              otherItems: this.fb.array([this.createItem()])

          });
          super.ngOnInit();
          this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 8, subMenuIndex: 1, value: "Replacement or Temporary Helper"});
  }

  addFile() {
      let items = this.upLoadFileModel.get('otherItems') as FormArray;
      items.push(this.createItem());
  }

  createItem(): FormGroup {
      return this.fb.group({
          otherFile: [],
      });
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  back() {
    let planCode = this.claim.getPlanCode();
    let planCodeList = ['F3','','F4','M3','M4','M7','M8','N0','N1','N2','N3','N4','N5','Q3','Q4','T1','T2','U3','U4','BA'];

    if(planCodeList.indexOf(planCode) > 1){
        this.router.navigate(["/claimform/repatriation/detailsOfIncident"], {
            relativeTo: this.activatedRoute
        });
    }else{
        this.router.navigate(["/claimform/repatriation/extraCosts"], {
            relativeTo: this.activatedRoute
        });
    }
}

  goToNext() {
      this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 2});
      this.sideMenuService.emitProcess(1, 0);
  }

//Google Analytics
pushGAViewRepatriationUpload() {
    (<any>window).dataLayer.push({
        'pageStep': 'Repatriation – Document Upload',
        'vPath': '/claim/repatriation/documents',
        'event': 'vpageview',
        'ecommerce': {
          'checkout': {
              'actionField': {'step': 6},	//Step6-DocumentUpload
          }
        }                                                                                                                                                              
    });
}  

}
