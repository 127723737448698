import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {StayInHospital} from "../../../../model/travel/stay-in-hospital.model";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-hospital-medical-bills',
    templateUrl: './hospital-medical-bills.component.html',
    styleUrls: ['./hospital-medical-bills.component.css']
})
export class HospitalMedicalBillsComponent extends ClaimsBaseComponent implements OnInit {

    medicalBillForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    stayInHospital: StayInHospital;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.stayInHospital = this.claim.getClaimTravel().getStayInHospital();
    }

    ngOnInit() {

        this.pushGAViewHospitalMedBills();
        if(this.stayInHospital.getMedicalExpenseCurrency() == null){
            this.stayInHospital.setMedicalExpenseCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.medicalBillForm = this.fb.group({
            numberOfTreatmentBill: [this.stayInHospital.getNumberOfTreatmentBill()],
            otherNumOfTreatmentBill: [this.stayInHospital.getOtherNumOfTreatmentBill()],
            medicalExpenseCurrency: [this.stayInHospital.getMedicalExpenseCurrency()],
            medicalExpenseTypeAmount: [this.stayInHospital.getMedicalExpenseTypeAmount()]
        });
    }

    ngAfterViewInit() {

        this.medicalBillForm.valueChanges.subscribe(data => {

            this.stayInHospital.setNumberOfTreatmentBill(super.getValueInComponent('numberOfTreatmentBill'));
            this.stayInHospital.setOtherNumOfTreatmentBill(super.getValueInComponent('otherNumOfTreatmentBill'));
            this.stayInHospital.setMedicalExpenseCurrency(this.medicalBillForm.get('medicalExpenseCurrency').value);
            this.stayInHospital.setMedicalExpenseTypeAmount(super.getValueInComponent('medicalExpenseTypeAmount'));
        });
    }

    uiComponentCreated(fieldId: string) {

        if (fieldId === 'HOS17') {
            this.medicalBillForm.get('numberOfTreatmentBill').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                if (data.toLowerCase() === 'others') {
                    this.medicalBillForm.get('otherNumOfTreatmentBill').enable({onlySelf: false, emitEvent: false});

                } else {
                    this.medicalBillForm.get('otherNumOfTreatmentBill').reset();
                    this.medicalBillForm.get('otherNumOfTreatmentBill').disable({onlySelf: false, emitEvent: false});
                }
            });
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 3});

            this.router.navigate(["/claimform/stayInHospital/hospitalSupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/stayInHospital/followUpTreatment"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.medicalBillForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.medicalBillForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 3});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 5, subMenuIndex: 3});
        }
    }

    //Google Analytics
    pushGAViewHospitalMedBills() {
        (<any>window).dataLayer.push({
            'pageStep': 'Stay Hospital – Medical Bills',
            'vPath': '/claim/stay-hospital/medicalbills',
            'event': 'vpageview',
        });
    }


}
