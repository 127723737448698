<div class="form-group section" >
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionMissConnect.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionMC.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionMC.origArrSchd' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDateTime(missConnection.getOriginalArrivalDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionMC.actArrSchd' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDateTime(missConnection.getActualArrivalDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionMC.countryOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{missConnection.getCountryOccurredDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionMC.reason' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="missConnection.getReasonOfMissConnect() !== 'Other'">
                    {{missConnection.getReasonOfMissConnectDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="missConnection.getReasonOfMissConnect() === 'Other'">
                    {{missConnection.getOtherReasonOfMissConnect()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionEC.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionEC.extraTrans' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    <span *ngIf="missConnection.getHasExtTransCost()">{{missConnection.getExtraTransportationCurrency()}}&nbsp;{{missConnection.getExtraTransportationCost() | number : '1.2-2'}}</span>
                    <span *ngIf="!missConnection.getHasExtTransCost()">{{ 'claimSection.generalLabels.radioButtonLabels.no' | translate }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionEC.extraAccom' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    <span *ngIf="missConnection.getHasExtAccomCost()">{{missConnection.getExtraAccommodationCurrency()}}&nbsp;{{missConnection.getExtraAccommodationCost() | number : '1.2-2'}}</span>
                    <span *ngIf="!missConnection.getHasExtAccomCost()">{{ 'claimSection.generalLabels.radioButtonLabels.no' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>