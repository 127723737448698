<div class="section form-group">
    <label class="section-title">{{ 'motorClaim.selectClaim.damage' | translate }}</label>
    <div class="section-content">
        <!-- Incident Accident Details-->
        <div class="sub-section">
            <label>{{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.description' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.whenAccidentOccured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(claimMotor.getCollission().getAccidentIncidentDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.country' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimMotor.getCollission().getCountryOccurenceDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.placeOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{claimMotor.getCollission().getIncidentAccidentOccur()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.wasVehicleParked' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getCollission().getWasVehicleParkWhenDamage())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.purposeOfUse' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimMotor.getCollission().getPurposeOfUseDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.descriptionOfHow' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{claimMotor.getCollission().getDescriptionOfAccident()}}
                </div>
            </div>
        </div>
        <!-- Driving Details-->
        <div class="sub-section" *ngIf="!claimMotor.getCollission().getWasVehicleParkWhenDamage()">
            <label>{{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.description' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.driverType' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimMotor.getCollission().getDriverTypeDesc()}}
                </div>
            </div>

            <div *ngIf="claimMotor.getCollission().getDriverType() === 'Others'">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.driverName' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{claimMotor.getCollission().getDriverName()}}
                    </div>
                </div>

                <div class="row" *ngIf="isNotNullOrUndefined(claimMotor.getCollission().getDriverAge())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.driverAge' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimMotor.getCollission().getDriverAge()}}
                    </div>
                </div>

                <div class="row" *ngIf="isNotNullOrUndefinedStr(claimMotor.getCollission().getContactNumber())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.driverContactNo' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimMotor.getCollission().getContactNumber()}}
                    </div>
                </div>

                <div class="row" *ngIf="isNotNullOrUndefinedStr(claimMotor.getCollission().getContactEmail())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.driverContactEmail' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimMotor.getCollission().getContactEmail()}}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.validLicense' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getCollission().getHasDriverHadValidLicense())}}
                </div>
            </div>
            <ng-container *ngIf="claimMotor.getCollission().getHasDriverHadValidLicense()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.firstLicense' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimMotor.getCollission().getFirstLicenseDesc()}}        
                    </div>
               </div>
                <div class="row" *ngIf="claimMotor.getCollission().getFirstLicense()?.toLowerCase() == 'less than 3 years'">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.validLicenseDate' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(claimMotor.getCollission().getLicenseIssueDate())}}
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.wasDriverDrunk' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getCollission().getWasDriverDrunk())}}
                </div>
            </div>
            <div class="row" *ngIf="claimMotor.getCollission().getWasDriverDrunk()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.breathTest' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getCollission().getWasBreathTest())}}
                </div>
            </div>
            <div class="row" *ngIf="isNotNullOrUndefined(claimMotor.getCollission().getWasBreathTest()) && claimMotor.getCollission().getWasBreathTest() == true">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.breathTestResult' | translate }}
                    ({{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.microgramsOfAlcohol' | translate }})
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimMotor.getCollission().getBreathTestResult()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.whoCaused' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content" 
                    [ngClass]="claimMotor.getCollission().getWhoCauseTheAccident() === 'Driver of the other Vehicle' ? 'div-sm' : ''">
                    {{claimMotor.getCollission().getWhoCauseTheAccidentDesc()}}
                </div>
            </div>

            <div *ngIf="claimMotor.getCollission().getWhoCauseTheAccident() === 'Driver of the other Vehicle'">
                <div class="row" *ngIf="isNotNullOrUndefinedStr(claimMotor.getCollission().getOtherDriverName())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.driverNameOther' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{claimMotor.getCollission().getOtherDriverName()}}
                    </div>
                </div>

                <div class="row" *ngIf="isNotNullOrUndefinedStr(claimMotor.getCollission().getOtherDriverContactNo())">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.driverDetails.driverContactOther' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimMotor.getCollission().getOtherDriverContactNo()}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Details of the Damage to Insured Vehicle-->
        <div class="sub-section">
            <label>{{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.description' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.claimRepairCost' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getCollission().getWillClaimRepairCost())}}
                </div>
            </div>
            <ng-container *ngIf="claimMotor.getCollission().getWillClaimRepairCost()">
                <div class="row" *ngFor="let item of this.claimMotor.getCollission().getDetailsOfDamagedItemDesc(); let i = index;">
                    <div class="col col-xs-12 col-lg-7" *ngIf="i == 0">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.damageAndExtent' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-7" *ngIf="i != 0"></div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{item}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                            {{ 'motorClaim.claimSection.repair.damageDetails.extentOfDamage' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimMotor.getCollission().getDamageExtentDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                            {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.anyLeaks' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimMotor.getCollission().getIsVehicleHasLeak())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.airbags' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimMotor.getCollission().getHasAirbagGoneOff())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.wheel' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimMotor.getCollission().getHasAnyWheelDamage())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.vehicleDetained' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimMotor.getCollission().getHasBeenDetained())}}
                    </div>
                </div>
                <div class="row" *ngIf="claimMotor.getCollission().getHasBeenDetained()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.vehicleDetainedLocation' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{claimMotor.getCollission().getGovernmentExamLocation()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.currentLocation' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{claimMotor.getCollission().getCurrentLocationDesc()}}
                    </div>
                </div>
                <div class="row" *ngIf="claimMotor.getCollission().getCurrentLocation() === 'Others'">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.currentLocationOthers' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{claimMotor.getCollission().getOtherLocation()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.repairerName' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content" *ngIf="claimMotor.getCollission().getSelectRepairer() != 'Others'">
                        {{claimMotor.getCollission().getSelectRepairerDesc()}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="claimMotor.getCollission().getSelectRepairer() === 'Others'">
                        {{claimMotor.getCollission().getRepairerName()}}
                    </div>
                </div>
                <div class="row" *ngIf="claimMotor.getCollission().getSelectRepairer() === 'Others'">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'motorClaim.claimSection.motorAccidentCollission.vehicleDamageDetails.repairerAddress' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{claimMotor.getCollission().getRepairerAddress()}}
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- Third Party Details -->
        <div class="sub-section">
            <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.description' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.didAccidentInvolve' | translate}}</label>
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getCollission().getHasAccidentInvolve())}}
                </div>
            </div>
            <div class="row" *ngIf="claimMotor.getCollission().getHasAccidentInvolve()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'motorClaim.claimSection.motorAccidentCollission.accidentDetails.paidOrReceived' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getCollission().getHasPaidOrReceived())}}
                </div>
            </div>
            <div class="sub-section" *ngIf="claimMotor.getCollission().getDetailsOfDamagedType().indexOf('Vehicle(s)') !== -1">
                <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.vehicle' | translate}}</label>
                <ng-container *ngFor="let item of claimMotor.getCollission().getVehicleDamageItemList(); let i = index;">
                    <ng-container>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.vehicle' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.vehicleRegNumber' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getVehicleRegNo()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="isNotNullOrUndefinedStr(item.getVehicleOwnerName())">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.vehicleOwnerName' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getVehicleOwnerName()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getVehicleOwnerNumber() != null && item.getVehicleOwnerNumber() != ''">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.vehicleOwnerNumber' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getVehicleOwnerNumber()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getOtherVehicleInsured() != null && item.getOtherVehicleInsured() != ''">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.otherVehicleInsured' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getOtherVehicleInsured()}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="sub-section" *ngIf="claimMotor.getCollission().getDetailsOfDamagedType().indexOf('Person(s)') !== -1">
                <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.injury' | translate}}</label>
                <ng-container *ngFor="let item of claimMotor.getCollission().getInjuredItemList(); let i = index;">
                    <ng-container>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.injury' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonRelationship' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getRelationshipDesc()}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonName' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getInjuredPersonName()}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonAgeGroup' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getAgeGroupDesc()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getInjuredPersonNumber() != null && item.getInjuredPersonNumber() != ''">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonNumber' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getInjuredPersonNumber()}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.extentOfInjury' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getExtentOfInjuryDesc()}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="sub-section" *ngIf="claimMotor.getCollission().getDetailsOfDamagedType().indexOf('Property') !== -1">
                <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.property' | translate}}</label>
                <ng-container *ngFor="let item of claimMotor.getCollission().getPropertyDamageItemList(); let i = index;">
                    <ng-container>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.property' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.hit' | translate }}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getWhatDidYouHit()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getPropertyOwnerName() != null && item.getPropertyOwnerName() != ''">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.propertyOwnerName' | translate }}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getPropertyOwnerName()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getPropertyOwnerNo() != null && item.getPropertyOwnerNo() != ''">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.propertyOwnerNumber' | translate }}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getPropertyOwnerNo()}}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <!-- Police Report -->
        <div class="sub-section">
            <label>{{'motorClaim.driverDetails.policeReport.heading' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.driverDetails.policeReport.hasReportedIncidentToPolice' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claimMotor.getPoliceReport().getHasReportedIncidentToPolice())}}
                </div>
            </div>
            <div *ngIf="claimMotor.getPoliceReport().getHasReportedIncidentToPolice()">
                <div class="row" *ngIf="isNotNullOrUndefined(claimMotor.getPoliceReport().getDateOfReport())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'motorClaim.driverDetails.policeReport.dateOfReport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(claimMotor.getPoliceReport().getDateOfReport())}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(claimMotor.getPoliceReport().getPoliceReportNumber())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'motorClaim.driverDetails.policeReport.policeReportNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{claimMotor.getPoliceReport().getPoliceReportNumber()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'motorClaim.driverDetails.policeReport.policeStation' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{claimMotor.getPoliceReport().getPoliceStation()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'motorClaim.driverDetails.policeReport.hasComplainedAgainstOtherParty' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimMotor.getPoliceReport().getHasComplainedAgainstOtherParty())}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Supporting Documents -->
        <div class="sub-section">
            <label>{{'motorClaim.claimSection.windscreen.documents.description' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>