import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liablity-support-doc',
  templateUrl: './liablity-support-doc.component.html',
  styleUrls: ['./liablity-support-doc.component.css']
})
export class LiablitySupportDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
