import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import { HomeFormBuilderUtil } from "./home-form-builder-util";

export class WeatherFormBuilder extends DocumentFormBuilderComponent implements OnInit {
   
    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        requiredDocuments.push(new DocumentField('WEATHERREPREPINVOICES', 'homeProduct.supportingDocs.types.repairReplacementInvoices', SmartqDocuments.HOME_INVOICE, true));
        requiredDocuments.push(new DocumentField('WEATHERREPRECEIPT', 'homeProduct.supportingDocs.types.possibleRepairReceipt', SmartqDocuments.HOME_REPRECEIPT, true));
        requiredDocuments.push(new DocumentField('WEATHERPURCHRECEIPT', 'homeProduct.supportingDocs.types.purchaseReceiptLoss', SmartqDocuments.HOME_REPINVOICE, true));
        requiredDocuments.push(new DocumentField('WEATHERPHOTOS', 'homeProduct.supportingDocs.types.photoDamagePropertyScene', SmartqDocuments.HOME_PHOTOS, true));
        requiredDocuments.push(new DocumentField('WEATHERREPORT', 'homeProduct.supportingDocs.types.buildingReport', SmartqDocuments.HOME_REPORT, true));
        
        let utilities: HomeFormBuilderUtil = new HomeFormBuilderUtil();
        requiredDocuments = requiredDocuments.concat(utilities.getRiskDocuments(this.claim));
        
        this.requiredDocuments = requiredDocuments;
    }
}