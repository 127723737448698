import {Component, OnInit} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'qnect-ui-multiselect-dropdown',
  templateUrl: './ui-multiselect-dropdown.component.html',
  styleUrls: ['./ui-multiselect-dropdown.component.scss', '../ui-base.component.scss']
})
export class UiMultiSelectDropdownComponent extends UiBaseComponent implements OnInit {

  dropdownList = [];
  selectedList = [];
  dropdownSettings: IDropdownSettings;
  placeHolder = '';
  requiredMultiSelectField: boolean = false;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.placeHolder = this.translateService.instant(this.uiModel.placeholder);
    this.loadMultiSelectDropdownOptions();
  }

  loadMultiSelectDropdownOptions() {
     if (this.uiModel.options) {
         for(let option of this.uiModel.options){
             this.dropdownList.push({value: option.value, label: this.translateService.instant(option.label)});
         }
     }

     this.dropdownSettings = {
         singleSelection: false,
         idField: 'value',
         textField: 'label',
         allowSearchFilter: false,
         enableCheckAll: false,
         limitSelection: this.uiModel.maxlength,
     };

     if (this.formControl.value) {
         for (let formValue of this.formControl.value) {
             this.selectedList.push({value: formValue.value, label: formValue.label});
         }
     }
  }

  setClass() : string {
    if (!this.validateMultiSelect()) {
        return 'validField';
    } else {
        return 'invalidField';
    }
  }

  validateMultiSelect(): boolean {
      return this.formControl.touched && (!this.formControl.value || this.formControl.value?.length == 0);
  }

  getMultiSelectDropdownValue() {
    return this.formControl.value;
  }
}
