import {Injectable} from '@angular/core';

@Injectable()
export class HospitalExpenseHelperService {

    getDatesForValidation(i, fieldType, hospitalExpenses): [Date, Date[][], Date] {
        if(fieldType == 'a'){
            return [null, this.getDates(i, hospitalExpenses), null];
        }
        return [this.getDateAdmission(i, hospitalExpenses), this.getDates(i, hospitalExpenses), null];
    }

    getDateAdmission(i, hospitalExpenses): Date {
        if(hospitalExpenses != null && hospitalExpenses.length > 0) {
            return hospitalExpenses[i].getDateAdmission();
        }   
        return null;     
    }

    getDates(i, hospitalExpenses): Date[][] {
        let completedDates : Date[][] = [];
        if(hospitalExpenses != null && hospitalExpenses.length > 1) {
            for(let x = 0; x < hospitalExpenses.length; x++){
                if(x != i) {
                    let dateOfAdmission = hospitalExpenses[x].getDateAdmission();
                    let dateOfDischarge = hospitalExpenses[x].getDateDischarge();
                    if(dateOfAdmission != null && dateOfDischarge != null && dateOfAdmission < dateOfDischarge) {
                        let dates: Date[] = [];
                        dates.push(dateOfAdmission);
                        dates.push(dateOfDischarge);
                        completedDates.push(dates);
                    }
                }
            }
        }
        return completedDates;     
    }
}