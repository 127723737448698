import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';

@Component({
  selector: 'qnect-utilities-test',
  template: `
    <button type="button" (click)="modal.show()">test</button>
    <qnect-modal-dialog>
      <div class="app-modal-header">
        This is the header
      </div>
      <div class="app-modal-body">
        Whatever content you like, form fields, anything
        <a href="">Some link</a>
      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-default" (click)="modal.hide()">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </qnect-modal-dialog>
  `,
  styles: []
})
export class UtilitiesTestComponent implements OnInit {

  constructor() { }

  @ViewChild(ModalDialogComponent, {static: true})
  public modal: ModalDialogComponent;

  ngOnInit() {
  }

}
