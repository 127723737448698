import {DomesticInfoPlan} from "../model/domestic-helper/domestic-info-plan.model";
import {PlanInfoService} from "./plan-info.service";
import {MotorCoverInfo} from '../model/motor/motor-cover-info.model';
import {ClaimTypes} from '../model/claim-type.model';
import {Injectable} from "@angular/core";
import { UtilitiesService } from "../utilities/utilities.service";

@Injectable()
export class PlanHelperService {

    constructor(private planInfoService: PlanInfoService) {}

    getAvailableDomesticClaimType(planCode: string, catalogCode: string, country: string): string[] {

        // get plan info based on country, plan code and catalogue code
        let domesticPlanDetails = this.getDomesticPlanDetails(planCode, catalogCode, country);
        let availableClaimType = this.getDomesticClaimTypesOnPlan(domesticPlanDetails);
        return availableClaimType;
    }

    getDomesticPlanDetails(planCode: string, catalogCodeInput: string, country: string):  DomesticInfoPlan {

        let domesticInfoPlanList = this.planInfoService.getDomesticPlanInfoByCountry(country);
        let catalogueCode = country.toLowerCase() == "hgi" ? catalogCodeInput : null;

        let domesticInfoPlan = new DomesticInfoPlan();

        if(domesticInfoPlanList.length > 0) {
            domesticInfoPlanList.forEach(function (domesticPlanItem) {
                if (!UtilitiesService.isNullOrUndefined(planCode) && !UtilitiesService.isNullOrUndefined(catalogueCode) && domesticPlanItem.planCode == planCode
                    && domesticPlanItem.catalogCode == catalogueCode) {
                    domesticInfoPlan = domesticPlanItem;
                } else if (!UtilitiesService.isNullOrUndefined(planCode) && UtilitiesService.isNullOrUndefined(catalogueCode) && domesticPlanItem.planCode == planCode) {
                    domesticInfoPlan = domesticPlanItem;
                }
            });
        }
        //console.log("getDomesticPlanDetails(): domesticInfoPlan.planCode:: " + domesticInfoPlan.planCode + 'catalogCodeInput:' + catalogCodeInput);
        return domesticInfoPlan;
    }


    getDomesticClaimTypesOnPlan(domesticInfoPlan : DomesticInfoPlan): string[] {
        let availableClaimTypeList: string[] = [];

        //check domestic claim type is available on the policy plan
        if (!UtilitiesService.isNullOrUndefined(domesticInfoPlan)) {
            if (domesticInfoPlan.clinicalExpenses == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_CLINICAL");
            }
            if (domesticInfoPlan.dentalExpenses == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_DENTAL");
            }
            if (domesticInfoPlan.hospitalizationOfHelper == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_HOSPITAL");
            }
            if (domesticInfoPlan.deathOrPermanentDisability == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_DEATH");
            }
            if (domesticInfoPlan.helpersLiability == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_HELP_LIABILITY");
            }
            if (domesticInfoPlan.employeeCompensation == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_EMP_LIABILITY");
            }
            if (domesticInfoPlan.dishonestyOfHelperLoss == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_DISHONESTY");
            }
            if (domesticInfoPlan.repatriation == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_REPATRIATION");
            }
            if (domesticInfoPlan.otherClaims == "Yes") {
                availableClaimTypeList.push("CLAIM_TYPE_DMH_OTHERS");
            }
        }
        return availableClaimTypeList;
    }

    getEligibleMotorClaimTypes(isPolicyHolder: boolean, contractType: string, riskType: string, coverType: string): string[] {
        let motorCoverInfo = new MotorCoverInfo();
        let eligibleClaimTypes: string[] = [];
        let coverPlanInfoList = this.planInfoService.getMotorCoverInfoList();
        if (coverPlanInfoList.length > 0) {
            motorCoverInfo = coverPlanInfoList.find(coverInfo => coverInfo.isPolicyHolder == isPolicyHolder && coverInfo.contractType == contractType &&
                coverInfo.riskType == riskType && coverInfo.coverType == coverType);
            if (!UtilitiesService.isNullOrUndefined(motorCoverInfo)) {
                eligibleClaimTypes = this.getApplicableMotorClaimTypeList(motorCoverInfo);
            }
        }
        return eligibleClaimTypes;
    }

    getApplicableMotorClaimTypeList(motorCoverInfo : MotorCoverInfo) {
        let availableClaimTypeList: string[] = [];

        if (!UtilitiesService.isNullOrUndefined(motorCoverInfo)) {
            if (motorCoverInfo.windscreen == "Yes") {
                availableClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]);
            }
            if (motorCoverInfo.collision == "Yes") {
                availableClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]);
            }
            if (motorCoverInfo.theft == "Yes") {
                availableClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]);
            }
        }
        return availableClaimTypeList;
    }

}