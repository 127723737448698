import {UiOptionsModel} from "./ui-options.model";
import {Injectable} from '@angular/core';

@Injectable()
export class UICountryService {
  getCountryOptions(): UiOptionsModel[] {
    return [
      {"label":"countryList.AFG"				,"value" :"AFG" },
      {"label":"countryList.ALB"				,"value" :"ALB" },
      {"label":"countryList.DZA"				,"value" :"DZA" },
      {"label":"countryList.ASM"				,"value" :"ASM" },
      {"label":"countryList.AND"				,"value" :"AND" },
      {"label":"countryList.AGO"				,"value" :"AGO" },
      {"label":"countryList.AIA"				,"value" :"AIA" },
      {"label":"countryList.ATA"				,"value" :"ATA" },
      {"label":"countryList.ATG"				,"value" :"ATG" },
      {"label":"countryList.ARG"				,"value" :"ARG" },
      {"label":"countryList.ARM"				,"value" :"ARM" },
      {"label":"countryList.ABW"				,"value" :"ABW" },
      {"label":"countryList.AUS"				,"value" :"AUS" },
      {"label":"countryList.AUT"				,"value" :"AUT" },
      {"label":"countryList.AZE"				,"value" :"AZE" },
      {"label":"countryList.BHR"				,"value" :"BHR" },
      {"label":"countryList.BGD"				,"value" :"BGD" },
      {"label":"countryList.BRB"				,"value" :"BRB" },
      {"label":"countryList.BLR"				,"value" :"BLR" },
      {"label":"countryList.BEL"				,"value" :"BEL" },
      {"label":"countryList.BLZ"				,"value" :"BLZ" },
      {"label":"countryList.BEN"				,"value" :"BEN" },
      {"label":"countryList.BMU"				,"value" :"BMU" },
      {"label":"countryList.BTN"				,"value" :"BTN" },
      {"label":"countryList.BOL"				,"value" :"BOL" },
      {"label":"countryList.BIH"				,"value" :"BIH" },
      {"label":"countryList.BWA"				,"value" :"BWA" },
      {"label":"countryList.BVT"				,"value" :"BVT" },
      {"label":"countryList.BRA"				,"value" :"BRA" },
      {"label":"countryList.IOT"				,"value" :"IOT" },
      {"label":"countryList.BRN"				,"value" :"BRN" },
      {"label":"countryList.BGR"				,"value" :"BGR" },
      {"label":"countryList.BFA"				,"value" :"BFA" },
      {"label":"countryList.BDI"				,"value" :"BDI" },
      {"label":"countryList.KHM"				,"value" :"KHM" },
      {"label":"countryList.CMR"				,"value" :"CMR" },
      {"label":"countryList.CAN"				,"value" :"CAN" },
      {"label":"countryList.CPV"				,"value" :"CPV" },
      {"label":"countryList.CYM"				,"value" :"CYM" },
      {"label":"countryList.CAF"				,"value" :"CAF" },
      {"label":"countryList.TCD"				,"value" :"TCD" },
      {"label":"countryList.CHL"				,"value" :"CHL" },
      {"label":"countryList.CHN"				,"value" :"CHN" },
      {"label":"countryList.CXR"				,"value" :"CXR" },
      {"label":"countryList.CCK"				,"value" :"CCK" },
      {"label":"countryList.COL"				,"value" :"COL" },
      {"label":"countryList.COM"				,"value" :"COM" },
      {"label":"countryList.COD"				,"value" :"COD" },
      {"label":"countryList.COG"				,"value" :"COG" },
      {"label":"countryList.COK"				,"value" :"COK" },
      {"label":"countryList.CRI"				,"value" :"CRI" },
      {"label":"countryList.CIV"				,"value" :"CIV" },
      {"label":"countryList.HRV"				,"value" :"HRV" },
      {"label":"countryList.CUB"				,"value" :"CUB" },
      {"label":"countryList.CYP"				,"value" :"CYP" },
      {"label":"countryList.CZE"				,"value" :"CZE" },
      {"label":"countryList.DNK"				,"value" :"DNK" },
      {"label":"countryList.DJI"				,"value" :"DJI" },
      {"label":"countryList.DMA"				,"value" :"DMA" },
      {"label":"countryList.DOM"				,"value" :"DOM" },
      {"label":"countryList.TLS"				,"value" :"TLS" },
      {"label":"countryList.ECU"				,"value" :"ECU" },
      {"label":"countryList.EGY"				,"value" :"EGY" },
      {"label":"countryList.SLV"				,"value" :"SLV" },
      {"label":"countryList.GNQ"				,"value" :"GNQ" },
      {"label":"countryList.ERI"				,"value" :"ERI" },
      {"label":"countryList.EST"				,"value" :"EST" },
      {"label":"countryList.ETH"				,"value" :"ETH" },
      {"label":"countryList.FLK"				,"value" :"FLK" },
      {"label":"countryList.FRO"				,"value" :"FRO" },
      {"label":"countryList.FJI"				,"value" :"FJI" },
      {"label":"countryList.FIN"				,"value" :"FIN" },
      {"label":"countryList.FRA"				,"value" :"FRA" },
      {"label":"countryList.FXX"				,"value" :"FXX" },
      {"label":"countryList.GUF"				,"value" :"GUF" },
      {"label":"countryList.PYF"				,"value" :"PYF" },
      {"label":"countryList.ATF"				,"value" :"ATF" },
      {"label":"countryList.GAB"				,"value" :"GAB" },
      {"label":"countryList.GMB"				,"value" :"GMB" },
      {"label":"countryList.GEO"				,"value" :"GEO" },
      {"label":"countryList.DEU"				,"value" :"DEU" },
      {"label":"countryList.GHA"				,"value" :"GHA" },
      {"label":"countryList.GIB"				,"value" :"GIB" },
      {"label":"countryList.GRC"				,"value" :"GRC" },
      {"label":"countryList.GRL"				,"value" :"GRL" },
      {"label":"countryList.GRD"				,"value" :"GRD" },
      {"label":"countryList.GLP"				,"value" :"GLP" },
      {"label":"countryList.GUM"				,"value" :"GUM" },
      {"label":"countryList.GTM"				,"value" :"GTM" },
      {"label":"countryList.GIN"				,"value" :"GIN" },
      {"label":"countryList.GNB"				,"value" :"GNB" },
      {"label":"countryList.GUY"				,"value" :"GUY" },
      {"label":"countryList.HTI"				,"value" :"HTI" },
      {"label":"countryList.HMD"				,"value" :"HMD" },
      {"label":"countryList.HND"				,"value" :"HND" },
      {"label":"countryList.HKG"				,"value" :"HKG" },
      {"label":"countryList.HUN"				,"value" :"HUN" },
      {"label":"countryList.ISL"				,"value" :"ISL" },
      {"label":"countryList.IND"				,"value" :"IND" },
      {"label":"countryList.IDN"				,"value" :"IDN" },
      {"label":"countryList.IRN"				,"value" :"IRN" },
      {"label":"countryList.IRQ"				,"value" :"IRQ" },
      {"label":"countryList.IRL"				,"value" :"IRL" },
      {"label":"countryList.ISR"				,"value" :"ISR" },
      {"label":"countryList.ITA"				,"value" :"ITA" },
      {"label":"countryList.JAM"				,"value" :"JAM" },
      {"label":"countryList.JPN"				,"value" :"JPN" },
      {"label":"countryList.JOR"				,"value" :"JOR" },
      {"label":"countryList.KAZ"				,"value" :"KAZ" },
      {"label":"countryList.KEN"				,"value" :"KEN" },
      {"label":"countryList.KIR"				,"value" :"KIR" },
      {"label":"countryList.PRK"				,"value" :"PRK" },
      {"label":"countryList.KOR"				,"value" :"KOR" },
      {"label":"countryList.KWT"				,"value" :"KWT" },
      {"label":"countryList.KGZ"				,"value" :"KGZ" },
      {"label":"countryList.LAO"				,"value" :"LAO" },
      {"label":"countryList.LVA"				,"value" :"LVA" },
      {"label":"countryList.LBN"				,"value" :"LBN" },
      {"label":"countryList.LSO"				,"value" :"LSO" },
      {"label":"countryList.LBR"				,"value" :"LBR" },
      {"label":"countryList.LBY"				,"value" :"LBY" },
      {"label":"countryList.LIE"				,"value" :"LIE" },
      {"label":"countryList.LTU"				,"value" :"LTU" },
      {"label":"countryList.LUX"				,"value" :"LUX" },
      {"label":"countryList.MAC"				,"value" :"MAC" },
      {"label":"countryList.MKD"				,"value" :"MKD" },
      {"label":"countryList.MDG"				,"value" :"MDG" },
      {"label":"countryList.MWI"				,"value" :"MWI" },
      {"label":"countryList.MYS"				,"value" :"MYS" },
      {"label":"countryList.MDV"				,"value" :"MDV" },
      {"label":"countryList.MLI"				,"value" :"MLI" },
      {"label":"countryList.MLT"				,"value" :"MLT" },
      {"label":"countryList.MHL"				,"value" :"MHL" },
      {"label":"countryList.MTQ"				,"value" :"MTQ" },
      {"label":"countryList.MRT"				,"value" :"MRT" },
      {"label":"countryList.MUS"				,"value" :"MUS" },
      {"label":"countryList.MYT"				,"value" :"MYT" },
      {"label":"countryList.MEX"				,"value" :"MEX" },
      {"label":"countryList.FSM"				,"value" :"FSM" },
      {"label":"countryList.MDA"				,"value" :"MDA" },
      {"label":"countryList.MCO"				,"value" :"MCO" },
      {"label":"countryList.MNG"				,"value" :"MNG" },
      {"label":"countryList.MSR"				,"value" :"MSR" },
      {"label":"countryList.MAR"				,"value" :"MAR" },
      {"label":"countryList.MOZ"				,"value" :"MOZ" },
      {"label":"countryList.MMR"				,"value" :"MMR" },
      {"label":"countryList.NAM"				,"value" :"NAM" },
      {"label":"countryList.NRU"				,"value" :"NRU" },
      {"label":"countryList.NPL"				,"value" :"NPL" },
      {"label":"countryList.NLD"				,"value" :"NLD" },
      {"label":"countryList.ANT"				,"value" :"ANT" },
      {"label":"countryList.NCL"				,"value" :"NCL" },
      {"label":"countryList.NZL"				,"value" :"NZL" },
      {"label":"countryList.NIC"				,"value" :"NIC" },
      {"label":"countryList.NER"				,"value" :"NER" },
      {"label":"countryList.NGA"				,"value" :"NGA" },
      {"label":"countryList.NIU"				,"value" :"NIU" },
      {"label":"countryList.NFK"				,"value" :"NFK" },
      {"label":"countryList.MNP"				,"value" :"MNP" },
      {"label":"countryList.NOR"				,"value" :"NOR" },
      {"label":"countryList.OMN"				,"value" :"OMN" },
      {"label":"countryList.PAK"				,"value" :"PAK" },
      {"label":"countryList.PLW"				,"value" :"PLW" },
      {"label":"countryList.PSE"				,"value" :"PSE" },
      {"label":"countryList.PAN"				,"value" :"PAN" },
      {"label":"countryList.PNG"				,"value" :"PNG" },
      {"label":"countryList.PRY"				,"value" :"PRY" },
      {"label":"countryList.PER"				,"value" :"PER" },
      {"label":"countryList.PHL"				,"value" :"PHL" },
      {"label":"countryList.PCN"				,"value" :"PCN" },
      {"label":"countryList.POL"				,"value" :"POL" },
      {"label":"countryList.PRT"				,"value" :"PRT" },
      {"label":"countryList.PRI"				,"value" :"PRI" },
      {"label":"countryList.QAT"				,"value" :"QAT" },
      {"label":"countryList.REU"				,"value" :"REU" },
      {"label":"countryList.ROU"				,"value" :"ROU" },
      {"label":"countryList.RUS"				,"value" :"RUS" },
      {"label":"countryList.RWA"				,"value" :"RWA" },
      {"label":"countryList.KNA"				,"value" :"KNA" },
      {"label":"countryList.LCA"				,"value" :"LCA" },
      {"label":"countryList.VCT"				,"value" :"VCT" },
      {"label":"countryList.WSM"				,"value" :"WSM" },
      {"label":"countryList.SMR"				,"value" :"SMR" },
      {"label":"countryList.STP"				,"value" :"STP" },
      {"label":"countryList.SAU"				,"value" :"SAU" },
      {"label":"countryList.SEN"				,"value" :"SEN" },
      {"label":"countryList.SYC"				,"value" :"SYC" },
      {"label":"countryList.SLE"				,"value" :"SLE" },
      {"label":"countryList.SGP"				,"value" :"SGP" },
      {"label":"countryList.SVK"				,"value" :"SVK" },
      {"label":"countryList.SVN"				,"value" :"SVN" },
      {"label":"countryList.SLB"				,"value" :"SLB" },
      {"label":"countryList.SOM"				,"value" :"SOM" },
      {"label":"countryList.ZAF"				,"value" :"ZAF" },
      {"label":"countryList.SGS"				,"value" :"SGS" },
      {"label":"countryList.ESP"				,"value" :"ESP" },
      {"label":"countryList.LKA"				,"value" :"LKA" },
      {"label":"countryList.SHN"				,"value" :"SHN" },
      {"label":"countryList.SPM"				,"value" :"SPM" },
      {"label":"countryList.SDN"				,"value" :"SDN" },
      {"label":"countryList.SUR"				,"value" :"SUR" },
      {"label":"countryList.SJM"				,"value" :"SJM" },
      {"label":"countryList.SWZ"				,"value" :"SWZ" },
      {"label":"countryList.SWE"				,"value" :"SWE" },
      {"label":"countryList.CHE"				,"value" :"CHE" },
      {"label":"countryList.SYR"				,"value" :"SYR" },
      {"label":"countryList.TWN"				,"value" :"TWN" },
      {"label":"countryList.TJK"				,"value" :"TJK" },
      {"label":"countryList.TZA"				,"value" :"TZA" },
      {"label":"countryList.THA"				,"value" :"THA" },
      {"label":"countryList.TGO"				,"value" :"TGO" },
      {"label":"countryList.TKL"				,"value" :"TKL" },
      {"label":"countryList.TON"				,"value" :"TON" },
      {"label":"countryList.TTO"				,"value" :"TTO" },
      {"label":"countryList.TUN"				,"value" :"TUN" },
      {"label":"countryList.TUR"				,"value" :"TUR" },
      {"label":"countryList.TKM"				,"value" :"TKM" },
      {"label":"countryList.TCA"				,"value" :"TCA" },
      {"label":"countryList.TUV"				,"value" :"TUV" },
      {"label":"countryList.UGA"				,"value" :"UGA" },
      {"label":"countryList.UKR"				,"value" :"UKR" },
      {"label":"countryList.ARE"				,"value" :"ARE" },
      {"label":"countryList.GBR"				,"value" :"GBR" },
      {"label":"countryList.USA"				,"value" :"USA" },
      {"label":"countryList.UMI"				,"value" :"UMI" },
      {"label":"countryList.URY"				,"value" :"URY" },
      {"label":"countryList.UZB"				,"value" :"UZB" },
      {"label":"countryList.VUT"				,"value" :"VUT" },
      {"label":"countryList.VAT"				,"value" :"VAT" },
      {"label":"countryList.VEN"				,"value" :"VEN" },
      {"label":"countryList.VNM"				,"value" :"VNM" },
      {"label":"countryList.VGB"				,"value" :"VGB" },
      {"label":"countryList.VIR"				,"value" :"VIR" },
      {"label":"countryList.WLF"				,"value" :"WLF" },
      {"label":"countryList.ESH"				,"value" :"ESH" },
      {"label":"countryList.YEM"				,"value" :"YEM" },
      {"label":"countryList.YUG"				,"value" :"YUG" },
      {"label":"countryList.ZMB"				,"value" :"ZMB" },
      {"label":"countryList.ZWE"				,"value" :"ZWE" }
    ]
  }

    getCountryDescription(countryId: string): string {
        switch (countryId) {
            case "AFG":
                return "AFGHANISTAN";
            case "ALB":
                return "ALBANIA";
            case "DZA":
                return "ALGERIA";
            case "ASM":
                return "AMERICAN SAMOA";
            case "AND":
                return "ANDORRA";
            case "AGO":
                return "ANGOLA";
            case "AIA":
                return "ANGUILLA";
            case "ATA":
                return "ANTARCTICA";
            case "ATG":
                return "ANTIGUA AND BARBUDA";
            case "ARG":
                return "ARGENTINA";
            case "ARM":
                return "ARMENIA";
            case "ABW":
                return "ARUBA";
            case "AUS":
                return "AUSTRALIA";
            case "AUT":
                return "AUSTRIA";
            case "AZE":
                return "AZERBAIJAN";
            case "BHS":
                return "BAHAMAS";
            case "BHR":
                return "BAHRAIN";
            case "BGD":
                return "BANGLADESH";
            case "BRB":
                return "BARBADOS";
            case "BLR":
                return "BELARUS";
            case "BEL":
                return "BELGIUM";
            case "BLZ":
                return "BELIZE";
            case "BEN":
                return "BENIN";
            case "BMU":
                return "BERMUDA";
            case "BTN":
                return "BHUTAN";
            case "BOL":
                return "BOLIVIA";
            case "BIH":
                return "BOSNIA AND HERZEGOVINA";
            case "BWA":
                return "BOTSWANA";
            case "BVT":
                return "BOUVET ISLAND";
            case "BRA":
                return "BRAZIL";
            case "IOT":
                return "BRITISH INDIAN OCEAN TERRITORY";
            case "BRN":
                return "BRUNEI DARUSSALAM";
            case "BGR":
                return "BULGARIA";
            case "BFA":
                return "BURKINA FASO";
            case "BDI":
                return "BURUNDI";
            case "KHM":
                return "CAMBODIA";
            case "CMR":
                return "CAMEROON";
            case "CAN":
                return "CANADA";
            case "CPV":
                return "CAPE VERDE";
            case "CYM":
                return "CAYMAN ISLANDS";
            case "CAF":
                return "CENTRAL AFRICAN REPUBLIC";
            case "TCD":
                return "CHAD";
            case "CHL":
                return "CHILE";
            case "CHN":
                return "CHINA";
            case "CXR":
                return "CHRISTMAS ISLAND";
            case "CCK":
                return "COCOS (KEELING) ISLANDS";
            case "COL":
                return "COLOMBIA";
            case "COM":
                return "COMOROS";
            case "COD":
                return "CONGO, Democratic Republic of";
            case "COG":
                return "CONGO, REPUBLIC OF";
            case "COK":
                return "COOK ISLANDS";
            case "CRI":
                return "COSTA RICA";
            case "CIV":
                return "COTE D'IVOIRE";
            case "HRV":
                return "CROATIA (local name: Hrvatska)";
            case "CUB":
                return "CUBA";
            case "CYP":
                return "CYPRUS";
            case "CZE":
                return "CZECH REPUBLIC";
            case "DNK":
                return "DENMARK";
            case "DJI":
                return "DJIBOUTI";
            case "DMA":
                return "DOMINICA";
            case "DOM":
                return "DOMINICAN REPUBLIC";
            case "TLS":
                return "EAST TIMOR";
            case "ECU":
                return "ECUADOR";
            case "EGY":
                return "EGYPT";
            case "SLV":
                return "EL SALVADOR";
            case "GNQ":
                return "EQUATORIAL GUINEA";
            case "ERI":
                return "ERITREA";
            case "EST":
                return "ESTONIA";
            case "ETH":
                return "ETHIOPIA";
            case "FLK":
                return "FALKLAND ISLANDS (MALVINAS)";
            case "FRO":
                return "FAROE ISLANDS";
            case "FJI":
                return "FIJI";
            case "FIN":
                return "FINLAND";
            case "FRA":
                return "FRANCE";
            case "FXX":
                return "FRANCE, METROPOLITAN";
            case "GUF":
                return "FRENCH GUIANA";
            case "PYF":
                return "FRENCH POLYNESIA";
            case "ATF":
                return "FRENCH SOUTHERN TERRITORIES";
            case "GAB":
                return "GABON";
            case "GMB":
                return "GAMBIA";
            case "GEO":
                return "GEORGIA";
            case "DEU":
                return "GERMANY";
            case "GHA":
                return "GHANA";
            case "GIB":
                return "GIBRALTAR";
            case "GRC":
                return "GREECE";
            case "GRL":
                return "GREENLAND";
            case "GRD":
                return "GRENADA";
            case "GLP":
                return "GUADELOUPE";
            case "GUM":
                return "GUAM";
            case "GTM":
                return "GUATEMALA";
            case "GIN":
                return "GUINEA";
            case "GNB":
                return "GUINEA-BISSAU";
            case "GUY":
                return "GUYANA";
            case "HTI":
                return "HAITI";
            case "HMD":
                return "HEARD AND MC DONALD ISLANDS";
            case "HND":
                return "HONDURAS";
            case "HKG":
                return "HONG KONG";
            case "HUN":
                return "HUNGARY";
            case "ISL":
                return "ICELAND";
            case "IND":
                return "INDIA";
            case "IDN":
                return "INDONESIA";
            case "IRN":
                return "IRAN (ISLAMIC REPUBLIC OF)";
            case "IRQ":
                return "IRAQ";
            case "IRL":
                return "IRELAND";
            case "ISR":
                return "ISRAEL";
            case "ITA":
                return "ITALY";
            case "JAM":
                return "JAMAICA";
            case "JPN":
                return "JAPAN";
            case "JOR":
                return "JORDAN";
            case "KAZ":
                return "KAZAKHSTAN";
            case "KEN":
                return "KENYA";
            case "KIR":
                return "KIRIBATI";
            case "PRK":
                return "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC (North Korea)";
            case "KOR":
                return "KOREA, REPUBLIC OF (South Korea)";
            case "KWT":
                return "KUWAIT";
            case "KGZ":
                return "KYRGYZSTAN";
            case "LAO":
                return "LAOS";
            case "LVA":
                return "LATVIA";
            case "LBN":
                return "LEBANON";
            case "LSO":
                return "LESOTHO";
            case "LBR":
                return "LIBERIA";
            case "LBY":
                return "LIBYAN ARAB JAMAHIRIYA";
            case "LIE":
                return "LIECHTENSTEIN";
            case "LTU":
                return "LITHUANIA";
            case "LUX":
                return "LUXEMBOURG";
            case "MAC":
                return "MACAU";
            case "MKD":
                return "MACEDONIA, THE FORMER YUGOSLAV";
            case "MDG":
                return "MADAGASCAR";
            case "MWI":
                return "MALAWI";
            case "MYS":
                return "MALAYSIA";
            case "MDV":
                return "MALDIVES";
            case "MLI":
                return "MALI";
            case "MLT":
                return "MALTA";
            case "MHL":
                return "MARSHALL ISLANDS";
            case "MTQ":
                return "MARTINIQUE";
            case "MRT":
                return "MAURITANIA";
            case "MUS":
                return "MAURITIUS";
            case "MYT":
                return "MAYOTTE";
            case "MEX":
                return "MEXICO";
            case "FSM":
                return "MICRONESIA, FEDERATED STATES OF";
            case "MDA":
                return "MOLDOVA, REPUBLIC OF";
            case "MCO":
                return "MONACO";
            case "MNG":
                return "MONGOLIA";
            case "MSR":
                return "MONTSERRAT";
            case "MAR":
                return "MOROCCO";
            case "MOZ":
                return "MOZAMBIQUE";
            case "MMR":
                return "MYANMAR";
            case "NAM":
                return "NAMIBIA";
            case "NRU":
                return "NAURU";
            case "NPL":
                return "NEPAL";
            case "NLD":
                return "NETHERLANDS";
            case "ANT":
                return "NETHERLANDS ANTILLES";
            case "NCL":
                return "NEW CALEDONIA";
            case "NZL":
                return "NEW ZEALAND";
            case "NIC":
                return "NICARAGUA";
            case "NER":
                return "NIGER";
            case "NGA":
                return "NIGERIA";
            case "NIU":
                return "NIUE";
            case "NFK":
                return "NORFOLK ISLAND";
            case "MNP":
                return "NORTHERN MARIANA ISLANDS";
            case "NOR":
                return "NORWAY";
            case "OMN":
                return "OMAN";
            case "PAK":
                return "PAKISTAN";
            case "PLW":
                return "PALAU";
            case "PSE":
                return "PALESTINIAN TERRITORIES";
            case "PAN":
                return "PANAMA";
            case "PNG":
                return "PAPUA NEW GUINEA";
            case "PRY":
                return "PARAGUAY";
            case "PER":
                return "PERU";
            case "PHL":
                return "PHILIPPINES";
            case "PCN":
                return "PITCAIRN";
            case "POL":
                return "POLAND";
            case "PRT":
                return "PORTUGAL";
            case "PRI":
                return "PUERTO RICO";
            case "QAT":
                return "QATAR";
            case "REU":
                return "REUNION";
            case "ROU":
                return "ROMANIA";
            case "RUS":
                return "RUSSIAN FEDERATION";
            case "RWA":
                return "RWANDA";
            case "KNA":
                return "SAINT KITTS AND NEVIS";
            case "LCA":
                return "SAINT LUCIA";
            case "VCT":
                return "SAINT VINCENT AND THE GRENADINES";
            case "WSM":
                return "SAMOA";
            case "SMR":
                return "SAN MARINO";
            case "STP":
                return "SAO TOME AND PRINCIPE";
            case "SAU":
                return "SAUDI ARABIA";
            case "SEN":
                return "SENEGAL";
            case "SYC":
                return "SEYCHELLES";
            case "SLE":
                return "SIERRA LEONE";
            case "SGP":
                return "SINGAPORE";
            case "SVK":
                return "SLOVAKIA (Slovak Republic)";
            case "SVN":
                return "SLOVENIA";
            case "SLB":
                return "SOLOMON ISLANDS";
            case "SOM":
                return "SOMALIA";
            case "ZAF":
                return "SOUTH AFRICA";
            case "SGS":
                return "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS";
            case "ESP":
                return "SPAIN";
            case "LKA":
                return "SRI LANKA";
            case "SHN":
                return "ST. HELENA";
            case "SPM":
                return "ST. PIERRE AND MIQUELON";
            case "SDN":
                return "SUDAN";
            case "SUR":
                return "SURINAME";
            case "SJM":
                return "SVALBARD AND JAN MAYEN ISLANDS";
            case "SWZ":
                return "SWAZILAND";
            case "SWE":
                return "SWEDEN";
            case "CHE":
                return "SWITZERLAND";
            case "SYR":
                return "SYRIAN ARAB REPUBLIC";
            case "TWN":
                return "TAIWAN";
            case "TJK":
                return "TAJIKISTAN";
            case "TZA":
                return "TANZANIA, UNITED REPUBLIC OF";
            case "THA":
                return "THAILAND";
            case "TGO":
                return "TOGO";
            case "TKL":
                return "TOKELAU";
            case "TON":
                return "TONGA";
            case "TTO":
                return "TRINIDAD AND TOBAGO";
            case "TUN":
                return "TUNISIA";
            case "TUR":
                return "TURKEY";
            case "TKM":
                return "TURKMENISTAN";
            case "TCA":
                return "TURKS AND CAICOS ISLANDS";
            case "TUV":
                return "TUVALU";
            case "UGA":
                return "UGANDA";
            case "UKR":
                return "UKRAINE";
            case "ARE":
                return "UNITED ARAB EMIRATES";
            case "GBR":
                return "UNITED KINGDOM";
            case "USA":
                return "UNITED STATES";
            case "UMI":
                return "UNITED STATES MINOR OUTLYING ISLANDS";
            case "URY":
                return "URUGUAY";
            case "UZB":
                return "UZBEKISTAN";
            case "VUT":
                return "VANUATU";
            case "VAT":
                return "VATICAN CITY STATE (HOLY SEE)";
            case "VEN":
                return "VENEZUELA";
            case "VNM":
                return "VIETNAM";
            case "VGB":
                return "VIRGIN ISLANDS (BRITISH)";
            case "VIR":
                return "VIRGIN ISLANDS (U.S.)";
            case "WLF":
                return "WALLIS AND FUTUNA ISLANDS";
            case "ESH":
                return "WESTERN SAHARA";
            case "YEM":
                return "YEMEN";
            case "YUG":
                return "YUGOSLAVIA";
            case "ZMB":
                return "ZAMBIA";
            case "ZWE":
                return "ZIMBABWE";
            default:
                return "";
        }
    }

    getCountryCodeOptions(): UiOptionsModel[] {
        return [
            {"label":"countryCodeList.AFG", "value" : "+93" },
            {"label":"countryCodeList.ALB", "value" : "+355" },
            {"label":"countryCodeList.DZA", "value" : "+213" },
            {"label":"countryCodeList.AND", "value" : "+376" },
            {"label":"countryCodeList.AGO", "value" : "+244" },
            {"label":"countryCodeList.AIA", "value" : "+1 264" },
            {"label":"countryCodeList.ATA", "value" : "+672" },
            {"label":"countryCodeList.ATG", "value" : "+1 268" },
            {"label":"countryCodeList.ARG", "value" : "+54" },
            {"label":"countryCodeList.ARM", "value" : "+374" },
            {"label":"countryCodeList.ABW", "value" : "+297 8" },
            {"label":"countryCodeList.ASC", "value" : "+247" },
            {"label":"countryCodeList.AUS", "value" : "+61" },
            {"label":"countryCodeList.AUT", "value" : "+43" },
            {"label":"countryCodeList.AZE", "value" : "+994" },
            {"label":"countryCodeList.BHS", "value" : "+1 242" },
            {"label":"countryCodeList.BHR", "value" : "+973" },
            {"label":"countryCodeList.BGD", "value" : "+880" },
            {"label":"countryCodeList.BRB", "value" : "+1 246" },
            {"label":"countryCodeList.BLR", "value" : "+375" },
            {"label":"countryCodeList.BEL", "value" : "+32" },
            {"label":"countryCodeList.BLZ", "value" : "+501" },
            {"label":"countryCodeList.BEN", "value" : "+229" },
            {"label":"countryCodeList.BMU", "value" : "+1 441" },
            {"label":"countryCodeList.BTN", "value" : "+975" },
            {"label":"countryCodeList.BOL", "value" : "+591" },
            {"label":"countryCodeList.BIH", "value" : "+387" },
            {"label":"countryCodeList.BWA", "value" : "+267" },
            {"label":"countryCodeList.BRA", "value" : "+55" },
            {"label":"countryCodeList.BRN", "value" : "+673" },
            {"label":"countryCodeList.BGR", "value" : "+359" },

            {"label":"countryCodeList.BFA", "value" : "+226" },
            {"label":"countryCodeList.BDI", "value" : "+257" },
            {"label":"countryCodeList.CPV", "value" : "+238" },
            {"label":"countryCodeList.KHM", "value" : "+855" },
            {"label":"countryCodeList.CMR", "value" : "+237" },
            {"label":"countryCodeList.CAN", "value" : "+1" },
            {"label":"countryCodeList.CYM", "value" : "+1 345" },
            {"label":"countryCodeList.CAF", "value" : "+236" },
            {"label":"countryCodeList.TCD", "value" : "+235" },
            {"label":"countryCodeList.CHT", "value" : "+64" },
            {"label":"countryCodeList.CHL", "value" : "+56" },
            {"label":"countryCodeList.CHN", "value" : "+86" },
            {"label":"countryCodeList.CXR", "value" : "+61 4" },
            {"label":"countryCodeList.CCK", "value" : "+61 89162" },
            {"label":"countryCodeList.COL", "value" : "+57" },
            {"label":"countryCodeList.COM", "value" : "+269" },
            {"label":"countryCodeList.COD", "value" : "+242" },
            {"label":"countryCodeList.COG", "value" : "+243" },
            {"label":"countryCodeList.COK", "value" : "+682" },
            {"label":"countryCodeList.CRI", "value" : "+506" },
            {"label":"countryCodeList.HRV", "value" : "+385" },
            {"label":"countryCodeList.CUB", "value" : "+53" },
            {"label":"countryCodeList.CYP", "value" : "+357" },
            {"label":"countryCodeList.CZE", "value" : "+420" },
            {"label":"countryCodeList.DNK", "value" : "+45" },
            {"label":"countryCodeList.DGO", "value" : "+246 9" },
            {"label":"countryCodeList.DJI", "value" : "+253" },
            {"label":"countryCodeList.DMA", "value" : "+1 767" },
            {"label":"countryCodeList.DOM", "value" : "+1 809" },
            {"label":"countryCodeList.TLS", "value" : "+670" },
            {"label":"countryCodeList.ECU", "value" : "+593" },
            {"label":"countryCodeList.EGY", "value" : "+20" },
            {"label":"countryCodeList.SLV", "value" : "+503" },
            {"label":"countryCodeList.GNQ", "value" : "+240" },
            {"label":"countryCodeList.ERI", "value" : "+291" },
            {"label":"countryCodeList.EST", "value" : "+372" },
            {"label":"countryCodeList.ETH", "value" : "+251" },
            {"label":"countryCodeList.FLK", "value" : "+500" },
            {"label":"countryCodeList.FRO", "value" : "+298" },
            {"label":"countryCodeList.FJI", "value" : "+679" },
            {"label":"countryCodeList.FIN", "value" : "+358" },
            {"label":"countryCodeList.FRA", "value" : "+33" },
            {"label":"countryCodeList.GUF", "value" : "+594" },
            {"label":"countryCodeList.PYF", "value" : "+689" },
            {"label":"countryCodeList.GAB", "value" : "+241" },
            {"label":"countryCodeList.GMB", "value" : "+220" },
            {"label":"countryCodeList.GEO", "value" : "+995" },
            {"label":"countryCodeList.DEU", "value" : "+49" },
            {"label":"countryCodeList.GHA", "value" : "+233" },
            {"label":"countryCodeList.GIB", "value" : "+350" },
            {"label":"countryCodeList.GRC", "value" : "+30" },
            {"label":"countryCodeList.GRL", "value" : "+299" },
            {"label":"countryCodeList.GRD", "value" : "+1 473" },
            {"label":"countryCodeList.GLP", "value" : "+590" },
            {"label":"countryCodeList.GUM", "value" : "+671" },
            {"label":"countryCodeList.GTM", "value" : "+502" },
            {"label":"countryCodeList.GNB", "value" : "+245" },
            {"label":"countryCodeList.GIN", "value" : "+224" },
            {"label":"countryCodeList.GUY", "value" : "+592" },
            {"label":"countryCodeList.HTI", "value" : "+509" },
            {"label":"countryCodeList.HND", "value" : "+504" },
            {"label":"countryCodeList.HKG", "value" : "+852" },
            {"label":"countryCodeList.HUN", "value" : "+36" },
            {"label":"countryCodeList.ISL", "value" : "+354" },
            {"label":"countryCodeList.IND", "value" : "+91" },
            {"label":"countryCodeList.IDN", "value" : "+62" },
            {"label":"countryCodeList.IRQ", "value" : "+964" },
            {"label":"countryCodeList.IRL", "value" : "+353" },
            {"label":"countryCodeList.IRN", "value" : "+98" },
            {"label":"countryCodeList.ISR", "value" : "+972" },
            {"label":"countryCodeList.ITA", "value" : "+39" },
            {"label":"countryCodeList.IVY", "value" : "+225" },
            {"label":"countryCodeList.JAM", "value" : "+1 876" },
            {"label":"countryCodeList.JPN", "value" : "+81" },
            {"label":"countryCodeList.KAZ", "value" : "+7" },
            {"label":"countryCodeList.KEN", "value" : "+254" },
            {"label":"countryCodeList.KIR", "value" : "+686" },
            {"label":"countryCodeList.PRK", "value" : "+850" },
            {"label":"countryCodeList.KOR", "value" : "+82" },
            {"label":"countryCodeList.KWT", "value" : "+965" },
            {"label":"countryCodeList.KGZ", "value" : "+996" },
            {"label":"countryCodeList.LAO", "value" : "+856" },
            {"label":"countryCodeList.LVA", "value" : "+371" },
            {"label":"countryCodeList.LBN", "value" : "+961" },
            {"label":"countryCodeList.LSO", "value" : "+266" },
            {"label":"countryCodeList.LBR", "value" : "+231" },
            {"label":"countryCodeList.LBY", "value" : "+218" },
            {"label":"countryCodeList.LIE", "value" : "+423" },
            {"label":"countryCodeList.LTU", "value" : "+370" },
            {"label":"countryCodeList.LUX", "value" : "+352" },
            {"label":"countryCodeList.MAC", "value" : "+853" },
            {"label":"countryCodeList.MKD", "value" : "+389" },
            {"label":"countryCodeList.MDG", "value" : "+261" },
            {"label":"countryCodeList.MWI", "value" : "+265" },
            {"label":"countryCodeList.MYS", "value" : "+60" },
            {"label":"countryCodeList.MDV", "value" : "+960" },
            {"label":"countryCodeList.MLI", "value" : "+223" },
            {"label":"countryCodeList.MLT", "value" : "+356" },
            {"label":"countryCodeList.MRI", "value" : "+670" },
            {"label":"countryCodeList.MHL", "value" : "+692" },
            {"label":"countryCodeList.MTQ", "value" : "+596" },
            {"label":"countryCodeList.MRT", "value" : "+222" },
            {"label":"countryCodeList.MUS", "value" : "+230" },
            {"label":"countryCodeList.MYT", "value" : "+262 269" },
            {"label":"countryCodeList.MEX", "value" : "+52" },
            {"label":"countryCodeList.FSM", "value" : "+691" },
            {"label":"countryCodeList.MDA", "value" : "+373" },
            {"label":"countryCodeList.MCO", "value" : "+377" },
            {"label":"countryCodeList.MNG", "value" : "+976" },
            {"label":"countryCodeList.MTG", "value" : "+382" },
            {"label":"countryCodeList.MSR", "value" : "+1 664" },
            {"label":"countryCodeList.MAR", "value" : "+212" },
            {"label":"countryCodeList.MOZ", "value" : "+258" },
            {"label":"countryCodeList.MMR", "value" : "+95" },
            {"label":"countryCodeList.NAM", "value" : "+264" },
            {"label":"countryCodeList.NRU", "value" : "+674" },
            {"label":"countryCodeList.NPL", "value" : "+977" },
            {"label":"countryCodeList.NLD", "value" : "+31" },
            {"label":"countryCodeList.ANT", "value" : "+599" },
            {"label":"countryCodeList.NZL", "value" : "+64" },
            {"label":"countryCodeList.NCR", "value" : "+505" },
            {"label":"countryCodeList.NER", "value" : "+227" },
            {"label":"countryCodeList.NGA", "value" : "+234" },
            {"label":"countryCodeList.NFK", "value" : "+672 3" },
            {"label":"countryCodeList.NOR", "value" : "+47" },
            {"label":"countryCodeList.OMN", "value" : "+968" },
            {"label":"countryCodeList.PAK", "value" : "+92" },
            {"label":"countryCodeList.PLW", "value" : "+680" },
            {"label":"countryCodeList.PAN", "value" : "+507" },
            {"label":"countryCodeList.PNG", "value" : "+675" },
            {"label":"countryCodeList.PRY", "value" : "+595" },
            {"label":"countryCodeList.PER", "value" : "+51" },
            {"label":"countryCodeList.PHL", "value" : "+63" },
            {"label":"countryCodeList.POL", "value" : "+48" },
            {"label":"countryCodeList.PRT", "value" : "+351" },
            {"label":"countryCodeList.PRI", "value" : "+1 787" },
            {"label":"countryCodeList.QAT", "value" : "+974" },
            {"label":"countryCodeList.REU", "value" : "+262" },
            {"label":"countryCodeList.ROU", "value" : "+40" },
            {"label":"countryCodeList.RUS", "value" : "+810" },
            {"label":"countryCodeList.RWA", "value" : "+250" },
            {"label":"countryCodeList.KNA", "value" : "+1 869" },
            {"label":"countryCodeList.LCA", "value" : "+1 758" },
            {"label":"countryCodeList.SPM", "value" : "+508" },
            {"label":"countryCodeList.VCT", "value" : "+1 809" },
            {"label":"countryCodeList.ASM", "value" : "+1684" },
            {"label":"countryCodeList.WSM", "value" : "+685" },
            {"label":"countryCodeList.SMR", "value" : "+378" },
            {"label":"countryCodeList.STP", "value" : "+239 12" },
            {"label":"countryCodeList.SAU", "value" : "+966" },
            {"label":"countryCodeList.SEN", "value" : "+221" },
            {"label":"countryCodeList.SRB", "value" : "+381" },
            {"label":"countryCodeList.SYC", "value" : "+248" },
            {"label":"countryCodeList.SLE", "value" : "+232" },
            {"label":"countryCodeList.SGP", "value" : "+65" },
            {"label":"countryCodeList.SVK", "value" : "+421" },
            {"label":"countryCodeList.SVN", "value" : "+386" },
            {"label":"countryCodeList.SLB", "value" : "+677" },
            {"label":"countryCodeList.SOM", "value" : "+252" },
            {"label":"countryCodeList.ZAF", "value" : "+27" },
            {"label":"countryCodeList.SSD", "value" : "+211" },
            {"label":"countryCodeList.ESP", "value" : "+34" },
            {"label":"countryCodeList.LKA", "value" : "+94" },
            {"label":"countryCodeList.SDN", "value" : "+249" },
            {"label":"countryCodeList.SUR", "value" : "+597" },
            {"label":"countryCodeList.SWZ", "value" : "+268" },
            {"label":"countryCodeList.SWE", "value" : "+46" },
            {"label":"countryCodeList.CHE", "value" : "+41" },
            {"label":"countryCodeList.SYR", "value" : "+963" },
            {"label":"countryCodeList.TWN", "value" : "+886" },
            {"label":"countryCodeList.TJK", "value" : "+992" },
            {"label":"countryCodeList.TZA", "value" : "+255" },
            {"label":"countryCodeList.THA", "value" : "+66" },
            {"label":"countryCodeList.TGO", "value" : "+228" },
            {"label":"countryCodeList.TON", "value" : "+676" },
            {"label":"countryCodeList.TTO", "value" : "+1 868" },
            {"label":"countryCodeList.TUN", "value" : "+216" },
            {"label":"countryCodeList.TUR", "value" : "+90" },
            {"label":"countryCodeList.TKM", "value" : "+993" },
            {"label":"countryCodeList.TCA", "value" : "+1 649" },
            {"label":"countryCodeList.TUV", "value" : "+688" },
            {"label":"countryCodeList.UGA", "value" : "+256" },
            {"label":"countryCodeList.UKR", "value" : "+380" },
            {"label":"countryCodeList.ARE", "value" : "+971" },
            {"label":"countryCodeList.GBR", "value" : "+44" },
            {"label":"countryCodeList.USA", "value" : "+1" },
            {"label":"countryCodeList.URY", "value" : "+598" },
            {"label":"countryCodeList.UZB", "value" : "+998" },
            {"label":"countryCodeList.VUT", "value" : "+678" },
            {"label":"countryCodeList.VEN", "value" : "+58" },
            {"label":"countryCodeList.VNM", "value" : "+84" },
            {"label":"countryCodeList.VGB", "value" : "+1 284" },
            {"label":"countryCodeList.VIR", "value" : "+1 340" },
            {"label":"countryCodeList.WLF", "value" : "+681" },
            {"label":"countryCodeList.YEM", "value" : "+967" },
            {"label":"countryCodeList.ZMB", "value" : "+260" },
            {"label":"countryCodeList.ZWE", "value" : "+263" }            
        ]
    }
}
