import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import {UiComponent} from '../../ui.component';

@Directive({selector: '[hkidValidator]'})
export class HkidValidatorDirective implements OnInit, AfterViewInit {

    @Input('hkidValidator') hkidRef: string;         // id type  hkid or passport
    hkidComponent: UiComponent;                      // hkid input field
    @Input() operator: string;

    constructor(private host: UiComponent) {
        this.hkidComponent = host;
    }

    ngOnInit() {
        this.initializeHkidValidator();
    }

    ngAfterViewInit() {
        this.initializeHkidValidator();
    }

    initializeHkidValidator() {
        const hkidComponentType = this.hkidComponent.fieldConfig['type'];

        if (hkidComponentType === 'textfield' || hkidComponentType === 'dropdowntextfield') {
            this.hkidComponent.setupCustomValidatorList([this.validate()]);
            if (this.hkidRef) {
                this.hkidComponent.update();
            }
        } else {
            console.log('claimsEmailValidator not in dropdownselect component. UI Component validator error: ' +
                this.hkidComponent.fieldId + ' - ' + this.hkidComponent.fieldConfig['name']);
        }
    }

    validate = () => {
        return (c: FormControl) => {
            let isValid: boolean = true;
            let hkidValue = !UtilitiesService.isNullOrUndefined(this.hkidComponent.getValue()) ?  this.hkidComponent.getValue() : "" ;

            if (this.hkidRef && hkidValue && this.hkidRef == "hkid" && hkidValue != "") {
                isValid = this.validateHKID(this.hkidComponent.getValue());
            }

            if (!isValid) {
                return {'pattern': true};
            }

            return null;
        }
    };

    validateHKID(hkidInput: string) : boolean {
        var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        // basic check length
        if (hkidInput.length < 8)
            return false;

        // handling paretnthesis
        if (hkidInput.charAt(hkidInput.length - 3) == '(' && hkidInput.charAt(hkidInput.length - 1) == ')')
            hkidInput = hkidInput.substring(0, hkidInput.length - 3) + hkidInput.charAt(hkidInput.length - 2);

        // convert to upper case
        hkidInput = hkidInput.toUpperCase();

        // regular expression to check pattern and split
        var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;
        var matchArray = hkidInput.match(hkidPat);

        // not match, return false
        if (matchArray == null)
            return false;

        // the character part, numeric part and check digit part
        var charPart = matchArray[1];
        var numPart = matchArray[2];//
        var checkDigit = matchArray[3];

        // calculate the checksum for character part
        var checkSum = 0;

        if (charPart.length == 2) {
            checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
            checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
        } else {
            checkSum += 9 * 36;
            checkSum += 8 * (10 + strValidChars.indexOf(charPart));
        }

        // calculate the checksum for numeric part
        for (var i = 0, j = 7; i < numPart.length; i++, j--) {
            // checkSum += j * numPart.charAt(i);
            checkSum += j * Number.parseInt(numPart.charAt(i));
        }

        // verify the check digit
        var remaining = checkSum % 11;
        var verify = remaining == 0 ? 0 : 11 - remaining;

        return verify == Number.parseInt(checkDigit) || (verify == 10 && checkDigit == 'A');
    }

}
