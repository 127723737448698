import {DocumentResponseDisplay} from "./document-response-display-model";

export class ClaimResponseDisplay {

    private claimIndex  : number;
    private claimNumberDisplayLine : string;
    private claimTypesList: string[] = [];
    private documentResponseDisplayList: DocumentResponseDisplay[] = [];
    private claimantName: string;
    private claimantIdentifier: string;
    private policyNumber: string;
    private contactEmail: string;

    getClaimTypesList(): string[] {
        return this.claimTypesList;
    }

    setClaimTypesList(value: string[]) {
        this.claimTypesList = value;
    }

    getClaimNumberDisplayLine(): string {
        return this.claimNumberDisplayLine;
    }

    setClaimNumberDisplayLine(value: string) {
        this.claimNumberDisplayLine = value;
    }

    getClaimIndex(): number {
        return this.claimIndex;
    }

    setClaimIndex(value: number) {
        this.claimIndex = value;
    }

    getDocumentResponseDisplayList(): DocumentResponseDisplay[] {
        return this.documentResponseDisplayList;
    }

    setDocumentResponseDisplayList(value: DocumentResponseDisplay[]) {
        this.documentResponseDisplayList = value;
    }

    getClaimantName(): string {
        return this.claimantName;
    }

    setClaimantName(value: string) {
        this.claimantName = value;
    }

    getClaimantIdentifier(): string {
        return this.claimantIdentifier;
    }

    setClaimantIdentifier(value: string) {
        this.claimantIdentifier = value;
    }

    getPolicyNumber(): string {
        return this.policyNumber;
    }

    setPolicyNumber(value: string) {
        this.policyNumber = value;
    }

    getContactEmail(): string {
        return this.contactEmail;
    }

    setContactEmail(value: string) {
        this.contactEmail = value;
    }

}

