import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DamagePersonalItem} from "../../../../model/travel/damage-personal-item.model";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
  selector: 'app-report-the-damage',
  templateUrl: './report-the-damage.component.html',
  styleUrls: ['./report-the-damage.component.css']
})
export class ReportTheDamageComponent extends ClaimsBaseComponent implements OnInit {

    reportTheLossForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    damagePersonalItem: DamagePersonalItem;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
    }

    ngOnInit() {
        this.pushGAViewDamageItemReport();

        this.damagePersonalItem = this.claim.getClaimTravel().getDamagePersonalItem();

        this.reportTheLossForm = this.fb.group({
            hasAnotherAuthority: [super.getBooleanString(this.damagePersonalItem.getHasAnotherAuthority())],
            authorityType: [this.damagePersonalItem.getAuthorityType()],
            otherAuthorityType: [this.damagePersonalItem.getOtherAuthorityType()],
            hasCompensationReceived: [super.getBooleanString(this.damagePersonalItem.getHasCompensationReceived())],
            compensationCurrency: [this.damagePersonalItem.getCompensationCurrency()],
            compensationValue: [this.damagePersonalItem.getCompensationValue()]

        });
    }

    ngAfterViewInit() {
        this.reportTheLossForm.valueChanges.subscribe(data => {

            if (this.reportTheLossForm.get('hasAnotherAuthority').value != null) {
                this.damagePersonalItem.setHasAnotherAuthority(this.reportTheLossForm.get('hasAnotherAuthority').value == 'true');
            }
            this.damagePersonalItem.setAuthorityType(super.getValueInComponent('authorityType'));
            this.damagePersonalItem.setAuthorityTypeDesc(super.getTextInComponent('authorityType'));
            this.damagePersonalItem.setOtherAuthorityType(super.getValueInComponent('otherAuthorityType'));
            
            if (this.reportTheLossForm.get('hasCompensationReceived').value != null) {
                this.damagePersonalItem.setHasCompensationReceived(this.reportTheLossForm.get('hasCompensationReceived').value == 'true');
            }

            this.damagePersonalItem.setCompensationCurrency(this.reportTheLossForm.get('compensationCurrency').value);
            this.damagePersonalItem.setCompensationValue(super.getValueInComponent('compensationValue'));


            this.validateClaimDetailsComplete();
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {
            self.hideCompensationField(self.getBooleanString(self.damagePersonalItem.getHasCompensationReceived()));
            self.hideAuthorityField(self.getBooleanString(self.damagePersonalItem.getHasAnotherAuthority()));
            self.hideOtherAuthorityField(self.damagePersonalItem.getAuthorityType());

        }, 10);


        this.reportTheLossForm.get('hasCompensationReceived').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideCompensationField(data);
        });

        this.reportTheLossForm.get('hasAnotherAuthority').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideAuthorityField(data);
        });

        this.reportTheLossForm.get('authorityType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOtherAuthorityField(data);
        });

    }

    hideAuthorityField(hasAuthority) {
        if (hasAuthority == "true") {
            this.reportTheLossForm.get('authorityType').enable({onlySelf: false, emitEvent: false});
        } else {
            this.reportTheLossForm.get('authorityType').reset();
            this.reportTheLossForm.get('otherAuthorityType').reset();
            this.reportTheLossForm.get('authorityType').disable({onlySelf: false, emitEvent: false});  this.reportTheLossForm.get('authorityType').reset();
            this.reportTheLossForm.get('otherAuthorityType').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideOtherAuthorityField(hasOtherAuthority: string) {
        if (hasOtherAuthority && hasOtherAuthority.toLowerCase() == "others") {
            this.reportTheLossForm.get('otherAuthorityType').enable({onlySelf: false, emitEvent: false});
        } else {
            this.reportTheLossForm.get('otherAuthorityType').reset();
            this.reportTheLossForm.get('otherAuthorityType').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideCompensationField(hasCompensation) {
        if (hasCompensation == "true") {
            this.reportTheLossForm.get('compensationCurrency').enable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('compensationValue').enable({onlySelf: false, emitEvent: false});
        } else {
            let currency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
            this.reportTheLossForm.get('compensationCurrency').setValue(currency);
            this.reportTheLossForm.get('compensationValue').reset();
            this.reportTheLossForm.get('compensationCurrency').disable({onlySelf: false, emitEvent: false});
            this.reportTheLossForm.get('compensationValue').disable({onlySelf: false, emitEvent: false});
        }
    }

    
    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 2});
    
            this.router.navigate(["/claimform/damagePersonalItem/damageSupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }
    
    back() {
        this.router.navigate(["/claimform/damagePersonalItem/detailsOfDamageItems"], {
            relativeTo: this.activatedRoute
        });
    }
    
    validateForm(): boolean {
        return super.validateForm(this.reportTheLossForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.reportTheLossForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 2, subMenuIndex: 2});
        }
    }

    save() {
        this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 2});
        this.sideMenuService.emitProcess(1, 0);
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewDamageItemReport() {
        (<any>window).dataLayer.push({
            'pageStep': 'Damage Item - Reporting',
            'vPath': '/claim/damage-item/reporting',
            'event': 'vpageview',
        });
    }
}
