import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curtailment-support-doc',
  templateUrl: './curtailment-support-doc.component.html',
  styleUrls: ['./curtailment-support-doc.component.css']
})
export class CurtailmentSupportDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
