export class GenericResponsibility {

    private hasOtherPersonInvolved: boolean = null;
    private contactName: string;
    private contactNumber: string;
    private address: string;
    private description: string;

    getHasOtherPersonInvolved(): boolean {
        return this.hasOtherPersonInvolved;
    }

    setHasOtherPersonInvolved(value: boolean) {
        this.hasOtherPersonInvolved = value;
    }

    getContactName(): string {
        return this.contactName;
    }

    setContactName(value: string) {
        this.contactName = value;
    }

    getContactNumber(): string {
        return this.contactNumber;
    }

    setContactNumber(value: string) {
        this.contactNumber = value;
    }

    getAddress(): string {
        return this.address;
    }

    setAddress(value: string) {
        this.address = value;
    }

    getDescription(): string {
        return this.description;
    }

    setDescription(value: string) {
        this.description = value;
    }
    static jsonConvert(genericResponsibility: GenericResponsibility): GenericResponsibility {
        genericResponsibility = Object.assign(new GenericResponsibility(), genericResponsibility);
        return genericResponsibility;
    }
}