<div class="more-claim-type-body">
  <div class="prev-bar">
    <a (click)="goBack()" class="btn new-btn btn-secondary" style="color: #0064c1"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'moreClaimType.body.title' | translate}}</h5>
    <p>{{ 'moreClaimType.body.description' | translate}}</p>
  </div>
  <div class="row col-12">
    <div class="col-lg-8 col-xl-8 col-md-12">
      <form>
        <div class="form-group">
          <label for="hasMoreClaimType">{{ 'moreClaimType.body.moreClaimLabelText1' | translate}}&nbsp;{{policyNo}}&nbsp;{{ 'moreClaimType.body.moreClaimLabelText2' | translate}}</label>
          <p *ngIf="transactionInfo.isDomesticHelperProduct()">{{ 'moreClaimType.body.moreClaimLabelText3' | translate}} </p><BR>
          <qnect-ui #HAS_MORE_CLAIM_TYPE [fieldId]="'HAS_MORE_CLAIM_TYPE'" [formGroup]="moreClaimTypeForm" name="hasMoreClaimType" id="hasMoreClaimType"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-4 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button  (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
