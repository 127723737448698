import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClaimMotor } from 'src/app/model/claim-motor.model';
import { UiOptionsModel } from 'src/app/ui/ui-options.model';
import { AccidentDetails } from 'src/app/model/motor/accident-details.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-theft-incident-accident-details-theft',
  templateUrl: './theft-incident-accident-details.component.html',
  styleUrls: ['./theft-incident-accident-details.component.css']
})
export class TheftIncidentAccidentDetailsComponent  extends ClaimsBaseComponent implements OnInit {

  incidentAccidentForm: FormGroup;
  claimMotor: ClaimMotor;
  yearOptions: UiOptionsModel[] = [];
  isDefaultValues: boolean = true;
  incidentAccidentDetails: AccidentDetails;
  showTotalBox = false;

  constructor(private fb: FormBuilder,
      private transactionInfoService: TransactionInfoService,
      private claimService: ClaimService,
      private sideMenuService: SideMenuService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private injector: Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.claimMotor = this.claim.getClaimMotor();
      this.incidentAccidentDetails = this.claimMotor.getTheft().getAccidentDetails();
  }

  ngOnInit() {

      this.pushGA();

      if (UtilitiesService.isNullOrUndefined(this.incidentAccidentDetails.getCountry()) || this.incidentAccidentDetails.getCountry() == "") {
        this.incidentAccidentDetails.setCountryDesc("HONG KONG");
        this.incidentAccidentDetails.setCountry('HKG');
    }

    if (!this.incidentAccidentDetails.getDateTime()) {
        this.incidentAccidentDetails.setDateTime(this.claim.getDateOfLoss());
        }

      this.incidentAccidentForm = this.fb.group({
          dateTime: [this.incidentAccidentDetails.getDateTime()],
          location: [this.incidentAccidentDetails.getLocation()],
          country: [this.incidentAccidentDetails.getCountry()],
          accidentDescription: [this.incidentAccidentDetails.getAccidentDescription()]
      });
  }

  ngAfterViewInit() {

      this.incidentAccidentForm.valueChanges.subscribe(data => {
          this.incidentAccidentDetails.setDateTime(super.getValueInComponent('dateTime'));
          this.incidentAccidentDetails.setLocation(this.incidentAccidentForm.get('location').value);
          this.incidentAccidentDetails.setCountry(this.incidentAccidentForm.get('country').value);
          this.incidentAccidentDetails.setCountryDesc(super.getTextInComponent('country'));
          this.incidentAccidentDetails.setAccidentDescription(this.incidentAccidentForm.get('accidentDescription').value);
      });
  }

  goToNext() {
      if (this.validateForm()) {
          this.sideMenuService.emitProcess(1, 0);
          this.sideMenuService.emitClaimComplete({ claimTypeId: 2, subMenuIndex: 0});
          this.router.navigate(["/claimform/theft/policeReport"], {
              relativeTo: this.activatedRoute
          });
      }
  }

  validateForm(): boolean {
      return super.validateForm(this.incidentAccidentForm);
  }

  isDriverNamedOrOthers(driverType: string): boolean {
      if (driverType != "policyholder" &&
          driverType != "unknown" &&
          driverType != "nobody") {
          return true;
      }
      return false;
  }

  showModal() {
      console.log('showModel');
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  back() {
      this.router.navigate(["/claimform/claimDetail/selectClaim"], {
          relativeTo: this.activatedRoute
      });
  }

  // Google Analytics
    pushGA() {
        (<any>window).dataLayer.push({
            'pageStep': 'Theft/Attempt Theft – Incident Details',
            'vPath': '/claim/theft-attempt-theft/incident-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},
                }
            }
        });
    }

}