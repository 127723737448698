import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateService } from "@ngx-translate/core";
import { Injector } from "@angular/core";
import { LOCATION_INITIALIZED } from "@angular/common";
import { Version } from "../version";
import { lastValueFrom } from 'rxjs';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json' + Version.getUrlVersion());
}

export function ApplicationInitializerFactory(
  translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

    try {
      const defaultLang = 'en';
      translate.addLangs(['en', 'zh']);

      let currLang = sessionStorage.getItem("currentLang");
      if (!currLang) {
        currLang = defaultLang;
      }
      
      translate.setDefaultLang(currLang);
      
      await lastValueFrom(translate.use(currLang));
    } catch (err) {
      console.log(err);
    }
  };
}