import { AfterViewInit, Directive, DoCheck, Input } from '@angular/core';
import { UiComponent } from '../ui.component';

@Directive({selector: '[qnectClearValueOnParentValueClear]'})
export class ClearValueOnParentValueClearDirective implements AfterViewInit, DoCheck {


  constructor(private uiComponent: UiComponent) {}

  @Input() qnectClearValueOnParentValueClear: UiComponent[] = [];

  ngAfterViewInit() {
    this.toggleDisplay();
  }

  ngDoCheck() {
    this.toggleDisplay();
  }

  private toggleDisplay() {
    let isAllParentsCleared = true;
    for (let parentUiComponent of this.qnectClearValueOnParentValueClear) {
      if (parentUiComponent.getValue()) {
        isAllParentsCleared = false;
        break;
      }
    }
    if (isAllParentsCleared) {
      this.uiComponent.setValue('');
    }
  }
}
