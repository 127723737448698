<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : !formControl.valid && formControl.touched && !formControl.disabled }"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
  <qnect-ui-label-part *ngIf="!uiModel.hideLabel" [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
  <div [ngClass]="[uiModel.hideLabel ? 'col-md-12' : 'col-md-' + uiModel.size]" class="col-xs-12">
    <select formControlName="{{uiModel.name}}" id="{{fieldId}}" class="form-control dropdown-padding" (change)="setSelectedText()"
            *ngIf="!uiModel.textOnly">
      <option value="null" disabled selected>{{uiModel.placeholder | translate}}</option>
      <option *ngFor="let option of options" value="{{option.value}}">{{option.label | translate}}</option>
    </select>
    <p class="form-control-static" *ngIf="uiModel.textOnly">{{selectedText}}</p>
    <ui-error *ngIf="formControl.touched" [inputErrors]="formControl.errors"
              [dictionary]="uiModel.dictionary"></ui-error>
  </div>
  <span *ngIf="uiModel.popover" class="{{uiModel.popover.icon}} ui-icon" role="button"
        [popover]="uiModel.popover.content"
        placement="right" triggers="mouseenter:mouseleave"></span>
</div>
