<!-- other claim -->
<div class="form-group section">
    <label class="section-title">{{ 'pageSummaryClaim.claimItem.sectionOther.title' | translate }}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionOther.subSectionDetails.dateOfEvent' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(otherClaim.getOtherDateOfIncident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionOther.subSectionDetails.countryOccurred' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="otherClaim.getOtherCountryIncidentOccurredDescription()">
                    {{otherClaim.getOtherCountryIncidentOccurredDescription()}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="otherClaim.getPlaceOfLossDesc()">
                    {{otherClaim.getPlaceOfLossDesc()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionDescription.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionOther.subSectionDescription.reason' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{otherClaim.getOtherIncidentDescription()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionOther.subSectionDescription.amountClaimed' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{otherClaim.getOtherCurrency()}}
                    {{otherClaim.getOtherAmount() | number : '1.2-2'}}

                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
    </div>
    <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
        <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
        <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
          <div class="col col-xs-12 col-lg-12">
              {{item.description | translate}}
          </div>
          <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
              <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                  {{ item.uploadedFileName }}
              </div>
          </ng-container>
      </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>