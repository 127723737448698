import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ClaimsBaseComponent} from "../../claim-pages/claim-base.component";
import {ClaimSearchService} from "../../services/claim-search.service";
import {ClaimSearchResult} from "../../model/search-claim/claim-search-result.model";
import {DatePipe} from "@angular/common";
import * as cloneDeep from "lodash/cloneDeep";
import { FormGroup, FormControl } from '@angular/forms';
import {finalize, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {TransactionInfoService} from '../../services/transaction-info.service';
import {Notifications} from '../../utilities/components/notification-messages/notifications.model';
import {TransactionInfo} from '../../model/transaction-info.model';
import {ClaimDetails} from '../../model/search-claim/claim-details.model';
import {ClaimProductService} from '../../services/claim-product.service';
import {DocumentHelperService} from '../../services/document-helper.service';
import {DocumentSearchResult} from '../../model/search-claim/document-search-result.model';
import * as FileSaver from 'file-saver';
import {SpinnerService} from '../../core/spinner/spinner.service';
import {UiOptionsModel} from '../../ui/ui-options.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';
import { SignInstructionModalComponent } from 'src/app/utilities/components/sign-instruction-modal/sign-instuction-modal';


@Component({
  selector: 'claim-status-summary',
  templateUrl: './claim-status-summary.component.html',
  styleUrls: ['./claim-status-summary.component.css']
})
export class ClaimStatusSummaryComponent extends ClaimsBaseComponent implements OnInit {

    claimSearchResult: ClaimSearchResult;
    claimSearchDetailList: ClaimDetails[] = [];
    filterDuration: number = 0;
    filterStatus: string = "";
    claimsSearchResultsFormGroup: FormGroup;
    notifications: Notifications = new Notifications();
    transactionInfo: TransactionInfo;
    productDescription: string = "";
    uiOptions: UiOptionsModel[] = [];
    documentToken: string = "";

    @ViewChild('sign_instruction_modal', {static: true}) 
    instructionModal: SignInstructionModalComponent;

    private spinnerService: SpinnerService;
    private documentHelperService: DocumentHelperService;

    constructor(private injector : Injector,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
           super(injector);
           this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
           this.claimSearchResult = this.injector.get(ClaimSearchService).getClaimStatusResult();
           this.productDescription =  this.injector.get(ClaimProductService).getProductLabel(
               this.claimSearchResult.getProductType(), this.claimSearchResult.getCatalogueCode());
           this.spinnerService = this.injector.get(SpinnerService);
           this.documentHelperService = this.injector.get(DocumentHelperService);
           this.claimSearchDetailList = this.getFreshClaimSearchDetailsList();
           this.sortClaimSearchDetailsListByDate();
           this.notifications.clearMessages();
           this.notifications.showPreamble = false;
           this.getUiStatusOptions();
           this.getClaimDocuments();
    }

    ngOnInit() {

        this.claimsSearchResultsFormGroup = new FormGroup({
           filterDuration: new FormControl(),
           filterStatus: new FormControl()
        });

         if (this.claimSearchDetailList == null || this.claimSearchDetailList.length == 0) {
             const noClaimFoundMsg = "claimStatus.error_messages.noClaimFound";
             this.notifications.addErrorMessage(noClaimFoundMsg);
         }

        this.pushGAClaimStatus();
    }

    ngAfterViewInit() {
      this.claimsSearchResultsFormGroup.get('filterDuration').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.filterDuration = this.claimsSearchResultsFormGroup.get('filterDuration').value;
      });
      this.claimsSearchResultsFormGroup.get('filterStatus').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.filterStatus = this.claimsSearchResultsFormGroup.get('filterStatus').value;
      });
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "d MMMM y");
        }
        return "";
    }

    getValue(value){
        if (value === undefined || value === null || value === ""){
            return '';
        }
        return value;
    }

    filterByDate(){
      this.claimSearchDetailList = this.claimSearchDetailList.filter(a=> this.isWithinDuration(a.getDateOfLoss(), this.filterDuration));
    }

    isWithinDuration(dateOfClaim: Date, monthsDuration: number): boolean{
      if(dateOfClaim != null && dateOfClaim != undefined){
        let today =  new Date();
        let monthDifference = (today.getMonth() - dateOfClaim.getMonth() +
        (12 * (today.getFullYear() - dateOfClaim.getFullYear()))) ;
        return monthDifference<= monthsDuration;
      }
      return false;
    }

    filterByStatus(){
      this.claimSearchDetailList = this.claimSearchDetailList.filter(a=> a.getQnectStatus() != undefined && a.getQnectStatus().toLocaleUpperCase() === this.filterStatus.toLocaleUpperCase());
    }

    filterResults(){
      this.claimSearchDetailList = this.getFreshClaimSearchDetailsList();
      if(this.filterDuration > 0){
          this.filterByDate();
        }
      if(this.filterStatus != "" && this.filterStatus != "ALL"){
          this.filterByStatus();
      }
      this.sortClaimSearchDetailsListByDate();
    }

    getFreshClaimSearchDetailsList(): ClaimDetails[] {
      return cloneDeep(this.claimSearchResult.getClaimDetailsList()); 
    }

    sortClaimSearchDetailsListByDate(){
      this.claimSearchDetailList.sort(
        (a,b) => (a.getDateOfLoss() < b.getDateOfLoss()) ? 1 : ((b.getDateOfLoss() < a.getDateOfLoss()) ? -1 : 0));
    }
    
    goToHomePage() {
        this.pushGAOnClick();
        this.transactionInfo.setPolicyNumber(null);
        this.transactionInfo.setClaimNumber(null);
        this.router.navigate(['/search-claim-homepage'], {
            relativeTo: this.activatedRoute
        });
        return false;
    }


    getClaimDocuments() {
        if (this.claimSearchDetailList.length > 0) {
            this.documentHelperService.getClaimDetailsDocuments(this.claimSearchResult.getClaimToken())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (docsSearchResult: any) => {
                        if (docsSearchResult.getDocuments().size > 0) {
                            this.mapDocuments(docsSearchResult);
                            this.documentToken = docsSearchResult.getDocumentToken();
                        }
                    },
                    error: () => {
                        throw new Error("Error retrieving claim documents");
                    }
                });
        }
    }

    mapDocuments(docsSearchResult: DocumentSearchResult) {
        let claimDetailsList = this.claimSearchDetailList;
        claimDetailsList.forEach((claimDetails)=>{
            claimDetails.setDocuments(docsSearchResult.getDocuments().get(claimDetails.getClaimNumber()));
        });
        this.claimSearchResult.setClaimDetailsList(claimDetailsList);
    }

    saveAsFile(dDocName: string, docType: string) {
        this.spinnerService.displayLoader(true);
        let filename: string =  docType + '.pdf';
        let claimToken = this.claimSearchResult.getClaimToken();
        this.documentHelperService.downloadDocument(claimToken, dDocName, this.documentToken)
            .pipe(finalize(() => {
                this.spinnerService.displayLoader(false);
            }))
            .subscribe({
                next: (data: any) => {
                    if(this.isIos()){
                        let fileURL = URL.createObjectURL(data);
                        window.open(fileURL, '_blank');
                    } else{
                        FileSaver.saveAs(data, filename);
                    }
                },
                error: () => {
                    throw new Error("Error retrieving file");
                }
            });
    }


    getDescriptionByDocType(docType: string) {
      if (docType) {
          if (docType.toLowerCase() == 'claim details') {
              return "claimStatus.summary.claimLodgement";
          } else {
              return "claimStatus.summary." + docType.replace(/\s/g, "").toLowerCase();
          }
      }
      return "";
    }

    isIos(): boolean{
        var iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator',
            'iPad', 'iPhone', 'iPod'];

        if (!!navigator.platform) {
            while (iDevices.length) {
                if (navigator.platform === iDevices.pop()){ return true; }
            }
        }

        return false;
    }

    getUiStatusOptions() {
        this.claimSearchDetailList.forEach(element => {
            if (!(this.uiOptions.find(x => x.value === element.getQnectStatus()))) {
                const option = new UiOptionsModel(element.getQnectStatus(), element.getStatusDesc());
                this.uiOptions.push(option);
            }
        });
        this.uiOptions.push(new UiOptionsModel("ALL", "claimStatus.filters.allClaims"));
    }

    //Google Analytics
    pushGAOnClick() {
        (<any>window).dataLayer.push({'event': 'checkanother'});
    }

    pushGAClaimStatus() {

        let transactionInfo = this.transactionInfo;
        let policyNumber = this.claimSearchResult.getPolicyNumber();
        let policyType = this.claimSearchResult.getPolicyType();
        let brand: string = "";
        let authMethod: string = "";
        let producType: string = "";

        if (this.claimSearchResult.getCountry() == "HKG"){
            if (this.claimSearchResult.getIsManulife()){
                brand = "MIL";
            } else {
                brand = "HKSI";
            }
        } else if(this.claimSearchResult.getCountry() == "HGI") {
            brand = "QGI";
        }

       if (policyType && policyType.toLowerCase() == "travel") {
           producType = "Travel";
           if (!UtilitiesService.isNullOrUndefined(transactionInfo.getDateOfBirth())) {
               authMethod =  "Traveller DOB";
           } else {
               authMethod =  "Traveller Name";
           }
       } else if (policyType && policyType.toLowerCase() == "domestichelper") {
           producType = "Helper";
           if (!UtilitiesService.isNullOrUndefined(transactionInfo.getAuthIdNumber()) && transactionInfo.getAuthIdNumber() != "") {
               authMethod =  "Helper ID";
           } else {
               authMethod =  "Employer Name";
           }
       }
       
        (<any>window).dataLayer.push({
            'event': 'vpageview',
            'pageStep': 'Finish',
            'vPath':'/finish',
            'ecommerce': {
                'purchase': {
                    'actionField': {'id': policyNumber,},
                    'products': [{'name': producType, 'brand': brand, 'dimension1': authMethod, 'quantity': 1}]
                }
            }
        });

    }

}
