export class VehicleItemDetail {

    private vehicleRegNo: string;
    private vehicleOwnerName: string;
    private vehicleOwnerNumber: string;
    private otherVehicleInsured: string;

    getVehicleRegNo(): string {
        return this.vehicleRegNo;
    }

    setVehicleRegNo(vehicleRegNo: string) {
        this.vehicleRegNo = vehicleRegNo;
    }

    getVehicleOwnerName(): string {
        return this.vehicleOwnerName;
    }

    setVehicleOwnerName(vehicleOwnerName: string) {
        this.vehicleOwnerName = vehicleOwnerName;
    }

    getVehicleOwnerNumber(): string {
        return this.vehicleOwnerNumber;
    }

    setVehicleOwnerNumber(vehicleOwnerNumber: string) {
        this.vehicleOwnerNumber = vehicleOwnerNumber;
    }

    getOtherVehicleInsured(): string {
        return this.otherVehicleInsured;
    }

    setOtherVehicleInsured(otherVehicleInsured: string) {
        this.otherVehicleInsured = otherVehicleInsured;
    }

}