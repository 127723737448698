<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
            <h5>{{ 'paClaim.outpatient.title' | translate }}</h5>
        <br>
    </div>
    <div class="row col-12">
        <div class="col-lg-12 col-md-12">
            <form>
                <div class="form-group">
                    <qnect-ui #PA_ICU_HASOUTPATIENTCONSULTATIONS [fieldId]="'PA_ICU_HASOUTPATIENTCONSULTATIONS'" [formGroup]="outpatientForm" name="hasOutpatientConsultations"></qnect-ui>
                </div>
            </form>
        </div>
    </div>
    <!-- clinical expenses -->
    <ng-container *ngIf="isNotNullOrUndefined(this.outpatientConsultation.getHasOutpatientConsultations()) &&  this.outpatientConsultation.getHasOutpatientConsultations()">
        <div class="row col-12">
            <div class="col-xl-9 col-lg-12">
                <form [formGroup]="clinicalExpenseForm">
                    <div formArrayName="items" *ngFor="let item of clinicalExpenseForm.get('items')['controls']; let i = index;">
                        <div [formGroupName]="i">
                            <div class="form-group click-more-content">
                                <label>{{'paClaim.common.clinicalExpenseTitle' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeItem(i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>

                                <qnect-ui [fieldId]="'PA_DATE_VISIT'"
                                          [frmArrayGroup]="clinicalExpenseForm"
                                          [frmArrayName]="'items'"
                                          frmArrayIndex="{{i}}"
                                          [claimsDateValidator]="claimPA.getAccidentDetails().getAccidentDate()"
                                          name="dateVisit"></qnect-ui>

                                <qnect-ui [fieldId]="'PA_MEDICAL_ATTENTION'"
                                          [frmArrayGroup]="clinicalExpenseForm"
                                          [frmArrayName]="'items'"
                                          frmArrayIndex="{{i}}"
                                          name="medicalAttention"></qnect-ui>

                                <ng-container *ngIf="this.clinicalExpenseItems !== null && this.clinicalExpenseItems.length > 0 &&
                                     this.clinicalExpenseItems[i].getMedicalAttention() === 'Others'">
                                    <qnect-ui [fieldId]="'PA_MEDICAL_ATTENTION_OTHERS'"
                                              [frmArrayGroup]="clinicalExpenseForm"
                                              [frmArrayName]="'items'"
                                              frmArrayIndex="{{i}}"
                                              name="medicalAttentionOthers"></qnect-ui>
                                </ng-container>

                                <qnect-ui [fieldId]="'PA_CLINICAL_EXPENSE'"
                                          [frmArrayGroup]="clinicalExpenseForm"
                                          [frmArrayName]="'items'"
                                          frmArrayIndex="{{i}}"
                                          name="clinicalExpense"></qnect-ui>

                            </div>
                            <div class="col-xl-2 col-lg-12">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </form>
                <div class="addFile" (click)="addItem()">
                    <em class="fas fa-plus"></em>
                    <span>{{ 'paClaim.accidentalMedicalExpenses.addVisitDetails' | translate}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-xl-3 col-md-12">
                &nbsp;
            </div>
        </div>
        <div class="col-lg-3 col-xl-3 col-md-12">
            &nbsp;
        </div>
        <div class="row col-12">
            <div class="col-xl-12 col-lg-12">
                <div class="form-group">
                    <qnect-ui #PA_FULLY_RECOVERED [fieldId]="'PA_FULLY_RECOVERED'" [formGroup]="clinicalExpenseForm" name="fullyRecovered"></qnect-ui>
                </div>
                <div class="form-group" *ngIf="this.outpatientConsultation.getFullyRecovered() === false">
                    <qnect-ui #PA_TREATMENT [fieldId]="'PA_TREATMENT'" [formGroup]="clinicalExpenseForm" name="treatment"></qnect-ui>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- clinical expenses -->

    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>