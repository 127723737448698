import {Component, Injector, OnInit} from "@angular/core";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {LostPersonalItem} from "src/app/model/travel/lost-personal-item.model";
import { ClaimTypes } from "../../../../model/claim-type.model";
import { DocumentField } from "../../../../model/document-field";
import { DocumentHelperService } from "../../../../services/document-helper.service";
import {DepreciationService} from '../../../../services/depreciation.service';
import * as moment from "moment";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import {SmartqDocuments} from "../../../../model/smartq-documents.model";
@Component({
  selector: 'app-summary-lost-personal-item',
  templateUrl: './summary-lost-personal-item.component.html',
  styleUrls: ['./summary-lost-personal-item.component.css']
})
export class SummaryLostPersonalItemComponent extends ClaimsBaseComponent implements OnInit {
    
    claim: Claim;
    claimTravel: ClaimTravel;
    lostPersonalItem: LostPersonalItem;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private depreciationService: DepreciationService,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.claimTravel = this.claim.getClaimTravel();
            this.lostPersonalItem = this.claim.getClaimTravel().getLostPersonalItem();
            this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]);
            this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]);
    }

    ngOnInit() {
        if(this.claim.getCountry() === 'HKG' || this.claim.getCountry() === 'HGI'){
            this.getDepreciationRateForItems();
        }
    }

    getDepreciationRateForItems() {
        let lostItemsList = this.lostPersonalItem.getDetailsOfLostItemsArray();
        let dateOfDamage = moment(this.lostPersonalItem.getDateTheDamageOccurred(), 'DD/MM/YYYY');
        let country = this.claim.getCountry();

        if (!UtilitiesService.isNullOrUndefined(dateOfDamage) && lostItemsList.length > 0) {
            for (let i = 0; i < lostItemsList.length; i++) {
                let purchasedDate = lostItemsList[i].getPurchaseDate();
                let item =  lostItemsList[i].getLossType();
                let depreciationRate = "NA";
                if (!UtilitiesService.isNullOrUndefined(purchasedDate) && !UtilitiesService.isNullOrUndefined(lostItemsList[i].getPurchasePrice()) &&
                    lostItemsList[i].getPurchasePrice() > 0 && this.isNotNullOrUndefinedStr(item)) {

                    let startDate = moment(purchasedDate, 'DD/MM/YYYY');
                    let diffInYears = dateOfDamage.diff(startDate, 'year');
                    startDate.add(diffInYears, 'years');
                    let diffInMonths = dateOfDamage.diff(startDate, 'months');
                    startDate.add(diffInMonths, 'months');
                    let diffInDays = dateOfDamage.diff(startDate, 'days');
                    let totalMonths = diffInMonths + (12 * diffInYears);
                    let totalDays = diffInDays;

                    // special case for item hgi and specific agent codes
                    if (this.isNotNullOrUndefinedStr(country) && country.toLowerCase() == "hgi") {
                        let agentCode = this.claim.getAgentCode();
                        let isApplicableToPlan: boolean = this.depreciationService.isApplicableToQgiPlan(this.claim.getCatalogueCode(), this.claim.getPlanCode(),
                            this.claim.getClaimTravel().getAreaCode(), this.claim.getClaimTravel().getPeriodOfInsurance());
                        if (['011RP091','011RP092', '011RP093'].indexOf(agentCode) !== -1 && isApplicableToPlan) {
                            depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST], item);
                            if (item.toLowerCase() == "mobile phone") {
                                lostItemsList[i].setExcessDepreciation(20); // Apply 20% excess on mobile phones
                            }
                        } else  {
                            // actual depreciation rule is applied only for qgi with more than 2 years of purchase
                            if (!(totalMonths <= 24)) {
                                depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST], item);
                            } else {
                                depreciationRate = "0";
                            }
                        }

                    } else {
                        depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST], item);
                    }

                    // if there is no purchase receipt attached, need to apply the additional 10% deduction to the actual depreciation rate
                    if (this.isNotNullOrUndefined(lostItemsList[i].getHasOriginalPurchasedReceipt())) {
                        if ((lostItemsList[i].getHasOriginalPurchasedReceipt() == true &&
                            !this.listOfDocuments.find(document => document.smartqField === SmartqDocuments.PURCHASERCPT &&
                                this.isNotNullOrUndefined(document.uploadedFileName))) ||
                            (lostItemsList[i].getHasOriginalPurchasedReceipt() == false) && depreciationRate !== 'NA'){
                            depreciationRate = (parseFloat(depreciationRate) + 10).toString();
                        }
                    }

                    if (depreciationRate == "0") {
                        depreciationRate = "NA";
                    }
                }
                lostItemsList[i].setDepreciationRate(depreciationRate);
            }
        }
    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if(!value){
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}
