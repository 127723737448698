import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import { ClaimDocumentsComponent } from "../../claim-documents/claim-documents.component";
import { SideMenuService } from "src/app/services/side-menu.service";
import { ClaimService } from "src/app/services/claim.service";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { DocumentFormFactoryService } from "src/app/services/document-form-factory.service";
import { SpinnerService } from "src/app/core/spinner/spinner.service";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionInfo } from "src/app/model/transaction-info.model";

@Component({
  selector: 'app-ec-upload-document',
  templateUrl: './ec-upload-document.component.html',
  styleUrls: ['./ec-upload-document.component.css']
})

export class ECUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    public upLoadFileModel: FormGroup;
    showTotalBox = false;
    showForm2DownloadLinks = false;
    transactionInfo: TransactionInfo;
    
    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService, 
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                documentFormFactoryService: DocumentFormFactoryService, 
                spinnerService: SpinnerService, 
                modalService: NgbModal, 
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
        this.setCurrentClaimType(this.claimService.getClaim().getSelectedClaimTypesList()[0]);
        this.transactionInfo = transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                otherItems: this.fb.array([this.createItem()])

            });
            super.ngOnInit();
            if(this.formBuilder != null && this.formBuilder.getRequiredDocuments() != null && this.formBuilder.getRequiredDocuments().findIndex((doc)=>{return doc.getId() === "ECFORMTWO"}) > -1){
                this.showForm2DownloadLinks = true;
            }
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: [],
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        let claimTypeItem = this.transactionInfo.getClaimTypeByCode('current');
        let route = this.transactionInfo.getRouteByCurrentUrl('/supportingDoc', false);
        this.sideMenuService.emitClaimComplete({claimTypeId: claimTypeItem?.claimTypeId, subMenuIndex: route?.routeIndex});
        this.sideMenuService.emitProcess(1, 0);
        this.router.navigate(["/claimform/anotherInsurance"], {
            relativeTo: this.activatedRoute
        });
    }

    goBack() {
        let route = this.transactionInfo.getRouteByCurrentUrl('/supportingDoc', false);
        this.router.navigate([route.routeUrl], {
            relativeTo: this.activatedRoute
        });
    }
}
