import {Directive, EventEmitter, Input, OnDestroy, Output} from "@angular/core";
import {AbstractControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subject} from "rxjs";

import {UiModel, UiPopoverModel} from './ui.model';
import {UiCustomValidatorFactory} from "./validators/ui-custom-validator.factory";
import {UiValidators} from "./validators/ui-validator";
import {UiFormService} from "./ui-form.service";
import {UiOptionsModel} from "./ui-options.model";
import {UtilitiesService} from '../utilities/utilities.service';

@Directive()
export abstract class UiBaseComponent implements OnDestroy{

  uiModel: UiModel = new UiModel();

  @Input() fieldId: string;
  @Input() fieldConfig = {};
  @Input() formGroup: FormGroup;
  @Input() value: String;
  @Input() dynamicOptions: UiOptionsModel[];
  @Input() frmArrayName: string;
  @Input() frmArrayIndex: string = '';
  @Input() dynamicLabel: string;
  @Input() uiPopover: UiPopoverModel;
  @Input() amountFieldRequired: boolean;
  @Input() prefix: string;
  @Input() isRequired: boolean;
  @Input() maxLength: string;
  @Input() dynamicPlaceholder: string;
  @Input() regexPattern: string;

  @Output() uiInit = new EventEmitter<UiModel>();

  private _formControl: AbstractControl;
  /* put common error codes here */
  errorCodes = UiValidators.ERROR_CODES;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.mapUiModel();
    this.formControl = this.formGroup.get(this.uiModel.name);
    this.formControl.setValidators(this.setupValidatorList(null));
    this.setupControlProperties();
    // this.uiInit.emit(this.uiModel);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setupCustomValidatorList(customValidators) {
    this.formControl = this.formGroup.get(this.uiModel.name);
    this.formControl.setValidators(this.setupValidatorList(customValidators));
  }

  setupValidatorList(customValidators) {
    let validatorList = [];
    if (this.uiModel.required) validatorList.push(Validators.required);
    if (this.uiModel.moneyFieldAmountRequired) validatorList.push(Validators.required);
    if (this.uiModel.moneyFieldCurrencyRequired) validatorList.push(Validators.required);
    if (this.uiModel.maxlength) validatorList.push(Validators.maxLength(this.uiModel.maxlength));
    if (this.uiModel.validationRegex) validatorList.push(Validators.pattern(this.uiModel.validationRegex));

    UiCustomValidatorFactory.getCustomValidators(this.uiModel).forEach(each => {
      validatorList.push(each);
    });

    if (customValidators) {
      customValidators.forEach(each => {
        validatorList.push(each);
      });
    }

    return validatorList;
  }

  setupControlProperties() {
    if (this.uiModel.disabled) this.formControl.disable();
    if (!this.formControl.value && this.uiModel.defaultValue) {
      this.formControl.setValue(this.uiModel.defaultValue);
    }
  }

  mapUiModel() {
    this.uiModel.type = this.fieldConfig['type'];
    this.uiModel.name = this.fieldConfig['name'];
    this.uiModel.required = this.fieldConfig['required'];
    this.uiModel.moneyFieldAmountRequired = this.fieldConfig['moneyFieldAmountRequired'];
    this.uiModel.moneyFieldCurrencyRequired = this.fieldConfig['moneyFieldCurrencyRequired'];
    this.uiModel.disabled = this.fieldConfig['disabled'];
    this.uiModel.hidden = this.fieldConfig['hidden'];
    this.uiModel.labelText = this.fieldConfig['labelText'];
    this.uiModel.labelText2 = this.fieldConfig['labelText2'];
    this.uiModel.labelSize = this.fieldConfig['labelSize'];
    this.uiModel.labelClass = this.fieldConfig['labelClass'];
    this.uiModel.size = this.fieldConfig['size'];
    this.uiModel.placeholder = this.fieldConfig['placeholder'];
    this.uiModel.textOnly = this.fieldConfig['textOnly'];
    this.uiModel.validationRegex = this.fieldConfig['validationRegex'];
    this.uiModel.defaultValue = this.fieldConfig['defaultValue'];
    this.uiModel.popover = this.fieldConfig['popover'];
    this.uiModel.options = this.fieldConfig['options'];
    this.uiModel.disabledOptions = this.fieldConfig['disabledOptions'];
    this.uiModel.dictionary = this.fieldConfig['dictionary'];
    this.uiModel.remoteSource = this.fieldConfig['remoteSource'];
    this.uiModel.rangeValidator = this.fieldConfig['rangeValidator'];
    this.uiModel.dateFormat = this.fieldConfig['dateFormat'];
    this.uiModel.momentFormat = this.fieldConfig['momentFormat'];
    this.uiModel.initialDate = this.fieldConfig['initialDate'];
    this.uiModel.minDate = this.fieldConfig['minDate'];
    this.uiModel.maxDate = this.fieldConfig['maxDate'];
    this.uiModel.maxlength = this.fieldConfig['maxlength'];
    this.uiModel.atLeastOneRequired = this.fieldConfig['atLeastOneRequired'];
    this.uiModel.hideLabel = this.fieldConfig['hideLabel'];
    this.uiModel.currencyFieldId = this.fieldConfig['currencyFieldId'];
    this.uiModel.currencyFieldName = this.fieldConfig['currencyFieldName'];
    this.uiModel.currencyPlaceholder = this.fieldConfig['currencyPlaceholder'];
    this.uiModel.currencyOptions = this.fieldConfig['currencyOptions'];
    this.uiModel.countryCodeOptions = this.fieldConfig['countryCodeOptions'];
    this.uiModel.style = this.fieldConfig['style'];
    this.uiModel.hideMandatoryMark = this.fieldConfig['hideMandatoryMark'];
    this.uiModel.twelveHourClock = this.fieldConfig['twelveHourClock'];
    this.uiModel.isOtherTextField = this.fieldConfig['isOtherTextField'];
    this.uiModel.currencyFieldStaticValue = this.fieldConfig['currencyFieldStaticValue'];
    this.uiModel.p400moreThan1 = this.fieldConfig['p400moreThan1'];
    this.uiModel.p400noSpace = this.fieldConfig['p400noSpace'];
    this.uiModel.p400Format = this.fieldConfig['p400Format'];
    this.uiModel.p400consecutive = this.fieldConfig['p400consecutive'];
    this.uiModel.atleastOneRegexValid = this.fieldConfig['atleastOneRegexValid'];
    this.uiModel.countryCodeFieldId = this.fieldConfig['countryCodeFieldId'];
    this.uiModel.countryCodeFieldName = this.fieldConfig['countryCodeFieldName'];
    this.uiModel.dropdownFieldId =  this.fieldConfig['dropdownFieldId'];
    this.uiModel.dropdownFieldName =  this.fieldConfig['dropdownFieldName'];
    this.uiModel.multiField = this.fieldConfig['multiField'];
    this.uiModel.optionDisplayLabel = this.fieldConfig['optionDisplayLabel'];

    // Usage: key-value pair
    this.uiModel.validationRegexList = this.fieldConfig['validationRegexList'];

    if (this.dynamicOptions) {
      this.uiModel.options = this.dynamicOptions;
    }

    if (this.dynamicLabel) {
        this.uiModel.labelText = this.dynamicLabel;
    }
    
    if (this.uiPopover) {
      this.uiModel.popover = this.uiPopover;
    }

    if (this.prefix) {
      this.uiModel.currencyFieldStaticValue = this.prefix;
    }

    if (this.amountFieldRequired) {
        this.uiModel.required  = this.amountFieldRequired == true ? 'true' : null;
        this.uiModel.moneyFieldAmountRequired = this.amountFieldRequired == true  ? 'true' : null;
    }

    if (!UtilitiesService.isNullOrUndefined(this.isRequired)) {
      this.uiModel.required = this.isRequired ? 'true' : null;
    }

    if (this.maxLength) {
      this.uiModel.maxlength = parseInt(this.maxLength);
    }

    if (this.dynamicPlaceholder) {
      this.uiModel.placeholder = this.dynamicPlaceholder;
    }

    if (this.regexPattern) {
      this.uiModel.validationRegex = this.regexPattern;
    }

  }


  get formControl(): AbstractControl {
    return this._formControl;
  }

  set formControl(value: AbstractControl) {
    this._formControl = value;
  }

  hide() {
    this.uiModel.hidden = true;
  }

  show() {
    this.uiModel.hidden = false;
  }

  isHidden(): boolean {
    return this.uiModel.hidden;
  }

  enable() {
    this.uiModel.disabled = false;
    this.formControl.enable({emitEvent: false});
  }

  disable() {
    this.uiModel.disabled = true;
    this.formControl.disable({emitEvent: false});
  }

  isDisabled(): boolean {
    return this.uiModel.disabled;
  }

  setDefaultValue(defaultValue: string): void {
    this.uiModel.defaultValue = defaultValue;
  }

  getDefaultValue(): string {
    return this.uiModel.defaultValue;
  }

  setValue(value: string) {
    this.formControl.setValue(value);
  }

  getValue(): string {
    return this.formControl.value;
  }

  valueChanges(): Observable<any> {
    return this.formControl.valueChanges;
  }

  error(errorCode: string) {
    UiFormService.mapFormControlError(this.formControl,  errorCode ? [errorCode] : null);
  }

  getOptionLabel(value: string): string {
    if (this.uiModel.options) {
      const option: UiOptionsModel = this.uiModel.options.find(each => each.value === value);
      return option.label;
    }
    return '';
  }

  update(): void {
    this.formControl.updateValueAndValidity({onlySelf: true, emitEvent: false});
  }

  set textOnly(textOnly: boolean) {
    this.uiModel.textOnly = textOnly;
  }

  set required(required: boolean) {
    this.uiModel.required = required ? 'true' : null;
    this.formControl.setValidators(this.setupValidatorList(null));
  }

  set moneyFieldAmountRequired(moneyFieldAmountRequired: boolean) {
    this.uiModel.moneyFieldAmountRequired = moneyFieldAmountRequired ? 'true' : null;
    this.formControl.setValidators(this.setupValidatorList(null));
  }

  set moneyFieldCurrencyRequired(moneyFieldCurrencyRequired: boolean) {
    this.uiModel.moneyFieldCurrencyRequired = moneyFieldCurrencyRequired ? 'true' : null;
    this.formControl.setValidators(this.setupValidatorList(null));
  }

  set hideLabel(hideLabel: boolean) {
    this.uiModel.hideLabel = hideLabel;
  }

  set maxValue(maxValue: number) {
    if (!this.uiModel.rangeValidator) {
      this.uiModel.rangeValidator = {};
    }
    this.uiModel.rangeValidator.max = maxValue;
  }

  get maxValue(): number {
    if (!this.uiModel.rangeValidator) {
      this.uiModel.rangeValidator = {};
    }
    return this.uiModel.rangeValidator.max;
  }

  set minValue(minValue: number) {
    if (!this.uiModel.rangeValidator) {
      this.uiModel.rangeValidator = {};
    }
    this.uiModel.rangeValidator.min = minValue;
  }

  get minValue(): number {
    if (!this.uiModel.rangeValidator) {
      this.uiModel.rangeValidator = {};
    }
    return this.uiModel.rangeValidator.min;
  }

  set validationRegex(regex: string) {
    this.uiModel.validationRegex = regex;
    this.formControl.setValidators(this.setupValidatorList(null));
  }

  set validationRegexList(regex: Object) {
    this.uiModel.validationRegexList = regex;
    this.formControl.setValidators(this.setupValidatorList(null));
  }

  removeError(error: string) {
    UiFormService.removeError(this.formControl, error);
  }

  isValid() {
    return this.formControl.valid;
  }

}
