import {Component, OnInit, Input} from '@angular/core';

import {NotificationMessage} from './notification-message.model';

@Component({
  selector: 'qnect-warning-notification',
  templateUrl: './warning-notification.component.html'
})
export class WarningNotificationComponent implements OnInit {

  @Input()
  messages: NotificationMessage[] = [];
  @Input() showPreamble: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

}
