import {Component, Injector, OnInit, ViewChild} from "@angular/core";
import {SideMenuService} from "../../../../services/side-menu.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MissConnection} from "../../../../model/travel/miss-connection.model";
import {takeUntil} from "rxjs";
import { TransactionInfoService } from "src/app/services/transaction-info.service";


@Component({
    selector: 'app-detail-of-miss-connect',
    templateUrl: './detail-of-miss-connect.component.html',
    styleUrls: ['./detail-of-miss-connect.component.css']
})
export class DetailOfMissConnectComponent extends ClaimsBaseComponent implements OnInit {

    missConnectionFormGroup: FormGroup;
    form: any;
    showTotalBox = false;
    claim: Claim;
    missConnection: MissConnection;

    constructor(private fb: FormBuilder,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.missConnection = this.claim.getClaimTravel().getMissConnection();
        if(this.missConnection.getOriginalArrivalDate() == null){
            this.missConnection.setOriginalArrivalDate(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {

        this.pushGAViewConnectDetails();

        this.missConnectionFormGroup = this.fb.group({
            countryOccurred: [this.missConnection.getCountryOccurred()],
            originArrivalTime: [this.missConnection.getOriginalArrivalDate()],
            actualArrivalTime: [this.missConnection.getActualArrivalDate()],
            reasonOfMissConnect: [this.missConnection.getReasonOfMissConnect()],
            otherReasonOfMissConnect: [this.missConnection.getOtherReasonOfMissConnect()],
        });

    }

    ngAfterViewInit() {

        this.missConnectionFormGroup.valueChanges.subscribe(data => {

            this.missConnection.setCountryOccurred(this.missConnectionFormGroup.get('countryOccurred').value);
            this.missConnection.setCountryOccurredDesc(this.getTextInComponent('countryOccurred'));
            this.missConnection.setOriginalArrivalDate(super.getValueInComponent('originArrivalTime'));
            this.missConnection.setActualArrivalDate(super.getValueInComponent('actualArrivalTime'));
            this.missConnection.setReasonOfMissConnect(this.missConnectionFormGroup.get('reasonOfMissConnect').value);
            this.missConnection.setReasonOfMissConnectDesc(this.getTextInComponent('reasonOfMissConnect'));
            this.missConnection.setOtherReasonOfMissConnect(this.missConnectionFormGroup.get('otherReasonOfMissConnect').value);

            this.validateClaimDetailsComplete();

        });
        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideOthersField(self.missConnection.getReasonOfMissConnect()),10});
        this.missConnectionFormGroup.get('reasonOfMissConnect').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOthersField(data);
        });
    }

    hideOthersField(reasonOfMissConnect) {

        if (reasonOfMissConnect && reasonOfMissConnect.toLowerCase() === 'other') {
            this.missConnectionFormGroup.get('otherReasonOfMissConnect').enable({onlySelf: false, emitEvent: false});
        } else {
            this.missConnectionFormGroup.get('otherReasonOfMissConnect').reset();
            this.missConnectionFormGroup.get('otherReasonOfMissConnect').disable({onlySelf: false, emitEvent: false});
        }
    }


    goToNext() {

        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 12, subMenuIndex: 0});

            this.router.navigate(["/claimform/missedConnect/lossOfMissConnect"], {
                relativeTo: this.activatedRoute
            });
        }

    }

    validateClaimDetailsComplete() {
        if(this.validateFormOnChange()){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 12, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 12, subMenuIndex: 0});
        }
    }

    validateFormOnChange(): boolean {
        return super.validateFormOnChange(this.missConnectionFormGroup);
    }

    validateForm(): boolean {
        return super.validateForm(this.missConnectionFormGroup);
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewConnectDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Missed Connection – Details',
            'vPath': '/claim/missed-connection/details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}
