import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import {HomeFormBuilderUtil} from './home-form-builder-util';
import { UtilitiesService } from "src/app/utilities/utilities.service";

export class OutsidehomeLostDamageFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = claim.getSelectedClaimTypesList()[0];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        if (this.documentFormClaimType ===  ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]) {
            let detailsOfLostItemsArray = this.claim.getClaimHome().getHomeDetails().getLostPersonalItem().getDetailsOfLostItemsArray();
            requiredDocuments.push(new DocumentField('RECLOST', 'homeProduct.supportingDocs.types.receiptLostItem', SmartqDocuments.INVOICE, true));
            requiredDocuments.push(new DocumentField('LOSSREPORT', 'homeProduct.supportingDocs.types.policeReportLostItem', SmartqDocuments.REPORT, true));
            if(!UtilitiesService.isNullOrUndefined(detailsOfLostItemsArray) && detailsOfLostItemsArray.length > 0){
                for(let i = 0; i < detailsOfLostItemsArray.length; i++) {
                    if (detailsOfLostItemsArray[i].getLossType() == 'Passport / HKID / China Re-entry card') {
                        requiredDocuments.push(new DocumentField('ORIGDOC', 'homeProduct.supportingDocs.types.receiptReplacementId', SmartqDocuments.REPLACEMENTRECEIPT, true));
                        break;
                    }
                }
            }
        }

        if (this.documentFormClaimType ===  ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]) {
            requiredDocuments.push(new DocumentField('RECDAM', 'homeProduct.supportingDocs.types.receiptDamagedItem', SmartqDocuments.PURCHASERECEIPT, true));
            requiredDocuments.push(new DocumentField('DAMREPORT', 'homeProduct.supportingDocs.types.damageReport', SmartqDocuments.REPORT, true));
            requiredDocuments.push(new DocumentField('DAMPHOTO', 'homeProduct.supportingDocs.types.photoDamagedItem', SmartqDocuments.PHOTOS, true));
        }

        let utilities: HomeFormBuilderUtil = new HomeFormBuilderUtil();
        requiredDocuments = requiredDocuments.concat(utilities.getRiskDocuments(this.claim));

        this.requiredDocuments = requiredDocuments;
    }

}