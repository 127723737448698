<div *ngIf="tabTableGender === 'Male' || 'Female'" class="body_table_wrapper body_table_main">
    <div *ngIf="tabTableIsLoading; else tableContent">
        <p>Loading...</p>
    </div>
    <ng-template #tableContent>
        <table class="tb_bd_collection" aria-label="main body table">
            <thead>
                <tr>
                    <th class="tb_edit"></th>
                    <th class="tb_main_body_part">{{'ecClaim.injuryDetails.mainBodyPart' | translate}}</th>
                    <th class="tb_selected_body_part">{{'ecClaim.injuryDetails.selectedBodyPart' | translate}}</th>
                    <th class="tb_injury">{{'ecClaim.injuryDetails.injuryLabel' | translate}}</th>
                    <th class="tb_remove"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let injuredBodyPart of injuredBodyParts">
                    <tr *ngFor="let bodyPart of injuredBodyPart.getBodyParts(); let i = index">
                        <td [attr.rowspan]="injuredBodyPart.getBodyParts().length" *ngIf="i === 0" class="td_edit">
                            <button (click)="tabTableOnClickArea($event)" [name]="injuredBodyPart.getArea()" class="td_btn_edit"></button>
                        </td>
                        <td [attr.rowspan]="injuredBodyPart.getBodyParts().length" *ngIf="i === 0" class="td_area">{{ injuredBodyPart.getAreaDesc()}}</td>
                        <td class="td_selected_part">{{ bodyPart.getBodyPartDesc()}}</td>
                        <td class="td_injury">
                            <ul>
                                <li *ngFor="let injury of bodyPart.getNatureOfInjuryDesc()">{{ injury}}</li>
                            </ul>
                        </td>
                        <td class="td_remove">
                            <button (click)="tabTableOnClickRemoveSpecificArea(bodyPart.getBodyPartId(), injuredBodyPart.getArea())" class="td_btn_remove">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" fill="#1B459C"/>
                                </svg>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <!--<div *ngIf="!tabTableNewTable">
            <p>Error loading table</p>
        </div>-->
    </ng-template>
</div>