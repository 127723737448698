export class MotorCoverInfo {

    isPolicyHolder: boolean = true;
    contractType = 'MVA';
    riskType = 'MPA';
    coverType = 'CO';
    windscreen = 'Yes';
    collision = "No";
    theft = "No";

}