<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div *ngIf="!othersPicked">
        <div class="col-12">
            <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
            <p>{{ 'claimSection.travelDelay.reason.description' | translate}}</p>
            <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
        </div>
        <BR>
        <div class="row reasonBox col-12">
            <div class="col-lg-4 col-md-6 col-6" *ngFor="let item of delayReasonList; let i = index">
                <div class="cperson-wrapper text-center toggleActive" (click)="changeReason(i, item)" [class.active]="currentReasonIndex == i">
                    <div class="cpreson-outter reasonTravelDelay">
                        <h5>{{item.delayedReasonDisplayName | translate}}</h5>
                    </div>
                    <div class="col-12 reason-description">
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'weather'">
                  <BR>
                  {{'claimDetails.reasonOfTravelDelay.weather' | translate}}
              </span>
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'natural disaster'">
                  <BR>
                  {{'claimDetails.reasonOfTravelDelay.naturalDisaster' | translate}}
              </span>
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'air traffic control'">
                  <BR>
                  {{'claimDetails.reasonOfTravelDelay.airTrafficControl' | translate}}
              </span>
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'airport closure'">
                  <BR>
                  {{'claimDetails.reasonOfTravelDelay.airportClosure' | translate}}
              </span>
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'overbooking or overselling'">
                  <BR>
                  {{'claimDetails.reasonOfTravelDelay.overBookingOverSelling' | translate}}
              </span>
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'mechanical or electrical breakdown'">
                  <BR>
                  {{'claimDetails.reasonOfTravelDelay.mechanicalElectricalBreakdown' | translate}}
              </span>
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'strike or industrial action'">
                  <BR>
                  {{'claimDetails.reasonOfTravelDelay.strikeOrIndustrialAction' | translate}}
              </span>
                        <span *ngIf="item.delayedReasonName?.toLowerCase() == 'acts of terrorism'">
              </span>
                    </div>
                </div>

            </div>
        </div>
        <div class="row click-more-content col-12" id="add-reason">
            <div class="col-md-12 col-sm-12" *ngIf="!isCnLanguage">
                <p class="searchPerson-desc">{{ 'claimSection.travelDelay.reason.otherRedirect' | translate}}
                    <a href="javascript:;" (click)="redirectToOthersScreen(true)" class="click-more"> {{ 'claimSection.travelDelay.reason.otherRedirect2' | translate}}</a>
                </p>
            </div>
            <div class="col-md-12 col-sm-12" *ngIf="isCnLanguage">
              <p class="searchPerson-desc"><a href="javascript:;" (click)="redirectToOthersScreen(true)" class="click-more"> {{ 'claimSection.travelDelay.reason.otherRedirect2' | translate}}</a>{{ 'claimSection.travelDelay.reason.otherRedirect' | translate}}</p>
          </div>
        </div>
    </div>
    <div *ngIf="othersPicked">
        <h5>{{ 'claimSection.travelDelay.reason.otherHeader1' | translate}}</h5>
        <p>{{ 'claimSection.travelDelay.reason.otherHeader2' | translate}}</p>
        <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
        <BR>
        <BR>
        <div class="row col-12">
            <div class="col-xl-12 col-lg-12">
                <form>
                    <div class="form-group">
                        <qnect-ui #TRAVEL_DEL16_TRIPCANCELLATION [fieldId]="'TRAVEL_DEL16_TRIPCANCELLATION'" [formGroup]="reasonTravelDelayForm" name="isTravelDelayed"></qnect-ui>
                    </div>
                    <div class="form-group" *ngIf="this.travelDelay.getIsTravelDelayed() != null && this.travelDelay.getIsTravelDelayed()">
                        <div class="form-group">
                            <qnect-ui #TRAVEL_DEL16 [fieldId]="'TRAVEL_DEL16'" [formGroup]="reasonTravelDelayForm" name="delayReasonOthers"></qnect-ui>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row click-more-content col-12" id="add-reason">
            <div class="col-md-12 col-sm-12">
                <p class="searchPerson-desc"><a href="javascript:;" (click)="redirectToOthersScreen(false)" class="click-more"> {{ 'claimSection.travelDelay.reason.optionsRedirect' | translate}}</a> {{ 'claimSection.travelDelay.reason.optionsRedirect2' | translate}}
                </p>
            </div>
        </div>

        
        <div class="col-xl-3 col-lg-12">
        </div>
      </div>

    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
    <ng-template #suggestion_message let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'claimSection.travelDelay.reason.suggestTitle' | translate}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="abort()">
        <span aria-hidden="true">&times;</span>
    </button>
        </div>
        <div class="modal-body">
            <p>{{ 'claimSection.travelDelay.reason.suggest1' | translate}} <b>{{travelDelay.getDelayReasonOthers()}}</b> {{ 'claimSection.travelDelay.reason.suggest2' | translate}}</p><br>
            <div *ngFor="let s of getKeys(suggestions)">
                <button type="button" (click)="proceedWithReason(s)" class="btn btn-secondary btn-sm mb-2">{{s | translate}}</button>
            </div><br>
            <div>
                <button type="button" class="btn btn-default btn-sm" (click)="proceedWithOriginal()">{{ 'claimSection.travelDelay.reason.proceed' | translate}}</button>
            </div>
        </div>
    </ng-template>