import {Component, Injector, Input} from '@angular/core';
import {ClaimsBaseComponent} from '../../claim-pages/claim-base.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TransactionInfo} from '../../model/transaction-info.model';

@Component({
    selector: 'documents-to-prepare',
    templateUrl: './documents-to-prepare.component.html',
    styleUrls: ['../homepage.component.css']
})

export class DocumentsToPrepareComponent extends ClaimsBaseComponent  {

    docTitle: string;
    docCategory: string;
    @Input() transactionInfo: TransactionInfo;
    @Input() isPolicyHolder:  boolean;
    isGBA: boolean = false;

    constructor(private injector : Injector,
                private modalService: NgbModal) {
        super(injector);
    }

    open(content, name, isGBA) {
        this.isGBA = isGBA;
        this.modalService.open(content, {size: 'lg'});
    }

    openModal(content, docTitle, docCategory) {
        this.docTitle = this.translate.instant(docTitle);
        this.docCategory = docCategory;
        this.modalService.open(content, {size: 'lg'});
    }
}
