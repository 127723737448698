<div *ngIf="messages?.length > 0" class="col-md-12 alert alert-danger error-notification">
  <div class="row">
    <div class="col-md-1 col-xs-2 text-center">
      <i class="fa fa-exclamation-circle notification-icon" aria-hidden="true"></i>
    </div>
    <div class="col-md-11 col-xs-10">
      <div *ngIf="showPreamble">Please correct the following errors:</div>
      <ul id="error-list" class="baseline-left">
        <li *ngFor="let error of messages">{{error.message | translate}}</li>
      </ul>
    </div>
  </div>
</div>
