export class InjuredItemDetail {

    private injuredPersonName: string;
    private injuredPersonNumber: string;
    private ageGroup: string;
    private ageGroupDesc: string;
    private relationship: string;
    private relationshipDesc: string;
    private extentOfInjury: string;
    private extentOfInjuryDesc: string;

    getInjuredPersonName(): string {
        return this.injuredPersonName;
    }

    setInjuredPersonName(injuredPersonName: string) {
        this.injuredPersonName = injuredPersonName;
    }

    getInjuredPersonNumber(): string {
        return this.injuredPersonNumber;
    }

    setInjuredPersonNumber(injuredPersonNumber: string) {
        this.injuredPersonNumber = injuredPersonNumber;
    }

    getAgeGroup(): string {
        return this.ageGroup;
    }

    setAgeGroup(value: string){ 
        this.ageGroup = value;
    }

    getAgeGroupDesc(): string {
        return this.ageGroupDesc;
    }

    setAgeGroupDesc(value: string){ 
        this.ageGroupDesc = value;
    }

    getRelationship(): string {
        return this.relationship;
    }

    setRelationship(value: string){ 
        this.relationship = value;
    }

    getRelationshipDesc(): string {
        return this.relationshipDesc;
    }

    setRelationshipDesc(value: string){ 
        this.relationshipDesc = value;
    }

    getExtentOfInjury(): string {
        return this.extentOfInjury;
    }

    setExtentOfInjury(value: string) {
        this.extentOfInjury = value;
    }

    getExtentOfInjuryDesc(): string {
        return this.extentOfInjuryDesc;
    }

    setExtentOfInjuryDesc(value: string) {
        this.extentOfInjuryDesc = value;
    }

}