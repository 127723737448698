export class ClinicalExpenseDetails {

    private dateVisit: Date;
    private medicalAttention: string;
    private medicalAttentionDesc: string;
    private clinicalExpense: number;
    private clinicalExpenseCurrency: string;
    private medicalAttentionOthers: string;

    constructor() {
        this.dateVisit = null;
        this.medicalAttention = '';
        this.medicalAttentionDesc = '';
        this.clinicalExpense = null;
        this.clinicalExpenseCurrency = '';
        this.medicalAttentionOthers = '';
    }

    public getDateVisit(): Date {
        return this.dateVisit;
    }

    public setDateVisit(dateVisit: Date): void {
        this.dateVisit = dateVisit;
    }

    public getMedicalAttention(): string {
        return this.medicalAttention;
    }

    public setMedicalAttention(medicalAttention: string): void {
        this.medicalAttention = medicalAttention;
    }

    public getMedicalAttentionDesc(): string {
        return this.medicalAttentionDesc;
    }

    public setMedicalAttentionDesc(medicalAttentionDesc: string): void {
        this.medicalAttentionDesc = medicalAttentionDesc;
    }

    public getClinicalExpense(): number {
        return this.clinicalExpense;
    }

    public setClinicalExpense(clinicalExpense: number): void {
        this.clinicalExpense = clinicalExpense;
    }

    public getClinicalExpenseCurrency(): string {
        return this.clinicalExpenseCurrency;
    }

    public setClinicalExpenseCurrency(clinicalExpenseCurrency: string): void {
        this.clinicalExpenseCurrency = clinicalExpenseCurrency;
    }

    public getMedicalAttentionOthers(): string {
        return this.medicalAttentionOthers;
    }

    public setMedicalAttentionOthers(medicalAttentionOthers: string): void {
        this.medicalAttentionOthers = medicalAttentionOthers;
    }

    static jsonConvert(clinicalExpense: ClinicalExpenseDetails): ClinicalExpenseDetails {
    
        if (clinicalExpense.getDateVisit() != null) {
            clinicalExpense.setDateVisit(new Date(clinicalExpense.getDateVisit()));
        }
    
        return clinicalExpense;
    }
}