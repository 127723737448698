
export class DocumentSearchResult {

    private documentToken: string;
    private documents: Map<string, any> = new Map();

    getDocumentToken(): string {
        return this.documentToken;
    }

    setDocumentToken(value: string) {
        this.documentToken = value;
    }

    getDocuments(): Map<string, any> {
        return this.documents;
    }

    setDocuments(value: Map<string, any>) {
        this.documents = value;
    }
}