<div class="section form-group">
    <label class="section-title">{{'motorClaim.driverDetails.description' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'motorClaim.claimSection.windscreen.detailsOfDamage.description' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.claimSection.windscreen.detailsOfDamage.dateOfDamage' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(windscreen.getDateOfAccident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.claimSection.windscreen.detailsOfDamage.causeOfDamage' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="windscreen.getCauseOfDamage()?.toLowerCase() != 'others'">
                    {{windscreen.getCauseOfDamageDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="windscreen.getCauseOfDamage()?.toLowerCase() == 'others'">
                    {{windscreen.getOtherCauseOfDamage()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.claimSection.windscreen.detailsOfDamage.countryOccured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{windscreen.getCountryOccuredDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.claimSection.windscreen.detailsOfDamage.placeOccured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{windscreen.getPlaceOccured()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'motorClaim.claimSection.windscreen.detailsOfRepair.description' | translate}}</label>
            <div class="row" *ngFor="let damageItem of windscreen.getDetailsOfDamagedItemDesc(); let i = index">
                <div class="col col-xs-12 col-lg-7"  *ngIf="i == 0">
                    {{'motorClaim.claimSection.windscreen.detailsOfRepair.section2' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-7"  *ngIf="i != 0">
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                {{damageItem}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.claimSection.windscreen.detailsOfRepair.nameOfRepairer' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="windscreen.getRepairerName()?.toLowerCase() != 'others'">
                    {{windscreen.getRepairerNameDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="windscreen.getRepairerName()?.toLowerCase() == 'others'">
                    {{windscreen.getRepairerOtherName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.claimSection.windscreen.detailsOfRepair.repairCost' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{windscreen.getRepairCurrency()}}
                    {{windscreen.getRepairCost() | number : '1.2-2'}}
                </div>
            </div>

        </div>

        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
             </div>
        </div>

    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>