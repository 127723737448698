import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { ClaimTypes } from "../../model/claim-type.model";
import { TransactionInfo } from "src/app/model/transaction-info.model";

export class GenericFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim, private transInfo: TransactionInfo){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC];
    }

    setRequiredDocuments(){
        this.requiredDocuments = this.transInfo.getGenDocList();
    }
}