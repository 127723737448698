import {Component, OnInit} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {Option} from "./ui-option.model";
import {UiOptionsModel} from "../ui-options.model";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
  selector: 'qnect-ui-multiple-select',
  templateUrl: './ui-multiple-select.component.html',
  styleUrls: ['./ui-multiple-select.component.scss', '../ui-base.component.scss']
})
export class UiMultipleSelectComponent extends UiBaseComponent implements OnInit {

  selectedOptions: String[] = [];
  options: Option[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadOptions(this.fieldConfig["options"], this.fieldConfig["disabledOptions"]);
  }

  loadOptions(optionsList, disabledOptionList) {

    this.options = [];

    for (let eachOption of optionsList) {
      let isDisabled = false;
      try {
        isDisabled = disabledOptionList.indexOf(eachOption.value) > -1;
      } catch (e) {
        isDisabled = false;
      }
      this.options.push(new Option(eachOption.label, eachOption.value, isDisabled));
    }

  }

  ngAfterViewInit() {
    var uiValue = this.formControl.value;

    if (!UtilitiesService.isNullOrUndefined(uiValue) && uiValue.length > 0) {
      this.selectedOptions = uiValue.split(",");
    }
  }

  toggle(item: string, disabled: boolean) {
    if (!disabled) {
      if (this.exists(item, this.selectedOptions)) {
        this.selectedOptions.splice(this.selectedOptions.indexOf(item), 1);
      } else {
        this.selectedOptions.push(item);
      }
      this.formControl.setValue(this.selectedOptions.join(","));
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
    }

  }

  exists(item: string, selected: String[]) {
    return selected.indexOf(item) > -1;
  }

  loadDropDownOptions(options : UiOptionsModel[]) {
    this.loadOptions(options, this.fieldConfig["disabledOptions"]);
  }

}
