<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.travelDelay.extralCost.description' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #TRAVEL_DEL_10_EXT_TRANS_RADIO [fieldId]="'TRAVEL_DEL_10_EXT_TRANS_RADIO'" [formGroup]="travelExtralCostForm"
                    name="hasExtTransCost" [dynamicLabel] ="this.claim.getCountry()?.toUpperCase() == 'HKG' ? 'claimSection.travelDelay.extralCost.hasPrepaidExpenses' : ''"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="travelDelay.getHasExtTransCost()">
          <qnect-ui #TRAVEL_DEL_8_EXT_TRANS_COST [fieldId]="'TRAVEL_DEL_8_EXT_TRANS_COST'" [formGroup]="travelExtralCostForm" name="extraTransportationCost"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #TRAVEL_DEL_11_EXT_ACCOM_RADIO [fieldId]="'TRAVEL_DEL_11_EXT_ACCOM_RADIO'" [formGroup]="travelExtralCostForm"
                    name="hasExtAccomCost" [dynamicLabel] ="this.claim.getCountry()?.toUpperCase() == 'HKG' ? 'claimSection.travelDelay.extralCost.hasAdditionalExpenses' : ''"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="travelDelay.getHasExtAccomCost()">
          <qnect-ui #TRAVEL_DEL_9_EXT_ACCOM_COST [fieldId]="'TRAVEL_DEL_9_EXT_ACCOM_COST'" [formGroup]="travelExtralCostForm" name="extraAccommodationCost"></qnect-ui>
        </div>
        <ng-container *ngIf="isGoldOrDiamondPlan && (travelDelay.getHasExtTransCost() || travelDelay.getHasExtAccomCost())">
          <div class="row col-12">
            <span style="margin-right: auto" [innerHtml]="'claimSection.travelDelay.extralCost.transAccomNote' | translate"> </span>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>