import {Injectable, Injector} from "@angular/core";
import {Claim} from "../model/claim.model";
import {ClaimService} from "./claim.service";
import {UiConfigService} from "../ui/ui-config.service";
import {TransactionInfo} from "../model/transaction-info.model";
import {TransactionInfoService} from "./transaction-info.service";
import {Product} from "../model/product.model";
import {TranslateService} from "@ngx-translate/core";
import {DocumentHelperService} from "./document-helper.service";
import {PageService} from "./page.service";
import {NavigationStart, Router} from "@angular/router";
import { RouterService } from "../guard/router.service";
import { ClaimTypeMenu } from "../model/claim-type-menu.model";
import { DynamicRoutingService } from "./dynamic-routing.service";
import {NavigationMenu} from "../model/navigation-menu.model";

@Injectable()
export class StoredDataService {

    private initialised: boolean = false;
    private claimService: ClaimService;
    private transactionInfoService: TransactionInfoService;
    private uiConfigService: UiConfigService;
    private documentHelperService : DocumentHelperService;
    private translate: TranslateService;
    private pageService : PageService;
    private routerService : RouterService;
    private dynamicRoutingService: DynamicRoutingService;

    constructor( private router: Router,
                 private injector : Injector) {
        this.claimService = this.injector.get(ClaimService);
        this.transactionInfoService = this.injector.get(TransactionInfoService);
        this.uiConfigService = this.injector.get(UiConfigService);
        this.documentHelperService = this.injector.get(DocumentHelperService);
        this.translate = this.injector.get(TranslateService);
        this.pageService = this.injector.get(PageService);
        this.routerService = this.injector.get(RouterService);
        this.dynamicRoutingService = this.injector.get(DynamicRoutingService);
    }

    storeSession() {
        this.clearSession();

        let transactionInfo: TransactionInfo = this.transactionInfoService.getTransactionInfo();
        if (transactionInfo) {
            this.storeTransaction(transactionInfo);
            console.log("Store Session Transaction", transactionInfo);

            if (transactionInfo.getNavigationMenu()) {
                this.storeNavigationMenuState(transactionInfo.getNavigationMenu())
                console.log("Store Session Navigation Menu State status");

                this.storeClaimDetailMenu(transactionInfo);    
                console.log("Store Session Claim Detail Menu");
            }
        }

        let claim: Claim = this.claimService.getClaim();
        if (claim) {
            this.storeClaim(claim);
            console.log("Store Session Claim", claim);
        }

        let config = this.uiConfigService.getUiConfig();
        if (config) {
            this.storeConfig(config);
            console.log("Store Session Config", config);
        }

        let entryUrl: string = this.pageService.getEntryUrlDirect();
        if (entryUrl) {
            sessionStorage.setItem("entryUrl", entryUrl);
            console.log("Store Session Entry Url", entryUrl);
        }

        sessionStorage.setItem("currentLang", this.translate.currentLang);
        console.log("Store Session Current Lang");

        let routerService: RouterService = this.routerService;
        if (routerService) {
            this.storeRouterService(routerService);
            console.log("Store Session Router Service :", routerService);
        }

        this.initialised = true;
    }

    retrieveSessionIfRequired(): boolean {

        // We only retrieve the session if not initialised
        if (!this.initialised) {
            this.initialised = true;
            return this.retrieveSession();
        }
    }

    retrieveSession(): boolean {

        let retrieved: boolean = false;

        // We only retrieve the session if the config is not setup.
        if (!this.uiConfigService.getUiConfig()) {

            console.log("Retrieving from Session Storage ");

            // Every time the url changes we store the browser session data in case of refresh
            this.router.events.subscribe(event => {
                if (event instanceof NavigationStart) {
                    if (event.url.indexOf('claim-boot') === -1) {
                        console.log('Storage Data :: Router Change Store Session:' + event);
                        // Store any data to the session.
                        this.storeSession();
                    }
                }
            });

            let transactionInfo: TransactionInfo = this.retrieveTransaction();
            if (transactionInfo) {
                this.transactionInfoService.setTransactionInfo(transactionInfo);
                console.log("Retrieve Session Transaction Info");

                if (transactionInfo.getNavigationMenu()) {    
                    
                    this.retrieveNavigationMenuState(transactionInfo);
                    console.log("Retrieve Session Navigation Menu State status");
                    
                    let claimTypeMenu: ClaimTypeMenu[];
                    console.log("Retrieving Claim Detail Menu");
                    claimTypeMenu = this.retrieveClaimDetailMenu();
                    transactionInfo.getNavigationMenu().setClaimDetailMenu(claimTypeMenu);
                }
            }

            let claim: Claim = this.retrieveClaim();
            if (claim) {
                this.claimService.setClaim(claim);
                console.log("Retrieve Session Claim", claim);
            }
            
            //repopulate routing service for home
            if(this.transactionInfoService.getTransactionInfo().isHomeProduct()){
                this.transactionInfoService.getTransactionInfo().setDynamicRoutes(this.dynamicRoutingService.getDynamicHomeRoutes(this.claimService.getClaim().getSelectedClaimTypesList()[0]));
            }

            // repopulate claim types from autosave
            const claimTypeList : string[] = this.claimService.getClaim().getSelectedClaimTypesList();
            for (let claimType of claimTypeList ) {
                this.documentHelperService.addDocumentFormBuilder(claimType);
            }
            
            this.retrieveRouterService();
            console.log("Retrieve Session Router Service");

            let config = this.retrieveConfig();
            if (config) {
                this.uiConfigService.setUiConfig(config);
                console.log("Retrieve Session config", config);
            }

            let entryUrl: string = sessionStorage.getItem("entryUrl");
            if (entryUrl) {
                this.pageService.setEntryUrl(entryUrl);
            }

            let language = sessionStorage.getItem("currentLang");
            if (language) {
                this.translate.use(language);
            }

            retrieved = true;
        }

        return retrieved;
    }

    clearSession() {
        sessionStorage.clear();
    }

    storeClaim(claim: Claim) {
        sessionStorage.setItem("claim", JSON.stringify(claim));
    }

    retrieveClaim(): Claim {

        let storedClaim: Claim;

        let storedClaimData: string = sessionStorage.getItem("claim");

        if (storedClaimData) {
            storedClaim = Object.assign(new Claim(), JSON.parse(storedClaimData));
            storedClaim = Claim.jsonConvertClaim(storedClaim);
        }

        return storedClaim;
    }

    storeTransaction(transaction: TransactionInfo) {
        sessionStorage.setItem("transaction", JSON.stringify(transaction));
    }

    retrieveTransaction(): TransactionInfo {

        let transaction: TransactionInfo;
        let transactionData = sessionStorage.getItem("transaction");

        if (transactionData) {
            transaction = Object.assign(new TransactionInfo(), JSON.parse(transactionData));
            transaction.setProduct(Object.assign(new Product(), transaction.getProduct()));

            transaction = TransactionInfo.jsonConvertTransInfo(transaction);

        }
        return transaction;
    }

    storeConfig(config: any) {
        sessionStorage.setItem("config", JSON.stringify(config));
    }

    retrieveConfig() {
        let config = new Object();
        let configData: string = sessionStorage.getItem("config");
        if (configData) {
            config = JSON.parse(configData);
        }
        return config;
    }

    storeRouterService(routerService: RouterService) {
        let routerFinishStatus: Array<Object> = [
            {id: 'finishGetStarted', value: routerService.finishGetStarted},
            {id: 'finishConfirmTravelPeriod', value: routerService.finishConfirmTravelPeriod},
            {id: 'finishSelectInsured', value: routerService.finishSelectInsured},
            {id: 'finishAccidentDetails', value: routerService.finishAcidentDetails},
            {id: 'finishInjuryDetails', value: routerService.finishInjuryDetails},
            {id: 'finishDetail', value: routerService.finishDetail},
            {id: 'finishPayment', value: routerService.finishPayment},
            {id: 'finishReview', value: routerService.finishReview},
            {id: 'finishChooseClaim', value: routerService.finishChooseClaim},
            {id: 'finishGenericClaimDetail', value: this.routerService.finishGenericClaimDetail},
            {id: 'finishGenericUploadDocument', value: this.routerService.finishGenericUploadDocument},
            {id: 'finishConfirmIncidentDetails', value: routerService.finishConfirmIncidentDetails}
        ];
        sessionStorage.setItem("routerService", JSON.stringify(routerFinishStatus));
    }
    
    retrieveRouterService() {
        let routerFinishStatusList: Array<Object>;
        let storeRouterServiceData: string = sessionStorage.getItem("routerService");

        if (storeRouterServiceData) {
            routerFinishStatusList = Object.assign(new Array<Object>(), JSON.parse(storeRouterServiceData));

            for (let i in routerFinishStatusList) {
                if (routerFinishStatusList[i]['id'] === 'finishGetStarted') {
                    this.routerService.finishGetStarted = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishConfirmTravelPeriod') {
                    this.routerService.finishConfirmTravelPeriod = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishSelectInsured') {
                    this.routerService.finishSelectInsured = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishAccidentDetails') {
                    this.routerService.finishAcidentDetails = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishInjuryDetails') {
                    this.routerService.finishInjuryDetails = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishDetail') {
                    this.routerService.finishDetail = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishPayment') {
                    this.routerService.finishPayment = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishReview') {
                    this.routerService.finishReview = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishChooseClaim') {
                    this.routerService.finishChooseClaim = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishGenericClaimDetail') {
                    this.routerService.finishGenericClaimDetail = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishGenericUploadDocument') {
                    this.routerService.finishGenericUploadDocument = routerFinishStatusList[i]['value'];
                } else if (routerFinishStatusList[i]['id'] === 'finishConfirmIncidentDetails') {
                    this.routerService.finishConfirmIncidentDetails = routerFinishStatusList[i]['value'];
                } 
            }
        }
    }

    storeNavigationMenuState(navigationMenuModel: NavigationMenu) {
        let navigationMenuState: Array<Object> = [
            {id: 'getStartedState', value: navigationMenuModel.getGetStartedState()},
            {id: 'confirmTravelPeriodState', value: navigationMenuModel.getConfirmTravelPeriodState()},
            {id: 'selectInsuredState', value: navigationMenuModel.getSelectInsuredState()},
            {id: 'selectClaimState', value: navigationMenuModel.getSelectClaimState()},
            {id: 'accidentDetailsState', value: navigationMenuModel.getAccidentDetailsState()},
            {id: 'injuryDetailsState', value: navigationMenuModel.getInjuryDetailsState()},
            {id: 'claimDetailsState', value: navigationMenuModel.getClaimDetailsState()},
            {id: 'paymentAndContactState', value: navigationMenuModel.getPaymentAndContactState()},
            {id: 'reviewSummaryState', value: navigationMenuModel.getReviewSummaryState()},
            {id: 'genericClaimDetailState', value: navigationMenuModel.getGenericClaimDetailState()},
            {id: 'genericUploadDocumentState', value: navigationMenuModel.getGenericUploadDocumentState()},
            {id: 'confirmIncidentDetailsState', value: navigationMenuModel.getConfirmIncidentDetailsState()}
        ]
        sessionStorage.setItem("navigationMenuState", JSON.stringify(navigationMenuState));
    }

    retrieveNavigationMenuState(transactionInfo: TransactionInfo) {
        let navigationMenuStateList: Array<Object>;
        let storeNavigationMenuStateData: string = sessionStorage.getItem("navigationMenuState");

        if (storeNavigationMenuStateData) {
            navigationMenuStateList = Object.assign(new Array<Object>(), JSON.parse(storeNavigationMenuStateData));
            
            let navigationMenuModel: NavigationMenu = transactionInfo.getNavigationMenu();
            
            for (let i in navigationMenuStateList) {
                if (navigationMenuStateList[i]['id'] === 'getStartedState') {
                    navigationMenuModel.setGetStartedState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'confirmTravelPeriodState') {
                    navigationMenuModel.setConfirmTravelPeriodState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'confirmIncidentDetailsState') {
                    navigationMenuModel.setConfirmIncidentDetailsState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'selectInsuredState') {
                    navigationMenuModel.setSelectInsuredState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'accidentDetailsState') {
                    navigationMenuModel.setAccidentDetailsState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'injuryDetailsState') {
                    navigationMenuModel.setInjuryDetailsState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'selectClaimState') {
                    navigationMenuModel.setSelectClaimState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'claimDetailsState') {
                    navigationMenuModel.setClaimDetailsState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'paymentAndContactState') {
                    navigationMenuModel.setPaymentAndContactState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'reviewSummaryState') {
                    navigationMenuModel.setReviewSummaryState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'genericClaimDetailState') {
                    navigationMenuModel.setGenericClaimDetailState(navigationMenuStateList[i]['value']);
                } else if (navigationMenuStateList[i]['id'] === 'genericUploadDocumentState') {
                    navigationMenuModel.setGenericUploadDocumentState(navigationMenuStateList[i]['value']);
                }
            }
        }

    }

    storeClaimDetailMenu(transactionInfo: TransactionInfo) {
        let claimDetailMenu = transactionInfo.getNavigationMenu()['claimDetailMenu'];
        if (claimDetailMenu) {
            // if empty claim detail menu regenerate claim menu.
            if (claimDetailMenu.length == 0) {
                this.claimService.getClaimTypeSubMenu(transactionInfo).subscribe(data => {
                    sessionStorage.setItem("claimTypeMenu", JSON.stringify(data));
                });        
            }
            sessionStorage.setItem("claimTypeMenu", JSON.stringify(claimDetailMenu));
        }
    }

    retrieveClaimDetailMenu() {
        let claimTypeMenu: ClaimTypeMenu[];
        let claimTypeMenuData = sessionStorage.getItem("claimTypeMenu");
        if (claimTypeMenuData) {
            claimTypeMenu = Object.assign(new Array<ClaimTypeMenu>(), JSON.parse(claimTypeMenuData));
            console.log("Retrieved Claim Detail Menu");
        }     
        return claimTypeMenu;  
    }

    setInitialised(value: boolean) {
        this.initialised = value;
    }

}


