<div class="body_table_wrapper modal_body_table_wrapper">
    <div *ngIf="modalTableIsLoading; else tableTabContent">
        <p>Loading...</p>
    </div>
    <ng-template #tableTabContent>
        <table class="tb_bd_collection modal_tb_bd_collection" aria-label="area body table">
            <thead>
                <tr>
                    <th class="tb_selected_body_part">{{'ecClaim.injuryDetails.selectedBodyPart' | translate}}</th>
                    <th class="tb_injury modal_tb_injury">{{'ecClaim.injuryDetails.injuryLabel' | translate}}</th>
                    <th class="tb_remove modal_tb_remove"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of modalTableSelect">
                    <td class="td_selected_part">{{ item.label | translate }}</td>
                    <td class="td_injury modal_td_injury">
                        <ng-select
                            [items]="modalTableInjuryList"
                            bindLabel="label"
                            placeholder="{{'ecClaim.accidentDetails.natureOfInjury.label' | translate}}"
                            appendTo=".modal_td_injury"
                            [hideSelected]="true"
                            clearAllText="Clear"
                            [loading]="modalTableIsLoading"
                            [searchFn]="customSearchFn"
                            [multiple]="true"
                            [(ngModel)]="item.injury"
                            addTagText="Add injury" 
                            [addTag]="addTagFn"
                        >
                            <ng-template #labelTemplate ng-label-tmp let-item="item" let-clear="clear">
                               {{ item.label | translate }}
                               <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                               {{ item.label | translate }}
                            </ng-template>
                        </ng-select>
                    </td>
                    <td class="td_remove">
                        <button (click)="modalTableOnClickRemoveSpecificArea(item.id, item.area)" class="td_btn_remove">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                                <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" fill="#1B459C"/>
                            </svg>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="!modalTableSelect">
            <p>Error loading table</p>
        </div>
    </ng-template>
</div>