import { Component, OnInit } from '@angular/core';
import {ClaimTypes} from "../../../model/claim-type.model";
import {TransactionInfoService} from "../../../services/transaction-info.service";

@Component({
  selector: 'app-rental-vehicle',
  templateUrl: './rental-vehicle.component.html',
  styleUrls: ['./rental-vehicle.component.css']
})
export class RentalVehicleComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]);
    }

  ngOnInit() {
  }

}
