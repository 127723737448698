<div class="section form-group">
    <label>{{'homeProduct.responsibility.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.responsibility.anyPersonInvolved' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{returnYesOrNo(genericResponsibility.getHasOtherPersonInvolved())}}
                </div>
            </div>
            <ng-container *ngIf="genericResponsibility.getHasOtherPersonInvolved()">
                <div class="row" *ngIf="isNotNullOrUndefinedStr(genericResponsibility.getContactName())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.responsibility.personName' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{genericResponsibility.getContactName()}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(genericResponsibility.getContactNumber())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.responsibility.contactNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{genericResponsibility.getContactNumber()}}
                    </div>
                </div>
                <div class="row" *ngIf="isNotNullOrUndefinedStr(genericResponsibility.getAddress())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.responsibility.address' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{genericResponsibility.getAddress()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.responsibility.circumstances' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{genericResponsibility.getDescription()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
