export class ClaimTypeSubMenu {

    id: number;
    name = '';
    url = '';
    complete: boolean = null;
    hidden: boolean = null;

    static jsonConvert(claimTypeSubMenu: ClaimTypeSubMenu): ClaimTypeSubMenu {

        claimTypeSubMenu = Object.assign(new ClaimTypeSubMenu(), claimTypeSubMenu);

        return claimTypeSubMenu;
    }

}