import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ClaimsBaseComponent} from "../../claim-base.component";
import {GenericResponsibility} from "../../../model/home/generic-responsibility.model";
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-generic-responsibility-details',
    templateUrl: './generic-responsibility.component.html',
    styleUrls: ['./generic-responsibility.component.css']
})
export class GenericResponsibilityComponent extends ClaimsBaseComponent implements OnInit {

    genResponsibilityForm: FormGroup;
    genResponsibility: GenericResponsibility;
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.genResponsibility =  this.claim.getClaimHome().getHomeDetails().getGenericResponsibility();
    }

    ngOnInit() {
        this.pushGAResponsibilityView();
        
        this.genResponsibilityForm = this.fb.group({
            hasOtherPersonInvolved: [super.getBooleanString(this.genResponsibility.getHasOtherPersonInvolved())],
            contactName: [this.genResponsibility.getContactName()],
            contactNumber: [this.genResponsibility.getContactNumber()],
            contactAddress: [this.genResponsibility.getAddress()],
            description: [this.genResponsibility.getDescription()]
        });
    }

    ngAfterViewInit() {
         this.genResponsibilityForm.valueChanges.subscribe(data => {
             this.genResponsibility.setContactName(this.genResponsibilityForm.get('contactName').value);
             this.genResponsibility.setContactNumber(this.genResponsibilityForm.get('contactNumber').value);
             this.genResponsibility.setAddress(this.genResponsibilityForm.get('contactAddress').value);
             this.genResponsibility.setDescription(this.genResponsibilityForm.get('description').value);
             if (this.genResponsibilityForm.get('hasOtherPersonInvolved').value != null) {
                 this.genResponsibility.setHasOtherPersonInvolved(this.genResponsibilityForm.get('hasOtherPersonInvolved').value == 'true');
             }
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideShowOtherPerson(self.getBooleanString(self.genResponsibility.getHasOtherPersonInvolved())),10});
        this.genResponsibilityForm.get('hasOtherPersonInvolved').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowOtherPerson(data);
        });
    }

    hideShowOtherPerson(hasOtherPersonInvolved) {
        if (hasOtherPersonInvolved == 'true') {
            this.genResponsibilityForm.get('contactName').enable({onlySelf: false, emitEvent: false});
            this.genResponsibilityForm.get('contactNumber').enable({onlySelf: false, emitEvent: false});
            this.genResponsibilityForm.get('contactAddress').enable({onlySelf: false, emitEvent: false});
            this.genResponsibilityForm.get('description').enable({onlySelf: false, emitEvent: false});
        } else {
            this.genResponsibilityForm.get('contactName').reset();
            this.genResponsibilityForm.get('contactName').disable({onlySelf: false, emitEvent: false});
            this.genResponsibilityForm.get('contactNumber').reset();
            this.genResponsibilityForm.get('contactNumber').disable({onlySelf: false, emitEvent: false});
            this.genResponsibilityForm.get('contactAddress').reset();
            this.genResponsibilityForm.get('contactAddress').disable({onlySelf: false, emitEvent: false});
            this.genResponsibilityForm.get('description').reset();
            this.genResponsibilityForm.get('description').disable({onlySelf: false, emitEvent: false});
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.genResponsibilityForm);
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    pushGAResponsibilityView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' – Responsibility';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/responsibility';

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,            
            'event': 'vpageview'
        });
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }
}
