<div class="form-group section">
        <label class="section-title">{{'pageSummaryClaim.claimItem.sectionLostItem.title' | translate}}</label>
        <div class="section-content">
            <div class="sub-section">
                <label>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecLossDetail.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionLostItem.subSecLossDetail.dateTheDamageOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(this.lostPersonalItem.getDateTheDamageOccurred())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.lostPersonalItem.getCountryTheDamageOccurredDesc()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionLostItem.subSecLossDetail.countryTheDamageOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.lostPersonalItem.getCountryTheDamageOccurredDesc()}}
                    </div>
                </div>
                <div class="row" *ngIf="this.lostPersonalItem.getPlaceOfLossDesc()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimSection.lostPersonalItem.detailOfLoss.whereLossOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.lostPersonalItem.getPlaceOfLossDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionLostItem.subSecLossDetail.howDamageOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content" *ngIf="this.lostPersonalItem.getHowDamageOccurred()?.toLowerCase() !== 'others'">
                        {{this.lostPersonalItem.getHowDamageOccurredDesc()}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="this.lostPersonalItem.getHowDamageOccurred()?.toLowerCase() === 'others'">
                        {{this.lostPersonalItem.getOtherDamageOccurred()}}
                    </div>
                </div>
            </div>
            <div class="sub-section">
                <label>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.title' | translate}}</label>
                <ng-container *ngFor="let item of this.lostPersonalItem.getDetailsOfLostItemsArray(); let i = index;">
                    <div class="row" *ngIf="isNotNullOrUndefinedStr(item.getLossTypeDesc())">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="isNotNullOrUndefinedStr(item.getLossTypeDetail())">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.descOfIncident' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getLossTypeDetail()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="isNotNullOrUndefinedStr(item.getOtherTypeBriefDesc())">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.otherTypeBriefDesc' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getOtherTypeBriefDesc()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="isNotNullOrUndefined(item.getPurchaseDate())">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row" *ngIf="isNotNullOrUndefined(item.getPurchasePrice())">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                    <div class="row" *ngIf="isNotNullOrUndefined(item.getWasWatchBeingStored())">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.wasWatchBeingStored' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(item.getWasWatchBeingStored())}}
                        </div>
                    </div>
                    <div class="row" *ngIf="isNotNullOrUndefined(item.getHasOriginalPurchasedReceipt())">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.hasOriginalPurchasedReceipt' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(item.getHasOriginalPurchasedReceipt())}}
                        </div>
                    </div>

                    <ng-container *ngIf="['Passport / HKID / China Re-entry card', 'Passport / NRIC / FIN',
                        'Passport / NRIC'].indexOf(item.getLossType()) !== -1">
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.costOfRepalceCost' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getCostOfRepalceCostType()}} {{item.getCostOfRepalceCost() | number : '1.2-2'}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.costOfTransCost' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                <span *ngIf="item.getHasExtraTransCost()">{{item.getCostOfTransCostType()}}&nbsp;{{item.getCostOfTransCost() | number : '1.2-2'}}</span>
                                <span *ngIf="!item.getHasExtraTransCost()">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.costOfAccomCost' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                <span *ngIf="item.getHasExtraAccomCost()">{{item.getCostOfAccomCostType()}}&nbsp;{{item.getCostOfAccomCost() | number : '1.2-2'}}</span>
                                <span *ngIf="!item.getHasExtraAccomCost()">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.getLossType() === 'Personal Money'">
                       <div class="row">
                           <div class="col col-xs-12 col-lg-7">
                               {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.lostMoneyAmount' | translate}}
                           </div>
                           <div class="col col-xs-12 col-lg-5 content">
                               {{item.getLostMoneyType()}} {{item.getLostMoneyAmount() | number : '1.2-2'}}
                           </div>
                       </div>
                   </ng-container>
                   <ng-container *ngIf="item.getLossType() === 'Credit Card'">
                       <div class="row">
                           <div class="col col-xs-12 col-lg-7">
                               {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.unauthorisedMoneyLoss' | translate}}
                           </div>
                           <div class="col col-xs-12 col-lg-5 content">
                               {{item.getUnauthorisedMoneyLossType()}} {{item.getUnauthorisedMoneyLoss() | number : '1.2-2'}}
                           </div>
                       </div>
                   </ng-container>
                   <div class="row" *ngIf="item.getDepreciationRate()">
                       <div class="col col-xs-12 col-lg-7">
                           {{'claimSection.damagePersonalItem.detailsOfDamageItems.depreciationRate' | translate}}
                       </div>
                       <div class="col col-xs-12 col-lg-5 content">
                           {{item.getDepreciationRate()}}<span *ngIf="item.getDepreciationRate() !== 'NA'">%</span>
                       </div>
                   </div>
               </ng-container>
           </div>
           <div class="sub-section">
               <label>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecReportLoss.title' | translate}}</label>
               <div class="row">
                   <div class="col col-xs-12 col-lg-7">
                       {{'pageSummaryClaim.claimItem.sectionLostItem.subSecReportLoss.rptToAuthority' | translate}}
                   </div>
                   <div class="col col-xs-12 col-lg-5 content">
                       <span>{{returnYesOrNo(this.lostPersonalItem.getHasAnotherAuthority())}}</span>
                   </div>
               </div>
               <ng-container *ngIf="this.lostPersonalItem.getHasAnotherAuthority()">
                   <div class="row">
                       <div class="col col-xs-12 col-lg-7">
                           {{'pageSummaryClaim.claimItem.sectionLostItem.subSecReportLoss.authorityType' | translate}}
                       </div>
                       <div class="col col-xs-12 col-lg-5 content" *ngIf="this.lostPersonalItem.getAuthorityType() !== 'Others'">
                           {{this.lostPersonalItem.getAuthorityTypeDesc()}}
                       </div>
                       <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="this.lostPersonalItem.getAuthorityType() === 'Others'">
                           {{this.lostPersonalItem.getOtherAuthorityType()}}
                       </div>
                   </div>
                   <div class="row">
                       <div class="col col-xs-12 col-lg-7">
                           {{'pageSummaryClaim.claimItem.sectionLostItem.subSecReportLoss.reportDate' | translate}}
                       </div>
                       <div class="col col-xs-12 col-lg-5 content">
                           {{getDate(this.lostPersonalItem.getReportDate())}}
                       </div>
                   </div>
               </ng-container>
               <div class="row">
                   <div class="col col-xs-12 col-lg-7">
                       {{'pageSummaryClaim.claimItem.sectionLostItem.subSecReportLoss.rptCompRecieved' | translate}}
                   </div>
                   <div class="col col-xs-12 col-lg-5 content">
                       <span>{{returnYesOrNo(this.lostPersonalItem.getHasCompensationReceived())}}</span>
                   </div>
               </div>
               <ng-container *ngIf="this.lostPersonalItem.getHasCompensationReceived()">
                   <div class="row">
                       <div class="col col-xs-12 col-lg-7">
                           {{'pageSummaryClaim.claimItem.sectionLostItem.subSecReportLoss.compensationMoney' | translate}}
                       </div>
                       <div class="col col-xs-12 col-lg-5 content">
                           {{this.lostPersonalItem.getCompensationCurrency()}} {{this.lostPersonalItem.getCompensationValue() | number : '1.2-2'}}
                       </div>
                   </div>
               </ng-container>
           </div>
           <div class="sub-section">
               <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
               <div class="row" *ngFor="let item of this.listOfDocuments;">
                 <div class="col col-xs-12 col-lg-12">
                     {{item.description | translate}}
                 </div>
                 <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                     <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                         {{ item.uploadedFileName }}
                     </div>
                 </ng-container>
             </div>
           </div>
   </div>
   <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
       <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
       <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
         <div class="col col-xs-12 col-lg-12">
             {{item.description | translate}}
         </div>
         <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
             <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                 {{ item.uploadedFileName }}
             </div>
         </ng-container>
     </div>
   </div>
</div>

<ng-template #documentNotUploaded>
   <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
       {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
   </div>
</ng-template>
