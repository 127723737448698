import {Component, OnInit} from "@angular/core";
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-lost-personal-item',
  templateUrl: './lost-personal-item.component.html',
  styleUrls: ['./lost-personal-item.component.css']
})
export class LostPersonalItemComponent implements OnInit {
    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]);
    }

  ngOnInit() {
  }

}
