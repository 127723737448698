import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class RepairFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_REPAIR];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        requiredDocuments.push(new DocumentField('REPAIR_LETTEROFAUTHORIZATION', 'motorClaim.claimSection.documents.letterOfAuthorization', SmartqDocuments.MTR_AUTHLETTER, true));
        requiredDocuments.push(new DocumentField('REPAIR_HKID', 'motorClaim.claimSection.documents.hkid', SmartqDocuments.MTR_HKID, true));
        requiredDocuments.push(new DocumentField('REPAIR_DRIVERSLICENSE', 'motorClaim.claimSection.documents.driversLicense', SmartqDocuments.MTR_DRIVINGLICENSE, true));
        requiredDocuments.push(new DocumentField('REPAIR_VEHICLEREGISTRATION', 'motorClaim.claimSection.documents.vehicleRegistration', SmartqDocuments.MTR_REGDOC, true));
        requiredDocuments.push(new DocumentField('REPAIR_VEHICLELICENSE', 'motorClaim.claimSection.documents.vehicleLicense', SmartqDocuments.MTR_REGDOC, true));
        requiredDocuments.push(new DocumentField('REPAIR_POLICESTATEMENT', 'motorClaim.claimSection.documents.policeStatement', SmartqDocuments.MTR_POLICEREPORT, true));
        requiredDocuments.push(new DocumentField('REPAIR_BREATHTESTRESULT', 'motorClaim.claimSection.documents.breathTestResult', SmartqDocuments.MTR_OTHERS, true));
        requiredDocuments.push(new DocumentField('REPAIR_PHOTOOFACCIDENT', 'motorClaim.claimSection.documents.photoOfAccident', SmartqDocuments.MTR_POLICEREPORT, true));
        requiredDocuments.push(new DocumentField('REPAIR_CARVIDEO', 'motorClaim.claimSection.documents.carVideo', SmartqDocuments.MTR_DVD, true));
        requiredDocuments.push(new DocumentField('REPAIR_REPAIRQUOTATION', 'motorClaim.claimSection.documents.repairQuotation', SmartqDocuments.MTR_QUOTATION, true));
      
        this.requiredDocuments = requiredDocuments;
    }
}
