<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.detailsOfNatureIllness' | translate}} </label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.countryIncidentOccur' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                        {{dentalExpenses.getCountryIncidentOccurDesc()}}
                </div>
            </div>
            <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.isIncidentReport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNoBoolean(dentalExpenses.getIsIncidentReport())}}
                    </div>
            </div>
            <div class="row" *ngIf="this.dentalExpenses.getIsIncidentReport()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.specifyCondition' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                                {{dentalExpenses.getSpecifyCondition()}} 
                        </div>

            </div>
            <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.selectTypeTreatment' | translate}}
                       </div>
                       <div class="col col-xs-12 col-lg-5 content">
                            {{dentalExpenses.getSelectTypeTreatmentDesc()}}  
                       </div>
            </div>            
        </div>
        <div class="sub-section">
                <label>{{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.dentalIlnessIncurred' | translate}} </label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.dateOFVisit' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(dentalExpenses.getDateOfVisit())}}
                    </div>
                </div>
                <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.summaryOfDentalExpenses.dentalExpenseAmount' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                                {{dentalExpenses.getDentalExpenseAmountCurrency()}} {{dentalExpenses.getDentalExpenseAmount() | number : '1.2-2'}} 
                        </div>
                </div>           
            </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>