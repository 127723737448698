<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.detailsOfIncident' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                     {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.dateOfincident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(dishonestyHelper.getDateOfIncident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.incidentOccured' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{dishonestyHelper.getCountryIncidentOccurDesc()}}
                </div>
            </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.descOfIncident' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{dishonestyHelper.getDescOfIncident()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.helpersLiabilitAmountClaim' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{dishonestyHelper.getAmountClaimCurrency()}} {{dishonestyHelper.getAmountClaim() | number : '1.2-2'}}
                    </div>
                </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.isIncidentReportedToPolice' | translate}} 
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                                {{returnYesOrNoBoolean(dishonestyHelper.getIsIncidentReport())}}
                        </div>
                    </div>
                    <div class="row" *ngIf="this.dishonestyHelper.getIsIncidentReport()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.dateOfReport' | translate}}  
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(dishonestyHelper.getDateOfReport())}}
                        </div>
                    </div>
                    <div class="row"  *ngIf="this.dishonestyHelper.getIsIncidentReport()">
                            <div class="col col-xs-12 col-lg-7">
                                {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.policeStationNameAdd' | translate}} 
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{dishonestyHelper.getPoliceStationNameAndAddress()}}
                            </div>
                        </div>    
                        <div class="row"  *ngIf="this.dishonestyHelper.getIsIncidentReport()">
                                <div class="col col-xs-12 col-lg-7">
                                        {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.summaryOfDishonestyHelper.reportRefNo' | translate}} 
                                </div>
                                <div class="col col-xs-12 col-lg-5-word-break content">
                                    {{dishonestyHelper.getReferenceNumber()}}
                                </div>
                            </div>        
                </div>               
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>