<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
      <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
      <p>{{ 'motorClaim.driverDetails.policeReport.heading' | translate }}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
                <qnect-ui #MV_DD_POLICE_REPORT [fieldId]="'MV_DD_POLICE_REPORT'" [formGroup]="policeReportForm" name="hasReportedIncidentToPolice"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="policeReport.getHasReportedIncidentToPolice() == true">
              <div class="form-group">
                <qnect-ui #MV_DD_REPORT_DATE [fieldId]="'MV_DD_REPORT_DATE'" [formGroup]="policeReportForm" [claimsDateValidator]="this.claimMotor.getCollission().getAccidentIncidentDate()" name="dateOfReport"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #MV_DD_REPORT_NUMBER [fieldId]="'MV_DD_REPORT_NUMBER'" [formGroup]="policeReportForm" name="policeReportNumber"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #MV_DD_POLICE_STATION [fieldId]="'MV_DD_POLICE_STATION'" [formGroup]="policeReportForm" name="policeStation"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #MV_DD_LODGED_COMPLAINT [fieldId]="'MV_DD_LODGED_COMPLAINT'" [formGroup]="policeReportForm" name="hasComplainedAgainstOtherParty"></qnect-ui>
              </div>
            </div>
            <div class="form-group" *ngIf="policeReport.getHasReportedIncidentToPolice() == false">
               <div class="row" id="report-warning">
                    <div class="col-sm-2" align="center" style="padding: 5px;">
                        <img src="assets/images/exclamation-trvl-dly-final.png" id="ray-report">
                    </div>
                    <div class="col-sm-10">
                       <div *ngIf="claimMotor.getCollission().getDetailsOfDamagedType().length > 0"  class="report-warning-msg-1">
                           <p>{{ 'motorClaim.driverDetails.policeReport.notReportedToPoliceDesc1' | translate}}</p><br>
                           <p>{{ 'motorClaim.driverDetails.policeReport.notReportedToPoliceDesc2' | translate}}</p>
                        </div>
                        <div *ngIf="claimMotor.getCollission().getDetailsOfDamagedType() == null || claimMotor.getCollission().getDetailsOfDamagedType().length == 0"  class="report-warning-msg-2">
                          <p>{{ 'motorClaim.driverDetails.policeReport.notReportedToPoliceDesc1' | translate}}</p>
                        </div>
                    </div>
                </div>
           </div>
       </form>
   </div>
   <div class="col-xl-3 col-lg-12">
   </div>
  </div>

  <div class="submit-bar">
   <abandon-button></abandon-button>
   <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
   <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
  </div>