import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from '../../../../services/side-menu.service';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {OtherClaim} from "../../../../model/travel/other-claim.model";
import { DocumentField } from '../../../../model/document-field';
import { ClaimTypes } from '../../../../model/claim-type.model';
import { DocumentHelperService } from "../../../../services/document-helper.service";
@Component({
  selector: 'app-summary-other-claim',
  templateUrl: './summary-other-claim.component.html',
  styleUrls: ['./summary-other-claim.component.css']
})
export class SummaryOtherClaimComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    otherClaim: OtherClaim;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
        private documentHelperService: DocumentHelperService,
                private injector : Injector) {

           super(injector);
           this.claim = this.claimService.getClaim();
           this.otherClaim = this.claim.getClaimTravel().getOtherClaim();
           this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]);
            this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]);
    }

    ngOnInit() {

    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}
