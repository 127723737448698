<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i
                class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div clas="col-12">
        <h5>{{ 'claimSection.generalLabels.whatHappened' | translate }}</h5>
        <p>{{ 'claimSection.missedConnection.detailsOfMissedConnection.title' | translate }}</p>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <div class="tab-content">
                <div class="tab-pane fade in active show">
                    <form>
                        <div class="form-group">
                            <qnect-ui #MISS_CONNECT_ORIGIN_DATE [fieldId]="'MISS_CONNECT_ORIGIN_DATE'" [formGroup]="missConnectionFormGroup"
                                      name="originArrivalTime"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MISS_CONNECT_ACTUAL_DATE [fieldId]="'MISS_CONNECT_ACTUAL_DATE'" [formGroup]="missConnectionFormGroup"
                            [claimsDateValidator]="this.missConnection.getOriginalArrivalDate()" name="actualArrivalTime"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MISS_CONNECT_COUNTRY [fieldId]="'MISS_CONNECT_COUNTRY'" [formGroup]="missConnectionFormGroup"
                                      name="countryOccurred"></qnect-ui>
                        </div>
                        <div class="form-group click-more-content" id="q1">
                            <qnect-ui #MISS_CONNECT_REASON [fieldId]="'MISS_CONNECT_REASON'" name="reasonOfMissConnect" [formGroup]="missConnectionFormGroup"></qnect-ui>
                        </div>
                        <div>
                            <div class="form-group click-more-content" id="q2" *ngIf="missConnection.getReasonOfMissConnect() === 'Other'">
                                <qnect-ui #MISS_CONNECT_OTHER_REASON [fieldId]="'MISS_CONNECT_OTHER_REASON'" name="otherReasonOfMissConnect" [formGroup]="missConnectionFormGroup"></qnect-ui>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-lg-12">
        </div>
    </div>
    <additional-claimant></additional-claimant>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
