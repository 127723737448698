import { AfterViewInit, Directive, DoCheck, Input } from '@angular/core';
import { UiComponent } from '../ui.component';
import {Subject, takeUntil} from "rxjs";

@Directive({selector: '[qnectToggleDisplayOnParentValueEnter]'})
export class ToggleDisplayOnParentValueEnterDirective implements AfterViewInit, DoCheck {


  constructor(private uiComponent: UiComponent) {}

  @Input() qnectToggleDisplayOnParentValueEnter: UiComponent[] = [];

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.toggleDisplay();

    for (let uiComponent of this.qnectToggleDisplayOnParentValueEnter) {
      uiComponent
          .valueChanges()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(data => {
              this.toggleDisplay();
          });
    }

  }

  ngDoCheck() {
  }

  private toggleDisplay() {
    for (let uiComponent of this.qnectToggleDisplayOnParentValueEnter) {
      if (uiComponent.getValue()) {
        this.uiComponent.show();
        break;
      } else {
        this.uiComponent.hide();
      }
    }
  }
}
