import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DynamicSubject} from './service/dynamic-table.subject';
import {DynamicItemAction} from './model/dynamic-item-action.model';
import {SubscriptionComponent} from "../../../core/subscription.component";

@Component({
  selector: 'qnect-dynamic-form-buttons',
  templateUrl: './dynamic-form-buttons.component.html'
})
export class DynamicFormButtonsComponent extends SubscriptionComponent implements OnInit, OnDestroy {

  @Output()
  action: EventEmitter<string> = new EventEmitter();

  @Input()
  subject: DynamicSubject;

  isEdit: boolean;

  constructor() {
    super();
  }

  ngOnInit() {
    this.addSubscription(this.subject.actionItem.subscribe(actionItem => {
      this.isEdit = DynamicItemAction.DISPLAY === actionItem.action || DynamicItemAction.RETRIEVE === actionItem.action;
    }));
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  edit() {
    this.action.emit(DynamicItemAction.EDIT);
  }

  add() {
    this.action.emit(DynamicItemAction.ADD);
  }

  clear() {
    this.action.emit(DynamicItemAction.CLEAR);
  }

}
