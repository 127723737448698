export class ClaimTypeItem {
    claimTypeId: number = null ;
    claimTypeCode = '';
    claimTypeIdName = '';
    description = "";
    icon = '';
    selected: boolean = null;
    show: boolean = null;
    completed: boolean = null;
    gaPageStep: string = '';
    gaVPath: string = '';

    static jsonConvert(claimTypeItem: ClaimTypeItem): ClaimTypeItem {

        claimTypeItem = Object.assign(new ClaimTypeItem(), claimTypeItem);

        return claimTypeItem;
    }
}