import {Component, OnInit} from "@angular/core";
import {UiBaseComponent} from "../ui-base.component";

@Component({
  selector: 'qnect-ui-caption',
  templateUrl: './ui-caption.component.html'
})
export class UiCaptionComponent extends UiBaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    this.uiModel.labelText = this.fieldConfig['labelText'];
    this.uiModel.dictionary = this.fieldConfig['dictionary'];
  }

}
