import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {ClaimTypes} from "../../../model/claim-type.model";
import { HomeFormBuilderUtil } from "./home-form-builder-util";

export class PersonalAccidentClaimFormBuilder extends DocumentFormBuilderComponent implements OnInit {
   
    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];        
        let utilities: HomeFormBuilderUtil = new HomeFormBuilderUtil();
        requiredDocuments = requiredDocuments.concat(utilities.getRiskDocuments(this.claim));
        
        this.requiredDocuments = requiredDocuments;
    }
}