import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {VisitedDoctor} from "../../../../model/travel/visted-a-doctor.model";

@Component({
    selector: 'app-details-of-oversea-doctor-visit',
    templateUrl: './details-of-oversea-doctor-visit.component.html',
    styleUrls: ['./details-of-oversea-doctor-visit.component.css']
})
export class DetailsOfOverseaDoctorVisitComponent extends ClaimsBaseComponent implements OnInit {

    deTailOfOverseaDoctorForm: FormGroup;
    showTotalBox = false;
    visitedDoctor: VisitedDoctor;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.visitedDoctor = this.claim.getClaimTravel().getVisitedDoctor();
    }


    ngOnInit() {

        this.pushGAViewDoctorOversea();

        this.deTailOfOverseaDoctorForm = this.fb.group(
            {
                wasVisitedpractitioner: [super.getBooleanString(this.visitedDoctor.getWasVisitedpractitioner())]
            }
        );
    }

    ngAfterViewInit() {
        this.deTailOfOverseaDoctorForm.valueChanges.subscribe(data => {
            if (this.deTailOfOverseaDoctorForm.get('wasVisitedpractitioner').value != null) {
                this.visitedDoctor.setWasVisitedpractitioner(this.deTailOfOverseaDoctorForm.get('wasVisitedpractitioner').value == 'true');
            }
            this.validateClaimDetailsComplete();
        });

    }



    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 1});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/visitedADoctor/followUpTreatment"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfOverseaDoctorForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfOverseaDoctorForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 4, subMenuIndex: 1});
        }
    }

    back() {
        this.router.navigate(["/claimform/visitedADoctor/detailsOfIllness"], {
            relativeTo: this.activatedRoute
        });
    }

    //Google Analytics
    pushGAViewDoctorOversea() {
        (<any>window).dataLayer.push({
            'pageStep': 'Visit Doctor – Oversea Doctor',
            'vPath': '/claim/visit-doctor/oversea-doctor',
            'event': 'vpageview',
        });
    }

}
