<form [formGroup]="fatalCaseForm" >
    <div class="form-group">
      <qnect-ui #EC_FATAL_POLICE_NOTIFIED [fieldId]="'EC_FATAL_POLICE_NOTIFIED'" [formGroup]="fatalCaseForm" name="wasPoliceNotified"></qnect-ui>
    </div>
    <div class="form-group" *ngIf="this.fatalCase.getWasPoliceNotified()">
      <qnect-ui #EC_FATAL_POLICE_STATION [fieldId]="'EC_FATAL_POLICE_STATION'" [formGroup]="fatalCaseForm" name="policeStation"></qnect-ui>
    </div>
    <div class="form-group">
      <qnect-ui #EC_FATAL_KIN [fieldId]="'EC_FATAL_KIN'" [formGroup]="fatalCaseForm" name="kin"></qnect-ui>
    </div>
    <div class="form-group">
      <qnect-ui #EC_FATAL_RELATIONSHIP [fieldId]="'EC_FATAL_RELATIONSHIP'" [formGroup]="fatalCaseForm" name="relationship"></qnect-ui>
    </div>
    <div class="form-group">
      <qnect-ui #EC_FATAL_TELEPHONE [fieldId]="'EC_FATAL_TELEPHONE'" [formGroup]="fatalCaseForm" name="telephoneNumber"></qnect-ui>
    </div>
    <div class="form-group">
      <qnect-ui #EC_FATAL_ADDRESS [fieldId]="'EC_FATAL_ADDRESS'" [formGroup]="fatalCaseForm" name="address"></qnect-ui>
    </div>
</form>