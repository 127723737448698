import {Component, Injector, OnInit} from "@angular/core";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {LostPersonalItem} from "src/app/model/travel/lost-personal-item.model";
import { ClaimTypes } from "../../../../model/claim-type.model";
import {DepreciationService} from '../../../../services/depreciation.service';
import * as moment from "moment";
import { PoliceReport } from "src/app/model/motor/police-report.model";
import { UtilitiesService } from "src/app/utilities/utilities.service";
@Component({
  selector: 'app-summary-lost-personal-items',
  templateUrl: './summary-lost-personal-items.component.html',
  styleUrls: ['./summary-lost-personal-items.component.css']
})
export class SummaryLostPersonalItemsComponent extends ClaimsBaseComponent implements OnInit {
    
    claim: Claim;
    lostPersonalItem: LostPersonalItem;
    policeReport: PoliceReport;

    constructor(private claimService: ClaimService,
                private depreciationService: DepreciationService,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.lostPersonalItem = this.claim.getClaimHome().getHomeDetails().getLostPersonalItem();
            this.policeReport = this.claim.getClaimHome().getHomeDetails().getPoliceReport();
    }

    ngOnInit() {
        if(this.claim.getCountry() === 'HKG' || this.claim.getCountry() === 'HGI'){
            this.getDepreciationRateForItems();
        }
    }

    getDepreciationRateForItems() {
        let lostItemsList = this.lostPersonalItem.getDetailsOfLostItemsArray();
        let dateOfDamage = moment(this.lostPersonalItem.getDateTheDamageOccurred(), 'DD/MM/YYYY');
        let country = this.claim.getCountry();

        if (!UtilitiesService.isNullOrUndefined(dateOfDamage) && lostItemsList.length > 0) {
            for (let i = 0; i < lostItemsList.length; i++) {
                let purchasedDate = lostItemsList[i].getPurchaseDate();
                let item =  lostItemsList[i].getLossType();
                let depreciationRate = "NA";
                if (!UtilitiesService.isNullOrUndefined(purchasedDate) && !UtilitiesService.isNullOrUndefined(lostItemsList[i].getPurchasePrice()) &&
                    lostItemsList[i].getPurchasePrice() > 0 && this.isNotNullOrUndefinedStr(item)) {

                    let startDate = moment(purchasedDate, 'DD/MM/YYYY');
                    let diffInYears = dateOfDamage.diff(startDate, 'year');
                    startDate.add(diffInYears, 'years');
                    let diffInMonths = dateOfDamage.diff(startDate, 'months');
                    startDate.add(diffInMonths, 'months');
                    let diffInDays = dateOfDamage.diff(startDate, 'days');
                    let totalMonths = diffInMonths + (12 * diffInYears);
                    let totalDays = diffInDays;

                    // special case for item hgi and specific agent codes
                    if (this.isNotNullOrUndefinedStr(country) && country.toLowerCase() == "hgi") {
                        let agentCode = this.claim.getAgentCode();
                        let isApplicableToPlan: boolean = this.depreciationService.isApplicableToQgiPlan(this.claim.getCatalogueCode(), this.claim.getPlanCode(),
                            this.claim.getClaimTravel().getAreaCode(), this.claim.getClaimTravel().getPeriodOfInsurance());
                        if (['011RP091','011RP092', '011RP093'].indexOf(agentCode) !== -1 && isApplicableToPlan) {
                            depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST], item);
                            if (item.toLowerCase() == "mobile phone") {
                                lostItemsList[i].setExcessDepreciation(20); // Apply 20% excess on mobile phones
                            }
                        } else if (!(totalMonths <= 24)) {
                            depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST], item);
                        }

                    } else {
                        depreciationRate = this.depreciationService.getDepreciationRate(totalMonths, totalDays, ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST], item);
                    }
                }
                lostItemsList[i].setDepreciationRate(depreciationRate);
            }
        }
    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if(!value){
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}
