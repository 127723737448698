import { Component, OnInit } from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from '../../../model/claim-type.model';

@Component({
  selector: 'app-repatriation',
  templateUrl: './repatriation.component.html',
  styleUrls: ['./repatriation.component.css']
})
export class RepatriationComponent implements OnInit {

  constructor( private transactionInfoService: TransactionInfoService) {
    this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]);
  }

  ngOnInit() {
  }

}
