import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from '@angular/router';
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-pages/claim-base.component";
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {ClaimTypes} from '../../../model/claim-type.model';
import {Notifications} from '../../../utilities/components/notification-messages/notifications.model';
import {TransactionInfo} from '../../../model/transaction-info.model';
import {ClaimEmployeeCompensation} from '../../../model/claim-employee-compensation.model';
import {ClaimTypeItemService} from '../../../services/claim-type-item-service';
import {NavigationMenuService} from '../../../services/navigation-menu.service';

declare let $: any;

@Component({
    selector: 'app-generic-select-claim',
    templateUrl: './generic-select-claim.component.html',
    styleUrls: ['./generic-select-claim.component.css']
})
export class GenericSelectClaimComponent extends ClaimsBaseComponent implements OnInit {
    country: string;
    claimTypeData: any[];
    notifications: Notifications = new Notifications();
    transactionInfo: TransactionInfo;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private injector : Injector,
                private fb: FormBuilder) {

        super(injector);
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.claimTypeData = this.getClaimTypes();
    }

    ngOnInit() {
        setTimeout(() => {
            this.showSvgPath();
        }, 10);

        if (this.isNotNullOrUndefinedStr(this.transactionInfo.getGenericClaimType())) {
            this.claimTypeData.find(claimType => this.transactionInfo.getGenericClaimType() == claimType.claimTypeId).selected = true;
        }
    }


    goToNext() {
        this.notifications.clearMessages();
        let claimType = this.claimTypeData.find(claimTypeItem => claimTypeItem.selected);
        if (claimType) {
            if (claimType.claimTypeId != "OTH") {
                if (this.claim.getClaimEmployeeCompensation() == null) {
                    this.claim.setClaimEmployeeCompensation(new ClaimEmployeeCompensation());
                }
                this.claim.getClaimEmployeeCompensation().setCurrency(UtilitiesService.getCountryCurrency(this.claim.getCountry()));

                let risk = this.claim.getPolicyRisks().find(risk => TransactionInfo.HK_EC_RISKS.indexOf(risk.getRiskType()) !== -1);
                if (risk) {
                    this.transactionInfo.setRiskType(risk.getRiskType());
                    this.claim.setRiskNumber(risk.getRiskNumber());
                    this.claim.setRiskType(risk.getRiskType());
                    this.claim.setPremiumClass(risk.getPremiumClasses()?.[0]);
                    this.claim.setIsGeneric(false);

                    this.claim.setForNotificationReason("");
                    this.claim.setAccountNumberDirect("");
                    this.claim.setPaymentClaimantName("");
                    this.claim.setContactName("");
                    this.claim.setClaimantIdNumber("");
                }

                this.claim.setSelectedClaimTypesList([]);

            } else {
                this.transactionInfo.setRiskType("");
                this.claim.setRiskNumber(0);
                this.claim.setForNotificationReason("Multi-risk policy");

                let policyHolderName = this.claim.getPolicyHolderName();
                this.claim.setAccountNameDirect(policyHolderName);
                this.claim.setPaymentClaimantName(policyHolderName);
                this.claim.setContactName(policyHolderName);
                this.claim.setClaimantIdNumber(this.transactionInfo.getAuthIdNumber());
                this.claim.setIsGeneric(true);

                let claimTypeList: string[] = [];
                claimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_GENERIC]);
                this.claim.setSelectedClaimTypesList(claimTypeList);
            }

            // navigation sub menu
            let claimTypeItemList = this.injector.get(ClaimTypeItemService).getClaimTypeList(this.claim.getCountry(), this.transactionInfo, "");
            this.transactionInfo.setClaimTypeItemList(claimTypeItemList);
            this.injector.get(NavigationMenuService).setupClaimNavigationMenu(this.transactionInfo, this.claim.getCountry());

            this.transactionInfo.setGenericClaimType(claimType.claimTypeId);
            this.router.navigate([claimType.nextRoute]);
        } else {
            this.notifications.showPreamble = false;
            this.notifications.addErrorMessage("error_messages.notificationsErrMsg.claimType");
        }
    }

    toggleActive(claimType) {
        this.claimTypeData.forEach(function (type) {
            if (type.claimTypeId == claimType.claimTypeId) {
                type.selected = !claimType.selected;
            } else {
                type.selected = false;
            }
        });
    }

    showSvgPath() {
        $('img.svg,.icon').each(function () {
            let $img = $(this);
            let imgID = $img.attr('id');
            let imgClass = $img.attr('class');
            let imgURL = $img.attr('src');

            $.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                let $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, else we gonna set it if we can.
                if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        });
    }

    getClaimTypes() {
        return [
            {
                "claimTypeId": "EC",
                "claimTypeName": "domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.title",
                "icon": "assets/images/domestic-helper-icon/employee-compensation.svg",
                "selected": false,
                "nextRoute": "/claimform/employerDetails"
            },
            {
                "claimTypeId": "OTH",
                "claimTypeName": "homeProduct.selectClaim.others",
                "icon": "assets/images/not-sure.svg",
                "selected": false,
                "nextRoute": "/claimform/genericClaimDetail"
            }
        ]
    }

}
