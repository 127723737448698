export class Version {
    
   public static version = 'uat1 09102024121216';
    
    public static getUrlVersion(): string {
        return '?version=' + this.getVersion();
    }

    public static getVersion(): string {
        return this.version;
    }
}