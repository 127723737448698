import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Claim } from 'src/app/model/claim.model';
import { Repatriation } from 'src/app/model/domestic-helper/repatriation.model';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimService } from 'src/app/services/claim.service';
import {Notifications} from "../../../../utilities/components/notification-messages/notifications.model";
import {ClaimTypeItemHelperService} from "../../../../services/claim-type-item-helper.service";

@Component({
  selector: 'app-details-of-repatriation',
  templateUrl: './details-of-repatriation.component.html',
  styleUrls: ['./details-of-repatriation.component.css']
})
export class DetailsOfRepatriationComponent extends ClaimsBaseComponent implements OnInit {

  showTotalBox = false;
  repatriationForm: FormGroup;
  claim: Claim;
  repatriation: Repatriation;
  notifications: Notifications;

  constructor(private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private sideMenuService: SideMenuService,
              private claimService: ClaimService,
              private injector : Injector,
              private claimTypeItemHelperService: ClaimTypeItemHelperService) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.repatriation = this.claim.getClaimDomesticHelper().getRepatriation();
  }

  ngOnInit() {

    this.pushGAViewRepatriationDetail();

        if(this.repatriation.getRepatClaimedAmount() == null){
            this.repatriation.setRepatClaimedCurrency('HKD')
        }

        if (this.repatriation.getCountryOfRepatIncident() == null || this.repatriation.getDescriptionOfRepatIncident() == ""){
            this.repatriation.setCountryOfRepatIncidentDesc('HONG KONG')
            this.repatriation.setCountryOfRepatIncident('HKG');
        }

        if (!this.repatriation.getDateOfRepatIncident()) {
            this.repatriation.setDateOfRepatIncident(this.claim.getDateOfLoss());
        }

        this.repatriationForm = this.fb.group({
            countryOfRepatIncident: [this.repatriation.getCountryOfRepatIncident()],
            dateOfRepatIncident : [this.repatriation.getDateOfRepatIncident()],
            descriptionOfRepatIncident : [this.repatriation.getDescriptionOfRepatIncident()],
            repatClaimedAmount : [this.repatriation.getRepatClaimedAmount()],
            repatClaimedCurrency: [this.repatriation.getRepatClaimedCurrency()]
        });

        this.notifications = new Notifications();
    }

  ngAfterViewInit() {

      this.repatriationForm.valueChanges.subscribe(data => {
          let repatriation: Repatriation = this.claim.getClaimDomesticHelper().getRepatriation();
          repatriation.setCountryOfRepatIncident(this.repatriationForm.get('countryOfRepatIncident').value);
          repatriation.setCountryOfRepatIncidentDesc(this.getTextInComponent("countryOfRepatIncident"));
          repatriation.setDescriptionOfRepatIncident( super.getValueInComponent('descriptionOfRepatIncident'));
          repatriation.setDateOfRepatIncident( super.getValueInComponent('dateOfRepatIncident'));
          repatriation.setRepatClaimedCurrency( this.repatriationForm.get('repatClaimedCurrency').value);
          repatriation.setRepatClaimedAmount( super.getValueInComponent('repatClaimedAmount'));
          
      });
      this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 8, subMenuIndex: 1, value: "Replacement or Temporary Helper"});
  }

  back() {
      this.router.navigate(["/claimform/claimDetail/selectClaim"], {
          relativeTo: this.activatedRoute
      });
  }

  goToNext() {
    let planCode = this.claim.getPlanCode();
    let planCodeList = ['F3','','F4','M3','M4','M7','M8','N0','N1','N2','N3','N4','N5','Q3','Q4','T1','T2','U3','U4','BA'];
    this.notifications.clearMessages();


      if (this.validateForm()) {
          this.sideMenuService.emitProcess(1, 0);
          this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 0});

          let isDateOfVisitAllowed = this.claimTypeItemHelperService.isAllowedDomesticClaimDetailsGroup(2, this.claim);
          if (isDateOfVisitAllowed) {
              if(planCodeList.indexOf(planCode) > 1){
                  this.sideMenuService.emitProcess(1, 0);
                  this.sideMenuService.emitClaimComplete({claimTypeId: 8, subMenuIndex: 1});
                  this.router.navigate(["/claimform/repatriation/supportDoc"], {
                      relativeTo: this.activatedRoute
                  });
              }else{
                  this.router.navigate(["/claimform/repatriation/extraCosts"], {
                      relativeTo: this.activatedRoute
                  });
              }
          } else {
              this.notifications = new Notifications();
              this.notifications.showPreamble = false;
              let errorMessage = "error_messages.domesticClaimGroup.notAllowedDateOfVisit";

              this.notifications.addErrorMessage(errorMessage);
          }




      }
  }

  validateForm(): boolean {
      return super.validateForm(this.repatriationForm);
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

    //Google Analytics
    pushGAViewRepatriationDetail() {
        (<any>window).dataLayer.push({
            'pageStep': 'Repatriation – Incident Details',
            'vPath': '/claim/repatriation/incident-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
              }
            }                                                                                                                                                    
        });
    }

}
