import {Injectable, Injector} from "@angular/core";
import {Logger} from "../utilities/service/logger/logger";
import {Claim} from "../model/claim.model";
import {Observable, throwError, catchError, finalize, map} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {SpinnerService} from "../core/spinner/spinner.service";
import { ErrorsService } from "../utilities/errors/errors.service";
import { FlightStatus } from "../model/flight-status.model";
import { FlightStatusOperationalTimes } from "../model/flight-status-operational-times.model";
import {DateService} from '../ui/ui-datepicker/date.service';

@Injectable()
export class FlightStatusService {

    appId: string = 'f0bbf460';
    appKey: string = '1ec1b7abbfef19ad1203a4faf31e1897';
    claim: Claim;

    constructor(private http: HttpClient,
                private _logger: Logger,
                private spinnerService : SpinnerService,
                private injector: Injector,
                private dateService: DateService) {
    }

    getFlightStatusByFlightDepartedOnDate(flightNumber: string, flightNumberDate:Date, integrationToken: string): Observable<FlightStatus>{

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization': 'Bearer ' + integrationToken
            })
        };

        let url: string = '/ms/claims-service/rest/get/flightDelay';
        url += "?flightNumber=" + encodeURIComponent(flightNumber?.toUpperCase());
        url += "&flightDate=" + this.dateService.dateToString(flightNumberDate, "dd/MM/yyyy");

        let flightStatusObservable: Observable<FlightStatus> = this.http.get<FlightStatus>(url, httpOptions)
            .pipe(map((res: FlightStatus) => {
                let flightStatus = this.mapFlightStatus(res);
                this.spinnerService.displayLoader(false);
                return flightStatus;
            }))
            //...errors if any
            .pipe(catchError(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false) }));

            return flightStatusObservable;
    }

    private mapFlightStatus(res: FlightStatus): FlightStatus{
        let flightStatus = new FlightStatus;
        Object.assign(flightStatus, res);
        
        let flightStatusOperationalTimesList: FlightStatusOperationalTimes[] = [];

        for (const flightStatusOperationalTimes of flightStatus.getFlightStatusOperationalTimesList()) {
            flightStatusOperationalTimesList.push(Object.assign(new FlightStatusOperationalTimes(),flightStatusOperationalTimes));
        } 
        flightStatus.setFlightStatusOperationalTimesList(flightStatusOperationalTimesList);    

        return flightStatus;
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        const errorsService = this.injector.get(ErrorsService); 
        let errMsg: string;
        if (error instanceof HttpResponse) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        errMsg = 'IntegrationService handleError' + errMsg;
        errorsService.log(errMsg);
        return throwError(() => new Error(errMsg));
    }
   

}


