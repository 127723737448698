import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'qnect-selectable',
  template: `
  <input type="checkbox" value="selected" (change)="toggle()">
  `
})
export class SelectableComponent implements OnInit {

  @Output("select")
  _select: EventEmitter<boolean> = new EventEmitter<boolean>();

  _selected: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this._selected = !this._selected;
    this._select.emit(this._selected);
  }

}
