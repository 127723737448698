import {AbstractControl} from '@angular/forms';
import {DateService} from '../ui-datepicker/date.service';
import * as moment from 'moment';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

// In order to make sure timezone doesn't shift the date around we set the time to noon.
function setDateAsNoonTime(dateToSet: Date): Date {
    if (dateToSet && dateToSet instanceof Date) {
        let d = dateToSet;
        dateToSet.setHours(12, 0, 0, 0);
        return d;
    }
    return null;
}

function isValidDate(srtDate: any) {
    let dateMomentValidFormat1 = moment(srtDate, 'DD/MM/YYYY');
    let dateMomentValidFormat2 = moment(srtDate, 'DDMMYYYY');

    if (dateMomentValidFormat1.isValid() || dateMomentValidFormat2.isValid()) {
        return true;
    }

    return false;
}

export class UiValidators {

    static get ERROR_CODES(): any {
        return {
            required: {
                'message': 'Required',
                'code': 'required'
            },
            pattern: {
                'message': 'Invalid format',
                'code': 'pattern'
            },
            rangeMin: {
                'message': 'Value is lower than the minimum allowed',
                'code': 'rangeMin'
            },
            rangeMax: {
                'message': 'Value exceeds the maximum allowed',
                'code': 'rangeMax'
            },
            maxlength: {
                'message': 'Invalid length',
                'code': 'maxlength'
            },
            atLeastOneRequired: {
                'message': 'Either of these fields should not be empty',
                'code': 'atLeastOneRequired'
            },
            min: {
                'message': 'Minimum date format',
                'code': 'min'
            },
            max: {
                'message': 'Maximum date format',
                'code': 'max'
            }, 
            p400noSpace: {
                'message': 'Please ensure no space before and after Hyphen (-)',
                'code': 'p400noSpace'
            },
            p400Format: {
                'message': ' Please enter valid character before Comma (,) or a Full stop (.)  followed by a space',
                'code': 'p400Format'
            },
            p400moreThan1: {
                'message': 'error_messages.required.paymentContact.p400moreThan1',
                'code': 'p400moreThan1'
            },
            p400consecutive: {
                'message': 'error_messages.required.paymentContact.p400consecutive',
                'code': 'p400consecutive'
            }

        };
    };


    static getValidatorErrors(errors: any): any[] {
        let errorList: any[] = null;
        if (errors) {
            errorList = [];
            Object.keys(errors).forEach(function (key) {
                if (UiValidators.ERROR_CODES[key]) {
                    errorList.push(UiValidators.ERROR_CODES[key]);
                } else {
                    errorList.push({
                        'message': '',
                        'code': key
                    });
                }
            });
        }
        return errorList;
    }


    static rangeMin = (_min: number) => {
        return (control: AbstractControl) => {
            var num = control.value;
            if (!isNaN(num) && num < _min) {
                return {'rangeMin': true};
            }
            return null;
        };
    }

    static rangeMax = (_max: number) => {
        return (control: AbstractControl) => {
            var num = control.value;
            if (!isNaN(num) && num > _max) {
                return {'rangeMax': true};
            }
            return null;
        };
    }

    static atLeastOneRequired = (fieldIdList: string[]) => {
        return (control: AbstractControl) => {
            let isAnyTouched = false;
            let isAllEmpty = true;
            for (const fieldId of fieldIdList) {
                let field = control.parent.get(fieldId);
                if (field.touched) {
                    isAnyTouched = true;
                }
                if (!isEmptyInputValue(field.value)) {
                    isAllEmpty = false;
                }
            }
            if (isAnyTouched && isAllEmpty) {
                return {'atLeastOneRequired': true};
            }
            return null;
        };
    }

    /**
     * Usage:
     * "validationRegexList": [{"1": "^[0-9]{18,}"},{"2": "^[0-9]{8,}"}],
     * "dictionary" : {"1" : {"en" : "Error1"},"2" : {"en" : "Erro2"}}
     * @param regexList
     * @returns {(control:AbstractControl)=>{}}
     */
    static validationRegexList = (regexList: any[]) => {
        return (control: AbstractControl) => {
            const result = {};
            let oneFound = false;
            for (const regex of regexList) {
                Object.keys(regex).forEach(key => {
                    if (!new RegExp(regex[key]).test(control.value) && !oneFound) {
                        result[key] = true;
                        oneFound = true;
                    }
                });
            }
            return result;
        };
    }

    /**
     * @param
     * @returns {(control:AbstractControl)=>{}}
     */
    static timepickerPatternValidation = () => {
        return (control: AbstractControl) => {
            const value = control.value;
            if (!value) {
                return {'timePattern': true}
            }
            return null;
        };
    }

    static moneyCurrencyValidation = (formControl: AbstractControl, currencyControl: AbstractControl, requiredFlag, moneyFieldAmountRequiredFlag, moneyFieldCurrencyRequiredFlag) => {
        return (currentControl: AbstractControl) => {
            const result = {};
            //console.log("formControl.value " + formControl.value );
            //console.log("currency control.value " + currencyControl.value );

            //console.log("requiredFlag " + requiredFlag);
           // console.log("moneyFieldAmountRequiredFlag " + moneyFieldAmountRequiredFlag);
            //console.log("moneyFieldCurrencyRequiredFlag " + moneyFieldCurrencyRequiredFlag);

            if(moneyFieldAmountRequiredFlag === undefined){
               // console.log("moneyFieldAmountRequiredFlag set to true");
                moneyFieldAmountRequiredFlag = true;
            }

            if(moneyFieldCurrencyRequiredFlag === undefined){
               // console.log("moneyFieldCurrencyRequiredFlag set to true");
                moneyFieldCurrencyRequiredFlag = true;
            }

        if(currencyControl.touched && formControl.touched && !formControl.disabled){
            if (!formControl.value && moneyFieldAmountRequiredFlag) {
                result['moneyFieldAmountRequired'] = true;
                //console.log(" returning  moneyFieldRequired true 1");
                return result;
            }
            else if (!currencyControl.value && moneyFieldCurrencyRequiredFlag) {
                result['moneyFieldCurrencyRequired'] = true;
                //console.log(" returning  moneyFieldRequired true 2" );
                return result;
            }
            else if (!currencyControl.value && !formControl.value && requiredFlag) {
                result['required'] = true;
                //console.log(" returning  moneyFieldRequired true 2" );
                return result;
            }
        }

        return null;
        
        };
    }

    static isDecimal(value) {
        let regexp = new RegExp('^[0-9]*(?:[\\.]\\d{1,2}){0,1}$');
        console.log("Regex " + value + " " + regexp.test(value));
        return regexp.test(value);
    }

    /**
     * @param
     * @returns {(control:AbstractControl)=>{}}
     */
    static dateValidation = (dateFormat: string, minDate: string, maxDate: string) => {
        return (control: AbstractControl) => {

            if (null !== control.value && undefined !== control.value && '' !== control.value) {

                if (isValidDate(control.value)) {

                    let inputDate: Date;
                    let inputMaxDate: Date;
                    let inputMinDate: Date;
                    if (control.value instanceof Date) {
                        inputDate = setDateAsNoonTime(control.value);
                    } else {
                        inputDate = setDateAsNoonTime(DateService.convertStringToDate(control.value, dateFormat));
                    }

                    if (maxDate) {
                        inputMaxDate = setDateAsNoonTime(DateService.convertStringToDate(maxDate, dateFormat));
                    }
                    if (minDate) {
                        inputMinDate = setDateAsNoonTime(DateService.convertStringToDate(minDate, dateFormat));
                    }

                    const checkDate: any = DateService.convertStringToDate(control.value, dateFormat);
                    if (control.value && !isNaN(Date.parse(checkDate))) {

                        if (inputMinDate !== null && inputDate < inputMinDate) {
                            return {'min': true};
                        }
                        if (inputMaxDate != null && inputDate > inputMaxDate) {
                            //console.log("dateValidation: show max date message");
                            return {'max': true};
                        }

                    } else {
                        //console.log("dateValidation: show pattern message");
                        return {'pattern': true};
                    }
                } else {
                    return {'pattern': true};
                }

            }
            return null;
        };
    }

    /**
     * @param
     * @returns {(control:AbstractControl)=>{}}
     */
    static datetimeValidation = (dateFormat: string, minDate: string, maxDate: string) => {
        return (control: AbstractControl) => {

            if (null !== control.value && undefined !== control.value && '' !== control.value) {

                 let inputDate: Date;
                 let inputMaxDate: Date;
                 let inputMinDate: Date;
                 if (control.value instanceof Date) {
                     inputDate = control.value;
                 } else {
                     inputDate = DateService.convertStringToDatetime(control.value, dateFormat);
                 }

                if (maxDate) {
                    if((/\s/.test(maxDate) && /\:/.test(maxDate)) || maxDate.toLocaleLowerCase() === 'today'){
                        inputMaxDate = DateService.convertStringToDatetime(maxDate, dateFormat);
                    }else{
                        inputMaxDate = setDateAsNoonTime(DateService.convertStringToDate(maxDate, dateFormat));
                        console.log(inputMaxDate);
                    }
                }
                if (minDate) {
                    if(/\s/.test(minDate) && /\:/.test(minDate)){
                        inputMinDate = DateService.convertStringToDatetime(minDate, dateFormat);
                    }else{
                        inputMinDate = setDateAsNoonTime(DateService.convertStringToDate(minDate, dateFormat));
                    }
                }
                const checkDate: any = DateService.convertStringToDatetime(control.value, dateFormat);
                 if (control.value && !isNaN(Date.parse(checkDate))) {

                     if (inputMinDate !== null && inputDate < inputMinDate) {
                          return {'min': true};
                      }
                     if (inputMaxDate != null && inputDate > inputMaxDate) {
                         //console.log("dateValidation: show max date message");
                         return {'max': true};
                     }

                 } else {
                     //console.log("dateValidation: show pattern message");
                     return {'pattern': true};
                 }
            };

            return null;
        };
    }

    static p400Format = () => {
        return (control: AbstractControl) => {
            var str = control.value;
            if(!UtilitiesService.isNullOrUndefined(str)){
                control.setValue(UtilitiesService.p400Format(str));
            }
            return null;
        };
    }

    static p400noSpace = () => {
        return (control: AbstractControl) => {
            var str = control.value;
            if(str.indexOf("- ") > -1 || str.indexOf(" -") > -1 ){
                return {'p400noSpace': true};
            }
            return null;
        };
    }

    
    static p400moreThan1 = () => {
        return (control: AbstractControl) => {
            var str = control.value;
            if(!UtilitiesService.isNullOrUndefined(str) && str.length == 1){
                return {'p400moreThan1': true};
             }
            return null;
        };
    }

    static p400consecutive = () => {
        return (control: AbstractControl) => {
            var str = control.value;
            if(UiValidators.checkForConsecutiveCharacters(str)){
                return {'p400consecutive': true};
             }
            return null;
        };
    }

    static checkForConsecutiveCharacters(str: string) : boolean {
        var ctr = 0;
        var x = 0;
        var previousChar = '';
        
        if(!UtilitiesService.isNullOrUndefined(str)){
            do {
                if(UiValidators.isLetter(str.charAt(x))){
                    if(str.charAt(x).toLowerCase()===previousChar.toLowerCase()){
                        ctr++;
                    } else {
                        ctr = 0;
                    }
                } else {
                    ctr = 0;
                }
                   
                    previousChar = str.charAt(x);
                    x++;
                
            } while(x < str.length && ctr < 3);
        
            if(ctr==3){
                return true;
            } 
            return false;
        }
    }

    static isLetter(c: string): boolean {
        return c.toLowerCase() != c.toUpperCase();
    }

    static atleastOneRegexValid = (regexList: any[]) => {
        return (control: AbstractControl) => {
            if (!UtilitiesService.isNullOrUndefined(control.value) && '' !== control.value) {
                let oneFound = false;
                let controlValue: string = control.value;
                let inputValue = controlValue.toLowerCase();

                for (const regex of regexList) {
                    Object.keys(regex).forEach(key => {
                        let patternValid = regex[key];
                        if (inputValue.match(patternValid) != null && !oneFound) {
                            oneFound = true;
                            return null;
                        }
                    });
                }

                if(!oneFound) {
                    return {'pattern': true};
                }
            }
            return null;
        };
    }




}
