import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class DamageFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_DAMAGE];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        requiredDocuments.push(new DocumentField('PHOTOOFDAMAGE', 'motorClaim.claimSection.windscreen.documents.photoOfDamage', SmartqDocuments.MTR_PHOTO, true));
        requiredDocuments.push(new DocumentField('PHOTOOFREPAIR', 'motorClaim.claimSection.windscreen.documents.photoOfRepair', SmartqDocuments.MTR_PHOTO, true));
        if(this.claim.getClaimMotor().getIsLodgeByPolicyHolder() == true){
            requiredDocuments.push(new DocumentField('RECEIPT', 'motorClaim.claimSection.windscreen.documents.receipt', SmartqDocuments.MTR_RECEIPT, true));
        } else {
            requiredDocuments.push(new DocumentField('BILL', 'motorClaim.claimSection.windscreen.documents.signedReceipt', SmartqDocuments.MTR_RECEIPT, true));
        }
        requiredDocuments.push(new DocumentField('LICENSE', 'motorClaim.claimSection.windscreen.documents.license', SmartqDocuments.MTR_ID, true));
        
        this.requiredDocuments = requiredDocuments;
    }
}
