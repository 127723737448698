import {Component, Input, OnInit} from "@angular/core";
import {Notifications} from "./notifications.model";
import {Subject} from "rxjs";

@Component({
  selector: 'qnect-notification-messages',
  templateUrl: './notification-messages.component.html',
  styleUrls: ['./notification-messages.component.css']
})
export class NotificationMessagesComponent implements OnInit {
  @Input()
  notifications: Notifications;
  show : boolean = false;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.show = true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
