<div class="upload-doc-body">
    <div class="prev-bar">
        <a href="javascript:;" (click)="goBack()" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
        <h5>{{ 'claimSection.generalLabels.uploadDocuments.uploadYourDocuments' | translate}}</h5>
        {{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded1' | translate}} {{ 'claimSection.generalLabels.uploadDocuments.documentsNeeded2' | translate}}<br/>
        {{ 'pageSummaryClaim.claimItem.pleaseRetain' | translate}}
    </div>
    <BR>
    <div>
        <div class="col-lg-9 col-xl-8 col-md-12">
            <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages><form [formGroup]="upLoadFileModel">
            <div class="form-group" *ngFor="let documentField of this.formBuilder.getRequiredDocuments(); let i = index;">
                <label [attr.for]="'fileToUpload' + i">{{documentField.description | translate}}</label><br/>
                <span style = "font-size:14px;" *ngIf="documentField.id == 'MEDRCPT'">
                    {{'ecClaim.supportingDocuments.medicalReceiptDesc' | translate}}
                </span>
                <div class="docRow">
                    <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" type="file" (change)="fileUpload($event, documentField.id)" [attr.name]="'fileToUpload' + i" [attr.id]="'fileToUpload' + i">
                    <label [attr.name]="documentField.id" [attr.for]="'fileToUpload' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocType(documentField)" (fileOver)="setDocType(documentField)" (drop)="dropFile($event)" (onFileDrop)="fileDrop($event, documentField)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop"><i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}</label>
                    <label [attr.name]="documentField.id" [attr.for]="'fileToUpload' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocType(documentField)" (fileOver)="setDocType(documentField)" (drop)="dropFile($event)" (onFileDrop)="fileDrop($event, documentField)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}</label>
                    <button [attr.name]="documentField.id + 'rmv'" class="show-in-desktop" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id, false)"></button>
                    <button [attr.name]="documentField.id + 'rmv'" class="show-in-mobile" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id, true)"></button>
                </div>
                <div class="row">
                </div>
                <div class="form-group">
                    <div *ngFor="let item of additionalDocs.get(documentField.id); let i = index;" >
                        <div ng-model="item">
                            <div class="docRow">
                                <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" (change)="fileUpload($event, documentField.id + i)" type="file" name="fileToUpload" [attr.id]="documentField.id + i">
                                <ng-container *ngIf="item.docFileName==''">
                                    <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop"><em class="fas fa-upload"></em>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}</label>
                                    <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><em class="fas fa-camera"></em> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}</label>
                                </ng-container>
                                <ng-container *ngIf="item.docFileName!=''">
                                    <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop file-uploaded"><em class="fas fa-check"></em>{{item.docFileName}}</label>
                                    <label [attr.name]="documentField.id + i" [attr.for]="documentField.id + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeIndexed(documentField, i)" (fileOver)="setDocTypeIndexed(documentField, i)" (drop)="dropFile($event)" (onFileDrop)="fileDropIndexed($event, documentField, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><em class="fas fa-check"></em>{{item.docFileName}})</label>
                                </ng-container>
                                <button [attr.name]="documentField.id + i + 'rmv'" class="show-in-desktop" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id + i, false)"></button>
                                <button [attr.name]="documentField.id + i + 'rmv'" class="show-in-mobile" [ngStyle]="{'display':'none'}" (click)="removeDoc(documentField.id + i, true)"></button>
                            </div>
                        </div>
                    </div>
                    <div class="addFile" (click)="addAdditionalFiles(documentField.id)">
                        <em class="fas fa-plus"></em>
                        <span>{{ 'claimSection.generalLabels.uploadDocuments.addFile' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    {{ 'claimSection.generalLabels.uploadDocuments.moreDocuments' | translate}}
                    <a href="javascript:;" class="click-more" (click)="showAddDoc = !showAddDoc">{{ 'claimSection.generalLabels.uploadDocuments.uploadHere' | translate}}</a><BR><BR>
                </div>
            </div>
            <div [hidden]="!showAddDoc">
                <div class="form-group">
                    <label>{{ 'claimSection.generalLabels.uploadDocuments.additionalDocument' | translate}}</label>
                    <div *ngFor="let item of otherUploadedDocs; let i = index;" >
                        <div ng-model="item">
                            <div class="docRow">
                                <input ng2FileSelect accept=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/jpg, image/jpeg, image/png, image/gif, .avi, .mgg, .mp4, .mov, .wmv" [uploader]="uploader" (change)="fileUpload($event, 'OTHERS' + i)" type="file" name="fileToUpload" [attr.id]="'OTHERS' + i">
                                <ng-container *ngIf="item.docFileName==''">
                                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop"><i class="fas fa-upload"></i>{{ 'claimSection.generalLabels.uploadDocuments.dragAndDropHere' | translate}}</label>
                                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-camera"></i> {{ 'claimSection.generalLabels.uploadDocuments.takeAPhoto' | translate}}</label>
                                </ng-container>
                                <ng-container *ngIf="item.docFileName!=''">
                                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-lg-6 col-md-6 col-9 show-in-desktop file-uploaded"><i class="fas fa-check"></i>{{item.docFileName}}</label>
                                    <label [attr.name]="'OTHERS' + i" [attr.for]="'OTHERS' + i" ng2FileDrop  [uploader]="uploader" (mouseenter)="setDocTypeOthers(i)" (fileOver)="setDocTypeOthers(i)" (drop)="dropFile($event)" (onFileDrop)="fileDropOthers($event, i)" (change)="uploadDrop($event)" class="form-control col-9 show-in-mobile"><i class="fas fa-check"></i>{{item.docFileName}})</label>
                                </ng-container>
                                <button [attr.name]="'OTHERS' + i + 'rmv'" class="show-in-desktop" [ngStyle]="{'display':'none'}" (click)="removeDoc('OTHERS' + i, false)"></button>
                                <button [attr.name]="'OTHERS' + i + 'rmv'" class="show-in-mobile" [ngStyle]="{'display':'none'}" (click)="removeDoc('OTHERS' + i, true)"></button>
                            </div>
                        </div>
                    </div>
                    <div class="addFile" (click)="addAdditionalFile()">
                        <i class="fas fa-plus"></i>
                        <span>{{ 'claimSection.generalLabels.uploadDocuments.addFile' | translate}}</span>
                    </div>
                </div>
            </div>
        </form>
        </div>
        <div class="col-lg-4 col-md-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" routerLink="/claimform/anotherInsurance" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right"></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #replace_duplicate let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{'pageSummaryClaim.claimItem.alreadyUploaded' | translate }}</h5>
        <button type="button" class="close" aria-label="Close"  (click)="abortReplace()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'pageSummaryClaim.claimItem.alreadyUploaded' | translate }} {{this.getDuplicateFile().file.name}}</p>
        <p>{{'pageSummaryClaim.claimItem.replace' | translate }}</p>

    </div>
    <div class="modal-footer">
        <div class="col-md-6 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="proceedReplace()">{{'pageSummaryClaim.claimItem.yes' | translate }}</button>
        </div>
        <div class="col-md-6 text-left">
            <button type="button" class="btn btn-default btn-sm" (click)="abortReplace()">{{'pageSummaryClaim.claimItem.no' | translate }}</button>
        </div>
    </div>
</ng-template>