import {Component, Injector, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SideMenuService} from "../../services/side-menu.service";
import {ClaimService} from "../../services/claim.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TransactionInfo} from "../../model/transaction-info.model";
import {TransactionInfoService} from "../../services/transaction-info.service";
import {ClaimsBaseComponent} from "../claim-base.component";
import {ClaimTypes} from "../../model/claim-type.model";

@Component({
    selector: 'app-more-claim-type',
    templateUrl: './more-claim-type.component.html',
    styleUrls: ['./more-claim-type.component.css']
})
export class MoreClaimTypeComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    hasMoreClaim: boolean;
    policyNo: string;
    moreClaimTypeForm: FormGroup;
    transactionInfo: TransactionInfo;

    constructor(private router: Router,
                private sideMenuService: SideMenuService,
                public claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private fb: FormBuilder,
                private injector : Injector,
                private activatedRoute: ActivatedRoute,) {
        super(injector);
    }

    ngOnInit() {
        this.policyNo = this.claimService.getClaim().getPolicyNumber();
        this.pushGAMoreClaimTypeView();

        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();

        this.moreClaimTypeForm = this.fb.group({
            hasMoreClaimType: [this.transactionInfo.getHasMoreClaimType()]
        });
    }

    ngAfterViewInit() {

        this.moreClaimTypeForm.valueChanges.subscribe(data => {

            this.transactionInfo.setHasMoreClaimType(this.moreClaimTypeForm.get('hasMoreClaimType').value);
        });
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    changeMore(bool) {
        this.hasMoreClaim = bool;
    }

    goToNext() {

        if (this.validateForm()) {
         let wantMoreClaimType = this.moreClaimTypeForm.get('hasMoreClaimType').value;
           if(wantMoreClaimType.toLowerCase() == "y"){
             this.router.navigate(['/claimform/claimDetail']);
           } else {
             this.sideMenuService.emitComplete('finishChooseClaim');
             this.router.navigate(['/claimform/anotherInsurance']);
          }
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.moreClaimTypeForm);
    }

    goBack() {
        //history.go(-1);
        let currentSelectedClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
        if (currentSelectedClaimType) {
            this.redirectToCurrentClaimTypeDocument(currentSelectedClaimType);
        }
    }

    redirectToCurrentClaimTypeDocument(currentClaimType) {
        console.log("redirectToCurrentClaimTypeDocument method");

        switch(currentClaimType) {
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]:
                console.log("redirectToCurrentClaimTypeDocument: travel delay doc url");
                this.redirectToDocumentRouter("/claimform/travelDelayed/travelSupportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]:
                this.redirectToDocumentRouter("/claimform/lostPersonalItem/lossSupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]:
                this.redirectToDocumentRouter("/claimform/damagePersonalItem/damageSupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]:
                this.redirectToDocumentRouter("/claimform/baggageDelay/baggageDelaySupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT]:
                this.redirectToDocumentRouter("/claimform/visitedADoctor/doctorSupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]:
                this.redirectToDocumentRouter("/claimform/stayInHospital/hospitalSupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]:
                this.redirectToDocumentRouter("/claimform/tripCancellation/cancelSupportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]:
                this.redirectToDocumentRouter("/claimform/tripCurtailment/curtailmentSupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]:
                this.redirectToDocumentRouter("/claimform/rentalVehicleExcess/vehicleSupportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT]:
                this.redirectToDocumentRouter("/claimform/reRouting/reroutingSupportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT]:
                this.redirectToDocumentRouter("/claimform/missedEvent/missEventSupportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]:
                this.redirectToDocumentRouter("/claimform/missedConnect/missConnectSupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]:
                this.redirectToDocumentRouter("/claimform/personalAccident/accidentSupportDoc/uploadDocument");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]:
                this.redirectToDocumentRouter("/claimform/personalLiability/liabilitySupportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]:
                this.redirectToDocumentRouter("/claimform/otherClaim/supportDoc");
                break;
            default:
        }

    }

    redirectToDocumentRouter(urlDoc: string) {
        this.router.navigate([urlDoc], {
            relativeTo: this.activatedRoute
        });
    }

    pushGAMoreClaimTypeView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Anymore to Claim?',
            'vPath': '/anymore-to-claim',
            'event': 'vpageview',
        });
    }

}
