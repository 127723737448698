import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-personal-liability',
  templateUrl: './personal-liability.component.html',
  styleUrls: ['./personal-liability.component.css']
})
export class PersonalLiabilityComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]);
    }

  ngOnInit() {
  }

}
