import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorsService } from './errors.service';
import { throwError } from 'rxjs';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    const errorsService = this.injector.get(ErrorsService);
    const router = this.injector.get(Router);
    
    if (error instanceof HttpErrorResponse) {
        // Server error happened      
          if (!navigator.onLine) {
            // No Internet connection
            return throwError(() => new Error('No Internet Connection'));
            //return notificationService.addErrorMessage('No Internet Connection');
          }
          // Http Error
          // Send the error to the server
          errorsService.log(error).subscribe();
          // Show notification to the user
          return throwError(() => new Error(`${error.status} - ${error.message}`));
          //return notificationService.addErrorMessage(`${error.status} - ${error.message}`);
    } else {
      //log error and redirect to common error page
      console.log('Error handler invoked...');
      errorsService.log(error).subscribe();
    }
  }
}
