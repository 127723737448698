import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../../services/side-menu.service";
import {ClaimService} from "../../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../../claim-base.component";
import {Claim} from "../../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {Windscreen} from '../../../../../model/motor/windscreen.model';
import {MotorHelperService} from '../../../../../services/motor-helper.service';
import {UiOptionsModel} from "../../../../../ui/ui-options.model";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
  selector: 'app-windscreen-detail',
  templateUrl: './windscreen-detail.component.html',
  styleUrls: ['./windscreen-detail.component.css']
})

export class WindscreenDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    detailForm: FormGroup;
    claim: Claim;
    windscreen: Windscreen;
    motorPartList = [];
    isLodgedByCustomer: boolean = true;
    isCnLanguage: boolean = false;
    uiOptions: UiOptionsModel[] = [];

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector,
                private motorHelperService: MotorHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.windscreen = this.claim.getClaimMotor().getWindscreen();
        this.isLodgedByCustomer = this.claim.getClaimMotor().getIsLodgeByPolicyHolder();
        this.isCnLanguage = this.translate.currentLang === 'zh';
        this.getWsRepairer();
    }

    ngOnInit() {

        this.pushGAWsDetail();

        if (!this.isNotNullOrUndefinedStr(this.windscreen.getRepairCurrency())){
            this.windscreen.setRepairCurrency('HKD');
        }

        this.detailForm = this.fb.group({
            repairerName: [this.windscreen.getRepairerName()],
            otherRepairerName: [this.windscreen.getRepairerOtherName()],
            repairCost: [this.windscreen.getRepairCost()],
            repairCurrency: [this.windscreen.getRepairCurrency()]
        });

      // get motor part list
      this.motorPartList = this.motorHelperService.getMotorParts();
      if (this.windscreen.getDetailsOfDamagedItem().length != 0){
        this.windscreen.setHasSelectedMotorPart(true);
        this.buildExistingMotorPartList();
      }

    }

    ngAfterViewInit() {

        this.detailForm.valueChanges.subscribe(data => {

            this.windscreen.setRepairerOtherName(this.detailForm.get('otherRepairerName').value);
            this.windscreen.setRepairCost(this.detailForm.get('repairCost').value);
            this.windscreen.setRepairCurrency(this.detailForm.get('repairCurrency').value);

            if (this.isLodgedByCustomer) {
                this.windscreen.setRepairerName(this.detailForm.get('repairerName').value);
                this.windscreen.setRepairerNameDesc(super.getTextInComponent('repairerName'));
            }

        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(function () {self.hideShowOthersField(self.windscreen.getRepairerName()),10});
        this.detailForm.get('repairerName').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowOthersField(data);
        });

    }

    hideShowOthersField(repairerName: string) {

        if (!UtilitiesService.isNullOrUndefined(repairerName) && repairerName.toLowerCase() == 'others') {
            this.detailForm.get('otherRepairerName').enable({onlySelf: false, emitEvent: false});
        } else {
            this.detailForm.get('otherRepairerName').reset();
            this.detailForm.get('otherRepairerName').disable({onlySelf: false, emitEvent: false});
        }
    }
    back() {
        this.router.navigate(["/claimform/windscreen/placeOfIncident"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        let motorPartSelectedItems = this.windscreen.getDetailsOfDamagedItem();

        if(motorPartSelectedItems.length == 0){
            this.windscreen.setHasSelectedMotorPart(false);
        }

        if (this.validateForm() && motorPartSelectedItems.length >= 1) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 1});
            this.router.navigate(["/claimform/windscreen/supportDoc"], {
                relativeTo: this.activatedRoute
            });

        }
    }


    getWsRepairer() {
        if (this.isLodgedByCustomer) {
            this.uiOptions = this.motorHelperService.getWsRepairerOptions();
            const option = new UiOptionsModel("Others", "motorClaim.repairer.others");
            this.uiOptions.push(option);
        } else {
            this.windscreen.setRepairerName(this.claim.getClaimMotor().getRepairerId());
        }
    }

    buildExistingMotorPartList(){
        let existingMotorPartList = this.windscreen.getDetailsOfDamagedItem();
        for(var x = 0; x < existingMotorPartList.length; x++){
            for(var y = 0; y < this.motorPartList.length; y++){
                if(existingMotorPartList[x] == this.motorPartList[y]['motorPartValue']){
                    this.motorPartList[y]['selected'] = true;
                }
            }
        }
    }

    selectMotorParts(selectedValue: string) {

        let motorWsSelectedItems: string[]  = this.windscreen.getDetailsOfDamagedItem().length > 0 ? this.windscreen.getDetailsOfDamagedItem() : [];
        let motorWsSelectedItemsDesc: string[] =  this.windscreen.getDetailsOfDamagedItemDesc().length > 0 ? this.windscreen.getDetailsOfDamagedItemDesc() : [];
        let self = this;
        self.motorPartList.forEach(function (motorlistPart, index){

            if (motorlistPart.motorPartValue == selectedValue) {
                if (motorlistPart.selected) {
                    motorlistPart.selected = false;
                    self.removeWsPartsSelectedList(selectedValue,motorWsSelectedItems, motorWsSelectedItemsDesc);
                } else {
                    motorlistPart.selected = true;
                    motorWsSelectedItems.push(motorlistPart.motorPartValue);
                    let motorPartNameText = self.translate.instant(motorlistPart.motorPartName);
                    motorWsSelectedItemsDesc.push(motorPartNameText);
                    self.windscreen.setHasSelectedMotorPart(true);
                }
                self.windscreen.setDetailsOfDamagedItem(motorWsSelectedItems);
                self.windscreen.setDetailsOfDamagedItemDesc(motorWsSelectedItemsDesc);
            }

        });
    }

    removeWsPartsSelectedList(selectedValue: string, motorWsSelectedItems: string[], motorWsSelectedItemsDesc: string[]) {
        let indexSelected: number = -1 ; // determine index for description to remove or not
        for(var i= motorWsSelectedItems.length - 1; i => 0; i--){
            if (motorWsSelectedItems[i] == selectedValue) {
                indexSelected = i;
                break;
            }
        }

        if (indexSelected => 0) {
            motorWsSelectedItems.splice(indexSelected, 1);
            motorWsSelectedItemsDesc.splice(indexSelected, 1);
        }

        this.windscreen.setDetailsOfDamagedItem(motorWsSelectedItems);
        this.windscreen.setDetailsOfDamagedItemDesc(motorWsSelectedItemsDesc);
    }


    validateForm(): boolean {
        return super.validateForm(this.detailForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAWsDetail() {
        (<any>window).dataLayer.push({
            'pageStep': 'Damage of Windscreen – Repair Details',
            'vPath': '/claim/damage-of-windscreen/repair-details',
            'event': 'vpageview',
        });
    }

}
