<ng-container *ngIf="transactionInfo.getCountry()?.toLowerCase() !== 'mys'">
    <div class="box-wrapper">
        <div style="display: flex; justify-content: space-between ;">
            <img class="img-responsive" class="box-icon" src="assets/images/claim-icon/specialty-programs.svg" alt="">
            <p class="doc-box-text">{{ 'home.body.documents' | translate }}</p>
        </div>
        <ng-container *ngIf="transactionInfo.isTravelProduct()">
            <h3 *ngIf="transactionInfo.getCountry()?.toLowerCase() !== 'hkg'" style="font-family:'Verdana', sans-serif; font-size: 18px">{{ 'home.body.select' | translate }}</h3>
            <h3 *ngIf="transactionInfo.getCountry()?.toLowerCase() === 'hkg'" style="font-family:'Verdana', sans-serif; font-size: 18px">{{ 'home.body.chinaAsiaWorldwide' | translate }}</h3>
            <ul class="linkGroup">
                <li (click)="open(travel_delay,'terms_and_conditions')">{{'sideMenu.travelDelayed.name' | translate}}</li>
                <li (click)="open(lost_personal_item,'terms_and_conditions', false)">
                    {{'sideMenu.lostPersonalItem.name' | translate}}
                </li>
                <li (click)="open(damage_personal_item,'terms_and_conditions', false)">
                    {{'sideMenu.damagePersonalItem.name' | translate}}
                </li>
                <li (click)="open(baggage_delay,'terms_and_conditions')">{{'sideMenu.baggageDelay.name' | translate}}</li>
                <li (click)="open(visited_a_doctor,'terms_and_conditions', false)">{{'sideMenu.visitedADoctor.name' | translate}}
                </li>
                <li (click)="open(stayed_at_hospital,'terms_and_conditions', false)">
                    {{'sideMenu.stayedInHospital.name' | translate}}
                </li>
                <li (click)="open(trip_cancellation,'terms_and_conditions')">
                    {{'sideMenu.tripCancellation.name' | translate}}
                </li>
                <li (click)="open(trip_curtailment,'terms_and_conditions')">
                    {{'sideMenu.tripCurtailment.name' | translate}}
                </li>
                <li (click)="open(rental_vehicle_excess,'terms_and_conditions')">
                    {{'sideMenu.rentalVehicleExcess.name' | translate}}
                </li>
                <li (click)="open(travel_rerouting,'terms_and_conditions')">
                    {{'sideMenu.travelRerouting.name' | translate}}
                </li>
                <li (click)="open(miss_event,'terms_and_conditions')">{{'sideMenu.missedEvent.name' | translate}}</li>
                <li (click)="open(miss_connection,'terms_and_conditions')">
                    {{'sideMenu.missedConnection.name' | translate}}
                </li>
                <li (click)="open(personal_accident,'terms_and_conditions', false)">
                    {{'sideMenu.deathAndSeriousBodyInjury.name' | translate}}
                </li>
                <li (click)="open(personal_liability,'terms_and_conditions', false)">
                    {{'sideMenu.personalLiability.name' | translate}}
                </li>
                <li (click)="open(others_claims,'terms_and_conditions', false)">{{'sideMenu.otherClaim.name' | translate}}</li>
            </ul>
            <ng-container *ngIf="transactionInfo.getCountry()?.toLowerCase() === 'hkg'">
                <h3 style="font-family:'Verdana', sans-serif; font-size: 18px">{{ 'home.body.gba' | translate }}</h3>
                <ul class="linkGroup">
                    <li (click)="open(lost_personal_item,'terms_and_conditions', true)">
                        {{'sideMenu.lostPersonalItem.name' | translate}}
                    </li>
                    <li (click)="open(damage_personal_item,'terms_and_conditions', true)">
                        {{'sideMenu.damagePersonalItem.name' | translate}}
                    </li>
                    <li (click)="open(visited_a_doctor,'terms_and_conditions', true)">{{'sideMenu.visitedADoctor.name' | translate}}</li>
                    <li (click)="open(stayed_at_hospital,'terms_and_conditions', true)">
                        {{'sideMenu.stayedInHospital.name' | translate}}
                    </li>
                    <li (click)="open(personal_accident,'terms_and_conditions', true)">
                        {{'sideMenu.deathAndSeriousBodyInjury.name' | translate}}
                    </li>
                    <li (click)="open(personal_liability,'terms_and_conditions', true)">
                        {{'sideMenu.personalLiability.name' | translate}}
                    </li>
                    <li (click)="open(others_claims,'terms_and_conditions', true)">{{'sideMenu.otherClaim.name' | translate}}</li>
                </ul>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="transactionInfo.isDomesticHelperProduct()">
            <h3 style="font-family:'Verdana', sans-serif; font-size: 18px">{{ 'home.body.select' | translate }}</h3>
            <ul class="linkGroup">
                <li (click)="open(dmh_clinical,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.title' | translate}}
                </li>
                <li (click)="open(dmh_dental,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.title' | translate}}
                </li>
                <li (click)="open(dmh_hospitalization,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.title' | translate}}
                </li>
                <li (click)="open(dmh_death,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.deathOrDisability.title' | translate}}
                </li>
                <li (click)="open(dmh_repatriation,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.title' | translate}}
                </li>
                <li (click)="open(dmh_helpers,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.title' | translate}}
                </li>
                <li (click)="open(dmh_dishonesty,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.title' | translate}}
                </li>
                <li (click)="open(dmh_employee,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.title' | translate}}
                </li>
                <li (click)="open(dmh_others,'terms_and_conditions')">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.title' | translate}}
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf="transactionInfo.isMotorProduct()">
            <h3 style="font-family:'Verdana', sans-serif; font-size: 18px">{{ 'home.body.select' | translate }}</h3>
            <ul class="linkGroup">
                <li (click)="open(mva_windscreen,'terms_and_conditions')">
                    {{'motorClaim.selectClaim.windscreen' | translate}}
                </li>
                <li *ngIf="isPolicyHolder" (click)="open(mva_damage,'terms_and_conditions')">
                    {{'motorClaim.selectClaim.damage' | translate}}</li>
                <li *ngIf="isPolicyHolder" (click)="open(mva_theft,'terms_and_conditions')">
                    {{'motorClaim.selectClaim.theft' | translate}}</li>
            </ul>
        </ng-container>
        <ng-container *ngIf="transactionInfo.isHomeProduct()">
            <ul class="linkGroup">
                <li (click)="openModal(home_doclist,'homeProduct.selectClaim.water', 'water')">{{'homeProduct.selectClaim.water' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.weather.docsToPrepareTitle', 'weather')">{{'homeProduct.weather.docsToPrepareTitle' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.accident.docsToPrepareTitle', 'accident')">{{'homeProduct.accident.docsToPrepareTitle' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.fire.docsToPrepareTitle', 'fire')">{{'homeProduct.fire.docsToPrepareTitle' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.selectClaim.burglary', 'burglary')">{{'homeProduct.selectClaim.burglary' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.selectClaim.vandalism', 'vandalism')">{{'homeProduct.selectClaim.vandalism' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.selectClaim.others', 'others')">{{'homeProduct.selectClaim.others' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.liability.docsToPrepareTitle', 'liability')">{{'homeProduct.liability.docsToPrepareTitle' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.domesticEmployer.docsToPrepareTitle', 'employeeCompensation')">{{'homeProduct.domesticEmployer.docsToPrepareTitle' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.personalAccident.title', 'personalAccident')">{{'homeProduct.personalAccident.title' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.lostPersonalItem.docsToPrepareTitle', 'lostItem')">{{'homeProduct.lostPersonalItem.docsToPrepareTitle' | translate}}</li>
                <li (click)="openModal(home_doclist,'homeProduct.damagedPersonalItem.docsToPrepareTitle', 'damagedItem')">{{'homeProduct.damagedPersonalItem.docsToPrepareTitle' | translate}}</li>
            </ul>
        </ng-container>
        <ng-container *ngIf="transactionInfo.isAccidentProduct()">
            <ul class="linkGroup">
                <li (click)="openModal(pa_doclist,'paClaim.accidentalMedicalExpenses.title', 'accidentalmedicalexpenses')">{{'paClaim.accidentalMedicalExpenses.title' | translate}}</li>
                <li (click)="openModal(pa_doclist,'paClaim.hospitalCash.title', 'hospitalcash')">{{'paClaim.hospitalCash.title' | translate}}</li>
                <li (click)="openModal(pa_doclist,'paClaim.others.title', 'others')">{{'paClaim.others.title' | translate}}</li>
            </ul>
        </ng-container>
        <ng-container *ngIf="transactionInfo.isEmployeeCompensationProduct()">
            <h3 style="font-family:'Verdana', sans-serif; font-size: 16px">{{'ecClaim.selectClaim.selectForm' | translate}}</h3>
            <ul class="linkGroup">
                <li (click)="openModal(ec_doclist,'ecClaim.selectClaim.form2', 'form2')">{{'ecClaim.selectClaim.form2' | translate}}</li>
                <li (click)="openModal(ec_doclist,'ecClaim.selectClaim.form2a', 'form2a')">{{'ecClaim.selectClaim.form2a' | translate}}</li>
                <li (click)="openModal(ec_doclist,'ecClaim.selectClaim.form2b', 'form2b')">{{'ecClaim.selectClaim.form2b' | translate}}</li>
            </ul>
        </ng-container>
        <ng-container *ngIf="transactionInfo.isGenericClaim() && transactionInfo.getCountry() === 'HKG'" [ngTemplateOutlet]="documentsGenericHKG">
        </ng-container>
        <ng-container *ngIf="transactionInfo.isGenericClaim() && transactionInfo.getCountry() === 'SGP'" [ngTemplateOutlet]="documentsGenericSGP"></ng-container>
    </div>
</ng-container>
<ng-container *ngIf="transactionInfo.getCountry()?.toLowerCase() === 'mys'">
<div class="row-mobile">
    <div class="div-wrapper">
        <div class="row col-12 icon-text">
            <img class="img-responsive" class="list-icon" src="assets/images/claim-icon/specialty-programs.svg" alt="">
            <p class="list-box-text">{{ 'home.body.documents' | translate }}</p>
        </div>
        <ul class="linkGroup">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-xs-12">
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.bond', 'property')">{{'claimDetails.generic.docsToPrepare.bond' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.broadformLiability', 'genLiability')">{{'claimDetails.generic.docsToPrepare.broadformLiability' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.builders', 'hull')">{{'claimDetails.generic.docsToPrepare.builders' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.burglary', 'property')">{{'claimDetails.generic.docsToPrepare.burglary' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.commercialProperty', 'property')">{{'claimDetails.generic.docsToPrepare.commercialProperty' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.directorsOfficers', 'director')">{{'claimDetails.generic.docsToPrepare.directorsOfficers' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.elecEquipment', 'property')">{{'claimDetails.generic.docsToPrepare.elecEquipment' | translate}}</li>
                </div>
                <div class="col-lg-4 col-md-4 col-xs-12">
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.empCompensation', 'wicasg')">
                        {{'claimDetails.generic.docsToPrepare.empCompensation' | translate}}
                    </li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.errorsOmissions', 'errorsomission')">{{'claimDetails.generic.docsToPrepare.errorsOmissions' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.fidelityGuarantee', 'property')">{{'claimDetails.generic.docsToPrepare.fidelityGuarantee' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.freightForwards', 'marineliability')">{{'claimDetails.generic.docsToPrepare.freightForwards' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.home', 'property')">{{'claimDetails.generic.docsToPrepare.home' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.jewellersBlock', 'property')">{{'claimDetails.generic.docsToPrepare.jewellersBlock' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.marineCargo', 'marine')">{{'claimDetails.generic.docsToPrepare.marineCargo' | translate}}</li>
                </div>
                <div class="col-lg-4 col-md-4 col-xs-12">
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.medical', 'medical')">{{'claimDetails.generic.docsToPrepare.medical' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.motor', 'motor')">
                        {{'claimDetails.generic.docsToPrepare.motor' | translate}}
                    </li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.personalAccident', 'pa')">{{'claimDetails.generic.docsToPrepare.personalAccident' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.pleasureCraft', 'pleasurecraft')">{{'claimDetails.generic.docsToPrepare.pleasureCraft' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.professionalIndemnity', 'pi')">{{'claimDetails.generic.docsToPrepare.professionalIndemnity' | translate}}</li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.travel', 'travel')">
                        {{'claimDetails.generic.docsToPrepare.travel' | translate}}
                    </li>
                    <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.others', 'othersLiability')">{{'claimDetails.generic.docsToPrepare.others' | translate}}</li>
                </div>
            </div>
        </ul>
    </div>
</div>
</ng-container>
<ng-template #documentsGenericHKG>
    <ul class="linkGroup">
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.bond', 'property')">{{'claimDetails.generic.docsToPrepare.bond' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.builders', 'hull')">{{'claimDetails.generic.docsToPrepare.builders' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.burglary', 'property')">{{'claimDetails.generic.docsToPrepare.burglary' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.commercialProperty', 'property')">{{'claimDetails.generic.docsToPrepare.commercialProperty' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.directorsOfficers', 'director')">{{'claimDetails.generic.docsToPrepare.directorsOfficers' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.elecEquipment', 'property')">{{'claimDetails.generic.docsToPrepare.elecEquipment' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.empCompensation', 'wicahk')">{{'claimDetails.generic.docsToPrepare.empCompensation' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.errorsOmissions', 'errorsomission')">{{'claimDetails.generic.docsToPrepare.errorsOmissions' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.fidelityGuarantee', 'property')">{{'claimDetails.generic.docsToPrepare.fidelityGuarantee' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.home', 'property')">{{'claimDetails.generic.docsToPrepare.home' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.jewellersBlock', 'property')">{{'claimDetails.generic.docsToPrepare.jewellersBlock' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.marineCargo', 'marine')">{{'claimDetails.generic.docsToPrepare.marineCargo' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.marineLiability', 'marineliability')">{{'claimDetails.generic.docsToPrepare.marineLiability' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.medical', 'medicalHK')">{{'claimDetails.generic.docsToPrepare.medical' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.personalAccident', 'pa')">{{'claimDetails.generic.docsToPrepare.personalAccident' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.pleasureCraft', 'pleasurecraft')">{{'claimDetails.generic.docsToPrepare.pleasureCraft' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.professionalIndemnity', 'piHK')">{{'claimDetails.generic.docsToPrepare.professionalIndemnity' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.residentialFire', 'residentialFire')">{{'claimDetails.generic.docsToPrepare.residentialFire' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.others', 'others')">{{'claimDetails.generic.docsToPrepare.others' | translate}}</li>
    </ul>
</ng-template>
<ng-template #documentsGenericSGP>
    <ul class="linkGroup">
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.bond', 'property')">{{'claimDetails.generic.docsToPrepare.bond' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.broadformLiability', 'genLiability')">{{'claimDetails.generic.docsToPrepare.broadformLiability' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.builders', 'hull')">{{'claimDetails.generic.docsToPrepare.builders' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.burglary', 'property')">{{'claimDetails.generic.docsToPrepare.burglary' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.commercialProperty', 'property')">{{'claimDetails.generic.docsToPrepare.commercialProperty' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.directorsOfficers', 'director')">{{'claimDetails.generic.docsToPrepare.directorsOfficers' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.elecEquipment', 'property')">{{'claimDetails.generic.docsToPrepare.elecEquipment' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.empCompensation', 'wicasg')">
            {{'claimDetails.generic.docsToPrepare.empCompensation' | translate}}
        </li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.errorsOmissions', 'errorsomission')">{{'claimDetails.generic.docsToPrepare.errorsOmissions' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.fidelityGuarantee', 'property')">{{'claimDetails.generic.docsToPrepare.fidelityGuarantee' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.freightForwards', 'marineliability')">{{'claimDetails.generic.docsToPrepare.freightForwards' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.home', 'property')">{{'claimDetails.generic.docsToPrepare.home' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.jewellersBlock', 'property')">{{'claimDetails.generic.docsToPrepare.jewellersBlock' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.marineCargo', 'marine')">{{'claimDetails.generic.docsToPrepare.marineCargo' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.medical', 'medical')">{{'claimDetails.generic.docsToPrepare.medical' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.motor', 'motor')">
            {{'claimDetails.generic.docsToPrepare.motor' | translate}}
        </li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.personalAccident', 'paSG')">{{'claimDetails.generic.docsToPrepare.personalAccident' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.pleasureCraft', 'pleasurecraft')">{{'claimDetails.generic.docsToPrepare.pleasureCraft' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.professionalIndemnity', 'pi')">{{'claimDetails.generic.docsToPrepare.professionalIndemnity' | translate}}</li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.travel', 'travel')">
            {{'claimDetails.generic.docsToPrepare.travel' | translate}}
        </li>
        <li (click)="openModal(gen_doclist,'claimDetails.generic.docsToPrepare.others', 'othersLiability')">{{'claimDetails.generic.docsToPrepare.others' | translate}}</li>
    </ul>
</ng-template>
<!-- SPECIFIC DOC LIST NG CONTAINER -->
<ng-template #travel_delay let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.travelDelayed.name' | translate}}
        </h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.copyTicket' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.rptReason' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptPrepaidTrans' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptPrepaidAccom' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptAdditionalTrans' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptAdditionalAccom' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #lost_personal_item let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.lostPersonalItem.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li *ngIf="!isGBA">{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.copyTicket' | translate}}</li>
            <li *ngIf="isGBA">{{'claimSection.greaterBayArea.proofOfTravel' | translate}}</li>
            
            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.purchaseReceipt' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.refPurchaseReceipt' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.loseReport' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.compBreakdown' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.origDocument' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #damage_personal_item let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.damagePersonalItem.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li *ngIf="!isGBA">{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.copyTicket' | translate}}</li>
            <li *ngIf="isGBA">{{'claimSection.greaterBayArea.proofOfTravel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.purchaseReceipt' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.refPurchaseReceipt' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.repairmentReceiptQuotation' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.dmgRpt' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.photos' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.compBreakdown' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #baggage_delay let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.baggageDelay.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.copyTicket' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.carrierCert' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.receiptEmergency' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #visited_a_doctor let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.visitedADoctor.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li *ngIf="!isGBA">{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.copyTicket' | translate}}</li>
            <li *ngIf="isGBA">{{'claimSection.greaterBayArea.proofOfTravel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medBills' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medReport' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptTreat' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptExp' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #stayed_at_hospital let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.stayedInHospital.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li *ngIf="!isGBA">{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.copyTicket' | translate}}</li>
            <li *ngIf="isGBA">{{'claimSection.greaterBayArea.proofOfTravel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medBills' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medReport' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptTreat' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptExp' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #trip_cancellation let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.tripCancellation.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.copyTicket' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.suppCancel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.receipt' | translate}}</li>
            <li *ngIf="transactionInfo.getCountry() === 'HKG'">{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.receiptTrans' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li *ngIf="transactionInfo.getCountry() === 'HKG'">{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.receiptAccom' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.certificate' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #trip_curtailment let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.tripCurtailment.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.copyTicket' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.suppCancel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receipt' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receiptTrans' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receiptAccom' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.certificate' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #rental_vehicle_excess let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.rentalVehicleExcess.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.airTicket' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.rentAgreement' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.rentalReceipt' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.repairReceipt' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.damageReport' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #travel_rerouting let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.travelRerouting.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.copyTicket' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.carrierCert' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.docsProof' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #miss_event let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.missedEvent.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.ticketDoc' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.receiptDoc' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.relDoc' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionSuppDocs.nonrefundDoc' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #miss_connection let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.missedConnection.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.copyTicket' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.relDocs' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptDoc' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptTrans' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptAccom' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #personal_accident let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.deathAndSeriousBodyInjury.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li *ngIf="!isGBA">{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.copyTicket' | translate}}</li>
            <li *ngIf="isGBA">{{'claimSection.greaterBayArea.proofOfTravel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.policeReport' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.deathCert' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.medReport' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.medBills' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.receiptTreat' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #personal_liability let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.personalLiability.name' | translate}}</h3>
        <ol class="uploadFileList">
            <li *ngIf="!isGBA">{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionSuppDocs.copyTicket' | translate}}</li>
            <li *ngIf="isGBA">{{'claimSection.greaterBayArea.proofOfTravel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionSuppDocs.photoIncident' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionSuppDocs.thirdParties' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_clinical let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.subSupportingDocs.medicalBill' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_dental let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.subSupportingDocs.dentalBill' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_hospitalization let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.medicalBill' | translate}}</li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.hospitalForm' | translate}}</li>
            <li><span [innerHtml]="'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.privateHospitalFormHtml' | translate"></span></li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_death let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.deathOrDisability.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>
                {{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.diagnosisReceipt' | translate}}
            </li>
            <li>
                {{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.medicalReport' | translate}}
            </li>
            <li>
                {{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.copyOfDeathCert' | translate}}
            </li>
            <li>
                {{'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.copyOfPoliceReport' | translate}}
            </li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_repatriation let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.repatExpense' | translate}}</li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.copyOfEmpContract' | translate}}
            </li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.certificate' | translate}}</li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.agentFeeExpense' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.copyOfReplacement' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.relevantExpense' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.copyOfEmployment' | translate}}
                {{ 'home.body.ifApplicable' | translate }}
            </li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_helpers let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.photos' | translate}}</li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.thirdParty' | translate}}</li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.witnessStatement' | translate}}
            </li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.helpersLiability.subSupportingDocs.policeReport' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_dishonesty let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.subSupportingDocs.evidence' | translate}}</li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.subSupportingDocs.docOfValue' | translate}}
            </li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.subSupportingDocs.policeReport' | translate}}
            </li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_employee let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.form2' | translate}}</li>
            <li>
                {{'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.medicalExpense' | translate}}
            </li>
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.sickLeaveCert' | translate}}
            </li>
            <li>
                {{'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.copyOfEmpContract' | translate}}
            </li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #dmh_others let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.title' | translate}}</h3>
        <ol class="uploadFileList">
            <li>{{'domesticHelperClaim.dmhClaimTypeDetails.otherClaimDMH.subSupportingDocs.receipts' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #others_claims let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'sideMenu.otherClaim.name' | translate}}
        </h3>
        <ol class="uploadFileList">
            <li *ngIf="!isGBA">{{'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.airTicket' | translate}}</li>
            <li *ngIf="isGBA">{{'claimSection.greaterBayArea.proofOfTravel' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate}}</li>
            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #mva_windscreen let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">
            {{'motorClaim.selectClaim.windscreen' | translate}}
        </h3>
        <ol class="uploadFileList">
            <li>{{'motorClaim.claimSection.windscreen.documents.photoOfDamage' | translate}}</li>
            <li>{{'motorClaim.claimSection.windscreen.documents.photoOfRepair' | translate}}</li>
            <li>{{'motorClaim.claimSection.windscreen.documents.license' | translate}}</li>
            <li *ngIf="isPolicyHolder">{{'motorClaim.claimSection.windscreen.documents.receipt' | translate}}</li>
            <li *ngIf="!isPolicyHolder">{{'motorClaim.claimSection.windscreen.documents.signedReceipt' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #mva_damage let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'motorClaim.selectClaim.damage' | translate}}</h3>
        <ol class="uploadFileList">
            <li><span [innerHtml]="'motorClaim.claimSection.documents.letterOfAuthorizationSignedCollisionHtml' | translate"></span></li>
            <li>{{'motorClaim.claimSection.documents.hkid' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.driversLicense' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.vehicleRegistration' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.vehicleLicense' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.policeStatement' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.breathTestResult' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.photoOfAccident' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.carVideo' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.repairQuotation' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.thirdParty' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #mva_theft let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{'motorClaim.selectClaim.theft' | translate}}</h3>
        <ol class="uploadFileList">
            <li><span [innerHtml]="'motorClaim.claimSection.documents.letterOfAuthorizationSignedTheftHtml' | translate"></span></li>
            <li>{{'motorClaim.claimSection.documents.vehicleRegistration' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.vehicleLicense' | translate}}</li>
            <li>{{'motorClaim.claimSection.documents.policeStatement' | translate}}</li>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #gen_doclist let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{docTitle}}</h3>
        <ng-container *ngIf="docCategory === 'travel'">
            <div class="accordion-tabs">
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck1">
                    <label class="accordion-tab-label" for="chck1">{{'sideMenu.travelDelayed.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.rptReason' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.copyTravelIT' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptTrans' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.receiptAccom' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck2">
                    <label class="accordion-tab-label" for="chck2">{{'sideMenu.lostPersonalItem.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.receiptLostItemSGP' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.loseReport' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.compBreakdown' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li *ngIf="transactionInfo.getCountry() === 'SGP'">{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.receiptReplacement' | translate}}</li>
                            <li *ngIf="transactionInfo.getCountry() === 'MYS'">{{'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.receiptReplacePassportNRIC' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck3">
                    <label class="accordion-tab-label" for="chck3">{{'sideMenu.damagePersonalItem.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.origReceiptSgp' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.dmgRpt' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.photos' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subSupportingDocs.compBreakdown' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck4">
                    <label class="accordion-tab-label" for="chck4">{{'sideMenu.baggageDelay.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.carrierCert' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.restoreLetter' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.receiptEmergency' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck5">
                    <label class="accordion-tab-label" for="chck5">{{'sideMenu.visitedADoctor.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medBills' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medReport' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptTreat' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptExp' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck6">
                    <label class="accordion-tab-label" for="chck6">{{'sideMenu.stayedInHospital.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medBills' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medReport' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptTreat' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptExp' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck7">
                    <label class="accordion-tab-label" for="chck7">{{'sideMenu.tripCancellation.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.suppCancel' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.receipt' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCancel.subSupportingDocs.certificate' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck8">
                    <label class="accordion-tab-label" for="chck8">{{'sideMenu.tripCurtailment.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.suppCancel' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receipt' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receiptTrans' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.receiptAccom' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionTripCurt.subSupportingDocs.certificate' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck9">
                    <label class="accordion-tab-label" for="chck9"> {{'sideMenu.rentalVehicleExcess.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.airTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.rentAgreement' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.rentalReceipt' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.repairReceipt' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionRental.subSectionSuppDocs.damageReport' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck10">
                    <label class="accordion-tab-label" for="chck10">{{'sideMenu.travelRerouting.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.carrierCert' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionReroute.subSectionSuppDocs.docsProof' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck11">
                    <label class="accordion-tab-label" for="chck11">{{'sideMenu.missedConnection.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.relDocs' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptDoc' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptTrans' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionMissConnect.subSectionSD.receiptAccom' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck12">
                    <label class="accordion-tab-label" for="chck12">{{'sideMenu.deathAndSeriousBodyInjury.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.policeReport' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.deathCert' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.medReport' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.medBills' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionDeath.subSupportingDocs.receiptTreat' | translate}}
                                {{ 'home.body.ifApplicable' | translate }}
                            </li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck13">
                    <label class="accordion-tab-label" for="chck13">{{'sideMenu.personalLiability.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionSuppDocs.copyTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionSuppDocs.photoIncident' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionSuppDocs.thirdParties' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
                <div class="accordion-tab">
                    <input type="checkbox" class="hideInput" id="chck14">
                    <label class="accordion-tab-label" for="chck14">{{'sideMenu.otherClaim.name' | translate}}</label>
                    <div class="accordion-tab-content uploadFileList">
                        <ol>
                            <li>{{'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.airTicket' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate}}</li>
                            <li>{{'pageSummaryClaim.claimItem.miscDoc' | translate}}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </ng-container>
        <ol class="uploadFileList">
            <ng-container *ngIf="docCategory === 'director'">
                <li>{{'claimDetails.generic.documents.director1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.director2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.director3' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'errorsomission'">
                <li>{{'claimDetails.generic.documents.errorsOmission1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.errorsOmission2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.errorsOmission3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.errorsOmission4' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'genLiability'">
                <li>{{'claimDetails.generic.documents.genLiability1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.genLiability2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.genLiability3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.genLiability4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.genLiability5' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'hull'">
                <li>{{'claimDetails.generic.documents.hull1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull5' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull6' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull7' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull8' | translate}}</li>
                <li>{{'claimDetails.generic.documents.hull9' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'marine'">
                <li>{{'claimDetails.generic.documents.marine1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marine2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marine3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marine4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marine5' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marine6' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marine7' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'marineliability'">
                <li>{{'claimDetails.generic.documents.marineLiability1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability5' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability6' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability7' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability8' | translate}}</li>
                <li>{{'claimDetails.generic.documents.marineLiability9' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'medical'">
                <li>{{'claimDetails.generic.documents.medical1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.medical5' | translate}}</li>
                <li>{{'claimDetails.generic.documents.medical4' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'medicalHK'">
                <li>{{'claimDetails.generic.documents.medical3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.medical1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.medical2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.medical4' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'motor'">
                <li>{{'claimDetails.generic.documents.motor1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.motor2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.motor3' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'others'">
                <li>{{'claimDetails.generic.documents.others1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.others2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.others3' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'othersLiability'">
                <li>{{'claimDetails.generic.documents.others1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.others2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.others3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.others4' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'pa'">
                <li>{{'claimDetails.generic.documents.pa1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pa2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pa3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pa4' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'paSG'">
                <li>{{'paClaim.supportingDocs.types.copyMedInvoices' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.hospDischargeSlip' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.doctorsMemo' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.medCertPayslip' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.medisaveStatement' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.settlementLetter' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.investigationResult' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.medReportExtentInjury' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.policeReport' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'pleasurecraft'">
                <li>{{'claimDetails.generic.documents.pleaseureCraft1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft5' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft6' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft7' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft8' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft9' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft10' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft11' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pleaseureCraft12' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'pi'">
                <li>{{'claimDetails.generic.documents.pi1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pi2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pi3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pi4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pi5' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'piHK'">
                <li>{{'claimDetails.generic.documents.pi1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pi2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pi3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.pi4' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'property'">
                <li>{{'claimDetails.generic.documents.property1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.property2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.property3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.property4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.property5' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'residentialFire'">
                <li>{{'claimDetails.generic.documents.resFire1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.resFire2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.resFire3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.resFire4' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'wicahk'">
                <li>{{'claimDetails.generic.documents.wicaHK1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaHK2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaHK3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaHK4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaHK5' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaHK6' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'wicasg'">
                <li>{{'claimDetails.generic.documents.wicaSG1' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaSG2' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaSG3' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaSG4' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaSG5' | translate}}</li>
                <li>{{'claimDetails.generic.documents.wicaSG6' | translate}}</li>
            </ng-container>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #home_doclist let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{docTitle}}</h3>
        <ol class="uploadFileList">
            <ng-container *ngIf="docCategory === 'water' || docCategory === 'weather'">
                <li>{{'homeProduct.supportingDocs.types.repairReplacementInvoices' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.possibleRepairReceipt' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.purchaseReceiptLoss' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.photoDamagePropertyScene' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.buildingReport' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'accident'">
                <li>{{'homeProduct.supportingDocs.types.possibleRepairReceipt' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.purchaseReceiptLoss' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.photoDamagePropertyScene' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.buildingReport' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'fire' || docCategory === 'burglary' || docCategory === 'vandalism'">
                <li>{{'homeProduct.supportingDocs.types.repairReplacementInvoices' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.possibleRepairReceipt' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.purchaseReceiptLoss' | translate}}</li>
                <li *ngIf="docCategory === 'fire' || docCategory === 'vandalism'">{{'homeProduct.supportingDocs.types.photoDamagePropertyScene' | translate}}</li>
                <li *ngIf="docCategory === 'burglary'">{{'homeProduct.supportingDocs.types.photoDamagePropertyPremises' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.lossDamageReportAuthorities' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.buildingReport' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.consentLetter' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'others'">
                <li>{{'homeProduct.supportingDocs.types.incidentReport' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.proofOfPurchase' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.photoItems' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.repairQuotations' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.reportByManagement' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'liability'">
                <li>{{'homeProduct.supportingDocs.types.photoThirdParty' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.thirdPartyCorrespondence' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.witnessStatement' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.policeReportLiability' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'employeeCompensation'">
                <li>{{'homeProduct.supportingDocs.types.form2' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.medicalExpenses' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.sickLeaveCertificate' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.employmentContract' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'personalAccident'">
                <li>{{'homeProduct.supportingDocs.types.medicalAccidentalExpenses' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.medicalReportDisablement' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.deathCertificate' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.policeReportAccident' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'lostItem'">
                <li>{{'homeProduct.supportingDocs.types.receiptLostItem' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.policeReportLostItem' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.receiptReplacementId' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'damagedItem'">
                <li>{{'homeProduct.supportingDocs.types.receiptDamagedItem' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.damageReport' | translate}}</li>
                <li>{{'homeProduct.supportingDocs.types.photoDamagedItem' | translate}}</li>
            </ng-container>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #pa_doclist let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{docTitle}}</h3>
        <ol class="uploadFileList">
            <ng-container *ngIf="docCategory === 'accidentalmedicalexpenses'">
                <li>{{'paClaim.supportingDocs.types.copyMedReceipt' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.dischargeSlip' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.copyReferralLetter' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.referralLetter' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.medCert' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'hospitalcash'">
                <li>{{'paClaim.supportingDocs.types.hospitalInvoice' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.medReceipt' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.dischargeSlip' | translate}}</li>
                <li><span [innerHtml]="'paClaim.supportingDocs.types.certOfHospitalizationHtml' | translate"></span></li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'others'">
                <li>{{'paClaim.supportingDocs.types.certMedBills' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.medReportExtentInjury' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.policeReport' | translate}}</li>
                <li>{{'paClaim.supportingDocs.types.expenseReceipts' | translate}}</li>
            </ng-container>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>
<ng-template #ec_doclist let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{'home.body.documents' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h3 class="modal-body-text">{{docTitle}} ({{'ecClaim.selectClaim.' + docCategory + 'Desc' | translate }})</h3>
        <ol class="uploadFileList">
            <ng-container *ngIf="docCategory === 'form2' || docCategory === 'form2a'">
                <li>{{'ecClaim.supportingDocuments.empContract' | translate}}</li>
                <li *ngIf="docCategory === 'form2'" >{{'ecClaim.supportingDocuments.salaryRecord' | translate}}</li>
                <li *ngIf="docCategory === 'form2a'" >{{'ecClaim.supportingDocuments.salaryRecordPrevToIncapacity' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.slCertificate' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.medicalReceipt' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.paymentReceipt' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.incidentReport' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.dischargeSummary' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.sketches' | translate}}</li>
            </ng-container>
            <ng-container *ngIf="docCategory === 'form2b'">
                <li>{{'ecClaim.supportingDocuments.empContract' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.slCertificate' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.medicalReceipt' | translate}}</li>
                <li>{{'ecClaim.supportingDocuments.paymentReceipt' | translate}}</li>
            </ng-container>
        </ol>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
    </div>
</ng-template>