import {Component, Injector, OnInit} from "@angular/core";
import { ClaimsBaseComponent } from "src/app/claim-pages/claim-base.component";
import { Claim } from "src/app/model/claim.model";
import { ClaimService } from "src/app/services/claim.service";
import { ThirdPartyLossDetails } from "src/app/model/home/third-party-loss-details.model";
import { ThirdPartyLegalDetails } from "src/app/model/home/third-party-legal-details.model";

@Component({
    selector: 'app-summary-liability',
    templateUrl: './summary-liability.component.html',
    styleUrls: ['./summary-liability.component.css']
})
export class SummaryLiabilityComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    thirdPartyLossDetails: ThirdPartyLossDetails;
    thirdPartyLegalDetails: ThirdPartyLegalDetails;

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.thirdPartyLossDetails = this.claim.getClaimHome().getHomeDetails().getThirdPartyLossDetails();
        this.thirdPartyLegalDetails = this.claim.getClaimHome().getHomeDetails().getThirdPartyLegalDetails();
    }

    ngOnInit() {
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
            }
        }
        return '';
    }

    returnYesOrNoStr(value: string) {
        if ('true' === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else if ('false' === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
        } else if ('Unsure' === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.unsure");
        }
        return '';
    }
}
