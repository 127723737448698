import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Injectable()
export class DateService {

  private static defaultDateFormat = 'dd/MM/yyyy';
  private static DELIMITERS: string[] = ['/', '-', ':'];

  static convertStringToDate(_date: string, _format: string): Date {

    if (_date) {
      if (_date.toLowerCase() === 'today') {
        return new Date();
      }

      const delimiter = this.getDelimiter(_date);
      const formatLowerCase: string = _format.toLowerCase();
      let formatItems: string[] = formatLowerCase.split(delimiter);

      // Check default format
      if (formatItems.length === 1) {
        formatItems = this.defaultDateFormat.toLowerCase().split(this.getDelimiter(this.defaultDateFormat));
      }

      const dateItems: string[] = _date.split(delimiter);
      const monthIndex: number = this.getIndexFromArray(formatItems, 'm');
      const dayIndex: number = this.getIndexFromArray(formatItems, 'd');
      const yearIndex: number = this.getIndexFromArray(formatItems, 'y');

      if (dayIndex > -1 && yearIndex > -1 && monthIndex > -1 &&
        dateItems[yearIndex] && dateItems[yearIndex].length === 4 && !isNaN(parseInt(dateItems[yearIndex], 0))) {
        if (isNaN(parseInt(dateItems[monthIndex], 0))) {
          return new Date(dateItems[yearIndex] + ' ' + dateItems[monthIndex] + ' ' + dateItems[dayIndex]);
        } else if (parseInt(dateItems[monthIndex], 0) > 0) {
          const month = parseInt(dateItems[monthIndex], 0) - 1;
          return new Date(parseInt(dateItems[yearIndex], 0), month, parseInt(dateItems[dayIndex], 0));
        }
      }
    }
    return null;
  }

  static convertStringToDatetime(_date: string, _format: string): Date {

      if (_date) {
      if (_date.toLowerCase() === 'today') {
        return new Date();
      }

      if(/\s/.test(_date) && /\s/.test(_format) && /\:/.test(_date) && /\:/.test(_format)){

        var monthIndex: number;
        var dayIndex: number;
        var yearIndex: number;
        var hourIndex: number;
        var minuteIndex: number;
        var formatLowerCase: string;
        var dateLowerCase: string;
        var mainFormatItems: string[];
        var timeFormatItems: string[];
        var mainDateItems: string[];
        var timeDateItems: string[];

        let dateArray = _date.split(/\s/);
        let formatArray = _format.split(/\s/);

        if(dateArray.length > 0 && formatArray.length > 0 && dateArray.length == formatArray.length){
            dateArray.forEach((dateArrayValue: string) => {

            dateLowerCase = dateArrayValue.toLowerCase();
            let delimiter = this.getDelimiter(dateArrayValue);

            if(/\//.test(dateArrayValue)){
              mainDateItems = dateLowerCase.split(delimiter);  

            }else if(/\:/.test(dateArrayValue)){
              timeDateItems = dateLowerCase.split(delimiter);

            }
          });
          formatArray.forEach((formatArrayValue: string) => {

            formatLowerCase = formatArrayValue.toLowerCase();
            let delimiter = this.getDelimiter(formatArrayValue);

            if(/\//.test(formatArrayValue)){
              mainFormatItems = formatLowerCase.split(delimiter);  

            }else if(/\:/.test(formatArrayValue)){
              timeFormatItems = formatLowerCase.split(delimiter);

            }
          });
        } else {
          return null;
        }
        monthIndex = this.getIndexFromArray(mainFormatItems, 'm');
        dayIndex = this.getIndexFromArray(mainFormatItems, 'd');
        yearIndex = this.getIndexFromArray(mainFormatItems, 'y');
        hourIndex= this.getIndexFromArray(timeFormatItems, 'h');
        minuteIndex = this.getIndexFromArray(timeFormatItems, 'm');
        
      if (dayIndex > -1 && yearIndex > -1 && monthIndex > -1 &&
        mainDateItems[yearIndex] && mainDateItems[yearIndex].length === 4 && !isNaN(parseInt(mainDateItems[yearIndex], 0))) {
        if (isNaN(parseInt(mainDateItems[monthIndex], 0))) {
          return new Date(mainDateItems[yearIndex] + ' ' + mainDateItems[monthIndex] + ' ' + mainDateItems[dayIndex]);
        } else if (parseInt(mainDateItems[monthIndex], 0) > 0) {
          const month = parseInt(mainDateItems[monthIndex], 0) - 1;
          return new Date(parseInt(mainDateItems[yearIndex], 0), month, parseInt(mainDateItems[dayIndex], 0), parseInt(timeDateItems[hourIndex], 0), parseInt(timeDateItems[minuteIndex], 0), 0);
        }
      }

      }
    }
    return null;
  }


  static getIndexFromArray(array: string[], char: string): number {
    let index = -1;
    array.forEach((value: string, key: number) => {
      if (value[0] === char) {
        index = key;
      }
    });
    return index;
  }

  static getDelimiter(_date: string) {
    return this.DELIMITERS.find(each => _date.indexOf(each) > -1);
  }

  static getDefaultDateFormat() {
    return this.defaultDateFormat;
  }

  constructor() {
  }

  stringToDate(_date: string, _format: string): Date {
    return DateService.convertStringToDate(_date, _format);
  }

  dateToString(date: Date, format: string): string {
    if (!UtilitiesService.isNullOrUndefined(date)) {
      let dateFormat: string;
      if (format) {
        dateFormat = format;
      } else {
        dateFormat = DateService.defaultDateFormat;
      }
      return new DatePipe('en-US').transform(date, dateFormat).toUpperCase();
    }
    return null;
  }

}
