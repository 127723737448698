export class DriverDetails {

    private driverType: string;
    private driverTypeDesc: string;
    private driverName: string;
    private address: string;
    private contactNumber: string;
    private email: string;
    private dateOfBirth: Date; 
    private gender: string;
    private genderDesc: string;
    private relationshipWithThePolicyHolder: string;
    private relationshipWithThePolicyHolderDesc: string;
    private otherRelationshipWithThePolicyHolder: string;
    private hasObtainedPermission: boolean;
    private wasUnderInfluence: boolean;
    private wasTestConducted: boolean;
    private hasDriverPaid: boolean;
    private hasPaidCompensation: boolean; 
    private paidCompensation: string;
    private paidCompensationCurrency: string;
    private hasReceivedCompensation: boolean;
    private receivedCompensation: string;
    private receivedCompensationCurrency: string;
    private wasCompensationExchanged: boolean;
    private compensationType: string;
    private compensationTypeDesc: string;
    private compensationAmount: string;
    private compensationAmountCurrency: string;
    private isDriverNamedOrOthers: boolean;

    getDriverType(): string {
        return this.driverType;
    }

    setDriverType(driverType: string): void {
        this.driverType = driverType;
    }

    
    getDriverTypeDesc(): string {
        return this.driverTypeDesc;
    }

    setDriverTypeDesc(driverTypeDesc: string): void {
        this.driverTypeDesc = driverTypeDesc;
    }

    getDriverName(): string {
        return this.driverName;
    }

    setDriverName(driverName: string): void {
        this.driverName = driverName;
    }

    getAddress(): string {
        return this.address;
    }

    setAddress(address: string): void {
        this.address = address;
    }

    getContactNumber(): string {
        return this.contactNumber;
    }

    setContactNumber(contactNumber: string): void {
        this.contactNumber = contactNumber;
    }

    getEmail(): string {
        return this.email;
    }

    setEmail(email: string): void {
        this.email = email;
    }

    getDateOfBirth(): Date {
        return this.dateOfBirth;
    }

    setDateOfBirth(dateOfBirth: Date): void {
        this.dateOfBirth = dateOfBirth;
    }

    getGender(): string {
        return this.gender;
    }

    setGender(gender: string): void {
        this.gender = gender;
    }

    getGenderDesc(): string {
        return this.genderDesc;
    }

    setGenderDesc(genderDesc: string): void {
        this.genderDesc = genderDesc;
    }

    getRelationshipWithThePolicyHolder(): string {
        return this.relationshipWithThePolicyHolder;
    }

    setRelationshipWithThePolicyHolder(relationshipWithThePolicyHolder: string): void {
        this.relationshipWithThePolicyHolder = relationshipWithThePolicyHolder;
    }

    getRelationshipWithThePolicyHolderDesc(): string {
        return this.relationshipWithThePolicyHolderDesc;
    }

    setRelationshipWithThePolicyHolderDesc(relationshipWithThePolicyHolderDesc: string): void {
        this.relationshipWithThePolicyHolderDesc = relationshipWithThePolicyHolderDesc;
    }

    getOtherRelationshipWithThePolicyHolder(): string {
        return this.otherRelationshipWithThePolicyHolder;
    }

    setOtherRelationshipWithThePolicyHolder(otherRelationshipWithThePolicyHolder: string): void {
        this.otherRelationshipWithThePolicyHolder = otherRelationshipWithThePolicyHolder;
    }

    getHasObtainedPermission(): boolean {
        return this.hasObtainedPermission;
    }

    setHasObtainedPermission(hasObtainedPermission: boolean): void {
        this.hasObtainedPermission = hasObtainedPermission;
    }

    getWasUnderInfluence(): boolean {
        return this.wasUnderInfluence;
    }

    setWasUnderInfluence(wasUnderInfluence: boolean): void {
        this.wasUnderInfluence = wasUnderInfluence;
    }

    getWasTestConducted(): boolean {
        return this.wasTestConducted;
    }

    setWasTestConducted(wasTestConducted: boolean): void {
        this.wasTestConducted = wasTestConducted;
    }

    getHasDriverPaid(): boolean {
        return this.hasDriverPaid;
    }

    setHasDriverPaid(hasDriverPaid: boolean): void {
        this.hasDriverPaid = hasDriverPaid;
    }

    isHasPaidCompensation(): boolean {
        return this.hasPaidCompensation;
    }

    setHasPaidCompensation(hasPaidCompensation: boolean): void {
        this.hasPaidCompensation = hasPaidCompensation;
    }

    getPaidCompensation(): string {
        return this.paidCompensation;
    }

    setPaidCompensation(paidCompensation: string): void {
        this.paidCompensation = paidCompensation;
    }

    getPaidCompensationCurrency(): string {
        return this.paidCompensationCurrency;
    }

    setPaidCompensationCurrency(paidCompensationCurrency: string): void {
        this.paidCompensationCurrency = paidCompensationCurrency;
    }

    getHasReceivedCompensation(): boolean {
        return this.hasReceivedCompensation;
    }

    setHasReceivedCompensation(hasReceivedCompensation: boolean): void {
        this.hasReceivedCompensation = hasReceivedCompensation;
    }

    getReceivedCompensation(): string {
        return this.receivedCompensation;
    }

    setReceivedCompensation(receivedCompensation: string): void {
        this.receivedCompensation = receivedCompensation;
    }

    getReceivedCompensationCurrency(): string {
        return this.receivedCompensationCurrency;
    }

    setReceivedCompensationCurrency(receivedCompensationCurrency: string): void {
        this.receivedCompensationCurrency = receivedCompensationCurrency;
    }

    getWasCompensationExchanged(): boolean {
        return this.wasCompensationExchanged;
    }

    setWasCompensationExchanged(wasCompensationExchanged: boolean): void {
        this.wasCompensationExchanged = wasCompensationExchanged;
    }

    
    getCompensationType(): string {
        return this.compensationType;
    }

    setCompensationType(compensationType: string): void {
        this.compensationType = compensationType;
    }

    getCompensationTypeDesc(): string {
        return this.compensationTypeDesc;
    }

    setCompensationTypeDesc(compensationTypeDesc: string): void {
        this.compensationTypeDesc = compensationTypeDesc;
    }

    getCompensationAmount(): string {
        return this.compensationAmount;
    }

    setCompensationAmount(compensationAmount: string): void {
        this.compensationAmount = compensationAmount;
    }

    getCompensationAmountCurrency(): string {
        return this.compensationAmountCurrency;
    }

    setCompensationAmountCurrency(compensationAmountCurrency: string): void {
        this.compensationAmountCurrency = compensationAmountCurrency;
    }

    getIsDriverNamedOrOthers(): boolean {
        return this.isDriverNamedOrOthers;
    }

    setIsDriverNamedOrOthers(isDriverNamedOrOthers: boolean): void {
        this.isDriverNamedOrOthers = isDriverNamedOrOthers;
    }

}
