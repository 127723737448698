import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import { HomeFormBuilderUtil } from "./home-form-builder-util";

export class VandalismFormBuilder extends DocumentFormBuilderComponent implements OnInit {
   
    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_VANDALISM];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        requiredDocuments.push(new DocumentField('VANDREPREPINVOICES', 'homeProduct.supportingDocs.types.repairReplacementInvoices', SmartqDocuments.HOME_INVOICE, true));
        requiredDocuments.push(new DocumentField('VANDREPRECEIPT', 'homeProduct.supportingDocs.types.possibleRepairReceipt', SmartqDocuments.HOME_REPRECEIPT, true));
        requiredDocuments.push(new DocumentField('VANDPURCHRECEIPT', 'homeProduct.supportingDocs.types.purchaseReceiptLoss', SmartqDocuments.HOME_REPINVOICE, true));
        requiredDocuments.push(new DocumentField('VANDPHOTOS', 'homeProduct.supportingDocs.types.photoDamagePropertyScene', SmartqDocuments.HOME_PHOTOS, true));
        requiredDocuments.push(new DocumentField('VANDAUTHREPORT', 'homeProduct.supportingDocs.types.lossDamageReportAuthorities', SmartqDocuments.HOME_INVESTIGATION, true));
        requiredDocuments.push(new DocumentField('VANDBLDGREPORT', 'homeProduct.supportingDocs.types.buildingReport', SmartqDocuments.HOME_REPORT, true));
        requiredDocuments.push(new DocumentField('VANDCONSENT', 'homeProduct.supportingDocs.types.consentLetter', SmartqDocuments.HOME_ENDORSEDOC, true));
        
        let utilities: HomeFormBuilderUtil = new HomeFormBuilderUtil();
        requiredDocuments = requiredDocuments.concat(utilities.getRiskDocuments(this.claim));
        
        this.requiredDocuments = requiredDocuments;
    }
}