import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {DentalExpenses} from "../../../../model/domestic-helper/dental-expenses.model";

@Component({
  selector: 'app-details-of-injury-illness',
  templateUrl: './details-of-injury-illness.component.html',
  styleUrls: ['./details-of-injury-illness.component.css']
})

export class DetailsOfInjuryIllnessComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    dentalForm: FormGroup;
    claim: Claim;
    dentalExpenses: DentalExpenses;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.dentalExpenses = this.claim.getClaimDomesticHelper().getDentalExpenses();
    }

    ngOnInit() {

        this.pushGAViewDentalTreatmentDetails();

        if(this.dentalExpenses.getNatureIllness() == null){
            this.dentalExpenses.setNatureIllness('Dental')
        }

        if(this.dentalExpenses.getCountryIncidentOccur() == null || this.dentalExpenses.getCountryIncidentOccur() == ""){
            this.dentalExpenses.setCountryIncidentOccur('HKG');
        }

        this.dentalForm = this.fb.group({
            countryIncidentOccur: [this.dentalExpenses.getCountryIncidentOccur()],
            natureIllness: [this.dentalExpenses.getNatureIllness()],
            isIncidentReport : [super.getBooleanString(this.dentalExpenses.getIsIncidentReport())],
            specifyCondition : [this.dentalExpenses.getSpecifyCondition()],
            selectTypeTreatment : [this.dentalExpenses.getSelectTypeTreatment()],
            selectTypeTreatmentDesc : [this.dentalExpenses.getSelectTypeTreatmentDesc()]
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
 
        setTimeout(function () {self.hideFields(self.getBooleanString(self.dentalExpenses.getIsIncidentReport())),10});
            this.dentalForm.get('isIncidentReport').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideFields(data);
        });
    }

    hideFields(value) {
        if (value == 'true') {
            this.dentalForm.get('specifyCondition').enable({onlySelf: false, emitEvent: false});
        } else {
            this.dentalForm.get('specifyCondition').reset();
            this.dentalForm.get('specifyCondition').disable({onlySelf: false, emitEvent: false});
        }
    }

    ngAfterViewInit() {

        this.dentalForm.valueChanges.subscribe(data => {
            let dentalExpenses: DentalExpenses = this.claim.getClaimDomesticHelper().getDentalExpenses();
            dentalExpenses.setCountryIncidentOccur(super.getValueInComponent('countryIncidentOccur'));
            dentalExpenses.setCountryIncidentOccurDesc(this.getTextInComponent('countryIncidentOccur'));
            dentalExpenses.setNatureIllness('Dental');
            if (this.dentalForm.get('isIncidentReport').value != null) {
                dentalExpenses.setIsIncidentReport( this.dentalForm.get('isIncidentReport').value == "true");
            }
            dentalExpenses.setSpecifyCondition( super.getValueInComponent('specifyCondition'));
            dentalExpenses.setSelectTypeTreatment( super.getValueInComponent('selectTypeTreatment'));
            dentalExpenses.setSelectTypeTreatmentDesc(this.getTextInComponent('selectTypeTreatment'));
        });
        
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 0});
            this.router.navigate(["/claimform/dental/dentalExpenseIncurred"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.dentalForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewDentalTreatmentDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Dental Expenses – Dental Treatment Details',
            'vPath': '/claim/dental-expenses/treatment-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }                           
        });
    }


}
