import { HomeDetails } from "./home/home-details.model";

export class ClaimHome {
  
  private dateOfIncident : Date;
  private placeOfIncident : string;
  private placeOfIncidentDesc : string;
  private riskAddress: string;
  private isHomeWhereIncidentOccurred: boolean;
  private homeDetails: HomeDetails = new HomeDetails();
  private locationPermissionGranted: boolean = true;
  private mapReachable: boolean = true;
  private incidentAccidentOccur: string;

  //Risk Types
  private hasHSRRisk: boolean = false;
  private hasHPLHHLRisk: boolean = false;
  private hasHPWRisk: boolean = false;
  private hasHPPRisk: boolean = false;
  private isContentPremClassExist: boolean = false;
  private isBuildingPremClassExist: boolean = false;
  private hasFixFitFlag: boolean = false;

    getDateOfIncident(): Date {
        return this.dateOfIncident;
    }

    setDateOfIncident(dateOfIncident: Date): void {
        this.dateOfIncident = dateOfIncident;
    }

    getPlaceOfIncident(): string {
        return this.placeOfIncident;
    }

    setPlaceOfIncident(placeOfIncident: string): void {
        this.placeOfIncident = placeOfIncident;
    }

    getPlaceOfIncidentDesc(): string {
        return this.placeOfIncidentDesc;
    }

    setPlaceOfIncidentDesc(placeOfIncidentDesc: string): void {
        this.placeOfIncidentDesc = placeOfIncidentDesc;
    }

    getRiskAddress(): string {
        return this.riskAddress;
    }

    setRiskAddress(riskAddress: string): void {
        this.riskAddress = riskAddress;
    }

    getIsHomeWhereIncidentOccurred(): boolean {
        return this.isHomeWhereIncidentOccurred;
    }

    setIsHomeWhereIncidentOccurred(isHomeWhereIncidentOccurred: boolean): void {
        this.isHomeWhereIncidentOccurred = isHomeWhereIncidentOccurred;
    }

    getHomeDetails(): HomeDetails {
        return this.homeDetails;
    }

    
    setHomeDetails(homeDetails: HomeDetails): void {
        this.homeDetails = homeDetails;
    }

    getLocationPermissionGranted(): boolean {
        return this.locationPermissionGranted;
    }

    setLocationPermissionGranted(value: boolean) {
        this.locationPermissionGranted = value;
    }

    getMapReachable(): boolean {
        return this.mapReachable;
    }
    
    setMapReachable(value: boolean) {
        this.mapReachable = value;
    }

    getHasHSRRisk(): boolean {
        return this.hasHSRRisk;
    }
    
    setHasHSRRisk(value: boolean) {
        this.hasHSRRisk = value;
    }

    getHasHPLHHLRisk(): boolean {
        return this.hasHPLHHLRisk;
    }
    
    setHasHPLHHLRisk(value: boolean) {
        this.hasHPLHHLRisk = value;
    }

    getHasHPWRisk(): boolean {
        return this.hasHPWRisk;
    }
    
    setHasHPWRisk(value: boolean) {
        this.hasHPWRisk = value;
    }

    getHasHPPRisk(): boolean {
        return this.hasHPPRisk;
    }
    
    setHasHPPRisk(value: boolean) {
        this.hasHPPRisk = value;
    }

    getHasFixFitFlag(): boolean {
        return this.hasFixFitFlag;
    }

    setHasFixFitFlag(value: boolean) {
        this.hasFixFitFlag = value;
    }

    setIsContentPremClassExist(value: boolean){
        this.isContentPremClassExist = value;
    }

    getIsContentPremClassExist(): boolean{
        return this.isContentPremClassExist;
    }

    setIsBuildingPremClassExist(value: boolean){
        this.isBuildingPremClassExist = value;
    }

    getIsBuildingPremClassExist(): boolean{
        return this.isBuildingPremClassExist;
    }

    getIncidentAccidentOccur(): string {
        return this.incidentAccidentOccur;
    }

    setIncidentAccidentOccur(incidentAccidentOccur: string): void {
        this.incidentAccidentOccur = incidentAccidentOccur;
    }
    
    static jsonConvertClaimHome(claimHome: ClaimHome): ClaimHome {

        claimHome = Object.assign(new ClaimHome(), claimHome);
    
        if (claimHome != null) {
    
            if (claimHome.getHomeDetails()) {
                claimHome.setHomeDetails(Object.assign(new HomeDetails(), claimHome.getHomeDetails()));
                HomeDetails.jsonConvert(claimHome.getHomeDetails());
            }

            if (claimHome.getDateOfIncident() != null) {
                claimHome.setDateOfIncident(new Date(claimHome.getDateOfIncident()));
            }
        }
    
        return claimHome;
    }
}
