import {BodyPart} from './body-part.model';

export class InjuredBodyPart {

    private area: string;
    private areaDesc: string;
    private areaValue: string;
    private bodyParts: BodyPart[] = [];

    
    getArea(): string {
        return this.area;
    }

    setArea(area: string) {
        this.area = area;
    }

    getAreaDesc(): string {
        return this.areaDesc;
    }

    setAreaDesc(areaDesc: string) {
        this.areaDesc = areaDesc;
    }

    getAreaValue(): string {
        return this.areaValue;
    }

    setAreaValue(areaValue: string) {
        this.areaValue = areaValue;
    }

    getBodyParts(): BodyPart[] {
        return this.bodyParts;
    }

    setBodyParts(bodyParts: BodyPart[]) {
        this.bodyParts = bodyParts;
    }

    static jsonConvert(injuredBodyPart: InjuredBodyPart): InjuredBodyPart {

        if (injuredBodyPart.getBodyParts() !== null) {
            let bodyPartArr: BodyPart[] = [];

            for (const bodyPart of injuredBodyPart.getBodyParts()) {
                let item = Object.assign(new BodyPart(), bodyPart);
                item = BodyPart.jsonConvert(item);
                bodyPartArr.push(item);
            }
            injuredBodyPart.setBodyParts(bodyPartArr);
        }
       
        return injuredBodyPart;
    }
}