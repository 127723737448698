import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimHome } from 'src/app/model/claim-home.model';
import { HomeDetails } from 'src/app/model/home/home-details.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { takeUntil } from 'rxjs/operators';
import { HomeItem } from '../../../model/home/home-item.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-contents-building',
  templateUrl: './contents-building.component.html',
  styleUrls: ['./contents-building.component.css']
})
export class ContentsBuildingDetailsComponent  extends ClaimsBaseComponent implements OnInit {

  contentsBuildingForm: FormGroup;
  claimHome: ClaimHome;
  showTotalBox = false;
  homeDetails: HomeDetails;
  isBurglary = false;

  constructor(private fb: FormBuilder,
      private transactionInfoService: TransactionInfoService,
      private claimService: ClaimService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private injector: Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.claimHome = this.claim.getClaimHome();
      this.homeDetails = this.claimHome.getHomeDetails();
      let currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
      if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_BURGLARY]].indexOf(currentClaimType) > -1)){
        this.isBurglary = true;
      }
  }

  ngOnInit() {
      this.pushGAContentsBuildingDetailsView();

      this.contentsBuildingForm = this.fb.group({
          isThereSeriousDamage: [super.getBooleanString(this.homeDetails.getIsThereSeriousDamage())],
          isThereInteriorDamage: [super.getBooleanString(this.homeDetails.getIsThereInteriorDamage())],
          isThereFixturesDamage: [super.getBooleanString(this.homeDetails.getIsThereFixturesDamage())],
          damageDescription: [this.homeDetails.getDamageDescription()],
          isAnyItemDamaged: [super.getBooleanString(this.homeDetails.getIsAnyItemDamaged())],
          isThePropertySafeToStayIn: [super.getBooleanString(this.homeDetails.getIsThePropertySafeToStayIn())],
          isAppointBuilder: [super.getBooleanString(this.homeDetails.getIsAppointBuilder())],
          isAssistBuilder: [super.getBooleanString(this.homeDetails.getIsAssistBuilder())],
          isThereWindowDamage: [super.getBooleanString(this.homeDetails.getIsThereWindowDamage())]
      });
  }

  ngAfterViewInit() {

      this.contentsBuildingForm.valueChanges.subscribe(data => {
        if (this.contentsBuildingForm.get('isThereSeriousDamage').value !== null) {
            this.homeDetails.setIsThereSeriousDamage(this.contentsBuildingForm.get('isThereSeriousDamage').value == 'true');
        }
        this.homeDetails.setDamageDescription(this.contentsBuildingForm.get('damageDescription').value);
        if (this.contentsBuildingForm.get('isAnyItemDamaged').value !== null) {
            this.homeDetails.setIsAnyItemDamaged(this.contentsBuildingForm.get('isAnyItemDamaged').value == 'true');
        }
        if (this.contentsBuildingForm.get('isThePropertySafeToStayIn').value != null) {
            this.homeDetails.setIsThePropertySafeToStayIn(this.contentsBuildingForm.get('isThePropertySafeToStayIn').value == 'true');
        }
        if (this.contentsBuildingForm.get('isAppointBuilder').value != null) {
            this.homeDetails.setIsAppointBuilder(this.contentsBuildingForm.get('isAppointBuilder').value == 'true');
        }
        if (this.contentsBuildingForm.get('isAssistBuilder').value != null) {
            this.homeDetails.setIsAssistBuilder(this.contentsBuildingForm.get('isAssistBuilder').value == 'true');
        } 
        if (this.contentsBuildingForm.get('isThereWindowDamage').value != null) {
            this.homeDetails.setIsThereWindowDamage(this.contentsBuildingForm.get('isThereWindowDamage').value == 'true');
        } 
        if (this.contentsBuildingForm.get('isThereInteriorDamage').value !== null) {
            this.homeDetails.setIsThereInteriorDamage(this.contentsBuildingForm.get('isThereInteriorDamage').value == 'true');
        }
        if (this.contentsBuildingForm.get('isThereFixturesDamage').value !== null) {
            this.homeDetails.setIsThereFixturesDamage(this.contentsBuildingForm.get('isThereFixturesDamage').value == 'true');
        }
      });  let self = this;
      
      // Stop validation of fields when not required.
      setTimeout(function () {self.hideAppointBuilder(self.getBooleanString(self.homeDetails.getIsThePropertySafeToStayIn())),10});
      this.contentsBuildingForm.get('isThePropertySafeToStayIn').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.hideAppointBuilder(data);
      });
      setTimeout(function () {self.toggleAssistBuilder(),10});
      this.contentsBuildingForm.get('isThereSeriousDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.toggleAssistBuilder();
      });
      this.contentsBuildingForm.get('isThereInteriorDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.toggleAssistBuilder();
      });
      this.contentsBuildingForm.get('isThereFixturesDamage').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.toggleAssistBuilder();
      });
      this.contentsBuildingForm.get('isAnyItemDamaged').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.clearHomeItemList(data);
      })
  }

      hideAppointBuilder(isThePropertySafeToStayIn) {
        if (isThePropertySafeToStayIn === "false") {
            this.contentsBuildingForm.get('isAppointBuilder').enable({onlySelf: false, emitEvent: false});
        } else {
            this.contentsBuildingForm.get('isAppointBuilder').reset();
            this.contentsBuildingForm.get('isAppointBuilder').disable({onlySelf: false, emitEvent: false});
        }
    }

    toggleAssistBuilder() {
        let showAssistBuilder = (this.isNotNullOrUndefined(this.contentsBuildingForm.get('isThereSeriousDamage').value) && this.contentsBuildingForm.get('isThereSeriousDamage').value == "true") ||
        (this.isNotNullOrUndefined(this.contentsBuildingForm.get('isThereInteriorDamage').value) && this.contentsBuildingForm.get('isThereInteriorDamage').value == "true") ||
        (this.isNotNullOrUndefined(this.contentsBuildingForm.get('isThereFixturesDamage').value) && this.contentsBuildingForm.get('isThereFixturesDamage').value == "true") ? true : false;
        if (showAssistBuilder == true) {
            this.contentsBuildingForm.get('isAssistBuilder').enable({onlySelf: false, emitEvent: false});
            if(!this.isBurglary){
                this.contentsBuildingForm.get('damageDescription').enable({onlySelf: false, emitEvent: false});
            } 
        } else {
            this.homeDetails.setIsAssistBuilder(false);
            this.contentsBuildingForm.get('isAssistBuilder').reset();
            this.contentsBuildingForm.get('isAssistBuilder').disable({onlySelf: false, emitEvent: false});
            if(!this.isBurglary){
                this.contentsBuildingForm.get('damageDescription').reset();
                this.contentsBuildingForm.get('damageDescription').disable({onlySelf: false, emitEvent: false});
            } 
        }
    }

    clearHomeItemList(hasDamagedItems) {
        if (hasDamagedItems === "false" && !UtilitiesService.isNullOrUndefined(this.homeDetails.getHomeItems())) {
            this.homeDetails.setHomeItems([] as HomeItem[]);
        }
    }

  goToNext() {
    if (this.validateForm()) {
        let routes = this.transactionInfoService.getTransactionInfo().getDynamicRoutes();
        this.transactionInfoService.getTransactionInfo().setDynamicRoutes(this.transactionInfoService.getDynamicRoutingService().updateScreenVisibility(routes, 
            this.homeDetails.getIsAnyItemDamaged(), this.claim.getSelectedClaimTypesList()[0], "homeItems", this.router));
        this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }
}

  resolveNextRoute(): String {
    let nextRoute = '/claimform/claimDetail/selectClaim';
    let currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
    if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER], ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WATER]].indexOf(currentClaimType) > -1) && this.homeDetails.getIsAnyItemDamaged()){
        let productRoot = this.router.url.split('/')[2];
        nextRoute = '/claimform/' + productRoot + '/homeItems';
    }
    return nextRoute;
  }

  validateForm(): boolean {
      return super.validateForm(this.contentsBuildingForm);
  }


  showModal() {
      console.log('showModel');
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  back() {
    this.router.navigate(["/claimform/claimDetail/selectClaim"], {
        relativeTo: this.activatedRoute
    });
  }

  pushGAContentsBuildingDetailsView() {
    let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Details of the Incident';
    let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details';

    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({
      'pageStep': pageStep,
      'vPath': vPath,
      'event': 'vpageview',
      'ecommerce': {
        'checkout': {
            'actionField': {'step': 4}	 //Step4-Claim Details
          }
      }
    });
  }

}