import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ClaimMotor } from 'src/app/model/claim-motor.model';
import { PoliceReport } from 'src/app/model/motor/police-report.model';
import { MotorAccidentCollission } from 'src/app/model/motor/motor-accident-collission.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {MotorHelperService} from '../../../../../services/motor-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PanelRepairers } from 'src/app/model/motor/panel-repairers';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
  selector: 'app-vehicle-damage-detail',
  templateUrl: './vehicle-damage-detail.component.html',
  styleUrls: ['./vehicle-damage-detail.component.css']
})
export class VehicleDamageDetailComponent extends ClaimsBaseComponent  implements OnInit {

  motorAccidentForm: FormGroup;
  vehicleDamageForm: FormGroup;
  filterFormGroup: FormGroup;
  claimMotor: ClaimMotor;
  policeReport: PoliceReport;
  collission : MotorAccidentCollission;
  showTotalBox = false;
  damagePartList = [];
  panelRepairerList: any;
  panelRepairers: any;
  filterDistrict: string = "ALL";

  constructor(private fb: FormBuilder,
              private transactionInfoService: TransactionInfoService,
              private claimService: ClaimService,
              private sideMenuService: SideMenuService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private injector : Injector,
              private modalService: NgbModal,
              private motorHelperService: MotorHelperService) {
      super(injector);
      this.claim = this.claimService.getClaim();
      this.claimMotor = this.claim.getClaimMotor();
      this.policeReport = this.claimMotor.getPoliceReport();
      this.collission = this.claimMotor.getCollission();
      this.panelRepairers = PanelRepairers;
  }

  ngOnInit() {

      this.pushGA();
      this.processPanelRepairerTranslations();
      // get damage part list
      this.damagePartList = this.motorHelperService.getDamageParts();
      if(this.collission.getDetailsOfDamagedItem().length != 0){
        this.collission.setHasSelectedDamagePart(true);
        this.buildExistingDamagePartList();
      }

      this.vehicleDamageForm = this.fb.group({
        damageExtent: [this.collission.getDamageExtent()],
        isVehicleHasLeak: [super.getBooleanString(this.collission.getIsVehicleHasLeak())],
        hasAirbagGoneOff: [super.getBooleanString(this.collission.getHasAirbagGoneOff())],
        hasAnyWheelDamage: [super.getBooleanString(this.collission.getHasAnyWheelDamage())],
        currentLocation: [this.collission.getCurrentLocation()],
        otherLocation: [this.collission.getOtherLocation()],
        willClaimRepairCost: [super.getBooleanString(this.collission.getWillClaimRepairCost())],
        repairerAddress: [this.collission.getRepairerAddress()],
        repairerAddressDesc: [this.collission.getRepairerAddressDesc()],
        repairerName: [this.collission.getRepairerName()],
        selectRepairer: [this.collission.getSelectRepairer()],
        selectRepairerDesc: [this.collission.getSelectRepairerDesc()],
        governmentExamLocation: [this.collission.getGovernmentExamLocation()],
        hasBeenDetained: [super.getBooleanString(this.collission.getHasBeenDetained())],
        anyMechanicalPartsDamaged: [super.getBooleanString(this.collission.getAnyMechanicalPartsDamaged())],
      });

      
      this.filterFormGroup = new FormGroup({
        filterDistrict: new FormControl()
     });
      
  }

  ngAfterViewInit() {

      this.vehicleDamageForm.valueChanges.subscribe(data => {
        if(this.vehicleDamageForm.get('willClaimRepairCost').value != null) {
          this.collission.setWillClaimRepairCost(this.vehicleDamageForm.get('willClaimRepairCost').value == 'true')
        }
        if(this.vehicleDamageForm.get('isVehicleHasLeak').value != null) {
          this.collission.setIsVehicleHasLeak(this.vehicleDamageForm.get('isVehicleHasLeak').value == 'true')
        }
        if(this.vehicleDamageForm.get('hasAirbagGoneOff').value != null) {
            this.collission.setHasAirbagGoneOff(this.vehicleDamageForm.get('hasAirbagGoneOff').value == 'true')
        }
        if(this.vehicleDamageForm.get('hasAnyWheelDamage').value != null) {
        this.collission.setHasAnyWheelDamage(this.vehicleDamageForm.get('hasAnyWheelDamage').value == 'true')
        }  
        if(this.vehicleDamageForm.get('hasBeenDetained').value != null) {
          this.collission.setHasBeenDetained(this.vehicleDamageForm.get('hasBeenDetained').value == 'true')
        }
        if(this.vehicleDamageForm.get('anyMechanicalPartsDamaged').value != null) {
          this.collission.setAnyMechanicalPartsDamaged(this.vehicleDamageForm.get('anyMechanicalPartsDamaged').value == 'true')
        }  
        this.collission.setDamageExtent(this.vehicleDamageForm.get('damageExtent').value);
        this.collission.setDamageExtentDesc(super.getSelectedRadioText('damageExtent'));
        this.collission.setCurrentLocation(this.vehicleDamageForm.get('currentLocation').value);
        this.collission.setCurrentLocationDesc(super.getTextInComponent('currentLocation'));
        this.collission.setOtherLocation(this.vehicleDamageForm.get('otherLocation').value);
        this.collission.setRepairerAddress(this.vehicleDamageForm.get('repairerAddress').value); // Address for Others

           let repairerInfo = this.panelRepairers.filter(i => i.value === this.vehicleDamageForm.get('selectRepairer').value);
           if (repairerInfo && repairerInfo.length > 0) {
              let selectedRepAddress = this.isNotNullOrUndefined(repairerInfo[0]['address']) ? self.translate.instant(repairerInfo[0]['address']) : "";
              let selectedRepContact = self.translate.instant(repairerInfo[0]['hotline'])
              if (repairerInfo[0]?.['email']) {
                  selectedRepContact +=  " " + self.translate.instant(repairerInfo[0]['email']);
              }
              this.collission.setRepairerAddressDesc(selectedRepAddress);
              this.collission.setRepairerContact(selectedRepContact);
          }

        this.collission.setRepairerName(this.vehicleDamageForm.get('repairerName').value);
        this.collission.setSelectRepairer(this.vehicleDamageForm.get('selectRepairer').value);
        this.collission.setSelectRepairerDesc(this.getTextInComponent('selectRepairer'));
        this.collission.setGovernmentExamLocation(this.vehicleDamageForm.get('governmentExamLocation').value);
       });

       this.filterDistricts();

       this.filterFormGroup.get('filterDistrict').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
        this.filterDistrict = this.filterFormGroup.get('filterDistrict').value;
      });
       

      // Stop validation of the other field when reason isn't other
      let self = this;

      setTimeout(function () {self.hideFormFieldsClaimRepair(self.collission.getWillClaimRepairCost()),10});
      this.vehicleDamageForm.get('willClaimRepairCost').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.hideFormFieldsClaimRepair(data);

     });

      setTimeout(function () {self.hideFormFieldsRepairer(self.collission.getSelectRepairer()),10});
      this.vehicleDamageForm.get('selectRepairer').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.hideFormFieldsRepairer(data);

     });

      setTimeout(function () {self.hideFormFieldsCentre(self.collission.getHasBeenDetained()),10});
       this.vehicleDamageForm.get('hasBeenDetained').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideFormFieldsCentre(data);

      });

      setTimeout(function () {self.hideFormFieldsLocation(self.collission.getCurrentLocation()),10});
       this.vehicleDamageForm.get('currentLocation').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideFormFieldsLocation(data);

      });

      setTimeout(function () {self.hasAnyMechanicalPartsDamaged(self.collission.getAnyMechanicalPartsDamaged()),10});
       this.vehicleDamageForm.get('anyMechanicalPartsDamaged').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hasAnyMechanicalPartsDamaged(data);

      });

    }

    hasAnyMechanicalPartsDamaged(showFormFields) {
      let self = this;
      let mechanicalPartsIndex = 7;
      let motorWsSelectedItems: string[]  = this.collission.getDetailsOfDamagedItem().length > 0 ? this.collission.getDetailsOfDamagedItem() : [];
      let motorWsSelectedItemsDesc: string[] =  this.collission.getDetailsOfDamagedItemDesc().length > 0 ? this.collission.getDetailsOfDamagedItemDesc() : [];
        
      if (String(showFormFields) == 'true') {
        if (motorWsSelectedItems.find(a => a.valueOf() === 'Mechanical Parts') == undefined) {
          self.damagePartList[mechanicalPartsIndex].selected = true;
          motorWsSelectedItems.push(self.damagePartList[mechanicalPartsIndex].damagePartValue);
          let damagePartNameText = self.translate.instant(self.damagePartList[mechanicalPartsIndex].damagePartName);
          motorWsSelectedItemsDesc.push(damagePartNameText);

          self.collission.setDetailsOfDamagedItem(motorWsSelectedItems);
          self.collission.setDetailsOfDamagedItemDesc(motorWsSelectedItemsDesc);
        }
      } else {        
        if (self.damagePartList[mechanicalPartsIndex].selected) {
          self.damagePartList[mechanicalPartsIndex].selected = false;
          self.removeWsPartsSelectedList(self.damagePartList[mechanicalPartsIndex].damagePartValue, motorWsSelectedItems, motorWsSelectedItemsDesc);
        }        
      }
    }

    hideFormFieldsClaimRepair(showFormFields) {

      if (String(showFormFields) == 'true') {
          this.vehicleDamageForm.get('damageExtent').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('hasAirbagGoneOff').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('hasAnyWheelDamage').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('currentLocation').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('selectRepairer').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('hasBeenDetained').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('isVehicleHasLeak').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('anyMechanicalPartsDamaged').enable({onlySelf: false, emitEvent: false});

      } else {
        this.vehicleDamageForm.get('damageExtent').reset();
        this.vehicleDamageForm.get('hasAirbagGoneOff').reset();
        this.vehicleDamageForm.get('hasAnyWheelDamage').reset();
        this.vehicleDamageForm.get('currentLocation').reset();
        this.vehicleDamageForm.get('selectRepairer').reset();
        this.vehicleDamageForm.get('hasBeenDetained').reset();
        this.vehicleDamageForm.get('isVehicleHasLeak').reset();
        this.vehicleDamageForm.get('anyMechanicalPartsDamaged').reset();
        this.vehicleDamageForm.get('damageExtent').disable({onlySelf: false, emitEvent: false});
        this.vehicleDamageForm.get('hasAirbagGoneOff').disable({onlySelf: false, emitEvent: false});
        this.vehicleDamageForm.get('hasAnyWheelDamage').disable({onlySelf: false, emitEvent: false});
        this.vehicleDamageForm.get('currentLocation').disable({onlySelf: false, emitEvent: false});
        this.vehicleDamageForm.get('selectRepairer').disable({onlySelf: false, emitEvent: false});
        this.vehicleDamageForm.get('hasBeenDetained').disable({onlySelf: false, emitEvent: false});
        this.vehicleDamageForm.get('isVehicleHasLeak').disable({onlySelf: false, emitEvent: false});
        this.vehicleDamageForm.get('anyMechanicalPartsDamaged').disable({onlySelf: false, emitEvent: false});
         this.hideFormFieldsRepairer(false);
          this.hideFormFieldsCentre(false);
          this.hideFormFieldsLocation(false);
      }
    }


    hideFormFieldsRepairer(showFormFields) {
      if (String(showFormFields) == 'Others') {
          this.collission.setRepairerAddressDesc("");
          this.collission.setRepairerContact("");
          this.vehicleDamageForm.get('repairerName').enable({onlySelf: false, emitEvent: false});
          this.vehicleDamageForm.get('repairerAddress').enable({onlySelf: false, emitEvent: false});
      } else {
         this.vehicleDamageForm.get('repairerName').reset();
         this.vehicleDamageForm.get('repairerAddress').reset();
         this.vehicleDamageForm.get('repairerName').disable({onlySelf: false, emitEvent: false});
         this.vehicleDamageForm.get('repairerAddress').disable({onlySelf: false, emitEvent: false});
      }
  }

  hideFormFieldsCentre(showFormFields) {
    if (String(showFormFields) == 'true') {
        this.vehicleDamageForm.get('governmentExamLocation').enable({onlySelf: false, emitEvent: false});
    } else {
       this.vehicleDamageForm.get('governmentExamLocation').reset();
       this.vehicleDamageForm.get('governmentExamLocation').disable({onlySelf: false, emitEvent: false});
    }
  }

  
  hideFormFieldsLocation(showFormFields) {
    if (String(showFormFields) == 'Others') {
      this.vehicleDamageForm.get('otherLocation').enable({onlySelf: false, emitEvent: false});
      } else {
        this.vehicleDamageForm.get('otherLocation').reset();
        this.vehicleDamageForm.get('otherLocation').disable({onlySelf: false, emitEvent: false});
      }
    }


  back() {
      if(this.collission.getWasVehicleParkWhenDamage() !== true){         
        this.router.navigate(["/claimform/collission/driverDetails"], {
          relativeTo: this.activatedRoute
        });
      }else{
        this.router.navigate(["/claimform/collission/accidentDetails"], {
          relativeTo: this.activatedRoute
       });
      }
  }

  goToNext() {
    let shouldPickDamagePart = false;
    if(this.collission.getWillClaimRepairCost() && (UtilitiesService.isNullOrUndefined(this.collission.getDetailsOfDamagedItem()) 
      || (!UtilitiesService.isNullOrUndefined(this.collission.getDetailsOfDamagedItem()) && this.collission.getDetailsOfDamagedItem().length == 0))){
      shouldPickDamagePart = true;
      this.collission.setHasSelectedDamagePart(false);
    } 
    if (this.validateForm() && !shouldPickDamagePart) {
      this.sideMenuService.emitProcess(1, 0);
      this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 2});
      this.router.navigate(["/claimform/collission/thirdPartyDetails"], {
          relativeTo: this.activatedRoute
      });
  }  
  }

  validateForm(): boolean {
      return super.validateForm(this.vehicleDamageForm);
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  buildExistingDamagePartList(){
    let existingDamagePartList = this.collission.getDetailsOfDamagedItem();

    for(var x = 0; x < existingDamagePartList.length; x++){
        for(var y = 0; y < this.damagePartList.length; y++){
            if(existingDamagePartList[x] == this.damagePartList[y]['damagePartValue']){
                this.damagePartList[y]['selected'] = true;
            }
        }
    }
}

selectDamageParts(selectedValue: string) {

  let motorWsSelectedItems: string[]  = this.collission.getDetailsOfDamagedItem().length > 0 ? this.collission.getDetailsOfDamagedItem() : [];
  let motorWsSelectedItemsDesc: string[] =  this.collission.getDetailsOfDamagedItemDesc().length > 0 ? this.collission.getDetailsOfDamagedItemDesc() : [];
  let self = this;

  self.damagePartList.forEach(function (damagePartList, index){

    if (damagePartList.damagePartValue == selectedValue) {
        if (damagePartList.selected) {
          damagePartList.selected = false;
          self.removeWsPartsSelectedList(selectedValue,motorWsSelectedItems, motorWsSelectedItemsDesc);
        } else {
          damagePartList.selected = true;
          motorWsSelectedItems.push(damagePartList.damagePartValue);
          let damagePartNameText = self.translate.instant(damagePartList.damagePartName);
          motorWsSelectedItemsDesc.push(damagePartNameText);
          self.collission.setHasSelectedDamagePart(true);
        }
        self.collission.setDetailsOfDamagedItem(motorWsSelectedItems);
        self.collission.setDetailsOfDamagedItemDesc(motorWsSelectedItemsDesc);
    }
  });
}

removeWsPartsSelectedList(selectedValue: string, motorWsSelectedItems: string[], motorWsSelectedItemsDesc: string[]) {
  let indexSelected: number = -1 ; // determine index for description to remove or not
  for(var i= motorWsSelectedItems.length - 1; i => 0; i--){
      if (motorWsSelectedItems[i] == selectedValue) {
          indexSelected = i;
          break;
      }
  }

  if (indexSelected => 0) {
      motorWsSelectedItems.splice(indexSelected, 1);
      motorWsSelectedItemsDesc.splice(indexSelected, 1);
  }

  this.collission.setDetailsOfDamagedItem(motorWsSelectedItems);
  this.collission.setDetailsOfDamagedItemDesc(motorWsSelectedItemsDesc);
}

    //Google Analytics
    pushGA() {
        (<any>window).dataLayer.push({
            'pageStep': 'Motor Accident/Damage – Vehicle Damage Details',
            'vPath': '/claim/motor-accident-damage/vehicle-damage-details',
            'event': 'vpageview',
        });
    }

    open(content, name) {
      this.modalService.open(content, {size: 'lg'});
  }

  redirectToGoogleMaps(mapUrl: string){
    window.open(mapUrl, "_blank");
  }

  filterDistricts(){
    if(this.filterDistrict == "ALL"){
      this.panelRepairerList = this.panelRepairers;
    } else {
      this.panelRepairerList = this.panelRepairers.filter(a=> a.districtValue != undefined && a.districtValue.toLocaleUpperCase() === this.filterDistrict.toLocaleUpperCase());
    }
  }

  splitNumbers(numbers: string): string[]{
    let n = [];
    if(!UtilitiesService.isNullOrUndefined(numbers)){
      if(numbers.indexOf('/')!=-1){
        n = numbers.split('/');
      } else {
        n = [numbers];
      }
    } 
    return n;
  }

  isNotEmpty(str: string){
    return super.isNotNullOrUndefinedStr(str);
  }

  processPanelRepairerTranslations(){
    let reps: any = [];
    let self = this;
    this.panelRepairers.forEach(panelRepairer => {
      panelRepairer.district = self.translate.instant(panelRepairer.district);
      reps.push(panelRepairer);
    });
    this.panelRepairers = reps;
  }
}
