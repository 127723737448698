export class PersonalAccident {

    private hasInjury: boolean;
    private injuredPerson: string;
    private doctorName: string;
    private descOfIncident: string;

    getDoctorName(): string {
        return this.doctorName;
    }

    setDoctorName(value: string) {
        this.doctorName = value;
    }

    getDescOfIncident(): string {
        return this.descOfIncident;
    }

    setDescOfIncident(value: string) {
        this.descOfIncident = value;
    }

    getHasInjury(): boolean {
        return this.hasInjury;
    }

    setHasInjury(value: boolean) {
        this.hasInjury = value;
    }

    getInjuredPerson(): string {
        return this.injuredPerson;
    }

    setInjuredPerson(value: string) {
        this.injuredPerson = value;
    }

    static jsonConvert(personalAccident: PersonalAccident): PersonalAccident {
        personalAccident = Object.assign(new PersonalAccident(), personalAccident);
        return personalAccident;
    }
}