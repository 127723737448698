import {Component, Injector, OnInit} from "@angular/core";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { Claim } from "src/app/model/claim.model";
import { ClaimService } from "src/app/services/claim.service";
import { DocumentField } from "src/app/model/document-field";
import { DocumentHelperService } from "src/app/services/document-helper.service";

@Component({
    selector: 'app-summary-supporting-docs',
    templateUrl: './summary-supporting-docs.component.html',
    styleUrls: ['./summary-supporting-docs.component.css']
})
export class SummarySupportingDocsComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private injector : Injector,
                private documentHelperService: DocumentHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
    }

    ngOnInit() {
        let selectedClaimType = this.claim.getSelectedClaimTypesList()[0];
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(selectedClaimType).getRequiredDocuments(),
          selectedClaimType);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(selectedClaimType).getRequiredDocuments(),
          selectedClaimType); 
    }

}
