import {InjuredBodyPart} from './injured-body-part.model';

export class ECAccidentDetails {

    private accidentDate: Date;
    private accidentAddress: string;
    private accidentDescription: string;
    private accidentType: string;
    private accidentTypeDesc: string;
    private accidentTypeOthers: string;
    private natureOfInjury: string;
    private fallFromHeightOf: number;
    private result: string;
    private resultDesc: string;
    private occurredAtWork: string;
    private occurredAtWorkDesc: string;
    private hospital: string;
    private timeOfAccident: string;
    private meridiem: string;
    private injuryDiagnosis: string;
    private injuryDescription: string;
    private isAgentInvolved: boolean;
    private isTreatmentReceived: boolean;
    private treatmentType: any[];
    private injuredBodyParts: InjuredBodyPart[] = [];
    private injuredGender: string;
    private agentsInvolved: any[];
    private agentsInvolvedOthers: string;
    private otherMachineryType: string;
    private partCausingInjury: any[];
    private agentsDescription: string;

    getAccidentDate(): Date {
        return this.accidentDate;
    }

    setAccidentDate(accidentDate: Date): void {
        this.accidentDate = accidentDate;
    }

    getAccidentAddress(): string {
        return this.accidentAddress;
    }

    setAccidentAddress(accidentAddress: string): void {
        this.accidentAddress = accidentAddress;
    }

    getAccidentDescription(): string {
        return this.accidentDescription;
    }

    setAccidentDescription(accidentDescription: string): void {
        this.accidentDescription = accidentDescription;
    }

    getAccidentType(): string {
        return this.accidentType;
    }

    setAccidentType(accidentType: string): void {
        this.accidentType = accidentType;
    }

    getAccidentTypeDesc(): string {
        return this.accidentTypeDesc;
    }

    setAccidentTypeDesc(accidentTypeDesc: string): void {
        this.accidentTypeDesc = accidentTypeDesc;
    }

    getAccidentTypeOthers(): string {
        return this.accidentTypeOthers;
    }

    setAccidentTypeOthers(accidentTypeOthers: string): void {
        this.accidentTypeOthers = accidentTypeOthers;
    }

    getNatureOfInjury(): string {
        return this.natureOfInjury;
    }

    setNatureOfInjury(natureOfInjury: string): void {
        this.natureOfInjury = natureOfInjury;
    }

    getFallFromHeightOf(): number {
        return this.fallFromHeightOf;
    }

    setFallFromHeightOf(fallFromHeightOf: number): void {
        this.fallFromHeightOf = fallFromHeightOf;
    }

    getResult(): string {
        return this.result;
    }

    setResult(result: string) {
        this.result = result;
    }

    getResultDesc(): string {
        return this.resultDesc;
    }

    setResultDesc(resultDesc: string) {
        this.resultDesc = resultDesc;
    }

    getOccurredAtWork(): string {
        return this.occurredAtWork;
    }

    setOccurredAtWork(occurredAtWork: string) {
        this.occurredAtWork = occurredAtWork;
    }

    getOccurredAtWorkDesc(): string {
        return this.occurredAtWorkDesc;
    }

    setOccurredAtWorkDesc(occurredAtWorkDesc: string) {
        this.occurredAtWorkDesc = occurredAtWorkDesc;
    }

    getHospital(): string {
        return this.hospital;
    }

    setHospital(hospital: string) {
        this.hospital = hospital;
    }

    getTimeOfAccident(): string {
        return this.timeOfAccident;
    }

    setTimeOfAccident(timeOfAccident: string) {
        this.timeOfAccident = timeOfAccident;
    }

    getMeridiem(): string {
        return this.meridiem;
    }

    setMeridiem(meridiem: string) {
        this.meridiem = meridiem;
    }


    getInjuryDiagnosis(): string {
        return this.injuryDiagnosis;
    }

    setInjuryDiagnosis(injuryDiagnosis: string) {
        this.injuryDiagnosis = injuryDiagnosis;
    }

    getInjuryDescription(): string {
        return this.injuryDescription;
    }

    setInjuryDescription(injuryDescription: string) {
        this.injuryDescription = injuryDescription;
    }

    getIsAgentInvolved(): boolean {
        return this.isAgentInvolved;
    }

    setIsAgentInvolved(isAgentInvolved: boolean) {
        this.isAgentInvolved = isAgentInvolved;
    }

    getIsTreatmentReceived(): boolean {
        return this.isTreatmentReceived;
    }

    setIsTreatmentReceived(isTreatmentReceived: boolean) {
        this.isTreatmentReceived = isTreatmentReceived;
    }

    getTreatmentType(): any[] {
        return this.treatmentType;
    }

    setTreatmentType(treatmentType: any[]) {
        this.treatmentType = treatmentType;
    }


    getInjuredBodyParts(): InjuredBodyPart[] {
        return this.injuredBodyParts;
    }

    setInjuredBodyParts(injuredBodyParts: InjuredBodyPart[]) {
        this.injuredBodyParts = injuredBodyParts;
    }

    getInjuredGender(): string {
        return this.injuredGender;
    }

    setInjuredGender(injuredGender: string) {
        this.injuredGender = injuredGender;
    }

    getAgentsInvolved(): any[] {
        return this.agentsInvolved;
    }

    setAgentsInvolved(agentsInvolved: any[]) {
        this.agentsInvolved = agentsInvolved;
    }

    getAgentsInvolvedOthers(): string {
        return this.agentsInvolvedOthers;
    }

    setAgentsInvolvedOthers(agentsInvolvedOthers: string) {
        this.agentsInvolvedOthers = agentsInvolvedOthers;
    }

    getOtherMachineryType(): string {
        return this.otherMachineryType;
    }

    setOtherMachineryType(otherMachineryType: string) {
        this.otherMachineryType = otherMachineryType;
    }

    getPartCausingInjury(): any[] {
        return this.partCausingInjury;
    }

    setPartCausingInjury(partCausingInjury: any[]) {
        this.partCausingInjury = partCausingInjury;
    }

    getAgentsDescription(): string {
        return this.agentsDescription;
    }

    setAgentsDescription(agentsDescription: string) {
        this.agentsDescription = agentsDescription;
    }

    static jsonConvert(accidentDetails: ECAccidentDetails): ECAccidentDetails {
        if (accidentDetails !== null) {
            if (accidentDetails.getAccidentDate() !== null) {
                accidentDetails.setAccidentDate(new Date(accidentDetails.getAccidentDate()));
            }
        }

        if (accidentDetails.getInjuredBodyParts() !== null) {
            let injuredBodyPartArr: InjuredBodyPart[] = [];

            for (const injuredBodyPart of accidentDetails.getInjuredBodyParts()) {
                let item = Object.assign(new InjuredBodyPart(), injuredBodyPart);
                item = InjuredBodyPart.jsonConvert(item);
                injuredBodyPartArr.push(item);
            }
            accidentDetails.setInjuredBodyParts(injuredBodyPartArr);
        }
        return accidentDetails;
    }

}