<ng-template #sign_instruction_modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{'completePage.body.signInstructions.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div><img alt="" src='assets/images/ec-instructions.jpg'></div>
    <div class="steps">
      <h6><span class="green">1</span>{{'completePage.body.signInstructions.step1' | translate}}</h6>
      <h6><span class="green">2</span>{{'completePage.body.signInstructions.step2' | translate}}</h6>
      <h6><span class="green">3</span>{{'completePage.body.signInstructions.step3' | translate}}</h6>
      <h6><span class="green">4</span>{{'completePage.body.signInstructions.step4' | translate}}</h6>
      <h6><span class="green">5</span>{{'completePage.body.signInstructions.step5' | translate}}</h6>
    </div>
    <br/>
  </div>
</ng-template>
