import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatHKID'
})
export class FormatHKIDPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value !=null && value != "") {
            return value.substr(0, 4) + '***';
        } else {
            return  '***';
        }
    }

}
