import {CoverDetails} from './cover-details.model';

export class InsuredPerson {

  private riskNumber: number;
  private insuredName: string;
  private premiumClass: string;
  private planCode: string;
  private insuredPersonNumber: string;
  private insuredDateOfBirth: Date = null;
  private insuredPersonNationalIdNumber: string;
  private insuredPersonIdType: string;
  private coverDetailsList: CoverDetails[];

    getInsuredPersonNationalIdNumber(): string {
        return this.insuredPersonNationalIdNumber;
    }

    setInsuredPersonNationalIdNumber(value: string) {
        this.insuredPersonNationalIdNumber = value;
    }

    getInsuredDateOfBirth(): Date {
        return this.insuredDateOfBirth;
    }

    setInsuredDateOfBirth(value: Date) {
        this.insuredDateOfBirth = value;
    }

  getInsuredPersonNumber(): string {
    return this.insuredPersonNumber;
  }

  setInsuredPersonNumber(value: string) {
    this.insuredPersonNumber = value;
  }

  getPlanCode(): string {
    return this.planCode;
  }

  setPlanCode(value: string) {
    this.planCode = value;
  }

  getRiskNumber(): number {
    return this.riskNumber;
  }

  setRiskNumber(value: number) {
    this.riskNumber = value;
  }

  getInsuredName(): string {
    return this.insuredName;
  }

  setInsuredName(value: string) {
    this.insuredName = value;
  }

  getPremiumClass(): string {
    return this.premiumClass;
  }

  setPremiumClass(value: string) {
    this.premiumClass = value;
  }

  getInsuredPersonIdType(): string {
      return this.insuredPersonIdType;
    }

  setInsuredPersonIdType(value: string) {
      this.insuredPersonIdType = value;
  }

  getCoverDetailsList(): CoverDetails[] {
     return this.coverDetailsList;
  }

  setCoverDetailsList(value: CoverDetails[]) {
     this.coverDetailsList = value;
  }


  static jsonConvert(insuredPerson: InsuredPerson): InsuredPerson {

    insuredPerson = Object.assign(new InsuredPerson(), insuredPerson);

    if (insuredPerson.getInsuredDateOfBirth() != null) {
      insuredPerson.setInsuredDateOfBirth(new Date(insuredPerson.getInsuredDateOfBirth()));
    }
    if (insuredPerson.getCoverDetailsList() != null) {
      let coverDetails: CoverDetails[] = [];
      for (const coverDetailsItems of insuredPerson.getCoverDetailsList()) {

        let item = Object.assign(new CoverDetails(), coverDetailsItems);
        item = CoverDetails.jsonConvert(item);
        coverDetails.push(item);

      }
        insuredPerson.setCoverDetailsList(coverDetails);
    }

    return insuredPerson;
  }

}
