<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentDetails.title' | translate}}</h5>
    <p>{{ 'ecClaim.accidentDetails.subHeading' | translate}}</p>
  </div>
  <br/>
  <ng-container *ngIf="transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_EC_FORM2'" [ngTemplateOutlet]="form2"></ng-container>
  <ng-container *ngIf="transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_EC_FORM2B'" [ngTemplateOutlet]="form2b"></ng-container>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #form2b>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="accidentDetailsForm">
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_DATE [fieldId]="'EC_ACCIDENT_DATE'" [formGroup]="accidentDetailsForm" name="accidentDate"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_ADDRESS [fieldId]="'EC_ACCIDENT_ADDRESS'" [formGroup]="accidentDetailsForm" name="accidentAddress"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_DESCRIPTION [fieldId]="'EC_ACCIDENT_DESCRIPTION'" [formGroup]="accidentDetailsForm" name="accidentDescription"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_TYPE [fieldId]="'EC_ACCIDENT_TYPE'" [formGroup]="accidentDetailsForm" name="accidentType"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="accidentDetails.getAccidentType() == '4'">
          <qnect-ui #EC_ACCIDENT_FALL_FROM_HEIGHT [fieldId]="'EC_ACCIDENT_FALL_FROM_HEIGHT'" [formGroup]="accidentDetailsForm" name="fallFromHeightOf"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="accidentDetails.getAccidentType() == '17_15'">
          <qnect-ui #EC_ACCIDENT_TYPE_OTHERS [fieldId]="'EC_ACCIDENT_TYPE_OTHERS'" [formGroup]="accidentDetailsForm" name="accidentTypeOthers"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
    <div class="col-12 col-md-12">
      <app-body-parts (emitShowLabelTxt)="showTooltip($event)" (emitHideLabelTxt)="hideToolTip()" #bodyParts></app-body-parts>
    </div>
  </div>
</ng-template>

<ng-template #form2>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="accidentDetailsForm">
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_RESULT [fieldId]="'EC_ACCIDENT_RESULT'" [formGroup]="accidentDetailsForm" name="accidentResult"></qnect-ui>
        </div>
        <app-fatal-case *ngIf="accidentDetails.getResult()?.toLowerCase() === 'death'" #fatalCase> </app-fatal-case>
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_OCCURRED_AT_WORK [fieldId]="'EC_ACCIDENT_OCCURRED_AT_WORK'" [formGroup]="accidentDetailsForm" name="occurredAtWork"></qnect-ui>
        </div>

        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_DESCRIPTION [fieldId]="'EC_ACCIDENT_DESCRIPTION'" [formGroup]="accidentDetailsForm" name="accidentDescription"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_DATE [fieldId]="'EC_ACCIDENT_DATE'" [formGroup]="accidentDetailsForm" name="accidentDate"></qnect-ui>
        </div>
        <!-- time hh:mm and am/pm start -->
        <label>{{ 'ecClaim.accidentDetails.accidentTime' | translate }}*</label>
          <div class="row col-md-12 col-xs-12">
            <div class="col-md-5 col-xs-8">
              <div class="form-group">
                <qnect-ui #EC_ACCIDENT_ACCIDENT_TIME [fieldId]="'EC_ACCIDENT_ACCIDENT_TIME'" [formGroup]="accidentDetailsForm" name="timeOfAccident"></qnect-ui>
              </div>
            </div>
            <div class="col-md-3 col-xs-4">
              <div class="form-group" style="margin-right: -10px;">
                <qnect-ui #EC_ACCIDENT_ACCIDENT_MERIDIEM [fieldId]="'EC_ACCIDENT_ACCIDENT_MERIDIEM'" [formGroup]="accidentDetailsForm" name="meridiem"></qnect-ui>
              </div>
            </div>
            <div class="col-md-4">
            </div>
          </div>
        <!-- time hh:mm and am/pm end -->
        <div class="form-group">
          <qnect-ui #EC_ACCIDENT_ADDRESS [fieldId]="'EC_ACCIDENT_ADDRESS'" [formGroup]="accidentDetailsForm" name="accidentAddress"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #EC_DISEASE_HOSPITAL_NAME [fieldId]="'EC_DISEASE_HOSPITAL_NAME'" [formGroup]="accidentDetailsForm" name="hospitalName"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
</ng-template>

