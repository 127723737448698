<div class="section form-group">
    <label class="section-title">{{'homeProduct.confirmIncidentDetails.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.confirmIncidentDetails.dateOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDateTime(claimHome.getDateOfIncident())}}
                </div>
            </div>
            <div class="row" *ngIf="this.isNotNullOrUndefinedStr(claimHome.getPlaceOfIncidentDesc())">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.confirmIncidentDetails.placeOfIncident.label' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimHome.getPlaceOfIncidentDesc()}}
                </div>
            </div>
            <ng-container *ngIf="claimHome.getPlaceOfIncident()=='home'">
                <div class="row" *ngIf="this.isNotNullOrUndefinedStr(claimHome.getRiskAddress())">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.confirmIncidentDetails.isHomeWhereIncidentOccurred' | translate}} <BR>
                        {{claimHome.getRiskAddress()}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(claimHome.getIsHomeWhereIncidentOccurred())}}
                    </div>
                </div>
            </ng-container>
            <div class="row" *ngIf="this.isNotNullOrUndefinedStr(claimHome.getIncidentAccidentOccur())">
                <div class="col col-xs-12 col-lg-7">
                    {{'homeProduct.others.incidentAccidentOccur' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimHome.getIncidentAccidentOccur()}}
                </div>
            </div>
        </div>
    </div>
</div>
