import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class HospitalizationHelperFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('MEDICALBILL', 'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.medicalBill', SmartqDocuments.DMH_HOSPITALBILLS, true));
        requiredDocuments.push(new DocumentField('HOSPITALFORM', 'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.hospitalForm', SmartqDocuments.DMH_DISCHARGE, true));
        let privateHospitalFormDocField = new DocumentField('PRIVATEHOSPITALFORM', 'domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.privateHospitalForm', SmartqDocuments.DMH_DISCHARGE, true);
        privateHospitalFormDocField.setFormUrl('domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.certificateOfHospitalizationPrivateUrl');
        privateHospitalFormDocField.setFormDescription('domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.certificateOfHospitalizationPrivate');
        privateHospitalFormDocField.setDescriptionHtml('domesticHelperClaim.dmhClaimTypeDetails.hospitalization.subSupportingDocs.privateHospitalFormHtml');
        requiredDocuments.push(privateHospitalFormDocField);
        this.requiredDocuments = requiredDocuments;
    }
}
