export class PropertyItemDetail {

    private whatDidYouHit: string;
    private propertyOwnerName: string;
    private propertyOwnerNo: string;

    getWhatDidYouHit(): string {
        return this.whatDidYouHit;
    }

    setWhatDidYouHit(whatDidYouHit: string) {
        this.whatDidYouHit = whatDidYouHit;
    }

    getPropertyOwnerName(): string {
        return this.propertyOwnerName;
    }

    setPropertyOwnerName(propertyOwnerName: string) {
        this.propertyOwnerName = propertyOwnerName;
    }

    getPropertyOwnerNo(): string {
        return this.propertyOwnerNo;
    }

    setPropertyOwnerNo(propertyOwnerNo: string) {
        this.propertyOwnerNo = propertyOwnerNo;
    }

}