export enum ClaimTypes {
    CLAIM_TYPE_TDEL,          // Travel Delayed
    CLAIM_TYPE_TDEL_MC,       // Missed Connection
    CLAIM_TYPE_BAG_LOST,      // Lost Personal Item
    CLAIM_TYPE_BAG_DMG,       // Damaged Personal Item
    CLAIM_TYPE_MED_VISIT,     // Visited A Doctor,
    CLAIM_TYPE_MED_STY,       // Stayed in Hospital,
    CLAIM_TYPE_MED_DTH,       // Death and Serious Body Injury
    CLAIM_TYPE_BDEL,          // Baggage Delay
    CLAIM_TYPE_CAN,           // Trip Cancellation
    CLAIM_TYPE_CAN_CRTL,      // Trip Curtailment
    CLAIM_TYPE_CAN_RROUT,     // Travel Rerouting
    CLAIM_TYPE_MSEVNT,        // Missed Event  (new type)
    CLAIM_TYPE_RVE,           // Rental Vehicle Access
    CLAIM_TYPE_LIAB,          // Personal Liability
    CLAIM_TYPE_OTH,           // Others
    CLAIM_TYPE_UNKNOWN,       // Unknown / Not sure

    CLAIM_TYPE_DMH_CLINICAL,        // Clinical Expenses
    CLAIM_TYPE_DMH_DENTAL,          // Dental Expenses
    CLAIM_TYPE_DMH_HOSPITAL,        // Hospitalization of helper
    CLAIM_TYPE_DMH_DEATH,           // Death or Permanent disability
    CLAIM_TYPE_DMH_HELP_LIABILITY,  // Helper's Liability
    CLAIM_TYPE_DMH_EMP_LIABILITY,   // Employee's Compensation
    CLAIM_TYPE_DMH_DISHONESTY,      // Loss due to dishonesty of helper
    CLAIM_TYPE_DMH_REPATRIATION,    // Repatriation
    CLAIM_TYPE_DMH_OTHERS,          // Not Sure

    CLAIM_TYPE_MVA_WINDSCREEN,       // Motor Windscreen
    CLAIM_TYPE_MV_REPAIR,            // Motor Repair
    CLAIM_TYPE_MV_DAMAGE,            // Motor Other Property Damage
    CLAIM_TYPE_MVA_COLLISION,         // Motor Accident Collission
    CLAIM_TYPE_MV_THEFT,              // Theft

    CLAIM_TYPE_HOME_FIRE,              // Fire
    CLAIM_TYPE_HOME_WEATHER,           // weather
    CLAIM_TYPE_HOME_BURGLARY,          // Burglary
    CLAIM_TYPE_HOME_WATER,             // Water
    CLAIM_TYPE_HOME_ACCIDENT,          // Accident
    CLAIM_TYPE_HOME_VANDALISM,         // Vandalism
    CLAIM_TYPE_HOME_OTHERS,            // Others
    CLAIM_TYPE_HOME_OUTSIDE_HSR,       // Others - Outside Home HSR
    CLAIM_TYPE_HOME_OUTSIDE_NOT_HSR,   // Others - Outside Home Not HSR
    CLAIM_TYPE_HOME_NOT_ADDRESS,       // Others - Home Not Address
    CLAIM_TYPE_HOME_DMH,               // DMH
    CLAIM_TYPE_HOME_PA,                // PA
    CLAIM_TYPE_HOME_TP_LIABILITY,      // TP Liability

    //EMPLOYEE COMPENSATION CLAIM TYPES

    CLAIM_TYPE_EC_FORM2,                //FOrm 2
    CLAIM_TYPE_EC_FORM2A,               //FOrm 2A
    CLAIM_TYPE_EC_FORM2B,               //FOrm 2B
    CLAIM_TYPE_EC_NOTSURE,              //Not Sure

    //GENERIC CLAIM TYPES

    CLAIM_TYPE_GENERIC,              // Generic

    //PA CLAIM TYPES
    CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES,  // Accidental Medical Expenses
    CLAIM_TYPE_PA_HOSPITAL_CASH,                // Hospital Cash
    CLAIM_TYPE_PA_OTHERS                        // Not sure/Others
}