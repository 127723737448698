<div class="section form-group">
    <label class="section-title">{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.detailsOfTheIncident.labelDetailsOfTheIncident' | translate}} </label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.detailsOfTheIncident.dateOfIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(repatriation.getDateOfRepatIncident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.detailsOfTheIncident.countryOfOccurence' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{repatriation.getCountryOfRepatIncidentDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.incidentDesc.labelIncidentDesc' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{repatriation.getDescriptionOfRepatIncident()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.incidentDesc.amountClaimed' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{repatriation.getRepatClaimedCurrency()}} {{repatriation.getRepatClaimedAmount() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.repTempHelper.label' | translate}}</label>
            <div class="row" *ngIf="repatriation.getHasAgentFeePaid() != null">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.repTempHelper.agentFeePaid' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    <span *ngIf="repatriation.getHasAgentFeePaid() == true">{{'claimSection.generalLabels.radioButtonLabels.yes' | translate}}</span>
                    <span *ngIf="repatriation.getHasAgentFeePaid() == false ">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                </div>
            </div>
            <div class="row" *ngIf="repatriation.getHasAgentFeePaid() == true">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.repTempHelper.agentFeePaidAmount' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{repatriation.getAgentFeePaidCurrency()}}&nbsp;{{repatriation.getAgentFeePaidAmount() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row" *ngIf="repatriation.getHasTempHelper() != null">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.repTempHelper.temporaryHelper' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    <span *ngIf="repatriation.getHasTempHelper() == true">{{'claimSection.generalLabels.radioButtonLabels.yes' | translate}}</span>
                    <span *ngIf="repatriation.getHasTempHelper() == false">{{'claimSection.generalLabels.radioButtonLabels.no' | translate}}</span>
                </div>
            </div>
            <div class="row" *ngIf="repatriation.getHasTempHelper() == true">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.repTempHelper.temporaryHelperAmount' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{repatriation.getTempHelperAmountCurrency()}}&nbsp;{{repatriation.getTempHelperAmount() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionTD.subSectionSD.title' | translate}}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>