export const StatusRemarks =
    [
        {
            value:  "Adjuster Assessment in Progress",
            status:  "claimStatus.summary.status.adjusterAssessment",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Fire Services Department investigation in progress",
            status:  "claimStatus.summary.status.fireInvestigation",
            remarks: "claimStatus.summary.status.fireInvestigationDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Police investigation in progress",
            status:  "claimStatus.summary.status.policeInvestigation",
            remarks: "claimStatus.summary.status.policeInvestigationDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Consultant investigation in progress",
            status:  "claimStatus.summary.status.consultantInvestigation",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Death Inquest in progress",
            status:  "claimStatus.summary.status.deathInquest",
            remarks: "claimStatus.summary.status.deathInquestDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Pending for the third party claim",
            status:  "claimStatus.summary.status.pendingThirdParty",
            remarks: "claimStatus.summary.status.pendingThirdPartyDesc",
            image: "assets/images/claim-search/processing.png"
        },

        {
            value:  "Await the third party response",
            status:  "claimStatus.summary.status.awaitThirdParty",
            remarks: "claimStatus.summary.status.awaitThirdPartyDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Await policy excess from the Insured",
            status:  "claimStatus.summary.status.awaitPolicyExcess",
            remarks: "claimStatus.summary.status.awaitPolicyExcessDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Investigation in progress",
            status:  "claimStatus.summary.status.investigation",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Survey in progress",
            status:  "claimStatus.summary.status.survey",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Litigation in progress",
            status:  "claimStatus.summary.status.litigation",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "The third party claim is declined",
            status:  "claimStatus.summary.status.declinedThirdParty",
            remarks: "claimStatus.summary.status.declinedThirdPartyDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Negotiating with the third party",
            status:  "claimStatus.summary.status.negotThirdParty",
            remarks: "claimStatus.summary.status.negotThirdPartyDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Labour Department Investigation in progress",
            status:  "claimStatus.summary.status.labourDepartment",
            remarks: "claimStatus.summary.status.labourDepartmentDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Medical Clearance to be scheduled",
            status:  "claimStatus.summary.status.medClearanceScheduled",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value:  "Medical Clearance to be conducted",
            status:  "claimStatus.summary.status.medClearanceConducted",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value: "Medical Assessment Board to be scheduled",
            status: "claimStatus.summary.status.medAssessmentScheduled",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value: "Medical Assessment Board to be conducted",
            status: "claimStatus.summary.status.medAssessmentConducted",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value: "Claim Received",
            status: "claimStatus.summary.status.claimReceived",
            remarks: "claimStatus.summary.status.claimReceivedDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value: "Notification received",
            status: "claimStatus.summary.status.notifReceived",
            remarks: "claimStatus.summary.status.notifReceivedDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value: "Additional Documents Request via Email / Letter",
            status: "claimStatus.summary.status.addtlDocuments",
            remarks: "claimStatus.summary.status.addtlDocumentsDesc",
            image: "assets/images/claim-search/awaiting-document.png"
        },
        {
            value: "In assessment",
            status: "claimStatus.summary.status.assessment",
            remarks: "claimStatus.summary.status.assessmentDesc",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value: "Payment Processed",
            status: "claimStatus.summary.status.paid",
            remarks: "claimStatus.summary.status.paidDesc",
            image: "assets/images/claim-search/paid.png"
        },
        {
            value: "Claim Closed",
            status: "claimStatus.summary.status.closed",
            remarks: "claimStatus.summary.status.closedDesc",
            image: "assets/images/claim-search/closed.png"
        },
        {
            value: "Linked claim file status",
            status: "claimStatus.summary.status.closed",
            remarks: "claimStatus.summary.status.closedDesc",
            image: "assets/images/claim-search/closed.png"
        },
        {
            value: "Received prosecution summons from the insured",
            status: "claimStatus.summary.status.summon",
            remarks: "",
            image: "assets/images/claim-search/processing.png"
        },
        {
            value: "Additional Documents Request via Email",
            status: "claimStatus.summary.status.documentRequest",
            remarks: "claimStatus.summary.status.documentRequestDesc",
            image: "assets/images/claim-search/awaiting-document.png"
        }
    ];