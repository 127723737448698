import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-back-view-tab',
  templateUrl: './back-view-tab.component.html'
})

@Injectable()
export class BackViewTabComponent {

  @Output() emitBackViewTabOnClickArea = new EventEmitter<any>();
  backViewTabOnClickArea(e: any) {
    this.emitBackViewTabOnClickArea.emit(e);
  }

  @Output() emitBackViewTabShowTooltip = new EventEmitter<any>();
  backViewTabShowTooltip(txt: any) {
    this.emitBackViewTabShowTooltip.emit(txt);
  }

  @Output() emitBackViewTabHideToolTip = new EventEmitter<any>();
  backViewTabHideToolTip() {
    this.emitBackViewTabHideToolTip.emit();
  }

  @Input() backViewTabGender: any;

  @Input() backViewTabSelectHead: any;

  @Input() backViewTabSelectNeckTrunk: any;

  @Input() backViewTabTemp: any;

  isSelected(id: string) {
    return this.backViewTabTemp.some((obj: any) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }
}
