<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.title' | translate}}</p>
  </div>
  <BR>

  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
                <div *ngIf="this.clinicalExpenses.getNatureOfIllness() == 'Sickness'">
                    <p><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.isConditionRelatedToTheFollowing' | translate}}</strong></p>
                    <ul>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.pregnancy' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.nervousOrMentalDisease' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.restCureOrPhyCheckups' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.preventiveMedication' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.alcoholIntoxicationOrDrugs' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.aidsOrArc' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.anyPreexistingSickness' | translate}}</strong></li>
                        <li *ngIf="this.domesticInfoPlan.cancerHeartDiseaseExtensionApplicability == 'No'"><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.sicknessConditionsList.cancerHeartDisease' | translate}}</strong></li>
                    </ul>
                    <qnect-ui #DMH_CLINICAL_RELATED_CONDITIONS_FOR_SICKNESS [fieldId]="'DMH_CLINICAL_RELATED_CONDITIONS_FOR_SICKNESS'" [formGroup]="clinicalForm" name="hasRelatedConditionsForSickness"></qnect-ui>
                </div>

                <div *ngIf="this.clinicalExpenses.getNatureOfIllness() == 'Injury'">
                    <p><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.isConditionRelatedToTheFollowing' | translate}}</strong></p>
                    <ul>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.injuryOutsideOfRestDays' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.selfInflicted' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.dentalTreatment' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.alcoholIntoxicationOrDrugs' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.illegalActivities' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.driving' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.underwaterActivities' | translate}}</strong></li>
                        <li><strong>{{'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.relatedCondition.injuryConditionsList.cosmetics' | translate}}</strong></li>
                    </ul>
                    <qnect-ui #DMH_CLINICAL_RELATED_CONDITIONS_FOR_INJURY [fieldId]="'DMH_CLINICAL_RELATED_CONDITIONS_FOR_INJURY'" [formGroup]="clinicalForm" name="hasRelatedConditionsForInjury"></qnect-ui>
                </div>
                <div [style.display]="this.clinicalExpenses.getHasRelatedConditionsForSickness() ? 'inline' : 'none'">
                    <qnect-ui #DMH_CLINICAL_SICKNESS_CONDITION [fieldId]="'DMH_CLINICAL_SICKNESS_CONDITION'" [formGroup]="clinicalForm" name="sicknessRelatedCondition"></qnect-ui>
                </div>
                <div [style.display]="this.clinicalExpenses.getHasRelatedConditionsForInjury() ? 'inline' : 'none'">
                    <qnect-ui #DMH_CLINICAL_INJURY_CONDITION [fieldId]="'DMH_CLINICAL_INJURY_CONDITION'" [formGroup]="clinicalForm" name="injuryRelatedCondition"></qnect-ui>
                </div>

            </div>
        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

