import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {DishonestyHelper} from "../../../../model/domestic-helper/dishonesty-helper.model";

@Component({
  selector: 'app-dishonesty-helper-detail',
  templateUrl: './dishonesty-helper-detail.component.html',
  styleUrls: ['./dishonesty-helper-detail.component.css']
})

export class DishonestyHelperDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    dishonestyHelperForm: FormGroup;
    claim: Claim;
    dishonestyHelper : DishonestyHelper;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.dishonestyHelper = this.claim.getClaimDomesticHelper().getDishonestyHelper();
    }

    ngOnInit() {

        this.pushGAViewDishonestyOfHelperDetail();

        if(this.dishonestyHelper.getCountryIncidentOccurDesc() == null || this.dishonestyHelper.getCountryIncidentOccurDesc() == ""){
            this.dishonestyHelper.setCountryIncidentOccurDesc('HONG KONG')
            this.dishonestyHelper.setCountryIncidentOccur('HKG');
        }
        if(this.dishonestyHelper.getAmountClaimCurrency() == null){
            this.dishonestyHelper.setAmountClaimCurrency('HKD')
        }

        if (!this.dishonestyHelper.getDateOfIncident()) {
            this.dishonestyHelper.setDateOfIncident(this.claim.getDateOfLoss());
        }

        this.dishonestyHelperForm = this.fb.group({            
            dateOfIncident: [this.dishonestyHelper.getDateOfIncident()],
            countryIncidentOccur: [this.dishonestyHelper.getCountryIncidentOccur()],
            countryIncidentOccurDesc: [this.dishonestyHelper.getCountryIncidentOccurDesc()],
            descOfIncident: [this.dishonestyHelper.getDescOfIncident()],
            amountClaimCurrency: [this.dishonestyHelper.getAmountClaimCurrency()],
            amountClaim: [this.dishonestyHelper.getAmountClaim()],
            isIncidentReport: [super.getBooleanString(this.dishonestyHelper.getIsIncidentReport())],
            dateOfReport: [this.dishonestyHelper.getDateOfReport()],
            policeStationNameAndAddress: [this.dishonestyHelper.getPoliceStationNameAndAddress()],
            referenceNumber: [this.dishonestyHelper.getReferenceNumber()]
        });
    }

    ngAfterViewInit() {
        this.dishonestyHelperForm.valueChanges.subscribe(data => {
            this.dishonestyHelper.setCountryIncidentOccur(this.dishonestyHelperForm.get('countryIncidentOccur').value);
            this.dishonestyHelper.setCountryIncidentOccurDesc(this.getTextInComponent('countryIncidentOccur'));
            this.dishonestyHelper.setDateOfIncident(super.getValueInComponent('dateOfIncident'));
            this.dishonestyHelper.setDescOfIncident(super.getValueInComponent('descOfIncident'));
            this.dishonestyHelper.setAmountClaimCurrency(this.dishonestyHelperForm.get('amountClaimCurrency').value);
            this.dishonestyHelper.setAmountClaim(super.getValueInComponent('amountClaim'));
            if (this.dishonestyHelperForm.get('isIncidentReport').value != null) {
                this.dishonestyHelper.setIsIncidentReport(this.dishonestyHelperForm.get('isIncidentReport').value == 'true');
            }
            this.dishonestyHelper.setDateOfReport(super.getValueInComponent('dateOfReport'));
            this.dishonestyHelper.setPoliceStationNameAndAddress(super.getValueInComponent('policeStationNameAndAddress'));
            this.dishonestyHelper.setReferenceNumber(super.getValueInComponent('referenceNumber'));
        });
        // Stop validation of the other field when reason isn't other
        let self = this;
    
        setTimeout(function () {self.hideFields(self.getBooleanString(self.dishonestyHelper.getIsIncidentReport())),10});
            this.dishonestyHelperForm.get('isIncidentReport').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideFields(data);
        });
    }

    hideFields(value) {

        if (value == 'true') {
            this.dishonestyHelperForm.get('dateOfReport').enable({onlySelf: false, emitEvent: false});
            this.dishonestyHelperForm.get('policeStationNameAndAddress').enable({onlySelf: false, emitEvent: false});
            this.dishonestyHelperForm.get('referenceNumber').enable({onlySelf: false, emitEvent: false});
        } else {
            this.dishonestyHelperForm.get('dateOfReport').reset();
            this.dishonestyHelperForm.get('dateOfReport').disable({onlySelf: false, emitEvent: false});
            this.dishonestyHelperForm.get('policeStationNameAndAddress').reset();
            this.dishonestyHelperForm.get('policeStationNameAndAddress').disable({onlySelf: false, emitEvent: false});
            this.dishonestyHelperForm.get('referenceNumber').reset();
            this.dishonestyHelperForm.get('referenceNumber').disable({onlySelf: false, emitEvent: false});
        }
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 7, subMenuIndex: 0});
            this.router.navigate(["/claimform/dishonestyHelper/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.dishonestyHelperForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewDishonestyOfHelperDetail() {
        (<any>window).dataLayer.push({
            'pageStep': 'Dishonesty of Helper – Incident Details',
            'vPath': '/claim/dishonesty-of-helper/Incident-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
              }
            }                                                                                                                                    
        });
    }


}
