import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {AbstractControl} from "@angular/forms";
import {UtilitiesService} from '../../utilities/utilities.service';

@Component({
  selector: 'qnect-ui-costfield-currency',
  templateUrl: './ui-costfield-currency.component.html',
  styleUrls: ['../ui-base.component.scss']
})
export class UiCostfieldCurrencyComponent extends UiBaseComponent implements OnInit {

  @ViewChild('inputField', {static: true}) inputField: ElementRef;

  currencyFormControl: AbstractControl;
  currencySelectedText: string = '';
  amountFieldSize: string = '7';

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getDivRemainder() : number {
      return 12-parseInt (this.amountFieldSize)-4;
  }

  setValue(value: string) {
    this.formControl.setValue(value);
    if (this.inputField) {
      // blur the field to trigger formatting
      this.inputField.nativeElement.dispatchEvent(new Event('blur'));
    }
  }

  onKeyPress(event: any) {
     const pattern = /^[0-9\.]*$/;
     let inputChar = String.fromCharCode(event.charCode);

     if (!pattern.test(inputChar)) {
       // invalid character, prevent input
         event.preventDefault();
     }
  }

  //Only triggers if the user somehow was able to input invalid characters, removes all non-numeric characters
  formatValue(event: any){
    if(!UtilitiesService.isNullOrUndefined(event)){
       let val = event.target.value;
       if(!UtilitiesService.isNullOrUndefined(val) && val.match(/\.(?![^.]+$)|[^0-9.]/g)){
         let value = (event.target.value || '').toString().replace(/\.(?![^.]+$)|[^0-9.]/g, '');
         this.formControl.setValue(value);
         console.log("TRIGGERED");
       }
    }
  }
}
