<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
        <h5>{{'anotherInsuranceSection.label' | translate}}</h5>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-lg-8 col-xl-8 col-md-12">
            <form>
                <div class="form-group">
                    <qnect-ui #VDR15_HAS_ANOTHER_INSURANCE [fieldId]="'VDR15_HAS_ANOTHER_INSURANCE'" [formGroup]="anotherInsuranceForm"
                              name="hasAnotherInsurance" [type]="this.transactionInfo.isEmployeeCompensationProduct() ? 'radiobutton' : 'radio'"></qnect-ui>
                </div>
                <div class="click-more-content" id="q1" *ngIf="this.claim.getOtherPolicyQuestion() == 'Y'">
                    <div class="form-group">
                        <qnect-ui #VDR16_INSURANCE_NAME [fieldId]="'VDR16_INSURANCE_NAME'" [formGroup]="anotherInsuranceForm" name="insuranceName"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui #VDR17_POLICY_NUMBER [fieldId]="'VDR17_POLICY_NUMBER'" [formGroup]="anotherInsuranceForm" name="policyNumber"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui #VDR18_HAS_SUBMIT_TO_OTHER_INSURANCE [fieldId]="'VDR18_HAS_SUBMIT_TO_OTHER_INSURANCE'" [formGroup]="anotherInsuranceForm"
                                  name="hasSubmitToOtherInsurance" [type]="this.transactionInfo.isEmployeeCompensationProduct() ? 'radiobutton' : 'radio'"></qnect-ui>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-4 col-md-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>