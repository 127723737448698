import {UiOptionsModel} from "./ui-options.model";
export class UICurrencyService {

  getCurrencyOptions(): UiOptionsModel[] {
    return [
      {"value": "SGD", "label": "SGD"},
      {"value": "USD", "label": "USD"},
      {"value": "AUD", "label": "AUD"},
      {"value": "IDR", "label": "IDR"},
      {"value": "MYR", "label": "MYR"},
      {"value": "GBP", "label": "GBP"},
      {"value": "EUR", "label": "EUR"},
      {"value": "AED", "label": "AED"},
      {"value": "JPY", "label": "JPY"},
      {"value": "CAD", "label": "CAD"},
      {"value": "CNY", "label": "CNY"},
      {"value": "HKD", "label": "HKD"},
      {"value": "", "label": "------"},
      {"value": "AED", "label": "AED"},
      {"value": "BND", "label": "BND"},
      {"value": "CHF", "label": "CHF"},
      {"value": "DKK", "label": "DKK"},
      {"value": "INR", "label": "INR"},
      {"value": "KRW", "label": "KRW"},
      {"value": "NOK", "label": "NOK"},
      {"value": "NZD", "label": "NZD"},
      {"value": "PHP", "label": "PHP"},
      {"value": "SEK", "label": "SEK"},
      {"value": "THB", "label": "THB"},
      {"value": "TRY", "label": "TRY"},
      {"value": "TWD", "label": "TWD"},
      {"value": "VND", "label": "VND"}
    ]
  }

  getCurrencyOptionsHKG(): UiOptionsModel[] {
    return [
      {"value": "HKD", "label": "HKD"},
      {"value": "JPY", "label": "JPY"},
      {"value": "CNY", "label": "CNY"},
      {"value": "SGD", "label": "SGD"},
      {"value": "USD", "label": "USD"},
      {"value": "AUD", "label": "AUD"},
      {"value": "IDR", "label": "IDR"},
      {"value": "MYR", "label": "MYR"},
      {"value": "GBP", "label": "GBP"},
      {"value": "EUR", "label": "EUR"},
      {"value": "AED", "label": "AED"},
      {"value": "CAD", "label": "CAD"},
      {"value": "", "label": "------"},
      {"value": "ALL", "label": "ALL"},
      {"value": "ANG", "label": "ANG"},
      {"value": "ARS", "label": "ARS"},
      {"value": "AWG", "label": "AWG"},
      {"value": "BAM", "label": "BAM"},
      {"value": "BBD", "label": "BBD"},
      {"value": "BDT", "label": "BDT"},
      {"value": "BGN", "label": "BGN"},
      {"value": "BHD", "label": "BHD"},
      {"value": "BMD", "label": "BMD"},
      {"value": "BND", "label": "BND"},
      {"value": "BRL", "label": "BRL"},
      {"value": "BSD", "label": "BSD"},
      {"value": "BWP", "label": "BWP"},
      {"value": "BZD", "label": "BZD"},
      {"value": "CHF", "label": "CHF"},
      {"value": "CLF", "label": "CLF"},
      {"value": "CLP", "label": "CLP"},
      {"value": "COP", "label": "COP"},
      {"value": "CRC", "label": "CRC"},
      {"value": "CZK", "label": "CZK"},
      {"value": "DKK", "label": "DKK"},
      {"value": "DOP", "label": "DOP"},
      {"value": "DZD", "label": "DZD"},
      {"value": "EEK", "label": "EEK"},
      {"value": "EGP", "label": "EGP"},
      {"value": "FJD", "label": "FJD"},
      {"value": "GTQ", "label": "GTQ"},
      {"value": "GYD", "label": "GYD"},
      {"value": "HNL", "label": "HNL"},
      {"value": "HRK", "label": "HRK"},
      {"value": "HUF", "label": "HUF"},
      {"value": "ILS", "label": "ILS"},
      {"value": "INR", "label": "INR"},
      {"value": "IQD", "label": "IQD"},
      {"value": "ISK", "label": "ISK"},
      {"value": "JMD", "label": "JMD"},
      {"value": "JOD", "label": "JOD"},
      {"value": "KES", "label": "KES"},
      {"value": "KHR", "label": "KHR"},
      {"value": "KRW", "label": "KRW"},
      {"value": "KWD", "label": "KWD"},
      {"value": "KYD", "label": "KYD"},
      {"value": "KZT", "label": "KZT"},
      {"value": "LKR", "label": "LKR"},
      {"value": "LSL", "label": "LSL"},
      {"value": "LTL", "label": "LTL"},
      {"value": "LVL", "label": "LVL"},
      {"value": "LYD", "label": "LYD"},
      {"value": "MAD", "label": "MAD"},
      {"value": "MDL", "label": "MDL"},
      {"value": "MKD", "label": "MKD"},
      {"value": "MMK", "label": "MMK"},
      {"value": "MOP", "label": "MOP"},
      {"value": "MRO", "label": "MRO"},
      {"value": "MUR", "label": "MUR"},
      {"value": "MXN", "label": "MXN"},
      {"value": "MXV", "label": "MXV"},
      {"value": "NAD", "label": "NAD"},
      {"value": "NOK", "label": "NOK"},
      {"value": "NPR", "label": "NPR"},
      {"value": "NZD", "label": "NZD"},
      {"value": "OMR", "label": "OMR"},
      {"value": "PEN", "label": "PEN"},
      {"value": "PGK", "label": "PGK"},
      {"value": "PHP", "label": "PHP"},
      {"value": "PKR", "label": "PKR"},
      {"value": "PLN", "label": "PLN"},
      {"value": "PYG", "label": "PYG"},
      {"value": "QAR", "label": "QAR"},
      {"value": "RON", "label": "RON"},
      {"value": "RUB", "label": "RUB"},
      {"value": "SAR", "label": "SAR"},
      {"value": "SBD", "label": "SBD"},
      {"value": "SEK", "label": "SEK"},
      {"value": "SYP", "label": "SYP"},
      {"value": "THB", "label": "THB"},
      {"value": "TND", "label": "TND"},
      {"value": "TOP", "label": "TOP"},
      {"value": "TRY", "label": "TRY"},
      {"value": "TTD", "label": "TTD"},
      {"value": "TWD", "label": "TWD"},
      {"value": "UAH", "label": "UAH"},
      {"value": "VEB", "label": "VEB"},
      {"value": "VEF", "label": "VEF"},
      {"value": "VND", "label": "VND"},
      {"value": "VUV", "label": "VUV"},
      {"value": "WST", "label": "WST"},
      {"value": "XCD", "label": "XCD"},
      {"value": "XOF", "label": "XOF"},
      {"value": "XPF", "label": "XPF"},
      {"value": "YER", "label": "YER"},
      {"value": "ZAR", "label": "ZAR"},
      {"value": "ZMW", "label": "ZMW"},
      {"value": "ZWL", "label": "ZWL"}    ]
  }

}
