export class NotificationMessage {
  type: string;
  code: string;
  message: string;

  //For file upload
  fileErrorType: string;
  fileName: string;
  docTypeId: string;
}
