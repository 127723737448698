import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { Claim } from 'src/app/model/claim.model';
import { Repatriation } from 'src/app/model/domestic-helper/repatriation.model';
import { DocumentField } from 'src/app/model/document-field';
import { ClaimService } from 'src/app/services/claim.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-summary-repatriation',
  templateUrl: './summary-repatriation.component.html',
  styleUrls: ['./summary-repatriation.component.css']
})
export class SummaryRepatriationComponent extends ClaimsBaseComponent implements OnInit {

  claim: Claim;
  repatriation: Repatriation
  listOfDocuments: DocumentField[] = [];

  constructor(private claimService: ClaimService,
              private documentHelperService: DocumentHelperService,
              private injector : Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.repatriation = this.claim.getClaimDomesticHelper().getRepatriation();
      this.listOfDocuments = this.documentHelperService.getListOfDocuments(this.claimService.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]).getRequiredDocuments(),
          ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]);
  }
  
  ngOnInit() { }

  returnYesOrNo(value: string) {
      if ('Yes' === value) {
          return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
      } else {
          if ('No' === value) {
              return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
          }
      }
      return '';
  }

  getDate(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
      }
      else {
          return "";
      }
  }

}