import {Component, Injector, OnInit, ViewChild, TemplateRef} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {TravelDelay} from "../../../../model/travel/travel-delay.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { Version } from "../../../../../version";
import {Notifications} from "../../../../utilities/components/notification-messages/notifications.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { ClaimTypes } from "src/app/model/claim-type.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { ClaimTypeItemHelperService } from "src/app/services/claim-type-item-helper.service";
import { DocumentHelperService } from "src/app/services/document-helper.service";
import { TransactionInfo } from "src/app/model/transaction-info.model";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
    selector: 'app-reason-of-travel-delay',
    templateUrl: './reason-of-travel-delay.component.html',
    styleUrls: ['./reason-of-travel-delay.component.css']
})
export class ReasonOfTravelDelayComponent extends ClaimsBaseComponent implements OnInit {

    public delayReasonList = [];
    public currentReasonIndex: number;
    showTotalBox = false;
    travelDelay: TravelDelay;
    reasonTravelDelayForm: FormGroup;
    notifications: Notifications;
    @ViewChild('suggestion_message', {static: true}) suggestionModal: TemplateRef<any>;
    suggestionModalRef: any;
    originalReason: string;
    suggestions: Map<String, number>;
    originalReasonChosen: boolean = false;
    othersPicked: boolean = false;
    isCnLanguage: boolean = false;
    transactionInfo: TransactionInfo;

    constructor(private fb: FormBuilder,
                public http: HttpClient,
                public sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private modalService: NgbModal,
                private transactionService: TransactionInfoService,
                private claimTypeItemHelperService: ClaimTypeItemHelperService,
                private documentHelperService : DocumentHelperService) {

        super(injector);
        this.travelDelay = this.claim.getClaimTravel().getTravelDelay();
        this.transactionInfo = this.transactionService.getTransactionInfo();
    }

    ngOnInit() {
        this.pushGATravelReasonView();
        this.currentReasonIndex = -1;
        this.getDelayReasonList().subscribe(data => {
            this.delayReasonList = data;

            // Set the initial value
            let delayReasonText : string = this.travelDelay.getDelayReason();

            for(let i=0; i < this.delayReasonList.length;i++) {
                let delayReasonObj = this.delayReasonList[i];

                if (delayReasonText == delayReasonObj.delayedReasonName) {
                    this.currentReasonIndex = delayReasonObj.delayReasonId;
                }
            }
        });

        this.reasonTravelDelayForm = this.fb.group({
            delayReason: [this.travelDelay.getDelayReason()],
            delayReasonOthers: [this.travelDelay.getDelayReasonOthers()],
            isTravelDelayed: [super.getBooleanString(this.travelDelay.getIsTravelDelayed())]
        })
        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
        if(this.travelDelay.getDelayReason() == 'Other'){
            this.othersPicked = true;
            this.currentReasonIndex = 100;
        } else {
            this.enableDisableOtherField();
        }

        let currentLanguage = this.translate.currentLang;
        if (currentLanguage == 'zh') {
            this.isCnLanguage = true;
        }

       
    }

    ngAfterViewInit() {

        this.reasonTravelDelayForm.valueChanges.subscribe(data => {
            this.travelDelay.setDelayReasonOthers(super.getValueInComponent('delayReasonOthers'));
            if (this.reasonTravelDelayForm.get('isTravelDelayed').value != null) {
               this.travelDelay.setIsTravelDelayed(this.reasonTravelDelayForm.get('isTravelDelayed').value == 'true');
            }
            this.validateClaimDetailsComplete();
        });

         // Stop validation of fields when not required.
         let self = this;
         setTimeout(function () {self.hideOthersField(self.getBooleanString(self.travelDelay.getIsTravelDelayed())),10});
         this.reasonTravelDelayForm.get('isTravelDelayed').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
             this.hideOthersField(data);
         });
    }

    hideOthersField(showFormFields) {
        if (String(showFormFields) == 'true') {
            this.reasonTravelDelayForm.get('delayReasonOthers').enable({onlySelf: false, emitEvent: false});
            this.currentReasonIndex = 100;
        } else {
            this.reasonTravelDelayForm.get('delayReasonOthers').reset();
            this.reasonTravelDelayForm.get('delayReasonOthers').disable({onlySelf: false, emitEvent: false});
        }
    }

    changeReason(i, currentReasonType) {
        this.currentReasonIndex = i;
        this.travelDelay.setDelayReason(currentReasonType.delayedReasonName);
        this.travelDelay.setDelayReasonDesc(this.translate.instant(currentReasonType.delayedReasonDisplayName));
        this.enableDisableOtherField();
    }

    enableDisableOtherField() {

        if (this.travelDelay.getDelayReason() == 'Other') {
            this.reasonTravelDelayForm.get('delayReasonOthers').enable({onlySelf: false, emitEvent: false});
            this.pushGATravelReasonOthers();
            this.currentReasonIndex = 100;
        }
        else {
            this.reasonTravelDelayForm.get('delayReasonOthers').reset();
            this.reasonTravelDelayForm.get('delayReasonOthers').disable({onlySelf: false, emitEvent: false});
        }

    }

    back() {
        this.router.navigate([this.transactionInfo.getRoute(0, 1, false)?.url], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        this.notifications.clearMessages();
        if(this.travelDelay.getIsTravelDelayed() == null || this.travelDelay.getIsTravelDelayed()){
            if(this.travelDelay.getDelayReason()=="Other" && 
            (this.originalReasonChosen == false ||
            (this.originalReasonChosen && this.originalReason != this.travelDelay.getDelayReasonOthers())) &&
            this.checkForSuggestions(this.travelDelay.getDelayReasonOthers())) {
                this.suggestionModalRef = this.modalService.open(this.suggestionModal, {
                backdrop: 'static',
                keyboard: false
                }); 
            } else {
            if (this.validateForm() && this.currentReasonIndex != -1) {
                this.sideMenuService.emitProcess(1, 0);
                this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 1});
                this.router.navigate([this.transactionInfo.getRoute(0, 1, true)?.url], {
                    relativeTo: this.activatedRoute
                })
            } else if(this.currentReasonIndex == -1) {
                    this.notifications = new Notifications();
                    this.notifications.showPreamble = false;
                    let errorMessage = "error_messages.travelDelay.reasonTravelDelay";
                    this.notifications.addErrorMessage(errorMessage);
                }
            }
        } else {
            this.goToClaimTripCancellation();
        }
        
    }

    goToClaimTripCancellation() {

        let selectedClaimTypeList = this.claim.getSelectedClaimTypesList();
        this.removeTravelDelayClaimType(selectedClaimTypeList);

        let choosedIndex = 0;
        if (selectedClaimTypeList.indexOf('CLAIM_TYPE_CAN') == -1) {
            selectedClaimTypeList.push(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]);

            let claimTypeData = this.transactionInfo.getClaimTypeItemList();
            for (var i = 0; i < claimTypeData.length; i++) {
                if (claimTypeData[i]['claimTypeCode'] === ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]) {
                    claimTypeData[i]['selected'] = true;
                    choosedIndex = claimTypeData[i]['claimTypeId'];
                    break;
                }
            }
            this.transactionInfo.setClaimTypeItemList(claimTypeData);
            this.documentHelperService.addDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]);
        }

        this.sideMenuService.emitCliamType(choosedIndex);
        this.sideMenuService.emitComplete('finishDetail');
        this.sideMenuService.emitProcess(0, 4);

        //navigate to trip cancellation claim details
        let claimTypeDetailMenu = this.transactionInfo.getNavigationMenu().getClaimDetailMenu();
        let nextLinkUrl = claimTypeDetailMenu[choosedIndex]['subMenus'][0]['url'];
        console.log("goToClaimTripCancellation : nextLinkUrl" + nextLinkUrl);
        this.router.navigate([nextLinkUrl]);

    }

    removeTravelDelayClaimType(selectedClaimTypeList: string[]) {
        if (selectedClaimTypeList.indexOf('CLAIM_TYPE_TDEL') !== -1) {
            this.claimTypeItemHelperService.deleteClaimType(0);
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.reasonTravelDelayForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.reasonTravelDelayForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 0, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    getDelayReasonList(): Observable<any> {
        return this.http.get('assets/ReasonOfTravelDelay.json' + Version.getUrlVersion());

    }

    //START - Google Analytics
    pushGATravelReasonView() {
        (<any>window).dataLayer.push({
            'pageStep': 'Travel Delay - Reason',
            'vPath': '/claim/travel-delay/reason',
            'event': 'vpageview',
        });
    }

    pushGATravelReasonOthers() {
        (<any>window).dataLayer.push({'event': 'otherdelayreason'});
    }
    //END - Google Analytics
    checkForSuggestions(reasonString: string): boolean{
        this.suggestions = new Map<String, number>();
        const self = this;
        if(!UtilitiesService.isNullOrUndefined(reasonString)){
            this.delayReasonList.forEach((reason)=>{
                reason.keywords.forEach((keyword, index)=>{
                   Object.keys(keyword).forEach((key, index)=>{
                            keyword[key].forEach((val, index)=>{
                                if(reasonString.toLowerCase().indexOf(val)!=-1){
                                    let total = +(self.suggestions.get(reason.delayedReasonDisplayName));
                                    if(isNaN(total)){
                                        total = 0;
                                    }
                                    self.suggestions.set(reason.delayedReasonDisplayName, (total + (+key)));
                                }
                            });
                   });
                });
            });
            if(this.suggestions.size > 0){
                return true;
            }
        }
        return false;
    }
    abort() {
        this.suggestionModalRef.close();
    }
    proceedWithSuggestion() {
        const self = this;
        this.suggestions.forEach((val, key)=>{
            self.proceedWithReason(key.valueOf());
        });
        this.suggestionModalRef.close();
    }
    proceedWithReason(reason: string){
        const self = this;
        this.delayReasonList.forEach((delayReason, index)=>{
            if(reason == delayReason.delayedReasonDisplayName){
                self.changeReason(index, self.delayReasonList[index]);
                self.suggestionModalRef.close();
                self.redirectToOthersScreen(false);
            }
        });
    }
    proceedWithOriginal() {
        this.originalReasonChosen = true;
        this.originalReason = this.travelDelay.getDelayReasonOthers();
        this.suggestionModalRef.close();
    }
    getKeys(map){
        return Array.from(map.keys());
    }
    redirectToOthersScreen(redirect: boolean) {
        if(redirect == true){
            this.othersPicked = true;
            this.travelDelay.setDelayReason('Other');
            this.travelDelay.setDelayReasonDesc('Other');
            this.reasonTravelDelayForm.get('isTravelDelayed').enable({onlySelf: false, emitEvent: false});
            this.reasonTravelDelayForm.get('isTravelDelayed').enable({onlySelf: false, emitEvent: false});
        } else {
            this.othersPicked = false;
            this.travelDelay.setDelayReason(null);
            this.travelDelay.setDelayReasonDesc(null);
            this.travelDelay.setDelayReasonOthers(null);
            this.travelDelay.setIsTravelDelayed(null);
            this.currentReasonIndex = -1;
            this.reasonTravelDelayForm.get('isTravelDelayed').reset();
            this.reasonTravelDelayForm.get('isTravelDelayed').disable({onlySelf: false, emitEvent: false});
        }
        this.hideOthersField(redirect);
     
    }
}
