import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { ClaimHome } from 'src/app/model/claim-home.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-summary-home-details',
    templateUrl: './summary-home-details.component.html',
    styleUrls: ['./summary-home-details.component.css']
})

export class SummaryHomeDetailsComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimHome: ClaimHome;

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimHome = this.claim.getClaimHome();

    }
    
    ngOnInit() {

    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.noSend");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }


}
