import { Component, OnInit } from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
  selector: 'app-form2a',
  templateUrl: './form2a.component.html'
})
export class Form2AComponent implements OnInit {

  constructor( private transactionInfoService: TransactionInfoService) {
      this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A]);
  }

  ngOnInit() {
  }

}


