<div class="saveBox" [style.display]="showTotalBox?'inherit':'none'">
  <div (click)="closeBox()">
  <i class="fas fa-times closeIcon" ></i>
  </div>
  <div class="sendBefore" [hidden]="!showSend">
    <h1>{{ 'saveBox.sendBefore.title' | translate }}</h1>
    <div *ngIf="errorMessage" class="error-notification">
      <div class="row col-12">
        <p>{{ errorMessage | translate }}</p>
      </div>
    </div>
    <p>{{ 'saveBox.sendBefore.desc' | translate }}</p>
    <form class="form-builder">
        <qnect-ui #SAVE_AND_SEND_EMAIL [fieldId]="'SAVE_AND_SEND_EMAIL'" [formGroup]="saveForm" name="saveEmail"></qnect-ui>
    </form>
    <button class="sendBtn" (click)="sendAction()" [disabled]="!isFormValid">{{ 'saveBox.sendBefore.button' | translate }} </button>
  </div>
  <div class="sendAfter" [hidden]="!showSave">
    <h1>{{ 'saveBox.sendAfter.title' | translate }}</h1>
    <p>{{ 'saveBox.sendAfter.desc1' | translate }} {{transactionInfo.getSaveEmail()}}. {{ 'saveBox.sendAfter.desc2' | translate }}
    </p>
    <button [disabled]="email === ''" class="saveBtn" (click)="closeAction()">{{ 'saveBox.sendAfter.button' | translate }}  <i class="fas fa-times"></i> </button>
  </div>
</div>
