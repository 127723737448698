import {Component, OnInit, ViewChild} from '@angular/core';

import { StoredDataService } from '../services/stored-data-service';
import { TransactionInfoService } from '../services/transaction-info.service';
import { ModalDialogComponent } from '../utilities/components/modal-dialog/modal-dialog.component';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { TransactionInfo } from '../model/transaction-info.model';

@Component({
  selector: 'abandon-button',
  templateUrl: './abandon-button.component.html',
  styleUrls: ['./abandon-button.component.scss']
})
export class AbandonButtonComponent implements OnInit {

  @ViewChild(ModalDialogComponent, {static: true})
  modal: ModalDialogComponent;
  abandonLink = "";
  transactionInfo : TransactionInfo;
  constructor(private transactionInfoService: TransactionInfoService,
              private storedDataService : StoredDataService,
              private modalService: NgbModal) {
    this.transactionInfo = this.transactionInfoService.getTransactionInfo();
  }

  ngOnInit() {
     this.abandonLink = this.transactionInfoService.getExitUrl();
  }

  abandon(): void {
    this.storedDataService.clearSession();
    window.location.href = this.abandonLink;
  }

  open(content, name) {
    this.modalService.open(content, {size: 'lg', backdrop: 'static', keyboard: false});
  }

}
