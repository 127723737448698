import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-left-view-modal',
  templateUrl: './left-view-modal.component.html'
})

@Injectable()
export class LeftViewModalComponent {

  @Input() leftViewModalGetArea: any;
  @Input() leftViewModalIsOpen: any;
  @Input() leftViewModalIsOpenF: any;

  @Input() leftViewModalTable: any;
  @Input() leftViewModalSelectHead: any;
  @Input() leftViewModalSelectNeckTrunk: any;
  @Input() leftViewModalSelectLeftArm: any;
  @Input() leftViewModalSelectRightArm: any;
  @Input() leftViewModalSelectLeftHand: any;
  @Input() leftViewModalSelectRightHand: any;
  @Input() leftViewModalSelectLeftLeg: any;
  @Input() leftViewModalSelectRightLeg: any;
  @Input() leftViewModalSelectLeftFoot: any;
  @Input() leftViewModalSelectRightFoot: any;
  @Input() leftViewModalTemp: any;

  @Input() leftViewModalFetchedData: any;

  @Input() leftViewModalInjuryList: any;

  @Input() leftViewModalIsLoading: any;

  @Output() emitLeftViewModalOnClickCloseSpecificArea = new EventEmitter<any>();
  leftViewModalOnClickCloseSpecificArea() {
    this.emitLeftViewModalOnClickCloseSpecificArea.emit();
  }

  @Output() emitLeftViewModalOnClickSpecificArea = new EventEmitter<any>();
  leftViewModalOnClickSpecificArea(id: any, area: any) {
    this.emitLeftViewModalOnClickSpecificArea.emit({ id, area });
  }

  @Output() emitLeftViewModalOnClickNotClose = new EventEmitter<any>();
  leftViewModalOnClickNotClose(e: any) {
    this.emitLeftViewModalOnClickNotClose.emit(e);
  }

  @Output() emitLeftViewModalShowTooltip = new EventEmitter<any>();
  leftViewModalShowTooltip(txt: any) {
    this.emitLeftViewModalShowTooltip.emit(txt);
  }

  @Output() emitLeftViewModalHideToolTip = new EventEmitter<any>();
  leftViewModalHideToolTip() {
    this.emitLeftViewModalHideToolTip.emit();
  }

  isSelected(id: any) {
    return this.leftViewModalTemp.some((obj: any) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }

  @Output() emitLeftViewModalOnSubmitSelected = new EventEmitter<any>();
  leftViewModalOnSubmitSelected() {
    this.emitLeftViewModalOnSubmitSelected.emit();
  }

  @Output() emitLeftViewModalOnSubmitSelectedF = new EventEmitter<any>();
  leftViewModalOnSubmitSelectedF() {
    this.emitLeftViewModalOnSubmitSelectedF.emit();
  }

  checkAreaExists(area: any) {
    return this.leftViewModalTable.some((item: any) => item.area === area);
  }

  @Output() emitLeftViewModalCustomSearchFn = new EventEmitter<any>();
  leftViewModalCustomSearchFn(term: any) {
    this.emitLeftViewModalCustomSearchFn.emit(term);
  }

  @Output() emitLeftViewModalAddTagFn = new EventEmitter<any>();
  leftViewModalAddTagFn(label: any) {
    this.emitLeftViewModalAddTagFn.emit(label);
  }

  @Output() emitLeftViewModalOnClickRemoveSpecificArea = new EventEmitter<any>();
  leftViewModalOnClickRemoveSpecificArea(id: any, area: any) {
    this.emitLeftViewModalOnClickRemoveSpecificArea.emit({ id, area });
  }
}
