import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import { DocumentField } from "src/app/model/document-field";
import { SmartqDocuments } from "src/app/model/smartq-documents.model";

export class OthersPaFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        if (this.claim.getCountry().toLowerCase() === 'sgp') {
            requiredDocuments.push(new DocumentField('MEDINVOICE', 'paClaim.supportingDocs.types.copyMedInvoices', SmartqDocuments.PA_INVOICE, true));
            requiredDocuments.push(new DocumentField('DISCHARGESLIP', 'paClaim.supportingDocs.types.hospDischargeSlip', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('DOCMEMO', 'paClaim.supportingDocs.types.doctorsMemo', SmartqDocuments.PA_MEDICAL, true));
            requiredDocuments.push(new DocumentField('MEDCERT', 'paClaim.supportingDocs.types.medCertPayslip', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('MEDISAVE', 'paClaim.supportingDocs.types.medisaveStatement', SmartqDocuments.PA_REPORT, true));
            if (this.claim.getOtherPolicyQuestion() === 'Y') {
                requiredDocuments.push(new DocumentField('SETTLEMENTLETTER', 'paClaim.supportingDocs.types.settlementLetter', SmartqDocuments.PA_OTHER, true));
            }
            requiredDocuments.push(new DocumentField('INVESTIGATIONRESULT', 'paClaim.supportingDocs.types.investigationResult', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('MEDREPORT', 'paClaim.supportingDocs.types.medReportExtentInjury', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('POLICEREPORT', 'paClaim.supportingDocs.types.policeReport', SmartqDocuments.PA_OTHER, true));
        } else {
            requiredDocuments.push(new DocumentField('RECEIPTS', 'paClaim.supportingDocs.types.certMedBills', SmartqDocuments.PA_INVOICE, true));
            requiredDocuments.push(new DocumentField('MEDICALREPORT', 'paClaim.supportingDocs.types.medReportExtentInjury', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('POLICEREPORT', 'paClaim.supportingDocs.types.policeReport', SmartqDocuments.PA_REPORT, true));
            requiredDocuments.push(new DocumentField('OTHERRECEIPTS', 'paClaim.supportingDocs.types.expenseReceipts', SmartqDocuments.PA_OTHER, true));
        }
        
        this.requiredDocuments = requiredDocuments;
    }
}