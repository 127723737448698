import { Component, OnInit } from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
  selector: 'app-home-damaged-personal-item',
  templateUrl: './home-damaged-personal-item.component.html',
  styleUrls: ['./home-damaged-personal-item.component.css']
})
export class HomeDamagedPersonalItemComponent implements OnInit {

    constructor(private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]);
    }

  ngOnInit() {
  }

}
