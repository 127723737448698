import {Injectable} from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable()
export class ClaimProductService {

    private productList = {
        "AVI": {description: "Aviation Insurance"               , label:"claimStatus.productDesc.AVI"},
        "BIZ": {description: "SME BPK"                          , label:"claimStatus.productDesc.BIZ"},
        "BIZSME": {description: "Business Insurance SME"        , label:"claimStatus.productDesc.BIZSME"},
        "BPK": {description: "Business Pack Insurance"          , label:"claimStatus.productDesc.BPK"},
        "BST": {description: "Bloodstock"                       , label:"claimStatus.productDesc.BST"},
        "BUR": {description: "Burglary"                         , label:"claimStatus.productDesc.BUR"},
        "BXP": {description: "Boiler Explosion"                 , label:"claimStatus.productDesc.BXP"},
        "CAN": {description: "Marine Cargo"                     , label:"claimStatus.productDesc.CAN"},
        "CAR": {description: "Contractors All Risk"             , label:"claimStatus.productDesc.CAR"},
        "CPM": {description: "Contractors Plant & Eq"           , label:"claimStatus.productDesc.CPM"},
        "CTP": {description: "Thai Compulsory Motor"            , label:"claimStatus.productDesc.CTP"},
        "CYB": {description: "Cyber Protect"                    , label:"claimStatus.productDesc.CYB"},
        "DMH": {description: "Domestic Helper Insurance"        , label:"claimStatus.productDesc.DMH"},
        "DOL": {description: "Directors & Officers"             , label:"claimStatus.productDesc.DOL"},
        "EAR": {description: "Erection All Risk"                , label:"claimStatus.productDesc.EAR"},
        "EEQ": {description: "Electronic Equipment"             , label:"claimStatus.productDesc.EEQ"},
        "ENO": {description: "Errors & Omissions"               , label:"claimStatus.productDesc.ENO"},
        "EPP": {description: "Employers Package"                , label:"claimStatus.productDesc.EPP"},
        "EWL": {description: "Employers Liability"              , label:"claimStatus.productDesc.EWL"},
        "EXW": {description: "Extended Warranty"                , label:"claimStatus.productDesc.EXW"},
        "FFF": {description: "Commercial Fire"                  , label:"claimStatus.productDesc.FFF"},
        "FGS": {description: "Fidelity Guarantee"               , label:"claimStatus.productDesc.FGS"},
        "FWC": {description: "Foreign Workers PA"               , label:"claimStatus.productDesc.FWC"},
        "GAP": {description: "GAP Extended Cover"               , label:"claimStatus.productDesc.GAP"},
        "GEN": {description: "General Property"                 , label:"claimStatus.productDesc.GEN"},
        "GMB": {description: "Group Medical"                    , label:"claimStatus.productDesc.GMB"},
        "GOF": {description: "Golfers Insurance"                , label:"claimStatus.productDesc.GOF"},
        "HHH": {description: "Householders Insurance"           , label:"claimStatus.productDesc.HHH"},
        "HHHMOF": {description: "Residential Fire Insurance"    , label:"claimStatus.productDesc.HHHMOFQOF"},
        "HHHQOF": {description: "Residential Fire Insurance"    , label:"claimStatus.productDesc.HHHMOFQOF"},
        "HPK": {description: "Home & Contents Insurance"        , label:"claimStatus.productDesc.HPK"},
        "HPW": {description: "Domestic Helper"                  , label:"claimStatus.productDesc.HPW"},
        "ICT": {description: "Professional Indemnity"           , label:"claimStatus.productDesc.ICT"},
        "ISR": {description: "Industrial Special Risks"         , label:"claimStatus.productDesc.ISR"},
        "JBK": {description: "Jewellers Block"                    , label:"claimStatus.productDesc.JBK"},
        "MBD": {description: "Machinery Breakdown"              , label:"claimStatus.productDesc.MBD"},
        "MBR": {description: "Builders Risk"                    , label:"claimStatus.productDesc.MBR"},
        "MCA": {description: "Marine Cargo Annual"              , label:"claimStatus.productDesc.MCA"},
        "MCH": {description: "Hull"                             , label:"claimStatus.productDesc.MCH"},
        "MED": {description: "Medical Malpractice"              , label:"claimStatus.productDesc.MED"},
        "MEG": {description: "Energy and Terrorism"             , label:"claimStatus.productDesc.MEG"},
        "MFF": {description: "Freight Forwarders"               , label:"claimStatus.productDesc.MFF"},
        "MLL": {description: "Marine Liability"                 , label:"claimStatus.productDesc.MLL"},
        "MOC": {description: "Marine Open Cover"                , label:"claimStatus.productDesc.MOC"},
        "MPC": {description: "Marine Pleasure Craft"            , label:"claimStatus.productDesc.MPC"},
        "MVA": {description: "Motor Insurance"                  , label:"claimStatus.productDesc.MVA"},
        "OCN": {description: "Marine Cover Note"                , label:"claimStatus.productDesc.OCN"},
        "OCP": {description: "Marine Open Cover"                , label:"claimStatus.productDesc.OCP"},
        "OFF": {description: "Office Pack Insurance"            , label:"claimStatus.productDesc.OFF"},
        "PAD": {description: "Personal Accident Group"          , label:"claimStatus.productDesc.PAD"},
        "PAM": {description: "Health Protector"                 , label:"claimStatus.productDesc.PAM"},
        "PAN": {description: "Personal Accident"                , label:"claimStatus.productDesc.PAN"},
        "PEL": {description: "QBE Eclipse Cover"                , label:"claimStatus.productDesc.PEL"},
        "PGB": {description: "Guarantee Bond"                   , label:"claimStatus.productDesc.PGB"},
        "PGL": {description: "Plate Glass"                      , label:"claimStatus.productDesc.PGL"},
        "PID": {description: "Professional Indemnity Insurance" , label:"claimStatus.productDesc.PID"},
        "PIT": {description: "Travel Insurance"                 , label:"claimStatus.productDesc.PIT"},
        "PHT": {description: "Travel Insurance"                 , label:"claimStatus.productDesc.PHT"},
        "PLB": {description: "Broadform Liability"              , label:"claimStatus.productDesc.PLB"},
        "PNI": {description: "Protection & Indemnity"           , label:"claimStatus.productDesc.PNI"},
        "POP": {description: "Ecuador"                          , label:"claimStatus.productDesc.POP"},
        "PPT": {description: "Group Travel Insurance"           , label:"claimStatus.productDesc.PPT"},
        "PSA": {description: "Study Abroad Insurance"           , label:"claimStatus.productDesc.PSA"},
        "PTR": {description: "Travel Insurance"                 , label:"claimStatus.productDesc.PTR"},
        "SPK": {description: "Special Risk (All classes)"       , label:"claimStatus.productDesc.SPK"},
        "SPR": {description: "Machinery All Risk"               , label:"claimStatus.productDesc.SPR"},
        "STO": {description: "Storage Tanks"                    , label:"claimStatus.productDesc.STO"},
        "SUR": {description: "Surety"                           , label:"claimStatus.productDesc.SUR"},
        "TER": {description: "Terrorism Insurance"              , label:"claimStatus.productDesc.TER"},
        "TPR": {description: "Structured Trade/Political"       , label:"claimStatus.productDesc.TPR"},
        "TRI": {description: "Trade Credit Insurance"           , label:"claimStatus.productDesc.TRI"},
        "WCA": {description: "Employees' Compensation"          , label:"claimStatus.productDesc.WCA"},
        "WCD": {description: "Work Injury Comp - DOMESTIC"      , label:"claimStatus.productDesc.WCD"}
    };

    public getProductLabel(product: string, catalogueCode: string): string {
        if (!UtilitiesService.isNullOrUndefined(this.productList[catalogueCode])) {
            return this.productList[catalogueCode].label;
        }
        return !UtilitiesService.isNullOrUndefined(this.productList[product]) ?
            this.productList[product].label : '';
    }

    public getProductDescription(product: string,  catalogueCode: string) {
        if (!UtilitiesService.isNullOrUndefined(this.productList[catalogueCode])) {
            return this.productList[catalogueCode].description;
        }
        return !UtilitiesService.isNullOrUndefined(this.productList[product]) ?
            this.productList[product].description : '';
    }
}
