import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {PersonalLiability} from "../../../../model/travel/personal-liability.model";


@Component({
  selector: 'app-liability-detail-of-incident',
  templateUrl: './liability-detail-of-incident.component.html',
  styleUrls: ['./liability-detail-of-incident.component.css']
})


export class LiabilityDetailOfIncidentComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    liabilityForm: FormGroup;
    personalLiability: PersonalLiability;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.personalLiability = this.claim.getClaimTravel().getPersonalLiability();
        if(this.personalLiability.getLiabilityDateIncident() == null){
            this.personalLiability.setLiabilityDateIncident(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {
        this.pushGAViewLiabilityDetails();

        this.liabilityForm = this.fb.group({
            liabilityDateOfIncident: [this.personalLiability.getLiabilityDateIncident()],
            liabilityCountryIncidentOccurred: [this.personalLiability.getLiabilityCountryIncidentOccurred()],
            placeOfLoss: [this.personalLiability.getPlaceOfLoss()],
            liabilityNatureOfIncident: [this.personalLiability.getLiabilityNatureIncident()]
        });
    }

    ngAfterViewInit() {
        this.liabilityForm.valueChanges.subscribe(data => {
            this.personalLiability.setLiabilityDateIncident(super.getValueInComponent('liabilityDateOfIncident'));
            this.personalLiability.setLiabilityCountryIncidentOccurred(super.getValueInComponent('liabilityCountryIncidentOccurred'));
            this.personalLiability.setLiabilityCountryIncidentOccurredDesc(this.getTextInComponent('liabilityCountryIncidentOccurred'));
            this.personalLiability.setPlaceOfLoss(super.getValueInComponent('placeOfLoss'));
            this.personalLiability.setPlaceOfLossDesc(super.getTextInComponent('placeOfLoss'));
            this.personalLiability.setLiabilityNatureIncident(super.getValueInComponent('liabilityNatureOfIncident'));
            this.personalLiability.setLiabilityNatureIncidentDesc(super.getTextInComponent('liabilityNatureOfIncident'));

            this.validateClaimDetailsComplete();
        });
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 14, subMenuIndex: 0});
            this.router.navigate(["/claimform/personalLiability/descOfIncident"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.liabilityForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.liabilityForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 14, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 14, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewLiabilityDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Personal Liability – Incident Details',
            'vPath': '/claim/personal-liability/incident-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }
}

