export class OverseasBankPayment {

  private beneficiaryName: string;
  private beneficiaryBankName: string;
  private beneficiaryBankAddress: string;
  private swiftCode: string;
  private beneficiaryAccountNumber: string;
  private beneficiaryBankCode: string;
  private beneficiaryBranchCode: string;
  private intermediaryAccountholderName: string;
  private intermediaryBankName: string;
  private intermediaryBankAddress: string;
  private intermediarySwiftCode: string;
  private intermediaryBankAccountNumber: string;

  getBeneficiaryName(): string {
    return this.beneficiaryName;
  }

  setBeneficiaryName(value: string) {
    this.beneficiaryName = value;
  }

  getBeneficiaryBankName(): string {
    return this.beneficiaryBankName;
  }

  setBeneficiaryBankName(value: string) {
    this.beneficiaryBankName = value;
  }

  getBeneficiaryBankAddress(): string {
    return this.beneficiaryBankAddress;
  }

  setBeneficiaryBankAddress(value: string) {
    this.beneficiaryBankAddress = value;
  }

  getSwiftCode(): string {
    return this.swiftCode;
  }

  setSwiftCode(value: string) {
    this.swiftCode = value;
  }

  getBeneficiaryAccountNumber(): string {
    return this.beneficiaryAccountNumber;
  }

  setBeneficiaryAccountNumber(value: string) {
    this.beneficiaryAccountNumber = value;
  }

  getBeneficiaryBankCode(): string {
    return this.beneficiaryBankCode;
  }

  setBeneficiaryBankCode(value: string) {
    this.beneficiaryBankCode = value;
  }

  getBeneficiaryBranchCode(): string {
    return this.beneficiaryBranchCode;
  }

  setBeneficiaryBranchCode(value: string) {
    this.beneficiaryBranchCode = value;
  }

  getIntermediaryAccountholderName(): string {
    return this.intermediaryAccountholderName;
  }

  setIntermediaryAccountholderName(value: string) {
    this.intermediaryAccountholderName = value;
  }

  getIntermediaryBankName(): string {
    return this.intermediaryBankName;
  }

  setIntermediaryBankName(value: string) {
    this.intermediaryBankName = value;
  }

  getIntermediaryBankAddress(): string {
    return this.intermediaryBankAddress;
  }

  setIntermediaryBankAddress(value: string) {
    this.intermediaryBankAddress = value;
  }

  getIntermediarySwiftCode(): string {
    return this.intermediarySwiftCode;
  }

  setIntermediarySwiftCode(value: string) {
    this.intermediarySwiftCode = value;
  }

  getIntermediaryBankAccountNumber(): string {
    return this.intermediaryBankAccountNumber;
  }

  setIntermediaryBankAccountNumber(value: string) {
    this.intermediaryBankAccountNumber = value;
  }

  static jsonConvert(overseasBankPayment: OverseasBankPayment): OverseasBankPayment {

    overseasBankPayment = Object.assign(new OverseasBankPayment(), overseasBankPayment);

    return overseasBankPayment;
  }
}
