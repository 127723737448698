<div class="form-group section">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionLostItem.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecLossDetail.title' | translate}}</label>
            
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionLostItem.subSecLossDetail.howDamageOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="this.lostPersonalItem.getHowDamageOccurred()?.toLowerCase() !== 'others'">
                    {{this.lostPersonalItem.getHowDamageOccurredDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="this.lostPersonalItem.getHowDamageOccurred()?.toLowerCase() === 'others'">
                    {{this.lostPersonalItem.getOtherDamageOccurred()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.title' | translate}}</label>
            <ng-container *ngFor="let item of this.lostPersonalItem.getDetailsOfLostItemsArray(); let i = index;">
                <ng-container *ngIf="item.getLossType() === 'Mobile Phone'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.descOfIncident' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getLossTypeDetail()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() == 'Luggage / Trunk' 
                        || item.getLossType() == 'Suitcase / Case' 
                        || item.getLossType() == 'Backpack / Bag' 
                        || item.getLossType() == 'Handbag / Briefcase' 
                        || item.getLossType() == 'Camera' 
                        || item.getLossType() == 'Wallet'
                        || item.getLossType() == 'Earphones / Headphones'
                        || item.getLossType() == 'Chargers / Memory cards'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() == 'Watch'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() === 'Tablet'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() === 'Laptop / Notebook'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() === 'Sports equipment'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() === 'Passport / HKID / China Re-entry card' ||
                                    item.getLossType() === 'Passport / NRIC / FIN'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.costOfRepalceCost' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getCostOfRepalceCostType()}} {{item.getCostOfRepalceCost() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() === 'Personal Money'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.lostMoneyAmount' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLostMoneyType()}} {{item.getLostMoneyAmount() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() === 'Credit Card'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getLossTypeDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.unauthorisedMoneyLossHomeLabelText' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getUnauthorisedMoneyLossType()}} {{item.getUnauthorisedMoneyLoss() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="item.getLossType() === 'Others'">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.lostPersonalItem.lossItem.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getLossTypeDetail()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.otherTypeBriefDesc' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getOtherTypeBriefDesc()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionLostItem.subSecItemDetail.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="item.getDepreciationRate()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimSection.damagePersonalItem.detailsOfDamageItems.depreciationRate' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{item.getDepreciationRate()}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionLostItem.subSecReportLoss.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.detailsOfIncident.isIncidentReportedToPolice' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    <span>{{returnYesOrNo(this.policeReport.getHasReportedIncidentToPolice())}}</span>
                </div>
            </div>
            <ng-container *ngIf="this.policeReport.getHasReportedIncidentToPolice()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.detailsOfIncident.policeStationNameAdd' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.policeReport.getPoliceStation()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.detailsOfIncident.reportRefNo' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.policeReport.getPoliceReportNumber()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'domesticHelperClaim.dmhClaimTypeDetails.lossDueToDishonesty.detailsOfIncident.dateOfReport' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(this.policeReport.getDateOfReport())}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
