import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClaimMotor } from 'src/app/model/claim-motor.model';
import { UiOptionsModel } from 'src/app/ui/ui-options.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DriverDetails } from 'src/app/model/motor/driver-details.model';
import { takeUntil } from 'rxjs/operators';
import { MotorAccidentCollission } from 'src/app/model/motor/motor-accident-collission.model';
import {TransactionInfo} from '../../../../../model/transaction-info.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'app-motor-driver-detail',
    templateUrl: './motor-driver-detail.component.html',
    styleUrls: ['./motor-driver-detail.component.css']
})
export class MotorDriverDetailComponent extends ClaimsBaseComponent implements OnInit {

    driverDetailsForm: FormGroup;
    claimMotor: ClaimMotor;
    driverOptions: UiOptionsModel[] = [];
    driverDetails: DriverDetails;
    showTotalBox = false;
    collission : MotorAccidentCollission;
    transactionInfo: TransactionInfo;

    constructor(private fb: FormBuilder,
        private transactionInfoService: TransactionInfoService,
        private claimService: ClaimService,
        private sideMenuService: SideMenuService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private injector: Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimMotor = this.claim.getClaimMotor();
        this.collission = this.claimMotor.getCollission();
        this.driverDetails = this.claimMotor.getDriverDetails();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {

        this.pushGA();

        // dynamic options for who was the driver
        this.initializeDriverOptions();

        this.driverDetailsForm = this.fb.group({
            driverType: [this.collission.getDriverType()],
            driverName: [this.collission.getDriverName()],
            driverAge: [this.collission.getDriverAge()],
            wasDriverDrunk: [super.getBooleanString(this.collission.getWasDriverDrunk())],
            whoCauseTheAccident: [this.collission.getWhoCauseTheAccident()],
            otherDriverName: [this.collission.getOtherDriverName()],
            otherDriverContactNo: [this.collission.getOtherDriverContactNo()],
            contactNumber: [this.collission.getContactNumber()],
            contactEmail: [this.collission.getContactEmail()],
            breathTestResult: [this.collission.getBreathTestResult()],
            wasBreathTest: [super.getBooleanString(this.collission.getWasBreathTest())],
            firstLicense: [this.collission.getFirstLicense()],
            licenseIssueDate: [this.collission.getLicenseIssueDate()],
            hasDriverHadValidLicense:  [super.getBooleanString(this.collission.getHasDriverHadValidLicense())]
        });
    }

    initializeDriverOptions() {
        let policyholder = this.claim.getPolicyHolderName();
        let policyHolderType = this.isNotNullOrUndefinedStr(this.claim.getPolicyHolderType()) ? this.claim.getPolicyHolderType() : "" ;
        let insuredPersonsList = this.transactionInfo.getInsuredPersonsList();

        if (policyHolderType === 'P') {
            const policyHolderOption = new UiOptionsModel(policyholder, policyholder);
            this.driverOptions.push(policyHolderOption);
        }

        if (insuredPersonsList != null && insuredPersonsList.length > 0) {
            for (var i = 0; i < insuredPersonsList.length; i++) {
                let driverName = insuredPersonsList[i].getInsuredName();
                let option  = this.driverOptions.find(x => !UtilitiesService.isNullOrUndefined(x.value) && x.value !== '' &&
                    x.value.toUpperCase() === driverName.toUpperCase());
                if (UtilitiesService.isNullOrUndefined(option)) {
                   if((policyHolderType === 'P' && !UtilitiesService.isNullOrUndefined(driverName)) ||
                       (policyHolderType !== 'P' && !UtilitiesService.isNullOrUndefined(driverName) &&
                           driverName.toUpperCase() !== policyholder.toUpperCase())) {
                       const driverOption = new UiOptionsModel(driverName, driverName);
                       this.driverOptions.push(driverOption);
                   }
                }
            }
        }

        let otherOptionValue = this.translate.instant("motorClaim.driverDetails.driverInformation.driverTypes.others");
        const otherOption = new UiOptionsModel("Others", otherOptionValue);
        this.driverOptions.push(otherOption);
    }

    ngAfterViewInit() {

        this.driverDetailsForm.valueChanges.subscribe(data => {
            this.collission.setDriverType(this.driverDetailsForm.get('driverType').value);
            this.collission.setDriverTypeDesc(super.getTextInComponent('driverType'));
            this.collission.setDriverName(this.driverDetailsForm.get('driverName').value);
            this.collission.setDriverAge(this.driverDetailsForm.get('driverAge').value);
            if(this.driverDetailsForm.get('wasDriverDrunk').value != null) {
                this.collission.setWasDriverDrunk(this.driverDetailsForm.get('wasDriverDrunk').value == 'true')
              }
            if(this.driverDetailsForm.get('wasBreathTest').value != null) {
            this.collission.setWasBreathTest(this.driverDetailsForm.get('wasBreathTest').value == 'true')
            }
            if(this.driverDetailsForm.get('hasDriverHadValidLicense').value != null) {
            this.collission.setHasDriverHadValidLicense(this.driverDetailsForm.get('hasDriverHadValidLicense').value == 'true')
            }
            this.collission.setWhoCauseTheAccident(this.driverDetailsForm.get('whoCauseTheAccident').value);
            this.collission.setWhoCauseTheAccidentDesc(super.getSelectedRadioText('whoCauseTheAccident'));
            this.collission.setOtherDriverName(this.driverDetailsForm.get('otherDriverName').value);
            this.collission.setOtherDriverContactNo(this.driverDetailsForm.get('otherDriverContactNo').value);
            this.collission.setContactNumber(this.driverDetailsForm.get('contactNumber').value);
            this.collission.setContactEmail(this.driverDetailsForm.get('contactEmail').value);
            this.collission.setBreathTestResult(this.driverDetailsForm.get('breathTestResult').value);
            this.collission.setLicenseIssueDate(super.getValueInComponent('licenseIssueDate'));
            this.collission.setFirstLicense(this.driverDetailsForm.get('firstLicense').value);
            this.collission.setFirstLicenseDesc(super.getTextInComponent('firstLicense'));
        });

       // Stop validation of the other field when reason isn't other
       let self = this;
       setTimeout(function () {
           self.hideFormFieldsDriver(self.collission.getDriverType());
           self.hideFormFieldsAccident(self.collission.getWhoCauseTheAccident());
           self.hideFormFieldsLicense(self.collission.getHasDriverHadValidLicense());
           self.hideFormFieldsBreathTestQuestion(self.collission.getWasDriverDrunk());
           self.hideFormFieldsBreathTest(self.collission.getWasBreathTest());
           self.hideFormFieldsLicenceDate(self.collission.getFirstLicense()),10});


       this.driverDetailsForm.get('driverType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideFormFieldsDriver(data);

      });

       this.driverDetailsForm.get('whoCauseTheAccident').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
           this.hideFormFieldsAccident(data);

      });

      this.driverDetailsForm.get('hasDriverHadValidLicense').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.hideFormFieldsLicense(data);

     });

      this.driverDetailsForm.get('wasDriverDrunk').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
          this.hideFormFieldsBreathTestQuestion(data);

     });

     this.driverDetailsForm.get('wasBreathTest').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
         this.hideFormFieldsBreathTest(data);
    });

     this.driverDetailsForm.get('firstLicense').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
         this.hideFormFieldsLicenceDate(data);
     });
      
    }
    
    hideFormFieldsLicense(showFormFields) {
        if (String(showFormFields) == 'true') {
            this.driverDetailsForm.get('licenseIssueDate').enable({onlySelf: false, emitEvent: false});
            this.driverDetailsForm.get('firstLicense').enable({onlySelf: false, emitEvent: false});
        } else {
           this.driverDetailsForm.get('licenseIssueDate').reset();
           this.driverDetailsForm.get('firstLicense').reset();
           this.driverDetailsForm.get('licenseIssueDate').disable({onlySelf: false, emitEvent: false});
           this.driverDetailsForm.get('firstLicense').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideFormFieldsBreathTestQuestion(showFormFields) {
        if (String(showFormFields) == 'true') {
            this.driverDetailsForm.get('wasBreathTest').enable({onlySelf: false, emitEvent: false});
        } else {
           this.driverDetailsForm.get('wasBreathTest').reset();
           this.driverDetailsForm.get('wasBreathTest').disable({onlySelf: false, emitEvent: false});
           this.hideFormFieldsBreathTest(false);
        }
    }

    hideFormFieldsBreathTest(showFormFields) {
        if (String(showFormFields) == 'true') {
            this.driverDetailsForm.get('breathTestResult').enable({onlySelf: false, emitEvent: false});
        } else {
           this.driverDetailsForm.get('breathTestResult').reset();
           this.driverDetailsForm.get('breathTestResult').disable({onlySelf: false, emitEvent: false});
        }
    }


    hideFormFieldsDriver(showFormFields) {
        if (String(showFormFields) == 'Others') {
            this.driverDetailsForm.get('driverName').enable({onlySelf: false, emitEvent: false});
            this.driverDetailsForm.get('driverAge').enable({onlySelf: false, emitEvent: false});
            this.driverDetailsForm.get('contactNumber').enable({onlySelf: false, emitEvent: false});
            this.driverDetailsForm.get('contactEmail').enable({onlySelf: false, emitEvent: false});
        } else {
           this.driverDetailsForm.get('driverName').reset();
           this.driverDetailsForm.get('driverAge').reset();
           this.driverDetailsForm.get('contactNumber').reset();
           this.driverDetailsForm.get('contactEmail').reset();
           this.driverDetailsForm.get('driverName').disable({onlySelf: false, emitEvent: false});
           this.driverDetailsForm.get('driverAge').disable({onlySelf: false, emitEvent: false});
           this.driverDetailsForm.get('contactNumber').disable({onlySelf: false, emitEvent: false});
           this.driverDetailsForm.get('contactEmail').disable({onlySelf: false, emitEvent: false});
        }
    }

   hideFormFieldsAccident(showFormFields) {
       if (showFormFields && String(showFormFields).toLowerCase() == 'driver of the other vehicle') {
           this.driverDetailsForm.get('otherDriverName').enable({onlySelf: false, emitEvent: false});
           this.driverDetailsForm.get('otherDriverContactNo').enable({onlySelf: false, emitEvent: false});
       } else {
          this.driverDetailsForm.get('otherDriverName').reset();
          this.driverDetailsForm.get('otherDriverContactNo').reset();
          this.driverDetailsForm.get('otherDriverName').disable({onlySelf: false, emitEvent: false});
          this.driverDetailsForm.get('otherDriverContactNo').disable({onlySelf: false, emitEvent: false});
       }
   }

    hideFormFieldsLicenceDate(showFormFields) {
        if (String(showFormFields) == 'Less than 3 years') {
            this.driverDetailsForm.get('licenseIssueDate').enable({onlySelf: false, emitEvent: false});
        } else {
            this.driverDetailsForm.get('licenseIssueDate').reset();
            this.driverDetailsForm.get('licenseIssueDate').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 1});
            let coverType = this.claimMotor.getCoverType();

            if(coverType === "CO" || coverType === "TF" || this.isNotNullOrUndefinedStr(this.claim.getIsCoverNotePreNumbered())){
                this.router.navigate(["/claimform/collission/vehicleDamage"], {
                    relativeTo: this.activatedRoute
                });
              }else {
                this.router.navigate(["/claimform/collission/thirdPartyDetails"], {
                    relativeTo: this.activatedRoute
                });
            }
            
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.driverDetailsForm);
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    back() {
        this.router.navigate(["/claimform/collission/accidentDetails"], {
            relativeTo: this.activatedRoute
        });
    }

    // Google Analytics
    pushGA() {
        (<any>window).dataLayer.push({
            'pageStep': 'Motor Accident/Damage – Driver Details',
            'vPath': '/claim/motor-accident-damage/driver-details',
            'event': 'vpageview',
        });
    }

}
