import {LostPersonalItemDetail} from "./lost-personal-detail.model";

export class LostPersonalItem {
    
    private dateTheDamageOccurred: Date;
    private countryTheDamageOccurred: string;
    private countryTheDamageOccurredDesc: string;
    private howDamageOccurred: string;
    private howDamageOccurredDesc: string;
    private otherDamageOccurred: string;
    private placeOfLoss: string;
    private placeOfLossDesc: string;

    private hasAnotherAuthority: boolean;
    private authorityType: string;
    private authorityTypeDesc: string;
    private otherAuthorityType: string;
    private reportDate: Date;
    private hasCompensationReceived: boolean;
    private compensationCurrency: string;
    private compensationValue: string;
    private detailsOfLostItemsArray: LostPersonalItemDetail[];
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getDetailsOfLostItemsArray(): LostPersonalItemDetail[] {
        return this.detailsOfLostItemsArray;
    } 

    setDetailsOfLostItemsArray(value: LostPersonalItemDetail[]){
        this.detailsOfLostItemsArray = value;
    }

    getHasAnotherAuthority(): boolean {
        return this.hasAnotherAuthority;
    } 

    setHasAnotherAuthority(value: boolean){
        this.hasAnotherAuthority = value;
    }

    getAuthorityType(): string {
        return this.authorityType;
    } 

    setAuthorityType(value: string){
        this.authorityType = value;
    }

    getAuthorityTypeDesc(): string {
        return this.authorityTypeDesc;
    } 

    setAuthorityTypeDesc(value: string){
        this.authorityTypeDesc = value;
    }

    getOtherAuthorityType(): string {
        return this.otherAuthorityType;
    } 

    setOtherAuthorityType(value: string){
        this.otherAuthorityType = value;
    }

    getReportDate(): Date {
        return this.reportDate;
    } 

    setReportDate(value: Date){
        this.reportDate = value;
    }

    getHasCompensationReceived(): boolean {
        return this.hasCompensationReceived;
    } 

    setHasCompensationReceived(value: boolean){
        this.hasCompensationReceived = value;
    }

    getCompensationCurrency(): string {
        return this.compensationCurrency;
    } 

    setCompensationCurrency(value: string){
        this.compensationCurrency = value;
    }

    getCompensationValue(): string {
        return this.compensationValue;
    } 

    setCompensationValue(value: string){
        this.compensationValue = value;
    }

    getDateTheDamageOccurred(): Date {
        return this.dateTheDamageOccurred;
    } 

    setDateTheDamageOccurred(value: Date){
        this.dateTheDamageOccurred = value;
    }
    
    getCountryTheDamageOccurred(): string {
        return this.countryTheDamageOccurred;
    } 

    setCountryTheDamageOccurred(value: string){
        this.countryTheDamageOccurred = value;
    }

    getCountryTheDamageOccurredDesc(): string {
        return this.countryTheDamageOccurredDesc;
    }

    setCountryTheDamageOccurredDesc(value: string){
        this.countryTheDamageOccurredDesc = value;
    }

    getHowDamageOccurred(): string {
        return this.howDamageOccurred;
    } 

    setHowDamageOccurred(value: string){
        this.howDamageOccurred = value;
    }

    getHowDamageOccurredDesc(): string {
        return this.howDamageOccurredDesc;
    } 

    setHowDamageOccurredDesc(value: string){
        this.howDamageOccurredDesc = value;
    }

    getOtherDamageOccurred(): string {
        return this.otherDamageOccurred;
    } 

    setOtherDamageOccurred(value: string){
        this.otherDamageOccurred = value;
    }

    getPlaceOfLoss(): string {
        return this.placeOfLoss;
    }

    setPlaceOfLoss(value: string){
        this.placeOfLoss = value;
    }

    getPlaceOfLossDesc(): string {
        return this.placeOfLossDesc;
    }

    setPlaceOfLossDesc(value: string){
        this.placeOfLossDesc = value;
    }

    static jsonConvert(lostPersonalItem: LostPersonalItem): LostPersonalItem {


        if (lostPersonalItem.getDateTheDamageOccurred() != null) {
            lostPersonalItem.setDateTheDamageOccurred(new Date(lostPersonalItem.getDateTheDamageOccurred()));
        }

        if (lostPersonalItem.getReportDate() != null){
            lostPersonalItem.setReportDate(new Date(lostPersonalItem.getReportDate()));
        }

        if (lostPersonalItem.getDetailsOfLostItemsArray() != null) {
            let lostPersonalItemDetail: LostPersonalItemDetail[] = [];

            for (const lostPersonalItems of lostPersonalItem.getDetailsOfLostItemsArray()) {
                    let item = Object.assign(new LostPersonalItemDetail(), lostPersonalItems);
                    item = LostPersonalItemDetail.jsonConvert(item);
                    lostPersonalItemDetail.push(item);
                }
    
            lostPersonalItem.setDetailsOfLostItemsArray(lostPersonalItemDetail);
    
        }

        return lostPersonalItem;
    }
}
