import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { ClaimTypes } from "../../model/claim-type.model";
import { DocumentField } from "../../model/document-field";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class BaggageDelayFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.copyTicket', SmartqDocuments.AIRTICKET, true));
        requiredDocuments.push(new DocumentField('CARRIERCERT', 'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.carrierCert', SmartqDocuments.REPORT, true));
        if (['SGP', 'MYS'].indexOf(this.claim.getCountry()) !== -1) {
            requiredDocuments.push(new DocumentField('RSTRLTR', 'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.restoreLetter', SmartqDocuments.REPORT, true));
        }
        requiredDocuments.push(new DocumentField('RECEMERG', 'pageSummaryClaim.claimItem.sectionBaggageDelay.subSupportingDocs.receiptEmergency', SmartqDocuments.INVOICE, true));
        if(UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth())){
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}