import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { Claim } from 'src/app/model/claim.model';
import { ClaimService } from 'src/app/services/claim.service';
import { DatePipe } from '@angular/common';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { ClaimHome } from 'src/app/model/claim-home.model';
import { HomeDetails } from 'src/app/model/home/home-details.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-summary-home-items',
  templateUrl: './summary-home-items.component.html',
  styleUrls: ['./summary-home-items.component.css']
})
export class SummaryHomeItemsComponent extends ClaimsBaseComponent implements OnInit {

  claim: Claim;
  claimHome: ClaimHome;
  homeDetails: HomeDetails;
  causeTypeTitle: String;
  isBurglaryOrAccident = false;
  isBurglary = false;

  constructor(private claimService: ClaimService,
      private injector: Injector,
      private documentHelperService: DocumentHelperService,
      private transactionInfoService: TransactionInfoService) {
       
        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimHome = this.claim.getClaimHome();
        this.homeDetails = this.claim.getClaimHome().getHomeDetails();
       
        this.causeTypeTitle = this.getCauseType(this.claim.getSelectedClaimTypesList()[0]);
        if((this.causeTypeTitle == "accident")){
            this.isBurglaryOrAccident = true;
        }
        if((this.causeTypeTitle == "burglary")){
            this.isBurglary = true;
        }
  }

  ngOnInit() {

  }

  getCauseType(claimType): String {
    let temp = claimType.split("_");
    let claimTypeVal = temp[temp.length-1].toLowerCase();
    return claimTypeVal;
  }

  returnYesOrNo(value: boolean) {
      if (value) {
          return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
      } else {
          return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
      }
  }
  returnYesOrNoBoolean(value: boolean) {
      if (value) {
          return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
      } else {
          if (!value) {
              return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
          }
      }
      return '';
  }
  getDate(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
      }
      else {
          return "";
      }
  }
  getDateTime(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en\-US').transform(dt, "dd/MM/yyyy HH:mm");
      } else {
          return "";
      }
  }

    isNotNullOrUndefined(val: any){
        if(!UtilitiesService.isNullOrUndefined(val)){
            return true;
        } 
        return false;
    }

    isNotNullOrUndefinedStr(val: any){
        if(!UtilitiesService.isNullOrUndefined(val) && val != ''){
            return true;
        } 
        return false;
    }
}

