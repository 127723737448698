import {Component, Injector, OnInit} from "@angular/core";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";

@Component({
    selector: 'domestic-helper-sections-summary',
    templateUrl: './domestic-helper-sections-summary.component.html'
})
export class DomesticHelperSectionsSummaryComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;

    constructor(private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
    }

    ngOnInit() {
    }

}
