export class DeathAndSeriousBodyInjuryLoss {
    private wasStayHospitalOversea: boolean = null;
    private wasStayInIntensiveCare: boolean =  null;
    private wardType: string;
    private wardTypeDesc: string;
    private periodType: string;
    private periodTypeDesc: string;
    private periodTime: string;
    private otherTypeDesc: string;
    private wasMugging: boolean
    private natureOfIllness: string;
    private natureOfIllnessDesc: string;
    private dateTheAccidentOccurred: Date;
    private hasReceiveFollowUpTreament: boolean
    private returnHkDate: Date;
    private lastTreamentDate: Date;
    private numberOfTreatmentBill: string;
    private otherNumOfTreatmentBill:string;
    private medicalExpenseType: string;
    private medicalExpenseTypeAmount: string;
    private countryOfLoss: string;
    private countryOfLossDesc: string;
    private estimatedAmount: number;
    private placeOfLoss: string;
    private placeOfLossDesc: string;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    setCountryOfLossDesc(value: string){
        this.countryOfLossDesc = value;
    }

    getCountryOfLossDesc(): string {
        return this.countryOfLossDesc;
    }  

    setCountryOfLoss(value: string){
        this.countryOfLoss = value;
    }

    getCountryOfLoss(): string {
        return this.countryOfLoss;
    }     

    setMedicalExpenseTypeAmount(value: string){
        this.medicalExpenseTypeAmount = value;
    }

    getMedicalExpenseTypeAmount(): string {
        return this.medicalExpenseTypeAmount;
    } 

    setMedicalExpenseType(value: string){
        this.medicalExpenseType = value;
    }

    getMedicalExpenseType(): string {
        return this.medicalExpenseType;
    } 

    setOtherNumOfTreatmentBill(value: string){
        this.otherNumOfTreatmentBill = value;
    }

    getOtherNumOfTreatmentBill(): string {
        return this.otherNumOfTreatmentBill;
    } 

    setNumberOfTreatmentBill(value: string){
        this.numberOfTreatmentBill = value;
    }

    getNumberOfTreatmentBill(): string {
        return this.numberOfTreatmentBill;
    } 

    setLastTreamentDate(value: Date){
        this.lastTreamentDate = value;
    }

    getLastTreamentDate(): Date {
        return this.lastTreamentDate;
    } 

    setReturnHkDate(value: Date){
        this.returnHkDate = value;
    }

    getReturnHkDate(): Date {
        return this.returnHkDate;
    }  

    setHasReceiveFollowUpTreament(value: boolean){
        this.hasReceiveFollowUpTreament = value;
    }

    getHasReceiveFollowUpTreament(): boolean {
        return this.hasReceiveFollowUpTreament;
    }  

    setPeriodTime(value: string){
        this.periodTime = value;
    }

    getPeriodTime(): string {
        return this.periodTime;
    }  

    setPeriodType(value: string){
        this.periodType = value;
    }

    getPeriodType(): string {
        return this.periodType;
    }  

    setPeriodTypeDesc(value: string){
        this.periodTypeDesc = value;
    }

    getPeriodTypeDesc(): string {
        return this.periodTypeDesc;
    }

    setWardType(value: string){
        this.wardType = value;
    }

    getWardType(): string {
        return this.wardType;
    }  

    setWardTypeDesc(value: string){
        this.wardTypeDesc = value;
    }

    getWardTypeDesc(): string {
        return this.wardTypeDesc;
    }

    setWasStayInIntensiveCare(value: boolean){
        this.wasStayInIntensiveCare = value;
    }

    getWasStayInIntensiveCare(): boolean {
        return this.wasStayInIntensiveCare;
    }  

    setWasStayHospitalOversea(value: boolean){
        this.wasStayHospitalOversea = value;
    }

    getWasStayHospitalOversea(): boolean {
        return this.wasStayHospitalOversea;
    }  

    setDateTheAccidentOccurred(value: Date){
        this.dateTheAccidentOccurred = value;
    }

    getDateTheAccidentOccurred(): Date {
        return this.dateTheAccidentOccurred;
    }  

    setNatureOfIllness(value: string){
        this.natureOfIllness = value;
    }

    getNatureOfIllness(): string {
        return this.natureOfIllness;
    }  

    setNatureOfIllnessDesc(value: string){
        this.natureOfIllnessDesc = value;
    }

    getNatureOfIllnessDesc(): string {
        return this.natureOfIllnessDesc;
    }

    setWasMugging(value: boolean){
        this.wasMugging = value;
    }

    getWasMugging(): boolean {
        return this.wasMugging;
    }


    setOtherTypeDesc(value: string){
        this.otherTypeDesc = value;
    }

    getOtherTypeDesc(): string {
        return this.otherTypeDesc;
    }

    getPlaceOfLoss(): string {
        return this.placeOfLoss;
    }

    setPlaceOfLoss(value: string){
        this.placeOfLoss = value;
    }

    getPlaceOfLossDesc(): string {
        return this.placeOfLossDesc;
    }

    setPlaceOfLossDesc(value: string){
        this.placeOfLossDesc = value;
    }


    static jsonConvert(personalAccident: DeathAndSeriousBodyInjuryLoss): DeathAndSeriousBodyInjuryLoss {
        if (personalAccident.getDateTheAccidentOccurred() != null) {
            personalAccident.setDateTheAccidentOccurred(new Date(personalAccident.getDateTheAccidentOccurred()))
        }

        if (personalAccident.getLastTreamentDate() != null) {
            personalAccident.setLastTreamentDate(new Date(personalAccident.getLastTreamentDate()))
        }

        if (personalAccident.getReturnHkDate() != null) {
            personalAccident.setReturnHkDate(new Date(personalAccident.getReturnHkDate()))
        }

        return personalAccident;
    }
}
