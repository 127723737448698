import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-dishonesty-helper',
  templateUrl: './dishonesty-helper.component.html',
  styleUrls: ['./dishonesty-helper.component.css']
})
export class DishonestyHelperComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY]);
    }

  ngOnInit() {
  }

}
