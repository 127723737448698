
export class HospitalizationHelper {

    private dateOfAdmission: Date;
    private dateOfDischarge: Date;
    private countryIncidentOccur: string;
    private countryIncidentOccurDesc: string;
    private descOfIncident: string;
    private amountClaim: string;
    private amountClaimCurrency: string;
    private lossOfCashServiceAllowance : string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getLossOfCashServiceAllowance(): string {
        return this.lossOfCashServiceAllowance;
    }

    setLossOfCashServiceAllowance(value: string) {
        this.lossOfCashServiceAllowance = value;
    }

    getDateOfAdmission(): Date {
        return this.dateOfAdmission;
    }

    setDateOfAdmission(value: Date) {
        this.dateOfAdmission = value;
    }

    getDateOfDischarge(): Date {
        return this.dateOfDischarge;
    }

    setDateOfDischarge(value: Date) {
        this.dateOfDischarge = value;
    }

    getCountryIncidentOccur(): string {
        return this.countryIncidentOccur;
    }

    setCountryIncidentOccur(value: string) {
        this.countryIncidentOccur = value;
    }

    getCountryIncidentOccurDesc(): string {
        return this.countryIncidentOccurDesc;
    }

    setCountryIncidentOccurDesc(value: string) {
        this.countryIncidentOccurDesc = value;
    }

    getDescOfIncident(): string {
        return this.descOfIncident;
    }

    setDescOfIncident(value: string) {
        this.descOfIncident = value;
    }

    getAmountClaim(): string {
        return this.amountClaim;
    }

    setAmountClaim(value: string) {
        this.amountClaim = value;
    }

    getAmountClaimCurrency(): string {
        return this.amountClaimCurrency;
    }

    setAmountClaimCurrency(value: string) {
        this.amountClaimCurrency = value;
    }


    
    static jsonConvert(hospitalizationHelper: HospitalizationHelper): HospitalizationHelper {

        //todo: convert date fields object if any
        if (hospitalizationHelper.getDateOfAdmission() != null) {
            hospitalizationHelper.setDateOfAdmission(new Date(hospitalizationHelper.getDateOfAdmission()));
        }
        if (hospitalizationHelper.getDateOfDischarge() != null) {
            hospitalizationHelper.setDateOfDischarge(new Date(hospitalizationHelper.getDateOfDischarge()));
        }
        return hospitalizationHelper;
    }
}