<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
        <h5>{{'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{'claimSection.visitedADoctor.detailOfIllness.title' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form>
                <div class="form-group">
                    <qnect-ui #VDR01_ILLNESS_OCCURED_DATE [fieldId]="'VDR01_ILLNESS_OCCURED_DATE'" [formGroup]="deTailOfIllnessForm" name="illnessOccuredDate"></qnect-ui>
                </div>
                <div class="form-group" *ngIf="!claim.getClaimTravel().getGBAPlanType()">
                    <qnect-ui #VDR19_COUNTRY_OF_THE_LOSS_OCCURRED [fieldId]="'VDR19_COUNTRY_OF_THE_LOSS_OCCURRED'" [formGroup]="deTailOfIllnessForm" name="countryOfTheLossOccurred"></qnect-ui>
                </div>
                <div class="form-group" *ngIf="claim.getClaimTravel().getGBAPlanType()">
                    <qnect-ui #GBA_PLACE_OF_LOSS [fieldId]="'GBA_PLACE_OF_LOSS'" [formGroup]="deTailOfIllnessForm" name="placeOfLoss"
                              [dynamicLabel] ="'claimSection.visitedADoctor.detailOfIllness.whereIllnessInjuryOccurred'"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #VDR20_IS_FIRST_VISIT_FOR_THIS_CONDITION [fieldId]="'VDR20_IS_FIRST_VISIT_FOR_THIS_CONDITION'" [formGroup]="deTailOfIllnessForm" name="isFirstVisitForThisCondition"></qnect-ui>
                </div>
                <div class="form-group click-more-content" id="q1">
                    <qnect-ui #VDR02_NATURE_OF_ILLNESS [fieldId]="'VDR02_NATURE_OF_ILLNESS'" [formGroup]="deTailOfIllnessForm" name="natureOfIllness"></qnect-ui>
                </div>
                <div class="form-group click-more-content" *ngIf="visitedDoctor.getNatureOfIllness() == 'Sickness'">
                    <qnect-ui #VDR03_DIAGNOSE_TYPE [fieldId]="'VDR03_DIAGNOSE_TYPE'" [formGroup]="deTailOfIllnessForm" name="diagnoseType"></qnect-ui>

                    <qnect-ui #VDR04_OTHER_DIAGNOSE [fieldId]="'VDR04_OTHER_DIAGNOSE'" [formGroup]="deTailOfIllnessForm" name="otherDiagnose" *ngIf="visitedDoctor.getDiagnoseType() == 'Others'"></qnect-ui>
                </div>
                <div>
                    <qnect-ui #VDR07_TYPE_OF_MEDICAL_PRACTITIONER [fieldId]="'VDR07_TYPE_OF_MEDICAL_PRACTITIONER'"
                                [formGroup]="deTailOfIllnessForm" name="typeOfMedicalPractitioner"></qnect-ui>
                    <qnect-ui #VDR08_OTHER_TYPE_OF_MEDICAL_PRACTITIONER [fieldId]="'VDR08_OTHER_TYPE_OF_MEDICAL_PRACTITIONER'"
                                [formGroup]="deTailOfIllnessForm" name="otherTypeOfMedicalPractitioner"
                                [hidden]="visitedDoctor.getTypeOfMedicalPractitioner() !=='Others'"></qnect-ui>
                </div>
                <div *ngIf="visitedDoctor.getNatureOfIllness() == 'Dental'">
                    <qnect-ui #VDR24_WAS_CAUSED_BY_ACCIDENTAL_INJURY [fieldId]="'VDR24_WAS_CAUSED_BY_ACCIDENTAL_INJURY'"
                                [formGroup]="deTailOfIllnessForm" name="wasCausedByAccidentalInjury"></qnect-ui>
                    <qnect-ui #VDR21_DENTAL_TREATMENT [fieldId]="'VDR21_DENTAL_TREATMENT'"
                                [formGroup]="deTailOfIllnessForm" name="dentalTreatment"></qnect-ui>
                </div>
            </form>
        </div>
        <div class="col-xl-3 col-lg-12">
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary">{{'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn btn-primary new-btn float-right"> {{'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right"></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>