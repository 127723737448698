<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
      <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
      <p>{{ 'motorClaim.claimSection.windscreen.detailsOfRepair.description' | translate }}</p>
  </div>
    <BR>
    <div class="col-12">
        <p>{{ 'motorClaim.claimSection.windscreen.detailsOfRepair.section' | translate }}</p>
    <div *ngIf="this.windscreen.getHasSelectedMotorPart() != null && !this.windscreen.getHasSelectedMotorPart()">
        <small id="error-message">{{'motorClaim.error_messages.pleaseSelectWindow' | translate}}</small>
    </div>

    <div id="car" class="row reasonBox col-12">
        <div class="col-lg-12 col-md-12 col-12" *ngIf="this.isCnLanguage">
            <div class="wspart-invi"></div>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
            <div class="wspart-invi"></div>
        </div>

        <!-- Top Row -->
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-part invi"></div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>

        <!-- Front Windscreen-->
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[4].motorPartValue)" [class.active]="motorPartList[4].selected == true">
                <span>{{motorPartList[4].motorPartName | translate}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>

        <!-- Row  above front windscreen-->
        <div class="col-lg-12 col-md-12 col-12">
            <div class="wspart-inv1"></div>
        </div>

        <!-- Left and Right Front Window -->
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[1].motorPartValue)" [class.active]="motorPartList[1].selected == true">
                <span>{{motorPartList[1].motorPartName  | translate}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[6].motorPartValue)" [class.active]="motorPartList[6].selected == true">
                <span>{{motorPartList[6].motorPartName  | translate}}</span>
            </div>
        </div>

        <!-- Row  above Left and Right Front window-->
        <div class="col-lg-12 col-md-12 col-12">
            <div class="wspart-inv2"></div>
        </div>

        <!-- Left mid, sunroof, and right mid window -->
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[7].motorPartValue)" [class.active]="motorPartList[7].selected == true">
                <span>{{motorPartList[7].motorPartName  | translate}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[3].motorPartValue)" [class.active]="motorPartList[3].selected == true">
                <span>{{motorPartList[3].motorPartName  | translate}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[8].motorPartValue)" [class.active]="motorPartList[8].selected == true">
                <span>{{motorPartList[8].motorPartName  | translate}}</span>
            </div>
        </div>

        <!-- Row above left and right mid window-->
        <div class="col-lg-12 col-md-12 col-12">
            <div class="wspart-inv1"></div>
        </div>

        <!-- Left rear and right rear window -->
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[0].motorPartValue)" [class.active]="motorPartList[0].selected == true">
                <span>{{motorPartList[0].motorPartName  | translate}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[5].motorPartValue)" [class.active]="motorPartList[5].selected == true">
                <span>{{motorPartList[5].motorPartName  | translate}}</span>
            </div>
        </div>

        <!-- Row above left and right rear window-->
        <div class="col-lg-12 col-md-12 col-12">
            <div class="wspart-inv3"></div>
        </div>

        <!-- Left rear and right rear window -->
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-wrapper text-center toggleActive" (click)="selectMotorParts(motorPartList[2].motorPartValue)" [class.active]="motorPartList[2].selected == true">
                <span>{{motorPartList[2].motorPartName | translate}}</span>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-4">
            <div class="wspart-invi"></div>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
            <div class="wspart-invi"></div>
        </div>
    </div>
    </div>

  <div class="row col-12 ws-rep-details">
    <div class="col-xl-9 col-lg-12">
        <form>
            <ng-container *ngIf="isLodgedByCustomer">
                <div class="form-group">
                    <qnect-ui #MVA_WS_REPAIRER [fieldId]="'MVA_WS_REPAIRER'" [formGroup]="detailForm" name="repairerName" [dynamicOptions]="uiOptions"></qnect-ui>
                </div>
                <div class="form-group" *ngIf="windscreen.getRepairerName()?.toLowerCase() == 'others'">
                    <qnect-ui #MVA_WS_OTHER_REPAIRER [fieldId]="'MVA_WS_OTHER_REPAIRER'" [formGroup]="detailForm" name="otherRepairerName"></qnect-ui>
                </div>
            </ng-container>
            <div class="form-group">
                <qnect-ui #MVA_WS_REPAIR_COST [fieldId]="'MVA_WS_REPAIR_COST'" [formGroup]="detailForm" name="repairCost"></qnect-ui>
            </div>
        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

