import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UiComponent} from '../../ui.component';

@Directive({selector: '[claimsEmailValidator]'})
export class EmailValidatorDirective implements OnInit, AfterViewInit {

  @Input('claimsEmailValidator') emailRef: string;
  @Input() operator: string;
  emailComponent: UiComponent;

  constructor(private host: UiComponent) {
    this.emailComponent = host;
  }

  ngOnInit() {
    this.initializeEmailValidator();
  }

  ngAfterViewInit() {
    this.initializeEmailValidator();
  }

  initializeEmailValidator() {
    const emailComponentType = this.emailComponent.fieldConfig['type'];
    if (emailComponentType === 'textfield' || emailComponentType === 'textfield') {
      this.emailComponent.setupCustomValidatorList([this.validate()]);
      if (this.emailRef) {
        this.emailComponent.update();
      }
    } else {
        console.log('claimsEmailValidator not in dropdownselect component. UI Component validator error: ' +
        this.emailComponent.fieldId + ' - ' + this.emailComponent.fieldConfig['name']);
    }
  }

  validate = () => {
    return (c: FormControl) => {
      let isError: boolean;
      if (this.emailRef && this.emailComponent.getValue()) {
        switch (this.operator) {
          case 'from':
          isError = this.emailRef.toLowerCase() != this.emailComponent.getValue().toLowerCase();
          break;
          default: // from
            isError = this.emailRef.toLowerCase() != this.emailComponent.getValue().toLowerCase();
            break;
        }
      }

      if (isError) {
        return {'emailValidator': true};
      }
      return null;
    }
  }

}
