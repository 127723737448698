import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ClaimsBaseComponent} from "../../claim-base.component";
import {PersonalAccident} from "../../../model/home/personal-accident.model";
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimTypes } from 'src/app/model/claim-type.model';


@Component({
    selector: 'app-personal-accident-details',
    templateUrl: './personal-accident-details.component.html',
    styleUrls: ['./personal-accident-details.component.css']
})
export class PersonalAccidentDetailsComponent extends ClaimsBaseComponent implements OnInit {

    personalAccidentForm: FormGroup;
    personalAccident: PersonalAccident;
    isPaClaim = false;
    currentClaimType: string; 
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.personalAccident = this.claim.getClaimHome().getHomeDetails().getPersonalAccident();
        this.currentClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
        if(([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA]].indexOf(this.currentClaimType) > -1)){
            this.isPaClaim = true;
            this.personalAccident.setHasInjury(true);
        }
    }

    ngOnInit() {
        this.pushGAPersonalAccidentView();

        this.personalAccidentForm = this.fb.group({
            hasInjury: [super.getBooleanString(this.personalAccident.getHasInjury())],
            injuredPerson: [this.personalAccident.getInjuredPerson()],
            doctorName: [this.personalAccident.getDoctorName()],
            descIncident: [this.personalAccident.getDescOfIncident()]
        });
    }

    ngAfterViewInit() {
         this.personalAccidentForm.valueChanges.subscribe(data => {
             this.personalAccident.setInjuredPerson(this.personalAccidentForm.get('injuredPerson').value);
             this.personalAccident.setDoctorName(this.personalAccidentForm.get('doctorName').value);
             this.personalAccident.setDescOfIncident(this.personalAccidentForm.get('descIncident').value);
             if (this.personalAccidentForm.get('hasInjury').value !== null) {
                 this.personalAccident.setHasInjury(this.personalAccidentForm.get('hasInjury').value == 'true');
             }
        });

        // Stop validation of fields when not required.
        let self = this;
        setTimeout(function () {self.hideShowDoctorField(self.getBooleanString(self.personalAccident.getHasInjury())),10});
        this.personalAccidentForm.get('hasInjury').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowDoctorField(data);
        });

    }

    hideShowDoctorField(hasInjury) {
        if (hasInjury == 'true') {
            this.personalAccidentForm.get('doctorName').enable({onlySelf: false, emitEvent: false});
            this.personalAccidentForm.get('descIncident').enable({onlySelf: false, emitEvent: false});
            this.personalAccidentForm.get('injuredPerson').enable({onlySelf: false, emitEvent: false});
        } else {
            this.personalAccidentForm.get('doctorName').reset();
            this.personalAccidentForm.get('doctorName').disable({onlySelf: false, emitEvent: false});
            this.personalAccidentForm.get('descIncident').reset();
            this.personalAccidentForm.get('descIncident').disable({onlySelf: false, emitEvent: false});
            this.personalAccidentForm.get('injuredPerson').reset();
            this.personalAccidentForm.get('injuredPerson').disable({onlySelf: false, emitEvent: false});
        }
    }


    validateForm(): boolean {
        return super.validateForm(this.personalAccidentForm);
    }

    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    pushGAPersonalAccidentView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep();
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath();

        if (([ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_PA]].indexOf(this.currentClaimType) > -1)) {
            pageStep = pageStep + ' - Details of the Incident';
            vPath = vPath + '/details';
        } else {
            pageStep = pageStep + ' – Personal Accident';
            vPath = vPath + '/personal-accident';
        }

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,            
            'event': 'vpageview'
        });
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

}
