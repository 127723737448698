<div>
  <div class="prev-bar" *ngIf="hasGenericClaimType">
    <a [routerLink]="['/claimform/genericSelectClaim']" class="btn btn-secondary new-btn">
      <i class="fas fa-arrow-left"></i>&nbsp;{{'claimDetails.body.back' | translate}}&nbsp;
    </a>
  </div>
  <div class="col-12">
    <h5> {{'claimDetails.body.claimDetails' | translate}}</h5>
    <br/>
  </div>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div *ngIf="isAgent" class="form-group">
          <qnect-ui #GENERIC_DATE_OF_LOSS [fieldId]="'GENERIC_DATE_OF_LOSS'" [formGroup]="genericForm" name="dateOfLoss"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #GENERIC_COUNTRY_OCCURRENCE [fieldId]="'GENERIC_COUNTRY_OCCURRENCE'" [formGroup]="genericForm" name="countryOfOccurrence"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #GENERIC_DESCRIPTION_OF_INCIDENT [fieldId]="'GENERIC_DESCRIPTION_OF_INCIDENT'" [formGroup]="genericForm" name="descIncident"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #GENERIC_EST_AMT_LOSS [fieldId]="'GENERIC_EST_AMT_LOSS'" [formGroup]="genericForm" name="estAmountLoss"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
        &nbsp;
    </div>   
  </div>
  <div class="submit-bar">
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'claimDetails.body.save' | translate}}</a>
    &nbsp;
    <abandon-button></abandon-button>
    <button (click)="goToNext()"  class="new-btn btn btn-primary float-right">&nbsp;{{'claimDetails.body.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
