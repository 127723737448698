export class Product {

  static travelProducts = ['PIT', 'PTR', 'PPT', 'PSA'];
  static helperProducts = ['DMH', 'HPW'];

  private productType: string;
  private productTypeDesc: string;

  public getProductType(): string {
    return this.productType;
  }

  public setProductType(value: string) {
    this.productType = value;
    this.setProductValues(value);
  }

  public getProductTypeDesc(): string {
    return this.productTypeDesc;
  }

  public setProductTypeDesc(value: string) {
    this.productTypeDesc = value;
  }

  setProductValues(productType: string) {
    switch (productType) {
      case 'PIT': {
        this.productTypeDesc = 'Travel';
        break;
      }
      case 'PTR': {
        this.productTypeDesc = 'Travel';
        break;
      }
      case 'PPT': {
        this.productTypeDesc = 'Travel';
        break;
      }
      case 'PSA': {
        this.productTypeDesc = 'Travel';
        break;
      }
      case 'DMH': {
        this.productTypeDesc = 'Domestic Helper';
        break;
      }
      case 'MVA': {
        this.productTypeDesc = 'Motors';
        break;
      }
      case 'HPK': {
        this.productTypeDesc = 'Home';
        break;
      }
      case 'HHH': {
        this.productTypeDesc = 'Home';
        break;
      }
      case 'PAN': {
        this.productTypeDesc = 'Accident';
        break;
      }
      case 'PAD': {
        this.productTypeDesc = 'Accident';
        break;
      }
      case 'PAM': {
        this.productTypeDesc = 'Accident';
        break;
      }
      case 'WCA': {
        this.productTypeDesc = 'EC';
        break;
      }
      default: {
        this.productTypeDesc = 'Generic';
        break;
      }
    }
  }

  public isDomesticHelperProduct(): boolean {
    return Product.helperProducts.indexOf(this.productType.toUpperCase()) > -1;
  }
}
