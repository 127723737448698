export class DocumentResponseDisplay {

    private description : string;
    private claimType: string;
    private descriptionHtml: string;
    private isOriginal: boolean;


    getDescription(): string {
        return this.description;
    }

    setDescription(value: string) {
        this.description = value;
    }

    getClaimType(): string {
        return this.claimType;
    }

    setClaimType(value: string) {
        this.claimType = value;
    }

    getDescriptionHtml(): string {
        return this.descriptionHtml;
    }

    setDescriptionHtml(value: string) {
        this.descriptionHtml = value;
    }

    getIsOriginal(): boolean {
        return this.isOriginal;
    }

    setIsOriginal(value: boolean){
        this.isOriginal = value;
    }
}
