<div class="section form-group">
    <label class="section-title"> {{ 'pageSummaryClaim.policy'  | translate}} {{claim.getPolicyNumber()}}</label>
    <div class="section-content">
        <!--  Employer -->
        <div class="sub-section">
            <label> {{'ecClaim.employerDetails.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.employerDetails.employerName' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employerDetails.getEmployerName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.employerDetails.brn' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employerDetails.getBrn()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pagePaymentAndContact.contactInfo.telephone' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employerDetails.getTelephoneNumberCode()}} {{employerDetails.getTelephoneNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.employerDetails.email' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employerDetails.getEmailAddress()}}
                </div>
            </div>
            <div class="row" *ngIf="employerDetails.getFaxNumber()">
                <div class="col col-xs-12 col-lg-7">
                    {{'pagePaymentAndContact.contactInfo.fax' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employerDetails.getFaxNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pagePaymentAndContact.contactInfo.address' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employerDetails.getAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.employerDetails.trade' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employerDetails.getTrade()}}
                </div>
            </div>
        </div>
        <!-- Particulars of the principal contractor/holding company -->
        <div class="sub-section">
            <label>{{'ecClaim.employerDetails.particularsOfComp' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.employerDetails.isEmployerSubContractor' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(employerDetails.getIsSubContractor())}}
                </div>
            </div>
            <div class="row" *ngIf="employerDetails.getIsSubContractor() == false">
                <div class="col col-xs-12 col-lg-7">
                    {{'ecClaim.employerDetails.isEmployerSubsidiary' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(employerDetails.getIsSubsidiary())}}
                </div>
            </div>
            <ng-container *ngIf="employerDetails.getIsSubContractor() || employerDetails.getIsSubsidiary()">
                <div class="row" *ngIf="employerDetails.getContractorName()">
                    <div class="col col-xs-12 col-lg-7">
                        <span *ngIf="employerDetails.getIsSubContractor() == true">{{'ecClaim.employerDetails.contractorName' | translate}}</span>
                        <span *ngIf="employerDetails.getIsSubsidiary() == true">{{'ecClaim.employerDetails.NameOfHoldingCompany' | translate}}</span>
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employerDetails.getContractorName()}}
                    </div>
                </div>
                <div class="row" *ngIf="employerDetails.getContractorBrn()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.employerDetails.brn' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employerDetails.getContractorBrn()}}
                    </div>
                </div>
                <div class="row" *ngIf="employerDetails.getContractorTelNumber()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pagePaymentAndContact.contactInfo.telephone' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employerDetails.getContractorTelNumberCode()}} {{employerDetails.getContractorTelNumber()}}
                    </div>
                </div>
                <div class="row" *ngIf="employerDetails.getContractorEmail()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.employerDetails.email' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employerDetails.getContractorEmail()}}
                    </div>
                </div>
                <div class="row" *ngIf="employerDetails.getContractorFaxNumber()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pagePaymentAndContact.contactInfo.fax' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employerDetails.getContractorFaxNumber()}}
                    </div>
                </div>
                <div class="row" *ngIf="employerDetails.getContractorAddress()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pagePaymentAndContact.contactInfo.address' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employerDetails.getContractorAddress()}}
                    </div>
                </div>
                <div class="row" *ngIf="employerDetails.getContractorTrade()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.employerDetails.trade' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employerDetails.getContractorTrade()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
