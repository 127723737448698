import {Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimService } from 'src/app/services/claim.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimsBaseComponent } from '../../claim-base.component';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { takeUntil } from 'rxjs/operators';
import {ClaimTypes} from '../../../model/claim-type.model';
import {ClaimTypeItem} from '../../../model/claim-type-item.model';
import { PersonalAccidentHelperService } from 'src/app/services/personal-accident-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimTypeItemHelperService } from 'src/app/services/claim-type-item-helper.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
    selector: 'app-injury-details',
    templateUrl: './injury-details.component.html',
    styleUrls: ['./injury-details.component.scss']
})
export class InjuryDetailsComponent extends ClaimsBaseComponent  implements OnInit {

    showTotalBox = false;
    injuryDetailsForm: FormGroup;
    transactionInfo: TransactionInfo;
    claimPA: ClaimPersonalAccident;
    selectedBodyParts: string[] = [];
    selectedBodyPartsDesc: string[] = [];
    bodyPartsList = [];
    internalOrgan: string = '';
    internalOrganDesc: string = '';
    translateService: TranslateService;
    paHelperService: PersonalAccidentHelperService;
    selectBodyPartStatus: string = 'init';
    deleteClaimTypeModalRef: any;
    modalService: NgbModal;
    claimTypeItemHelperService: ClaimTypeItemHelperService;
    selectedClaimTypeId: number;
    hasBeenInjuredInit: boolean;
    claimTypeData: ClaimTypeItem[] = [];
    right: string = 'Right';
    left: string = 'Left';
    backBodyPartsList: string[] = ['Right Ankle', 'Left Ankle', 'Back', 'Right Elbow', 'Left Elbow'];

    @ViewChild('confirm_delete_claim_type', {static: true}) deleteClaimTypeModal: TemplateRef<any>;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private claimService: ClaimService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.translateService = this.injector.get(TranslateService);
        this.paHelperService = this.injector.get(PersonalAccidentHelperService);
        this.modalService = this.injector.get(NgbModal);
        this.claimTypeItemHelperService = this.injector.get(ClaimTypeItemHelperService);
        this.hasBeenInjuredInit = this.claimPA.getInjuryDetails().getHasBeenInjured();
        this.claimTypeData = this.transactionInfo.getClaimTypeItemList();
        this.right = this.translate.instant('paClaim.injuryDetails.right');
        this.left = this.translate.instant('paClaim.injuryDetails.left');
    }

    ngOnInit() {
        this.pushGAView();
        this.injuryDetailsForm = this.fb.group({
            hasBeenInjured: [super.getBooleanString(this.claimPA.getInjuryDetails().getHasBeenInjured())],
            natureInjury: [this.claimPA.getInjuryDetails().getNatureInjury()],
            natureInjuryDesc: [this.claimPA.getInjuryDetails().getNatureInjuryDesc()],
            natureInjuryOthers: [this.claimPA.getInjuryDetails().getNatureInjuryOthers()],
            hasEncounteredSimilarIncident: [super.getBooleanString(this.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident())],
            isInternalInjury: [super.getBooleanString(this.claimPA.getInjuryDetails().getIsInternalInjury())],
            placeOfIncident: [this.claimPA.getInjuryDetails().getPlaceOfIncident()]
        });
    }

    ngAfterViewInit() {
        this.injuryDetailsForm.valueChanges.subscribe(data => {
            if (this.injuryDetailsForm.get('hasBeenInjured').value !== null) {
                this.claimPA.getInjuryDetails().setHasBeenInjured(this.injuryDetailsForm.get('hasBeenInjured').value === 'true');
            }
            if (!UtilitiesService.isNullOrUndefined(this.selectedBodyParts) && this.selectedBodyParts.length > 0) {
                this.selectedBodyParts.sort((a, b) => a.localeCompare(b));
                this.claimPA.getInjuryDetails().setInjuredPartsList(this.selectedBodyParts);
            }
            if (!UtilitiesService.isNullOrUndefined(this.selectedBodyPartsDesc) && this.selectedBodyPartsDesc.length > 0) {
                this.selectedBodyPartsDesc.sort((a, b) => a.localeCompare(b));
                this.claimPA.getInjuryDetails().setInjuredPartsDescList(this.selectedBodyPartsDesc);
            }
            this.claimPA.getInjuryDetails().setNatureInjury(super.getValueInComponent('natureInjury'));
            if (this.injuryDetailsForm.get('natureInjury').value !== null) {
                this.claimPA.getInjuryDetails().setNatureInjuryDesc(super.getTextInComponent('natureInjury'));
            } else {
                this.claimPA.getInjuryDetails().setNatureInjuryDesc("");
            }
            this.claimPA.getInjuryDetails().setNatureInjuryOthers(super.getValueInComponent('natureInjuryOthers'));
            this.claimPA.getInjuryDetails().setIsInternalInjury(this.injuryDetailsForm.get('isInternalInjury')?.value === 'true');
            this.claimPA.getInjuryDetails().setHasEncounteredSimilarIncident(this.injuryDetailsForm.get('hasEncounteredSimilarIncident')?.value === 'true');
            this.claimPA.getInjuryDetails().setPlaceOfIncident(super.getValueInComponent('placeOfIncident'));
        });

        let self = this;

       // Stop validation of fields when not required.
        setTimeout(function () {
            self.hideAllFields(self.getBooleanString(self.claimPA.getInjuryDetails().getHasBeenInjured()))},10);
            this.injuryDetailsForm.get('hasBeenInjured').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideAllFields(data);
        });
   
       setTimeout(function () {
            self.hideFields(self.getBooleanString(self.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident()))},10);
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideFields(data);
       });

       
       setTimeout(function () {
            self.hideShowOthersField(self.claimPA.getInjuryDetails().getNatureInjury())},10);
            this.injuryDetailsForm.get('natureInjury').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.hideShowOthersField(data);
       });

       setTimeout(function () {
            self.checkForInternalDamage(self.getBooleanString(self.claimPA.getInjuryDetails().getIsInternalInjury()))},10);
            this.injuryDetailsForm.get('isInternalInjury').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                this.checkForInternalDamage(data);
       });
    }

    hideAllFields(hasbeenInjured) {
        if (hasbeenInjured === "true") {
            this.injuryDetailsForm.get('natureInjury').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('natureInjuryOthers').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('placeOfIncident').enable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('isInternalInjury').enable({onlySelf: false, emitEvent: false});

            this.selectBodyPartStatus = 'init';
            this.buildExistingInjuredBodyList();
            this.setRightLeftLabel();
        } else {
            this.injuryDetailsForm.get('natureInjury').reset();
            this.injuryDetailsForm.get('natureInjury').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('natureInjuryOthers').reset();
            this.injuryDetailsForm.get('natureInjuryOthers').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').reset();
            this.injuryDetailsForm.get('hasEncounteredSimilarIncident').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('placeOfIncident').reset();
            this.injuryDetailsForm.get('placeOfIncident').disable({onlySelf: false, emitEvent: false});
            this.injuryDetailsForm.get('isInternalInjury').reset();
            this.injuryDetailsForm.get('isInternalInjury').disable({onlySelf: false, emitEvent: false});

            this.selectBodyPartStatus = 'none';
            this.resetSelectedBodyParts();
        }
    }

    hideShowOthersField(natureInjury) {

        if (natureInjury && this.showOthers(natureInjury)) {
            this.injuryDetailsForm.get('natureInjuryOthers').enable({onlySelf: false, emitEvent: false});
        } else {
            this.injuryDetailsForm.get('natureInjuryOthers').reset();
            this.injuryDetailsForm.get('natureInjuryOthers').disable({onlySelf: false, emitEvent: false});
        }
    }
    
    showOthers(val: string): boolean {
        if(['Other', 'Multiple'].indexOf(val) != -1){
            return true;
        }
        return false;
    }

    hideFields(hasExperienced) {
        if (hasExperienced === "true") {
            this.injuryDetailsForm.get('placeOfIncident').enable({onlySelf: false, emitEvent: false});
        } else {
            this.injuryDetailsForm.get('placeOfIncident').reset();
            this.injuryDetailsForm.get('placeOfIncident').disable({onlySelf: false, emitEvent: false});
        }
    }

    checkForInternalDamage(isInternalDamage) {
        if (isInternalDamage === "true") {
            this.addInternalDamage();
        } else {
            this.removeInternalDamage();
        }
    }

    addInternalDamage() {
        if (!this.exists(this.internalOrgan, this.selectedBodyParts)) {
            this.selectedBodyParts.push(this.internalOrgan);
            this.selectedBodyPartsDesc.push(this.internalOrganDesc);
        }
    }

    removeInternalDamage() {
        if (this.exists(this.internalOrgan, this.selectedBodyParts)) {
            this.selectedBodyParts.splice(this.selectedBodyParts.indexOf(this.internalOrgan), 1);
            this.selectedBodyPartsDesc.splice(this.selectedBodyPartsDesc.indexOf(this.internalOrganDesc), 1);
        }
    }

    goToNext() {
        this.setEligibleClaimTypes();
        this.checkSelectBodyPartStatus();
        if (this.validateForm()) {
            if (this.isRemoveCurrentClaimType()) {
                if (this.hasBeenInjuredInit) {
                    this.openDeleteModal();
                } else {
                    this.deleteClaimType();
                }
            } else {
                this.sideMenuService.emitComplete('finishInjuryDetails');
                this.sideMenuService.emitProcess(1, 0);
                this.transactionInfo.setIsClaimDetailsLabel(!this.claimPA.getHasEligibleClaimTypes());
                this.goToNextPage();
            }
        }
    }

    goToNextPage() {
        if ((this.claimPA.getInjuryDetails().getHasBeenInjured() && this.selectBodyPartStatus === 'selected') ||
            !this.claimPA.getInjuryDetails().getHasBeenInjured()) {
            if (!this.claimPA.getHasEligibleClaimTypes()) {
                this.goToOthersClaimDetails();
            } else {
                this.transactionInfo.setIsClaimDetailsLabel(false);
                this.sideMenuService.emitSelectClaimType(true);
                this.router.navigate(['/claimform/claimDetail'], {
                    relativeTo: this.activatedRoute
                });
            }
        }
    }

    goToOthersClaimDetails() {
        this.sideMenuService.emitSelectClaimType(false);
        let chooseIndex:number = 2;
        let selectedClaimCode:string = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS];
        let claimTypeDetailMenu = this.transactionInfo.getNavigationMenu().getClaimDetailMenu();
        const selectedClaimItemList: string[] = this.claim.getSelectedClaimTypesList();

        let claimTypeSelected =  this.claimTypeData.find(claimTypeItem =>
            claimTypeItem.claimTypeCode === selectedClaimCode);
        if(!UtilitiesService.isNullOrUndefined(claimTypeSelected)){
            claimTypeSelected.selected = true;
            if(selectedClaimItemList.indexOf(claimTypeSelected.claimTypeCode) < 0){
                selectedClaimItemList.push(claimTypeSelected.claimTypeCode);
            }
            this.transactionInfo.setGaPageStep(claimTypeSelected.gaPageStep);
            this.transactionInfo.setGaVPath(claimTypeSelected.gaVPath);
        }

        if (Object.keys(claimTypeDetailMenu[chooseIndex]['subMenus']).length !== 0) {
            this.claim.setSelectedClaimTypesList(selectedClaimItemList);
            this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(this.claimTypeData);
            this.sideMenuService.emitCliamType(chooseIndex);
            this.sideMenuService.emitComplete('finishDetail');
            this.sideMenuService.emitProcess(0, 4);

            let nextLinkUrl: string = claimTypeDetailMenu[chooseIndex]['subMenus'][0]['url'];
            this.router.navigate([nextLinkUrl]);

        }
    }

    validateForm(): boolean {
        return super.validateForm(this.injuryDetailsForm);
    }

    back() {
        this.router.navigate(["/claimform/paAccidentDetails"], {
            relativeTo: this.activatedRoute
        });
    }

    selectBodyPart(id: string) {
        let bodyPart = this.paHelperService.geBodyPartById(id);
        let name = '';
        let desc = '';
        if (!UtilitiesService.isNullOrUndefined(bodyPart)) {
            name = this.translate.instant(bodyPart.name);
            desc = bodyPart.description;
        }

        if (this.exists(name, this.selectedBodyParts)) {
            this.selectedBodyParts.splice(this.selectedBodyParts.indexOf(name), 1);
            this.selectedBodyPartsDesc.splice(this.selectedBodyPartsDesc.indexOf(desc), 1);
            this.highlightSelectedBodyPart(id, 'none')
        } else {
            this.selectedBodyParts.push(name);
            this.selectedBodyPartsDesc.push(desc);
            this.highlightSelectedBodyPart(id, '#00a8f3');
        }

        if (this.selectedBodyParts.length > 0) {
            this.selectBodyPartStatus = 'selected';
        } else {
            this.selectBodyPartStatus = 'none';
        }
    }

    exists(name: string, selected: string[]) {
        let isExists: boolean = false;
        if (!UtilitiesService.isNullOrUndefined(selected)) {
            isExists = selected.indexOf(name) > -1;
        }
        return isExists;
    }

    buildExistingInjuredBodyList() {
        this.bodyPartsList = this.paHelperService.getInjuredBodyParts();
        this.selectedBodyParts = this.claimPA.getInjuryDetails().getInjuredPartsList();
        this.selectedBodyPartsDesc = this.claimPA.getInjuryDetails().getInjuredPartsDescList();
        if (!UtilitiesService.isNullOrUndefined(this.selectedBodyPartsDesc) && this.selectedBodyPartsDesc.length > 0) {
            this.selectBodyPartStatus = 'selected';
            for (let injuredBodyPart of this.selectedBodyPartsDesc) {
                for(let bodyPart of this.bodyPartsList){
                    if(injuredBodyPart === bodyPart['description']) {
                        this.highlightSelectedBodyPart(bodyPart['id'], '#00a8f3');
                    }
                }
            }
        } else {
            this.resetSelectedBodyParts();
        }

        let internalOrgan = this.paHelperService.geBodyPartById('internal-organs');
        if (!UtilitiesService.isNullOrUndefined(internalOrgan)) {
            this.internalOrgan = this.translate.instant(internalOrgan.name);
            this.internalOrganDesc = internalOrgan.description;
        }
    }

    highlightSelectedBodyPart(id: string, fillColor: string) {
        let pathId = '.back-container svg #' + id + ' path';
        if ($('#frontDiv').css('display') ==='block') {
            pathId = '.front-container svg #' + id + ' path';
        }
        $(pathId).css('fill', fillColor);
        $(pathId).css('fill-opacity', 1);
    }
    

    setEligibleClaimTypes() {
        // set flag to determine if user has eligible claim type aside from others
        let hasPAClaimTypes = false;

        if (this.claimPA.getInjuryDetails().getHasBeenInjured()) {
            hasPAClaimTypes = this.claimTypeData.filter(y => y.show === true &&
                y.claimTypeCode.indexOf("OTHERS") === -1).length > 0;
        }

        this.claimPA.setHasEligibleClaimTypes(hasPAClaimTypes);
    }

    checkSelectBodyPartStatus() {
        if (this.claimPA.getInjuryDetails().getHasBeenInjured() && this.selectBodyPartStatus === 'init') {
            this.selectBodyPartStatus = 'none';
        }
    }
    
    isRemoveCurrentClaimType() {
        //check if there is any selected claimtype, remove them
        let hasBeenInjuredForm = this.injuryDetailsForm.get('hasBeenInjured').value;
        let selectedClaimCode: string = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS];
        let claimTypeSelected: ClaimTypeItem = this.claimTypeData.find(claimTypeItem =>
            claimTypeItem.selected === true);

        if ((!this.claimPA.getHasEligibleClaimTypes() || hasBeenInjuredForm === 'false') &&
            (!UtilitiesService.isNullOrUndefined(claimTypeSelected) && claimTypeSelected.claimTypeCode === selectedClaimCode)) {
            return false;
        } else if (hasBeenInjuredForm !== super.getBooleanString(this.hasBeenInjuredInit) && !UtilitiesService.isNullOrUndefined(claimTypeSelected)) {
            this.selectedClaimTypeId = claimTypeSelected.claimTypeId;
            return true;
        }

        return false;
    }

    openDeleteModal() {
        this.deleteClaimTypeModalRef = this.modalService.open(this.deleteClaimTypeModal, {size: 'sm',
            backdrop: 'static',
            keyboard: false
        });
    }

    deleteClaimType() {
        this.claimTypeItemHelperService.deleteClaimType(this.selectedClaimTypeId);
        if (this.hasBeenInjuredInit) {
            this.deleteClaimTypeModalRef.close();
        }
        this.transactionInfo.setIsClaimDetailsLabel(!this.claimPA.getHasEligibleClaimTypes());

        setTimeout(() => {
            this.sideMenuService.emitComplete('finishInjuryDetails');
            this.sideMenuService.emitProcess(1, 0);
            if (this.claimPA.getInjuryDetails().getHasBeenInjured()) {
                this.transactionInfo.setIsClaimDetailsLabel(false);
                this.sideMenuService.emitSelectClaimType(true);
                let url = "/claimform/claimDetail";
                this.router.navigate([url], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.goToOthersClaimDetails();
            }
        }, 100);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    pushGAView() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Injury Details',
            'vPath': '/injury-details',
            'event': 'vpageview',
        });
    }

    flipImage() {
        const modelWrapper = document.querySelector('.model');
        if (modelWrapper.classList.contains('front')) {
            modelWrapper.classList.remove('front');
            modelWrapper.classList.add('back');

            $('#backDiv').css('display', 'block');
            $('#frontDiv').css('display', 'none');

            this.setSelectedBackBodyParts();
        } else {
            modelWrapper.classList.remove('back');
            modelWrapper.classList.add('front');

            $('#backDiv').css('display', 'none');
            $('#frontDiv').css('display', 'block');
        }
        
    }

    resetSelectedBodyParts() {
        this.selectedBodyParts = [];
        this.selectedBodyPartsDesc = [];
        this.claimPA.getInjuryDetails().setInjuredPartsList([]);
        this.claimPA.getInjuryDetails().setInjuredPartsDescList([]);
    }

    setSelectedBackBodyParts() {
        //highlight back parts if previously selected
        let selectedBodyPartsDesc = this.claimPA.getInjuryDetails().getInjuredPartsDescList();
        if (!UtilitiesService.isNullOrUndefined(selectedBodyPartsDesc) && selectedBodyPartsDesc.length > 0) {
            for(let desc of this.selectedBodyPartsDesc){
                if (this.backBodyPartsList.indexOf(desc) > -1) {
                    let backPart = this.paHelperService.geBodyPartByDesc(desc);
                    if (!UtilitiesService.isNullOrUndefined(backPart)) {
                        $('.back-container svg #' + backPart.id + ' path').css('fill', '#00a8f3');
                    }
                }
            }
        }
    }

    setRightLeftLabel() {
        let self = this;
        setTimeout(function () {
            let bodyPartDiv = document.getElementById('bodyPartDiv');
            if (!UtilitiesService.isNullOrUndefined(bodyPartDiv)) {
                bodyPartDiv.setAttribute('data-right', self.right);
                bodyPartDiv.setAttribute('data-left', self.left);
            }}
        ,10);
    }
}
