import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-front-view-tab',
  templateUrl: './front-view-tab.component.html'
})

@Injectable()
export class FrontViewTabComponent {

  @Output() emitFrontViewTabOnClickArea = new EventEmitter<any>();
  frontViewTabOnClickArea(e: any) {
    this.emitFrontViewTabOnClickArea.emit(e);
  }

  @Output() emitFrontViewTabShowTooltip = new EventEmitter<any>();
  frontViewTabShowTooltip(txt: any) {
    this.emitFrontViewTabShowTooltip.emit(txt);
  }

  @Output() emitFrontViewTabHideToolTip = new EventEmitter<any>();
  frontViewTabHideToolTip() {
    this.emitFrontViewTabHideToolTip.emit();
  }

  @Input() frontViewTabGender: any;

  @Input() frontViewTabSelectHead: any;

  @Input() frontViewTabTemp: any;

  isSelected(id: string) {
    return this.frontViewTabTemp.some((obj: any) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }
}
