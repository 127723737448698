<!-- MALE -->
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-head'" class="_modal"> 
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button> 
    <h3>{{checkAreaExists('back-head') || checkAreaExists('head') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>  
    <span class="span_area">{{ 'ecClaim.injuryDetails.backHead' | translate }}</span>
    <div class="modal_select_container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="3 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="3" y="10" xlink:href="assets/images/body-parts/male/back/male-back-head.png"></image>
        <g id="right-temple" (click)="backViewModalOnClickSpecificArea('right-temple', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightTemple')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector" d="M199.291 123.93C199.151 123.96 199.011 123.97 198.901 123.93C198.031 123.56 197.861 120.39 199.301 118.14C200.091 116.9 200.761 116.91 202.681 114.59C203.821 113.22 204.121 112.56 204.201 112.18C204.221 112.06 204.231 111.97 204.231 111.89C204.201 110.44 202.631 110.03 201.941 108.57C200.971 106.53 202.471 104.05 203.371 102.26C204.491 100.06 208.861 91.4597 206.801 84.8297C206.131 82.6597 204.721 80.5297 204.291 78.7197C204.321 78.8597 204.361 79.0097 204.411 79.1597H194.791V142.97L199.291 123.93Z" [attr.fill]="changeColor(isSelected('right-temple'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-cheek" (click)="backViewModalOnClickSpecificArea('right-cheek', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightCheek')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_2" d="M190.91 173.329L188.76 168.409L183.84 166.189V201.929C183.84 201.929 183.86 201.959 183.9 202.019L185.45 198.009C185.12 198.959 184.83 199.689 184.61 200.229C185.38 198.379 186.93 194.179 188.49 186.389C189.17 182.989 189.93 178.529 190.47 173.229C190.85 173.319 191.23 173.409 191.61 173.499C191.38 173.449 191.15 173.389 190.92 173.329H190.91Z" [attr.fill]="changeColor(isSelected('right-cheek'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-cheek" (click)="backViewModalOnClickSpecificArea('left-cheek', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftCheek')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_3" d="M70.1605 171.1L67.5605 173.02C67.6405 172.97 67.7305 172.92 67.8005 172.87C67.8405 173.94 67.9305 175.51 68.1405 177.4C68.4805 180.36 68.9505 182.5 69.2305 183.82C71.4505 194.18 70.9105 194.72 72.3305 198.38C72.8005 199.59 73.4905 201.13 74.4905 202.88V168.64L70.1605 171.09V171.1Z" [attr.fill]="changeColor(isSelected('left-cheek'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-temple" (click)="backViewModalOnClickSpecificArea('left-temple', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftTemple')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_4" d="M55.1301 107.819C55.5401 109.309 53.4701 110.879 53.8701 111.369C54.2401 111.819 55.9801 110.549 56.5601 111.079C56.7801 111.279 56.7601 111.689 56.6401 112.169C56.3501 113.339 55.4401 114.979 55.8701 115.319C56.3601 115.709 57.8501 113.839 58.7901 114.169C59.5801 114.449 60.1401 116.309 59.1901 123.229H58.5701L59.0401 123.919L63.5101 142.909L63.5301 142.899V79.1494L50.7201 79.1094C50.5201 79.3794 50.4101 79.6394 50.4701 79.8994C50.6701 80.6994 52.3201 80.3794 52.9301 81.3894C53.5701 82.4494 52.3301 83.7494 51.3201 86.4294C51.1801 86.8194 50.0301 89.9694 50.2901 93.7094C50.4201 95.5694 50.5901 98.0794 52.1201 98.8694C53.6301 99.6494 55.4701 98.2494 56.0201 98.9794C56.4201 99.5094 55.7801 100.639 55.1001 101.849C53.7901 104.179 52.7401 104.649 52.9201 105.579C53.1401 106.689 54.7701 106.599 55.1001 107.819H55.1301Z" [attr.fill]="changeColor(isSelected('left-temple'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-ear" (click)="backViewModalOnClickSpecificArea('left-ear', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftEar')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_5" d="M59.0505 123.93L58.5806 123.24H57.1405C56.8905 122.58 56.3706 121.47 55.3106 120.49C54.8606 120.07 54.3806 119.63 53.6506 119.4C51.8706 118.84 50.1706 119.96 49.8106 120.2C48.3506 121.17 47.6805 122.6 47.3505 123.47C44.8905 129.77 46.3806 140.26 46.4906 141.01C47.1806 145.63 47.6205 148.6 49.0705 152.59C50.3805 156.2 52.2606 159.48 55.9506 165.89C57.6806 168.9 59.1805 171.38 60.2505 173.11C60.8005 173.59 61.7405 174.23 63.0005 174.37C64.2705 174.51 65.3105 174.08 66.2705 173.68C66.8105 173.46 67.2406 173.22 67.5806 173.02L70.1805 171.1L59.0705 123.93H59.0505Z" [attr.fill]="changeColor(isSelected('left-ear'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-ear" (click)="backViewModalOnClickSpecificArea('right-ear', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightEar')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_6" d="M207.41 155.51C209.6 151.11 210.55 147.49 210.76 146.65C211.78 142.64 211.9 139.62 212.05 135.73C212.14 133.51 211.88 130.87 211.36 125.58C211.28 124.72 211.09 122.99 209.9 121.37C209.45 120.76 208.67 119.73 207.23 119.31C206.98 119.24 205.47 118.82 204.05 119.57C203.15 120.04 203.13 120.53 201.9 121.81C201.05 122.69 200 123.77 199.28 123.93L188.76 168.41L190.91 173.33C191.38 173.44 191.84 173.55 192.28 173.66C194.68 174.23 194.94 174.29 195.29 174.26C196.62 174.16 197.6 172.96 198.47 171.68C200.6 168.57 201.49 166 202.34 164.03C204.19 159.75 204.91 160.54 207.41 155.52V155.51Z" [attr.fill]="changeColor(isSelected('right-ear'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-head" (click)="backViewModalOnClickSpecificArea('back-of-the-head', 'back-head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_7" d="M183.851 79.1499V44.4199H74.481V79.1499H63.541V97.7299V142.91L70.161 171.1L74.491 168.65L79.221 171.51L100.541 180.62L119.121 185.09L134.491 185.78L152.141 183.37L169.331 177.87L177.931 171.51L183.851 166.19L188.771 168.41L194.791 142.97V97.7299V79.1499H183.851Z" [attr.fill]="changeColor(isSelected('back-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-of-the-head" (click)="backViewModalOnClickSpecificArea('left-of-the-head', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_8" d="M74.4805 32.2402C74.4805 32.2402 74.4605 32.2502 74.4505 32.2602C73.9305 32.7302 74.3105 33.1902 73.7005 34.1502C73.6905 34.1702 73.6705 34.2002 73.6405 34.2402C73.4505 34.5202 72.9305 35.2102 72.0305 35.5902C71.1305 35.9702 70.8505 35.6202 70.3705 35.9902C69.5605 36.6202 70.1905 37.7802 69.4005 38.5102C68.7305 39.1302 68.0305 38.5202 67.2205 39.1402C66.4405 39.7302 66.8305 40.4702 66.0205 41.7202C65.3305 42.7802 64.8505 42.5502 64.1305 43.5502C63.5005 44.4202 63.7805 44.7102 63.1005 46.8702C62.5105 48.7302 62.1405 49.8802 61.1505 50.3102C60.5805 50.5602 60.3005 50.3602 59.8905 50.7102C59.1305 51.3602 59.6705 52.4202 59.0305 52.9502C58.3305 53.5202 57.3005 52.5902 56.5105 53.1802C55.9305 53.6102 55.9005 54.5502 55.8805 55.2402C55.8405 56.7002 56.4505 57.0902 56.1105 57.8802C56.0305 58.0802 56.0105 58.0102 54.6805 59.2602C53.8705 60.0202 53.6005 60.3002 53.5305 60.7502C53.4505 61.2402 53.7105 61.3602 53.5905 61.7202C53.3305 62.5102 51.9205 62.5502 51.9305 62.8702C51.9305 63.2202 53.6105 63.1502 53.8205 63.7902C54.0205 64.3902 52.7305 64.9402 51.9305 66.3702C51.4505 67.2302 51.6005 67.6002 51.3005 69.6902C51.2405 70.1302 51.1705 70.5202 51.1005 70.8902C50.5805 73.7502 50.0005 74.6902 50.6605 75.4802C51.3605 76.3102 52.5905 75.9302 52.7805 76.5702C53.0105 77.3302 51.3905 78.2302 50.7305 79.1102H74.4705V32.2402H74.4805Z" [attr.fill]="changeColor(isSelected('left-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-of-the-head" (click)="backViewModalOnClickSpecificArea('right-of-the-head', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_9" d="M205.721 69.6397C205.751 68.5097 204.581 68.4597 204.061 66.9497C203.441 65.1497 204.621 63.8797 203.831 63.0497C203.171 62.3597 202.001 62.8997 201.711 62.2997C201.351 61.5797 202.951 60.5797 202.631 59.4897C202.431 58.8197 201.731 58.8697 201.201 58.2297C200.171 56.9897 201.621 55.4397 201.371 53.2397C201.051 50.4397 198.421 50.5497 196.671 46.2497C196.021 44.6597 196.161 44.1097 195.351 43.2097C193.961 41.6697 192.631 42.2597 191.281 40.6897C190.111 39.3297 190.481 38.1597 189.331 37.6497C188.321 37.1997 187.271 37.7497 186.921 37.1897C186.621 36.7097 187.231 36.0697 186.921 35.6997C186.571 35.2797 185.591 35.8697 185.091 35.5997C185.051 35.5797 185.011 35.5597 184.981 35.5297C184.931 35.4797 184.901 35.4197 184.881 35.3597C184.661 34.7997 185.371 33.7297 184.921 33.0697C184.701 32.7497 184.311 32.7997 183.861 32.7997V79.1597H204.421C204.111 78.1397 204.051 77.2097 204.531 76.4097C204.841 75.8997 205.741 74.8397 205.451 73.7197C205.271 73.0297 204.801 72.9597 204.701 72.2897C204.611 71.7097 204.901 71.3197 205.191 70.9097C205.451 70.5497 205.721 70.1797 205.731 69.6497L205.721 69.6397Z" [attr.fill]="changeColor(isSelected('right-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="top-of-the-head" (click)="backViewModalOnClickSpecificArea('top-of-the-head', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.topOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_10" d="M183.85 32.7898C183.55 32.7898 183.23 32.7698 182.91 32.5998C181.94 32.0998 181.87 30.8398 181.53 29.7898C180.5 26.5198 176.42 23.7398 173.96 24.3398C173.19 24.5298 172.62 25.0398 171.21 25.1398C170.64 25.1798 169.55 25.2598 169.26 24.7398C168.85 23.9898 170.5 22.6998 170.12 22.0998C169.73 21.4798 167.71 22.4298 167.2 21.6998C166.84 21.1898 167.6 20.3898 167.26 19.9798C166.75 19.3598 164.8 20.9198 163.53 20.1498C162.68 19.6398 163.14 18.6798 162.27 18.0898C161.18 17.3398 159.96 18.5198 158.14 17.9198C157.28 17.6298 157.12 17.2198 156.36 16.7198C154.14 15.2298 152.39 16.6598 147.88 16.1998C144.8 15.8898 144.9 15.1498 142.43 15.2298C140.38 15.2998 139.5 15.8298 137.67 15.3398C136.91 15.1398 136.27 14.8298 135.15 14.8198C134.15 14.8198 133.87 15.0498 133.03 14.9898C132.14 14.9298 131.66 14.6198 130.45 14.0198C128.42 13.0098 127.4 12.4998 126.44 12.3598C125.26 12.1798 124.37 12.4198 122.71 12.8798C120.43 13.4998 120.16 14.0098 118.47 14.5398C115.3 15.5398 114.99 14.1398 112.11 15.0598C109.43 15.9098 108.78 17.3998 106.61 17.1798C105.75 17.0898 105.27 16.7998 104.32 17.0098C103.05 17.2898 102.87 18.0298 101.63 18.2098C100.46 18.3898 100.28 17.7798 99.3405 18.0398C98.1105 18.3698 98.2005 19.4498 96.9305 19.8698C95.8205 20.2398 95.4605 19.5098 94.4105 19.9298C93.4505 20.3098 93.4805 21.0198 92.2905 21.8198C91.5405 22.3198 91.0805 22.3398 90.3405 22.6198C87.4805 23.6998 87.6305 25.9798 85.1205 26.7998C83.4505 27.3498 82.9805 26.4698 81.9105 27.1998C80.4805 28.1698 80.8905 30.0198 79.3305 31.1598C78.6405 31.6598 77.7305 31.7498 77.0905 31.8498C75.6505 32.0698 75.0205 31.7598 74.4805 32.2298V44.3998H183.86V32.7798L183.85 32.7898Z" [attr.fill]="changeColor(isSelected('top-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectHead"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-head') || checkAreaExists('head') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" stroke="white" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-neck-trunk'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button> 
    <h3>{{checkAreaExists('neck-trunk') || checkAreaExists('back-neck-trunk') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backNeckTrunk' | translate }}</span>
    <div class="modal_select_container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-neck-trunk.png"></image>
        <g id="back-of-the-neck" (click)="backViewModalOnClickSpecificArea('back-of-the-neck', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheNeck')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector" d="M107.209 47.07C106.299 47.86 105.529 48.4 104.569 49.04C102.239 50.59 101.089 51.38 99.4887 52.23C97.0587 53.52 95.0887 54.22 93.8187 54.67C93.1987 54.89 92.6087 55.08 92.0387 55.25L91.9088 55.48L91.7188 55.9H159.869L159.659 55.48C156.329 54.43 153.879 53.21 152.699 52.58C152.149 52.29 150.889 51.51 148.359 49.96C145.719 48.34 144.789 47.71 143.719 46.63C142.399 45.3 141.869 44.3 141.629 43.78C141.129 42.72 140.929 41.75 140.849 41.08C140.909 38.74 140.979 36.4 141.039 34.06V30.93L141.789 29.77V19.29L140.049 20.85L137.529 22.72L132.489 24.33L127.309 25.04L122.799 24.84L117.349 23.53L111.089 20.86L109.699 20.02V30.07C109.839 30.32 109.999 30.58 110.189 30.85C110.209 30.92 110.229 31 110.259 31.07C110.319 31.14 110.379 31.2 110.439 31.27C110.439 31.29 110.449 31.31 110.459 31.33L110.579 42.04C110.479 42.49 110.289 43.15 109.919 43.87C109.699 44.31 109.089 45.43 107.199 47.08L107.209 47.07Z" [attr.fill]="changeColor(isSelected('back-of-the-neck'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="upper-back" (click)="backViewModalOnClickSpecificArea('upper-back', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.upperBack')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_2" d="M90.8988 57.7604C89.5788 60.8104 88.2587 63.9104 86.9487 67.0704C85.4887 70.5904 84.0887 74.0604 82.7387 77.5004L80.7188 84.4904L80.1087 87.7904L79.8187 90.4104L79.4688 93.5404V97.0704L80.1787 100.36L80.8988 102.73L81.1787 103.66L81.9887 110.32L82.7988 119.87L81.8887 122.63C81.9987 122.75 82.1087 122.96 82.1987 123.16C82.4987 123.8 82.8988 124.84 83.1588 126.44C83.7488 128.61 84.1687 130.39 84.4487 131.59C84.7887 133.09 84.8188 133.35 85.5088 136.58C86.1688 139.67 86.1987 139.7 86.5687 141.5C87.1687 144.41 87.4687 145.86 87.6287 147.12C87.6987 147.68 87.7588 148.21 87.7988 148.73H163.599C163.739 147.69 163.939 146.59 164.179 145.21C164.469 143.56 164.529 143.48 165.439 139.11C166.159 135.67 166.119 135.67 166.749 132.7C167.479 129.24 167.889 127.49 168.569 125.33C168.869 124.36 169.149 123.56 169.349 123.01C169.469 122.94 169.589 122.87 169.709 122.8C169.709 122.79 169.709 122.77 169.719 122.76L168.769 119.87L169.579 110.32L170.389 103.66L171.399 100.36L172.109 97.0704V93.5404L171.469 87.7904L170.859 84.4904L168.839 77.5004C168.509 76.6604 168.179 75.8104 167.839 74.9704C167.829 74.9404 167.819 74.9104 167.809 74.8804C167.499 74.0904 167.179 73.3104 166.859 72.5204C166.819 72.4204 166.779 72.3304 166.739 72.2304C166.449 71.5104 166.149 70.7804 165.859 70.0504C165.799 69.9004 165.739 69.7604 165.679 69.6104C165.329 68.7604 164.979 67.9104 164.629 67.0604C163.079 63.3204 161.519 59.6504 159.939 56.0504L159.869 55.9004H91.7188L90.8887 57.7504L90.8988 57.7604Z" [attr.fill]="changeColor(isSelected('upper-back'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="lower-back" (click)="backViewModalOnClickSpecificArea('lower-back', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.lowerBack')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_3" d="M164.069 177.1C163.909 176.63 163.779 176.08 163.739 175.05C163.719 174.42 163.729 173.61 163.789 172.54C163.959 169.74 164.209 169.83 164.319 167.72C164.419 165.81 164.289 164.33 164.089 162.15C163.899 160.04 163.779 159.89 163.639 157.89C163.589 157.19 163.489 155.62 163.489 153.63C163.489 152.4 163.489 150.76 163.689 148.74H87.8394C87.9994 149.85 88.0994 150.88 88.1494 152.07C88.2494 154.7 88.0294 156.78 87.7494 159.38C87.4194 162.38 87.1694 163.07 87.1194 165.46C87.0794 167.5 87.0594 168.53 87.2994 169.9C87.5694 171.45 87.9194 172.21 87.8494 173.76C87.8294 174.14 87.7794 174.51 87.6894 174.96C87.6094 175.34 87.5094 175.78 87.3694 176.36C87.2294 176.95 87.0694 177.61 86.8594 178.33C88.5394 180.88 89.9094 184.15 90.8594 187.89H160.109C161.109 183.95 162.579 180.53 164.379 177.92C164.229 177.54 164.129 177.29 164.069 177.12V177.1Z" [attr.fill]="changeColor(isSelected('lower-back'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="buttock" (click)="backViewModalOnClickSpecificArea('buttock', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.buttock')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_4" d="M163.01 219.62C160.34 214.63 158.67 207.63 158.67 199.89C158.67 195.58 159.19 191.5 160.1 187.87H90.8501C91.7701 191.5 92.2801 195.58 92.2801 199.89C92.2801 207.28 90.7601 213.99 88.3001 218.93C88.2701 218.99 88.2401 219.04 88.2201 219.1C88.0801 219.39 87.9301 219.66 87.7801 219.94C86.5001 222.26 84.9901 224.13 83.3301 225.42L84.1301 226.12L85.9101 227.38L88.9701 229.08L92.9701 230.96L95.5601 231.94L98.7201 233.02L101.91 233.93L105.71 234.6L109.14 234.87H112.3C112.3 234.87 116.5 234.7 116.71 234.7C116.92 234.7 120.71 234.43 120.71 234.43L123.7 234.09L124.06 233.99L124.48 233.43L124.89 232.89H126.64L127.07 233.48L127.08 233.51L127.42 233.98L127.82 234.1L130.81 234.44C130.81 234.44 134.61 234.71 134.81 234.71C135.01 234.71 139.22 234.88 139.22 234.88H142.38L145.81 234.61L149.61 233.94L152.8 233.03L155.96 231.95L158.55 230.97L162.55 229.09L165.61 227.39L167.39 226.13L167.92 225.67C166.06 224.33 164.38 222.26 162.97 219.64L163.01 219.62Z" [attr.fill]="changeColor(isSelected('buttock'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-hip" (click)="backViewModalOnClickSpecificArea('right-hip', 'neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightHip')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_5" d="M172.03 218.92C172.13 218.34 172.03 215.77 171.71 212.29C172.01 209.61 171.88 207.44 171.59 205.71V205.68C171.59 205.64 171.58 205.6 171.57 205.57L171.29 204.35L170.01 194.08C169.93 192.47 169.74 191.17 169.58 190.3C169.68 190.19 169.78 190.08 169.88 189.97C169.61 188.96 169.34 187.93 169.05 186.89C168.72 185.7 168.38 184.53 168.04 183.38C167.63 183.32 167.22 183.26 166.8 183.2C166.47 182.64 166.06 181.87 165.64 180.93C165.11 179.73 164.76 178.66 164.5 177.71C160.97 182.71 158.67 190.78 158.67 199.87C158.67 211.67 162.53 221.74 167.95 225.63C168.13 225.76 168.3 225.87 168.48 225.99C168.49 225.99 168.5 226 168.51 226.01C168.86 226.23 169.22 226.43 169.59 226.6C169.6 226.6 169.61 226.61 169.63 226.62C169.81 226.7 169.98 226.77 170.16 226.84C170.18 226.84 170.2 226.86 170.23 226.86C170.59 226.99 170.95 227.1 171.31 227.18C171.34 227.18 171.37 227.19 171.39 227.2C171.56 227.24 171.74 227.27 171.91 227.29C171.94 227.29 171.98 227.29 172.01 227.3C172.19 227.32 172.37 227.34 172.55 227.35C172.28 225.55 171.96 223.63 171.58 221.6C171.5 221.17 171.42 220.73 171.33 220.28C171.45 220.15 171.64 219.93 171.78 219.63C171.78 219.63 171.94 219.3 172.01 218.9L172.03 218.92Z" [attr.fill]="changeColor(isSelected('right-hip'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-hip" (click)="backViewModalOnClickSpecificArea('left-hip', 'neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftHip')" (mouseleave)="backViewModalHideToolTip()">
            <path id="Vector_6" d="M92.2894 199.89C92.2894 191.14 90.1694 183.34 86.8594 178.31C86.7594 178.55 86.6494 178.82 86.5294 179.13C86.1294 180.11 85.9194 180.59 85.6194 181.4C85.3594 182.1 85.1794 182.67 85.0694 183.04C84.5894 183.11 84.1094 183.17 83.6294 183.24C83.2994 184.09 82.9694 185.01 82.6694 185.99C82.2194 187.47 81.8994 188.86 81.6594 190.13C81.7394 190.27 81.8094 190.42 81.8894 190.56C81.8394 190.75 81.7794 191.02 81.7094 191.37C81.4694 192.62 81.4594 193.56 81.4694 194.44L79.9894 207.73C79.9894 207.83 79.9694 207.92 79.9594 208.02C79.9194 208.48 79.8594 209.25 79.7094 212.64C79.6594 213.87 79.5794 215.56 79.5094 217.58C79.5394 217.73 79.5794 217.88 79.6094 218.03C79.5794 218.55 79.5394 219.07 79.5094 219.59C79.5094 219.66 79.5394 219.88 79.7094 220.07C79.8594 220.24 80.0394 220.3 80.1094 220.32C80.1194 220.51 80.1294 220.8 80.1394 221.15C80.1394 221.35 80.1394 221.54 80.1394 221.73C80.0694 223.86 79.5794 225.41 79.2894 226.55C79.2294 226.78 79.1694 227.03 79.1094 227.3C79.2794 227.28 79.4494 227.24 79.6194 227.21C79.6494 227.21 79.6794 227.2 79.6994 227.19C80.0394 227.11 80.3794 227.01 80.7094 226.89C80.7294 226.89 80.7594 226.87 80.7794 226.86C81.1194 226.73 81.4494 226.58 81.7794 226.41C81.7994 226.41 81.8094 226.39 81.8294 226.39C82.1694 226.21 82.4994 226.01 82.8194 225.79C82.8194 225.79 82.8394 225.78 82.8394 225.77C83.0094 225.66 83.1694 225.54 83.3294 225.41C88.5794 221.36 92.2894 211.46 92.2894 199.89Z" [attr.fill]="changeColor(isSelected('left-hip'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectNeckTrunk"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('neck-trunk') || checkAreaExists('back-neck-trunk') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" stroke="white" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-left-hand'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-hand') || checkAreaExists('left-hand') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3> 
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftHand' | translate }}</span>  
    <div class="modal_select_container">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-left-hand.png"></image>
        <g id="left-wrist" (click)="backViewModalOnClickSpecificArea('left-wrist', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftWrist')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M127.34-1.31c-0.19,0.63-0.39,1.26-0.6,1.93c-4.45,14.43-8.3,27.47-11.56,38.8c-0.18,0.61-0.48,1.5-1.03,2.48
            c-0.53,0.94-1.16,1.74-1.84,2.43L178.1,64.8c-0.17-0.96-0.33-1.87-0.49-2.68c0.5-2.12,1.25-5.27,2.17-9.08
            c3.31-13.78,4.97-20.67,6.4-25.8c0.79-2.85,1.68-5.75,2.84-9.36L127.34-1.31z" [attr.fill]="changeColor(isSelected('left-wrist'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-knuckles" (click)="backViewModalOnClickSpecificArea('left-knuckles', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftKnuckles')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M153.78,154.58c0.17,0.08,0.33,0.18,0.47,0.29l19.12-7.58l0.23-0.06c0.5-2.74,0.93-4.34,1.54-8.12
            c1.08-6.7,0.98-10,1.55-16.82c0.12-1.41,0.23-2.59,0.33-3.61l-25.22,13.79l-23.07,3.92l-20.38-4.4l-27.38-21.51
            c-2.03,6.2-2.69,11.64-3.06,14.67c-0.5,4.17-0.28,5.73-0.39,8.19l21.49,20.24c0.42,0.02,0.85,0.12,1.22,0.38
            c0.19,0.14,0.35,0.31,0.47,0.5l23.33,6.04c0.74-0.57,1.24-0.59,1.48-0.56c0.42,0.05,0.78,0.28,1.07,0.59l22.2-4.28
            c0.17-0.19,0.35-0.37,0.56-0.54C149.6,155.5,151.79,153.61,153.78,154.58z" [attr.fill]="changeColor(isSelected('left-knuckles'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-left-hand" (click)="backViewModalOnClickSpecificArea('back-of-the-left-hand', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheLeftHand')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M108.34,131.98l20.38,4.4l23.07-3.92l25.22-13.79c0.6-5.73,1.07-6.36,1.83-14.34
            c0.73-7.62,1.47-15.83,0.83-26.11c-0.34-5.31-0.96-9.86-1.57-13.41l-65.32-20.33c1.7,10.99-0.22,22.66-6.25,33.11
            c-5.63,9.75-13.98,16.93-23.48,21.12c-0.02,0.12-0.04,0.22-0.06,0.29c-0.82,3.61-0.67,7.43-1.86,10.94
            c-0.06,0.18-0.12,0.36-0.18,0.54L108.34,131.98z" [attr.fill]="changeColor(isSelected('back-of-the-left-hand'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-thumb', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M112.79,44.48l-0.47-0.15c-2.19,2.24-4.85,3.28-6,3.76c-1.6,0.66-7.33,4.22-18.78,11.35
            c-9.18,5.71-11.84,8.28-13.21,9.7c-2.91,3.01-3.3,4.23-6.4,8.05c-3.99,4.91-4.91,4.82-13.83,13.41c-0.04,0.04-0.08,0.08-0.12,0.12
            l1.96,1.42l13.58,11.8c0.53-0.54,1.07-1.09,1.68-1.69c1-0.99,2.14-2.1,3.53-3.46c1.35-1.31,3.58-3.44,5.92-3.1
            c0.28,0.04,1.54,0.22,2.13,1.17c0.41,0.65,0.34,1.4,0.26,1.85c9.51-4.19,17.86-11.37,23.48-21.12
            C112.57,67.14,114.49,55.46,112.79,44.48z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-thumb" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-thumb', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M44.98,114.81l-1.55-4.64l-1.03-4.23l0.06-3.43c-2.29,2.52-3.95,4.47-5.08,5.84
            c-1.17,1.41-2.11,2.59-2.73,3.38c-0.34,0.45-0.91,1.34-1.03,2.55c-0.15,1.53,0.51,2.68,0.98,3.48c0.77,1.31,1.79,2.25,2.73,2.93
            l1.04-0.72l4.75-2.99L44.98,114.81z" [attr.fill]="changeColor(isSelected('nail-of-the-left-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-thumb', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M55.94,92.14l-1.96-1.42c-3.51,3.39-6.41,6.34-8.8,8.86c-1,1.05-1.9,2.03-2.72,2.93l-0.06,3.43l1.03,4.23
            l1.55,4.64l-1.86,2.17l-4.75,2.99l-1.04,0.72c1.24,0.87,2.36,1.28,2.66,1.38c3.07,1.06,5.76,0.37,7.69-0.15
            c2.61-0.71,4.63-1.89,7.09-3.69c1.96-1.44,5.9-4.51,11.78-11.12c0.98-1.1,1.91-2.09,2.97-3.17L55.94,92.14z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-little-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-little-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M162.39,203.43L162.39,203.43c-3.04,0-5.5,2.46-5.5,5.5v0.65c0.38,0.64,0.96,1.37,1.84,1.95
            c1.7,1.13,3.99,1.37,5.98,0.41c1.91-0.92,2.75-2.56,3.13-3.7C167.5,205.53,165.2,203.43,162.39,203.43z" [attr.fill]="changeColor(isSelected('nail-of-the-left-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-little-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M155.02,191.7c0.06,4.2,0.23,7.6,0.61,11.27c0.23,2.19,0.46,3.98,0.62,5.16c0.08,0.26,0.26,0.82,0.64,1.46
            v-0.65c0-3.04,2.46-5.5,5.5-5.5l0,0c2.8,0,5.11,2.1,5.45,4.81c0.22-0.69,0.27-1.19,0.28-1.25c0.43-4,1-9.47,1.64-15.95
            L155.02,191.7z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-little-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M155.12,175.39L155.12,175.39c-0.09,7.01-0.15,12.07-0.09,16.3l14.74-0.66c0.04-0.39,0.08-0.77,0.12-1.17
            c0.72-7.36,1.21-12.42,1.54-16.13L155.12,175.39z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-little-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M154.25,154.87c1.05,0.79,1.34,2.14,1.39,2.39c-0.27,5.52-0.44,11.2-0.52,17.03c0,0.38-0.01,0.74-0.01,1.11
            h0.01l16.3-1.66c0.2-2.23,0.33-3.98,0.42-5.43c0.57-9.39,0.3-11.83,1.34-18.57c0.15-0.96,0.29-1.77,0.42-2.5l-0.23,0.06
            L154.25,154.87z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-ring-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-ring-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M136.1,233.08c-0.15-3.67-3.05-6.7-6.8-6.93c-3.63-0.22-6.79,2.27-7.52,5.71c0.12,1.12,0.29,2.2,0.53,3.2
            c0.1,0.44,0.31,1.2,0.93,1.86c1.26,1.33,3.29,1.28,4.85,1.24c1.37-0.04,3.14-0.08,4.85-1.13
            C134.68,235.95,135.58,234.31,136.1,233.08z" [attr.fill]="changeColor(isSelected('nail-of-the-left-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-ring-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M123.06,212.09c-0.09,0.49-0.2,1.08-0.34,1.82c0,0-1.79,9.99-0.94,17.95c0.74-3.45,3.9-5.94,7.52-5.71
            c3.74,0.23,6.65,3.26,6.8,6.93c0.09-0.21,0.17-0.41,0.24-0.6c2.3-6,2.17-12.01,3.1-19.48L123.06,212.09z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-ring-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M124.74,187.83c-0.21,1.99-0.35,4.31-0.57,7.81c-0.31,4.91,0.24,7.53-0.41,12.59
            c-0.2,1.57-0.29,1.76-0.7,3.85l16.38,0.92c0.12-0.95,0.25-1.92,0.41-2.91c0.67-4.21,0.85-3.11,2.27-10.63
            c0.61-3.23,1.28-6.76,1.83-10.96L124.74,187.83z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-ring-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M126.58,160.53c0.47,0.48,0.79,1.15,0.99,1.58c1.44,3.08,0.78,6.34,0.62,7.12
            c-1.56,7.66-2.61,12.84-3.1,15.89c-0.14,0.89-0.26,1.77-0.36,2.71l19.21,0.67c0.16-1.21,0.31-2.46,0.44-3.79
            c0.72-7.07,0.93-13.21,0.93-13.21s0.12-3.63,1.96-11.97c0.18-0.84,0.5-2.18,1.5-3.28L126.58,160.53z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-middle-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-middle-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M96.62,233.04L96.62,233.04c-3.94-0.73-7.71,1.88-8.44,5.81l-0.21,1.13c0.21,0.86,0.55,1.67,1.11,2.41
            c1.48,1.96,3.79,2.71,4.02,2.79c0.63,0.2,2.39,0.72,4.54,0.1c2.24-0.65,3.5-2.14,3.92-2.68c0.33-0.89,0.65-1.79,0.97-2.72
            C102.41,236.56,100.02,233.66,96.62,233.04z" [attr.fill]="changeColor(isSelected('nail-of-the-left-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-middle-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M92.42,212.97l-0.41-0.52c-0.14,0.63-0.29,1.29-0.45,1.97c-0.63,2.73-2.35,10.16-3.51,19.09
            c-0.33,2.51-0.52,4.63-0.08,6.47l0.21-1.13c0.73-3.94,4.5-6.54,8.44-5.81l0,0c3.4,0.63,5.79,3.52,5.92,6.84
            c0.92-2.65,1.81-5.46,2.64-8.42c1.39-4.97,2.4-9.67,3.15-14.05L92.42,212.97z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-middle-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M116.26,192.79l-20.93-4.36c-1.01,4.14-1.94,9.84-1.81,17.13c-0.35,1.64-0.86,4.02-1.51,6.91l0.41,0.52
            l15.91,4.42c0.16-0.93,0.32-1.87,0.46-2.77c0.62-1.93,1.24-3.85,1.86-5.78c1.21-2.38,2.92-6.08,4.33-10.83
            c0.53-1.79,0.95-3.48,1.32-5.23h-0.04V192.79z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-middle-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M101.26,156.95c0.08,1.93-0.02,4.91-1.03,8.77c-0.32,2.99-0.92,7.41-2.17,12.69c-0.96,4.05-1.7,6.1-2.4,8.73
            c-0.11,0.4-0.22,0.84-0.32,1.28l20.93,4.36h0.04c0.61-2.95,1.08-6.07,1.68-10.14c0.68-4.63,2.06-9.16,2.37-13.83
            c0.05-0.7,0.29-4.42,2.68-7.33c0.37-0.45,0.7-0.76,1-0.99l-23.33-6.04C101.13,155.1,101.22,156,101.26,156.95z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-index-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-index-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M69.14,209.58c-3.59-0.66-7.04,1.45-8.17,4.82c0.09,0.9,0.22,1.79,0.43,2.64c0.2,0.83,0.52,1.87,1.38,2.82
            c2.14,2.37,5.77,2.05,6.4,1.99c0.85-0.08,3.14-0.3,4.88-1.93c0.19-0.18,0.43-0.44,0.69-0.78l0.21-1.13
            C75.68,214.08,73.07,210.3,69.14,209.58z" [attr.fill]="changeColor(isSelected('nail-of-the-left-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-index-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M64.98,194.24c-1.11,2.7-1.86,5.01-2.35,6.7c-1.4,4.81-2.1,9.35-1.67,13.46c1.13-3.36,4.58-5.48,8.17-4.82
            c3.94,0.73,6.54,4.5,5.81,8.44l-0.21,1.13c0.33-0.44,0.68-1.01,0.96-1.77c1.22-3.21,2.42-6.73,3.51-10.52
            c0.76-2.64,1.39-5.18,1.94-7.63L64.98,194.24z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-index-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M88.82,176.9l-19.39-5.73l-0.52-0.58c-1.28,4.5-2.4,9.61-3.11,15.28c-0.35,2.81-0.57,5.5-0.69,8.05
            c-0.05,0.11-0.09,0.21-0.13,0.32l16.17,4.98c0.38-1.7,0.73-3.36,1.02-4.95c0.83-1.72,1.65-3.44,2.48-5.16
            c0.23-1.05,0.46-2.11,0.69-3.16c0.58-0.79,1.38-2.01,2.1-3.61c0.85-1.9,1.13-3.34,1.47-5.25L88.82,176.9z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-index-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M96.82,154.06c0.14-0.07,1.16-0.53,2.19-0.49l-21.49-20.24c-0.05,1.12-0.16,2.43-0.44,4.26
            c-0.7,4.58-1.69,7.56-2.68,10.87c-1.43,4.76-3.32,11.71-5.09,20.77c-0.13,0.45-0.26,0.91-0.39,1.37l0.52,0.58l19.39,5.73
            l0.08,0.19c0.09-0.49,0.18-1,0.28-1.56c0.78-4.17,0.89-3,2.37-9.7c0.58-2.63,0.93-4.48,1.96-7.12
            C94.59,155.95,95.47,154.71,96.82,154.06z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftHand"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-hand') || checkAreaExists('left-hand') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-right-hand'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-hand') || checkAreaExists('right-hand') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightHand' | translate }}</span>  
    <div class="modal_select_container">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-right-hand.png"></image>
        <g id="right-wrist" (click)="backViewModalOnClickSpecificArea('right-wrist', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightWrist')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M116.9-2.15c0.19,0.63,0.39,1.26,0.6,1.93c4.45,14.43,8.3,27.47,11.56,38.8c0.18,0.61,0.48,1.5,1.03,2.48
            c0.53,0.94,1.16,1.74,1.84,2.43L66.14,63.96c0.17-0.96,0.33-1.87,0.49-2.68c-0.5-2.12-1.25-5.27-2.17-9.08
            c-3.31-13.78-4.97-20.67-6.4-25.8c-0.79-2.85-1.68-5.75-2.84-9.36L116.9-2.15z" [attr.fill]="changeColor(isSelected('right-wrist'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-knuckles" (click)="backViewModalOnClickSpecificArea('right-knuckles', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightKnuckles')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M90.46,153.73c-0.17,0.08-0.33,0.18-0.47,0.29l-19.12-7.58l-0.23-0.06c-0.5-2.74-0.93-4.34-1.54-8.12
            c-1.08-6.7-0.98-10-1.55-16.82c-0.12-1.41-0.23-2.59-0.33-3.61l25.22,13.79l23.07,3.92l20.38-4.4l27.38-21.51
            c2.03,6.2,2.69,11.64,3.06,14.67c0.5,4.17,0.28,5.73,0.39,8.19l-21.49,20.24c-0.42,0.02-0.85,0.12-1.22,0.38
            c-0.19,0.14-0.35,0.31-0.47,0.5l-23.33,6.04c-0.74-0.57-1.24-0.59-1.48-0.56c-0.42,0.05-0.78,0.28-1.07,0.59l-22.2-4.28
            c-0.17-0.19-0.35-0.37-0.56-0.54C94.64,154.66,92.45,152.77,90.46,153.73z" [attr.fill]="changeColor(isSelected('right-knuckles'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-right-hand" (click)="backViewModalOnClickSpecificArea('back-of-the-right-hand', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheRightHand')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M135.9,131.14l-20.38,4.4l-23.07-3.92l-25.22-13.79c-0.6-5.73-1.07-6.36-1.83-14.34
            c-0.73-7.62-1.47-15.83-0.83-26.11c0.34-5.31,0.96-9.86,1.57-13.41l65.32-20.33c-1.7,10.99,0.22,22.66,6.25,33.11
            c5.63,9.75,13.98,16.93,23.48,21.12c0.02,0.12,0.04,0.22,0.06,0.29c0.82,3.61,0.67,7.43,1.86,10.94c0.06,0.18,0.12,0.36,0.18,0.54
            L135.9,131.14z" [attr.fill]="changeColor(isSelected('back-of-the-right-hand'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-thumb', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M131.45,43.64l0.47-0.15c2.19,2.24,4.85,3.28,6,3.76c1.6,0.66,7.33,4.22,18.78,11.35
            c9.18,5.71,11.84,8.28,13.21,9.7c2.91,3.01,3.3,4.23,6.4,8.05c3.99,4.91,4.91,4.82,13.83,13.41c0.04,0.04,0.08,0.08,0.12,0.12
            l-1.96,1.42l-13.58,11.8c-0.53-0.54-1.07-1.09-1.68-1.69c-1-0.99-2.14-2.1-3.53-3.46c-1.35-1.31-3.58-3.44-5.92-3.1
            c-0.28,0.04-1.54,0.22-2.13,1.17c-0.41,0.65-0.34,1.4-0.26,1.85c-9.51-4.19-17.86-11.37-23.48-21.12
            C131.67,66.3,129.75,54.62,131.45,43.64z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-thumb" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-thumb', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M199.26,113.97l1.55-4.64l1.03-4.23l-0.06-3.43c2.29,2.52,3.95,4.47,5.08,5.84c1.17,1.41,2.11,2.59,2.73,3.38
            c0.34,0.45,0.91,1.34,1.03,2.55c0.15,1.53-0.51,2.68-0.98,3.48c-0.77,1.31-1.79,2.25-2.73,2.93l-1.04-0.72l-4.75-2.99
            L199.26,113.97z" [attr.fill]="changeColor(isSelected('nail-of-the-right-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-thumb', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M188.3,91.3l1.96-1.42c3.51,3.39,6.41,6.34,8.8,8.86c1,1.05,1.9,2.03,2.72,2.93l0.06,3.43l-1.03,4.23
            l-1.55,4.64l1.86,2.17l4.75,2.99l1.04,0.72c-1.24,0.87-2.36,1.28-2.66,1.38c-3.07,1.06-5.76,0.37-7.69-0.15
            c-2.61-0.71-4.63-1.89-7.09-3.69c-1.96-1.44-5.9-4.51-11.78-11.12c-0.98-1.1-1.91-2.09-2.97-3.17L188.3,91.3z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-little-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-little-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M81.85,202.59L81.85,202.59c3.04,0,5.5,2.46,5.5,5.5v0.65c-0.38,0.64-0.96,1.37-1.84,1.95
            c-1.7,1.13-3.99,1.37-5.98,0.41c-1.91-0.92-2.75-2.56-3.13-3.7C76.74,204.69,79.04,202.59,81.85,202.59z" [attr.fill]="changeColor(isSelected('nail-of-the-right-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-little-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M89.22,190.85c-0.06,4.2-0.23,7.6-0.61,11.27c-0.23,2.19-0.46,3.98-0.62,5.16c-0.08,0.26-0.26,0.82-0.64,1.46
            v-0.65c0-3.04-2.46-5.5-5.5-5.5l0,0c-2.8,0-5.11,2.1-5.45,4.81c-0.22-0.69-0.27-1.19-0.28-1.25c-0.43-4-1-9.47-1.64-15.95
            L89.22,190.85z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-little-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M89.12,174.55L89.12,174.55c0.09,7.01,0.15,12.07,0.09,16.3l-14.74-0.66c-0.04-0.39-0.08-0.77-0.12-1.17
          c-0.72-7.36-1.21-12.42-1.54-16.13L89.12,174.55z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-little-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M89.99,154.02c-1.05,0.79-1.34,2.14-1.39,2.39c0.27,5.52,0.44,11.2,0.52,17.03c0,0.38,0.01,0.74,0.01,1.11
            h-0.01l-16.3-1.66c-0.2-2.23-0.33-3.98-0.42-5.43c-0.57-9.39-0.3-11.83-1.34-18.57c-0.15-0.96-0.29-1.77-0.42-2.5l0.23,0.06
            L89.99,154.02z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-ring-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-ring-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M108.14,232.24c0.15-3.67,3.05-6.7,6.8-6.93c3.63-0.22,6.79,2.27,7.52,5.71c-0.12,1.12-0.29,2.2-0.53,3.2
            c-0.1,0.44-0.31,1.2-0.93,1.86c-1.26,1.33-3.29,1.28-4.85,1.24c-1.37-0.04-3.14-0.08-4.85-1.13
            C109.56,235.1,108.66,233.47,108.14,232.24z" [attr.fill]="changeColor(isSelected('nail-of-the-right-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-ring-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M121.18,211.24c0.09,0.49,0.2,1.08,0.34,1.82c0,0,1.79,9.99,0.94,17.95c-0.74-3.45-3.9-5.94-7.52-5.71
            c-3.74,0.23-6.65,3.26-6.8,6.93c-0.09-0.21-0.17-0.41-0.24-0.6c-2.3-6-2.17-12.01-3.1-19.48L121.18,211.24z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-ring-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M119.5,186.99c0.21,1.99,0.35,4.31,0.57,7.81c0.31,4.91-0.24,7.53,0.41,12.59c0.2,1.57,0.29,1.76,0.7,3.85
            l-16.38,0.92c-0.12-0.95-0.25-1.92-0.41-2.91c-0.67-4.21-0.85-3.11-2.27-10.63c-0.61-3.23-1.28-6.76-1.83-10.96L119.5,186.99z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-ring-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M117.66,159.69c-0.47,0.48-0.79,1.15-0.99,1.58c-1.44,3.08-0.78,6.34-0.62,7.12
            c1.56,7.66,2.61,12.84,3.1,15.89c0.14,0.89,0.26,1.77,0.36,2.71l-19.21,0.67c-0.16-1.21-0.31-2.46-0.44-3.79
            c-0.72-7.07-0.93-13.21-0.93-13.21s-0.12-3.63-1.96-11.97c-0.18-0.84-0.5-2.18-1.5-3.28L117.66,159.69z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-middle-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-middle-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M147.62,232.2L147.62,232.2c3.94-0.73,7.71,1.88,8.44,5.81l0.21,1.13c-0.21,0.86-0.55,1.67-1.11,2.41
            c-1.48,1.96-3.79,2.71-4.02,2.79c-0.63,0.2-2.39,0.72-4.54,0.1c-2.24-0.65-3.5-2.14-3.92-2.68c-0.33-0.89-0.65-1.79-0.97-2.72
            C141.83,235.72,144.22,232.82,147.62,232.2z" [attr.fill]="changeColor(isSelected('nail-of-the-right-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-middle-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M151.82,212.13l0.41-0.52c0.14,0.63,0.29,1.29,0.45,1.97c0.62,2.73,2.35,10.16,3.51,19.09
            c0.33,2.51,0.52,4.63,0.08,6.47l-0.21-1.13c-0.73-3.94-4.5-6.54-8.44-5.81l0,0c-3.4,0.63-5.79,3.52-5.92,6.84
            c-0.92-2.65-1.81-5.46-2.64-8.42c-1.39-4.97-2.4-9.67-3.15-14.05L151.82,212.13z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-middle-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M127.98,191.95l20.93-4.36c1.01,4.14,1.94,9.84,1.81,17.13c0.35,1.64,0.86,4.02,1.51,6.91l-0.41,0.52
            l-15.91,4.42c-0.16-0.93-0.32-1.87-0.46-2.77c-0.62-1.93-1.24-3.85-1.86-5.78c-1.21-2.38-2.92-6.08-4.33-10.83
            c-0.53-1.79-0.95-3.48-1.32-5.23h0.04V191.95z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-middle-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M142.98,156.11c-0.08,1.93,0.02,4.91,1.03,8.77c0.32,2.99,0.92,7.41,2.17,12.69c0.96,4.05,1.7,6.1,2.4,8.73
            c0.11,0.4,0.22,0.84,0.32,1.28l-20.93,4.36h-0.04c-0.61-2.95-1.08-6.07-1.68-10.14c-0.68-4.63-2.06-9.16-2.37-13.83
            c-0.05-0.7-0.29-4.42-2.68-7.33c-0.37-0.45-0.7-0.76-1-0.99l23.33-6.04C143.11,154.26,143.02,155.16,142.98,156.11z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-index-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-index-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M175.1,208.74c3.59-0.66,7.04,1.45,8.17,4.82c-0.09,0.9-0.22,1.79-0.43,2.64c-0.2,0.83-0.52,1.87-1.38,2.82
            c-2.14,2.37-5.77,2.05-6.4,1.99c-0.85-0.08-3.14-0.3-4.88-1.93c-0.19-0.18-0.43-0.44-0.69-0.78l-0.21-1.13
            C168.56,213.24,171.17,209.46,175.1,208.74z" [attr.fill]="changeColor(isSelected('nail-of-the-right-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-index-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M179.26,193.4c1.11,2.7,1.86,5.01,2.35,6.7c1.4,4.81,2.1,9.35,1.67,13.46c-1.13-3.36-4.58-5.48-8.17-4.82
            c-3.94,0.73-6.54,4.5-5.81,8.44l0.21,1.13c-0.33-0.44-0.68-1.01-0.96-1.77c-1.22-3.21-2.42-6.73-3.51-10.52
            c-0.76-2.64-1.39-5.18-1.94-7.63L179.26,193.4z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-index-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M155.42,176.05l19.39-5.73l0.52-0.58c1.28,4.5,2.4,9.61,3.11,15.28c0.35,2.81,0.57,5.5,0.69,8.05
            c0.05,0.11,0.09,0.21,0.13,0.32l-16.17,4.98c-0.38-1.7-0.73-3.36-1.02-4.95c-0.83-1.72-1.65-3.44-2.48-5.16
            c-0.23-1.05-0.46-2.11-0.69-3.16c-0.58-0.79-1.38-2.01-2.1-3.61c-0.85-1.9-1.13-3.34-1.47-5.25L155.42,176.05z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-index-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M147.42,153.22c-0.14-0.07-1.16-0.53-2.19-0.49l21.49-20.24c0.05,1.12,0.16,2.43,0.44,4.26
            c0.7,4.58,1.69,7.56,2.68,10.87c1.43,4.76,3.32,11.71,5.09,20.77c0.13,0.45,0.26,0.91,0.39,1.37l-0.52,0.58l-19.39,5.73
            l-0.08,0.19c-0.09-0.49-0.18-1-0.28-1.56c-0.78-4.17-0.89-3-2.37-9.7c-0.58-2.63-0.93-4.48-1.96-7.12
            C149.65,155.11,148.77,153.87,147.42,153.22z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightHand"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-hand') || checkAreaExists('right-hand') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-left-leg'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-leg') || checkAreaExists('left-leg') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftLeg' | translate }}</span>  
    <div class="modal_select_container">      
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-left-leg.png"></image>
        <g id="left-calf" (click)="backViewModalOnClickSpecificArea('left-calf', 'back-left-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftCalf')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M136.598 151.93C136.468 151.14 135.908 147.77 135.588 143.4C135.438 141.32 135.418 140.06 135.588 138.68C131.478 140.82 126.438 142.08 120.988 142.08C116.238 142.08 111.808 141.13 108.028 139.47C107.828 140.32 107.608 141.28 107.338 142.44C105.478 150.31 105.528 149.8 105.318 150.97C104.528 155.4 104.538 158.95 104.558 160.25C104.638 164.44 105.288 167.57 106.118 171.6C106.288 172.43 106.888 174.87 108.088 179.72C109.708 186.31 109.618 185.85 110.008 187.49C110.638 190.14 110.688 190.54 112.478 198.94C113.838 205.3 113.958 205.76 114.348 207.82C115.318 212.95 115.708 215.99 115.808 216.8C116.118 219.24 116.348 221.15 116.468 223.71C116.538 225.37 116.528 226.55 116.468 227.85H132.098C131.778 225.66 131.668 223.78 131.538 221.7C131.368 218.79 131.588 215.99 132.038 210.5C132.438 205.67 132.658 203.02 133.248 199.2C133.888 195.07 134.348 192.1 135.518 188.15C136.628 184.4 137.228 183.65 137.938 180.28C138.628 177.01 138.758 174.46 138.848 172.36C138.928 170.64 139.038 166.36 138.238 160.76C137.768 157.45 137.598 158.02 136.578 151.93H136.598Z" [attr.fill]="changeColor(isSelected('left-calf'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-left-knee" (click)="backViewModalOnClickSpecificArea('back-of-the-left-knee', 'back-left-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheLeftKnee')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M120.989 106.159C116.519 106.159 112.319 107.009 108.689 108.489C108.949 110.259 109.099 111.689 109.209 112.719C109.409 114.689 109.529 117.009 109.759 121.649C110.079 127.929 110.039 129.059 109.859 130.479C109.719 131.619 109.409 133.069 108.799 135.979C108.549 137.159 108.319 138.209 108.029 139.469C111.809 141.129 116.239 142.079 120.989 142.079C126.439 142.079 131.489 140.819 135.589 138.679C135.599 138.569 135.619 138.459 135.629 138.359C135.899 136.519 136.349 135.429 136.989 133.619C137.669 131.689 138.919 128.159 139.559 124.889C139.949 122.939 140.019 120.899 140.169 116.819C140.219 115.519 140.229 114.279 140.239 112.709C135.659 108.719 128.739 106.169 120.979 106.169L120.989 106.159Z" [attr.fill]="changeColor(isSelected('back-of-the-left-knee'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-thigh" (click)="backViewModalOnClickSpecificArea('left-thigh', 'left-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftThigh')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M144.859 39.2795L144.079 34.4995L143.039 31.7395L143.149 31.5895L142.789 31.6895L139.799 32.0295C139.799 32.0295 135.999 32.2995 135.799 32.2995C135.599 32.2995 131.389 32.4695 131.389 32.4695H128.229L124.799 32.1995L120.999 31.5295L117.809 30.6195L114.649 29.5395L112.059 28.5595L108.059 26.6795L104.999 24.9795L103.219 23.7195L102.419 23.0195C101.099 24.0395 99.6794 24.6895 98.1894 24.9095C97.9294 26.0595 97.6794 27.5695 97.5894 29.4095C97.2794 31.9595 96.9694 34.5095 96.6594 37.0495C96.5594 38.3695 96.4494 40.1195 96.3794 42.1695C96.3394 43.6195 96.2694 45.9995 96.3794 48.7795C96.4994 51.6495 96.7694 53.9895 97.1394 56.5995C97.7694 61.0795 98.4994 65.1595 99.6894 71.0495C101.359 79.3495 102.189 83.4995 103.019 86.3395C104.049 89.8695 104.879 92.0495 106.399 97.6895C107.289 101 107.739 102.99 108.119 104.95C108.369 106.24 108.559 107.42 108.709 108.48C112.339 107 116.539 106.15 121.009 106.15C128.759 106.15 135.689 108.7 140.269 112.69C140.269 111.4 140.269 109.88 140.249 107.92C140.219 102.8 140.159 103.04 140.199 100.91C140.259 97.0195 140.499 93.8095 140.699 91.5795C141.059 87.6695 141.529 84.6695 141.709 83.5595C142.339 79.6395 142.729 78.5795 143.429 74.4295C144.429 68.5095 144.389 67.6695 144.389 67.6695C144.589 65.8695 144.699 64.4395 144.889 64.4395C144.889 64.4395 144.899 64.4395 144.909 64.4395V39.1295L144.889 39.2595L144.859 39.2795Z" [attr.fill]="changeColor(isSelected('left-thigh'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftLeg"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-leg') || checkAreaExists('left-leg') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-right-leg'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-leg') || checkAreaExists('right-leg') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightLeg' | translate }}</span>  
    <div class="modal_select_container">      
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250.17" style="enable-background:new 0 0 250 250.17;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-right-leg.png"></image>
        <g id="right_calf" (click)="backViewModalOnClickSpecificArea('right-calf', 'back-right-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightCalf')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M143.371 145.86C142.851 143.58 142.291 141.54 141.751 139.79C137.961 141.46 133.511 142.42 128.751 142.42C123.271 142.42 118.201 141.15 114.091 138.99C114.151 139.62 114.171 140.41 114.171 141.52C114.151 144.68 113.871 147.27 113.671 148.89C113.421 150.87 113.151 152.3 112.661 154.99C112.031 158.41 111.951 158.41 111.601 160.44C111.251 162.48 110.811 165.16 110.691 168.61C110.561 172.52 110.911 175.46 110.991 176.08C111.391 179.25 112.051 181.67 112.601 183.7C113.331 186.38 113.561 186.57 114.371 189.65C115.141 192.6 115.541 194.73 115.631 195.2C115.751 195.85 115.891 196.65 116.441 200.7C116.941 204.37 117.181 206.2 117.301 207.31C117.611 210.32 117.711 212.61 117.801 214.62C117.881 216.33 117.971 218.37 117.951 221.08C117.941 223.27 117.931 224.36 117.801 225.82C117.731 226.61 117.671 227.01 117.481 228.19H133.131C133.101 227.55 133.071 226.88 133.051 226.17C133.201 223.79 133.341 221.42 133.491 219.04C133.751 217.01 134.171 213.91 134.841 210.16C135.421 206.88 136.381 202.66 138.271 194.35C141.041 182.21 141.201 181.52 141.631 179.82C143.141 173.92 143.411 173.59 143.981 170.54C144.561 167.44 145.371 162.97 145.121 157.22C144.921 152.68 144.151 149.27 143.371 145.85V145.86Z" [attr.fill]="changeColor(isSelected('right-calf'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right_knee" (click)="backViewModalOnClickSpecificArea('back-of-the-right-knee', 'back-right-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheRightKnee')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M140.969 108.8C137.359 107.34 133.189 106.5 128.749 106.5C120.819 106.5 113.759 109.16 109.189 113.31C109.189 114.71 109.199 115.89 109.269 117.2C109.409 120.1 109.549 122.88 110.329 126.53C111.049 129.89 111.409 131.57 112.399 133.69C112.889 134.74 113.479 135.8 113.859 137.52C113.959 137.96 114.029 138.4 114.079 138.97C118.199 141.13 123.259 142.4 128.739 142.4C133.499 142.4 137.959 141.44 141.739 139.77C141.409 138.7 141.089 137.74 140.799 136.89C140.309 133.9 139.819 129.83 139.719 124.98C139.689 123.26 139.619 117.09 140.929 108.9C140.929 108.86 140.939 108.81 140.949 108.77L140.969 108.8Z" [attr.fill]="changeColor(isSelected('back-of-the-right-knee'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right_thigh" (click)="backViewModalOnClickSpecificArea('right-thigh', 'right-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightThigh')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M151.65 25.3301C150.03 25.2501 148.48 24.6501 147.04 23.6201L146.51 24.0801L144.73 25.3401L141.67 27.0401L137.67 28.9201L135.08 29.9001L131.92 30.9801L128.73 31.8901L124.93 32.5601L121.5 32.8301H118.34C118.34 32.8301 114.14 32.6601 113.93 32.6601C113.72 32.6601 109.93 32.3901 109.93 32.3901L106.94 32.0501L106.54 31.9301L106.76 32.2301L105.75 34.8901L104.91 39.5101V64.8201C105.09 64.9001 105.23 66.2201 105.45 67.9901C105.72 70.2301 106.07 72.4601 106.36 74.7001C106.75 77.7401 106.84 77.4201 107.92 83.9801C108.59 88.0601 108.93 90.0901 109.08 91.5501C109.5 95.5401 109.49 98.7301 109.48 101.24C109.47 104.06 109.37 103.93 109.28 108.35C109.24 110.42 109.21 111.98 109.2 113.34C113.77 109.19 120.83 106.53 128.76 106.53C133.2 106.53 137.37 107.37 140.98 108.83C141.56 105.24 142.31 102.24 143.78 96.3101C145.92 87.7401 146.31 87.5501 147.88 80.9701C149.11 75.8401 149.8 72.0901 151.18 64.6301C152.48 57.5701 153.13 54.0301 153.33 51.5101C153.84 45.1701 153.37 40.4201 152.72 33.8901C152.48 31.4801 152.14 28.6001 151.65 25.3501V25.3301Z" [attr.fill]="changeColor(isSelected('right-thigh'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightLeg"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-leg') || checkAreaExists('right-leg') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-left-arm'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-arm') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftArm' | translate }}</span>  
    <div class="modal_select_container">      
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-left-arm.png"></image>
        <g id="left-forearm" (click)="backViewModalOnClickSpecificArea('left-forearm', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftForearm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M126.23,164.77c-0.58,1.37-1.14,2.93-2.18,5.82c-1.68,4.68-1.64,4.92-2.61,7.52
            c-0.8,2.16-1.68,4.14-3.39,8.03c-2.52,5.7-2.63,5.64-4.26,9.45c-0.88,2.06-2.24,5.21-3.76,9.4c-0.11,0.31-0.98,2.83-2.71,7.89
            c-1.29,3.78-1.64,4.8-2.16,6.42c-0.41,1.3-0.77,2.46-1.06,3.42c-7.81-2.16-14.71-4.37-20.54-6.38c0.12-0.43,0.27-0.94,0.45-1.56
            c1.32-4.5,1.44-4.51,1.9-6.4c0.18-0.75,0.39-1.67,1.17-6.67c0.71-4.55,1.06-6.82,1.26-8.58c0.29-2.55,0.19-2.47,0.68-8.63
            c0.2-2.54,0.38-4.49,0.72-8.39c0.45-5.02,0.67-7.56,0.86-9.01c0.35-2.74,0.73-5.61,1.62-9.36c0.17-0.7,0.36-1.51,0.6-2.41l0,0
            L126.23,164.77z" [attr.fill]="changeColor(isSelected('left-forearm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-elbow" (click)="backViewModalOnClickSpecificArea('left-elbow', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftElbow')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M94.87,149.07c0.53-1.35,1.34-3.36,2.72-5.85c1.01-1.82,2.01-3.31,3.2-5.09c1.99-2.97,2-2.7,2.65-3.89
            c1.23-2.24,1.74-4.2,2.34-6.57c0.33-1.31,0.59-2.56,1.03-5.3c0.01-0.06,0.02-0.11,0.03-0.17l0,0l28.3,7.86l0,0
            c-0.19,0.5-0.4,1.04-0.63,1.63c-2.43,6.3-2.58,6.72-2.8,7.48c-0.38,1.32-1.16,4.03-1.51,7.61c-0.26,2.66-0.02,3.01-0.37,6.33
            c-0.26,2.48-0.41,3.9-0.96,5.73c-0.63,2.08-1.08,2.36-2.48,5.55c-0.05,0.12-0.11,0.25-0.16,0.37l-33.41-9.44
            C93.28,153.59,93.91,151.5,94.87,149.07z" [attr.fill]="changeColor(isSelected('left-elbow'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-upper-arm" (click)="backViewModalOnClickSpecificArea('left-upper-arm', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftUpperArm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M108.08,113.26c0.75-6.27,0.61-6.23,1.2-10.66c0.52-3.89,0.78-5.84,1.31-8.12c0.65-2.77,1.04-3.55,1.41-5.95
            c0.24-1.54,0.31-2.78,0.34-4.1c5.43-1.73,10.93-4.09,16.34-7.09c-0.09,0.05-0.17,0.09-0.26,0.14c0.01,0.02,0.01,0.04,0.02,0.06
            c1.84,6.68,6.95,11.14,11.61,10.27l0.39,1.27l1.1,9.08l1.1,13.02l-1.3,3.94c0.01,0.02,0.01,0.04,0.02,0.06
            c-0.01-0.01-0.02-0.01-0.03-0.02c-0.24,0.63-0.61,1.6-1.09,2.77c-1.46,3.59-2.2,4.95-3.49,7.98c-0.14,0.34-0.65,1.65-1.62,4.15
            l-28.3-7.86l0,0C107.47,118.22,107.86,115.06,108.08,113.26z" [attr.fill]="changeColor(isSelected('left-upper-arm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-armpit" (click)="backViewModalOnClickSpecificArea('left-armpit', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftArmpit')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M130.86,76.1c0.58-0.34,1.16-0.69,1.73-1.04c0.03-0.02,0.06-0.04,0.08-0.05c0.56-0.35,1.1-0.7,1.65-1.05
            c0.11-0.07,0.22-0.14,0.33-0.21c0.57-0.37,1.14-0.75,1.69-1.14c0.02-0.01,0.04-0.03,0.06-0.04c0.55-0.38,1.1-0.77,1.64-1.16
            c0.07-0.05,0.13-0.1,0.2-0.14c0.11-0.08,0.23-0.16,0.34-0.24l-0.47,4.27v4.82l0.96,4.49l0.99,3.23
            c-4.66,0.88-9.78-3.59-11.61-10.27c-0.01-0.02-0.01-0.04-0.02-0.06c0.09-0.05,0.17-0.09,0.26-0.14c0.6-0.33,1.19-0.67,1.78-1.01
            C130.59,76.26,130.72,76.18,130.86,76.1z" [attr.fill]="changeColor(isSelected('left-armpit'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-shoulder" (click)="backViewModalOnClickSpecificArea('left-shoulder', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftShoulder')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M112.34,81.93c0,0,0-4.55,0.17-10.35c0.12-3.98,0.29-8.87,1.44-14.86c0.54-2.77,1.07-5.46,2.37-8.91
            c0.86-2.27,2.27-5.91,5.3-9.91c1.35-1.78,3.16-4.12,6.26-6.33c3.6-2.56,7.03-3.57,9.7-4.33c2.12-0.61,1.98-0.38,7.5-1.51
            c3.82-0.78,6.11-1.26,8.13-1.8l1.84-0.54l-1.39,3.1c-1.81,4.16-3.6,8.39-5.39,12.69c-1.99,4.8-3.9,9.54-5.74,14.23l-2.76,9.54
            l-0.83,4.49l-0.4,3.58c-3.08,2.26-6.38,4.39-9.9,6.33c-5.41,3-10.91,5.36-16.34,7.09C112.35,83.65,112.34,82.85,112.34,81.93z" [attr.fill]="changeColor(isSelected('left-shoulder'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftArm"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-arm') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-right-arm'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-arm') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightArm' | translate }}</span>  
    <div class="modal_select_container"> 
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-right-arm.png"></image>
        <g id="right-forearm" (click)="backViewModalOnClickSpecificArea('right-forearm', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightForearm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M127.51,166.72c0.58,1.37,1.14,2.93,2.18,5.82c1.68,4.68,1.64,4.92,2.61,7.52c0.8,2.16,1.68,4.14,3.39,8.03
            c2.52,5.7,2.63,5.64,4.26,9.45c0.88,2.06,2.24,5.21,3.76,9.4c0.11,0.31,0.98,2.83,2.71,7.89c1.29,3.78,1.64,4.8,2.16,6.42
            c0.41,1.3,0.77,2.46,1.06,3.42c7.81-2.16,14.71-4.37,20.54-6.38c-0.12-0.43-0.27-0.94-0.45-1.56c-1.32-4.5-1.44-4.51-1.9-6.4
            c-0.18-0.75-0.39-1.67-1.17-6.67c-0.71-4.55-1.06-6.82-1.26-8.58c-0.29-2.55-0.19-2.47-0.68-8.63c-0.2-2.54-0.38-4.49-0.72-8.39
            c-0.45-5.02-0.67-7.56-0.86-9.01c-0.35-2.74-0.73-5.61-1.62-9.36c-0.17-0.7-0.36-1.51-0.6-2.41l0,0L127.51,166.72z" [attr.fill]="changeColor(isSelected('right-forearm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-elbow" (click)="backViewModalOnClickSpecificArea('right-elbow', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightElbow')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M158.86,151.02c-0.53-1.35-1.34-3.36-2.72-5.85c-1.01-1.82-2.01-3.31-3.2-5.09c-1.99-2.97-2-2.7-2.65-3.89
            c-1.23-2.24-1.74-4.2-2.34-6.57c-0.33-1.31-0.59-2.56-1.03-5.3c-0.01-0.06-0.02-0.11-0.03-0.17l0,0l-28.3,7.86l0,0
            c0.19,0.5,0.4,1.04,0.63,1.63c2.43,6.3,2.58,6.72,2.8,7.48c0.38,1.32,1.16,4.03,1.51,7.61c0.26,2.66,0.02,3.01,0.37,6.33
            c0.26,2.48,0.41,3.9,0.96,5.73c0.63,2.08,1.08,2.36,2.48,5.55c0.05,0.12,0.11,0.25,0.16,0.37l33.41-9.44
            C160.45,155.54,159.82,153.45,158.86,151.02z" [attr.fill]="changeColor(isSelected('right-elbow'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-upper-arm" (click)="backViewModalOnClickSpecificArea('right-upper-arm', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightUpperArm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M145.66,115.22c-0.75-6.27-0.61-6.23-1.2-10.66c-0.52-3.89-0.78-5.84-1.31-8.12
            c-0.65-2.77-1.04-3.55-1.41-5.95c-0.24-1.54-0.31-2.78-0.34-4.1c-5.43-1.73-10.93-4.09-16.34-7.09c0.09,0.05,0.17,0.09,0.26,0.14
            c-0.01,0.02-0.01,0.04-0.02,0.06c-1.84,6.68-6.95,11.14-11.61,10.27l-0.39,1.27l-1.1,9.08l-1.1,13.02l1.3,3.94
            c-0.01,0.02-0.01,0.04-0.02,0.06c0.01-0.01,0.02-0.01,0.03-0.02c0.24,0.63,0.61,1.6,1.09,2.77c1.46,3.59,2.2,4.95,3.49,7.98
            c0.14,0.34,0.65,1.65,1.62,4.15l28.3-7.86l0,0C146.26,120.17,145.87,117.02,145.66,115.22z" [attr.fill]="changeColor(isSelected('right-upper-arm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-armpit" (click)="backViewModalOnClickSpecificArea('right-armpit', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightArmpit')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M122.87,78.05c-0.58-0.34-1.16-0.69-1.73-1.04c-0.03-0.02-0.06-0.04-0.08-0.05c-0.56-0.35-1.1-0.7-1.65-1.05
            c-0.11-0.07-0.22-0.14-0.33-0.21c-0.57-0.37-1.14-0.75-1.69-1.14c-0.02-0.01-0.04-0.03-0.06-0.04c-0.55-0.38-1.1-0.77-1.64-1.16
            c-0.07-0.05-0.13-0.1-0.2-0.14c-0.11-0.08-0.23-0.16-0.34-0.24l0.47,4.27v4.82l-0.96,4.49l-0.99,3.23
            c4.66,0.88,9.78-3.59,11.61-10.27c0.01-0.02,0.01-0.04,0.02-0.06c-0.09-0.05-0.17-0.09-0.26-0.14c-0.6-0.33-1.19-0.67-1.78-1.01
            C123.15,78.21,123.01,78.13,122.87,78.05z" [attr.fill]="changeColor(isSelected('right-armpit'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-shoulder" (click)="backViewModalOnClickSpecificArea('right-shoulder', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightShoulder')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M141.39,83.88c0,0,0-4.55-0.17-10.35c-0.12-3.98-0.29-8.87-1.44-14.86c-0.54-2.77-1.07-5.46-2.37-8.91
            c-0.86-2.27-2.27-5.91-5.3-9.91c-1.35-1.78-3.16-4.12-6.26-6.33c-3.6-2.56-7.03-3.57-9.7-4.33c-2.12-0.61-1.98-0.38-7.5-1.51
            c-3.82-0.78-6.11-1.26-8.13-1.8l-1.84-0.54l1.39,3.1c1.81,4.16,3.6,8.39,5.39,12.69c1.99,4.8,3.9,9.54,5.74,14.23l2.76,9.54
            l0.83,4.49l0.4,3.58c3.08,2.26,6.38,4.39,9.9,6.33c5.41,3,10.91,5.36,16.34,7.09C141.39,85.61,141.39,84.8,141.39,83.88z" [attr.fill]="changeColor(isSelected('right-shoulder'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightArm"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-arm') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-left-foot'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-foot') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftFoot' | translate }}</span>  
    <div class="modal_select_container">      
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-left-foot.png"></image>
        <g id="left-ankle" (click)="backViewModalOnClickSpecificArea('left-ankle', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftAnkle')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M104.2,117.58c0,0.7,0,1.39,0,2.09c-0.63,1.05-1.45,2.46-2.36,4.14h54.12c1.26-7.95,1.9-11.98,2.01-12.97
            c0.8-6.87,0.5-12.23,0.32-15.25c-0.4-6.68-1.4-10.49-2.41-15.89c-0.38-2.02-0.69-3.93-0.96-5.78h-49.74
            c-0.2,5.12-0.58,10.78-0.83,20.55C104.22,100,104.09,107.93,104.2,117.58z" [attr.fill]="changeColor(isSelected('left-ankle'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-heel" (click)="backViewModalOnClickSpecificArea('left-heel', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftHeel')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M155.96,123.81h-47.72v53.81c5.39,0.13,6,0.49,9.13,0.47c5.24-0.03,5.74-1.04,12.2-1.44
            c4.46-0.28,4.8,0.17,8.03,0c4.17-0.22,13.24-0.7,18.62-6.9c4.44-5.12,4.36-11.83,4.33-13.96c-0.07-5.74-2.4-12.23-3.37-14.93
            c-0.73-2.04-1.2-2.99-1.61-4.98c-0.86-4.2-0.33-7.51,0.16-10.59C155.81,124.78,155.88,124.29,155.96,123.81z" [attr.fill]="changeColor(isSelected('left-heel'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="top-of-the-left-foot" (click)="backViewModalOnClickSpecificArea('top-of-the-left-foot', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.topOfTheLeftFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M99.71,128.02c-3.12,6.53-2.54,7.16-4.65,10.75c-3.11,5.31-7.16,8.68-10.59,11.56
            c-1.76,1.47-3.68,3.08-6.58,4.82c-4.06,2.44-5.67,2.51-8.83,4.98c-2.04,1.6-3.79,2.96-4.98,5.46c-0.27,0.57-0.51,1.19-0.7,1.84
            h44.86v-43.61h-6.39C101.17,125.06,100.45,126.46,99.71,128.02z" [attr.fill]="changeColor(isSelected('top-of-the-left-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="bottom-of-the-left-foot" (click)="backViewModalOnClickSpecificArea('bottom-of-the-left-foot', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.bottomOfTheLeftFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M64.24,176.01c0.32,0.5,1.5,2.25,3.69,3.05c0.75,0.27,2.03,0.74,3.37,0.16c0.99-0.42,1.53-1.21,1.77-1.61
            c0.6,0.45,1.58,1.1,2.89,1.61c2.88,1.12,5.48,0.82,6.9,0.64c10.72-1.35,14.1-2.08,19.26-2.25c2.18-0.07,4.36-0.03,5.62,0
            c0.18,0,0.34,0.01,0.5,0.01v-10.21H63.38C62.58,170.11,62.54,173.4,64.24,176.01z" [attr.fill]="changeColor(isSelected('bottom-of-the-left-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftFoot"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-foot') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpen && backViewModalGetArea === 'back-right-foot'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-foot') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightFoot' | translate }}</span>  
    <div class="modal_select_container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/male/back/male-back-right-foot.png"></image>
        <g id="right-ankle" (click)="backViewModalOnClickSpecificArea('right-ankle', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightAnkle')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M89.21,97.36c-0.08,2.2-0.17,7.55,0.8,14.45c0.59,4.17,0.81,3.59,1.44,7.87c0.1,0.65,0.19,1.28,0.28,1.9
            h54.43c-0.94-1.93-1.74-3.61-2.38-4.95c0.08-0.82,0.16-1.63,0.24-2.45c1.07-10.83,0.18-18.49-0.45-27.09
            c-0.28-3.85-0.63-9.06-0.92-15.41H92.86c-0.4,2.53-0.98,6.16-1.88,12.04C89.43,93.85,89.29,95.28,89.21,97.36z" [attr.fill]="changeColor(isSelected('right-ankle'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-heel" (click)="backViewModalOnClickSpecificArea('right-heel', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightHeel')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M139.55,121.57H91.74c0.87,6.02,1.32,10.3,0.04,15.12c-0.97,3.69-1.79,3.3-3.05,8.03
            c-0.56,2.1-1.48,5.67-1.28,10.27c0.17,4.03,0.32,7.46,2.57,10.59c2.12,2.95,5,4.05,10.75,6.26c2.44,0.94,5.53,2.12,9.79,2.57
            c3.46,0.36,4.4-0.1,9.31,0.32c4.11,0.36,3.9,0.72,7.06,0.96c5.28,0.4,6.15-0.6,12.52-0.64c0.03,0,0.07,0,0.1,0V121.57z" [attr.fill]="changeColor(isSelected('right-heel'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="top-of-the-right-foot" (click)="backViewModalOnClickSpecificArea('top-of-the-right-foot', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.topOfTheRightFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M139.55,165.18h44.55c-1.14-3.42-3.84-5.64-6.93-8.1c-3.49-2.78-4.85-2.89-8.67-5.3
            c-1.88-1.19-7.79-4.97-12.52-11.24c-0.72-0.95-1.65-2.27-7.38-13.96c-0.89-1.82-1.7-3.49-2.44-5h-6.61V165.18z" [attr.fill]="changeColor(isSelected('top-of-the-right-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="bottom-of-the-right-foot" (click)="backViewModalOnClickSpecificArea('bottom-of-the-right-foot', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.bottomOfTheRightFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="M139.55,175.05c3.33-0.01,5.84,0.25,9.53,0.64c5.13,0.54,10.09,1.39,10.43,1.44c6,1.03,8.57,1.8,11.72,0.48
            c1.49-0.62,2.56-1.48,3.21-2.09c0.39,0.33,1.89,1.53,4.01,1.44c2.18-0.09,3.56-1.48,4.01-1.93c3.12-3.12,2.04-8.32,1.93-8.83
            c-0.08-0.36-0.18-0.71-0.3-1.05h-44.55V175.05z" [attr.fill]="changeColor(isSelected('bottom-of-the-right-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightFoot"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-foot') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<!-- FEMALE -->
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-head'" class="_modal"> 
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button> 
    <h3>{{checkAreaExists('back-head') || checkAreaExists('head') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>  
    <span class="span_area">{{ 'ecClaim.injuryDetails.backHead' | translate }}</span>  
    <div class="modal_select_container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="3 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-head.png"></image>
        <g id="right-temple" (click)="backViewModalOnClickSpecificArea('right-temple', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightTemple')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector" d="m61.59,66.64h-2.61c-3.81,7.72-5.54,14.63-7.02,20.55-3.15,12.63-2.37,18.71-1.98,21.07.67,4.07,1.87,7.33,2.84,9.54l8.77,24.91v-76.07Z" [attr.fill]="changeColor(isSelected('right-temple'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-cheek" (click)="backViewModalOnClickSpecificArea('right-cheek', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightCheek')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_2" d="m171.39,196.22c3.86-4.99,5.46-9.64,6.96-14.1.11-.31.21-.65.32-.99,0-.02.02-.05.02-.07v-16.05l-22.18,28.89,12.59,5c.7-.75,1.48-1.64,2.28-2.68Z" [attr.fill]="changeColor(isSelected('right-cheek'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-cheek" (click)="backViewModalOnClickSpecificArea('left-cheek', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftCheek')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_3" d="m71.01,181.06s.01.04.02.07c1.19,4.19,2.1,6.93,3.55,9.84,1.25,2.51,3.33,6.1,6.79,9.97l14.14-5.62-24.97-27.22.47,12.96Z" [attr.fill]="changeColor(isSelected('left-cheek'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-temple" (click)="backViewModalOnClickSpecificArea('left-temple', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftTemple')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_4" d="m195.81,118.57c.73-1.82,1.63-4.46,2.24-7.74.66-3.57.67-6.31.69-9.37.02-5.07-.45-8.93-.6-10.06-.16-1.23-.87-6.41-3.1-13.41-.86-2.72-2.14-6.66-4.35-11.35h-3.44v75.19l8.57-23.26Z" [attr.fill]="changeColor(isSelected('left-temple'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-ear" (click)="backViewModalOnClickSpecificArea('left-ear', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftEar')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_5" d="m70.53,168.1l-17.71-50.3c-.51.21-1.27.59-1.98,1.29-1.08,1.08-1.5,2.36-1.72,3.35-1.28,5.7-.34,12.47-.34,12.47.59,4.31,1.04,7.54,1.44,9.97.26,1.57.49,2.67.77,3.68,0,0,.21.76.45,1.48.61,1.81,2.67,5.41,5.93,9.72.2.81.56,2,1.2,3.35,1.09,2.29,2.17,4.56,4.3,5.16,1.86.53,3.68-.46,4.56-1.03.52,2.29,1.31,5.63,2.41,9.63.43,1.56.81,2.94,1.16,4.19l-.47-12.96Z" [attr.fill]="changeColor(isSelected('left-ear'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-ear" (click)="backViewModalOnClickSpecificArea('right-ear', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightEar')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_6" d="m198.91,118.32c-.48-.16-1.1-.07-2.15.09-.39.06-.72.12-.95.17l-17.11,46.43v16.05c1.15-3.61,2.37-8.38,3.01-14.16.26.31.87.96,1.89,1.29,1.21.4,2.23.1,2.58,0,1.52-.46,2.42-1.64,3.01-2.49,2.15-3.1,2.92-5.62,4.13-8.17,1.6-3.37,2.11-2.87,3.61-5.85,1.68-3.35,2.1-6.13,2.92-11.69,1.07-7.18-.15-5.78.86-11.61,0,0,.75-4.36-.34-8-.26-.88-.68-1.8-1.46-2.06Z" [attr.fill]="changeColor(isSelected('right-ear'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-head" (click)="backViewModalOnClickSpecificArea('back-of-the-head', 'back-head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_7" d="m175.39,66.64v-21.62c-.27-.27-.54-.55-.81-.83-1.06-1.05-2.39-2.36-4.03-3.8h-91.31c-1.17,1.11-2.28,2.27-3.46,3.55-.86.94-1.66,1.81-2.42,2.64v20.06h-11.76v76.07l8.94,25.4,24.97,27.22,1.86-.74,7.39-1.55h45.23l6.54.86,22.18-28.89,8.54-23.18v-75.19h-11.85Z" [attr.fill]="changeColor(isSelected('back-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-of-the-head" (click)="backViewModalOnClickSpecificArea('left-of-the-head', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_8" d="m73.35,66.64v-20.06c-4.66,5.09-7.6,8.52-10.73,13.52-1.4,2.24-2.6,4.42-3.64,6.54h14.37Z" [attr.fill]="changeColor(isSelected('left-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-of-the-head" (click)="backViewModalOnClickSpecificArea('right-of-the-head', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_9" d="m187.04,59.84c-1-1.66-4.88-7.92-11.65-14.82v21.62h15.3c-1-2.13-2.2-4.4-3.64-6.79Z" [attr.fill]="changeColor(isSelected('right-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="top-of-the-head" (click)="backViewModalOnClickSpecificArea('top-of-the-head', 'head')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.topOfTheHead')" (mouseleave)="backViewModalHideToolTip()">
          <path id="Vector_10" d="m133.99,26.65c-2.61.54-4.78,1.72-6.62,2.67-.98.5-1.77.96-2.32,1.29-1.22-.96-2.99-2.13-5.33-3.01-3.88-1.45-7.23-1.25-9.29-1.12-2.53.17-4.27.66-7.74,1.63-5.04,1.42-8.73,3.05-10.15,3.7-2.36,1.08-5.28,2.42-8.6,4.73-1.77,1.23-3.25,2.47-4.64,3.78h91.17c-2.55-2.25-5.83-4.83-10-7.31-5.93-3.53-10.32-4.87-12.38-5.42-3.06-.82-5.48-1.09-7.05-1.2-2.17-.15-4.32-.31-7.05.26Z" [attr.fill]="changeColor(isSelected('top-of-the-head'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectHead"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-head') || checkAreaExists('head') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" stroke="white" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-neck-trunk'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button> 
    <h3>{{checkAreaExists('neck-trunk') || checkAreaExists('back-neck-trunk') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backNeckTrunk' | translate }}</span>  
    <div class="modal_select_container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
      <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-neck-trunk.png"></image>
      <g id="back-of-the-neck" (click)="backViewModalOnClickSpecificArea('back-of-the-neck', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheNeck')" (mouseleave)="backViewModalHideToolTip()">
        <path id="Vector" d="m94.98,55.81h60.42v-.04c-.16,0-.31,0-.46.01l-.18-.53c-.98-.19-1.95-.39-2.93-.58-.13.13-.25.27-.38.4-3.14-1.16-5.52-2.53-7.16-3.61-2.34-1.54-3.66-2.82-4.57-3.86-1.5-1.72-1.96-2.86-2.12-3.28-.41-1.1-.47-1.95-.58-3.56-.13-1.87-.02-3.31.08-4.49.08-1.02.24-2.36.53-3.93.58-.65,1.03-1.28,1.38-1.83-.1.15-.21.3-.33.45-.24.31-.46.57-.67.79l-3.69-1.47-1.92-.25h-13.27l-2.17.45-4.63,1.84s0,.02,0,.03c.15.83.37,2.25.5,4,.22,2.97.07,5.62,0,5.62-.02,0-.01-.24-.08-.25-.22-.05-.58,2.64-1.74,4.79-.98,1.81-2.29,2.78-4.92,4.72-1.13.83-2.78,2.03-5.25,3-1.2.47-2.25.76-3.03.93-.18-.11-.35-.22-.53-.33-.8.08-1.6.17-2.4.25l-.18.55.25.13s0,0,0,.03Z" [attr.fill]="changeColor(isSelected('back-of-the-neck'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
      </g>
      <g id="upper-back" (click)="backViewModalOnClickSpecificArea('upper-back', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.upperBack')" (mouseleave)="backViewModalHideToolTip()">
        <path id="Vector_2" d="m156.05,130.49c.4-2.56.59-3.75.83-5.2.24-1.4.44-2.55.58-3.33.11-.09.22-.18.33-.28.09-.45.21-1.11.35-1.92.44-2.52.69-4.46.88-5.95.15-1.15.33-2.65.5-4.41,1.7-3.94,2.01-6.41,1.94-7.97-.02-.41-.11-1.95-.05-4.09.02-.65.05-1.23.08-1.7l-.41-2.01v-11.64l-.61-1.82-2.62-6.59-1.61-5.72-.86-12.07h-60.42c-.07.54-1.21,10.04-1.36,10.24-.03.03-.08.18-.14.41h0s-.05.16-.05.16c-.36,1.23-1.07,4.03-1.07,4.03l-.62,1.63-1.46,4.9-1.75,6.32.3,8.07-.51,3.68.18.5c.51.09-.55,4.37.38,8.88.41,1.99,1,3.71,1.59,5.12.04.38.1.94.18,1.61.2,1.76.42,3.72.73,5.98.16,1.14.39,2.69.71,4.54.1.13.2.25.3.38.25,1.1.63,2.91,1.01,5.17.38,2.24.47,3.27.91,6.23.21,1.42.36,2.31.47,2.93h60.34c.02-.12.03-.24.05-.36.1-.72.36-2.39.88-5.73Z" [attr.fill]="changeColor(isSelected('upper-back'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
      </g>
      <g id="lower-back" (click)="backViewModalOnClickSpecificArea('lower-back', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.lowerBack')" (mouseleave)="backViewModalHideToolTip()">
        <path id="Vector_3" d="m159.84,164.02c-1.11-2.82-1.21-2.76-2.14-5.2-.4-1.05-1.1-2.95-1.84-5.37-.83-2.72-1.25-4.08-1.44-5.75-.19-1.64-.13-2.82-.03-4.77.1-1.85.31-3.42.73-6.35h-60.34c.22,1.22.29,1.44.44,2.69.31,2.58.64,5.31.23,8.75-.34,2.82-1.03,4.94-1.64,6.78-.82,2.46-1.09,2.55-2.72,6.58-.38.94-1.63,4.06-2.98,8.1-.01.04-.02.08-.04.11.25-.71.47-1.34.67-1.9,2,3.34,3.48,7.82,4.18,12.91h64.47c.67-4.89,2.07-9.21,3.94-12.51-.67-1.91-1.09-3.06-1.5-4.09Z" [attr.fill]="changeColor(isSelected('lower-back'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
      </g>
      <g id="buttock" (click)="backViewModalOnClickSpecificArea('buttock', 'back-neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.buttock')" (mouseleave)="backViewModalHideToolTip()">
        <path id="Vector_4" d="m160.23,204.38c-2.06-4.55-3.3-10.52-3.3-17.05,0-2.32.16-4.57.45-6.71h-64.47c.29,2.14.45,4.39.45,6.71,0,6.63-1.28,12.68-3.4,17.26-.62,1.35-1.32,2.58-2.08,3.66l1.59,1.61,4.64,4.54,5.17,4.29,3.15,2.07,5.1,3,3.81,1.99,3.63,1.14h1.31l2.4-.55,1.92-.78,1.24-1.31.38-1.03c.09.3.2.73.33,1.24.02.06.04.15.06.26-.15-.77-.3-1.44-.44-2,.1-.3.28-.74.63-1.19.24-.31.5-.53.71-.68.52.02,1.07.02,1.64,0,.46-.01.91-.04,1.34-.08.35.47.71.94,1.06,1.41.04.28.08.56.12.84,0,0,0-.01,0-.02-.06.28-.13.58-.2.91l1.61,1.46,3.3,1.41h3.63l5.78-2.15,5.65-3.7,5.72-4.03,5.38-4.85,3.73-3.97c-.75-1.08-1.43-2.32-2.04-3.67Z" [attr.fill]="changeColor(isSelected('buttock'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
      </g>
      <g id="right-hip" (click)="backViewModalOnClickSpecificArea('right-hip', 'neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightHip')" (mouseleave)="backViewModalHideToolTip()">
        <path id="Vector_5" d="m168.51,209.09c-.32-2.98-.59-5.44-.78-7.14.13-.1.25-.2.38-.3-.12-1.14-.29-2.83-.5-4.87-.03-.25-.05-.49-.08-.72-.65-6.18-.85-7.68-.97-8.44-.01-.09-.03-.17-.04-.25-.1-.64-.06-.3-1.26-6.73-.24-1.31-.49-2.64-.71-3.78l-.71-.73c-.5-1.8-1.16-4.05-2.02-6.63-.16-.48-.32-.95-.48-1.41-2.71,4.74-4.41,11.6-4.41,19.24,0,6.53,1.25,12.5,3.3,17.05,2.17,4.81,5.24,8.04,8.7,8.7-.13-1.17-.27-2.5-.43-3.99Z" [attr.fill]="changeColor(isSelected('right-hip'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
      </g>
      <g id="left-hip" (click)="backViewModalOnClickSpecificArea('left-hip', 'neck-trunk')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftHip')" (mouseleave)="backViewModalHideToolTip()">
        <path id="Vector_6" d="m88.74,167.7c-.23.65-.49,1.38-.79,2.24-.55,1.55-.93,2.67-1.31,4.24-.22.89-.36,1.64-.45,2.17-.27.22-.54.44-.81.66-.11.55-.27,1.35-.45,2.32-.21,1.07-.6,3.09-.88,4.59-.29,1.56-.51,2.74-.69,3.71-.17.97-.3,1.74-.4,2.5-.25,1.82-.24,2.35-.58,6.03,0,0,0,.01,0,.02-.22,2.39-.42,4.33-.55,5.55l.2.38c-.1,1.63-.28,4.1-.55,7.11-.15,1.67-.28,2.89-.4,3.91,3.53-.52,6.68-3.71,8.91-8.54,2.11-4.58,3.4-10.63,3.4-17.26,0-7.84-1.8-14.87-4.63-19.62Z" [attr.fill]="changeColor(isSelected('left-hip'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
      </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectNeckTrunk"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('neck-trunk') || checkAreaExists('back-neck-trunk') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" stroke="white" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-left-hand'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-hand') || checkAreaExists('left-hand') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>  
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftHand' | translate }}</span>    
    <div class="modal_select_container">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-left-hand.png"></image>
        <g id="left-wrist" (click)="backViewModalOnClickSpecificArea('left-wrist', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftWrist')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m140.55,30.76c-1.77,5.77-3.71,11.48-7.87,17.98-1.33,2.08-2.65,3.87-3.83,5.35l50.53,19.7.08.05s0-.03,0-.05c.61-1.6,1.5-4,2.53-6.92,1.08-3.07,1.91-5.54,2.61-7.73.97-3.24,1.52-5.5,1.7-6.21.83-3.28.06-1.45,1.69-5.58l-47.22-16.44-.22-.15Z" [attr.fill]="changeColor(isSelected('left-wrist'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-knuckles" (click)="backViewModalOnClickSpecificArea('left-knuckles', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftKnuckles')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m151.22,147.96c.64-.48,1.43-.9,2.31-.7.27.06.51.18.72.31l15.55-2.86c1.22-3.99,1.87-4.36,3.12-8.95,1.18-4.35,1.07-5.78,2.05-12.7l-21.21,6.46h-20.92l-13.12-3.12-13.25-6.89-8.9-5.85c-1.26,4.36-1.92,6.77-2.21,9.91-.36,3.85.13,4.6-.39,7.89-.08.49-.16.93-.25,1.35l16.23,10.75.08.02c.3.05.58.13.85.26l20.73,6.26,18.62-2.15Z" [attr.fill]="changeColor(isSelected('left-knuckles'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-left-hand" (click)="backViewModalOnClickSpecificArea('back-of-the-left-hand', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheLeftHand')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m97.89,112.57c-.11.38-.22.75-.32,1.11l8.9,5.85,13.25,6.89,13.12,3.12h20.92l21.21-6.46c.14-.98.3-2.06.49-3.28.9-5.87.97-5.14,2.24-13.06,1.1-6.88,1.66-10.4,1.95-14.33.39-5.27.18-10.17.1-12.77-.08-2.37-.19-4.36-.29-5.8l-.08-.05-50.53-19.7c-.15.18-.29.35-.43.53l.52,7.99v9.88l-2.21,7.41-2.53,4.81-4.61,4.94-9.75,4.16-9.35,2.29-.2.22c.34-.01.65-.02.91-.01,0,.98-.01,2.44-.19,4.19-.46,4.43-1.47,6.4-3.12,12.08Z" [attr.fill]="changeColor(isSelected('back-of-the-left-hand'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-thumb', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m128.42,54.62c-.69.85-1.33,1.59-1.88,2.21-7.12,3.18-12.91,6.36-17.25,8.97-9.33,5.59-13.51,9.4-14.62,10.43-2.97,2.75-5.2,5.32-6.72,7.21-4.73,3.39-8.32,6.38-10.72,8.48-.21.19-.4.35-.6.53l9.07,13.56c1.35-1.25,3.11-2.91,5.36-5.02,1.83-1.72,3.87-3.64,7.11-4.39.8-.18,1.51-.25,2.11-.28l.2-.22,9.35-2.29,9.75-4.16,4.61-4.94,2.53-4.81,2.21-7.41v-9.88l-.52-7.99Z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-thumb" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-thumb', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m68.95,108.09v-2.44l-.68-5.07-.32-.56c-1.92,1.49-3.63,3.24-5.53,4.75-.55.44-1.48,1.17-1.85,2.44-.58,1.99.67,3.87.88,4.19.29.43.61.79.95,1.09l3.82-1.94,2.73-2.47Z" [attr.fill]="changeColor(isSelected('nail-of-the-left-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-thumb', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m76.63,92.44c-4.78,4.23-4.55,4.72-7.59,6.78-.38.25-.74.53-1.1.8l.32.56.68,5.07v2.44l-2.73,2.47-3.82,1.94c1.2,1.09,2.62,1.51,3.43,1.73,1.25.34,3.25.64,6.92-.39,3.1-.87,6.3-2.37,9.65-4.87.44-.33,1.5-1.29,3.31-2.97l-9.07-13.56Z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-little-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-little-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m157.53,187.28h-2.4l-1.62,1.1v3.8c.06.1.12.2.19.29.86,1.07,2.43,1.91,4.19,1.66.99-.14,1.88-.62,2.57-1.31v-3.46l-.58-1.17-2.34-.91Z" [attr.fill]="changeColor(isSelected('nail-of-the-left-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-little-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m153.14,177.77c-.24,3.4,0,6.83-.32,10.23-.1,1-.27,2.75.68,4.19v-3.8l1.62-1.1h2.4l2.34.91.58,1.17v3.46c.45-.45.8-1,1.04-1.61.59-1.83,1.43-4.63,2.24-8.09.4-1.72.72-3.3,1-4.81l-11.59-.55Z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-little-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m153.44,176.01c-.08.58-.13,1.17-.17,1.76l11.59.55c.94-5.23,1.25-9.57,1.97-15.56l-12.08-.35c-.67,7.5-1.02,11.33-1.31,13.6Z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-little-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m155.68,149.41c-.27,5.11-.61,9.34-.88,12.38-.02.22-.04.42-.06.63l12.08.35c.05-.42.1-.83.15-1.27,1.42-11.33,1.87-13.45,2.53-15.79.1-.36.2-.68.29-.99l-15.55,2.86c.9.57,1.34,1.63,1.42,1.83Z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-ring-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-ring-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m129.14,204.69l-2.73.45-1.69,2.01-.26,3.2c.27.63.63,1.2,1.14,1.7,1.07,1.07,2.76,1.84,4.58,1.66,1.4-.14,2.48-.89,3.31-1.74v-4.17l-1.69-2.08-2.66-1.04Z" [attr.fill]="changeColor(isSelected('nail-of-the-left-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-ring-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m127,191.27c-.16.86-.27,1.36-.43,1.98-1.14,4.61-1.88,5.58-2.34,9.16-.36,2.81-.72,5.71.23,7.94l.26-3.2,1.69-2.01,2.73-.45,2.66,1.04,1.69,2.08v4.17c.67-.68,1.18-1.42,1.56-1.97,1.8-2.62,1.98-4.89,2.73-9.36,0,0,.61-3.61,1.51-7.72l-12.29-1.67Z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-ring-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m128.1,183.9c-.68,4.17-1,6.14-1.23,7.37l12.29,1.67c.14-.64.29-1.28.44-1.93,1.7-7.22,2.77-7.25,4.19-13.64.33-1.5.6-3.11.9-5.33l-14.5-1.01c-.33,1.99-.92,5.67-2.09,12.87Z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-ring-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m131.58,149.81l-.11.59c.32.08.74.29,1.21.96,1.64,2.35,1.17,5.94,1.17,5.94-.61,4.61-2.11,7.94-3.12,11.01-.14.43-.2.62-.54,2.73l14.5,1.01c.22-1.59.45-3.48.76-5.88.5-4,1.54-7.92,2.24-11.89.28-1.57.67-3.84,2.53-5.46.26-.23.6-.56,1-.86l-18.62,2.15-1.02-.31Z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-middle-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-middle-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m101.33,208.72l-2.4-.39-1.88,1.23-1,2.24c-.04,1.71.21,3.2,1.26,4.26.92.93,2.16,1.26,2.92,1.46.72.19,2.9.77,4.68-.19.08-.04.14-.09.21-.14l.63-2.82v-1.82l-.97-1.88-3.44-1.95Z" [attr.fill]="changeColor(isSelected('nail-of-the-left-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-middle-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m99.76,194.55c-.38,1.06-.8,2.31-1.29,3.88-.13.43-1.53,4.99-2.24,11.01-.1.82-.17,1.61-.19,2.37l1-2.24,1.88-1.23,2.4.39,3.44,1.95.97,1.88v1.82l-.63,2.82c1.48-.93,2.03-2.64,2.42-3.86,1.19-3.69,2.92-7.19,4-10.91.25-.86.73-2.51,1.38-4.58l-13.15-3.29Z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-middle-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m103.83,178.06c-.76,3.62-.57,4.42-1.07,7.31-.68,3.92-1.43,4.81-3,9.18l13.15,3.29c.67-2.1,1.52-4.63,2.51-7.22,2.38-6.22,3.14-6.45,4.78-11.5.38-1.19.59-1.94.75-2.62l-15.65-4.06c-.89,2.88-1.32,4.88-1.47,5.61Z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-middle-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m112.8,144.53c1.1,1.26.5,3.39.1,4.87-2.75,10.04-1.41,6.96-2.73,10.52-2.05,5.56-2.45,5.31-4.09,10.14-.29.85-.54,1.64-.77,2.38l15.65,4.06c.34-1.45.48-2.56,1.68-6.83,2.85-10.09,3.21-7.77,3.9-11.89.21-1.24.72-4.75,2.73-6.53.34-.3,1.2-1.07,2.14-.88.02,0,.04.01.06.02l.11-.59-19.71-5.95c.37.17.69.4.93.67Z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-left-index-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-left-index-finger', 'back-left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheLeftIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m83.66,189.04l-2.4-.91-2.47,1.17-1.71,2.9c0,.75.1,1.37.35,2.03.66,1.77,1.9,2.79,2.34,3.12.49.37,2.22,1.68,4.09,1.36.48-.08.93-.27,1.4-.63l.49-.93.45-4.09-.58-2.08-1.95-1.95Z" [attr.fill]="changeColor(isSelected('nail-of-the-left-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-distal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('left-distal-phalanx-of-the-index-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftDistalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m80.39,176.49c-.59,1.83-1.05,3.48-1.41,4.88-.16.64-.53,2.57-1.27,6.43-.39,2.06-.63,3.36-.64,4.4l1.71-2.9,2.47-1.17,2.4.91,1.95,1.95.58,2.08-.45,4.09-.49.93c.68-.52,1.41-1.39,2.4-2.88,2.03-3.03,4.93-7.38,5.85-13.74,0-.04,0-.07.01-.1l-13.13-4.87Z" [attr.fill]="changeColor(isSelected('left-distal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-middle-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('left-middle-phalanx-of-the-index-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftMiddlePhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m101.1,163.83c.05-.17.09-.32.13-.47l-15.36-5.61c-1.11,2.04-2.1,3.71-2.8,6.56-.67,2.72-.74,5.07-.68,6.63-.8,2-1.46,3.86-2,5.55l13.13,4.87c.16-1.18.17-1.76.47-3.02.51-2.1,1.23-3.54,3.12-6.92,3.05-5.46,3.45-5.79,4-7.6Z" [attr.fill]="changeColor(isSelected('left-middle-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-proximal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('left-proximal-phalanx-of-the-index-finger', 'left-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftProximalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m101.98,158.76c.83-3.68,1.35-3.16,3.51-9.36.54-1.54,1.12-3.34,2.73-4.78.97-.87,1.7-1.05,2.24-1.07.19,0,.37.02.56.04l-.08-.02-16.23-10.75c-.51,2.32-1.3,3.8-2.87,7.91-3.91,10.28-2.49,10.36-5.36,15.88-.21.4-.41.77-.61,1.14l15.36,5.61c.46-1.7.1-1.77.74-4.6Z" [attr.fill]="changeColor(isSelected('left-proximal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftHand"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-hand') || checkAreaExists('left-hand') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-right-hand'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-hand') || checkAreaExists('right-hand') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>    
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightHand' | translate }}</span>  
    <div class="modal_select_container">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-right-hand.png"></image>
        <g id="right-wrist" (click)="backViewModalOnClickSpecificArea('right-wrist', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightWrist')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m110.76,30.6c1.77,5.77,3.71,11.48,7.87,17.98,1.33,2.08,2.65,3.87,3.83,5.35l-50.53,19.7-.08.05s0-.03,0-.05c-.61-1.6-1.5-4-2.53-6.92-1.08-3.07-1.91-5.54-2.61-7.73-.97-3.24-1.52-5.5-1.7-6.21-.83-3.28-.06-1.45-1.69-5.58l47.22-16.44.22-.15Z" [attr.fill]="changeColor(isSelected('right-wrist'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-knuckles" (click)="backViewModalOnClickSpecificArea('right-knuckles', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightKnuckles')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m100.09,147.8c-.64-.48-1.43-.9-2.31-.7-.27.06-.51.18-.72.31l-15.55-2.86c-1.22-3.99-1.87-4.36-3.12-8.95-1.18-4.35-1.07-5.78-2.05-12.7l21.21,6.46h20.92s13.12-3.12,13.12-3.12l13.25-6.89,8.9-5.85c1.26,4.36,1.92,6.77,2.21,9.91.36,3.85-.13,4.6.39,7.89.08.49.16.93.25,1.35l-16.23,10.75-.08.02c-.3.05-.58.13-.85.26l-20.73,6.26-18.62-2.15Z" [attr.fill]="changeColor(isSelected('right-knuckles'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-right-hand" (click)="backViewModalOnClickSpecificArea('back-of-the-right-hand', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheRightHand')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m153.42,112.41c.11.38.22.75.32,1.11l-8.9,5.85-13.25,6.89-13.12,3.12h-20.92s-21.21-6.46-21.21-6.46c-.14-.98-.3-2.06-.49-3.28-.9-5.87-.97-5.14-2.24-13.06-1.1-6.88-1.66-10.4-1.95-14.33-.39-5.27-.18-10.17-.1-12.77.08-2.37.19-4.36.29-5.8l.08-.05,50.53-19.7c.15.18.29.35.43.53l-.52,7.99v9.88s2.21,7.41,2.21,7.41l2.53,4.81,4.61,4.94,9.75,4.16,9.35,2.29.2.22c-.34-.01-.65-.02-.91-.01,0,.98.01,2.44.19,4.19.46,4.43,1.47,6.4,3.12,12.08Z" [attr.fill]="changeColor(isSelected('back-of-the-right-hand'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-thumb', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m122.89,54.46c.69.85,1.33,1.59,1.88,2.21,7.12,3.18,12.91,6.36,17.25,8.97,9.33,5.59,13.51,9.4,14.62,10.43,2.97,2.75,5.2,5.32,6.72,7.21,4.73,3.39,8.32,6.38,10.72,8.48.21.19.4.35.6.53l-9.07,13.56c-1.35-1.25-3.11-2.91-5.36-5.02-1.83-1.72-3.87-3.64-7.11-4.39-.8-.18-1.51-.25-2.11-.28l-.2-.22-9.35-2.29-9.75-4.16-4.61-4.94-2.53-4.81-2.21-7.41v-9.88s.52-7.99.52-7.99Z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-thumb" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-thumb', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m182.37,107.93v-2.44s.68-5.07.68-5.07l.32-.56c1.92,1.49,3.63,3.24,5.53,4.75.55.44,1.48,1.17,1.85,2.44.58,1.99-.67,3.87-.88,4.19-.29.43-.61.79-.95,1.09l-3.82-1.94-2.73-2.47Z" [attr.fill]="changeColor(isSelected('nail-of-the-right-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-thumb" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-thumb', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheThumb')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m174.68,92.28c4.78,4.23,4.55,4.72,7.59,6.78.38.25.74.53,1.1.8l-.32.56-.68,5.07v2.44s2.73,2.47,2.73,2.47l3.82,1.94c-1.2,1.09-2.62,1.51-3.43,1.73-1.25.34-3.25.64-6.92-.39-3.1-.87-6.3-2.37-9.65-4.87-.44-.33-1.5-1.29-3.31-2.97l9.07-13.56Z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-thumb'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-little-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-little-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m93.78,187.12h2.4s1.62,1.1,1.62,1.1v3.8c-.06.1-.12.2-.19.29-.86,1.07-2.43,1.91-4.19,1.66-.99-.14-1.88-.62-2.57-1.31v-3.46s.58-1.17.58-1.17l2.34-.91Z" [attr.fill]="changeColor(isSelected('nail-of-the-right-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-little-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m98.17,177.61c.24,3.4,0,6.83.32,10.23.1,1,.27,2.75-.68,4.19v-3.8s-1.62-1.1-1.62-1.1h-2.4s-2.34.91-2.34.91l-.58,1.17v3.46c-.45-.45-.8-1-1.04-1.61-.59-1.83-1.43-4.63-2.24-8.09-.4-1.72-.72-3.3-1-4.81l11.59-.55Z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-little-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m97.88,175.85c.08.58.13,1.17.17,1.76l-11.59.55c-.94-5.23-1.25-9.57-1.97-15.56l12.08-.35c.67,7.5,1.02,11.33,1.31,13.6Z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-little-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-little-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheLittleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m95.63,149.25c.27,5.11.61,9.34.88,12.38.02.22.04.42.06.63l-12.08.35c-.05-.42-.1-.83-.15-1.27-1.42-11.33-1.87-13.45-2.53-15.79-.1-.36-.2-.68-.29-.99l15.55,2.86c-.9.57-1.34,1.63-1.42,1.83Z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-little-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-ring-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-ring-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m122.17,204.54l2.73.45,1.69,2.01.26,3.2c-.27.63-.63,1.2-1.14,1.7-1.07,1.07-2.76,1.84-4.58,1.66-1.4-.14-2.48-.89-3.31-1.74v-2.03s0-2.14,0-2.14l1.69-2.08,2.66-1.04Z" [attr.fill]="changeColor(isSelected('nail-of-the-right-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-ring-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m124.31,191.12c.16.86.27,1.36.43,1.98,1.14,4.61,1.88,5.58,2.34,9.16.36,2.81.72,5.71-.23,7.94l-.26-3.2-1.69-2.01-2.73-.45-2.66,1.04-1.69,2.08v2.14s0,2.03,0,2.03c-.67-.68-1.18-1.42-1.56-1.97-1.8-2.62-1.98-4.89-2.73-9.36,0,0-.61-3.61-1.51-7.72l12.29-1.67Z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-ring-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m123.21,183.75c.68,4.17,1,6.14,1.23,7.37l-12.29,1.67c-.14-.64-.29-1.28-.44-1.93-1.7-7.22-2.77-7.25-4.19-13.64-.33-1.5-.6-3.11-.9-5.33l14.5-1.01c.33,1.99.92,5.67,2.09,12.87Z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-ring-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-ring-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheRingFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m119.74,149.65l.11.59c-.32.08-.74.29-1.21.96-1.64,2.35-1.17,5.94-1.17,5.94.61,4.61,2.11,7.94,3.12,11.01.14.43.2.62.54,2.73l-14.5,1.01c-.22-1.59-.45-3.48-.76-5.88-.5-4-1.54-7.92-2.24-11.89-.28-1.57-.67-3.84-2.53-5.46-.26-.23-.6-.56-1-.86l18.62,2.15,1.02-.31Z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-ring-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-middle-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-middle-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m149.98,208.56l2.4-.39,1.88,1.23,1,2.24c.04,1.71-.21,3.2-1.26,4.26-.92.93-2.16,1.26-2.92,1.46-.72.19-2.9.77-4.68-.19-.08-.04-.14-.09-.21-.14l-.63-2.82v-1.82s.97-1.88.97-1.88l3.44-1.95Z" [attr.fill]="changeColor(isSelected('nail-of-the-right-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-middle-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m151.55,194.39c.38,1.06.8,2.31,1.29,3.88.13.43,1.53,4.99,2.24,11.01.1.82.17,1.61.19,2.37l-1-2.24-1.88-1.23-2.4.39-3.44,1.95-.97,1.88v1.82s.63,2.82.63,2.82c-1.48-.93-2.03-2.64-2.42-3.86-1.19-3.69-2.92-7.19-4-10.91-.25-.86-.73-2.51-1.38-4.58l13.15-3.29Z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-middle-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m147.48,177.9c.76,3.62.57,4.42,1.07,7.31.68,3.92,1.43,4.81,3,9.18l-13.15,3.29c-.67-2.1-1.52-4.63-2.51-7.22-2.38-6.22-3.14-6.45-4.78-11.5-.38-1.19-.59-1.94-.75-2.62l15.65-4.06c.89,2.88,1.32,4.88,1.47,5.61Z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-middle-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-middle-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheMiddleFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m138.51,144.37c-1.1,1.26-.5,3.39-.1,4.87,2.75,10.04,1.41,6.96,2.73,10.52,2.05,5.56,2.45,5.31,4.09,10.14.29.85.54,1.64.77,2.38l-15.65,4.06c-.34-1.45-.48-2.56-1.68-6.83-2.85-10.09-3.21-7.77-3.9-11.89-.21-1.24-.72-4.75-2.73-6.53-.34-.3-1.2-1.07-2.14-.88-.02,0-.04.01-.06.02l-.11-.59,19.71-5.95c-.37.17-.69.4-.93.67Z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-middle-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="nail-of-the-right-index-finger" (click)="backViewModalOnClickSpecificArea('nail-of-the-right-index-finger', 'back-right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.nailOfTheRightIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m167.65,188.88l2.4-.91,2.47,1.17,1.71,2.9c0,.75-.1,1.37-.35,2.03-.66,1.77-1.9,2.79-2.34,3.12-.49.37-2.22,1.68-4.09,1.36-.48-.08-.93-.27-1.4-.63l-.49-.93-.45-4.09.58-2.08,1.95-1.95Z" [attr.fill]="changeColor(isSelected('nail-of-the-right-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-distal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('right-distal-phalanx-of-the-index-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightDistalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m170.92,176.33c.59,1.83,1.05,3.48,1.41,4.88.16.64.53,2.57,1.27,6.43.39,2.06.63,3.36.64,4.4l-1.71-2.9-2.47-1.17-2.4.91-1.95,1.95-.58,2.08.45,4.09.49.93c-.68-.52-1.41-1.39-2.4-2.88-2.03-3.03-4.93-7.38-5.85-13.74,0-.04,0-.07-.01-.1l13.13-4.87Z" [attr.fill]="changeColor(isSelected('right-distal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-middle-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('right-middle-phalanx-of-the-index-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightMiddlePhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m150.21,163.67c-.05-.17-.09-.32-.13-.47l15.36-5.61c1.11,2.04,2.1,3.71,2.8,6.56.67,2.72.74,5.07.68,6.63.8,2,1.46,3.86,2,5.55l-13.13,4.87c-.16-1.18-.17-1.76-.47-3.02-.51-2.1-1.23-3.54-3.12-6.92-3.05-5.46-3.45-5.79-4-7.6Z" [attr.fill]="changeColor(isSelected('right-middle-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-proximal-phalanx-of-the-index-finger" (click)="backViewModalOnClickSpecificArea('right-proximal-phalanx-of-the-index-finger', 'right-hand')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightProximalPhalanxOfTheIndexFinger')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m149.33,158.6c-.83-3.68-1.35-3.16-3.51-9.36-.54-1.54-1.12-3.34-2.73-4.78-.97-.87-1.7-1.05-2.24-1.07-.19,0-.37.02-.56.04l.08-.02,16.23-10.75c.51,2.32,1.3,3.8,2.87,7.91,3.91,10.28,2.49,10.36,5.36,15.88.21.4.41.77.61,1.14l-15.36,5.61c-.46-1.7-.1-1.77-.74-4.6Z" [attr.fill]="changeColor(isSelected('right-proximal-phalanx-of-the-index-finger'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightHand"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-hand') || checkAreaExists('right-hand') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-left-leg'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-leg') || checkAreaExists('left-leg') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftLeg' | translate }}</span>   
    <div class="modal_select_container">      
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-left-leg.png"></image>
        <g id="left-calf" (click)="backViewModalOnClickSpecificArea('left-calf', 'back-left-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftCalf')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m141.83,151.65c-.01-.4-.02-.82-.03-1.24h-26.09c-.12.75-.23,1.59-.34,2.63-.26,2.51-.32,4.44-.38,6.15-.06,1.86-.11,5.17.13,9.46.36,6.39,1.16,11.35,1.94,16.07.84,5.05,1.65,8.88,3.18,16.12,1.15,5.43,1.66,7.5,2.35,11.75.21,1.3.56,3.97,1.26,9.26,1.1,8.32,1.18,9.22,1.24,10.37.02.46.04.96.05,1.49h13.45s0,0,0,0c-.1-1.61-.11-3.53-.11-6.53,0-2.68.07-3.27.25-8.88.24-7.57.15-7.79.33-10.74.05-.81.11-1.52,1.01-9.86.63-5.87.96-8.85,1.19-10.77.96-7.99,1.07-7.2,1.31-10.29.43-5.41.25-9.71.08-13.42-.28-6.01-.65-5.91-.81-11.55Z" [attr.fill]="changeColor(isSelected('left-calf'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="back-of-the-left-knee" (click)="backViewModalOnClickSpecificArea('back-of-the-left-knee', 'left-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheLeftKnee')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m115.52,108.42c.63,3.24,1.14,5.88,1.61,9.43.23,1.73.53,3.96.73,6.94.15,2.26.37,5.52.15,9.64-.18,3.33-.56,5.77-1.03,8.83-.56,3.57-.95,5.03-1.28,7.16h26.09c-.06-2.71-.08-5.82.28-9.71.38-4.09.72-4.17,1.08-8.12.16-1.74.27-3.61.23-10.9-.03-4.9-.11-8.91-.2-12.99-.02-.93-.04-1.75-.06-2.48h-28.05c.23,1.13.38,1.89.44,2.2Z" [attr.fill]="changeColor(isSelected('back-of-the-left-knee'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-thigh" (click)="backViewModalOnClickSpecificArea('left-thigh', 'left-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftThigh')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m146.12,41s-.04-3.55-.53-7.06c-.27-1.96-.61-3.36-.71-3.76-.13-.51-.24-.93-.33-1.24l-.38,1.03-1.24,1.31-1.92.78-2.4.55h-1.31l-3.63-1.14-3.81-1.99-5.1-3-3.15-2.07-5.17-4.29-4.64-4.54-1.59-1.61c-1.94,2.76-4.29,4.51-6.83,4.89,0,0,0,0,0,0-.24,2.16-.42,3.39-.61,5.88-.34,4.4-.36,7.99-.33,10.16.05,4.23.34,7.39.55,9.79.16,1.8.59,6.09,1.82,13.29.77,4.55,1.33,7.1,2.98,15.03.76,3.68,1.93,9.29,3.35,16.29,1.19,4.62,2.11,8.54,2.77,11.53.49,2.2.88,4.04,1.15,5.37h28.05c-.15-6.07-.18-5.9-.24-9.61-.12-7.35-.18-11.02.05-15.94.29-6.11.87-11.31,1.03-12.74.49-4.27.72-4.96,1.11-9.11.11-1.18.3-4.16.68-10.06.32-4.93.37-5.99.35-7.77Z" [attr.fill]="changeColor(isSelected('left-thigh'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftLeg"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-leg') || checkAreaExists('left-leg') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-right-leg'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-leg') || checkAreaExists('right-leg') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>    
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightLeg' | translate }}</span>  
    <div class="modal_select_container">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250.17" style="enable-background:new 0 0 250 250.17;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-right-leg.png"></image>
        <g id="right_calf" (click)="backViewModalOnClickSpecificArea('right-calf', 'back-right-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightCalf')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m108.54,157.07c-.32,4.16-.4,4.65-.5,6.63-.08,1.51-.2,4.46-.1,8.07.14,5.06.63,8.96.98,11.65.5,3.87.73,4.28,1.21,8.12.21,1.62.22,2.06.81,8.68.66,7.48.73,8.06.83,9.96.12,2.29.06,2.12.2,8,.2,8.17.34,9.04.23,11.93-.02.38-.08,1.69-.21,3.28h13.31c.01-.55.04-1.05.06-1.49.06-.96.25-2.48.63-5.47.26-2.02.4-3.19.63-4.77.26-1.84.45-2.91,1.01-6.31,1.21-7.3,1.18-7.17,1.36-8.15.55-2.92.85-3.97,1.59-7.34,1.32-5.99,2.14-10.61,2.7-13.77.86-4.87,1.31-7.41,1.74-11.15.29-2.49.62-5.46.73-9.38.2-6.99-.43-12.32-.58-13.57-.09-.72-.18-1.34-.26-1.9h-25.87c-.06,1.29-.23,3.43-.5,6.97Z" [attr.fill]="changeColor(isSelected('right-calf'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right_knee" (click)="backViewModalOnClickSpecificArea('back-of-the-right-knee', 'right-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.backOfTheRightKnee')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m108.34,138.86c.46,4.22.75,6.91.73,10.39,0,.23,0,.51-.03.85h25.87c-.51-3.31-.96-4.16-1.56-9.18-.23-1.94-.58-4.97-.68-8.93-.03-1.21-.12-5.94.55-12.18.39-3.57,1.06-7.94,2.27-12.86.08-.35.15-.7.22-1.04h-28.2c-.14,7.5-.04,6.2-.17,11.2-.22,8.17-.42,9.61-.08,13.42.27,2.97.47,2.88,1.06,8.32Z" [attr.fill]="changeColor(isSelected('back-of-the-right-knee'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right_thigh" (click)="backViewModalOnClickSpecificArea('right-thigh', 'right-leg')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightThigh')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m148.04,25.86c-.06-1.06-.31-3.41-.77-7.72.01.13.03.26.04.38-2.48-.47-4.77-2.27-6.66-5.02l-3.73,3.97-5.38,4.85-5.72,4.03-5.65,3.7-5.78,2.15h-3.63l-3.3-1.41-1.61-1.46c-.13.59-.26,1.26-.39,1.98-.12.67-.4,2.32-.61,4.51-.12,1.26-.39,4.52-.23,9.48.05,1.53.13,2.83.43,6.43.51,6.17,1,11.03,1.21,13.17,1.09,10.84,1.08,10.06,1.21,12.08.33,4.94.34,8.74.35,12.71,0,3.75-.07,4.11-.25,13.34-.02,1.08-.04,2.03-.06,2.87h28.2c1.06-4.89,1.93-8.87,2.55-11.67.91-4.12,1.29-5.82,1.97-9,.18-.86.72-3.37,2.02-9.71,1.44-7.01,2.15-10.51,2.57-12.74,1-5.33,1.56-8.31,2.09-12.59.19-1.51.64-5.35.93-10.39.27-4.66.5-8.68.18-13.95Z" [attr.fill]="changeColor(isSelected('right-thigh'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightLeg"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-leg') || checkAreaExists('right-leg') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-left-arm'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-arm') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>    
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftArm' | translate }}</span>  
    <div class="modal_select_container">      
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-left-arm.png"></image>
        <g id="left-forearm" (click)="backViewModalOnClickSpecificArea('left-forearm', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftForearm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m108.05,150.08c-.5,1.32-1.05,2.8-1.71,4.61-1.57,4.28-2.6,7.53-3.85,11.52-1.04,3.31-1.94,6.39-3.75,12.55-2.07,7.05-2.02,7.05-3.23,11.08-.73,2.44-1.66,5.4-3.51,11.32-5.25,16.79-2.11,6.22-2.96,9.01-.11.35-.21.7-.32,1.05l.08.05,16.67,5.8.12.04c.65-1.89,1.3-3.46,2.44-6.22.39-.93,1.37-3.28,2.79-6.33,1.27-2.72,1.45-2.93,3.41-6.98,1.42-2.94,1.31-2.81,3.03-6.4.9-1.89,1.85-3.83,3.75-7.7,2.01-4.1,3.02-6.16,3.47-7.02.64-1.19.78-1.41,4.3-7.43,2.8-4.8,3.15-5.42,3.61-6.26,1.11-2.02,1.96-3.58,2.84-5.47l-27.16-7.22Z" [attr.fill]="changeColor(isSelected('left-forearm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-elbow" (click)="backViewModalOnClickSpecificArea('left-elbow', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftElbow')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m124.24,117.08c-.73,2.1-1.67,4.58-2.91,7.38-1.66,3.76-3.14,6.44-5.12,10.04-3.07,5.58-3.45,5.41-5.33,9.11-1.12,2.2-1.9,4.05-2.83,6.47l27.16,7.22c.2-.43.4-.87.6-1.34.39-.9,1.45-3.37,2.51-6.74.28-.89.45-1.47,1.79-6.67,1.66-6.41,1.73-6.78,2.17-8.22.89-2.93,1.73-5.18,2.34-6.81.36-.97.76-2.03,1.24-3.22h0s-21.62-7.22-21.62-7.22h0Z" [attr.fill]="changeColor(isSelected('left-elbow'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-upper-arm" (click)="backViewModalOnClickSpecificArea('left-upper-arm', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftUpperArm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m152.27,83.22c-.92-2.09-1.35-4.25-1.37-6.33-5.12,2.9-10.81,3.88-16.25,2.34-.11-.03-.22-.06-.32-.1-.16.86-.35,1.86-.61,3.15-1.07,5.22-2.12,9.27-2.61,11.14-.68,2.58-.72,2.49-2.24,7.84-2.45,8.64-2.29,8.59-3.34,11.9-.34,1.09-.76,2.4-1.29,3.91h0l21.62,7.22h0c.39-.97.84-2.03,1.37-3.21,1.31-2.9,1.47-2.84,2.79-5.74.76-1.66,1.44-3.4,2.82-6.88,1.11-2.79,1.66-4.19,2.3-5.98.53-1.46,1.28-3.56,2.1-6.33.46-1.53.97-3.42,1.49-5.6-2.7-1.54-5.03-4.07-6.46-7.33Z" [attr.fill]="changeColor(isSelected('left-upper-arm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-armpit" (click)="backViewModalOnClickSpecificArea('left-armpit', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftArmpit')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m150.9,76.89c.02,2.08.45,4.24,1.37,6.33,1.43,3.26,3.76,5.79,6.46,7.33.12-.52.25-1.06.37-1.61l.69-5.02-.41-11.01,1.37-4.95c-2.79,3.81-6.17,6.86-9.84,8.93Z" [attr.fill]="changeColor(isSelected('left-armpit'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-shoulder" (click)="backViewModalOnClickSpecificArea('left-shoulder', 'left-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftShoulder')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m167.19,35.11c-1.73-.02-3.94.08-6.47.5-2.25.38-4.65.78-7.53,2.17-.73.35-3.59,1.78-6.5,4.68-2.36,2.35-3.64,4.56-4.44,5.95-.64,1.12-2.03,3.71-3.82,10.15-1.01,3.65-1.52,6.28-2.44,11.08-1.03,5.35-1.15,6.7-1.66,9.5.11.03.22.07.32.1,9.33,2.64,19.39-2.13,26.09-11.28l1.01-3.67,2-6.68.85-2.22s.96-3.81,1.46-5.49l.06-.22h0c.09-.3.16-.51.2-.55.15-.19,1.02-7.12,1.51-11.16-.17-.98-.39-1.93-.64-2.87Z" [attr.fill]="changeColor(isSelected('left-shoulder'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftArm"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-arm') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-right-arm'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-arm') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>    
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightArm' | translate }}</span>  
    <div class="modal_select_container"> 
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-right-arm.png"></image>
        <g id="right-forearm" (click)="backViewModalOnClickSpecificArea('right-forearm', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightForearm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m167.06,204.54c-1.04-3.59-.43-1.14-3.54-11.28-1.06-3.44-1.88-6.11-2.92-9.66-1.94-6.6-1.93-6.97-3.37-11.83-.95-3.19-2.33-7.84-4.37-13.62-.78-2.22-1.78-4.95-3.02-8.08l-26.88,7.22c.66,1.32,1.4,2.75,2.28,4.44,2.64,5.08,3.83,6.81,6.26,11.14,2.18,3.88,4.36,8.39,8.74,17.4.55,1.12,2.9,6.15,7.6,16.2,1.81,3.87,3.65,7.83,4.68,10.92h0s0-.02,0-.03c0,0,.01,0,.01,0l16.71-6.1.07-.06c-1.61-4.47-2.24-6.66-2.24-6.66Z" [attr.fill]="changeColor(isSelected('right-forearm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-elbow" (click)="backViewModalOnClickSpecificArea('right-elbow', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightElbow')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m149.84,150.06c-.53-1.35-1.11-2.77-1.73-4.26-1.12-2.08-2.85-5.23-5.06-9.05-2.51-4.33-3.07-5.07-4.26-7.36-2.2-4.2-3.35-7.49-4.82-11.66-.1-.29-.2-.56-.28-.82l-.2.15-21.47,7.22c1.65,4.06,3.3,8.32,4.85,13.95,1.8,6.55,1.5,7.8,3.41,12.93.82,2.19,1.64,4.02,2.68,6.12l26.88-7.22Z" [attr.fill]="changeColor(isSelected('right-elbow'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-upper-arm" (click)="backViewModalOnClickSpecificArea('right-upper-arm', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightUpperArm')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m133.69,116.91c-1.43-4.19-1.38-4.97-3.74-12.94-2.71-9.15-3.05-9.1-4.2-13.86-.45-1.88-1.03-5.09-2.07-11.08-.2.06-.4.13-.61.18-5.36,1.51-10.95.58-16.02-2.21-.03,2.04-.47,4.15-1.37,6.2-1.45,3.31-3.83,5.87-6.58,7.4.38,1.61.93,3.73,1.67,6.18,1.29,4.26,2.59,7.55,4.02,11.01,3.66,8.83,4.08,8.78,6.64,15.06.19.47.39.95.58,1.43l21.47-7.22.2-.15Z" [attr.fill]="changeColor(isSelected('right-upper-arm'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-armpit" (click)="backViewModalOnClickSpecificArea('right-armpit', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightArmpit')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m105.69,83.2c.9-2.05,1.34-4.16,1.37-6.2-3.68-2.03-7.08-5.04-9.9-8.83l.09.22.83,2.48v15.87l.59,2.91c.08.07.19.2.33.46.04.16.08.32.12.49,2.75-1.53,5.13-4.09,6.58-7.4Z" [attr.fill]="changeColor(isSelected('right-armpit'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-shoulder" (click)="backViewModalOnClickSpecificArea('right-shoulder', 'right-arm')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightShoulder')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m123.08,79.22c.2-.06.41-.12.61-.18-.12-.68-.24-1.4-.38-2.16-1.7-9.8-1.78-10.8-2.58-14.14-1.11-4.66-1.92-7.96-3.92-12.11-1.33-2.75-2.87-5.86-6.09-8.87-2.89-2.7-5.65-3.96-6.36-4.26-2.1-.92-3.78-1.24-6.12-1.69-1.92-.37-4.52-.74-7.66-.84-.08.28-.15.57-.22.86l1.12,15.79,2.2,7.8,3.49,8.77c6.7,8.99,16.66,13.65,25.91,11.04Z" [attr.fill]="changeColor(isSelected('right-shoulder'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightArm"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-arm') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-left-foot'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-left-foot') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3> 
    <span class="span_area">{{ 'ecClaim.injuryDetails.backLeftFoot' | translate }}</span>     
    <div class="modal_select_container">      
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-left-foot.png"></image>
        <g id="left-ankle" (click)="backViewModalOnClickSpecificArea('left-ankle', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftAnkle')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m102.28,93.02c-1.37,6.01-2.44,13.65-2.09,22.47.16,3.91.57,7.51,1.12,10.75-.13.27-.25.53-.38.8h45.48c.1-.8.23-1.74.41-2.89,1.24-8.06,1.84-7.88,2.09-11.72.24-3.72-.15-6.82-.56-10.19-.41-3.31-.92-5.74-1.04-6.34-.99-4.81-1.69-11.53-2.14-17.17h-42.36c.09,3.92,0,8.74-.52,14.29Z" [attr.fill]="changeColor(isSelected('left-ankle'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="left-heel" (click)="backViewModalOnClickSpecificArea('left-heel', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.leftHeel')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m130.91,173.6h16.4s.05-.05.07-.08c1.1-1.31,1.56-2.56,2.17-4.17.84-2.24,1.09-4.06,1.28-5.46.19-1.37.36-3.34.24-5.7-.12-2.37-.47-4.06-1.2-7.22-1.74-7.56-2.03-8.04-2.73-11.64-1.03-5.31-1.04-7.65-1.04-8.35,0-.65.02-1.65.31-3.93h-34.76v47.89l19.26-1.34Z" [attr.fill]="changeColor(isSelected('left-heel'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="top-of-the-left-foot" (click)="backViewModalOnClickSpecificArea('top-of-the-left-foot', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.topOfTheLeftFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m111.65,127.05h-10.72c-2.74,5.77-5.34,10.48-7.4,13.96-3.16,5.35-4.79,8.09-7.46,10.91-2.89,3.06-3.16,2.37-9.23,8.03-3.11,2.9-.94,1.28-7.78,8.03-2.08,2.05-3.14,3.01-3.85,4.9-.03.09-.13.35-.23.72h.13l2.25,4.17,7.38.32,20.22-4.49,12.84,1.61,3.85-.27v-47.89Z" [attr.fill]="changeColor(isSelected('top-of-the-left-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="bottom-of-the-left-foot" (click)="backViewModalOnClickSpecificArea('bottom-of-the-left-foot', 'left-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.bottomOfTheLeftFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m68.73,184.19c2.39.94,4.94-.34,5.54-.64.55-.28.99-.58,1.28-.8,3.38.67,7.73,1.17,12.76.8,2.46-.18,4.69-.53,6.66-.96,4.64.23,9.57.31,14.77.16,2.76-.08,5.44-.22,8.03-.4.94-.32,1.87-.64,2.81-.96,2.37.31,5.42.45,8.91,0,.95-.12,4.29-.6,8.35-2.17.5-.19,2.66-1.04,5.3-2.57,2.07-1.2,3.15-1.84,4.18-3.05h-16.4l-23.11,1.61-12.84-1.61-20.22,4.49-7.38-.32-2.25-4.17h-.13c-.38,1.36-.9,4.36.63,7.3.52,1,1.35,2.59,3.13,3.29Z" [attr.fill]="changeColor(isSelected('bottom-of-the-left-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectLeftFoot"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-left-foot') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>
  
<div *ngIf="backViewModalIsOpenF && backViewModalGetArea === 'back-right-foot'" class="_modal">
  <div class="modal_container" (click)="backViewModalOnClickNotClose($event)">
    <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_close">
      <svg width="25" height="25" viewBox="0 0 39 39" fill="nTwo" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3L36.5 36.5M36.5 3L3 36.5" stroke="black" stroke-width="6"/>
      </svg>            
    </button>
    <h3>{{checkAreaExists('back-right-foot') ? ('ecClaim.injuryDetails.editArea' | translate) : ('ecClaim.injuryDetails.selectArea' | translate)}}</h3>   
    <span class="span_area">{{ 'ecClaim.injuryDetails.backRightFoot' | translate }}</span>   
    <div class="modal_select_container">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve">
        <image style="overflow:visible;" x="0" y="0" xlink:href="assets/images/body-parts/female/back/female-back-right-foot.png"></image>
        <g id="right-ankle" (click)="backViewModalOnClickSpecificArea('right-ankle', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightAnkle')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m99.73,109.45c.09,1.88.35,5.43.88,12.52.15,2.02.28,3.68.39,5.06h45.71c-.12-.25-.24-.49-.35-.72.86-2.17,1.83-5.29,2.17-9.15.37-4.26-.17-7.8-.72-10.19-1.55-11.26-1.84-20.83-1.69-28.25h-42.8c-.13,1.94-.3,3.74-.53,5.54-1.48,11.26-3.5,15.19-3.05,25.2Z" [attr.fill]="changeColor(isSelected('right-ankle'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="right-heel" (click)="backViewModalOnClickSpecificArea('right-heel', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.rightHeel')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m101.5,135.38c-.25,4.64-.98,4.52-3.29,16.77-1.31,6.93-1.53,9.36-1.04,12.44.35,2.22.96,5.79,3.69,8.99h16.3l19.56,1.36v-47.91h-35.71c.5,6.39.58,6.7.49,8.35Z" [attr.fill]="changeColor(isSelected('right-heel'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="top-of-the-right-foot" (click)="backViewModalOnClickSpecificArea('top-of-the-right-foot', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.topOfTheRightFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m179.18,167.56c-2.61-2.73-4.1-3.75-7.95-7.06-3.78-3.25-5.67-4.88-7.38-6.74-1.58-1.72-3.39-4.48-7.06-9.87-2.59-3.79-4.57-6.72-6.9-10.83-1.34-2.37-2.4-4.43-3.18-6.02h-10v47.91l3.55.25,12.84-1.61,20.22,4.49,7.38-.32,2.25-4.17h.65c-.32-.89-1.25-2.68-4.43-6.02Z" [attr.fill]="changeColor(isSelected('top-of-the-right-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
        <g id="bottom-of-the-right-foot" (click)="backViewModalOnClickSpecificArea('bottom-of-the-right-foot', 'right-foot')" (mouseenter)="backViewModalShowTooltip('ecClaim.bodyParts.bottomOfTheRightFoot')" (mouseleave)="backViewModalHideToolTip()">
          <path class="st0" d="m108.16,178.39c4.62,2.18,8.61,2.82,10.51,3.05,3.27.39,6.05.24,8.03,0,5.69,1.24,9.69,1.36,12.36,1.2.94-.05,3.36-.23,6.74-.16,1.94.04,3.37.16,4.57.24,8.38.53,11.04,1.3,15.81.96,2.62-.19,5.03-.53,7.22-.96.5.39,1.31.93,2.41,1.28.77.25,2.17.71,3.69.24,1.47-.46,2.3-1.55,2.81-2.25,2.49-3.4,1.5-7.7,1.44-7.95-.03-.12-.07-.28-.14-.48h-.65l-2.25,4.17-7.38.32-20.22-4.49-12.84,1.61-23.11-1.61h-16.3c1.96,2.3,4.07,3.29,7.3,4.82Z" [attr.fill]="changeColor(isSelected('bottom-of-the-right-foot'))" fill-opacity="0.8" stroke="white" stroke-opacity="0" stroke-width="1" stroke-miterlimit="5"/>
        </g>
      </svg>
    </div>

    <app-modal-table
      [modalTableSelect]="backViewModalSelectRightFoot"
      [modalTableInjuryList]="backViewModalInjuryList"
      [modalTableIsLoading]="backViewModalIsLoading"
      (emitTableOnClickRemoveSpecificArea)="backViewModalOnClickRemoveSpecificArea($event.id, $event.area)"
    ></app-modal-table>

    <div class="modal_btn_container">
      <button (click)="backViewModalOnClickCloseSpecificArea()" class="modal_cancel">{{ 'ecClaim.injuryDetails.cancel' | translate }}</button>
      <button (click)="backViewModalOnSubmitSelected()" class="modal_add">
        <span>{{checkAreaExists('back-right-foot') ? ('ecClaim.injuryDetails.addChanges' | translate) : ('ecClaim.injuryDetails.add' | translate)}}</span>
        <svg width="27" height="29" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 14.7347C27 14.7347 10.5442 14.7347 0 14.7347H27ZM27 14.7347L16.0169 3M27 14.7347L16.0169 28" fill-opacity="1" stroke="white" stroke-opacity="1" stroke-width="3" stroke-linecap="square"/>
        </svg>
      </button>
    </div>
  </div>
</div>