import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";

import {DatePipe, DOCUMENT} from "@angular/common";
import {DateService} from "../ui-datepicker/date.service";

declare var moment: any;
declare var $: any;

@Component({
    selector: 'qnect-ui-datetimepicker',
    templateUrl: './ui-datetimepicker.component.html',
    styleUrls: ['../ui-base.component.scss', './ui-datetimepicker.component.scss']
})
export class UiDateTimePickerComponent extends UiBaseComponent implements OnInit, AfterViewInit, OnDestroy {

    dt: Date;

    constructor(private dateService: DateService,
                private _elementRef: ElementRef,
                @Inject(DOCUMENT) private document: any) {

        super();
    }

    ngOnInit() {
        super.ngOnInit();
        if (!this.uiModel.dateFormat) {
            this.uiModel.dateFormat = DateService.getDefaultDateFormat();
        }

        if (this.formControl.value && this.formControl.value != '') {

            if (this.formControl.value instanceof Date) {
                this.setDateValue(this.formControl.value);
            }
            else {

                let momentVar = moment(this.formControl.value, this.uiModel.momentFormat);
                let date: Date = momentVar.toDate();
                //let date: Date = moment(this.formControl.value, this.uiModel.dateFormat).toDate();
                this.setDateValue(date);
            }
        }

        console.log("Date ngOnInit form control:" + this.formControl.value);
        //console.log("Date ngOnInit date :" + this.dt);
    }

    ngAfterViewInit(): void {

        //www.daterangepicker.com/
        let self = this;

        if (!this.frmArrayIndex) this.frmArrayIndex = '';
        let fieldId = this.uiModel.name + 'DateField' + this.frmArrayIndex;

        $('#' + fieldId).datetimepicker({
            format: this.uiModel.momentFormat,
            icons: {
                time: 'far fa-clock',
                date: 'fas fa-calendar-alt',
                up: 'fa fa-arrow-up',
                down: 'fa fa-arrow-down'
            },
            sideBySide: true,
        }).on('dp.change', function (e) {
            let newDate : Date;
            if (e.date) {
                newDate = e.date.toDate();
            }

            self.setDateValue(newDate);
        });
    }

    ngOnDestroy() {
    }

    getDateValue() {
        return this.dt;

    }

    setDateValue(date: Date) {
        this.dt = date;
        if (this.dt && this.dt != null) {
            this.formControl.setValue(new DatePipe('en-US').transform(this.dt, this.uiModel.dateFormat), {onlySelf: false, emitEvent: true});
        }
    }

    // In order to make sure timezone doesn't shift the date around we set the time to noon.
    setDateAsNoonTime(dateToSet: Date) {
        if (dateToSet && dateToSet instanceof Date) {
            dateToSet.setHours(12, 0, 0, 0);
        }
    }

}
