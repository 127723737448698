<div class="section form-group">
    <label *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_TP_LIABILITY') === -1" class="sub-section">
        {{'homeProduct.liability.thirdPartyLoss.title' | translate}}</label>
    <label *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_TP_LIABILITY') !== -1" class="sub-section">
        {{'homeProduct.liability.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <!-- Loss Details -->
            <label *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_TP_LIABILITY') !== -1" class="sub-section">
                {{'homeProduct.liability.thirdPartyLoss.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'homeProduct.liability.thirdPartyLoss.didIncidentInvolve' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNoStr(this.thirdPartyLossDetails.getIsIncidentInvolve())}}
                </div>
            </div>

            <ng-container *ngIf="this.thirdPartyLossDetails.getIsIncidentInvolve() === 'true'">
                <!-- PERSON -->
                <div class="sub-section" *ngIf="this.thirdPartyLossDetails.getDetailsOfDamagedType().indexOf('Person') !== -1">
                    <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.injury' | translate}}</label>
                    <ng-container *ngFor="let item of this.thirdPartyLossDetails.getInjuredItemList(); let i = index;">
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.injury' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonRelationship' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getRelationshipDesc()}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonName' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getInjuredPersonName()}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonAgeGroup' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getAgeGroupDesc()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getInjuredPersonNumber() != null && item.getInjuredPersonNumber() != ''">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.injuredPersonNumber' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getInjuredPersonNumber()}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.extentOfInjury' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getExtentOfInjuryDesc()}}
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- PROPERTY -->
                <div class="sub-section" *ngIf="this.thirdPartyLossDetails.getDetailsOfDamagedType().indexOf('Property') !== -1">
                    <label>{{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.property' | translate}}</label>
                    <ng-container *ngFor="let item of this.thirdPartyLossDetails.getPropertyDamageItemList(); let i = index;">
                        <ng-container>
                            <div class="row">
                                <div class="col col-xs-12 col-lg-7">
                                    {{'motorClaim.claimSection.motorAccidentCollission.thirdParty.thirdPartyDetails.property' | translate}} {{i + 1}}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-xs-12 col-lg-7">
                                    {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.hit' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-5-word-break content">
                                    {{item.getWhatDidYouHit()}}
                                </div>
                            </div>
                            <div class="row" *ngIf="item.getPropertyOwnerName() != null && item.getPropertyOwnerName() != ''">
                                <div class="col col-xs-12 col-lg-7">
                                    {{ 'homeProduct.liability.thirdPartyLoss.propertyDamaged.propertyOwnerName' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-5-word-break content">
                                    {{item.getPropertyOwnerName()}}
                                </div>
                            </div>
                            <div class="row" *ngIf="item.getPropertyOwnerNo() != null && item.getPropertyOwnerNo() != ''">
                                <div class="col col-xs-12 col-lg-7">
                                    {{ 'motorClaim.claimSection.motorAccidentCollission.thirdParty.propertyOwnerNumber' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{item.getPropertyOwnerNo()}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="this.thirdPartyLossDetails.getIsIncidentInvolve() === 'Unsure'">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'homeProduct.liability.thirdPartyLoss.incidentDescription' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.thirdPartyLossDetails.getIncidentDescription()}}
                    </div>
                </div>
            </ng-container>

            <!-- Legal Details -->
            <div class="row">
                &nbsp;
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'homeProduct.liability.thirdPartyLegal.anyClaimMade' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.thirdPartyLegalDetails.getHasAnyClaimMade())}}
                </div>
            </div>

            <ng-container *ngIf="this.thirdPartyLegalDetails.getHasAnyClaimMade()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.liability.thirdPartyLegal.otherInsurerName' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content">
                        {{this.thirdPartyLegalDetails.getTpClaimOtherInsurerName()}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'homeProduct.liability.thirdPartyLegal.contactNumber' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.thirdPartyLegalDetails.getTpClaimContactNumber()}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>