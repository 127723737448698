import { AfterViewInit, Directive, Input } from '@angular/core';
import { UiComponent } from '../ui.component';

@Directive({selector: '[qnectParentTotalAggregator]'})
export class ParentTotalAggregatorDirective implements AfterViewInit {


  constructor(private uiComponent: UiComponent) {}

  @Input() qnectParentTotalAggregator: UiComponent[] = [];

  ngAfterViewInit() {
    this.updateTotalValue();
    for (let parentUi of this.qnectParentTotalAggregator) {
      parentUi.valueChanges().subscribe(() => {
        this.updateTotalValue();
      });
    }
  }

  private updateTotalValue() {
    let totalValue = 0;
    for (let parentUi of this.qnectParentTotalAggregator) {
      if (parentUi.getValue()) {
        totalValue += Number(parentUi.getValue());
      }
    }
    this.uiComponent.setValue(String(totalValue));
  }
}

