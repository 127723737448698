
export class ClinicalExpenses {

    private countryOccurred: string;
    private countryOccurredDesc: string;
    private natureOfIllness: string;
    private natureOfIllnessDesc: string;
    private hasRelatedConditionsForSickness: boolean;
    private hasRelatedConditionsForSicknessDesc: boolean;
    private hasRelatedConditionsForInjury: boolean;
    private specifyCondition: string;
    private sicknessRelatedCondition: string;
    private injuryRelatedCondition: string;
    private didHelperSustainInjury: boolean;
    private typeOfIllnessOrInjury: string;
    private typeOfIllnessOrInjuryDesc: string;
    private otherTypeOfIllnessOrInjury: string;
    private dateOfVisit: Date;
    private typeOfClinicalExpensesCode: string;
    private typeOfClinicalExpenses: string;
    private typeOfClinicalExpensesDesc: string;
    private totalAmountOfExpensesCurrency: string;
    private totalAmountOfExpensesValue: string; 
    private cancerHeartDiseaseExtensionApplicability: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getCancerHeartDiseaseExtensionApplicability(): string {
        return this.cancerHeartDiseaseExtensionApplicability;
    }

    setCancerHeartDiseaseExtensionApplicability(value: string) {
        this.cancerHeartDiseaseExtensionApplicability = value;
    }    

    getDateOfVisit(): Date {
        return this.dateOfVisit;
    }

    setDateOfVisit(value: Date) {
        this.dateOfVisit = value;
    }

    getTypeOfClinicalExpensesCode(): string {
        return this.typeOfClinicalExpensesCode;
    }

    setTypeOfClinicalExpensesCode(value: string) {
        this.typeOfClinicalExpensesCode = value;
    }

    getTypeOfClinicalExpenses(): string {
        return this.typeOfClinicalExpenses;
    }

    setTypeOfClinicalExpenses(value: string) {
        this.typeOfClinicalExpenses = value;
    }

    getTypeOfClinicalExpensesDesc(): string {
        return this.typeOfClinicalExpensesDesc;
    }

    setTypeOfClinicalExpensesDesc(value: string) {
        this.typeOfClinicalExpensesDesc = value;
    }

    getTotalAmountOfExpensesCurrency(): string {
        return this.totalAmountOfExpensesCurrency;
    }

    setTotalAmountOfExpensesCurrency(value: string) {
        this.totalAmountOfExpensesCurrency = value;
    }

    getTotalAmountOfExpensesValue(): string {
        return this.totalAmountOfExpensesValue;
    }

    setTotalAmountOfExpensesValue(value: string) {
        this.totalAmountOfExpensesValue = value;
    }

    getCountryOccurred(): string {
        return this.countryOccurred;
    }

    setCountryOccurred(value: string) {
        this.countryOccurred = value;
    }

    setCountryOccurredDesc(value: string) {
        this.countryOccurredDesc = value;
    }

    getCountryOccurredDesc(): string {
        return this.countryOccurredDesc;
    }

    getNatureOfIllness(): string {
        return this.natureOfIllness;
    }

    setNatureOfIllness(value: string) {
        this.natureOfIllness = value;
    }

    getNatureOfIllnessDesc(): string {
        return this.natureOfIllnessDesc;
    }

    setNatureOfIllnessDesc(value: string) {
        this.natureOfIllnessDesc = value;
    }

    getTypeOfIllnessOrInjury(): string {
        return this.typeOfIllnessOrInjury;
    }

    setTypeOfIllnessOrInjury(value: string) {
        this.typeOfIllnessOrInjury = value;
    }

    getTypeOfIllnessOrInjuryDesc(): string {
        return this.typeOfIllnessOrInjuryDesc;
    }

    setTypeOfIllnessOrInjuryDesc(value: string) {
        this.typeOfIllnessOrInjuryDesc = value;
    }

    getOtherTypeOfIllnessOrInjury(): string {
        return this.otherTypeOfIllnessOrInjury;
    }

    setOtherTypeOfIllnessOrInjury(value: string) {
        this.otherTypeOfIllnessOrInjury = value;
    }

    getHasRelatedConditionsForSickness(): boolean {
        return this.hasRelatedConditionsForSickness;
    }

    setHasRelatedConditionsForSickness(value: boolean) {
        this.hasRelatedConditionsForSickness = value;
    }

    getHasRelatedConditionsForSicknessDesc(): boolean {
        return this.hasRelatedConditionsForSicknessDesc;
    }

    setHasRelatedConditionsForSicknessDesc(value: boolean) {
        this.hasRelatedConditionsForSicknessDesc = value;
    }

    getHasRelatedConditionsForInjury(): boolean {
        return this.hasRelatedConditionsForInjury;
    }

    setHasRelatedConditionsForInjury(value: boolean) {
        this.hasRelatedConditionsForInjury = value;
    }

    getSpecifyCondition(): string {
        return this.specifyCondition;
    }

    setSpecifyCondition(value: string) {
        this.specifyCondition = value;
    }

    getSicknessRelatedCondition(): string {
        return this.sicknessRelatedCondition;
    }

    setSicknessRelatedCondition(value: string) {
        this.sicknessRelatedCondition = value;
    }

    getInjuryRelatedCondition(): string {
        return this.injuryRelatedCondition;
    }

    setInjuryRelatedCondition(value: string) {
        this.injuryRelatedCondition = value;
    }

    getDidHelperSustainInjury(): boolean {
        return this.didHelperSustainInjury;
    }

    setDidHelperSustainInjury(value: boolean) {
        this.didHelperSustainInjury = value;
    }

    static jsonConvert(clinicalExpenses: ClinicalExpenses): ClinicalExpenses {

        //todo: convert date fields object if any
        if (clinicalExpenses.getDateOfVisit() != null) {
            clinicalExpenses.setDateOfVisit(new Date(clinicalExpenses.getDateOfVisit()));
        }

        return clinicalExpenses;
    }
}