import {Component, Injector, OnInit} from "@angular/core";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {BaggageDelay} from "src/app/model/travel/baggage-delay.model";
import { ClaimTypes } from "../../../../model/claim-type.model";
import { DocumentField } from "../../../../model/document-field";
import { DocumentHelperService } from "../../../../services/document-helper.service";

@Component({
  selector: 'app-summary-baggage-delay',
  templateUrl: './summary-baggage-delay.component.html',
  styleUrls: ['./summary-baggage-delay.component.css']
})
export class SummaryBaggageDelayComponent extends ClaimsBaseComponent implements OnInit {
    
    claim: Claim;
    claimTravel: ClaimTravel;
    baggageDelay: BaggageDelay;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
        private documentHelperService: DocumentHelperService,
                private injector : Injector) {

            super(injector);
            this.claim = this.claimService.getClaim();
            this.claimTravel = this.claim.getClaimTravel();
            this.baggageDelay = this.claim.getClaimTravel().getBaggageDelay();
            this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]);
            this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]);
    }

    ngOnInit() {
        
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDatetime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        }
        else {
            return "";
        }
    }

}
