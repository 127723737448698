<div class="section form-group">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionPersLiab.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionDetail.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionDetail.dateIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(personalLiability.getLiabilityDateIncident())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionDetail.countryIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="personalLiability.getLiabilityCountryIncidentOccurredDesc()">
                    {{personalLiability.getLiabilityCountryIncidentOccurredDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="personalLiability.getPlaceOfLossDesc()">
                    {{personalLiability.getPlaceOfLossDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionDetail.natureIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{personalLiability.getLiabilityNatureIncidentDesc()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionDescription.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionDescription.descIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{personalLiability.getLiabilityIncidentDescription()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionPersLiab.subSectionDescription.amountIncident' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{personalLiability.getLiabilityCurrency()}}
                    {{personalLiability.getLiabilityAmount() | number : '1.2-2'}}

                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>
<div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
    <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
    <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
      <div class="col col-xs-12 col-lg-12">
          {{item.description | translate}}
      </div>
      <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
          <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
              {{ item.uploadedFileName }}
          </div>
      </ng-container>
  </div>
</div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>  
</ng-template>