import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { Claim } from 'src/app/model/claim.model';
import { ClaimService } from 'src/app/services/claim.service';
import { DatePipe } from '@angular/common';
import { ClaimHome } from 'src/app/model/claim-home.model';
import { Others } from 'src/app/model/common/others.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-summary-other-damage',
  templateUrl: './summary-other-damage.component.html',
  styleUrls: ['./summary-other-damage.component.css']
})
export class SummaryOtherDamageComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimHome: ClaimHome;
    otherDetails: Others;
    isHomeNotAddress: boolean = false;
    isOutsideHome: boolean = false;

    constructor(private claimService: ClaimService,
        private injector: Injector) {
       
        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimHome = this.claim.getClaimHome();
        this.otherDetails = this.claim.getClaimHome().getHomeDetails().getOthers();

        this.isHomeNotAddress = this.claimHome.getPlaceOfIncident() === 'home' 
            && !this.claimHome.getIsHomeWhereIncidentOccurred();
        this.isOutsideHome = this.claimHome.getPlaceOfIncident() !== 'home';
    }

    ngOnInit() {

    }

    returnYesOrNo(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
        }
    }

    returnYesOrNoBoolean(value: boolean) {
        if (value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (!value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }
        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en\-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }

    isNotNullOrUndefined(val: any) {
        if(!UtilitiesService.isNullOrUndefined(val)) {
            return true;
        } 
        return false;
    }

    isNotNullOrUndefinedStr(val: any){
        if(!UtilitiesService.isNullOrUndefined(val) && val != '') {
            return true;
        } 
        return false;
    }
}

