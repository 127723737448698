import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-tab-table',
  templateUrl: './tab-table.component.html',
})

@Injectable()
export class TabTableComponent {

  @Input() tabTableGender: any;
  @Input() tabTableIsLoading;
  @Input() injuredBodyParts;

  @Output() emitTabTableOnClickArea = new EventEmitter<any>();
  tabTableOnClickArea(e) {
    this.emitTabTableOnClickArea.emit(e);
  }

  @Output() emitTabTableOnClickRemoveSpecificArea = new EventEmitter<any>();
  tabTableOnClickRemoveSpecificArea(id, area) {
    this.emitTabTableOnClickRemoveSpecificArea.emit({ id, area });
  }
}
