<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && ((!formControl.valid && formControl.touched) || (!currencyFormControl.valid && currencyFormControl.touched))) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
    <div class="col-md-{{uiModel.size}}">
        <div class="row">

            <div *ngIf="uiModel.currencyFieldName" class="col-lg-4 col-md-4">
                <select formControlName="{{uiModel.currencyFieldName}}" id="{{uiModel.currencyFieldId}}"
                        class="form-control"
                        (blur)="currencyFieldBlur()"
                        (change)="currencyFieldChange()">
                    <option *ngIf="uiModel.required" value="null" disabled selected>{{uiModel.currencyPlaceholder}}</option>
                    <option *ngIf="!uiModel.required" value="">{{uiModel.currencyPlaceholder}}</option>
                    <option *ngFor="let option of uiModel.currencyOptions" value="{{option.value}}">{{option.label}}</option>
                </select>
            </div>
            <div class="col-lg-8 col-md-8 col-xs-{{amountFieldSize}}">
                <div class="input-group" *ngIf="!uiModel.textOnly">
                    <input id="{{fieldId}}"
                           formControlName="{{uiModel.name}}"
                           type="text"
                           autocomplete="off"
                           class="form-control"
                           aria-label="Amount (to the nearest dollar)"
                           (change)="formatValue($event)"

                           (keypress)="onKeyPress($event)"
                           (blur)="moneyFieldBlur()"
                           maxlength="{{uiModel.maxlength}}"
                           #inputField
                           qnectCurrencyInputFormatter
                           (paste)="disabledPasteEvent($event)">
                </div>
            </div>
            <div class="col-md-{{getDivRemainder()}}">
            </div>
        </div>
        <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value}} {{currencySelectedText}}</p>
        <ui-error *ngIf="(formControl.touched || uiModel.textOnly) && !currencyFormControl.errors" [inputErrors]="formControl.errors"
                  [dictionary]="uiModel.dictionary"></ui-error>
        <ui-error *ngIf="uiModel.currencyFieldName && (currencyFormControl.touched || uiModel.textOnly)" [inputErrors]="currencyFormControl.errors"
                  [dictionary]="uiModel.dictionary"></ui-error>
    </div>
</div>
