import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class DeathAndPermanentDisabilityFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('DIAGNOSISRECEIPT', 'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.diagnosisReceipt', SmartqDocuments.DMH_HOSPITALBILLS, false));
        requiredDocuments.push(new DocumentField('MEDICALREPORT', 'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.medicalReport', SmartqDocuments.DMH_EXTENTOFINJURY, false));
        requiredDocuments.push(new DocumentField('COPYOFDEATHCERT', 'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.copyOfDeathCert', SmartqDocuments.DMH_DEATH, false));
        requiredDocuments.push(new DocumentField('COPYOFPOLICEREPORT', 'domesticHelperClaim.dmhClaimTypeDetails.deathAndPermanentDisability.subSupportingDocs.copyOfPoliceReport', SmartqDocuments.DMH_POLICE, false));
        this.requiredDocuments = requiredDocuments;
    }
}
