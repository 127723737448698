<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
      <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
      <p>{{'domesticHelperClaim.dmhClaimTypeDetails.repatriation.repTempHelper.label' | translate}}</p>
    </div>
    <BR>
  
    <div class="row col-12">
      <div class="col-xl-9 col-lg-12">
          <form>
              <div>
                <div class="form-group" *ngIf="domesticInfoPlan.claimQuestionReplacement == 'Yes'">
                  <qnect-ui #DMH_REPATRIATION_AGENTFEE_RADIO [fieldId]="'DMH_REPATRIATION_AGENTFEE_RADIO'" [formGroup]="repatriationForm" name="hasAgentFeePaid"></qnect-ui>
                </div>
                <div class="form-group click-more-content" id="q1" *ngIf="claim.getClaimDomesticHelper().getRepatriation().getHasAgentFeePaid()">
                    <qnect-ui #DMH_REPATRIATION_AGENTFEE [fieldId]="'DMH_REPATRIATION_AGENTFEE'" [formGroup]="repatriationForm" name="agentFeePaidAmount"></qnect-ui>
                </div>
              </div>
              <div class="form-group">
                <div *ngIf="domesticInfoPlan.claimQuestionTemporary == 'Yes'">
                  <qnect-ui #DMH_REPATRIATION_TEMPHELPPAID_RADIO [fieldId]="'DMH_REPATRIATION_TEMPHELPPAID_RADIO'" [formGroup]="repatriationForm" name="hasTempHelper"></qnect-ui>
                </div>
                <div class="form-group click-more-content" id="q2" *ngIf="claim.getClaimDomesticHelper().getRepatriation().getHasTempHelper()">
                    <qnect-ui #DMH_REPATRIATION_TEMPHELPPAID [fieldId]="'DMH_REPATRIATION_TEMPHELPPAID'" [formGroup]="repatriationForm" name="tempHelperAmount"></qnect-ui>
                </div>
              </div>
          </form>
      </div>
      <div class="col-xl-3 col-lg-12">
      </div>
    </div>
  
  <div class="submit-bar">
      <abandon-button></abandon-button>
      <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
      <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
  </div>
  
  