<div class="form-group section">
        <label class="section-title">{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.title' | translate}}</label>
        <div class="section-content">
            <div class="sub-section">
                <label> {{'claimSection.damagePersonalItem.detailsOfDamage.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimSection.damagePersonalItem.detailsOfDamage.dateDamageOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(this.damagePersonalItem.getDateTheDamageOccurred())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.damagePersonalItem.getCountryTheDamageOccurredDesc()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimSection.damagePersonalItem.detailsOfDamage.countryDamageOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.damagePersonalItem.getCountryTheDamageOccurredDesc()}}
                    </div>
                </div>
                <div class="row" *ngIf="this.damagePersonalItem.getPlaceOfLossDesc()">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimSection.damagePersonalItem.detailsOfDamage.whereDamageOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.damagePersonalItem.getPlaceOfLossDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimSection.damagePersonalItem.detailsOfDamage.howDamageOccurred' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content" *ngIf="this.damagePersonalItem.getHowDamageOccurred()?.toLowerCase() != 'others'">
                        {{this.damagePersonalItem.getHowDamageOccurredDesc()}}
                    </div>
                    <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="this.damagePersonalItem.getHowDamageOccurred()?.toLowerCase() == 'others'">
                        {{this.damagePersonalItem.getOtherDamageOccurred()}}
                    </div>
                </div>
            </div>
            <div class="sub-section">
                <label>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subDmgItemDetail.title' | translate}}</label>
                <ng-container *ngFor="let item of this.damagePersonalItem.getDetailsOfDamageItemsArray(); let i = index;">
                    <ng-container >
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.item' | translate}} {{i + 1}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getLossType() != 'Others'">
                                {{item.getLossTypeDesc()}}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="item.getLossType() == 'Others'">
                              {{item.getLossTypeDetail()}}
                           </div>
                        </div>
                        <div class="row" *ngIf="item.getLossType() == 'Others'">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.briefDescriptionOfItem' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getOtherTypeBriefDesc()}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getLossType() == 'Mobile Phone'">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.descOfIncident' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5-word-break content">
                                {{item.getLossTypeDetail()}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.purchaseDate' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{getDate(item.getPurchaseDate())}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getPurchasePrice()">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.purchasePrice' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                            </div>
                        </div>
                        <div class="row" *ngIf="this.isNotNullOrUndefined(item.getHasOriginalPurchasedReceipt())">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.hasOriginalPurchasedReceipt' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{returnYesOrNo(item.getHasOriginalPurchasedReceipt())}}
                            </div>
                        </div>
                        <ng-container *ngIf="(this.claim.getCountry().toLowerCase() == 'hkg' || this.claim.getCountry().toLowerCase() == 'hgi') &&
                            (item.getLossType() == 'Luggage / Trunk' || item.getLossType() == 'Suitcase / Case')">
                            <div class="row">
                                <div class="col col-xs-12 col-lg-7">
                                    {{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subDmgItemDetail.damageDetail' | translate}}
                                </div>
                                <div class="col col-xs-12 col-lg-5-word-break content">
                                    {{getDamagedParts(item)}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-xs-12 col-lg-7">
                                    {{'claimSection.damagePersonalItem.detailsOfDamageItems.hasRepairOrQuotationReceipt' | translate}}
                                </div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{returnYesOrNo(item.getHasRepairOrQuotationReceipt())}}
                                </div>
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="item.getRepairCost()">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.repairCost' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getRepairCostType()}} {{item.getRepairCost() | number : '1.2-2'}}
                            </div>
                        </div>
                        <div class="row" *ngIf="(this.claim.getCountry().toLowerCase() == 'hkg' || this.claim.getCountry().toLowerCase() == 'sgp') && item.getLossType() == 'Watch'">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.wasWatchBeingStored' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{returnYesOrNo(item.getWasWatchBeingStored())}}
                            </div>
                        </div>
                        <div class="row" *ngIf="item.getDepreciationRate()">
                            <div class="col col-xs-12 col-lg-7">
                                {{'claimSection.damagePersonalItem.detailsOfDamageItems.depreciationRate' | translate}}
                            </div>
                            <div class="col col-xs-12 col-lg-5 content">
                                {{item.getDepreciationRate()}}<span *ngIf="item.getDepreciationRate() !== 'NA'">%</span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="sub-section">
                <label>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subRptDamage.title' | translate}}</label>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subRptDamage.rptToAuthority' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(this.damagePersonalItem.getHasAnotherAuthority())}}
                    </div>
                </div>
                <ng-container *ngIf="this.damagePersonalItem.getHasAnotherAuthority()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subRptDamage.AuthorityType' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{this.damagePersonalItem.getAuthorityTypeDesc()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="this.damagePersonalItem.getAuthorityType() === 'Others'">
                        <div class="col col-xs-12 col-lg-7">
                            {{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subRptDamage.otherAuthrityType' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{this.damagePersonalItem.getOtherAuthorityType()}}
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'claimSection.damagePersonalItem.reportTheDamage.anyCompensationFromAuthority' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(this.damagePersonalItem.getHasCompensationReceived())}}
                    </div>
                </div>
                <ng-container *ngIf="this.damagePersonalItem.getHasCompensationReceived()">
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.reportTheDamage.compensationAmount' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{this.damagePersonalItem.getCompensationCurrency()}} {{this.damagePersonalItem.getCompensationValue() | number : '1.2-2'}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="sub-section">
                <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
                <div class="row" *ngFor="let item of this.listOfDocuments;">
                  <div class="col col-xs-12 col-lg-12">
                      {{item.description | translate}}
                  </div>
                  <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                      <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                          {{ item.uploadedFileName }}
                      </div>
                  </ng-container>
              </div>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>
