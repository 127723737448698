import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../services/side-menu.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ClaimService} from "../../services/claim.service";
import {Claim} from "../../model/claim.model";
import {ClaimsBaseComponent} from "../claim-base.component";
import {takeUntil} from "rxjs";
import {TransactionInfo} from '../../model/transaction-info.model';
import {TransactionInfoService} from '../../services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
    selector: 'app-another-insurance',
    templateUrl: './another-insurance.component.html',
    styleUrls: ['./another-insurance.component.css']
})
export class AnotherInsuranceComponent extends ClaimsBaseComponent implements OnInit {

    anotherInsuranceForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    transactionInfo: TransactionInfo;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private router: Router,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private injector : Injector,
                private transactionInfoService: TransactionInfoService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.transactionInfo = transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {

        this.pushGAView();
        this.anotherInsuranceForm = this.fb.group({
            hasAnotherInsurance: [this.claim.getOtherPolicyQuestion()],
            insuranceName: [this.claim.getOtherPolicyName()],
            policyNumber: [this.claim.getOtherPolicyNumber()],
            hasSubmitToOtherInsurance: [super.getBooleanString(this.claim.getOtherPolicyGoingToSubmit())]
        });
    }

    ngAfterViewInit() {

        this.anotherInsuranceForm.valueChanges.subscribe(data => {

            this.claim.setOtherPolicyQuestion(this.anotherInsuranceForm.get('hasAnotherInsurance').value);
            this.claim.setOtherPolicyName(this.anotherInsuranceForm.get('insuranceName').value);
            this.claim.setOtherPolicyNumber(this.anotherInsuranceForm.get('policyNumber').value);
            this.claim.setOtherPolicyGoingToSubmit(this.anotherInsuranceForm.get('hasSubmitToOtherInsurance').value == 'true');
        });

        // Stop validation of the other field when reason isn't other
        let self = this;
        setTimeout(() => {self.hideOtherInsuranceFields(self.claim.getOtherPolicyQuestion())},10);
        this.anotherInsuranceForm.get('hasAnotherInsurance').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideOtherInsuranceFields(data);
        });
    }

    hideOtherInsuranceFields(hasOtherInsurance) {
        if (hasOtherInsurance && hasOtherInsurance.toLowerCase() == "y") {
            this.anotherInsuranceForm.get('insuranceName').enable({onlySelf: false, emitEvent: false});
            this.anotherInsuranceForm.get('policyNumber').enable({onlySelf: false, emitEvent: false});
            this.anotherInsuranceForm.get('hasSubmitToOtherInsurance').enable({onlySelf: false, emitEvent: false});
        } else {
            this.anotherInsuranceForm.get('insuranceName').reset();
            this.anotherInsuranceForm.get('policyNumber').reset();
            this.anotherInsuranceForm.get('hasSubmitToOtherInsurance').reset();
            this.anotherInsuranceForm.get('insuranceName').disable({onlySelf: false, emitEvent: false});
            this.anotherInsuranceForm.get('policyNumber').disable({onlySelf: false, emitEvent: false});
            this.anotherInsuranceForm.get('hasSubmitToOtherInsurance').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {
            let currentSelectedClaimType = this.transactionInfo.getCurrentClaimType();
            let url = '/claimform/paymentAndContact';
            if (this.claim.getCountry().toLowerCase() === 'sgp' && this.transactionInfo.isAccidentProduct()) {
                if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES] === currentSelectedClaimType) {
                    this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 4});
                    url = '/claimform/accidentalMedicalExpenses/supportDoc';
                } else if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS] === currentSelectedClaimType) {
                    this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 2});
                    url = '/claimform/paOthers/supportDoc';
                }
            } else {
                this.sideMenuService.emitComplete('finishChooseClaim');
            }
            this.router.navigate([url]);
        }
    }

    back() {

        if (this.transactionInfo.isGenericClaim()) {
            this.router.navigate(["/claimform/genericUploadDocument"], {
                relativeTo: this.activatedRoute
            });
        } else if (this.transactionInfo.isMotorProduct()) {
            let currentSelectedClaimType = this.transactionInfo.getCurrentClaimType();
            let currentDocumentRoute = "";

            switch(currentSelectedClaimType) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]:    // Theft
                    currentDocumentRoute = "/claimform/theft/supportDoc";
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]:    // Collision
                    currentDocumentRoute = "/claimform/collission/supportDoc";
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]:    // Windscreen
                    currentDocumentRoute = "/claimform/windscreen/supportDoc";
                    break;
                default:
            }

            this.router.navigate([currentDocumentRoute], {
                relativeTo: this.activatedRoute
            });
        } else if (this.transactionInfo.isHomeProduct()) {
            this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        } else if (this.transactionInfo.isAccidentProduct() || this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isEmployeeCompensationProduct()) {
            let currentSelectedClaimType = this.transactionInfoService.getTransactionInfo().getCurrentClaimType();
            if (currentSelectedClaimType) {
                this.redirectToCurrentClaimTypeDocument(currentSelectedClaimType);
            }
        }  else {
            this.router.navigate(["/claimform/moreClaimType"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.anotherInsuranceForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }


    // Google Analytics
    pushGAView(){
        let currentClaimType = this.transactionInfo.getCurrentClaimType();
        switch(currentClaimType) {
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]:
                this.pushGA('Theft/Attempt Theft– Other Insurance Cover',
                    '/claim/theft-attempt-theft/other-insurance-cover');
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]:
                this.pushGA('Motor Accident/Damage – Other Insurance Cover' ,
                    '/claim/motor-accident-damage/other-insurance-cover');
                break;
            default:
                this.pushGA('Another Insurance Cover',
                    '/another-insurance-cover');
        }
    }

    pushGA(gaStep: string, gaPath: string) {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': gaStep,
            'vPath': gaPath,
            'event': 'vpageview'
        });
    }

    redirectToCurrentClaimTypeDocument(currentClaimType) {
        let url = '';
        switch(currentClaimType) {
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]:
                if (this.claim.getCountry().toLowerCase() === 'sgp') {
                    url = "/claimform/accidentalMedicalExpenses/medicalCertificates";
                } else {
                    url = "/claimform/accidentalMedicalExpenses/supportDoc";
                }
                this.redirectToDocumentRouter(url);
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]:
                this.redirectToDocumentRouter("/claimform/hospitalCash/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]:
                url = this.claim.getCountry().toLowerCase() === 'sgp' ? "/claimform/paOthers/authorityReport" 
                        : "/claimform/paOthers/supportDoc";
                this.redirectToDocumentRouter(url);
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]:
                this.redirectToDocumentRouter("/claimform/clinical/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]:
                this.redirectToDocumentRouter("/claimform/dental/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL]:
                this.redirectToDocumentRouter("/claimform/hospitalization/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH]:
                this.redirectToDocumentRouter("/claimform/deathAndPermanentDisability/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY]:
                this.redirectToDocumentRouter("/claimform/helpersLiability/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]:
                this.redirectToDocumentRouter("/claimform/employeeCompensation/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY]:
                this.redirectToDocumentRouter("/claimform/dishonestyHelper/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]:
                this.redirectToDocumentRouter("/claimform/repatriation/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS]:
                this.redirectToDocumentRouter("/claimform/other/supportDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B]:
                this.redirectToDocumentRouter("/claimform/form2b/supportingDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A]:
                this.redirectToDocumentRouter("/claimform/form2a/supportingDoc");
                break;
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]:
                this.redirectToDocumentRouter("/claimform/form2/supportingDoc");
                break;
            default: // do nothing
        }
    }

    redirectToDocumentRouter(urlDoc: string) {
        this.router.navigate([urlDoc], {
            relativeTo: this.activatedRoute
        });
    }

}