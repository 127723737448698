import {Injectable} from '@angular/core';

@Injectable()
export class HomeHelperService {

    damageTypeList = [
        {
            "damageTypeId": 1,
            "damageTypeValue": "Person",
            "damageTypeName": "homeProduct.liability.thirdPartyLoss.incidentInvolveItems.person",
            "selected" : false
        },
        {
            "damageTypeId": 2,
            "damageTypeValue": "Property",
            "damageTypeName": "homeProduct.liability.thirdPartyLoss.incidentInvolveItems.property",
            "selected" : false
        }
    ];

    getDamageType(){
        return this.damageTypeList;
    }
}