<main>
    <section class = "claim-search-home col-12">
        <div class="claim-search-bg-overlay" id="bg-overlay-id"></div>
        <div class="home-wrap container">
            <h1 class="claim-search-desc">{{'claimStatus.description' | translate }}</h1>
        </div>
    </section>
    <section class="home-claim-simple bg-grey text-left">
        <div class="claim-search-home-form" [ngClass]="this.transactionInfo.getClaimNumber() === null ? 'mobile-border' : ''">
            <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
            <form>
                <div class="form-group section">
                    <label class="section-title">{{'claimStatus.summary.statusOfClaims' | translate}}</label>
                    <div class="section-content">
                        <BR>
                        <div class="row" style="justify-content: flex-start;">
                            <div class="col-6 col-lg-3">
                                <div class="claim-status-label">
                                    {{'claimStatus.summary.product' | translate}}
                                </div>
                                <div class="info-style">
                                    <span> {{ productDescription | translate}}</span>
                                </div>
                            </div>

                            <div class="col-6 col-lg-4">
                                <ng-container *ngIf="this.transactionInfo.getClaimNumber() === null">
                                    <div>{{'claimStatus.summary.policyNo' | translate}}</div>
                                    <div class="info-style">{{claimSearchResult.getPolicyNumber()}}</div>
                                </ng-container>
                                <ng-container *ngIf="this.transactionInfo.getClaimNumber() !== null">
                                    <div>{{'claimStatus.summary.claimNo' | translate}}</div>
                                    <div class="info-style">{{this.transactionInfo.getClaimNumber()}}</div>
                                </ng-container>
                            </div>

                            <div class="col-12 col-lg-5">
                                <div class="row btn-section">
                                    <button (click)="goToHomePage()" class="new-btn btn-claim-status btn">
                                        <ng-container *ngIf="this.transactionInfo.getClaimNumber() === null">
                                        {{ 'claimStatus.summary.checkAnotherPolicy' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="this.transactionInfo.getClaimNumber() !== null">
                                            {{ 'claimStatus.summary.checkAnotherClaim' | translate}}
                                        </ng-container>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="this.claimSearchResult != null && this.claimSearchResult.getClaimDetailsList().length > 0">
            <div *ngIf="this.transactionInfo.getClaimNumber() === null" class="row col-12 filter-grp">
                <div class="col-12 col-lg-5 form-group">
                    <qnect-ui #B2CCS_FILTER_DATE [fieldId]="'B2CCS_FILTER_DURATION'" [formGroup]="claimsSearchResultsFormGroup" (change)="filterResults()" name="filterDuration"></qnect-ui>
                </div>
                <div class="col-12 col-lg-5 form-group">
                    <qnect-ui #B2CCS_FILTER_STATUS [fieldId]="'B2CCS_FILTER_STATUS'" [formGroup]="claimsSearchResultsFormGroup" (change)="filterResults()" name="filterStatus" [dynamicOptions]="uiOptions"></qnect-ui>
                </div>
                <div class="col-12 col-lg-2"></div>
            </div>
            <div class="div-break"></div>
            <div *ngFor="let claimDetailItem of this.claimSearchDetailList;">
                <form>
                    <div class="claim-search-home-form-blue">
                        <div class="row" style="justify-content: flex-start;">
                            <div class="col-6 col-lg-6 claim-header-left">
                                {{'claimStatus.summary.claimNo' | translate}}: {{claimDetailItem.getClaimNumber()}}
                            </div>
                            <div class="col-6 col-lg-6 claim-header-right">
                                <ng-container *ngFor="let documents of claimDetailItem.getDocuments(); let last = last">
                                    <span *ngIf="documents.dDocName" [style.padding-right.px]="last ? 0 : 20">
                                        <a href="javascript: void(0);"  (click)="saveAsFile(documents.dDocName, documents.docFileType)">
                                            <em class="fas fa-download"></em> {{getDescriptionByDocType(documents.docFileType) | translate}}</a>
                                            <ng-container *ngIf="documents.docFileType !== 'Claim Details'"><em class="hits" (click)="instructionModal.open();"></em></ng-container>
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="claim-search-home-form">
                        <!-- Claim Status Details -->
                        <div class="form-group section">
                            <div class="section-content">
                                <div class="row" *ngIf="isNotNullOrUndefinedStr(claimDetailItem.getStatusDesc())">
                                    <div class="col-5 col-xs-12 col-lg-3">
                                        <img class="search-logo" src="{{claimDetailItem.getImage()}}">
                                    </div>
                                    <div class="col-7 col-xs-12 col-lg-9">
                                        <div class="row">
                                            <div class="col-12 status">
                                                {{claimDetailItem.getStatusDesc() | translate}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 status-description">
                                                {{claimDetailItem.getRemarks() | translate}}
                                            </div>
                                        </div>
                                        <BR><BR><BR>
                                        <div class="row">
                                            <div class="col-6 col-lg-6 col-xs-6">
                                                <div class="search-details">
                                                    <span class="search-details">{{'claimStatus.summary.policyHolder' | translate}}  </span>
                                                    <span class="details-style">{{claimDetailItem.getClientName()}}</span>
                                                </div>
                                                <div class="search-details" *ngIf="isNotNullOrUndefined(claimDetailItem.getLastUpdate())" >
                                                    <span class="search-details">{{'claimStatus.summary.updateAsOf' | translate}}  </span>
                                                    <span class="details-style"> {{getDate(claimDetailItem.getLastUpdate())}} </span>
                                                </div>
                                            </div>
                                            <div class="col-6 col-lg-6 col-xs-6">
                                                <div class="search-details">
                                                    <span class="search-details">{{'claimStatus.summary.dateOfLoss' | translate}} </span>
                                                    <span class="details-style"> {{getDate(claimDetailItem.getDateOfLoss())}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
</main>

<sign-instruction-modal #sign_instruction_modal></sign-instruction-modal>