<ng-container *ngIf="uiModel.multiField">
    <div class="form-group" [formGroup]="formGroup" [ngClass]="{'ui-error' : !formControl.valid && formControl.touched}"
         [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
            <input autocomplete="off" id="{{uiModel.name}}DateField{{frmArrayIndex}}" formControlName="{{uiModel.name}}" type="text"
                   (paste)="disabledPasteEvent($event)"
                   (keydown)="keydownEvent($event)"
                   class="form-control datetimepicker1" placeholder="{{uiModel.placeholder | translate}}"
                   [ngClass]="{'ui-disabled' : uiModel.disabled}"
                   [readonly]="disabled">
            <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | uppercase}}</p>
            <ui-error *ngIf="(formControl.touched || uiModel.textOnly)" [inputErrors]="formControl.errors"
                      [dictionary]="uiModel.dictionary"></ui-error>
        <span *ngIf="uiModel.popover" class="{{uiModel.popover.icon}} ui-icon" role="button"
              [popover]="uiModel.popover.content" placement="right" triggers="mouseenter:mouseleave"></span>
    </div>
</ng-container>
<ng-container *ngIf="!uiModel.multiField">
    <div class="form-group row" [formGroup]="formGroup" [ngClass]="{'ui-error' : !formControl.valid && formControl.touched}"
         [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
        <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
        <div class="col-md-{{uiModel.size}} col-xs-12">
            <input autocomplete="off" id="{{uiModel.name}}DateField{{frmArrayIndex}}" formControlName="{{uiModel.name}}" type="text"
                   (paste)="disabledPasteEvent($event)"
                   (keydown)="keydownEvent($event)"
                   class="form-control datetimepicker1" placeholder="{{uiModel.placeholder | translate}}" [ngClass]="{'ui-disabled' : uiModel.disabled}">

            <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | uppercase}}</p>
            <ui-error *ngIf="(formControl.touched || uiModel.textOnly)" [inputErrors]="formControl.errors"
                      [dictionary]="uiModel.dictionary"></ui-error>
        </div>
        <span *ngIf="uiModel.popover" class="{{uiModel.popover.icon}} ui-icon" role="button"
              [popover]="uiModel.popover.content" placement="right" triggers="mouseenter:mouseleave"></span>
    </div>
</ng-container>
