import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-right-view-tab',
  templateUrl: './right-view-tab.component.html'
})

@Injectable()
export class RightViewTabComponent {

  @Output() emitRightViewTabOnClickArea = new EventEmitter<any>();
  rightViewTabOnClickArea(e: any) {
    this.emitRightViewTabOnClickArea.emit(e);
  }

  @Output() emitRightViewTabShowTooltip = new EventEmitter<any>();
  rightViewTabShowTooltip(txt: any) {
    this.emitRightViewTabShowTooltip.emit(txt);
  }

  @Output() emitRightViewTabHideToolTip = new EventEmitter<any>();
  rightViewTabHideToolTip() {
    this.emitRightViewTabHideToolTip.emit();
  }

  @Input() rightViewTabGender: any;

  @Input() rightViewTabTemp: any;

  isSelected(id: string) {
    return this.rightViewTabTemp.some((obj: any) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }
}
