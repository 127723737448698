import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import { HomeFormBuilderUtil } from "./home-form-builder-util";

export class OthersHomeFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    isResidentialFire: boolean = false;
    constructor(claim: Claim, causeType, isResidentialFire){
       super(claim);
       this.documentFormClaimType = causeType;
       this.isResidentialFire = isResidentialFire;
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        if (this.isResidentialFire) {
            requiredDocuments.push(new DocumentField('RESFIREPHOTOS', 'claimDetails.generic.documents.resFire1', SmartqDocuments.PHOTOS, true));
            requiredDocuments.push(new DocumentField('RESFIREREPORT', 'claimDetails.generic.documents.resFire2', SmartqDocuments.REPORT, true));
            requiredDocuments.push(new DocumentField('RESFIREQUOTATION', 'claimDetails.generic.documents.resFire3', SmartqDocuments.REPAIRQUOTATION, true));
            requiredDocuments.push(new DocumentField('RESFIRERELREPORT', 'claimDetails.generic.documents.resFire4', SmartqDocuments.REPORT, true));
        } else {
            requiredDocuments.push(new DocumentField('OTHINCIDENTLETTER', 'homeProduct.supportingDocs.types.incidentReport', SmartqDocuments.HOME_LEGALDOC, true));
            requiredDocuments.push(new DocumentField('OTHPROOFRECEIPT', 'homeProduct.supportingDocs.types.proofOfPurchase', SmartqDocuments.HOME_INVOICE, true));
            requiredDocuments.push(new DocumentField('OTHPHOTOSITEMS', 'homeProduct.supportingDocs.types.photoItems', SmartqDocuments.HOME_PHOTOS, true));
            requiredDocuments.push(new DocumentField('OTHREPAIRQUOTES', 'homeProduct.supportingDocs.types.repairQuotations', SmartqDocuments.HOME_QUOTE, true));
            requiredDocuments.push(new DocumentField('OTHMGMTREPORT', 'homeProduct.supportingDocs.types.reportByManagement', SmartqDocuments.HOME_REPORT, true));

            let utilities: HomeFormBuilderUtil = new HomeFormBuilderUtil();
            requiredDocuments = requiredDocuments.concat(utilities.getRiskDocuments(this.claim));
        }
        
        this.requiredDocuments = requiredDocuments;
    }
}