<div class="form-group section">
    <label class="section-title">{{ 'ecClaim.selectClaim.form2a' | translate }}</label>
    <div class="section-content">
        <!-- details of injured employee -->
        <div class="sub-section">
            <label>{{ 'ecClaim.employeeDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.name' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7" *ngIf="employeeCompensation.getEmployeeDetails().getIdType() === 'HKID'">
                    {{ 'ecClaim.employeeDetails.hkid' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-7" *ngIf="employeeCompensation.getEmployeeDetails().getIdType() !== 'HKID'">
                    {{ 'ecClaim.employeeDetails.passport' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getIdNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.birthDate' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.getFormattedDate(employeeCompensation.getEmployeeDetails().getBirthDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.gender.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getGenderDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.telephone' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getTelephoneNumberCode()}}
                    {{employeeCompensation.getEmployeeDetails().getTelephoneNumber()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeDetails().getEmailAddress()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employerDetails.email' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getEmailAddress()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeDetails().getFaxNumber()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.fax' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getFaxNumber()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pagePaymentAndContact.contactInfo.address' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.occupation' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeDetails().getOccupation()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.apprentice.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(employeeCompensation.getEmployeeDetails().getIsApprentice())}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeDetails().getDurationDateFrom() || employeeCompensation.getEmployeeDetails().getDurationDateTo()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeDetails.durationDate' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDateDuration(employeeCompensation.getEmployeeDetails().getDurationDateFrom(), employeeCompensation.getEmployeeDetails().getDurationDateTo())}}
                </div>
            </div>
        </div>
        <!-- occupational disease -->
        <div class="sub-section">
            <label>{{ 'ecClaim.occupationalDisease.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.occupationalDisease.hospitalName' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getOccupationalDisease().getHospitalName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.occupationalDisease.commencementDate' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.getFormattedDate(employeeCompensation.getOccupationalDisease().getCommencementDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.occupationalDisease.suffering' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getOccupationalDisease().getSuffering()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.occupationalDisease.workType' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getOccupationalDisease().getWorkType()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.occupationalDisease.result.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getOccupationalDisease().getResultDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getOccupationalDisease().getResultDate()">
                <div class="col col-xs-12 col-lg-7">
                    {{getResultDateLabel(employeeCompensation.getOccupationalDisease().getResult())}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.getFormattedDate(employeeCompensation.getOccupationalDisease().getResultDate())}}
                </div>
            </div>
            <ng-container *ngIf="employeeCompensation.getOccupationalDisease().getResult()?.toLowerCase() == 'death'">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.police.label' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(employeeCompensation.getFatalCase().getWasPoliceNotified())}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.policeStation' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getPoliceStation()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.kin' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getKin()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.relationship' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getRelationship()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.telephoneNumber' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getTelephoneNumberCode()}}
                        {{employeeCompensation.getFatalCase().getTelephoneNumber()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.occupationalDisease.address' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getFatalCase().getAddress()}}
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- earnings of the injured employee -->
        <div class="sub-section">
            <label>{{ 'ecClaim.employeeEarnings.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.workingDays.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getWorkingDaysDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getWorkingDays()?.toLowerCase() == 'others'">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.specify' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getSpecify()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.paidOrUnpaid.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getPaidOrUnpaidDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.fixedOrFlexible.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getFixedOrFlexibleDesc()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getDayOfWeek()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.dayOfWeek.label' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getCommaSeparatedValues(employeeCompensation.getEmployeeEarnings().getDayOfWeek())}}
                </div>
            </div>
            <div class = "content" style="padding-top: 10px;">
                {{'ecClaim.employeeEarnings.monthlyEarningsForm2a' | translate}}
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.wages' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getWages() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getFoodAllowance()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.foodAllowance' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getFoodAllowance() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getOtherItem()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.otherItem' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getEmployeeEarnings().getOtherItem()}}
                </div>
            </div>
            <div class="row" *ngIf="employeeCompensation.getEmployeeEarnings().getOtherAmount()">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.otherAmount' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getOtherAmount() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.totalAmount' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getTotalAmount() | number : '1.2-2'}}
                </div>
            </div>
            <div class = "content" style="padding-top: 10px;">
                {{'ecClaim.employeeEarnings.averageMonthlyEarnings' | translate}}
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.employeeEarnings.averageEarnings' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getCurrency()}}
                    {{employeeCompensation.getEmployeeEarnings().getAverageEarnings() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <!-- direct settlement -->
        <ng-container *ngIf="this.isNotEmptyObject(employeeCompensation.getCompensationDetails()) || employeeCompensation.getPeriodOfSickLeaves()?.length > 0 ||
                         this.isNotNullOrUndefined(employeeCompensation.getIsSickLeaveContinuing())">
            <div class="sub-section">
                <label>{{ 'ecClaim.directSettlement.title' | translate }}</label>
                <ng-container *ngIf="employeeCompensation.getPeriodOfSickLeaves()?.length > 0">
                    <ng-container *ngFor="let period of employeeCompensation.getPeriodOfSickLeaves(); let i = index;">
                        <div *ngIf="period.getStartDate() && period.getEndDate()">
                            <div class="row">
                                <div class="col col-xs-12 col-lg-7" *ngIf="i == 0">
                                    {{ 'ecClaim.accidentDetails.periodOfSickLeave' | translate }}
                                </div>
                                <div class="col col-xs-12 col-lg-7" *ngIf="i != 0">
                                </div>
                                <div class="col col-xs-12 col-lg-5 content" *ngIf="period.getQuantityDesc()">
                                    {{period.getQuantityDesc()}}
                                </div>
                                <div class="col col-xs-12 col-lg-7" *ngIf="i == 0">
                                </div>
                                <div class="col col-xs-12 col-lg-7" *ngIf="i != 0"></div>
                                <div class="col col-xs-12 col-lg-5 content">
                                    {{getDateDuration(period.getStartDate(), period.getEndDate())}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row" *ngIf="employeeCompensation.getTotalSickLeaveDays() && employeeCompensation.getTotalSickLeaveDays() > 0">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.accidentDetails.sickLeaveDays' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{employeeCompensation.getTotalSickLeaveDays()}}
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'ecClaim.compensationDetails.isSickLeaveContinuing' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(employeeCompensation.getIsSickLeaveContinuing())}}
                    </div>
                    <ng-container *ngIf="employeeCompensation.getIsSickLeaveContinuing()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.compensationDetails.isDutySuitableForEmployee' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(employeeCompensation.getIsDutySuitableForEmployee())}}
                        </div>
                        <div class="col col-xs-12 col-lg-7">
                            {{'ecClaim.compensationDetails.contactEmployeeForValueAddedService' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{returnYesOrNo(employeeCompensation.getContactEmployeeForValueAddedService())}}
                        </div>
                    </ng-container>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getCompensationAmount()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.compensationAmount' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getCurrency()}}
                        {{employeeCompensation.getCompensationDetails().getCompensationAmount() | number : '1.2-2'}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getPaymentStatusDesc()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.paymentStatus.title' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{employeeCompensation.getCompensationDetails().getPaymentStatusDesc()}}
                    </div>
                </div>
                <div class="row" *ngIf="employeeCompensation.getCompensationDetails().getPaymentDate()">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'ecClaim.compensationDetails.paymentStatus.paidOn' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.getFormattedDate(employeeCompensation.getCompensationDetails().getPaymentDate())}}
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- insurance details -->
        <div class="sub-section">
            <label>{{ 'ecClaim.insuranceDetails.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.insuranceDetails.name' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getInsuranceDetails().getCompanyName()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.insuranceDetails.address' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getInsuranceDetails().getCompanyAddress()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'ecClaim.insuranceDetails.policyNumber' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{employeeCompensation.getInsuranceDetails().getPolicyNumber()}}
                </div>
            </div>
        </div>
        <!-- supporting documents -->
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                <div class="col col-xs-12 col-lg-12">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>