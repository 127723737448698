
export class DeathAndPermanentDisability {

    private countryOccurred: string;
    private countryOccurredDesc: string;
    private dateOfIncident: Date;
    private descriptionOfIncident: string;
    private totalAmountCurrency: string;
    private totalAmountValue: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getDateOfIncident(): Date {
        return this.dateOfIncident;
    }

    setDateOfIncident(value: Date) {
        this.dateOfIncident = value;
    }

    getDescriptionOfIncident(): string {
        return this.descriptionOfIncident;
    }

    setDescriptionOfIncident(value: string) {
        this.descriptionOfIncident = value;
    }

    getTotalAmountCurrency(): string {
        return this.totalAmountCurrency;
    }

    setTotalAmountCurrency(value: string) {
        this.totalAmountCurrency = value;
    }

    getTotalAmountValue(): string {
        return this.totalAmountValue;
    }

    setTotalAmountValue(value: string) {
        this.totalAmountValue = value;
    }

    getCountryOccurred(): string {
        return this.countryOccurred;
    }

    setCountryOccurred(value: string) {
        this.countryOccurred = value;
    }

    setCountryOccurredDesc(value: string) {
        this.countryOccurredDesc = value;
    }

    getCountryOccurredDesc(): string {
        return this.countryOccurredDesc;
    }

    static jsonConvert(deathAndPermanentDisability: DeathAndPermanentDisability): DeathAndPermanentDisability {

        //todo: convert date fields object if any
        if (deathAndPermanentDisability.getDateOfIncident() != null) {
            deathAndPermanentDisability.setDateOfIncident(new Date(deathAndPermanentDisability.getDateOfIncident()));
        }

        return deathAndPermanentDisability;
    }
}