import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {AbstractControl, Validators} from "@angular/forms";
import {UiValidators} from "../validators/ui-validator";

@Component({
  selector: 'qnect-ui-costfield',
  templateUrl: './ui-costfield.component.html',
  styleUrls: ['../ui-base.component.scss']
})
export class UiCostfieldComponent extends UiBaseComponent implements OnInit {

  @ViewChild('inputField', {static: true}) inputField: ElementRef;

  currencyFormControl: AbstractControl;
  currencySelectedText: string = '';
  amountFieldSize: string = '7';

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getDivRemainder() : number {
      return 12-parseInt (this.amountFieldSize)-4;
  }

  setValue(value: string) {
    this.formControl.setValue(value);
    if (this.inputField) {
      // blur the field to trigger formatting
      this.inputField.nativeElement.dispatchEvent(new Event('blur'));
    }
  }

  onKeyPress(event: any) {
    const pattern = /^[0-9\.]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }


}
