export class EmployerDetails {

    private employerName: string;
    private brn: string;
    private telephoneNumber: number;
    private telephoneNumberCode: string;
    private emailAddress: string;
    private faxNumber: string;
    private address: string;
    private trade: string;
    private isSubContractor: boolean;
    private isSubsidiary: boolean;
    private contractorName: string;
    private contractorBrn: string;
    private contractorTelNumber: number;
    private contractorTelNumberCode: string;
    private contractorEmail: string;
    private contractorFaxNumber: string;
    private contractorAddress: string;
    private contractorTrade: string;

    public getEmployerName(): string {
        return this.employerName;
    }

    public setEmployerName(employerName: string): void {
        this.employerName = employerName;
    }

    public getBrn(): string {
        return this.brn;
    }

    public setBrn(brn: string): void {
        this.brn = brn;
    }

    public getTelephoneNumber(): number {
        return this.telephoneNumber;
    }

    public setTelephoneNumber(telephoneNumber: number): void {
        this.telephoneNumber = telephoneNumber;
    }

    public getTelephoneNumberCode(): string {
        return this.telephoneNumberCode;
    }

    public setTelephoneNumberCode(telephoneNumberCode: string): void {
        this.telephoneNumberCode = telephoneNumberCode;
    }

    public getEmailAddress(): string {
        return this.emailAddress;
    }

    public setEmailAddress(emailAddress: string): void {
        this.emailAddress = emailAddress;
    }

    public getFaxNumber(): string {
        return this.faxNumber;
    }

    public setFaxNumber(faxNumber: string): void {
        this.faxNumber = faxNumber;
    }

    public getAddress(): string {
        return this.address;
    }

    public setAddress(address: string): void {
        this.address = address;
    }

    public getTrade(): string {
        return this.trade;
    }

    public setTrade(trade: string): void {
        this.trade = trade;
    }

    public getIsSubContractor(): boolean {
        return this.isSubContractor;
    }

    public setIsSubContractor(value: boolean) {
        this.isSubContractor = value;
    }

    public getIsSubsidiary(): boolean {
        return this.isSubsidiary;
    }

    public setIsSubsidiary(value: boolean) {
        this.isSubsidiary = value;
    }

    public getContractorName(): string {
        return this.contractorName;
    }

    public setContractorName(contractorName: string): void {
        this.contractorName = contractorName;
    }

    public getContractorBrn(): string {
        return this.contractorBrn;
    }

    public setContractorBrn(contractorBrn: string): void {
        this.contractorBrn = contractorBrn;
    }

    public getContractorTelNumber(): number {
        return this.contractorTelNumber;
    }

    public setContractorTelNumber(contractorTelNumber: number): void {
        this.contractorTelNumber = contractorTelNumber;
    }

    public getContractorTelNumberCode(): string {
        return this.contractorTelNumberCode;
    }

    public setContractorTelNumberCode(contractorTelNumberCode: string): void {
        this.contractorTelNumberCode = contractorTelNumberCode;
    }

    public getContractorEmail(): string {
        return this.contractorEmail;
    }

    public setContractorEmail(contractorEmail: string): void {
        this.contractorEmail = contractorEmail;
    }

    public getContractorFaxNumber(): string {
        return this.contractorFaxNumber;
    }

    public setContractorFaxNumber(faxNumber: string): void {
        this.contractorFaxNumber = faxNumber;
    }

    public getContractorAddress(): string {
        return this.contractorAddress;
    }

    public setContractorAddress(contractorAddress: string): void {
        this.contractorAddress = contractorAddress;
    }

    public getContractorTrade(): string {
        return this.contractorTrade;
    }

    public setContractorTrade(contractorTrade: string): void {
        this.contractorTrade = contractorTrade;
    }

    static jsonConvert(employerDetails: EmployerDetails): EmployerDetails {
        employerDetails = Object.assign(new EmployerDetails(), employerDetails);
        return employerDetails;
    }
}