<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : !formControl.valid && formControl.touched && !formControl.disabled }"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
  <qnect-ui-label-part *ngIf="!uiModel.hideLabel" [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
  <div [ngClass]="[uiModel.hideLabel ? 'col-md-12' : 'col-md-' + uiModel.size]" class="col-xs-12">
    <ng-multiselect-dropdown
            formControlName="{{uiModel.name}}"
            [placeholder]="placeHolder"
            [settings]="dropdownSettings"
            [data]="dropdownList"
            [(ngModel)]="selectedList"
            [ngClass]="formControl.touched && formControl.errors ? 'invalidField' : 'validField'">
    </ng-multiselect-dropdown>
    <ui-error *ngIf="formControl.touched" [inputErrors]="formControl.errors"
              [dictionary]="uiModel.dictionary"></ui-error>
  </div>
</div>
