
<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && ((!formControl.valid && formControl.touched) )) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
    <div class="col-md-{{uiModel.size}}">

        <div class="input-group">
            <div class="input-group-prepend">
                <button type="button" class="btn-drpdown dropdown-toggle" data-toggle="dropdown">{{countryCodeSelectedText}} </button>
                <input type="hidden" formControlName="{{uiModel.countryCodeFieldName}}" id="{{uiModel.countryCodeFieldId}}" 
                    value="{{countryCodeSelectedText}}">
                <div class="dropdown-menu scrollable-menu" role="menu">
                    <ng-container *ngFor="let option of uiModel.countryCodeOptions">
                        <span class="dropdown-item" (click)="countryCodeFieldChangeHandler(option.value)">{{option.label | translate}}</span>
                    </ng-container>
                </div>
            </div>
            <input id="{{fieldId}}" formControlName="{{uiModel.name}}" type="text" class="form-control"
                    placeholder="{{uiModel.placeholder | translate}}"
                    maxlength="{{uiModel.maxlength}}" autocomplete="off">
        </div>
        <div class="col-md-{{getDivRemainder()}}">
        </div>
        <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value}}</p>
        <ui-error *ngIf="(formControl.touched || uiModel.textOnly) " [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
    </div>
</div>
