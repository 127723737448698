<div>
  <div class="col-12">
    <h5>{{ 'homeProduct.confirmIncidentDetails.title' | translate }}</h5>
    <p>{{ 'homeProduct.confirmIncidentDetails.subtitle' | translate }}</p>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #HOME_DATEOFINCIDENT [fieldId]="'HOME_DATEOFINCIDENT'" [formGroup]="confirmIncidentDetails" name="dateOfIncident"></qnect-ui>
        </div>
        <ng-container *ngIf="!this.transactionInfo.isResidentialFire()">
            <div class="form-group">
                <qnect-ui #HOME_PLACEOFINCIDENT [fieldId]="'HOME_PLACEOFINCIDENT'" [formGroup]="confirmIncidentDetails" name="placeOfIncident"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="claim.getClaimHome().getPlaceOfIncident()=='home' && this.isNotNullOrUndefinedStr(claim.getClaimHome().getRiskAddress())">
                <label>{{'homeProduct.confirmIncidentDetails.isHomeWhereIncidentOccurred' | translate }}</label><BR>
                <span class="highlightAddress">{{claim.getClaimHome().getRiskAddress()}}</span><BR>
                <qnect-ui #HOME_ISHOMEWHEREINCIDENTOCCURRED [fieldId]="'HOME_ISHOMEWHEREINCIDENTOCCURRED'" [formGroup]="confirmIncidentDetails" name="isHomeWhereIncidentOccurred"></qnect-ui>
            </div>
        </ng-container>
        <div class="form-group" *ngIf="showMap">
          <qnect-ui #HOME_INCIDENT_OCCUR_MAP [fieldId]="'HOME_INCIDENT_OCCUR_MAP'" [formGroup]="confirmIncidentDetails" name="incidentAccidentOccur"></qnect-ui>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>

</div>

<ng-template #confirm_delete_claim_type let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h5 class="modal-title">{{'homeProduct.deleteClaim.header' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p>{{'homeProduct.deleteClaim.question1' | translate }}</p>
      <span><p>{{'homeProduct.deleteClaim.question2' | translate }}</p></span>
  </div>
  <div class="modal-footer">
      <div class="col-md-6 text-right">
          <button type="button" class="btn btn-primary btn-sm" (click)="deleteClaimType()">{{'homeProduct.deleteClaim.yes' | translate }}</button>
      </div>
      <div class="col-md-6 text-left">
          <button type="button" class="btn btn-default btn-sm" (click)="c('Close click')">{{'homeProduct.deleteClaim.no' | translate }}</button>
      </div>
  </div>
</ng-template>
