import {InsuredPerson} from './insured-person.model';

export class IntegrationRiskJson {

  private riskNumber: number;
  private riskClass: string;
  private insuredPersonsList: InsuredPerson[] = [];
  private premiumClassesList: string[] = [];
  private headOfficeClassesList: string[] = [];
  private planCode: string;
  private periodOfInsurance: string;
  private areaCode: string;
  private clausesList: string[] = [];
  private vehicleRegistrationNumber: string;
  private makeAndModel: string;
  private engineNumber: string;
  private chassisNumber: string;
  private yearOfManufacture: string;
  private coverType: string;
  private riskLocation: string;
  private fixFitFlag: string;

  getPlanCode(): string {
    return this.planCode;
  }

  setPlanCode(value: string) {
    this.planCode = value;
  }

  getPeriodOfInsurance(): string {
    return this.periodOfInsurance;
  }

  setPeriodOfInsurance(value: string) {
    this.periodOfInsurance = value;
  }

  getAreaCode(): string {
    return this.areaCode;
  }

  setAreaCode(value: string) {
    this.areaCode = value;
  }

  getRiskNumber(): number {
    return this.riskNumber;
  }

  setRiskNumber(value: number) {
    this.riskNumber = value;
  }

  getRiskClass(): string {
    return this.riskClass;
  }

  setRiskClass(value: string) {
    this.riskClass = value;
  }

  getInsuredPersonsList(): InsuredPerson[] {
    return this.insuredPersonsList;
  }

  setInsuredPersonsList(value: InsuredPerson[]) {
    this.insuredPersonsList = value;
  }

  getPremiumClassesList(): string[] {
    return this.premiumClassesList;
  }

  setPremiumClassesList(value: string[]) {
    this.premiumClassesList = value;
  }

  getHeadOfficeClassesList(): string[] {
    return this.headOfficeClassesList;
  }

  setHeadOfficeClassesList(value: string[]) {
    this.headOfficeClassesList = value;
  }

  getVehicleRegistrationNumber(): string {
    return this.vehicleRegistrationNumber;
  }

  setVehicleRegistrationNumber(value: string) {
    this.vehicleRegistrationNumber = value;
  }

    getMakeAndModel(): string {
        return this.makeAndModel;
    }

    setMakeAndModel(value: string) {
        this.makeAndModel = value;
    }

    getEngineNumber(): string {
        return this.engineNumber;
    }

    setEngineNumber(value: string) {
        this.engineNumber = value;
    }

    getChassisNumber(): string {
        return this.chassisNumber;
    }

    setChassisNumber(value: string) {
        this.chassisNumber = value;
    }

    getYearOfManufacture(): string {
        return this.yearOfManufacture;
    }

    setYearOfManufacture(value: string) {
        this.yearOfManufacture = value;
    }

    getCoverType(): string {
        return this.coverType;
    }

    setCoverType(value: string) {
        this.coverType = value;
    }

    getClausesList(): string[] {
        return this.clausesList;
    }

    setClausesList(value: string[]) {
        this.clausesList = value;
    }

    getRiskLocation(): string {
        return this.riskLocation;
    }

    setRiskLocation(value: string) {
        this.riskLocation = value;
    }

  getFixFitFlag(): string {
    return this.fixFitFlag;
  }

  setFixFitFlag(value: string) {
    this.fixFitFlag = value;
  }

  static jsonConvert(integrationJson: IntegrationRiskJson): IntegrationRiskJson {
      if (integrationJson.getInsuredPersonsList()) {
          let insuredPersonsList: InsuredPerson[] = [];
          for (const insuredPerson of integrationJson.getInsuredPersonsList()) {
              let item = Object.assign(new InsuredPerson(), insuredPerson);
              item = InsuredPerson.jsonConvert(item);
              insuredPersonsList.push(item);
          }
          integrationJson.setInsuredPersonsList(insuredPersonsList);
      }

    return integrationJson;
  }


}
