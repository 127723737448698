import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UtilitiesModule} from '../utilities/utilities.module';

import {UiComponent} from './ui.component';
import {UiTextfieldComponent} from './ui-textfield/ui-textfield.component';
import {UiTextfieldLabelComponent} from './ui-textfield-label/ui-textfield-label.component';
import {UiMoneyfieldComponent} from './ui-moneyfield/ui-moneyfield.component';
import {UiUnknownTypeComponent} from './ui-unknown-type/ui-unknown-type.component';
import {UiButtonGrpSingleSelectComponent} from './ui-button-grp-single-select/ui-button-grp-single-select.component';
import {UiMultipleSelectComponent} from './ui-multiple-select/ui-multiple-select.component';
import {UiDropdownSelectComponent} from './ui-dropdown-select/ui-dropdown-select.component';
import {UiRadioComponent} from './ui-radio/ui-radio.component';
import {UiLabelPartComponent} from './parts/ui-label-part/ui-label-part.component';
import {UiCaptionComponent} from './ui-caption/ui-caption.component';
import {UiDatepickerComponent} from './ui-datepicker/ui-datepicker.component';
import {DateService} from './ui-datepicker/date.service';
import {UiFormService} from './ui-form.service';
import {UiTimepickerComponent} from './ui-timepicker/ui-timepicker.component';
import {UiOptionsService} from './ui-options.service';
import {UiOptionsPipe} from './ui-options.pipe';
import {UiErrorComponent} from './parts/ui-error/ui-error.component';
import {UiTextareaComponent} from './ui-textarea/ui-textarea.component';
import {ToggleDisplayOnParentValueEnterDirective} from './directives/toggle-display-on-parent-value-enter.directive';
import {ToggleDisplayOnParentValueChangeDirective} from './directives/toggle-display-on-parent-value-change.directive';
import {ParentPercentDefaulterDirective} from './directives/parent-percent-defaulter.directive';
import {ParentTotalAggregatorDirective} from './directives/parent-total-aggregator';
import {MaxDefaultValueValidatorDirective} from './directives/max-default-value-validator.directive';
import {ToggleDisableOnParentValueChangeDirective} from './directives/toggle-disable-on-parent-value-change.directive';
import {ClearValueOnHideDirective} from './directives/clear-value-on-hide.directive';
import {ClearValueOnParentValueClearDirective} from './directives/clear-value-on-parent-value-clear.directive';
import {ChangeValueOnParentValueChangeDirective} from './directives/change-value-on-parent-value-change.directive';
import {DateValidatorDirective} from './validators/directives/date-validator.directive';
import {AboveMaxDateValidatorDirective} from './validators/directives/above-max-date-validator.directive'
import {ToggleEnableOnParentValueChangeDirective} from './directives/toggle-enable-on-parent-value-change.directive';
import {UiConfigService} from "./ui-config.service";
import {PageScroll} from "./directives/page-scroll/page-scroll.directive";
import {TranslateModule} from "@ngx-translate/core";
import {HttpClientModule} from "@angular/common/http";
import {UiDateTimePickerComponent} from "./ui-datetimepicker/ui-datetimepicker.component";
import { EmailValidatorDirective } from './validators/directives/email-validator.directive';
import {UiCostfieldComponent} from './ui-costfield/ui-costfield.component';
import {HkidValidatorDirective} from './validators/directives/hkid-validator.directive';
import { UiMapComponent } from './ui-map/ui-map.component';
import { AgmCoreModule } from '@agm/core';
import { UiCostfieldCurrencyComponent } from './ui-costfield-currency/ui-costfield-currency.component';
import {UiPhoneNumberComponent} from './ui-phonenumber/ui-phonenumber.component';
import { DateRangeValidatorDirective } from './validators/directives/date-range-validator.directive';
import {UiRadioButtonComponent} from "./ui-radio-button/ui-radio-button.component";
import {UiDropdownTextfieldComponent} from './ui-dropdown-textfield/ui-dropdown-textfield.component';
import {MaxValidatorDirective} from './validators/directives/max-validator.directive';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {UiMultiSelectDropdownComponent} from './ui-multiselect-dropdown/ui-multiselect-dropdown.component';
import { UiPasswordfieldComponent } from './ui-passwordfield/ui-passwordfield.component';


@NgModule({
  declarations: [
    UiComponent,
    UiTextfieldComponent,
    UiTextfieldLabelComponent,
    UiMoneyfieldComponent,
    UiUnknownTypeComponent,
    UiButtonGrpSingleSelectComponent,
    UiMultipleSelectComponent,
    UiDropdownSelectComponent,
    UiRadioComponent,
    UiRadioButtonComponent,
    UiLabelPartComponent,
    UiCaptionComponent,
    UiDatepickerComponent,
    UiErrorComponent,
    UiTimepickerComponent,
    UiTextareaComponent,
    UiDateTimePickerComponent,
    ToggleDisplayOnParentValueEnterDirective,
    ToggleDisplayOnParentValueChangeDirective,
    ParentPercentDefaulterDirective,
    ParentTotalAggregatorDirective,
    MaxDefaultValueValidatorDirective,
    ToggleDisableOnParentValueChangeDirective,
    ToggleEnableOnParentValueChangeDirective,
    ClearValueOnHideDirective,
    ClearValueOnParentValueClearDirective,
    ChangeValueOnParentValueChangeDirective,
    UiOptionsPipe,
    DateValidatorDirective,
    AboveMaxDateValidatorDirective,
    PageScroll,
    EmailValidatorDirective,
    UiCostfieldComponent,
    HkidValidatorDirective,
    UiMapComponent,
    UiCostfieldCurrencyComponent,
    UiPhoneNumberComponent,
    DateRangeValidatorDirective,
    UiDropdownTextfieldComponent,
    MaxValidatorDirective,
    UiMultiSelectDropdownComponent,
    UiPasswordfieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UtilitiesModule,
    TranslateModule,
    AgmCoreModule,
    NgMultiSelectDropDownModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UiComponent,
    UiTextfieldComponent,
    UiTextfieldLabelComponent,
    UiMoneyfieldComponent,
    UiUnknownTypeComponent,
    UiButtonGrpSingleSelectComponent,
    UiMultipleSelectComponent,
    UiDropdownSelectComponent,
    UiRadioComponent,
    UiRadioButtonComponent,
    UiLabelPartComponent,
    UiCaptionComponent,
    UiDatepickerComponent,
    UiErrorComponent,
    UiTextareaComponent,
    UiDateTimePickerComponent,
    UiTimepickerComponent,
    UiMapComponent,
    UiMultiSelectDropdownComponent,
    ToggleDisplayOnParentValueEnterDirective,
    ToggleDisplayOnParentValueChangeDirective,
    ParentPercentDefaulterDirective,
    ParentTotalAggregatorDirective,
    MaxDefaultValueValidatorDirective,
    ToggleDisableOnParentValueChangeDirective,
    ToggleEnableOnParentValueChangeDirective,
    ClearValueOnHideDirective,
    ClearValueOnParentValueClearDirective,
    ChangeValueOnParentValueChangeDirective,
    UiOptionsPipe,
    DateValidatorDirective,
    AboveMaxDateValidatorDirective,
    EmailValidatorDirective,
    UiCostfieldComponent,
    HkidValidatorDirective,
    UiCostfieldCurrencyComponent,
    DateRangeValidatorDirective,
    UiDropdownTextfieldComponent,
    MaxValidatorDirective,
    UiPasswordfieldComponent
  ],
  providers: [
    UiOptionsService,
    UiConfigService,
    UiFormService,
    DateService
  ]
})
export class UiModule {
}
