<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{'pagePaymentAndContact.contactInfo.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{'pagePaymentAndContact.contactInfo.title' | translate}}</h5>
    <p>{{'pagePaymentAndContact.contactInfo.acctContactInfo' | translate}}</p>
  </div>
  <div class="row col-12">
    <div class="col-lg-8 col-xl-8 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #CLM10_CONTACT_NAME [fieldId]="'CLM10_CONTACT_NAME'"  (focusout)="applyP400Format($event, 'contactName')"
                    [formGroup]="contactInfoForm" name="contactName" [uiPopover]="uiPopover"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #CLM04_CONTACT_NUMBER [fieldId]="'CLM04_CONTACT_NUMBER'" [formGroup]="contactInfoForm" name="contactNumber"></qnect-ui>
          <small>{{'pagePaymentAndContact.contactInfo.numberUsedForWhatsApp' | translate}}</small>
        </div>
        <div class="form-group">
          <qnect-ui #CLM05_EMAIL [fieldId]="'CLM05_EMAIL'" [formGroup]="contactInfoForm" name="contactEmail"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui (paste)="disabledPasteEvent($event)" #CLM06_EMAIL [fieldId]="'CLM06_EMAIL'" [claimsEmailValidator]="this.claim.getContactEmail()" [formGroup]="contactInfoForm" name="contactEmail"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="!transactionInfo.isGroupProduct()">
          <qnect-ui #AGENT_EMAIL_NOTIFICATION [fieldId]="'AGENT_EMAIL_NOTIFICATION'" [formGroup]="contactInfoForm"
                    name="isAgentEmailNotification"  [type]="this.transactionInfo.isEmployeeCompensationProduct() ? 'radiobutton' : 'radio'"></qnect-ui>
        </div>
        <ng-container *ngIf="transactionInfo.isGroupProduct()">
          <div class="form-group">
            <qnect-ui #AGENT_EMAIL [fieldId]="'AGENT_EMAIL'" [formGroup]="contactInfoForm" name="agentEmail" [uiPopover]="uiPopoverAgentEmail"></qnect-ui>
            <small>{{'pagePaymentAndContact.contactInfo.agentEmailInfo' | translate}}</small>
          </div>
          <div class="form-group">
            <qnect-ui #SHARE_DOCUMENTS [fieldId]="'SHARE_DOCUMENTS'" [formGroup]="contactInfoForm" name="documentSharedToAgent"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-4 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'pagePaymentAndContact.contactInfo.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{'pagePaymentAndContact.contactInfo.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
