<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
        <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{'claimSection.damagePersonalItem.detailsOfDamageItems.title' | translate}}</p>
    </div>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form [formGroup]="damageDetailForm">
                <div formArrayName="items" *ngFor="let item of damageDetailForm.get('items')['controls']; let i = index;">
                    <div [formGroupName]="i">
                        <div class="form-group click-more-content">
                            <label>{{'claimSection.damagePersonalItem.detailsOfDamageItems.item' | translate}} &nbsp; {{i+1}}</label><button [hidden]="i==0" class="removeBtn" (click)="removeItem(i)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button><BR>
                            <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_11'"
                                      [frmArrayGroup]="damageDetailForm"
                                      [frmArrayName]="'items'"
                                      frmArrayIndex="{{i}}"
                                      name="lossType"></qnect-ui>
                        </div>
                        <div *ngIf="this.detailsOfDamageItemsArray && this.detailsOfDamageItemsArray.length > 0">
                            <ng-container *ngIf="this.isNotNullOrUndefinedStr(this.detailsOfDamageItemsArray[i].getLossType())">
                                <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() == 'Mobile Phone'" class="form-group">
                                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_18'"
                                              [frmArrayGroup]="damageDetailForm"
                                              [frmArrayName]="'items'"
                                              frmArrayIndex="{{i}}"
                                              name="descOfIncident"></qnect-ui>
                                </div>
                                <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() == 'Others'">
                                    <div class="form-group">
                                        <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_14'"
                                                  [frmArrayGroup]="damageDetailForm"
                                                  [frmArrayName]="'items'"
                                                  frmArrayIndex="{{i}}"
                                                  name="lossTypeDetail"></qnect-ui>
                                    </div>
                                    <div class="form-group click-more-content" id="q1">
                                        <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_15'"
                                                  [frmArrayGroup]="damageDetailForm"
                                                  [frmArrayName]="'items'"
                                                  frmArrayIndex="{{i}}"
                                                  name="otherTypeBriefDesc"></qnect-ui>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_12'"
                                              [frmArrayGroup]="damageDetailForm"
                                              [frmArrayName]="'items'"
                                              frmArrayIndex="{{i}}"
                                              [aboveMaxDateValidator]="this.claim.getClaimTravel().getDamagePersonalItem().getDateTheDamageOccurred()"
                                              name="purchaseDate"></qnect-ui>
                                </div>
                                <div class="form-group">
                                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_13'"
                                              [frmArrayGroup]="damageDetailForm"
                                              [frmArrayName]="'items'"
                                              frmArrayIndex="{{i}}"
                                              name="purchasePrice"></qnect-ui>
                                </div>
                                <div class="form-group" *ngIf="this.claim.getCountry().toLowerCase() == 'hkg' || this.claim.getCountry().toLowerCase() == 'hgi'">
                                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_19'"
                                              [frmArrayGroup]="damageDetailForm"
                                              [frmArrayName]="'items'"
                                              frmArrayIndex="{{i}}"
                                              name="hasOriginalPurchasedReceipt"></qnect-ui>
                                    <div class="row col-md-8 col-xs-12" *ngIf="this.detailsOfDamageItemsArray[i].getHasOriginalPurchasedReceipt() == true">
                                      <span style="margin-right: auto">
                                         <small>{{'claimSection.damagePersonalItem.detailsOfDamageItems.hasOriginalPurchasedReceiptDesc1' | translate}}</small>
                                      </span>
                                    </div>
                                    <div class="row col-md-8 col-xs-12" *ngIf="this.detailsOfDamageItemsArray[i].getHasOriginalPurchasedReceipt() == false">
                                     <span style="margin-right: auto">
                                         <small>{{'claimSection.damagePersonalItem.detailsOfDamageItems.hasOriginalPurchasedReceiptDesc2' | translate}}</small>
                                     </span>
                                     <span style="margin-right: auto">
                                          <small>{{'claimSection.damagePersonalItem.detailsOfDamageItems.hasOriginalPurchasedReceiptDesc3' | translate}}</small>
                                     </span>
                                    </div>
                                </div>
                                <ng-container *ngIf="this.isHKGorHGI && ['luggage / trunk', 'suitcase / case'].indexOf(this.detailsOfDamageItemsArray[i].getLossType()?.toLowerCase()) !== -1">
                                    <div class="form-group">
                                        <label> {{'claimSection.damagePersonalItem.detailsOfDamageItems.provideMoreDetailOfTheDamage' | translate}}</label>
                                        <div class="row col-md-8 col-xs-12">
                                            <span style="margin-right: auto">
                                                 <small>{{'claimSection.damagePersonalItem.detailsOfDamageItems.provideMoreDetailOfTheDamageDesc' | translate}}</small>
                                             </span>
                                        </div>
                                    </div>
                                    <div *ngIf="showCheckboxError">
                                        <small id="error-message">{{'claimSection.damagePersonalItem.detailsOfDamageItems.error.damageItemNotSelected' | translate}}</small>
                                    </div>
                                    <div class="form-group">
                                        <input type="checkbox" class="damage-item-check-input" id="hasBrokenHandle" formControlName="hasBrokenHandle"
                                               [checked]="this.detailsOfDamageItemsArray[i].getHasBrokenHandle()" >
                                        <label for="hasBrokenHandle" class="damage-item"  [innerHtml]="'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.brokenHandle' | translate"></label>
                                    </div>
                                    <div class="form-group">
                                        <input type="checkbox" class="damage-item-check-input" id="hasDamagedWheel" formControlName="hasDamagedWheel"
                                               [checked]="this.detailsOfDamageItemsArray[i].getHasDamagedWheel()">
                                        <label for="hasDamagedWheel" class="damage-item"  [innerHtml]="'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.damagedWheel' | translate"></label>
                                    </div>
                                    <div class="form-group">
                                        <input type="checkbox" class="damage-item-check-input" id="hasDamagedZip" formControlName="hasDamagedZip"
                                               [checked]="this.detailsOfDamageItemsArray[i].getHasDamagedZip()">
                                        <label for="hasDamagedZip" class="damage-item"  [innerHtml]="'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.damagedZip' | translate"></label>
                                    </div>
                                    <div class="form-group">
                                        <input type="checkbox" class="damage-item-check-input" id="hasScratches" formControlName="hasScratches"
                                               [checked]="this.detailsOfDamageItemsArray[i].getHasScratches()">
                                        <label for="hasScratches" class="damage-item"  [innerHtml]="'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.scratches' | translate"></label>
                                    </div>
                                    <div class="form-group">
                                        <input type="checkbox" class="damage-item-check-input" id="hasCrushedCrackedTorn" formControlName="hasCrushedCrackedTorn"
                                               [checked]="this.detailsOfDamageItemsArray[i].getHasCrushedCrackedTorn()">
                                        <label for="hasCrushedCrackedTorn" class="damage-item"  [innerHtml]="'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.crushedCrackedTorn' | translate"></label>
                                    </div>
                                    <div class="form-group">
                                        <input type="checkbox" class="damage-item-check-input" id="hasNoDamagedParts" formControlName="hasNoDamagedParts"
                                               [checked]="this.detailsOfDamageItemsArray[i].getHasNoDamagedParts()">
                                        <label for="hasNoDamagedParts" class="damage-item"  [innerHtml]="'claimSection.damagePersonalItem.detailsOfDamageItems.damagedParts.noneOfTheAbove' | translate"></label>
                                    </div>
                                    <div class="form-group" *ngIf="this.detailsOfDamageItemsArray[i].getHasNoDamagedParts() == true">
                                        <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_20'"
                                                  [frmArrayGroup]="damageDetailForm"
                                                  [frmArrayName]="'items'"
                                                  frmArrayIndex="{{i}}"
                                                  name="damageDetail"></qnect-ui>
                                    </div>
                                    <div class="form-group">
                                        <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_21'"
                                                  [frmArrayGroup]="damageDetailForm"
                                                  [frmArrayName]="'items'"
                                                  frmArrayIndex="{{i}}"
                                                  name="hasRepairOrQuotationReceipt"></qnect-ui>
                                    </div>
                                    <div class="form-group" *ngIf="this.detailsOfDamageItemsArray[i].getHasRepairOrQuotationReceipt() == true">
                                        <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_16'"
                                                  [frmArrayGroup]="damageDetailForm"
                                                  [frmArrayName]="'items'"
                                                  frmArrayIndex="{{i}}"
                                                  [amountFieldRequired]="true"
                                                  name="repairCost"></qnect-ui>
                                        <div class="row col-md-8 col-xs-12">
                                          <span style="margin-right: auto">
                                            <small>{{'claimSection.damagePersonalItem.detailsOfDamageItems.repairCostInfo' | translate}}</small>
                                          </span>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="form-group" *ngIf="(this.isHKGorHGI && ['luggage / trunk', 'suitcase / case'].indexOf(this.detailsOfDamageItemsArray[i].getLossType()?.toLowerCase()) === -1) ||
                                        (['SGP', 'MYS'].indexOf(this.claim.getCountry()?.toUpperCase()) !== -1)">
                                    <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_16'"
                                              [frmArrayGroup]="damageDetailForm"
                                              [frmArrayName]="'items'"
                                              frmArrayIndex="{{i}}"
                                              name="repairCost"></qnect-ui>
                                    <div class="row col-md-8 col-xs-12">
                                      <span style="margin-right: auto">
                                        <small>{{'claimSection.damagePersonalItem.detailsOfDamageItems.repairCostInfo' | translate}}</small>
                                      </span>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() == 'Watch' && this.claim.getCountry().toLowerCase() !== 'hgi'" class="form-group click-more-content">
                                <qnect-ui [fieldId]="'DAMAGE_PERS_ITEM_17'"
                                          [frmArrayGroup]="damageDetailForm"
                                          [frmArrayName]="'items'"
                                          frmArrayIndex="{{i}}"
                                          name="wasWatchBeingStored"></qnect-ui>
                            </div>
                            <div *ngIf="this.detailsOfDamageItemsArray[i].getLossType() != null && this.detailsOfDamageItemsArray[i].getLossType() != ''">
                                <div class="dept-del-info col-xl-8 col-xs-12 col-md-8">
                                    <div class="row col-12 col-md-12">
                                        <div class="col-3 col-md-2 percent-text"> % </div>
                                        <div class=" col-9 col-md-10 dept-desc-text">{{'claimSection.damagePersonalItem.detailsOfDamageItems.depreciationInfo' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-12">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </form>
            <div class="addFile" (click)="addItem()">
                <i class="fas fa-plus"></i>
                <span>{{ 'claimSection.generalLabels.generalButtonLabels.add' | translate}}</span>
            </div>
        </div>
        <div class="col-xl-3 col-lg-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>