import { Injectable } from '@angular/core';
import {UiConfigService} from './ui-config.service';
import {UiComponent} from './ui.component';
import {Observable} from "rxjs";

@Injectable()
export class UiOptionsService {

  constructor(private uiConfigService: UiConfigService) { }

  public getConfigOption(id: string, value: string): Observable<string> {
    let optionValue: Observable<string> = new Observable<string>();
    this.uiConfigService.loadUiConfig().subscribe(data => {
      const temp = data[id]['options'];
      optionValue = Array.isArray(temp) ? temp.find(each =>
          each.value === value
        ).label : temp[value];
    });
    return optionValue;
  }

  public getUiOption(uiComponent: UiComponent, value: string): string {
    return uiComponent.getOptionLabel(value);
  }


}
