<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && ((!formControl.valid && formControl.touched) )) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
    <div class="col-md-{{uiModel.size}} col-xs-12">
        <div class="input-group" *ngIf="!uiModel.textOnly">
            <div class="input-group-prepend">
                <span class="input-group-text">{{uiModel.currencyFieldStaticValue}}</span>
            </div>
            <input id="{{fieldId}}"
                    formControlName="{{uiModel.name}}"
                    type="text"
                    [ngClass]="{'ui-disabled' : uiModel.disabled}"
                    autocomplete="off"
                    class="form-control"
                    aria-label="Amount (to the nearest dollar)"
                    (change)="formatValue($event)"
                    (keypress)="onKeyPress($event)"
                    maxlength="{{uiModel.maxlength}}"
                    #inputField
                    qnectCurrencyInputFormatter>
        </div>
        <div class="col-md-{{getDivRemainder()}}">
        </div>
        <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value | translate}}</p>
        <ui-error *ngIf="(formControl.touched || uiModel.textOnly) " [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
    </div>

    <span *ngIf="uiModel.popover" style="margin-top:10px" class="{{uiModel.popover.icon}} ui-icon" role="button"
          [popover]="uiModel.popover.content" placement="right" triggers="mouseenter:mouseleave"></span>
</div>
