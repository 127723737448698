import { Component, OnInit } from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
  selector: 'app-domestic-employer-claim',
  templateUrl: './domestic-employer-claim.component.html',
  styleUrls: ['./domestic-employer-claim.component.css']
})
export class DomesticEmployerClaimComponent implements OnInit {

  constructor( private transactionInfoService: TransactionInfoService) {
      this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_DMH]);
  }

  ngOnInit() {
  }

}


