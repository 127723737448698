<div class="section form-group">
    <label class="section-title">{{'paClaim.hospitalCash.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <ng-container *ngFor="let item of this.hospitalCash.getHospitalExpenses(); let i = index;">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.common.hospitalExpenseTitle' | translate}} {{i + 1}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.common.hospitalName' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{item.getHospitalName()}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.common.dateAdmission' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(item.getDateAdmission())}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.common.dateDischarge' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDateTime(item.getDateDischarge())}}
                    </div>
                </div>

                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{'paClaim.common.hospitalExpense' | translate}}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{item.getHospitalExpenseCurrency()}} {{item.getHospitalExpense() | number : '1.2-2'}}
                    </div>
                </div>
            </ng-container>
            <div class="row" *ngIf="isNotNullOrUndefinedStr(hospitalCash.getRequiredSurgery())">
                <div class="col col-xs-12 col-lg-7 ">
                    {{'paClaim.hospitalCash.requiredSurgery' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content ">
                    {{hospitalCash.getRequiredSurgeryDesc()}}
                </div>
            </div>
        </div>

        <div class="sub-section ">
            <label>{{'motorClaim.claimSection.windscreen.documents.description' | translate}}</label>
            <div class="row " *ngFor="let item of this.listOfDocuments; ">
                <div class="col col-xs-12 col-lg-12 ">
                    {{item.description | translate}}
                </div>
                <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !='' ; else documentNotUploaded ">
                    <div class="col col-xs-12 col-lg-12 file-uploaded-summary ">
                        {{ item.uploadedFileName }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="sub-section " *ngIf="this.listOfOtherDocuments.length> 0">
                <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
                <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
                    <div class="col col-xs-12 col-lg-12">
                        {{item.description | translate}}
                    </div>
                    <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                        <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                            {{ item.uploadedFileName }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <ng-template #documentNotUploaded>
        <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
            {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
        </div>
    </ng-template>