import {Component, Injector, OnInit} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import { TransactionInfo } from "src/app/model/transaction-info.model";

@Component({
    selector: 'app-summary-delarations',
    templateUrl: './summary-declarations.component.html',
    styleUrls: ['./summary-declarations.component.css']
})
export class SummaryDeclarationsComponent extends ClaimsBaseComponent  implements OnInit {

    declarationTermsForm: FormGroup;
    transactionInfo : TransactionInfo;
    constructor(private modalService: NgbModal,
                private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.transactionInfo =  this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.declarationTermsForm = this.fb.group({
            termsAndConditions: [this.claim.getTermsAndConditionsCheckbox(), Validators.requiredTrue]
        });
    }

    ngAfterViewInit() {
        this.declarationTermsForm.valueChanges.subscribe(data => {
            this.claim.setTermsAndConditionsCheckbox(this.declarationTermsForm.get('termsAndConditions').value === true);
        });
    }

    openTermsAndCondition(content, name) {
        this.modalService.open(content, {size: 'lg'});
        this.pushGATerms(name);
    }

    getPicsContent() {
        return "pageSummaryClaim.summaryDeclarations.pics_" + this.transactionInfo.getRealCountry()?.toLowerCase()+"";
    }

    getDeclarationContent() {
        const baseDeclaration = "pageSummaryClaim.summaryDeclarations.declaration_";
        if (this.transactionInfo.isEmployeeCompensationProduct() || this.transactionInfo.isMotorProduct()) {
            return baseDeclaration + this.transactionInfo.getRealCountry()?.toLowerCase() + "_" +
                this.transactionInfo.getPolicyType();
        }
        return baseDeclaration + this.transactionInfo.getRealCountry()?.toLowerCase();
    }

    validateForm(): boolean {
       return super.validateForm(this.declarationTermsForm);
   }

    pushGATerms(tcName: string) {
        (<any>window).dataLayer.push({'event': tcName});
    }

}