import {Component, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'qnect-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  public visible = false;
  public visibleAnimate = false;

  public show(): void {
    this.visible = true;
    this.renderer.addClass(document.body, 'modal-open');
    setTimeout(() => this.visibleAnimate = true);
  }

  public hide(): void {
    this.visibleAnimate = false;
    this.renderer.removeClass(document.body, 'modal-open');
    setTimeout(() => this.visible = false, 300);
  }

  ngOnInit() {
  }

}
