<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
  <p>{{ 'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.title' | translate }}</p>
    <p *ngIf="this.claim.getStaffCode() == 'HK0022' || this.claim.getAgentCode() == '5001272'">{{ 'domesticHelperClaim.dmhClaimTypeDetails.clinicalExpenses.detailsOfClinicVisit.cashlessPaymentNote' | translate }}</p>
  </div>
  <BR>
    <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>    
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div *ngIf="this.clinicalExpenses.getNatureOfIllness() === 'Sickness'" class="form-group">
                <qnect-ui #DMH_CLINICAL_TYPE_ILLNESS_OR_INJURY_FOR_SICKNESS [fieldId]="'DMH_CLINICAL_TYPE_ILLNESS_OR_INJURY_FOR_SICKNESS'" [formGroup]="clinicalForm" name="typeOfIllnessOrInjury"></qnect-ui>
            </div>
            <div *ngIf="this.clinicalExpenses.getTypeOfIllnessOrInjury()?.toLowerCase() === 'others'" class="form-group">
                <qnect-ui #DMH_CLINICAL_OTHER_TYPE_ILLNESS_OR_INJURY [fieldId]="'DMH_CLINICAL_OTHER_TYPE_ILLNESS_OR_INJURY'" [formGroup]="clinicalForm" name="otherTypeOfIllnessOrInjury"></qnect-ui>
            </div>
            <div *ngIf="this.clinicalExpenses.getNatureOfIllness() === 'Injury'" class="form-group">
                <qnect-ui #DMH_CLINICAL_TYPE_ILLNESS_OR_INJURY_FOR_INJURY [fieldId]="'DMH_CLINICAL_TYPE_ILLNESS_OR_INJURY_FOR_INJURY'" [formGroup]="clinicalForm" name="typeOfIllnessOrInjury"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #DMH_CLINICAL_DATE_OF_VISIT [fieldId]="'DMH_CLINICAL_DATE_OF_VISIT'" [formGroup]="clinicalForm" name="dateOfVisit"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #DMH_CLINICAL_TYPE_OF_TREATMENT [fieldId]="'DMH_CLINICAL_TYPE_OF_TREATMENT'" [formGroup]="clinicalForm" name="typeOfClinicalExpenses"></qnect-ui>
            </div>
            <div class="form-group">
                <qnect-ui #DMH_CLINICAL_TOTAL_AMOUNT_OF_EXPENSES [fieldId]="'DMH_CLINICAL_TOTAL_AMOUNT_OF_EXPENSES'" [formGroup]="clinicalForm" name="totalAmountOfExpensesValue"></qnect-ui> 
            </div>
        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>
<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #info_modal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">{{this.clinicalExpenses.getTypeOfClinicalExpensesDesc()}}</h4>
    </div>
    <div class="modal-body modal_contents">
        <p>{{modalDescription | translate}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-modal" (click)="c('Close click')">
            {{ 'claimSection.generalLabels.generalButtonLabels.ok' | translate}}
        </button>
    </div>
</ng-template>
