import {ClinicalExpenseDetails} from './clinical-expense-details.model';

export class OutpatientConsultDetails {

    private hasOutpatientConsultations: boolean;
    private clinicalExpenses: ClinicalExpenseDetails[] = [];
    private fullyRecovered: boolean;
    private treatment: string;

    public getHasOutpatientConsultations(): boolean {
        return this.hasOutpatientConsultations;
    }

    public setHasOutpatientConsultations(hasOutpatientConsultations: boolean): void {
        this.hasOutpatientConsultations = hasOutpatientConsultations;
    }

    public getClinicalExpenses(): ClinicalExpenseDetails[] {
        return this.clinicalExpenses;
    }

    public setClinicalExpenses(clinicalExpenses: ClinicalExpenseDetails[]) {
        this.clinicalExpenses = clinicalExpenses;
    }

    public getFullyRecovered(): boolean {
        return this.fullyRecovered;
    }

    public setFullyRecovered(fullyRecovered: boolean): void {
        this.fullyRecovered = fullyRecovered;
    }

    public getTreatment(): string {
        return this.treatment;
    }

    public setTreatment(treatment: string): void {
        this.treatment = treatment;
    }

    static jsonConvert(outpatientConsultDetails: OutpatientConsultDetails): OutpatientConsultDetails {

        if (outpatientConsultDetails.getClinicalExpenses() != null) {
            let expenses: ClinicalExpenseDetails[] = [];
            for (const expense of outpatientConsultDetails.getClinicalExpenses()) {
                let item = Object.assign(new ClinicalExpenseDetails(), expense);
                item = ClinicalExpenseDetails.jsonConvert(item);
                expenses.push(item);
            }
            outpatientConsultDetails.setClinicalExpenses(expenses);
        }

        return outpatientConsultDetails;
    }

}