import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {StayInHospital} from "../../../../model/travel/stay-in-hospital.model";
import {takeUntil} from "rxjs";


@Component({
    selector: 'app-detail-of-oversea-hospital-stay',
    templateUrl: './detail-of-oversea-hospital-stay.component.html',
    styleUrls: ['./detail-of-oversea-hospital-stay.component.css']
})
export class DetailOfOverseaHospitalStayComponent extends ClaimsBaseComponent implements OnInit {

    deTailOfOverseaHospitalForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    stayInHospital: StayInHospital;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.stayInHospital = this.claim.getClaimTravel().getStayInHospital();
    }

    ngOnInit() {

        this.pushGAViewHospitalOversea();

        this.deTailOfOverseaHospitalForm = this.fb.group({
            wasStayHospitalOversea: [super.getBooleanString(this.stayInHospital.getWasStayHospitalOversea())],
            wasStayInIntensiveCare: [super.getBooleanString(this.stayInHospital.getWasStayInIntensiveCare())],
            wardType: [this.stayInHospital.getWardType()],
            periodType: [this.stayInHospital.getPeriodType()],
            periodTime: [this.stayInHospital.getPeriodTime()]
        });

    }

    ngAfterViewInit() {

        this.deTailOfOverseaHospitalForm.valueChanges.subscribe(data => {

            this.stayInHospital.setWasStayHospitalOversea(this.deTailOfOverseaHospitalForm.get('wasStayHospitalOversea').value == 'true');

            //check for other required fields based on hospital oversea value
            if (this.deTailOfOverseaHospitalForm.get('wasStayHospitalOversea').value == 'true') {
                if (this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value !== null && this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value !== undefined ) {
                   this.stayInHospital.setWasStayInIntensiveCare(this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value == 'true' ? true : false);
                }
            } else {
                this.stayInHospital.setWasStayInIntensiveCare(this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value);
            }
            this.stayInHospital.setPeriodType(super.getValueInComponent('periodType'));
            this.stayInHospital.setPeriodTypeDesc(super.getTextInComponent('periodType'));
            this.stayInHospital.setPeriodTime(super.getValueInComponent('periodTime'));
            this.stayInHospital.setWardType(super.getValueInComponent('wardType'));
            this.stayInHospital.setWardTypeDesc(super.getTextInComponent('wardType'));

            this.validateClaimDetailsComplete();
        });
    }

    uiComponentCreated(fieldId: string) {

        if (fieldId === 'HOS04') {
            this.deTailOfOverseaHospitalForm.get('wasStayHospitalOversea').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                if (data === 'true') {
                    this.deTailOfOverseaHospitalForm.get('periodType').enable({onlySelf: false, emitEvent: false});
                    this.deTailOfOverseaHospitalForm.get('periodTime').enable({onlySelf: false, emitEvent: false});
                    this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').enable({onlySelf: false, emitEvent: false});
                } else {
                    this.deTailOfOverseaHospitalForm.get('periodType').reset();
                    this.deTailOfOverseaHospitalForm.get('periodTime').reset();
                    this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').reset();
                    this.deTailOfOverseaHospitalForm.get('periodType').disable({onlySelf: false, emitEvent: false});
                    this.deTailOfOverseaHospitalForm.get('periodTime').disable({onlySelf: false, emitEvent: false});
                    this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').disable({onlySelf: false, emitEvent: false});
                }
            });
        }

        if (fieldId === 'HOS05') {
            this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                if (data === 'false' || data === false) {
                    this.deTailOfOverseaHospitalForm.get('wardType').enable({onlySelf: false, emitEvent: false});
                } else {
                    this.deTailOfOverseaHospitalForm.get('wardType').reset();
                    this.deTailOfOverseaHospitalForm.get('wardType').disable({onlySelf: false, emitEvent: false});
                }
            });
        }
        console.log("uiComponentCreated:  deTailOfOverseaHospitalForm.wasStayInIntensiveCare:: " +  this.deTailOfOverseaHospitalForm.get('wasStayInIntensiveCare').value);

    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 1});

            this.router.navigate(["/claimform/stayInHospital/followUpTreatment"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/stayInHospital/detailsOfIllness"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.deTailOfOverseaHospitalForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.deTailOfOverseaHospitalForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 5, subMenuIndex: 1});
        }
    }

    //Google Analytics
    pushGAViewHospitalOversea() {
        (<any>window).dataLayer.push({
            'pageStep': 'Stay Hospital – Oversea Hospital Stay',
            'vPath': '/claim/stay-hospital/oversea-hospital',
            'event': 'vpageview',
        });
    }

}
