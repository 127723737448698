import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {RentalVehicle} from "../../../../model/travel/rental-vehicle.model";
import { ClaimTypes } from '../../../../model/claim-type.model';
import { DocumentField } from '../../../../model/document-field';
import { DocumentHelperService } from "../../../../services/document-helper.service";

@Component({
  selector: 'app-summary-rental-vehicle',
  templateUrl: './summary-rental-vehicle.component.html',
  styleUrls: ['./summary-rental-vehicle.component.css']
})
export class SummaryRentalVehicleComponent  extends ClaimsBaseComponent implements OnInit {

  claim: Claim;
  rentalVehicle: RentalVehicle;
  listOfDocuments: DocumentField[] = [];
  listOfOtherDocuments: DocumentField[] = [];

  constructor(private claimService: ClaimService,
              private documentHelperService: DocumentHelperService,
              private injector : Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.rentalVehicle = this.claim.getClaimTravel().getRentalVehicle();
      this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
      this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]).getRequiredDocuments(),
      ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]);
      this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
      this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]).getRequiredDocuments(),
      ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]);
  }

  ngOnInit() {

  }

  returnYesOrNo(value: boolean) {
    if (true === value) {
        return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
    } else {
        if (false === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
        }
    }

    return '';
}

  getDate(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
      }
      else {
          return "";
      }
  }

}

