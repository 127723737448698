import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-other-claim-support-doc',
    templateUrl: './other-claim-support-doc.component.html',
    styleUrls: ['./other-claim-support-doc.component.css']
})
export class OtherClaimSupportDocComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
    
}
