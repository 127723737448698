import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import {TransactionInfoService} from '../../../services/transaction-info.service';
import { EmployeeEarnings } from 'src/app/model/employee-compensation/employee-earnings.model';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import {TransactionInfo} from '../../../model/transaction-info.model';
import {ClaimTypes} from '../../../model/claim-type.model';

@Component({
    selector: 'app-employee-earnings',
    templateUrl: './employee-earnings.component.html',
    styleUrls: ['./employee-earnings.component.css']
})
export class EmployeeEarningsComponent extends ClaimsBaseComponent implements OnInit {

    employeeEarningsForm: FormGroup;
    employeeEarningsAmountForm: FormGroup;
    showTotalBox = false;
    employeeEarnings: EmployeeEarnings;
    currency: string;
    compensationDate: Date;
    listOfMonthlyEarnings = [];
    currentMonth: string;
    datedFrom: string;
    datedTo: string;
    listMonthlyEarningsAmounts = [];
    modalRef: any;
    transactionInfo: TransactionInfo;

    constructor(private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService,
                private modalService: NgbModal) {
        super(injector);
        this.employeeEarnings = this.claim.getClaimEmployeeCompensation().getEmployeeEarnings();
        this.currency = this.claim.getClaimEmployeeCompensation().getCurrency();
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.compensationDate = this.claim.getClaimEmployeeCompensation().getAccidentDetails().getAccidentDate() ?
            this.claim.getClaimEmployeeCompensation().getAccidentDetails().getAccidentDate() :
            this.claim.getClaimEmployeeCompensation().getOccupationalDisease()?.getResultDate();
    }

    ngOnInit() {
        this.employeeEarningsForm = this.fb.group({
            workingDays: [this.employeeEarnings.getWorkingDays()],
            specify: [this.employeeEarnings.getSpecify()],
            paidOrUnpaid: [super.getBooleanString(this.employeeEarnings.getIsPaid())],
            fixedOrFlexible: [super.getBooleanString(this.employeeEarnings.getIsFixed())],
            dayOfWeek: [this.employeeEarnings.getDayOfWeek()],
            wages: [this.employeeEarnings.getWages()],
            foodAllowance: [this.employeeEarnings.getFoodAllowance()],
            otherItem: [this.employeeEarnings.getOtherItem()],
            otherAmount: [this.employeeEarnings.getOtherAmount()],
            totalAmount: [this.employeeEarnings.getTotalAmount()],
            averageEarnings: [this.employeeEarnings.getAverageEarnings()]
        });
    }

    ngAfterViewInit() {
       let totalAmountFormControl = this.employeeEarningsForm.get('totalAmount') as FormControl;

        this.employeeEarningsForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                this.employeeEarnings.setWorkingDays(this.employeeEarningsForm.get('workingDays').value);
                this.employeeEarnings.setWorkingDaysDesc(super.getTextInComponent('workingDays'));
                this.employeeEarnings.setSpecify(this.employeeEarningsForm.get('specify').value);
                if (this.isNotNullOrUndefined(this.employeeEarningsForm.get('paidOrUnpaid').value)) {
                    this.employeeEarnings.setIsPaid(this.employeeEarningsForm.get('paidOrUnpaid').value == 'true');
                    this.employeeEarnings.setPaidOrUnpaidDesc(super.getSelectedRadioText('paidOrUnpaid'));
                }
                if (this.isNotNullOrUndefined(this.employeeEarningsForm.get('fixedOrFlexible').value)) {
                    this.employeeEarnings.setIsFixed(this.employeeEarningsForm.get('fixedOrFlexible').value == 'true');
                    this.employeeEarnings.setFixedOrFlexibleDesc(super.getSelectedRadioText('fixedOrFlexible'));
                }
                this.employeeEarnings.setDayOfWeek(super.getMultiSelectDropdownValue('dayOfWeek'));
                this.employeeEarnings.setWages(this.employeeEarningsForm.get('wages').value);
                this.employeeEarnings.setFoodAllowance(this.employeeEarningsForm.get('foodAllowance').value);
                this.employeeEarnings.setOtherItem(this.employeeEarningsForm.get('otherItem').value);
                this.employeeEarnings.setOtherAmount(this.employeeEarningsForm.get('otherAmount').value);
                this.employeeEarnings.setTotalAmount(this.employeeEarningsForm.get('totalAmount').value);
                this.employeeEarnings.setAverageEarnings(this.employeeEarningsForm.get('averageEarnings').value);
            });

        this.employeeEarningsForm.get('workingDays').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.validateSpecifyField(data);
            });

        this.employeeEarningsForm.get('fixedOrFlexible').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(data => {
                this.validateDayOfWeekField(data);
            });

        this.employeeEarningsForm.get('wages').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                totalAmountFormControl.setValue(this.calculateTotalAmount());
            });

        this.employeeEarningsForm.get('foodAllowance').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                totalAmountFormControl.setValue(this.calculateTotalAmount());
            });

        this.employeeEarningsForm.get('otherAmount').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data) => {
                totalAmountFormControl.setValue(this.calculateTotalAmount());
            });

    }

    validateSpecifyField(data) {
        if (data && data?.toLowerCase() == "others") {
            this.employeeEarningsForm.get('specify').enable({onlySelf: false, emitEvent: false});
        } else {
            this.employeeEarningsForm.get('specify').reset();
            this.employeeEarningsForm.get('specify').disable({onlySelf: false, emitEvent: false});
        }
    }

    validateDayOfWeekField(data) {
        if (data == "true") {
            this.employeeEarningsForm.get('dayOfWeek').enable({onlySelf: false, emitEvent: false});
        } else {
            this.employeeEarningsForm.get('dayOfWeek').reset();
            this.employeeEarningsForm.get('dayOfWeek').disable({onlySelf: false, emitEvent: false});
        }
    }

    calculateTotalAmount() {
        let total = 0;
        let wagesAmt = this.employeeEarningsForm.get('wages').value;
        let fdAllowanceAmt = this.employeeEarningsForm.get('foodAllowance').value;
        let othersAmt= this.employeeEarningsForm.get('otherAmount').value;

        if (this.isNotNullOrUndefinedNum(wagesAmt)) {
            total += parseFloat(wagesAmt.toString());
        }
        if (this.isNotNullOrUndefinedNum(fdAllowanceAmt)) {
            total += parseFloat(fdAllowanceAmt.toString());
        }
        if (this.isNotNullOrUndefinedNum(othersAmt)) {
            total += parseFloat(othersAmt.toString());
        }
        return total.toFixed(2);
    }

    goToNext() {
        if (this.validateForm()) {
            let route = this.transactionInfo.getRouteByCurrentUrl('/employeeEarnings', true);
            if (route) {
                this.sideMenuService.emitClaimComplete({claimTypeId: route.claimIndex, subMenuIndex: route.routeIndex});
                this.sideMenuService.emitProcess(1, 0);
                this.router.navigate([route.routeUrl], {
                    relativeTo: this.activatedRoute
                })
            }
        }

    }

    back() {
        let route = this.transactionInfo.getRouteByCurrentUrl('/employeeEarnings', false);
        this.router.navigate([route.routeUrl], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.employeeEarningsForm);
    }

    showModal() {
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }

    open(content, name) {
        if (name == 'calculate_monthly_earnings') {
            let formGroupArray : FormGroup[] = [];
            this.employeeEarningsAmountForm = this.fb.group({
                items: this.fb.array(formGroupArray),
                aveMonthlyEarnings: ['0.00']
            });
            this.listOfMonthlyEarnings = this.getListOfMonthlyEarnings(this.compensationDate);
        }
        this.modalRef =  this.modalService.open(content, {size: 'lg', backdrop: 'static', keyboard: false});
    }

    getListOfMonthlyEarnings(dt: Date){
        //set label values for pop-up computation link
        this.setPopUplabel(dt);
        let startDate = moment(dt);
        let listOfMonthlyEarnings = [];
         for(let i = 0; i < 12; i++) {
            let monthValue = startDate.subtract(1, 'M').format('YYYY/MM');
            listOfMonthlyEarnings.push(monthValue);
            this.addItem(monthValue);
         }

        return listOfMonthlyEarnings;
    }

    getDate(dt: Date){
        let dateString = dt.toString();
        let dateArray = dateString.split("/");
        let newDate = dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2];
        const formattedDate = new Date(newDate);
        return new Date(formattedDate);
    }

    setPopUplabel(dt : Date){
        let startDate = moment(dt);
        let startDateLabel = moment(dt);
        let startMonth;
        let endMonth;
         for(let i = 0; i < 12; i++) {
            if(i == 0){
                this.currentMonth = startDate.format('YYYY/MM');
                startMonth = startDateLabel.subtract(1, 'M').format('YYYY/MM');
            }else if(i == 11){
                 endMonth = startDateLabel.subtract(11, 'M').format('YYYY/MM');
            }
         }
        this.datedFrom = endMonth;
        this.datedTo = startMonth;
    }

    addItem(monthValue: string): void {
        let self = this;
        setTimeout(function () {
            let items = self.employeeEarningsAmountForm.get('items') as FormArray;
            let newItem : FormGroup = self.createDefaultItems(monthValue);
            items.push(newItem);

        }, 10);
    }

    createDefaultItems(monthValue: string): FormGroup {
        return this.fb.group({
            month: monthValue,
            monthlyEarnings: '0.00',
        });
    }

    calculate(){
        let amount = 0;
        let count = 0;
        let formArray : FormArray =  <FormArray> this.employeeEarningsAmountForm.controls["items"];

        for (let i = 0; i < formArray.controls.length; i++) {
            let formValue =  formArray.controls[i].get('monthlyEarnings').value;
            if (this.isNotNullOrUndefinedNum(formValue) && formValue != 0.00) {
                amount += parseFloat(formValue.toString());
                count++;
            }
        }

        if (amount > 0 && count > 0) {
            amount = amount / count;
        }

        let aveMonthlyEarningsFormControl = this.employeeEarningsAmountForm.get('aveMonthlyEarnings') as FormControl;
        aveMonthlyEarningsFormControl.setValue(amount.toFixed(2));
    }

    confirm() {
        let aveEarningsFormControl = this.employeeEarningsForm.get('averageEarnings') as FormControl;
        if (this.isNotNullOrUndefinedNum(this.employeeEarningsAmountForm.get('aveMonthlyEarnings').value)) {
            aveEarningsFormControl.setValue(this.employeeEarningsAmountForm.get('aveMonthlyEarnings').value);
        }
        this.modalRef.close();
    }

    getClaimTypeCaption() : string {
        if (this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A]) {
            return 'Form2a';
        } else if (this.transactionInfo.getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]) {
            return 'Form2';
        }
    }

}