import { Component, OnInit, NgZone, ViewChild, ElementRef, Injector } from '@angular/core';
import { UiBaseComponent } from '../ui-base.component';
import { MapsAPILoader } from '@agm/core';
import { FormControl } from '@angular/forms';
import { ErrorsService } from 'src/app/utilities/errors/errors.service';
import { Claim } from 'src/app/model/claim.model';
import { ClaimService } from 'src/app/services/claim.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare let google: any;
@Component({
  selector: 'app-ui-map',
  templateUrl: './ui-map.component.html',
  styleUrls: ['./ui-map.component.css']
})
export class UiMapComponent  extends UiBaseComponent implements OnInit {
    lat:any;
    lng:any;
    address:any;
    zoom: any;
    searchControl: FormControl;
    mapError = false;
    mapLoaded = false;
    claim: Claim;
    mapInterval: any;
    locationPermissionGranted: boolean = true;
    claimProduct: any;

    @ViewChild("mapLocation", {static: false})
    public searchElementRef: ElementRef;

    @ViewChild("gMap", {static: false})
    public mapElementRef: ElementRef;

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private injector: Injector,
    private claimService: ClaimService,
    private transactionInfoService: TransactionInfoService
    ) {
    super();
    this.claim = claimService.getClaim();

    if (this.transactionInfoService.getTransactionInfo().isHomeProduct()) {
      this.claimProduct = this.claim.getClaimHome();
    } else if (this.transactionInfoService.getTransactionInfo().isAccidentProduct()) {
      this.claimProduct = this.claim.getClaimPersonalAccident();
    } else if (this.transactionInfoService.getTransactionInfo().isEmployeeCompensationProduct()) {
      this.claimProduct = this.claim.getClaimEmployeeCompensation();
    } else { 
      this.claimProduct = this.claim.getClaimMotor();
    }
  }

  ngAfterViewInit() {

   if(!this.claimProduct.getMapReachable()){
      this.mapError = true;
    } else { 

    if(!this.claimProduct.getLocationPermissionGranted()){
      this.locationPermissionGranted = false;
    }

    this.mapInterval = setInterval(() => {
        if(!UtilitiesService.isNullOrUndefined(this.searchElementRef) && this.searchElementRef.nativeElement.disabled){
          this.mapError = true;
          this.claimProduct.setMapReachable(false);
          clearInterval(this.mapInterval);
          console.log("ERROR IN MAP API");
        }
    }, 200);
     
  
      this.searchControl = new FormControl();
      let self = this;
      this.mapsAPILoader.load().then(() => {
          let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          });   
          autocomplete.addListener("place_changed", () => {
              this.ngZone.run(() => {
                  //get the place result
                  let place: google.maps.places.PlaceResult = autocomplete.getPlace();
                  //verify result
                  if (place.geometry === undefined || place.geometry === null) {
                      return;
                  }
                  //set latitude, longitude and zoom
                  this.lat = place.geometry.location.lat();
                  this.lng = place.geometry.location.lng();
                  this.zoom = 15;
                  this.formControl.setValue(place.formatted_address);
              });
          });
          this.initializeWorldMap();
          if(!UtilitiesService.isNullOrUndefined(this.formControl) && !UtilitiesService.isNullOrUndefined(this.formControl.value)){
            this.getGeoLocation(this.formControl.value);
          }    
      }).catch((err) => { 
        console.log("Error loading map", err);
        this.handleError(err);
        this.mapError = true;
      });
    }
  }

  zoomOut(){
      this.zoom = 1;
  }

  initializeWorldMap(){
      if (this.claim.getCountry()?.toUpperCase() === 'SGP') {
          this.lat = 1.3521;
          this.lng = 103.8198;
      } else {
          this.lat = 22.3193;
          this.lng = 114.1694;
      }
      this.zoom = 9;
  }

  setCurrentUserLocation(){
      this.formControl.setValue("");
        let self = this;
         if (navigator && navigator.geolocation)
         {
         navigator.geolocation.getCurrentPosition( pos => {
            self.lng = +pos.coords.longitude;
            self.lat = +pos.coords.latitude;
            self.mapReady();
            self.zoom = 15;
         },
         error => {
          console.log("PERMISSION DENIED " + error);
          self.locationPermissionGranted = false;
          self.claimProduct.setLocationPermissionGranted(false);
         });
         }
     
  }
  
  mapReady() {
      this.getAddress(this.lat, this.lng);
  }

  getGeoLocation(address: string){
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let self = this;
          geocoder.geocode({
              'address': address
          }, (results, status) => {
              if (status == google.maps.GeocoderStatus.OK) {
                let place = results[0];
                  //verify result
                  if (place.geometry === undefined || place.geometry === null) {
                      return;
                  }
                  //set latitude, longitude and zoom
                  self.lat = place.geometry.location.lat();
                  self.lng = place.geometry.location.lng();
                  self.zoom = 15;
              } else {
                  console.log('Error: ', results, ' & Status: ', status);
              }
          });
    }
  }

  placeMarker($event){
      this.lng = $event.coords.lng;
      this.lat = $event.coords.lat;
      this.mapReady();
  }

  getAddress( lat: number, lng: number ) {
      if (navigator.geolocation) {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(lat, lng);
        let request = { latLng: latlng };
        let self = this;
        geocoder.geocode(request, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            let result = results[0];
            if (result != null && result.formatted_address != null && result.formatted_address.length > 0) {
              self.address = result.formatted_address;
              self.zoom = 15;
              self.formControl.setValue(self.address);
            } else {
              alert('No address available!');
            }
          }
        });
    }
  }

  ngOnDestroy() {
    clearInterval(this.mapInterval)
  }
  
  private handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    const errorsService = this.injector.get(ErrorsService); 
    this.mapError = true;
    let errMsg: string;
    if (error instanceof Response) {
        errMsg = `${error.status} - ${error.statusText || ''}`;
    } else {
        errMsg = error.message ? error.message : error.toString();
    }
    errMsg = 'Google Maps handleError' + errMsg;
    errorsService.log(errMsg);
}

}