<div class="form-group section">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionDamagePersonalItem.subDmgItemDetail.title' | translate}}</label>
            <ng-container *ngFor="let item of this.damagePersonalItem.getDetailsOfDamageItemsArray(); let i = index;">
                <ng-container >
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.item' | translate}} {{i + 1}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content" *ngIf="item.getLossType() != 'Others'">
                            {{item.getLossTypeDesc()}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="item.getLossType() == 'Others'">
                          {{item.getLossTypeDetail()}}
                       </div>
                    </div>
                    <div class="row" *ngIf="item.getLossType() == 'Others'">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.briefDescriptionOfItem' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getOtherTypeBriefDesc()}}
                        </div>
                    </div>
                    <div class="row" *ngIf="item.getLossType() == 'Mobile Phone'">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.descOfIncident' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5-word-break content">
                            {{item.getLossTypeDetail()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.purchaseDate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{getDate(item.getPurchaseDate())}}
                        </div>
                    </div>
                    <div class="row" *ngIf="item.getPurchasePrice()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.purchasePrice' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getPurchasePriceType()}} {{item.getPurchasePrice() | number : '1.2-2'}}
                        </div>
                    </div>

                    <div class="row" *ngIf="item.getRepairCost()">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.repairCost' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getRepairCostType()}} {{item.getRepairCost() | number : '1.2-2'}}
                        </div>
                    </div>

                    <div class="row" *ngIf="item.getDepreciationRate() != null && item.getDepreciationRate() != ''">
                        <div class="col col-xs-12 col-lg-7">
                            {{'claimSection.damagePersonalItem.detailsOfDamageItems.depreciationRate' | translate}}
                        </div>
                        <div class="col col-xs-12 col-lg-5 content">
                            {{item.getDepreciationRate()}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
