export class VisitedDoctor {

    private dateTheIllnessOccurred: Date;
    private natureOfIllness: string;
    private natureOfIllnessDesc: string;
    private diagnoseType: string;
    private diagnoseTypeDesc: string;
    private otherDiagnose: string;
    private wasMugging: boolean;
    private hasReceiveFollowUpTreatment: boolean;
    private returnHkDate: Date;
    private lastTreatmentDate: Date;
    private numberOfTreatmentBill: string;
    private otherNumOfTreatmentBill: string;
    private medicalExpenseTypeAmount: string;
    private medicalExpenseType: string;
    private wasVisitedpractitioner: boolean;
    private typeOfMedicalPractitioner: string;
    private typeOfMedicalPractitionerDesc: string;
    private otherTypeOfMedicalPractitioner: string;
    private countryOfTheLossOccurred: string;
    private countryOfTheLossOccurredDesc: string;
    private estimatedAmount: number;
    private isFirstVisitForThisCondition: boolean;
    private dentalTreatment: string;
    private dentalTreatmentDesc: string;
    private isTreatmentCompleted: boolean;
    private hasSufferedIllness: boolean;
    private wasCausedByAccidentalInjury: boolean;
    private dateOfFirstVisit: Date;
    private placeOfLoss: string;
    private placeOfLossDesc: string;

    getWasCausedByAccidentalInjury(): boolean {
        return this.wasCausedByAccidentalInjury;
    }

    setWasCausedByAccidentalInjury(value: boolean) {
        this.wasCausedByAccidentalInjury = value;
    }

    getHasSufferedIllness(): boolean {
        return this.hasSufferedIllness;
    }

    setHasSufferedIllness(value: boolean) {
        this.hasSufferedIllness = value;
    }

    getIsTreatmentCompleted(): boolean {
        return this.isTreatmentCompleted;
    }

    setIsTreatmentCompleted(value: boolean) {
        this.isTreatmentCompleted = value;
    }

    getDentalTreatmentDesc(): string {
        return this.dentalTreatmentDesc;
    }

    setDentalTreatmentDesc(value: string) {
        this.dentalTreatmentDesc = value;
    }

    getDentalTreatment(): string {
        return this.dentalTreatment;
    }

    setDentalTreatment(value: string) {
        this.dentalTreatment = value;
    }

    getIsFirstVisitForThisCondition(): boolean {
        return this.isFirstVisitForThisCondition;
    }

    setIsFirstVisitForThisCondition(value: boolean) {
        this.isFirstVisitForThisCondition = value;
    }

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getCountryOfTheLossOccurredDesc(): string {
        return this.countryOfTheLossOccurredDesc;
    }

    setCountryOfTheLossOccurredDesc(value: string) {
        this.countryOfTheLossOccurredDesc = value;
    }

    getCountryOfTheLossOccurred(): string {
        return this.countryOfTheLossOccurred;
    }

    setCountryOfTheLossOccurred(value: string) {
        this.countryOfTheLossOccurred = value;
    }
    
    getDateTheIllnessOccurred(): Date {
        return this.dateTheIllnessOccurred;
    }

    setDateTheIllnessOccurred(value: Date) {
        this.dateTheIllnessOccurred = value;
    }

    getNatureOfIllness(): string {
        return this.natureOfIllness;
    }

    setNatureOfIllness(value: string) {
        this.natureOfIllness = value;
    }

    getNatureOfIllnessDesc(): string {
        return this.natureOfIllnessDesc;
    }

    setNatureOfIllnessDesc(value: string) {
        this.natureOfIllnessDesc = value;
    }

    getDiagnoseType(): string {
        return this.diagnoseType;
    }

    setDiagnoseType(value: string) {
        this.diagnoseType = value;
    }

    getDiagnoseTypeDesc(): string {
        return this.diagnoseTypeDesc;
    }

    setDiagnoseTypeDesc(value: string) {
        this.diagnoseTypeDesc = value;
    }

    getOtherDiagnose(): string {
        return this.otherDiagnose;
    }

    setOtherDiagnose(value: string) {
        this.otherDiagnose = value;
    }

    getWasMugging(): boolean {
        return this.wasMugging;
    }

    setWasMugging(value: boolean) {
        this.wasMugging = value;
    }

    getHasReceiveFollowUpTreatment(): boolean {
        return this.hasReceiveFollowUpTreatment;
    }

    setHasReceiveFollowUpTreatment(value: boolean) {
        this.hasReceiveFollowUpTreatment = value;
    }

    getReturnHkDate(): Date {
        return this.returnHkDate;
    }

    setReturnHkDate(value: Date) {
        this.returnHkDate = value;
    }

    getLastTreatmentDate(): Date {
        return this.lastTreatmentDate;
    }

    setLastTreatmentDate(value: Date) {
        this.lastTreatmentDate = value;
    }

    getNumberOfTreatmentBill(): string {
        return this.numberOfTreatmentBill;
    }

    setNumberOfTreatmentBill(value: string) {
        this.numberOfTreatmentBill = value;
    }

    getOtherNumOfTreatmentBill(): string {
        return this.otherNumOfTreatmentBill;
    }

    setOtherNumOfTreatmentBill(value: string) {
        this.otherNumOfTreatmentBill = value;
    }

    getMedicalExpenseTypeAmount(): string {
        return this.medicalExpenseTypeAmount;
    }

    setMedicalExpenseTypeAmount(value: string) {
        this.medicalExpenseTypeAmount = value;
    }

    setMedicalExpenseType(value: string) {
        this.medicalExpenseType = value;
    }

    getMedicalExpenseType(): string {
        return this.medicalExpenseType;
    }

    getWasVisitedpractitioner(): boolean {
        return this.wasVisitedpractitioner;
    }

    setWasVisitedpractitioner(value: boolean) {
        this.wasVisitedpractitioner = value;
    }

    getTypeOfMedicalPractitioner(): string {
        return this.typeOfMedicalPractitioner;
    }

    setTypeOfMedicalPractitioner(value: string) {
        this.typeOfMedicalPractitioner = value;
    }

    getTypeOfMedicalPractitionerDesc(): string {
        return this.typeOfMedicalPractitionerDesc;
    }

    setTypeOfMedicalPractitionerDesc(value: string) {
        this.typeOfMedicalPractitionerDesc = value;
    }

    getOtherTypeOfMedicalPractitioner(): string {
        return this.otherTypeOfMedicalPractitioner;
    }

    setOtherTypeOfMedicalPractitioner(value: string) {
        this.otherTypeOfMedicalPractitioner = value;
    }

    getDateOfFirstVisit(): Date {
        return this.dateOfFirstVisit;
    }

    setDateOfFirstVisit(value: Date) {
        this.dateOfFirstVisit = value;
    }

    getPlaceOfLoss(): string {
        return this.placeOfLoss;
    }

    setPlaceOfLoss(value: string){
        this.placeOfLoss = value;
    }

    getPlaceOfLossDesc(): string {
        return this.placeOfLossDesc;
    }

    setPlaceOfLossDesc(value: string){
        this.placeOfLossDesc = value;
    }

    static jsonConvert(visitedDoctor: VisitedDoctor): VisitedDoctor {

        if (visitedDoctor.getReturnHkDate() != null) {
            visitedDoctor.setReturnHkDate(new Date(visitedDoctor.getReturnHkDate()));
        }

        if (visitedDoctor.getLastTreatmentDate() != null) {
            visitedDoctor.setLastTreatmentDate(new Date(visitedDoctor.getLastTreatmentDate()));
        }

        if (visitedDoctor.getDateTheIllnessOccurred() != null) {
            visitedDoctor.setDateTheIllnessOccurred(new Date(visitedDoctor.getDateTheIllnessOccurred()));
        }

        if (visitedDoctor.getDateOfFirstVisit() != null) {
            visitedDoctor.setDateOfFirstVisit(new Date(visitedDoctor.getDateOfFirstVisit()))
        }

        return visitedDoctor;
    }

}