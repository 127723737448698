import {Component, Injector, OnInit} from "@angular/core";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {StayInHospital} from "../../../../model/travel/stay-in-hospital.model";
import { ClaimTypes } from "../../../../model/claim-type.model";
import { DocumentField } from "../../../../model/document-field";
import { DocumentHelperService } from "../../../../services/document-helper.service";
@Component({
    selector: 'app-summary-stay-in-hospital',
    templateUrl: './summary-stay-in-hospital.component.html',
    styleUrls: ['./summary-stay-in-hospital.component.css']
})
export class SummaryStayInHospitalComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    stayInHospital: StayInHospital;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];
    country: string;

    constructor(private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.stayInHospital = this.claim.getClaimTravel().getStayInHospital();
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
        this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]).getRequiredDocuments(),
        ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
        this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]).getRequiredDocuments(),
        ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]);
        this.country = this.claim.getCountry().toLowerCase();
    }

    ngOnInit() {
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}
