import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rerouting-support-doc',
  templateUrl: './rerouting-support-doc.component.html',
  styleUrls: ['./rerouting-support-doc.component.css']
})
export class ReroutingSupportDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
