import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class CollisionFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        let authFormDocField = new DocumentField('COLLISION_LETTEROFAUTHORIZATION', 'motorClaim.claimSection.documents.letterOfAuthorizationSignedCollision', SmartqDocuments.MTR_AUTHLETTER, true);
        authFormDocField.setFormUrl('motorClaim.claimSection.documents.loaFileUrl');
        authFormDocField.setFormDescription('motorClaim.claimSection.documents.letterOfAuthorizationSignedCollision');
        authFormDocField.setDescriptionHtml('motorClaim.claimSection.documents.letterOfAuthorizationSignedCollisionHtml');
        authFormDocField.setIsOriginal(true);
        requiredDocuments.push(authFormDocField);
        requiredDocuments.push(new DocumentField('COLLISION_HKID', 'motorClaim.claimSection.documents.hkid', SmartqDocuments.MTR_HKID, true));
        requiredDocuments.push(new DocumentField('COLLISION_DRIVERSLICENSE', 'motorClaim.claimSection.documents.driversLicense', SmartqDocuments.MTR_DRIVINGLICENSE, true));
        requiredDocuments.push(new DocumentField('COLLISION_VEHICLEREGISTRATION', 'motorClaim.claimSection.documents.vehicleRegistration', SmartqDocuments.MTR_REGDOC, true));
        requiredDocuments.push(new DocumentField('COLLISION_VEHICLELICENSE', 'motorClaim.claimSection.documents.vehicleLicense', SmartqDocuments.MTR_REGDOC, true));
        requiredDocuments.push(new DocumentField('COLLISION_POLICESTATEMENT', 'motorClaim.claimSection.documents.policeStatement', SmartqDocuments.MTR_POLICEREPORT, true));
        requiredDocuments.push(new DocumentField('COLLISION_BREATHTESTRESULT', 'motorClaim.claimSection.documents.breathTestResult', SmartqDocuments.MTR_OTHERS, true));
        requiredDocuments.push(new DocumentField('COLLISION_PHOTOOFACCIDENT', 'motorClaim.claimSection.documents.photoOfAccident', SmartqDocuments.MTR_POLICEREPORT, true));
        requiredDocuments.push(new DocumentField('COLLISION_CARVIDEO', 'motorClaim.claimSection.documents.carVideo', SmartqDocuments.MTR_DVD, true));
        requiredDocuments.push(new DocumentField('COLLISION_REPAIRQUOTATION', 'motorClaim.claimSection.documents.repairQuotation', SmartqDocuments.MTR_QUOTATION, true));
        requiredDocuments.push(new DocumentField('COLLISION_THIRDPARTY', 'motorClaim.claimSection.documents.thirdParty', SmartqDocuments.MTR_CORRESPONDENCE, true));

        if(this.claim.getClaimMotor().getCollission().getHasPaidOrReceived()){
            requiredDocuments.push(new DocumentField('COLLISION_RELATEDThirdPARTY', 'motorClaim.claimSection.documents.thirdPartyRelated', SmartqDocuments.MTR_OTHERS, true));
        }
        
      
        this.requiredDocuments = requiredDocuments;
    }
}
