<main>
    <div class="wrapper">

        <div *ngIf="showFields" style="padding-bottom: 60px">
            <div class="outerTitle" *ngIf="isRegistration">{{ 'registration.title' | translate }}</div>
            <div class="outerTitle" *ngIf="!isRegistration">{{ 'forgotPassword.title' | translate }}</div>
            <div class="main">
                <div class="requirementsDiv">
                    <div class="firstLine" *ngIf="isRegistration">{{ 'registration.setPassword' | translate }}</div>
                    <div class="firstLine" *ngIf="!isRegistration">{{ 'forgotPassword.last8PassUsed' | translate }}</div>
                    <ul>
                        <li [ngClass]="{'reqPassed' : cond7}" class="reqFailed">{{ 'registration.requirements.notContainUsername' | translate }}</li>
                        <li [ngClass]="{'reqPassed' : cond1}" class="reqFailed">{{ 'registration.requirements.atLeast14Char' | translate }}</li>
                        <li [ngClass]="{'reqPassed' : cond2}" class="reqFailed">{{ 'registration.requirements.haveAtleast3CharTypes' | translate }}</li>
                        <ul>
                            <li [ngClass]="{'reqPassed' : cond3}" class="reqFailed">{{ 'registration.requirements.aLowerCase' | translate }}</li>
                            <li [ngClass]="{'reqPassed' : cond4}" class="reqFailed">{{ 'registration.requirements.anUpperCase' | translate }}</li>
                            <li [ngClass]="{'reqPassed' : cond5}" class="reqFailed">{{ 'registration.requirements.aNumeric' | translate }}</li>
                            <li [ngClass]="{'reqPassed' : cond6}" class="reqFailed">{{ 'registration.requirements.aSpecial' | translate }}</li>
                        </ul>
                    </ul>
                    <div class="passwordDiv">
                        <div class="form-group">
                            <qnect-ui #MOTOR_REPAIRER_PASSWORD [fieldId]="'MOTOR_REPAIRER_PASSWORD'" [formGroup]="passwordForm" name="password"></qnect-ui>
                        </div>
                        <div class="form-group">
                            <qnect-ui #MOTOR_REPAIRER_VERIFY_PASSWORD [fieldId]="'MOTOR_REPAIRER_VERIFY_PASSWORD'" [formGroup]="passwordForm" name="verifyPassword"></qnect-ui>
                        </div>
                    </div>
                    <div *ngIf="errorMessage" >
                        <small id="error-message">{{errorMessage | translate}}</small>
                    </div>
                    <div *ngIf="isTermsRequired" class="termsOfUseDiv">
                        <div class="checkboxWrapper">
                            <input type="checkbox" id="termsOfUseCheckbox" class="form-check-input" (change)="onCheckTerms($event)">
                            <label class="checkboxContent" for="termsOfUseCheckbox">
                                {{'registration.terms.label' | translate}}
                                <a href="{{getTermsOfUseUrl()}}" rel="noopener" target='_blank'>
                                    {{'registration.terms.termsOfUse' | translate}}
                                </a>
                                {{'registration.terms.and' | translate}}
                                <a href="{{getPrivacyPolicyUrl()}}" rel="noopener" target='_blank'>
                                    {{'registration.terms.privacyPolicy' | translate}}
                                </a>
                            </label>
                        </div>
                    </div>
                    <div class="savePasswordDiv">
                        <button *ngIf="isRegistration" class="new-btn btn-primary btn" [ngClass]="{'button-disabled' : validate()}"
                                [disabled]="validate()" (click)="handleResetPasswordSubmit();">
                                {{'registration.registerNow' | translate}}
                        </button>
                        <button *ngIf="!isRegistration" class="new-btn btn-primary btn" [ngClass]="{'button-disabled' : validate()}"
                                [disabled]="validate()" (click)="handleResetPasswordSubmit();">
                                {{'resetPassword.savePassword' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #modal_resetpassword let-c="close" let-d="dismiss">
        <div *ngIf="isRegistration" class="modal-body">
            <div class="modal-title">{{ 'registration.title' | translate }}</div><br/>
            <h4>{{ 'registration.successMsg' | translate }}</h4>
        </div>
        <div *ngIf="!isRegistration" class="modal-body">
            <div class="modal-title">{{ 'registration.setPassword' | translate }}</div><br/>
            <h4>{{ 'forgotPassword.resetPwMessage' | translate }}</h4>
        </div>
        <div class="mt-4 mb-4">
            <span>
                <a href="{{exitUrl}}" class="new-btn btn-primary btn" role="button">
                    <img class="img-responsive" style="max-width:25px" alt="">
                    {{'claimSection.generalLabels.generalButtonLabels.close' | translate }}
                </a>
            </span>
        </div>
      </ng-template>

    <ng-template #modal_error let-c="close" let-d="dismiss">
        <div class="modal-body">
            <div class="modal-title">{{ 'registration.title' | translate }}</div><br/>
            <h4>{{ errorMessage | translate }}</h4>
        </div>
        <div class="mb-4">
            <div *ngIf="subErrorMessage" class="mx-3 content d-flex justify-content-center align-items-center"> 
                <span>{{ subErrorMessage | translate }}</span>
            </div>
            <button class="new-btn btn-primary btn" (click)="c('Close click')" >
                    {{'claimSection.generalLabels.generalButtonLabels.close' | translate}}
            </button>
        </div>
      </ng-template>

      <otp-box #modal_otp [otp]="otp" [maskedEmailAddress]="maskedEmailAddress" [showCloseIcon]="false" 
            [showResendOtpLink]="false" [showOtpError]="showOtpError" (onVerifyOtpEmit)="verifyOtp($event)"></otp-box>
</main>