import {Windscreen} from './motor/windscreen.model';
import { AccidentDetails } from './motor/accident-details.model';
import { DriverDetails } from './motor/driver-details.model';
import { PoliceReport } from './motor/police-report.model';
import { MotorAccidentCollission } from './motor/motor-accident-collission.model';
import { Theft } from './motor/theft-model';

export class ClaimMotor {

  private customerWorkshop : string;
  private driverGivenName : string;
  private driverFamilyName : string;
  private driverNricLicenseNumber : string;
  private driverContactNumber : string;
  private driverEmail : string;
  private driverAddressLine1 : string;
  private driverAddressLine2 : string;
  private driverAddressLine3 : string;
  private driverPostCode : string;
  private driverCountry : string;
  private vehicleNumber : string;                 // confirmed vehicle registration number
  private make : string;                          // confirmed make and model
  private model : string;
  private yearManufacture : string;               // confirmed year of manufacture
  private isDriverOwnerYesNo : string;
  private isPermittedToDriveYesNo : string;
  private accidentDate : Date;
  private accidentTime : Date;
  private accidentLocation : string;
  private damageNature : string;
  private damageType : string;
  private damageLocationWindscreen : string;
  private costParts : string;
  private costLabour : string;
  private costMiscellaneous : string;
  private costTotalRepair : string;
  private currency : string;
  private approvedWorkshops : string;
  private repairerName: string;
  private repairerId: string;

  private isLodgeByPolicyHolder: boolean = true;
  private engineNumber: string;
  private chassisNumber: string;
  private coverType: string;

  // confirm vehicle details
  private confirmMakeAndModel: string;
  private confirmEngineNumber: string;
  private confirmChassisNumber: string;
  private confirmYearOfManufacture: string;
  private mapReachable: boolean = true;
  private locationPermissionGranted: boolean = true;

  private driverDetails: DriverDetails = new DriverDetails();
  private accidentDetails: AccidentDetails = new AccidentDetails();
  private policeReport: PoliceReport = new PoliceReport();
  private windscreen: Windscreen = new Windscreen();
  private collission : MotorAccidentCollission = new MotorAccidentCollission();
  private theft: Theft = new Theft();

  private claimRedirect: string;

  getMapReachable(): boolean {
    return this.mapReachable;
  }

  setMapReachable(value: boolean) {
    this.mapReachable = value;
  }

  getLocationPermissionGranted(): boolean {
    return this.locationPermissionGranted;
  }

  setLocationPermissionGranted(value: boolean) {
    this.locationPermissionGranted = value;
  }

  getCollission(): MotorAccidentCollission {
    return this.collission;
  }

  setCollission(value: MotorAccidentCollission) {
    this.collission = value;
  }

  getWindscreen(): Windscreen {
    return this.windscreen;
  }

  setWindscreen(value: Windscreen) {
    this.windscreen = value;
  }

  getTheft(): Theft {
    return this.theft;
  }
  setTheft(value: Theft) {
    this.theft = value;
  }
  
  getDriverDetails(): DriverDetails {
    return this.driverDetails;
  }
 
  setDriverDetails(value: DriverDetails): void {
    this.driverDetails = value;
  }
 
  getAccidentDetails(): AccidentDetails {
    return this.accidentDetails;
  }
 
  setAccidentDetails(value: AccidentDetails): void {
    this.accidentDetails = value;
  }
  
  getPoliceReport(): PoliceReport {
    return this.policeReport;
  }
 
  setPoliceReport(value: PoliceReport): void {
    this.policeReport = value;
  }

  getChassisNumber(): string {
    return this.chassisNumber;
  }

  setChassisNumber(value: string) {
    this.chassisNumber = value;
  }

  getEngineNumber(): string {
    return this.engineNumber;
  }

  setEngineNumber(value: string) {
    this.engineNumber = value;
  }

  getIsLodgeByPolicyHolder(): boolean {
    return this.isLodgeByPolicyHolder;
  }

  setIsLodgeByPolicyHolder(value: boolean) {
    this.isLodgeByPolicyHolder = value;
  }

  getCustomerWorkshop(): string {
    return this.customerWorkshop;
  }

  setCustomerWorkshop(value: string) {
    this.customerWorkshop = value;
  }

  getDriverGivenName(): string {
    return this.driverGivenName;
  }

  setDriverGivenName(value: string) {
    this.driverGivenName = value;
  }

  getDriverFamilyName(): string {
    return this.driverFamilyName;
  }

  setDriverFamilyName(value: string) {
    this.driverFamilyName = value;
  }

  getDriverNricLicenseNumber(): string {
    return this.driverNricLicenseNumber;
  }

  setDriverNricLicenseNumber(value: string) {
    this.driverNricLicenseNumber = value;
  }

  getDriverContactNumber(): string {
    return this.driverContactNumber;
  }

  setDriverContactNumber(value: string) {
    this.driverContactNumber = value;
  }

  getDriverEmail(): string {
    return this.driverEmail;
  }

  setDriverEmail(value: string) {
    this.driverEmail = value;
  }

  getDriverAddressLine1(): string {
    return this.driverAddressLine1;
  }

  setDriverAddressLine1(value: string) {
    this.driverAddressLine1 = value;
  }

  getDriverAddressLine2(): string {
    return this.driverAddressLine2;
  }

  setDriverAddressLine2(value: string) {
    this.driverAddressLine2 = value;
  }

  getDriverAddressLine3(): string {
    return this.driverAddressLine3;
  }

  setDriverAddressLine3(value: string) {
    this.driverAddressLine3 = value;
  }

  getDriverPostCode(): string {
    return this.driverPostCode;
  }

  setDriverPostCode(value: string) {
    this.driverPostCode = value;
  }

  getDriverCountry(): string {
    return this.driverCountry;
  }

  setDriverCountry(value: string) {
    this.driverCountry = value;
  }

  getVehicleNumber(): string {
    return this.vehicleNumber;
  }

  setVehicleNumber(value: string) {
    this.vehicleNumber = value;
  }

  getMake(): string {
    return this.make;
  }

  setMake(value: string) {
    this.make = value;
  }

  getModel(): string {
    return this.model;
  }

  setModel(value: string) {
    this.model = value;
  }

  getYearManufacture(): string {
    return this.yearManufacture;
  }

  setYearManufacture(value: string) {
    this.yearManufacture = value;
  }

  getCoverType(): string {
    return this.coverType;
  }

  setCoverType(value: string) {
    this.coverType = value;
  }

  getIsDriverOwnerYesNo(): string {
    return this.isDriverOwnerYesNo;
  }

  setIsDriverOwnerYesNo(value: string) {
    this.isDriverOwnerYesNo = value;
  }

  getIsPermittedToDriveYesNo(): string {
    return this.isPermittedToDriveYesNo;
  }

  setIsPermittedToDriveYesNo(value: string) {
    this.isPermittedToDriveYesNo = value;
  }

  getAccidentDate(): Date {
    return this.accidentDate;
  }

  setAccidentDate(value: Date) {
    this.accidentDate = value;
  }

  getAccidentTime(): Date {
    return this.accidentTime;
  }

  setAccidentTime(value: Date) {
    this.accidentTime = value;
  }

  getAccidentLocation(): string {
    return this.accidentLocation;
  }

  setAccidentLocation(value: string) {
    this.accidentLocation = value;
  }

  getDamageNature(): string {
    return this.damageNature;
  }

  setDamageNature(value: string) {
    this.damageNature = value;
  }

  getDamageType(): string {
    return this.damageType;
  }

  setDamageType(value: string) {
    this.damageType = value;
  }

  getDamageLocationWindscreen(): string {
    return this.damageLocationWindscreen;
  }

  setDamageLocationWindscreen(value: string) {
    this.damageLocationWindscreen = value;
  }

  getCostParts(): string {
    return this.costParts;
  }

  setCostParts(value: string) {
    this.costParts = value;
  }

  getCostLabour(): string {
    return this.costLabour;
  }

  setCostLabour(value: string) {
    this.costLabour = value;
  }

  getCostMiscellaneous(): string {
    return this.costMiscellaneous;
  }

  setCostMiscellaneous(value: string) {
    this.costMiscellaneous = value;
  }

  getCostTotalRepair(): string {
    return this.costTotalRepair;
  }

  setCostTotalRepair(value: string) {
    this.costTotalRepair = value;
  }

  getCurrency(): string {
    return this.currency;
  }

  setCurrency(value: string) {
    this.currency = value;
  }

  getApprovedWorkshops(): string {
    return this.approvedWorkshops;
  }

  setApprovedWorkshops(value: string) {
    this.approvedWorkshops = value;
  }

  getRepairerName(): string {
    return this.repairerName;
  }

  setRepairerName(value: string) {
    this.repairerName = value;
  }

  getRepairerId(): string {
    return this.repairerId;
  }

  setRepairerId(value: string) {
    this.repairerId = value;
  }


  getConfirmMakeAndModel(): string {
    return this.confirmMakeAndModel;
  }

  setConfirmMakeAndModel(value: string) {
    this.confirmMakeAndModel = value;
  }

  getConfirmEngineNumber(): string {
    return this.confirmEngineNumber;
  }

  setConfirmEngineNumber(value: string) {
    this.confirmEngineNumber = value;
  }

  getConfirmChassisNumber(): string {
    return this.confirmChassisNumber;
  }

  setConfirmChassisNumber(value: string) {
    this.confirmChassisNumber = value;
  }

  getConfirmYearOfManufacture(): string {
    return this.confirmYearOfManufacture;
  }

  setConfirmYearOfManufacture(value: string) {
    this.confirmYearOfManufacture = value;
  }

  getClaimRedirect(): string {
    return this.claimRedirect;
  }

  setClaimRedirect(value: string) {
    this.claimRedirect = value;
  }

  static jsonConvertClaimMotor(claimMotor: ClaimMotor): ClaimMotor {

    claimMotor = Object.assign(new ClaimMotor(), claimMotor);

    if (claimMotor != null) {

        if (claimMotor.getWindscreen()) {
            claimMotor.setWindscreen(Object.assign(new Windscreen(), claimMotor.getWindscreen()));
            Windscreen.jsonConvert(claimMotor.getWindscreen());
        }

        if (claimMotor.getTheft()) {
            claimMotor.setTheft(Object.assign(new Theft(), claimMotor.getTheft()));
            Theft.jsonConvert(claimMotor.getTheft());
        }

        if(claimMotor.getAccidentDetails() != null){
          claimMotor.setAccidentDetails(Object.assign(new AccidentDetails(), claimMotor.getAccidentDetails()));
          AccidentDetails.jsonConvert(claimMotor.getAccidentDetails());
        }

        if(claimMotor.getPoliceReport() != null){
          claimMotor.setPoliceReport(Object.assign(new PoliceReport(), claimMotor.getPoliceReport()));
          PoliceReport.jsonConvert(claimMotor.getPoliceReport());
        }
        
        if (claimMotor.getCollission()) {
          claimMotor.setCollission(Object.assign(new MotorAccidentCollission(), claimMotor.getCollission()));
          MotorAccidentCollission.jsonConvert(claimMotor.getCollission());
        }
    }

    return claimMotor;
  }
}
