import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {VisitedDoctor} from "../../../../model/travel/visted-a-doctor.model";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-medical-bills',
    templateUrl: './medical-bills.component.html',
    styleUrls: ['./medical-bills.component.css']
})
export class MedicalBillsComponent extends ClaimsBaseComponent implements OnInit {

    medicalBillForm: FormGroup;
    showTotalBox = false;
    visitedDoctor: VisitedDoctor;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.visitedDoctor = this.claim.getClaimTravel().getVisitedDoctor();
    }

    ngOnInit() {

        this.pushGAViewMedBills();
        if(this.visitedDoctor.getMedicalExpenseType() == null){
            this.visitedDoctor.setMedicalExpenseType(UtilitiesService.getCountryCurrency(this.claim.getCountry()));
        }

        this.medicalBillForm = this.fb.group(
            {
                numberOfTreatmentBill: [this.visitedDoctor.getNumberOfTreatmentBill()],
                otherNumOfTreatmentBill: [this.visitedDoctor.getOtherNumOfTreatmentBill()],
                medicalExpenseTypeAmount: [this.visitedDoctor.getMedicalExpenseTypeAmount()],
                medicalExpenseType: [this.visitedDoctor.getMedicalExpenseType()],
            }
        );
    }

    ngAfterViewInit() {
        this.medicalBillForm.valueChanges.subscribe(data => {
            this.visitedDoctor.setNumberOfTreatmentBill(this.medicalBillForm.get('numberOfTreatmentBill').value);
            this.visitedDoctor.setOtherNumOfTreatmentBill(this.medicalBillForm.get('otherNumOfTreatmentBill').value);
            this.visitedDoctor.setMedicalExpenseTypeAmount(this.medicalBillForm.get('medicalExpenseTypeAmount').value);
            this.visitedDoctor.setMedicalExpenseType(this.medicalBillForm.get('medicalExpenseType').value);

            this.validateClaimDetailsComplete();
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 3});
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/visitedADoctor/doctorSupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/visitedADoctor/followUpTreatment"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.medicalBillForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.medicalBillForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 3});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 4, subMenuIndex: 3});
        }
    }

    //Google Analytics
    pushGAViewMedBills() {
        (<any>window).dataLayer.push({
            'pageStep': 'Visit Doctor – Medical Bills',
            'vPath': '/claim/visit-doctor/medical-bills',
            'event': 'vpageview',
        });
    }
}
