import {Injectable} from '@angular/core';
import {SideMenuService} from '../services/side-menu.service';

@Injectable({
    providedIn: 'root'
})

export class RouterService {

    public finishGetStarted = false;
    public finishConfirmTravelPeriod = false;
    public finishSelectInsured = false;
    public finishAcidentDetails = false;
    public finishInjuryDetails = false;
    public finishDetail = false;
    public finishPayment = false;
    public finishReview = false;
    public finishChooseClaim = false;
    public finishConfirmVehicleDetails = false;
    public finishGenericClaimDetail = false;
    public finishGenericUploadDocument = false;
    public finishConfirmIncidentDetails = false;

    constructor(private sideMenuService: SideMenuService) {

        this.sideMenuService.changeStepComplete$.subscribe(
            finishType => {
                console.log('RouterService finishType:', finishType);
                if (finishType === 'finishGetStarted') {
                    this.finishGetStarted = true;
                } else if (finishType === 'finishConfirmTravelPeriod') {
                    this.finishConfirmTravelPeriod = true;
                } else if (finishType === 'finishConfirmVehicleDetails') {
                    this.finishConfirmVehicleDetails = true;
                } else if (finishType === 'finishSelectInsured') {
                    this.finishSelectInsured = true;
                } else if (finishType === 'finishAccidentDetails') {
                    this.finishAcidentDetails = true;
                } else if (finishType === 'finishInjuryDetails') {
                    this.finishInjuryDetails = true;
                } else if (finishType === 'finishDetail') {
                    this.finishDetail = true;
                } else if (finishType === 'finishPayment') {
                    this.finishPayment = true;
                } else if (finishType === 'finishReview') {
                    this.finishReview = true;
                } else if (finishType === 'finishChooseClaim') {
                    this.finishChooseClaim = true;
                } else if (finishType === 'finishGenericClaimDetail') {
                    this.finishGenericClaimDetail = true;
                } else if (finishType === 'finishGenericUploadDocument') {
                    this.finishGenericUploadDocument = true;
                } else if (finishType === 'finishConfirmIncidentDetails') {
                    this.finishConfirmIncidentDetails = true;
                }
            }
        );
    }

    getSelectInsured() {
        return this.finishSelectInsured;
    }

}
