import { Component, Input, Output, EventEmitter, Injectable } from '@angular/core';

@Component({
  selector: 'app-right-view-modal',
  templateUrl: './right-view-modal.component.html'
})

@Injectable()
export class RightViewModalComponent {

  @Input() rightViewModalGetArea: any;
  @Input() rightViewModalIsOpen: any;
  @Input() rightViewModalIsOpenF: any;

  @Input() rightViewModalTable: any;
  @Input() rightViewModalSelectHead: any;
  @Input() rightViewModalSelectNeckTrunk: any;
  @Input() rightViewModalSelectLeftArm: any;
  @Input() rightViewModalSelectRightArm: any;
  @Input() rightViewModalSelectLeftHand: any;
  @Input() rightViewModalSelectRightHand: any;
  @Input() rightViewModalSelectLeftLeg: any;
  @Input() rightViewModalSelectRightLeg: any;
  @Input() rightViewModalSelectLeftFoot: any;
  @Input() rightViewModalSelectRightFoot: any;
  @Input() rightViewModalTemp: any;

  @Input() rightViewModalFetchedData: any;

  @Input() rightViewModalInjuryList: any;

  @Input() rightViewModalIsLoading: any;

  @Input() rightViewModalPathDisabled: any;

  @Output() emitRightViewModalOnClickCloseSpecificArea = new EventEmitter<any>();
  rightViewModalOnClickCloseSpecificArea() {
    this.emitRightViewModalOnClickCloseSpecificArea.emit();
  }

  @Output() emitRightViewModalOnClickSpecificArea = new EventEmitter<any>();
  rightViewModalOnClickSpecificArea(id: any, area: any) {
    this.emitRightViewModalOnClickSpecificArea.emit({ id, area });
  }

  @Output() emitRightViewModalOnClickNotClose = new EventEmitter<any>();
  rightViewModalOnClickNotClose(e: any) {
    this.emitRightViewModalOnClickNotClose.emit(e);
  }

  @Output() emitRightViewModalShowTooltip = new EventEmitter<any>();
  rightViewModalShowTooltip(txt: any) {
    this.emitRightViewModalShowTooltip.emit(txt);
  }

  @Output() emitRightViewModalHideToolTip = new EventEmitter<any>();
  rightViewModalHideToolTip() {
    this.emitRightViewModalHideToolTip.emit();
  }

  isSelected(id: any) {
    return this.rightViewModalTemp.some((obj) => obj.id === id);
  }

  changeColor(selected: any) {
    return selected ? '#003da5' : 'transparent';
  }

  @Output() emitRightViewModalOnSubmitSelected = new EventEmitter<any>();
  rightViewModalOnSubmitSelected() {
    this.emitRightViewModalOnSubmitSelected.emit();
  }

  checkAreaExists(area: any) {
    return this.rightViewModalTable.some((item: any) => item.area === area);
  }
  @Output() emitRightViewModalCustomSearchFn = new EventEmitter<any>();
  rightViewModalCustomSearchFn(term: any) {
    this.emitRightViewModalCustomSearchFn.emit(term);
  }

  @Output() emitRightViewModalAddTagFn = new EventEmitter<any>();
  rightViewModalAddTagFn(label: any) {
    this.emitRightViewModalAddTagFn.emit(label);
  }

  @Output() emitRightViewModalOnClickRemoveSpecificArea = new EventEmitter<any>();
  rightViewModalOnClickRemoveSpecificArea(id: any, area: any) {
    this.emitRightViewModalOnClickRemoveSpecificArea.emit({ id, area });
  }
}
