import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import { DocumentField } from "src/app/model/document-field";
import { SmartqDocuments } from "src/app/model/smartq-documents.model";

export class AccidentalMedicalExpensesFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        let receiptlabel = this.claim.getCountry().toLowerCase() === 'sgp' ? 'paClaim.supportingDocs.types.copyMedInvoices' : 'paClaim.supportingDocs.types.copyMedReceipt';
        let hospitalDischarge = this.claim.getCountry().toLowerCase() === 'sgp' ? 'paClaim.supportingDocs.types.hospDischargeSlip' : 'paClaim.supportingDocs.types.dischargeSlip';
        if (this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getWasAdmitted()) {
            requiredDocuments.push(new DocumentField('RECEIPTS', receiptlabel, SmartqDocuments.PA_INVOICE, true));
            requiredDocuments.push(new DocumentField('DISCHARGESLIP', hospitalDischarge, SmartqDocuments.PA_MEDICALREPORT, true));
            if (this.claim.getCountry().toLowerCase() !== 'sgp') {
                requiredDocuments.push(new DocumentField('REFERRALLETTER', 'paClaim.supportingDocs.types.copyReferralLetter', SmartqDocuments.PA_MEDICAL, true));
            }
        } else {
            requiredDocuments.push(new DocumentField('RECEIPTS', receiptlabel, SmartqDocuments.PA_INVOICE, true));
            if (this.claim.getClaimPersonalAccident().getAccidentalMedicalExpenses().getClinicalExpenses().find(
                clinicalExpense => ['Specialist', 'Diagnostic Testing'].indexOf(clinicalExpense.getMedicalAttention()) !== -1 ) && this.claim.getCountry().toLowerCase() !== 'sgp') {
                requiredDocuments.push(new DocumentField('REFERRALLETTER', 'paClaim.supportingDocs.types.referralLetter', SmartqDocuments.PA_MEDICAL, true));
            }
        }
        if (this.claim.getCountry().toLowerCase() === 'sgp') {
            requiredDocuments.push(new DocumentField('DOCMEMO', 'paClaim.supportingDocs.types.doctorsMemo', SmartqDocuments.PA_MEDICAL, true));
            requiredDocuments.push(new DocumentField('MEDCERT', 'paClaim.supportingDocs.types.medCertPayslip', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('MEDISAVE', 'paClaim.supportingDocs.types.medisaveStatement', SmartqDocuments.PA_REPORT, true));
            if (this.claim.getOtherPolicyQuestion() === 'Y') {
                requiredDocuments.push(new DocumentField('SETTLEMENTLETTER', 'paClaim.supportingDocs.types.settlementLetter', SmartqDocuments.PA_OTHER, true));
            }
            requiredDocuments.push(new DocumentField('INVESTIGATIONRESULT', 'paClaim.supportingDocs.types.investigationResult', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('MEDREPORT', 'paClaim.supportingDocs.types.medReportExtentInjury', SmartqDocuments.PA_MEDICALREPORT, true));
            requiredDocuments.push(new DocumentField('POLICEREPORT', 'paClaim.supportingDocs.types.policeReport', SmartqDocuments.PA_OTHER, true));
        } else {
            requiredDocuments.push(new DocumentField('MEDCERT', 'paClaim.supportingDocs.types.medCert', SmartqDocuments.PA_OTHER, true));
        }

        this.requiredDocuments = requiredDocuments;
    }
}