export class Water {

    private isTheWaterStillLeaking: boolean;

    getIsTheWaterStillLeaking(): boolean {
        return this.isTheWaterStillLeaking;
    }

    setIsTheWaterStillLeaking(isTheWaterStillLeaking: boolean): void {
        this.isTheWaterStillLeaking = isTheWaterStillLeaking;
    }

    static jsonConvert(water: Water): Water {
        water = Object.assign(new Water(), water);
        return water;
    }
}