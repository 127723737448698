import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { OnInit } from "@angular/core";
import { Claim } from "../../model/claim.model";
import { ClaimTypes } from "../../model/claim-type.model";
import { DocumentField } from "../../model/document-field";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";
import { LostPersonalItemDetail } from 'src/app/model/travel/lost-personal-detail.model';

export class LostPersonalItemFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    detailsOfLostItemsArray: LostPersonalItemDetail[];
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST];
    }

    setRequiredDocuments(){
        this.detailsOfLostItemsArray = this.claim.getClaimTravel().getLostPersonalItem().getDetailsOfLostItemsArray();
        let requiredDocuments: DocumentField[] = [];

        if (this.claim.getCountry()?.toLowerCase() == 'mys') {
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.copyTicket', SmartqDocuments.AIRTICKET, true), false));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('RECLOST', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.receiptLostItemSGP', SmartqDocuments.INVOICE, true), false));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('LOSSREPORT', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.loseReport', SmartqDocuments.REPORT, true), false));

            if (this.detailsOfLostItemsArray.find(item => item.getLossType() === 'Passport / NRIC')) {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('ORIGDOC', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.receiptReplacePassportNRIC', SmartqDocuments.REPLACEMENTRECEIPT, true), false));
            }

        } else if (this.claim.getCountry()?.toLowerCase() == 'sgp') {
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.copyTicket', SmartqDocuments.AIRTICKET, true), false));
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('RECLOST', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.receiptLostItemSGP', SmartqDocuments.INVOICE, true), true));

            let lossReportStpRequired = this.claim.getClaimTravel().getLostPersonalItem()?.getHowDamageOccurred()?.toLowerCase() == 'theft/robbery/burglary';
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('LOSSREPORT', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.loseReport', SmartqDocuments.REPORT, true), lossReportStpRequired));

            if (this.detailsOfLostItemsArray.find(item => item.getLossType() === 'Passport / NRIC / FIN')) {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('ORIGDOC', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.receiptReplacement', SmartqDocuments.REPLACEMENTRECEIPT, true), true));
            }

        } else {
            // hkg, hgi
            let lostItems:  LostPersonalItemDetail[] = this.claim.getClaimTravel().getLostPersonalItem().getDetailsOfLostItemsArray();
            let hasDocStpRequired =  true;

            if (this.claim.getClaimTravel().getGBAPlanType()) {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('PROOFTRAVEL', 'claimSection.greaterBayArea.proofOfTravel', SmartqDocuments.PROOF_TRAVEL, true), false));
                hasDocStpRequired = false;
            } else {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.copyTicket', SmartqDocuments.AIRTICKET, true), false));
            }

            if (lostItems.find(item => item.getHasOriginalPurchasedReceipt() == true)) {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('PRCHRCPT', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.purchaseReceipt', SmartqDocuments.PURCHASERCPT, true), hasDocStpRequired));
            }

            if (lostItems.find(item => item.getHasOriginalPurchasedReceipt() == false)) {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('REFPRCHRCPT', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.refPurchaseReceipt', SmartqDocuments.PURCHASERCPTREF, true), false));
            }

            let lossReportStpRequired = this.claim.getClaimTravel().getLostPersonalItem()?.getHowDamageOccurred()?.toLowerCase() == 'theft/robbery/burglary' && hasDocStpRequired;
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('LOSSREPORT', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.loseReport', SmartqDocuments.REPORT, true), lossReportStpRequired));

            if (this.detailsOfLostItemsArray.find(item => item.getLossType() === 'Passport / HKID / China Re-entry card')) {
                requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('ORIGDOC', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.origDocument', SmartqDocuments.REPLACEMENTRECEIPT, true), hasDocStpRequired));
            }
        }

        if (this.claim.getClaimTravel().getLostPersonalItem().getHasCompensationReceived()){
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('COMPBREAK', 'pageSummaryClaim.claimItem.sectionLostItem.subSecSupportingDocs.compBreakdown', SmartqDocuments.PAYMENTDOCUMENT, true), false));
        }

        if (UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth())){
            requiredDocuments.push(this.addDocumentWithSTP(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true), false));
        }

        this.requiredDocuments = requiredDocuments;
    }
}