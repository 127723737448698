<div style="padding-top:5px;">
    <label style="margin-bottom: 0;">{{ 'ecClaim.injuryDetails.injuredBodyParts' | translate }}*</label> <br/>
    <small>{{'ecClaim.injuryDetails.injuredBodyPartsDesc' | translate}}</small>
</div>
<div *ngIf="showErrorMessage">
    <small id="error-message">{{'ecClaim.injuryDetails.injuredBodyPartsNotSelected' | translate}}</small>
</div>
<app-front-view-modal
    [frontViewModalGetArea]="getArea"
    [frontViewModalIsOpen]="isOpen"
    [frontViewModalIsOpenF]="isOpenF"
    [frontViewModalInjuryList]="injuryList"
    [frontViewModalTemp]="temp"
    [frontViewModalTable]="table"
    [frontViewModalSelectHead]="selectHead"
    [frontViewModalSelectNeckTrunk]="selectNeckTrunk"
    [frontViewModalSelectLeftArm]="selectLeftArm"
    [frontViewModalSelectRightArm]="selectRightArm"
    [frontViewModalSelectLeftHand]="selectLeftHand"
    [frontViewModalSelectRightHand]="selectRightHand"
    [frontViewModalSelectLeftLeg]="selectLeftLeg"
    [frontViewModalSelectRightLeg]="selectRightLeg"
    [frontViewModalSelectLeftFoot]="selectLeftFoot"
    [frontViewModalSelectRightFoot]="selectRightFoot"
    (emitFrontViewModalOnClickCloseSpecificArea)="onClickCloseSpecificArea()"
    (emitFrontViewModalOnClickNotClose)="onClickNotClose($event)"
    (emitFrontViewModalShowTooltip)="showTooltip($event)"
    (emitFrontViewModalHideToolTip)="hideToolTip()"
    (emitFrontViewModalCustomSearchFn)="customSearchFn($event)"
    (emitFrontViewModalAddTagFn)="addTagFn($event)"
    (emitFrontViewModalOnSubmitSelected)="onSubmitSelected()"
    (emitFrontViewModalOnClickSpecificArea)="onClickSpecificArea($event.id, $event.area)"
    (emitFrontViewModalOnClickRemoveSpecificArea)="onClickRemoveSpecificArea($event.id, $event.area)"
></app-front-view-modal> 

<app-back-view-modal
    [backViewModalGetArea]="getArea"
    [backViewModalIsOpen]="isOpen"
    [backViewModalIsOpenF]="isOpenF"
    [backViewModalInjuryList]="injuryList"
    [backViewModalTemp]="temp"
    [backViewModalTable]="table"
    [backViewModalSelectHead]="selectHead"
    [backViewModalSelectNeckTrunk]="selectNeckTrunk"
    [backViewModalSelectLeftArm]="selectLeftArm"
    [backViewModalSelectRightArm]="selectRightArm"
    [backViewModalSelectLeftHand]="selectLeftHand"
    [backViewModalSelectRightHand]="selectRightHand"
    [backViewModalSelectLeftLeg]="selectLeftLeg"
    [backViewModalSelectRightLeg]="selectRightLeg"
    [backViewModalSelectLeftFoot]="selectLeftFoot"
    [backViewModalSelectRightFoot]="selectRightFoot"
    (emitBackViewModalOnClickCloseSpecificArea)="onClickCloseSpecificArea()"
    (emitBackViewModalOnClickNotClose)="onClickNotClose($event)"
    (emitBackViewModalShowTooltip)="showTooltip($event)"
    (emitBackViewModalHideToolTip)="hideToolTip()"
    (emitBackViewModalCustomSearchFn)="customSearchFn($event)"
    (emitBackViewModalAddTagFn)="addTagFn($event)"
    (emitBackViewModalOnSubmitSelected)="onSubmitSelected()"
    (emitBackViewModalOnClickSpecificArea)="onClickSpecificArea($event.id, $event.area)"
    (emitBackViewModalOnClickRemoveSpecificArea)="onClickRemoveSpecificArea($event.id, $event.area)"
></app-back-view-modal>

<app-left-view-modal 
    [leftViewModalGetArea]="getArea"
    [leftViewModalIsOpen]="isOpen"
    [leftViewModalIsOpenF]="isOpenF"
    [leftViewModalTemp]="temp"
    [leftViewModalTable]="table"
    [leftViewModalInjuryList]="injuryList"
    [leftViewModalSelectHead]="selectHead"
    [leftViewModalSelectNeckTrunk]="selectNeckTrunk"
    [leftViewModalSelectLeftArm]="selectLeftArm"
    [leftViewModalSelectRightArm]="selectRightArm"
    [leftViewModalSelectLeftHand]="selectLeftHand"
    [leftViewModalSelectRightHand]="selectRightHand"
    [leftViewModalSelectLeftLeg]="selectLeftLeg"
    [leftViewModalSelectRightLeg]="selectRightLeg"
    [leftViewModalSelectLeftFoot]="selectLeftFoot"
    [leftViewModalSelectRightFoot]="selectRightFoot"
    (emitLeftViewModalOnClickCloseSpecificArea)="onClickCloseSpecificArea()"
    (emitLeftViewModalOnClickNotClose)="onClickNotClose($event)"
    (emitLeftViewModalShowTooltip)="showTooltip($event)"
    (emitLeftViewModalHideToolTip)="hideToolTip()"
    (emitLeftViewModalCustomSearchFn)="customSearchFn($event)"
    (emitLeftViewModalAddTagFn)="addTagFn($event)"
    (emitLeftViewModalOnSubmitSelected)="onSubmitSelected()"
    (emitLeftViewModalOnClickSpecificArea)="onClickSpecificArea($event.id, $event.area)"
    (emitLeftViewModalOnClickRemoveSpecificArea)="onClickRemoveSpecificArea($event.id, $event.area)"
></app-left-view-modal>

<app-right-view-modal 
    [rightViewModalGetArea]="getArea"
    [rightViewModalIsOpen]="isOpen"
    [rightViewModalIsOpenF]="isOpenF"
    [rightViewModalTemp]="temp"
    [rightViewModalTable]="table"
    [rightViewModalInjuryList]="injuryList"
    [rightViewModalSelectHead]="selectHead"
    [rightViewModalSelectNeckTrunk]="selectNeckTrunk"
    [rightViewModalSelectLeftArm]="selectLeftArm"
    [rightViewModalSelectRightArm]="selectRightArm"
    [rightViewModalSelectLeftHand]="selectLeftHand"
    [rightViewModalSelectRightHand]="selectRightHand"
    [rightViewModalSelectLeftLeg]="selectLeftLeg"
    [rightViewModalSelectRightLeg]="selectRightLeg"
    [rightViewModalSelectLeftFoot]="selectLeftFoot"
    [rightViewModalSelectRightFoot]="selectRightFoot"
    (emitRightViewModalOnClickCloseSpecificArea)="onClickCloseSpecificArea()"
    (emitRightViewModalOnClickNotClose)="onClickNotClose($event)"
    (emitRightViewModalShowTooltip)="showTooltip($event)"
    (emitRightViewModalHideToolTip)="hideToolTip()"
    (emitRightViewModalCustomSearchFn)="customSearchFn($event)"
    (emitRightViewModalAddTagFn)="addTagFn($event)"
    (emitRightViewModalOnSubmitSelected)="onSubmitSelected()"
    (emitRightViewModalOnClickSpecificArea)="onClickSpecificArea($event.id, $event.area)"
    (emitRightViewModalOnClickRemoveSpecificArea)="onClickRemoveSpecificArea($event.id, $event.area)"
></app-right-view-modal>

<div class="body_outer_container">
    <div class="body_content_container">
        <div class="body_section">
            <div class="body_wrapper">
                <div class="gender_container">
                    <div class="txt_wrapper">
                        <span>{{'ecClaim.injuryDetails.gender' | translate}}</span>
                    </div>
                    <button (click)="onClickGender('Male')" [ngClass]="gender === 'Male' ? 'active_male' : ''">
                        <img src="assets/images/body-parts/gender-male.png" alt="male"/>
                        <span class="gender_male">{{'ecClaim.injuryDetails.male' | translate}}</span>
                    </button>
                    <button (click)="onClickGender('Female')" [ngClass]="gender === 'Female' ? 'active_female' : ''">
                        <img src="assets/images/body-parts/gender-female.png" alt="female"/>
                        <span class="gender_female">{{'ecClaim.injuryDetails.female' | translate}}</span>
                    </button>
                </div>
                <div class="tab_body_container">
                    <span class="txt_views">{{'ecClaim.injuryDetails.view' | translate}}</span>
                    <!-- male -->
                    <div *ngIf="gender === 'Male'">
                        <mat-tab-group>
                            <mat-tab label="Tab1">
                                <ng-template mat-tab-label>
                                    <img src="assets/images/body-parts/male/view-male-front.png" alt="front-male"/>
                                    <span class="view_front">{{'ecClaim.injuryDetails.front' | translate}}</span>
                                </ng-template>                              
                                <app-front-view-tab 
                                    [frontViewTabTemp]="temp"
                                    [frontViewTabGender]="gender"
                                    (emitFrontViewTabOnClickArea)="onClickArea($event)"
                                    (emitFrontViewTabShowTooltip)="showTooltip($event)"
                                    (emitFrontViewTabHideToolTip)="hideToolTip()"
                                ></app-front-view-tab> 
                            </mat-tab>
                            <mat-tab label="Tab 2">
                                <ng-template mat-tab-label> 
                                    <img src="assets/images/body-parts/male/view-male-back.png" alt="back-male"/>   
                                    <span class="view_back">{{'ecClaim.injuryDetails.back' | translate}}</span>                        
                                </ng-template>
                                <app-back-view-tab 
                                    [backViewTabTemp]="temp"
                                    [backViewTabGender]="gender"
                                    (emitBackViewTabOnClickArea)="onClickArea($event)"
                                    (emitBackViewTabShowTooltip)="showTooltip($event)"
                                    (emitBackViewTabHideToolTip)="hideToolTip()"
                                ></app-back-view-tab> 
                            </mat-tab>
                            <mat-tab label="Tab 3">
                                <ng-template mat-tab-label>
                                    <img src="assets/images/body-parts/male/view-male-left.png" alt="left-male"/>   
                                    <span class="view_left">{{'ecClaim.injuryDetails.left' | translate}}</span>                 
                                </ng-template>
                                <app-left-view-tab 
                                    [leftViewTabTemp]="temp"
                                    [leftViewTabGender]="gender"
                                    (emitLeftViewTabOnClickArea)="onClickArea($event)"
                                    (emitLeftViewTabShowTooltip)="showTooltip($event)"
                                    (emitLeftViewTabHideToolTip)="hideToolTip()"
                                ></app-left-view-tab>
                            </mat-tab>
                            <mat-tab label="Tab 4">
                                <ng-template mat-tab-label>    
                                    <img src="assets/images/body-parts/male/view-male-right.png" alt="right-male"/>         
                                    <span class="view_right">{{'ecClaim.injuryDetails.right' | translate}}</span>              
                                </ng-template>
                                <app-right-view-tab 
                                    [rightViewTabTemp]="temp"
                                    [rightViewTabGender]="gender"
                                    (emitRightViewTabOnClickArea)="onClickArea($event)"
                                    (emitRightViewTabShowTooltip)="showTooltip($event)"
                                    (emitRightViewTabHideToolTip)="hideToolTip()"
                                ></app-right-view-tab>
                            </mat-tab>
                        </mat-tab-group>
                    </div>

                    <!-- female -->
                    <div *ngIf="gender === 'Female'">
                        <mat-tab-group>
                            <mat-tab label="Tab1">
                                <ng-template mat-tab-label>
                                    <img src="assets/images/body-parts/male/view-male-front.png" alt="front-female"/>
                                    <span class="view_front">{{'ecClaim.injuryDetails.front' | translate}}</span>
                                </ng-template>  
                                <app-front-view-tab 
                                    [frontViewTabTemp]="temp"
                                    [frontViewTabGender]="gender"
                                    (emitFrontViewTabOnClickArea)="onClickArea($event)"
                                    (emitFrontViewTabShowTooltip)="showTooltip($event)"
                                    (emitFrontViewTabHideToolTip)="hideToolTip()"
                                ></app-front-view-tab> 
                            </mat-tab>
                            <mat-tab label="Tab 2">
                                <ng-template mat-tab-label> 
                                    <img src="assets/images/body-parts/male/view-male-back.png" alt="back-female"/>   
                                    <span class="view_back">{{'ecClaim.injuryDetails.back' | translate}}</span>                                 
                                </ng-template> 
                                <app-back-view-tab 
                                    [backViewTabTemp]="temp"
                                    [backViewTabGender]="gender"
                                    (emitBackViewTabOnClickArea)="onClickArea($event)"
                                    (emitBackViewTabShowTooltip)="showTooltip($event)"
                                    (emitBackViewTabHideToolTip)="hideToolTip()"
                                ></app-back-view-tab> 
                            </mat-tab>
                            <mat-tab label="Tab 3">
                                <ng-template mat-tab-label>
                                    <img src="assets/images/body-parts/male/view-male-left.png" alt="left-female"/>     
                                    <span class="view_left">{{'ecClaim.injuryDetails.left' | translate}}</span>                          
                                </ng-template>
                                <app-left-view-tab 
                                    [leftViewTabTemp]="temp"
                                    [leftViewTabGender]="gender"
                                    (emitLeftViewTabOnClickArea)="onClickArea($event)"
                                    (emitLeftViewTabShowTooltip)="showTooltip($event)"
                                    (emitLeftViewTabHideToolTip)="hideToolTip()"
                                ></app-left-view-tab>
                            </mat-tab>
                            <mat-tab label="Tab 4">
                                <ng-template mat-tab-label>    
                                    <img src="assets/images/body-parts/male/view-male-right.png" alt="right-female"/>   
                                    <span class="view_right">{{'ecClaim.injuryDetails.right' | translate}}</span>                              
                                </ng-template>
                                <app-right-view-tab 
                                    [rightViewTabTemp]="temp"
                                    [rightViewTabGender]="gender"
                                    (emitRightViewTabOnClickArea)="onClickArea($event)"
                                    (emitRightViewTabShowTooltip)="showTooltip($event)"
                                    (emitRightViewTabHideToolTip)="hideToolTip()"
                                ></app-right-view-tab>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
                <div class="body_table_outer_container">
                    <div class="body_table_container">
                        <div class="body_table_left"></div>
                        <div class="body_table_right">
                            <app-tab-table
                                [tabTableGender]="gender"
                                [injuredBodyParts]="claimEmployeeCompensation.getAccidentDetails().getInjuredBodyParts()"
                                (emitTabTableOnClickArea)="onClickArea($event)"
                                (emitTabTableOnClickRemoveSpecificArea)="onClickRemoveSpecificAreaMain($event.id, $event.area)"
                            >
                            </app-tab-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
