
export class IntegrationClaimDetailsJson {

    private claimNumber: string;
    private claimNotificationNumber: string;
    private dateOfLoss: Date;
    private qnectStatus: string;
    private productType: string;
    private policyNumber: string;
    private clientName: string;
    private lastUpdate: Date;


    getClaimNumber(): string {
        return this.claimNumber;
    }

    setClaimNumber(value: string) {
        this.claimNumber = value;
    }

    getClaimNotificationNumber(): string {
        return this.claimNotificationNumber;
    }

    setClaimNotificationNumber(value: string) {
        this.claimNotificationNumber = value;
    }

    getDateOfLoss(): Date {
        return this.dateOfLoss;
    }

    setDateOfLoss(value: Date) {
        this.dateOfLoss = value;
    }

    getQnectStatus(): string {
        return this.qnectStatus;
    }

    setQnectStatus(value: string) {
        this.qnectStatus = value;
    }

    getProductType(): string {
        return this.productType;
    }

    setProductType(value: string) {
        this.productType = value;
    }

    getPolicyNumber(): string {
        return this.policyNumber;
    }

    setPolicyNumber(value: string) {
        this.policyNumber = value;
    }

    getClientName(): string {
        return this.clientName;
    }

    setClientName(value: string) {
        this.clientName = value;
    }

    getLastUpdate(): Date {
        return this.lastUpdate;
    }

    setLastUpdate(value: Date) {
        this.lastUpdate = value;
    }

    static jsonConvert(integrationClaimDetailsJson: IntegrationClaimDetailsJson): IntegrationClaimDetailsJson {

        integrationClaimDetailsJson = Object.assign(new IntegrationClaimDetailsJson(), integrationClaimDetailsJson);

        if (integrationClaimDetailsJson.getDateOfLoss() != null) {
            integrationClaimDetailsJson.setDateOfLoss(new Date(integrationClaimDetailsJson.getDateOfLoss()));
        }

        if (integrationClaimDetailsJson.getLastUpdate() != null) {
            integrationClaimDetailsJson.setLastUpdate(new Date(integrationClaimDetailsJson.getLastUpdate()));
        }

        return integrationClaimDetailsJson;
    }
}