import {Injectable} from "@angular/core";
import {DocumentDetails} from "../model/document-details.model";
import {ClaimService} from "./claim.service";
import {HttpClient} from "@angular/common/http";
import {TransactionInfoService} from "./transaction-info.service";
import {Observable} from "rxjs";
import {Claim} from "../model/claim.model";
import {ClaimTypes} from "../model/claim-type.model";
import {TravelDelay} from "../model/travel/travel-delay.model";
import {LostPersonalItem} from "../model/travel/lost-personal-item.model";
import {DamagePersonalItem} from "../model/travel/damage-personal-item.model";
import {BaggageDelay} from "../model/travel/baggage-delay.model";
import {VisitedDoctor} from "../model/travel/visted-a-doctor.model";
import {StayInHospital} from "../model/travel/stay-in-hospital.model";
import {TripCancellation} from "../model/travel/trip-cancellation.model";
import {TripCurtailment} from "../model/travel/trip-curtailment.model";
import {RentalVehicle} from "../model/travel/rental-vehicle.model";
import {TravelReRoute} from "../model/travel/travel-reroute.model";
import {MissedEvent} from "../model/travel/missed-event.model";
import {MissConnection} from "../model/travel/miss-connection.model";
import {DeathAndSeriousBodyInjuryLoss} from "../model/travel/death-and-serious-body-injury-loss";
import {PersonalLiability} from "../model/travel/personal-liability.model";
import {OtherClaim} from "../model/travel/other-claim.model";
import {DocumentHelperService} from "./document-helper.service";
import {Repatriation} from "../model/domestic-helper/repatriation.model";
import {HospitalizationHelper} from "../model/domestic-helper/hospitalization-helper.model";
import {OtherClaimDMH} from "../model/domestic-helper/other-claim.model";
import {ClinicalExpenses} from "../model/domestic-helper/clinical-expenses.model";
import {DentalExpenses} from "../model/domestic-helper/dental-expenses.model";
import {DeathAndPermanentDisability} from "../model/domestic-helper/death-and-permanent-disability.model";
import {HelpersLiability} from "../model/domestic-helper/helpers-liability.model";
import {EmployeeCompensation} from "../model/domestic-helper/employee-compensation.model";
import {DishonestyHelper} from "../model/domestic-helper/dishonesty-helper.model";
import {ClaimTypeItem} from '../model/claim-type-item.model';
import {Windscreen} from '../model/motor/windscreen.model';
import {MotorHelperService} from './motor-helper.service';
import {MotorAccidentCollission} from '../model/motor/motor-accident-collission.model';
import {Theft} from '../model/motor/theft-model';
import {PoliceReport} from "../model/motor/police-report.model";
import { HomeDetails } from "../model/home/home-details.model";
import { AccidentalMedicalExpenses } from "../model/personal-accident/accidental-medical-expenses.model";
import { HospitalCash } from "../model/personal-accident/hospital-cash.model";
import { Others } from "../model/common/others.model";
import { UtilitiesService } from "../utilities/utilities.service";
import {ClaimEmployeeCompensation} from '../model/claim-employee-compensation.model';


@Injectable()
export class ClaimTypeItemHelperService {

    constructor(private http: HttpClient,
                private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private transactionInfoService: TransactionInfoService,
                private motorHelperService: MotorHelperService) {
    }

    deleteClaimType(claimTypeIndex) {
           let claimTypeCode = "";
           let transactionInfo = this.transactionInfoService.getTransactionInfo();

           //get claim type item list , then set 'selected' and complete property of claim to false
           let claimTypeList: ClaimTypeItem[] = transactionInfo.getClaimTypeItemList();

           claimTypeList.forEach(function (claimTypeItem) {
               if(claimTypeItem.claimTypeId == claimTypeIndex){
                   claimTypeCode = claimTypeItem.claimTypeCode;
                   claimTypeItem.selected = false;
                   claimTypeItem.completed = false;
               }
           });

           let subMenuList = transactionInfo.getNavigationMenu().getClaimDetailMenu();

           for (var i = 0; i < subMenuList.length; i++) {
               if (subMenuList[i]['claimTypeId'] == claimTypeIndex) {
                   subMenuList[i]['hidden'] = true;
                   subMenuList[i]['complete'] = false;
                   let submenus = subMenuList[i]['subMenus'];
                   if(submenus.length > 0) {
                       for (var j = 0; j < submenus.length; j++) {
                           submenus[j]['complete'] = false;
                       }
                   }
               }
           }

          //update claim - selectedClaimTypeList
           this.updateClaimTypeSelectedList(claimTypeCode);

           //clear Claim Type details object
           this.resetClaimTypeDetails(claimTypeCode, transactionInfo.getPolicyType());

           //clear Claim Type documents object
           this.resetClaimTypeDetailDocuments(claimTypeCode);

    }

    updateClaimTypeSelectedList(claimTypeCode: string) {
        let selectedClaimTypeList = this.claimService.getClaim().getSelectedClaimTypesList();
        this.claimService.getClaim().setSelectedClaimTypesList(
            selectedClaimTypeList.filter(claimType => claimType !== claimTypeCode));
    }

    resetClaimTypeDetails(claimTypeCode: string, policyType: string) {
        // console.log("resetClaimTypeDetails(): claimTypeCode:: " + claimTypeCode);
        if(policyType == "travel") {
            let claim = this.claimService.getClaim();
            let claimTravel = claim.getClaimTravel();
            switch(claimTypeCode) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]:
                    let travelDelay: TravelDelay = new TravelDelay();
                    travelDelay.setDelayedDepartureOrArrival("delayedDeparture");
                    claimTravel.setTravelDelay(travelDelay);
                    this.updateClaimantListForClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL], claim);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]:
                    let lostPersonalItem: LostPersonalItem = new LostPersonalItem();
                    claimTravel.setLostPersonalItem(lostPersonalItem);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]:
                    let damagePersonalItem: DamagePersonalItem = new DamagePersonalItem();
                    claimTravel.setDamagePersonalItem(damagePersonalItem);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]:
                    let baggageDelay: BaggageDelay = new BaggageDelay();
                    claimTravel.setBaggageDelay(baggageDelay);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT]:
                    let visitedDoctor : VisitedDoctor = new VisitedDoctor();
                    claimTravel.setVisitedDoctor(visitedDoctor);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]:
                    let stayHospital: StayInHospital = new StayInHospital();
                    stayHospital.setWasStayInIntensiveCare(null);
                    claimTravel.setStayInHospital(stayHospital);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]:
                    let tripCancellation: TripCancellation = new TripCancellation();
                    claimTravel.setTripCancellation(tripCancellation);
                    this.updateClaimantListForClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN], claim);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]:
                    let tripCurtailment: TripCurtailment = new TripCurtailment();
                    claimTravel.setTripCurtailment(tripCurtailment);
                    this.updateClaimantListForClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL], claim);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]:
                    let rentalVehicle: RentalVehicle = new RentalVehicle();
                    claimTravel.setRentalVehicle(rentalVehicle);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT]:
                    let travelReroute: TravelReRoute = new TravelReRoute();
                    claimTravel.setTravelReRoute(travelReroute);
                    this.updateClaimantListForClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT], claim);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT]:
                    let missEvent: MissedEvent = new MissedEvent();
                    missEvent.setIsCreditCardPayment(null);
                    claimTravel.setMissedEvent(missEvent);
                    this.updateClaimantListForClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT], claim);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]:
                    let missConnection: MissConnection = new MissConnection();
                    claimTravel.setMissConnection(missConnection);
                    this.updateClaimantListForClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC], claim);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]:
                    let deathAndSeriousBodyInjury: DeathAndSeriousBodyInjuryLoss = new DeathAndSeriousBodyInjuryLoss();
                    deathAndSeriousBodyInjury.setWasStayInIntensiveCare(null);
                    claimTravel.setDeathAndSeriousBodyInjuryLoss(deathAndSeriousBodyInjury);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]:
                    let personalLiability: PersonalLiability = new PersonalLiability();
                    claimTravel.setPersonalLiability(personalLiability);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]:
                    let otherClaims: OtherClaim = new OtherClaim();
                    claimTravel.setOtherClaim(otherClaims);
                    break;
                default:
            }

        } else if (policyType == "domesticHelper") {
            let claimDomesticHelper = this.claimService.getClaim().getClaimDomesticHelper();
            switch(claimTypeCode) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]:
                    let clinicalExpenses: ClinicalExpenses = new ClinicalExpenses();
                    claimDomesticHelper.setClinicalExpenses(clinicalExpenses);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]:
                    let dentalExpenses: DentalExpenses = new DentalExpenses();
                    claimDomesticHelper.setDentalExpenses(dentalExpenses);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL]:
                    let hospitalization: HospitalizationHelper = new HospitalizationHelper();
                    claimDomesticHelper.setHospitalizationHelper(hospitalization);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH]:
                    let deathDisability: DeathAndPermanentDisability = new DeathAndPermanentDisability()
                    claimDomesticHelper.setDeathAndPermanentDisability(deathDisability);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY]:
                    let helpersLiability: HelpersLiability = new HelpersLiability();
                    claimDomesticHelper.setHelpersLiability(helpersLiability);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]:
                    let employeeCompensation: EmployeeCompensation = new EmployeeCompensation();
                    claimDomesticHelper.setEmployeeCompensation(employeeCompensation)
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY]:
                    let dishonestyHelper: DishonestyHelper = new DishonestyHelper();
                    claimDomesticHelper.setDishonestyHelper(dishonestyHelper);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]:
                    let repatration: Repatriation = new Repatriation();
                    claimDomesticHelper.setRepatriation(repatration);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS]:
                    let dmhOthers: OtherClaimDMH = new OtherClaimDMH();
                    claimDomesticHelper.setOtherClaimDMH(dmhOthers);
                    break;
                default:
            }
        } else if (policyType == "motor") {
            let claimMotor = this.claimService.getClaim().getClaimMotor();
            switch(claimTypeCode) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]:
                    let motorWindscreen = claimMotor.getWindscreen();
                    if (!UtilitiesService.isNullOrUndefined(motorWindscreen) && motorWindscreen.getDetailsOfDamagedItem().length > 0) {
                        let motorPartList = this.motorHelperService.getMotorParts();
                        for (var i = 0; i < motorPartList.length; i++) {
                            if (motorPartList[i]['selected'] == true) {
                                motorPartList[i]['selected'] = false;
                            }
                        }
                    }

                    let windscreen: Windscreen = new Windscreen();
                    claimMotor.setWindscreen(windscreen);
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]:
                    let collision: MotorAccidentCollission = new MotorAccidentCollission();
                    claimMotor.setCollission(collision);
                    claimMotor.setPoliceReport(new PoliceReport());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]:
                    let theft: Theft = new Theft();
                    claimMotor.setTheft(theft);
                    break;
                default:
            }
        } else if (policyType == "home") {
            let home = this.claimService.getClaim().getClaimHome();
            let others: Others = home.getHomeDetails().getOthers();
            home.setHomeDetails(new HomeDetails());

            if (claimTypeCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST] || 
                claimTypeCode === ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]) {
                    home.getHomeDetails().setOthers(others);
            }
        } else if (policyType == "accident") {
            let pa = this.claimService.getClaim().getClaimPersonalAccident();
            switch(claimTypeCode) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]:
                    let accidentalMedicalExpenses = new AccidentalMedicalExpenses();
                    accidentalMedicalExpenses.setSumInsured(pa.getAccidentalMedicalExpenses().getSumInsured());
                    pa.setAccidentalMedicalExpenses(new AccidentalMedicalExpenses());
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]:
                    let hospitalCash = new HospitalCash();
                    hospitalCash.setSumInsured(pa.getHospitalCash().getSumInsured());
                    pa.setHospitalCash(hospitalCash);
                    break;
                default:
                    pa.setOthers(new Others());
                    break;
            }
        } else if (policyType == 'ec') {
            if ([ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2], ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2A],
                ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B]].indexOf(claimTypeCode) !== -1) {
                let claim = this.claimService.getClaim();
                claim.setClaimEmployeeCompensation(new ClaimEmployeeCompensation());
                claim.getClaimEmployeeCompensation().setCurrency(UtilitiesService.getCountryCurrency(claim.getCountry()));
            }
        }
    }

    resetClaimTypeDetailDocuments(claimTypeCode: string) {
        const self = this;
        //console.log("resetClaimTypeDetailDocuments(): claimTypeCode:: " + claimTypeCode);
        //let claimModel: Claim = this.claimService.getClaim();
        let claimDocuments = this.claimService.getClaim().getDocumentForm();
        let documentDetails: DocumentDetails[] = [];
        let removedDocuments: DocumentDetails[] = [];
        if(claimDocuments) {
            documentDetails = claimDocuments.documents;
            documentDetails.forEach(function (docDetail, index) {
                if(docDetail.docClaimType == claimTypeCode) {
                    self.documentHelperService.removeDocument(docDetail.docID, self.transactionInfoService, self.http, docDetail.hasAutoSaveId);
//                        this.documentDetails = documentDetails.splice(index, 1);
                }
            });
            this.claimService.getClaim().getDocumentForm().documents = documentDetails.filter(doc => doc.docClaimType != claimTypeCode);
            this.documentHelperService.documentFormBuilders = this.documentHelperService.documentFormBuilders.filter(doc => doc.documentFormClaimType != claimTypeCode);
            console.log("List of docs after purge");
            console.log(this.claimService.getClaim().getDocumentForm().documents);
        }
        //console.log("resetClaimTypeDetailDocuments() : RESET CLAIM TYPE DOCUMENTS: " );
    }

    isAllowedDomesticClaimDetailsGroup(transaction: number, claim: Claim): boolean {

        let claimTypeSelectedList = claim.getSelectedClaimTypesList();
        //check if claim types selected of the same group have the same date of incident/visit
        if (claimTypeSelectedList.length > 1) {
            // check dental expense and clinical expenses date of visit
            if (transaction == 0 || transaction == 1 && (claimTypeSelectedList.indexOf('CLAIM_TYPE_DMH_CLINICAL') !== -1 &&
                claimTypeSelectedList.indexOf('CLAIM_TYPE_DMH_DENTAL') !== -1)) {
                let dentalExpense = claim.getClaimDomesticHelper().getDentalExpenses();
                let clinicalExpense = claim.getClaimDomesticHelper().getClinicalExpenses();
                if (!UtilitiesService.isNullOrUndefined(dentalExpense) && !UtilitiesService.isNullOrUndefined(clinicalExpense) &&
                    !UtilitiesService.isNullOrUndefined(clinicalExpense.getDateOfVisit()) && !UtilitiesService.isNullOrUndefined(dentalExpense.getDateOfVisit()) &&
                    clinicalExpense.getDateOfVisit().getTime() != dentalExpense.getDateOfVisit().getTime()) {
                    return false;
                }

            }
            // check hospitalization, repartriation, not sure date of visit
            if (transaction == 0 || transaction == 2) {
                let hasHopitalization: boolean = false;
                let hasRepatration: boolean = false;
                let hasOthers: boolean = false;
                let hospitalizationHelper = new HospitalizationHelper();
                let repatriation = new Repatriation();
                let oherClaim = new OtherClaimDMH();
                let claimTypesCount: number = 0;

                if (claimTypeSelectedList.indexOf('CLAIM_TYPE_DMH_HOSPITAL') !== -1) {
                    hasHopitalization = true;
                    hospitalizationHelper = claim.getClaimDomesticHelper().getHospitalizationHelper();
                    claimTypesCount++;
                }
                if (claimTypeSelectedList.indexOf('CLAIM_TYPE_DMH_REPATRIATION') !== -1) {
                    hasRepatration = true;
                    repatriation = claim.getClaimDomesticHelper().getRepatriation();
                    claimTypesCount++;
                }
                if (claimTypeSelectedList.indexOf('CLAIM_TYPE_DMH_OTHERS') !== -1) {
                    hasOthers = true;
                    oherClaim = claim.getClaimDomesticHelper().getOtherClaimDMH();
                    claimTypesCount++;
                }

                if (claimTypesCount > 1 &&
                    (hasHopitalization  && !UtilitiesService.isNullOrUndefined(hospitalizationHelper.getDateOfAdmission()) && hasRepatration && !UtilitiesService.isNullOrUndefined(repatriation.getDateOfRepatIncident()) && hospitalizationHelper.getDateOfAdmission().getTime() !=  repatriation.getDateOfRepatIncident().getTime()) ||
                    (hasHopitalization  && !UtilitiesService.isNullOrUndefined(hospitalizationHelper.getDateOfAdmission()) && hasOthers && !UtilitiesService.isNullOrUndefined(oherClaim.getDateOfIncident()) && hospitalizationHelper.getDateOfAdmission().getTime() !=  oherClaim.getDateOfIncident().getTime()) ||
                    (hasRepatration  && !UtilitiesService.isNullOrUndefined(repatriation.getDateOfRepatIncident()) && hasOthers && !UtilitiesService.isNullOrUndefined(oherClaim.getDateOfIncident()) && repatriation.getDateOfRepatIncident().getTime() !=  oherClaim.getDateOfIncident().getTime())) {
                    return false;
                }
            }
        }

        return true;
    }

    isMotorValidForClaim(claim: Claim) : boolean {
        let claimTypeSelectedList = claim.getSelectedClaimTypesList();
        let claimMotor = claim.getClaimMotor();
        if (claimTypeSelectedList.length > 0 && claimTypeSelectedList.indexOf('CLAIM_TYPE_MVA_COLLISION') !== -1) {
         if (!UtilitiesService.isNullOrUndefined(claimMotor.getCollission()) && !claimMotor.getCollission().getWillClaimRepairCost() &&
             !claimMotor.getCollission().getHasAccidentInvolve()) {
             return false;
           }
        }
         return true;
    }

    updateClaimantListForClaimType(claimTypeCode: string, claim: Claim) {

        let claimantList = claim.getClaimantList();

        if (claimantList.length > 0) {

            for (var i = claimantList.length - 1; i >= 0; i--)  {
                let claimantClaimTypes: string[] = claimantList[i].getSelectedClaimTypesList();
                for (var j = claimantClaimTypes.length - 1; j >= 0; j--)  {
                    if (claimantClaimTypes[j] == claimTypeCode) {
                        if (claimantClaimTypes.length > 1){
                            claimantClaimTypes.splice(j, 1);
                        } else if (claimantClaimTypes.length == 1) {
                            claimantList.splice(i, 1);
                        }
                    }
                }
            }
        }

    }

}
