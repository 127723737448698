export const environment = {
  production: true,
    gaID: {
        travel: 'GTM-57FMRXB',
        domesticHelper: 'GTM-KD6VR4K',
        motor: 'GTM-WTB2SDS',
        home: 'GTM-W8SQQQF',
        accident: 'GTM-ML8D5WH',
        generic: 'GTM-KZBQ843',
        claimStatus: 'GTM-WS337FC'
    },
    faq: {
        accident_hkg_en: 'https://www.qbe.com/hk/en/eclaims-faqs',
        accident_hkg_zh: 'https://www.qbe.com/hk/zh-hk/eclaims-faqs',
        domestichelper_hkg_en: 'https://qbe.com/hk/en/helper-claims-faqs',
        domestichelper_hkg_zh: 'https://qbe.com/hk/zh-hk/helper-claims-faqs',
        ec_hkg_en: 'https://www.qbe.com/hk/en/eclaims-faqs',
        ec_hkg_zh: 'https://www.qbe.com/hk/zh-hk/eclaims-faqs',
        generic_hkg_en: 'https://www.qbe.com/hk/en/eclaims-faqs',
        generic_hkg_zh: 'https://www.qbe.com/hk/zh-hk/eclaims-faqs',
        generic_sgp_en: 'https://www.qbe.com/sg/eclaims-faqs',
        generic_mys_en: 'https://www.qbe.com/my/eclaims-faqs',
        home_hkg_en: 'https://qbe.com/hk/en/home-claims-faqs',
        home_hkg_zh: 'https://qbe.com/hk/zh-hk/home-claims-faqs',
        motor_hkg_en: 'https://qbe.com/hk/en/motor-claims-faqs',
        motor_hkg_zh: 'https://qbe.com/hk/zh-hk/motor-claims-faqs',
        travel_hkg_en: 'https://www.qbe.com/hk/en/claims-faqs',
        travel_hkg_zh: 'https://www.qbe.com/hk/zh-hk/claims-faqs'
    },
    videoId: {
        hkg_en: 'lcPReB6M5i4',
        hkg_zh: '6t8R-Ug-Au4',
        hkg_en_motor: 'M--yuG5J4j4',
        hkg_zh_motor: 'km_S5DEQs5A',
        sgp_en: '32wUZ_vu1pM'
    },
    eClaims: {
        hkg: 'https://claims.qbe.com/hk',
        sgp: 'https://claims.qbe.com/sg',
        mys: 'https://claims.qbe.com/my'
    }
};
