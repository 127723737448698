import {NotificationMessage} from './notification-message.model';
import {JsonProperty} from '../../utils/json-property.decorator';

export class Notifications {
  @JsonProperty({name: 'messages', clazz: NotificationMessage})
  messages: NotificationMessage[] = [];
  showPreamble: boolean = true;

  get errorMessages(): NotificationMessage[] {
    return this.getMessages('E');
  }

  get fileErrorMessages(): NotificationMessage[] {
    return this.getMessages('FE');
  }

  get warningMessages(): NotificationMessage[] {
    return this.getMessages('W');
  }

  get referralMessages(): NotificationMessage[] {
    return this.getMessages('R');
  }

  get infoMessages(): NotificationMessage[] {
    return this.getMessages('I');
  }

  get declineMessages(): NotificationMessage[] {
    return this.getMessages('D');
  }

  private getMessages(errorType: string): NotificationMessage[] {
    return this.messages ? this.messages.filter(message => message.type === errorType) : null;
  }

  public isMessageExisting(notificationMessage: NotificationMessage): boolean {
    return this.messages.filter(message =>
        message.code === notificationMessage.code &&
        message.message.toLowerCase() === notificationMessage.message.toLowerCase()
      ).length > 0;
  };

  public addMessage(notificationMessage: NotificationMessage) {
    this.messages.push(notificationMessage);
  }

  public addErrorMessage(errorMessage: string) {
    const message: NotificationMessage = new NotificationMessage();
    message.code = '';
    message.type = 'E'
    message.message = errorMessage;

    if (!this.messages.some(m => m.message.toLowerCase() === message.message.toLowerCase())) {
      this.messages.push(message);
    }
  }

  public clearMessages() {
    this.messages = [];
  }

  public hasErrorMessages(): boolean {
    return this.getMessages('E').length > 0;
  };

  public clearErrorMessages() {
    this.messages = this.messages.filter(message => message.type !== 'E');
  };

  public addFileErrorMessage(errorMessage: string, fileName: string, fileErrType: string, docTypeId: string) {
    const message: NotificationMessage = new NotificationMessage();
    message.code = '';
    message.type = 'FE';
    message.fileErrorType = fileErrType;
    message.fileName = fileName;
    message.message = errorMessage;
    message.docTypeId = docTypeId;

    this.messages.push(message);
    this.messages.sort((a,b) => a.fileErrorType.localeCompare(b.fileErrorType) || a.fileName.localeCompare(b.fileName));
  }

  public removeFileErrorMessage(docTypeId: string) {
    this.messages = this.messages.filter(message => message.docTypeId !== docTypeId);
  }
}
