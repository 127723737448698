import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travel-support-doc',
  templateUrl: './travel-support-doc.component.html',
  styleUrls: ['./travel-support-doc.component.css']
})
export class TravelSupportDocComponent implements OnInit {
  public showAddDoc = false;
  constructor() { }

  ngOnInit() {
  }

}
