<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
      <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
      <p>{{ 'motorClaim.driverDetails.driverInformation.heading' | translate }}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
        <form>
            <div class="form-group">
                <qnect-ui #MV_DD_DRIVER_TYPE [fieldId]="'MV_DD_DRIVER_TYPE'" [formGroup]="driverDetailsForm" name="driverType" [dynamicOptions]="driverOptions"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="collission.getDriverType()==='Others'">
              <div class="form-group">
                <qnect-ui #MV_DD_DRIVER_NAME [fieldId]="'MV_DD_DRIVER_NAME'" [formGroup]="driverDetailsForm" name="driverName"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #MV_DRIVER_AGE [fieldId]="'MV_DRIVER_AGE'" [formGroup]="driverDetailsForm" name="driverAge"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #MV_DD_CONTACT_NUMBER [fieldId]="'MV_DD_CONTACT_NUMBER'" [formGroup]="driverDetailsForm" name="contactNumber"></qnect-ui>
              </div>
              <div class="form-group">
                <qnect-ui #MV_DD_CONTACT_EMAIL [fieldId]="'MV_DD_CONTACT_EMAIL'" [formGroup]="driverDetailsForm" name="contactEmail"></qnect-ui>
              </div>
            </div>
            <div class="form-group">
              <qnect-ui #MV_COLLISSION_HAVING_DRIVERS_LICENSE [fieldId]="'MV_COLLISSION_HAVING_DRIVERS_LICENSE'" [formGroup]="driverDetailsForm" name="hasDriverHadValidLicense"></qnect-ui>
            </div>
            <ng-container  *ngIf="collission.getHasDriverHadValidLicense()">
            <div class="form-group">
              <qnect-ui #MV_COLLISSION_FIRST_LICENSE [fieldId]="'MV_COLLISSION_FIRST_LICENSE'" [formGroup]="driverDetailsForm" name="firstLicense"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="collission.getFirstLicense()?.toLowerCase() == 'less than 3 years'">
              <qnect-ui #MV_COLLISSION_LICENSE_ISSUE_DATE [fieldId]="'MV_COLLISSION_LICENSE_ISSUE_DATE'" [formGroup]="driverDetailsForm" name="licenseIssueDate"></qnect-ui>
            </div>
           </ng-container>
            <div class="form-group">
              <qnect-ui #MV_COLLISSION_IS_DRIVER_DRUNK [fieldId]="'MV_COLLISSION_IS_DRIVER_DRUNK'" [formGroup]="driverDetailsForm" name="wasDriverDrunk"></qnect-ui>
            </div>

            <div class="form-group" *ngIf="collission.getWasDriverDrunk()">
              <div class="form-group">
                <qnect-ui #MV_COLLISSION_BREATH_TEST [fieldId]="'MV_COLLISSION_BREATH_TEST'" [formGroup]="driverDetailsForm" name="wasBreathTest"></qnect-ui>
              </div>
              <div class="form-group" *ngIf="collission.getWasBreathTest()">
                <qnect-ui #MV_COLLISSION_BREATH_TEST_RESULT [fieldId]="'MV_COLLISSION_BREATH_TEST_RESULT'" [formGroup]="driverDetailsForm" name="breathTestResult"></qnect-ui>
              </div>
            </div>

            <div class="form-group">
              <qnect-ui #MV_COLLISSION_WHO_CAUSE_THE_ACCIDENT [fieldId]="'MV_COLLISSION_WHO_CAUSE_THE_ACCIDENT'" [formGroup]="driverDetailsForm" name="whoCauseTheAccident"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="collission.getWhoCauseTheAccident() === 'Driver of the other Vehicle'">
              <div class="form-group">
                  <qnect-ui #MV_COLLISSION_OTHER_DRIVER_NAME [fieldId]="'MV_COLLISSION_OTHER_DRIVER_NAME'" [formGroup]="driverDetailsForm" name="otherDriverName"></qnect-ui>
              </div>
              <div class="form-group">
                  <qnect-ui #MV_COLLISSION_OTHER_DRIVER_CONTACT_NUMBER [fieldId]="'MV_COLLISSION_OTHER_DRIVER_CONTACT_NUMBER'" [formGroup]="driverDetailsForm" name="otherDriverContactNo"></qnect-ui>
              </div>
            </div>

        </form>
    </div>
    <div class="col-xl-3 col-lg-12">
    </div>
  </div>

<div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
</div>
<app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>