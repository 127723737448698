import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {AbstractControl, Validators} from "@angular/forms";
import {UiValidators} from "../validators/ui-validator";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { UiOptionsModel } from "../ui-options.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'qnect-ui-dropdowntextfield',
  templateUrl: './ui-dropdown-textfield.component.html',
  styleUrls: ['../ui-base.component.scss', './ui-dropdown-textfield.component.scss']
})
export class UiDropdownTextfieldComponent extends UiBaseComponent implements OnInit {

  @ViewChild('inputField', {static: true}) inputField: ElementRef;

  dropdownFormControl: AbstractControl;
  dropdownSelectedText: string = '';
  amountFieldSize: string = '12';

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
      if (this.uiModel.dropdownFieldName) {
          this.dropdownFormControl = this.formGroup.get(this.uiModel.dropdownFieldName);

          let validatorList = [];
          if (this.uiModel.required) validatorList.push(Validators.required);
          if (this.uiModel.maxlength) validatorList.push(Validators.maxLength(this.uiModel.maxlength));
          if (this.uiModel.validationRegex) validatorList.push(Validators.pattern(this.uiModel.validationRegex));
          this.formControl.setValidators(validatorList);

          this.setDropdownSelectedText();
          this.dropdownFormControl.valueChanges.subscribe(data => {
              this.setDropdownSelectedText();
          });
      }
  }

  getDivRemainder() : number {
      return 12-parseInt (this.amountFieldSize)-4;
  }

  setDropdownSelectedText() {
    this.uiModel.options.forEach(each => {
        if (this.dropdownFormControl.value === each.value) {
          if (this.uiModel.optionDisplayLabel) {
            this.dropdownSelectedText = this.translateService.instant(each.label);
          } else {
            this.dropdownSelectedText = each.value;
          }
          // break loop
          return false;
        }
    });
  }

  dropdownFieldChangeHandler(option: UiOptionsModel) {
    if (!UtilitiesService.isNullOrUndefined(option.value)) {
      if (this.uiModel.optionDisplayLabel) {
        this.dropdownSelectedText = this.translateService.instant(option.label);
      } else {
        this.dropdownSelectedText = option.value;
      }
      this.dropdownFormControl.setValue(option.value);
    }
  }

  disabledPasteEvent(event: ClipboardEvent) {
    event.preventDefault();
  }

}
