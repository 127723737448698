<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.stayedInHospital.detailsOfIllness.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group" *ngIf="!claim.getClaimTravel().getGBAPlanType()">
          <qnect-ui #HOS24 [fieldId]="'HOS24'" [formGroup]="deTailOfIllnessForm" name="countryOfTheLossOccurred"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="claim.getClaimTravel().getGBAPlanType()">
          <qnect-ui #GBA_PLACE_OF_LOSS [fieldId]="'GBA_PLACE_OF_LOSS'" [formGroup]="deTailOfIllnessForm" name="placeOfLoss"
                    [dynamicLabel] ="'claimSection.stayedInHospital.detailsOfIllness.whereIllnessFirstOccurred'"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #HOS12 [fieldId]="'HOS12'" [formGroup]="deTailOfIllnessForm" name="dateTheIllnessOccurred"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #HOS13 [fieldId]="'HOS13'" [formGroup]="deTailOfIllnessForm" name="natureOfIllness" (uiComponentCreated)="uiComponentCreated($event)"></qnect-ui>
        </div>
        <ng-container *ngIf="stayInHospital.getNatureOfIllness() == 'Sickness'">
          <div class="form-group">
            <qnect-ui #HOS14 [fieldId]="'HOS14'" [formGroup]="deTailOfIllnessForm" name="diagnoseType" (uiComponentCreated)="uiComponentCreated($event)"></qnect-ui>
          </div>
          <div *ngIf="stayInHospital.getDiagnoseType() == 'Other'" class="form-group">
              <qnect-ui #HOS15 [fieldId]="'HOS15'" [formGroup]="deTailOfIllnessForm" name="otherDiagnose"></qnect-ui>    
          </div>
        </ng-container>
        <ng-container *ngIf="stayInHospital.getNatureOfIllness() == 'Injury'">
          <div class="form-group">
            <qnect-ui #HOS16 [fieldId]="'HOS16'" [formGroup]="deTailOfIllnessForm" name="wasMugging"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>