import {Component, OnInit} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {UiOptionsModel} from "../ui-options.model";

@Component({
  selector: 'qnect-ui-dropdown-select',
  templateUrl: './ui-dropdown-select.component.html',
  styleUrls: ['./ui-dropdown-select.component.scss', '../ui-base.component.scss']
})
export class UiDropdownSelectComponent extends UiBaseComponent implements OnInit {

  selectedText: string;
  options: UiOptionsModel[] = [];

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.options = this.uiModel.options;

    // IE Fix, add empty option
    /*if (UtilitiesService.detectIE() !== false && this.options && this.options.filter(x => x.value === '').length < 1) {
      this.options.unshift({label: '', value: ''});
    }*/

    this.setSelectedText();
    this.formControl.valueChanges.subscribe(data => {
      this.setSelectedText();
    });

    if (!this.uiModel.placeholder) {
      this.uiModel.placeholder = 'Please Select';
    }
  }

  loadDropDownOptions(options: UiOptionsModel[]) {
    console.log('UiDropdownSelectComponent loadDropDownOptions called ! : ' + options.length);
    this.uiModel.options = options;
  }

  setSelectedText() {
    this.uiModel.options.forEach(each => {
    if (this.formControl.value === each.value) {
        this.selectedText = each.label;
        // break loop
        return false;
      }
    });
  }

  getSelectedTextDropDownSelect() {
    return this.selectedText;
  }
}
