import {Component, ElementRef, Injector, OnInit, ViewChild} from "@angular/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {BaggageDelay} from "src/app/model/travel/baggage-delay.model";
import {DateService} from "src/app/ui/ui-datepicker/date.service";
import {BaggageDelayItemDetail} from "../../../../model/travel/baggage-delay-item.model";
import {takeUntil} from "rxjs";
import {UtilitiesService} from "../../../../utilities/utilities.service";

@Component({
    selector: 'app-emergency-purchased-item',
    templateUrl: './emergency-purchased-item.component.html',
    styleUrls: ['./emergency-purchased-item.component.css']
})
export class EmergencyPurchasedItemComponent extends ClaimsBaseComponent implements OnInit {

    emergencyPurchaseForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    emergencyPurchasedItemsArray: BaggageDelayItemDetail[] = [];
    defaultCurrency: string;

    constructor(private fb: FormBuilder, 
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private dateService: DateService,
                private injector : Injector) {
        super(injector);
        this.claim = claimService.getClaim();
        this.defaultCurrency = UtilitiesService.getCountryCurrency(this.claim.getCountry());
    }

    ngOnInit() {
        this.pushGAViewPurchaseItem();
        this.emergencyPurchasedItemsArray = this.claim.getClaimTravel().getBaggageDelay().getEmergencyPurchasedItemsArray();
        this.emergencyPurchaseForm = this.fb.group({
            items: this.fb.array(this.buildStoredItems(this.emergencyPurchasedItemsArray))
        });
    }

    ngAfterViewInit() {

        let baggageDelay: BaggageDelay = this.claim.getClaimTravel().getBaggageDelay();
        this.emergencyPurchaseForm.valueChanges.subscribe(data => {

            this.emergencyPurchasedItemsArray = [];

            for (let i = 0; i < data.items.length; i++) {

                let itemDetail : BaggageDelayItemDetail = new BaggageDelayItemDetail();

                itemDetail.setEmergencyPurchaseType(data.items[i].emergencyPurchaseType);
                itemDetail.setEmergencyPurchaseTypeDesc(super.getIndexedTextInComponent("emergencyPurchaseType", i));
                itemDetail.setEmergencyPurchaseTypeDetail(data.items[i].emergencyPurchaseTypeDetail);
                itemDetail.setPurchaseDate(super.getIndexedValueInComponent("purchaseDate", i));
                itemDetail.setPurchasePriceType(data.items[i].purchasePriceType);
                itemDetail.setPurchasePrice(data.items[i].purchasePrice);

                this.emergencyPurchasedItemsArray.push(itemDetail);

            }

            baggageDelay.setEmergencyPurchasedItemsArray(this.emergencyPurchasedItemsArray);

            setTimeout(() => {
                this.validateClaimDetailsComplete();
            }, 200);


        });

        // Stop validation of fields when not required.
        let formArray : FormArray =  <FormArray> this.emergencyPurchaseForm.controls["items"];
        for (let i = 0; i < formArray.controls.length; i++) {
             formArray.controls[i].get('emergencyPurchaseType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.emergencyPurchaseTypeChanged(data, formArray.controls[i]);
             });
        }

    }

    emergencyPurchaseTypeChanged(lossType, formGroup) {

        formGroup.get('purchaseDate').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePriceType').disable({onlySelf: false, emitEvent: false});
        formGroup.get('purchasePrice').disable({onlySelf: false, emitEvent: false});
        formGroup.get('emergencyPurchaseTypeDetail').disable({onlySelf: false, emitEvent: false});

        if (lossType == 'Others') {
            formGroup.get('emergencyPurchaseTypeDetail').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        }
        else {
            formGroup.get('purchaseDate').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePriceType').enable({onlySelf: false, emitEvent: false});
            formGroup.get('purchasePrice').enable({onlySelf: false, emitEvent: false});
        }
    }

    hideValidation(data) {
        if (data == 'Others') {
            this.emergencyPurchaseForm.get('otherReason').enable({onlySelf: false, emitEvent: false});
        } else {
            this.emergencyPurchaseForm.get('otherReason').disable({onlySelf: false, emitEvent: false});
        }
    }

    buildStoredItems(emergencyPurchasedItemsArray: BaggageDelayItemDetail[]): FormGroup[] {

        let formGroupArray : FormGroup[] = [];

        if(emergencyPurchasedItemsArray != null && emergencyPurchasedItemsArray.length > 0 ) {

            for (let i = 0; i < emergencyPurchasedItemsArray.length; i++) {

                let rowFormGroup = this.fb.group({
                    emergencyPurchaseType: [emergencyPurchasedItemsArray[i].getEmergencyPurchaseType()],
                    emergencyPurchaseTypeDetail: [emergencyPurchasedItemsArray[i].getEmergencyPurchaseTypeDetail()],
                    purchaseDate: [emergencyPurchasedItemsArray[i].getPurchaseDate()],
                    purchasePriceType: [emergencyPurchasedItemsArray[i].getPurchasePriceType()],
                    purchasePrice: [emergencyPurchasedItemsArray[i].getPurchasePrice()],
                });

                formGroupArray.push(rowFormGroup);
            }
        }
        else {
            formGroupArray.push(this.createDefaultItems());
        }

        return formGroupArray;
    }


    createDefaultItems(): FormGroup {
        return this.fb.group({
            emergencyPurchaseType: null,
            emergencyPurchaseTypeDetail: '',
            purchaseDate: null,
            purchasePriceType: this.defaultCurrency,
            purchasePrice: '',
            otherTypeBriefDesc: ''
        });

    }

    addItem(): void {
        let items = this.emergencyPurchaseForm.get('items') as FormArray;
        items.push(this.createDefaultItems());
    }

    removeItem(i) {
        let items = this.emergencyPurchaseForm.get('items') as FormArray;
        items.removeAt(i);
        this.emergencyPurchasedItemsArray.splice(i, 1);
    }

    save(): boolean {
        // if (!form.valid) {
        //   return false;
        // }
        this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 1});
        this.sideMenuService.emitProcess(1, 0);
        return true;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 1});

            this.router.navigate(["/claimform/baggageDelay/baggageDelaySupportDoc/uploadDocument"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/baggageDelay/detailsOfBaggageDelay"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.emergencyPurchaseForm);
    }

    validateMoneyFields(): boolean {

        let data = this.emergencyPurchasedItemsArray;
        for (let i = 0; i < data.length; i++) {
                let formArray : FormArray =  <FormArray> this.emergencyPurchaseForm.controls["items"];
                for (let i = 0; i < formArray.controls.length; i++) {                    

                    if (formArray.controls[i].get('purchasePrice').value == '' 
                    || formArray.controls[i].get('purchasePrice').value == null) { 
                        return true;
 
                    }
                }

        }
        return false;
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.emergencyPurchaseForm);
        let isMoneyFieldsPopulated : boolean = this.validateMoneyFields();
        if(isFormValidOnChange && !isMoneyFieldsPopulated){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 3, subMenuIndex: 1});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 3, subMenuIndex: 1});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewPurchaseItem() {
        (<any>window).dataLayer.push({
            'pageStep': 'Baggage Delay – Emergency Item',
            'vPath': '/claim/baggage-delay/emergency-items',
            'event': 'vpageview',
        });
    }

}
