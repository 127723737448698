import { Injectable, Optional } from "@angular/core";
import { Options } from './options';
import { Level } from './level';
import { HttpClient, HttpRequest, HttpHeaders } from "@angular/common/http";

const CONSOLE_DEBUG_METHOD = console["debug"] ? "debug" : "log";

const DEFAULT_OPTIONS: Options = {
	level: Level.WARN,
	restUrl: null
};

@Injectable()
export class Logger {
	private _level: Level;
	private _restUrl: string;

	constructor(private _http: HttpClient, @Optional() options?: Options) {

		let { level, restUrl } = Object.assign({}, DEFAULT_OPTIONS, options);

		this._level = level;
		this._restUrl = restUrl;

	}

	error(message?: any, ...optionalParams: any[]) {
		if (this.isErrorEnabled()) {
			console.error.apply(console, arguments);
			this.sendLog("ERROR", message, optionalParams);
		}
	}

	warn(message?: any, ...optionalParams: any[]) {
		if (this.isWarnEnabled()) {
			console.warn.apply(console, arguments);
			this.sendLog("WARN", message, optionalParams);
		}
	}

	info(message?: any, ...optionalParams: any[]) {
		if (this.isInfoEnabled()) {
			console.info.apply(console, arguments);
			this.sendLog("INFO", message, optionalParams);
		}
	}

	debug(message?: any, ...optionalParams: any[]) {
		if (this.isDebugEnabled()) {
			(<any>console)[CONSOLE_DEBUG_METHOD].apply(console, arguments);
			this.sendLog("DEBUG",message, optionalParams);
		}
	}

	log(message?: any, ...optionalParams: any[]) {
		if (this.isLogEnabled()) {
			console.log.apply(console, arguments);
			this.sendLog("LOG", message, optionalParams);
		}
	}

	isErrorEnabled = (): boolean => this.level >= Level.ERROR;
	isWarnEnabled = (): boolean => this.level >= Level.WARN;
	isInfoEnabled = (): boolean => this.level >= Level.INFO;
	isDebugEnabled = (): boolean => this.level >= Level.DEBUG;
	isLogEnabled = (): boolean => this.level >= Level.LOG;

	get level(): Level { return this._level; }

	set level(level: Level) {
		this._level = level;
	}

	sendLog(type: string, message?: any, ...optionalParams: any[]) {
		if (this._restUrl) {
			let data = {
				type: type,
				message: message,
				optionalParams: optionalParams
			}

			let httpOptions = {
				headers: new HttpHeaders({
					'Content-Type': 'application/json'
				})
			}

			let req = new HttpRequest('POST', this._restUrl, data, httpOptions);

			this._http.request(req);

			/* let headers = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
			let options = new HttpRequest(({ headers: headers }); // Create a request option */

			//this._http.post(this._restUrl, data, options).subscribe();
		}

	}


}
