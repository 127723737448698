import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {OtherClaimDMH} from "../../../../model/domestic-helper/other-claim.model";
import {Notifications} from "../../../../utilities/components/notification-messages/notifications.model";
import {ClaimTypeItemHelperService} from "../../../../services/claim-type-item-helper.service";

@Component({
  selector: 'app-other-claim-detail',
  templateUrl: './other-claim-detail.component.html',
  styleUrls: ['./other-claim-detail.component.css']
})

export class OtherClaimDMHDetailComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    otherClaimForm: FormGroup;
    claim: Claim;
    otherClaimDMH : OtherClaimDMH;
    notifications: Notifications;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector,
                private claimTypeItemHelperService: ClaimTypeItemHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.otherClaimDMH = this.claim.getClaimDomesticHelper().getOtherClaimDMH();
    }

    ngOnInit() {

        this.pushGAViewOtherClaimDetail();

        if(this.otherClaimDMH.getCountryIncidentOccurDesc() == null || this.otherClaimDMH.getCountryIncidentOccurDesc() == ""){
            this.otherClaimDMH.setCountryIncidentOccurDesc('HONG KONG')
            this.otherClaimDMH.setCountryIncidentOccur('HKG');
        }
        if(this.otherClaimDMH.getAmountClaimCurrency() == null){
            this.otherClaimDMH.setAmountClaimCurrency('HKD')
        }

        if (!this.otherClaimDMH.getDateOfIncident()) {
            this.otherClaimDMH.setDateOfIncident(this.claim.getDateOfLoss());
        }

        this.otherClaimForm = this.fb.group({            
            dateOfIncident: [this.otherClaimDMH.getDateOfIncident()],
            countryIncidentOccur: [this.otherClaimDMH.getCountryIncidentOccur()],
            descOfIncident: [this.otherClaimDMH.getDescOfIncident()],
            amountClaimCurrency: [this.otherClaimDMH.getAmountClaimCurrency()],
            amountClaim: [this.otherClaimDMH.getAmountClaim()]
        });

        this.notifications = new Notifications();
    }

    ngAfterViewInit() {
        this.otherClaimForm.valueChanges.subscribe(data => {
            this.otherClaimDMH.setCountryIncidentOccur(this.otherClaimForm.get('countryIncidentOccur').value);
            this.otherClaimDMH.setCountryIncidentOccurDesc(this.getTextInComponent("countryIncidentOccur"));
            this.otherClaimDMH.setDateOfIncident(super.getValueInComponent('dateOfIncident'));
            this.otherClaimDMH.setDescOfIncident(super.getValueInComponent('descOfIncident'));
            this.otherClaimDMH.setAmountClaimCurrency(this.otherClaimForm.get('amountClaimCurrency').value);
            this.otherClaimDMH.setAmountClaim(super.getValueInComponent('amountClaim'));

        });

    }


    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {

        if (this.validateForm()) {
            let isDateOfVisitAllowed = this.claimTypeItemHelperService.isAllowedDomesticClaimDetailsGroup(2, this.claim);
            if (isDateOfVisitAllowed) {
                this.sideMenuService.emitProcess(1, 0);
                this.sideMenuService.emitClaimComplete({claimTypeId: 9, subMenuIndex: 0});
                this.router.navigate(["/claimform/other/supportDoc"], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.notifications = new Notifications();
                this.notifications.showPreamble = false;
                let errorMessage = "error_messages.domesticClaimGroup.notAllowedDateOfVisit";
                this.notifications.addErrorMessage(errorMessage);
            }
        }

    }

    validateForm(): boolean {
        return super.validateForm(this.otherClaimForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewOtherClaimDetail() {
        (<any>window).dataLayer.push({
            'pageStep': 'Other Claims – Incident Details',
            'vPath': '/claim/other-claims/incident-details',
            'event': 'vpageview',
            'ecommerce': {
              'checkout': {
                  'actionField': {'step': 5},	//Step5-ClaimDetailsStart
              }
            }                                                                                                                                                                      
        });
    }  


}
