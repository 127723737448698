import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {UiModel} from "../../ui.model";
import {Subject} from "rxjs";

@Component({
  selector: 'qnect-ui-label-part',
  templateUrl: './ui-label-part.component.html',
  styleUrls: ['./ui-label-part.component.scss', '../../ui-base.component.scss']
})
export class UiLabelPartComponent implements OnInit, OnDestroy {

  @Input() fieldId: string;
  @Input() uiModel: UiModel;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
