import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {ClaimsBaseComponent} from '../../claim-base.component';
import {TransactionInfoService} from '../../../services/transaction-info.service';
import {SideMenuService} from '../../../services/side-menu.service';
import {ClaimMotor} from '../../../model/claim-motor.model';
import {UiOptionsModel} from '../../../ui/ui-options.model';
import {TransactionInfo} from "../../../model/transaction-info.model";
import {MotorHelperService} from "../../../services/motor-helper.service";
import {takeUntil} from 'rxjs/operators';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

declare var $: any;

@Component({
    selector: 'app-confirm-vehicle-details',
    templateUrl: './confirm-vehicle-details.component.html',
    styleUrls: ['./confirm-vehicle-details.component.css']
})
export class ConfirmVehicleDetailsComponent extends ClaimsBaseComponent  implements OnInit {

    confirmVehicleDetailFormGroup: FormGroup;
    claimMotor: ClaimMotor;
    yearOptions: UiOptionsModel[] = [];
    isDefaultValues: boolean = true;
    transactionInfo: TransactionInfo;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private motorHelperService: MotorHelperService) {

        super(injector);
        this.claimMotor = this.claim.getClaimMotor();
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {

        this.pushGAConfirmView();

        // initialize dynamic options for year of manufacture
        let currentYear = new Date().getFullYear();
        for (var year = currentYear; year >= 1980; year--) {
            let yearStr = year.toString();
            const option = new UiOptionsModel(yearStr, yearStr);
            this.yearOptions.push(option);
        }


        this.confirmVehicleDetailFormGroup = this.fb.group({
            policyHolderName: [this.claim.getPolicyHolderName()],
            vehicleRegistrationNumber: [this.claimMotor.getVehicleNumber()],
            makeAndModel: [!UtilitiesService.isNullOrUndefined(this.claimMotor.getConfirmMakeAndModel()) ? this.claimMotor.getConfirmMakeAndModel() : this.claimMotor.getMake()],
            yearOfManufacture: [!UtilitiesService.isNullOrUndefined(this.claimMotor.getConfirmYearOfManufacture()) ? this.claimMotor.getConfirmYearOfManufacture() : this.claimMotor.getYearManufacture()],
            isPolicyholder: [super.getBooleanString(this.claim.getIsPolicyholder())],
            policyHolderEmail: [this.claim.getPolicyholderEmail()]
        });

    }

    ngAfterViewInit() {

        this.confirmVehicleDetailFormGroup.valueChanges.subscribe(data => {
           this.setConfirmVehicleInfo();
           this.isDefaultValues = false;
        });

        this.confirmVehicleDetailFormGroup.get('isPolicyholder').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideConditionalField(data);
        });
    }

    hideConditionalField(isPolicyholder) {
        if (String(isPolicyholder) === 'false') {
            this.confirmVehicleDetailFormGroup.get('policyHolderEmail').enable({onlySelf: false, emitEvent: false});
        } else {
            this.confirmVehicleDetailFormGroup.get('policyHolderEmail').reset();
            this.confirmVehicleDetailFormGroup.get('policyHolderEmail').disable({onlySelf: false, emitEvent: false});
        }
    }

    goToNext() {
        if (this.validateForm()) {

            if (this.isDefaultValues) {
                this.setConfirmVehicleInfo();
            }

            // populate claimant details depending on who lodge in
            if (this.claimMotor.getIsLodgeByPolicyHolder()) {
                let policyHolderName = !UtilitiesService.isNullOrUndefined(this.claim.getPolicyHolderName()) ? this.claim.getPolicyHolderName() : "";
                let policyHolderIdNumber = !UtilitiesService.isNullOrUndefined(this.claim.getClaimantClientKey()) ? this.claim.getClaimantClientKey() : "";
                this.claim.setClaimantName(policyHolderName);
                this.claim.setContactName(policyHolderName);
                this.claim.setClaimantIdNumber(policyHolderIdNumber);
                this.claim.setAccountNameDirect(policyHolderName);
            } else {
                let repairerName = this.claimMotor.getRepairerName();
                if (!UtilitiesService.isNullOrUndefined(repairerName)) {
                    this.claim.setClaimantName(repairerName);
                    this.claim.setContactName(repairerName);
                    this.claim.setAccountNameDirect(repairerName);
                }
                this.claim.setContactEmail("");
                this.claim.setContactNumber("");
            }

            this.sideMenuService.emitConfirmVehicleDetailsInfo('')
            this.sideMenuService.emitComplete('finishConfirmVehicleDetails');
            this.sideMenuService.emitProcess(1, 0);
            this.router.navigate(["/claimform/claimDetail"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    setConfirmVehicleInfo() {
        this.claim.setPolicyHolderName(this.confirmVehicleDetailFormGroup.get('policyHolderName').value);
        this.claimMotor.setVehicleNumber(this.confirmVehicleDetailFormGroup.get('vehicleRegistrationNumber').value);
        this.claimMotor.setConfirmMakeAndModel(this.confirmVehicleDetailFormGroup.get('makeAndModel').value);
        this.claimMotor.setConfirmYearOfManufacture(this.confirmVehicleDetailFormGroup.get('yearOfManufacture').value);
        this.claim.setIsPolicyholder(this.confirmVehicleDetailFormGroup.get('isPolicyholder').value === 'true');
        this.claim.setPolicyholderEmail(this.confirmVehicleDetailFormGroup.get('policyHolderEmail').value)
    }

    validateForm(): boolean {
        return super.validateForm(this.confirmVehicleDetailFormGroup);
    }

    pushGAConfirmView() {
        let channel: string = "HKSI";
        let country = this.claim.getCountry();

        if (country && country == "HKG" && this.claim.getIsManulife()){
            channel = "Manulife";
        } else if(country && country  == "HGI") {
            channel = "QGI";
        }

        (<any>window).dataLayer.push({
            'pageStep': 'Confirm Vehicle Details',
            'Channel': channel,                     //QGI or HKSI or Manulife
            'vPath': '/confirm-vehicle-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 3},	   //Step3-Confirm-Vehicle-Details
                }
            }
        });
    }


}
