export class DentalExpenses {

    private countryIncidentOccur: string;
    private countryIncidentOccurDesc: string;
    private natureIllness: string;
    private isIncidentReport: boolean;
    private specifyCondition: string;
    private selectTypeTreatment: string;
    private selectTypeTreatmentDesc: string;
    private dateOFVisit : Date
    private typeOfTreatmentReceived: string;
    private dentalExpenseAmount : string;
    private dentalExpenseAmountCurrency: string;
    private estimatedAmount: number;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }
    
    getSelectTypeTreatment(): string {
        return this.selectTypeTreatment;
    }

    setSelectTypeTreatment(value: string) {
        this.selectTypeTreatment = value;
    }

    getSelectTypeTreatmentDesc(): string {
        return this.selectTypeTreatmentDesc;
    }

    setSelectTypeTreatmentDesc(value: string) {
        this.selectTypeTreatmentDesc = value;
    }

    getSpecifyCondition(): string {
        return this.specifyCondition;
    }

    setSpecifyCondition(value: string) {
        this.specifyCondition = value;
    }
    
    getIsIncidentReport(): boolean {
        return this.isIncidentReport;
    }

    setIsIncidentReport(value: boolean) {
        this.isIncidentReport = value;
    }

    getCountryIncidentOccur(): string {
        return this.countryIncidentOccur;
    }

    setCountryIncidentOccur(value: string) {
        this.countryIncidentOccur = value;
    }

    setCountryIncidentOccurDesc(value: string) {
        this.countryIncidentOccurDesc = value;
    }

    getCountryIncidentOccurDesc(): string {
        return this.countryIncidentOccurDesc;
    }

    getTypeOfTreatmentReceived(): string {
        return this.typeOfTreatmentReceived;
    }

    setTypeOfTreatmentReceived(value: string) {
        this.typeOfTreatmentReceived = value;
    }

    getDateOfVisit(): Date {
        return this.dateOFVisit;
    }

    setDateOfVisit(value: Date) {
        this.dateOFVisit = value;
    }

    getDentalExpenseAmount(): string {
        return this.dentalExpenseAmount;
    }

    setDentalExpenseAmount(value: string) {
        this.dentalExpenseAmount = value;
    }

    getDentalExpenseAmountCurrency(): string {
        return this.dentalExpenseAmountCurrency;
    }

    setDentalExpenseAmountCurrency(value: string) {
        this.dentalExpenseAmountCurrency = value;
    }

    getNatureIllness(): string {
        return this.natureIllness;
    }

    setNatureIllness(value: string) {
        this.natureIllness = value;
    }


    static jsonConvert(dentalExpenses: DentalExpenses): DentalExpenses {

        //todo: convert date fields object if any
        if (dentalExpenses.getDateOfVisit() != null) {
            dentalExpenses.setDateOfVisit(new Date(dentalExpenses.getDateOfVisit()));
        }
        
        return dentalExpenses;
    }
}