import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {DentalExpenses} from "../../../../model/domestic-helper/dental-expenses.model";
import {Notifications} from "../../../../utilities/components/notification-messages/notifications.model";
import {ClaimTypeItemHelperService} from "../../../../services/claim-type-item-helper.service";

@Component({
  selector: 'app-dental-expenses-incurred',
  templateUrl: './dental-expenses-incurred.component.html',
  styleUrls: ['./dental-expenses-incurred.component.css']
})

export class DentalExpenseIncurred  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    dentalForm: FormGroup;
    claim: Claim;
    dentalExpenses: DentalExpenses;
    notifications: Notifications;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector,
                private claimTypeItemHelperService: ClaimTypeItemHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.dentalExpenses = this.claim.getClaimDomesticHelper().getDentalExpenses();
        if(this.dentalExpenses.getDentalExpenseAmountCurrency() == null){
            this.dentalExpenses.setDentalExpenseAmountCurrency('HKD')
        }

    }

    ngOnInit() {

        this.pushGAViewDentalTreatmentIncurred();

        if(this.dentalExpenses.getTypeOfTreatmentReceived() == null || this.dentalExpenses.getTypeOfTreatmentReceived() == ""){
            this.dentalExpenses.setTypeOfTreatmentReceived('Dental treatment')
        }

        if (!this.dentalExpenses.getDateOfVisit()) {
            this.dentalExpenses.setDateOfVisit(this.claim.getDateOfLoss());
        }

        this.dentalForm = this.fb.group({
            dateOfVisit: [this.dentalExpenses.getDateOfVisit()],
            typeOfTreatmentReceived: [this.dentalExpenses.getTypeOfTreatmentReceived()],
            dentalExpenseAmount: [this.dentalExpenses.getDentalExpenseAmount()],
            dentalExpenseAmountCurrency: [this.dentalExpenses.getDentalExpenseAmountCurrency()]
        });

        this.notifications = new Notifications();
        this.notifications.showPreamble = false;
    }

    ngAfterViewInit() {
        this.dentalForm.valueChanges.subscribe(data => {
            this.dentalExpenses.setDateOfVisit(super.getValueInComponent('dateOfVisit'));
            this.dentalExpenses.setTypeOfTreatmentReceived('Dental treatment');
            this.dentalExpenses.setDentalExpenseAmount(super.getValueInComponent('dentalExpenseAmount'));
            this.dentalExpenses.setDentalExpenseAmountCurrency(this.dentalForm.get('dentalExpenseAmountCurrency').value);
        });

    }



    back() {
        this.router.navigate(["/claimform/dental/detailsOfInjuryIllnessComponent"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        this.notifications.clearMessages();
        if (this.validateForm()) {
            let isDateOfVisitAllowed = this.claimTypeItemHelperService.isAllowedDomesticClaimDetailsGroup(1, this.claim);
            if (isDateOfVisitAllowed) {
                this.sideMenuService.emitProcess(1, 0);
                this.sideMenuService.emitClaimComplete({claimTypeId: 1, subMenuIndex: 1});
                this.router.navigate(["/claimform/dental/supportDoc"], {
                    relativeTo: this.activatedRoute
                });
            } else {
                this.notifications = new Notifications();
                this.notifications.showPreamble = false;
                let errorMessage = "error_messages.domesticClaimGroup.notAllowedDateOfVisit";

                this.notifications.addErrorMessage(errorMessage);
            }


        }
    }

    validateForm(): boolean {
        return super.validateForm(this.dentalForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }


    //Google Analytics
    pushGAViewDentalTreatmentIncurred() {
        (<any>window).dataLayer.push({
            'pageStep': 'Dental Expenses – Dental Expenses Details',
            'vPath': '/claim/dental-expenses/expense-details',
            'event': 'vpageview',                                   
        });
    }

}
