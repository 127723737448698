<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'ecClaim.accidentPlace.title' | translate}}</h5>
    <p>{{ 'ecClaim.accidentPlace.subHeading' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form [formGroup]="accidentPlaceForm">
        <div class="form-group">
          <label>{{ 'ecClaim.accidentPlace.placeOfOccurrence.label' | translate}}*</label><em class="hits" (click)="open(place_of_accident_tooltip)"></em>
          <qnect-ui #EC_ACCIDENT_PLACE [fieldId]="'EC_ACCIDENT_PLACE'" [formGroup]="accidentPlaceForm" name="accidentPlace"></qnect-ui>
        </div>
        <div *ngIf="accidentPlaceDetails.getAccidentPlace()">
          <div *ngIf="accidentPlaceDetails.getAccidentPlace() === 'Construction site'">
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_TYPE [fieldId]="'EC_ACCIDENT_PLACE_TYPE'" [formGroup]="accidentPlaceForm" name="placeType" [dynamicOptions]="placeTypeOptions1"></qnect-ui>
            </div>
          </div>
          <div *ngIf="accidentPlaceDetails.getAccidentPlace() === 'Shipyard'">
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_TYPE [fieldId]="'EC_ACCIDENT_PLACE_TYPE'" [formGroup]="accidentPlaceForm" name="placeType" [dynamicOptions]="placeTypeOptions2"></qnect-ui>
            </div>
          </div>
          <div *ngIf="accidentPlaceDetails.getAccidentPlace() === 'Manufactory'">
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_TYPE [fieldId]="'EC_ACCIDENT_PLACE_TYPE'" [formGroup]="accidentPlaceForm" name="placeType" [dynamicOptions]="placeTypeOptions3"></qnect-ui>
            </div>
          </div>
          <div *ngIf="accidentPlaceDetails.getAccidentPlace() === 'Others'">
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_TYPE [fieldId]="'EC_ACCIDENT_PLACE_TYPE'" [formGroup]="accidentPlaceForm" name="placeType" [dynamicOptions]="placeTypeOptions4"></qnect-ui>
            </div>
          </div>
          
          <div class="form-group" *ngIf="accidentPlaceDetails.getPlaceType() === '15'">
            <qnect-ui #EC_ACCIDENT_PLACE_TYPE_OTHER [fieldId]="'EC_ACCIDENT_PLACE_TYPE_OTHER'" [formGroup]="accidentPlaceForm" name="placeTypeOther"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #EC_ACCIDENT_PLACE_ACTIVITY [fieldId]="'EC_ACCIDENT_PLACE_ACTIVITY'" [formGroup]="accidentPlaceForm" name="activity"></qnect-ui>
          </div>
          <div *ngIf="accidentPlaceDetails.getAccidentPlace() === 'Construction site'">
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_WORKTYPE [fieldId]="'EC_ACCIDENT_PLACE_WORKTYPE'" [formGroup]="accidentPlaceForm" name="workType"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="accidentPlaceDetails.getWorkType() === '20'">
              <qnect-ui #EC_ACCIDENT_PLACE_WORK_TYPE_OTHER [fieldId]="'EC_ACCIDENT_PLACE_WORK_TYPE_OTHER'" [formGroup]="accidentPlaceForm" name="workTypeOther"></qnect-ui>
            </div>
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_WHEREABOUTS [fieldId]="'EC_ACCIDENT_PLACE_WHEREABOUTS'" [formGroup]="accidentPlaceForm" name="whereabouts"></qnect-ui>
            </div>
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_MACHINERY_INVOLVED [fieldId]="'EC_ACCIDENT_PLACE_MACHINERY_INVOLVED'" [formGroup]="accidentPlaceForm" name="machineryInvolved"></qnect-ui>
              <span style="margin-right: auto" [innerHtml]="'ecClaim.accidentPlace.machineryInvolved.note' | translate"> </span>
            </div>
            <div class="form-group" *ngIf="this.isMachineryInvolvedContainOthers(accidentPlaceDetails.getMachineryInvolved())">
              <qnect-ui #EC_ACCIDENT_PLACE_MACHINERY_OTHER [fieldId]="'EC_ACCIDENT_PLACE_MACHINERY_OTHER'" [formGroup]="accidentPlaceForm" name="machineryOther"></qnect-ui>
            </div>
            <div class="form-group">
              <qnect-ui #EC_ACCIDENT_PLACE_TRANSPORTING_CONSTRUCTION_MACHINERY_INVOLVED [fieldId]="'EC_ACCIDENT_PLACE_TRANSPORTING_CONSTRUCTION_MACHINERY_INVOLVED'" 
                [formGroup]="accidentPlaceForm" name="transportingConstructionMachineryInvolved"></qnect-ui>
            </div>
            <div class="form-group" *ngIf="accidentPlaceDetails.getTransportingConstructionMachineryInvolved() === '07'">
              <qnect-ui #EC_ACCIDENT_PLACE_TRANSPORTING_CONSTRUCTION_MACHINERY_OTHER [fieldId]="'EC_ACCIDENT_PLACE_TRANSPORTING_CONSTRUCTION_MACHINERY_OTHER'" [formGroup]="accidentPlaceForm" name="transportingConstructionMachineryOther"></qnect-ui>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #place_of_accident_tooltip let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h1 class="modal-title cursor-pointer">
      {{'ecClaim.accidentPlace.title' | translate}}</h1>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="policy-num-p" style="font-size: 20px; font-weight: bold; text-align: left">
      {{'ecClaim.accidentPlace.placeOfOccurrence.constructionSite' | translate}}</p>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.buildingWorksite' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.buildingWorksiteDesc' | translate}}</p>
    </div>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.civilWorksite' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.civilWorksiteDesc' | translate}}</p>
    </div>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.renovation' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.renovationDesc' | translate}}</p>
    </div>
    <br/>
    <p class="policy-num-p" style="font-size: 20px; font-weight: bold; text-align: left">
      {{'ecClaim.accidentPlace.placeOfOccurrence.shipyard' | translate}}</p>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.floatingVessel' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.floatingVesselDesc' | translate}}</p>
    </div>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.nonFloatingVessel' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.nonFloatingVesselDesc' | translate}}</p>
    </div>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.maintenanceWorkshop' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.maintenanceWorkshopShipyardDesc' | translate}}</p>
    </div>
    <br/>
    <p class="policy-num-p" style="font-size: 20px; font-weight: bold; text-align: left">
      {{'ecClaim.accidentPlace.placeOfOccurrence.manufactory' | translate}}</p>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.productionArea' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.productionAreaDesc' | translate}}</p>
    </div>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.maintenanceWorkshop' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.maintenanceWorkshopManufactoryDesc' | translate}}</p>
    </div>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.loadingUnloading' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.loadingUnloadingDesc' | translate}}</p>
    </div>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.storageArea' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.storageAreaDesc' | translate}}</p>
    </div>
    <br/>
    <p class="policy-num-p" style="font-size: 20px; font-weight: bold; text-align: left">
      {{'ecClaim.accidentPlace.placeOfOccurrence.others' | translate}}</p>
    <div class="docRow">
      <p style="text-align: left"><span style="font-weight: bold;">{{'ecClaim.accidentPlace.placeType.containeryard' | translate}}</span>
        {{'ecClaim.accidentPlace.placeType.containeryardDesc' | translate}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light"
      (click)="c('Close click')">{{'completePage.body.generalButtonLabels.close' | translate}}</button>
  </div>
</ng-template>
