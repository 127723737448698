import { AfterViewInit, Directive, DoCheck, Input } from '@angular/core';
import { UiComponent } from '../ui.component';
import {Subject, takeUntil} from "rxjs";

@Directive({selector: '[qnectToggleDisplayOnParentValueChange]'})
export class ToggleDisplayOnParentValueChangeDirective implements AfterViewInit, DoCheck {


  constructor(private uiComponent: UiComponent) {}

  @Input() qnectToggleDisplayOnParentValueChange: UiComponent;
  @Input() displayValues: string;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.toggleDisplay();

    this.qnectToggleDisplayOnParentValueChange
        .valueChanges()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
            this.toggleDisplay();
        });

  }

  ngDoCheck() {
  }

  private toggleDisplay() {

    let parentValue = this.qnectToggleDisplayOnParentValueChange.getValue();

    if (!parentValue || parentValue == '') {
      parentValue = ' ';
    }

    if (this.displayValues.indexOf(parentValue) > -1) {
      this.uiComponent.show();
    } else {
      this.uiComponent.hide();
    }
  }
}
