export class PersonalLiability {

    private liabilityIncidentDescription: string;
    private liabilityDateIncident: Date;
    private liabilityAmount: string;
    private liabilityCurrency: string;
    private liabilityCountryIncidentOccurred: string;
    private liabilityCountryIncidentOccurredDesc: string;
    private liabilityNatureIncident: string;
    private liabilityNatureIncidentDesc: string;
    private estimatedAmount: number;
    private placeOfLoss: string;
    private placeOfLossDesc: string;

    getEstimatedAmount(): number {
        return this.estimatedAmount;
    }

    setEstimatedAmount(value: number) {
        this.estimatedAmount = value;
    }

    getLiabilityIncidentDescription(): string {
        return this.liabilityIncidentDescription;
    }

    setLiabilityIncidentDescription(value: string) {
        this.liabilityIncidentDescription = value;
    }

    getLiabilityDateIncident(): Date {
        return this.liabilityDateIncident;
    }

    setLiabilityDateIncident(value: Date) {
        this.liabilityDateIncident = value;
    }

    getLiabilityAmount(): string {
        return this.liabilityAmount;
    }

    setLiabilityAmount(value: string) {
        this.liabilityAmount = value;
    }

    getLiabilityCurrency(): string {
        return this.liabilityCurrency;
    }

    setLiabilityCurrency(value: string) {
        this.liabilityCurrency = value;
    }

    getLiabilityCountryIncidentOccurred(): string {
        return this.liabilityCountryIncidentOccurred;
    }

    setLiabilityCountryIncidentOccurred(value: string) {
        this.liabilityCountryIncidentOccurred = value;
    }

    getLiabilityCountryIncidentOccurredDesc(): string {
        return this.liabilityCountryIncidentOccurredDesc;
    }

    setLiabilityCountryIncidentOccurredDesc(value: string) {
        this.liabilityCountryIncidentOccurredDesc = value;
    }

    getLiabilityNatureIncident(): string {
        return this.liabilityNatureIncident;
    }

    setLiabilityNatureIncident(value: string) {
        this.liabilityNatureIncident = value;
    }

    getLiabilityNatureIncidentDesc(): string {
        return this.liabilityNatureIncidentDesc;
    }

    setLiabilityNatureIncidentDesc(value: string) {
        this.liabilityNatureIncidentDesc = value;
    }

    getPlaceOfLoss(): string {
        return this.placeOfLoss;
    }

    setPlaceOfLoss(value: string){
        this.placeOfLoss = value;
    }

    getPlaceOfLossDesc(): string {
        return this.placeOfLossDesc;
    }

    setPlaceOfLossDesc(value: string){
        this.placeOfLossDesc = value;
    }

    static jsonConvert(personalLiability: PersonalLiability): PersonalLiability {
        if (personalLiability.getLiabilityDateIncident() != null) {
            personalLiability.setLiabilityDateIncident(new Date(personalLiability.getLiabilityDateIncident()))
        }
        return personalLiability;
    }

}