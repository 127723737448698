import { Component, OnInit, Injector } from '@angular/core';
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { DatePipe } from '@angular/common';
import { ClaimHome } from 'src/app/model/claim-home.model';
import {GenericResponsibility} from '../../../../model/home/generic-responsibility.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
  selector: 'app-summary-responsibility',
  templateUrl: './summary-responsibility.component.html',
  styleUrls: ['./summary-responsibility.component.css']
})
export class SummaryResponsibilityComponent extends ClaimsBaseComponent implements OnInit {

  claimHome: ClaimHome;
  genericResponsibility: GenericResponsibility;

  constructor(private injector: Injector) {
       
        super(injector);
        this.claimHome = this.claim.getClaimHome();
        this.genericResponsibility = this.claim.getClaimHome().getHomeDetails().getGenericResponsibility();
  }

  ngOnInit() {

  }

  returnYesOrNo(value: boolean) {
      if (value) {
          return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
      } else {
          return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
      }
  }

  returnYesOrNoBoolean(value: boolean) {
      if (value) {
          return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
      } else {
          if (!value) {
              return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
          }
      }
      return '';
  }

  getDate(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
      }
      else {
          return "";
      }
  }
  getDateTime(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en\-US').transform(dt, "dd/MM/yyyy HH:mm");
      } else {
          return "";
      }
  }

    isNotNullOrUndefined(val: any){
        if(!UtilitiesService.isNullOrUndefined(val)){
            return true;
        } 
        return false;
    }

    isNotNullOrUndefinedStr(val: any){
        if(!UtilitiesService.isNullOrUndefined(val) && val != ''){
            return true;
        } 
        return false;
    }
}

