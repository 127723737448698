import {Component} from '@angular/core';
import { Version } from '../../version';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent {
    version: string;

    ngOnInit() {
            this.version = 'version ' + Version.getVersion();
    }
}
