<div class="form-group row" [formGroup]="formGroup" [ngClass]="{'ui-error' : !formControl.valid && formControl.touched}" [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
    <input type="text" style="display: none" formControlName="{{uiModel.name}}" />
    <div class="ui-checkbox-row" *ngFor="let option of options">
        <div class="ui-checkbox" [ngClass]="{'ui-disabled': option.disabled || uiModel.disabled}" (click)="toggle(option.value, option.disabled || uiModel.disabled)"
            id="{{fieldId}}-{{option.value}}">
            <div class="ui-container">
                <div class="ui-icon">
                    <span *ngIf="exists(option.value, selectedOptions)" class="fas fa-check"></span>
                </div>
            </div>
            <div class="ui-label">
                <span>{{option.label | translate}}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12" style="padding-left:30px;">
            <ui-error *ngIf="formControl.touched" [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
        </div>
    </div>

</div>
