<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
    </div>
    <div class="col-12">
        <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p *ngIf="country === 'hkg' || country === 'hgi'">{{ 'claimSection.stayedInHospital.followUpTreatmentInHongkong.title' | translate}}</p>
        <p *ngIf="country ==='sgp'">{{ 'claimSection.stayedInHospital.followUpTreatmentInSG.title' | translate}}</p>
        <p *ngIf="country ==='mys'">{{ 'claimSection.stayedInHospital.followUpTreatmentInMY.title' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form>
                <div class="form-group">
                    <qnect-ui #HOS09 [fieldId]="'HOS09'" [formGroup]="detailOfFollowUpTreamentForm" [claimsDateValidator]="claim.getClaimTravel().getOriginalDepartureDate()" name="returnHkDate"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #HOS11 [fieldId]="'HOS11'" [formGroup]="detailOfFollowUpTreamentForm" name="hasReceiveFollowUpTreament" (uiComponentCreated)="uiComponentCreated($event)"></qnect-ui>
                </div>
                <div>
                    <div>
                        <div class="form-group click-more-content" id="q1" *ngIf="stayInHospital.getHasReceiveFollowUpTreament() == true">
                            <div class="form-group">
                                <qnect-ui #HOS10 [fieldId]="'HOS10'" [formGroup]="detailOfFollowUpTreamentForm" [claimsDateValidator]="this.claim.getClaimTravel().getStayInHospital().getReturnHkDate()" name="lastTreamentDate"></qnect-ui>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-xl-3 col-lg-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>