<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : (!uiModel.textOnly && !formControl.valid && formControl.touched) || (uiModel.textOnly && !formControl.valid)}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
  <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
  <div class="col-md-{{uiModel.size}} col-xs-12">
      <textarea formControlName="{{uiModel.name}}" class="form-control" id="{{fieldId}}"
                maxlength="{{uiModel.maxlength}}" placeholder="{{uiModel.placeholder | translate}}"
                *ngIf="!uiModel.textOnly" rows="5" spellcheck="true"></textarea>
    <p class="form-control-static" id="{{fieldId}}" *ngIf="uiModel.textOnly">{{formControl.value}}</p>
    <ui-error *ngIf="formControl.touched || uiModel.textOnly" [inputErrors]="formControl.errors"
              [dictionary]="uiModel.dictionary"></ui-error>
  </div>
</div>
