import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import {ClaimsBaseComponent} from "../../claim-base.component";


@Component({
    selector: 'another-insurance-summary',
    templateUrl: './another-insurance-summary.component.html',
    styleUrls: ['./another-insurance-summary.component.css']
})
export class AnotherInsuranceSummary extends ClaimsBaseComponent implements OnInit {


    claim: Claim;

    constructor(private modalService: NgbModal,
                private fb: FormBuilder,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
    }

    ngOnInit() {
        
    }

    returnYesOrNoString(value: string) {
        if ('Y' === value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            if ('N' === value) {
                return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
            }
        }
        return '';
    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.generalButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.generalButtonLabels.no");
            }
        }

        return '';
    }

}










