import {DocumentDetails} from '../document-details.model';

export class ClaimDetails {

    private claimNumber: string;
    private claimNotificationNumber: string;
    private dateOfLoss: Date;
    private qnectStatus: string;
    private productType: string;
    private clientName: string;
    private policyNumber: string;
    private efnolStatus: string;
    private efnolStatusDescription: string;
    private efnolStatusReason;
    private image: string;
    private lastUpdate: Date;
    private remarks: string;
    private statusDesc; string;
    private documents: DocumentDetails[];

    getClaimNumber(): string {
        return this.claimNumber;
    }

    setClaimNumber(value: string) {
        this.claimNumber = value;
    }

    getClaimNotificationNumber(): string {
        return this.claimNotificationNumber;
    }

    setClaimNotificationNumber(value: string) {
        this.claimNotificationNumber = value;
    }

    getDateOfLoss(): Date {
        return this.dateOfLoss;
    }

    setDateOfLoss(value: Date) {
        this.dateOfLoss = value;
    }

    getQnectStatus(): string {
        return this.qnectStatus;
    }

    setQnectStatus(value: string) {
        this.qnectStatus = value;
    }

    getProductType(): string {
        return this.productType;
    }

    setProductType(value: string) {
        this.productType = value;
    }

    getPolicyNumber(): string {
        return this.policyNumber;
    }

    setPolicyNumber(value: string) {
        this.policyNumber = value;
    }

    getClientName(): string {
        return this.clientName;
    }

    setClientName(value: string) {
        this.clientName = value;
    }

    getEfnolStatus(): string {
        return this.efnolStatus;
    }

    setEfnolStatus(value: string) {
        this.efnolStatus = value;
    }

    getEfnolStatusReason(): string {
        return this.efnolStatusReason;
    }

    setEfnolStatusReason(value: string) {
        this.efnolStatusReason = value;
    }

    getImage(): string {
        return this.image;
    }

    setImage(value: string) {
        this.image = value;
    }

    getEfnolStatusDescription(): string {
        return this.efnolStatusDescription;
    }

    setEfnolStatusDescription(value: string) {
        this.efnolStatusDescription = value;
    }

    getLastUpdate(): Date {
        return this.lastUpdate;
    }

    setLastUpdate(value: Date) {
        this.lastUpdate = value;
    }

    getRemarks() {
        return this.remarks;
    }

    setRemarks(value) {
        this.remarks = value;
    }

    getStatusDesc() {
        return this.statusDesc;
    }

    setStatusDesc(value) {
        this.statusDesc = value;
    }

    getDocuments(): DocumentDetails[] {
        return this.documents;
    }

    setDocuments(value: DocumentDetails[]) {
        this.documents = value;
    }

    static jsonConvert(claimDetails: ClaimDetails): ClaimDetails {

        claimDetails = Object.assign(new ClaimDetails(), claimDetails);

        if (claimDetails.getDateOfLoss() != null) {
            claimDetails.setDateOfLoss(new Date(claimDetails.getDateOfLoss()));
        }

        if (claimDetails.getLastUpdate() != null) {
            claimDetails.setLastUpdate(new Date(claimDetails.getLastUpdate()));
        }

        return claimDetails;
    }
}