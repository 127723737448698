<div class="form-group section">
    <label class="section-title">{{'pageSummaryClaim.claimItem.sectionMissEvent.title' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionDetails.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionDetails.dateOfEvent' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(missEvent.getDateOfMissedEvent())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionDetails.countryOccurred' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{missEvent.getCountryEventHeldDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionDetails.reason' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="missEvent.getReasonOfMissedEvent()?.toLowerCase() !== 'others'">
                    {{missEvent.getReasonOfMissedEventDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content" *ngIf="missEvent.getReasonOfMissedEvent()?.toLowerCase() === 'others'">
                    {{missEvent.getOtherReasonOfMissedEvent()}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionLoss.title' | translate}}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionLoss.ticketCost' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{missEvent.getTicketCostCurrency()}}
                    {{missEvent.getTicketCost() | number : '1.2-2'}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionLoss.ccPayment' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(missEvent.getIsCreditCardPayment())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionLoss.hasRefund' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(missEvent.getHasRefund())}}
                </div>
            </div>
            <div class="row" *ngIf="missEvent.getHasRefund()">
                <div class="col col-xs-12 col-lg-7">
                    {{'pageSummaryClaim.claimItem.sectionMissEvent.subSectionLoss.refund' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{missEvent.getRefundAmountCurrency()}}
                    {{missEvent.getRefundAmount() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
        <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
            <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
            <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
              <div class="col col-xs-12 col-lg-12">
                  {{item.description | translate}}
              </div>
              <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                  <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                      {{ item.uploadedFileName }}
                  </div>
              </ng-container>
          </div>
        </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>
