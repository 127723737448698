export class MedicalCertificate {

    private periodFrom: Date;
    private periodTo: Date;
    private medCertPurpose: string;

    constructor() {
        this.periodFrom = null;
        this.periodTo = null;
        this.medCertPurpose = '';
    }

    public getPeriodFrom(): Date {
        return this.periodFrom;
    }

    public setPeriodFrom(periodFrom: Date): void {
        this.periodFrom = periodFrom;
    }

    public getPeriodTo(): Date {
        return this.periodTo;
    }

    public setPeriodTo(periodTo: Date): void {
        this.periodTo = periodTo;
    }

    public getMedCertPurpose(): string {
        return this.medCertPurpose;
    }

    public setMedCertPurpose(medCertPurpose: string): void {
        this.medCertPurpose = medCertPurpose;
    }

    static jsonConvert(medicalCertificate: MedicalCertificate): MedicalCertificate {
    
        if (medicalCertificate.getPeriodFrom() != null) {
            medicalCertificate.setPeriodFrom(new Date(medicalCertificate.getPeriodFrom()));
        }

        if (medicalCertificate.getPeriodTo() != null) {
            medicalCertificate.setPeriodTo(new Date(medicalCertificate.getPeriodTo()));
        }
    
        return medicalCertificate;
    }

}