import {Component, OnInit} from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
    selector: 'app-pa-others',
    templateUrl: './pa-others.component.html'
})
export class PaOthersComponent implements OnInit {
    constructor(private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS]);
    }

    ngOnInit() {
    }
}
