export class FlightStatusOperationalTimes {
  private departureCountryName: string;
  private departureCountryCode: string;
  private arrivalCountryName: string;
  private arrivalCountryCode: string;
  private publishedDepartureDate: Date;
  private publishedArrivalDate: Date;
  private actualDepartureDate: Date;
  private actualArrivalDate: Date;
  private diffPublishedAndActualDepartureDatesMins: string;
  private diffPublishedAndActualArrivalDatesMins: string;
  private departureDelayHours: number;
  private arrivalDelayHours: number;
  private isDepartureDelayHoursNegative: boolean;
  private isArrivalDelayHoursNegative: boolean;
  private departureOrArrival: string;
  private status: string;

  getDepartureOrArrival(): string {
    return this.departureOrArrival;
  } 

  setDepartureOrArrival(value: string){
    this.departureOrArrival = value;
  }

  getDepartureCountryName(): string {
    return this.departureCountryName;
  } 

  setDepartureCountryName(value: string){
    this.departureCountryName = value;
  }
  
  getDepartureCountryCode(): string {
    return this.departureCountryCode;
  } 

  setDepartureCountryCode(value: string){
    this.departureCountryCode = value;
  }

  getArrivalCountryName(): string {
    return this.arrivalCountryName;
  } 

  setArrivalCountryName(value: string){
    this.arrivalCountryName = value;
  }

  getArrivalCountryCode(): string {
    return this.arrivalCountryCode;
  } 

  setArrivalCountryCode(value: string){
    this.arrivalCountryCode = value;
  }

  getPublishedDepartureDate(): Date {
    return this.publishedDepartureDate;
  } 

  setPublishedDepartureDate(value: Date){
    this.publishedDepartureDate = value;
  }

  getPublishedArrivalDate(): Date {
    return this.publishedArrivalDate;
  } 

  setPublishedArrivalDate(value: Date){
    this.publishedArrivalDate = value;
  }

  getActualDepartureDate(): Date {
    return this.actualDepartureDate;
  } 

  setActualDepartureDate(value: Date){
    this.actualDepartureDate = value;
  }

  getActualArrivalDate(): Date {
    return this.actualArrivalDate;
  } 

  setActualArrivalDate(value: Date){
    this.actualArrivalDate = value;
  }

  getDiffPublishedAndActualDepartureDatesMins(): string {
    return this.diffPublishedAndActualDepartureDatesMins;
  } 

  setDiffPublishedAndActualDepartureDatesMins(value: string){
    this.diffPublishedAndActualDepartureDatesMins = value;
  }

  getDiffPublishedAndActualArrivalDatesMins(): string {
    return this.diffPublishedAndActualArrivalDatesMins;
  } 

  setDiffPublishedAndActualArrivalDatesMins(value: string){
    this.diffPublishedAndActualArrivalDatesMins = value;
  }

  getDepartureDelayHours(): number {
    return this.departureDelayHours;
  } 

  setDepartureDelayHours(value: number){
    this.departureDelayHours = value;
  }

  getArrivalDelayHours(): number {
    return this.arrivalDelayHours;
  } 

  setArrivalDelayHours(value: number){
    this.arrivalDelayHours = value;
  }

  getIsDepartureDelayHoursNegative(): boolean {
    return this.isDepartureDelayHoursNegative;
  } 

  setIsDepartureDelayHoursNegative(value: boolean){
    this.isDepartureDelayHoursNegative = value;
  }

  getIsArrivalDelayHoursNegative(): boolean {
    return this.isArrivalDelayHoursNegative;
  } 

  setIsArrivalDelayHoursNegative(value: boolean){
    this.isArrivalDelayHoursNegative = value;
  }

  getStatus(): string {
    return this.status;
  }

  setStatus(value: string){
    this.status = value;
  }


    static jsonConvert(flightStatusOperationalTimes: FlightStatusOperationalTimes): FlightStatusOperationalTimes {

  flightStatusOperationalTimes = Object.assign(new FlightStatusOperationalTimes(), FlightStatusOperationalTimes);

    if (flightStatusOperationalTimes.getPublishedDepartureDate() != null) {
      flightStatusOperationalTimes.setPublishedDepartureDate(new Date(flightStatusOperationalTimes.getPublishedDepartureDate()));
    }

    if (flightStatusOperationalTimes.getPublishedArrivalDate() != null) {
      flightStatusOperationalTimes.setPublishedArrivalDate(new Date(flightStatusOperationalTimes.getPublishedArrivalDate()));
    }

    if (flightStatusOperationalTimes.getActualDepartureDate() != null) {
      flightStatusOperationalTimes.setActualDepartureDate(new Date(flightStatusOperationalTimes.getActualDepartureDate()));
    }

    if (flightStatusOperationalTimes.getActualArrivalDate() != null) {
      flightStatusOperationalTimes.setActualArrivalDate(new Date(flightStatusOperationalTimes.getActualArrivalDate()));
    }

    return flightStatusOperationalTimes;
  } 

}
