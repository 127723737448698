import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class TheftFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];

        let authFormDocField = new DocumentField('THEFT_LETTEROFAUTHORIZATION', 'motorClaim.claimSection.documents.letterOfAuthorizationSignedTheft', SmartqDocuments.MTR_AUTHLETTER, true);
        authFormDocField.setFormUrl('motorClaim.claimSection.documents.loaFileUrl');
        authFormDocField.setFormDescription('motorClaim.claimSection.documents.letterOfAuthorizationSignedTheft');
        authFormDocField.setDescriptionHtml('motorClaim.claimSection.documents.letterOfAuthorizationSignedTheftHtml');
        authFormDocField.setIsOriginal(true);
        requiredDocuments.push(authFormDocField);
        requiredDocuments.push(new DocumentField('THEFT_VEHICLEREGISTRATION', 'motorClaim.claimSection.documents.vehicleRegistration', SmartqDocuments.MTR_REGDOC, true));
        requiredDocuments.push(new DocumentField('THEFT_VEHICLELICENSE', 'motorClaim.claimSection.documents.vehicleLicense', SmartqDocuments.MTR_REGDOC, true));
        requiredDocuments.push(new DocumentField('THEFT_POLICESTATEMENT', 'motorClaim.claimSection.documents.policeStatement', SmartqDocuments.MTR_POLICEREPORT, true));
        
        this.requiredDocuments = requiredDocuments;
    }
}
