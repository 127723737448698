<div class="form-group row" [formGroup]="formGroup"
     [ngClass]="{'ui-error' : !formControl.valid && formControl.touched}"
     [ngStyle]="{'display': uiModel.hidden ? 'none' : 'block'}">
    <qnect-ui-label-part [fieldId]="fieldId" [uiModel]="uiModel"></qnect-ui-label-part>
    <div class="col-md-{{uiModel.size}} radioButtonsMarginTop" [style]="uiModel.style">
        <label class="ui-radio" *ngFor="let option of uiModel.options" for="{{fieldId}}_{{option.value}}{{frmArrayIndex}}">
            <input type="radio" [formGroup]="formGroup" formControlName="{{uiModel.name}}" id="{{fieldId}}_{{option.value}}{{frmArrayIndex}}" value="{{option.value}}">
            <span id="span{{fieldId}}"
                  [ngClass]="{'btn-secondary' : formControl.value != option.value,  'btn-primary' : formControl.value == option.value}"
                  class="btn new-btn onlyoneActive click-more-less show-more">{{option.label | translate}}</span>
        </label>
        <div class="row">
            <div class="col-md-12">
                <ui-error *ngIf="formControl.touched" [inputErrors]="formControl.errors" [dictionary]="uiModel.dictionary"></ui-error>
            </div>
        </div>
    </div>
</div>
