import { Claimant } from "./claimant.model";

export class DocumentDetails {
  docID: string = '';
  docSize: number = 0;
  docFileName: string = '';
  docFileType: string = '';
  docSubType: string = '';
  documentBytes: any;
  docClaimType: string = '';
  docSubTypeId: string = '';
  claimant: Claimant;
  claimantIdentifier: string = '';
  docRiskId = '';
  dDocName: string =  '';
  hasAutoSaveId: boolean;

    static jsonConvert(documentDetails: DocumentDetails): DocumentDetails {

        documentDetails = Object.assign(new DocumentDetails(), documentDetails);

        if (documentDetails != null) {
            if (documentDetails.claimant != null) {
                let claimantDetails = Object.assign(new Claimant(), documentDetails.claimant);
                claimantDetails = Claimant.jsonConvert(claimantDetails);
                documentDetails.claimant = claimantDetails;
            }
        }
        return documentDetails;
    }

}

