<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
        <h5>{{'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{'claimSection.visitedADoctor.followUpTreatment.title-2' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form>
                <div class="form-group">
                    <qnect-ui #VDR09_RETURN_HKDATE [fieldId]="'VDR09_RETURN_HKDATE'" [formGroup]="detailOfFollowUpTreamentForm" [claimsDateValidator]="claim.getClaimTravel().getOriginalDepartureDate()" name="returnHkDate"></qnect-ui>
                </div>
                <div class="form-group" *ngIf="this.claim.getCountry() == 'SGP'">
                    <qnect-ui #VDR25_DATE_OF_FIRST_VISIT [fieldId]="'VDR25_DATE_OF_FIRST_VISIT'"
                              [formGroup]="detailOfFollowUpTreamentForm" name="dateOfFirstVisit"></qnect-ui>
                </div>
                <div class="form-group">
                    <qnect-ui #VDR10_HAS_RECEIVED_FOLLOWUP_TREATMENT [fieldId]="'VDR10_HAS_RECEIVED_FOLLOWUP_TREATMENT'"
                              [formGroup]="detailOfFollowUpTreamentForm" name="hasReceiveFollowUpTreament"></qnect-ui>
                </div>
                <ng-container *ngIf="visitedDoctor.getHasReceiveFollowUpTreatment()">
                    <div class="form-group">
                        <qnect-ui #VDR11_LAST_TREATMENT_DATE [fieldId]="'VDR11_LAST_TREATMENT_DATE'" [formGroup]="detailOfFollowUpTreamentForm"
                                  [claimsDateValidator]="claim.getClaimTravel().getVisitedDoctor().getDateOfFirstVisit()" name="lastTreamentDate"></qnect-ui>
                    </div>
                    <div class="form-group">
                        <qnect-ui #VDR22_IS_TREATMENT_COMPLETED [fieldId]="'VDR22_IS_TREATMENT_COMPLETED'"
                                  [formGroup]="detailOfFollowUpTreamentForm" name="isTreatmentCompleted"></qnect-ui>
                    </div>
                </ng-container>
                <div class="form-group">
                    <qnect-ui #VDR23_HAS_SUFFERED_ILLNESS [fieldId]="'VDR23_HAS_SUFFERED_ILLNESS'"
                              [formGroup]="detailOfFollowUpTreamentForm" name="hasSufferedIllness"></qnect-ui>
                </div>
            </form>
        </div>
        <div class="col-xl-3 col-lg-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn btn-primary new-btn float-right"> {{'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right"></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>