<ng-template #replace_duplicate let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{'pageSummaryClaim.claimItem.duplicate' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'pageSummaryClaim.claimItem.duplicate' | translate }}</p>
        <p>{{'pageSummaryClaim.claimItem.replace' | translate }}</p>

    </div>
    <div class="modal-footer">
        <div class="col-md-6 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="replaceDuplicate()">{{'pageSummaryClaim.claimItem.yes' | translate }}</button>
        </div>
        <div class="col-md-6 text-left">
            <button type="button" class="btn btn-default btn-sm" (click)="c('Close click')">{{'pageSummaryClaim.claimItem.no' | translate }}</button>
        </div>
    </div>
</ng-template>