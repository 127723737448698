import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../../../services/side-menu.service";
import {ClaimDocumentsComponent} from '../../../../claim-documents/claim-documents.component';
import {ClaimService} from '../../../../../services/claim.service';
import {DocumentHelperService} from '../../../../../services/document-helper.service';
import {TransactionInfoService} from '../../../../../services/transaction-info.service';
import {HttpClient} from '@angular/common/http';
import {DocumentFormFactoryService} from '../../../../../services/document-form-factory.service';
import {ClaimTypes} from '../../../../../model/claim-type.model';
import { SpinnerService } from '../../../../../core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Claim } from 'src/app/model/claim.model';
import { DocumentField } from 'src/app/model/document-field';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

declare var $: any;
@Component({
    selector: 'app-visited-doctor-upload-document',
    templateUrl: './visited-doctor-upload-document.component.html',
    styleUrls: ['./visited-doctor-upload-document.component.css']
})
export class VisitedDoctorUploadDocumentComponent extends ClaimDocumentsComponent implements OnInit {

    upLoadFileModel: FormGroup;
    showTotalBox = false;
    requiredDocuments: DocumentField[];
    unuploadedDocs: string[][] = [];
    doc = "DOC";
    @ViewChild('alert_unuploaded_docs', {static: true}) docAlertModal: TemplateRef<any>;
    docAlertModalRef: any;
    listOfUnuploadedDocs: any;
    translateService: TranslateService;
    claim: Claim;

    constructor(public sideMenuService: SideMenuService,
                private fb: FormBuilder,
                private activatedRoute: ActivatedRoute,
                private router: Router, 
                private translate: TranslateService,
                claimService: ClaimService,
                documentHelperService: DocumentHelperService,
                transactionInfoService: TransactionInfoService,
                http: HttpClient,
                documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
        super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
        this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT]);
        this.claim = this.claimService.getClaim();
        this.translateService = this.translate;
    }

    ngOnInit() {

        this.pushGAViewVisitDoctorDocs();
        super.ngOnInit();
        this.upLoadFileModel = this.fb.group(
            {
                airTicket: [],
                carrierCertificate: [],
                medicalReport: [],
                medicalBills: this.fb.array([]),
                receiptOfFollowUp: [],
                receiptOfOtherExpense: [],
                otherItems: this.fb.array([this.createItem()])

            });
        this.requiredDocuments = this.formBuilder.getRequiredDocuments();
    }

    addFile() {
        let items = this.upLoadFileModel.get('otherItems') as FormArray;
        items.push(this.createItem());
    }

    createItem(): FormGroup {
        return this.fb.group({
            otherFile: []
        });
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() { 
        this.listOfUnuploadedDocs = "";
        if(this.verifySTPUploadedDocs()){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 4});
            this.router.navigate(["/claimform/moreClaimType"], {
                relativeTo: this.activatedRoute
            });
        }else{
            this.showDocAlertModal();
        }
      
    }

    verifySTPUploadedDocs(){
        // not applicable for MYS and GBA plans
        if (this.claim.getCountry() === 'MYS' || this.claim.getClaimTravel().getGBAPlanType()) {
            return true;
        }

        // verify required documents for stp
        const documentsDetails = this.requiredDocuments;
        let uploadedDocuments = this.claim.getDocumentForm().documents;
        let previousClaimDoc: string = 'none';
        // we check all required document if uploaded
        let medBills = parseInt(this.claim.getClaimTravel().getVisitedDoctor().getNumberOfTreatmentBill());
        
        for (let documentsDetailsValue of documentsDetails) {
            //check if there are already documents uploaded
            let docUploaded: boolean = false;
            if(uploadedDocuments.length < 1){
                if(documentsDetailsValue.id == 'AIRTICKET' || documentsDetailsValue.id == 'MEDICALBILLA'){
                    this.unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);
                }
                
            }else{
                for (let uploadedDoc of uploadedDocuments) {
                    //if required documents uploaded we skip then proceed to check the other details
                    if(uploadedDoc.docSubTypeId.startsWith(documentsDetailsValue.id) && uploadedDoc.docClaimType == this.getCurrentClaimType() && (documentsDetailsValue.id == 'AIRTICKET' || documentsDetailsValue.id.startsWith('MEDICALBILL'))){
                        if(documentsDetailsValue.id == 'AIRTICKET'){
                            docUploaded = true;
                            previousClaimDoc = documentsDetailsValue.id;
                            break;  
                        }else{
                            if(!isNaN(medBills) && medBills > 0){
                                for(var x = 1; x < medBills + 1; x++){
                                    let medBillsDoc: string = 'MEDICALBILL' + String.fromCharCode(x+64);
                                    if(documentsDetailsValue.id == medBillsDoc)
                                    docUploaded = true;
                                    previousClaimDoc = documentsDetailsValue.id;
  
                                }
                            }
                        }

                    }
                    
                }
                if(docUploaded == false){
                    if(previousClaimDoc != documentsDetailsValue.id && (documentsDetailsValue.id == 'AIRTICKET' || documentsDetailsValue.id.startsWith('MEDICALBILL'))){
                       if(documentsDetailsValue.id == 'AIRTICKET'){
                            this.unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);
                            previousClaimDoc = documentsDetailsValue.id;
                       }else{
                        if(!isNaN(medBills) && medBills > 0){
                            for(var x = 1; x < medBills + 1; x++){
                                let medBillsDoc: string = 'MEDICALBILL' + String.fromCharCode(x+64);
                                if(documentsDetailsValue.id == medBillsDoc)
                                this.unuploadedDocs.push([documentsDetailsValue.id, documentsDetailsValue.description]);
                                previousClaimDoc = documentsDetailsValue.id;
 
                            }
                        }
                       }
                        
                    }
                    
                }
            }
        }

        return this.unuploadedDocs.length < 1 && uploadedDocuments.length > 0;
    }

    showDocAlertModal() {
        this.getSTPUnuploadedDocs();
         this.docAlertModalRef = this.modalService.open(this.docAlertModal, {size: 'lg',
             backdrop: 'static',
             keyboard: false
         });
     }

    getSTPUnuploadedDocs() {
        //remove redundant doc file
        let copyUnuploadedDocs: string[][]  = [];
        let medBillCount = 0;
        let airTicketCount = 0;
        this.unuploadedDocs.forEach(function (unuploaded, index){
            if(unuploaded[0].startsWith("MEDICALBILL") && medBillCount == 0){
                copyUnuploadedDocs.push([unuploaded[0], unuploaded[1]])
                medBillCount++;
            }else if(unuploaded[0].startsWith("AIRTICKET") && airTicketCount == 0){
                copyUnuploadedDocs.push([unuploaded[0], unuploaded[1]])
                airTicketCount++;
            }
        });
        // set unuploaded documents to display in alert message
        if(copyUnuploadedDocs.length >= 1){

            let totalLength = copyUnuploadedDocs.length;
            let maxSize = copyUnuploadedDocs.length -1;
            let self = this;
            let currentLanguage = this.translate.currentLang;
            copyUnuploadedDocs.forEach(function (description, index) {
                self.translate.stream(description[1]).subscribe((res:string) => {
                    console.log("res:"+res)
                    if(self.listOfUnuploadedDocs == null || self.listOfUnuploadedDocs == "" || self.listOfUnuploadedDocs === undefined){
                        if(index > 0 || totalLength > 1){
                            self.listOfUnuploadedDocs = res + ", ";
                        }else{
                            if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                                self.listOfUnuploadedDocs = res + "。";
                            }else{
                                self.listOfUnuploadedDocs = res + ".";
                            }
                        }
                    }else if(index == maxSize){
                        if(currentLanguage == 'zh' || currentLanguage == 'cn'){
                            self.listOfUnuploadedDocs += (res + "。");
                        }else{
                            self.listOfUnuploadedDocs += (res + ".");
                        }
                    }else{
                        self.listOfUnuploadedDocs += (res + ", ");
                    }

                });

            });
        }

        this.unuploadedDocs = [];
    }

     continueToNext(){
        this.sideMenuService.emitProcess(1, 0);
        this.sideMenuService.emitClaimComplete({claimTypeId: 4, subMenuIndex: 4});
        this.router.navigate(["/claimform/moreClaimType"], {
            relativeTo: this.activatedRoute
        });
        this.docAlertModalRef.close();
     }

    //Google Analytics
    pushGAViewVisitDoctorDocs() {
        (<any>window).dataLayer.push({
            'pageStep': 'Visit Doctor – Document Upload',
            'vPath': '/claim/visit-doctor/documents',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 6},	//Step6-DocumentUpload
                }
            }
        });
    }
}
