import {Component, Injector} from "@angular/core";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ClaimService} from "../../../services/claim.service";

@Component({
    selector: 'employee-compensation-sections-summary',
    templateUrl: './employee-compensation-summary.component.html'

})
export class EmployeeCompensationSummaryComponent extends ClaimsBaseComponent {

    constructor(private claimService: ClaimService,
                private injector : Injector) {
        super(injector);
    }
}
