import {DynamicItem} from '../model/dynamic-item.model';
import {DynamicItemAction} from '../model/dynamic-item-action.model';
import {BehaviorSubject, Subject} from "rxjs";

export class DynamicSubject {

  private _actionSubject: BehaviorSubject<DynamicItemAction>;
  private _itemsSubject: BehaviorSubject<DynamicItem[]>;

  constructor(items: DynamicItem[], actionItem: DynamicItemAction) {
    this._actionSubject = new BehaviorSubject(actionItem);
    this._itemsSubject = new BehaviorSubject(items);
  }


  get actionItem(): Subject<DynamicItemAction> {
    return this._actionSubject;
  }


  setActionItem(actionItem: DynamicItemAction) {
    this._actionSubject.next(actionItem);
  }


  get items(): Subject<DynamicItem[]> {
    return this._itemsSubject;
  }

  setItems(items: DynamicItem[]) {
    this._itemsSubject.next(items);
  }
}
