import {Component, Inject} from '@angular/core';
import {PathLocationStrategy} from '@angular/common';
import {PageService} from './services/page.service';
import { ClaimService } from './services/claim.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    title = 'app';

    constructor(pathLocationStrategy: PathLocationStrategy,
                pageService : PageService, private claimService : ClaimService) {

        console.log('entryUrl',document.location.href);
        if (!pageService.getEntryUrlDirect()) {
            let entryUrl = document.location.href;
            pageService.setEntryUrl(entryUrl);
            console.log('setEntryUrl',entryUrl);
        }

        const absolutePathWithParams : string  = pathLocationStrategy.path();
        console.log('path', absolutePathWithParams);

        let queryString = absolutePathWithParams ? absolutePathWithParams.split('?')[1] : '';
        if (queryString === undefined) {
            queryString = absolutePathWithParams;
        }
        console.log('queryString', queryString);

        let parameters = pageService.getParameters();

        if (queryString && queryString != '') {

            let paramSection = queryString.split('&');
            //console.log('paramSection', paramSection);
            for (let i=0; i<paramSection.length; i++) {
                // separate the keys and the values
                let pairs = paramSection[i].split('=');
                let paramName = pairs[0];
                let paramValue = pairs[1];

                parameters[paramName] = paramValue;
                //console.log('param Name Value', paramName, paramValue);
            }
        }
    }

    ngOnInit() {
        window.addEventListener("dragover", e => {
            e && e.preventDefault();
          }, false);
          window.addEventListener("drop", e => {
            e && e.preventDefault();
          }, false);
    }
}
