export enum SmartqDocuments {
    AIRTICKET = "Airline Ticket",
    ASSESSMENT = "Assessment",
    CARLEASEAGREEMENT = "Car lease agreement",
    CORRESPONDENCE = "Correspondence",
    INVOICE = "Invoice/Receipt",
    LEGALDOCUMENT = "Legal Document",
    MEDICALREPORT = "Medical Report",
    PAYMENTDOCUMENT = "Payment document",
    PHOTOS = "Photos",
    REPORT = "Report",
    PROOF = "Proof of Relationship",
    REPLACEMENTRECEIPT = "Rcpt of replacement Id/docs",
    PURCHASERECEIPT = "Replaced/Repair Receipt",
    RECEIPTTRANSCOST = "Transport receipt",
    RECEIPTACCOMCOST = "Accom receipt",
    REPAIRRECEIPT = "Repair Receipt",
    REPAIRQUOTATION = "Repair Quotation",
    REPAIRRCPTQUOTATION = "Repair Receipt/Quotation",
    REPLACEMENTRCPTTQUOTATION = "Replacement Receipt/Quotation",
    PURCHASERCPT = "Purchase Receipt",
    PURCHASERCPTREF = "Purchase Price Reference",
    PROOF_TRAVEL = "Proof of Travel",

    //For Domestic Helper Claim Types
    DMH_HOSPITALBILLS = "DMH-Hospital/Medical bill(s)",
    DMH_EXTENTOFINJURY = "DMH-Extent of Injury report",
    DMH_AGENTFEEEXP = "DMH-Agent fee exp receipt",
    DMH_DEATH = "DMH-Death certificate",
    DMH_DENTALTREATMENTBILL = "DMH-Dental Treatment bill(s)",
    DMH_DISCHARGE = "DMH-Discharge Slip",
    DMH_EC_ECFORMA = "DMH-EC-Form 2/2A/2B",
    DMH_EMPLOYEE = "DMH-Employee Contract",
    DMH_EXPRECEIPTFORTEMP = "DMH-Exp receipt for temp DH",
    DMH_POLICE = "DMH-Police Report",
    DMH_PROOFFORREPLACE = "DMH-Proof for Replace DH ",
    DMH_PROOFOFDAMAGE = "DMH-Proof of damage/loss",
    DMH_PROOFOFEMPLOYINGTEMP = "DMH-Proof of employing temp DH",
    DMH_PURCHRTOFDMDLOSS = "DMH-Purch Rt of Dmd/Loss item",
    DMH_RELEVANTEXP = "DMH-Relevant Exp Rcpts",
    DMH_REPATRIATIONEXP = "DMH-Repatriation exp receipt",
    DMH_SICKLEAVE = "DMH-Sick Leave Certificate",
    DMH_TPCORRESPONDENCE = "DMH-TP Correspondences",
    DMH_TPDAMAGEORINJURY = "DMH-TP Damage or Injury Photo",
    DMH_UNFIT = "DMH-Unfit Certificate",
    DMH_WITNESS = "DMH-Witness Statement",

    //For Motor Claim Types
    MTR_PHOTO = "Photos",
    MTR_DMGPHOTO = "Damaged Photos",
    MTR_RECEIPT = "Invoice/Receipt",
    MTR_ID = "Identification/Passport",
    MTR_QUOTATION =  "Quotation",
    MTR_AUTHLETTER =  "Authorization Letter",
    MTR_DRIVINGLICENSE =  "Driving License",
    MTR_HKID =  "HKID/Passport",
    MTR_REGDOC =  "Vehicle Reg Doc /Veh Licence",
    MTR_PASSPORT =  "Identification/Passport",
    MTR_DVD =  "DVD",
    MTR_OTHERS =  "Others",
    MTR_PHOTOS =  "Photos",
    MTR_POLICEREPORT =  "Police statement",
    MTR_CORRESPONDENCE =  "Correspondence",

    //Home
    HOME_ASSESSMENT = "Assessment",
    HOME_CORRESPONDENCE = "Correspondence",
    HOME_ENDORSEDOC = "Endorsement Document",
    HOME_INVESTIGATION = "Investigation",
    HOME_INVOICE = "Invoice/Receipt",
    HOME_LEGALDOC = "Legal Document",
    HOME_MEDICAL = "Medical",
    HOME_MEDREP = "Medical report",
    HOME_OTHER = "Other",
    HOME_PHOTOS = "Photos",
    HOME_QUOTE = "Quote Related Document",
    HOME_REPINVOICE = "Replaced item Purchase invoice",
    HOME_REPRECEIPT = "Replaced/Repair Receipt",
    HOME_REPORT = "Report",

    //PA
    PA_INVOICE = "Invoice/Receipt",
    PA_MEDICAL = "Medical",
    PA_MEDICALREPORT = "Medical Report",
    PA_OTHER = "Other",
    PA_REPORT = "Report"
    
} 