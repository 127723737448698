<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{'pagePaymentAndContact.sendCheque.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{'pagePaymentAndContact.sendCheque.title' | translate}}</h5>
    <p>{{'pagePaymentAndContact.sendCheque.desc1' | translate}} {{'pagePaymentAndContact.sendCheque.desc2' | translate}}</p>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-xl-8 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #PYM17 [fieldId]="'PYM17'" [formGroup]="sendChequeForm" name="claimantName" (focusout)="applyP400Format($event, 'claimantName')" [disabled]="isMotorLodgedBy != ''" [dynamicOptions]="chequePayeeList"></qnect-ui>
          <qnect-ui #PYM05 [fieldId]="'PYM05'" [formGroup]="sendChequeForm" name="addressLine1" (focusout)="applyP400Format($event, 'addressLine1')" [disabled]="isMotorLodgedBy != '' && isMotorLodgedBy == 'repairer'"></qnect-ui>
          <qnect-ui #PYM06 [fieldId]="'PYM06'" [formGroup]="sendChequeForm" name="addressLine2" (focusout)="applyP400Format($event, 'addressLine2')" [disabled]="isMotorLodgedBy != '' && isMotorLodgedBy == 'repairer'"></qnect-ui>
          <qnect-ui #PYM02 [fieldId]="'PYM02'" [formGroup]="sendChequeForm" name="addressLine3" (focusout)="applyP400Format($event, 'addressLine3')" [disabled]="isMotorLodgedBy != '' && isMotorLodgedBy == 'repairer'"></qnect-ui>
          <qnect-ui #PYM19 [fieldId]="'PYM19'" [formGroup]="sendChequeForm" name="addressLine4" (focusout)="applyP400Format($event, 'addressLine4')" [disabled]="isMotorLodgedBy != '' && isMotorLodgedBy == 'repairer'"></qnect-ui>
          <qnect-ui #PYM20 [fieldId]="'PYM20'" [formGroup]="sendChequeForm" name="addressCountryText"></qnect-ui>
        </div>

      </form>
      <div>
        <span>{{ 'pagePaymentAndContact.sendCheque.chequeHelpText.desc2' | translate}}
          <a href="javascript:;" (click)="goToBank()" class="click-more">{{ 'pagePaymentAndContact.sendCheque.chequeHelpText.desc1' | translate}}</a></span>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'pagePaymentAndContact.sendCheque.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{'pagePaymentAndContact.sendCheque.next' | translate}} <i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
