import {Injectable} from '@angular/core';
import {Claim} from '../model/claim.model';
import {ClaimTravel} from '../model/claim-travel.model';
import {ClaimTypes} from '../model/claim-type.model';
import {LostPersonalItemDetail} from '../model/travel/lost-personal-detail.model';
import {DamagePersonalItemDetail} from '../model/travel/damage-personal-detail.model';
import {BaggageDelayItemDetail} from '../model/travel/baggage-delay-item.model';
import {UICountryService} from '../ui/ui-country.service';
import {ClaimMotor} from '../model/claim-motor.model';
import {PanelRepairers} from 'src/app/model/motor/panel-repairers';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../environments/environment";
import {TransactionInfoService} from "./transaction-info.service";
import { ClaimHome } from '../model/claim-home.model';
import { ThirdPartyLegalDetails } from '../model/home/third-party-legal-details.model';
import { ThirdPartyLossDetails } from '../model/home/third-party-loss-details.model';
import { DomesticEmployerLiability } from '../model/home/domestic-employer-liability.model';
import { PersonalAccident } from '../model/home/personal-accident.model';
import { GenericResponsibility } from '../model/home/generic-responsibility.model';
import {HomeDetails} from '../model/home/home-details.model';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable()
export class GoogleAnalyticsService {

    private utm_source: string = "";
    private utm_medium: string = "";
    private utm_campaign: string = "";
    private totalEstimatedAmount: number = 0;

    constructor(private uiCountryService: UICountryService,
                private translate: TranslateService,
                private transactionInfoService: TransactionInfoService) {}

    getUtmSource(): string {
        return this.utm_source;
    }

    setUtmSource(value : string) {
        this.utm_source = value;
    }

    getUtmMedium(): string {
        return this.utm_medium;
    }

    setUtmMedium(value : string) {
        this.utm_medium = value;
    }

    getUtmCampaign(): string {
        return this.utm_campaign;
    }

    setUtmCampaign(value : string) {
        this.utm_campaign = value;
    }

    getTotalEstimatedAmount(): number {
        return this.totalEstimatedAmount;
    }

    setTotalEstimatedAmount(value : number) {
        this.totalEstimatedAmount = value;
    }

    setUpGoogleAnalytics() {
        try {
            let transactionInfo  = this.transactionInfoService.getTransactionInfo();
            let policyType = transactionInfo.getPolicyType();
            let action = transactionInfo.getAction();
            if (!UtilitiesService.isNullOrUndefined(action) && action !== "" && action === "claimstatus") {
                this.appendGAScript(environment.gaID.claimStatus);
            } else {
                if (!UtilitiesService.isNullOrUndefined(policyType) && policyType !== "") {
                    this.appendGAScript(environment.gaID[policyType]);
               }
            }
        } catch (ex) {
            console.error('Error appending google analytics ' , ex);
        }
    }

    appendGAScript(gaID: string) {
        const gaScript = document.createElement('script');
        gaScript.innerHTML = ` 
                 (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-XXXXXXX');
               `;

        gaScript.innerHTML = gaScript.innerHTML.replace(/GTM-XXXXXXX/g, gaID);
        const headHtml = document.getElementsByTagName('head')[0];
        headHtml.appendChild(gaScript);
    }

    getGAClaimProductListSummary(claim: Claim, brand: string): any {
        let transactionInfo = this.transactionInfoService.getTransactionInfo();
        let origin = transactionInfo.getTransactionOrigin();
        let notifyAgent = claim.getNotifyAgent() ? "Agent Copy - Yes" : "Agent Copy - No";

        if (transactionInfo.isTravelProduct()) {
            return this.getGATravelClaimProductSummary(claim, brand, origin, notifyAgent);
        } else if (transactionInfo.isDomesticHelperProduct()) {
            return this.getGAHelperClaimProductSummary(claim, brand, origin, notifyAgent);
        } else if (transactionInfo.isMotorProduct()) {
            return this.getGAMotorClaimProductSummary(claim, brand, origin, notifyAgent);
        } else if (transactionInfo.isHomeProduct()) {
            return this.getGAHomeClaimProductSummary(claim, brand, origin, notifyAgent);
        } else if (transactionInfo.isAccidentProduct()) {
            return this.getGAAccidentClaimSummary(claim, brand, origin, notifyAgent);
        } else {
            return this.getGAGenericProductSummary(claim, brand, origin, notifyAgent);
        }
    }

    getGATravelClaimProductSummary(claim: Claim, brand: string, originTransaction: string, notifyAgent: string): any {
        const productList: any = [];
        let newProduct = {};
        let self = this;
        let claimTypeCountry: string ="";
        let claimTypeReason: string ="";
        let estimateAmount: number = 0;
        let totalClaimAmount: number = 0;
        let claimSelectedList = claim.getSelectedClaimTypesList();
        let claimTravel: ClaimTravel = claim.getClaimTravel();
        let category = claimTravel.getCoverType() === 'S' ? "Single Trip" : "Annual Travel";

            claimSelectedList.forEach(function (claimTypeSelected) {
                switch (claimTypeSelected) {
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL]:
                        let travelDelay = claimTravel.getTravelDelay();
                        claimTypeCountry = travelDelay.getDelayedDepartureOrArrival().toLowerCase() == "delayeddeparture" ?
                            self.uiCountryService.getCountryDescription(travelDelay.getDelayedDepartureCountry()) :
                            self.uiCountryService.getCountryDescription(travelDelay.getDelayedArrivalCountryDesc());
                        claimTypeReason = travelDelay.getDelayReason().toLowerCase() != "other" ? travelDelay.getDelayReason() : travelDelay.getDelayReasonOthers();
                        estimateAmount = travelDelay.getEstimatedAmount();
                        totalClaimAmount= totalClaimAmount + estimateAmount;
                        newProduct = {
                            'name': 'Travel Delay',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension1': claimTypeReason,
                            'dimension12': originTransaction,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]:
                        let lostPersonalItem = claimTravel.getLostPersonalItem();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(lostPersonalItem.getCountryTheDamageOccurred());
                        claimTypeReason = lostPersonalItem.getHowDamageOccurred().toLowerCase() != "others" ? lostPersonalItem.getHowDamageOccurred() : lostPersonalItem.getOtherDamageOccurred();
                        let lostItem: LostPersonalItemDetail[] = lostPersonalItem.getDetailsOfLostItemsArray();
                        estimateAmount = lostPersonalItem.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        let lostItemStr: string = "";
                        if (lostItem.length > 0) {
                            let temp: String[] = [];
                            for (var i = 0; i < lostItem.length; i++) {
                                if (lostItem[i].getLossType().toLowerCase() != "others") {
                                    temp.push(lostItem[i].getLossType());
                                } else {
                                    temp.push(lostItem[i].getLossTypeDetail());
                                }
                            }
                            lostItemStr = temp.join(", ");
                        }
                        newProduct = {
                            'name': 'Lost Personal Item',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension2': claimTypeReason,
                            'dimension3': lostItemStr,
                            'dimension12': originTransaction,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]:
                        let damagePersonalItem = claimTravel.getDamagePersonalItem();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(damagePersonalItem.getCountryTheDamageOccurred());
                        claimTypeReason = damagePersonalItem.getHowDamageOccurred().toLowerCase() != "others" ? damagePersonalItem.getHowDamageOccurred() : damagePersonalItem.getOtherDamageOccurred();
                        let damageItem: DamagePersonalItemDetail[] = damagePersonalItem.getDetailsOfDamageItemsArray();
                        estimateAmount = damagePersonalItem.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        let damageItemStr: string = "";
                        if (damageItem.length > 0) {
                            let temp: String[] = [];
                            for (var i = 0; i < damageItem.length; i++) {
                                if (damageItem[i].getLossType().toLowerCase() != "others") {
                                    temp.push(damageItem[i].getLossType());
                                } else {
                                    temp.push(damageItem[i].getLossTypeDetail());
                                }
                            }
                            damageItemStr = temp.join(", ");
                        }

                        newProduct = {
                            'name': 'Damaged Personal Item',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension2': claimTypeReason,
                            'dimension3': damageItemStr,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_BDEL]:
                        let baggageDelay = claimTravel.getBaggageDelay();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(baggageDelay.getCountryOccurred());
                        let baggageItem: BaggageDelayItemDetail[] = baggageDelay.getEmergencyPurchasedItemsArray();
                        estimateAmount = baggageDelay.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        let baggageItemStr: string = "";

                        if (baggageItem.length > 0) {
                            let temp: String[] = [];
                            for (var i = 0; i < baggageItem.length; i++) {
                                if (baggageItem[i].getEmergencyPurchaseType().toLowerCase() != "others") {
                                    temp.push(baggageItem[i].getEmergencyPurchaseType());
                                } else {
                                    temp.push(baggageItem[i].getEmergencyPurchaseTypeDetail());
                                }
                            }
                            baggageItemStr = temp.join(", ");
                        }

                        newProduct = {
                            'name': 'Baggage Delay',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension3': baggageItemStr,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT]:
                        let visitedDoctor = claimTravel.getVisitedDoctor();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(visitedDoctor.getCountryOfTheLossOccurred());
                        let hasFollowUps: string = visitedDoctor.getHasReceiveFollowUpTreatment() == true ? "With Follow-up" : "No Follow-up";
                        claimTypeReason = visitedDoctor.getNatureOfIllness();
                        estimateAmount = visitedDoctor.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        if (visitedDoctor.getNatureOfIllness().toLowerCase() == "sickness") {
                            if (visitedDoctor.getDiagnoseType().toLowerCase() != "others") {
                                claimTypeReason = claimTypeReason + " - " + visitedDoctor.getDiagnoseType();
                            } else {
                                claimTypeReason = claimTypeReason + " - " + visitedDoctor.getOtherDiagnose();
                            }
                        }

                        newProduct = {
                            'name': 'Visited a Doctor',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension4': claimTypeReason,
                            'dimension5': hasFollowUps,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_STY]:
                        let stayHospital = claimTravel.getStayInHospital();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(stayHospital.getCountryOfTheLossOccurred());
                        let hasFollowUp: string = stayHospital.getHasReceiveFollowUpTreament() == true ? "With Follow-up" : "No Follow-up";
                        claimTypeReason = stayHospital.getNatureOfIllness();
                        estimateAmount = stayHospital.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        if (stayHospital.getNatureOfIllness().toLowerCase() == "sickness") {
                            if (stayHospital.getDiagnoseType().toLowerCase() != "other") {
                                claimTypeReason = claimTypeReason + " - " + stayHospital.getDiagnoseType();
                            } else {
                                claimTypeReason = claimTypeReason + " - " + stayHospital.getOtherDiagnose();
                            }
                        }

                        newProduct = {
                            'name': 'Stayed in Hospital',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension4': claimTypeReason,
                            'dimension5': hasFollowUp,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN]:
                        let tripCancellation = claimTravel.getTripCancellation();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(tripCancellation.getCountryOfLoss());
                        claimTypeReason = tripCancellation.getCancelTripReason();
                        estimateAmount = tripCancellation.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        if (tripCancellation.getCancelTripReason().toLowerCase() == "others") {
                            claimTypeReason = tripCancellation.getOtherCancelTripReason();
                        }

                        newProduct = {
                            'name': 'Trip Cancellation',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension6': claimTypeReason,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_CRTL]:
                        let tripCurtailment = claimTravel.getTripCurtailment();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(tripCurtailment.getCountryCurtailmentOccurred());
                        claimTypeReason = tripCurtailment.getReasonForCancel();
                        estimateAmount = tripCurtailment.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        if (tripCurtailment.getReasonForCancel().toLowerCase() == "others") {
                            claimTypeReason = tripCurtailment.getOtherReason();
                        }

                        newProduct = {
                            'name': 'Trip Curtailment',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension6': claimTypeReason,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_RVE]:
                        let rentalVehicle = claimTravel.getRentalVehicle();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(rentalVehicle.getCountryIncidentOccured());
                        estimateAmount = rentalVehicle.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;

                        newProduct = {
                            'name': 'Rental Vehicle Excess',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_CAN_RROUT]:
                        let travelReroute = claimTravel.getTravelReRoute();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(travelReroute.getReroutingCountryOccurred());
                        estimateAmount = travelReroute.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;

                        newProduct = {
                            'name': 'Travel Re-routing',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_MSEVNT]:
                        let missEvent = claimTravel.getMissedEvent();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(missEvent.getCountryEventHeld());
                        claimTypeReason = missEvent.getReasonOfMissedEvent();
                        estimateAmount = missEvent.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        if (missEvent.getReasonOfMissedEvent().toLowerCase() == "others") {
                            claimTypeReason = missEvent.getOtherReasonOfMissedEvent();
                        }

                        newProduct = {
                            'name': 'Missed Event',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension8': claimTypeReason,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]:
                        let missConnection = claimTravel.getMissConnection();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(missConnection.getCountryOccurred());
                        claimTypeReason = missConnection.getReasonOfMissConnect();
                        estimateAmount = missConnection.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        if (missConnection.getReasonOfMissConnect().toLowerCase() == "other") {
                            claimTypeReason = missConnection.getOtherReasonOfMissConnect();
                        }

                        newProduct = {
                            'name': 'Missed Connection',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension7': claimTypeReason,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]:
                        let deathAndSeriousBodyInjury = claimTravel.getDeathAndSeriousBodyInjuryLoss();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(deathAndSeriousBodyInjury.getCountryOfLoss());
                        let hasFollowUpTreat: string = deathAndSeriousBodyInjury.getHasReceiveFollowUpTreament() == true ? "With Follow-up" : "No Follow-up";
                        claimTypeReason = deathAndSeriousBodyInjury.getNatureOfIllness();
                        estimateAmount = deathAndSeriousBodyInjury.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        if (deathAndSeriousBodyInjury.getNatureOfIllness().toLowerCase() != "others") {
                            claimTypeReason = deathAndSeriousBodyInjury.getOtherTypeDesc();
                        }

                        newProduct = {
                            'name': 'Serious Body Injury & Death',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension4': claimTypeReason,
                            'dimension5': hasFollowUpTreat,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]:
                        let personalLiability = claimTravel.getPersonalLiability();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(personalLiability.getLiabilityCountryIncidentOccurred());
                        claimTypeReason = personalLiability.getLiabilityNatureIncident();
                        estimateAmount = personalLiability.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        newProduct = {
                            'name': 'Personal Liability',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension9': claimTypeReason,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_OTH]:
                        let otherClaims = claimTravel.getOtherClaim();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(otherClaims.getOtherCountryIncidentOccurred());
                        estimateAmount = otherClaims.getEstimatedAmount();
                        totalClaimAmount = totalClaimAmount + estimateAmount;
                        newProduct = {
                            'name': 'Other Claim',
                            'variant': claimTypeCountry,
                            'price': estimateAmount,
                            'brand': brand,
                            'category': category,
                            'dimension12': originTransaction,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        };
                        productList.push(newProduct);
                        break;
                    default:

                }
            }, this);
        this.setTotalEstimatedAmount(totalClaimAmount);
        return productList;
    }

    getGAHelperClaimProductSummary(claim: Claim, brand: string, originTransaction: string, notifyAgent: string): any {
        const productList: any = [];
        let totalClaimAmount: number = 0;
        let totalAmount: number = 0;
        let claimSelectedList = claim.getSelectedClaimTypesList();
        let claimDomesticHelper = claim.getClaimDomesticHelper();
        let claimNumber: string = "";
        let self = this;

        claimSelectedList.forEach(function (claimTypeSelected) {
            switch (claimTypeSelected) {
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]:
                    let clinicalExpenses = claimDomesticHelper.getClinicalExpenses();
                    totalAmount = parseFloat(clinicalExpenses.getTotalAmountOfExpensesValue());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    let natureOfIllness = clinicalExpenses.getNatureOfIllness();
                    let typeOfPractioner = clinicalExpenses.getTypeOfClinicalExpensesCode();
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_CLINICAL]);

                    productList.push({
                        'name': 'Clinical Expenses',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension2': natureOfIllness,
                        'dimension3': typeOfPractioner,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]:
                    let dentalExpense = claimDomesticHelper.getDentalExpenses();
                    totalAmount = parseFloat(dentalExpense.getDentalExpenseAmount());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    let typeOfTreatment = dentalExpense.getSelectTypeTreatment();
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL]);

                    productList.push({
                        'name': 'Dental Expenses',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension4': typeOfTreatment,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL]:
                    let hospitalizationHelper = claimDomesticHelper.getHospitalizationHelper();
                    totalAmount = parseFloat(hospitalizationHelper.getAmountClaim());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HOSPITAL]);

                    productList.push({
                        'name': 'Hospitalization of helper',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH]:
                    let deathAndPermanentDisability = claimDomesticHelper.getDeathAndPermanentDisability();
                    totalAmount = parseFloat(deathAndPermanentDisability.getTotalAmountValue());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DEATH]);

                    productList.push({
                        'name': 'Permanent disability or Death',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY]:
                    let helpersLiability = claimDomesticHelper.getHelpersLiability();
                    totalAmount = parseFloat(helpersLiability.getAmountClaim());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_HELP_LIABILITY]);
                    let natureOfIncident = helpersLiability.getNatureOfIncidentCode();

                    productList.push({
                        'name': 'Helpers Liability',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension5': natureOfIncident,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]:
                    totalAmount = 9;
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY]);

                    productList.push({
                        'name': "Employee's Compensation",
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY]:
                    let dishonestyHelper = claimDomesticHelper.getDishonestyHelper();
                    totalAmount = parseFloat(dishonestyHelper.getAmountClaim());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DISHONESTY]);

                    productList.push({
                        'name': 'Dishonesty of Helper',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]:
                    let repatriaton = claimDomesticHelper.getRepatriation();
                    totalAmount = parseFloat(repatriaton.getRepatClaimedAmount());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION]);

                    productList.push({
                        'name': 'Repatriation',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
                case ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS]:
                    let otherClaimsDmh = claimDomesticHelper.getOtherClaimDMH();
                    totalAmount = parseFloat(otherClaimsDmh.getAmountClaim());
                    totalClaimAmount = totalClaimAmount + totalAmount;
                    claimNumber = self.getClaimNumberInClaimTransacton(claim, ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS]);

                    productList.push({
                        'name': 'Not Sure',
                        'price': totalAmount,
                        'brand': brand,
                        'dimension1': claimNumber,
                        'dimension6': originTransaction,
                        'dimension20': notifyAgent,
                        'quantity': '1',
                    });
                    break;
            }
        }, this);

        this.setTotalEstimatedAmount(totalClaimAmount);
        return productList;
    }

    getGAMotorClaimProductSummary(claim: Claim, brand: string, originTransaction: string, notifyAgent: string): any {
        const productList: any = [];
        let self = this;
        let claimTypeCountry: string ="";
        let totalClaimAmount: number = 0;
        let claimSelectedList = claim.getSelectedClaimTypesList();
        let claimMotor: ClaimMotor = claim.getClaimMotor();
        let makeAndModel = claimMotor.getConfirmMakeAndModel();
        let lodgedBy = claimMotor.getIsLodgeByPolicyHolder() ? originTransaction : "Repairer - " + claimMotor.getRepairerName();

            claimSelectedList.forEach(function (claimTypeSelected) {
                switch (claimTypeSelected) {
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]:
                        let windscreen = claimMotor.getWindscreen();
                        let damageItems: string = windscreen.getDetailsOfDamagedItem().join(", ");
                        let damageCause = windscreen.getCauseOfDamage().toLowerCase() !== "Others" ? windscreen.getCauseOfDamage() : "Others - " + windscreen.getOtherCauseOfDamage();
                        claimTypeCountry = self.uiCountryService.getCountryDescription(windscreen.getCountryOccured());
                        let repairerName = windscreen.getRepairerName();
                        let repairCost = parseFloat(windscreen.getRepairCost());
                        totalClaimAmount += repairCost;

                        if (claimMotor.getIsLodgeByPolicyHolder()) {
                           if (claim.getCountry() === "HKG" && windscreen.getRepairerName() === "Others") {
                               repairerName = "Others - " + windscreen.getRepairerOtherName();
                           } else if (claim.getCountry() === "HGI") {
                               repairerName =  "Others - " + windscreen.getRepairerName();
                           }
                        }

                        productList.push({
                            'name': 'Damage of Windscreen',
                            'variant': damageItems,
                            'price': repairCost,
                            'brand': brand,
                            'category': damageCause,
                            'dimension1': claimTypeCountry,
                            'dimension2': repairerName,
                            'dimension3': makeAndModel,
                            'dimension4': lodgedBy,
                            'dimension20': notifyAgent,
                            'quantity': '1',
                        });
                        break;
                    case ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_COLLISION]:
                       let motorCollision = claimMotor.getCollission();
                       claimTypeCountry = self.uiCountryService.getCountryDescription(motorCollision.getCountryOccurence());
                       let claimAmount = 0;
                       totalClaimAmount += claimAmount;
                       let vehicleDmgDetails = motorCollision.getDetailsOfDamagedItem().length > 0 ? motorCollision.getDetailsOfDamagedItem().join(", ") : "Vehicle Damage - None";
                       let thrdPrtyDetails = motorCollision.getDetailsOfDamagedType().length > 0 ?  "Third Party - " + motorCollision.getDetailsOfDamagedType().join(", ") : "Third Party - None";
                       let damageExtent = !UtilitiesService.isNullOrUndefined(motorCollision.getDamageExtent()) ? motorCollision.getDamageExtent() : "None" ;
                       let selectedRepairer =  !UtilitiesService.isNullOrUndefined(motorCollision.getSelectRepairer()) && motorCollision.getSelectRepairer() === "Others" ?
                           "Others - "  + motorCollision.getRepairerName() : null;

                        if (UtilitiesService.isNullOrUndefined(selectedRepairer) && motorCollision.getWillClaimRepairCost()) {
                            let selectedPanelRepairer = PanelRepairers.find(repairer => repairer.value.toLowerCase() === motorCollision.getSelectRepairer().toLowerCase());
                            selectedRepairer = selectedPanelRepairer.description;
                        }

                        let damageRepairer = !UtilitiesService.isNullOrUndefined(selectedRepairer) ? selectedRepairer : "None";

                        productList.push({
                           'name': 'Motor Accident / Damage',
                           'variant': vehicleDmgDetails,
                           'price': claimAmount,
                           'brand': brand,
                           'dimension1': claimTypeCountry,
                           'dimension2': damageRepairer,
                           'dimension3': makeAndModel,
                           'dimension4': lodgedBy,
                           'dimension5': damageExtent,
                           'dimension6': thrdPrtyDetails ,
                           'dimension20': notifyAgent,
                           'quantity': '1',
                       });
                       break;
                   case ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]:
                       let motorTheft = claimMotor.getTheft();
                       claimTypeCountry = self.uiCountryService.getCountryDescription(motorTheft.getAccidentDetails().getCountry());
                       let claimAmt = 0;
                       totalClaimAmount += claimAmt;

                       productList.push({
                           'name': 'Theft / Attempted Theft',
                           'price': claimAmt,
                           'brand': brand,
                           'dimension1': claimTypeCountry,
                           'dimension3': makeAndModel,
                           'dimension4': lodgedBy,
                           'dimension20': notifyAgent,
                           'quantity': '1',
                       });
                       break;
                    default: break;
                }
            }, this);

        this.setTotalEstimatedAmount(totalClaimAmount);
        return productList;
    }

    getGAHomeClaimProductSummary(claim: Claim, brand: string, originTransaction: string, notifyAgent: string): any {
        const productList: any = [];
        let newProduct = {};
        let self = this;
        let transactionInfo  = this.transactionInfoService.getTransactionInfo();
        let claimSelectedList = claim.getSelectedClaimTypesList();
        let claimHome: ClaimHome = claim.getClaimHome();
        let placeOfIncident = claimHome.getPlaceOfIncident();
        let category = claim.getProductDescription();
        let home: string = placeOfIncident === "outside" ? "Outside Home" : placeOfIncident === 'home' &&
        !claimHome.getIsHomeWhereIncidentOccurred() ? "Other insured address" : "Home";
        let items: string = self.getHomeItems(claimHome.getHomeDetails(), claimSelectedList[0]);
        let tpLiability: ThirdPartyLossDetails = claimHome.getHomeDetails().getThirdPartyLossDetails();
        let legalLiability: ThirdPartyLegalDetails = claimHome.getHomeDetails().getThirdPartyLegalDetails();
        let domesticLiability: DomesticEmployerLiability = claimHome.getHomeDetails().getDomesticEmployerLiability();
        let personalAccident: PersonalAccident = claimHome.getHomeDetails().getPersonalAccident();
        let responsibility: GenericResponsibility = claimHome.getHomeDetails().getGenericResponsibility();
        let estimatedAmount: number = self.getClaimTypeAmount(claimSelectedList[0], claim.getEstimatedAmountList());
        let productName = transactionInfo.getGaPageStep();
        let weatherType = claimSelectedList.indexOf(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_WEATHER]) !== -1 ?
            claimHome.getHomeDetails().getWeather().getTypeOfWeather(): 'NA';
        let lossOccurred = claimSelectedList.indexOf(ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]) !== -1 ?
            claimHome.getHomeDetails().getLostPersonalItem().getHowDamageOccurred() : 'NA';

        newProduct = {
            'name': productName,
            'variant': home,
            'price': estimatedAmount,
            'brand': brand,
            'category': category,
            'dimension1': items,
            'dimension2': 'Liability to Third Party - ' + self.getYesOrNoStr(tpLiability.getIsIncidentInvolve()),
            'dimension3': 'Legal Liability - ' + self.getYesOrNo(legalLiability.getHasAnyClaimMade()),
            'dimension4': 'Domestic Employer’s Liability - ' + self.getYesOrNo(domesticLiability.getWasHelperInjured()),
            'dimension5': 'Personal Accident - ' + self.getYesOrNo(personalAccident.getHasInjury()),
            'dimension6': 'Responsibility - ' + self.getYesOrNo(responsibility.getHasOtherPersonInvolved()),
            'dimension7': weatherType,
            'dimension8': lossOccurred,
            'dimension9': 'Property still safe - ' + self.getYesOrNo(claimHome.getHomeDetails().getIsThePropertySafeToStayIn()),
            'dimension13': 'Window Glass damaged - ' + self.getYesOrNo(claimHome.getHomeDetails().getIsThereWindowDamage()),
            'dimension14': 'Serious damage to structure - ' + self.getYesOrNo(claimHome.getHomeDetails().getIsThereSeriousDamage()),
            'dimension15': originTransaction,
            'dimension20': notifyAgent,
            'quantity': '1',
        };

        productList.push(newProduct);
        this.setTotalEstimatedAmount(estimatedAmount);
        return productList;
    }

    getGAGenericProductSummary(claim: Claim, brand: string, originTransaction: string, notifyAgent: string): any {
        const productList: any = [];
        let claimTypeCountry: string = this.uiCountryService.getCountryDescription(claim.getClaimEventOccurred());
        let totalClaimAmount = parseFloat(claim.getAmountClaimed());
        let productDesc = claim.getProductDescription();

        productList.push({
            'name': productDesc,
            'price': totalClaimAmount,
            'brand': brand,
            'dimension1': claimTypeCountry,
            'dimension2': originTransaction,
            'dimension20': notifyAgent,
            'quantity': '1',
        });

        this.setTotalEstimatedAmount(totalClaimAmount);
        return productList;
    }

    getGAAccidentClaimSummary(claim: Claim, brand: string, originTransaction: string, notifyAgent: string): any {
        const productList: any = [];
        let self = this;
        let transactionInfo = self.transactionInfoService.getTransactionInfo();
        let claimPa = claim.getClaimPersonalAccident();
        let category = claim.getProductDescription();
        let claimType = transactionInfo.getGaPageStep();
        let selectedClaimType = claim.getSelectedClaimTypesList();
        let estimatedAmount: number = self.getClaimTypeAmount(selectedClaimType[0], claim.getEstimatedAmountList());
        let injuredPartsList = claimPa.getInjuryDetails().getInjuredPartsDescList().length > 0 ?
            claimPa.getInjuryDetails().getInjuredPartsDescList().join(", ") : 'N/A';
        let natureOfInjury = claimPa.getInjuryDetails().getNatureInjury() === 'Other' ? claimPa.getInjuryDetails().getNatureInjuryOthers() :
            claimPa.getInjuryDetails().getNatureInjury();
        let stayedInIcu = 'N/A';
        let outpatient = 'N/A';
        let stayedInHospital = 'N/A';
        let requireSurgery = 'N/A';

        if (selectedClaimType.indexOf(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES]) !== -1) {
            stayedInHospital = 'Hospital - ' + self.getYesOrNo(claimPa.getAccidentalMedicalExpenses().getWasAdmitted());
            if (claimPa.getAccidentalMedicalExpenses().getWasAdmitted()) {
                stayedInIcu = 'Intensive Care or Quarantine - ' + self.getYesOrNo(claimPa.getAccidentalMedicalExpenses().getStayedIn());
                outpatient = !UtilitiesService.isNullOrUndefined(claimPa.getAccidentalMedicalExpenses().getOutpatientConsultation()) ?
                    'Outpatient - ' + self.getYesOrNo(claimPa.getAccidentalMedicalExpenses().getOutpatientConsultation().getHasOutpatientConsultations()) :
                    'N/A';
            }
        } else if (selectedClaimType.indexOf(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]) !== -1 &&
                    !UtilitiesService.isNullOrUndefined(claimPa.getHospitalCash().getRequiredSurgery())) {
            requireSurgery = 'Surgery - ' + self.getYesOrNoStr(claimPa.getHospitalCash().getRequiredSurgery());
        }

        productList.push({
            'name': claimType,
            'variant': 'Personal Accident',
            'price': estimatedAmount,
            'brand': brand,
            'category': category,
            'dimension1': claimPa.getAccidentDetails().getOccupation(),
            'dimension2': claimPa.getAccidentDetails().getJobTitle(),
            'dimension3': injuredPartsList,
            'dimension4': natureOfInjury,
            'dimension5': stayedInIcu,
            'dimension6': outpatient,
            'dimension7': requireSurgery,
            'dimension9': stayedInHospital,
            'dimension19': originTransaction,
            'dimension20': notifyAgent,
            'quantity': '1',
        });

        this.setTotalEstimatedAmount(estimatedAmount);
        return productList;
    }

    getClaimNumberInClaimTransacton(claim: Claim, claimTypeCode: string): string {
        let claimNumber: string = "";
        if(claim.getClaimResponseList().length > 0) {
            let claimResponseList = claim.getClaimResponseList();
            claimResponseList.forEach(function (claimResponseItem) {
                if(claimResponseItem.getSelectedClaimTypesList().indexOf(claimTypeCode) !== -1) {
                    claimNumber = claimResponseItem.getClaimNumber();
                    if (UtilitiesService.isNullOrUndefined(claimNumber) || claimNumber  == "") {
                        claimNumber = claimResponseItem.getNotificationNumber();
                    }
                }
            });
        }
         return claimNumber;
    }

    getHomeItems(homeDetails: HomeDetails, claimType: string): string {
        let temp: string[] = [];
        switch(claimType) {
            case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_LOST]:
                let lostItems = homeDetails.getLostPersonalItem().getDetailsOfLostItemsArray();
                if (!UtilitiesService.isNullOrUndefined(lostItems) && lostItems.length > 0) {
                    lostItems.forEach(function (i) {
                        temp.push(i.getLossType());
                    });
                }
                break;

            case ClaimTypes[ClaimTypes.CLAIM_TYPE_BAG_DMG]:
                let dmgItems = homeDetails.getDamagePersonalItem().getDetailsOfDamageItemsArray();
                if (!UtilitiesService.isNullOrUndefined(dmgItems) && dmgItems.length > 0) {
                    dmgItems.forEach(function (i) {
                        temp.push(i.getLossType());
                    });
                }
                break;

            default:
                let homeItems = homeDetails.getHomeItems();
                if (!UtilitiesService.isNullOrUndefined(homeItems) && homeItems.length > 0) {
                    homeItems.forEach(function (i) {
                        temp.push(i.getLossType());
                    });
                }
                break;
        }

        if (!UtilitiesService.isNullOrUndefined(temp) && temp.length > 0) {
            return temp.join(", ");
        }
        return 'NA';
    }

    getYesOrNo(value: boolean): string {
        if (value === true) {
            return 'Yes';
        } else if (value === false) {
            return 'No';
        }
        return 'NA';
    }

    getYesOrNoStr(value: string): string {
        if (value === 'true') {
            return 'Yes';
        } else if (value === 'false') {
            return 'No';
        }
        return value;
    }

    getClaimTypeAmount(claimType: string, claimAmountList: string[]): number {
        let amount = 0;
        claimAmountList.forEach((item)=>{
           if (item.indexOf(claimType) !== -1) {
               let claimAmount = item.split(';');
               if (claimAmount.length > 1 && !UtilitiesService.isNullOrUndefined(claimAmount[1])) {
                  amount = parseFloat(claimAmount[1]);
               }
           }
        });
        return amount;
    }

    getTransOrigin(transOrigin: string): string {
        if(!UtilitiesService.isNullOrUndefined(transOrigin) && transOrigin !== ""){
            if (transOrigin.toLowerCase().startsWith("internaluser")) {
                return "internaluser";
            } else if (transOrigin.toLowerCase().startsWith("qnectinternal")) {
                return "staff";
            } else if (transOrigin.toLowerCase().startsWith("qnect")) {
                return "agent";
            }
        }
        return "customer";
    }
}

