import { Component } from '@angular/core';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
  selector: 'app-form2',
  templateUrl: './form2.component.html'
})
export class Form2Component {

  constructor( private transactionInfoService: TransactionInfoService) {
      this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]);
  }

}


