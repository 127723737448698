<ng-container *ngIf="matchedInsuredPerson === null">
<div *ngIf="this.insuredPersonList.length > 0 || transactionInfo.isTravelCorporatePolicyHolder()" class="col-12">

    <div class="row insuredBox col-12">
        <div class="form-group">
            <qnect-ui #MORE_INSURED [fieldId]="'MORE_INSURED'" [formGroup]="moreInsuredForm" name="moreInsured"></qnect-ui>
        </div>
    </div>

    <div class="row" *ngIf="showAdditionalClaimant==true">
        <div class="col-xl-12 col-md-8">
            <div class="form-group">
                <div  class="section-content">
                    <p *ngIf="transactionInfo.isDomesticHelperProduct() || transactionInfo.isTravelPersonal()"
                        class="searchPerson-desc other-description" >{{ 'additionalClaimant.selectOthers' | translate}}</p>
                    
                    <div *ngIf="transactionInfo.isDomesticHelperProduct() || transactionInfo.isTravelPersonal() || transactionInfo.isTravelCorporateInsuredPerson() || 
                        (transactionInfo.isTravelCorporatePolicyHolder() && this.isAddMoreInsured)"
                        class="row add-claim-content insuredBox col-12">
                        <div class="col-lg-4 col-md-12" *ngFor="let person of insuredPersonList; let i = index" (click)="chooseClaimant(person)">
                            <div class="cperson-wrapper text-center toggleActive" [class.active]="showAdditionalClaimant == true && checkInsuredPersonSelected(person) == true">
                                <div class="cpreson-outter">
                                    <h5 class="insuredNameWrap">{{person.getInsuredName()}}</h5>
                                    <span *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isDomesticHelperProduct()">{{person.getInsuredPersonNationalIdNumber() | formatHKID}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- OTHER INSURED PERSON ADD-->
                    <div *ngIf="transactionInfo.isDomesticHelperProduct() || transactionInfo.isTravelPersonal()"
                        class = "other-description other-insured">
                        <p class="searchPerson-desc" >{{ 'additionalClaimant.description4' | translate}}
                        <a href="javascript:;" (click)="showOtherContent()" class="click-more"> {{ 'additionalClaimant.description2' | translate}}</a>
                        </p>
                    </div>
                    <div *ngIf="transactionInfo.isTravelCorporatePolicyHolder() && this.isAddMoreInsured"
                        class = "other-description other-insured">
                        <p class="searchPerson-desc" >{{ 'additionalClaimant.description1' | translate}}
                        <a href="javascript:;" (click)="showOtherContent()" class="click-more"> {{ 'additionalClaimant.description2' | translate}}</a>
                        </p>
                    </div>

                    <div [style.display]="showMoreContent ? 'block' : 'none'">
                        <div class="col-lg-8 col-md-12">
                            <form class="add-claim-form" >
                                <div>
                                    <div class="form-group">
                                        <qnect-ui #OTHER_INSURED_PERSON_NAME [fieldId]="'OTHER_INSURED_PERSON_NAME'" [formGroup]="otherInsuredPersonForm" name="otherPersonName"></qnect-ui>
                                    </div>
                                    <div class="form-group" *ngIf="transactionInfo.getRealCountry()==='HKG'">
                                        <qnect-ui #OTHER_INSURED_PERSON_HKID_PASSPORT [fieldId]="'OTHER_INSURED_PERSON_HKID_PASSPORT'" [formGroup]="otherInsuredPersonForm" name="otherPersonIdType"></qnect-ui>
                                        <span *ngIf="this.otherIdType == 'hkid'"> <qnect-ui #OTHER_INSURED_PERSON_HKID_1 [fieldId]="'OTHER_INSURED_PERSON_HKID_1'" [formGroup]="otherInsuredPersonForm" [hkidValidator]="'hkid'" name="otherPersonHKID"></qnect-ui></span>
                                        <span *ngIf="this.otherIdType == 'passport'"> <qnect-ui #OTHER_INSURED_PERSON_PASSPORT [fieldId]="'OTHER_INSURED_PERSON_PASSPORT'" [formGroup]="otherInsuredPersonForm" name="otherPersonHKID"></qnect-ui></span>
                                    </div>
                                    <div class="form-group" *ngIf="transactionInfo.getRealCountry()!=='HKG'">
                                        <qnect-ui #OTHER_INSURED_PERSON_HKID [fieldId]="'OTHER_INSURED_PERSON_HKID'" [formGroup]="otherInsuredPersonForm" name="otherPersonHKID"></qnect-ui>
                                    </div>
                                    <div class="form-group" (click)="scrollIntoView(insureddobsection)" >
                                        <qnect-ui #OTHER_INSURED_PERSON_DOB [fieldId]="'OTHER_INSURED_PERSON_DOB'" [formGroup]="otherInsuredPersonForm" name="otherPersonDOB"></qnect-ui>
                                    </div>
                                </div>

                                <div class="form-group pad-top5" #insureddobsection>
                                    <input type="button" (click)="addInsuredPerson()" class="btn btn-secondary new-btn add-claim-btn btn-save-insured"
                                           value="{{ 'pageSelectInsured.body.save' | translate }}">
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- OTHER INSURED PERSON ADD-->

                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-4">
        </div>
    </div>
</div>
</ng-container>

