import {StayInHospital} from "./travel/stay-in-hospital.model";
import {VisitedDoctor} from "./travel/visted-a-doctor.model";
import { TravelDelay } from "./travel/travel-delay.model";
import { RentalVehicle } from "./travel/rental-vehicle.model";
import {PersonalLiability} from "./travel/personal-liability.model";
import { DeathAndSeriousBodyInjuryLoss } from "./travel/death-and-serious-body-injury-loss";
import {LostPersonalItem} from "./travel/lost-personal-item.model";
import {DamagePersonalItem} from "./travel/damage-personal-item.model";
import {TravelReRoute} from "./travel/travel-reroute.model";
import {OtherClaim} from "./travel/other-claim.model";
import {MissConnection} from "./travel/miss-connection.model";
import {TripCancellation} from "./travel/trip-cancellation.model";
import {MissedEvent} from "./travel/missed-event.model";
import {TripCurtailment} from "./travel/trip-curtailment.model";
import {BaggageDelay} from "src/app/model/travel/baggage-delay.model";
export class ClaimTravel {

    private claimantInsurer: string;
    private insurerPolicyHolder: string;
    private originalDepartureDate: Date;
    private originalReturnDate: Date;
    private policyHolderDateOfBirth: Date;
    private previousTravelClaims: string;
    private periodOfInsurance: string;
    private areaCode: string;
    private policyStartDate: Date;
    private coverType: string = 'S';
    private gbaPlanType: string;

    // Baggage Delay
    private baggageDelay: BaggageDelay = new BaggageDelay();

    // Lost personal Item
    private lostPersonalItem: LostPersonalItem = new LostPersonalItem();

    // Damage personal Item
    private damagePersonalItem: DamagePersonalItem = new DamagePersonalItem();

    // Stay in hospital
    private stayInHospital : StayInHospital = new StayInHospital();

    // Visited a Doctor
    private visitedDoctor : VisitedDoctor = new VisitedDoctor();

    //Travel Delay
    private travelDelay : TravelDelay = new TravelDelay();

    //Rental Vehicle
    private rentalVehicle : RentalVehicle = new RentalVehicle();

    //Personal Liability
    private personalLiability : PersonalLiability = new PersonalLiability();

    //Death And Serious Body Injury Loss
    private deathAndSeriousBodyInjuryLoss : DeathAndSeriousBodyInjuryLoss = new DeathAndSeriousBodyInjuryLoss();

    //Travel Rerouting
    private travelReRoute : TravelReRoute = new TravelReRoute();

    //Missed Connection
    private missConnection : MissConnection = new MissConnection();

    //Trip Cancellation
    private tripCancellation : TripCancellation = new TripCancellation();

    //Missed Event
    private missedEvent : MissedEvent = new MissedEvent();

    //Other Claim
    private otherClaim : OtherClaim = new OtherClaim();

    //Trip Curtailment
    private tripCurtailment: TripCurtailment = new TripCurtailment();

    getOriginalDepartureDate(): Date {
        return this.originalDepartureDate;
    }

    setOriginalDepartureDate(value: Date) {
        this.originalDepartureDate = value;
    }

    getOriginalReturnDate(): Date {
        return this.originalReturnDate;
    }

    setOriginalReturnDate(value: Date) {
        this.originalReturnDate = value;
    }

    getPolicyHolderDateOfBirth(): Date {
        return this.policyHolderDateOfBirth;
    }

    setPolicyHolderDateOfBirth(value: Date) {
        this.policyHolderDateOfBirth = value;
    }

    getPreviousTravelClaims(): string {
        return this.previousTravelClaims;
    }

    setPreviousTravelClaims(value: string) {
        this.previousTravelClaims = value;
    }

    getClaimantInsurer(): string {
        return this.claimantInsurer;
    }

    setClaimantInsurer(value: string) {
        this.claimantInsurer = value;
    }

    getInsurerPolicyHolder(): string {
        return this.insurerPolicyHolder;
    }

    setInsurerPolicyHolder(value: string) {
        this.insurerPolicyHolder = value;
    }

    getBaggageDelay(): BaggageDelay {
        return this.baggageDelay;
    }

    setBaggageDelay(value: BaggageDelay) {
        this.baggageDelay = value;
    }

    getDamagePersonalItem(): DamagePersonalItem {
        return this.damagePersonalItem;
    }

    setDamagePersonalItem(value: DamagePersonalItem) {
        this.damagePersonalItem = value;
    }

    getLostPersonalItem(): LostPersonalItem {
        return this.lostPersonalItem;
    }

    setLostPersonalItem(value: LostPersonalItem) {
        this.lostPersonalItem = value;
    }

    getStayInHospital(): StayInHospital {
        return this.stayInHospital;
    }

    setStayInHospital(value: StayInHospital) {
        this.stayInHospital = value;
    }

    getVisitedDoctor(): VisitedDoctor {
        return this.visitedDoctor;
    }

    setVisitedDoctor(value: VisitedDoctor) {
        this.visitedDoctor = value;
    }

    getTravelDelay(): TravelDelay {
        return this.travelDelay;
    }

    setTravelDelay(value: TravelDelay) {
        this.travelDelay = value;
    }

    getRentalVehicle(): RentalVehicle{
        return this.rentalVehicle;
    }

    setRentalVehicle(value : RentalVehicle){
         this.rentalVehicle = value;
    }

    getPersonalLiability(): PersonalLiability {
        return this.personalLiability;
    }

    setPersonalLiability(value: PersonalLiability) {
        this.personalLiability = value;
    }

    getDeathAndSeriousBodyInjuryLoss(): DeathAndSeriousBodyInjuryLoss {
        return this.deathAndSeriousBodyInjuryLoss;
    }

    setDeathAndSeriousBodyInjuryLoss(value: DeathAndSeriousBodyInjuryLoss) {
        this.deathAndSeriousBodyInjuryLoss = value;
    }

    getTravelReRoute(): TravelReRoute {
        return this.travelReRoute;
    }

    setTravelReRoute(value: TravelReRoute) {
        this.travelReRoute = value;
    }
    
    getTripCancellation(): TripCancellation {
        return this.tripCancellation;
    }
    
    setTripCancellation(value: TripCancellation) {
        this.tripCancellation = value;
    }

    getOtherClaim(): OtherClaim {
        return this.otherClaim;
    }

    setOtherClaim(value: OtherClaim) {
        this.otherClaim = value;
    }

    getMissConnection(): MissConnection {
        return this.missConnection;
    }

    setMissConnection(value: MissConnection) {
        this.missConnection = value;
    }

    getMissedEvent(): MissedEvent {
        return this.missedEvent;
    }

    setMissedEvent(value: MissedEvent) {
        this.missedEvent = value;
    }

    getTripCurtailment(): TripCurtailment{
        return this.tripCurtailment
    }

    setTripCurtailment(value : TripCurtailment){
         this.tripCurtailment = value;
    }

    getPeriodOfInsurance(): string {
        return this.periodOfInsurance;
    }

    setPeriodOfInsurance(value: string) {
        this.periodOfInsurance = value;
    }

    getAreaCode(): string {
        return this.areaCode;
    }

    setAreaCode(value: string) {
        this.areaCode = value;
    }

    getPolicyStartDate(): Date {
        return this.policyStartDate;
    }

    setPolicyStartDate(value: Date) {
        this.policyStartDate = value;
    }

    getCoverType(): string {
        return this.coverType;
    }

    setCoverType(value: string) {
        this.coverType = value;
    }

    getGBAPlanType(): string {
        return this.gbaPlanType;
    }

    setGBAPlanType(value: string) {
        this.gbaPlanType = value;
    }

    static jsonConvertClaimTravel(claimTravel: ClaimTravel): ClaimTravel {

        claimTravel = Object.assign(new ClaimTravel(), claimTravel);

        if (claimTravel != null) {

            if (claimTravel.getTravelDelay()) {
                    claimTravel.setTravelDelay(Object.assign(new TravelDelay(), claimTravel.getTravelDelay()));
                    TravelDelay.jsonConvert(claimTravel.getTravelDelay());
            }
            if (claimTravel.getBaggageDelay()) {
                    claimTravel.setBaggageDelay(Object.assign(new BaggageDelay(), claimTravel.getBaggageDelay()));
                    BaggageDelay.jsonConvert(claimTravel.getBaggageDelay());
            }
            if (claimTravel.getLostPersonalItem()) {
                    claimTravel.setLostPersonalItem(Object.assign(new LostPersonalItem(), claimTravel.getLostPersonalItem()));
                    LostPersonalItem.jsonConvert(claimTravel.getLostPersonalItem());
            }
            if (claimTravel.getDamagePersonalItem()) {
                    claimTravel.setDamagePersonalItem(Object.assign(new DamagePersonalItem(), claimTravel.getDamagePersonalItem()));
                    DamagePersonalItem.jsonConvert(claimTravel.getDamagePersonalItem());
            }
            if (claimTravel.getStayInHospital()) {
                    claimTravel.setStayInHospital(Object.assign(new StayInHospital(), claimTravel.getStayInHospital()));
                    StayInHospital.jsonConvert(claimTravel.getStayInHospital());
            }
            if (claimTravel.getVisitedDoctor()) {
                    claimTravel.setVisitedDoctor(Object.assign(new VisitedDoctor(), claimTravel.getVisitedDoctor()));
                    VisitedDoctor.jsonConvert(claimTravel.getVisitedDoctor());
            }
            if (claimTravel.getRentalVehicle()) {
                    claimTravel.setRentalVehicle(Object.assign(new RentalVehicle(), claimTravel.getRentalVehicle()));
                    RentalVehicle.jsonConvert(claimTravel.getRentalVehicle());
            }
            if (claimTravel.getPersonalLiability()) {
                    claimTravel.setPersonalLiability(Object.assign(new PersonalLiability(), claimTravel.getPersonalLiability()));
                    PersonalLiability.jsonConvert(claimTravel.getPersonalLiability());
            }
            if (claimTravel.getDeathAndSeriousBodyInjuryLoss()) {
                    claimTravel.setDeathAndSeriousBodyInjuryLoss(Object.assign(new DeathAndSeriousBodyInjuryLoss(), claimTravel.getDeathAndSeriousBodyInjuryLoss()));
                    DeathAndSeriousBodyInjuryLoss.jsonConvert(claimTravel.getDeathAndSeriousBodyInjuryLoss());
            }
            if (claimTravel.getTravelReRoute()) {
                    claimTravel.setTravelReRoute(Object.assign(new TravelReRoute(), claimTravel.getTravelReRoute()));
                    TravelReRoute.jsonConvert(claimTravel.getTravelReRoute());
            }
            if (claimTravel.getMissConnection()) {
                    claimTravel.setMissConnection(Object.assign(new MissConnection(), claimTravel.getMissConnection()));
                    MissConnection.jsonConvert(claimTravel.getMissConnection());
            }
            if (claimTravel.getTripCancellation()) {
                    claimTravel.setTripCancellation(Object.assign(new TripCancellation(), claimTravel.getTripCancellation()));
                    TripCancellation.jsonConvert(claimTravel.getTripCancellation());
            }
            if (claimTravel.getMissedEvent()) {
                    claimTravel.setMissedEvent(Object.assign(new MissedEvent(), claimTravel.getMissedEvent()));
                    MissedEvent.jsonConvert(claimTravel.getMissedEvent());
            }
            if (claimTravel.getOtherClaim()) {
                    claimTravel.setOtherClaim(Object.assign(new OtherClaim(), claimTravel.getOtherClaim()));
                    OtherClaim.jsonConvert(claimTravel.getOtherClaim());
            }
            if (claimTravel.getTripCurtailment()) {
                    claimTravel.setTripCurtailment(Object.assign(new TripCurtailment(), claimTravel.getTripCurtailment()));
                    TripCurtailment.jsonConvert(claimTravel.getTripCurtailment());
            }

            if (claimTravel.getOriginalDepartureDate() != null) {
                claimTravel.setOriginalDepartureDate(new Date(claimTravel.getOriginalDepartureDate()));
            }
            if (claimTravel.getOriginalReturnDate() != null) {
                claimTravel.setOriginalReturnDate(new Date(claimTravel.getOriginalReturnDate()));
            }
            if (claimTravel.getPolicyHolderDateOfBirth() != null) {
                claimTravel.setPolicyHolderDateOfBirth(new Date(claimTravel.getPolicyHolderDateOfBirth()));
            }

            if (claimTravel.getTravelDelay()) {
                TravelDelay.jsonConvert(claimTravel.getTravelDelay());
            }

            if (claimTravel.getStayInHospital()) {
                StayInHospital.jsonConvert(claimTravel.getStayInHospital());
            }

            if (claimTravel.getVisitedDoctor()) {
                VisitedDoctor.jsonConvert(claimTravel.getVisitedDoctor());
            }

            if(claimTravel.getRentalVehicle()){
                RentalVehicle.jsonConvert(claimTravel.getRentalVehicle());
            }
            if (claimTravel.getPersonalLiability()) {
                PersonalLiability.jsonConvert(claimTravel.getPersonalLiability());
            }

            if (claimTravel.getDeathAndSeriousBodyInjuryLoss()) {
                DeathAndSeriousBodyInjuryLoss.jsonConvert(claimTravel.getDeathAndSeriousBodyInjuryLoss());
            }
            
            if (claimTravel.getTravelReRoute()) {
                TravelReRoute.jsonConvert(claimTravel.getTravelReRoute());
            }

            if (claimTravel.getOtherClaim()) {
                OtherClaim.jsonConvert(claimTravel.getOtherClaim());
            }

            if (claimTravel.getMissConnection()) {
                MissConnection.jsonConvert(claimTravel.getMissConnection());

            if(claimTravel.getTripCancellation()){
                TripCancellation.jsonConvert(claimTravel.getTripCancellation());
            }

            if(claimTravel.getMissedEvent()) {
                MissedEvent.jsonConvert(claimTravel.getMissedEvent());
            }

           if(claimTravel.getTripCurtailment()){
                TripCurtailment.jsonConvert(claimTravel.getTripCurtailment());
            }

           if (claimTravel.getPolicyStartDate() != null) {
               claimTravel.setPolicyStartDate(new Date(claimTravel.getPolicyStartDate()));

           }

        }

        return claimTravel;
     }
    }
}
