<ng-template #forgot_password_modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{'forgotPassword.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'forgotPassword.message' | translate }}</p><br/>
    <div class="form-group">
      <qnect-ui #MV_REPAIRER_EMAIL [fieldId]="'MV_REPAIRER_EMAIL'" [formGroup]="forgotPasswordForm" name="repairerEmail"
      [dynamicPlaceholder]="'forgotPassword.email'"></qnect-ui>
    </div>
  </div>
  <div class="mt-4 mb-4">
    <button class="new-btn btn-primary btn" (click)="handleSubmit();">
          {{'claimSection.generalLabels.generalButtonLabels.submit' | translate}} 
    </button>
  </div>
</ng-template>
