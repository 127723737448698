<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.damagePersonalItem.detailsOfDamage.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #DAMAGE_PERS_ITEM_01 [fieldId]="'DAMAGE_PERS_ITEM_01'" [formGroup]="detailOfDamageForm" [claimsDateValidator]="claim.getClaimTravel().getOriginalDepartureDate()" name="dateTheDamageOccurred"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="!claim.getClaimTravel().getGBAPlanType()">
          <qnect-ui #DAMAGE_PERS_ITEM_02 [fieldId]="'DAMAGE_PERS_ITEM_02'" [formGroup]="detailOfDamageForm" name="countryTheDamageOccurred"></qnect-ui>
        </div>
        <div class="form-group" *ngIf="claim.getClaimTravel().getGBAPlanType()">
          <qnect-ui #GBA_PLACE_OF_LOSS [fieldId]="'GBA_PLACE_OF_LOSS'" [formGroup]="detailOfDamageForm" name="placeOfLoss"
                    [dynamicLabel] ="'claimSection.damagePersonalItem.detailsOfDamage.whereDamageOccurred'"></qnect-ui>
        </div>
        <div class="form-group click-more-content" id="q1">
          <qnect-ui #DAMAGE_PERS_ITEM_03 [fieldId]="'DAMAGE_PERS_ITEM_03'" [formGroup]="detailOfDamageForm" name="howDamageOccurred"></qnect-ui>
          <div *ngIf="this.damagePersonalItem.getHowDamageOccurred() == 'Others'">
            <qnect-ui #DAMAGE_PERS_ITEM_04 [fieldId]="'DAMAGE_PERS_ITEM_04'" [formGroup]="detailOfDamageForm" name="otherDamageOccurred"></qnect-ui>
          </div>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>
    <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
