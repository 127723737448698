<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'paClaim.injuryDetails.title' | translate }}</h5>
    <br>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #PA_HAS_BEEN_INJURED [fieldId]="'PA_HAS_BEEN_INJURED'" [formGroup]="injuryDetailsForm" name="hasBeenInjured"></qnect-ui>
        </div>
        <ng-container *ngIf="this.claimPA.getInjuryDetails().getHasBeenInjured()">
          <div class="form-group">
            <label>{{'paClaim.injuryDetails.labelParts' | translate}}</label>
            <div id="bodyPartDiv" class="model front" data-right="" data-left="">
                <div id="control" (click)="flipImage();" style="background-image:url('assets/images/pa-body-parts/rotate-icon.svg')"></div>
                <div class="model-wrapper">
                
                <div id="frontDiv" class="front-container">
                    <img src="assets/images/pa-body-parts/front_body.png" alt=""/>
                    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                    <svg x="0px" y="0px" viewBox="0 0 422 760" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                        <rect x="0" y="0" width="422" height="760" style="fill:none;fill-rule:nonzero;"/>
                        <g class="part-of-body">
                            <g id="calf" transform="matrix(1,0,0,1,156,559)">
                                <g opacity="0.5">
                                    <g id="right-calf" (click)="this.selectBodyPart('right-calf');">
                                        <path d="M46.5,0C46.5,0 52.5,19.3 45.5,67.1C45.5,67.1 40.6,111.9 45.1,131.6C45.684,134.056 45.819,136.596 45.5,139.1L44.1,154.9C44.015,155.674 43.882,156.443 43.7,157.2L42.5,162.7C42.5,162.7 15,157.6 9,161.7C9,161.7 13.4,156.6 13.9,152.6C13.986,152.023 14.12,151.455 14.3,150.9L15.2,148.2C15.7,146.7 15.1,145 15.1,143.4C14.8,135.7 14.5,111.6 12,102.3C8.9,90.9 -2.1,9.7 0.4,0C0.4,0 25.5,12.2 46.5,0Z" style="fill:none;"/>
                                    </g>
                                    <g id="left-calf" (click)="this.selectBodyPart('left-calf');">
                                        <path d="M109.4,2C109.4,2 87.5,12.6 66.4,0.4C66.4,0.4 61.2,25.4 67.2,68C73.2,110.6 68.2,119.8 67.8,131.9C67.4,144 67.1,136.9 67.4,139.4L68.8,155.2C68.885,155.974 69.018,156.743 69.2,157.5L70.4,163C70.4,163 97.3,158.5 103.3,162.6C103.3,162.6 99,156.8 98.6,152.7C98.514,152.123 98.38,151.555 98.2,151C97.9,150.2 97.7,149.3 97.3,148.3C96.8,146.8 97.2,142.8 97.3,141.2C97.6,133.5 98.6,116.9 100.3,105.6C102.3,92.4 115.4,17.2 111.3,-0.1" style="fill:none;"/>
                                    </g>
                                </g>
                            </g>
                            <g id="thigh" opacity="0.5" (click)="this.selectBodyPart('thigh');">
                                <path d="M274.2,317C274.2,317 279.2,340 278.2,349L279.2,380C279.2,380 286,415 283,437C283,437 276,481 268,502C268,502 242.2,485 218.2,501C218.2,501 218.7,463.5 215.7,453.5C215.7,453.5 210.7,411.5 213.7,402.5C213.7,402.5 214,399 212,399C210,399 210,403 210,403C210,403 213,426 209,440C205,454 205.7,500.5 205.7,500.5C205.7,500.5 181.7,480.5 155.7,500.5C155.7,500.5 139.6,443.6 141.1,428.6C141.1,428.2 141.2,427.8 141.2,427.4C141.3,422.8 141.9,392.2 144.7,376.5C145.7,357.5 143.7,346.5 149.2,317C149.2,317 202.2,354 274.2,317Z" style="fill:none;"/>
                            </g>
                            <g id="knee" transform="matrix(1,0,0,1,156,495)">
                                <g opacity="0.5">
                                  <g id="right-knee" (click)="this.selectBodyPart('right-knee');">
                                    <path d="M0,8.3C0,8.3 26,-11.7 50,10.3C50,10.3 50,39 48,47C46,55 46,53 45,60.3C45,60.3 28,75.3 1,60.3C1,60.3 3,36.3 3,32.3C3,28.5 1.6,20.5 1.5,15.5C1.49,15.06 1.423,14.623 1.3,14.2L0,8.3Z" style="fill:none;"/>
                                  </g>
                                  <g id="left-knee" (click)="this.selectBodyPart('left-knee');">
                                      <path d="M112,9.6C112,9.6 86,-10.4 62,11.6C62,11.6 62,43 64,47C64,47 67,55 67,61.6C67,61.6 84,76.6 111,61.6C111,61.6 109,37.6 109,33.6C109,29.8 110.4,21.8 110.5,16.8C110.51,16.36 110.577,15.923 110.7,15.5L112,9.6Z" style="fill:none;"/>
                                  </g>  
                                </g>
                            </g>
                            <g id="toe" transform="matrix(1,0,0,1,152,726)">
                                <g opacity="0.5">
                                    <g id="right-toe" (click)="this.selectBodyPart('right-toe');">
                                        <path d="M0.3,12.3C0.3,12.3 -1.7,10.3 4.3,3.3C4.3,3.3 35.3,-3.7 39.3,3.3C39.3,3.3 48.3,13.3 33.3,18.3C33.3,18.3 31.3,21.3 22.3,19.3L20.8,17.8C20.8,17.8 16.8,20.8 13.8,17.8C13.8,17.8 9.8,18.8 8.8,16.8C8.8,16.8 5.3,18.3 4.8,15.8C4.3,16.3 -0.2,18.8 0.3,12.3" style="fill:none;"/>
                                    </g>
                                    <g id="left-toe" (click)="this.selectBodyPart('left-toe');">
                                        <path d="M120,12.5C120,12.5 122.1,10.4 115.9,3.2C115.9,3.2 83.8,-4.1 79.7,3.2C79.7,3.2 70.4,13.6 85.9,18.7C85.9,18.7 88,21.8 97.3,19.7L98.9,18.1C98.9,18.1 103,21.2 106.1,18.1C106.1,18.1 110.2,19.1 111.3,17.1C111.3,17.1 114.9,18.7 115.4,16.1C115.8,16.7 120.5,19.3 120,12.5" style="fill:none;"/>
                                    </g>
                                </g>
                            </g>
                            <g id="waist" opacity="0.5" (click)="this.selectBodyPart('waist');">
                                <path d="M150,236L274,236C274,236 267.7,248.7 268.5,261.5C268.5,261.9 268.2,270.7 269,274C270,278 276,294 274,306L274,313C274,313 206.5,345 150.5,313C150.5,313 148.1,289.3 153.7,277.1C153.9,276.7 154.9,273.4 155,273C156,269.1 157.5,258.5 153,244L150,236Z" style="fill:none;"/>
                            </g>
                            <g id="arms" opacity="0.5">
                                <g id="right-arm" (click)="this.selectBodyPart('right-arm');">
                                    <path d="M142,188L145,204C145.4,205.98 145.4,208.02 145,210L146,215L146,215.1C146,216.7 145.8,230.4 142.5,237.9C142.174,238.645 141.906,239.414 141.7,240.2C140.9,244.3 138.4,257.7 138.8,265.6C138.867,266.667 138.833,267.739 138.7,268.8C137.5,277.4 131.9,314.1 121.9,326.8C121.151,327.758 120.514,328.798 120,329.9C118,334.2 113.4,344.8 113.6,351C113.712,352.534 113.472,354.073 112.9,355.5L111,361L83,356C83,356 94.7,324.9 93.1,303C93.05,302.334 93.05,301.666 93.1,301C93.4,295.4 95.2,269.8 104.3,248.8C104.797,247.693 105.102,246.509 105.2,245.3C105.9,237.6 108.7,207.5 113,197.1C125,200 136,197 142,188Z" style="fill:none;"/>
                                </g>
                                <g id="left-arm" (click)="this.selectBodyPart('left-arm');">
                                    <path d="M282,188C288,197 298.5,200 310.5,197C314.8,207.4 317.7,237.5 318.3,245.2C318.398,246.409 318.703,247.593 319.2,248.7C328.3,269.7 330.1,295.3 330.4,300.9L330.4,302.9C328.8,324.9 340.5,355.9 340.5,355.9L312.5,360.9L310.4,355.4C309.909,353.952 309.672,352.429 309.7,350.9C309.9,344.7 305.3,334.1 303.3,329.8C302.823,328.709 302.182,327.698 301.4,326.8C291.4,314.1 285.8,277.3 284.6,268.8C284.467,267.739 284.433,266.667 284.5,265.6C284.9,257.7 282.4,244.3 281.6,240.2C281.439,239.4 281.17,238.627 280.8,237.9C277.4,230.2 277.8,217.6 277.8,217C277.8,216.4 278.7,213.3 278.8,213C278.9,212.5 279.1,210.2 279.3,207.7L279.3,207.1C279.5,204.1 279.7,201 279.7,201L282,188Z" style="fill:none;"/>
                                </g>
                            </g>
                            <g id="palm" transform="matrix(1,0,0,1,71,358)">
                                <g opacity="0.5">
                                    <g id="right-palm" (click)="this.selectBodyPart('right-palm');">
                                        <path d="M10.5,0C10.5,0 29.8,0.5 40.8,6.4C40.9,6.5 41,6.6 41,6.7L41,26C40.977,26.271 41.011,26.543 41.1,26.8C41.487,29.246 41.281,31.75 40.5,34.1C40.215,34.985 39.654,35.756 38.9,36.3L32.9,40.8C32.283,41.2 31.609,41.504 30.9,41.7L20.6,43.4C20.206,43.483 19.803,43.516 19.4,43.5L9.4,42.7C8.486,42.624 7.615,42.276 6.9,41.7L1.2,37.4C0.485,36.87 0.134,35.974 0.3,35.1C0.7,32.6 1.6,27.5 1.9,24.9C2.007,24.051 1.71,23.199 1.1,22.6L0,21.5C0,21.5 4,10.5 0,6.5L10.5,0Z" style="fill:none;"/>
                                    </g>
                                    <g id="left-palm" (click)="this.selectBodyPart('left-palm');">
                                        <path d="M271.5,0C271.5,0 252.2,0.5 241.2,6.4C241,6.5 241,6.6 241,6.7L241,26C241.023,26.271 240.989,26.543 240.9,26.8C240.513,29.246 240.719,31.75 241.5,34.1C241.785,34.985 242.346,35.756 243.1,36.3L249.1,40.8C249.717,41.2 250.391,41.504 251.1,41.7L261.4,43.4C261.794,43.483 262.197,43.516 262.6,43.5L272.6,42.7C273.514,42.624 274.385,42.276 275.1,41.7L280.8,37.4C281.515,36.87 281.866,35.974 281.7,35.1C281.3,32.6 280.4,27.5 280.1,24.9C280.011,24.083 280.307,23.269 280.9,22.7L282,21.6C282,21.6 278,10.6 282,6.6L271.5,0Z" style="fill:none;"/>
                                    </g>
                                </g>
                            </g>
                            <g id="finger" transform="matrix(1,0,0,1,59,365)">
                                <g opacity="0.5">
                                    <g transform="matrix(1,0,0,1,-59,-365)">
                                        <g id="right-finger" (click)="this.selectBodyPart('right-finger');">
                                            <path d="M59.2,390C58.651,387.951 59.016,385.761 60.2,384C61.384,382.239 60.2,376 64.2,373C64.2,373 66.2,366 70.2,365C70.2,365 73.2,365 71.2,379C71.2,379 66.2,394 59.2,390M103.2,400L111.2,394C111.2,394 111.2,403 112.2,404C113.2,405 112.2,422 110.2,421C110.2,421 107.2,423 105.2,411L103.2,400ZM81,402L91,403C91,403 88.2,420 86.2,424C86.2,424 84.2,438 79.2,435C79.2,435 76.2,435 79.2,418L81,402ZM70.2,395L78.2,401L67.2,425C67.2,425 61.2,431 60.2,422L70.2,395ZM91.2,403L101.2,401L100.2,413L98.2,431C98.2,431 94.2,438 91.2,432L91.2,403Z" style="fill:none;"/>
                                        </g>
                                    </g>
                                    <g transform="matrix(1,0,0,1,-59,-365)">
                                        <g id="left-finger" (click)="this.selectBodyPart('left-finger');">
                                            <path d="M364.442,390C364.991,387.951 364.626,385.761 363.442,384C362.258,382.239 363.442,376 359.442,373C359.442,373 357.442,366 353.442,365C353.442,365 350.442,365 352.442,379C352.442,379 357.442,394 364.442,390M353.442,395L345.442,401L356.442,425C356.442,425 362.442,431 363.442,422L353.442,395ZM342.642,402L332.642,403C332.642,403 335.442,420 337.442,424C337.442,424 339.442,438 344.442,435C344.442,435 347.442,435 344.442,418L342.642,402ZM332.442,403L322.442,401L323.442,413L325.442,431C325.442,431 329.442,438 332.442,432L332.442,403ZM320.442,400L312.442,394C312.442,394 312.442,403 311.442,404C310.442,405 311.442,422 313.442,421C313.442,421 316.442,423 318.442,411L320.442,400Z" style="fill:none;"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <g id="shoulder" transform="matrix(1,0,0,1,112,127)">
                                <g opacity="0.5">
                                    <g id="right-shoulder" (click)="this.selectBodyPart('right-shoulder');">
                                        <path d="M1.7,70C1.7,70 20.7,74 29.7,60C38.7,46 46.7,0 46.7,0C46.7,0 -10.3,-4 1.7,70Z" style="fill:none;"/>
                                    </g>
                                    <g id="left-shoulder" (click)="this.selectBodyPart('left-shoulder');">
                                        <path d="M153,0C153,0 210,-4 198,70C198,70 179,74 170,60C161,46 153,0 153,0" style="fill:none;"/>
                                    </g>
                                </g>
                            </g>
                            <g id="chest" opacity="0.5" (click)="this.selectBodyPart('chest');">
                                <path d="M156,143C153.733,158.521 149.36,173.661 143,188L149,233C151.117,232.756 153.16,232.075 155,231C162.1,223.9 192.3,225.2 211,225C229.7,225.2 259.9,221.9 271,233C273.7,234.7 275,234 275,234L281,190C275.4,175.7 272.1,165.1 271,158L265,128C247.606,133.904 229.368,136.944 211,137C176.4,136.6 159,127 159,127C159,127 158,132.3 156,143Z" style="fill:none;"/>
                            </g>
                            <g id="head" opacity="0.5" (click)="this.selectBodyPart('head');">
                                <path d="M185.1,89.3C188.6,95.4 200,103.8 210.1,104.3C223,104.9 235.9,97.1 238.1,90.3C242.1,78.3 258.1,27.3 224.1,12.3C218.1,9.3 209.1,9.3 201.6,11.8C194.1,14.3 167.1,22.3 185.1,89.3" style="fill:none;"/>
                            </g>
                            <g id="neck" opacity="0.5" (click)="this.selectBodyPart('neck');">
                                <path d="M214.5,104C229.5,103.1 238.5,91 238.5,91C238.5,91 237.1,99.2 238.1,105.8C238.641,109 240.353,111.888 242.9,113.9C248.3,118 253,121 265,127C265,127 218.5,146.5 159,127C166.809,123.264 174.33,118.952 181.5,114.1C184.1,112.4 184.9,109.7 185.4,107C186.2,102.4 187.2,94.9 185.5,90C185.5,90 198.5,105 214.5,104Z" style="fill:none;"/>
                            </g>
                        </g>
                    </svg>
                </div>
            
                <div id="backDiv" class="back-container" style="display:none">
                    <img src="assets/images/pa-body-parts/back_body.png" alt=""/>
                    <svg id="body_part_back_transparent" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  viewBox="0 0 422 760">
                        <g class="part-of-body">
                            <g id="ankle" transform="translate(169 677)" opacity="0.5">
                                <g id="right-ankle" (click)="this.selectBodyPart('right-ankle');">
                                    <path d="M3.4,1.3C8.3.2,17.6-1.1,26.1,1.3a4.315,4.315,0,0,1,3,3.5c.5,4.5,1.7,13.5,2.4,15.8a3.4,3.4,0,0,1,.2,1.4c0,2.6-.2,9.8-1,13.1-1,3.8-.1,6.7-1.8,12.3a7.719,7.719,0,0,0-.2,1.5c.1,3,2,12.3-3,14.3,0,0-12.5,4.2-23,.3A4.247,4.247,0,0,1,0,59.3c.5-5.7,1.4-18.2.9-23.1a5.105,5.105,0,0,1,.3-2.1l.4-.8a3.854,3.854,0,0,0,.2-2.6c-.3-1.2-.6-3.9-.6-9.8v-.3L.1,5.8A4.231,4.231,0,0,1,3.4,1.3" style="fill:none;"/>
                                </g>
                                <g id="left-ankle" (click)="this.selectBodyPart('left-ankle');">
                                    <path d="M81.4,1.3C76.5.2,67.2-1.1,58.7,1.3a4.315,4.315,0,0,0-3,3.5c-.5,4.5-1.7,13.5-2.4,15.8a3.4,3.4,0,0,0-.2,1.4c0,2.6.2,9.8,1,13.1,1,3.8.1,6.7,1.8,12.3a7.719,7.719,0,0,1,.2,1.5c-.1,3-2,12.3,3,14.3,0,0,12.5,4.2,23,.3a4.05,4.05,0,0,0,2.7-4.3c-.5-5.7-1.4-18.2-.9-23.1a5.105,5.105,0,0,0-.3-2.1l-.4-.8a3.854,3.854,0,0,1-.2-2.6c.3-1.2.6-3.9.6-9.8v-.3L84.8,5.7a4.375,4.375,0,0,0-3.4-4.4" style="fill:none;"/>
                                </g>
                            </g>
                            <g id="back" opacity="0.5" (click)="this.selectBodyPart('back');">
                                <path d="M280.4,202c9-45,3-76,3-76-13-1-18-5-18-5l-23-12c-.2.1-.3.1-.5.2V109a76.113,76.113,0,0,1-30.8,7.1,76.113,76.113,0,0,1-30.8-7.1v.2c-.2-.1-.3-.1-.5-.2l-23,12s-5,4-18,5c0,0-6,31,3,76,0,0,3,34,11,44,0,0,5,20,0,35,0,0-6,17-3,29H272.2c3-12-3-29-3-29-5-15,0-35,0-35,8.2-10,11.2-44,11.2-44" style="fill:none;"/>
                            </g>
                            <g id="elbow" transform="translate(93 245)" opacity="0.5">
                                <g id="right-elbow" (click)="this.selectBodyPart('right-elbow');">
                                    <path d="M8,7.1s15-16,37,0c0,0,1,25-3,35,0,0-19,16-42-2,0,0,3-23,8-33" style="fill:none;"/>
                                </g>
                                <g id="left-elbow" (click)="this.selectBodyPart('left-elbow');">
                                    <path d="M228.7,7.1s-15-16-37,0c0,0-1,25,3,35,0,0,19,16,42-2,0,0-3-23-8-33" style="fill:none;"/>
                                </g>
                            </g>
                        </g>
                        <path d="M0,0H422V760H0Z" fill="none"/>
                    </svg>
                </div>
            </div>
            </div>
          
            <div *ngIf="this.claimPA.getInjuryDetails().getHasBeenInjured() && this.selectBodyPartStatus === 'none'">
              <small id="error-message">{{'error_messages.required.selectValue' | translate}}</small>
            </div>
          </div>
          <div class="form-group">
            <qnect-ui #PA_INTERNAL_INJURY [fieldId]="'PA_INTERNAL_INJURY'" [formGroup]="injuryDetailsForm" name="isInternalInjury"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #PA_NATURE_INJURY [fieldId]="'PA_NATURE_INJURY'" [formGroup]="injuryDetailsForm" name="natureInjury"></qnect-ui>
          </div>
          <div class="form-group" *ngIf="showOthers(claimPA.getInjuryDetails().getNatureInjury())">
            <qnect-ui #PA_NATURE_INJURY_OTHERS [fieldId]="'PA_NATURE_INJURY_OTHERS'" [formGroup]="injuryDetailsForm" name="natureInjuryOthers"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #PA_HAS_ENCOUNTERED_SIMILAR [fieldId]="'PA_HAS_ENCOUNTERED_SIMILAR'" [formGroup]="injuryDetailsForm" name="hasEncounteredSimilarIncident"></qnect-ui>
          </div>
          <div *ngIf="this.claimPA.getInjuryDetails().getHasEncounteredSimilarIncident()" class="form-group">
            <qnect-ui #PA_PLACE_OF_INCIDENT [fieldId]="'PA_PLACE_OF_INCIDENT'" [formGroup]="injuryDetailsForm" name="placeOfIncident"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary">{{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<em class="fas fa-arrow-right"></em></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>

<ng-template #confirm_delete_claim_type let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h5 class="modal-title">{{'homeProduct.deleteClaim.header' | translate }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p>{{'homeProduct.deleteClaim.question1' | translate }}</p>
      <span><p>{{'homeProduct.deleteClaim.question2' | translate }}</p></span>
  </div>
  <div class="modal-footer">
      <div class="col-md-6 text-right">
          <button type="button" class="btn btn-primary btn-sm" (click)="deleteClaimType()">{{'homeProduct.deleteClaim.yes' | translate }}</button>
      </div>
      <div class="col-md-6 text-left">
          <button type="button" class="btn btn-default btn-sm" (click)="c('Close click')">{{'homeProduct.deleteClaim.no' | translate }}</button>
      </div>
  </div>
</ng-template>

