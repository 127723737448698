import { Component, OnInit } from '@angular/core';
import { ClaimDocumentsComponent } from 'src/app/claim-pages/claim-documents/claim-documents.component';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimService } from 'src/app/services/claim.service';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { HttpClient } from '@angular/common/http';
import { DocumentFormFactoryService } from 'src/app/services/document-form-factory.service';
import { SpinnerService } from 'src/app/core/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-theft-upload',
  templateUrl: './theft-upload.component.html',
  styleUrls: ['./theft-upload.component.css']
})
export class TheftUploadComponent extends ClaimDocumentsComponent implements OnInit {

  public upLoadFileModel: FormGroup;
  showTotalBox = false;
  
  constructor(private fb: FormBuilder,
      public sideMenuService: SideMenuService, 
      claimService: ClaimService,
      private activatedRoute: ActivatedRoute,
      private router: Router, 
      documentHelperService: DocumentHelperService,
      transactionInfoService: TransactionInfoService,
      http: HttpClient,
      documentFormFactoryService: DocumentFormFactoryService, spinnerService: SpinnerService, modalService: NgbModal) {
              super(claimService, documentHelperService, transactionInfoService, http, documentFormFactoryService, spinnerService, modalService);
              this.setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_MV_THEFT]);
  }

  ngOnInit() {

      this.pushGA();

      this.upLoadFileModel = this.fb.group(
          {
              airTicket: [],
              carrierCertificate: [],
              otherItems: this.fb.array([this.createItem()])

          });
          super.ngOnInit();
  }

  addFile() {
      let items = this.upLoadFileModel.get('otherItems') as FormArray;
      items.push(this.createItem());
  }

  createItem(): FormGroup {
      return this.fb.group({
          otherFile: [],
      });
  }

  showModal() {
      this.showTotalBox = true;
  }

  getData(msg) {
      this.showTotalBox = msg;
  }

  goToNext() {
      this.sideMenuService.emitProcess(1, 0);
      this.sideMenuService.emitClaimComplete({claimTypeId: 2, subMenuIndex: 2});
      this.sideMenuService.emitComplete('finishChooseClaim');
      this.router.navigate(["/claimform/anotherInsurance"], {
        relativeTo: this.activatedRoute
    });

  }

    // Google Analytics
    pushGA() {
        (<any>window).dataLayer.push({
            'pageStep': 'Theft/Attempt Theft – Document Upload',
            'vPath': '/claim/theft-attempt-theft/documents',
            'event': 'vpageview',
        });
    }

}
