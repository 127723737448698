import { Component, OnInit, Injector, ViewChild, TemplateRef } from '@angular/core';
import { ClaimsBaseComponent } from '../claim-pages/claim-base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TransactionInfoService } from '../services/transaction-info.service';
import { TransactionInfo } from '../model/transaction-info.model';
import { UtilitiesService } from '../utilities/utilities.service';
import { takeUntil } from 'rxjs';
import { OtpBoxComponent } from '../otp-box/otp-box.component';
import { UserService } from '../services/user.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends ClaimsBaseComponent implements OnInit {
    
    passwordForm: FormGroup;
    transactionInfo: TransactionInfo;
    country: string = "";
    email: string = "";
    passwordLength : number = 14;

    //Requirements
    cond1: boolean = false;
    cond2: boolean = false;
    cond3: boolean = false;
    cond4: boolean = false;
    cond5: boolean = false;
    cond6: boolean = false;
    cond7: boolean = false;
    isConfirmPasswordValid: boolean = false;
    hasAcceptedTerms: boolean = false;
    isTermsRequired: boolean = false;

    errorMessage: string = "";
    subErrorMessage: string = "";
    hasError: boolean = false;
    isValid: boolean = false;
    exitUrl: string = "";
    showFields: boolean = false;
    isRegistration: boolean = false;

    //OTP
    otpToken: string = "";
    maskedEmailAddress: string = "";
    otp: string = ""; 
    showOtpError: boolean = false;

    @ViewChild('modal_otp', {static: true}) 
    otpBox: OtpBoxComponent;

    @ViewChild('modal_resetpassword', {static: true}) 
    private modalResetPassword: TemplateRef<any>;

    @ViewChild('modal_error', {static: true}) 
    private modalError: TemplateRef<any>;

    private transactionInfoService : TransactionInfoService;
    private userService: UserService;

    constructor(private injector : Injector,
                private modalService: NgbModal,
                private fb: FormBuilder) {
        super(injector);
        this.transactionInfoService = this.injector.get(TransactionInfoService);
        this.userService = this.injector.get(UserService);
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
        this.country = this.transactionInfo.getCountry().toUpperCase();
        this.isRegistration = this.transactionInfo.getAction() === 'register' ? true : false;
        this.isTermsRequired = this.isRegistration;

        this.isValid = this.validate();
        this.exitUrl = this.getExitUrl();

        if (this.isRegistration) {
            this.verifyEmail();
        } else {
            this.decryptResetPasswordToken();
        }
    }

    ngOnInit() {
        this.passwordForm = this.fb.group({
            password: [],
            verifyPassword: []
        });
    }

    ngAfterViewInit() {
        let self = this;
        
        setTimeout(function () {self.validatePassword(self.passwordForm.get('password').value)},10);
        this.passwordForm.get('password').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validatePassword(data);
        });

        setTimeout(function () {self.validateConfirmPassword(self.passwordForm.get('verifyPassword').value)},10);
        this.passwordForm.get('verifyPassword').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validateConfirmPassword(data);
        });
    }

    verifyEmail() {
        this.errorMessage = '';
        this.modalService.dismissAll();

        let token = this.transactionInfo.getIntegrationToken();
        let iv = this.transactionInfo.getIv();

        if(token && iv) {
            this.userService.verifyEmail(token, iv)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (result: any) => {
                    if (result?.status === 'SUCCESS') {
                        this.email = result.email;
                        this.otpToken = result.token;
                        this.maskedEmailAddress = UtilitiesService.maskEmail(result.email);
                        this.otpBox.open();
                    } else {
                        this.errorMessage = 'invalidLink.title';
                        this.subErrorMessage = 'invalidLink.registration';
                        this.openModal(this.modalError);
                    }
                },
                error: (err: any) => {
                    console.log('err', err);
                    this.errorMessage = 'registration.genericError';
                    this.subErrorMessage = '';
                    this.openModal(this.modalError);
                }
            });
        } else {
            document.location.href = this.getExitUrl();
        }    
    }

    decryptResetPasswordToken() {
        this.errorMessage = '';
        this.modalService.dismissAll();

        let token = this.transactionInfo.getIntegrationToken();
        let iv = this.transactionInfo.getIv();

        if(token && iv) {
            this.userService.decryptResetPasswordToken(token, iv)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (result: any) => {
                    if (result?.status === 'SUCCESS') {
                        this.showFields = true;
                        this.email = result.email;
                        this.otpToken = result.token;
                    } else {
                        this.errorMessage = 'invalidLink.title';
                        this.subErrorMessage = 'invalidLink.forgotPassword';
                        this.openModal(this.modalError);
                    }
                },
                error: (err: any) => {
                    console.log('err', err);
                    this.errorMessage  = 'registration.genericError';
                    this.subErrorMessage = '';
                    this.openModal(this.modalError);
                }
            });
        } else {
            document.location.href = this.getExitUrl();
        }    
    }

    validatePassword(data) {
        this.errorMessage = "";
        if (data && data.length >= this.passwordLength) {
            this.cond1 = true;
        } else {
            this.cond1 = false;
        }

        let cond2Checker = 0;

        if (data && data.match(/[a-z]/g)) {
            cond2Checker++;
            this.cond3 = true;
        } else {
            this.cond3 = false;
        }

        if (data && data.match(/[A-Z]/g)) {
            cond2Checker++;
            this.cond4 = true;
        } else {
            this.cond4 = false;
        }

        if (data && data.match(/\d/g)) {
            cond2Checker++;
            this.cond5 = true;
        } else {
            this.cond5 = false;
        }

        if (data && data.match(/((?=.*[!@#$%&*()_+=|<>?{}\[\]~-]).+$)/g)) {
            cond2Checker++;
            this.cond6 = true;
        } else {
            this.cond6 = false;
        }

        if (cond2Checker > 2) {
            this.cond2 = true;
        } else {
            this.cond2 = false;
        }

        this.cond7 = data && !this.containsUsername(data);
    }

    validateConfirmPassword(data) {
        this.errorMessage = "";
        let confirmPasswordValidCount = 0;

        if (data && data.match(/[a-z]/g)) {
            confirmPasswordValidCount++;
        }

        if (data && data.match(/[A-Z]/g)) {
            confirmPasswordValidCount++;
        }

        if (data && data.match(/\d/g)) {
            confirmPasswordValidCount++;
        }

        if (data && data.match(/((?=.*[!@#$%&*()_+=|<>?{}\[\]~-]).+$)/g)) {
            confirmPasswordValidCount++;
        }

        if ((data && data.length >= this.passwordLength) && confirmPasswordValidCount >= 3 && (data && !this.containsUsername(data))) {
            this.isConfirmPasswordValid = true;
        } else {
            this.isConfirmPasswordValid = false;
        }
    }

    getTermsOfUseUrl() {
        return UtilitiesService.termsOfUseLink(this.country);
    }

    getPrivacyPolicyUrl() {
        return UtilitiesService.privacyPolicyLink(this.country);
    }

    containsUsername(password) {
        let username = this.email.toLowerCase();
        if (username && password) {
            username = username.replace(/[^a-zA-Z0-9]/g, '');
            let passwordVal = password.replace(/[^a-zA-Z0-9]/g, '');
            if (passwordVal?.toLowerCase().indexOf(username) !== -1) {
                return true;
            }
        }
        return false;
    };

    validate() {
        if (this.isRegistration) {
            return !this.cond1 || !this.cond2 || !this.cond7 
            || !this.isConfirmPasswordValid  || (!this.hasAcceptedTerms && this.isTermsRequired);
        } else {
            return !this.cond1 || !this.cond2 || !this.cond7;
        }
    }

    onCheckTerms(event) {
        this.errorMessage  = "";
        this.hasAcceptedTerms = event.target.checked;
    }
    
    handleResetPasswordSubmit() {
        this.errorMessage = "";
        if (this.passwordForm.get('password').value !== this.passwordForm.get('verifyPassword').value) {
            this.errorMessage = 'resetPassword.errorMessages.passwordNotMatch';
            return;
        }

        this.userService.resetPassword(this.email, this.passwordForm.get('password').value, this.otpToken)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                    next: (result: any) => {
                        if (result.success) {
                            this.openModal(this.modalResetPassword);
                        } else {
                            this.errorMessage  = "resetPassword.errorMessages.notLast8";
                        }
                    },
                    error: (err: any) => {
                        this.errorMessage  = "registration.genericError";
                    }
            }
        );
    }

    openModal(ref) {
        this.modalService.open(ref, {size: 'md', backdrop: 'static', keyboard: false});
    }

    getExitUrl() {
        let baseUrl = document.location.origin
        if (!UtilitiesService.isNullOrUndefined(baseUrl) && baseUrl != "") {
            baseUrl += "/claims/?";
            let countryParamValue = "country=" + this.country;
            let languageParam = "&language=" + this.translate.currentLang;
            let productParam = '&product=motor';
            let isRepairer = '&isRepairer=true';
            baseUrl = baseUrl + countryParamValue + productParam + isRepairer + languageParam;
        }
        return baseUrl;
    }

    verifyOtp(otp: string) {
        this.userService.verifyOtp(this.otpToken, otp, this.email, this.country, 'register')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (result: any) => {
                    if (result.success) {
                        this.otpBox.dismiss();
                        this.showFields = true;
                    } else {
                        if (result.locked) {
                            this.modalService.dismissAll();
                            this.otpBox.openOtpLockedModal();
                        } else {
                            this.showOtpError = true;
                        }
                    }
                },
                error: () => {
                    this.errorMessage  = "registration.genericError"; 
                }
            });
    }
}
