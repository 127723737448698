<div>
  <div class="col-12">
    <h5>{{ 'pageConfirmTravelPeriod.heading.title' | translate }}</h5>
    <p>{{ 'pageConfirmTravelPeriod.heading.desc' | translate }}</p>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #TRV_DEPART_DATE [fieldId]="'TRV_DEPART_DATE'" [formGroup]="confirmTravelModel"
                    name="originalDepartureDate"></qnect-ui>
        </div>
        <div class="form-group">
            <qnect-ui #TRV_RETURN_DATE [fieldId]="'TRV_RETURN_DATE'" [formGroup]="confirmTravelModel" 
            [claimsDateValidator]="claim.getClaimTravel().getOriginalDepartureDate()"
                      name="originalReturnDate"></qnect-ui>
        </div>
      </form>

    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>

</div>
