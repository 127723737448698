export const PanelRepairers = 
    [
        {
            "name": "motorClaim.panelRepairers.repairers.challenger_kwuntong.name",
            "value": "PANREP002001",
            "address": "motorClaim.panelRepairers.repairers.challenger_kwuntong.address",
            "district": "motorClaim.panelRepairers.repairers.challenger_kwuntong.district",
            "districtValue": "Kwun Tong",
            "email": "",
            "fax": "motorClaim.panelRepairers.repairers.challenger_kwuntong.fax",
            "website": "motorClaim.panelRepairers.repairers.challenger_kwuntong.website",
            "hotline": "motorClaim.panelRepairers.repairers.challenger_kwuntong.hotline",
            "mapImage": "assets/images/motor-repairer/challenger_kwuntong.png",
            "mapUrl": "https://goo.gl/maps/1ZPrCk33duHj3dws7",
            "description": "Challenger Auto Services Ltd."
        },
        {
            "name": "motorClaim.panelRepairers.repairers.gainfull_kowloon.name",
            "value": "PANREP004001",
            "address": "motorClaim.panelRepairers.repairers.gainfull_kowloon.address",
            "district": "motorClaim.panelRepairers.repairers.gainfull_kowloon.district",
            "districtValue": "Kowloon City",
            "email": "",
            "fax": "motorClaim.panelRepairers.repairers.gainfull_kowloon.fax",
            "website": "motorClaim.panelRepairers.repairers.gainfull_kowloon.website",
            "hotline": "motorClaim.panelRepairers.repairers.gainfull_kowloon.hotline",
            "mapImage": "assets/images/motor-repairer/gainfull_kowloon.png",
            "mapUrl": "https://goo.gl/maps/T4yo3AFUVhXY5nYN8",
            "description": "Gainfull Service Centre"
        },
        {
            "name": "motorClaim.panelRepairers.repairers.kbia_kowloon.name",
            "value": "PANREP001001",
            "address": "motorClaim.panelRepairers.repairers.kbia_kowloon.address",
            "district": "motorClaim.panelRepairers.repairers.kbia_kowloon.district",
            "districtValue": "Kowloon Bay",
            "email": "motorClaim.panelRepairers.repairers.kbia_kowloon.email",
            "fax": "motorClaim.panelRepairers.repairers.kbia_kowloon.fax",
            "website": "motorClaim.panelRepairers.repairers.kbia_kowloon.website",
            "hotline": "motorClaim.panelRepairers.repairers.kbia_kowloon.hotline",
            "mapImage": "assets/images/motor-repairer/kbia_kowloon.png",
            "mapUrl": "https://goo.gl/maps/LMMJbGmXmHYYn4sz5",
            "description": "KBIA Motor Services Centre"
        },
        {
            "name": "motorClaim.panelRepairers.repairers.mandarin_tuenmun.name",
            "value": "PANREP006001",
            "address": "motorClaim.panelRepairers.repairers.mandarin_tuenmun.address",
            "district": "motorClaim.panelRepairers.repairers.mandarin_tuenmun.district",
            "districtValue": "Tuen Mun",
            "email": "motorClaim.panelRepairers.repairers.mandarin_tuenmun.email",
            "fax": "motorClaim.panelRepairers.repairers.mandarin_tuenmun.fax",
            "website": "motorClaim.panelRepairers.repairers.mandarin_tuenmun.website",
            "hotline": "motorClaim.panelRepairers.repairers.mandarin_tuenmun.hotline",
            "mapImage": "assets/images/motor-repairer/mandarin_tuenmun.png",
            "mapUrl": "https://goo.gl/maps/R9JNaNDtGsskBPYSA",
            "description": "Mandarin Auto Services Ltd. (Tuen Mun)"
        },
        {
            "name": "motorClaim.panelRepairers.repairers.mandarin_kamtin.name",
            "value": "PANREP006002",
            "address": "motorClaim.panelRepairers.repairers.mandarin_kamtin.address",
            "district": "motorClaim.panelRepairers.repairers.mandarin_kamtin.district",
            "districtValue": "Kam Tin",
            "email": "motorClaim.panelRepairers.repairers.mandarin_kamtin.email",
            "fax": "motorClaim.panelRepairers.repairers.mandarin_kamtin.fax",
            "website": "motorClaim.panelRepairers.repairers.mandarin_kamtin.website",
            "hotline": "motorClaim.panelRepairers.repairers.mandarin_kamtin.hotline",
            "mapImage": "assets/images/motor-repairer/mandarin_kamtin.png",
            "mapUrl": "https://goo.gl/maps/sMenfhRFAbXuWNZE6",
            "description": "Mandarin Auto Services Ltd. (Kam Tin)"
        }
    ];