import {Component, Injector, OnInit, ViewChild} from "@angular/core";
import {ClaimService} from "../../services/claim.service";
import {DocumentTypes} from "../../utilities/enums/document-types.enum";
import {TransactionInfoService} from "../../services/transaction-info.service";
import * as FileSaver from "file-saver";
import {ClaimsBaseComponent} from "../claim-base.component";
import {Claim} from "../../model/claim.model";
import {ClaimTypes} from "../../model/claim-type.model";
import {DocumentHelperService} from "../../services/document-helper.service";
import {StoredDataService} from "../../services/stored-data-service";
import {TransactionInfo} from "../../model/transaction-info.model";
import {takeUntil} from "rxjs";
import {PageService} from "../../services/page.service";
import {ClaimResponse} from "../../model/claim-response-model";
import {ClaimResponseDisplay} from "./claim-response-display-model";
import {DocumentResponseDisplay} from "./document-response-display-model";
import {ClaimTypeItem} from "../../model/claim-type-item.model";
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {Claimant} from '../../model/claimant.model';
import * as cloneDeep from "lodash/cloneDeep";
import { UtilitiesService } from "src/app/utilities/utilities.service";
import { CanComponentDeactivate } from "src/app/guard/candeactivate.guard";
import { SignInstructionModalComponent } from "src/app/utilities/components/sign-instruction-modal/sign-instuction-modal";

declare var $: any;

@Component({
    selector: 'app-complete',
    templateUrl: './complete.component.html',
    styleUrls: ['./complete.component.css']
})
export class CompleteComponent extends ClaimsBaseComponent implements OnInit, CanComponentDeactivate {

    claim: Claim;
    transactionInfo : TransactionInfo;
    public email: string;
    public claimNumberLine: string = '';
    finishUpload = false;
    unuploadedDocs: string[][] = [];
    type = "TYPE";
    doc = "DOC";
    displayLabelOnce = "true";
    totalClaimAmount : number = 0;
    public entryUrl: string;
    claimResponse: ClaimResponse[] = this.claim.getClaimResponseList();
    claimResponseDisplayList: ClaimResponseDisplay[] = [];
    missingDocs: boolean = false;

    @ViewChild('sign_instruction_modal', {static: true}) 
    instructionModal: SignInstructionModalComponent;

    constructor(private claimService: ClaimService,
                private transactionService : TransactionInfoService,
                private documentHelperService : DocumentHelperService,
                private injector : Injector,
                private storedDataService : StoredDataService,
                private pageService : PageService,
                private googleAnalyticsService: GoogleAnalyticsService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.transactionInfo = this.transactionService.getTransactionInfo();
        this.claimNumberLine = this.getAllClaimNumbers(this.claim);
    }

    ngOnInit() {
        let transactionOrigin = this.transactionService.getTransactionInfo().getTransactionApplicationOrigin();
        if([TransactionInfo.EBUSINESS, TransactionInfo.CUSTOMER_PORTAL].indexOf(transactionOrigin) != -1){
           this.entryUrl = this.transactionService.getExitUrl();
        } else {
            this.pageService.getEntryUrl().pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                entryUrl => { this.entryUrl = entryUrl;}
            );
        }

        this.showSvgPath();
        this.getUnuploadedDocuments();
        this.setupClaimDisplayInformation();
        this.pushGAClaimSubmitted();

        // Blank the claim and transaction data.
        // Make sure the browser storage is updated.
        this.claimService.setClaim(new Claim());
        this.transactionService.setTransactionInfo(new TransactionInfo());
        this.storedDataService.storeSession();

        // Survey tool
        this.survicate();
    }

    getUnuploadedDocuments() {

        let uploadedDocuments = this.claim.getDocumentForm().documents;
        //hardcoded for the demo
        const formBuilders = this.documentHelperService.getDocumentFormBuilders();

        var index;
        let documentTypes = new DocumentTypes();
        let claimType: string = '';
        let previousClaimType: string = 'none';
        
        for (let formBuilder of formBuilders) {
            claimType = formBuilder.documentFormClaimType;
            for(let documentField of formBuilder.getRequiredDocuments()){
                let docUploaded: boolean = false;
                for (let uploadedDoc of uploadedDocuments) {
                    if(uploadedDoc.docClaimType == formBuilder.documentFormClaimType){
                        if(uploadedDoc.docSubTypeId == documentField.id){
                            docUploaded = true;
                            break;
                        }
                    }
                }
                if(docUploaded == false) {
                    if (!this.transactionInfo.isGenericClaim()) {
                        if(previousClaimType != claimType){
                            let c = this.transactionInfo.getClaimTypeByCode(claimType);
                            this.unuploadedDocs.push([this.type, c.claimTypeIdName]);
                            previousClaimType = claimType;
                        }
                    }
                    if(documentField.isRequired){
                        this.unuploadedDocs.push([this.doc, documentField.description]);
                    }
                   
                }
            }
        }

        if(this.unuploadedDocs != undefined && this.unuploadedDocs.length > 0) {
            this.finishUpload = false;
        } else {
            this.finishUpload = true;
        }
    }

    setupRequiredDocuments(formBuilders, uploadedDocuments){
        //Check if there are uploaded documents
        if(uploadedDocuments != null && uploadedDocuments.length > 0)
        if(this.transactionInfo.isDomesticHelperProduct()){
            if(formBuilders != null && formBuilders.length > 0){
                //Check if the document form builder is Hospitalization of Helper
                if(formBuilders.filter(formBuilder => formBuilder.documentFormClaimType == "CLAIM_TYPE_DMH_HOSPITAL").length == 1){
                    //Check if at least one of the uploaded documents is Government or Private
                    let uploadedDocumentsChecker = this.claim.getDocumentForm().documents.filter(uploadedDoc => uploadedDoc.docClaimType == "CLAIM_TYPE_DMH_HOSPITAL" 
                    && (["HOSPITALFORM", "PRIVATEHOSPITALFORM"].indexOf(uploadedDoc.docSubTypeId) > -1));
                    //If at least one is true, set the Government and Private fields as not required so they won't display
                    if(uploadedDocumentsChecker != null && uploadedDocumentsChecker.length == 1){
                        for (let formBuilder of formBuilders) {
                            if(formBuilder.documentFormClaimType == "CLAIM_TYPE_DMH_HOSPITAL"){
                                for (let requiredDoc of formBuilder.getRequiredDocuments()){
                                    if(["HOSPITALFORM", "PRIVATEHOSPITALFORM"].indexOf(requiredDoc.id) > -1) {
                                        requiredDoc.setIsRequired(false);
                                    }                                
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }

    setupClaimDisplayInformation(){

        let formBuilders = this.documentHelperService.getDocumentFormBuilders();
        let claimType: string = '';
        let previousClaimType: string = 'none';
        let uploadedDocuments = cloneDeep(this.claim.getDocumentForm().documents);
        let primaryClaimantIdentifier = this.claimService.claim.getClaimantIdentifier();
        
        //Check if we should remove documents based on other uploaded documents
        this.setupRequiredDocuments(formBuilders, uploadedDocuments);
        
        uploadedDocuments.forEach(function(uploadedDoc){
            if(uploadedDoc.claimant == undefined || 
                uploadedDoc.claimant == null){
                    uploadedDoc.claimantIdentifier = primaryClaimantIdentifier;
                }
        });
        

        let claimResponseDisplayList: ClaimResponseDisplay[] = [];
        // Construct the claim response details to show on the page.
        for(let claimResponse of this.claimResponse){

            let claimResponseDisplay : ClaimResponseDisplay = new ClaimResponseDisplay();
            claimResponseDisplay.setClaimIndex(claimResponse.getClaimIndex());
            claimResponseDisplay.setPolicyNumber(this.claim.getPolicyNumber());
            claimResponseDisplay.setContactEmail(this.claim.getContactEmail())

            if (claimResponse.getErrorMessage()) {
                claimResponseDisplay.setClaimNumberDisplayLine("ERROR: " + this.transactionInfo.getLoggingId() +  " " + claimResponse.getErrorMessage() );
            }
            else if (claimResponse.getClaimNumber() && !this.isNotNullOrUndefinedStr(this.claim.getIsCoverNotePreNumbered())) {
                claimResponseDisplay.setClaimNumberDisplayLine(claimResponse.getClaimNumber());
            }
            else {
                claimResponseDisplay.setClaimNumberDisplayLine(claimResponse.getNotificationNumber());
            }

            if (this.transactionInfo.isHomeProduct() && this.isNotNullOrUndefinedStr(claimResponse.getRiskType())) {
                let claimDesc =  !this.transactionInfo.isResidentialFire() ?
                    "homeProduct.claimDescription." + claimResponse.getRiskType().toLowerCase(): '';
                claimResponseDisplay.getClaimTypesList().push(claimDesc);
            } else {
                let claimTypesList = claimResponse.getSelectedClaimTypesList();
                for (let claimTypeId of claimTypesList) {
                    let claimTypeObject : ClaimTypeItem = this.transactionInfo.getClaimTypeByCode(claimTypeId);
                    if(claimTypeObject) {
                        if(claimTypeObject.claimTypeIdName === "claimDetails.claimTypeName.notSure"){
                            let typeIdName = "claimDetails.claimTypeName.otrClaim";
                            claimResponseDisplay.getClaimTypesList().push(typeIdName);
                        }else{
                            claimResponseDisplay.getClaimTypesList().push(claimTypeObject.claimTypeIdName);
                        }
                    }
                }
            }

            // for multiple claimant list if any
            let claimant: Claimant = claimResponse.getClaimant();

            if(!UtilitiesService.isNullOrUndefined(claimant)) {
                claimResponseDisplay.setClaimantName(claimant.getClaimantName());
                claimResponseDisplay.setClaimantIdentifier(claimant.getClaimantIdentifier());
            }

            // Work out documents for non-Home
            if (!this.transactionInfo.isHomeProduct()) {
                for (let formBuilder of formBuilders) {
                    claimType = formBuilder.documentFormClaimType;
                    if(claimResponse.getSelectedClaimTypesList().indexOf(claimType) > -1){
                        for(let documentField of formBuilder.getRequiredDocuments()){
    
                            if(documentField.id.startsWith('PROOFOFREL') && !UtilitiesService.isMinor(claimant.getClaimantDateOfBirth())){
                                continue;
                            }
    
                            let docUploaded: boolean = false;
                            for (let uploadedDoc of uploadedDocuments) {
                                if(uploadedDoc.docClaimType == formBuilder.documentFormClaimType){
                                    if(uploadedDoc.docSubTypeId.startsWith(documentField.id)){
                                        if((claimant == null || claimant == undefined) || ((uploadedDoc.claimantIdentifier != undefined &&
                                            uploadedDoc.claimantIdentifier != null &&
                                            uploadedDoc.claimantIdentifier == claimant.getClaimantIdentifier()) ||
                                            (uploadedDoc.claimant != undefined &&
                                            uploadedDoc.claimant!= null &&
                                            uploadedDoc.claimant.getClaimantIdentifier() == claimant.getClaimantIdentifier()))){
                                                docUploaded = true;
                                                break;
                                            }
                                    }
                                }
                            }
                            if(docUploaded == false) {
                                this.missingDocs = true;
                                if(previousClaimType != claimType){
                                    previousClaimType = claimType;
                                }
    
                                let documentDisplay = new DocumentResponseDisplay();
                                //remove not required documents on display
                                if(documentField.isRequired){
                                    documentDisplay.setDescription(documentField.description);
                                    documentDisplay.setDescriptionHtml(documentField.descriptionHtml);
                                    if(this.transactionInfo.isMotorProduct()){
                                        documentDisplay.setIsOriginal(documentField.isOriginal);
                                    }
                                   
                                    let claimTypeObject : ClaimTypeItem = this.transactionInfo.getClaimTypeByCode(claimType);
                                    if (claimTypeObject) {
                                        if(claimTypeObject.claimTypeIdName === "claimDetails.claimTypeName.notSure"){
                                            let typeIdName = "claimDetails.claimTypeName.otrClaim";
                                            documentDisplay.setClaimType(typeIdName);
                                        }else{
                                            documentDisplay.setClaimType(claimTypeObject.claimTypeIdName);
                                        }
                                    }
                                    claimResponseDisplay.getDocumentResponseDisplayList().push(documentDisplay);
                                }
                               
                            }
                        }
                    }
                }
            } else {
                for (let formBuilder of formBuilders) {
                    claimType = formBuilder.documentFormClaimType;
                    let risk = claimResponse.getRiskType();
                    let requiredDocs = formBuilder.getRequiredDocuments();
                    if(["HPK", "HHH", "HSR"].indexOf(risk)==-1){
                        requiredDocs = requiredDocs.filter(function(reqDoc){ 
                            return (reqDoc.getRiskId() == risk)
                        });
                    } else {
                        requiredDocs = requiredDocs.filter(function(reqDoc){ 
                            return (reqDoc.getRiskId() == null)
                        });
                    }
                    if(claimResponse.getSelectedClaimTypesList().indexOf(claimType) > -1){
                        for(let documentField of requiredDocs){
    
                            if(documentField.id.startsWith('PROOFOFREL') && !UtilitiesService.isMinor(claimant.getClaimantDateOfBirth())){
                                continue;
                            }
    
                            let docUploaded: boolean = false;
                            for (let uploadedDoc of uploadedDocuments) {
                                if(uploadedDoc.docClaimType == formBuilder.documentFormClaimType){
                                    if(uploadedDoc.docSubTypeId.startsWith(documentField.id)){
                                        if((claimant == null || claimant == undefined) || ((uploadedDoc.claimantIdentifier != undefined &&
                                            uploadedDoc.claimantIdentifier != null &&
                                            uploadedDoc.claimantIdentifier == claimant.getClaimantIdentifier()) ||
                                            (uploadedDoc.claimant != undefined &&
                                            uploadedDoc.claimant!= null &&
                                            uploadedDoc.claimant.getClaimantIdentifier() == claimant.getClaimantIdentifier()))){
                                                docUploaded = true;
                                                break;
                                            }
                                    }
                                }
                            }
                            if(docUploaded == false) {
                                this.missingDocs = true;
                                if(previousClaimType != claimType){
                                    previousClaimType = claimType;
                                }
    
                                let documentDisplay = new DocumentResponseDisplay();
                                //remove not required documents on display
                                if(documentField.isRequired){
                                    documentDisplay.setDescription(documentField.description);
                                    documentDisplay.setDescriptionHtml(documentField.descriptionHtml);
                                    claimResponseDisplay.getDocumentResponseDisplayList().push(documentDisplay);
                                }
                               
                            }
                        }
                    }
                }
            }
            
            claimResponseDisplayList.push(claimResponseDisplay)
        }

        // sort list to show claim transaction with unuploaded mandatory document as priority
        this.sortClaimResponseDisplayList(claimResponseDisplayList);
    }

    sortClaimResponseDisplayList(claimRespDisplayList: ClaimResponseDisplay[]) {
        if (claimRespDisplayList.length > 0) {
            let claimWithUnuploadedDocs = claimRespDisplayList.filter(claimResponse => claimResponse.getDocumentResponseDisplayList().length > 0);
            this.claimResponseDisplayList.push(...claimWithUnuploadedDocs);
            let claimWithoutUnuploadedDocs = claimRespDisplayList.filter(claimResponse => claimResponse.getDocumentResponseDisplayList().length == 0 ||
                claimResponse.getDocumentResponseDisplayList() == null);
            this.claimResponseDisplayList.push(...claimWithoutUnuploadedDocs);
        }
    }

    checkUploadFileNum() {
        console.log('$(\'.notUpload\').children(\'li\').length', $('.notUpload').html());
        if ($('.notUpload').find('li').length > 0) {
            this.finishUpload = true;
        }

    }

    showSvgPath() {
        $('img.svg,.icon').each(function () {
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function (data) {
                // Get the SVG tag, ignore the rest
                var $svg = $(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Check if the viewport is set, else we gonna set it if we can.
                if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
                }

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        });
    }

    downloadPDF(docType: string, doc: string) {

        this.pushGASavePdf();
        let integrationToken = this.transactionInfo.getIntegrationToken();

        // Need cn for chinese.
        let currentLanguage = this.translate.currentLang;
        if (currentLanguage == 'zh') {
            currentLanguage = 'cn';
        }
        this.claim.setLanguage(currentLanguage);

        let filename = this.translate.instant(doc) + '.pdf';

        this.claimService.downloadPdfDocument(integrationToken, this.claim, docType)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: data => {
                    if(this.isIos()){
                        let fileURL = URL.createObjectURL(data);
                        window.open(fileURL, '_blank');
                    } else {
                        FileSaver.saveAs(data, filename);
                    }
                }
            });


        this.claim.setCurrentClaimIndex(0);
    }

    isIos(): boolean{
        var iDevices = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ];

        console.log("navi " + navigator.platform);

        if (!!navigator.platform) {
            while (iDevices.length) {
            if (navigator.platform === iDevices.pop()){ return true; }
            }
        }

        return false;
    }


    // Google Analytics
    pushGASavePdf() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'save'});
    }

    //Google Analytics
    pushGAClaimSubmitted() {
        let paymentMethod: string = "No Payment Section";
        let productList: any;
        let affiliation: string = "";
        let brand: string = this.claim.getCountry();
        this.totalClaimAmount = 0;
        let estimatedClaimAmount: string;

        if(this.claim.getCountry().toLowerCase() == "hkg"){
            if(this.claim.getIsManulife()){
                affiliation = "MIL" + "-" + this.claim.getPolicyNumber();
                brand = "Manulife";
            } else {
                affiliation = "HKSI" + "-" + this.claim.getPolicyNumber();
                brand = "HKSI";
            }
        } else if (this.claim.getCountry().toLowerCase() == "hgi") {
            affiliation = "QGI" + "-" + this.claim.getPolicyNumber();
            brand = "QGI";
        } else {
            affiliation = this.claim.getCountry() + "-" + this.claim.getPolicyNumber();
        }

        let paymentRefundToBank = this.claim.getPaymentRefundToBank();
        if (this.isNotNullOrUndefinedStr(paymentRefundToBank)) {
            if (paymentRefundToBank === 'Y' && this.claim.getServiceUnit() !== 'GP') {
                paymentMethod = "Bank";
            } else if (paymentRefundToBank === 'N') {
                paymentMethod = "Cheque";
            }
        }

        productList = this.googleAnalyticsService.getGAClaimProductListSummary(this.claim, brand);
        estimatedClaimAmount = this.googleAnalyticsService.getTotalEstimatedAmount().toFixed(2);

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'event': 'vpageview',
            'pageStep': 'Finish – Claim Submitted',
            'vPath':'/finish',
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id':  this.claimNumberLine,                            // Case Number. Replace EZ1234 with actual number
                        'affiliation': affiliation,	                   // Channel of the claimed policy+original policy number, eg QGI-TA123456, MIL-T123467, HKSI-T1234567
                        'revenue':  estimatedClaimAmount,             // Total Claim Amount in a submission. Replace 1555 with actual claim amount
                        'coupon': paymentMethod,                       // Payment method. Bank if direct debit Cheque if cheque payment
                    },
                    'products': productList
                }
            }
        });

    }

    survicate() {
        (<any>window)._sva = {};
        (<any>window)._sva.traits = {
            "claimNumber": this.claimNumberLine,
            "language": this.translate.currentLang,
            "product" : this.transactionInfo.getPolicyType()
        };

        var s = document.createElement('script');
        s.src = '//survey.survicate.com/workspaces/2976a64dece8b7c78d2b796a4cc21460/web_surveys.js';
        s.async = true;
        var e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);

    }

    canDeactivate() {
        let element: HTMLElement = document.getElementById("closeBtn") as HTMLElement;
        element.click();
        return false;
    }

    showOrigDocsAddressMotor(): boolean {
        if(this.transactionInfo.isMotorProduct() && this.transactionInfo.getCurrentClaimType() != ClaimTypes[ClaimTypes.CLAIM_TYPE_MVA_WINDSCREEN]){
            return true
        }
        return false;
    }

    getAllClaimNumbers(claim: Claim): string {
        let isCoverNoteClaim = this.isNotNullOrUndefinedStr(claim.getIsCoverNotePreNumbered());
        let claimNumberList: string[] = [];
        let claimResponseList = claim.getClaimResponseList();
        if (claimResponseList.length > 0) {
            claimResponseList.forEach(function (claimResponseItem) {
                let claimNumber = claimResponseItem.getClaimNumber();
                if (UtilitiesService.isNullOrUndefined(claimNumber) || claimNumber === '' || isCoverNoteClaim) {
                    claimNumber = claimResponseItem.getNotificationNumber();
                }
                claimNumberList.push(claimNumber);
            });
        }

        if (!UtilitiesService.isNullOrUndefined(claimNumberList) && claimNumberList.length > 0) {
            return claimNumberList.join(", ");
        }
        return '';
    }

}
