import { Component, OnInit } from '@angular/core';
import {TransactionInfoService} from "../../../services/transaction-info.service";
import {ClaimTypes} from "../../../model/claim-type.model";

@Component({
  selector: 'app-other-claim',
  templateUrl: './other-claim.component.html',
  styleUrls: ['./other-claim.component.css']
})
export class OtherClaimDMHComponent implements OnInit {

    constructor( private transactionInfoService: TransactionInfoService) {
        this.transactionInfoService.getTransactionInfo().setCurrentClaimType(ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_OTHERS]);
    }

  ngOnInit() {
  }

}
