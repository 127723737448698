<main>
    <section class = "claim-search-home">
        <div class="claim-search-bg-overlay" id="bg-overlay-id"></div>
        <div class="home-wrap container">
            <h1 class="claim-search-desc">{{'claimStatus.description' | translate }}</h1>
        </div>
    </section>
    <section class="home-claim-simple bg-grey text-left">
        <div class="">
            <div class="claim-search-home-form" >
                <qnect-notification-messages [notifications]="notifications"></qnect-notification-messages>
                <div class="form-group section">
                    <div class="section-content"><BR>
                        <form>
                            <div class="row col-12" style="margin: 0 auto">
                                <div class="col-xl-10 col-lg-12">
                                    <div class="div-bottom" *ngIf="this.transactionInfo.getCountry() === 'HKG'">
                                        <qnect-ui #B2CCS_PRODUCT_TYPE [fieldId]="'B2CCS_PRODUCT_TYPE'" [formGroup]="claimsSearchFormGroup" name="productType"></qnect-ui>
                                    </div>
                                    <div class="form-group">
                                        <qnect-ui #B2CCS_CLAIM_NUMBER [fieldId]="'B2CCS_CLAIM_NUMBER'" [formGroup]="claimsSearchFormGroup" (keyup)="trim(this.claimsSearchFormGroup.get('claimNumber'), $event)" (keydown)="onKeyDown($event)" name="claimNumber"></qnect-ui>
                                    </div>
                                    <ng-container *ngIf="this.transactionInfo.getCountry() === 'HKG'">
                                        <div *ngIf="!this.transactionInfo.isEmployeeCompensationProduct() && !this.transactionInfo.isMotorProduct()">
                                            <div class="auth-selector">
                                                <ul class="nav auth-nav-tabs ff-stag" *ngIf="this.transactionInfo.isTravelProduct()">
                                                    <li><a data-toggle="tab" href="#nameDiv" (click)="resetFields('fullname')"  [ngClass]="{'active': this.transactionInfo.getDateOfBirth() == null || this.transactionInfo.getDateOfBirth() == undefined}">{{ 'claimStatus.homepage.travelAuthName' | translate }}</a></li>
                                                    <li class="sep-padding"> | </li>
                                                    <li><a data-toggle="tab" href="#birthDiv" (click)="resetFields('dateofbirth')" [ngClass]="{'active': this.transactionInfo.getDateOfBirth() != null && this.transactionInfo.getDateOfBirth() != undefined}">{{ 'claimStatus.homepage.travelAuthDob' | translate }}</a></li>
                                                </ul>
                                                <ul class="nav auth-nav-tabs ff-stag" *ngIf="this.transactionInfo.isDomesticHelperProduct()">
                                                    <li><a data-toggle="tab" href="#nameDiv" (click)="resetFields('fullname')"  [ngClass]="{'active': this.transactionInfo.getAuthIdNumber() == null || this.transactionInfo.getAuthIdNumber() == ''}">{{ 'claimStatus.homepage.helperAuthName' | translate }}</a></li>
                                                    <li class="sep-padding"> | </li>
                                                    <li><a data-toggle="tab" href="#idNumberDiv" (click)="resetFields('idNumber')" [ngClass]="{'active': this.transactionInfo.getAuthIdNumber() != null && this.transactionInfo.getAuthIdNumber() != ''}">{{ 'claimStatus.homepage.helperAuthId' | translate }}</a></li>
                                                </ul>
                                                <ul class="nav auth-nav-tabs ff-stag" *ngIf="this.transactionInfo.isHomeProduct() || this.transactionInfo.isAccidentProduct()">
                                                    <li class="txt-skyblue" style="font-size:18px;">{{ 'claimStatus.homepage.genericName' | translate }}*</li>
                                                    <ng-container><em class="hits"><div>{{'claimStatus.homepage.genericNameToolTip' | translate}}</div></em></ng-container>
                                                </ul>
                                                <ul class="nav auth-nav-tabs ff-stag" *ngIf="this.transactionInfo.isGenericClaim()">
                                                    <li class="txt-skyblue" style="font-size:18px;">{{ 'claimStatus.homepage.genericHolderName' | translate }}*</li>
                                                    <ng-container><em class="hits"><div>{{'claimStatus.homepage.genericNameToolTip' | translate}}</div></em></ng-container>
                                                </ul>
                                            </div>
                                            <div class="auth-fields">
                                                <div class="tab-content">
                                                    <div id="nameDiv" class="tab-pane fade in active show">
                                                        <qnect-ui #B2CCS_AUTH_NAME [fieldId]="'B2CCS_AUTH_NAME'"  [formGroup]="claimsSearchFormGroup" name="authName"></qnect-ui>
                                                    </div>
                                                    <div id="birthDiv" class="tab-pane fade" *ngIf="this.transactionInfo.isTravelProduct()">
                                                        <qnect-ui #B2CCS_AUTH_DOB [fieldId]="'B2CCS_AUTH_DOB'"  [formGroup]="claimsSearchFormGroup" name="authDateOfBirth"></qnect-ui>
                                                    </div>
                                                    <div id="idNumberDiv" class="tab-pane fade" *ngIf="this.transactionInfo.isDomesticHelperProduct()">
                                                        <qnect-ui #B2CCS_AUTH_ID_NUMBER [fieldId]="'B2CCS_AUTH_ID_NUMBER'" [formGroup]="claimsSearchFormGroup" name="authIdNumber"></qnect-ui>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="this.transactionInfo.isHomeProduct() || this.transactionInfo.isGenericClaim() || this.transactionInfo.isAccidentProduct()">
                                                <div class="auth-selector">
                                                    <ul class="nav auth-nav-tabs ff-stag">
                                                        <li class="txt-skyblue" style="font-size:18px;">{{ 'claimStatus.homepage.genericNricUenHK' | translate }}*</li>
                                                    </ul>
                                                </div>
                                                <div class="auth-fields">
                                                    <div class="tab-content">
                                                        <div id="idNumberDiv" class="tab-pane fade in active show">
                                                            <qnect-ui #B2CCS_AUTH_ID_NUMBER [fieldId]="'B2CCS_AUTH_ID_NUMBER'" [formGroup]="claimsSearchFormGroup" name="authIdNumber"></qnect-ui>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="this.transactionInfo.isMotorProduct()">
                                            <div class="auth-selector">
                                                <ul class="nav auth-nav-tabs ff-stag">
                                                    <li class="txt-skyblue" style="font-size:18px;">{{ 'claimStatus.homepage.policyHolderFullName' | translate }}*</li>
                                                </ul>
                                            </div>
                                            <div class="auth-fields">
                                                <div class="tab-content">
                                                    <div id="nameDiv" class="tab-pane fade in active show">
                                                        <qnect-ui #B2CCS_AUTH_NAME [fieldId]="'B2CCS_AUTH_NAME'"  [formGroup]="claimsSearchFormGroup" name="authName"></qnect-ui>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="auth-selector">
                                                <ul class="nav auth-nav-tabs ff-stag">
                                                    <li class="txt-skyblue" style="font-size:18px;">{{ 'claimStatus.homepage.vehicleRegistrationNumber' | translate }}*</li>
                                                </ul>
                                            </div>
                                            <div class="auth-fields">
                                                <div class="tab-content">
                                                    <div id="idNumberDiv" class="tab-pane fade in active show">
                                                        <qnect-ui #B2CCS_AUTH_ID_NUMBER [fieldId]="'B2CCS_AUTH_ID_NUMBER'" [formGroup]="claimsSearchFormGroup" name="authIdNumber"></qnect-ui>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="this.transactionInfo.isEmployeeCompensationProduct()">
                                        <div class="auth-selector">
                                            <ul class="nav auth-nav-tabs ff-stag">
                                                <li class="txt-skyblue" style="font-size:18px;" >{{ 'claimStatus.homepage.policyHolderFullName' | translate }}*</li>
                                                <em class="hits"><div style="white-space:pre-wrap">{{'claimStatus.homepage.genericHolderName' | translate}}</div></em>
                                            </ul>
                                        </div>
                                        <div class="auth-fields">
                                            <div class="tab-content">
                                                <div class="tab-pane fade in active show">
                                                    <qnect-ui #B2CCS_AUTH_NAME [fieldId]="'B2CCS_AUTH_NAME'" [formGroup]="claimsSearchFormGroup" name="authName"></qnect-ui>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <qnect-ui #B2CCS_AUTH_ID_NUMBER [fieldId]="'B2CCS_AUTH_ID_NUMBER'" [formGroup]="claimsSearchFormGroup"
                                                        [dynamicLabel] ="'claimStatus.homepage.businessRegNumber'" name="authIdNumber"></qnect-ui>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="this.transactionInfo.getCountry() === 'SGP' || this.transactionInfo.getCountry() === 'MYS'">
                                        <div class="auth-selector">
                                            <ul class="nav auth-nav-tabs ff-stag">
                                                <li class="txt-skyblue" style="font-size:18px;" >{{ 'claimStatus.homepage.genericHolderName' | translate }}*</li>
                                                <ng-container><em class="hits"><div style="white-space:pre-wrap">{{'claimStatus.homepage.genericNameToolTipSGP' | translate}}</div></em></ng-container>
                                            </ul>
                                        </div>
                                        <div class="auth-fields">
                                            <div class="tab-content">
                                                <div id="nameDiv" class="tab-pane fade in active show">
                                                    <qnect-ui #B2CCS_AUTH_NAME [fieldId]="'B2CCS_AUTH_NAME'" [formGroup]="claimsSearchFormGroup" name="authName"></qnect-ui>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="auth-selector">
                                            <ul class="nav auth-nav-tabs ff-stag">
                                                <span *ngIf="this.transactionInfo.getCountry() === 'SGP'">
                                                    <li class="txt-skyblue" style="font-size:18px;">{{ 'claimStatus.homepage.genericNricUen' | translate }}*</li>
                                                </span>
                                                <span *ngIf="this.transactionInfo.getCountry() === 'MYS'">
                                                    <li class="txt-skyblue" style="font-size:18px;">{{ 'claimStatus.homepage.genericNricPassportMY' | translate }}*</li>
                                                </span>
                                            </ul>
                                        </div>
                                        <div class="auth-fields">
                                            <div class="tab-content">
                                                <div id="idNumberDiv" class="tab-pane fade in active show">
                                                    <qnect-ui #B2CCS_AUTH_ID_NUMBER [fieldId]="'B2CCS_AUTH_ID_NUMBER'" [formGroup]="claimsSearchFormGroup" name="authIdNumber"></qnect-ui>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="col-xl-2 col-lg-12"></div>
                                <div class="col-xl-12 col-lg-12 desktop-ver">
                                    <button (click)="goToNext()" class="new-btn btn-claim-status btn" type="submit" name="submit">&nbsp;{{'claimStatus.homepage.checkBtn' | translate }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row mobile-ver text-center">
                    <div class="col-12 form-group">
                        &nbsp;<BR>
                        <button (click)="goToNext()" class="new-btn btn-claim-status btn" type="submit" name="submit">{{ 'claimStatus.homepage.checkBtn' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<ng-template #sgp_tooltip>
    <em class="hits"><div style="white-space:pre-wrap">{{'claimStatus.homepage.genericNameToolTipSGP' | translate}}</div></em>
</ng-template>

<otp-box #modal_otp [otp]="otp" [maskedEmailAddress]="maskedEmailAddress" [showCloseIcon]="true" [showResendOtpLink]="true"
         [showOtpError]="showOtpError" (onVerifyOtpEmit)="verifyOtpAndGetClaimStatus($event)" (onSendOtpEmit)="resendOtp()"></otp-box>

<ng-template #modal_info let-c="close" let-d="dismiss">
    <div class="modal-body">
        <div class="modal-title">{{ infoMessage | translate }}</div>
        <span *ngIf="subInfoMessage"><br/><h4>{{ subInfoMessage | translate }}</h4></span>
    </div>
    <div class="mt-4">
        <button class="new-btn btn-primary btn" (click)="c('Close click')" >
        {{'claimSection.generalLabels.generalButtonLabels.ok' | translate}}
        </button>
        <br/><br/>
    </div>
</ng-template>