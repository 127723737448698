import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import {ClaimTypes} from '../../../model/claim-type.model';
import {takeUntil} from 'rxjs/operators';
import { ECAccidentPlaceDetails } from 'src/app/model/employee-compensation/ec-accident-place-details.model';
import { UiOptionsModel } from 'src/app/ui/ui-options.model';
import { EcHelperService } from 'src/app/services/ec-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'app-ec-accident-place',
    templateUrl: './ec-accident-place.component.html'
})
export class ECAccidentPlaceComponent extends ClaimsBaseComponent implements OnInit {

    accidentPlaceForm: FormGroup;
    accidentPlaceDetails: ECAccidentPlaceDetails;
    showTotalBox = false;
    placeTypeOptions1: UiOptionsModel[] = [];
    placeTypeOptions2: UiOptionsModel[] = [];
    placeTypeOptions3: UiOptionsModel[] = [];
    placeTypeOptions4: UiOptionsModel[] = [];
    transactionInfo: TransactionInfo;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector,
                private sideMenuService: SideMenuService,
                private ecHelperService: EcHelperService,
                private modalService: NgbModal,) {
        super(injector);
        this.transactionInfo = this.injector.get(TransactionInfoService).getTransactionInfo();
        this.accidentPlaceDetails = this.claim.getClaimEmployeeCompensation().getAccidentPlaceDetails();
    }

    ngOnInit() {
        if (!this.isNotNullOrUndefinedStr(this.accidentPlaceDetails.getContactNoCode())){
            this.accidentPlaceDetails.setContactNoCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }

        this.accidentPlaceForm = this.fb.group({
            accidentPlace: [this.accidentPlaceDetails.getAccidentPlace()],
            accidentPlaceDesc: [this.accidentPlaceDetails.getAccidentPlaceDesc()],
            placeType: [this.accidentPlaceDetails.getPlaceType()],
            placeTypeDesc: [this.accidentPlaceDetails.getPlaceTypeDesc()],
            placeTypeOther: [this.accidentPlaceDetails.getPlaceTypeOther()],
            activity: [this.accidentPlaceDetails.getActivity()],
            workType: [this.accidentPlaceDetails.getWorkType()],
            workTypeDesc: [this.accidentPlaceDetails.getWorkTypeDesc()],
            workTypeOther: [this.accidentPlaceDetails.getWorkTypeOther()],
            whereabouts: [this.accidentPlaceDetails.getWhereabouts()],
            machineryInvolved: [this.accidentPlaceDetails.getMachineryInvolved()],
            machineryOther: [this.accidentPlaceDetails.getMachineryInvolvedOther()],
            transportingConstructionMachineryInvolved: [this.accidentPlaceDetails.getTransportingConstructionMachineryInvolved()],
            transportingConstructionMachineryInvolvedDesc: [this.accidentPlaceDetails.getTransportingConstructionMachineryInvolvedDesc()],
            transportingConstructionMachineryOther: [this.accidentPlaceDetails.getTransportingConstructionMachineryInvolvedOther()]
        });

        this.placeTypeOptions1 = this.ecHelperService.getPlaceTypeOptions('Construction site');
        this.placeTypeOptions2 = this.ecHelperService.getPlaceTypeOptions('Shipyard');
        this.placeTypeOptions3 = this.ecHelperService.getPlaceTypeOptions('Manufactory');
        this.placeTypeOptions4 = this.ecHelperService.getPlaceTypeOptions('Others');
    }

    ngAfterViewInit() {
        this.accidentPlaceForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.accidentPlaceDetails.setAccidentPlace(this.accidentPlaceForm.get('accidentPlace').value);
                this.accidentPlaceDetails.setAccidentPlaceDesc(super.getSelectedRadioText('accidentPlace'));
                this.accidentPlaceDetails.setPlaceType(this.accidentPlaceForm.get('placeType').value);
                this.accidentPlaceDetails.setPlaceTypeDesc(super.getTextInComponent('placeType'));
                this.accidentPlaceDetails.setPlaceTypeOther(this.accidentPlaceForm.get('placeTypeOther').value);
                this.accidentPlaceDetails.setActivity(this.accidentPlaceForm.get('activity').value);
                this.accidentPlaceDetails.setWorkType(this.accidentPlaceForm.get('workType').value);
                this.accidentPlaceDetails.setWorkTypeDesc(super.getTextInComponent('workType'));
                this.accidentPlaceDetails.setWorkTypeOther(this.accidentPlaceForm.get('workTypeOther').value);
                this.accidentPlaceDetails.setWhereabouts(this.accidentPlaceForm.get('whereabouts').value);
                this.accidentPlaceDetails.setMachineryInvolved(super.getMultiSelectDropdownValue('machineryInvolved'));
                this.accidentPlaceDetails.setMachineryInvolvedOther(this.accidentPlaceForm.get('machineryOther').value);
                this.accidentPlaceDetails.setTransportingConstructionMachineryInvolved(this.accidentPlaceForm.get('transportingConstructionMachineryInvolved').value);
                this.accidentPlaceDetails.setTransportingConstructionMachineryInvolvedDesc(super.getTextInComponent('transportingConstructionMachineryInvolved'));
                this.accidentPlaceDetails.setTransportingConstructionMachineryInvolvedOther(this.accidentPlaceForm.get('transportingConstructionMachineryOther').value);
        });

        let self = this;
        
        setTimeout(function () {self.hideShowField(self.accidentPlaceDetails.getAccidentPlace())},10);
        this.accidentPlaceForm.get('accidentPlace').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowField(data);
        });

        setTimeout(function () {self.hideShowFieldPlaceTypeOthers(self.accidentPlaceDetails.getPlaceType())},10);
        this.accidentPlaceForm.get('placeType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowFieldPlaceTypeOthers(data);
        });

        setTimeout(function () {self.hideShowFieldWorkTypeOthers(self.accidentPlaceDetails.getWorkType())},10);
        this.accidentPlaceForm.get('workType').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowFieldWorkTypeOthers(data);
        });

        setTimeout(function () {self.hideShowFieldMachineryInvolvedOthers(self.accidentPlaceDetails.getMachineryInvolved())},10);
        this.accidentPlaceForm.get('machineryInvolved').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowFieldMachineryInvolvedOthers(data);
        });

        setTimeout(function () {self.hideShowFieldTransportingMachineryInvolvedOthers(self.accidentPlaceDetails.getTransportingConstructionMachineryInvolved())},10);
        this.accidentPlaceForm.get('transportingConstructionMachineryInvolved').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideShowFieldTransportingMachineryInvolvedOthers(data);
        });
}

    hideShowField(accidentPlace) {
        if (accidentPlace !== this.accidentPlaceDetails.getAccidentPlace()) {
            this.accidentPlaceForm.get('placeType').reset();
            this.accidentPlaceForm.get('placeTypeOther').reset();
            this.accidentPlaceForm.get('placeTypeOther').disable({onlySelf: false, emitEvent: false});
    
            this.accidentPlaceForm.get('activity').reset();
        }

        if (accidentPlace && accidentPlace === 'Construction site') {
            this.accidentPlaceForm.get('workType').enable({onlySelf: false, emitEvent: false});
            this.accidentPlaceForm.get('whereabouts').enable({onlySelf: false, emitEvent: false});
            this.accidentPlaceForm.get('machineryInvolved').enable({onlySelf: false, emitEvent: false});
            this.accidentPlaceForm.get('transportingConstructionMachineryInvolved').enable({onlySelf: false, emitEvent: false});
        } else {
            this.accidentPlaceForm.get('workType').reset();
            this.accidentPlaceForm.get('workType').disable({onlySelf: false, emitEvent: false});
            this.accidentPlaceForm.get('workTypeOther').reset();
            this.accidentPlaceForm.get('workTypeOther').disable({onlySelf: false, emitEvent: false});

            this.accidentPlaceForm.get('whereabouts').reset();
            this.accidentPlaceForm.get('whereabouts').disable({onlySelf: false, emitEvent: false});

            this.accidentPlaceForm.get('machineryInvolved').reset();
            this.accidentPlaceForm.get('machineryInvolved').disable({onlySelf: false, emitEvent: false});
            this.accidentPlaceForm.get('machineryOther').reset();
            this.accidentPlaceForm.get('machineryOther').disable({onlySelf: false, emitEvent: false});

            this.accidentPlaceForm.get('transportingConstructionMachineryInvolved').reset();
            this.accidentPlaceForm.get('transportingConstructionMachineryInvolved').disable({onlySelf: false, emitEvent: false});
            this.accidentPlaceForm.get('transportingConstructionMachineryOther').reset();
            this.accidentPlaceForm.get('transportingConstructionMachineryOther').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowFieldPlaceTypeOthers(placeType) {
        if (placeType && placeType.toLowerCase() === '15') {
            this.accidentPlaceForm.get('placeTypeOther').enable({onlySelf: false, emitEvent: false});
        } else {
            this.accidentPlaceForm.get('placeTypeOther').reset();
            this.accidentPlaceForm.get('placeTypeOther').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowFieldWorkTypeOthers(workType) {
        if (workType && workType.toLowerCase() === '20') {
            this.accidentPlaceForm.get('workTypeOther').enable({onlySelf: false, emitEvent: false});
        } else {
            this.accidentPlaceForm.get('workTypeOther').reset();
            this.accidentPlaceForm.get('workTypeOther').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowFieldMachineryInvolvedOthers(machineryInvolved) {
        if (machineryInvolved && this.isMachineryInvolvedContainOthers(machineryInvolved)) {
            this.accidentPlaceForm.get('machineryOther').enable({onlySelf: false, emitEvent: false});
        } else {
            this.accidentPlaceForm.get('machineryOther').reset();
            this.accidentPlaceForm.get('machineryOther').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideShowFieldTransportingMachineryInvolvedOthers(transportingConstructionMachineryInvolved) {
        if (transportingConstructionMachineryInvolved && transportingConstructionMachineryInvolved.toLowerCase() === '07') {
            this.accidentPlaceForm.get('transportingConstructionMachineryOther').enable({onlySelf: false, emitEvent: false});
        } else {
            this.accidentPlaceForm.get('transportingConstructionMachineryOther').reset();
            this.accidentPlaceForm.get('transportingConstructionMachineryOther').disable({onlySelf: false, emitEvent: false});
        }
    }

    isMachineryInvolvedContainOthers(machineryInvolved) {
        return machineryInvolved.find(item => item.value === '14');
    }

    validateForm(): boolean {
        return super.validateForm(this.accidentPlaceForm);
    }
    
    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitClaimTypeSubMenuShowUsingUrl({claimTypeId: 1, routeIndex: 2, 
                routeUrl: "/claimform/form2/supplementaryInfo", show: this.accidentPlaceDetails.getAccidentPlace() === 'Construction site'});

            let route = this.transactionInfo.getRouteByCurrentUrl('/placeOfAccident', true);
            if (route) {
                this.sideMenuService.emitClaimComplete({claimTypeId: route.claimIndex, subMenuIndex: route.routeIndex});
                this.sideMenuService.emitProcess(1, 0);
                this.router.navigate([route.routeUrl], {
                    relativeTo: this.activatedRoute
                });
            }
        }
    }

    back() {
        if (this.transactionInfoService.getTransactionInfo().getCurrentClaimType() == ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]) {
            this.router.navigate(["/claimform/form2/detailsOfEmployee"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }

    open(content) {
        this.modalService.open(content, {size: 'lg'});
    }
}
