<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate }}</h5>
    <p>{{ 'claimSection.missedConnection.lossOfMissedConnection.title' | translate }}</p>
  </div>
  <BR>
  <div class="row">
    <div class="col-lg-9 col-md-12">
      <form>
        <div>
          <div class="form-group">
            <qnect-ui #MISS_CONNECT_EXT_TRANS_RADIO [fieldId]="'MISS_CONNECT_EXT_TRANS_RADIO'" [formGroup]="missConnectExtraCostForm" name="hasMissConnectTransCost"></qnect-ui>
          </div>
          <div class="form-group click-more-content" id="q1" *ngIf="missConnection.getHasExtTransCost()">
            <qnect-ui #MISS_CONNECT_EXT_TRANS_COST [fieldId]="'MISS_CONNECT_EXT_TRANS_COST'" [formGroup]="missConnectExtraCostForm" name="missConnectTransCost"></qnect-ui>
          </div>
        </div>
        <div>
          <div>
            <qnect-ui #MISS_CONNECT_EXT_ACCOM_RADIO [fieldId]="'MISS_CONNECT_EXT_ACCOM_RADIO'" [formGroup]="missConnectExtraCostForm" name="hasMissConnectAccomCost"></qnect-ui>
          </div>
          <div class="form-group click-more-content" id="q2" *ngIf="missConnection.getHasExtAccomCost()">
            <qnect-ui #MISS_CONNECT_EXT_ACCOM_COST [fieldId]="'MISS_CONNECT_EXT_ACCOM_COST'" [formGroup]="missConnectExtraCostForm" name="missConnectAccomCost"></qnect-ui>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <a (click)="goToNext()"  href="javascript:;" class="new-btn btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></a>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
