<div class="section form-group">
    <label class="section-title">{{'motorClaim.confirmVehicle.vehicleDetails' | translate}}</label>
    <div class="section-content">
        <div class="sub-section">
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.confirmVehicle.policyHolderName' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5-word-break content">
                    {{claim.getPolicyHolderName()}}
                </div>
            </div>

            <div class="row" *ngIf="isNotNullOrUndefined(claim.getIsPolicyholder())">
                <div class="col col-xs-12 col-lg-7">
                    {{'policyHolder.isPolicyholder' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(claim.getIsPolicyholder())}}
                </div>
            </div>

            <div class="row" *ngIf="isNotNullOrUndefinedStr(claim.getPolicyholderEmail())">
                <div class="col col-xs-12 col-lg-7">
                    {{'policyHolder.emailAddress' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claim.getPolicyholderEmail()}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.confirmVehicle.vehicleRegNumber' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimMotor.getVehicleNumber()}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.confirmVehicle.makeAndModel' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimMotor.getConfirmMakeAndModel()}}
                </div>
            </div>

            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{'motorClaim.confirmVehicle.yearOfManufacture' | translate}}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{claimMotor.getConfirmYearOfManufacture()}}
                </div>
            </div>
        </div>

    </div>
</div>
