export class PoliceReport {
    
    private hasReportedIncidentToPolice: boolean;
    private dateOfReport: Date;
    private policeReportNumber: string;
    private policeStation: string;
    private hasComplainedAgainstOtherParty: boolean;

    getHasReportedIncidentToPolice(): boolean {
        return this.hasReportedIncidentToPolice;
    }

    setHasReportedIncidentToPolice(hasReportedIncidentToPolice: boolean): void {
        this.hasReportedIncidentToPolice = hasReportedIncidentToPolice;
    }

    getDateOfReport(): Date {
        return this.dateOfReport;
    }

    setDateOfReport(dateOfReport: Date): void {
        this.dateOfReport = dateOfReport;
    }

    getPoliceReportNumber(): string {
        return this.policeReportNumber;
    }

    setPoliceReportNumber(policeReportNumber: string): void {
        this.policeReportNumber = policeReportNumber;
    }

    getPoliceStation(): string {
        return this.policeStation;
    }

    setPoliceStation(policeStation: string): void {
        this.policeStation = policeStation;
    }

    getHasComplainedAgainstOtherParty(): boolean {
        return this.hasComplainedAgainstOtherParty;
    }

    setHasComplainedAgainstOtherParty(hasComplainedAgainstOtherParty: boolean): void {
        this.hasComplainedAgainstOtherParty = hasComplainedAgainstOtherParty;
    }

    
    static jsonConvert(policeReport: PoliceReport): PoliceReport {

        if (policeReport.getDateOfReport() != null) {
            policeReport.setDateOfReport(new Date(policeReport.getDateOfReport()));
        }

        return policeReport;
    }

}
