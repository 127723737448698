import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import { PolicyRisks } from "src/app/model/policy-risks.model";

export class HomeFormBuilderUtil {

    constructor() {
        //do nothing
    }
   
   getRiskDocuments(claim: Claim): DocumentField[]{
        let claimHome = claim.getClaimHome();
        let risks = claim.getPolicyRisks();
        let tempDocs: DocumentField[] = [];

        if(claimHome.getHasHPLHHLRisk() && ['true', 'Unsure'].indexOf(claimHome.getHomeDetails().getThirdPartyLossDetails().getIsIncidentInvolve()) !== -1){
            let riskId = this.hasRisk("HPL", risks) ? "HPL" : "HHL";
            tempDocs.push(this.addDocument(new DocumentField('LIABPHOTOTHIRDPARTY', 'homeProduct.supportingDocs.types.photoThirdParty', SmartqDocuments.HOME_PHOTOS, true), riskId));
            tempDocs.push(this.addDocument(new DocumentField('LIABCORRESPONDENCE', 'homeProduct.supportingDocs.types.thirdPartyCorrespondence', SmartqDocuments.HOME_CORRESPONDENCE, true), riskId));
            tempDocs.push(this.addDocument(new DocumentField('LIABWITNESS', 'homeProduct.supportingDocs.types.witnessStatement', SmartqDocuments.HOME_OTHER, true), riskId));
            tempDocs.push(this.addDocument(new DocumentField('LIABPOLICEREPORT', 'homeProduct.supportingDocs.types.policeReportLiability', SmartqDocuments.HOME_REPORT, true), riskId));
        }
        if(claimHome.getHasHPWRisk() && claimHome.getHomeDetails().getDomesticEmployerLiability().getWasHelperInjured()){
            tempDocs.push(this.addDocument(new DocumentField('ECFORMTWO', 'homeProduct.supportingDocs.types.form2', SmartqDocuments.HOME_ASSESSMENT, true), "HPW"));
            tempDocs.push(this.addDocument(new DocumentField('ECMEDEXP', 'homeProduct.supportingDocs.types.medicalExpenses', SmartqDocuments.HOME_INVOICE, true), "HPW"));
            tempDocs.push(this.addDocument(new DocumentField('ECSICKLEAVEXP', 'homeProduct.supportingDocs.types.sickLeaveCertificate', SmartqDocuments.HOME_OTHER, true), "HPW"));
            tempDocs.push(this.addDocument(new DocumentField('ECEMPCONTRACT', 'homeProduct.supportingDocs.types.employmentContract', SmartqDocuments.HOME_LEGALDOC, true), "HPW"));
        }
        if(claimHome.getHasHPPRisk() && claimHome.getHomeDetails().getPersonalAccident().getHasInjury()){
            tempDocs.push(this.addDocument(new DocumentField('PADIAGNOSIS', 'homeProduct.supportingDocs.types.medicalAccidentalExpenses', SmartqDocuments.HOME_MEDICAL, true), "HPP"));
            tempDocs.push(this.addDocument(new DocumentField('PADISABILITY', 'homeProduct.supportingDocs.types.medicalReportDisablement', SmartqDocuments.HOME_MEDREP, true), "HPP"));
            tempDocs.push(this.addDocument(new DocumentField('PADEATHCERT', 'homeProduct.supportingDocs.types.deathCertificate', SmartqDocuments.HOME_LEGALDOC, true), "HPP"));
            tempDocs.push(this.addDocument(new DocumentField('PAPOLICEREPORT', 'homeProduct.supportingDocs.types.policeReportAccident', SmartqDocuments.HOME_REPORT, true), "HPP"));
        }

        return tempDocs;
   }

   addDocument(documentField: DocumentField, riskId: string): DocumentField{
       documentField.setRiskId(riskId);
       return documentField;
   }

   hasRisk(riskType: string, risks: PolicyRisks[]): boolean {
        if(risks.length > 0){
            const index : number = risks.findIndex(risk => risk.getRiskType() === riskType);
            if(index > -1) {
                return true;
            }
        }
        return false;
    }
}