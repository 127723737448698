import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import {UtilitiesService} from '../../../utilities/utilities.service';
import {FatalCase} from '../../../model/employee-compensation/fatal-case.model';

@Component({
    selector: 'app-fatal-case',
    templateUrl: './fatal-case.component.html'
})
export class FatalCaseComponent extends ClaimsBaseComponent implements OnInit {

    fatalCaseForm: FormGroup;
    fatalCase: FatalCase;

    constructor(private fb: FormBuilder,
                private injector : Injector) {
        super(injector);
        this.fatalCase = this.claim.getClaimEmployeeCompensation().getFatalCase();
    }

    ngOnInit() {
        if (!this.isNotNullOrUndefinedStr(this.fatalCase.getTelephoneNumberCode())){
            this.fatalCase.setTelephoneNumberCode(UtilitiesService.getCountryCode(this.claim.getCountry()));
        }
        
        this.fatalCaseForm = this.fb.group({
            wasPoliceNotified: [super.getBooleanString(this.fatalCase.getWasPoliceNotified())],
            policeStation: [this.fatalCase.getPoliceStation()],
            kin: [this.fatalCase.getKin()],
            relationship: [this.fatalCase.getRelationship()],
            telephoneNumber: [this.fatalCase.getTelephoneNumber()],
            telephoneNumberCode: [this.fatalCase.getTelephoneNumberCode()],
            address: [this.fatalCase.getAddress()]
        })
    }

    ngAfterViewInit() {
        this.fatalCaseForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                if (this.fatalCaseForm.get('wasPoliceNotified').value != null) {
                    this.fatalCase.setWasPoliceNotified(this.fatalCaseForm.get('wasPoliceNotified').value == 'true');
                }
                this.fatalCase.setPoliceStation(this.fatalCaseForm.get('policeStation').value);
                this.fatalCase.setKin(this.fatalCaseForm.get('kin').value);
                this.fatalCase.setRelationship(this.fatalCaseForm.get('relationship').value);
                this.fatalCase.setTelephoneNumber(this.fatalCaseForm.get('telephoneNumber').value);
                this.fatalCase.setTelephoneNumberCode(this.fatalCaseForm.get('telephoneNumberCode').value);
                this.fatalCase.setAddress(this.fatalCaseForm.get('address').value);
            });

        this.fatalCaseForm.get('wasPoliceNotified').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.validatePoliceStationField(data);
        })
    }

    validatePoliceStationField(data){
        if (data == 'true') {
            this.fatalCaseForm.get('policeStation').enable({onlySelf: false, emitEvent: false});
        } else {
            this.fatalCaseForm.get('policeStation').reset();
            this.fatalCaseForm.get('policeStation').disable({onlySelf: false, emitEvent: false});
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.fatalCaseForm);
    }
}
