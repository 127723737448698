import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { HomeHelperService } from 'src/app/services/home-helper.service';
import { InjuredItemDetail } from 'src/app/model/motor/injured-item-detail.model';
import { PropertyItemDetail } from 'src/app/model/motor/property-item-detail.model';
import { ThirdPartyLossDetails } from 'src/app/model/home/third-party-loss-details.model';
import { ThirdPartyLegalDetails } from 'src/app/model/home/third-party-legal-details.model';
import { UtilitiesService } from 'src/app/utilities/utilities.service';

@Component({
    selector: 'app-third-party-loss',
    templateUrl: './loss-details.component.html',
    styleUrls: ['./loss-details.component.css']
})
export class TpLossDetailsComponent extends ClaimsBaseComponent implements OnInit {

    tpLossDetailsForm: FormGroup;
    damageTypeList = [];
    injuredItemsArray: InjuredItemDetail[] = [];
    propertyDamageItemsArray: PropertyItemDetail[] = [];
    isPerson: Boolean = false;
    isProperty: Boolean = false;
    tpLossDetails: ThirdPartyLossDetails;
    tpLegalDetails: ThirdPartyLegalDetails;
    showErrMsg: boolean = false;
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private transactionInfoService: TransactionInfoService,
                private homeHelperService: HomeHelperService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.tpLossDetails = this.claim.getClaimHome().getHomeDetails().getThirdPartyLossDetails();
        this.tpLegalDetails = this.claim.getClaimHome().getHomeDetails().getThirdPartyLegalDetails();
    }

    ngOnInit() {

        this.pushGATPLossDetailsView();

        this.injuredItemsArray = this.tpLossDetails.getInjuredItemList();
        this.propertyDamageItemsArray = this.tpLossDetails.getPropertyDamageItemList();
        this.damageTypeList = this.homeHelperService.getDamageType();

        this.tpLossDetailsForm = this.fb.group({
            didIncidentInvolve: [this.tpLossDetails.getIsIncidentInvolve()],
            didIncidentInvolveDesc: [this.tpLossDetails.getIsIncidentInvolveDesc()],
            incidentDescription:[this.tpLossDetails.getIncidentDescription()],
            personItem:  this.fb.array(this.buildInjuredStoredItems(this.injuredItemsArray)),
            propertyItem:  this.fb.array(this.buildPropertyStoredItems(this.propertyDamageItemsArray)),
            hasMadeAnyClaim: [super.getBooleanString(this.tpLegalDetails.getHasAnyClaimMade())],
            otherInsurerName: [this.tpLegalDetails.getTpClaimOtherInsurerName()],
            contactNumber: [this.tpLegalDetails.getTpClaimContactNumber()]
        });

        this.initilizeItemsArray();
        this.toggleLiabilities();
    }

    ngAfterViewInit() {
        let self = this;

        this.tpLossDetailsForm.valueChanges.subscribe(data => {
            if(this.claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_TP_LIABILITY') !== -1) {
                this.tpLossDetails.setIsIncidentInvolve('true');
                this.tpLossDetails.setIsIncidentInvolveDesc(this.translate.instant('claimSection.generalLabels.radioButtonLabels.yes'));
            } else if(this.tpLossDetailsForm.get('didIncidentInvolve').value != null) {
                this.tpLossDetails.setIsIncidentInvolve(super.getValueInComponent('didIncidentInvolve'));
                this.tpLossDetails.setIsIncidentInvolveDesc(super.getSelectedRadioText('didIncidentInvolve'));
            }

            this.tpLossDetails.setIncidentDescription(this.tpLossDetailsForm.get('incidentDescription').value)
            
            if(!UtilitiesService.isNullOrUndefined(data.personItem) && (data.personItem !== null || data.personItem !== [] || data.personItem !== "")){
                for (let i = 0; i < data.personItem.length; i++) {

                    let injuredDamageItemDetails : InjuredItemDetail = self.injuredItemsArray[i];
                    injuredDamageItemDetails.setExtentOfInjury(data.personItem[i].extentOfInjury);
                    injuredDamageItemDetails.setExtentOfInjuryDesc(super.getIndexedTextInComponent("extentOfInjury", i));
                    injuredDamageItemDetails.setAgeGroup(data.personItem[i].ageGroup);
                    injuredDamageItemDetails.setAgeGroupDesc(super.getIndexedTextInComponent("ageGroup", i));
                    injuredDamageItemDetails.setRelationship(data.personItem[i].relationship);
                    injuredDamageItemDetails.setRelationshipDesc(super.getIndexedTextInComponent("relationship", i));
                    injuredDamageItemDetails.setInjuredPersonName(data.personItem[i].injuredPersonName);
                    injuredDamageItemDetails.setInjuredPersonNumber(data.personItem[i].injuredPersonNumber);
                }
            }

            if(!UtilitiesService.isNullOrUndefined(data.propertyItem) && (data.propertyItem !== null || data.propertyItem !== [] || data.propertyItem !== "")){
                for (let i = 0; i < data.propertyItem.length; i++) {
                    let propertyDamageItemDetails : PropertyItemDetail = self.propertyDamageItemsArray[i];
                    propertyDamageItemDetails.setWhatDidYouHit(data.propertyItem[i].whatDidYouHit);
                    propertyDamageItemDetails.setPropertyOwnerName(data.propertyItem[i].propertyOwnerName);
                    propertyDamageItemDetails.setPropertyOwnerNo(data.propertyItem[i].propertyOwnerNo);
                }
            }

            if (this.tpLossDetailsForm.get('hasMadeAnyClaim').value != null) {
                this.tpLegalDetails.setHasAnyClaimMade(this.tpLossDetailsForm.get('hasMadeAnyClaim').value == 'true');
            }
            this.tpLegalDetails.setTpClaimOtherInsurerName(this.tpLossDetailsForm.get('otherInsurerName').value);
            this.tpLegalDetails.setTpClaimContactNumber(this.tpLossDetailsForm.get('contactNumber').value);
        });

        setTimeout(function () {self.hideConditionalField(self.tpLossDetails.getIsIncidentInvolve())},10);
        this.tpLossDetailsForm.get('didIncidentInvolve').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideConditionalField(data);
        });

        setTimeout(function () {self.hideHasMadeAnyClaimFields(self.getBooleanString(self.tpLegalDetails.getHasAnyClaimMade()))},10);
        this.tpLossDetailsForm.get('hasMadeAnyClaim').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            this.hideHasMadeAnyClaimFields(data);
        });
    }

    hideConditionalField(didIncidentInvolve) {
        if (!UtilitiesService.isNullOrUndefined(didIncidentInvolve) && 
            (didIncidentInvolve === "false" || didIncidentInvolve === "Unsure")) {
            setTimeout(() => {
                this.resetThirdPartyDetails();
                this.initilizeItemsArray();
            }, 200);
        }
        if (didIncidentInvolve === "Unsure") {
            this.tpLossDetailsForm.get('incidentDescription').enable({onlySelf: false, emitEvent: false});
        } else {
            this.tpLossDetailsForm.get('incidentDescription').reset();
            this.tpLossDetailsForm.get('incidentDescription').disable({onlySelf: false, emitEvent: false});
        }
    }

    hideHasMadeAnyClaimFields(hasMadeAnyClaim) {
        if (hasMadeAnyClaim == "true") {
            this.tpLossDetailsForm.get('otherInsurerName').enable({onlySelf: false, emitEvent: false});
            this.tpLossDetailsForm.get('contactNumber').enable({onlySelf: false, emitEvent: false});
        } else {
            this.tpLossDetailsForm.get('otherInsurerName').reset();
            this.tpLossDetailsForm.get('otherInsurerName').disable({onlySelf: false, emitEvent: false});
            this.tpLossDetailsForm.get('contactNumber').reset();
            this.tpLossDetailsForm.get('contactNumber').disable({onlySelf: false, emitEvent: false});
        }
    }

    buildInjuredStoredItems(injuredDamageItemsArray: InjuredItemDetail[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];
        if(injuredDamageItemsArray != null && injuredDamageItemsArray.length > 0 ) {
            for (let i = 0; i < injuredDamageItemsArray.length; i++) {
                let rowFormGroup = this.fb.group({
                    injuredPersonName: [injuredDamageItemsArray[i].getInjuredPersonName()],
                    ageGroup: [injuredDamageItemsArray[i].getAgeGroup()],
                    relationship: [injuredDamageItemsArray[i].getRelationship()],
                    injuredPersonNumber: [injuredDamageItemsArray[i].getInjuredPersonNumber()],
                    extentOfInjury: [injuredDamageItemsArray[i].getExtentOfInjury()]
                });

                formGroupArray.push(rowFormGroup);
            }
        } else {
            formGroupArray.push(this.createInjuredDefaultItems());
        }

        return formGroupArray;
    }

    createInjuredDefaultItems(): FormGroup {
        return this.fb.group({
            injuredPersonName: '',
            ageGroup: '',
            relationship: '',
            injuredPersonNumber: '',
            extentOfInjury: ''
        });
    }

    buildPropertyStoredItems(propertyDamageItemsArray: PropertyItemDetail[]): FormGroup[] {
        let formGroupArray : FormGroup[] = [];
        if(propertyDamageItemsArray != null && propertyDamageItemsArray.length > 0 ) {
            for (let i = 0; i < propertyDamageItemsArray.length; i++) {
                let rowFormGroup = this.fb.group({
                    whatDidYouHit: [propertyDamageItemsArray[i].getWhatDidYouHit()],
                    propertyOwnerName: [propertyDamageItemsArray[i].getPropertyOwnerName()],
                    propertyOwnerNo: [propertyDamageItemsArray[i].getPropertyOwnerNo()]
                });

                formGroupArray.push(rowFormGroup);
            }
        } else {
            formGroupArray.push(this.createPropertyDefaultItems());
        }

        return formGroupArray;
    }

    createPropertyDefaultItems(): FormGroup {
        return this.fb.group({
            whatDidYouHit: '',
            propertyOwnerName: '',
            propertyOwnerNo: ''
        });
    }

    initilizeItemsArray (){
        if (this.injuredItemsArray == null || this.injuredItemsArray.length == 0) {
            this.damageTypeList[0].selected = false;
        }

        if (this.propertyDamageItemsArray == null || this.propertyDamageItemsArray.length == 0) {
            this.damageTypeList[1].selected = false;
        }

        if (this.injuredItemsArray == null) {
            this.injuredItemsArray = [];
            this.tpLossDetails.setInjuredItemList(this.injuredItemsArray);
        }

        if (this.injuredItemsArray.length == 0) {
            let injuredItemDetail: InjuredItemDetail = new InjuredItemDetail();
            this.injuredItemsArray.push(injuredItemDetail);
        }

        if (this.propertyDamageItemsArray == null) {
            this.propertyDamageItemsArray = [];
            this.tpLossDetails.setPropertyDamageItemList(this.propertyDamageItemsArray);
        }

        if (this.propertyDamageItemsArray.length == 0) {
            let propertyItemDetail: PropertyItemDetail = new PropertyItemDetail();
            this.propertyDamageItemsArray.push(propertyItemDetail);
        }
    }

    toggleLiabilities(){
        if(this.tpLossDetails.getIsIncidentInvolve() === 'true'){
            this.toggleLiability(0, this.injuredItemsArray);
            this.toggleLiability(1, this.propertyDamageItemsArray);
        }
    }

    toggleLiability(damageTypeIndex, damageItemArray) {
        if (damageItemArray != null && damageItemArray.length > 0) { 
            let toggleEnabled = false;
            for(let damageTypeItem of  damageItemArray) {
                if(Object.keys(damageTypeItem).length > 0){
                    let hasValues = Object.values(damageTypeItem).some( v => v !== null && typeof v !== "undefined" && v !== "");
                    if(hasValues){
                        toggleEnabled = true;
                        break;
                    }
                }
            }
            this.damageTypeList[damageTypeIndex].selected = toggleEnabled;
        }  
    }

    resetThirdPartyDetails() {
        let self = this;
        self.damageTypeList.forEach(function (damageTypeItem, index){
            if (damageTypeItem.selected) {
                damageTypeItem.selected = false;
            }
        });

        let emptyList: string[] = [];
        self.tpLossDetails.setDetailsOfDamagedType(emptyList);
        self.tpLossDetails.setDetailsOfDamagedTypeDesc(emptyList);
        this.tpLossDetailsForm.get('personItem').reset();
        this.tpLossDetailsForm.get('personItem').disable({onlySelf: false, emitEvent: false});
        this.tpLossDetailsForm.get('propertyItem').reset();
        this.tpLossDetailsForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        this.showErrMsg = false;
    }

    selectDamageType(selectedValue: string) {
        this.showErrMsg = false;
        let motorWsSelectedItems: string[]  = this.tpLossDetails.getDetailsOfDamagedType().length > 0 ? this.tpLossDetails.getDetailsOfDamagedType() : [];
        let motorWsSelectedItemsDesc: string[] =  this.tpLossDetails.getDetailsOfDamagedTypeDesc().length > 0 ? this.tpLossDetails.getDetailsOfDamagedTypeDesc() : [];
        let self = this;
        self.damageTypeList.forEach(function (damageTypeList, index){
            if (damageTypeList.damageTypeValue == selectedValue) {
                if (damageTypeList.selected) {
                    damageTypeList.selected = false;
                    setTimeout(function () {self.isEnabledDisabledDamageType(selectedValue, "Disable")},10);
                    self.removeDamageTypeList(selectedValue,motorWsSelectedItems, motorWsSelectedItemsDesc);
                } else {
                    damageTypeList.selected = true;
                    setTimeout(function () {self.isEnabledDisabledDamageType(selectedValue, "Enable")},10);
                    motorWsSelectedItems.push(damageTypeList.damageTypeValue);
                    let damageTypeNameText = damageTypeList.damageTypeName;
                    motorWsSelectedItemsDesc.push(damageTypeNameText);
                    self.tpLossDetails.setHasSelectedDamageType(true);
                    self.enableDamageTypeDetails(damageTypeList.damageTypeValue);
                }
                self.tpLossDetails.setDetailsOfDamagedType(motorWsSelectedItems);
                self.tpLossDetails.setDetailsOfDamagedTypeDesc(motorWsSelectedItemsDesc);
            }
        });
    }

    isEnabledDisabledDamageType(damageType: string, isEnableDisableType: string){
        let type = isEnableDisableType === "Enable" ? true : false;
        if(damageType === "Person"){
            this.isPerson = type;
        }else if(damageType === "Property"){
            this.isProperty = type;
        }
    }

    removeDamageTypeList(selectedValue: string, motorWsSelectedItems: string[], motorWsSelectedItemsDesc: string[]) {
        let indexSelected: number = -1 ; // determine index for description to remove or not
        for(var i= motorWsSelectedItems.length - 1; i => 0; i--){
            if (motorWsSelectedItems[i] == selectedValue) {
                indexSelected = i;
                break;
            }
        }
    
        if (indexSelected => 0) {
            motorWsSelectedItems.splice(indexSelected, 1);
            motorWsSelectedItemsDesc.splice(indexSelected, 1);
        }
    
        if (selectedValue == "Property") {
            this.propertyDamageItemsArray = [];
            this.tpLossDetails.setPropertyDamageItemList(this.propertyDamageItemsArray);
           if (this.propertyDamageItemsArray.length == 0) { 
              let propertyItemDetail: PropertyItemDetail = new PropertyItemDetail();
              this.propertyDamageItemsArray.push(propertyItemDetail);
            }    
        } else if(selectedValue == "Person"){
            this.injuredItemsArray = [];
            this.tpLossDetails.setInjuredItemList(this.injuredItemsArray);
            if (this.injuredItemsArray.length == 0) {
                let injuredItemDetail: InjuredItemDetail = new InjuredItemDetail();
                this.injuredItemsArray.push(injuredItemDetail);
            }
        }

        this.disableDamageTypeDetails(selectedValue);
        this.tpLossDetails.setDetailsOfDamagedType(motorWsSelectedItems);
        this.tpLossDetails.setDetailsOfDamagedTypeDesc(motorWsSelectedItemsDesc);
    }

    disableDamageTypeDetails(damageType: string) {
        if (damageType == "Person"){
            this.tpLossDetailsForm.get('personItem').reset();
            this.tpLossDetailsForm.get('personItem').disable({onlySelf: false, emitEvent: false});
        } else if (damageType == "Property"){
            this.tpLossDetailsForm.get('propertyItem').reset();
            this.tpLossDetailsForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        } else if (damageType == "All") {
            this.tpLossDetailsForm.get('personItem').reset();
            this.tpLossDetailsForm.get('personItem').disable({onlySelf: false, emitEvent: false});
            this.tpLossDetailsForm.get('propertyItem').reset();
            this.tpLossDetailsForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        }
    }

    addDamageItem(damageType: string): void {
        let self = this;
        setTimeout(function () {
            if (damageType == "personItem") {
                let injuredDetail: InjuredItemDetail = new InjuredItemDetail();
                self.injuredItemsArray.push(injuredDetail);

                let items = self.tpLossDetailsForm.get('personItem') as FormArray;
                let newItem: FormGroup = self.createInjuredDefaultItems();
                items.push(newItem);
            } else if (damageType == "propertyItem") {
                let propertyDetail : PropertyItemDetail = new PropertyItemDetail();
                self.propertyDamageItemsArray.push(propertyDetail);

                let items = self.tpLossDetailsForm.get('propertyItem') as FormArray;
                let newItem :FormGroup = self.createPropertyDefaultItems();
                items.push(newItem)
            }
        }, 10);
    }

    removeDamageItem(damageType: string, i: number) {
        let damageItem = this.tpLossDetailsForm.get(damageType) as FormArray;
        damageItem.removeAt(i);
        if (damageType == "personItem") {
            this.injuredItemsArray.splice(i,1);
        } else if (damageType == "propertyItem"){
            this.propertyDamageItemsArray.splice(i,1);
        }
    }

    enableDamageTypeDetails(damageType: string) {
        if (damageType == "Person"){
            this.tpLossDetailsForm.get('personItem').enable({onlySelf: false, emitEvent: false});
        } else if (damageType == "Property"){
            this.tpLossDetailsForm.get('propertyItem').enable({onlySelf: false, emitEvent: false});
        } else if (damageType == "All") {
            this.tpLossDetailsForm.get('personItem').reset();
            this.tpLossDetailsForm.get('personItem').disable({onlySelf: false, emitEvent: false});
            this.tpLossDetailsForm.get('propertyItem').reset();
            this.tpLossDetailsForm.get('propertyItem').disable({onlySelf: false, emitEvent: false});
        }
    }

    validateForm(): boolean {
        let damageTypeSelectedItems = this.tpLossDetails.getDetailsOfDamagedType();
        let isValidated : boolean = true;

        if(this.tpLossDetails.getIsIncidentInvolve() === 'true' && damageTypeSelectedItems.length == 0){
            this.tpLossDetails.setHasSelectedDamageType(false);
            isValidated = false;
            this.showErrMsg = true;
        }

        return super.validateForm(this.tpLossDetailsForm) && isValidated;
    }
    goToNext() {
        if (this.validateForm()) {
            this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
        }
    }

    back() {
        this.transactionInfoService.getDynamicRoutingService().goToPreviousRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);
    }

    pushGATPLossDetailsView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' – Third Party';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/third-party';

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': pageStep,
            'vPath': vPath,            
            'event': 'vpageview'
        });
    }

    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }
  
    getData(msg) {
        this.showTotalBox = msg;
    }
}
