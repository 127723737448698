<app-summary-home-details></app-summary-home-details>
<app-summary-water-damage *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_WATER') !== -1"></app-summary-water-damage>
<app-summary-weather *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_WEATHER') !== -1"></app-summary-weather>
<app-summary-contents-building *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_FIRE') !== -1 ||
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_VANDALISM') !== -1 ||
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_BURGLARY') !== -1"></app-summary-contents-building>
<app-summary-home-items *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_ACCIDENT') !== -1"></app-summary-home-items>
<app-summary-authority-report *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_FIRE') !== -1 ||
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_VANDALISM' ) !== -1 ||
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_BURGLARY') !== -1"></app-summary-authority-report>
<app-summary-other-damage *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_OTHERS') !== -1"></app-summary-other-damage>
<app-summary-lost-personal-items *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_BAG_LOST') !== -1"></app-summary-lost-personal-items>
<app-summary-damaged-personal-items *ngIf="claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_BAG_DMG') !== -1"></app-summary-damaged-personal-items>
<app-summary-liability *ngIf="((claimHome.getPlaceOfIncident() === 'home' || claimHome.getPlaceOfIncident() === 'outside') && 
claimHome.getHasHPLHHLRisk()) && (claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_DMH') === -1 && 
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_PA') === -1) && claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_BAG_LOST') === -1 && 
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_BAG_DMG') === -1"></app-summary-liability>
<app-summary-domestic-employer *ngIf="claimHome.getHasHPWRisk() && (claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_DMH') !== -1)"></app-summary-domestic-employer>
<app-summary-personal-accident-details *ngIf="claimHome.getHasHPPRisk() && (claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_FIRE') !== -1 || 
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_BURGLARY') !== -1 || 
claim.getSelectedClaimTypesList().indexOf('CLAIM_TYPE_HOME_PA') !== -1)"></app-summary-personal-accident-details>
<app-summary-responsibility></app-summary-responsibility>
<app-summary-supporting-docs></app-summary-supporting-docs>
