import {ClaimDetails} from './claim-details.model';

export class ClaimSearchResult {

    public static readonly CLAIM_STATUS_PROCESSING = "PROCESSING";
    public static readonly CLAIM_STATUS_AWAITING_DOCUMENT = "AWAITING DOCUMENT";
    public static readonly CLAIM_STATUS_PAID = "PAID";
    public static readonly CLAIM_STATUS_CLOSED = "CLOSED";

    private claimToken: string;
    private errorCode: string = "";
    private errorStr: string = "";
    private productType: string;
    private country: string;
    private policyType: string;
    private policyNumber: string;
    private isManulife: boolean;
    private catalogueCode: string;
    private claimDetailsList: ClaimDetails[] = [];

    getClaimDetailsList(): ClaimDetails[] {
        return this.claimDetailsList;
    }

    setClaimDetailsList(value: ClaimDetails[]) {
        this.claimDetailsList = value;
    }

    getClaimToken(): string {
        return this.claimToken;
    }

    setClaimToken(value: string) {
        this.claimToken = value;
    }

    getErrorCode(): string {
        return this.errorCode;
    }

    setErrorCode(value: string) {
        this.errorCode = value;
    }

    getErrorStr(): string {
        return this.errorStr;
    }

    setErrorStr(value: string) {
        this.errorStr = value;
    }

    getProductType(): string {
        return this.productType;
    }

    setProductType(value: string) {
        this.productType = value;
    }

    getCountry(): string {
        return this.country;
    }

    setCountry(value: string) {
        this.country = value;
    }

    getPolicyType(): string {
        return this.policyType;
    }

    setPolicyType(value: string) {
        this.policyType = value;
    }

    getPolicyNumber(): string {
        return this.policyNumber;
    }

    setPolicyNumber(value: string) {
        this.policyNumber = value;
    }

    getIsManulife(): boolean {
        return this.isManulife;
    }

    setIsManulife(value: boolean) {
        this.isManulife = value;
    }

    getCatalogueCode(): string {
        return this.catalogueCode;
    }

    setCatalogueCode(value: string) {
        this.catalogueCode = value;
    }


}