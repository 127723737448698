import {AfterViewInit, Directive, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UiComponent} from '../../ui.component';

@Directive({selector: '[dateRangeValidator]'})
export class DateRangeValidatorDirective implements OnInit, AfterViewInit {

  @Input('dateRangeValidator') dateRef: [Date, Date[][], Date];
  @Input() operator: string;
  dateComponent: UiComponent;

  constructor(private host: UiComponent) {
    this.dateComponent = host;
  }

  ngOnInit() {
    this.initializeDateValidator();
  }

  ngAfterViewInit() {
    this.initializeDateValidator();
  }

  initializeDateValidator() {
    const dateComponentType = this.dateComponent.fieldConfig['type'];
    if (dateComponentType === 'datepicker' || dateComponentType === 'datetimepicker') {
        if (dateComponentType === 'datepicker') {
            this.dateComponent.setupCustomValidatorList([this.validateDate()]);
        } else {
            this.dateComponent.setupCustomValidatorList([this.validate()]);
        }
      if (this.dateRef) {
        this.dateComponent.update();
      }
    } else {
        console.log('dateRangeValidator not in datepicker component. UI Component validator error: ' +
        this.dateComponent.fieldId + ' - ' + this.dateComponent.fieldConfig['name']);
    }
  }

  validate = () => {
    return (c: FormControl) => {
      if (this.dateRef && this.dateComponent.getDateValue()) {
        if(this.dateRef[0] != null && this.checkIfOverInitialDate(false)) {
          return {'dateValidator': true};
        }
        if(this.dateRef[1] != null && this.checkDates()) {
          return {'dateRangeValidator': true};
        }
        if(this.dateRef[2] != null && this.checkIfOverLastDate(false)){
            return {'aboveMaxDateValidator': true};
        }
      }
      return null;
    }
  };

  validateDate = () => {
    return (c: FormControl) => {
        if (this.dateRef && this.dateComponent.getDateValue()) {
            if(this.dateRef[0] != null && this.checkIfOverInitialDate(true)) {
                return {'dateValidator': true};
            }
            if(this.dateRef[1] != null && this.checkDates()) {
                return {'dateRangeValidator': true};
            }
            if(this.dateRef[2] != null && this.checkIfOverLastDate(true)){
                return {'aboveMaxDateValidator': true};
            }
        }
        return null;
    }
  };

  checkIfOverInitialDate(dateOnly: boolean): boolean {
    if (dateOnly) {
        return this.getDateValue(this.dateRef[0]) > this.getDateValue(this.dateComponent.getDateValue());
    }
    return new Date(this.dateRef[0]) > new Date(this.dateComponent.getDateValue());
  }

  checkDates(): boolean {
    let dates = this.dateRef[1];
    if(dates != null){
        return this.checkForOverlappingDates(dates, this.dateComponent.getDateValue());
    }
    return false;
  }

  checkForOverlappingDates(dates: Date[][], dateToCheck: Date) : boolean {
    let hasOverlap = false;
    for(let x = 0; x < dates.length;  x++) {
      if(this.datesOverlap(dateToCheck, dates[x][0], dates[x][1])){
        hasOverlap = true;
      }
    }
    return hasOverlap;
  }
  
  datesOverlap(dateToCompare: Date, dateOfAdmission: Date, dateOfDischarge: Date): boolean {
     if(dateToCompare != null && (dateToCompare >= dateOfAdmission && dateToCompare <= dateOfDischarge)) {
        return true;
     }
     return false;
  }

  checkIfOverLastDate(dateOnly: boolean): boolean {
    if (dateOnly) {
        return this.getDateValue(this.dateRef[2]) < this.getDateValue(this.dateComponent.getDateValue());
    }
    return new Date(this.dateRef[2]) < new Date(this.dateComponent.getDateValue());
  }

  getDateValue(dateRef: Date): number {
      return new Date(dateRef).setHours(0,0,0,0);
  }

}
