import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class RepatriationFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_REPATRIATION];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('REPATEXPENSE', 'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.repatExpense', SmartqDocuments.DMH_REPATRIATIONEXP, true));
        requiredDocuments.push(new DocumentField('COPYOFEMPCONTRACT', 'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.copyOfEmpContract', SmartqDocuments.DMH_EMPLOYEE, true));
        requiredDocuments.push(new DocumentField('CERTIFICATE', 'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.certificate', SmartqDocuments.DMH_UNFIT, true));
        if(this.claim.getClaimDomesticHelper().getRepatriation().getHasAgentFeePaid()){
            requiredDocuments.push(new DocumentField('AGENTFEEEXPENSE', 'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.agentFeeExpense', SmartqDocuments.DMH_AGENTFEEEXP, true));
            requiredDocuments.push(new DocumentField('COPYOFREPLACEMENT', 'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.copyOfReplacement', SmartqDocuments.DMH_PROOFFORREPLACE, true));
        }
        if(this.claim.getClaimDomesticHelper().getRepatriation().getHasTempHelper()){
            requiredDocuments.push(new DocumentField('RELEVANTEXPENSE', 'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.relevantExpense', SmartqDocuments.DMH_EXPRECEIPTFORTEMP, true));
            requiredDocuments.push(new DocumentField('COPYOFEMPLOYMENT', 'domesticHelperClaim.dmhClaimTypeDetails.repatriation.subSupportingDocs.copyOfEmployment', SmartqDocuments.DMH_PROOFOFEMPLOYINGTEMP, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}
