<div class="section form-group">
  <label class="section-title">{{ 'pageSummaryClaim.claimItem.sectionStayInHospital.title' | translate }}</label>
  <div class="section-content">
      <div class="sub-section">
          <label>{{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subDetails.title' | translate }}</label>
          <div class="row" *ngIf="this.stayInHospital.getCountryOfTheLossOccurredDesc()">
            <div class="col col-xs-12 col-lg-7">
                {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subDetails.country' | translate }}
            </div>
            <div class="col col-xs-12 col-lg-5 content">
                {{this.stayInHospital.getCountryOfTheLossOccurredDesc()}}
            </div>
          </div>
          <div class="row" *ngIf="this.stayInHospital.getPlaceOfLossDesc()">
              <div class="col col-xs-12 col-lg-7">
                  {{'claimSection.stayedInHospital.detailsOfIllness.whereIllnessFirstOccurred' | translate}}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{this.stayInHospital.getPlaceOfLossDesc()}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subDetails.date' | translate }}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{getDate(stayInHospital.getDateTheIllnessOccurred())}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subDetails.natureInjury' | translate }}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{stayInHospital.getNatureOfIllnessDesc()}}
              </div>
          </div>
          <ng-container *ngIf="stayInHospital.getNatureOfIllness() === 'Sickness'">
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subDetails.diagnosis' | translate }}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{stayInHospital.getDiagnoseTypeDesc()}}
                  </div>
              </div>
              <div class="row"
                    *ngIf="stayInHospital.getDiagnoseType() === 'Other'">
                  <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subDetails.otherDiagnosis' | translate }}
                  </div>
                  <div class="col col-xs-12 col-lg-5-word-break content">
                      {{stayInHospital.getOtherDiagnose()}}
                  </div>
              </div>
          </ng-container>
          <ng-container *ngIf="stayInHospital.getNatureOfIllness() === 'Injury'">
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subDetails.mugging' | translate }}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{returnYesOrNo(stayInHospital.getWasMugging())}}
                  </div>
              </div>
          </ng-container>

      </div>
      <div class="sub-section">
          <label>{{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subHospitalStayed.title' | translate }}</label>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subHospitalStayed.stayHosp' | translate }}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{returnYesOrNo(stayInHospital.getWasStayHospitalOversea())}}
              </div>
          </div>
          <ng-container *ngIf="stayInHospital.getWasStayHospitalOversea()">
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subHospitalStayed.periodHosp' | translate }}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{stayInHospital.getPeriodTime()}}&nbsp;{{stayInHospital.getPeriodTypeDesc()}}
                  </div>
              </div>
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subHospitalStayed.intensiveOrQuar' | translate }}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{returnYesOrNo(stayInHospital.getWasStayInIntensiveCare())}}
                  </div>
              </div>
              <div class="row" *ngIf="stayInHospital.getWasStayInIntensiveCare() == false">
                  <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subHospitalStayed.wardStayed' | translate }}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{stayInHospital.getWardTypeDesc()}}
                  </div>
              </div>
          </ng-container>
      </div>
      <div class="sub-section">
          <label *ngIf="country === 'hkg' || country === 'hgi'">{{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subFFTreatment.title' | translate }}</label>
          <label *ngIf="country === 'sgp'">{{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subFFTreatmentSG.title' | translate }}</label>
          <label *ngIf="country === 'mys'">{{ 'claimSection.stayedInHospital.followUpTreatmentInMY.title' | translate }}</label>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                <ng-container *ngIf="country === 'hkg' || country === 'hgi'">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subFFTreatment.whenReturn' | translate }}
                </ng-container>
                <ng-container *ngIf="country === 'sgp'">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subFFTreatmentSG.whenReturn' | translate }}
                </ng-container>
                  <ng-container *ngIf="country === 'mys'">
                      {{ 'claimSection.stayedInHospital.followUpTreatmentInMY.whenReturnedInMY' | translate }}
                  </ng-container>
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{getDate(stayInHospital.getReturnHkDate())}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                <ng-container *ngIf="country === 'hkg' || country === 'hgi'">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subFFTreatment.followUp' | translate }}
                </ng-container>
                <ng-container *ngIf="country === 'sgp'">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subFFTreatmentSG.followUp' | translate }}
                </ng-container>
                <ng-container *ngIf="country === 'mys'">
                    {{ 'claimSection.stayedInHospital.followUpTreatmentInMY.anyFollowUpTreatmentsInMY' | translate }}
                </ng-container>
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{returnYesOrNo(stayInHospital.getHasReceiveFollowUpTreament())}}
              </div>
          </div>
          <ng-container *ngIf="stayInHospital.getHasReceiveFollowUpTreament()">
              <div class="row">
                  <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subFFTreatment.date' | translate }}
                  </div>
                  <div class="col col-xs-12 col-lg-5 content">
                      {{getDate(stayInHospital.getLastTreamentDate())}}
                  </div>
              </div>
          </ng-container>
      </div>
      <div class="sub-section">
          <label>{{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subMedBill.title' | translate }}</label>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subMedBill.noOfBills' | translate }}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{stayInHospital.getNumberOfTreatmentBill()}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7"
                    *ngIf="stayInHospital.getNumberOfTreatmentBill() == 'others'">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subMedBill.noOfBills' | translate }}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{stayInHospital.getOtherNumOfTreatmentBill()}}
              </div>
          </div>
          <div class="row">
              <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionStayInHospital.subMedBill.totalAmount' | translate }}
              </div>
              <div class="col col-xs-12 col-lg-5 content">
                  {{stayInHospital.getMedicalExpenseCurrency()}}
                  {{stayInHospital.getMedicalExpenseTypeAmount() | number : '1.2-2'}}
              </div>
          </div>
      </div>
      <div class="sub-section">
        <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
        <div class="row" *ngFor="let item of this.listOfDocuments;">
          <div class="col col-xs-12 col-lg-12">
              {{item.description | translate}}
          </div>
          <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
              <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                  {{ item.uploadedFileName }}
              </div>
          </ng-container>
      </div>
    </div>
  </div>
  <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
    <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
    <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
      <div class="col col-xs-12 col-lg-12">
          {{item.description | translate}}
      </div>
      <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
          <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
              {{ item.uploadedFileName }}
          </div>
      </ng-container>
  </div>
</div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>