<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{ 'homeProduct.common.tellUsWhatHappened' | translate }}</h5>
    <p>{{ 'homeProduct.domesticEmployer.title' | translate}}</p>
  </div>
  <br/>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group" *ngIf="!isDmhClaim">
          <qnect-ui #HOME_DOMESTIC_EMPLOYER_IS_INJURED [fieldId]="'HOME_DOMESTIC_EMPLOYER_IS_INJURED'" [formGroup]="domesticEmployerForm" name="isHelperInjured"></qnect-ui>
        </div>
        <ng-container *ngIf="domesticEmployerLiability.getWasHelperInjured()">
          <div class="form-group">
            <qnect-ui #HOME_DOMESTIC_EMPLOYER_HELPER_NAME [fieldId]="'HOME_DOMESTIC_EMPLOYER_HELPER_NAME'" [formGroup]="domesticEmployerForm" name="helperName"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #HOME_DOMESTIC_EMPLOYER_HKID_HELPER [fieldId]="'HOME_DOMESTIC_EMPLOYER_HKID_HELPER'" [formGroup]="domesticEmployerForm" name="helperHkidPassport"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #HOME_DOMESTIC_EMPLOYER_DETAILS [fieldId]="'HOME_DOMESTIC_EMPLOYER_DETAILS'" [formGroup]="domesticEmployerForm" name="dmhDetails"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn btn-primary float-right new-btn">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right"></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
