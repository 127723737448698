import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimService} from "../../../services/claim.service";
import {Claim} from "../../../model/claim.model";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import { TransactionInfo } from "src/app/model/transaction-info.model";
import { TransactionInfoService } from "src/app/services/transaction-info.service";
import {UtilitiesService} from "../../../utilities/utilities.service";
import { UiOptionsModel } from "src/app/ui/ui-options.model";

@Component({
    selector: 'app-send-cheque',
    templateUrl: './send-cheque.component.html',
    styleUrls: ['./send-cheque.component.css']
})
export class SendChequeComponent extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    sendChequeForm: FormGroup;
    insuredPerson: string;
    claim: Claim;
    isMotorLodgedBy: string = '';
    transactionInfo: TransactionInfo;
    chequePayeeList: UiOptionsModel[] = [];

    constructor(private fb: FormBuilder,
                private claimService: ClaimService,
                public sideMenuService: SideMenuService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private transactionInfoService: TransactionInfoService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();
        this.claim.setPaymentRefundToBank("N");
    }

    ngOnInit() {

        this.pushGAChequeAccountView();

        //prepopulate addressCountryText in claim model for cheque payment
        this.claim.setAddressCountryText(UtilitiesService.getCountryDesc(this.transactionInfo.getRealCountry()));
        this.claim.setAddressCountry(UtilitiesService.getCountryDesc(this.transactionInfo.getRealCountry()));

        this.populateChequePayeeList();
        this.sendChequeForm = this.fb.group(
            {
                claimantName: [this.claim.getPaymentClaimantName()],
                addressLine1: [this.claim.getAddressLine1()],
                addressLine2: [this.claim.getAddressLine2()],
                addressLine3: [this.claim.getAddressLine3()],
                addressLine4: [this.claim.getAddressLine4()],
                addressCountryText: [this.claim.getAddressCountryText()],
            }
        );

        this.sendChequeForm.get('addressCountryText').disable({onlySelf: false, emitEvent: false});
    }

    ngAfterViewInit() {

        this.sendChequeForm.valueChanges.subscribe(data => {

            this.claim.setPaymentClaimantName(super.getValueInComponent('claimantName'));
            this.claim.setAddressLine1(super.getValueInComponent('addressLine1'));
            this.claim.setAddressLine2(super.getValueInComponent('addressLine2'));
            this.claim.setAddressLine3(super.getValueInComponent('addressLine3'));
            this.claim.setAddressLine4(super.getValueInComponent('addressLine4'));
        });

    }

    showModal() {
        this.showTotalBox = true;
    }

    back() {
       // history.go(-1);
       if(this.transactionInfo.isMotorProduct() && this.transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_MVA_WINDSCREEN'){
        this.router.navigate(["/claimform/windscreen/supportDoc"], {
            relativeTo: this.activatedRoute
        });
        } else {
            this.router.navigate(["/claimform/anotherInsurance"], {
                relativeTo: this.activatedRoute
            });
    }

    }

    applyP400Format(event: any, fieldName: string) {
        let newStr = UtilitiesService.p400Format(event.target.value);
        event.target.value = UtilitiesService.p400Format(event.target.value);

        if(fieldName == 'claimantName'){
            this.claim.setPaymentClaimantName(newStr);
            this.sendChequeForm.get(fieldName).setValue(newStr);
        } else if(fieldName == 'addressLine1'){
            this.claim.setAddressLine1(newStr);
            this.sendChequeForm.get(fieldName).setValue(newStr);
        } else if(fieldName == 'addressLine2'){
            this.claim.setAddressLine2(newStr);
            this.sendChequeForm.get(fieldName).setValue(newStr);
        } else if(fieldName == 'addressLine3'){
            this.claim.setAddressLine3(newStr);
            this.sendChequeForm.get(fieldName).setValue(newStr);
        } else if(fieldName == 'addressLine4'){
            this.claim.setAddressLine4(newStr);
            this.sendChequeForm.get(fieldName).setValue(newStr);
        }    
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 0, subMenuIndex: 2});

            this.router.navigate(["/claimform/paymentAndContact/provideContactInfo"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.sendChequeForm);
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToBank() {
        this.pushGASwitchBank();
        this.router.navigate(["/claimform/paymentAndContact/bankAccount"], {
            relativeTo: this.activatedRoute
        });
    }

    //START - Google Analytics
    pushGAChequeAccountView() {
        let gaStepNum = this.getGaStep();
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Payment - Cheque',
            'vPath': '/payment-cheque',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': gaStepNum, 'option': 'Cheque'} 	//Step7-Payment
                }
            }
        });
    }

    pushGASwitchBank() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'switchtobank'});
    }

    getGaStep(): number {
        if (this.transactionInfo.isGenericClaim()) {
            return 5;
        } else if (this.transactionInfo.isHomeProduct()) {
            return 6;
        } else if (this.transactionInfo.isAccidentProduct()) {
            return 8;
        }
        return 7;
    }
    //END - Google Analytics

    populateChequePayeeList() {
        if(this.transactionInfo.isMotorProduct()) {
            if(this.claim.getClaimMotor().getIsLodgeByPolicyHolder()){
                this.claim.setPaymentClaimantName(this.claim.getPolicyHolderName());
                this.isMotorLodgedBy = "customer";
            } else {
                this.claim.setPaymentClaimantName(this.claim.getClaimMotor().getRepairerName());
                this.isMotorLodgedBy = "repairer";
            }
        } else if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelProduct()) {
            let policyholder = this.claim.getPolicyHolderName();
            const policyHolderOption = new UiOptionsModel(policyholder, policyholder);
            if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelPersonal()) {
                this.chequePayeeList.push(policyHolderOption);
            }

            if (this.transactionInfo.isTravelProduct()) {
                if (this.transactionInfo.isTravelCorporatePolicyHolder()) {
                    this.chequePayeeList.push(policyHolderOption);
                } else {
                    let storedInsuredPersonLists = this.transactionInfo.getInsuredPersonsList();
                    if (!UtilitiesService.isNullOrUndefined(storedInsuredPersonLists)  && storedInsuredPersonLists.length > 0) {
                        for (var i = 0; i < storedInsuredPersonLists.length; i++) {
                            let insuredPerson = storedInsuredPersonLists[i].getInsuredName();
                            if (storedInsuredPersonLists.find(a => a.valueOf() === insuredPerson) == undefined) {
                                const insuredPersonOption = new UiOptionsModel(insuredPerson, insuredPerson);
                                this.chequePayeeList.push(insuredPersonOption);
                            }
                        }
                    }
                }
            }  
        }        
    }
}
