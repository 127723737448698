export class BaggageDelayItemDetail {
    private emergencyPurchaseType: string;
    private emergencyPurchaseTypeDesc: string;
    private emergencyPurchaseTypeDetail: string;
    private purchaseDate: Date;
    private purchasePriceType: string;
    private purchasePrice: number;

  constructor() {
      this.emergencyPurchaseType = 'default';
      this.emergencyPurchaseTypeDesc = '';
      this.emergencyPurchaseTypeDetail = '';
      this.purchaseDate = null;
      this.purchasePriceType = '';
      this.purchasePrice = null;
  }


  getEmergencyPurchaseType(): string {
    return this.emergencyPurchaseType;
  } 

  setEmergencyPurchaseType(value: string){
    this.emergencyPurchaseType = value;
  }

  getEmergencyPurchaseTypeDesc(): string {
    return this.emergencyPurchaseTypeDesc;
  } 

  setEmergencyPurchaseTypeDesc(value: string){
    this.emergencyPurchaseTypeDesc = value;
  }

  getEmergencyPurchaseTypeDetail(): string {
    return this.emergencyPurchaseTypeDetail;
  } 

  setEmergencyPurchaseTypeDetail(value: string){
    this.emergencyPurchaseTypeDetail = value;
  }

  getPurchaseDate(): Date {
    return this.purchaseDate;
  } 

  setPurchaseDate(value: Date){
    this.purchaseDate = value;
  }

  getPurchasePriceType(): string {
    return this.purchasePriceType;
  } 

  setPurchasePriceType(value: string){
    this.purchasePriceType = value;
  }

  getPurchasePrice(): number {
    return this.purchasePrice;
  } 

  setPurchasePrice(value: number){
    this.purchasePrice = value;
  }

  static jsonConvert(baggageDelayItemDetail: BaggageDelayItemDetail): BaggageDelayItemDetail {

    baggageDelayItemDetail = Object.assign(new BaggageDelayItemDetail(), baggageDelayItemDetail);

    if (baggageDelayItemDetail.getPurchaseDate() != null) {
        baggageDelayItemDetail.setPurchaseDate(new Date(baggageDelayItemDetail.getPurchaseDate()));
      }

      
    return baggageDelayItemDetail;
  }

}
