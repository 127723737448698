import {Component, Injector, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { SideMenuService } from 'src/app/services/side-menu.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { DynamicRoutingService } from 'src/app/services/dynamic-routing.service';
import { Claim } from 'src/app/model/claim.model';
import { ClaimService } from 'src/app/services/claim.service';
import { Others } from 'src/app/model/common/others.model';

@Component({
    selector: 'app-home-other-details',
    templateUrl: './other-details.component.html',
    styleUrls: ['./other-details.component.css']
})
export class OtherDetailsComponent extends ClaimsBaseComponent implements OnInit {

    othersForm: FormGroup;
    currency: string;
    country: string;
    isHomePlaceOfIncident: boolean = false;
    isOutsideHomeHSR: boolean = false;
    othersDetails: Others;
    claim: Claim;
    showTotalBox = false;

    constructor(private fb: FormBuilder,
                private claimService: ClaimService,
                private transactionInfoService: TransactionInfoService,
                private sideMenuService: SideMenuService,
                private activatedRoute: ActivatedRoute,
                private dynamicRoutingService: DynamicRoutingService,
                private router: Router,
                private injector : Injector) {
        super(injector);
        this.isHomePlaceOfIncident = this.claim.getClaimHome().getPlaceOfIncident() === 'home';
        this.isOutsideHomeHSR = this.claim.getClaimHome().getHasHSRRisk() 
            && this.claim.getClaimHome().getPlaceOfIncident() !== 'home';
        this.othersDetails = this.claim.getClaimHome().getHomeDetails().getOthers();
        this.claim = this.claimService.getClaim();
    }

    ngOnInit() {
        this.pushGAOtherDetailsView();

        if (this.claim.getCountry().toLowerCase() === 'sgp') {
            this.currency = 'SGD';
            this.country =  'SGP';
        } else {
            this.currency = 'HKD';
            this.country =  'HKG';
        }

        if (!this.isNotNullOrUndefinedStr(this.othersDetails.getEstAmountLossCurrency())){
            this.othersDetails.setEstAmountLossCurrency(this.currency);
        }

        this.othersForm = this.fb.group({
            descIncident: [this.othersDetails.getDescIncident()],
            estAmountLoss: [this.othersDetails.getEstAmountLoss()],
            estAmountLossCurrency: [this.othersDetails.getEstAmountLossCurrency()],
        });
    }

    ngAfterViewInit() {
         this.othersForm.valueChanges.subscribe(data => {
            this.othersDetails.setDescIncident(super.getValueInComponent('descIncident'));
            this.othersDetails.setEstAmountLoss(super.getValueInComponent('estAmountLoss'));
            this.othersDetails.setEstAmountLossCurrency(this.othersForm.get('estAmountLossCurrency').value);             
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitComplete('finishDetail');
            this.sideMenuService.emitProcess(0, 4);
            if (this.claim.getClaimHome().getPlaceOfIncident() !== 'home'
                && this.claim.getClaimHome().getHasHSRRisk()) {
                  //  this.buildClaimTypeItemList();
                    this.transactionInfoService.getTransactionInfo().setIsClaimDetailsLabel(false);
                    let url = "/claimform/claimDetail";
                    this.router.navigate([url], {
                        relativeTo: this.activatedRoute
                    });
            } else {
                this.transactionInfoService.getTransactionInfo().setDynamicRoutes(this.dynamicRoutingService.getDynamicHomeRoutes(ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OTHERS]));
                this.sideMenuService.emitClaimTypeSubMenuHideShow({claimTypeId: 6, subMenuIndex: 1, value: ClaimTypes[ClaimTypes.CLAIM_TYPE_HOME_OTHERS]});
                this.transactionInfoService.getDynamicRoutingService().goToNextRoute(this.transactionInfoService.getTransactionInfo().getDynamicRoutes(), this.router, this.activatedRoute);        
            }
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.othersForm);
    }

    back() {
        if (this.claim.getClaimHome().getPlaceOfIncident() === 'home' && 
            this.claim.getClaimHome().getIsHomeWhereIncidentOccurred()) {
                this.router.navigate(["/claimform/claimDetail/selectClaim"], {
                    relativeTo: this.activatedRoute
                });
        } else {
            this.router.navigate(['/claimform/confirmIncidentDetails'], {
                relativeTo: this.activatedRoute
            });
        }
    }

    buildClaimTypeItemList() {
        let claimTypesList = this.transactionInfoService.getTransactionInfo().getClaimTypeItemList();
        claimTypesList.forEach((claimType, index)=>{
            if(index === 7 || index === 8){
                claimType['show'] = true;
            } else {
                claimType['show'] = false;
            }
        });
        this.transactionInfoService.getTransactionInfo().setClaimTypeItemList(claimTypesList);
    }

    pushGAOtherDetailsView() {
        let pageStep = this.transactionInfoService.getTransactionInfo().getGaPageStep() + ' - Details of the Incident';
        let vPath = '/claim/' + this.transactionInfoService.getTransactionInfo().getGaVPath() + '/details';
    
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
          'pageStep': pageStep,
          'vPath': vPath,
          'event': 'vpageview',
          'ecommerce': {
            'checkout': {
                'actionField': {'step': 4}	 //Step4-Claim Details
              }
          }
        });
      }
    
    showModal() {
        console.log('showModel');
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }
}
