export class InsuranceDetails {

    private companyName: string;
    private companyAddress: string;
    private policyNumber: string;

    public getCompanyName(): string {
        return this.companyName;
    }

    public setCompanyName(companyName: string): void {
        this.companyName = companyName;
    }

    public getCompanyAddress(): string {
        return this.companyAddress;
    }

    public setCompanyAddress(companyAddress: string): void {
        this.companyAddress = companyAddress;
    }

    public getPolicyNumber(): string {
        return this.policyNumber;
    }

    public setPolicyNumber(policyNumber: string): void {
        this.policyNumber = policyNumber;
    }

    static jsonConvert(insuranceDetails: InsuranceDetails): InsuranceDetails {
        insuranceDetails = Object.assign(new InsuranceDetails(), insuranceDetails);
        return insuranceDetails;
    }

}