<!-- person accident -->
<div class="form-group section">
    <label class="section-title">{{ 'pageSummaryClaim.claimItem.sectionDeath.title' | translate }}</label>
    <div class="section-content">
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.claimItem.sectionDeath.subDetail.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionDeath.subDetail.date' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(this.deathAndSeriousBodyInjuryLoss.getDateTheAccidentOccurred())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionDeath.subDetail.country' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="this.deathAndSeriousBodyInjuryLoss.getCountryOfLossDesc()">
                    {{this.deathAndSeriousBodyInjuryLoss.getCountryOfLossDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5 content" *ngIf="this.deathAndSeriousBodyInjuryLoss.getPlaceOfLossDesc()">
                    {{this.deathAndSeriousBodyInjuryLoss.getPlaceOfLossDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionDeath.subDetail.natureInjury' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content"
                     *ngIf="this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness()?.toLowerCase() !== 'others'">
                    {{this.deathAndSeriousBodyInjuryLoss.getNatureOfIllnessDesc()}}
                </div>
                <div class="col col-xs-12 col-lg-5 content"
                     *ngIf="this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness()?.toLowerCase() === 'others'">
                    {{this.deathAndSeriousBodyInjuryLoss.getOtherTypeDesc()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionDeath.subDetail.mugging' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.deathAndSeriousBodyInjuryLoss.getWasMugging())}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.claimItem.sectionDeath.subOverStay.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionDeath.subOverStay.overStayed' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.deathAndSeriousBodyInjuryLoss.getWasStayHospitalOversea())}}
                </div>
            </div>
            <ng-container *ngIf="this.deathAndSeriousBodyInjuryLoss.getWasStayHospitalOversea()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subOverStay.periodStay' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.deathAndSeriousBodyInjuryLoss.getPeriodTime()}} {{this.deathAndSeriousBodyInjuryLoss.getPeriodTypeDesc()}}
                    </div>
                </div>
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subOverStay.intensiveOrQuar' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{returnYesOrNo(this.deathAndSeriousBodyInjuryLoss.getWasStayInIntensiveCare())}}
                    </div>
                </div>
                <div class="row" *ngIf="this.deathAndSeriousBodyInjuryLoss.getWasStayInIntensiveCare() == false">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subOverStay.typeStayed' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{this.deathAndSeriousBodyInjuryLoss.getWardTypeDesc()}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="sub-section" *ngIf="this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness()?.toLowerCase()  != 'death by accident' && this.deathAndSeriousBodyInjuryLoss.getNatureOfIllness()?.toLowerCase() != 'death due to sickness'">
            <label *ngIf="this.claim.getCountry()?.toLowerCase() === 'sgp'">{{ 'pageSummaryClaim.claimItem.sectionDeath.subFollowTreatmentSG.title' | translate }}</label>
            <label *ngIf="this.claim.getCountry()?.toLowerCase() === 'hkg' || this.claim.getCountry()?.toLowerCase() === 'hgi'">{{ 'pageSummaryClaim.claimItem.sectionDeath.subFollowTreatment.title' | translate }}</label>
            <label *ngIf="this.claim.getCountry()?.toLowerCase() === 'mys'">{{ 'claimSection.deathAndSeriousBodyInjury.followUpTreatment.titleMY' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    <ng-container *ngIf="this.claim.getCountry()?.toLowerCase() === 'hkg' || this.claim.getCountry()?.toLowerCase() === 'hgi'">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subFollowTreatment.returnHkDate' | translate }}
                    </ng-container>
                    <ng-container *ngIf="this.claim.getCountry()?.toLowerCase() === 'sgp'">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subFollowTreatmentSG.returnHkDate' | translate }}
                    </ng-container>
                    <ng-container *ngIf="this.claim.getCountry()?.toLowerCase() === 'mys'">
                        {{ 'claimSection.deathAndSeriousBodyInjury.followUpTreatment.returnedMY' | translate }}
                    </ng-container>
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{getDate(this.deathAndSeriousBodyInjuryLoss.getReturnHkDate())}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    <ng-container *ngIf="this.claim.getCountry()?.toLowerCase() === 'hkg' || this.claim.getCountry()?.toLowerCase() === 'hgi'">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subFollowTreatment.followTreatment' | translate }}
                    </ng-container>
                    <ng-container *ngIf="this.claim.getCountry()?.toLowerCase() === 'sgp'">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subFollowTreatmentSG.followTreatment' | translate }}
                    </ng-container>
                    <ng-container *ngIf="this.claim.getCountry()?.toLowerCase() === 'mys'">
                        {{ 'claimSection.deathAndSeriousBodyInjury.followUpTreatment.anyFollowUpTreatmentMY' | translate }}
                    </ng-container>
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{returnYesOrNo(this.deathAndSeriousBodyInjuryLoss.getHasReceiveFollowUpTreament())}}
                </div>
            </div>
            <ng-container *ngIf="this.deathAndSeriousBodyInjuryLoss.getHasReceiveFollowUpTreament()">
                <div class="row">
                    <div class="col col-xs-12 col-lg-7">
                        {{ 'pageSummaryClaim.claimItem.sectionDeath.subFollowTreatment.dateTreatment' | translate }}
                    </div>
                    <div class="col col-xs-12 col-lg-5 content">
                        {{getDate(this.deathAndSeriousBodyInjuryLoss.getLastTreamentDate())}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.claimItem.sectionDeath.subMedBills.title' | translate }}</label>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionDeath.subMedBills.noOfBills' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.deathAndSeriousBodyInjuryLoss.getNumberOfTreatmentBill()}}
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 col-lg-7">
                    {{ 'pageSummaryClaim.claimItem.sectionDeath.subMedBills.totalAmount' | translate }}
                </div>
                <div class="col col-xs-12 col-lg-5 content">
                    {{this.deathAndSeriousBodyInjuryLoss.getMedicalExpenseType()}}
                    {{this.deathAndSeriousBodyInjuryLoss.getMedicalExpenseTypeAmount() | number : '1.2-2'}}
                </div>
            </div>
        </div>
        <div class="sub-section">
            <label>{{ 'pageSummaryClaim.supportDocuments.title' | translate }}</label>
                <div class="row" *ngFor="let item of this.listOfDocuments;">
                    <div class="col col-xs-12 col-lg-12">
                        {{item.description | translate}}
                    </div>
                    <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
                        <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                            {{ item.uploadedFileName }}
                        </div>
                    </ng-container>
                </div>
        </div>
    </div>
    <div class="sub-section" *ngIf="this.listOfOtherDocuments.length > 0">
        <label>{{ 'pageSummaryClaim.claimItem.sectionOther.subSectionSuppDocs.others' | translate }}</label>
        <div class="row" *ngFor="let item of this.listOfOtherDocuments;">
          <div class="col col-xs-12 col-lg-12">
              {{item.description | translate}}
          </div>
          <ng-container *ngIf="item.uploadedFileName!=undefined && item.uploadedFileName !=''; else documentNotUploaded">
              <div class="col col-xs-12 col-lg-12 file-uploaded-summary">
                  {{ item.uploadedFileName }}
              </div>
          </ng-container>
      </div>
    </div>
</div>

<ng-template #documentNotUploaded>
    <div class="col col-xs-12 col-lg-12 file-not-uploaded-summary">
        {{ 'pageSummaryClaim.supportDocuments.notUpload' | translate }}
    </div>
</ng-template>