import { InjuredItemDetail } from "../motor/injured-item-detail.model";
import { PropertyItemDetail } from "../motor/property-item-detail.model";

export class ThirdPartyLossDetails {

    private injuredItemList: InjuredItemDetail[] = [];
    private propertyDamageItemList: PropertyItemDetail[] = [];
    private detailsOfDamagedType: string[] = [];
    private detailsOfDamagedTypeDesc: string[] = [];
  
    private isIncidentInvolve: string;
    private isIncidentInvolveDesc: string;
    private incidentDescription: string;
    private hasSelectedDamageType: boolean;

    getIsIncidentInvolve(): string {
        return this.isIncidentInvolve;
    }

    setIsIncidentInvolve(isIncidentInvolve: string): void {
        this.isIncidentInvolve = isIncidentInvolve;
    }

    getIsIncidentInvolveDesc(): string {
        return this.isIncidentInvolveDesc;
    }

    setIsIncidentInvolveDesc(value: string): void {
        this.isIncidentInvolveDesc = value;
    }

    getIncidentDescription(): string {
        return this.incidentDescription;
    }

    setIncidentDescription(value: string): void {
        this.incidentDescription = value;
    }

    getInjuredItemList(): InjuredItemDetail[] {
        return this.injuredItemList;
    }

    setInjuredItemList(value: InjuredItemDetail[]) {
        this.injuredItemList = value;
    }

    getPropertyDamageItemList(): PropertyItemDetail[] {
        return this.propertyDamageItemList;
    }

    setPropertyDamageItemList(value: PropertyItemDetail[]) {
        this.propertyDamageItemList = value;
    }

    getDetailsOfDamagedType(): string[] {
        return this.detailsOfDamagedType;
    }

    setDetailsOfDamagedType(value: string[]) {
        this.detailsOfDamagedType = value;
    }

    getDetailsOfDamagedTypeDesc(): string[] {
        return this.detailsOfDamagedTypeDesc;
    }

    setDetailsOfDamagedTypeDesc(value: string[]) {
        this.detailsOfDamagedTypeDesc = value;
    }

    getHasSelectedDamageType(): boolean {
        return this.hasSelectedDamageType;
    }
    
    setHasSelectedDamageType(value: boolean) {
        this.hasSelectedDamageType = value;
    } 
 
    static jsonConvert(thirdPartyLossDetails: ThirdPartyLossDetails): ThirdPartyLossDetails {

        if (thirdPartyLossDetails.getPropertyDamageItemList() != null) {
            let propertyItemDetail: PropertyItemDetail[] = [];

            for (const propertyItems of thirdPartyLossDetails.getPropertyDamageItemList()) {
                    let item = Object.assign(new PropertyItemDetail(),propertyItems);
                    propertyItemDetail.push(item);
            }
            thirdPartyLossDetails.setPropertyDamageItemList(propertyItemDetail);
        }

        if (thirdPartyLossDetails.getInjuredItemList() != null) {
            let injuredItemDetail: InjuredItemDetail[] = [];

            for (const injuredItems of thirdPartyLossDetails.getInjuredItemList()) {
                    let item = Object.assign(new InjuredItemDetail(),injuredItems);
                    injuredItemDetail.push(item);
            }
            thirdPartyLossDetails.setInjuredItemList(injuredItemDetail);
        }
       
        return thirdPartyLossDetails;
    }

}
