export class FatalCase {

    private wasPoliceNotified: boolean;
    private policeStation: string;
    private kin: string;
    private relationship: string;
    private telephoneNumber: string;
    private telephoneNumberCode: string;
    private address: string;

    public getWasPoliceNotified(): boolean{
        return this.wasPoliceNotified;
    }

    public setWasPoliceNotified(wasPoliceNotified: boolean): void {
        this.wasPoliceNotified = wasPoliceNotified;
    }

    public getPoliceStation(): string{
        return this.policeStation;
    }

    public setPoliceStation(policeStation: string): void {
        this.policeStation = policeStation;
    }

    public getKin(): string{
        return this.kin;
    }

    public setKin(kin: string): void {
        this.kin = kin;
    }

    public getRelationship(): string{
        return this.relationship;
    }

    public setRelationship(relationship: string): void {
        this.relationship = relationship;
    }

    public getTelephoneNumber(): string{
        return this.telephoneNumber;
    }

    public setTelephoneNumber(telephoneNumber: string): void {
        this.telephoneNumber = telephoneNumber;
    }

    public getTelephoneNumberCode(): string {
        return this.telephoneNumberCode;
    }

    public setTelephoneNumberCode(telephoneNumberCode: string): void {
        this.telephoneNumberCode = telephoneNumberCode;
    }

    public getAddress(): string{
        return this.address;
    }

    public setAddress(address: string): void {
        this.address = address;
    }

    static jsonConvert(fatalCase: FatalCase): FatalCase {
        fatalCase = Object.assign(new FatalCase(), fatalCase);
        return fatalCase;
    }
}