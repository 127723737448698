export class ECAccidentPlaceDetails {

    private accidentPlace: string;
    private accidentPlaceDesc: string;
    private placeType: string;
    private placeTypeDesc: string;
    private placeTypeOther: string;
    private activity: string;
    private workType: string;
    private workTypeDesc: string;
    private workTypeOther: string
    private whereabouts: string;
    private machineryInvolved: any[];
    private machineryInvolvedOther: string;
    private transportingConstructionMachineryInvolved: string;
    private transportingConstructionMachineryInvolvedDesc: string;
    private transportingConstructionMachineryInvolvedOther: string;

    //Supplementary Information on Accidents on Construction Sites
    //Particulars of Worksite
    private commencementDate: string;
    private completionDate: string;
    private contractorName: string;
    private siteAddress: string;
    private contractNo: string;
    private accidentDate: Date;
    private contactNo: string;
    private contactNoCode: string;

    //Particulars of Project
    private projectNature: string;
    private projectNatureDesc: string;
    private isPrivateProject : boolean;
    private authorizedPersonName: string;
    private authorizedPersonPosition: string;
    private authorizedPersonTelNo: string;
    private authorizedPersonTelNoCode: string
    private publicWorks: string;
    private publicWorksDesc: string;
    private publicWorksOther: string;

    //Particulars of Place of Fall (If Injured by Fall from Height)
    private placeOfFall: string;
    private placeOfFallDesc: string;
    private placeOfFallOther: string;

    //Ethnicity
    private ethnicity: string;
    private ethnicityDesc: string;
    private ethnicityOther: string;

    //Language Ability
    private spokenCantonese: string;
    private spokenPutonghua: string;
    private spokenEnglish: string;
    private spokenOtherText: string;
    private spokenOtherOpt: string;

    private readingChinese: string;
    private readingEnglish: string;
    private readingOtherText: string;
    private readingOtherOpt: string;

    private writtenChinese: string;
    private writtenEnglish: string;
    private writtenOtherText: string;
    private writtenOtherOpt: string;

    public getAccidentPlace(): string { return this.accidentPlace; }
    public setAccidentPlace(accidentPlace: string): void { this.accidentPlace = accidentPlace; }
    public getAccidentPlaceDesc(): string { return this.accidentPlaceDesc; }
    public setAccidentPlaceDesc(accidentPlaceDesc: string): void { this.accidentPlaceDesc = accidentPlaceDesc; }
    public getPlaceType(): string { return this.placeType; }
    public setPlaceType(placeType: string): void { this.placeType = placeType; }
    public getPlaceTypeDesc(): string { return this.placeTypeDesc; }
    public setPlaceTypeDesc(placeTypeDesc: string): void { this.placeTypeDesc = placeTypeDesc; }
    public getPlaceTypeOther(): string { return this.placeTypeOther; }
    public setPlaceTypeOther(placeTypeOther: string): void { this.placeTypeOther = placeTypeOther; }
    public getActivity(): string { return this.activity; }
    public setActivity(activity: string): void { this.activity = activity; }
    public getWorkType(): string { return this.workType; }
    public setWorkType(workType: string): void { this.workType = workType; }
    public getWorkTypeDesc(): string { return this.workTypeDesc; }
    public setWorkTypeDesc(workTypeDesc: string): void { this.workTypeDesc = workTypeDesc; }
    public getWorkTypeOther(): string { return this.workTypeOther; }
    public setWorkTypeOther(workTypeOther: string): void { this.workTypeOther = workTypeOther; }
    public getWhereabouts(): string { return this.whereabouts; }
    public setWhereabouts(whereabouts: string): void { this.whereabouts = whereabouts; }
    public getMachineryInvolved(): any[] { return this.machineryInvolved; }
    public setMachineryInvolved(machineryInvolved: any[]) { this.machineryInvolved = machineryInvolved; }
    public getMachineryInvolvedOther(): string { return this.machineryInvolvedOther; }
    public setMachineryInvolvedOther(machineryInvolvedOther: string): void { this.machineryInvolvedOther = machineryInvolvedOther; }
    public getTransportingConstructionMachineryInvolved(): string { return this.transportingConstructionMachineryInvolved; }
    public setTransportingConstructionMachineryInvolved(transportingConstructionMachineryInvolved: string): void { this.transportingConstructionMachineryInvolved = transportingConstructionMachineryInvolved; }
    public getTransportingConstructionMachineryInvolvedDesc(): string { return this.transportingConstructionMachineryInvolvedDesc; }
    public setTransportingConstructionMachineryInvolvedDesc(transportingConstructionMachineryInvolvedDesc: string): void { this.transportingConstructionMachineryInvolvedDesc = transportingConstructionMachineryInvolvedDesc; }
    public getTransportingConstructionMachineryInvolvedOther(): string { return this.transportingConstructionMachineryInvolvedOther; }
    public setTransportingConstructionMachineryInvolvedOther(transportingConstructionMachineryInvolvedOther: string): void { this.transportingConstructionMachineryInvolvedOther = transportingConstructionMachineryInvolvedOther; }

    //Supplementary Information on Accidents on Construction Sites
    //Particulars of Worksite
    public getCommencementDate(): string { return this.commencementDate; }
    public setCommencementDate(commencementDate: string): void { this.commencementDate = commencementDate; }
    public getCompletionDate(): string { return this.completionDate; }
    public setCompletionDate(completionDate: string): void { this.completionDate = completionDate; }
    public getContractorName(): string { return this.contractorName; }
    public setContractorName(contractorName: string): void { this.contractorName = contractorName; }
    public getSiteAddress(): string { return this.siteAddress; }
    public setSiteAddress(siteAddress: string): void { this.siteAddress = siteAddress; }
    public getContractNo(): string { return this.contractNo; }
    public setContractNo(contractNo: string): void { this.contractNo = contractNo; }
    public getAccidentDate(): Date { return this.accidentDate; }
    public setAccidentDate(accidentDate: Date): void { this.accidentDate = accidentDate; }
    public getContactNo(): string { return this.contactNo; }
    public setContactNo(contactNo: string): void { this.contactNo = contactNo; }
    public getContactNoCode(): string { return this.contactNoCode; }
    public setContactNoCode(contactNoCode: string): void { this.contactNoCode = contactNoCode; }
    
    //Particulars of Project
    public getProjectNature(): string { return this.projectNature; }
    public setProjectNature(projectNature: string): void { this.projectNature = projectNature; }
    public getProjectNatureDesc(): string { return this.projectNatureDesc; }
    public setProjectNatureDesc(projectNatureDesc: string): void { this.projectNatureDesc = projectNatureDesc; }
    public getIsPrivateProject(): boolean { return this.isPrivateProject; }
    public setIsPrivateProject(isPrivateProject: boolean): void { this.isPrivateProject = isPrivateProject; }
    public getAuthorizedPersonName(): string { return this.authorizedPersonName; }
    public setAuthorizedPersonName(authorizedPersonName: string): void { this.authorizedPersonName = authorizedPersonName; }
    public getAuthorizedPersonPosition(): string { return this.authorizedPersonPosition; }
    public setAuthorizedPersonPosition(authorizedPersonPosition: string): void { this.authorizedPersonPosition = authorizedPersonPosition; }
    public getAuthorizedPersonTelNo(): string { return this.authorizedPersonTelNo; }
    public setAuthorizedPersonTelNo(authorizedPersonTelNo: string): void { this.authorizedPersonTelNo = authorizedPersonTelNo; }
    public getAuthorizedPersonTelNoCode(): string { return this.authorizedPersonTelNoCode; }
    public setAuthorizedPersonTelNoCode(authorizedPersonTelNoCode: string): void { this.authorizedPersonTelNoCode = authorizedPersonTelNoCode; }
    public getPublicWorks(): string { return this.publicWorks; }
    public setPublicWorks(publicWorks: string): void { this.publicWorks = publicWorks; }
    public getPublicWorksDesc(): string { return this.publicWorksDesc; }
    public setPublicWorksDesc(publicWorksDesc: string): void { this.publicWorksDesc = publicWorksDesc; }
    public getPublicWorksOther(): string { return this.publicWorksOther; }
    public setPublicWorksOther(publicWorksOther: string): void { this.publicWorksOther = publicWorksOther; }

    //Particulars of Place of Fall (If Injured by Fall from Height)
    public getPlaceOfFall(): string { return this.placeOfFall; }
    public setPlaceOfFall(placeOfFall: string): void { this.placeOfFall = placeOfFall; }
    public getPlaceOfFallDesc(): string { return this.placeOfFallDesc; }
    public setPlaceOfFallDesc(placeOfFallDesc: string): void { this.placeOfFallDesc = placeOfFallDesc; }
    public getPlaceOfFallOther(): string { return this.placeOfFallOther; }
    public setPlaceOfFallOther(placeOfFallOther: string): void { this.placeOfFallOther = placeOfFallOther; }

    //Ethnicity
    public getEthnicity(): string { return this.ethnicity; }
    public setEthnicity(ethnicity: string): void { this.ethnicity = ethnicity; }
    public getEthnicityDesc(): string { return this.ethnicityDesc; }
    public setEthnicityDesc(ethnicityDesc: string): void { this.ethnicityDesc = ethnicityDesc; }
    public getEthnicityOther(): string { return this.ethnicityOther; }
    public setEthnicityOther(ethnicityOther: string): void { this.ethnicityOther = ethnicityOther; }

    //Language Ability
    public getSpokenCantonese(): string { return this.spokenCantonese; }
    public setSpokenCantonese(spokenCantonese: string): void { this.spokenCantonese = spokenCantonese; }
    public getSpokenPutonghua(): string { return this.spokenPutonghua; }
    public setSpokenPutonghua(spokenPutonghua: string): void { this.spokenPutonghua = spokenPutonghua; }
    public getSpokenEnglish(): string { return this.spokenEnglish; }
    public setSpokenEnglish(spokenEnglish: string): void { this.spokenEnglish = spokenEnglish; }
    public getSpokenOtherText(): string { return this.spokenOtherText; }
    public setSpokenOtherText(spokenOtherText: string): void { this.spokenOtherText = spokenOtherText; }
    public getSpokenOtherOpt(): string { return this.spokenOtherOpt; }
    public setSpokenOtherOpt(spokenOtherOpt: string): void { this.spokenOtherOpt = spokenOtherOpt; }

    public getReadingChinese(): string { return this.readingChinese; }
    public setReadingChinese(readingChinese: string): void { this.readingChinese = readingChinese; }
    public getReadingEnglish(): string { return this.readingEnglish; }
    public setReadingEnglish(readingEnglish: string): void { this.readingEnglish = readingEnglish; }
    public getReadingOtherText(): string { return this.readingOtherText; }
    public setReadingOtherText(readingOtherText: string): void { this.readingOtherText = readingOtherText; }
    public getReadingOtherOpt(): string { return this.readingOtherOpt; }
    public setReadingOtherOpt(readingOtherOpt: string): void { this.readingOtherOpt = readingOtherOpt; }

    public getWrittenChinese(): string { return this.writtenChinese; }
    public setWrittenChinese(writtenChinese: string): void { this.writtenChinese = writtenChinese; }
    public getWrittenEnglish(): string { return this.writtenEnglish; }
    public setWrittenEnglish(writtenEnglish: string): void { this.writtenEnglish = writtenEnglish; }
    public getWrittenOtherText(): string { return this.writtenOtherText; }
    public setWrittenOtherText(writtenOtherText: string): void { this.writtenOtherText = writtenOtherText; }
    public getWrittenOtherOpt(): string { return this.writtenOtherOpt; }
    public setWrittenOtherOpt(writtenOtherOpt: string): void { this.writtenOtherOpt = writtenOtherOpt; }

    static jsonConvert(placeDetails: ECAccidentPlaceDetails): ECAccidentPlaceDetails {
        if (placeDetails !== null) {
            if (placeDetails.getAccidentDate() !== null) {
                placeDetails.setAccidentDate(new Date(placeDetails.getAccidentDate()));
            }
        }        
        return placeDetails;
    }
}