<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p *ngIf="this.claim.getCountry()?.toLowerCase() === 'hkg' || this.claim.getCountry()?.toLowerCase() === 'hgi'">{{ 'claimSection.deathAndSeriousBodyInjury.followUpTreatment.title' | translate}}</p>
    <p *ngIf="this.claim.getCountry()?.toLowerCase() === 'sgp'">{{ 'claimSection.deathAndSeriousBodyInjury.followUpTreatment.titleSG' | translate}}</p>
    <p *ngIf="this.claim.getCountry()?.toLowerCase() === 'mys'">{{ 'claimSection.deathAndSeriousBodyInjury.followUpTreatment.titleMY' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-xl-9 col-lg-12">
      <form>
        <div class="form-group">
          <qnect-ui #PAT10_RETURN_HK_DATE [fieldId]="'PAT10_RETURN_HK_DATE'" [formGroup]="detailOfFollowUpTreamentForm" name="returnHkDate"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PAT12_HAS_RECEIVE_FOLLOW_UP_TREATMENT [fieldId]="'PAT12_HAS_RECEIVE_FOLLOW_UP_TREATMENT'" [formGroup]="detailOfFollowUpTreamentForm" name="hasReceiveFollowUpTreament"></qnect-ui>
        </div>
        <div >
          <div>
            <div class="form-group click-more-content" *ngIf="this.deathAndSeriousBodyInjuryLoss.getHasReceiveFollowUpTreament()" id="q1">
              <div class="form-group">
                <qnect-ui #PAT11_LAST_TREATMENT_DATE [fieldId]="'PAT11_LAST_TREATMENT_DATE'" [formGroup]="detailOfFollowUpTreamentForm" [claimsDateValidator]="this.claim.getClaimTravel().getDeathAndSeriousBodyInjuryLoss().getReturnHkDate()" name="lastTreamentDate"></qnect-ui>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-xl-3 col-lg-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()"    class="btn btn-primary new-btn float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i
            class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>