import {Component, OnInit, Input, Output, EventEmitter, Injector, SimpleChanges, OnChanges} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AutoSaveService} from '../services/autosave.service';
import {TransactionInfoService} from '../services/transaction-info.service';
import {ClaimService} from "../services/claim.service";
import { ClaimsBaseComponent } from '../claim-pages/claim-base.component';
import { PageService } from '../services/page.service';
import {TransactionInfo} from '../model/transaction-info.model';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-save-box',
    templateUrl: './save-box.component.html',
    styleUrls: ['./save-box.component.css']
})
export class SaveBoxComponent extends ClaimsBaseComponent implements OnInit, OnChanges {
    public email: string;
    public showSave: boolean;
    public showSend: boolean;
    public isFormValid: boolean;
    saveForm: FormGroup;
    transactionInfo: TransactionInfo;
    @Input() showTotalBox;
    @Output() childEvent = new EventEmitter<boolean>();
    errorMessage: string;

    constructor( private transactionInfoService: TransactionInfoService,
                 private autoSaveService: AutoSaveService,
                 private fb: FormBuilder,
                 private injector : Injector,
                 private claimService: ClaimService,
                 private pageService: PageService) {
        super(injector);
        this.transactionInfo = this.transactionInfoService.getTransactionInfo();
    }

    ngOnInit() {
        this.showSave = false;
        this.showSend = true;
        this.isFormValid = false;
        // this.showTotalBox = false;
        this.saveForm = this.fb.group(
            {
                saveEmail: [""],
            }
        );
    }

    ngAfterViewInit() {
        this.saveForm.valueChanges.subscribe(data => {
            this.transactionInfo.setSaveEmail(this.saveForm.get('saveEmail').value);
            this.isFormValid = this.validateForm();
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        // google analytics for save for later
        if (changes['showTotalBox'].currentValue == true)  {
            this.pushGASaveForLater();
        }
    }

    validateForm(): boolean {
        return super.validateFormOnChange(this.saveForm);
    }

    sendAction() {
        this.errorMessage = '';
        let isFormValidOnChange: boolean = this.validateForm();
        if(isFormValidOnChange){
            let currentLanguage = this.translate.currentLang;
            if (currentLanguage == 'zh') {
                currentLanguage = 'cn';
            }
            this.claimService.getClaim().setLanguage(currentLanguage);
            this.transactionInfoService.getTransactionInfo().setEntryUrl(this.pageService.getEntryUrlDirect());
            this.autoSaveService.setupAutoSaveForm(this.claim, this.transactionInfoService.getTransactionInfo());

            this.autoSaveService.claimSaveAndSendMail(this.claimService.getClaim())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    status => {
                        if (status) {
                            this.showSave = true;
                            this.showSend = false;
                        } else {
                            this.showSave = false;
                            this.showSend = true;
                            this.errorMessage = "saveBox.errorMessage";
                        }
                    }
                );
        }

    }

    closeAction() {
        this.showTotalBox = false;
        this.showSave = false;
        this.showSend = true;
        this.childEvent.emit(false);
    }
    closeBox() {
        this.showTotalBox = false;
        this.showSave = false;
        this.showSend = true;
        this.childEvent.emit(false);
        this.errorMessage = '';
    }

    pushGASaveForLater() {
        (<any>window).dataLayer.push({'event': 'saveforlater'});
    }

}
