import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pa-others-support-doc',
  templateUrl: './pa-others-support-doc.component.html'
})
export class PaOthersSupportDocComponent implements OnInit {
  public showAddDoc = false;
  constructor() { }

  ngOnInit() {
  }

}
