import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";

import {UiBaseComponent} from "../ui-base.component";
import {AbstractControl, Validators} from "@angular/forms";
import {UiValidators} from "../validators/ui-validator";
import { UtilitiesService } from "src/app/utilities/utilities.service";

@Component({
  selector: 'qnect-ui-moneyfield',
  templateUrl: './ui-moneyfield.component.html',
  styleUrls: ['../ui-base.component.scss']
})
export class UiMoneyfieldComponent extends UiBaseComponent implements OnInit {

  @ViewChild('inputField', {static: true}) inputField: ElementRef;

  currencyFormControl: AbstractControl;
  currencySelectedText: string = '';
  amountFieldSize: string = '7';

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.uiModel.currencyFieldName) {
      this.currencyFormControl = this.formGroup.get(this.uiModel.currencyFieldName);

      let validatorList = [];
      if (this.uiModel.currencyFieldId) {
          validatorList.push(UiValidators.moneyCurrencyValidation(this.formControl, this.currencyFormControl, this.uiModel.required, this.uiModel.moneyFieldAmountRequired, this.uiModel.moneyFieldCurrencyRequired));
          this.currencyFormControl.setValidators(validatorList);
      }
      else {
          if (this.uiModel.required) validatorList.push(Validators.required);
      }

      if (this.uiModel.maxlength) validatorList.push(Validators.maxLength(this.uiModel.maxlength));
      if (this.uiModel.validationRegex) validatorList.push(Validators.pattern(this.uiModel.validationRegex));
      this.formControl.setValidators(validatorList);

      this.setCurrencySelectedText();
      this.currencyFormControl.valueChanges.subscribe(data => {
        this.setCurrencySelectedText();
      });
    } else {
      this.amountFieldSize = '12';
    }

  }

  getDivRemainder() : number {
      return 12-parseInt (this.amountFieldSize)-4;
  }

  setValue(value: string) {
    this.formControl.setValue(value);
    if (this.inputField) {
      // blur the field to trigger formatting
      this.inputField.nativeElement.dispatchEvent(new Event('blur'));
    }
  }

  onKeyPress(event: any) {
    const pattern = /^[0-9\.]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  //Only triggers if the user somehow was able to input invalid characters, removes all non-numeric characters
  formatValue(event: any){
    if(!UtilitiesService.isNullOrUndefined(event)){
      let val = "";
      val = event.target.value;

        if(!UtilitiesService.isNullOrUndefined(val) && val.match(/\.(?![^.]+$)|[^0-9.]/g)){
          let value = (event.target.value || '').toString().replace(/\.(?![^.]+$)|[^0-9.]/g, '');
        this.formControl.setValue(value);
        console.log("TRIGGERED");
      }
    }
  }

  moneyFieldBlur() {
    // Cross validation between the amount and currency field
      this.currencyFormControl.updateValueAndValidity();    
  }

  currencyFieldBlur(){
    // Cross validation between the amount and currency field
    this.formControl.updateValueAndValidity();
  }

  // Called when the currency box changes.
  currencyFieldChange() {
    this.setCurrencySelectedText();
  }

  disabledPasteEvent(event: ClipboardEvent) {
    event.preventDefault();
  }
  
  setCurrencySelectedText() {
    this.uiModel.currencyOptions.forEach(each => {
      if (this.currencyFormControl.value === each.value) {
        this.currencySelectedText = each.label;
        // break loop
        return false;
      }
    });
  }

}
