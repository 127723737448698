import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {OtherClaim} from "../../../../model/travel/other-claim.model";

@Component({
    selector: 'app-details-of-incident',
    templateUrl: './details-of-incident.component.html',
    styleUrls: ['./details-of-incident.component.css']
})
export class DetailsOfIncidentComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    otherForm: FormGroup;
    otherClaim: OtherClaim;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private injector : Injector) {

        super(injector);
        this.otherClaim = this.claim.getClaimTravel().getOtherClaim();
        if(this.otherClaim.getOtherDateOfIncident() == null){
            this.otherClaim.setOtherDateOfIncident(this.claim.getDateOfLoss());
        }
    }

    ngOnInit() {

        this.pushGAViewOtherDetails();

        this.otherForm = this.fb.group({
            otherDateOfIncident: [this.otherClaim.getOtherDateOfIncident()],
            otherCountryDamageOccurred: [this.otherClaim.getOtherCountryIncidentOccurred()],
            placeOfLoss: [this.otherClaim.getPlaceOfLoss()]
        });
    }

    ngAfterViewInit() {
        this.otherForm.valueChanges.subscribe(data => {
            this.otherClaim.setOtherDateOfIncident(super.getValueInComponent('otherDateOfIncident'));
            this.otherClaim.setOtherCountryIncidentOccurred(super.getValueInComponent('otherCountryDamageOccurred'));
            this.otherClaim.setOtherCountryIncidentOccurredDescription(this.getTextInComponent('otherCountryDamageOccurred'));
            this.otherClaim.setPlaceOfLoss(super.getValueInComponent('placeOfLoss'));
            this.otherClaim.setPlaceOfLossDesc(super.getTextInComponent('placeOfLoss'));

            this.validateClaimDetailsComplete();
        });
    }

    back() {
        this.router.navigate(["/claimform/claimDetail/selectClaim"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 15, subMenuIndex: 0});
            this.router.navigate(["/claimform/otherClaim/descOfIncident"], {
                relativeTo: this.activatedRoute
            });
        }
    }


    validateForm(): boolean {
        return super.validateForm(this.otherForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.otherForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 15, subMenuIndex: 0});
        } else {
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 15, subMenuIndex: 0});
        }
    }

    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewOtherDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Other Claims – Incident Details',
            'vPath': '/claim/other-claims/incident-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': 5},	//Step5-ClaimDetailsStart
                }
            }
        });
    }

}
