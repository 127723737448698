<div>
    <div class="prev-bar">
        <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i> {{'claimSection.generalLabels.generalButtonLabels.back' | translate}} </a>
    </div>
    <div class="col-12">
        <h5>{{'claimSection.generalLabels.whatHappened' | translate}}</h5>
        <p>{{'claimSection.visitedADoctor.medicalBills.title' | translate}}</p>
    </div>
    <BR>
    <div class="row col-12">
        <div class="col-xl-9 col-lg-12">
            <form>
                <div>
                    <div>
                        <div class="form-group click-more-content" id="q1">
                            <qnect-ui #VDR12_NUMBER_OF_TREATMENT_BILL [fieldId]="'VDR12_NUMBER_OF_TREATMENT_BILL'" [formGroup]="medicalBillForm"
                                      name="numberOfTreatmentBill"></qnect-ui>

                            <qnect-ui #VDR13_OTHER_NUM_OF_TREATMENT_BILL [fieldId]="'VDR13_OTHER_NUM_OF_TREATMENT_BILL'" [formGroup]="medicalBillForm"
                                      name="otherNumOfTreatmentBill" [hidden]="visitedDoctor.getDiagnoseType() !== 'Other'"></qnect-ui>
                        </div>
                        <div class="form-group click-more-content" id="q2">
                            <qnect-ui #VDR14_MEDICAL_EXPENSE_TYPE [fieldId]="'VDR14_MEDICAL_EXPENSE_TYPE'" [formGroup]="medicalBillForm"
                                      name="medicalExpenseTypeAmount"></qnect-ui>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-xl-3 col-lg-12">
            &nbsp;
        </div>
    </div>
    <div class="submit-bar">
        <abandon-button></abandon-button>
        <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
        <button (click)="goToNext()" class="btn btn-primary new-btn float-right"> {{'claimSection.generalLabels.generalButtonLabels.next' | translate}} <i class="fas fa-arrow-right"></i></button>
    </div>
    <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>