import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancel-support-doc',
  templateUrl: './cancel-support-doc.component.html',
  styleUrls: ['./cancel-support-doc.component.css']
})
export class CancelSupportDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
