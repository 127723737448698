import {Component, OnInit, Input} from '@angular/core';

import {NotificationMessage} from './notification-message.model';

@Component({
  selector: 'qnect-decline-notification',
  templateUrl: './decline-notification.component.html',
})
export class DeclineNotificationComponent implements OnInit {

  @Input()
  messages: NotificationMessage[] = [];
  @Input() showPreamble: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

}
