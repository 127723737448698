<div>
    <h4 class="ff-stag policy-info">{{ 'sideMenu.heading.title' | translate }} {{selectInsuredName}}<BR>
        <span>{{ this.productDescription | translate}} {{claim.getPolicyNumber()}}</span>
    </h4>
    <ul class="processing-bar ff-stag">
        <li [ngClass]="{'current': transactionInfo.getNavigationMenu().getGetStartedState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getGetStartedState() == 'not started', 
                'active': transactionInfo.getNavigationMenu().getGetStartedState() == 'finish'}">
                <span>{{ 'pageGetStarted.heading.title' | translate }}</span>
        </li>
        <li *ngIf="transactionInfo.isTravelProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/confirmTravelPeriod':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
            {{ 'sideMenu.confirmTravelPeriod' | translate }}
        </li> 
         <li *ngIf="transactionInfo.isHomeProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/confirmIncidentDetails':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
                {{ 'homeProduct.confirmIncidentDetails.title' | translate }}
        </li>  
        <li *ngIf="transactionInfo.isMotorProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/confirmVehicleDetails':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
            {{ 'motorClaim.confirmVehicle.heading' | translate }}
        </li>
        <li *ngIf="transactionInfo.isEmployeeCompensationProduct()" [routerLink]="transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish'?'/claimform/employerDetails':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current',
                'active': transactionInfo.getNavigationMenu().getConfirmTravelPeriodState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
            (click)="closeMenu();">
            {{ 'ecClaim.employerDetails.title' | translate }}
        </li>
        <li *ngIf="(!transactionInfo.isMotorProduct() && !transactionInfo.isGenericClaim() && !transactionInfo.isHomeProduct() && !transactionInfo.isEmployeeCompensationProduct())" [routerLink]="transactionInfo.getNavigationMenu().getSelectInsuredState() == 'finish'?'/claimform/selectInsured':null"
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getSelectInsuredState() == 'current',
                'notFinish': transactionInfo.getNavigationMenu().getSelectInsuredState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getSelectInsuredState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
            <span *ngIf="transactionInfo.isTravelProduct() || transactionInfo.isAccidentProduct()">&nbsp;{{ 'sideMenu.selectInsured' | translate}}</span>
            <span *ngIf="transactionInfo.isDomesticHelperProduct()">&nbsp;{{ 'sideMenu.selectHelper' | translate}}</span>
        </li>

        <ng-container *ngIf="transactionInfo.isAccidentProduct()">
            <li [routerLink]="transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'finish'?'/claimform/paAccidentDetails':null"
                [ngClass]="{'current':  transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'current',
                    'notFinish': transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                    'active': transactionInfo.getNavigationMenu().getAccidentDetailsState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                    (click)="closeMenu();">
                {{ 'sideMenu.accidentDetails' | translate }}
            </li>
            <li [routerLink]="transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'finish'?'/claimform/paInjuryDetails':null" 
                [ngClass]="{'current':  transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'current',
                    'notFinish': transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                    'active': transactionInfo.getNavigationMenu().getInjuryDetailsState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                    (click)="closeMenu();">
                {{ 'sideMenu.injuryDetails' | translate }}
            </li>
        </ng-container>
        
        <ng-container *ngIf="transactionInfo.isGenericClaim()">
            <li [routerLink]="transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'finish'?'/claimform/genericClaimDetail':null"
                [ngClass]="{'current':  transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'current',
                    'notFinish': transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                    'active': transactionInfo.getNavigationMenu().getGenericClaimDetailState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                    (click)="closeMenu();">
                {{ 'sideMenu.claimDetails' | translate }}
            </li>
            <li [routerLink]="transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'finish'?'/claimform/genericUploadDocument':null" 
            [ngClass]="{'current':  transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'current',
                    'notFinish': transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                    'active': transactionInfo.getNavigationMenu().getGenericUploadDocumentState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                    (click)="closeMenu();">
                {{ 'sideMenu.supportingDocuments' | translate }}
            </li>
        </ng-container>

        <ng-container *ngIf="!transactionInfo.isGenericClaim()">
            <li [routerLink]="transactionInfo.getNavigationMenu().getSelectClaimState() == 'finish' ? '/claimform/claimDetail' : null" 
                [hidden]="transactionInfo.getNavigationMenu().getClaimDetailsState() == 'false'"
                [ngClass]="{'current': transactionInfo.getNavigationMenu().getSelectClaimState() == 'current' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish',
                    'notFinish': transactionInfo.getNavigationMenu().getSelectClaimState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current',  
                    'active':transactionInfo.getNavigationMenu().getSelectClaimState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}">
                {{ 'sideMenu.claimDetails' | translate }}
            </li>
            <li *ngFor="let subMenu of subMenuList; let i = index" [hidden]="subMenu.hidden"
                [ngClass]="{'active': subMenu.complete && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish', 
                    'notFinish':!subMenu.complete || transactionInfo.getNavigationMenu().getGetStartedState() === 'current'}">
                <span *ngIf="this.transactionInfo.getIsClaimDetailsLabel()">{{'sideMenu.claimDetails' | translate}}&nbsp;</span>
                <span *ngIf="!this.transactionInfo.getIsClaimDetailsLabel()">{{subMenu.claimTypeIdName | translate}}&nbsp;</span>
                <button *ngIf="transactionInfo.getNavigationMenu().getGetStartedState() === 'finish' && !this.transactionInfo.getIsClaimDetailsLabel()"
                            class="removeBtn" (click)="open(confirm_delete_claim_type, subMenu.claimTypeId)">{{ 'claimSection.generalLabels.generalButtonLabels.remove' | translate}}</button>
                <ul>
                    <li [hidden]="subTitle.hidden" [ngClass]="{'active':subTitle.complete && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish', 'current':this.router.url === subTitle.url}"
                        [routerLink]="subTitle.complete && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish' ? subTitle.url : null" *ngFor="let subTitle of subMenuList[i].subMenus"
                        (click)="closeMenu();">
                        <span>{{subTitle.name | translate}} </span>
                    </li>
                </ul>
            </li>
        </ng-container>

        <li [routerLink]="transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'finish'?'/claimform/paymentAndContact':null"
            [ngClass]="{'current': transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'current' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish',
                'notFinish': transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getPaymentAndContactState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
            {{claim.getPaymentRefundToBank() != '' ? ('sideMenu.paymentContact' | translate ): ('sideMenu.contact' | translate)}}
        </li>
        <li [routerLink]="transactionInfo.getNavigationMenu().getReviewSummaryState() == 'finish'?'/claimform/summary':null"
            [ngClass]="{'current': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'current' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish',
                'notFinish': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'not started' || transactionInfo.getNavigationMenu().getGetStartedState() === 'current', 
                'active': transactionInfo.getNavigationMenu().getReviewSummaryState() == 'finish' && transactionInfo.getNavigationMenu().getGetStartedState() === 'finish'}"
                (click)="closeMenu();">
            {{'sideMenu.reviewSubmit' | translate }}
        </li>
    </ul>
</div>

<ng-template #confirm_delete_claim_type let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h5 class="modal-title">{{'sideMenu.deleteClaim.header' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{'sideMenu.deleteClaim.question1' | translate }}</p>
        <span *ngIf="claim.getSelectedClaimTypesList().length == 1"><p>{{'sideMenu.deleteClaim.question2' | translate }}</p></span>

    </div>
    <div class="modal-footer">
        <div class="col-md-6 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="deleteClaimType(selectedClaimTypeNumber)">{{'sideMenu.deleteClaim.yes' | translate }}</button>
        </div>
        <div class="col-md-6 text-left">
            <button type="button" class="btn btn-default btn-sm" (click)="c('Close click')">{{'sideMenu.deleteClaim.no' | translate }}</button>
        </div>
    </div>
</ng-template>
