import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {DeathAndSeriousBodyInjuryLoss} from "../../../../model/travel/death-and-serious-body-injury-loss";
import { DocumentField } from '../../../../model/document-field';
import { ClaimTypes } from '../../../../model/claim-type.model';
import { DocumentHelperService } from "../../../../services/document-helper.service";
@Component({
  selector: 'app-summary-personal-accident',
  templateUrl: './summary-personal-accident.component.html',
  styleUrls: ['./summary-personal-accident.component.css']
})
export class SummaryPersonalAccidentComponent extends ClaimsBaseComponent implements OnInit {

  claim: Claim;
  deathAndSeriousBodyInjuryLoss: DeathAndSeriousBodyInjuryLoss;
  listOfDocuments: DocumentField[] = [];
  listOfOtherDocuments: DocumentField[] = [];

  constructor(private claimService: ClaimService,
              private documentHelperService: DocumentHelperService,
              private injector : Injector) {

      super(injector);
      this.claim = this.claimService.getClaim();
      this.deathAndSeriousBodyInjuryLoss = this.claim.getClaimTravel().getDeathAndSeriousBodyInjuryLoss();
      this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]);
      this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_DTH]);
  }

  ngOnInit() {

  }

  returnYesOrNo(value: boolean) {
    if (true === value) {
        return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend");
    } else {
        if (false === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.noSend");
        }
    }

    return '';
}

  getDate(dt: Date) {
      if (dt && dt != null) {
          return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
      }
      else {
          return "";
      }
  }
  
}
