import {Component, Injector, Input, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimTravel} from "../../../../model/claim-travel.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import { DocumentTypes } from '../../../../utilities/enums/document-types.enum';
import {MissConnection} from "../../../../model/travel/miss-connection.model";
import { ClaimTypes } from '../../../../model/claim-type.model';
import { DocumentField } from '../../../../model/document-field';
import { DocumentHelperService } from "../../../../services/document-helper.service";
import {UtilitiesService} from '../../../../utilities/utilities.service';

@Component({
    selector: 'app-summary-miss-connection',
    templateUrl: './summary-miss-connection.component.html',
    styleUrls: ['./summary-miss-connection.component.css']
})
export class SummaryMissConnectionComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimTravel: ClaimTravel;
    documentTypes = new DocumentTypes();
    missConnection: MissConnection;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];
    @Input() claimItem: Claim;

    constructor(private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private injector : Injector) {

        super(injector);
    }

    ngOnInit() {

        if (!UtilitiesService.isNullOrUndefined(this.claimItem)) {
            this.claim = this.claimItem;
        } else {
            this.claim = this.claimService.getClaim();
        }

        this.missConnection = this.claim.getClaimTravel().getMissConnection();
        let isClaimantMinor = UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth());
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocumentsClaimant(this.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC], isClaimantMinor);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_TDEL_MC]);

    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        } else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }

}
