import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class DentalExpenseFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_DENTAL];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('DENTALBILL', 'domesticHelperClaim.dmhClaimTypeDetails.dentalExpenses.subSupportingDocs.dentalBill', SmartqDocuments.DMH_DENTALTREATMENTBILL, true));
        this.requiredDocuments = requiredDocuments;
    }
}
