import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {Claim} from "../../../../model/claim.model";
import {ActivatedRoute, Router} from "@angular/router";
import {HelpersLiability} from "../../../../model/domestic-helper/helpers-liability.model";

@Component({
  selector: 'app-helpers-liability-incident',
  templateUrl: './helpers-liability-incident.component.html',
  styleUrls: ['./helpers-liability-incident.component.css']
})

export class HelpersLiabilityIncidentComponent  extends ClaimsBaseComponent implements OnInit {

    showTotalBox = false;
    helpersLiabilityForm: FormGroup;
    claim: Claim;
    helpersLiability: HelpersLiability;

    constructor(private fb: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.helpersLiability = this.claim.getClaimDomesticHelper().getHelpersLiability();
    }

    ngOnInit() {

        this.pushGAViewHelpersLiabilityIncident();

        if(this.helpersLiability.getAmountClaimCurrency() == null){
            this.helpersLiability.setAmountClaimCurrency('HKD')
        }

        this.helpersLiabilityForm = this.fb.group({
            descOfIncident: [this.helpersLiability.getDescOfIncident()],
            amountClaimCurrency: [this.helpersLiability.getAmountClaimCurrency()],
            amountClaim: [this.helpersLiability.getAmountClaim()],

        });
    }

    ngAfterViewInit() {
        this.helpersLiabilityForm.valueChanges.subscribe(data => {
            this.helpersLiability.setDescOfIncident(super.getValueInComponent('descOfIncident'));
            this.helpersLiability.setAmountClaimCurrency(this.helpersLiabilityForm.get('amountClaimCurrency').value);
            this.helpersLiability.setAmountClaim(super.getValueInComponent('amountClaim'));
        });

    }


    back() {
        this.router.navigate(["/claimform/helpersLiability/detailOfIncident"], {
            relativeTo: this.activatedRoute
        });
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 1});
            this.router.navigate(["/claimform/helpersLiability/reportingOfPolicy"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.helpersLiabilityForm);
    }

    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    //Google Analytics
    pushGAViewHelpersLiabilityIncident() {
        (<any>window).dataLayer.push({
            'pageStep': 'Helpers Liability – Incident Description',
            'vPath': '/claim/helpers-liability/incident-description',
            'event': 'vpageview',                                                                                        
        });
    }


}
