import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {DocumentField} from "../../../model/document-field";
import {SmartqDocuments} from "../../../model/smartq-documents.model";
import {ClaimTypes} from "../../../model/claim-type.model";

export class EmployeeCompensationFormBuilder extends DocumentFormBuilderComponent implements OnInit {

    constructor(claim: Claim){
        super(claim);
       this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_DMH_EMP_LIABILITY];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('ECMFORMA', 'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.form2', SmartqDocuments.DMH_EC_ECFORMA, true));
        requiredDocuments.push(new DocumentField('MEDICALEXPENSE', 'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.medicalExpense', SmartqDocuments.DMH_HOSPITALBILLS, false));
        requiredDocuments.push(new DocumentField('SICKLEAVECERT', 'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.sickLeaveCert', SmartqDocuments.DMH_SICKLEAVE, false));
        requiredDocuments.push(new DocumentField('COPYOFEMPCONTRACT', 'domesticHelperClaim.dmhClaimTypeDetails.employeeCompensation.subSupportingDocs.copyOfEmpContract', SmartqDocuments.DMH_EMPLOYEE, true));
        this.requiredDocuments = requiredDocuments;
    }
}
