<div>
  <div class="prev-bar">
    <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><i class="fas fa-arrow-left"></i>&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.back' | translate}}&nbsp;</a>
  </div>
  <div class="col-12">
    <h5>{{ 'claimSection.generalLabels.whatHappened' | translate}}</h5>
    <p>{{ 'claimSection.tripCancellation.lossCausedByCancellation.title' | translate}}</p>
  </div>
  <BR>
  <div class="row col-12">
    <div class="col-lg-9 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #TRAVEL_CANCEL13 [fieldId]="'TRAVEL_CANCEL13'" [formGroup]="lossCausedForm" name="didUseMiles"></qnect-ui>
        </div>
        <div >
          <div class="form-group">
            <qnect-ui #TRAVEL_CANCEL17 [fieldId]="'TRAVEL_CANCEL17'" [formGroup]="lossCausedForm" name="totalAmountPaidCost"
              [dynamicLabel] ="claim.isHKGorHGI() ? 'claimSection.tripCancellation.lossCausedByCancellation.amountPaidPerPerson' : ''"></qnect-ui>
          </div>
        </div>
        <div >
          <div class="form-group">
            <qnect-ui #TRAVEL_CANCEL14 [fieldId]="'TRAVEL_CANCEL14'" [formGroup]="lossCausedForm" name="hasRefund"></qnect-ui>
          </div>
          <div class="form-group click-more-content" *ngIf="this.tripCancellation.getHasRefund()=='true'">
            <qnect-ui #TRAVEL_CANCEL18 [fieldId]="'TRAVEL_CANCEL18'" [formGroup]="lossCausedForm"
                      [dynamicLabel] ="claim.isHKGorHGI() ? 'claimSection.tripCancellation.lossCausedByCancellation.refundPerPerson' : ''" name="totalAmountRefundedCost"></qnect-ui>
          </div>
        </div>
        <ng-container *ngIf="claim.isHKGorHGI()">
          <div class="form-group">
            <qnect-ui #TRAVEL_CANCEL_20_EXT_TRANS_RADIO [fieldId]="'TRAVEL_CANCEL_20_EXT_TRANS_RADIO'" [formGroup]="lossCausedForm" name="hasExtTransCost"></qnect-ui>
          </div>
          <div class="form-group" *ngIf="claim.getClaimTravel().getTripCancellation().getHasExtTransCost()">
            <qnect-ui #TRAVEL_CANCEL_21_EXT_TRANS_COST [fieldId]="'TRAVEL_CANCEL_21_EXT_TRANS_COST'" [formGroup]="lossCausedForm" name="extraTransportationCost"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #TRAVEL_CANCEL_22_EXT_ACCOM_RADIO [fieldId]="'TRAVEL_CANCEL_22_EXT_ACCOM_RADIO'" [formGroup]="lossCausedForm" name="hasExtAccomCost"></qnect-ui>
          </div>
          <div class="form-group" *ngIf="claim.getClaimTravel().getTripCancellation().getHasExtAccomCost()">
            <qnect-ui #TRAVEL_CANCEL_23_EXT_ACCOM_COST [fieldId]="'TRAVEL_CANCEL_23_EXT_ACCOM_COST'" [formGroup]="lossCausedForm" name="extraAccommodationCost"></qnect-ui>
          </div>
        </ng-container>
      </form>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{ 'claimSection.generalLabels.generalButtonLabels.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right">&nbsp;{{ 'claimSection.generalLabels.generalButtonLabels.next' | translate}}&nbsp;<i class="fas fa-arrow-right" ></i></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
