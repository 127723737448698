import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import { DatePipe } from '@angular/common';
import { ClaimPersonalAccident } from 'src/app/model/claim-personal-accident.model';
import { HospitalCash } from 'src/app/model/personal-accident/hospital-cash.model';
import { DocumentHelperService } from 'src/app/services/document-helper.service';
import { ClaimTypes } from 'src/app/model/claim-type.model';
import { DocumentField } from 'src/app/model/document-field';

@Component({
    selector: 'app-summary-hospital-cash-details',
    templateUrl: './summary-hospital-cash-details.component.html',
    styleUrls: ['./summary-hospital-cash-details.component.css']
})
export class SummaryHospitalCashDetailsComponent extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    claimPA: ClaimPersonalAccident;
    hospitalCash: HospitalCash;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private injector : Injector,
                private documentHelperService: DocumentHelperService) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.claimPA = this.claim.getClaimPersonalAccident();
        this.hospitalCash = this.claimPA.getHospitalCash();
        this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
        this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]).getRequiredDocuments(),
        ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
          this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]).getRequiredDocuments(),
          ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH]); 
    }
    
    ngOnInit() {

    }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yesSend");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.noSend");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

    getDateTime(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy HH:mm");
        } else {
            return "";
        }
    }


}
