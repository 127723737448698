import {Directive, ElementRef, HostListener, HostBinding, OnInit, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[claimsViewportOffset]'
})
export class ViewportOffsetDirective implements OnChanges {
  private el: HTMLDivElement;

  @Input()
  isOpen = false;

  constructor(private ref: ElementRef) {
    this.el = this.ref.nativeElement;
  }

  @HostListener('window:scroll', ['$event.target'])
  onWindowScroll() {
    this.adjustDivView();
  }

  ngOnChanges(changes) {
    if (changes.isOpen) {
      this.adjustDivView();
    }
  }

  private adjustDivView() {
    const divAdjustmentPercent = 0.25;
    const divElement = this.el.getElementsByClassName('target-viewport')[0];
    if (divElement) {
      const div = divElement.getBoundingClientRect();
      const divAdjustment = (div.height * divAdjustmentPercent);
      if (div.bottom > (window.innerHeight - (div.height - divAdjustment)) && div.top > div.height + divAdjustment) {
        this.el.style.pointerEvents = 'none';
        this.el.style.top = ((div.height + divAdjustment) * -1) + 'px';
      } else {
        this.el.style.pointerEvents = 'auto';
        this.el.style.top = 'auto';
      }
    }
  }
}
