import {Component, Injector, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SideMenuService} from "../../../../services/side-menu.service";
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {StayInHospital} from "../../../../model/travel/stay-in-hospital.model";
import {takeUntil} from "rxjs";

@Component({
    selector: 'app-hospital-follow-up-treatment',
    templateUrl: './hospital-follow-up-treatment.component.html',
    styleUrls: ['./hospital-follow-up-treatment.component.css']
})
export class HospitalFollowUpTreatmentComponent extends ClaimsBaseComponent implements OnInit {

    detailOfFollowUpTreamentForm: FormGroup;
    showTotalBox = false;
    claim: Claim;
    stayInHospital: StayInHospital;
    country: string;

    constructor(private fb: FormBuilder,
                public sideMenuService: SideMenuService,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.stayInHospital = this.claim.getClaimTravel().getStayInHospital();
        this.country = this.claim.getCountry().toLowerCase();
    }

    ngOnInit() {

        this.pushGAViewFollowUpDetails();

        this.detailOfFollowUpTreamentForm = this.fb.group(
            {
                hasReceiveFollowUpTreament: [super.getBooleanString(this.stayInHospital.getHasReceiveFollowUpTreament())],
                returnHkDate: [this.stayInHospital.getReturnHkDate()],
                lastTreamentDate: [this.stayInHospital.getLastTreamentDate()]
            }
        );
    }

    ngAfterViewInit() {

        this.detailOfFollowUpTreamentForm.valueChanges.subscribe(data => {

            if (this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').value != null) {
                this.stayInHospital.setHasReceiveFollowUpTreament(this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').value == 'true');
            }
            this.stayInHospital.setReturnHkDate(super.getValueInComponent('returnHkDate'));
            this.stayInHospital.setLastTreamentDate(super.getValueInComponent('lastTreamentDate'));

            this.validateClaimDetailsComplete();

        });

    }

    uiComponentCreated(fieldId: string) {

        if (fieldId === 'HOS11') {
            this.detailOfFollowUpTreamentForm.get('hasReceiveFollowUpTreament').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
                if (data === 'true') {
                    this.detailOfFollowUpTreamentForm.get('lastTreamentDate').enable({onlySelf: false, emitEvent: false});
                } else {
                    this.detailOfFollowUpTreamentForm.get('lastTreamentDate').reset();
                    this.detailOfFollowUpTreamentForm.get('lastTreamentDate').disable({onlySelf: false, emitEvent: false});

                }
            });
        }
    }


    showModal() {
        this.showTotalBox = true;
    }

    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.validateForm()) {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 2});

            this.router.navigate(["/claimform/stayInHospital/medicalBills"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        this.router.navigate(["/claimform/stayInHospital/detailsOfOverseaDoctorVisit"], {
            relativeTo: this.activatedRoute
        });
    }

    validateForm(): boolean {
        return super.validateForm(this.detailOfFollowUpTreamentForm);
    }

    validateClaimDetailsComplete() {
        let isFormValidOnChange: boolean = super.validateFormOnChange(this.detailOfFollowUpTreamentForm);

        if(isFormValidOnChange){
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimComplete({claimTypeId: 5, subMenuIndex: 2});
        } else {
            this.sideMenuService.emitProcess(1, 0);
            this.sideMenuService.emitClaimIncomplete({claimTypeId: 5, subMenuIndex: 2});
        }
    }

    //Google Analytics
    pushGAViewFollowUpDetails() {
        (<any>window).dataLayer.push({
            'pageStep': 'Stay Hospital – Follow-up Treatment',
            'vPath': '/claim/stay-hospital/followup',
            'event': 'vpageview',
        });
    }

}
