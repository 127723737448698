<div>
  <div class="prev-bar">
      <a (click)="back()" style="color: #0064c1" class="btn new-btn btn-secondary"><em class="fas fa-arrow-left"></em> {{'pagePaymentAndContact.payOverseasBank.back' | translate}} </a>
  </div>
  <div class="col-12">
    <h5>{{'pagePaymentAndContact.payOverseasBank.title' | translate}}</h5>
    <p>{{'pagePaymentAndContact.payOverseasBank.desc1' | translate}}</p>
  </div>
  <div class="row col-12">
    <div class="col-lg-9 col-xl-8 col-md-12">
      <form>
        <div class="form-group">
          <qnect-ui #PYM23 [fieldId]="'PYM23'" [formGroup]="payOverseasBankForm" name="beneficiaryName" (focusout)="applyP400Format($event, 'beneficiaryName')" [disabled]="isMotorLodgedBy != ''" [dynamicOptions]="accountNameDirectList"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PYM24 [fieldId]="'PYM24'" [formGroup]="payOverseasBankForm" name="beneficiaryBankName"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PYM25 [fieldId]="'PYM25'" [formGroup]="payOverseasBankForm" name="beneficiaryBankAddress"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PYM21 [fieldId]="'PYM21'" [formGroup]="payOverseasBankForm" name="swiftCode"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PYM26 [fieldId]="'PYM26'" [formGroup]="payOverseasBankForm" name="beneficiaryAccountNumber"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PYM22 [fieldId]="'PYM22'" [formGroup]="payOverseasBankForm" name="beneficiaryBankCode"></qnect-ui>
        </div>
        <div class="form-group">
          <qnect-ui #PYM27 [fieldId]="'PYM27'" [formGroup]="payOverseasBankForm" name="beneficiaryBranchCode"></qnect-ui>
        </div>
        <div class="form-group">
          <span>{{ 'pagePaymentAndContact.payOverseasBank.enterIntermediaryDetails.desc2' | translate}}
            <a href="javascript:;" (click)="showIntermediary(true)" class="click-more">{{ 'pagePaymentAndContact.payOverseasBank.enterIntermediaryDetails.desc1' | translate}}</a></span>
        </div>
        <ng-container *ngIf="this.transactionInfo.getHasOverseasBankIntermediary()">
          <div class="form-group">
            <label>{{'pagePaymentAndContact.payOverseasBank.intermediaryBankDetails' | translate}}</label>
          </div>
          <div class="form-group">
            <qnect-ui #PYM28 [fieldId]="'PYM28'" [formGroup]="payOverseasBankForm" name="intermediaryAccountholderName" (focusout)="applyP400Format($event, 'intermediaryAccountholderName')" [disabled]="isMotorLodgedBy != ''" [dynamicOptions]="accountNameDirectList"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #PYM29 [fieldId]="'PYM29'" [formGroup]="payOverseasBankForm" name="intermediaryBankName"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #PYM30 [fieldId]="'PYM30'" [formGroup]="payOverseasBankForm" name="intermediaryBankAddress"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #PYM31 [fieldId]="'PYM31'" [formGroup]="payOverseasBankForm" name="intermediarySwiftCode"></qnect-ui>
          </div>
          <div class="form-group">
            <qnect-ui #PYM32 [fieldId]="'PYM32'" [formGroup]="payOverseasBankForm" name="intermediaryBankAccountNumber"></qnect-ui>
          </div>
        </ng-container>
      </form>
      <div>
        <div>
          <span>{{ 'pagePaymentAndContact.payOverseasBank.directCredit.desc2' | translate}}
            <a href="javascript:;" (click)="goToBank()" class="click-more">{{ 'pagePaymentAndContact.payOverseasBank.directCredit.desc1' | translate}}</a></span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      &nbsp;
    </div>
  </div>
  <div class="submit-bar">
    <abandon-button></abandon-button>
    <a href="javascript:;" (click)="showModal()" class="Tertiary"> {{'pagePaymentAndContact.payOverseasBank.save' | translate}}</a>
    <button (click)="goToNext()" class="btn new-btn btn-primary float-right"> {{'pagePaymentAndContact.payOverseasBank.next' | translate}} <em class="fas fa-arrow-right" ></em></button>
  </div>
  <app-save-box [showTotalBox]="showTotalBox" (childEvent)="getData($event)"></app-save-box>
</div>
