
import {Subscription} from "rxjs";
export class SubscriptionComponent {
  private subscription: Subscription = new Subscription();

  addSubscription(subscription: Subscription) {
    this.subscription.add(subscription);

  }
  unsubscribe() {
    this.subscription.unsubscribe();
  }
}
