import {Injectable, Injector} from "@angular/core";
import {Observable, throwError, catchError, map} from "rxjs";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {SpinnerService} from "../core/spinner/spinner.service";
import {finalize} from 'rxjs/operators';
import { Logger } from "../utilities/service/logger/logger";

@Injectable()
export class UserService {

    constructor(private logger: Logger,
                private http: HttpClient,
                private spinnerService: SpinnerService,
                private injector: Injector) {
    }

    public verifyEmail(token: string, iv: string) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer CP'
            })
        };

        let url = '/ms/claims-service/rest/supplierPortal/verifyEmail';
        url += "?token=" + token;
        url += "&iv=" + iv;

        return this.http.get<any>(url, httpOptions)
            .pipe(map((res: any) => {
                return res;
            }))
            .pipe(catchError<any,Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    public resetPassword(email: string, password: string, token: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };

        let requestBody = {
            email: email,
            password: password
        }

        const url: string = '/ms/claims-service/rest/supplierPortal/resetPassword';

        return this.http.post<any>(url, requestBody, httpOptions)
            .pipe(map((res: any) => {
                return res;
            }))
            .pipe(catchError<any,Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    public sendResetPasswordLink(email: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer CP'
        });

        let url: string = '/ms/claims-service/rest/supplierPortal/sendForgetPasswordLink';
        url += "?email=" + email;
        return this.http.get(url, {headers: headers, responseType: 'text'})
            .pipe(map((res: any) => {
                return res;
            }))
            .pipe(catchError<any,Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    public decryptResetPasswordToken(token: string, iv: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer CP'
            }),
        };

        let url: string = '/ms/claims-service/rest/supplierPortal/decryptResetPasswordToken';
        url += "?token=" + token;
        url += "&iv=" + iv;
        
        return this.http.get(url, httpOptions)
            .pipe(map((res: any) => {
                return res;
            }))
            .pipe(catchError<any,Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    // OTP
    public checkEmailIfRegistered(token: string, email: string, country: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };

        let url: string = '/ms/claims-service/rest/supplierPortal/checkEmailIfRegistered';
        url += "?email=" + email;
        url += "&country=" + country;

        return this.http.get<any>(url, httpOptions)
            .pipe(map((res: any) => {
                return res;
            }))
            .pipe(catchError<any,Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    public verifyOtp(token: string, otp: string, email: string, country: string, action: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };

        let url: string = "/ms/claims-service/rest/supplierPortal/verifyOtpAndGetUserDetail";
        url += "?otp=" + otp;
        url += "&email=" + email;
        url += "&country=" + country;
        url += "&action=" + action;

        return this.http.get<any>(url, httpOptions)
            .pipe(map((res : any) => {
                return res;
            }))
            .pipe(catchError<any, Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }


    public verifyUserCredentials(country:string, email: string, password: string, token: string): Observable<any> {

        this.spinnerService.displayLoader(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            })
        };

        let requestBody = {
            country: country,
            email: email,
            password: password
        };

        const url: string = '/ms/claims-service/rest/supplierPortal/verifyUserCredentials';

        return this.http.post<any>(url, requestBody, httpOptions)
            .pipe(map((res: any) => {
                return res;
            }))
            .pipe(catchError<any,Observable<any>>(this.handleError))
            .pipe(finalize( () => {this.spinnerService.displayLoader(false)}));
    }

    private handleError(error: HttpResponse<any> | any) {
        // In a real world app, you might use a remote logging infrastructure
        console.error('RegistrationService handleError');
        let errMsg: string;
        if (error instanceof HttpResponse) {
            console.error(error);
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            console.error('error.message ' + error.message);
            errMsg = error.message ? error.message : error.toString();
        }
        return throwError(() => new Error(errMsg));
    }
}
