import {Injectable} from '@angular/core';

@Injectable()
export class PersonalAccidentHelperService {

    injuredBodyPartList = [
        {
            "id": "head",
            "name": "paClaim.injuryDetails.injuredParts.head",
            "description": "Head"
        },
        {
            "id": "neck",
            "name": "paClaim.injuryDetails.injuredParts.neck",
            "description": "Neck"
        },
        {
            "id": "right-shoulder",
            "name": "paClaim.injuryDetails.injuredParts.right-shoulder",
            "description": "Right Shoulder"
        },
        {
            "id": "left-shoulder",
            "name": "paClaim.injuryDetails.injuredParts.left-shoulder",
            "description": "Left Shoulder"
        },
        {
            "id": "right-arm",
            "name": "paClaim.injuryDetails.injuredParts.right-arm",
            "description": "Right Arm"
        },
        {
            "id": "left-arm",
            "name": "paClaim.injuryDetails.injuredParts.left-arm",
            "description": "Left Arm"
        },
        {
            "id": "right-elbow",
            "name": "paClaim.injuryDetails.injuredParts.right-elbow",
            "description": "Right Elbow"
        },
        {
            "id": "left-elbow",
            "name": "paClaim.injuryDetails.injuredParts.left-elbow",
            "description": "Left Elbow"
        },
        {
            "id": "right-palm",
            "name": "paClaim.injuryDetails.injuredParts.right-palm",
            "description": "Right Palm"
        },
        {
            "id": "left-palm",
            "name": "paClaim.injuryDetails.injuredParts.left-palm",
            "description": "Left Palm"
        },
        {
            "id": "right-finger",
            "name": "paClaim.injuryDetails.injuredParts.right-finger",
            "description": "Right Finger"
        },
        {
            "id": "left-finger",
            "name": "paClaim.injuryDetails.injuredParts.left-finger",
            "description": "Left Finger"
        },
        {
            "id": "chest",
            "name": "paClaim.injuryDetails.injuredParts.chest",
            "description": "Chest"
        },
        {
            "id": "waist",
            "name": "paClaim.injuryDetails.injuredParts.waist",
            "description": "Waist"
        },
        {
            "id": "back",
            "name": "paClaim.injuryDetails.injuredParts.back",
            "description": "Back"
        },
        {
            "id": "thigh",
            "name": "paClaim.injuryDetails.injuredParts.thigh",
            "description": "Thigh"
        },
        {
            "id": "right-calf",
            "name": "paClaim.injuryDetails.injuredParts.right-calf",
            "description": "Right Calf"
        },
        {
            "id": "left-calf",
            "name": "paClaim.injuryDetails.injuredParts.left-calf",
            "description": "Left Calf"
        },
        {
            "id": "right-knee",
            "name": "paClaim.injuryDetails.injuredParts.right-knee",
            "description": "Right Knee"
        },
        {
            "id": "left-knee",
            "name": "paClaim.injuryDetails.injuredParts.left-knee",
            "description": "Left Knee"
        },
        {
            "id": "right-ankle",
            "name": "paClaim.injuryDetails.injuredParts.right-ankle",
            "description": "Right Ankle"
        },
        {
            "id": "left-ankle",
            "name": "paClaim.injuryDetails.injuredParts.left-ankle",
            "description": "Left Ankle"
        },
        {
            "id": "right-toe",
            "name": "paClaim.injuryDetails.injuredParts.right-toe",
            "description": "Right Toe"
        },
        {
            "id": "left-toe",
            "name": "paClaim.injuryDetails.injuredParts.left-toe",
            "description": "Left Toe"
        },
        {
            "id": "internal-organs",
            "name": "paClaim.injuryDetails.injuredParts.internal",
            "description": "Internal Organs"
        }
    ];

    getInjuredBodyParts() {
        return this.injuredBodyPartList;
    }

    geBodyPartById(id: string) {
       return this.injuredBodyPartList.find(bodyPart => bodyPart.id === id);
    }

    geBodyPartByDesc(description: string) {
        return this.injuredBodyPartList.find(bodyPart => bodyPart.description === description);
    }

}