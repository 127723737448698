import {IJsonMetaData} from './json-property.decorator';

export default class JsonMapUtils {
  static isPrimitive(obj) {
    switch (typeof obj) {
      case 'string':
      case 'number':
      case 'boolean':
        return true;
    }
    return !!(obj instanceof String || obj === String ||
    obj instanceof Number || obj === Number ||
    obj instanceof Boolean || obj === Boolean);
  }

  static isArray(object) {
    if (object === Array) {
      return true;
    } else if (typeof Array.isArray === 'function') {
      return Array.isArray(object);
    } else {
      return !!(object instanceof Array);
    }
  }

  static getClazz(target: any, propertyKey: string): any {
    return Reflect.getMetadata('design:type', target, propertyKey);
  }

  static getJsonProperty<T>(target: any, propertyKey: string): IJsonMetaData<T> {
    return Reflect.getMetadata('jsonProperty', target, propertyKey);
  }

  static deserializeList<T>(clazz: { new(): T }, jsonObject) {
    if ((!clazz) || (!jsonObject)) {
      return undefined;
    }
    const obj = [];
    Object.keys(jsonObject).forEach((key) => {
      obj.push(this.deserialize(clazz, jsonObject[key]));
    });
    return obj;
  }

  static deserialize<T>(clazz: { new(): T }, jsonObject) {
    if ((!clazz) || (!jsonObject)) {
      return undefined;
    }
    const obj = new clazz();
    Object.keys(jsonObject).forEach((key) => {
      const propertyMetadataFn: (IJsonMetaData) => any = (propertyMetadata) => {
        const propertyName = propertyMetadata.name || key;
        const innerJson = jsonObject ? jsonObject[propertyName] : undefined;
        const clazz = JsonMapUtils.getClazz(obj, key);
        if (JsonMapUtils.isArray(clazz)) {
          const metadata = JsonMapUtils.getJsonProperty(obj, key);
          if (metadata.clazz || JsonMapUtils.isPrimitive(clazz)) {
            if (innerJson && JsonMapUtils.isArray(innerJson)) {
              return innerJson.map(
                (item) => JsonMapUtils.deserialize(metadata.clazz, item)
              );
            } else {
              return undefined;
            }
          } else {
            return innerJson;
          }

        } else if (!JsonMapUtils.isPrimitive(clazz)) {
          return JsonMapUtils.deserialize(clazz, innerJson);
        } else {
          return jsonObject ? jsonObject[propertyName] : undefined;
        }
      };

      const propertyMetadata = JsonMapUtils.getJsonProperty(obj, key);
      if (propertyMetadata) {
        obj[key] = propertyMetadataFn(propertyMetadata);
      } else {
        if (jsonObject && jsonObject[key] !== undefined) {
          obj[key] = jsonObject[key];
        }
      }
    });
    return obj;
  }
}
