import {Component, Injector, OnInit} from '@angular/core';
import {ClaimService} from "../../../../services/claim.service";
import {Claim} from "../../../../model/claim.model";
import {ClaimsBaseComponent} from "../../../claim-base.component";
import {DatePipe} from "@angular/common";
import {PersonalLiability} from "../../../../model/travel/personal-liability.model";
import { DocumentField } from '../../../../model/document-field';
import { ClaimTypes } from '../../../../model/claim-type.model';
import { DocumentHelperService } from "../../../../services/document-helper.service";

@Component({
    selector: 'app-summary-personal-liability',
    templateUrl: './summary-personal-liability.component.html',
    styleUrls: ['./summary-personal-liability.component.css']
})


export class SummaryPersonalLiabilityComponent  extends ClaimsBaseComponent implements OnInit {

    claim: Claim;
    personalLiability: PersonalLiability;
    listOfDocuments: DocumentField[] = [];
    listOfOtherDocuments: DocumentField[] = [];

    constructor(private claimService: ClaimService,
                private documentHelperService: DocumentHelperService,
                private injector : Injector) {

        super(injector);
        this.claim = this.claimService.getClaim();
        this.personalLiability = this.claim.getClaimTravel().getPersonalLiability();
                this.listOfDocuments = this.documentHelperService.getListOfUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]);
        this.listOfOtherDocuments = this.documentHelperService.getListOfOtherUploadedDocuments(this.claimService.claim.getDocumentForm().documents,
            this.documentHelperService.getDocumentFormBuilder(ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]).getRequiredDocuments(),
            ClaimTypes[ClaimTypes.CLAIM_TYPE_LIAB]);
    }

    ngOnInit() { }

    returnYesOrNo(value: boolean) {
        if (true === value) {
            return this.translate.instant("claimSection.generalLabels.radioButtonLabels.yes");
        } else {
            if (false === value) {
                return this.translate.instant("claimSection.generalLabels.radioButtonLabels.no");
            }
        }

        return '';
    }

    getDate(dt: Date) {
        if (dt && dt != null) {
            return new DatePipe('en-US').transform(dt, "dd/MM/yyyy");
        }
        else {
            return "";
        }
    }

}

