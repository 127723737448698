import {DocumentFormBuilderComponent} from "../document-form-builder.component";
import {OnInit} from "@angular/core";
import {Claim} from "../../../model/claim.model";
import {ClaimTypes} from "../../../model/claim-type.model";
import { DocumentField } from "src/app/model/document-field";
import { SmartqDocuments } from "src/app/model/smartq-documents.model";

export class HospitalCashFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_HOSPITAL_CASH];
    }

    setRequiredDocuments(){
        let requiredDocuments: DocumentField[] = [];
        requiredDocuments.push(new DocumentField('RECEIPTS', 'paClaim.supportingDocs.types.hospitalInvoice', SmartqDocuments.PA_INVOICE, true));
        requiredDocuments.push(new DocumentField('MEDICALRECEIPTS', 'paClaim.supportingDocs.types.medReceipt', SmartqDocuments.PA_INVOICE, true));
        requiredDocuments.push(new DocumentField('REFERRALLETTER', 'paClaim.supportingDocs.types.dischargeSlip', SmartqDocuments.PA_MEDICALREPORT, true));

        let certHospitalizationField = new DocumentField('CERTOFHOSPITALIZATION', 'paClaim.supportingDocs.types.certOfHospitalization', SmartqDocuments.PA_MEDICALREPORT, true);
        certHospitalizationField.setFormUrl('paClaim.supportingDocs.types.certOfHospitalizationUrl');
        certHospitalizationField.setFormDescription('paClaim.supportingDocs.types.certOfHospitalizationDesc');
        certHospitalizationField.setDescriptionHtml('paClaim.supportingDocs.types.certOfHospitalizationHtml');
        requiredDocuments.push(certHospitalizationField);

        this.requiredDocuments = requiredDocuments;
    }
}