import { DocumentFormBuilderComponent } from "./document-form-builder.component";
import { Claim } from "../../model/claim.model";
import { DocumentField } from "../../model/document-field";
import { OnInit } from "@angular/core";
import { ClaimTypes } from "../../model/claim-type.model";
import { SmartqDocuments } from "../../model/smartq-documents.model";
import { UtilitiesService } from "../../utilities/utilities.service";

export class VisitedDoctorFormBuilder extends DocumentFormBuilderComponent implements OnInit {
    constructor(claim: Claim){
        super(claim);
        this.documentFormClaimType = ClaimTypes[ClaimTypes.CLAIM_TYPE_MED_VISIT];
    }

    setRequiredDocuments() {
        let requiredDocuments: DocumentField[] = [];
        if (this.claim.getClaimTravel().getGBAPlanType()) {
            requiredDocuments.push(new DocumentField('PROOFTRAVEL', 'claimSection.greaterBayArea.proofOfTravel', SmartqDocuments.PROOF_TRAVEL, true));
        } else {
            requiredDocuments.push(new DocumentField('AIRTICKET', 'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.copyTicket', SmartqDocuments.AIRTICKET, true));
        }
        let medBills = parseInt(this.claim.getClaimTravel().getVisitedDoctor().getNumberOfTreatmentBill());
        if(!isNaN(medBills) && medBills > 0){
            for(let x = 1; x < medBills + 1; x++){
                requiredDocuments.push(new DocumentField('MEDICALBILL' + String.fromCharCode(x+64), 'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medBills', SmartqDocuments.INVOICE, true));
            }
        }
        requiredDocuments.push(new DocumentField('MEDICALREPORT', 'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.medReport', SmartqDocuments.MEDICALREPORT, true));
        if(this.claim.getClaimTravel().getVisitedDoctor().getHasReceiveFollowUpTreatment()){
            requiredDocuments.push(new DocumentField('RECEIPTFOLLOWUP', 'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptTreat', SmartqDocuments.INVOICE, true));
        }
        requiredDocuments.push(new DocumentField('RECEIPTOTHERS', 'pageSummaryClaim.claimItem.sectionDoctorVisit.subSupportingDocs.receiptExp', SmartqDocuments.INVOICE, true));
        if(UtilitiesService.isMinor(this.claim.getClaimantDateOfBirth())){
            requiredDocuments.push(new DocumentField('PROOFOFREL', 'pageSummaryClaim.claimItem.miscDoc', SmartqDocuments.LEGALDOCUMENT, true));
        }
        this.requiredDocuments = requiredDocuments;
    }
}
