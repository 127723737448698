export class Windscreen {

    private countryOccured: string;
    private countryOccuredDesc: string;
    private placeOccured: string;
    private detailsOfDamagedItem: string[] = [];
    private detailsOfDamagedItemDesc: string[] = [];
    private repairerName: string;
    private repairerNameDesc: string;
    private repairerOtherName: string;
    private repairCost: string;
    private repairCurrency: string;
    private dateOfAccident: Date;
    private causeOfDamage: string;
    private causeOfDamageDesc: string;
    private otherCauseOfDamage: string;

    private hasSelectedMotorPart: boolean;

    getHasSelectedMotorPart(): boolean {
        return this.hasSelectedMotorPart;
    }

    setHasSelectedMotorPart(value: boolean) {
        this.hasSelectedMotorPart = value;
    }

    getRepairerNameDesc(): string {
        return this.repairerNameDesc;
    }

    setRepairerNameDesc(value: string) {
        this.repairerNameDesc = value;
    }

    getRepairerOtherName(): string {
        return this.repairerOtherName;
    }

    setRepairerOtherName(value: string) {
        this.repairerOtherName = value;
    }

    getCountryOccured(): string {
        return this.countryOccured;
    }

    setCountryOccured(value: string) {
        this.countryOccured = value;
    }

    getCountryOccuredDesc(): string {
        return this.countryOccuredDesc;
    }

    setCountryOccuredDesc(value: string) {
        this.countryOccuredDesc = value;
    }

    getPlaceOccured(): string {
        return this.placeOccured;
    }

    setPlaceOccured(value: string) {
        this.placeOccured = value;
    }

    getDetailsOfDamagedItem(): string[] {
        return this.detailsOfDamagedItem;
    }

    setDetailsOfDamagedItem(value: string[]) {
        this.detailsOfDamagedItem = value;
    }

    getDetailsOfDamagedItemDesc(): string[] {
        return this.detailsOfDamagedItemDesc;
    }

    setDetailsOfDamagedItemDesc(value: string[]) {
        this.detailsOfDamagedItemDesc = value;
    }

    getRepairerName(): string {
        return this.repairerName;
    }

    setRepairerName(value: string) {
        this.repairerName = value;
    }

    getRepairCost(): string {
        return this.repairCost;
    }

    setRepairCost(value: string) {
        this.repairCost = value;
    }

    getRepairCurrency(): string {
        return this.repairCurrency;
    }

    setRepairCurrency(value: string) {
        this.repairCurrency = value;
    }

    getDateOfAccident(): Date {
        return this.dateOfAccident;
    }

    setDateOfAccident(value: Date) {
        this.dateOfAccident = value;
    }

    getCauseOfDamage(): string {
        return this.causeOfDamage;
    }

    setCauseOfDamage(value: string) {
        this.causeOfDamage = value;
    }

    getCauseOfDamageDesc(): string {
        return this.causeOfDamageDesc;
    }

    setCauseOfDamageDesc(value: string) {
        this.causeOfDamageDesc = value;
    }

    getOtherCauseOfDamage(): string {
        return this.otherCauseOfDamage;
    }

    setOtherCauseOfDamage(value: string) {
        this.otherCauseOfDamage = value;
    }

    static jsonConvert(windscreen: Windscreen): Windscreen {

        if (windscreen.getDateOfAccident() != null) {
            windscreen.setDateOfAccident(new Date(windscreen.getDateOfAccident()));
        }
        return windscreen;
    }
}