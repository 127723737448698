import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accidental-medical-expenses-support-doc',
  templateUrl: './accidental-medical-expenses-support-doc.component.html'
})
export class AccidentalMedicalExpensesSupportDocComponent implements OnInit {
  public showAddDoc = false;
  constructor() { }

  ngOnInit() {
  }

}
